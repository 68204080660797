(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.documentThumbnail
   */
  angular
    .module("akitabox.ui.directives.documentThumbnail", [
      "angular-inview",
      "ui.router",
      "akitabox.core.constants",
      "akitabox.core.toast",
      "akitabox.core.services.asset",
      "akitabox.core.services.document",
      "akitabox.core.services.flag",
      "akitabox.core.services.pinType",
      "akitabox.core.services.room",
    ])
    .directive("abxDocumentThumbnail", AbxDocumentThumbnail);

  /**
   * @ngdoc directive
   * @name AbxDocumentThumbnail
   * @module akitabox.ui.directives.documentThumbnail
   * @restrict E
   *
   * @description
   * `<abx-document-thumbnail> is responsible for interactive thumbnails`
   *
   * @usage
   *
   * <hljs lang="html">
   * <abx-document-thumbnail src=>
   * </abx-document-thumbnail>
   * </hljs>
   *
   * @ngInject
   */
  function AbxDocumentThumbnail(
    AssetService,
    PinTypeService,
    RoomService,
    ToastService,
    MathService
  ) {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/document-thumbnail/document-thumbnail.html",
      controller: AbxThumbnailController,
      bindToController: true,
      controllerAs: "vm",
      link: postLink,
      scope: {
        document: "=",
        newTab: "&?",
        useMediaViewer: "<?",
        popover: "&?abxPopover",
        popoverSize: "@?abxPopoverSize",
        size: "@?",
        state: "=?",
        room: "<?",
        asset: "<?",
        followLinkEnabled: "<?abxFollowLinkEnabled",
      },
    };

    function postLink($scope, $elem, attrs, vm) {
      vm.pin = vm.asset || vm.room || null;
      vm.followLinkEnabled = angular.isDefined(vm.followLinkEnabled)
        ? vm.followLinkEnabled
        : true;

      // don't bother to load the thumbnail
      if (!vm.document || !vm.document.is_supported) {
        vm.loading = false;
        return;
      }

      var loadErrored = false;

      $scope.$watch("vm.document", function (newValue, oldValue) {
        vm.imageStyle = {};
        vm.loading = true;

        vm.loadImage()
          .then(function (image) {
            // Sometimes the pin.pinType comes in unpopulated....we need it to be populated
            // We can't just query for the pin type because we need the pin's color as well
            // which is on the pin, not the pinType
            if (vm.pin && !angular.isObject(vm.pin.pinType)) {
              var building = vm.pin.building;

              // if we have a pinType ID as a string and a pin color
              if (angular.isString(vm.pin.pinType) && vm.pin.color) {
                PinTypeService.getById(building, vm.pin.pinType).then(function (
                  pinType
                ) {
                  vm.pin.pinType = pinType;
                  return image;
                });
              }

              // if pinType is not on the pin at all or if we don't have a pin color - this happens in
              // a Service Request detail Activity tab
              var options = { includePinType: true };
              var params = {};
              // if the pin has values on it, then we can assume it will expect to have values on the
              // returned object
              if (vm.pin.values) {
                params.include_values = true;
              }
              if (vm.asset) {
                AssetService.getById(
                  building,
                  vm.pin._id,
                  params,
                  options
                ).then(function (asset) {
                  vm.asset = asset;
                  vm.pin = vm.asset;
                  return image;
                });
              } else if (vm.room) {
                RoomService.getById(building, vm.pin._id, params, options).then(
                  function (room) {
                    vm.room = room;
                    vm.pin = vm.room;
                    return image;
                  }
                );
              }
            }
            return image;
          })
          .then(function (image) {
            $elem.find("img").attr("src", image.src);
            resizeImage(image);
          })
          .catch(onLoadError)
          .finally(function () {
            vm.loading = false;
          });
      });
      $scope.$watch("vm.room", function (newValue) {
        vm.pin = newValue;
      });
      $scope.$watch("vm.asset", function (newValue) {
        vm.pin = newValue;
      });
      $scope.$watch("vm.pin", function () {
        if (vm.room && vm.asset) {
          vm.pin = vm.asset;
        } else {
          vm.pin = vm.asset || vm.room || null;
        }

        vm.loadImage().then(function (image) {
          if (vm.pin && vm.pin.percentX && vm.pin.percentY) {
            placePin(vm.pin, image);
            vm.showPin = true;
          } else {
            vm.showPin = false;
          }
        });
      });

      vm.inView = function (inView, inViewInfo) {
        if (inView) {
          vm.loadImage()
            .then(function (image) {
              $elem.find("img").attr("src", image.src);
              resizeImage(image);
              if (vm.pin && vm.pin.percentX && vm.pin.percentY) {
                placePin(vm.pin, image);
              }
            })
            .catch(onLoadError)
            .finally(function () {
              if (vm.pin && vm.pin.percentX && vm.pin.percentY)
                vm.showPin = true;
              vm.loading = false;
            });
        }
      };

      $scope.$watch("vm.popover", function (value) {
        vm.hasPopover = true;
        if (angular.isDefined(value)) {
          vm.hasPopover = angular.isFunction(value)
            ? Boolean(value())
            : Boolean(value);
        }
      });

      function onLoadError(err) {
        if (loadErrored) {
          return;
        }
        $elem.remove();
        ToastService.showError(err);
        loadErrored = true;
      }

      function placePin(pin, image) {
        if (!pin.percentX || !pin.percentY) return;

        var $container = angular.element(
          $elem[0].querySelector(".abx-thumbnail-image")
        );
        var $pin = angular.element(
          $elem[0].querySelector(".abx-thumbnail-pin")
        );
        var containerBounds = $container[0].getBoundingClientRect();
        var pinBounds = $pin[0].getBoundingClientRect();
        var imageHeight =
          vm.imageStyle.height && vm.imageStyle.height !== "auto"
            ? parseFloat(vm.imageStyle.height.slice(0, -2))
            : image.height;
        var imageWidth =
          vm.imageStyle.width && vm.imageStyle.width !== "auto"
            ? parseFloat(vm.imageStyle.width.slice(0, -2))
            : image.width;
        var pinSize = pinBounds.height / 2;
        var yOrigin =
          imageHeight < containerBounds.height
            ? (containerBounds.height - imageHeight) / 2 - pinSize
            : -pinSize;
        var xOrigin =
          imageWidth < containerBounds.width
            ? (containerBounds.width - imageWidth) / 2 - pinSize
            : -pinSize;

        // convert the percentX, percentY as needed to account for rotation
        var rotatedPercentages = MathService.rotateAroundPoint(
          0.5,
          0.5,
          pin.percentX,
          pin.percentY,
          360 - vm.document.rotation
        );
        var yDelta = imageHeight * rotatedPercentages.y;
        var xDelta = imageWidth * rotatedPercentages.x;

        vm.pinStyle.top = yOrigin + yDelta + "px";
        vm.pinStyle.left = xOrigin + xDelta + "px";
      }

      function resizeImage(image) {
        var sizeMap = {
          mini: "53px",
          small: "100px",
          medium: "300px",
          large: "500px",
        };
        vm.imageStyle.height =
          image.height > image.width ? sizeMap[vm.size] : "auto";
        vm.imageStyle.width =
          image.width >= image.height ? sizeMap[vm.size] : "auto";
      }
    }
  }

  /**
   * @ngInject
   */
  function AbxThumbnailController(
    models,
    $log,
    $q,
    $state,
    $timeout,
    $window,
    IMAGE_SIZES,
    DocumentService,
    FeatureFlagService
  ) {
    var self = this;

    self.useMediaViewer = self.useMediaViewer || false;
    self.loading = true;
    self.onPage = false;
    self.hasPopover = true;
    self.showPin = false;
    self.imageStyle = {};
    self.pinStyle = {};
    self.popoverError = null;
    self.popoverImageStyle = null;
    self.popoverLoaded = false;
    self.popoverStyle = null;
    self.popoverUrl =
      "app/core/ui/directives/document-thumbnail/document-thumbnail-popover.html";

    if (!self.document) {
      return $log.error("<abx-document-thumbnail>: document is required");
    }

    // Defaulted parameters
    if (
      !angular.isString(self.size) ||
      !Object.prototype.hasOwnProperty.call(
        IMAGE_SIZES,
        self.size.toUpperCase()
      )
    )
      self.size = IMAGE_SIZES.MINI;
    if (
      !angular.isString(self.popoverSize) ||
      !Object.prototype.hasOwnProperty.call(
        IMAGE_SIZES,
        self.popoverSize.toUpperCase()
      )
    ) {
      if ($window.innerWidth > 1100 && $window.innerHeight > 800) {
        self.popoverSize = IMAGE_SIZES.LARGE;
      } else if ($window.innerWidth > 800 && $window.innerHeight > 500) {
        self.popoverSize = IMAGE_SIZES.MEDIUM;
      } else {
        self.popoverSize = IMAGE_SIZES.SMALL;
      }
    }
    if (angular.isDefined(self.popover)) {
      // Make sure we force these into booleans
      self.hasPopover = angular.isFunction(self.popover)
        ? Boolean(self.popover())
        : Boolean(self.popover);
    }

    self.loadImage = loadImage;
    self.loadPopoverImage = loadPopoverImage;
    self.followLink = followLink;

    function loadImage() {
      return $q(function (resolve, reject) {
        var image = new Image();

        image.onload = function () {
          return resolve(this);
        };
        image.onerror = function (err) {
          return reject(err);
        };

        var imgUrl = self.document["public_thumbnail_url_" + self.size];

        image.src = imgUrl;
      });
    }

    function loadPopoverImage() {
      let image = new Image();

      image.onload = function () {
        let image = this;

        if (image.src && image.src.includes("X-Amz-Date")) {
          // get url
          const params = new Proxy(new URLSearchParams(image.src), {
            get: (searchParams, prop) => searchParams.get(prop),
          });

          // add to get final expire time
          const expireTime =
            getUTCFromDate(params["X-Amz-Date"]) +
            Number(params["X-Amz-Expires"]) * 1000;
          const currentTime = new Date().getTime();

          if (expireTime <= currentTime) {
            DocumentService.getById(
              self.document.building,
              self.document._id
            ).then((doc) => {
              $timeout(function () {
                self.popoverLoaded = true;
                self.popoverImage =
                  doc[
                    `public_thumbnail_url_${
                      IMAGE_SIZES[self.popoverSize.toUpperCase()]
                    }`
                  ];

                self.popoverStyle = {
                  height: image.height + "px",
                  width: image.width + "px",
                };
              });
            });
          } else {
            setPopoverImageTimeout(image);
          }
        } else {
          setPopoverImageTimeout(image);
        }
      };
      image.onerror = function (err) {
        self.popoverError = err.message;
      };

      self.popoverLoaded = false;

      var imgUrl =
        self.document[
          `public_thumbnail_url_${IMAGE_SIZES[self.popoverSize.toUpperCase()]}`
        ];

      image.src = imgUrl;
    }

    function followLink($event) {
      if (!self.followLinkEnabled) {
        self.hasPopover = false;
        return;
      }

      var isMedia = models.DOCUMENT.MEDIA_VIEWER_SUPPORTED_TYPES.includes(
        self.document.extension.toLowerCase()
      );
      var openInMediaViwer =
        (self.useMediaViewer || !self.document.is_floor_plan) && isMedia;

      var openInBrowser =
        self.useMediaViewer && self.document.extension.toLowerCase() === ".pdf";
      if (self.state) {
        $event.stopImmediatePropagation();
      }

      // https://akitabox.atlassian.net/browse/WEBAPP-5437
      // Media      -> Media Viewer
      // Floor Plan -> PlanView
      // Other      -> Let browser handle it
      if (openInMediaViwer) {
        $window.open(self.document.media_uri, "_blank");
      } else if (openInBrowser) {
        $window.open(self.document.public_url, "_blank");
      } else if (self.document.is_floor_plan) {
        var data = {
          buildingId: self.document.building,
          floorId: self.document.level,
        };

        if (self.asset) {
          data.asset = self.asset._id;
          data.search = JSON.stringify({
            model: models.ASSET.SINGULAR,
            pin: self.asset._id,
          });
        } else if (self.room) {
          data.room = self.room._id;
          data.search = JSON.stringify({
            model: models.ROOM.SINGULAR,
            pin: self.room._id,
          });
        }

        var hasPage = self.asset
          ? self.asset.page
          : self.room
          ? self.room.page
          : false;

        if (hasPage) {
          data.page = hasPage;
        }

        var url = $state.href("planView.building.floor", data);
        $window.open(url, "_blank");
      } else {
        $window.open(self.document.public_url, "_blank");
      }
    }

    function getUTCFromDate(isoStringDate) {
      // format X-Amz-Date into form that Date() can handle
      // e.g. 20220721T0935310Z -> 2022-07-21T09:35:310Z
      let formatted = isoStringDate.replace(
        /(\d{4})(\d{2})(\w+)(\d{2})(\d{2})/,
        "$1-$2-$3:$4:$5"
      );

      const time = new Date(formatted).getTime();
      return time || 0;
    }

    function setPopoverImageTimeout(image) {
      $timeout(function () {
        self.popoverLoaded = true;
        self.popoverImage = image.src;

        self.popoverStyle = {
          height: image.height + "px",
          width: image.width + "px",
        };
      });
    }
  }
})();
