(function () {
  /**
   * @ngdoc directive
   * @name abxBlurOnEnter
   *
   * @description
   * Apply to inputs to simulate the input blurring when the user presses the
   * Enter key.
   *
   * @usage
   * - <input abx-blur-on-enter />
   * - <input abx-blur-on-enter="vm.blurOnEnter" />
   * - <input abx-blur-on-enter="true" />
   */
  angular
    .module("akitabox.core.directives.enter", [])
    .directive("abxBlurOnEnter", AbxBlurOnEnterDirective);

  /** @ngInject */
  function AbxBlurOnEnterDirective() {
    return {
      restrict: "A",
      link: postLink,
    };

    function postLink($scope, $element, attrs) {
      // Only enable this for `input` elements to avoid namespace collisions
      // with component bindings
      var isInput = $element[0].tagName === "INPUT";
      if (!isInput) return;

      // It will evaluate to an empty string if no value is given for the
      // HTML attribute -- we want truthy behavior in this case
      var blurOnEnter =
        attrs.abxBlurOnEnter === "" || $scope.$eval(attrs.abxBlurOnEnter);
      if (!blurOnEnter) return;

      $element.on("keydown keypress", function ($event) {
        var enterKeyCode = 13;
        if ($event.which === enterKeyCode) {
          $event.target.blur();
          $event.preventDefault();
        }
      });
    }
  }
})();
