(function () {
  angular.module("akitabox.ui.components.pinDetails", [
    "akitabox.core.services.fieldGroup",
    "akitabox.core.services.flag",
    "akitabox.core.services.floor",
    "akitabox.core.services.organization",
    "akitabox.core.services.room",
    "akitabox.core.toast",
    "akitabox.core.utils",
    "akitabox.ui.components.conditionInput",
    "akitabox.ui.components.qrcodeInput",
    "akitabox.ui.dialogs.document.download",
    "akitabox.ui.dialogs.filePreview",
  ]);
})();
