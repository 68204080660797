(function () {
  angular.module("akitabox.desktop.reporting").run(states);

  /* @ngInject */
  function states(models, Router) {
    Router.configureStates([
      {
        state: "app.building.reporting",
        config: {
          url: "/reporting",
          templateUrl: "app/desktop/modules/reporting/reporting.html",
          controller: "ReportingController",
          controllerAs: "vm",
          data: {
            pageTitle: "Reporting",
          },
          resolve: {
            redirect: redirect,
          },
        },
      },
      {
        state: "app.reporting",
        config: {
          url: "/reporting",
          templateUrl: "app/desktop/modules/reporting/reporting.html",
          controller: "ReportingController",
          controllerAs: "vm",
          data: {
            pageTitle: "Reporting",
          },
          resolve: {
            redirect: redirect,
          },
        },
      },
    ]);
  }

  /* @ngInject */
  function redirect($state, user, UserService) {
    var permissions = UserService.getPermissions();

    if (!(permissions.task.reporting && permissions.request.reporting)) {
      return $state.go("root.404");
    }
  }
})();
