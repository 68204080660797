(function () {
  angular.module("akitabox.desktop.room.list", [
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.services.filter",
    "akitabox.core.services.room",
    "akitabox.core.services.pin",
    "akitabox.core.services.pinType",
    "akitabox.core.services.room",
    "akitabox.core.services.flag",
    "akitabox.core.services.organization",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.room.create",
    "akitabox.desktop.components.filterBar",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
