(function () {
  angular
    .module("akitabox.desktop.directives.pinValueList")
    .directive("abxPinValueList", AbxPinValueListDirective);

  /* @ngInject */
  function AbxPinValueListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/pin-value-list/pin-value-list.html",
      controller: "PinValueListController", // Use core pin value list in core/modules/pin-value
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=",
        pin: "=",
        isRoom: "=?",
        isAsset: "=?",
        values: "=",
        onChange: "&",
        save: "=?",
      },
    };

    function postLink($scope, $element, attrs, vm) {
      vm.noSections = angular.isDefined(attrs.abxNoSections);
      vm.useDefaults = angular.isDefined(attrs.abxUseDefaults);
      vm.hidden = angular.isDefined(attrs.abxHideTypes)
        ? attrs.abxHideTypes.split(",")
        : null;

      $scope.$watch(function () {
        return $element[0].attributes.readonly;
      }, setReadonly);

      function setReadonly(value) {
        vm.readonly = Boolean(value);
      }
    }
  }
})();
