(function () {
  angular.module("akitabox.ui.dialogs", [
    "akitabox.ui.dialogs.alert",
    "akitabox.ui.dialogs.assemblyCondition",
    "akitabox.ui.dialogs.assign.assign",
    "akitabox.ui.dialogs.asset",
    "akitabox.ui.dialogs.assetCondition",
    "akitabox.ui.dialogs.assetExportJob",
    "akitabox.ui.dialogs.association",
    "akitabox.ui.dialogs.autocomplete",
    "akitabox.ui.dialogs.bulkAssign",
    "akitabox.ui.dialogs.bulkAdd.relationships",
    "akitabox.ui.dialogs.bulkDelete.asset",
    "akitabox.ui.dialogs.changeDecommissionDate",
    "akitabox.ui.dialogs.externalWorkOrderLink",
    "akitabox.ui.dialogs.decommission",
    "akitabox.ui.dialogs.delete",
    "akitabox.ui.dialogs.document",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.floor",
    "akitabox.ui.dialogs.job",
    "akitabox.ui.dialogs.editLocation",
    "akitabox.ui.dialogs.filePreview",
    "akitabox.ui.dialogs.printInspectionProgram",
    "akitabox.ui.dialogs.qrCode",
    "akitabox.ui.dialogs.qrConfirmAssign",
    "akitabox.ui.dialogs.qrScanner",
    "akitabox.ui.dialogs.recommission",
    "akitabox.ui.dialogs.schedule",
    "akitabox.ui.dialogs.note",
    "akitabox.ui.dialogs.request",
    "akitabox.ui.dialogs.room",
    "akitabox.ui.dialogs.workOrder",
    "akitabox.ui.dialogs.workOrderLog",
    "akitabox.ui.dialogs.message",
    "akitabox.ui.dialogs.displayText",
    "akitabox.ui.dialogs.updateIdentityNames",
    "akitabox.ui.dialogs.inspectionProgram",
  ]);
})();
