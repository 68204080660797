(function () {
  angular
    .module("akitabox.desktop.directives.pinValueList")
    .directive("abxPinValueField", AbxPinValueFieldDirective);

  /**
   * @ngInject
   */
  function AbxPinValueFieldDirective($http, $compile, $log, $templateCache) {
    return {
      restrict: "E",
      require: ["abxPinValueField", "^^?form", "^?abxPinValueList"],
      controller: PinValueFieldController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        model: "=abxModel", // Pin value object
        onChange: "&abxOnChange",
        field: "=",
        building: "=",
        floor: "=",
        pin: "=",
        isAsset: "=",
        isRoom: "=",
        fetchOpts: "&",
        useDefault: "<abxUseDefault",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.formCtrl = controllers[1];
      vm.listCtrl = controllers[2];

      vm.inputName = "field_" + vm.field._id;
      vm.formName = "form_" + vm.field._id;

      var hidden = vm.hidden && vm.hidden.indexOf(vm.field.data_type !== -1);
      var noSections = vm.listCtrl && vm.listCtrl.noSections;
      var templateUrl = buildTemplateUrl(vm.field.data_type, noSections);

      if (!templateUrl || hidden) return;

      $scope.$watch(function () {
        return $element[0].attributes.readonly;
      }, setReadonly);

      $http
        .get(templateUrl, { cache: $templateCache })
        .then(function (response) {
          if (response.status === 200) {
            $element.html(response.data);
            $compile($element.contents())($scope);
          } else {
            return $log.error(
              "<abx-pin-value-field> unable to retrieve '" + templateUrl + "'"
            );
          }
        });

      function setReadonly(value) {
        vm.readonly = Boolean(value);
      }
    }

    /**
     * Build a template URL based on pin field type
     *
     * @param    {String}     type         Pin field type
     * @param    {String}     noSection    Template URL
     */
    function buildTemplateUrl(type, noSection) {
      var base = "app/desktop/directives/pin-value-list/templates/";
      if (!angular.isDefined(type))
        return $log.error("<abx-pin-value-field> type is required");
      switch (type) {
        case "level":
          type = "floor";
          break;
        case "document_array":
        case "tag_filter":
          noSection = true;
          break;
      }
      var url =
        base + type.replace("_", "-") + (noSection ? "" : "-section") + ".html";
      return url;
    }
  }

  /**
   * @ngInject
   */
  function PinValueFieldController(
    // Angular
    $log,
    $scope,
    // Third-party
    $state,
    // Services
    AssetService,
    FeatureFlagService,
    PinValueService,
    RoomService,
    ToastService
  ) {
    var self = this;
    var buildingId;

    // Attributes
    self.editing = false;
    self.value = null;
    self.originalValue = null;
    self.options = null;
    self.placeholder = "Enter " + self.field.name.toLowerCase();
    self.save = angular.isDefined(self.save) ? self.save : true;
    self.readonly = !self.field.is_editable;
    self.saving = false;

    // Functions
    self.onSave = onSave;
    self.onCancel = onCancel;
    self.goToState = goToState;

    init();

    function init() {
      if (self.isAsset ? self.isRoom : !self.isRoom) {
        $log.error(
          "<abx-pin-value-field>: Only one of is-asset and is-room can be true"
        );
      }
      if (!self.save) self.editing = true;
      $scope.$watch("vm.editing", onEditingChange);

      buildingId = Object.prototype.hasOwnProperty.call(self.building, "_id")
        ? self.building._id
        : self.building;

      PinValueService.getByDataType(
        buildingId,
        self.field.data_type,
        self.model
      )
        .then(function (value) {
          if (self.useDefault && !self.field.is_default_empty) {
            self.value = self.field.default_value;
          } else {
            self.value = value;
          }

          self.originalValue = angular.copy(self.value);
          return self.fetchOpts({ pinField: self.field });
        })
        .then(function (options) {
          self.options = options;
        })
        .catch(function (err) {
          if (err) ToastService.showError(err);
          self.options = [];
        });
    }

    /**
     * Handle editing state change
     *
     * @param  {Boolean} newValue   Currently editing
     * @param  {Boolean} oldValue   Was editing
     */
    function onEditingChange(newValue, oldValue) {
      if (newValue === oldValue) return;
      if (newValue) {
        // Hide the FAB
        $scope.$emit("fab:hide");
      } else {
        // Show the FAB
        $scope.$emit("fab:show");
      }
    }

    function onCancel() {
      self.value = angular.copy(self.originalValue);
    }

    function onSave(newValue, save) {
      // self.save defaults to true, so this will execute unless specifically told not to
      save = angular.isDefined(save) ? save : true;

      if (save) {
        // Update this value
        var dataType = self.field.data_type;
        var valueId = self.model ? self.model._id : null;

        var updateRequest;
        if (self.isRoom) {
          updateRequest = RoomService.updateValueByDataType(
            buildingId,
            dataType,
            self.pin,
            valueId,
            newValue
          );
        } else if (self.isAsset) {
          updateRequest = AssetService.updateValueByDataType(
            buildingId,
            dataType,
            self.pin,
            valueId,
            newValue
          );
        }
        self.saving = true;
        updateRequest
          .then(function () {
            // Save was successful
            self.originalValue = angular.copy(newValue);
            self.onChange({ field: self.field, value: newValue });
          })
          .catch(function (err) {
            // revert
            ToastService.showError(err);
            self.onCancel();
          })
          .finally(function () {
            self.saving = false;
          });

        // account for the case where we need to create an asset that doesn't yet have a Room or Floor
      } else if (angular.isDefined(newValue)) {
        return self.onChange({ field: self.field, value: newValue });
      }
    }

    function goToState(type) {
      var state;
      var params = { buildingId: self.building };

      switch (type) {
        case "room":
          state = "app.room.detail";
          params.roomId = self.value._id;
          break;
        default:
          return;
      }

      $state.go(state, params);
    }
  }
})();
