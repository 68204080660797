(function () {
  /**
   * @ngdoc service
   * @name TokenService
   * @module akitabox.core.services.token
   *
   * @description
   * helper service that handles storing the auth token for http requests
   */

  angular
    .module("akitabox.core.services.token", ["akitabox.core.services.env"])
    .factory("TokenService", TokenService);

  /* @ngInject */
  function TokenService($cookies, EnvService) {
    var TOKEN_KEY = "abx_jwt";
    var BASE_URL = "." + EnvService.getBaseUrl().split("://")[1].split(":")[0];
    var authToken;

    var service = {
      setAuthToken: setAuthToken,
      getAuthToken: getAuthToken,
      getAuthHeader: getAuthHeader,
      removeAuthToken: removeAuthToken,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * sets the auth token in session storage
     *
     * @param  {String}     token // jwt
     */
    function setAuthToken(token) {
      $cookies.put(TOKEN_KEY, token, {
        domain: BASE_URL,
      });
    }

    /**
     * returns the auth token currently stored
     *
     * @return {String}
     */
    function getAuthToken() {
      if (!authToken) {
        return $cookies.get(TOKEN_KEY) || "";
      }

      return authToken;
    }

    /**
     * returns the auth header required to send the auth token to the server
     *
     * @return {String}
     */
    function getAuthHeader() {
      var headerPrefix = "Bearer ";
      var token = getAuthToken();

      return headerPrefix + token;
    }

    /**
     * removes the auth token from storage
     *
     * @return {String}
     */
    function removeAuthToken() {
      $cookies.remove(TOKEN_KEY, {
        domain: BASE_URL,
      });
    }
  }
  // ==============
})();
