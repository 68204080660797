(function () {
  /**
   * @ngdoc component
   * @name abxBuilding
   *
   * @param {Object}    model       Initial place data model
   * @param {Function}  onChange    Invoked when the user selects an option
   * @param {Boolean}   [disabled]  Disable the input, defaults to false
   * @param {Boolean}   [required]  Visually marks the input as required and
   *                                adds validation
   *
   * @description
   * Round Template input that pulls suggestions from the current organization
   */
  angular
    .module("akitabox.ui.components.input.roundTemplate", [
      "akitabox.ui.components.typeAheadInput",
    ])
    .component("abxRoundTemplate", {
      bindings: {
        buildingId: "<abxBuildingId",
        model: "<abxModel",
        disabled: "<abxDisabled",
        onBlur: "&abxOnBlur",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        value: "<abxValue",
        initialOptions: "<?abxInitialOptions",
      },
      controller: AbxRoundController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/input/components/building/building.component.html",
    });

  /* @ngInject */
  function AbxRoundController(
    RoundTemplateService,
    OrganizationService,
    ToastService
  ) {
    var self = this;

    self.$onChanges = $onChanges;

    // Private
    var roundTemplatesRequest;
    var organization = OrganizationService.getCurrent();

    // Attributes
    self.suggestions = []; // Options objects consumable by the typeahead
    self.loading = false;

    // Functions
    self.handleFocus = handleFocus;

    // =================
    // Public Functions
    // =================

    function $onChanges(changes) {
      var skipRoundTemplateFetch = false;
      if (changes.initialOptions && self.initialOptions) {
        self.suggestions = createEnumObjects(self.initialOptions);
        skipRoundTemplateFetch = true;
        self.initialOptions = [];
      }
      if (changes.buildingId && !skipRoundTemplateFetch) {
        roundTemplatesRequest = getRoundTemplates();
        if (self.model) {
          var modelBuildingId = self.model.building._id || self.model.building;
          if (modelBuildingId !== self.buildingId) {
            self.onChange({
              $event: {
                model: null,
                value: "",
              },
            });
          }
        }
      }
    }

    /**
     * Handle input focus
     */
    function handleFocus() {
      self.onFocus();

      if (!roundTemplatesRequest) {
        roundTemplatesRequest = getRoundTemplates();
      }
    }

    // =================
    // Public Functions
    // =================
    /**
     * Fetch round templates and populate self.suggestions
     * with them. Toasts on error.
     *
     * If there is a current organization set in the application,
     * get that organization's round templates. Error otherwise.
     *
     * @return {Promise} - A promise that resolves with the round templates once
     *    self.suggestions has been populated.
     */
    function getRoundTemplates() {
      self.loading = true;

      var params = {
        sort_by: "name,asc",
        $or: "assets_count=$gt,0;rooms_count=$gt,0",
      };
      if (self.buildingId) {
        params.building = self.buildingId;
      }

      if (!organization) {
        ToastService.showError(
          new Error("No organization set, unable to load Round Templates")
        );
      }
      var roundTemplatesRequest = RoundTemplateService.getAll(
        organization._id,
        params
      );
      return roundTemplatesRequest
        .then(function (roundTemplates) {
          createEnumObjects(roundTemplates);
        })
        .catch(function () {
          self.suggestions = [];
          ToastService.showError();
        })
        .finally(function () {
          self.loading = false;
        });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Update self.suggestions with the given round templates, thus providing
     * all of the buildings as options to the typeahead.
     *
     * @param {Object[]} roundTemplates - The round templates to provide to the typeahead
     */
    function createEnumObjects(roundTemplates) {
      self.suggestions = [];
      for (var i = 0; i < roundTemplates.length; i++) {
        self.suggestions.push(createValueObject(roundTemplates[i]));
      }
    }

    /**
     * Helper function to create a valueObject for the typeahead
     *
     * @param {Object} roundTemplate - The round template to create a valueObject for
     * @return {Object} - A valueObject populated with the building as the value,
     *    the building's name as the label, and the building's mongo id as id.
     */
    function createValueObject(roundTemplate) {
      if (!roundTemplate) {
        return {
          model: null,
          value: null,
        };
      }
      return {
        model: roundTemplate,
        value: roundTemplate.name,
      };
    }
  }
})();
