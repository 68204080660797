import { Alert, AlertColor } from "@mui/material";
import { forwardRef, ReactNode } from "react";

/**
 * Simple preconfigured alert component for snackbars. Supports minimal
 * customization.
 *
 * This component shouldn't be used directly very often. Generally prefer
 * the `simple` method on the global snackbar context.
 */
export const SimpleAlert = forwardRef<HTMLDivElement, SimpleAlertProps>(
  ({ ...props }, ref) => <Alert {...props} ref={ref} />
);
SimpleAlert.displayName = "SimpleAlert";

export interface SimpleAlertProps {
  "data-testId"?: string;
  onClose: () => void;
  severity: AlertColor;
  children?: ReactNode;
}
