(function () {
  angular
    .module("akitabox.ui.dialogs.document.restore")
    .controller(
      "RestoreDocumentDialogController",
      RestoreDocumentDialogController
    );

  /* @ngInject */
  function RestoreDocumentDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    DocumentService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since documents is passed in through locals
    self.multiple = self.documents ? self.documents.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.restore = restore;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Restore documents
     */
    function restore() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.documents.length) * 100;
      var promiseArray = [];

      // Restore the documents
      for (var i = 0; i < self.documents.length; i++) {
        promiseArray[i] = DocumentService.unarchive(
          self.documents[i].building,
          self.documents[i]._id
        ).then(function (restoredDocument) {
          self.determinateValue += increment;
          return restoredDocument._id;
        });
      }

      $q.all(promiseArray)
        .then(function (restoredDocumentIDs) {
          $mdDialog.hide(restoredDocumentIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
