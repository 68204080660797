(function () {
  angular
    .module("akitabox.ui.dialogs.workOrderLog.delete")
    .factory("DeleteWorkOrderLogDialog", DeleteWorkOrderLogDialog);

  /* @ngInject */
  function DeleteWorkOrderLogDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/work-order-log/delete/delete-work-order-log.dialog.html",
      controller: "DeleteWorkOrderLogDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.workOrderLog` and `options.locals.buildingId` are required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error>}              Promise that resolves with the deleted work orders
     */
    function show(options) {
      // Verify we have workOrderIDs and building locals
      if (
        !options ||
        !options.locals ||
        !options.locals.workOrderLog ||
        !options.locals.buildingId
      ) {
        $log.error(
          "DeleteWorkOrderLogDialog: you must pass in workOrderLog and building via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
