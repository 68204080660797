(function () {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .service("AssetCostLineSelectionDialog", AssetCostLineSelectionDialog);

  /* @ngInject */
  function AssetCostLineSelectionDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/asset-cost-line-selection.dialog.html",
      controller: "AssetCostLineSelectionDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * AssetCostLineSelectionDialog service
     *
     * @type {Object}
     */
    var service = {
      show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}    options                       Dialog options
     * @param {Asset[]}   [options.locals.assets]       Assets to manage cost lines for
     * @return {Promise<Object[]|Error>}                Promise that resolves with the newly created assets.
     *                                                  Their pin types will come as populated
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
