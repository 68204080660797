import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FC, useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { stylesheet } from "../stylesheet";
import {
  ReportBlockBuildingFilters,
  ReportBlockSelectionDataGrid,
  ReportBlockSelectionDataGridFilters,
} from "./ReportBlockSelectionDataGrid";
import {
  AttachmentResponse,
  ReportBlockResponse,
  WebAssetResponse,
  WebBuildingResponse,
} from "@akitabox/api-client";
import { api } from "../api";
import SaveIcon from "@mui/icons-material/Save";
import { useGlobalSnackbar } from "../consecutive-snackbar/GlobalSnackbar";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { isAxiosError } from "axios";

export const ASSET_SELECTION_TEMPLATE_OPTIONS = [
  "image-grid-with-details",
  "image-grid",
  "single-image-with-details",
  "single-image",
] as const;

export type ReportBlockSelectionTemplate =
  typeof ASSET_SELECTION_TEMPLATE_OPTIONS[number];

export type AssetAttachmentSelection = {
  [key: string]: {
    default?: AttachmentResponse;
    attachments: AttachmentResponse[];
    loading: boolean;
  };
};

export type ReportBlockSelectionDialogResult =
  | {
      action: "cancel";
    }
  | {
      action: "confirm";
    };

export interface ReportBlockSelectionDialogProps
  extends Omit<DialogProps, "onClose"> {
  organizationId: string;
  onClose: (result: ReportBlockSelectionDialogResult) => Promise<void>;
  processChunks: (blockContents: string[]) => Promise<void>;
}

export const ReportBlockSelectionDialog: FC<
  ReportBlockSelectionDialogProps
> = ({ organizationId, onClose, processChunks, ...props }) => {
  const { simple } = useGlobalSnackbar();

  const [loading, setLoading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<WebAssetResponse[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<
    WebBuildingResponse[]
  >([]);
  const [assetSortModel, setAssetSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [buildingSortModel, setBuildingSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [insertAllFilters, setInsertAllFilters] = useState<
    ReportBlockSelectionDataGridFilters | ReportBlockBuildingFilters | undefined
  >();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState<boolean>(true);

  // Both these are used to display the count of assets and buildings when user interacts with "Insert All" feature
  const [assetsCount, setAssetsCount] = useState<number>(0);
  const [buildingsCount, setBuildingsCount] = useState<number>(0);

  const [selectedReportBlock, setSelectedReportBlock] =
    useState<ReportBlockResponse | null>(null);

  const BLOCK_CONTENT_LIMIT = 150;

  const cancelDialog = useCallback(() => {
    resetState();
    onClose({ action: "cancel" });
  }, [onClose]);

  const handleClose = useCallback<
    Exclude<DialogProps["onClose"], null | undefined>
  >(
    (e, reason) => {
      if (reason === "backdropClick" || loading) {
        return;
      }
      cancelDialog();
    },
    [cancelDialog, loading]
  );

  const resetState = () => {
    setLoading(false);
    setSelectedAssets([]);
    setAssetSortModel([{ field: "name", sort: "asc" }]);
    setSelectedBuildings([]);
    setBuildingSortModel([{ field: "name", sort: "asc" }]);
    setSelectedReportBlock(null);
    setAssetsCount(0);
    setBuildingsCount(0);
    setProgress(0);
    setShowProgress(true);
    setInsertAllFilters(undefined);
  };

  const sortItems = (items: WebAssetResponse[] | WebBuildingResponse[]) => {
    const sortOrder = assetSortModel.find((item) => item.field === "name");
    let sorted;
    if (sortOrder) {
      const isAsc = sortOrder.sort === "asc";
      sorted = items.sort((a, b) => {
        if (isAsc) {
          return a.name.localeCompare(b.name, "en", { numeric: true });
        } else {
          return b.name.localeCompare(a.name, "en", { numeric: true });
        }
      });
    } else {
      // If there is no sort order, sort by name in ascending order
      sorted = items.sort((a, b) =>
        a.name.localeCompare(b.name, "en", { numeric: true })
      );
    }

    return sorted;
  };

  const processAssets = async (selectedReportBlock: ReportBlockResponse) => {
    const sortOrder = assetSortModel.find((item) => item.field === "name");
    const sort = sortOrder
      ? `${sortOrder.field},${sortOrder.sort}`
      : "name,asc";
    let currentAssetCount = 0;

    while (currentAssetCount < assetsCount) {
      const content = await api.reportBlockContent.previewReportBlockContent({
        organizationId,
        reportBlockId: selectedReportBlock._id,
        name: insertAllFilters?.name,
        values: (insertAllFilters as ReportBlockSelectionDataGridFilters)
          ?.values,
        sort,
        skip: currentAssetCount,
        limit: BLOCK_CONTENT_LIMIT,
      });
      await processChunks([content.data.content || ""]);

      currentAssetCount += BLOCK_CONTENT_LIMIT;
      if (showProgress) {
        setProgress(Math.min((currentAssetCount / assetsCount) * 100, 100));
      }
    }
  };

  const processBuildings = async (selectedReportBlock: ReportBlockResponse) => {
    const sortOrder = buildingSortModel.find((item) => item.field === "name");
    const sort = sortOrder
      ? `${sortOrder.field},${sortOrder.sort}`
      : "name,asc";
    let currentBuildingCount = 0;

    while (currentBuildingCount < buildingsCount) {
      const content = await api.reportBlockContent.previewReportBlockContent({
        organizationId,
        reportBlockId: selectedReportBlock._id,
        name: insertAllFilters?.name,
        address: (insertAllFilters as ReportBlockBuildingFilters)?.address,
        sort,
        skip: currentBuildingCount,
        limit: BLOCK_CONTENT_LIMIT,
      });
      await processChunks([content.data.content || ""]);

      currentBuildingCount += BLOCK_CONTENT_LIMIT;
      if (showProgress) {
        setProgress(Math.min((currentBuildingCount / assetsCount) * 100, 100));
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" {...props} onClose={handleClose}>
      <DialogTitle component={Box} css={ss.dialogTitle}>
        <Typography variant="h6" color="inherit">
          Insert Blocks
        </Typography>
        <IconButton color="inherit" onClick={cancelDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ReportBlockSelectionDataGrid
          organizationId={organizationId}
          selectedReportBlock={selectedReportBlock}
          setAssetSortModel={setAssetSortModel}
          assetSortModel={assetSortModel}
          setBuildingSortModel={setBuildingSortModel}
          buildingSortModel={buildingSortModel}
          setSelectedAssets={setSelectedAssets}
          setSelectedReportBlock={setSelectedReportBlock}
          setSelectedBuildings={setSelectedBuildings}
          onFilterChange={(filter) => {
            setInsertAllFilters(filter);
          }}
          onAssetCountChange={(count) => setAssetsCount(count)}
          onBuildingsCountChange={(count) => setBuildingsCount(count)}
        />
      </DialogContent>
      <DialogActions
        css={{
          justifyContent: selectedReportBlock ? "space-between" : "flex-end",
        }}
      >
        {selectedReportBlock !== null && (
          <Button
            onClick={async () => {
              setLoading(true);

              if (!selectedReportBlock) {
                throw new Error("Report block not selected");
              }

              if (assetsCount > 0) {
                if (assetsCount < BLOCK_CONTENT_LIMIT) {
                  setShowProgress(false);
                }
                await processAssets(selectedReportBlock);
              } else if (buildingsCount > 0) {
                if (buildingsCount < BLOCK_CONTENT_LIMIT) {
                  setShowProgress(false);
                }
                await processBuildings(selectedReportBlock);
              }

              resetState();
              await onClose({
                action: "confirm",
              });
            }}
            disabled={loading}
            css={{ flex: "0 0 auto" }}
            variant="contained"
          >
            Insert All{" "}
            {selectedReportBlock.context === "asset"
              ? assetsCount
              : buildingsCount}{" "}
            {selectedReportBlock.context === "asset" ? "Assets" : "Buildings"}
          </Button>
        )}
        {loading && showProgress && (
          <Box sx={{ width: "60%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
        <Box>
          <Button onClick={cancelDialog} disabled={loading}>
            CANCEL
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            color="primary"
            disabled={
              (selectedBuildings.length === 0 && selectedAssets.length === 0) ||
              loading
            }
            onClick={async () => {
              setLoading(true);
              try {
                if (!selectedReportBlock) {
                  throw new Error("Report block not selected");
                }

                if (selectedAssets.length > 0) {
                  const sortedAssets = sortItems(selectedAssets);
                  if (sortedAssets.length < BLOCK_CONTENT_LIMIT) {
                    setShowProgress(false);
                  }
                  const assetIds = sortedAssets.map((asset) => asset._id);
                  let currentAssetCount = 0;

                  const sortOrder = assetSortModel.find(
                    (item) => item.field === "name"
                  );
                  const sort = sortOrder
                    ? `${sortOrder.field},${sortOrder.sort}`
                    : "name,desc";

                  while (currentAssetCount < assetIds.length) {
                    const batchIds = assetIds.slice(
                      currentAssetCount,
                      currentAssetCount + BLOCK_CONTENT_LIMIT
                    );

                    const content =
                      await api.reportBlockContent.previewReportBlockContent({
                        organizationId,
                        reportBlockId: selectedReportBlock._id,
                        id: `$in,${batchIds}`,
                        sort,
                        limit: BLOCK_CONTENT_LIMIT,
                      });
                    await processChunks([content.data.content || ""]);
                    currentAssetCount += BLOCK_CONTENT_LIMIT;
                    setProgress(
                      Math.min(
                        (currentAssetCount / sortedAssets.length) * 100,
                        100
                      )
                    );
                  }
                }

                if (selectedBuildings.length > 0) {
                  const sortedBuildings = sortItems(selectedBuildings);
                  if (sortedBuildings.length < BLOCK_CONTENT_LIMIT) {
                    setShowProgress(false);
                  }
                  const buildingIds = sortedBuildings.map(
                    (building) => building._id
                  );
                  let currentBuildingCount = 0;
                  const sortOrder = buildingSortModel.find(
                    (item) => item.field === "name"
                  );
                  const sort = sortOrder
                    ? `${sortOrder.field},${sortOrder.sort}`
                    : "name,asc";

                  while (currentBuildingCount < buildingIds.length) {
                    const batchIds = buildingIds.slice(
                      currentBuildingCount,
                      currentBuildingCount + BLOCK_CONTENT_LIMIT
                    );

                    const content =
                      await api.reportBlockContent.previewReportBlockContent({
                        organizationId,
                        reportBlockId: selectedReportBlock._id,
                        id: `$in,${batchIds}`,
                        limit: BLOCK_CONTENT_LIMIT,
                        sort,
                      });
                    await processChunks([content.data.content || ""]);
                    currentBuildingCount += BLOCK_CONTENT_LIMIT;
                    setProgress(
                      Math.min(
                        (currentBuildingCount / sortedBuildings.length) * 100,
                        100
                      )
                    );
                  }
                }

                await onClose({
                  action: "confirm",
                });
              } catch (err: any) {
                let message =
                  "Error generting report block content. Please try again.";
                if (isAxiosError(err)) {
                  message = err.message;
                  if (err.response?.data?.error?.message) {
                    message = err.response.data.error.message as string;
                  }
                }
                simple(message, { severity: "error" });
              } finally {
                setLoading(false);
                resetState();
              }
            }}
          >
            {loading ? "Inserting..." : "Insert"}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ss = stylesheet({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
