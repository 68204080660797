(function () {
  /**
   * @ngdoc component
   * @name AbxRangeInput
   *
   * @param {Function} onBlur - Function to be called when input loses focus
   * @param {String} [label] - Label for this input. If omitted or falsy, no label
   *    will be displayed, and the space it would have consumed will be collapsed.
   * @param {String} type - One of "float", "int", "date".
   * @param {Object} [model] - Model for this input. Defaults to a range open on
   *    both ends.
   * @param {Number|Date} [model.start] - Start value for this range.
   * @param {Number|Date} [model.end] - End value for this range.
   * @param {Boolean} [blurOnEnter=false] - Blur any focused inputs on Enter
   *     keypress.
   * @param {Boolean} [required=false] - If true, niether range field can be empty s
   *
   * @callback onBlur
   * @param {Object} $event - Null if both fields are empty.
   * @param {Number|Date} $event.newValue.start - The new start value for this range.
   *    Omitted if the range is of the form "<= end"
   * @param {Number|Date} $event.newValue.end - The new end value for this range.
   *    Omitted if the range is of the form ">= start".
   *
   * @description A component to collect ranges from users. Regardless
   *    of which field data is entered into, the value (range) for this input
   *    will always have start <= end. Note that open-ended ranges are
   *    represented by omitting the start and/or end keys entirely. A range that
   *    is open on both ends will be represented as just null
   */
  angular
    .module("akitabox.ui.components.rangeInput", [
      "akitabox.core.constants",
      "akitabox.core.utils",
      "akitabox.ui.components.input",
    ])
    .component("abxRangeInput", {
      bindings: {
        onBlur: "&abxOnBlur",
        onChange: "&abxOnChange",
        label: "@?abxLabel",
        labels: "<?abxLabels",
        type: "@abxType",
        model: "<?abxModel",
        max: "<?abxMax",
        min: "<?abxMin",
        blurOnEnter: "<?abxBlurOnEnter",
        saveOnBlur: "<?abxSaveOnBlur",
        required: "<?abxRequired",
        step: "<?abxStep",
        forceEndOfDay: "<?abxForceEndOfDay",
        disabled: "<?abxDisabled",
        timeZoneOffset: "<?abxTimeZoneOffset",
      },
      controller: AbxRangeInputController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/range-input/range-input.component.html",
    });

  function AbxRangeInputController(moment, COMPONENT_STATE, Utils) {
    var self = this;

    // Attributes
    self.fieldModels = [getEmptyFieldModel(), getEmptyFieldModel()];
    self.blurOnEnter = self.blurOnEnter || false;
    self.placeholders = self.type === "float" ? ["min", "max"] : [];
    self.forceEndOfDay = angular.isBoolean(self.forceEndOfDay)
      ? self.forceEndOfDay
      : true;

    // Functions
    self.updateFieldModel = updateFieldModel;
    self.handleChange = handleChange;
    self.getMin = getMin;

    // =================
    // Lifecycle
    // =================
    /**
     * Lifecycle handler for binding changes.
     * @param {Object} changes - An object with a SimpleChange for each binding
     *    that was affected.
     */
    self.$onChanges = function (changes) {
      if (changes.model) {
        syncFieldModels();
      }
    };

    // =================
    // Public Functions
    // =================
    /**
     * Updates the given fieldModel's value.
     * @param {Object} $event - Change event.
     * @param {Number|Date} $event.newValue - The new value for the specified field.
     * @param {Boolean} $event.invalid - True if new filter value is invalid.
     * @param {Number} fieldIndex - Which field model to change.
     */
    function updateFieldModel($event, fieldIndex) {
      var field = self.fieldModels[fieldIndex];
      self.fieldModels[fieldIndex] = {
        model: $event.newValue, // (DRL 6.2)
        state: field.state,
        oldModel: field.model,
        invalid: $event.invalid || false,
      };

      if (fieldIndex === 0) {
        var end = self.fieldModels[1];
        // Reset end input state if it has become valid due to start change
        // We only care about the end input because the start input is not,
        // currently allowed to be greater than the end input's value and
        // will automatically update to be equal to the end input's value.
        var endValid = checkValidity(1);
        if (end.invalid && endValid) {
          end.state = COMPONENT_STATE.default;
          end.invalid = false;
        }
      }

      // (DRL 6.7)
      return self.onBlur({
        $event: {
          newValue: getValueObject(), // (DRL 6.3)
          invalid: isRangeInvalid(),
        },
      });
    }

    /**
     * Updates the given fieldModel's value on change
     *
     * @param {Object} $event - Change event
     * @param {Number} fieldIndex - Which field model to change.
     */
    function handleChange($event, fieldIndex) {
      var field = self.fieldModels[fieldIndex];
      self.fieldModels[fieldIndex] = {
        model: $event.model, // (DRL 5.2)
        state: field.state,
        oldModel: field.model,
        invalid: $event.invalid || false,
      };

      if (fieldIndex === 0) {
        var end = self.fieldModels[1];
        // Reset end input state if it has become valid due to start change
        // We only care about the end input because the start input is not,
        // currently allowed to be greater than the end input's value and
        // will automatically update to be equal to the end input's value.
        var endValid = checkValidity(1);
        if (end.invalid && endValid) {
          end.state = COMPONENT_STATE.default;
          end.invalid = false;
        }
      }

      // (DRL 5.7)
      self.onChange({
        $event: {
          newValue: getValueObject(), // (DRL 5.3)
          invalid: isRangeInvalid(),
        },
      });
    }

    // =================
    // Private Functions
    // =================

    function isRangeInvalid() {
      return self.fieldModels[0].invalid || self.fieldModels[1].invalid;
    }

    function checkValidity(index) {
      var valid = false;
      if (self.type === "date") {
        var model = self.fieldModels[index].model;
        if (model instanceof Date) {
          valid = true;
        } else {
          valid = Utils.isDateStringValid(model);
        }
      } else {
        valid = true;
      }

      var start = self.fieldModels[0];
      var end = self.fieldModels[1];

      return valid && compareModels(end.model, start.model) > -1;
    }

    /**
     * Comparator function to sort model values.
     * @param {Number|Date} a - The first model.
     * @param {Number|Date} b - The second model.
     * @return {Number}
     *  0 if a is equivalent to b
     *  -1 if a < b
     *  1 if a > b
     */
    function compareModels(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }

    /**
     * Get an empty field model that is marked as valid.
     */
    function getEmptyFieldModel() {
      return {
        model: null,
        invalid: false,
        state: COMPONENT_STATE.default,
      };
    }

    /**
     * Get the start value for this input range.
     * @return {Number|Date} If the first field is populated, the lesser of the two
     *    field values. Otherwise, null.
     */
    function getStart() {
      // (DRL 5.4) (DRL 6.4)
      if (inputIsEmpty(0)) {
        return null;
      }
      if (inputIsEmpty(1)) {
        return self.fieldModels[0].model;
      }

      if (self.fieldModels[0].model instanceof Date) {
        return self.fieldModels[0].model;
      } else {
        // TODO: If !self.onChange, self.fieldModels[0] not updated?
        return minModel(self.fieldModels[0].model, self.fieldModels[1].model);
      }
    }

    /**
     * Get the end value for this input range.
     * @return {Number|Date} If the second field is populated, the greater of the two
     *    field values. Otherwise, null.
     */
    function getEnd() {
      var startField = self.fieldModels[0];
      var endField = self.fieldModels[1];

      if (inputIsEmpty(1)) {
        return null;
      }

      if (endField.model instanceof Date) {
        // Keep total date range length in days the same if
        // start is altered to be occur end
        var startDate = moment(startField.model);
        var oldStartDate = moment(startField.oldModel);
        var endDate = moment(endField.model);

        if (
          startDate.isAfter(endDate) &&
          !endField.invalid &&
          startDate.year() <= 9999
        ) {
          var difference =
            endDate.startOf("day").diff(oldStartDate.startOf("day"), "days") ||
            1;
          // Preventing large jumps when typing last digit of start year
          difference = difference < 36500 ? difference : 1;
          endDate = startDate.add(difference, "days");
        }

        // default = true
        if (!self.forceEndOfDay) {
          return (endField.model = endDate.toDate());
        }
        // (DRL 5.5) (DRL 5.6) (DRL 6.5) (DRL 6.6)
        return (endField.model = endDate.endOf("day").toDate());
      } else {
        // TODO: If !self.onChange, self.fieldModels[1] not updated?
        return maxModel(startField.model, endField.model);
      }
    }

    /**
     * Get the greater of two model values.
     * @param {Number|Date} a - The first model.
     * @param {Number|Date} b - The second model.
     */
    function maxModel(a, b) {
      if (compareModels(a, b) > 0) {
        return a;
      }
      return b;
    }

    /**
     * Get the lesser of two model values.
     * @param {Number|Date} a - The first model.
     * @param {Number|Date} b - The second model.
     */
    function minModel(a, b) {
      if (compareModels(a, b) < 0) {
        return a;
      }
      return b;
    }
    /**
     * Get the range value object for this input.
     * @return {Object} An object representing the state of this input with
     *    a start and end value.
     */
    function getValueObject() {
      var result = {};
      var start = getStart();
      var end = getEnd();

      if (start === null && end === null) {
        return null;
      }

      result.start = start;
      result.end = end;

      return result;
    }

    /**
     * Helper function to determine if a field is empty.
     * @param {Number} fieldIndex - The field to check.
     * @return {Boolean} true if the field is empty.
     */
    function inputIsEmpty(fieldIndex) {
      return angular.isEmpty(self.fieldModels[fieldIndex].model);
    }

    /**
     * Update self.fieldModels to be representative of the range at self.model
     */
    function syncFieldModels() {
      if (!self.model) {
        self.fieldModels[0] = getEmptyFieldModel();
        self.fieldModels[1] = getEmptyFieldModel();
        return;
      }

      if (angular.isEmpty(self.model.start)) {
        self.fieldModels[0] = getEmptyFieldModel();
      } else {
        self.fieldModels[0].model = self.model.start;
      }

      if (angular.isEmpty(self.model.end)) {
        self.fieldModels[1] = getEmptyFieldModel();
      } else {
        self.fieldModels[1].model = self.model.end;
      }
    }

    /**
     * Returns minimum value of range input, accounting for falsiness of 0
     */
    function getMin() {
      var firstModel = self.fieldModels[0].model;
      if (firstModel || firstModel === 0) {
        return firstModel;
      }
      return self.min;
    }
  }
})();
