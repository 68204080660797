(function () {
  angular
    .module("akitabox.ui.components.appHeader")
    .filter("headerSearch", AbxHeaderSearch)
    .filter("headerOrgSearch", AbxOrgHeaderSearch)
    .filter("headerBuildingGroupsSearch", AbxBuildingGroupsSearch);

  function AbxHeaderSearch() {
    return function (list, searchTerm) {
      if (list) {
        var regex = new RegExp(searchTerm, "i");

        return list.filter(function (building) {
          return (
            regex.test(building.name) ||
            regex.test(building.address) ||
            regex.test(building.postal_code) ||
            regex.test(building.city) ||
            regex.test(building.state)
          );
        });
      } else {
        return list;
      }
    };
  }

  function AbxOrgHeaderSearch() {
    return function (list, searchTerm) {
      if (list) {
        var regex = new RegExp(searchTerm, "i");

        return list.filter(function (organization) {
          return regex.test(organization.name);
        });
      } else {
        return list;
      }
    };
  }

  function AbxBuildingGroupsSearch() {
    return function (list, searchTerm) {
      if (list) {
        var regex = new RegExp(searchTerm, "i");

        return list.filter(function (buildingGroup) {
          return regex.test(buildingGroup.name);
        });
      } else {
        return list;
      }
    };
  }
})();
