(function () {
  angular.module("akitabox.planView").config(redirects).run(stateBlock);

  /** @ngInject */
  function stateBlock(
    // AkitaBox
    models,
    // Third-party
    CacheFactory,
    Router
  ) {
    Router.configureStates([
      /**
       * Root state. Will always redirect to 'planView.buildings'.
       */
      {
        state: "planView",
        config: {
          abstract: true,
          url: "/plan",
          template: "<abx-plan-view-app></abx-plan-view-app>",
          resolve: {
            account: getIdentity,
            organization: getOrganization,
            user: getUser,
          },
          data: {
            pageTitle: "PlanView",
          },
        },
      },
      /**
       * Display a dropdown to select from the user's buildings.
       */
      {
        state: "planView.buildings",
        config: {
          onEnter: function () {
            CacheFactory.clearAll();
          },
          url: "/" + models.BUILDING.ROUTE_PLURAL,
        },
      },
      /**
       * Building is selected. Will always redirect to 'planView.building.floors'
       */
      {
        state: "planView.building",
        config: {
          url: "/" + models.BUILDING.ROUTE_PLURAL + "/:buildingId",
          resolve: {
            building: getBuilding,
          },
        },
      },
      /**
       * Display a dropdown to select from the building's floors.
       */
      {
        state: "planView.building.floors",
        config: {
          url: "/" + models.FLOOR.ROUTE_PLURAL,
        },
      },
      /**
       * Floor is selected. Show the floor plan.
       *
       * Query string parameters:
       *   - version: Version of floor plan to view. In reality, this maps to one
       *         of the floor plan's revisions (specifically
       *         revision.document_rev_num).
       *   - page: Page of the floor plan to view.
       *   - asset: Asset to filter on
       *   - room: Room to filter on
       *   - mode: Mode to start on
       */
      {
        state: "planView.building.floor",
        config: {
          url:
            "/" +
            models.FLOOR.ROUTE_PLURAL +
            "/:floorId?version&page&asset&mode&room&pin_types&name&search&legacyFilter&round_task&stop&level",
        },
      },
    ]);
  }

  /**
   * Handles redirects from root states to a default state.
   *
   * @ngInject
   */
  function redirects($urlRouterProvider) {
    $urlRouterProvider.when("/plan", "/plan/buildings");
    $urlRouterProvider.when(
      "/plan/buildings/:buildingId",
      "/plan/buildings/:buildingId/levels"
    );
  }

  /**
   * Get the current user's account
   *
   * @param $state
   * @param $q
   * @param IdentityService
   *
   * @ngInject
   */
  function getIdentity($state, $q, IdentityService) {
    return IdentityService.getCurrent().catch(function (err) {
      // If we catch an error because the server can't be reached, show the 503 page
      if (err.status === -1) {
        $state.go("root.503");
      }
      return $q.reject(err);
    });
  }

  /**
   * Get the user's current organization
   *
   * @param account
   * @param OrganizationService
   *
   * @ngInject
   */
  function getOrganization(account, RedirectService, OrganizationService) {
    var path = RedirectService.getDestination();
    var current = OrganizationService.getCurrent();
    if (current) {
      return current;
    }
    return OrganizationService.init(path);
  }

  /**
   * Get the building based on the destination url
   *
   * @ngInject
   */
  function getBuilding(
    $state,
    organization,
    BuildingService,
    RedirectService,
    OrganizationService
  ) {
    var path = RedirectService.getDestination();
    return BuildingService.init(path).then(function (building) {
      if (!organization) {
        return OrganizationService.redirectToBuildingOrg(building);
      }
      if (organization._id !== building.organization) {
        return $state.go("root.404");
      }
      return building;
    });
  }

  /**
   * Get current user of organization
   *
   * @param organization
   * @param UserService
   *
   * @return {Object} User of organization
   *
   * @ngInject
   */
  function getUser(organization, UserService) {
    if (organization) {
      return UserService.init(organization._id);
    }
    return null;
  }
})();
