(function () {
  angular.module("akitabox.desktop.media-viewer", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.core.services.env",
    "akitabox.core.services.document",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.document.download",
  ]);
})();
