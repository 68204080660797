(function () {
  angular
    .module("akitabox.desktop.directives.noteInput", [
      "akitabox.core.services.user",
    ])
    .directive("abxNoteInput", AbxNoteInputDirective);

  function AbxNoteInputDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/note-input/note-input.html",
      controller: AbxNoteInputController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        onChange: "&?abxOnChange",
        hasRequester: "=?abxHasRequester",
        building: "=abxBuilding",
        showButtons: "=abxShowButtons",
        noteText: "=?abxNoteText",
      },
    };
  }

  /*
   * @ngInject
   */
  function AbxNoteInputController(
    $element,
    $sanitize,
    $window,
    $scope,
    OrganizationService,
    ToastService,
    UserService
  ) {
    var self = this;

    var userPerms = UserService.getPermissions();

    const taggingPlaceHolder = " Type @ to mention and notify someone.";
    // Attributes
    self.hasRequester = self.hasRequester || false;
    self.noteText = self.noteText || "";
    self.canSendPublic = canSendPublic();
    self.organization = OrganizationService.getCurrent();
    self.buildingId = self.building._id;
    self.mentionList = [];
    self.notProcessInput = false;
    self.cannotTagUser = true;
    self.messagePlaceholder = self.canSendPublic
      ? "Send a message to the requester or add an internal only message for your team."
      : "Add an internal message." + taggingPlaceHolder;

    // Functions
    self.addNote = addNote;
    self.handleFocusedElement = handleFocusedElement;

    const KEY_ESC = 27;
    const KEY_ENTER = 13;
    const KEY_DOWN = 40;
    const KEY_UP = 38;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;
    const KEY_AT = 50;

    const htmlWrapper = angular.element(
      $element[0].querySelectorAll("#wrapper")
    );
    let htmlInputBox = angular.element(
      $element[0].querySelectorAll("#input-box")
    );

    htmlInputBox.on("input", () => {
      if (self.notProcessInput) return;
      return processInput(true, false, 0);
    });
    const htmlMentionSelectChoices = $element
      .find("#mention-select-choices")
      .on("mousedown", (e) => e.preventDefault());

    htmlInputBox.on("mousedown", () => {
      removeInvalidMentions();
    });

    htmlInputBox.on("keydown", (e) => {
      self.notProcessInput = !(e.shiftKey && e.keyCode === KEY_AT);

      let notMentionFocused = true;
      if ([KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN].includes(e.keyCode)) {
        // Prevents the cursor to be out of the mention
        const inputChildNodes = htmlInputBox[0].childNodes;
        let initialCursorPosition = 0;
        for (let node of inputChildNodes) {
          if (node && node.classList && node.classList.contains("focused")) {
            const nodeSize = node.innerText ? node.innerText.length : 0;
            const position = getCaretPosition(htmlInputBox[0]);

            if (
              (position === initialCursorPosition && e.keyCode === KEY_LEFT) ||
              (position === initialCursorPosition + nodeSize &&
                e.keyCode === KEY_RIGHT)
            ) {
              e.preventDefault();
              notMentionFocused = false;
            } else if ([KEY_UP, KEY_DOWN].includes(e.keyCode)) {
              e.preventDefault();
            }
            break;
          } else {
            initialCursorPosition += node.textContent.length;
          }
        }
      }

      // Enable keys different from END and Shift+ENTER
      // ENTER key is blocked to enable to press ENTER and select one mention
      return (
        notMentionFocused &&
        (e.keyCode !== KEY_ENTER || (e.keyCode === KEY_ENTER && e.shiftKey))
      );
    });

    htmlInputBox.on("mouseup keyup", (e) => {
      $scope.noteForm.$invalid = htmlInputBox[0].textContent.length <= 0;

      if (e.type === "mouseup" || !handleChoicesArrowKeys(e)) {
        const element = getElementOfCurrentCaret();
        handleFocusedElement(element, e.type !== "mouseup");
      }
    });

    htmlInputBox.on("blur", function (e) {
      self.noteText = e.currentTarget.innerText;
      removeInvalidMentions();
      handleFocusedElement();
    });

    htmlInputBox[0].textContent = self.noteText;
    rebuildSelectionBox();
    fetchUsers();
    processInput(false, false, 0);

    function addNote(isPublic) {
      const inputContent = htmlInputBox[0].innerHTML;

      if (isPublic) {
        const matches = [
          ...inputContent.matchAll(/<div class="mention">(.*?)<\/div>/g),
        ];
        if (matches.length > 0) {
          ToastService.showError(
            "Messages to requester cannot include tagged users"
          );
          return;
        }
      }

      // Replace the mentions and remove HTML
      const contentParsed = parseMentionsAndHtml(
        inputContent,
        self.mentionList
      );

      self.onChange({
        noteText: contentParsed,
        sendToRequester: isPublic,
      });
      $scope.noteForm.$setPristine();
      $scope.noteForm.$setUntouched();
      htmlInputBox[0].textContent = "";
      self.noteText = "";
    }

    function canSendPublic() {
      // with the new perms, we only allow those with create_public to send messages to requesters
      return self.hasRequester && userPerms.comment.create_public;
    }

    function parseMentionsAndHtml(htmlInputContent, listOfMention) {
      let contentParsed = htmlInputContent;

      // Replace the mentions (@displayName) by an index of the list
      listOfMention.forEach(
        (mention, index) =>
          (contentParsed = contentParsed.replaceAll(
            `@${mention.displayName}`,
            `<<${index}>>`
          ))
      );

      // Replace the index by the user Id
      contentParsed = contentParsed.replace(
        /<<(\d+)>>/g,
        // eslint-disable-next-line no-template-curly-in-string
        (...{ 1: index }) => "<@" + listOfMention[index].id + ">"
      );

      // Replace other HTML's tag
      contentParsed = contentParsed.replace(/<br>/g, "\n");
      contentParsed = contentParsed.replace(/<div class="mention">/g, "");
      contentParsed = contentParsed.replace(/<div class="invalid">/g, "");
      contentParsed = contentParsed.replace(/<\/div>/g, "");
      contentParsed = contentParsed.replace(/&nbsp;/g, " ");
      contentParsed = contentParsed.replace(
        /<div class="mention ignore">/g,
        ""
      );
      contentParsed = contentParsed.replace(
        /<div class="mention ignore focused">/g,
        ""
      );

      return contentParsed;
    }

    // It will remove invalid mentions
    function removeInvalidMentions() {
      $element.find(".focused").removeClass("focused");

      let inputContent = htmlInputBox[0].innerHTML.replaceAll("&nbsp;", " ");
      let contentChanged = false;
      const matches = [
        ...inputContent.matchAll(/<div class="mention">@(.*?)<\/div>/g),
      ];

      const matchesIgnored = [
        ...inputContent.matchAll(/<div class="mention ignore">@(.*?)<\/div>/g),
      ];
      for (let match of matches) {
        const mention = match[1];
        const foundedUsers = self.mentionList.filter(
          (user) => user.displayName === mention.replaceAll(" ", "&nbsp;")
        );
        if (foundedUsers.length === 0) {
          inputContent = inputContent.replaceAll(
            `<div class="mention">@${mention}</div>`,
            `@${mention}`
          );
          contentChanged = true;
        }
      }

      for (let match of matchesIgnored) {
        const mention = match[1];
        const foundedUsers = self.mentionList.filter(
          (user) => user.displayName === mention.replaceAll(" ", "&nbsp;")
        );
        if (foundedUsers.length === 0) {
          inputContent = inputContent.replaceAll(
            `<div class="mention ignore">@${mention}</div>`,
            `@${mention}`
          );
          contentChanged = true;
        }
      }
      if (contentChanged) htmlInputBox.html($sanitize(inputContent));
    }

    function escapeHTML(text) {
      return text
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\s/g, "&nbsp;");
    }

    function fetchUsers(searchPhrase = "") {
      const reqParams = {
        identity: "$ne,null",
        status: "active",
        sort: "firstOrEmail,asc",
      };

      UserService.getAll(self.organization._id, reqParams)
        .then((users) => {
          self.mentionList = users.map((user) => {
            const userHasBuildingAccess = user.buildings.find(
              (building) => building._id === self.buildingId
            );
            return {
              displayName: escapeHTML(user.identity.display_name),
              email: user.email,
              id: user._id,
              hasBuildingAccess: !!userHasBuildingAccess,
            };
          });
          self.cannotTagUser = false;
          rebuildSelectionBox();
        })
        .catch(function (err) {
          self.mentionList = [];
          ToastService.showError(err);
        });
    }

    // Get keyboard cursor
    function getCaretPosition(element) {
      let position = 0;
      if ($window.getSelection) {
        const selection = $window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          position = preCaretRange.toString().length;
        }
      }
      return position;
    }

    /**
     *
     * @returns {Document|(Node & ParentNode)}
     */
    function getElementOfCurrentCaret() {
      if (window.getSelection) {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          return (
            range.startContainer === range.endContainer
              ? range.startContainer
              : range.commonAncestorContainer
          ).parentNode;
        }
      }
      return document;
    }

    /**
     *
     * @param caretOffset
     */
    function processInput(
      keyAtTyped = false,
      replacingMention = false,
      caretOffset = 0
    ) {
      const position = getCaretPosition(htmlInputBox[0]);
      let finalPosition = 0;
      let textToSanitize = self.noteText ? self.noteText : "";
      let firstCharacterOfCaret = htmlInputBox[0].textContent.substr(
        position === 0 ? 0 : position - 1,
        1
      );

      // User just inserted only "@"
      if (!replacingMention && firstCharacterOfCaret === "@") {
        // Text displayed on the screen with line break using "\n"
        let textInputWithLineBreak = htmlInputBox[0].innerText.replaceAll(
          "\n",
          "»"
        );
        // Text displayed on the screen without line break
        let textInputWithoutLineBreak = htmlInputBox[0].textContent;

        // Just replace the @ character in the
        // position entered by the user at that time
        let textInputWithoutAt =
          textInputWithoutLineBreak.substr(0, position - 1) +
          `Ø` +
          textInputWithoutLineBreak.substr(
            position,
            textInputWithoutLineBreak.length
          );

        const builtTextInput = [];
        let y = 0;
        for (let i = 0; i <= textInputWithLineBreak.length; i++) {
          const character = textInputWithLineBreak[i];
          if (character === textInputWithoutAt[y]) {
            builtTextInput.push(character);
            y++;
          } else if (character === "»") {
            builtTextInput.push(character);
          } else if (textInputWithoutAt[y] === "Ø") {
            builtTextInput.push(textInputWithoutAt[y]);
            y++;
          }
        }

        const textInputMentions = replaceMentions(
          builtTextInput.join(""),
          self.mentionList
        );
        const textInputHtml = textInputMentions
          .replaceAll("»", "<br>")
          .replace("Ø", `<div class="mention ignore focused">@</div>`);

        textToSanitize = textInputHtml;
        replacingMention = false;
        finalPosition = 1;
      } else if (self.mentionList && self.mentionList.length) {
        let textInput = htmlInputBox[0].innerHTML;

        const displayNameList = self.mentionList.map(
          (user) => user.displayName
        );

        displayNameList.forEach((name, index) => {
          textInput = textInput.replaceAll(
            `<div class="mention ignore focused">@${name}&nbsp;</div>`,
            replaceMentions(`@${name}`, self.mentionList) + "&nbsp;"
          );
          textInput = textInput.replaceAll(
            `<div class="mention focused">@${name}&nbsp;</div>`,
            replaceMentions(`@${name}`, self.mentionList) + "&nbsp;"
          );
          textInput = textInput.replaceAll(
            `<div class="ignore mention focused">@${name}&nbsp;</div>`,
            replaceMentions(`@${name}`, self.mentionList) + "&nbsp;"
          );
        });

        textToSanitize = textInput;
        finalPosition = position + caretOffset;
      }
      htmlInputBox.html($sanitize(textToSanitize));
      setCaretPosition(
        replacingMention,
        htmlInputBox[0],
        finalPosition,
        "",
        keyAtTyped
      );
    }

    /**
     *
     * @param element
     * @param position
     */
    function setCaretPosition(
      replacingMention,
      element,
      position,
      ignoredTag,
      keyAtTyped
    ) {
      if ($window.getSelection) {
        const range = document.createRange();
        const selection = window.getSelection();
        let currentNode = null;
        let currentPosition = position;

        if (
          replacingMention &&
          element.childNodes.length > 1 &&
          element.textContent.length - 2 === position
        ) {
          currentNode = element.childNodes[element.childNodes.length - 1];
          currentPosition = 1;
        } else {
          let previousNode = null;
          if (selection.rangeCount > 0) {
            for (let i = 0; i < htmlInputBox[0].childNodes.length; i++) {
              previousNode = currentNode;
              currentNode = htmlInputBox[0].childNodes[i];

              if (previousNode !== null && position !== 1) {
                let decreasePosition = 0;
                if (previousNode && previousNode.innerText) {
                  if (previousNode.innerText.length > 0) {
                    decreasePosition = previousNode.innerText.length;
                  } else {
                    decreasePosition = 1;
                  }
                } else if (previousNode && previousNode.length) {
                  decreasePosition = previousNode.length;
                }
                currentPosition -= decreasePosition;
              }

              if (
                currentNode.outerHTML ===
                  `<div class="mention ignore focused">@</div>` ||
                (ignoredTag.length > 0 && currentNode.outerHTML === ignoredTag)
              ) {
                while (currentNode.childNodes.length > 0) {
                  currentNode = currentNode.childNodes[0];
                }
                break;
              } else {
                const nodeLength =
                  currentNode && currentNode.length
                    ? currentNode.length
                    : currentNode.textContent.length;
                if (currentPosition <= nodeLength) {
                  if (i + 1 < htmlInputBox[0].childNodes.length) {
                    let extraNode = htmlInputBox[0].childNodes[i + 1];

                    if (
                      extraNode.outerHTML ===
                      `<div class="mention ignore focused">@</div>`
                    ) {
                      while (extraNode.childNodes.length > 0) {
                        extraNode = extraNode.childNodes[0];
                      }
                      currentNode = extraNode;
                      break;
                    } else if (!keyAtTyped) {
                      currentPosition = 1;
                      currentNode = extraNode;
                      range.setEnd(currentNode, 1);
                      break;
                    }
                  } else {
                    break;
                  }
                }
              }
            }
          }
        }

        //move caret to specified offset
        if (currentNode !== null) {
          let newOffset = currentPosition < 0 ? 1 : currentPosition;
          const currentNodeLength =
            currentNode && currentNode.length
              ? currentNode.length
              : currentNode.textContent.length;
          range.setStart(
            currentNode,
            newOffset > currentNodeLength ? currentNodeLength : newOffset
          );
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }

    function replaceMentions(textInput, listOfMention, onlyIgnore = false) {
      // replace all space-like characters into spaces
      let newText = textInput.replace(/\s/g, "&nbsp;");
      const displayNameList = listOfMention.map((user) => user.displayName);

      displayNameList.forEach(
        (name, index) =>
          (newText = newText.replaceAll(`@${name}`, `<<${index}>>`))
      );

      const mentions = newText.replace(
        /<<(\d+)>>/g,
        // eslint-disable-next-line no-template-curly-in-string
        (...{ 1: index }) =>
          "<div class='mention'>@" + displayNameList[index] + "</div>"
      );

      if (onlyIgnore) {
        return newText;
      }

      return mentions;
    }

    function rebuildSelectionBox(searchPhrase = "") {
      if (self.cannotTagUser) {
        htmlMentionSelectChoices
          .empty()
          .append(
            "<ul><li class='disabled'><div class='mention-list-no-permission'><span>Loading...</span></div></li></ul>"
          );
      } else {
        const usersFound = searchUsers(searchPhrase);

        let authorizedUsers = usersFound.filter(
          (user) => user.hasBuildingAccess === true
        );
        let unauthorizedUsers = usersFound.filter(
          (user) => user.hasBuildingAccess === false
        );

        let unauthorizedUsersHTML =
          unauthorizedUsers.length > 0
            ? unauthorizedUsers
                .map(
                  (user) =>
                    `<li disabled class="mention-list-user-no-access"><span>${user.displayName}</span> <span class="mention-list-user-email">${user.email}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span class="mention-list-user-access-message">Needs access<span></li>`
                )
                .join("")
            : "";
        let authorizedUsersHTML =
          authorizedUsers.length > 0
            ? authorizedUsers
                .map(
                  (user) =>
                    `<li class="mention-list-user"><span>${user.displayName}</span> <span class="mention-list-user-email">${user.email}</span></li>`
                )
                .join("")
            : "";
        if (unauthorizedUsers.length === 0 && authorizedUsers.length === 0) {
          htmlMentionSelectChoices
            .empty()
            .append(
              "<ul><li disabled class='disabled'><div class='mention-list-no-permission'><span>No results found</span></div></li></ul>"
            );
        } else {
          htmlMentionSelectChoices
            .empty()
            .append(
              "<ul>" + authorizedUsersHTML + unauthorizedUsersHTML + "</ul>"
            );
        }
        $element
          .find("li", htmlMentionSelectChoices)
          .on("mouseenter", function () {
            if ($element.find(this).hasClass("mention-list-user")) {
              const selected = $element.find(
                "li.selected",
                htmlMentionSelectChoices
              );
              if (selected) {
                selected.removeClass("hover");
                selected.removeClass("selected");
              }
              $element.find(this).addClass("hover");
            }
          })
          .on("mouseleave", function () {
            $element.find(this).removeClass("hover");
          });
      }
    }

    function searchUsers(searchPhrase = "") {
      const usersFound = self.mentionList.filter((user) =>
        user.displayName
          .toLowerCase()
          .includes(searchPhrase.replaceAll(" ", "&nbsp;").toLowerCase())
      );
      return usersFound;
    }

    function handleChoicesControlKeys(e) {
      if (e.keyCode === KEY_ESC) {
        hideSelectionChoices();
        e.preventDefault();
      }
    }

    function fixScrollY(jScrollElement, jVisibleElement) {
      const scrollableHeight = jScrollElement.innerHeight();
      const scrollableTop = jScrollElement.scrollTop();
      const visibleHeight = jVisibleElement.innerHeight();
      const visibleTop = jVisibleElement.position().top;
      if (visibleHeight + visibleTop > scrollableHeight) {
        jScrollElement.scrollTop(
          scrollableTop + visibleHeight + visibleTop - scrollableHeight
        );
      } else if (visibleTop < 0) {
        jScrollElement.scrollTop(scrollableTop + visibleTop);
      }
    }

    function handleChoicesArrowKeys(e) {
      let htmlLiHovered = $element.find("li.hover", htmlMentionSelectChoices);
      if ([KEY_UP, KEY_DOWN].includes(e.keyCode)) {
        let htmlLiSelected = $element.find(
          "li.selected",
          htmlMentionSelectChoices
        );
        const action = {
            [KEY_UP]: { limit: ":first-child", move: "prev" },
            [KEY_DOWN]: { limit: ":last-child", move: "next" },
          },
          code = e.keyCode;

        //Remove hover in case of being hovered
        if (htmlLiHovered.length) htmlLiHovered.removeClass("hover").first();

        if (htmlLiSelected.length) {
          // Select the next LI
          htmlLiSelected.first().is(action[code].limit) ||
            htmlLiSelected
              .removeClass("selected")
              .first()
              [action[code].move]()
              .addClass("selected");

          // Actual selected LI
          htmlLiSelected = $element.find(
            "li.selected",
            htmlMentionSelectChoices
          );

          if (htmlLiSelected.length) {
            if (htmlLiSelected.hasClass("mention-list-user")) {
              $element
                .find("li.selected", htmlMentionSelectChoices)
                .addClass("hover");
            }
          }
        } else {
          $element
            .find("li:first-child", htmlMentionSelectChoices)
            .addClass("selected");
          if (
            $element
              .find("li:first-child", htmlMentionSelectChoices)
              .hasClass("mention-list-user")
          ) {
            $element
              .find("li:first-child", htmlMentionSelectChoices)
              .addClass("hover");
          } else {
            $element
              .find("li:first-child", htmlMentionSelectChoices)
              .addClass("selected");
          }
        }
        fixScrollY(
          htmlMentionSelectChoices,
          $element.find("li.selected", htmlMentionSelectChoices).first()
        );
        e.preventDefault();
        return true;
      } else if (e.keyCode === KEY_ENTER) {
        // Only hovered LI can accept ENTER command
        if (htmlLiHovered.length) {
          replaceFocusedMention(htmlLiHovered.children()[0].innerText);
          hideSelectionChoices();
          e.preventDefault();
        }
        return true;
      }
      return false;
    }

    /**
     *
     * @param mention
     */
    function replaceFocusedMention(userName) {
      $element.find(".mention.focused").html("@" + userName + "&nbsp;");
      processInput(
        false,
        true,
        angular
          .element("<p></p>")
          .html(userName.replaceAll(" ", "&nbsp;"))
          .text().length
      );
    }

    /**
     *
     * @param contextElement
     */
    function showSelectionChoices(contextElement) {
      const { top: eTop, left: eLeft } = $element.find(contextElement).offset();
      const { top: wTop, left: wLeft } = htmlWrapper.offset();
      htmlMentionSelectChoices
        .css({ top: eTop - wTop, left: eLeft - wLeft, "z-index": 9995 })
        .removeClass("hidden")
        .scrollTop(0);
      $element.find("li", htmlMentionSelectChoices).one("click", function () {
        const htmlLiElement = this;
        if (htmlLiElement && htmlLiElement.hasAttribute("disabled")) return;
        const children = angular.element(this).children();
        replaceFocusedMention(children[0].innerText);
        hideSelectionChoices();
      });
      $element.find(document).on("keyup", handleChoicesControlKeys);
      const htmlFirstLiElement = $element.find(
        "li:first",
        htmlMentionSelectChoices
      );
      if (htmlFirstLiElement.hasClass("disabled")) {
        htmlFirstLiElement.removeClass("hover");
      } else {
        htmlFirstLiElement
          .addClass("hover")
          .addClass("selected")
          .siblings(".hover")
          .removeClass("hover");
      }
    }

    function hideSelectionChoices() {
      htmlMentionSelectChoices.addClass("hidden");
      $element.find(document).off("keyup", handleChoicesControlKeys);
      $element.find("li.hover", htmlMentionSelectChoices).removeClass("hover");
    }

    function handleFocusedElement(element, isFilterChoices = true) {
      if (element && $element.find(element).hasClass("mention")) {
        $element.find(element).addClass("focused");
        const searchPhrase = isFilterChoices
          ? $element.find(element).html().substr(1)
          : "";
        if (searchPhrase === "&nbsp;") {
          $element
            .find(element)
            .removeClass("focused")
            .removeClass("mention")
            .removeClass("ignore")
            .addClass("invalid");

          hideSelectionChoices();
        } else {
          const usersFound = searchUsers(searchPhrase);
          if (usersFound && usersFound.length > 0) {
            rebuildSelectionBox(searchPhrase, false);
            showSelectionChoices(element);
          } else {
            $element
              .find(element)
              .removeClass("focused")
              .removeClass("mention")
              .addClass("invalid");
            hideSelectionChoices();
          }
        }
      } else if (element && $element.find(element).hasClass("invalid")) {
        const searchPhrase = isFilterChoices
          ? $element.find(element).html().substr(1)
          : "";
        if (searchPhrase === "&nbsp;") {
          $element
            .find(element)
            .removeClass("focused")
            .removeClass("mention")
            .removeClass("ignore")
            .addClass("invalid");
          hideSelectionChoices();
        } else {
          const usersFound = searchUsers(searchPhrase);
          if (usersFound && usersFound.length > 0) {
            $element
              .find(element)
              .removeClass("invalid")
              .addClass("mention")
              .addClass("focused");
            rebuildSelectionBox(searchPhrase, false);
            showSelectionChoices(element);
          }
        }
      } else {
        // on Chrome, after removing a Tagging, the browser includes this tags
        // It should be removed to avoid side effects
        const spanTag = `<span style="background-color: rgb(153, 207, 234);">`;
        const fontTag = `<font color="#2d8dd9">`;
        if (
          element &&
          element.outerHTML &&
          (element.outerHTML.contains(spanTag) ||
            element.outerHTML.contains(fontTag))
        ) {
          let inputContent = htmlInputBox[0].innerHTML;
          const position = getCaretPosition(htmlInputBox[0]);

          inputContent = inputContent.replaceAll(spanTag, "");
          inputContent = inputContent.replaceAll(fontTag, "");
          inputContent = inputContent.replace(/<\/span>/g, "");
          inputContent = inputContent.replace(/<\/font>/g, "");
          htmlInputBox.html($sanitize(inputContent));
          processInput(false, true, position);
        }
        hideSelectionChoices();
      }
      $element.find(".mention").not(element).removeClass("focused");
    }
  }
})();
