(function () {
  angular
    .module("akitabox.core.auth.logout")
    .controller("LogoutController", LogoutController);

  /* @ngInject */
  function LogoutController(
    // Angular
    // Third Party
    $stateParams,
    $window,
    // Services
    AppcuesService,
    AuthService,
    FilterService,
    RecentService,
    ToastService,
    SessionService,
    TokenService
  ) {
    var self = this;

    // Get state params if there are any
    self.redirect_url = $stateParams.redirect_url;

    // so log out already
    logout();

    function logout() {
      // Invalidate the `recents` cookie
      RecentService.clearCookie();

      // Invalidate cached filters
      FilterService.clearAllFilters();

      SessionService.clearSession();

      TokenService.removeAuthToken();

      // make api call to clear access token and user cookies
      AuthService.logout()
        .catch(function (response) {
          ToastService.showError(response);
        })
        .finally(function () {
          AppcuesService.removeInstance();
          if (self.redirect_url) {
            $window.location.href = "/login?redirect_url=" + self.redirect_url;
          } else {
            $window.location.href = "/login";
          }
        });
    }
  }
})();
