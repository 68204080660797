import * as vvrview from "vrview/build/vrview.js";
(function () {
  angular
    .module("akitabox.core.lib.vrview", [])
    .factory("VRView", VRViewService);

  /* @ngInject */
  function VRViewService() {
    if (!vvrview) throw new Error("VRView is not available");
    return vvrview;
  }
})();
