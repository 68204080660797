import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { FunctionComponent, ReactNode } from "react";
import {
  Box,
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogProps,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { stylesheet } from "../stylesheet";

export interface ConfirmationDialogProps extends DialogProps {
  title: string;
  loading: boolean;
  content?: ReactNode;
  onCancel: () => void;
  onConfirm: () => Promise<void> | void;
}

export const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  title,
  loading,
  content,
  onCancel,
  onConfirm,
  ...rest
}) => {
  return (
    <Dialog {...rest}>
      <DialogTitle component={Box} css={ss.dialogTitle}>
        <Typography variant="h6" color="inherit">
          {title}
        </Typography>
        <IconButton color="inherit" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button css={ss.cancelButton} onClick={onCancel}>
          NO
        </Button>
        <LoadingButton
          color="error"
          loading={loading}
          disabled={loading}
          variant="contained"
          onClick={onConfirm}
        >
          YES
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const ss = stylesheet({
  cancelButton: { fontWeight: "bold" },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
