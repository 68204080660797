(function () {
  /**
   * @ngdoc component
   * @name abxSideBar
   *
   * @description
   * Application side navigation bar
   */
  angular
    .module("akitabox.ui.components.sideBar", [
      "akitabox.core.services.building",
      "akitabox.core.services.customerAccount",
      "akitabox.core.services.env",
      "akitabox.core.services.flag",
      "akitabox.core.services.identity",
      "akitabox.core.services.tagCategory",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.document.select",
      "akitabox.ui.dialogs.document.thumbnailFit",
      "akitabox.ui.directives.sideMenu",
      "akitabox.constants",
    ])
    .component("abxSideBar", {
      bindings: {
        organization: "<?abxOrganization",
        building: "<?abxBuilding",
      },
      controller: AbxSideBarController,
      controllerAs: "vm",
      templateUrl: "app/core/ui/components/side-bar/side-bar.component.html",
    });

  /* @ngInject */
  function AbxSideBarController(
    // Constants
    INSPECTION_PROGRAM_STATUS_ACTIVE,
    INSPECTION_PROGRAM_STATUS_CANCELED,
    models,
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
    MAINTENANCE_SCHEDULE_STATUS_CANCELED,
    SERVICE_REQUEST_STATUS_NEW,
    SERVICE_REQUEST_STATUS_OPEN,
    SERVICE_REQUEST_STATUS_DENIED,
    SERVICE_REQUEST_STATUS_COMPLETED,
    THUMBNAIL_FIT_OPTIONS,
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_CANCELED,
    WORK_ORDER_STATUS_COMPLETED,
    // Services
    BuildingService,
    CustomerAccountService,
    FeatureFlagService,
    IdentityService,
    TagCategoryService,
    ToastService,
    UserService,
    // Dialogs
    SelectDocumentDialog,
    ThumbnailFitDialog
  ) {
    var self = this;
    var permissions = UserService.getPermissions();
    const currentUser = UserService.getCurrent();

    // Constants
    var GA_SIDEBAR_CATEGORY = "sidebar";

    // Private Attributes
    var stateBase = "app";
    var gaActionPrefix = "";
    var userAccount;

    // Attributes
    self.fitOptions = THUMBNAIL_FIT_OPTIONS;
    self.sideMenu = getSideMenu();
    self.permissions = {
      canUpdate: permissions.is_administrator,
    };

    // Functions
    self.changeThumbnail = changeThumbnail;
    self.onFitChange = onFitChange;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      IdentityService.getCurrent().then(function (identity) {
        userAccount = identity;
        self.sideMenu = getSideMenu();
      });
    };

    self.$onChanges = function () {
      stateBase = self.building ? "app.building" : "app";
      gaActionPrefix = self.building ? "" : "organization-";
      self.sideMenu = getSideMenu();
    };

    /**
     * Change the building's thumbnail
     */
    function changeThumbnail() {
      if (!self.building) {
        ToastService.show("Not supported");
      }

      return TagCategoryService.getAll(self.building._id)
        .then(function (categories) {
          var selectDialogLocals = {
            building: self.building,
            title: "Change Thumbnail",
            uploadText: "Upload Thumbnail",
            allowMultiple: true,
            tagCategories: categories,
          };
          return SelectDocumentDialog.show({
            locals: selectDialogLocals,
          });
        })
        .then(function (document) {
          if (
            !(
              models.DOCUMENT.EQUIRECTANGULAR_IMAGE_TYPES.indexOf(
                document.extension.toLowerCase()
              ) >= 0
            )
          ) {
            throw new Error("Cannot use this file type as a thumbnail");
          }
          var fitDialogLocals = {
            building: self.building,
            document: document,
          };
          return ThumbnailFitDialog.show({
            locals: fitDialogLocals,
          });
        })
        .then(function (updatedBuilding) {
          self.building = updatedBuilding;
          angular.extend(self.building, updatedBuilding);
        })
        .catch(ToastService.showError);
    }

    /**
     * Handle building thumbnail fit change
     *
     * @param {String} fit  New thumbnail fit
     */
    function onFitChange(fit) {
      BuildingService.update(self.building._id, {
        thumbnail_fit: fit,
      })
        .then(function (building) {
          self.building = building;
        })
        .catch(ToastService.showError);
    }

    // =================
    // Private Functions
    // =================

    function getSideMenu() {
      var dashboard = {
        name: "Dashboard",
        icon: "apps",
        state: {
          name: self.building ? "app.building.detail" : "app.dashboard",
        },
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "dashboard",
        },
      };

      var menu = [dashboard];

      if (self.organization.show_tasks) {
        var requestSection = getRequestSection();
        var workOrderSection = getWorkOrderSection();
        var scheduleSection = getScheduleSection();

        if (!self.organization.third_party_cmms_reactive_wos) {
          menu.push(requestSection, workOrderSection, scheduleSection);
        } else {
          menu.push(workOrderSection);
        }
      }

      if (self.organization.show_inspections) {
        if (permissions.inspection_program.read) {
          menu.push(getInspectionProgramSection());
        }
        if (FeatureFlagService.isEnabled("enhanced_inspections_and_wo")) {
          menu.push({
            isReact: true,
            icon: "assignment_turned_in",
            name: "Checklists",
            pathname: "/inspection_checklists",
          });
        }
      }

      const hasSisenseRole = !!currentUser.sisense_role;
      if (self.organization.show_embedded_reporting && hasSisenseRole) {
        menu.push(getInsightsSection());
      }

      const isReportBuilderEnabled =
        FeatureFlagService.isEnabled("report_builder");
      if (isReportBuilderEnabled && permissions.report_builder.read) {
        menu.push(getReportBuilderSection());
      }

      menu.push(getFloorSection());
      menu.push(getRoomSection());
      menu.push(getAssetSection());

      if (FeatureFlagService.isEnabled("enhanced_asset_list")) {
        menu.push({
          isReact: true,
          icon: "place",
          name: "Enhanced Assets (beta)",
          pathname: "/enhanced_assets",
        });
      }

      menu.push(getFileSection());
      menu.push(getExportSection());

      if (
        self.organization.show_tasks &&
        !self.organization.third_party_cmms_reactive_wos
      ) {
        if (permissions.task.reporting && permissions.request.reporting) {
          menu.push(getReportingSection());
        }
      }

      if (self.building) {
        if (userAccount && userAccount.is_super_admin) {
          var adminSection = {
            name: "Admin",
            icon: "person",
            state: {
              name: "app.building.admin.jobs",
            },
          };
          menu.push(adminSection);
        }
      }

      return menu;
    }

    function getInsightsSection() {
      return {
        name: "Insights",
        icon: "timeline",
        state: {
          name: self.building ? "app.building.insights" : "app.insights",
        },
      };
    }

    function getRequestSection() {
      return {
        name: "Service Requests",
        icon: "announcement",
        state: {
          name: stateBase + ".requests",
          params: {
            status: SERVICE_REQUEST_STATUS_NEW,
          },
        },
        children: [
          {
            name: "New",
            icon: "new_releases",
            state: {
              name: stateBase + ".requests",
              params: {
                status: SERVICE_REQUEST_STATUS_NEW,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "requests-new",
            },
          },
          {
            name: "Open",
            icon: "drafts",
            state: {
              name: stateBase + ".requests",
              params: {
                status: SERVICE_REQUEST_STATUS_OPEN,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "requests-open",
            },
          },
          {
            name: "Completed",
            icon: "check_circle",
            state: {
              name: stateBase + ".requests",
              params: {
                status: SERVICE_REQUEST_STATUS_COMPLETED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "requests-completed",
            },
          },
          {
            name: "Denied",
            icon: "cancel",
            state: {
              name: stateBase + ".requests",
              params: {
                status: SERVICE_REQUEST_STATUS_DENIED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "requests-denied",
            },
          },
        ],
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "requests",
        },
      };
    }

    function getWorkOrderSection() {
      return {
        name: "Work Orders",
        icon: "build",
        state: {
          name: stateBase + ".workOrders",
          params: {
            status: WORK_ORDER_STATUS_OPEN,
          },
        },
        children: [
          {
            name: "Open",
            icon: "drafts",
            state: {
              name: stateBase + ".workOrders",
              params: {
                status: WORK_ORDER_STATUS_OPEN,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "workorders-open",
            },
          },
          {
            name: "Scheduled",
            icon: "event",
            state: {
              name: stateBase + ".workOrders",
              params: {
                status: WORK_ORDER_STATUS_SCHEDULED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "workorders-scheduled",
            },
          },
          {
            name: "Completed",
            icon: "check_circle",
            state: {
              name: stateBase + ".workOrders",
              params: {
                status: WORK_ORDER_STATUS_COMPLETED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "workorders-completed",
            },
          },
          {
            name: "Canceled",
            icon: "cancel",
            state: {
              name: stateBase + ".workOrders",
              params: {
                status: WORK_ORDER_STATUS_CANCELED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "workorders-canceled",
            },
          },
        ],
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "workorders",
        },
      };
    }

    function getScheduleSection() {
      return {
        name: "Maintenance Schedules",
        icon: "event",
        state: {
          name: stateBase + ".schedules",
          params: {
            status: MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
          },
        },
        children: [
          {
            name: "Active",
            icon: "event_available",
            state: {
              name: stateBase + ".schedules",
              params: {
                status: MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "maint-schedules-active",
            },
          },
          {
            name: "Canceled",
            icon: "event_busy",
            state: {
              name: stateBase + ".schedules",
              params: {
                status: MAINTENANCE_SCHEDULE_STATUS_CANCELED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "maint-schedules-canceled",
            },
          },
        ],
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "maint-schedules",
        },
      };
    }

    function getInspectionProgramSection() {
      return {
        name: "Inspection Programs",
        icon: "dns",
        state: {
          name: stateBase + ".inspectionPrograms",
          params: {
            status: INSPECTION_PROGRAM_STATUS_ACTIVE,
          },
        },
        children: [
          {
            name: "Active",
            icon: "event",
            state: {
              name: stateBase + ".inspectionPrograms",
              params: {
                status: INSPECTION_PROGRAM_STATUS_ACTIVE,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "inspection-programs-active",
            },
          },
          {
            name: "Canceled",
            icon: "event_busy",
            state: {
              name: stateBase + ".inspectionPrograms",
              params: {
                status: INSPECTION_PROGRAM_STATUS_CANCELED,
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "inspection-programs-canceled",
            },
          },
        ],
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "inspection-programs",
        },
      };
    }

    function getFloorSection() {
      return {
        name: "Floors",
        icon: "layers",
        state: {
          name: stateBase + ".floors",
        },
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "floors",
        },
      };
    }

    function getRoomSection() {
      return {
        name: "Rooms",
        icon: "dashboard",
        state: {
          name: stateBase + ".rooms",
          params: { decommissioned: false },
        },
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "rooms",
        },
        children: [
          {
            name: "Active",
            icon: "dashboard",
            state: {
              name: stateBase + ".rooms",
              params: { decommissioned: false },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "rooms",
            },
          },
          {
            name: "Decommissioned",
            icon: "remove_circle",
            state: {
              name: stateBase + ".rooms",
              params: { decommissioned: true },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "rooms",
            },
          },
        ],
      };
    }

    function getAssetSection() {
      return {
        name: "Assets",
        icon: "place",
        state: {
          name: stateBase + ".assets",
          params: { decommissioned: false },
        },
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "assets",
        },
        children: [
          {
            name: "Active",
            icon: "place",
            state: {
              name: stateBase + ".assets",
              params: { decommissioned: false },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "assets",
            },
          },
          {
            name: "Decommissioned",
            icon: "remove_circle",
            state: {
              name: stateBase + ".assets",
              params: { decommissioned: true },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "assets",
            },
          },
        ],
      };
    }

    function getFileSection() {
      return {
        name: "Files",
        icon: "folder",
        state: {
          name: stateBase + ".documents",
          params: {
            archived: "false",
          },
        },
        children: [
          {
            name: "Active",
            icon: "folder",
            state: {
              name: stateBase + ".documents",
              params: {
                archived: "false",
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "files-active",
            },
          },
          {
            name: "Archived",
            icon: "archive",
            state: {
              name: stateBase + ".documents",
              params: {
                archived: "true",
              },
            },
            gaParams: {
              category: GA_SIDEBAR_CATEGORY,
              action: gaActionPrefix + "files-archived",
            },
          },
        ],
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "files",
        },
      };
    }

    function getReportingSection() {
      return {
        name: "Reporting",
        icon: "timeline",
        state: {
          name: stateBase + ".reporting",
        },
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: gaActionPrefix + "reporting",
        },
      };
    }

    function getExportSection() {
      return {
        name: "Exports",
        svgIcon: "img/export.svg",
        state: {
          name: self.building ? "app.building.exports" : "app.exports",
        },
      };
    }

    function getReportBuilderSection() {
      return {
        isReact: true,
        icon: "edit_document",
        name: "Report Builder",
        // the reason to have both pathname AND to properies is when
        // your path name differs from where you want to navigate to
        // pathname is always required, to is optional
        pathname: FeatureFlagService.isEnabled("report_template")
          ? "/report_builder"
          : "/report_builder/reports",
        ...(FeatureFlagService.isEnabled("report_template")
          ? { to: "/report_builder/reports" }
          : {}),
        gaParams: {
          category: GA_SIDEBAR_CATEGORY,
          action: `${gaActionPrefix}report-builder`,
        },
        ...(FeatureFlagService.isEnabled("report_template")
          ? {
              children: [
                {
                  name: "Reports",
                  icon: "folder",
                  isReact: true,
                  pathname: "/report_builder/reports",
                  gaParams: {
                    category: GA_SIDEBAR_CATEGORY,
                    action: `${gaActionPrefix}report-builder-templates`,
                  },
                },
                {
                  name: "Templates",
                  icon: "folder",
                  isReact: true,
                  pathname: "/report_builder/templates",
                  gaParams: {
                    category: GA_SIDEBAR_CATEGORY,
                    action: `${gaActionPrefix}report-builder-templates`,
                  },
                },
              ],
            }
          : {}),
      };
    }
  }
})();
