(function () {
  angular
    .module("akitabox.core.services.account", [
      "ngCookies",
      "akitabox.core.constants",
      "akitabox.core.services.http",
    ])
    .factory("AccountService", AccountService);

  /** @ngInject */
  function AccountService($q, $cookies, USER_COOKIE, HttpService) {
    /**
     * Account service
     *
     * @type {Object}
     */
    var service = {
      getById: getById,
      get: get,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Get an account by ID
     *
     * @param  {String}                 accountId   Account ID
     * @param  {Object}                 params      Query params
     * @return {Promise<Object|Error>}              Promise that resolves with account
     */
    function getById(accountId, params) {
      if (!params) params = {};
      params.include_site_permissions = true;

      return HttpService.get("/admin/accounts/" + accountId, params);
    }

    /**
     * Get a list of accounts
     *
     * @return {Array} List of accounts
     */
    function get(params) {
      if (!params) params = {};

      return HttpService.get("/admin/accounts", params);
    }
  }
})();
