(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.accountAutocomplete
   */
  angular
    .module("akitabox.ui.directives.accountAutocomplete")
    .directive("abxAccountAutocomplete", AbxAccountAutocompleteDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.accountAutocomplete
   * @name AbxAccountAutocomplete
   * @restrict E
   *
   * @description
   * `abx-account-autocomplete` creates a typeahead for accounts
   *
   * @usage
   * <hljs lang="html">
   *   <abx-account-autocomplete
   *      id="account"
   *      name="account"
   *      ng-model="vm.account"
   *      abx-label="Account">
   *   </abx-account-autocomplete>
   * </hljs>
   *
   * @ngInject
   */
  function AbxAccountAutocompleteDirective($timeout) {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/account-autocomplete/account-autocomplete.html",
      require: ["abxAccountAutocomplete", "ngModel"],
      controller: "AbxAccountAutocompleteController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        label: "@?abxLabel",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Attributes
      var noPlaceholder = angular.isDefined(attrs.abxNoPlaceholder);
      var placeholder = noPlaceholder
        ? vm.label
        : attrs.placeholder || "Enter an account";

      // UI elements
      var $input;

      // Functions
      vm.setModelValue = setModelValue;

      init();

      /**
       * Initialize the element, watch properties, and add event listeners
       */
      function init() {
        attrs.$set("abx-custom-input", "");

        // Watch for model changes
        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        attachPropertyWatchers();

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and handle events
          $input = angular.element($element.find("input")[0]);
          setPlaceholder(placeholder);
          $input.on("blur", onBlur);
        });
      }

      /**
       * Handle external value changes
       *
       * @param  {*} value    New value
       * @return {*}          Parsed value
       */
      function onExternalChange(value) {
        vm.account = value;
        return value;
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          return $element.attr("placeholder");
        }, setPlaceholder);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
      }

      /**
       * Handle input blur events
       */
      function onBlur() {
        vm.ngModelCtrl.$setTouched();
      }

      /**
       * Set disabled, if building doesn't exist value is ignored
       * and disabled is set to true
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        vm.disabled = angular.isEmpty(value) ? false : value !== "false";
      }

      /**
       * Set input placeholder, if building doesn't exist value is ignored
       * and the placeholder is set to indicate that building is required
       *
       * @param {String} value    Attribute value
       */
      function setPlaceholder(value) {
        if (noPlaceholder) {
          if (value) vm.label = value;
        } else {
          placeholder = value || "Enter an account";
          $element.attr("placeholder", placeholder);
          if ($input) $input.attr("placeholder", placeholder);
        }
      }
    }
  }
})();
