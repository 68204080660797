(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineCreationSection", {
      bindings: {
        setInvalid: "<abxSetInvalid",
        onCustomCostLineDataChange: "<abxOnCustomCostLineDataChange",
        existingCostLine: "<abxExistingCostLine",
      },
      controllerAs: "vm",
      controller: AbxCostLineCreationSectionController,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/creation-section/cost-line-creation-section.component.html",
    });

  /* @ngInject */
  function AbxCostLineCreationSectionController(
    patterns,
    CostLineService,
    OrganizationService,
    SessionService,
    ToastService
  ) {
    const _private = {};
    // =================
    // Properties
    // =================

    /**
     * @public
     * @type { string } Form state for uniformat field
     */
    this.uniformat = "";

    /**
     * @public
     * @type { string } Form state for description field
     */
    this.description = "";

    /**
     * Customization form data
     */
    this.formData = {};

    /**
     * @property { array }
     * Current state of costline options
     */
    this.costOptions = [];

    /**
     * @property { object }
     * Current state of the reference costline
     */
    this.selectedCostLineOption = null;

    /**
     * @public
     * @type { boolean } store whether the cost search is loading
     */
    this.loadingCostOptions = false;

    /**
     * @public
     * @type { string } placeholder value for the search typeahead
     */
    this.searchPlaceholder = "Search";

    /**
     * @property { array }
     * Current state of organization options
     */
    this.organizationOptions = [];

    /**
     * @property { Organization }
     * Selected organization value
     */
    this.selectedOrganization = OrganizationService.getCurrent();

    // =================
    // Lifecycle
    // =================

    this.$onInit = () => {
      _private.fetchOrganizationOptions();
    };

    this.$onChanges = (changes) => {
      if (changes.existingCostLine && this.existingCostLine) {
        _private.populateFormFromExistingCostLine();
      }
    };

    // ================
    // Utils
    // ================
    const validityMap = {
      uniformat: false,
      description: false,
      formData: true,
    };

    _private.populateFormFromExistingCostLine = () => {
      if (!this.existingCostLine) {
        return;
      }
      this.validityMap = {
        uniformat: true,
        description: true,
        formData: true,
      };

      this.formData = {
        quantity: this.existingCostLine.quantity,
        baseCost: this.existingCostLine.total_cost,
        costPerUnit: this.existingCostLine.unit_cost,
        unit: this.existingCostLine.quantity_unit,
      };

      this.uniformat = this.existingCostLine.uniformat;
      this.description = this.existingCostLine.description;

      _private.emitCostLineData();
    };

    _private.emitCostLineData = () => {
      this.onCustomCostLineDataChange({
        model: {
          uniformat: this.uniformat,
          description: this.description,
          baseCost: this.formData.baseCost,
          quantity: this.formData.quantity,
          costPerUnit: this.formData.costPerUnit,
          unit: this.formData.unit,
        },
        invalid: !_private.isValid(validityMap),
      });
    };

    const pattern = new RegExp(patterns.CUSTOM_UNIFORMAT, "i");
    _private.isValidUniformat = (uniformat) => pattern.test(uniformat);

    this.getInvalidMessage = () => {
      if (!_private.isValidUniformat(this.uniformat)) {
        return "Uniformat can only consist of letters and numbers.";
      }
    };

    _private.isValid = (validityMap) => {
      return (
        validityMap.uniformat && validityMap.description && validityMap.formData
      );
    };

    /**
     * Parse a list of asset costs into a list of typeahead-consumable options of
     * form: { model, value }, where `model` is the cost line bff model, and `value`
     * is the asset's name
     *
     * @param {Array} costs - costline bff array
     *
     * @return {Promise<Object[]>} - Resolves with parsed options
     */
    _private.parseCostOptions = (costs) => {
      return costs.map((cost) => ({
        model: cost,
        value: cost.asset.name,
      }));
    };

    /**
     * Parse a list of organizations into a list of typeahead-consumable options of
     * form: { model, value }, where `model` is the organization model, and `value`
     * is the organization name
     *
     * @param {Array} organizations - costline bff array
     *
     * @return {Promise<Object[]>} - Resolves with parsed options
     */
    _private.parseOrganizationOptions = (organizations) => {
      return organizations.map((org) => ({
        model: org,
        value: org.name,
      }));
    };

    /**
     * Fetches and updates the organization options
     */
    _private.fetchOrganizationOptions = () => {
      const params = {
        deactivated_date: "null",
      };
      return OrganizationService.getAll(params)
        .then((organizations) => {
          this.organizationOptions =
            _private.parseOrganizationOptions(organizations);
          const previousOrganizationId =
            SessionService.getCostDialogOrganizationId() ||
            OrganizationService.getCurrent()._id;
          let previousOrganization = null;
          if (previousOrganizationId) {
            previousOrganization = organizations.find(
              (org) => org._id === previousOrganizationId
            );
          }
          this.selectedOrganization = previousOrganization;
        })
        .catch((err) => {
          ToastService.showError("Unable to fetch organizations");
          this.organizationOptions = [];
        });
    };

    // =================
    // Event Handlers
    // =================
    this.handleUniformatChange = ({ model, invalid }) => {
      validityMap.uniformat = !invalid && _private.isValidUniformat(model);
      this.setInvalid(!_private.isValid(validityMap));
      this.uniformat = model;
      _private.emitCostLineData();
    };

    this.handleDescriptionChange = ({ model, invalid }) => {
      validityMap.description = !invalid;
      this.setInvalid(!_private.isValid(validityMap));
      this.description = model;
      _private.emitCostLineData();
    };

    this.handleFormDataChange = ({ model, invalid }) => {
      validityMap.formData = !invalid;
      this.setInvalid(!_private.isValid(validityMap));
      this.formData = model;
      _private.emitCostLineData();
    };

    this.handleCostLineOptionChange = ({ model, value, invalid }) => {
      if (invalid) return;

      this.selectedCostLineOption = value;
      if (model) {
        // Populate from existing costline BFF format
        this.description = model.description;
        this.uniformat = model.uniformat;
        this.formData = {
          quantity: model.quantity,
          unit: model.quantity_unit,
          costPerUnit: model.user_cost,
          baseCost: model.user_cost * model.quantity,
        };

        validityMap.uniformat = true;
        validityMap.description = true;
        validityMap.formData = true;
        this.setInvalid(!_private.isValid(validityMap));
        _private.emitCostLineData();
      }
    };

    this.handleOrganizationChange = ({ model }) => {
      this.selectedOrganization = model;
      this.selectedCostLineOption = null;
      this.costOptions = [];
      SessionService.setCostDialogOrganizationId(model ? model._id : null);
    };

    // =================
    // Public Functions
    // =================

    /**
     * Fetch the cost options.
     *
     * @param {object} [$event]
     * @param {string} [$event.value]
     *
     * @return {Promise} - Resolves when cost options have been fetched and parsed
     */
    this.getCostOptions = ($event) => {
      if (!$event) return;
      const value = $event.value;
      if (!value || !this.selectedOrganization) {
        this.costOptions = [];
        return;
      }

      this.loadingCostOptions = true;
      return CostLineService.getByAssetNameBFF(
        this.selectedOrganization._id,
        value
      )
        .then((costs) => {
          if (angular.isEmpty(costs)) return [];
          this.costOptions = _private.parseCostOptions(costs);
        })
        .catch(ToastService.showError)
        .finally(() => {
          this.loadingCostOptions = false;
        });
    };
  }
})();
