(function () {
  angular
    .module("akitabox.core.services.condition")
    .factory("AssetConditionService", AssetConditionService);

  /** @ngInject */
  function AssetConditionService(models, HttpService) {
    /**
     * Condition service
     *
     * @type {Object}
     */
    var service = {
      // Routes
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      // CRUD
      get: get,
      getAll: getAll,
      create: create,
      remove: remove,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildDetailRoute(buildingId, assetId, conditionId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/assets/" +
        assetId +
        "/conditions/" +
        conditionId
      );
    }

    function buildListRoute(buildingId, assetId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/assets/" +
        assetId +
        "/conditions"
      );
    }

    /**
     * Get an array of conditions for an asset
     *
     * @param {String} buildingId     Building ID in which the asset exists
     * @param {String} assetId        Asset ID for which to fetch assemblies
     * @param {Object} params         An object of query string parameters
     */
    function get(buildingId, assemblyId, params) {
      var route = buildListRoute(buildingId, assemblyId);
      return HttpService.get(route, params);
    }

    /**
     * Get all asset conditions
     *
     * @param {String} buildingId     Building ID in which the asset exists
     * @param {String} assetId        Asset ID for which to fetch conditions
     * @param {Object} params         Query params
     */
    function getAll(buildingId, assetId, params) {
      var route = buildListRoute(buildingId, assetId);
      var conditions = [];
      return HttpService.getAll(route, params)
        .then(function () {
          return conditions;
        })
        .finally(angular.noop, function (data) {
          angular.copy(data, conditions);
        });
    }

    /**
     * Creates a new condition
     *
     * @param {String} buildingId     Building ID in which asset exists
     * @param {String} assetId        Asset ID to create condition on
     * @param {Object} data           Object containing fields to set on the new condition
     */
    function create(buildingId, assetId, data) {
      var route = buildListRoute(buildingId, assetId);
      return HttpService.post(route, data);
    }

    /**
     * Hard-delete a condition
     *
     * @param {String} buildingId     Building ID in which the asset exists
     * @param {String} assetId        Asset ID for which to fetch conditions
     * @param {String} conditionId    Condition to remove
     */
    function remove(buildingId, assetId, conditionId) {
      var route = buildDetailRoute(buildingId, assetId, conditionId);
      return HttpService.remove(route);
    }
  }
})();
