angular.module("akitabox.ui.dialogs").controller(
  "PinFieldFloorConfirmationDialogController",
  /**
   * @class
   * @ngInject
   */
  function AbxPinFieldFloorConfirmationDialogController($mdDialog, $q, $sce) {
    // ===========
    // Locals (Provided via calls to the service)
    // ===========

    /**
     * Callback invoked when the confirm stay button is clicked. If this function
     * rejects, the dialog will not be closed. If it resolves, it will.
     * @type { () => Promise<void> }
     */
    this.onConfirmStay;

    /**
     * Callback invoked when the confirm leave button is clicked. If this function
     * rejects, the dialog will not be closed. If it resolves, it will.
     * @type { () => Promise<void> }
     */
    this.onConfirmLeave;

    // ===========
    // State
    // ===========

    /**
     * @type { boolean }
     */
    this.saving = false;

    // ===========
    // Public Methods
    // ===========

    /**
     * @public
     * @type { () => Promise<void> }
     */
    this.handleConfirmStayClick = () => {
      this.saving = true;
      $q.resolve(this.onConfirmStay()).then(
        (result) => {
          this.saving = false;
          return $mdDialog.hide(result);
        },
        (_err) => {
          this.saving = false;
        }
      );
    };

    /**
     * @public
     * @type { () => Promise<void> }
     */
    this.handleConfirmLeaveClick = () => {
      this.saving = true;
      $q.resolve(this.onConfirmLeave()).then(
        (result) => {
          this.saving = false;
          return $mdDialog.hide(result);
        },
        (_err) => {
          this.saving = false;
        }
      );
    };
  }
);
