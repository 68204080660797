import { Key } from "swr";
import useSWRMutation, {
  SWRMutationConfiguration,
  SWRMutationResponse,
} from "swr/mutation";

/**
 * Wrapper for {@link useSWRMutation}.
 * @param fetcher An asynchronous function to perform the mutation
 * @returns A modified {@link useSWRMutation} result to allow for multiple
 *  arguments to be passed to the fetcher function
 *
 * @example
 * const MyComponent = () => {
 *   const { data, isMutating, error, trigger } = useApiMutation(api.documents.upload);
 *   return <button onClick={() => trigger({args: {file: someFile, name: "mock_document" }})}
 * }
 * 
 * @note
 * You MUST destructure the fields you use off of the result from this call,
 * or risk the component not rerendering when data is finished loading. See
 * {@link https://swr.vercel.app/docs/advanced/performance#dependency-collection}

 */
export function useApiMutation<A extends any[], R>(
  fetcher: (...args: A) => Promise<R>
): AbxSWRMutationResponse<R, any, A, [typeof fetcher]> {
  const { trigger, ...result } = useSWRMutation<R, any, [typeof fetcher], A>(
    [fetcher],
    ([fn], { arg }) => fn(...arg)
  );

  return {
    ...result,
    trigger: ({ args, options }) =>
      trigger(Array.isArray(args) ? args : ([args] as A), options) as any,
  };
}

/**
 * Parameter to the modified `trigger` function
 */
interface TriggerOptions<
  Data = any,
  Error = any,
  ExtraArg extends any[] = [],
  MutationKey extends Key = Key
> {
  /**
   * Arguments to pass to the fetcher function. If the fetcher function
   * can be invoked with one argument, and that argument isn't an array,
   * you may pass it here, otherwise pass an array of arguments to the fetcher
   * function.
   */
  args: TriggerArgsOption<ExtraArg>;
  /**
   * useSWRMutation trigger options. Can be used to override initial
   * useSWRMutation settings. See {@link SWRMutationConfiguration}
   */
  options?: SWRMutationConfiguration<Data, Error, ExtraArg, MutationKey>;
}

interface AbxSWRMutationResponse<
  Data = any,
  Error = any,
  ExtraArg extends any[] = [],
  MutationKey extends Key = Key
> extends Omit<
    SWRMutationResponse<Data, Error, ExtraArg, MutationKey>,
    "trigger"
  > {
  /**
   * Used to actually trigger the mutation.
   * @param options Options for the mutation fetcher call
   */
  trigger: (
    options: TriggerOptions<Data, Error, ExtraArg, MutationKey>
  ) => Promise<Data>;
}

type TriggerArgsOption<Args extends any[]> =
  | Args
  | IsMonoArgCompatible<Args, Args[0], Args>;

/**
 * Determine if functions using the given parameter tuple can be safely invoked
 * with only their first argument, and that that argument is not an array.
 *
 * This is to support `args` being optionally an array in {@link TriggerArgsOption}.
 *
 * @param T The param tule type
 * @param True The type to return if `T` is compatible
 * @param False The type to return if `T` is incompatible
 */
type IsMonoArgCompatible<T extends any[], True, False> = Func<T, any> extends (
  arg: any
) => any
  ? T[0] extends any[]
    ? False
    : True
  : False;

/**
 * Shorthand for a function type. Useful for readability
 * @param P Params tuple type
 * @param R Return type
 */
type Func<P extends any[], R> = (...args: P) => R;
