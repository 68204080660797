(function () {
  angular
    .module("akitabox.ui.dialogs.decommission")
    .controller("DecommissionDialogController", DecommissionDialogController);

  /* @ngInject */
  function DecommissionDialogController(
    // Angular
    $log,
    $mdDialog,
    $window,
    // Utils
    moment,
    ServiceHelpers,
    // Services
    AssetService,
    EnvService,
    OrganizationService,
    RequestService,
    RoomService,
    RoundTemplateService,
    ScheduleService,
    WorkOrderService
  ) {
    var self = this;

    // fields
    /**
     * @prop {Asset|null} asset - the asset that is being decommission
     */
    /**
     * @prop {Object} count - holds all the counts needed to decommission
     * @prop {Number} count.roundTemplate - number of round templates needed to take care of before user can decommission
     * @prop {Number} count.futureTask - number of MS needed to take care of before user can decommission
     * @prop {Number} count.openTask - number of open WO needed to take care of before user can decommission
     * @prop {Number} count.request - number of SR needed to take care of before user can decommission
     * @prop {Number} count.scheduledTask - number of scheduled WO needed to take care of before user can decommission
     */
    self.count = {
      roundTemplate: 0,
      futureTask: 0,
      openTask: 0,
      request: 0,
      scheduledTask: 0,
    };
    /**
     * @prop {boolean} decommissioning - flag to indicate we're decommissioning
     */
    self.decommissioning = false;
    /**
     * @prop {Date|null} decommissionDate - date of the decommissioning
     */
    self.decommissionDate = null;
    /**
     * @prop {boolean} denying - flag to indicate we're denying service requests
     */
    self.denying = false;
    /**

    /**
     * @prop {string} buildingId - the ID of the building the room/asset is in
     */
    self.buildingId = self.asset ? self.asset.building : self.room.building;

    if (self.buildingId && typeof self.buildingId === "object") {
      self.buildingId = self.buildingId._id;
    }
    /**
     
     * @prop {Object} error - Holds errors to show withn the dialog if any happens
     * @prop {Error|null} error.date - holds any eror that occurs during date selection process
     * @prop {Error|null} error.decommission - holds any eror that occurs during decommission process
     * @prop {Error|null} error.futureTask - holds any eror that occurs during the fetching of this count
     * @prop {Error|null} error.roundTemplate - holds any eror that occurs during the fetching of this count
     * @prop {Error|null} error.openTask - holds any eror that occurs during the fetching of this count
     * @prop {Error|null} error.request - holds any eror that occurs during the fetching of this count
     * @prop {Error|null} error.scheduledTask - holds any eror that occurs during the fetching of this count
     */
    self.error = {
      date: null,
      decommission: null,
      futureTask: null,
      roundTemplate: null,
      openTask: null,
      request: null,
      scheduledTask: null,
    };
    /**
     * @prop {Date|null} maximumDate - the furthest a decommission date can be set to
     */
    self.maximumDate = null;
    /**
     * @prop {Organization} organization - the organization the user belongs to
     */
    self.organization = OrganizationService.getCurrent();
    /**
     * @prop {Room|null} room - the room that is being decommission
     */
    /**
     * @prop {string} roomDisplayName - the full room name, [room_number] - [room_name]
     */
    self.roomDisplayName = "...";
    /**
     * @prop {boolean} showRoundTemplates
     */
    self.showRoundTemplates = self.organization.show_inspections;

    // methods
    self.decommission = decommission;
    self.decommissionDisabled = decommissionDisabled;
    self.onClickActiveMSHandler = onClickActiveMSHandler;
    self.onClickNewSRHandler = onClickNewSRHandler;
    self.onClickOpenWOHandler = onClickOpenWOHandler;
    self.onClickScheduledWOHandler = onClickScheduledWOHandler;
    self.onClickActiveRTHandler = onClickActiveRTHandler;
    self.onCloseClickHandler = $mdDialog.cancel;
    self.onChangeDecommissionDateHandler = onChangeDecommissionDateHandler;

    init();

    function init() {
      self.maximumDate = new Date();
      self.decommissionDate = new Date();
      // if we're decommissioning a room, we need to get the pretty room name
      if (self.room) {
        // async call here
        // TODO: use self.room.display_name
        ServiceHelpers.getRoomDisplayName(self.room)
          .then(function (roomDisplayName) {
            self.roomDisplayName = roomDisplayName;
          })
          .catch(function (err) {
            // default to the room number if we can't get the full name
            self.roomDisplayName = self.room.number;
          });
      }

      // Get all the initial count values
      getServiceRequestCount()
        .then(function (count) {
          self.count.request = count || 0;
          self.error.request = null;
        })
        .catch(function (err) {
          self.error.request = err;
        });
      getOpenWorkOrderCount()
        .then(function (count) {
          self.count.openTask = count || 0;
          self.error.openTask = null;
        })
        .catch(function (err) {
          self.error.openTask = err;
        });
      getScheduledWorkOrderCount()
        .then(function (count) {
          self.count.scheduledTask = count || 0;
          self.error.scheduledTask = null;
        })
        .catch(function (err) {
          self.error.scheduledTask = err;
        });
      getActiveMaintenanceScheduleCount()
        .then(function (count) {
          self.count.futureTask = count || 0;
          self.error.futureTask = null;
        })
        .catch(function (err) {
          self.error.futureTask = err;
        });
      if (self.showRoundTemplates) {
        getActiveRoundTemplateCount()
          .then(function (count) {
            self.count.roundTemplate = count || 0;
            self.error.roundTemplate = null;
          })
          .catch(function (err) {
            self.error.roundTemplate = err;
          });
      }
    }

    /**
     * Decommissions the room/asset
     */
    function decommission() {
      // remove the decommission error prior to decommissioning, if it errors
      // again it'll repopulate itself
      // this error is only populated from back-end errors
      delete self.error.decommission;
      self.decommissioning = true;
      var decommissionFn = self.asset
        ? AssetService.decommission(
            self.asset.building,
            self.asset._id,
            self.decommissionDate
          )
        : RoomService.decommission(
            self.buildingId,
            self.room._id,
            self.decommissionDate
          );

      decommissionFn
        .then(function (decommissionedPin) {
          $mdDialog.hide(decommissionedPin);
          self.error.decommission = null;
        })
        .catch(function (err) {
          if (err && err.data && err.data.error) {
            $log.error(err.data.error);
            // need to translate this back-end error to something we can read/display
            err = new Error(err.data.error.message);
          }
          self.error.decommission = err;
        })
        .finally(function () {
          self.decommissioning = false;
        });
    }

    /**
     * Flag that indicates if decommissioning is currently possible for this room/asset
     * @return {Boolean} true if disabled, false otherwise
     */
    function decommissionDisabled() {
      if (self.decommissioning) return true;
      // do not allow decommissiong if there are any outstanding SR,WO,MS,RT to close out first
      for (var count in self.count) {
        if (self.count[count] !== 0) {
          return true; // any count not being 0 is a no no.
        }
      }

      // Do not allow decommissioning if there are any errors present
      for (var error in self.error) {
        if (error === "decommission") continue;
        else if (self.error[error] !== null) {
          return true; // any count not being 0 is a no no.
        }
      }

      return false;
    }

    /**
     * Grabs a count of all the active maintenance schedules within this building
     * @return {Promise<Number>} number of new service requests
     */
    function getActiveMaintenanceScheduleCount() {
      // get the asset/room and status for which these requests need to include
      var params = {
        count: true,
        status: "active",
      };
      if (self.asset) params.asset = self.asset._id;
      else params.room = self.room._id;

      // Do our fetch
      // useCache is false because we always want this list to be nice and fresh
      return ScheduleService.getAll(self.buildingId, params, false).then(
        function (response) {
          return response.count;
        }
      );
    }

    function getActiveRoundTemplateCount() {
      var orgId = self.organization._id;
      var params = {
        count: true,
        status: "active",
      };
      if (self.asset) {
        params["stops.asset"] = self.asset._id;
      } else {
        params["stops.room"] = self.room._id;
      }
      return RoundTemplateService.getAll(orgId, params, false).then(function (
        response
      ) {
        return response.count;
      });
    }

    /**
     * Grabs a count of all the new service requests within this building
     * @return {Promise<Number>} number of new service requests
     */
    function getServiceRequestCount() {
      // get the asset/room and status for which these requests need to include
      var params = {
        count: true,
        status: "new",
      };
      if (self.asset) params.asset = self.asset._id;
      else params.room = self.room._id;

      // Do our fetch
      // useCache is false because we always want this list to be nice and fresh
      return RequestService.getAll(self.buildingId, params, false).then(
        function (response) {
          return response.count;
        }
      );
    }

    /**
     * Grabs a count of all the new service requests within this building
     * @return {Promise<Number>} number of open work orders
     */
    function getOpenWorkOrderCount() {
      // get the asset/room and status for which these requests need to include
      var params = {
        count: true,
        status: "open",
      };
      if (self.asset) params.asset = self.asset._id;
      else params.room = self.room._id;

      // Do our fetch
      // useCache is false because we always want this list to be nice and fresh
      return WorkOrderService.getAllByBuildingId(
        self.buildingId,
        params,
        false
      ).then(function (response) {
        return response.count;
      });
    }

    /**
     * Grabs a count of all the new service requests within this building
     * @return {Promise<Number>} number of open work orders
     */
    function getScheduledWorkOrderCount() {
      // get the asset/room and status for which these requests need to include
      var params = {
        count: true,
        status: "scheduled",
      };
      if (self.asset) params.asset = self.asset._id;
      else params.room = self.room._id;

      // Do our fetch
      // useCache is false because we always want this list to be nice and fresh
      return WorkOrderService.getAllByBuildingId(
        self.buildingId,
        params,
        false
      ).then(function (response) {
        return response.count;
      });
    }

    /**
     * Updates the decommission date whenever the user changes it
     * @param {Object} event
     * @param {Date} event.model - the actual piced date object
     * @param {String} event.value - string format of the date
     * @param {Boolean} event.invalid - true if not a valid date, false otherwise
     */
    function onChangeDecommissionDateHandler(event) {
      if (event.invalid) {
        var error = new Error("Decommission date is invalid");
        if (moment(event.model).isAfter(moment(self.decommissionDate))) {
          error = new Error("Decommission date cannot be in the future");
        }
        self.error.date = error;
      } else {
        self.error.date = null;
        self.decommissionDate = new Date(event.model);
      }
    }

    /**
     * Handles the user click on the service request item. Sends them to the new service request page
     */
    function onClickActiveMSHandler() {
      var entity = self.asset ? "asset" : "room";
      var id = self.asset ? self.asset._id : self.room._id;
      var url =
        EnvService.getCurrentBaseUrl() +
        "/buildings/" +
        self.buildingId +
        "/future_tasks?status=active&" +
        entity +
        "=" +
        id;
      // do the actual navigate here
      $window.open(url, "_blank");
    }

    /**
     * Handles the user click on the round template count. Sends them to the filtered round templates page
     */
    function onClickActiveRTHandler() {
      // TODO: link to filtered list
      var url =
        EnvService.getOrgSettingsUrl() +
        "/organization/settings/round_templates";

      // do the actual navigate here
      $window.open(url, "_blank");
    }

    /**
     * Handles the user click on the service request item. Sends them to the new service request page
     */
    function onClickNewSRHandler() {
      var entity = self.asset ? "asset" : "room";
      var id = self.asset ? self.asset._id : self.room._id;
      var url =
        EnvService.getCurrentBaseUrl() +
        "/buildings/" +
        self.buildingId +
        "/requests?status=new&" +
        entity +
        "=" +
        id;
      // do the actual navigate here
      $window.open(url, "_blank");
    }

    /**
     * Handles the user click on the service request item. Sends them to the new service request page
     */
    function onClickOpenWOHandler() {
      var entity = self.asset ? "asset" : "room";
      var id = self.asset ? self.asset._id : self.room._id;
      var url =
        EnvService.getCurrentBaseUrl() +
        "/buildings/" +
        self.buildingId +
        "/tasks?status=open&" +
        entity +
        "=" +
        id;
      // do the actual navigate here
      $window.open(url, "_blank");
    }

    /**
     * Handles the user click on the service request item. Sends them to the new service request page
     */
    function onClickScheduledWOHandler() {
      var entity = self.asset ? "asset" : "room";
      var id = self.asset ? self.asset._id : self.room._id;
      var url =
        EnvService.getCurrentBaseUrl() +
        "/buildings/" +
        self.buildingId +
        "/tasks?status=scheduled&" +
        entity +
        "=" +
        id;
      // do the actual navigate here
      $window.open(url, "_blank");
    }
  }
})();
