import { LoadingButton } from "@mui/lab";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ChangeEvent, FunctionComponent, useState } from "react";
import {
  Box,
  List,
  Stack,
  Tooltip,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
} from "@mui/material";

import { api } from "../../api";
import { getENV } from "../../../utils/getEnv";
import { constants } from "../../../utils/constants";
import { BulkAddStepProps } from "../BulkAddDialogTypes";
import { downloadFile } from "../../../utils/downloadFile";
import { useGlobalSnackbar } from "../../consecutive-snackbar/GlobalSnackbar";
import { getMultiLevelPropertyValue } from "../../../utils/getMultiLevelPropertyValue";

export const UploadCSV: FunctionComponent<BulkAddStepProps> = ({
  model,
  onNext,
  onImport,
  organizationId,
}) => {
  const { simple } = useGlobalSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleFileUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.files) return;
    const [file] = target.files;
    setFile(file);
  };

  const handleFileDownload = () => {
    const env = getENV();
    const { URL_BASE } = constants.APPS.CORE.ENV[env];
    const apiURL = URL_BASE.replace(/:\d+$/, "");
    const path = `/import/import_entities/${model}/csv_template`;

    const csvTemplateURL = new URL(path, apiURL);
    csvTemplateURL.searchParams.append("target_id", organizationId);
    downloadFile(csvTemplateURL);
  };

  const handleStart = async () => {
    if (!file) return;
    try {
      setIsLoading(true);
      const { data: response } = await api.imports.importCSV({
        files: file,
        importEntity: model,
        targetId: organizationId,
        targetEntity: "organization",
      });
      const { id: importId } = response.data;
      onImport(importId);
      onNext();
    } catch (error) {
      let message = "Unknown network error";
      if (error instanceof Error) {
        const errorMessage = getMultiLevelPropertyValue(
          error,
          "response.data.error.message"
        );
        message = errorMessage ? errorMessage : error.message;
      }
      simple(message, { severity: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box css={(theme) => ({ padding: theme.spacing(2) })}>
      <Typography
        fontWeight="bold"
        css={(theme) => ({ padding: `0 ${theme.spacing(2)}` })}
      >
        Steps
      </Typography>
      <List>
        <ListItem
          secondaryAction={
            <Tooltip placement="top" title="Download">
              <IconButton color="primary" onClick={handleFileDownload}>
                <FileDownloadIcon />
              </IconButton>
            </Tooltip>
          }
        >
          <ListItemText primary="1. Download CSV template" />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`2. Enter ${model} data into the CSV template`}
          />
        </ListItem>
        <ListItem
          secondaryAction={
            <Tooltip placement="top" title="Upload">
              <IconButton
                color="primary"
                component="label"
                htmlFor="upload_csv_input_id"
              >
                <FileUploadIcon />
                <input
                  type="file"
                  role="input"
                  accept=".csv"
                  id="upload_csv_input_id"
                  css={{
                    left: 0,
                    width: 1,
                    bottom: 0,
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    whiteSpace: "nowrap",
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                  }}
                  onChange={handleFileUpload}
                />
              </IconButton>
            </Tooltip>
          }
        >
          <ListItemText primary="3. Upload your CSV file" />
        </ListItem>
      </List>
      <Stack
        direction="row"
        justifyContent="flex-end"
        css={(theme) => ({ padding: `0 ${theme.spacing(2)}` })}
      >
        <LoadingButton
          color="primary"
          disableElevation
          loading={isLoading}
          variant="contained"
          onClick={handleStart}
          disabled={!file || isLoading}
        >
          START
        </LoadingButton>
      </Stack>
    </Box>
  );
};
