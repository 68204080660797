(function () {
  angular.module("akitabox.ui.themes", ["ngMaterial"]).config(mdConfig);

  /** @ngInject */
  function mdConfig($mdThemingProvider) {
    // Themes
    $mdThemingProvider.definePalette("black", {
      50: "#E4E4E4",
      100: "#BCBCBC",
      200: "#909090",
      300: "#646464",
      400: "#424242",
      500: "#212121",
      600: "#1D1D1D",
      700: "#181818",
      800: "#141414",
      900: "#0B0B0B",
      A100: "#FFFFFF",
      A200: "#FAFAFA",
      A400: "#F5F5F5",
      A700: "#EEEEEE",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200 A400 A700",
    });
    $mdThemingProvider.definePalette("sunglow", {
      50: "#fffae7",
      100: "#fff3c2",
      200: "#ffec9a",
      300: "#ffe472",
      400: "#ffde53",
      500: "#ffd835",
      600: "#ffd430",
      700: "#ffce28",
      800: "#ffc822",
      900: "#ffbf16",
      A100: "#ffffff",
      A200: "#fffefc",
      A400: "#ffefc9",
      A700: "#ffe7af",
      contrastDefaultColor: "light",
      contrastDarkColors: [
        "50",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "A100",
        "A200",
        "A400",
        "A700",
      ],
    });
    $mdThemingProvider.definePalette("midnight", {
      50: "#e4e5e7",
      100: "#bcbec4",
      200: "#90939d",
      300: "#646775",
      400: "#424758",
      500: "#21263a",
      600: "#1d2234",
      700: "#181c2c",
      800: "#141725",
      900: "#0b0d18",
      A100: "#D5E8F7",
      A200: "#238DD9",
      A400: "#2072B3",
      A700: "#B2DBFB",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200",
      contrastLightColors: [
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "A100",
        "A200",
        "A400",
        "A700",
      ],
    });
    $mdThemingProvider.definePalette("deep-red", {
      50: "#FCf8f8",
      100: "#E7C2C1",
      200: "#D79A99",
      300: "#C36765",
      400: "#BA514F",
      500: "#A94442",
      600: "#933B39",
      700: "#7D3231",
      800: "#672928",
      900: "#512120",
      A100: "#FCEEEE",
      A200: "#EF9795",
      A400: "#D1504D",
      A700: "#A94442",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 300 A100 A200",
    });
    $mdThemingProvider
      .theme("default")
      .primaryPalette("sunglow")
      .accentPalette("midnight")
      .warnPalette("deep-red")
      .backgroundPalette("grey", {
        default: "200",
      });
    $mdThemingProvider
      .theme("black")
      .primaryPalette("black")
      .accentPalette("black")
      .warnPalette("deep-red");
    $mdThemingProvider
      .theme("dark")
      .primaryPalette("midnight")
      .accentPalette("sunglow", {
        default: "500",
      })
      .warnPalette("deep-red");
  }
})();
