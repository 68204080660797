import { AlertColor } from "@mui/material";
import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import {
  ConsecutiveSnackbar,
  SnackbarQueue,
  useSnackbarQueue,
} from "./ConsecutiveSnackbar";
import { SimpleAlert } from "./SimpleAlert";

/**
 * Snackbar context used for the entire application.
 * See the {@link useGlobalSnackbar} hook to spawn snackbars in the
 * application.
 */
export const GlobalSnackbar: FC<{ children?: ReactNode }> = ({ children }) => {
  const queue = useSnackbarQueue();
  const { append, close } = queue;
  const ctx = useMemo<GlobalSnackbarContextType>(
    () => ({
      add: append,
      close: close,
      simple: (text, { severity = "success", testId = undefined } = {}) =>
        append({
          /**
           * Auto hide after 6 seconds
           */
          autoHideDuration: 6000,
          children: (
            <SimpleAlert
              onClose={close}
              severity={severity}
              data-testid={testId}
            >
              {text}
            </SimpleAlert>
          ),
        }),
    }),
    [append, close]
  );
  return (
    <GlobalSnackbarContext.Provider value={ctx}>
      <ConsecutiveSnackbar {...queue} />
      {children}
    </GlobalSnackbarContext.Provider>
  );
};

/**
 * Context provided by the {@link GlobalSnackbar} and consumed
 * by the {@link useGlobalSnackbar} hook
 */
const GlobalSnackbarContext = createContext<GlobalSnackbarContextType>(
  {} as any
);

export interface SimpleSnackbarOptions {
  /**
   * Alert severity, controls color. Defaults to "success"
   */
  severity?: AlertColor;
  /**
   * data-testid value to apply to the alert
   */
  testId?: string;
}

export interface GlobalSnackbarContextType {
  /**
   * See {@link SnackbarQueue.append}
   */
  add: SnackbarQueue["append"];
  /**
   * @see {@link SnackbarQueue.close}
   */
  close: SnackbarQueue["close"];
  /**
   * Spawn a simple toast for most basic informative use-cases.
   * @param message Alert contents
   * @param options Snackbar options
   */
  simple(message: ReactNode, options?: SimpleSnackbarOptions): void;
}

/**
 * Hook to allow spawning snackbars (toasts) from components.
 * @see {@link GlobalSnackbarContextType}
 * @example
 * const { add, close } = useSnackbar();
 * useEffect(() => {
 *   add({
 *    children: <Alert severity="warning" onClose={close}>Component Mounted</Alert>
 *   })
 * }, []);
 */
export const useGlobalSnackbar = () => useContext(GlobalSnackbarContext);
