(function () {
  angular.module("akitabox.ui.dialogs.bulkEdit.pinField", [
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.toast",
    "akitabox.core.services.room",
    "akitabox.core.services.asset",
    "akitabox.core.services.pinField",
    "akitabox.core.services.flag",
  ]);
})();
