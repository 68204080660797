(function () {
  angular
    .module("akitabox.desktop.directives.list.serviceRequest", [
      "akitabox.core.constants",
      "akitabox.core.services.building",
      "akitabox.core.services.issueType",
      "akitabox.core.services.organization",
      "akitabox.core.services.trade",
      "akitabox.core.services.user",
      "akitabox.core.services.workOrder",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.request",
      "akitabox.ui.dialogs.workOrder.create",
      "angular.filter",
    ])
    .directive("abxServiceRequestList", AbxServiceRequestListDirective);

  /* @ngInject */
  function AbxServiceRequestListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/request/request-list.html",
      controller: ServiceRequestListController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        status: "=abxStatus",
        parentFetch: "&abxFetch",
        parentFetchAll: "&abxFetchAll",
        allowAdd: "&?abxAllowAdd",
        createOptions: "=?abxCreateOptions",
      },
    };
  }

  /* @ngInject */
  function ServiceRequestListController(
    // Angular
    $scope,
    $timeout,
    $q,
    $stateParams,
    // Constants
    SERVICE_REQUEST_STATUS_NEW,
    SERVICE_REQUEST_STATUS_OPEN,
    SERVICE_REQUEST_STATUS_OPEN_NEW,
    SERVICE_REQUEST_STATUS_DENIED,
    SERVICE_REQUEST_STATUS_COMPLETED,
    // Dialogs
    CreateWorkOrderDialog,
    DenyRequestDialog,
    DeleteRequestDialog,
    ReopenRequestDialog,
    // Services
    BuildingService,
    IssueTypeService,
    OrganizationService,
    ToastService,
    TradeService,
    UserService,
    WorkOrderService,
    Router
  ) {
    var self = this;

    var openStatuses = [
      SERVICE_REQUEST_STATUS_NEW,
      SERVICE_REQUEST_STATUS_OPEN,
      SERVICE_REQUEST_STATUS_OPEN_NEW,
    ];

    var showWOStatuses = [
      SERVICE_REQUEST_STATUS_COMPLETED,
      SERVICE_REQUEST_STATUS_OPEN,
      SERVICE_REQUEST_STATUS_OPEN_NEW,
      SERVICE_REQUEST_STATUS_DENIED,
    ];

    var permissions = UserService.getPermissions();

    // Attributes
    self.isOpen = openStatuses.indexOf(self.status) > -1;
    self.showWorkOrderColumn =
      self.building && showWOStatuses.indexOf(self.status) > -1;
    self.showIssueTypeColumn = !self.building || self.building.show_issue_types;
    self.permissions = getDefaultPermissions();
    self.organization = OrganizationService.getCurrent();
    self.creatingWOs = false;

    self.issueTypeMap = {};
    self.tradeMap = {};
    self.buildings = {};

    // Functions
    self.getListActions = getListActions;
    self.getRequests = getRequests;
    self.getAllRequests = getAllRequests;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watch("vm.status", function (value) {
        if (value) {
          self.isOpen = openStatuses.indexOf(self.status) > -1;
          self.showWorkOrderColumn =
            self.building && showWOStatuses.indexOf(self.status) > -1;
        }
      });
      $scope.$watchCollection("vm.models", function (models) {
        if (models && !self.building) {
          BuildingService.populateBuildings(self.buildings, models);
        }
        if (
          models &&
          self.building &&
          self.building.show_issue_types &&
          self.building.require_trades
        ) {
          getTypesAndTrades();
        }
      });
    }

    function getTypesAndTrades() {
      if (!self.building) {
        return;
      }
      IssueTypeService.getAll(self.building._id)
        .then(function (issueTypes) {
          self.issueTypeMap = {};
          issueTypes.map(function (issueType) {
            self.issueTypeMap[issueType._id] = issueType;
          });

          return TradeService.getAll(self.building._id);
        })
        .then(function (trades) {
          self.tradeMap = {};
          trades.map(function (trade) {
            self.tradeMap[trade._id] = trade;
          });
        })
        .finally(function () {
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        });
    }

    function getDefaultPermissions() {
      return {
        canDeny: false,
        canReopen: false,
        canAddWorkOrder: false,
      };
    }

    function openCreateWorkOrderDialog(items) {
      var index = items[0];
      var request = self.models[index];
      var building = BuildingService.getById(request.building);
      var locals = {
        building: building,
        request: request,
      };

      return CreateWorkOrderDialog.show({ locals: locals })
        .then(function () {
          self.models.splice(index, 1);
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    /**
     * Takes the selected service requests and attempts to automatically make work orders out of them based on their
     * existing data
     *
     * @param {Array} items Array of indices (i.e. [1, 4, 2], etc) of the selected service requests
     */
    function createWorkOrders(items) {
      var promiseArray = [];

      // Kind of hacky but I can't think of a better way to update the list's actions such that a loading indicator
      // appears on the Accept button
      $timeout(function () {
        self.creatingWOs = true;
        $scope.$broadcast("list:refreshListActions");
      });

      // Iterate through selected requests and add work order create calls to promise array
      for (var i = 0; i < items.length; i++) {
        var itemIndex = items[i];
        var request = self.models[itemIndex];
        var tradeId = request.issue_type ? request.issue_type.trade : null;
        var trade = self.tradeMap[tradeId];
        promiseArray[i] = WorkOrderService.createFromRequest(
          self.building,
          request,
          trade
        );
      }

      return $q
        .all(promiseArray)
        .then(function (newWorkOrders) {
          var serviceRequestIdsToRemove = [];
          var newWorkOrderNumbers = [];
          var numCreatedWorkOrders = newWorkOrders.length;
          for (var i = 0; i < numCreatedWorkOrders; i++) {
            var workOrder = newWorkOrders[i];
            serviceRequestIdsToRemove.push(
              workOrder.request._id || workOrder.request
            );
            newWorkOrderNumbers.push(workOrder.number);
          }
          // If all service requests were created, just empty the list
          if (self.models.length === numCreatedWorkOrders) {
            self.models.length = 0;
          } else {
            // Filter out requests we made work orders for
            self.models = self.models.filter(function (request) {
              return serviceRequestIdsToRemove.indexOf(request._id) < 0;
            });
          }

          var toastText =
            "Successfully created " +
            numCreatedWorkOrders +
            " work order" +
            (numCreatedWorkOrders > 1 ? "s" : "");
          ToastService.complex()
            .text(toastText)
            .action("View", function () {
              goToWorkOrders(newWorkOrderNumbers);
            })
            .show();
        })
        .catch(ToastService.showError)
        .finally(function () {
          // Need to refresh the actions and click events
          $timeout(function () {
            self.creatingWOs = false;
            $scope.$broadcast("list:refreshListActions");
            $scope.$broadcast("list:refreshClickEvents");
          });
        });
    }

    function goToWorkOrders(numbers) {
      var stateParams = {
        buildingId: $stateParams.buildingId,
        number: "$in," + numbers.join(","),
      };
      Router.go("app.workOrders", stateParams);
    }

    function denyRequests(items) {
      var locals = {
        requests: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };
      return DenyRequestDialog.show({ locals: locals })
        .then(function (deniedRequestIDs) {
          // update self.models to remove the relevant service requests
          if (self.models.length === deniedRequestIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (request) {
              return deniedRequestIDs.indexOf(request._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function deleteRequests(items) {
      var locals = {
        requests: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };

      return DeleteRequestDialog.show({ locals: locals })
        .then(function (deletedRequestIDs) {
          // update models to remove the relevant requests
          if (self.models.length === deletedRequestIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (listRequest) {
              return deletedRequestIDs.indexOf(listRequest._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function reopenRequests(items) {
      var locals = {
        requests: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };
      return ReopenRequestDialog.show({ locals: locals })
        .then(function (removedRequestIDs) {
          // update self.models to remove the relevant service requests
          if (self.models.length === removedRequestIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (request) {
              return removedRequestIDs.indexOf(request._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getListActions(items) {
      if (!self.building) {
        return [];
      }
      var reopenAction = {
        text: "Reopen",
        icon: "refresh",
        onClick: reopenRequests,
      };
      var denyAction = {
        text: "Deny",
        icon: "close",
        tooltip: "Deny this Service Request",
        onClick: denyRequests,
      };
      var deleteAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteRequests,
      };
      var createWorkOrderAction = {
        text: "Accept + Edit",
        icon: "build",
        tooltip:
          "Accept this Service Request and edit details for the Work Order.",
        onClick: openCreateWorkOrderDialog,
      };
      var acceptWorkOrdersAction = {
        text: "Accept",
        icon: "done",
        tooltip:
          "Accept this Service Request as is and instantly create a Work Order.",
        loading: self.creatingWOs,
        onClick: createWorkOrders,
      };
      var actions = [];

      var canDeny = permissions.request.deny;
      var canDelete = permissions.request.remove;
      var canReopen = permissions.request.reopen;
      var canAddWorkOrder = permissions.task.create && self.isOpen;
      var canAccept = permissions.task.create && self.isOpen;

      if (canDeny || canReopen || canAddWorkOrder) {
        for (var i = 0; i < items.length; ++i) {
          var request = self.models[items[i]];
          if (request.task) {
            // If any request has a work order, can't do anything
            canDeny = false;
            canAddWorkOrder = false;
            canReopen = false;
            canAccept = false;
          } else if (request.is_denied) {
            // If any request is denied, can't deny
            canDeny = false;
          } else {
            // If any request is open, can't reopen
            canReopen = false;
          }

          // If any request doesn't have an issue type with a linked trade, and the building requires trades for work
          // orders, then we can't one-click create work orders
          if (
            self.building.require_trades &&
            (!request.issue_type || !request.issue_type.trade)
          ) {
            canAccept = false;
          }
        }
      }

      if (canDeny) {
        actions.push(denyAction);
      } else if (canReopen) {
        actions.push(reopenAction);
      }

      if (canDelete && self.status === SERVICE_REQUEST_STATUS_DENIED) {
        actions.push(deleteAction);
      }

      if (items.length === 1 && canAddWorkOrder) {
        actions.push(createWorkOrderAction);
      }

      if (canAccept) {
        actions.push(acceptWorkOrdersAction);
      }

      return actions;
    }

    function getRequests(skip, limit) {
      return self.parentFetch({
        skip: skip,
        limit: limit,
      });
    }

    function getAllRequests(limit) {
      return self.parentFetchAll({
        limit: limit,
      });
    }
  }
})();
