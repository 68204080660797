(function () {
  angular
    .module("akitabox.core.services.organization", [
      "akitabox.constants",
      "akitabox.core.services.env",
      "akitabox.core.services.http",
      "akitabox.core.services.redirect",
    ])
    .factory("OrganizationService", OrganizationService);

  /** @ngInject */
  function OrganizationService(
    // Angular
    $log,
    $q,
    $timeout,
    $cookies,
    // AkitaBox
    models,
    // Services
    EnvService,
    HttpService,
    RedirectService,
    SessionService,
    // Constants
    RECENT_SUBDOMAIN_COOKIE
  ) {
    var service = {
      init: init,
      redirectToBuildingOrg: redirectToBuildingOrg,
      // Attributes
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      // Accessors
      getCurrent: getCurrent,
      setCurrent: setCurrent,
      // Create
      // Retrieve
      get: get,
      getById: getById,
      getBySubdomain: getBySubdomain,
      getAll: getAll,
      isUserIpTrusted: isUserIpTrusted,
      // Update
      // Filters
    };

    var current;
    var isOrgSubdomain;

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildDetailRoute(organizationId) {
      return "/" + models.ORGANIZATION.ROUTE_PLURAL + "/" + organizationId;
    }

    function buildListRoute() {
      return "/" + models.ORGANIZATION.ROUTE_PLURAL;
    }

    function goToFirstOrganization() {
      return get().then(function (organizations) {
        if (!organizations || !organizations.length) {
          $log.error("No organizations, please create one");
          return;
        }

        // Attempt to get last used org from cookie
        var organization;
        var cookieSubdomain = $cookies.get(RECENT_SUBDOMAIN_COOKIE);
        for (var i = 0; i < organizations.length && cookieSubdomain; i += 1) {
          var org = organizations[i];
          if (org.subdomain.key === cookieSubdomain) {
            organization = org;
          }
        }

        // If organization not found from cookie, default to the first returned org
        organization = organization || organizations[0];

        return RedirectService.redirectToOrganization(organization);
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function init(path) {
      current = null;

      var isPortal = EnvService.isPortalDomain();
      var subdomain = EnvService.getSubdomain();

      isOrgSubdomain = !isPortal && Boolean(subdomain);

      if (isOrgSubdomain) {
        // Set the current based on the subdomain
        return service
          .getBySubdomain(subdomain)
          .then(function (organization) {
            if (subdomain !== organization.subdomain.key) {
              RedirectService.redirectToOrganization(organization);
            }
            return organization;
          })
          .then(setCurrent);
      }

      // If not building, plan view (with building), or media viewer route
      // eg. /buildings, /tasks, /assets/:assetId
      if (
        path &&
        (path.match(
          /^\/((?!buildings|media|plan)[a-zA-Z0-9_]*\/([a-zA-Z0-9]*)|[a-zA-Z0-9_]*\/?$)/
        ) ||
          path === "/plan/buildings")
      ) {
        // There's no way to know where this entity exists
        // Redirect to first organization
        return goToFirstOrganization();
      }

      return $q.resolve(null);
    }

    /**
     * Redirect the user to a building's organization
     */
    function redirectToBuildingOrg(building) {
      return service
        .getById(building.organization)
        .then(function (organization) {
          return RedirectService.redirectToOrganization(organization);
        });
    }

    /******************* *
     *   Accessors
     * ****************** */

    function getCurrent() {
      return current;
    }

    function setCurrent(organization) {
      current = organization;
      SessionService.setOrganizationId(current ? current._id : null);
      return current;
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function getById(organizationId, params) {
      var route = buildDetailRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getBySubdomain(subdomain, params) {
      if (!params) params = {};
      params.subdomain = subdomain;
      return service.get(params).then(function (organizations) {
        if (!organizations.length) {
          const error = new Error("Organizations not found");
          error.status = 404;
          error.error = {
            name: "NotFoundError",
            message: "Organizations Not Found",
          };

          return $q.reject(error);
        }
        return organizations[0];
      });
    }

    function get(params) {
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getAll(params) {
      var route = buildListRoute();
      return HttpService.getAll(route, params);
    }

    /**
     * Checks if the currently logged in user's ip address is whitelisted by the
     * organization
     * @param organizationId id of the org to check against
     * @return {Promise<boolean>} true if whitelisted, false otherwise
     */
    function isUserIpTrusted(organizationId) {
      return service.getById(organizationId, { is_trusted_ip: 1 });
    }
  }
})();
