/**
 * Maks
 * When we go the route of multiple tools, see the link below for good way to go about it.
 * https://stackoverflow.com/questions/49215584/toggling-multiple-tools-on-paper-js
 */
(function () {
  angular.module("akitabox.planView").factory("abxMarkupTools", MarkupTools);

  /* @ngInject */
  function MarkupTools($rootScope, paper, MarkupService) {
    var activeMapEvents = 0;
    var activeDrawingEvents = 0;
    var tools = {};

    // Attributes
    tools.activeTool = null;
    tools.cursorLayer = null;
    tools.strokeWidth = 1;
    tools.eraserStrokeWidth = 1;
    tools.strokeColor = "#FF0000";

    // Functions
    tools.reset = reset;
    tools.setStrokeWidth = setStrokeWidth;
    tools.setEraserWidth = setEraserWidth;
    tools.setStrokeColor = setStrokeColor;
    tools.startMapEvent = function () {
      activeMapEvents += 1;
    };
    tools.endMapEvent = function () {
      // We get a map zoom end event on window resize/rotate but no start
      activeMapEvents = Math.max(0, activeMapEvents - 1);
    };
    tools.isDrawing = function () {
      return activeDrawingEvents > 0;
    };
    tools.Highlighter = Highlighter;
    tools.LineTool = LineTool;
    tools.PaintBrush = PaintBrush;
    tools.RectangleTool = RectangleTool;
    tools.EraserToolByLine = EraserToolByLine;
    tools.EraserToolLasso = EraserToolLasso;

    return tools;

    /**
     * Event listener decorator that only fires
     * when Leaflet is not zooming or dragging
     */
    function whileMapInactive(func) {
      return function (event) {
        if (activeMapEvents === 0) {
          func(event);
        }
      };
    }

    function startDrawingEvent() {
      activeDrawingEvents += 1;
    }

    function completeDrawingEvent() {
      activeDrawingEvents -= 1;
      MarkupService.queueSave(tools);
    }

    function withGlobalOptions(options) {
      return Object.assign({}, options, {
        strokeWidth: tools.strokeWidth,
        strokeColor: tools.strokeColor,
      });
    }

    function reset() {
      tools.activeTool = null;

      if (paper.tool) {
        paper.tool.remove();
      }

      // Create new layer just for drawing cursors
      if (tools.cursorLayer) {
        tools.cursorLayer.remove();
      }
      tools.cursorLayer = new paper.Layer();

      // Reactivate the layer we should be drawing on
      var markup = MarkupService.getActiveMarkup();
      if (markup && markup.$layer) {
        markup.$layer.activate();
      }

      activeDrawingEvents = 0;
    }

    function setStrokeWidth(strokeWidth) {
      tools.strokeWidth = strokeWidth;
    }

    function setEraserWidth(strokeWidth) {
      tools.setEraserWidth = strokeWidth;
    }

    function setStrokeColor(strokeColor) {
      tools.strokeColor = strokeColor;
    }

    function drawCrosshairs(event, radius) {
      for (var i = 0; i < 4; i++) {
        var crosshair = new paper.Path.Line({
          from: [event.point.x, event.point.y + radius + 2],
          to: [event.point.x, event.point.y + radius + 6],
        });
        crosshair.strokeScaling = false;
        crosshair.strokeWidth = 1;
        crosshair.strokeColor = "#000000";

        crosshair.rotate(90 * i, event.point);
        crosshair.remove();
        tools.cursorLayer.addChild(crosshair);
        crosshair.removeOn({
          move: true,
          drag: true,
          up: true,
          down: true,
        });
      }
    }

    function Highlighter(options) {
      var defaultOptions = {
        strokeColor: "#FFFF00",
        opacity: 0.5,
        strokeWidth: tools.strokeWidth * 5,
        strokeCap: "round",
      };
      var _options = Object.assign({}, defaultOptions, options);

      var Highlighter = new paper.Tool();

      // props
      Highlighter.path = null;
      Highlighter.name = "Highlighter";

      // functions
      Highlighter.onMouseMove = whileMapInactive(onMouseMoveHandler);
      Highlighter.onMouseDown = whileMapInactive(onMouseDownHandler);
      Highlighter.onMouseDrag = whileMapInactive(onMouseDragHandler);
      Highlighter.onMouseUp = whileMapInactive(onMouseUpHandler);

      function onMouseMoveHandler(event) {
        drawCursor(event);
      }
      function onMouseDownHandler(event) {
        var opts = withGlobalOptions(_options);
        // Keep it default
        opts.strokeColor = defaultOptions.strokeColor;

        Highlighter.path = new paper.Path(opts);
        Highlighter.path.bringToFront(); // always keep the highlighter in on top of everything
        Highlighter.path.add(event.point);
        drawCursor(event);
        startDrawingEvent();
      }

      function onMouseDragHandler(event) {
        Highlighter.path.add(event.point);
        drawCursor(event);
      }

      function onMouseUpHandler(event) {
        Highlighter.path.smooth();
        drawCursor(event);
        completeDrawingEvent();
      }

      function drawCursor(event) {
        var radius = _options.strokeWidth / 2;

        var cursor = new paper.Path.Circle({
          strokeWidth: 1,
          strokeScaling: false,
          center: event.point,
          radius: tools.strokeWidth / 2,
          strokeColor: _options.strokeColor + "80", // 50% opacity
          fillColor: _options.strokeColor + "33", // 20% opacity
        });
        cursor.remove();
        tools.cursorLayer.addChild(cursor);
        cursor.removeOn({
          move: true,
          drag: true,
          up: true,
          down: true,
        });

        drawCrosshairs(event, radius);
      }

      return Highlighter;
    }

    function PaintBrush(options) {
      var defaultOptions = {
        strokeCap: "round",
      };
      var _options = Object.assign({}, defaultOptions, options);
      var PaintBrush = new paper.Tool();

      PaintBrush.path = null;
      PaintBrush.cursor = null;
      PaintBrush.onMouseMove = whileMapInactive(onMouseMoveHandler);
      PaintBrush.onMouseDown = whileMapInactive(onMouseDownHandler);
      PaintBrush.onMouseDrag = whileMapInactive(onMouseDragHandler);
      PaintBrush.onMouseUp = whileMapInactive(onMouseUpHandler);
      PaintBrush.name = "PaintBrush";

      return PaintBrush;

      function onMouseMoveHandler(event) {
        drawCursor(event);
      }

      function onMouseDownHandler(event) {
        PaintBrush.path = new paper.Path(withGlobalOptions(_options));
        PaintBrush.path.add(event.point);
        drawCursor(event);
        startDrawingEvent();
      }

      function onMouseDragHandler(event) {
        PaintBrush.path.add(event.point);
        drawCursor(event);
      }

      function onMouseUpHandler(event) {
        PaintBrush.path.smooth();
        drawCursor(event);
        completeDrawingEvent();
      }

      function drawCursor(event) {
        var radius = tools.strokeWidth / 2;

        PaintBrush.cursor = new paper.Path.Circle({
          strokeWidth: 1,
          strokeScaling: false,
          center: event.point,
          radius: radius,
          strokeColor: tools.strokeColor,
          fillColor: tools.strokeColor + "33", // 20% opacity
        });
        PaintBrush.cursor.remove();
        tools.cursorLayer.addChild(PaintBrush.cursor);
        PaintBrush.cursor.removeOn({
          move: true,
          drag: true,
          up: true,
          down: true,
        });

        drawCrosshairs(event, radius);
      }
    }

    function LineTool(options) {
      var defaultOptions = {};
      var _options = Object.assign({}, defaultOptions, options);
      var LineTool = new paper.Tool();

      LineTool.path = null;
      LineTool.onMouseDown = whileMapInactive(onMouseDownHandler);
      LineTool.onMouseUp = whileMapInactive(onMouseUpHandler);
      LineTool.onMouseMove = whileMapInactive(onMouseMoveHandler);
      LineTool.onMouseDrag = whileMapInactive(onMouseDragHandler);
      LineTool.name = "LineTool";

      return LineTool;

      function onMouseDownHandler(event) {
        startDrawingEvent();
        drawCrosshairs(event, 0);
        LineTool.path = new paper.Path(withGlobalOptions(_options));
        LineTool.path.add(event.point);
        LineTool.path.add(event.point);
      }

      function onMouseMoveHandler(event) {
        drawCrosshairs(event, 0);
      }

      function onMouseDragHandler(event) {
        drawCrosshairs(event, 0);
        LineTool.path.segments[1].point = event.point;
      }

      function onMouseUpHandler(event) {
        drawCrosshairs(event, 0);
        LineTool.path.segments[1].point = event.point;
        completeDrawingEvent();
      }
    }

    function RectangleTool(options) {
      var FROM = 0; // also 4, which is the closing point
      var TO = 2;

      var defaultOptions = {};
      var _options = Object.assign({}, defaultOptions, options);
      var RectangleTool = new paper.Tool();

      RectangleTool.path = null;
      RectangleTool.onMouseDrag = function (event) {
        drawCrosshairs(event, 0);
        updateToPoint(event);
      };
      RectangleTool.onMouseUp = function (event) {
        drawCrosshairs(event, 0);
        updateToPoint(event);
        completeDrawingEvent();
      };
      RectangleTool.onMouseDown = whileMapInactive(onMouseDownHandler);
      RectangleTool.onMouseMove = whileMapInactive(onMouseMoveHandler);
      RectangleTool.name = "RectangleTool";

      return RectangleTool;

      function onMouseDownHandler(event) {
        startDrawingEvent();
        drawCrosshairs(event, 0);
        RectangleTool.path = new paper.Path(withGlobalOptions(_options));
        RectangleTool.path.add(event.point);
        RectangleTool.path.add(event.point);
        RectangleTool.path.add(event.point);
        RectangleTool.path.add(event.point);
        RectangleTool.path.add(event.point);
      }

      function onMouseMoveHandler(event) {
        drawCrosshairs(event, 0);
      }

      function updateToPoint(event) {
        var from = RectangleTool.path.segments[FROM].point;
        var to = event.point;

        RectangleTool.path.segments[TO].point = to;
        RectangleTool.path.segments[1].point = new paper.Point(to.x, from.y);
        RectangleTool.path.segments[3].point = new paper.Point(from.x, to.y);
      }
    }

    function EraserToolByLine(options) {
      var ERASER_TOOL_MULTIPLIER = 8;
      var EraserTool = new paper.Tool();
      var activeLayer = paper.project.activeLayer;

      EraserTool.cursor = null;
      EraserTool.onMouseDown = whileMapInactive(onMouseDownHandler);
      EraserTool.onMouseDrag = whileMapInactive(onMouseDragHandler);
      EraserTool.onMouseMove = whileMapInactive(onMouseMoveHandler);
      EraserTool.onMouseUp = whileMapInactive(onMouseUpHandler);
      EraserTool.name = "EraserToolByLine";

      return EraserTool;

      function onMouseDownHandler(event) {
        drawCursor(event);
        eraseAt(event);
        startDrawingEvent();
      }

      function onMouseUpHandler(event) {
        drawCursor(event);
        eraseAt(event);
        completeDrawingEvent();
      }

      function onMouseDragHandler(event) {
        drawCursor(event);
        eraseAt(event);
      }

      function onMouseMoveHandler(event) {
        drawCursor(event);
      }

      function eraseAt(event) {
        var cursorRadius =
          (tools.eraserStrokeWidth || options.eraserStrokeWidth || 0) *
          ERASER_TOOL_MULTIPLIER;

        var items = activeLayer
          .hitTestAll(event.point, {
            tolerance: cursorRadius,
            fill: false,
            segments: false,
            stroke: true,
          })
          .map(function (result) {
            return result && result.item;
          });

        items.forEach(function (item) {
          item.remove();
        });
      }

      function drawCursor(event) {
        var cursorRadius =
          (tools.eraserStrokeWidth || options.eraserStrokeWidth || 0) *
          ERASER_TOOL_MULTIPLIER;

        EraserTool.cursor = new paper.Path.Circle({
          strokeWidth: 1,
          strokeScaling: false,
          center: event.point,
          radius: cursorRadius,
          strokeColor: "#c4c4c4",
        });
        EraserTool.cursor.remove();
        tools.cursorLayer.addChild(EraserTool.cursor);
        EraserTool.cursor.removeOn({
          move: true,
          drag: true,
          up: true,
          down: true,
        });
      }
    }

    function EraserToolLasso(options) {
      var EraserTool = new paper.Tool();
      var activeLayer = paper.project.activeLayer;

      EraserTool.lasso = null;
      EraserTool.cursor = null;
      EraserTool.onMouseDown = whileMapInactive(onMouseDownHandler);
      EraserTool.onMouseDrag = whileMapInactive(onMouseDragHandler);
      EraserTool.onMouseMove = whileMapInactive(onMouseMoveHandler);
      EraserTool.onMouseUp = whileMapInactive(onMouseUpHandler);
      EraserTool.name = "EraserToolLasso";

      return EraserTool;

      function onMouseDownHandler(event) {
        EraserTool.lasso = new paper.Path({
          closed: true,
          fillColor: "rgba(255,255,255,0.3)",
          strokeWidth: 1,
          strokeColor: "#c4c4c4",
        });
        EraserTool.lasso.add(event.point);
        drawCrosshairs(event, 3);
        startDrawingEvent();
      }

      function onMouseDragHandler(event) {
        EraserTool.lasso.add(event.point);
        drawCrosshairs(event, 3);
      }

      function onMouseUpHandler(event) {
        eraseLasso(EraserTool.lasso);
        drawCrosshairs(event, 3);
        completeDrawingEvent();
      }

      function onMouseMoveHandler(event) {
        drawCrosshairs(event, 3);
      }

      function eraseLasso(path) {
        var items = activeLayer.getItems({
          overlapping: path.bounds.clone(),
        });

        items.forEach(function (item) {
          if (item !== path) {
            var newItem = item.subtract(path, {
              trace: false,
            });
            item.remove();

            // We must promote all children to base layer because
            // .subtract blows up on CompoundPath
            if (newItem.hasChildren()) {
              activeLayer.addChildren(newItem.removeChildren());
              newItem.remove();
            }

            if (newItem.isEmpty()) {
              newItem.remove();
            }
          }
        });

        path.remove();
      }
    }
  }
})();
