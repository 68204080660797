/* eslint-disable no-console */
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouteLoaderData } from "react-router";
import {
  OrganizationResponse,
  UserResponse,
  WebAssetResponse,
} from "@akitabox/api-client";
import { useAngularApp } from "../legacy/angularApp";

import {
  Drawer,
  Box,
  Typography,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import {
  AccountTree,
  Assessment,
  CurrencyExchange,
  Edit,
} from "@mui/icons-material";

import { LocationCard } from "./LocationPreview";
import { AttachmentPreviewCard } from "./AttachmentsPreview";
import { AssetActions } from "./AssetActions";

type SideDrawerProps = {
  open: boolean;
  onClose: () => void;
  rows: WebAssetResponse[];
};

const SideDrawer: FunctionComponent<SideDrawerProps> = ({
  open,
  onClose,
  rows,
}) => {
  const [selectedRow, setSelectedRow] = useState(rows ? rows[0] : null);

  useEffect(() => {
    setSelectedRow(rows ? rows[0] : null);
  }, [rows]);

  const { user, organization, flags } = useRouteLoaderData("shell") as {
    user: UserResponse;
    organization: OrganizationResponse;
    flags: any;
  };

  const injector = useAngularApp();

  const permissions = useMemo(
    () => ({
      canDecommissionAsset: user.permission_group.asset["decommission"],
      canRecommissionAsset: user.permission_group.asset["recommission"],
      canDeleteAsset: user.permission_group.asset["remove"],
      canAddRequest:
        organization.show_tasks && user.permission_group.request["create"],
      canAddWorkOrder:
        organization.show_tasks && user.permission_group.task["create"],
      canAddSchedule:
        organization.show_tasks && user.permission_group.future_task["create"],
      canManageAssociations: user.permission_group.association["create"],
      canManageCostAdjustments:
        flags["asset_life_cycle"] &&
        user.permission_group.cost_adjustment["create"] &&
        user.permission_group.cost_adjustment["remove"] &&
        user.permission_group.cost_adjustment["update"],
      canManageLifeCycle:
        organization.show_fca &&
        flags["asset_life_cycle"] &&
        user.permission_group.cost_line["create"] &&
        user.permission_group.cost_line["update"] &&
        user.permission_group.cost_line["remove"],
      canUpdateAsset: user.permission_group.asset["update"],
    }),
    [user.permission_group, organization]
  );

  const areAllRowsInSameBuilding = (rows: WebAssetResponse[]) => {
    if (rows.length === 0) return false;
    const firstRowBuildingId = rows[0].building?._id;
    return rows.every((row) => row.building?._id === firstRowBuildingId);
  };

  // const areAllRowsInSameLevel = (rows: WebAssetResponse[]) => {
  //   if (rows.length === 0) return false;
  //   const firstRowLevel = rows[0].level?._id;
  //   return rows.every((row) => row.level?._id === firstRowLevel);
  // };

  const handleLegacyAssociation = useCallback(
    async (assets: WebAssetResponse[]) => {
      if (assets.length === 1) {
        await injector.get<any>("AssociationDialog").show({
          locals: {
            model: selectedRow,
            modelType: "Asset",
            associations: [],
            messageAboutMissingRelationships: "",
          },
        });
      } else {
        await injector.get<any>("BulkAddRelationshipsDialog").show({
          locals: {
            model: "assets",
            selectedItems: assets,
            building: assets[0].building,
            floor: assets[0].level,
          },
        });
      }
    },
    [injector, selectedRow]
  );

  const handleLegacyLifeCycleSelection = useCallback(
    async (assets: WebAssetResponse[]) => {
      const transformedAssets = assets.map((asset: WebAssetResponse) => {
        const { building, ...rest } = asset;
        return {
          ...rest,
          building: building?._id,
        };
      });
      await injector.get<any>("AssetLifeCycleDialog").show({
        locals: {
          assets: transformedAssets,
        },
      });
    },
    [injector]
  );

  const handleLegacyBulkEdit = useCallback(
    async (assets: WebAssetResponse[]) => {
      const transformedAssets = assets.map((asset: WebAssetResponse) => {
        const { building, ...rest } = asset;
        return {
          ...rest,
          building: building?._id,
        };
      });
      // Gather a list of unique pin types from the assets
      const pinTypes = transformedAssets.reduce((acc: any, asset: any) => {
        if (!acc.includes(asset.pinType)) {
          acc.push(asset.pinType);
        }
        return acc;
      }, []);
      await injector.get<any>("BulkEditPinFieldDialog").show({
        locals: {
          model: "assets",
          selectedItems: transformedAssets,
          pinTypes: pinTypes,
          buildingId: assets[0].building,
        },
      });
    },
    [injector]
  );

  const handleLegacyAssetCostAdjustment = useCallback(
    async (assets: WebAssetResponse[]) => {
      const transformedAssets = assets.map((asset: WebAssetResponse) => {
        const { building, ...rest } = asset;
        return {
          ...rest,
          building: building?._id,
        };
      });
      await injector.get<any>("AssetCostAdjustmentDialog").show({
        locals: {
          assets: transformedAssets,
        },
      });
    },
    [injector]
  );

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        position: "relative",
        "& .MuiDrawer-paper": {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          zIndex: "60",
        },
      }}
    >
      {rows?.length === 0 ? (
        <Box sx={{ width: 400, padding: 2, height: "100%", overflowY: "auto" }}>
          {/* Render content for rows = 0 */}
          <Typography>No rows available</Typography>
        </Box>
      ) : rows?.length > 0 ? (
        <Grid
          container
          spacing={2}
          columns={1}
          direction="column"
          sx={{
            width: 400,
            paddingLeft: 2,
            paddingRight: 2,
            height: "100%",
            overflowY: "auto",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            xs={12}
            position="sticky"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <AssetActions
              assets={rows}
              assetsInSameBuilding={areAllRowsInSameBuilding(rows)}
              permissions={permissions}
              onClose={onClose}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              overflowY: "auto",
            }}
          />
          {rows?.length === 1 ? (
            <Grid item spacing={2}>
              {selectedRow && (
                <AttachmentPreviewCard asset={selectedRow} showHeader={false} />
              )}
            </Grid>
          ) : null}
          {rows?.length > 0 ? (
            <Grid item spacing={2} xs={12}>
              <LocationCard
                asset={selectedRow}
                assets={rows}
                showHeader={false}
              />
            </Grid>
          ) : null}
          {permissions.canManageLifeCycle && (
            <Grid item spacing={2} xs={12}>
              <ListItemButton
                onClick={() => handleLegacyLifeCycleSelection(rows)}
              >
                <ListItemIcon>
                  <CurrencyExchange />
                </ListItemIcon>
                <ListItemText primary="Life Cycle & Cost" />
              </ListItemButton>
            </Grid>
          )}
          {permissions.canManageCostAdjustments && (
            <Grid item spacing={2} xs={12}>
              <ListItemButton
                onClick={() => handleLegacyAssetCostAdjustment(rows)}
              >
                <ListItemIcon>
                  <Assessment />
                </ListItemIcon>
                <ListItemText primary="Asset Cost Adjustment" />
              </ListItemButton>
            </Grid>
          )}
          {permissions.canManageAssociations && (
            <Grid item spacing={2} xs={12}>
              <Tooltip
                title={
                  !areAllRowsInSameBuilding(rows)
                    ? "Relationships only available for assets in the same building"
                    : ""
                }
                disableHoverListener={areAllRowsInSameBuilding(rows)}
              >
                <ListItemButton
                  onClick={() => handleLegacyAssociation(rows)}
                  disabled={!areAllRowsInSameBuilding(rows)}
                >
                  <ListItemIcon>
                    <AccountTree />
                  </ListItemIcon>
                  <ListItemText primary="Asset Relationships" />
                </ListItemButton>
              </Tooltip>
            </Grid>
          )}
          {permissions.canUpdateAsset && rows?.length > 1 ? (
            <Grid item spacing={2} xs={12}>
              <Tooltip
                title={
                  !areAllRowsInSameBuilding(rows)
                    ? "Bulk Edit only available for assets in the same building"
                    : ""
                }
                disableHoverListener={areAllRowsInSameBuilding(rows)}
              >
                <span>
                  <ListItemButton
                    onClick={() => handleLegacyBulkEdit(rows)}
                    disabled={!areAllRowsInSameBuilding(rows)}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <ListItemText primary="Bulk Edit" />
                  </ListItemButton>
                </span>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </Drawer>
  );
};

export default SideDrawer;
