(function () {
  angular
    .module("akitabox.ui.dialogs.schedule.cancel")
    .controller(
      "CancelScheduleDialogController",
      CancelScheduleDialogController
    );

  /* @ngInject */
  function CancelScheduleDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    ScheduleService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since schedule is passed in through locals
    self.multiple = self.schedules ? self.schedules.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.confirm = confirm;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Cancel maintenance schedules
     */
    function confirm() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.schedules.length) * 100;
      var promiseArray = [];

      // Cancel the maintenance schedules
      for (var i = 0; i < self.schedules.length; i++) {
        promiseArray[i] = ScheduleService.cancel(
          self.schedules[i].building._id,
          self.schedules[i]._id
        ).then(function (canceledSchedule) {
          self.determinateValue += increment;
          return canceledSchedule._id;
        });
      }

      $q.all(promiseArray)
        .then(function (canceledScheduleIDs) {
          $mdDialog.hide(canceledScheduleIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
