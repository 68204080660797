(function () {
  /**
   * @ngdoc component
   * @name abxMarkupToolDrawer
   *
   * @param {Boolean} [isOpenOnInit] - override the isOpen property on initialization
   * @param {Boolean} [disabled] - close and disable component
   *
   * @description
   * Tab for unplaced pins
   */
  angular.module("akitabox.planView").component("abxMarkupToolDrawer", {
    bindings: {
      isOpenOnInit: "<?abxIsOpenOnInit",
      disabled: "<?abxDisabled",
    },
    controller: AbxMarkupToolDrawerController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-drawer.component.html",
  });

  /* @ngInject */
  function AbxMarkupToolDrawerController(
    // Constants
    MARKUP_TOOL,
    // Angular
    $scope,
    $rootScope,
    // Services
    abxMarkupTools,
    OrganizationService,
    BuildingService,
    CreateMarkupDialog,
    MarkupService,
    IdentityService,
    ShadowService
  ) {
    var self = this;
    var GA_CATEGORY = "markup";
    // Attributes
    self.isOpen = false;

    // Functions
    self.selectedColor = function () {
      return { "background-color": abxMarkupTools.strokeColor };
    };
    self.toggleOpen = toggleOpen;
    self.toggleVisibility = toggleVisibility;
    self.onClickNewMarkup = onClickNewMarkup;
    self.hasActiveMarkup = MarkupService.hasActiveMarkup;
    self.isMarkupSaving = MarkupService.isMarkupSaving;
    self.isMarkupSynced = MarkupService.isMarkupSynced;
    self.isMarkupSyncFailing = MarkupService.isMarkupSyncFailing;
    self.isInternetUnavailable = MarkupService.isInternetUnavailable;
    self.isGloballyVisible = MarkupService.isGloballyVisible;
    self.getActiveMarkup = MarkupService.getActiveMarkup;
    self.areToolsEnabled = areToolsEnabled;
    self.selected = selected;
    self.hasActiveTool = hasActiveTool;

    var resetSaveIconState = angular.debounce(function () {
      self.justSaved = false;
    }, 2000);

    self.save = function () {
      MarkupService.forceSave(abxMarkupTools).then(function () {
        self.justSaved = true;

        resetSaveIconState();
      });
    };

    self.$onInit = init;

    // =================
    // Lifecycle
    // =================

    function init() {
      self.isOpenOnInit = self.isOpenOnInit || false;

      IdentityService.getCurrent().then(function (user) {
        self.hasUserMarkups = function () {
          return MarkupService.getActiveMarkups().some(
            MarkupService.filterByCreAccount(user)
          );
        };
      });

      $rootScope.$on(MARKUP_TOOL.ACTIONS.CLOSE_MARKUP_DRAWER, function () {
        toggleOpen(false);
      });

      $rootScope.$watch(
        function () {
          return MarkupService.hasActiveMarkup();
        },
        function (value) {
          if (value === false && abxMarkupTools.activeTool !== null) {
            abxMarkupTools.reset();
            $rootScope.$broadcast(
              MARKUP_TOOL.ACTIONS.DRAWER_TOOL_SELECTED,
              false
            );
          }
        }
      );
    }
    /**
     * Toggle visibility of unplacedPin tag and list
     * @param {Boolean} [visibility] - optional override; true sets it to open, false sets it closed
     */
    function toggleOpen(visibility) {
      if (angular.isDefined(visibility)) {
        self.isOpen = visibility;
      } else {
        self.isOpen = !self.isOpen;
      }

      if (self.isOpen) {
        sendGAEvent("toggle-toolbar", "open");
      } else {
        sendGAEvent("toggle-toolbar", "close");
      }

      abxMarkupTools.reset();

      $rootScope.$broadcast(MARKUP_TOOL.ACTIONS.DRAWER_TOOL_SELECTED, false);
    }

    function toggleVisibility() {
      var currentVisibility = MarkupService.isGloballyVisible();
      MarkupService.toggleGlobalVisibility(!currentVisibility);

      if (currentVisibility) {
        sendGAEvent("toggle-visibility", "off");
      } else {
        sendGAEvent("toggle-visibility", "on");
      }

      abxMarkupTools.reset();
      $rootScope.$broadcast(MARKUP_TOOL.ACTIONS.DRAWER_TOOL_SELECTED, false);
    }

    /**
     * Shows the dialog to create a new markup
     *
     * @param {Object} event  contains paperjs properties
     */
    function onClickNewMarkup(event) {
      self.organization = OrganizationService.getCurrent();
      self.building = BuildingService.getCurrent();
      var locals = {
        building: self.building,
        organization: self.organization,
        floor: self.floor,
      };
      CreateMarkupDialog.show({
        locals: locals,
      });

      if (self.hasUserMarkups()) {
        sendGAEvent("add-markup", "secondary");
      } else {
        sendGAEvent("add-markup", "initial");
      }
    }

    function areToolsEnabled() {
      return (
        MarkupService.hasActiveMarkup() && MarkupService.isGloballyVisible()
      );
    }

    function selected(toolName) {
      return abxMarkupTools.activeTool === toolName;
    }

    function sendGAEvent(action, label) {
      ShadowService.sendEvent(GA_CATEGORY, action, label, null);
    }

    function hasActiveTool() {
      return Boolean(abxMarkupTools.activeTool);
    }
  }
})();
