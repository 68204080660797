(function () {
  angular
    .module("akitabox.core.directives.transcludeReplace", [
      "akitabox.core.utils",
    ])
    .directive("abxTranscludeReplace", AbxTranscludeReplaceDirective);

  function AbxTranscludeReplaceDirective($log, Utils) {
    return {
      restrict: "EA",
      link: postLink,
    };

    function postLink($scope, $element, attrs, vm, transclude) {
      if (!transclude) {
        $log.error("abx-transclude-replace", "No transclusion found");
        return;
      }
      transclude(function (clone) {
        if (clone.length) {
          $element.replaceWith(clone);
        } else {
          Utils.removeElement($element);
        }
      });
    }
  }
})();
