import { FunctionComponent } from "react";
import { CircularProgress, ListItem, Box } from "@mui/material";

import { AttachmentDialogTestids } from "../AttachmentDialog";

export interface LoadingItemProps {
  className?: string;
}

/**
 * Renders a list item with the loading indicator
 * @param `LoadingItemProps`
 * @returns FunctionComponent
 */
export const LoadingItem: FunctionComponent<LoadingItemProps> = ({
  className,
}) => {
  return (
    <ListItem className={className}>
      <Box>
        {/* loading indicator */}
        <CircularProgress
          data-testid={AttachmentDialogTestids.LoadingIndicator}
        />
      </Box>
    </ListItem>
  );
};
