(function () {
  angular
    .module("akitabox.ui.dialogs.message.edit")
    .factory("EditMessageDialog", EditMessageDialog);

  /* @ngInject */
  function EditMessageDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/message/edit/edit-message.dialog.html",
      controller: "EditMessageDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * EditMessageDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.itemId`, `options.locals.type`, and `options.locals.building` are required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error}               Promise that resolves with the denied requests
     */
    function show(options) {
      // Verify we have a message and building locals
      if (
        !options ||
        !options.locals ||
        !options.locals.message ||
        !options.locals.building
      ) {
        $log.error(
          "EditMessageDialog: you must pass in message and building via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
