(function () {
  angular.module("akitabox.core.directives", [
    "akitabox.core.directives.editable",
    "akitabox.core.directives.enter",
    "akitabox.core.directives.exposeController",
    "akitabox.core.directives.focusOut",
    "akitabox.core.directives.onClickAway",
    "akitabox.core.directives.pinType",
    "akitabox.core.directives.sref",
    "akitabox.core.directives.transcludeReplace",
  ]);
})();
