(function () {
  angular.module("akitabox.desktop.document").run(states);

  /* @ngInject */
  function states(models, Router) {
    var LIST_QUERY_PARAMS = models.DOCUMENT.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.documents",
        config: {
          url: "/" + models.DOCUMENT.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/document/building-list/document-list.html",
          controller: "DocumentListController",
          controllerAs: "vm",
          reloadOnSearch: true,
          data: {
            pageTitle: "Files",
          },
          resolve: {
            archived: getArchived,
            tagCategories: getAllTagCategories,
          },
        },
      },
      {
        state: "app.building.document",
        config: {
          url: "/" + models.DOCUMENT.ROUTE_PLURAL + "/:documentId",
          templateUrl:
            "app/desktop/modules/document/detail/document-detail.html",
          controller: "DocumentDetailController",
          controllerAs: "vm",
          resolve: {
            document: getDocument,
          },
        },
      },
      {
        state: "app.documents",
        config: {
          url: "/" + models.DOCUMENT.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/document/organization-list/org-document-list.html",
          controller: "OrganizationDocumentListController",
          controllerAs: "vm",
          reloadOnSearch: true,
          data: {
            pageTitle: "Files",
          },
          resolve: {
            archived: getArchived,
          },
        },
      },
      {
        state: "app.document",
        config: {
          url: "/" + models.DOCUMENT.ROUTE_PLURAL + "/:documentId",
          templateUrl:
            "app/desktop/modules/document/detail/document-detail.html",
          controller: "DocumentDetailController",
          controllerAs: "vm",
          resolve: {
            document: getOrganizationDocument,
            building: getBuilding,
          },
        },
      },
    ]);
  }

  /** @ngInject */
  function getOrganizationDocument(
    $stateParams,
    account,
    organization,
    DocumentService
  ) {
    return DocumentService.getByOrganization(organization._id, {
      _id: $stateParams.documentId,
      archived: "both", // API by default hides archived documents in list routes
    }).then(function (document) {
      return document;
    });
  }

  /** @ngInject */
  function getBuilding($stateParams, account, document, BuildingService) {
    return BuildingService.getById(document.building);
  }

  /**
   * @ngInject
   */
  function getDocument($stateParams, account, building, DocumentService) {
    return DocumentService.getById(building._id, $stateParams.documentId);
  }

  /**
   * @ngInject
   */
  function getArchived(account, $stateParams) {
    return $stateParams.archived || false;
  }

  /**
   * @ngInject
   */
  function getAllTagCategories(TagCategoryService, account, building) {
    return TagCategoryService.getAll(building._id);
  }
})();
