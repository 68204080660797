(function () {
  angular.module("akitabox.ui.dialogs.asset", [
    "akitabox.ui.dialogs.asset.costLineSelection",
    "akitabox.ui.dialogs.asset.create",
    "akitabox.ui.dialogs.asset.delete",
    "akitabox.ui.dialogs.asset.lifeCycle",
    "akitabox.ui.dialogs.bulkEdit.pinField",
    "akitabox.ui.dialogs.asset.costAdjustment",
    "ngMaterial",
    "akitabox.core.constants",
  ]);
})();
