import {
  DefaultApi as ApiClient,
  createAkitaBoxClient,
  Configuration,
} from "@akitabox/api-client";

import { getENV } from "../../utils/getEnv";
import { constants } from "../../utils/constants";

const { CORE } = constants.APPS;
const { URL_BASE } = CORE.ENV[getENV()];
const basePath = URL_BASE.replace(/:\d+$/, "");

const client = new ApiClient(
  new Configuration({
    // base URL for api calls
    basePath,
    baseOptions: {
      // include cookies in axios calls
      withCredentials: true,
      // 5 mins
      timeout: 300000,
    },
  })
);

export const api = createAkitaBoxClient(client);
