(function () {
  angular
    .module("akitabox.ui.dialogs.room.delete")
    .controller("DeleteRoomDialogController", DeleteRoomDialogController);

  /* @ngInject */
  function DeleteRoomDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    FeatureFlagService,
    OrganizationService,
    RoomService,
    ToastService
  ) {
    var self = this;

    // Fields
    /**
     * @prop {string} roomDisplayName - the full room name, [room_number] - [room_name]
     */
    self.roomDisplayName = "...";

    // Attributes
    /**
     * @prop {boolean} saving - Whether the dialog is currently saving
     */
    self.saving = false;
    /**
     * have to set default false in order to run tests since roomIDs is passed in through locals
     * @prop {boolean} multiple - Whether the dialog is set for multiple rooms or not
     */
    self.multiple = self.roomIDs ? self.roomIDs.length > 1 : false;
    /**
     * @prop {string} buildingId - the ID of the building the room is in
     */
    self.buildingId = self.building ? self.building._id : self.buildingId;
    /**
     * @prop {Organization} organization - the organization the user belongs to
     */
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteRoom = deleteRoom;

    // ------------------------
    //   Private Functions
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete rooms
     */
    function deleteRoom() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.roomIDs.length) * 100;
      var promiseArray = [];

      // Delete the rooms
      for (var i = 0; i < self.roomIDs.length; i++) {
        promiseArray[i] = RoomService.deleteRoom(
          self.buildingId,
          self.roomIDs[i]
        )
          // when we delete a room, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.roomIDs[storedI];
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedRoomIDs) {
          $mdDialog.hide(deletedRoomIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
