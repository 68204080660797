(function () {
  angular
    .module("akitabox.ui.directives.simpleAutocomplete")
    .controller(
      "AbxSimpleAutocompleteController",
      AbxSimpleAutocompleteController
    );

  /**
   * @ngdoc controller
   * @module akitabox.ui.directives.simpleAutocomplete
   * @name AbxSimpleAutocompleteController
   * @restrict E
   *
   * @description
   * A controller intended to be used with the abx-simple-autcomplete-directive.
   * Handles calling the passed-in fetch function, querying on items, and
   * attribute initialization/validation.
   *
   * @ngInject
   */
  function AbxSimpleAutocompleteController(
    // Angular
    $q,
    // Third-party
    ToastService
  ) {
    var self = this;

    // Private Attributes
    var fetching;
    var fetchRequest;

    // Public Attributes
    self.allItems = null;
    self.disabled = false;
    self.fetchRequest = null;
    self.isCategorized = angular.isDefined(self.categoryKey);
    self.query = null;
    self.readonly = true;
    self.required = false;
    self.selectedItem = null;

    // Public Functions
    self.onSelectedItemChange = onSelectedItemChange;
    self.querySearch = querySearch;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Used when the user explicitly selects a new item from the
     * md-autocomplete.
     *
     * @param  {*} selectedItem      The newly selected item
     */
    function onSelectedItemChange(selectedItem) {
      self.setModelValue(selectedItem);
    }

    /**
     * Filters the list of available items to the user's query
     *
     * @param  {String}        query    User-given query text
     * @return {Promise|Array}          Filtered results
     */
    function querySearch(query) {
      self.query = query;

      if (self.items) {
        return queryOnStaticItems();
      }
      return queryOnFetchedItems();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Queries against the items fetched from the passed in fetch function
     *
     * On the initial query, it calls the passed-in fetch function, and
     * returns its return promise. Any additional queries performed before
     * the fetch is completed will update the query text used when the fetch
     * is resolved.
     *
     * @return {Promise}   Filtered results
     */
    function queryOnFetchedItems() {
      if (!fetchRequest) {
        fetching = true;
        fetchRequest = $q
          .resolve(self.fetch())
          .then(function (items) {
            self.items = items;
            if (angular.isEmpty(self.query)) return self.items;
            return filterQuery(self.query, self.items);
          })
          .catch(function (err) {
            ToastService.showError(err);
            self.items = [];
            return self.items;
          })
          .finally(function () {
            fetching = false;
          });
      }

      // Return fetch request if it is still outstanding
      if (fetching) {
        return fetchRequest;
      }

      return queryOnStaticItems();
    }

    /**
     * Queries against the items passed in statically
     *
     * @return {Array}           Filtered results
     */
    function queryOnStaticItems() {
      if (angular.isEmpty(self.query)) return self.items;
      return filterQuery(self.query, self.items);
    }

    /**
     * Filters a given list of items based on the controller's bound
     * text key for all items. Performs a simple RegEx match
     *
     * @param  {String} query     Text to filter by
     * @param  {Array}  items     Items to filter on
     * @return {Array}            Filtered items
     */
    function filterQuery(query, items) {
      return items.filter(function (item) {
        var name = item[self.itemTextKey];
        return name.match(new RegExp(query, "i"));
      });
    }
  }
})();
