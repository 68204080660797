(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .component("abxManagedFilterBar", {
      bindings: {
        /**
         * @type {FilterBarManager} - The manager for this filter bar.
         */
        manager: "<abxManager",
      },
      controller: ManagedFilterBarController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/components/filter-bar-manager/managed-filter-bar.component.html",
    });

  function ManagedFilterBarController() {}
})();
