(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.floorInput
   */
  angular
    .module("akitabox.ui.directives.floorInput", [
      "akitabox.constants",
      "akitabox.core.services.floor",
    ])
    .directive("abxFloorInput", AbxFloorInputDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.floorInput
   * @name AbxFloorInputDirective
   * @restrict E
   *
   * @description
   * `abx-floor-input` creates a dropdown menu to allow for selection of the floors
   *
   * @usage
   * <hljs lang="html">
   *   <abx-floor-input
   *      id="floor"
   *      name="floor"
   *      ng-model="vm.floor"
   *      abx-label="Floor"
   *      abx-building="vm.building">
   *   </abx-floor-input>
   * </hljs>
   *
   * @ngInject
   */
  function AbxFloorInputDirective($log, $timeout, models) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/floor-input/floor-input.html",
      require: ["abxFloorInput", "ngModel"],
      controller: AbxFloorInputController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        label: "@?abxLabel",
        building: "=abxBuilding",
        saving: "<?abxSaving",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Attributes
      var noPlaceholder = angular.isDefined(attrs.abxNoPlaceholder);
      var placeholder = noPlaceholder
        ? vm.label
        : attrs.placeholder || "Enter a " + models.FLOOR.SINGULAR;
      var originalPlaceholder = placeholder;

      // UI elements
      var $input;

      // Functions
      vm.setModelValue = setModelValue;

      init();

      /**
       * Initialize the element, verify building is provided,
       * watch properties, and add event listeners
       */
      function init() {
        if (!angular.isDefined(attrs.abxBuilding)) {
          return $log.error("<abx-floor-input>: abx-building is unassigned");
        }

        attrs.$set("abx-custom-input", "");

        // Watch for model changes
        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        // Watch dependency changes
        $scope.$watch("vm.building", onBuildingChange);

        attachPropertyWatchers();

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and handle events
          $input = angular.element($element.find("input")[0]);
          setPlaceholder(placeholder);
          $input.on("blur", onBlur);
        });
      }

      /**
       * Determines if model should be set to null
       *
       * @param newValue
       * @param oldValue
       */
      function onBuildingChange(newValue, oldValue) {
        // If editable, set disabled and reset placeholder
        if ($input && !vm.readonly) {
          setDisabled();
          setPlaceholder(originalPlaceholder);
        }

        if (!vm.floor || newValue === oldValue) return;

        var hasChanged = false;
        if (newValue && oldValue) {
          if (
            Object.prototype.hasOwnProperty.call(newValue, "_id") &&
            Object.prototype.hasOwnProperty.call(oldValue, "_id")
          ) {
            hasChanged = newValue._id !== oldValue._id;
          } else {
            hasChanged = true;
          }
        }

        if (hasChanged) {
          vm.floor = null;
          setModelValue(null);
        }
      }

      /**
       * Handle external value changes
       *
       * @param  {*} value    New value
       * @return {*}          Parsed value
       */
      function onExternalChange(value) {
        vm.floor = value;
        return value;
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Readonly
        $scope.$watch(function () {
          return $element[0].attributes.readonly;
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          return $element.attr("placeholder");
        }, setPlaceholder);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
        vm.ngModelCtrl.$render();
      }

      /**
       * Handle input blur events
       */
      function onBlur() {
        vm.ngModelCtrl.$setTouched();
      }

      /**
       * Set readonly
       *
       * @param {String} value    Attribute value
       */
      function setReadonly(value) {
        vm.readonly = Boolean(value);
        // Show / hide input
        if (vm.readonly && vm.floor) {
          hideInput();
        } else {
          showInput();
        }
      }

      /**
       * Set disabled, if building doesn't exist value is ignored
       * and disabled is set to true
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        if (vm.building) {
          vm.disabled = angular.isEmpty(value) ? false : value !== "false";
        } else {
          vm.disabled = true;
          setPlaceholder();
        }
      }

      /**
       * Set input placeholder, if building doesn't exist value is ignored
       * and the placeholder is set to indicate that building is required
       *
       * @param {String} value    Attribute value
       */
      function setPlaceholder(value) {
        value = vm.building ? value : "Building required";
        if (noPlaceholder) {
          if (value) vm.label = value;
        } else {
          placeholder = value || "Enter a " + models.FLOOR.SINGULAR;
          $element.attr("placeholder", placeholder);
          if ($input) $input.attr("placeholder", placeholder);
        }
      }

      /**
       * Show input element
       */
      function hideInput() {
        if ($input) $input.css("visibility", "hidden");
      }

      /**
       * Hide input element
       */
      function showInput() {
        if ($input) $input.css("visibility", "visible");
      }
    }
  }

  /* @ngInject */
  function AbxFloorInputController($q, FloorService, ToastService) {
    var self = this;

    var allFloors = null;
    var floorRequest = null;

    // Attributes
    self.label = self.label || "Floor";
    self.loading = false;
    self.query = null;
    self.disabled = false;
    self.readonly = true;
    self.required = false;

    // Functions
    self.querySearch = querySearch;
    self.selectedItemChange = selectedItemChange;

    /**
     * Search for buildling floors based on query, if query is blank
     * all floors will be returned. If query is in process, that same
     * request will be returned until it is resolved.
     *
     * @param  {String} query   Search text
     * @return {Array}          List of floors
     */
    function querySearch(query) {
      if (!self.building) return $q.resolve([]);

      if (self.loading) {
        // Update what will be queried for when request returned
        self.query = query;

        // Return the outstanding request if still loading
        return floorRequest;
      } else if (allFloors) {
        // Return all floors if we've already fetched them
        if (angular.isEmpty(query)) return allFloors;
        return filterQuery(query, allFloors);
      }

      self.loading = true;
      floorRequest = FloorService.getAll(self.building._id)
        .then(function (floors) {
          allFloors = floors;
          if (angular.isEmpty(self.query)) return floors;
          return filterQuery(self.query, floors);
        })
        .catch(function (err) {
          ToastService.showError(err);
          return [];
        })
        .finally(function () {
          self.loading = false;
        });

      return floorRequest;
    }

    /**
     * Handle floor select
     *
     * @param  {Object} item    Selected item (floor)
     */
    function selectedItemChange(item) {
      self.setModelValue(item);
    }

    /**
     * Filter matches based on a query string
     *
     * @param  {String} query       Query string
     * @param  {Array} matches      List of possible matches
     * @return {Array}              Filtered list of matches
     */
    function filterQuery(query, matches) {
      return matches.filter(function (match) {
        return match.name.match(new RegExp(query, "i"));
      });
    }
  }
})();
