(function () {
  angular
    .module("akitabox.core.srpRedirect")
    .controller("SrpRedirectController", SrpRedirectController);

  /* @ngInject */
  function SrpRedirectController(
    $window,
    BuildingService,
    EnvService,
    HttpService,
    OrganizationService,
    RedirectService,
    Utils
  ) {
    this.isMobile = EnvService.getBrowser().mobile;
    this.organization = OrganizationService.getCurrent();
    this.building = BuildingService.getCurrent();
    this.loading = false;

    this.group = null;
    if (!this.building) {
      this.group = {
        _id: null,
        name: "All Buildings",
        all: true,
      };
    }

    this.$onInit = () => {
      this.loading = true;
      // the user has already been deemed an SRP user if they've reachd this state
      // we are just doing an addtional check to see if we can auto redirect them to their
      // service request portal to avoid them having to click the button to go there themselves
      HttpService.get("/identity/users")
        .then((users) => {
          // user has access to only 1 org, that's what we want
          if (users.length === 1) {
            const { buildings, organization, permission_group } = users[0];
            // this user only has access to this SRP, redirect them to it
            if (permission_group.name === "Service Request Portal") {
              const nonHiddenBuildings = buildings.filter(
                (b) => !b.hide_from_portal
              );
              if (nonHiddenBuildings.length === 1) {
                let { portal_url = "", portal } = buildings[0];
                if (!portal_url) {
                  // sometimes we don't send back the building with .portal_url, so we gotta build it
                  portal_url = `${organization.portal_url}/buildings/${portal}`;
                }
                // the user will only be take straight to the building portal page if
                // 1. user has access to only 1 building
                // 2. building is not hidden from the portal
                $window.location.href = portal_url;
                // don't remove the returns, the $window.locaton redirect is not fast enough to
                // stop this condition from falling through to the org portal logic
                return;
              }
              // go straight to the org's SRP building list page
              // they can pick which building they want to go to
              $window.location.href = organization.portal_url;
              return;
            }
          }
        })
        .then(() => {
          // we have to be sure that the organization and building data are available
          if (!this.organization) {
            OrganizationService.init().then((organization) => {
              this.organization = organization;
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    };

    // handles organization selection for mobile website
    this.onSelectOrganization = ($event) => {
      const organization = $event.organization || $event.model;

      if (!Utils.isSameModel(organization, this.organization)) {
        RedirectService.redirectToOrganization(organization, "/m");
      }
    };

    // handles building selection
    this.onSelectBuilding = ($event) => {
      const organization = $event.organization;
      const building = $event.building || $event.model;
      if (!Utils.isSameModel(organization, this.organization)) {
        // navigate them to the building that's in a different org
        return RedirectService.redirectToOrganization(
          organization,
          `/buildings/${building._id}`
        );
      }

      // They opted to navigate to a building within the same org, we fake
      // it like they're changing buildings but really don't do much here.
      // They still only have SRP permissions within this org
      this.building = building;
      this.group = null;
    };

    // handles srp portal button click
    this.onClick = () => {
      $window.location.href = this.organization.portal_url;
    };

    // handles group selection for desktop website
    this.onSelectGroup = ($event) => {
      var organization = $event.organization;
      if (!Utils.isSameModel(organization, this.organization)) {
        // navigate them to the 'All Buldings' group that's in a different org
        return RedirectService.redirectToOrganization(
          organization,
          "/buildings"
        );
      }

      // They opted to just select a group within the same org, we fake
      // it like they're changing groups but really don't do much here.
      // They still only have SRP permissions within this org
      this.building = null;
      this.group = $event.group || $event.model;
    };
  }
})();
