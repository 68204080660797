(function () {
  angular.module("akitabox.desktop.document", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.core.services.env",
    "akitabox.core.services.tagCategory",
    "akitabox.ui.dialogs.document",
    "akitabox.desktop.document.detail",
    "akitabox.desktop.document.list",
    "akitabox.desktop.document.organizationList",
  ]);
})();
