(function () {
  angular.module("akitabox.desktop.workOrder").run(states);

  /* @ngInject */
  function states(models, Router, FeatureFlagService) {
    var LIST_QUERY_PARAMS = models.WORK_ORDER.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.workOrders",
        config: {
          url: "/" + models.WORK_ORDER.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/work-order/building-list/work-order-list.html",
          controller: "WorkOrderListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Orders",
          },
          reloadOnSearch: true,
          resolve: {
            status: getStatus,
            start_date: getStartDate,
          },
        },
      },
      {
        state: "app.building.workOrder",
        config: {
          url: "/" + models.WORK_ORDER.ROUTE_PLURAL + "/:workOrderId",
          templateUrl:
            "app/desktop/modules/work-order/detail/work-order-detail.html",
          controller: "WorkOrderDetailController",
          controllerAs: "vm",
          resolve: {
            workOrder: getWorkOrder,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.workOrders",
        config: {
          url: "/" + models.WORK_ORDER.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/work-order/organization-list/org-work-order-list.html",
          controller: "OrganizationWorkOrderListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Orders",
          },
          reloadOnSearch: true,
          resolve: {
            status: getStatus,
          },
        },
      },
      {
        state: "app.workOrder",
        config: {
          url: "/" + models.WORK_ORDER.ROUTE_PLURAL + "/:workOrderId",
          templateUrl:
            "app/desktop/modules/work-order/detail/work-order-detail.html",
          controller: "WorkOrderDetailController",
          controllerAs: "vm",
          resolve: {
            workOrder: getOrganizationWorkOrder,
            building: getBuilding,
            redirect: redirect,
          },
        },
      },
    ]);
  }

  /* @ngInject */
  function getOrganizationWorkOrder(
    $state,
    $stateParams,
    account,
    organization,
    WorkOrderService
  ) {
    var workOrderId = $stateParams.workOrderId;
    if (workOrderId === "create") {
      return $state.go("app.workOrders");
    }
    return WorkOrderService.getByOrganization(organization._id, {
      _id: workOrderId,
    }).then(function (workOrder) {
      return workOrder;
    });
  }

  /** @ngInject */
  function getBuilding($stateParams, account, workOrder, BuildingService) {
    return BuildingService.getById(workOrder.building);
  }

  /* @ngInject */
  function getWorkOrder($stateParams, account, building, WorkOrderService) {
    return WorkOrderService.getById(building._id, $stateParams.workOrderId);
  }

  /* @ngInject */
  function redirect(
    $stateParams,
    $state,
    account,
    workOrder,
    RedirectService,
    WorkOrderService
  ) {
    var destination = RedirectService.getDestination();
    var hasLevel = WorkOrderService.getLevel(workOrder);
    var hasRoom = WorkOrderService.getRoom(workOrder);
    var hasAsset = WorkOrderService.getAsset(workOrder);
    if (destination.match(/^.*\/maintenance-schedule$/)) {
      if (angular.isEmpty(workOrder.future_task)) {
        return $state.go("root.404");
      }
    } else if (destination.match(/^.*\/service-request$/)) {
      if (angular.isEmpty(workOrder.request)) {
        return $state.go("root.404");
      }
    } else if (destination.match(/^.*\/floor$/)) {
      if (!hasLevel) {
        return $state.go("root.404");
      }
    } else if (destination.match(/^.*\/room$/)) {
      if (!hasRoom) {
        return $state.go("root.404");
      }
    } else if (destination.match(/^.*\/asset$/)) {
      if (!hasAsset) {
        return $state.go("root.404");
      }
    } else if (
      destination.match(/^\/([a-zA-Z0-9_]*\/){2}tasks\/[a-zA-Z0-9]+$/)
    ) {
      return $state.go("app.building.workOrder.overview", $stateParams);
    } else if (destination.match(/\/tasks\/[a-zA-Z0-9]+$/)) {
      return $state.go("app.workOrder.overview", $stateParams);
    }
  }

  /* @ngInject */
  function getStatus($stateParams, account, WORK_ORDER_STATUS_OPEN) {
    return $stateParams.status || WORK_ORDER_STATUS_OPEN;
  }

  /* @ngInject */
  function getStartDate(account, $stateParams) {
    return $stateParams.start_date;
  }
})();
