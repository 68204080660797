(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedFilterConfiguration",
      ManagedFilterConfigurationClassFactory
    );

  /**
   * @ngInject
   * @return The ManagedFilterConfiguration class
   */
  function ManagedFilterConfigurationClassFactory(
    $log,
    $q,
    $timeout,
    FilterBarManager,
    ToastService,
    Utils
  ) {
    /**
     * @class
     * @implements { FilterConfiguration }
     * @see FilterBarComponent For documentation of the FilterConfiguration
     *  interface
     * @template InputType - The input data type for this filter configuration.
     * @param {FilterBarManager} manager - The manager that owns this configuration.
     * @param {object} config - Configuration options
     *
     * @param {(filterValue, inputModel) => void } applyFilter - Function invoked to actually
     *  apply the filter value to the manager. Will be invoked with the filter
     *  value when this filter is applied. The filter value will be the input value,
     *  or the result of `modelValueToFilterValue` if it is available. The model
     *  will be the raw input model. This function should be a synchronous
     *  function. Must be provided here or as a subclass override.
     *
     * @param {(inputModel?) => void } removeFilter - Function invoked to actually remove
     *  the filter value from the manager. Must be provided here or as a subclass
     *  override.
     *
     * @param {() => filterValue } getFilterValue - Function invoked to read the
     *  filter value from the manager. Must be provided here or as a subclass
     *  override.
     * @param {string} config.displayName - The display name for this filter
     *  option in the dropdown.
     * @param {string} [config.chipName=config.displayName] - The name to display
     *  for this filter in its chip.
     * @param {string} config.inputType - The type of input for this filter
     *  configuration's inputConfiguration. See the `InputConfiguration` type in
     *  `FilterBarComponent`.
     * @param {boolean} [config.allowTextValue=false] - If true, allows text values in typeahead-based
     *  input configurations. Useful for allowing optional selection of a model from a typeahead
     * @param {string} [config.prefix=""] - The prefix to use for this filter's
     *  input. Only applicable to `inputType`s that use a `text` abx-input.
     * @param {string} [config.placeholder=""] - The placeholder for this filter
     *  configuration's input configuration. Used for string type filters.
     * @param {string} [config.utcOffset=""] - The utcOffset (time zone) in which to
     *  interpret nput. Only applicable to `inputType`s that use a `date` or `date-range` abx-input.
     * @param {() => any} [config.afterApply] - Optional function to be invoked
     *  after this filter is applied. May be provided by overriding in subclass
     *  as well.
     * @param {() => any} [config.onInputChange] - Input change event handler
     *  for the filter's input.
     * @param {() => Promise<any>} [config.onAdd] - Override for this filter's
     *  onAdd hook. May return a promise. If it rejects, the filter will not
     *  be added to the list. Rejecting with a falsy value is swallowed, rejections
     *  with a truthy reason will be handled. This method may not `throw`
     *  synchronously.
     * @param {() => any} [config.afterRemove] - Optional function to be invoked
     *  after this filter is cleared. May be provided by overriding in subclass
     *  as well.
     * @param {() => any} [config.onAfterGetFilterValue] -Optional function to peform filtering over many pin fields from different pin types.
     * May be provided by overriding in subclass as well.
     * @param {() => Array | Promise<Array>} getEnumOptions - A function that returns
     *  an array of typeahead options. Required for "typeahead" type input configuration.
     *  See `abxInput`'s `options` binding for details on what this should return.
     *  May return a promise. May be provided by overriding in subclass as well.
     * @param {(modelA, modelB) => boolean} [modelsAreEqual] - Function that is
     *  used to determine the new input model when enum options are fetched
     *  while the typeahead is not empty. Defaults to a deep equality comparison.
     *  Example: `(a, b) => a && b && (a._id === b._id);`
     * @param {(InputType) => string} [config.modelValueToFilterValue] -
     *  Required for inputType="typeahead". This function should convert a model
     *  sent up from the input into a value that can be added to the manager's filters.
     *  May be provided by overriding in subclass as well.
     *  Example: `(model) => model._id`
     * @param {(string) => InputType | Promise<InputType>} [config.filterValueToModelValue] -
     *  Required for inputType="typeahead". This function should convert a string
     *  value stored in the querystring into a matching `InputType` input value.
     *  May be provided by overriding in subclass as well.
     *  Example: `(modelId) => ModelService.get(modelId)`
     * @param {(InputType) => string} [config.modelValueToChipText] - Required for
     *  inputType="typeahead". This function should convert a model sent up from
     *  the input into a string that can be set as the "right" text of a chip.
     *  May be provided by overriding in subclass as well.
     *  Example: `(model) => model.name`
     *  @param {(Error) => void} [config.onAddFilterError] - optional error
     *  handler during filter addition
     *  @param {(Error) => void} [config.onApplyFilterError] - optional error
     *  handler during filter application
     *  @param {boolean} [config.isMultiChip] - optional flag that
     *  allows users to create multiple filters of the same type
     */
    function ManagedFilterConfiguration(manager, config) {
      var isFunction = function (fn) {
        return typeof fn === "function";
      };

      if (!manager || !(manager instanceof FilterBarManager)) {
        throw new Error(
          "ManagedFilterConfiguration: manager must be a FilterBarManager"
        );
      }
      if (!config) {
        throw new Error("ManagedFilterConfiguration: No config provided");
      }
      if (
        typeof config.displayName !== "string" ||
        !config.displayName.length
      ) {
        throw new Error(
          "ManagedFilterConfiguration: displayName must be a string"
        );
      }
      if (!isFunction(config.applyFilter || this.applyFilter)) {
        throw new Error(
          "ManagedFilterConfiguration: applyFilter must be provided"
        );
      }
      if (!isFunction(config.removeFilter || this.removeFilter)) {
        throw new Error(
          "ManagedFilterConfiguration: removeFilter must be provided"
        );
      }
      if (!isFunction(config.getFilterValue || this.getFilterValue)) {
        throw new Error(
          "ManagedFilterConfiguration: getFilterValue must be provided"
        );
      }
      if (!isFunction(config.onAdd || this.onAdd)) {
        throw new Error("ManagedFilterConfiguration: onAdd must be provided");
      }

      this.isMultiChip = Boolean(config.isMultiChip);

      /**
       * @member
       * @type { Array<FilterBarChip> | [] }
       * @see FilterBarComponent For documentation of the FilterBarChip interface
       * The series chips applied by this filter, or an empty list if this filter is not
       * currently applied.
       */
      this.filterTypeChips = [];

      /**
       * @member
       * @type { FilterBarManager }
       * The filter bar manager instance that this filter configuration should
       * be applied to when activated.
       */
      this.manager = manager;

      /**
       * @member
       * @type { string }
       * The display name for this filter.
       */
      this.displayName = config.displayName;

      /**
       * @member
       * @type { string }
       * The left-side text for chips created by this filter. Defaults to match
       * the display name.
       */
      this.chipName = config.chipName || this.displayName;

      /**
       * @member
       * @type { InputConfiguration }
       * @see FilterBarComponent For documentation of the InputConfiguration interface.
       */
      this.inputConfiguration = {
        type: config.inputType || "string",
        placeholder: config.placeholder || "",
        prefix: config.prefix || "",
        utcOffset: config.utcOffset || "",
        optionsGroupKey: config.optionsGroupKey || null,
      };
      if (this.inputConfiguration.type === "typeahead-dynamic") {
        this.inputConfiguration.getEnumOptions =
          this.loadTypeAheadEnumOptions.bind(this);
        this.inputConfiguration.allowTextValue = config.allowTextValue || false;
      }

      // Typeaheads must have getEnumOptions, modelValueToFilterValue,
      // filterValueToModelValue, modelValueToChipText to function properly.
      // These methods may be passed in as configuration options, or
      // implemented in a subclass directly.
      if (
        this.inputConfiguration.type === "typeahead" ||
        this.inputConfiguration.type === "typeahead-dynamic"
      ) {
        if (!isFunction(config.getEnumOptions || this.getEnumOptions)) {
          throw new Error(
            "Cannot instantiate typeahead without getEnumOptions"
          );
        }
        if (
          !isFunction(
            config.modelValueToFilterValue || this.modelValueToFilterValue
          )
        ) {
          throw new Error(
            "Cannot instantiate typeahead without modelValueToFilterValue"
          );
        }
        if (
          !isFunction(
            config.filterValueToModelValue || this.filterValueToModelValue
          )
        ) {
          throw new Error(
            "Cannot instantiate typeahead without filterValueToModelValue"
          );
        }
        if (
          !isFunction(config.modelValueToChipText || this.modelValueToChipText)
        ) {
          throw new Error(
            "Cannot instantiate typeahead without modelValueToChipText"
          );
        }
      }

      var applyOverride = function (key) {
        if (config[key]) {
          this[key] = config[key];
        }
      };
      applyOverride = applyOverride.bind(this);

      applyOverride("getEnumOptions");
      applyOverride("modelsAreEqual");
      applyOverride("modelValueToFilterValue");
      applyOverride("filterValueToModelValue");
      applyOverride("modelValueToChipText");
      applyOverride("afterApply");
      applyOverride("afterRemove");
      applyOverride("onInputChange");
      applyOverride("onAdd");
      /**
       * @member applyFilter
       * @type {(filterValue) => void}
       */
      applyOverride("applyFilter");
      /**
       * @member removeFilter
       * @type {() => void}
       */
      applyOverride("removeFilter");
      /**
       * @member getFilterValue
       * @type {() => filterValue }
       */
      applyOverride("getFilterValue");
      applyOverride("onAfterGetFilterValue");

      applyOverride("onAddFilterError");
      applyOverride("onApplyFilterError");

      this._decorateOnRemove();
      this._decorateOnSelect();
    }

    ManagedFilterConfiguration.prototype.onInputChange = function (event) {
      var model;
      if (
        this.inputConfiguration.type === "date-range" ||
        this.inputConfiguration.type === "number-range" ||
        this.inputConfiguration.type === "float-range"
      ) {
        model = event.newValue;
      } else {
        model = event.model;
      }

      if (
        this.inputConfiguration.type === "typeahead" ||
        this.inputConfiguration.type === "typeahead-dynamic"
      ) {
        this.inputConfiguration.value = event.value;
      }

      this.inputConfiguration.model = model;
    };

    /**
     * @protected
     * Remove all filters and chips applied by this configuration WITHOUT
     * notifying of updates.
     */
    ManagedFilterConfiguration.prototype.clearAll = function () {
      this.filterTypeChips.forEach(function (chip) {
        chip._clearWithoutNotifying();
      });
    };

    /**
     * Apply this filter. Removes the filter (and its chip) if it is already
     * applied, defers to `applyFilter` for actual filter application logic. Adds
     * a chip to the manager and saves it as the last chip.
     * @return {Promise<{}>}
     */
    ManagedFilterConfiguration.prototype.onApply = function (inputValue) {
      var self = this;
      /** @return { boolean } true iff `fn` is a function */
      var isFunction = function (fn) {
        return typeof fn === "function";
      };

      if (this.filterTypeChips.length && !this.isMultiChip) {
        this.clearAll();
      }

      var filterValue;
      var chipText;

      if (isFunction(this.modelValueToFilterValue)) {
        filterValue = this.modelValueToFilterValue(inputValue);
      } else {
        filterValue = inputValue;
      }
      if (isFunction(this.modelValueToChipText)) {
        chipText = this.modelValueToChipText(inputValue);
      } else {
        chipText = inputValue;
      }

      // Check for duplicates
      var duplicateChip = this.filterTypeChips.find(function (_chip) {
        return _chip.right === chipText;
      });

      if (duplicateChip) {
        duplicateChip._clearWithoutNotifying();
      }

      // actually add the filter to the manager's query
      return $q
        .resolve(this.applyFilter(filterValue, inputValue))
        .then(function () {
          var chip = self.getChip(chipText, inputValue);
          // Keep track of chips for this filter type
          self.filterTypeChips.push(chip);
          // Send the chip to filter manager
          self.manager.addChip(chip);
          if (self.afterApply) {
            $timeout(function () {
              self.afterApply();
            });
          }
        })
        .catch(function (err) {
          $log.error(err.stack);
          self.onApplyFilterError(err);
        });
    };

    ManagedFilterConfiguration.prototype.getChip = function (
      right,
      inputModel
    ) {
      var self = this;
      var chip = { left: this.chipName, right: right };

      chip.onClick = function () {
        self.removeFilter(inputModel);
        if (self.filterTypeChips.length) {
          self.manager.removeChip(chip);
        }

        self.filterTypeChips = self.filterTypeChips.filter(function (_chip) {
          return _chip !== chip;
        });

        if (self.afterRemove) {
          $timeout(function () {
            self.afterRemove();
          });
        }
      };

      chip._clearWithoutNotifying = chip.onClick;

      return chip;
    };

    /**
     * Binds `this.onRemove` explicitly to `this` so it can be used context-free.
     */
    ManagedFilterConfiguration.prototype._decorateOnRemove = function () {
      this.onRemove = this.onRemove.bind(this);
    };

    /**
     * Remove this filter, and invoke the afterRemove callback if there is one.
     * Removes all of the applied filters
     */
    ManagedFilterConfiguration.prototype.onRemove = function () {
      var self = this;

      this.clearAll();

      if (this.filterTypeChips.length) {
        this.manager.removeChips(this.filterTypeChips);
      }

      this.filterTypeChips = [];
      if (this.afterRemove) {
        $timeout(function () {
          self.afterRemove();
        });
      }
    };

    /**
     * Fetch the original input value that led to this filter updating
     * the manager's query with its current data. Depends on
     * `filterValueToModelValue` to do any requisite fetching of models.
     */
    ManagedFilterConfiguration.prototype.getInputValue = function (manager) {
      var self = this;
      return (
        $q
          .resolve(this.getFilterValue(manager))
          // We need this addtional hook to peform filtering over many pin fields from different pin types
          .then(function (filterValue) {
            if (typeof self.onAfterGetFilterValue === "function") {
              return self.onAfterGetFilterValue(filterValue);
            } else {
              return filterValue;
            }
          })
          .then(function (filterValue) {
            // bail before accidentally passing undefined to filterValueToModelValue
            if (filterValue === undefined) {
              return [];
            }
            if (typeof self.filterValueToModelValue === "function") {
              return self.filterValueToModelValue(filterValue);
            }
            return [filterValue];
          })
      );
    };

    /**
     * @private
     * Decorate `this.onSelect` to report errors to `onSelectFilterError`
     */
    ManagedFilterConfiguration.prototype._decorateOnSelect = function () {
      var onSelect = this.onSelect;
      this.onSelect = function (e) {
        var self = this;
        return $q.resolve(onSelect.call(this, e)).catch(function (err) {
          self.onSelectFilterError(err);
          return $q.reject(err);
        });
      };
    };

    /**
     * Load typeahead options and clear stale input on select.
     */
    ManagedFilterConfiguration.prototype.onSelect = function () {
      this.inputConfiguration.model = undefined;
      this.inputConfiguration.value = undefined;
      if (
        this.inputConfiguration.type === "typeahead" ||
        this.inputConfiguration.type === "typeahead-dynamic"
      ) {
        return this._loadEnumOptions();
      }
    };

    /**
     * @return { boolean } True iff this filter configuration is currently
     *  the selected configuration for the manager.
     */
    ManagedFilterConfiguration.prototype.isSelected = function () {
      return this.manager.currentFilterConfiguration === this;
    };

    /**
     * Invalidate the enum options for this input configuration. If it is the
     * currently active configuration, enum options will be refetched
     * immediately.
     */
    ManagedFilterConfiguration.prototype.invalidateEnumOptions = function () {
      var self = this;
      var currentModel = this.inputConfiguration.model;
      this.inputConfiguration.enumOptions = undefined;
      if (this.isSelected()) {
        return $q.resolve(this._loadEnumOptions()).then(function () {
          var enumOptions = self.inputConfiguration.enumOptions;
          var hasSameModel = function (enumOption) {
            return self.modelsAreEqual(currentModel, enumOption.model);
          };
          var newOption = enumOptions.find(hasSameModel) || {
            model: undefined,
            value: "",
          };
          self.inputConfiguration.model = newOption.model;
          self.inputConfiguration.value = newOption.value;
        });
      }
    };

    /**
     * @protected
     * Load enum options and update the input configuration with them. Sets
     * the loading state for the input configuration while awaiting enum
     * options.
     * @return {Promise<void>}
     */
    ManagedFilterConfiguration.prototype._loadEnumOptions = function () {
      var self = this;
      self.inputConfiguration.isLoading = true;
      return $q
        .resolve(this.getEnumOptions())
        .then(function (enumOptions) {
          self.inputConfiguration.enumOptions = enumOptions;
          self.inputConfiguration.isLoading = false;
        })
        .catch(function (err) {
          self.inputConfiguration.isLoading = false;
          return $q.reject(err);
        });
    };

    /**
     * Used to load and validate enumOptions for typeaheads on an onChange
     * event
     * If typed input matches an option, then we supply the model to inputConfiguration so
     * that we can apply the filter
     *
     * @param {*} event
     * @param {string} event.value
     * @param {boolean} [event.noLimit] - Passing through `noLimit === true` will cause getEnumOptions to get all available, with no limit
     */
    ManagedFilterConfiguration.prototype.loadTypeAheadEnumOptions = function (
      event
    ) {
      var self = this;
      return $q
        .resolve(
          this.getEnumOptions({ name: event.value, noLimit: event.noLimit })
        )
        .then(function (enumOptions) {
          self.inputConfiguration.enumOptions = enumOptions;

          //Think about using on 'OnOptionsChange' lifecycle event through 'type-ahead.component' instead
          //to see if what we've typed is in our enumOptions
          function findOptionByValue(value) {
            if (!enumOptions) return;
            return Utils.find(enumOptions, function (option) {
              return option.value === value;
            });
          }

          var currentOption = findOptionByValue(event.value);
          if (currentOption) {
            var currentModel = currentOption.model;

            var hasSameModel = function (enumOption) {
              return self.modelsAreEqual(currentModel, enumOption.model);
            };
            var newOption = enumOptions.find(hasSameModel) || {
              model: undefined,
              value: "",
            };
            self.inputConfiguration.model = newOption.model;
            self.inputConfiguration.value = newOption.value;
          }
        })
        .catch(function (err) {
          return $q.reject(err);
        });
    };

    /**
     * Runs a one-time bit of asynchronous code prior to adding
     * a manager's internal array. Is added on resolution. Is discarded
     * on rejection, logging any error to console.
     * @return {Promise}
     */
    ManagedFilterConfiguration.prototype.onAdd = function () {
      return $q.resolve(true);
    };

    /**
     * Shows a toast message when adding a new filter errors
     * @param {Error} err
     */
    ManagedFilterConfiguration.prototype.onAddFilterError = function (err) {
      if (err) {
        ToastService.showSimple(
          "Error adding " + this.displayName + " to the list of filters"
        );
      }
    };

    /**
     * Shows a toast message when applying a new filter value errors
     * @param {Error} err
     */
    ManagedFilterConfiguration.prototype.onApplyFilterError = function (err) {
      ToastService.showSimple(
        "Error applying the " + this.displayName + " filter to this list."
      );
    };

    /**
     * Callback invoked when `onSelect` rejects.
     */
    ManagedFilterConfiguration.prototype.onSelectFilterError = function (err) {
      var msg = "Error selecting the " + this.displayName + " filter.";
      $log.error(msg);
      if (err && err instanceof Error) {
        $log.error(err.toString());
        $log.error(err.stack);
      } else {
        $log.error(err);
      }
      ToastService.showSimple(msg);
    };

    /**
     * Default implementation of modelsAreEqual is a deep comparison.
     */
    ManagedFilterConfiguration.prototype.modelsAreEqual = angular.equals;

    return ManagedFilterConfiguration;
  }
})();
