(function () {
  angular.module("akitabox.desktop.request", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.desktop.request.detail",
    "akitabox.desktop.request.list",
    "akitabox.desktop.request.organizationList",
  ]);
})();
