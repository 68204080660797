(function () {
  /**
   * @ngdoc component
   * @name abxPinTypeFilterSidebar
   *
   * @param {Object} floor - Currently displayed floor
   * @param {Boolean} isShown - Sidebar visibility
   * @param {Function} onPinTypeFilterChange - To be invoked when a pin type
   *     filter is activated, deactivated, or modified
   * @param {Function} onPinTypeSubfilterClick - To be invoked when a pin type
   *     filter item's subfilter button is clicked. Should be invoked with:
   *     event.pinTypeId.
   * @param {Number} page - Page of the floor plan to view
   * @param {Object} pinTypeFilters - Filters for shown pins based on pin type.
   *     Should be a mapping of pin type IDs to their filter objects.
   * @param {Object[]} pinTypes - Pin types for selected building
   * @param {Function} toggle - Toggle sidebar
   * @param {Boolean} disabled - Disable sidebar
   *
   * @description
   * PlanView sidebar that displays pin types and allows the user to filter
   * by them.
   */
  angular.module("akitabox.planView").component("abxPinTypeFilterSidebar", {
    bindings: {
      floor: "<abxFloor",
      isShown: "<abxIsShown",
      onPinTypeFilterChange: "&abxOnPinTypeFilterChange",
      onPinTypeSubfilterClick: "&abxOnPinTypeSubfilterClick",
      page: "<abxPage",
      pinTypeFilters: "<abxPinTypeFilters",
      pinTypes: "<abxPinTypes",
      toggle: "&abxToggleSidebar",
      disabled: "<?abxDisabled",
    },
    controller: AbxSidebarController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/pin-type-filter-sidebar/pin-type-filter-sidebar.component.html",
  });

  function AbxSidebarController($scope, EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED) {
    var self = this;

    self.checkStatus = getStatus;
    self.onToggleAll = onToggleAll;
    self.$onChanges = onChange;
    self.status = null;

    self.toggleAll = angular.debounce(toggleAll, 750);

    $scope.$on(EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED, function () {
      self.status = getStatus();
    });

    function onChange(changes) {
      if (self.pinTypes && changes.pinTypes) {
        self.status = getStatus();
      }
      if (self.pinTypeFilters && changes.pinTypeFilters) {
        self.status = getStatus();
      }
    }

    function getStatus() {
      // Pin type Ids that are selected
      var selectedPinTypeIds = Object.keys(self.pinTypeFilters);

      // Pin type objects that are available to place on the plan
      var pinTypesWithPins =
        self.pinTypes &&
        self.pinTypes.filter(function (pinType) {
          return pinType.pins_count > 0;
        });

      // Pin type Ids that are selected and available to place on the plan
      var selectedPinTypeIdsWithPins = selectedPinTypeIds.filter(function (
        selectedFilter
      ) {
        for (var i = 0; i < pinTypesWithPins.length; i++) {
          if (selectedFilter === pinTypesWithPins[i]._id) {
            return true;
          }
        }
        return false;
      });

      // If there are no pin types with pins or selected pin type ids with pins, return null
      if (
        pinTypesWithPins &&
        selectedPinTypeIdsWithPins.length > 0 &&
        pinTypesWithPins.length > 0
      ) {
        // The length of the selected pin types is equal to the total number of pin types available to place on the plan
        if (selectedPinTypeIdsWithPins.length === pinTypesWithPins.length) {
          return "all";
        }

        // The length of the selected pin types is less than the total number of pin types available to place on the plan
        if (selectedPinTypeIdsWithPins.length < pinTypesWithPins.length) {
          return "indeterminate";
        }
      }

      return null;
    }

    function onToggleAll() {
      var clearingFilters = self.status === "all";
      if (clearingFilters) {
        self.status = null;
      }
      if (!clearingFilters) {
        self.status = "all";
      }

      self.toggleAll(clearingFilters);
    }

    function toggleAll(clearingFilters) {
      for (var i = 0; i < self.pinTypes.length; i++) {
        var currentPinType = self.pinTypes[i];
        if (currentPinType.pins_count > 0) {
          var event = {
            filter: null,
            pinType: currentPinType,
          };
          if (!clearingFilters) {
            event.filter = { _id: currentPinType._id };
          }
          self.onPinTypeFilterChange({
            $event: event,
          });
        }
      }
    }
  }
})();
