(function () {
  angular.module("akitabox.core.srpRedirect", [
    "ui.router",
    "akitabox.core.services.building",
    "akitabox.core.services.env",
    "akitabox.core.services.http",
    "akitabox.core.services.organization",
    "akitabox.core.services.redirect",
    "akitabox.core.utils",
  ]);
})();
