import { Plugin } from "@ckeditor/ckeditor5-core";
import { Element } from "@ckeditor/ckeditor5-engine";

export class MentionCustomization extends Plugin {
  init() {
    const editor = this.editor;

    // The upcast converter will convert view <a class="mention" href="" data-user-id="">
    // elements to the model 'mention' text attribute.
    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "a",
        key: "data-mention",
        classes: "mention",
        attributes: {
          "data-field": true,
        },
      },
      model: {
        key: "mention",
        value: (viewItem: Element) => {
          // The mention feature expects that the mention attribute value
          // in the model is a plain object with a set of additional attributes.
          // In order to create a proper object use the toMentionAttribute() helper method:
          const mentionAttribute = editor.plugins
            .get("Mention")
            .toMentionAttribute(viewItem, {
              // Add any other properties that you need.
              field: viewItem.getAttribute("data-field"),
            });

          return mentionAttribute;
        },
      },
      converterPriority: "high",
    });

    // Downcast the model 'mention' text attribute to a view <a> element.
    editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue, { writer }) => {
        // Do not convert empty attributes (lack of value means no mention).
        if (!modelAttributeValue) {
          return;
        }

        return writer.createAttributeElement(
          "a",
          {
            class: "mention",
            "data-mention": modelAttributeValue.id,
            "data-field": modelAttributeValue.field,
          },
          {
            // Make mention attribute to be wrapped by other attribute elements.
            priority: 20,
            // Prevent merging mentions together.
            id: modelAttributeValue.uid,
          }
        );
      },
      converterPriority: "high",
    });
  }
}
