(function () {
  angular
    .module("akitabox.ui.dialogs.job.viewStepLog")
    .service("ViewStepLogDialog", ViewStepLogDialog);

  /* @ngInject */
  function ViewStepLogDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/job/view-step-log/view-step-log.dialog.html",
      controller: "ViewStepLogDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * ViewStepLogDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.step` is required
     *
     * @param  {Object}                 options                 Dialog options
     * @return {Promise<Object|Error}                           Promise that resolves
     */
    function show(options) {
      // Verify we have a building local
      if (!options || !options.locals || !options.locals.step) {
        $log.error("ViewStepLogDialog: no step local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
