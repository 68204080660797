(function () {
  angular.module("akitabox.core.services.recentActivity", [
    "akitabox.constants",
    "akitabox.core.services.attachment",
    "akitabox.core.services.note",
    "akitabox.core.services.organization",
    "akitabox.core.services.userAction",
    "akitabox.core.services.workOrder",
  ]);
})();
