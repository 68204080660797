import { AttachmentLinkEnum, DocumentResponse } from "@akitabox/api-client";
import { Subject } from "../../../util/Subject";
export interface AttachmentDialogOpenOptions {
  /** Organization id where the attachment dialog is */
  organization: string;
  /** Building id where the attachment dialog may be */
  building?: string;
  /**
   * The type of entity attachments should be created for.
   */
  entityType: AttachmentLinkEnum;
  /**
   * Array of ids for all entities it'll attach and/or detach documents to.
   * Undefined when we do not have any entity yet (creation flow).
   * If so, handles creating (attach) attachments _after_ dialog is finished
   */
  entityIds?: string[];
  imagesOnly?: boolean;
}

export interface AttachmentDialogCloseData {
  /**
   * Array of uploaded files & their related abx documents
   * that were created during the preceeding invocation of the dialog.
   */
  uploads: AttachmentDialogUpload[];
}

export interface AttachmentDialogUpload {
  /**
   * The abx document created when the file was uploaded
   */
  document: DocumentResponse;
  /**
   * The actual file that was uploaded
   */
  file?: File;
}

export const attachmentDialogOpened =
  new Subject<AttachmentDialogOpenOptions>();

export const attachmentDialogClosed = new Subject<AttachmentDialogCloseData>();
