/**
 * Get the root domain for a given hostname.
 * @param hostName A host name, usually `window.location.hostname`
 * @returns The domain & TLD from the provided hostname.
 * @note Do not pass a full url to this function, it does not handle parsing
 *  urls. Only pass the host name portion. See {@link URL} constructor for parsing
 *  URLs.
 * @example Get the current domain
 * ```
 * getDomain(window.location.hostName); // "beta-abx.com" for example
 * ```
 */
export function getDomain(hostName: string = window.location.hostname) {
  return hostName.split(".").slice(-2).join(".");
}
