(function () {
  angular
    .module("akitabox.ui.dialogs.document.download")
    .controller(
      "DownloadDocumentDialogController",
      DownloadDocumentDialogController
    );

  function DownloadDocumentDialogController(
    $q,
    $mdDialog,
    DocumentService,
    ToastService
  ) {
    var self = this;
    self.canceling = false; // Whether the user has canceled the download
    self.progress = 0; // Amount of progress for the progress bar
    self.multiple = self.docs ? self.docs.length > 1 : false;
    self.failedFiles = []; // Files that fail to download get pushed into this array
    self.loading = true; // Used to modify the view depending on whether the download has finished
    self.retryDownload = retryDownload;

    self.cancel = cancel; // Cancel stops the download from continuing
    self.close = $mdDialog.hide; // Closes the window after downloading has stopped

    init();

    function init() {
      if (angular.isEmpty(self.docs)) return;
      downloadDocuments(self.docs);
    }

    // Takes the array of files that failed to download and re-calls download on them
    function retryDownload() {
      self.loading = true;
      self.progress = 0;
      downloadDocuments(self.failedFiles);
    }

    function downloadDocuments(docs) {
      self.failedFiles = []; // Resets failed files just in case this is coming from retryDownload
      var progressIncrement = 100 / docs.length; // Calculates how much to increment progress bar
      var sequence = $q.resolve();
      // Recursively chains promises for array of documents to download
      angular.forEach(docs, function (document) {
        sequence = sequence.then(function () {
          if (self.canceling) return $q.reject(); // Checks if download has been canceled
          self.progress += progressIncrement; // Increments progress bar even if file fails to download

          return DocumentService.downloadFromPurl(document).catch(function (
            error
          ) {
            // If file failed, push to array of failed files and show Toaster error on screen (throttled)
            self.failedFiles.push(
              document.document_image ? document.document_image : document
            );
            ToastService.showError(error, true);
          });
        });
      });
      sequence
        .then(function () {
          // After downloading, set self.loading to false if there were were failed files or hide dialog if
          // all files downloaded successfully
          if (self.failedFiles.length) {
            self.loading = false;
          } else {
            $mdDialog.hide();
          }
        })
        .catch($mdDialog.cancel);
    }

    function cancel() {
      self.canceling = true;
    }
  }
})();
