(function () {
  angular.module("akitabox.ui.dialogs.document", [
    "akitabox.ui.dialogs.document.archive",
    "akitabox.ui.dialogs.document.delete",
    "akitabox.ui.dialogs.document.editDocumentArray",
    "akitabox.ui.dialogs.document.download",
    "akitabox.ui.dialogs.document.editTagFilter",
    "akitabox.ui.dialogs.document.restore",
    "akitabox.ui.dialogs.document.revert",
    "akitabox.ui.dialogs.document.select",
    "akitabox.ui.dialogs.document.selectBuilding",
    "akitabox.ui.dialogs.document.tag",
    "akitabox.ui.dialogs.document.thumbnailFit",
    "akitabox.ui.dialogs.document.upload",
  ]);
})();
