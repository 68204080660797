import {
  Button,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, FormEvent, FunctionComponent, useState } from "react";
import { Color } from "../../colors";
import { stylesheet } from "../../stylesheet";
import { theme } from "../../WebClientContexts";
import { AttachmentDialogTestids } from "../AttachmentDialog";
import { TagResponse, TagResponseValue } from "@akitabox/api-client";

/**
 * Enum for the kinds of filters available in the filter bar
 */
export enum FilterKind {
  NAME = "name",
  EXTENSION = "extension",
  TAG_CATEGORY = "tag_category",
}

/**
 * Type for currently applied filters. Filters that are not active
 * will have a value of empty string ("") or empty array as appropriate.
 */
export interface ActiveFilters
  extends Record<Exclude<FilterKind, FilterKind.TAG_CATEGORY>, string> {
  [FilterKind.TAG_CATEGORY]: {
    tagCategory: TagResponse;
    tagCategoryValue: TagResponseValue;
  }[];
}

export interface FilterBarProps {
  /**
   * Invoked when a filter is added or removed
   * @param filters The new active filters
   */
  onFilterChange: (filters: ActiveFilters) => void;
  /**
   * Currently active filters, controls chips.
   */
  activeFilters: ActiveFilters;
  /**
   * Tag categories for filter options
   */
  tagCategories: TagResponse[];
}

/**
 * Type guard for narrowing strings to {@link FilterKind}s
 * @param value
 * @returns true if the provided value is a `FilterKind`
 */
const isFilterKind = (value: unknown): value is FilterKind => {
  return value === FilterKind.NAME || value === FilterKind.EXTENSION;
};

/**
 * Generate a user-friendly display name for a {@link FilterKind}
 * @param filterKind
 * @returns A human-readable name for the specified filter kind appropriate
 *  for use in the UI.
 */
const filterKindToString = (filterKind: FilterKind, tagCategory?: string) => {
  return {
    [FilterKind.EXTENSION]: "File Type",
    [FilterKind.NAME]: "Name",
    [FilterKind.TAG_CATEGORY]: tagCategory,
  }[filterKind];
};

const filterValueToString = (filterValue: string) => {
  if (filterValue === allImagesFilter) {
    return "Image";
  }
  return filterValue;
};

/**
 * Component to allow a user to filter the `AttachmentDialog` document list.
 * Simplified version of what's available in listview.
 */
export const FilterBar: FunctionComponent<FilterBarProps> = ({
  onFilterChange,
  activeFilters,
  tagCategories,
}) => {
  /**
   * Currently selected filter type from the filter type dropdown
   */
  const [selectedFilterType, setSelectedFilterType] = useState<FilterKind>(
    FilterKind.NAME
  );
  /**
   * Current filter value (either from the extension dropdown or name input)
   */
  const [selectedFilterValue, setSelectedFilterValue] = useState<string>("");

  const [selectedTagCategory, setSelectedTagCategory] = useState<TagResponse>();

  /**
   * Event handler for changes to the filter type dropdown.
   * @param event
   */
  const handleFilterTypeChange = (
    event: SelectChangeEvent<FilterKind | string>
  ) => {
    if (isFilterKind(event.target.value)) {
      setSelectedFilterType(event.target.value);
      setSelectedTagCategory(undefined);
      setSelectedFilterValue("");
    } else {
      setSelectedFilterType(FilterKind.TAG_CATEGORY);
      setSelectedTagCategory(
        tagCategories.find((tc) => tc._id === event.target.value)
      );
    }
  };

  /**
   * Event handler for changes to the filter value input
   * @param event
   */
  const handleFilterValueChange = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedFilterValue(event.target.value);
  };

  /**
   * Event handler for clicking the `Filter` button
   * @param event
   */
  const handleFilterApply = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFilterType === FilterKind.TAG_CATEGORY) {
      if (!selectedTagCategory) {
        throw new Error("selectedTagCategory cannot be undefined");
      }
      const tagCategoryValue = selectedTagCategory.values.find(
        (v) => v._id === selectedFilterValue
      );
      if (tagCategoryValue) {
        onFilterChange({
          ...activeFilters,
          [FilterKind.TAG_CATEGORY]: [
            ...activeFilters[FilterKind.TAG_CATEGORY].filter(
              (filter) => filter.tagCategory._id !== selectedTagCategory._id
            ),
            {
              tagCategory: selectedTagCategory,
              tagCategoryValue: tagCategoryValue,
            },
          ],
        });
      }

      setSelectedFilterValue("");
    } else {
      const newActiveFilters = {
        ...activeFilters,
        [selectedFilterType]: selectedFilterValue,
      };
      onFilterChange(newActiveFilters);
      setSelectedFilterValue("");
    }
  };

  type HandleFilterRemove = {
    (key: Exclude<FilterKind, FilterKind.TAG_CATEGORY>): void;
    (key: FilterKind.TAG_CATEGORY, tagCategory: TagResponse): void;
  };

  /**
   * Event handler for clicks on filter chip remove buttons
   * @param key The filter kind to remove
   */
  const handleFilterRemove: HandleFilterRemove = (
    key: FilterKind,
    tagCategory?: TagResponse
  ) => {
    const newActiveFilters = { ...activeFilters };
    if (key === FilterKind.TAG_CATEGORY) {
      if (!tagCategory) {
        throw new Error("Missing tag category on filter remove");
      }
      const existingTagCategoryFilters = activeFilters[FilterKind.TAG_CATEGORY];
      newActiveFilters[FilterKind.TAG_CATEGORY] =
        existingTagCategoryFilters.filter(
          (curFilter) => curFilter.tagCategory._id !== tagCategory._id
        );
    } else {
      newActiveFilters[key] = "";
    }
    onFilterChange(newActiveFilters);
  };

  /**
   * Event handler for clicks on the `Clear All` button
   */
  const handleClearAll = () => {
    onFilterChange({
      [FilterKind.NAME]: "",
      [FilterKind.EXTENSION]: "",
      [FilterKind.TAG_CATEGORY]: [],
    });
  };

  const renderFilterInput = (
    filterKind: FilterKind,
    tagCategory?: TagResponse
  ) => {
    const filterInput = {
      [FilterKind.EXTENSION]: (
        <Select
          inputProps={{
            "data-testid": AttachmentDialogTestids.FilterInput,
          }}
          label=""
          onChange={handleFilterValueChange}
          value={selectedFilterValue}
          css={ss.filterInput}
          size="small"
        >
          {supportedExtensions.map((extension) => (
            <MenuItem key={extension} value={extension}>
              {filterValueToString(extension)}
            </MenuItem>
          ))}
        </Select>
      ),
      [FilterKind.NAME]: (
        <TextField
          inputProps={{
            "data-testid": AttachmentDialogTestids.FilterInput,
          }}
          variant="outlined"
          onChange={handleFilterValueChange}
          value={selectedFilterValue}
          css={ss.filterInput}
          size="small"
        />
      ),
    };
    if (filterKind === FilterKind.EXTENSION || filterKind === FilterKind.NAME) {
      return filterInput[filterKind];
    } else {
      return (
        <>
          <Select
            inputProps={{
              "data-testid": AttachmentDialogTestids.FilterInput,
            }}
            label=""
            onChange={handleFilterValueChange}
            value={selectedFilterValue}
            css={ss.filterInput}
            size="small"
          >
            {tagCategory?.values.map((tcv) => (
              <MenuItem key={tcv._id} value={tcv._id}>
                {tcv.value_alias}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    }
  };

  return (
    <div css={ss.root}>
      <form css={ss.form} onSubmit={handleFilterApply}>
        <InputLabel id="select-label" css={ss.sectionLabel}>
          Filter by
        </InputLabel>
        <Stack direction="row">
          <Select
            inputProps={{
              "data-testid": AttachmentDialogTestids.FilterSelect,
            }}
            labelId="select-label"
            onChange={handleFilterTypeChange}
            value={
              selectedFilterType === FilterKind.TAG_CATEGORY
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  selectedTagCategory!._id
                : selectedFilterType
            }
            css={ss.filterTypeSelect}
            size="small"
          >
            <MenuItem value={FilterKind.NAME}>Name</MenuItem>
            <MenuItem value={FilterKind.EXTENSION}>File Type</MenuItem>
            {tagCategories.map((tc) => (
              <MenuItem key={tc._id} value={tc._id}>
                {tc.cat_alias}
              </MenuItem>
            ))}
          </Select>
          {renderFilterInput(selectedFilterType, selectedTagCategory)}
          <Button
            data-testid={AttachmentDialogTestids.FilterButton}
            disabled={selectedFilterValue === ""}
            variant="contained"
            type="submit"
          >
            FILTER
          </Button>
        </Stack>
      </form>
      <Stack direction="row" marginTop={theme.spacing(1)}>
        {Object.keys(activeFilters).map((filterKey) => {
          const key = filterKey as FilterKind;
          if (activeFilters[key as FilterKind] === "") return "";
          if (key === FilterKind.TAG_CATEGORY) {
            return activeFilters[FilterKind.TAG_CATEGORY].map(
              ({ tagCategory, tagCategoryValue }) => (
                <Chip
                  key={tagCategory._id}
                  label={`${tagCategory.cat_alias}: ${tagCategoryValue.value_alias}`}
                  onDelete={() => {
                    handleFilterRemove(FilterKind.TAG_CATEGORY, tagCategory);
                  }}
                  data-testid={AttachmentDialogTestids.FilterChip(
                    `${key}-${tagCategory.cat_name}`
                  )}
                  variant="outlined"
                  css={ss.chip}
                ></Chip>
              )
            );
          } else {
            return (
              <Chip
                key={key}
                label={`${filterKindToString(key as FilterKind)}: ${
                  activeFilters[key as FilterKind]
                }`}
                onDelete={() =>
                  key === FilterKind.NAME
                    ? handleFilterRemove(FilterKind.NAME)
                    : handleFilterRemove(FilterKind.EXTENSION)
                }
                data-testid={AttachmentDialogTestids.FilterChip(
                  key as FilterKind
                )}
                variant="outlined"
                css={ss.chip}
              />
            );
          }
        })}
        {Object.values(activeFilters).some((v) => {
          if (Array.isArray(v) && !v.length) {
            return v.length;
          }
          return v;
        }) && (
          <Button
            variant="text"
            onClick={handleClearAll}
            data-testid={AttachmentDialogTestids.FilterClearAllButton}
          >
            Clear All
          </Button>
        )}
      </Stack>
    </div>
  );
};

const ss = stylesheet({
  root: (theme) => ({
    padding: theme.spacing(2),
    backgroundColor: Color.LightGray,
    display: "flex",
    flexDirection: "column",
  }),
  /**
   * Styles for the `Filter By` label
   */
  sectionLabel: (theme) => ({
    marginLeft: theme.spacing(1),
    fontSize: "12px",
    fontWeight: "bold",
  }),
  /**
   * Styles for the filter form itself
   */
  form: {
    flexGrow: 1,
  },
  /**
   * Styles for the filter type dropdown
   */
  filterTypeSelect: { backgroundColor: Color.White, width: "200px" },
  /**
   * Styles for the filter input
   */
  filterInput: (theme) => ({
    backgroundColor: Color.White,
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }),
  /**
   * Styles for the filter chips
   */
  chip: { backgroundColor: Color.White },
});

export const allImagesFilter = "$in,.jpg,.jpeg,.png,.webp,.gif";

/**
 * Extensions supported for the extension filter dropdown
 */
const supportedExtensions = [
  allImagesFilter,
  "doc",
  "docx",
  "dwfx",
  "jpg",
  "jpeg",
  "mp3",
  "mp4",
  "pdf",
  "png",
  "rvt",
  "xls",
  "xlsx",
  "zip",
];
