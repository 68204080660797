(function () {
  angular.module("akitabox.desktop.floor", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.desktop.floor.detail",
    "akitabox.desktop.floor.list",
    "akitabox.desktop.floor.organizationList",
  ]);
})();
