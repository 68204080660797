(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.assetLifeCycle
   *
   * @param {Object} assetId  ID of the asset
   */
  angular
    .module("akitabox.ui.components.assetLifeCycle", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.assembly",
      "akitabox.core.services.asset",
      "akitabox.core.services.condition",
      "akitabox.core.services.costLine",
      "akitabox.core.services.env",
      "akitabox.core.services.organization",
      "akitabox.core.services.user",
      "akitabox.core.toast",
    ])
    .component("abxAssetLifeCycle", {
      bindings: {
        assetId: "<abxAssetId",
      },
      controller: AbxAssetLifeCycleController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/asset-life-cycle/asset-life-cycle.component.html",
    });

  /* @ngInject */
  function AbxAssetLifeCycleController(
    // Angular
    $log,
    $q,
    // AkitaBox
    models,
    // Libraries
    moment,
    // Services
    AssemblyService,
    AssetService,
    ConditionService,
    CostLineService,
    CostLineTaskService,
    EnvService,
    OrganizationService,
    ToastService,
    UserService
  ) {
    var self = this;

    var SEVERITY_OF_FAILURE_VERBIAGE =
      models.ASSEMBLY.SEVERITY_OF_FAILURE_VERBIAGE;

    var CONDITION_GRADES_VERBIAGE = models.CONDITION.CONDITION_GRADES_VERBIAGE;

    var _organization = OrganizationService.getCurrent();
    var _cmBaseUrl = EnvService.getCmUrl("/cm", _organization.subdomain_key);

    // Attributes
    self.loading = true;
    self.organization = _organization;
    self.asset = null;
    self.assembly = null;
    self.severityOfFailure = null;
    self.installationDate = null;
    self.installationDisplayDate = null;
    self.conditionText = null;
    self.conditionDate = null;
    self.historicalConditions = null;
    self.replacementCostLine = null;
    self.replacementYear = null;
    self.linkToCm = _cmBaseUrl;
    self.linkToAssembly = null;
    self.linkToCreateAssembly = null;
    self.showLifeCycle = false;
    self.maxDate = moment().endOf("day").toDate();
    self.chartHeader = "Assembly Life Cycle";

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.assetId) {
        $log.error("abxAssetLifeCycle: abx-asset-id is required");
      }
    };

    self.$onChanges = function (changes) {
      if (changes.assetId) {
        self.assembly = null;
        self.linkToAssembly = null;
        self.linkToCreateAssembly = null;

        if (self.organization.show_rpi_fci && self.assetId) {
          getData(self.assetId);
        } else {
          self.showLifeCycle = false;
          self.loading = false;
        }
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    // ------------------------
    //   Private Functions
    // ------------------------

    function getData(assetId) {
      self.loading = true;

      AssetService.getByOrganization(self.organization._id, { _id: assetId })
        .then(function (asset) {
          self.asset = asset;
          var assemblyQuery;
          assemblyQuery = {
            associated_assets: self.asset._id || self.asset,
            limit: 1,
          };

          return AssemblyService.getByBuilding(asset.building, assemblyQuery);
        })
        .then(function (assemblies) {
          if (assemblies.length) {
            self.assembly = parseAssembly(assemblies[0]);
            self.severityOfFailure =
              SEVERITY_OF_FAILURE_VERBIAGE[self.assembly.severity_of_failure];
            self.showLifeCycle = true;
            self.linkToAssembly =
              _cmBaseUrl + "/assemblies/" + self.assembly._id;
            return $q.all([
              getAssemblyConditions(self.assembly),
              getReplacementCostLine(self.assembly),
            ]);
          } else {
            self.showLifeCycle = false;
            self.linkToCreateAssembly =
              _cmBaseUrl +
              "/assemblies?asset=" +
              assetId +
              "&buildings=$in," +
              self.asset.building;
          }
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function getAssemblyConditions(assembly) {
      var params = {
        sort: "date_recorded,desc",
      };
      if (self.installationDate) {
        params.date_recorded = "$gte," + self.installationDate.getTime();
      }
      return ConditionService.getAll(
        assembly.building._id || assembly.building,
        assembly._id,
        params
      )
        .then(function (conditions) {
          if (conditions.length) {
            self.historicalConditions = conditions;
            var condition = conditions[0];
            self.conditionText =
              CONDITION_GRADES_VERBIAGE[condition.condition_rating];
            var rawConditionDate = new Date(condition.date_recorded);
            var day = rawConditionDate.getDate();
            var month = rawConditionDate.getMonth() + 1;
            var year = rawConditionDate.getFullYear();
            self.conditionDate = month + "/" + day + "/" + year;
          } else {
            self.conditionText = null;
            self.conditionDate = null;
            self.historicalConditions = null;
          }
        })
        .catch(ToastService.showError);
    }

    function getReplacementCostLine(assembly) {
      var params = {
        assembly: assembly._id,
        category: "replacement",
      };
      return CostLineService.get(
        assembly.building._id || assembly.building,
        params
      )
        .then(function (costLines) {
          if (costLines.length > 0) {
            self.replacementCostLine = costLines[0];
          } else {
            self.replacementCostLine = null;
          }

          if (self.replacementCostLine) {
            return CostLineTaskService.get(
              assembly.building._id || assembly.building,
              {
                cost_line: self.replacementCostLine._id,
                sort: "due_date,asc",
              }
            );
          }
        })
        .then(function (costLineTasks) {
          if (!costLineTasks || !costLineTasks.length) {
            return;
          }

          var replacementCostLineTask = costLineTasks[0];
          var rawDueDate = new Date(replacementCostLineTask.due_date);
          self.replacementYear = rawDueDate.getFullYear();
        })
        .catch(ToastService.showError);
    }

    function parseAssembly(assembly) {
      if (assembly.installation_date) {
        self.installationDate = new Date(assembly.installation_date);
        var year = self.installationDate.getFullYear();
        var month = self.installationDate.getMonth() + 1;
        var day = self.installationDate.getDate();
        self.installationDisplayDate = month + "/" + day + "/" + year;
      } else {
        self.installationDate = null;
        self.installationDisplayDate = null;
      }
      return assembly;
    }
  }
})();
