(function () {
  angular.module("akitabox.desktop.directives.list", [
    "akitabox.core",
    "akitabox.core.toast",
    "akitabox.core.utils",
    "akitabox.core.services.shadow",
    "akitabox.desktop.directives.list.asset",
    "akitabox.desktop.directives.list.document",
    "akitabox.desktop.directives.list.export",
    "akitabox.desktop.directives.list.floor",
    "akitabox.desktop.directives.list.inspectionProgram",
    "akitabox.desktop.directives.list.job",
    "akitabox.desktop.directives.list.schedule",
    "akitabox.desktop.directives.list.room",
    "akitabox.desktop.directives.list.serviceRequest",
    "akitabox.desktop.directives.list.workOrder",
  ]);
})();
