import { useCallback } from "react";
import useSWRInfinite, {
  SWRInfiniteKeyLoader,
  SWRInfiniteFetcher,
} from "swr/infinite";

/**
 * Middleware for {@link useSWRInfinite} hook
 *
 * @example
 * const MyComponent = () => {
 *   const { isLoading, data, setSize } = useSWRInfinite(
 *     api.model.get,
 *     (index) => [{ ...args }],
 *   );
 * }
 *
 * @note
 * You **MUST** destructure the fields you use off of the result from this call,
 * or risk the component not rerendering when data is finished loading/changes
 *
 * @see https://swr.vercel.app/docs/advanced/performance#dependency-collection
 */
export function useInfiniteCall<A extends any[], R extends Promise<any>>(
  fn: (...args: A) => R,
  getKey: SWRInfiniteKeyLoader<Awaited<R>, [...A]>,
  shouldFetch = true
) {
  const fetcher = useCallback<SWRInfiniteFetcher>(
    (...[args]) => fn(...(args as A)),
    [fn]
  );

  return useSWRInfinite<Awaited<R>, Error>(
    getKey,
    shouldFetch ? fetcher : null,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateFirstPage: false,
    }
  );
}
