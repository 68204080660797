(function () {
  /**
   * @ngdoc component
   * @name abxTypeAhead
   *
   * @param {Boolean} disabled - Disable the input
   * @param {String} [groupKey] - Key to group each `options` object by.
   * @param {Boolean} [loading] - The options for the input are being loaded.
   *     Will show a loading indicator to the user.
   * @param {Function} onChange - To be invoked when the value in the input has
   *     changed (i.e. a `keyup` event), but the input is not blurred. Will be
   *     invoked with: event.model, event.value, [event.invalid], [event.id].
   * @param {Function} onSelect - To be invoked when an option is selected
   * @param {Function} onFocus - To be invoked when the input is focused
   * @param {Function} onBlur - To be invoked when the input blurs
   * @param {*[]} options - All selectable options. If the model for the drop
   *     down can be identical for each value, this can be an array of primitive
   *     values. Otherwise, it should be an array of objects of form:
   *     { model, value }.
   *     Optionally, the object can include `secondaryOption` and `secondaryOptionName`
   *     keys. If `secondaryOption` is true, the option is secondary, and will be grouped
   *     below the primary options, under the header stored in `secondaryOptionName`. The
   *     option will also be italicized in the dropdown list.
   * @param {String} value - Currently chosen (display) value (not the model)
   * @param {Boolean} [hideClearButton] - Whether or not to hide the "X" (clear) button
   * @param {Boolean} [blurOnSelect=false] - Blur the input when the user
   *     selects an option (either through Enter keypress or clicking one)
   *
   * @param {String} [placeholder] - placeholder attribute for the input element
   * @param {boolean} [dynamic] - passed to the type-ahead panel so it knows whether
   * the typeahead is dynamic or not. If `true` it will render the 'see all' button
   * and associated text as appropriate in the typeahead panel
   * @param {function} [fetchAllOptions] - To be invoked for dynamic typeaheads upon clicking
   * 'see all' in the typeahead panel
   * @param {boolean} [showOptionsAfterSelect] - keep the drop down options opened even after user has selected one
   * @param {boolean} [allowTextValue] - If true, a text-only value (ie, no model selected) will be
   * considered a valid value and will not be cleared on blur.
   * @description
   * Type ahead functionality for an input component
   */
  angular
    .module("akitabox.ui.components.typeAheadInput")
    .component("abxTypeAhead", {
      bindings: {
        disabled: "<abxDisabled",
        groupKey: "<abxGroupKey",
        loading: "<abxLoading",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        onBlur: "&abxOnBlur",
        options: "<abxOptions",
        hideClearButton: "<?abxHideClearButton",
        value: "<abxValue",
        blurOnSelect: "<?abxBlurOnSelect",
        placeholder: "<abxPlaceholder",
        dynamic: "<?abxDynamic",
        noLocalFiltering: "<?abxNoLocalFiltering",
        fetchAllOptions: "&?abxFetchAllOptions",
        showOptionsAfterSelect: "<?abxShowOptionsAfterSelect",
        allowTextValue: "<?abxAllowTextValue",
      },
      controller: AbxTypeAheadController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/type-ahead/type-ahead.component.html",
    });

  /* @ngInject */
  function AbxTypeAheadController(
    // Angular
    $element,
    $scope,
    $window,
    $timeout,
    // Constants
    KEY_CODES,
    EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION,
    // Helpers
    DomService,
    raf,
    Utils,
    // Material
    $mdPanel
  ) {
    var self = this;
    // Private
    var MD_LIST_ELEMENT_SELECTOR = "md-list";
    var MD_LIST_ITEM_ELEMENT_SELECTOR = "md-list-item";
    var INPUT_ELEMENT_SELECTOR = "input";
    var blurDisabled = false;
    var debouncedUpdatePosition = angular.debounce(updatePanelPosition, 100);
    var updatePositionRafId = null;
    var inputElemScrollParent;

    // Attributes
    self.filteredOptions = [];
    self.blurOnSelect = self.blurOnSelect || false;
    self.focusIndex;
    self.inputElem;
    self.panelRef;
    self.isPanelShown = false;
    self.showOptionsAfterSelect = self.showOptionsAfterSelect || false;
    self.toArray = null;
    self.allowTextValue = self.allowTextValue || false;

    // Functions
    self.handleBlur = handleBlur;
    self.handleClearClick = handleClearClick;
    self.handleDebouncedChange = handleDebouncedChange;
    self.showList = showList;
    self.onDropDownSelect = onDropDownSelect;
    self.handleKeydown = handleKeydown;
    self.setFocusIndex = setFocusIndex;
    self.focusOption = focusOption;
    self.optionIsFocused = optionIsFocused;
    self.maintainInputFocus = maintainInputFocus;
    self.checkIfSecondaryOption = checkIfSecondaryOption;

    $scope.$on(EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION, function () {
      $timeout(function () {
        updatePanelPosition();
      });
    });

    // =================
    // Lifecycle
    // =================

    self.$onInit = function () {
      // Cache the typeahead input element for later use.
      self.inputElem = $element.find(INPUT_ELEMENT_SELECTOR)[0];
      inputElemScrollParent = DomService.getScrollParent(self.inputElem);
      initOptionsPanel();
    };

    self.$onChanges = function (changes) {
      if (changes.options) {
        self.options = parseOptions(self.options);
      }
      if (changes.options || changes.value) {
        // Don't filter on ONLY a value change if noLocalFiltering is true
        if (!(changes.value && !changes.options && self.noLocalFiltering)) {
          filter();
        }
        // Clear the focusIndex and scroll to the first item in list
        self.setFocusIndex(undefined, true);
      }
    };

    self.$onDestroy = function () {
      // Cleanup the panelRef
      if (self.panelRef) {
        self.panelRef.destroy();
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Handle text input blur.
     */
    function handleBlur() {
      if (blurDisabled) {
        return;
      }

      hideList();
      ensureValidData();
      self.onBlur();
    }

    /**
     * Check whether the value chosen corresponds to a valid option
     * If not, clear the input and reset the filtered options list
     */
    function ensureValidData() {
      if (self.allowTextValue) {
        return;
      }
      var selectedOption = findOptionByValue(self.value);
      if (!selectedOption) {
        self.value = "";
        self.filteredOptions = self.options;
      }
    }

    /**
     * Show the list of options
     */
    function showList() {
      self.onFocus();
      openPanel();
    }

    /**
     * Notify the parent when a value is chosen from the options
     *
     * @param {Object} selectedOption - Option selected from the list.
     */
    function onDropDownSelect(selectedOption) {
      // the user intentionally selected something from the dropdown; ensure it can blur if necessary
      if (!self.showOptionsAfterSelect) {
        // We want to keep the options list shown, so we do not want any blur
        // events here which will hide the options list
        blurDisabled = false;
      }
      selectOption(selectedOption);
      if (self.blurOnSelect) {
        self.inputElem.blur();
      }
    }

    /**
     * Handler for clicking the typeahead "clear" button. Simulates a workflow
     * of a user focusing the input and then emptying its contents.
     */
    function handleClearClick() {
      // Changes made to save-on-blur inputs while they are not in the editing
      // state are immediately committed. So first, give the input a chance to
      // enter the editing state before clearing it.
      focusInput();
      $timeout(clearInput);

      /**
       * Focus this typeahead's input element.
       */
      function focusInput() {
        self.inputElem.focus();
      }

      /**
       * Notify parent components of the change to empty.
       */
      function clearInput() {
        self.onChange({
          $event: {
            model: undefined,
            value: "",
            clearInput: true,
          },
        });
      }
    }

    /**
     * The handleChange must be lead-debounced to avoid race conditions with save-on-blur.
     */
    function handleDebouncedChange() {
      // Show the list of options if it was hidden (i.e. just selected an option
      // with keyboard navigation)
      if (!self.isPanelShown) {
        openPanel();
      }

      angular.debounce(handleChange, 250, true)();

      /**
       * Handle input changes
       */
      function handleChange() {
        if (!self.noLocalFiltering) {
          filter();
        }
        var selectedOption = findOptionByValue(self.value);
        // The only valid value from a change event is empty string
        var valid = self.allowTextValue || !self.value;
        self.onChange({
          $event: {
            model: selectedOption && selectedOption.model,
            value: self.value,
            invalid: !valid,
          },
        });
      }
    }

    /**
     * Handle keypress and set the focusIndex or
     * select an option based on keyCode
     *
     * @param {Object} $event - The keydown event object
     */
    function handleKeydown($event) {
      if (!$event || !$event.which || !self.isPanelShown) return;

      var index;
      var hasFocusedOption = self.focusIndex !== undefined;
      if ($event.which === KEY_CODES.ARROW_UP) {
        // $event.preventDefault() stops the cursor from returning to the beginning of input field
        $event.preventDefault();

        if (!hasFocusedOption) return;

        index = self.focusIndex > 0 ? self.focusIndex - 1 : 0;
        self.setFocusIndex(index, true);
      } else if ($event.which === KEY_CODES.ARROW_DOWN) {
        $event.preventDefault();

        if (!hasFocusedOption) {
          // First option should receive focus
          index = 0;
        } else if (self.focusIndex < self.filteredOptions.length - 1) {
          // Only advance focusIndex if not on last option
          index = self.focusIndex + 1;
        } else {
          // Currently at last option
          return;
        }

        self.setFocusIndex(index, true);
      } else if ($event.which === KEY_CODES.ENTER) {
        // To prevent dialogs containing a typeahead from closing on enter.
        $event.preventDefault();
        if (self.focusIndex !== undefined) {
          // prevent double-save race from selecting + blurring in one keystroke
          blurDisabled = true;
          // When ENTER is pressed, select the option at the current focusIndex
          selectOption(self.filteredOptions[self.focusIndex]);
        }
      }
    }

    /**
     * Set the focusIndex to provided index; optionally update scroll position of list container
     *
     * @param {Number} index - index of the option to be focused from self.filteredOptions
     * @param {Boolean} [updateScroll] - When true, will update the scroll position of the md-list
     * to keep the focused option in view
     */
    function setFocusIndex(index, updateScroll) {
      self.focusIndex = index;

      if (updateScroll) {
        scrollOptionIntoView(index);
      }
    }

    /**
     * Set the focusIndex to match the provided option
     *
     * @param {*} option - option to be set as focused option
     */
    function focusOption(option) {
      var index = findOptionIndex(option);
      self.setFocusIndex(index, true);
    }

    /**
     * Determine if the provided option matches the current focusIndex
     *
     * @param {*} option - option to evaluate against current focusIndex
     */
    function optionIsFocused(option) {
      var index = findOptionIndex(option);
      return index === self.focusIndex;
    }

    /**
     * Used to catch and mask an event dispatched on another element while resetting
     * focus on input element
     *
     * @param {Object} $event - Dom event to be masked
     */
    function maintainInputFocus($event) {
      $event.preventDefault();
      $event.stopPropagation();
      self.inputElem.focus();
    }

    /**
     * Search the options array by (display) value to determine if the
     * option is a secondary option
     *
     * @param {string} displayVal string value to be displayed in the input
     */
    function checkIfSecondaryOption(displayVal) {
      if (!self.options || !self.options.length) {
        return false;
      }

      var isSecondary = self.options.find(function (option) {
        if (option.value === displayVal && option.secondaryOption) {
          return true;
        }
      });

      return isSecondary;
    }

    // =================
    // Private Functions
    // =================

    /**
     * Select the given option.
     *
     * @param {Object} option - Option to select
     */
    function selectOption(option) {
      if (!self.showOptionsAfterSelect) {
        // Multiple assignees need the ability keep the options shown even
        // after a select happens
        hideList();
      }

      self.onSelect({
        $event: {
          model: option.model,
          value: option.value,
          valid: true,
        },
      });
    }

    /**
     * Filter the selectable options
     */
    function filter() {
      if (!self.value || !self.options) {
        self.filteredOptions = self.options;
      } else {
        self.filteredOptions = self.options.filter(function (option) {
          return (
            option.value.toLowerCase().indexOf(self.value.toLowerCase()) > -1
          );
        });
      }
    }

    /**
     * Converts given options into an `object` format, if they are not
     * already. In other words, if the given values are a set of primitives,
     * convert them into objects that have a model and value (that are equal
     * to the primitive value). This way, no matter what kind of values are
     * given, they will always have the same format.
     *
     * @param {*[]} options - Options to convert
     */
    function parseOptions(options) {
      if (!options) return [];

      return options.map(function (value) {
        var isInOptionFormat = value.model && value.value;
        if (isInOptionFormat) {
          return value;
        } else {
          return {
            model: value,
            value: value,
          };
        }
      });
    }

    /**
     * Hide the list of options
     */
    function hideList() {
      self.setFocusIndex(undefined);
      closePanel();
    }

    /**
     * Find, among the selectable options, the one that has the given value
     *
     * @param {String} value - Value of option to find
     * @return {Object} - Option with the matching value
     */
    function findOptionByValue(value) {
      if (!self.options) return;

      return Utils.find(self.options, function (option) {
        return option.value === value;
      });
    }

    /**
     *
     * @param {Object} option - option used to find index of matching element in filteredOptions
     * @return {Number} - index of the matching option from filteredOptions
     */
    function findOptionIndex(option) {
      var index;

      self.filteredOptions.some(function (item, i) {
        var isMatch = item === option;
        if (isMatch) index = i;
        return isMatch;
      });

      return index;
    }

    /**
     * Scrolls an option at the provided index into the visible area of the scroll container
     *
     * @param {Number} index - index used to select the DOM node for option to be scrolled into view
     */

    function scrollOptionIntoView(index) {
      // The scroll container and list options are contained in the panelRef.panelEl.
      if (!(self.panelRef && self.panelRef.panelEl)) return;
      // If no index provided when called, scroll to top of list
      if (index === undefined) index = 0;

      var scrollElem = self.panelRef.panelEl.find(MD_LIST_ELEMENT_SELECTOR)[0];
      var optionElem = self.panelRef.panelEl.find(
        MD_LIST_ITEM_ELEMENT_SELECTOR
      )[index];

      if (!scrollElem || !optionElem) return;

      var stickyHeaderHeight = 0;
      /**
       * When a groupKey is set, a group header will be rendered position: sticky in the list.
       * Account for this height when calculating visible scroll area
       */
      if (self.groupKey) {
        // The header is rendered as the first child of an option's parent element
        stickyHeaderHeight = optionElem.parentNode.children[0].offsetHeight;
      }

      var optionRect = optionElem.getBoundingClientRect();
      var scrollRect = scrollElem.getBoundingClientRect();

      if (optionRect.top < scrollRect.top + stickyHeaderHeight) {
        scrollElem.scrollTop -=
          scrollRect.top - optionRect.top + stickyHeaderHeight;
      } else if (optionRect.bottom > scrollRect.bottom) {
        scrollElem.scrollTop += optionRect.bottom - scrollRect.bottom;
      }
    }

    // =================
    // MdPanel Config
    // =================

    /**
     * Initializes the mdPanel containing the type-ahead list results.
     */
    function initOptionsPanel() {
      // Init the position relative to the type-ahead component
      var position = calculatePanelPosition();

      var config = {
        attachTo: angular.element(document.body),
        controller: PanelController,
        controllerAs: "vm",
        position: position,
        templateUrl: "app/core/ui/components/type-ahead/type-ahead.panel.html",
        propagateContainerEvents: true,
        clickOutsideToClose: false,
        escapeToClose: true,
        focusOnOpen: false,
        trapFocus: false,
        zIndex: 81,
        onDomAdded: onPanelAdded,
        onDomRemoved: onPanelRemoved,
        locals: {
          focusOption: self.focusOption,
          onDropDownSelect: self.onDropDownSelect,
          optionIsFocused: self.optionIsFocused,
          dynamic: self.dynamic,
          fetchAllOptions: self.fetchAllOptions,
          onEnter: onListEnter,
          onLeave: onListLeave,
          onMouseup: onListMouseup,
        },
      };

      // Create the panel and cache the reference;
      // this creates the panelRef, but does not attach it to the DOM
      self.panelRef = $mdPanel.create(config);
    }

    function onListMouseup() {
      // Re-focus the input on mouseup (for example, when the user clicks
      // something in the list that's not an item, like the scroll bar)
      self.inputElem.focus();
    }

    function onListEnter() {
      // Disable input blur handling in case the user clicks anything within
      // the list
      blurDisabled = true;
    }

    function onListLeave() {
      // Re-enable input blur handling if the user isn't interacting with the
      // list anymore
      blurDisabled = false;
    }

    // This method is exposed for testing purposes only
    self.rafThrottledUpdatePosition = rafThrottledUpdatePosition;
    /**
     * Update the panel position. Throttled by requestAnimationFrame.
     */
    function rafThrottledUpdatePosition() {
      if (updatePositionRafId === null) {
        updatePositionRafId = raf(function () {
          self.updatePanelPosition();
          updatePositionRafId = null;
        });
      }
    }

    // This method is exposed for testing purposes only.
    self.updatePanelPosition = updatePanelPosition;
    /**
     * Updates the position of the mdPanel to stay visually aligned with the typeahead input element.
     * Also sizes the panel width to match the typeahead input element.
     */
    function updatePanelPosition() {
      // Only calculate new position if panel is defined and attached to the DOM
      if (self.panelRef && self.panelRef.isAttached && self.panelRef.panelEl) {
        var listElem = self.panelRef.panelEl.find(MD_LIST_ELEMENT_SELECTOR)[0];
        var newPanelWidth =
          self.inputElem.clientLeft + self.inputElem.clientWidth;

        var newPosition = calculatePanelPosition();

        listElem.style.width = newPanelWidth + "px";
        self.panelRef.updatePosition(newPosition);

        var scrolledOff = !DomService.isVerticallyScrolledIntoView(
          self.inputElem,
          "bottom",
          inputElemScrollParent
        );
        if (self.isPanelShown && scrolledOff) {
          self.panelRef.hide();
          self.isPanelShown = false;
        } else if (!self.isPanelShown && !scrolledOff) {
          self.panelRef.show();
          self.isPanelShown = true;
        }
      }
    }

    /**
     * Opens the mdPanel.
     *
     * The open() method returns a promise that resolves when
     * the panel has been attached to the DOM.
     */
    function openPanel() {
      if (self.panelRef) {
        self.panelRef.open().then(updatePanelPosition);
      }
    }

    /**
     * Closes the mdPanel
     */
    function closePanel() {
      if (self.panelRef) {
        self.panelRef.close();
      }
    }

    /**
     * Called after the mdPanel has been attached to the DOM.
     * Adds a listner to window resize that updates the panel position.
     */
    function onPanelAdded() {
      angular
        .element(inputElemScrollParent)
        .on("scroll", rafThrottledUpdatePosition);

      angular
        .element($window)
        .on("resize orientationchange", debouncedUpdatePosition);
      self.isPanelShown = true;
    }

    /**
     * Called after the mdPanel has been removed from the DOM.
     * Removes the window resize listener as it's not needed when the panel is detached.
     */
    function onPanelRemoved() {
      angular
        .element(inputElemScrollParent)
        .off("scroll", rafThrottledUpdatePosition);
      angular
        .element($window)
        .off("resize orientationchange", debouncedUpdatePosition);
      self.isPanelShown = false;

      // Re-enable blur click events, since the user can't interact with/click
      // anything in the list anymore. This is deferred until next tick
      // to prevent double-saves when using the keyboard to select an item.
      $timeout(function () {
        blurDisabled = false;
      });
    }

    /**
     * Calculates the position for the mdPanel based on the typeahead input
     *
     * @returns {Object<MdPanelPosition>} The object containing panel position information.
     */
    function calculatePanelPosition() {
      var inputRect = self.inputElem.getBoundingClientRect();
      // The top of the panel should align with the bottom
      // of the input element.
      var top = inputRect.bottom;
      var left = inputRect.left;

      var position = $mdPanel
        .newPanelPosition()
        .absolute()
        .top(top + "px")
        .left(left + "px");

      return position;
    }

    /**
     * The Controller for the mdPanel.
     *
     * This controller is purposefully defined within the context of the AbxTypeAheadController function
     * in order to $scope.$watch values of the AbxTypeAheadController used in the panel template.
     *
     * This works around an issue where changes to data passed via locals was not detected until re-attaching the
     * mdPanel to the DOM.
     */
    function PanelController() {
      var self = this;

      self.groupKey;
      self.filteredOptions = [];
      self.loading = false;

      $scope.$watch("vm.groupKey", function (value) {
        self.groupKey = value;
        if (self.groupKey === "secondaryOptionName") {
          // if grouping by primary and secondary options, force
          // the primary options to be displayed above the secondary options
          self.orderBy = "$key";
          self.toArray = true;
        }
      });
      $scope.$watch("vm.filteredOptions", function (value) {
        self.filteredOptions = value;
        // if ordering the list by primary/secondary, update the sort
        // so items are indexed correctly to prevent bugs with scrolling
        if (self.groupKey === "secondaryOptionName") {
          self.filteredOptions.sort(primarySecondarySort);
        }
      });
      $scope.$watch("vm.loading", function (value) {
        self.loading = value;
      });
    }
  }
  /**
   * Sorting method to re-index an array of dropdown values, placing primary options
   * above secondary options
   * @param {Object} a option to sort
   * @param {Object} b option to sort
   */
  function primarySecondarySort(a, b) {
    if (a.secondaryOption === b.secondaryOption) {
      return a.value > b.value;
    } else if (a.secondaryOption === true && b.secondaryOption === false) {
      return 1;
    } else if (a.secondaryOption === false && b.secondaryOption === true) {
      return -1;
    }
  }
})();
