(function () {
  angular.module("akitabox.planView").component("abxMarkupColorSelector", {
    bindings: {
      onStrokeColorSelect: "<?abxOnStrokeColorSelect",
    },
    controller: AbxMarkupColorSelectorController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-dropdowns/markup-color-selector/markup-color-selector.component.html",
  });

  function AbxMarkupColorSelectorController(abxMarkupTools, ShadowService) {
    var self = this;
    var GA_CATEGORY = "markup";

    // Attributes
    self.colorList = [
      // Red
      { name: "Red", value: "#FF0000" },
      // Lime Green
      { name: "Lime Green", value: "#00FF00" },
      // Pink
      { name: "Pink", value: "#FF00FF" },
      // Blue
      { name: "Blue", value: "#0000FF" },
      // White
      { name: "White", value: "#FFFFFF" },
    ];
    self.currentlySelectedIndex = self.colorList.indexOf(
      abxMarkupTools.strokeColor
    );

    // Function
    self.selectColor = selectColor;
    self.getColor = getColor;
    self.$onInit = init;

    function init() {
      self.selectColor(self.currentlySelectedIndex);
    }

    function selectColor(index) {
      var event = {};
      if (self.currentlySelectedIndex !== index) {
        // send event with the newly selected size
        ShadowService.sendEvent(
          GA_CATEGORY,
          "select-color",
          self.colorList[index].value,
          null
        );
      }
      self.currentlySelectedIndex = index;
      self.selected = false;
      var color = self.colorList[index].value;
      event.strokeColor = color;
      abxMarkupTools.strokeColor = color;
      if (self.onStrokeColorSelect) {
        self.onStrokeColorSelect(event);
      }
    }

    function getColor(color) {
      var colorBoxStyle = {};

      colorBoxStyle["background-color"] = color;

      if (color === self.colorList[4].value) {
        /*
         * Check for a white color and add a border.
         * This helps the color be distigueshed from
         * the background.
         */
        colorBoxStyle["border"] = "1px solid #dcdcdc";
      }

      return colorBoxStyle;
    }
  }
})();
