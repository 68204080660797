(function () {
  /**
   * @ngdoc component
   * @name abxInspectionProgramInstruction
   *
   * @param {Boolean} [isOpenOnInit] - override the isOpen property on initialization
   * @param {Boolean} [disabled] - close and disable component
   *
   * @description
   * Tab for instructions to create an inspection program
   */
  angular
    .module("akitabox.desktop.inspectionProgram")
    .component("abxInspectionProgramInstruction", {
      bindings: {
        isOpenOnInit: "<?abxIsOpenOnInit",
        onToggle: "&?abxOnToggle",
      },
      controller: AbxInspectionProgramInstructionController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/modules/inspection-program/inspection-program-instruction/inspection-program-instruction.component.html",
    });

  function AbxInspectionProgramInstructionController(
    $q,
    BuildingService,
    EnvService,
    OrganizationService,
    CreateInspectionProgramDialog
  ) {
    var self = this;

    var baseOrgSettingsUrl = EnvService.getOrgSettingsUrl();
    var currentOrg = OrganizationService.getCurrent();
    var currentBuilding = BuildingService.getCurrent();

    self.roundTemplateTitle = "Create or select a Round Template";
    self.roundTemplateDescription =
      "A “Round Template” is the list of stops (any combination of rooms and assets) that a technician makes when they’re completing an inspection.";
    self.linkRoundTemplateDialog =
      baseOrgSettingsUrl + "/organization/settings/round_templates?dialog=true";
    self.linkRoundTemplateList =
      baseOrgSettingsUrl + "/organization/settings/round_templates";

    self.checklistTemplateTitle = "Create or select a Checklist Template";
    self.checklistTemplateDescription =
      "A “Checklist Template” is a series of questions to be answered at each stop in an inspection.";
    self.linkChecklistTemplateDialog =
      baseOrgSettingsUrl +
      "/organization/settings/checklist_templates?dialog=true";
    self.linkChecklistTemplateList =
      baseOrgSettingsUrl + "/organization/settings/checklist_templates";

    self.inspectionProgramTitle =
      "Connect Round and Checklist Templates to create a recurring Inspection Program";
    self.inspectionProgramDescription =
      "Once you are ready to connect Round and Checklist Templates you will then be able to schedule the recurrence of the Inspection Program. Each recurrence will create a new work order.";
    var locals = {
      building: currentBuilding,
      organization: currentOrg,
      editMode: false,
    };
    self.inspectionProgramDialog = function () {
      CreateInspectionProgramDialog.show({ locals: locals });
    };

    // Attributes
    self.isOpen = true;

    // Functions
    self.viewInspectionProgramInstructions = viewInspectionProgramInstructions;
    self.handleToggle = handleToggle;

    // =================
    // Lifecycle
    // =================

    self.$onInit = function () {
      if (angular.isDefined(self.isOpenOnInit)) {
        if (!self.isOpenOnInit) {
          viewInspectionProgramInstructions(false);
        }
      } else {
        self.isOpenOnInit = true;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.isOpenOnInit && self.isOpen !== self.isOpenOnInit) {
        viewInspectionProgramInstructions();
      }
    };

    /**
     * Toggle visibility of instruction drawer and list
     * @param {Boolean} [visibility] - optional override; true sets it to open, false sets it closed
     */
    function viewInspectionProgramInstructions(visibility) {
      if (angular.isDefined(visibility)) {
        self.isOpen = visibility;
      } else {
        self.isOpen = !self.isOpen;
      }
    }

    function handleToggle($event) {
      if (self.onToggle) {
        $q.resolve(self.onToggle({ $event: $event })).then(function () {
          viewInspectionProgramInstructions();
        });
      } else {
        viewInspectionProgramInstructions();
      }
    }
  }
})();
