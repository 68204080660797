(function () {
  angular.module("akitabox.ui.components.roomInput", [
    "akitabox.core",
    "akitabox.core.services.building",
    "akitabox.core.services.pinField",
    "akitabox.core.services.pinType",
    "akitabox.core.services.room",
    "akitabox.core.services.roomsBff",
    "akitabox.core.toast",
    "akitabox.core.utils",
    "akitabox.ui.components.typeAheadInput",
  ]);
})();
