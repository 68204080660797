(function () {
  /**
   * @ngdoc filter
   * @name abxUrlSchemePrefix
   *
   * @description
   * Formats text to be an absolute (rather than relative) url. This is done by
   * prefixing incoming values with an assumed scheme. Incoming values will be
   * prefixed if all of the following are true:
   *    they do not have a scheme: - this denotes an absolute url
   *    they do not have a // prefix - this denotes a protocol-relative absolute url
   *    they are not the empty string
   *
   * @param {String} url - Input to filter
   * @param {String} [scheme="http"] - The scheme to prefix urls with
   * @return {String} the (potentially) url prefixed with scheme://
   */
  angular
    .module("akitabox.core.filters.urlSchemePrefix", [])
    .filter("abxUrlSchemePrefix", abxUrlSchemePrefixFilter);

  function abxUrlSchemePrefixFilter() {
    return function (url, scheme) {
      scheme = scheme || "http";
      if (!angular.isString(url) || url === "") {
        return "";
      }
      if (urlIsPrefixed(url) || url.indexOf("//") === 0) {
        return url;
      }
      return scheme + "://" + url;
    };

    /**
     * Determine if the given url starts with `scheme://`
     * RFC 3986 Section 3.1 defines valid url schemes as follows:
     *    Scheme names consist of a sequence of characters beginning with a
     *    letter and followed by any combination of letters, digits, plus
     *    ("+"), period ("."), or hyphen ("-").
     *
     * This function includes the optional `//` slashes in the regex test
     * to align more closely with the intended values of url.
     * Added for [WEBAPP-6652](https://akitabox.atlassian.net/browse/WEBAPP-6652)
     *
     * @return {Boolean} True iff the url has a scheme + '://' specified
     */
    function urlIsPrefixed(url) {
      return /^[a-z][a-z0-9+.-]+:\/\//i.test(url);
    }
  }
})();
