(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.roomStats
   *
   * @param {Object} building - building to build the chart with
   */
  angular.module("akitabox.ui.components.roomStats").component("abxRoomStats", {
    bindings: {
      building: "<abxBuilding",
      onViewAll: "&?abxOnViewAll",
      allowClick: "&?abxAllowClick",
    },
    controller: AbxRoomStatsController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/room-stats/room-stats.component.html",
  });
  function AbxRoomStatsController(
    // Angular
    $scope,
    $attrs,
    $q,
    $log,
    // Constants
    EVENT_ROOM_CREATE,
    // Services
    FloorService,
    RoomService,
    Router,
    ToastService,
    Utils
  ) {
    var self = this;

    // Attributes
    self.showViewAll = !angular.isEmpty($attrs.abxOnViewAll);
    self.floors = {};
    self.values = [];
    self.defaultValue = 0;
    self.defaultText = {};
    self.loading = true;
    self.allowClick = Utils.parseExpression(self, "allowClick", false);

    // Functions
    self.onClick = self.allowClick ? onClick : angular.noop;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.building) {
        $log.error("abxRoomStats: abx-building is required");
        return;
      }
      getData(self.building);
    };

    self.$onChanges = function (changes) {
      if (changes.building) {
        getData(self.building);
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ROOM_CREATE, onRoomCreate);

    // ------------------------
    //   Public Functions
    // ------------------------

    function onClick($event) {
      var data = $event.data;
      if (self.building && self.building._id && data._id) {
        Router.go("app.building.rooms", {
          buildingId: self.building._id,
          level: data._id,
        });
      }
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getData(building) {
      self.loading = true;
      FloorService.getAll(building._id)
        .then(function (floors) {
          return getRoomCount(building, floors);
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.data = [];
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function getRoomCount(building, floors) {
      var roomPromises = [];
      floors.forEach(function (floor) {
        roomPromises.push(
          RoomService.get(building._id, {
            level: floor._id,
            count: true,
          }).then(function (roomCount) {
            floor.rooms = roomCount;
          })
        );
      });
      return $q.all(roomPromises).then(function () {
        buildData(floors);
      });
    }

    function buildData(floors) {
      var floorData = [];

      self.defaultText.value = 0;
      self.defaultText.name = "Total Rooms";

      floors.forEach(function (floor) {
        var count = floor.rooms.count;
        if (count > 0) {
          self.defaultText.value += count;
          floorData.push({
            name: floor.name,
            value: count,
            _id: floor._id,
          });
        }
      });

      self.data = floorData.slice(0, 12);
    }

    /**
     * Handle room creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Room[]} rooms    List of new rooms
     */
    function onRoomCreate($event, rooms) {
      // We can only create one room at a time
      var room = rooms[0];
      const building = room.building._id || room.building;
      if (building === self.building._id) {
        getData(self.building);
      }
    }
  }
})();
