(function () {
  angular
    .module("akitabox.core.router", [
      "ui.router",
      "akitabox.core.services.building",
      "akitabox.core.router.react",
    ])
    .provider("Router", RouterProvider);

  /* @ngInject */
  function RouterProvider(
    $locationProvider,
    $stateProvider,
    $urlRouterProvider
  ) {
    this.$get = Router;

    $locationProvider.html5Mode(true);
    $urlRouterProvider.rule(function ($injector, $location) {
      var path = $location.path();
      var hasTrailingSlash = path[path.length - 1] === "/";

      if (hasTrailingSlash) {
        //if last character is a slash, return the same url without the slash
        var newPath = path.substr(0, path.length - 1);
        var search = $location.search();

        var params = [];
        angular.forEach(search, function (value, key) {
          params.push(key + "=" + value);
        });

        // Preserve the query string when stripping trailing slashes from paths #WEBAPP-6029
        if (!params.length) {
          return newPath;
        } else {
          return newPath + "?" + params.join("&");
        }
      }
    });

    /* @ngInject */
    function Router($state, BuildingService) {
      var hasOtherwise = false;
      var service = {
        configureStates,
        getStates,
        configureRedirect,
        href,
        go,
      };

      return service;

      //------------------
      // Public Functions
      //------------------

      function configureStates(states, otherwise) {
        states.forEach(function (state) {
          $stateProvider.state(state.state, state.config);
        });
        if (otherwise && !hasOtherwise) {
          hasOtherwise = true;
          $urlRouterProvider.otherwise(otherwise);
        }
      }

      function getStates() {
        return $state.get();
      }

      function configureRedirect(from, to) {
        $urlRouterProvider.when(from, to);
      }

      function go(stateName, params, options) {
        var state = translateState(stateName, params);
        return $state.go(state.name, state.params, options);
      }

      function href(stateName, params) {
        var state = translateState(stateName, params);
        return $state.href(state.name, state.params);
      }

      //------------------
      // Private Functions
      //------------------

      function translateState(stateName, params) {
        var building = BuildingService.getCurrent();
        var isAppState = stateName.indexOf("app") > -1;

        if (isAppState && building) {
          // Add building param if not in params
          if (!params) {
            params = {};
          }
          if (!params.buildingId) {
            angular.extend(params, { buildingId: building._id });
          }

          // Translate state name
          if (stateName.indexOf("app.building") < 0) {
            stateName = stateName.replace(/^app\./, "app.building.");
          }
          // Dashboard may not exist depending on list flags, use detail
          if (stateName === "app.building.dashboard") {
            stateName = "app.building.detail";
          }
        }
        return { name: stateName, params: params };
      }
    }
  }
})();
