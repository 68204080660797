(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.buildingHeader
   */
  angular
    .module("akitabox.desktop.directives.filterBar", [
      "akitabox.core.lib.moment",
      "akitabox.core.services.http",
      "akitabox.core.services.redirect",
    ])
    .controller("AbxFilterBarController", AbxFilterBarController)
    .directive("abxFilterBar", AbxFilterBarDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.filterBar
   * @name AbxFilterBarDirective
   * @restrict E
   *
   * @description
   * `<abx-filter-bar>` is a directive that displays the available filters for whatever list instantiates it and controls
   * the selection of those filters
   *
   * @ngInject
   */
  function AbxFilterBarDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/filter-bar/filter-bar.html",
      controller: "AbxFilterBarController",
      controllerAs: "vm",
      bindToController: true,
      scope: {
        filters: "<filterOptions", // config object from FilterService
        activeFilters: "<?abxActiveFilters", // filters that are currently "turned on"
        customParsers: "=?",
        updateUrl: "&?", // update URL on filter changes
        onChange: "&", // callback that runs on filter changes; called with activeFilters
        onLocationChange: "&?",
      },
    };
  }

  /**
   * @ngdoc controller
   * @module akitabox.desktop.directives.filterBar
   * @name AbxFilterBarController
   *
   * @description
   * This controller watches for changes to the filter selection inputs
   *
   * @ngInject
   */
  function AbxFilterBarController(
    // Angular
    $q,
    $location,
    $log,
    $scope,
    $attrs,
    // Libraries
    moment,
    // Services
    HttpService,
    RedirectService
  ) {
    var self = this;

    var internalLocationChange = false;
    var hasBeenDestroyed = false;

    // Attributes
    // the filter config for the most recently chosen filter
    self.selected = null;

    // the value chosen (or entered) by the user
    self.filterValue = null;

    // the pinType ID if a pinType is involved in the filter
    self.selectedPinType = null;

    // updateURL
    self.updateUrl = angular.isDefined(self.updateUrl)
      ? self.updateUrl()
      : true;

    // filters that are not hidden by their config
    self.availableFilters = [];

    // modified filters (they do not look like the filter config object) that are currently selected
    // Example activeFilters object {
    //  pin_type:{
    //    {_id:'53dc..'},
    //    {pin_fields: [
    //      {data_type:'something', value:'something', id:'222dc...'}
    //    ]
    //  }
    // }
    self.activeFilters = self.updateUrl ? parseUrlParams() : self.activeFilters;

    // custom filters
    self.customFilters = parseCustomFilters();

    // pinFields that are currently selected
    // Example activePinFields object
    // {
    //   { floor_type: { data_type:'something', value:'something', id:'33cd...' } },
    //   { manufacturer: { value:'something', name:'Manufacturer' } }
    // }
    self.activePinFields = {};

    // displayed filter chips
    // Example self.activeFilterDisplay object
    // {
    //   { pin_type: { left:'something', right:'something' } },
    //   { type: { left: 'something', right:'something' } },
    // }
    self.activeFilterDisplay = {};

    // show or hide activeFilters
    self.showActiveFilters = false;

    // loading
    self.loading = false;

    // Functions
    self.addFilter = addFilter;
    self.removeFilter = removeFilter;
    self.clearFilters = clearFilters;
    self.onSelectFilterOption = onSelectFilterOption;
    self.isInvalid = isInvalid;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize filter bar and parse active (url) filters
     */
    function init() {
      reset();
      // Listen for filters added externally
      $scope.$on("abxFilterBar:addFilter", onExternalFilterAdd);
      // Watch for filter config changes
      $scope.$watch("vm.filters", parseFilterConfig);
      $scope.$on("$destroy", function () {
        hasBeenDestroyed = true;
      });
      // Listen for location changes
      if (angular.isDefined($attrs.onLocationChange)) {
        internalLocationChange = true;
        $scope.$on(
          "$locationChangeSuccess",
          angular.debounce(function () {
            // Prevent this callback from running if the component has
            // been destroyed, as it may have unintended effects that result
            // in errors
            if (hasBeenDestroyed) return;
            onUrlChange();
          }, 500)
        );
      }
    }

    /**
     * Reset the filter bar
     */
    function reset() {
      // Reset attributes
      self.selected = null;
      self.filterValue = null;
      self.selectedPinType = null;
      self.availableFilters = [];
      self.activeFilters = self.updateUrl
        ? parseUrlParams()
        : self.activeFilters;
      self.customFilters = parseCustomFilters();
      self.activePinFields = getActivePinFields();
      self.activeFilterDisplay = {};
      self.showActiveFilters = false;
      // Populate visible (not hidden) filter options
      parseFilterConfig();
      // Parse the url filters
      parseActiveFilters();
      // Notify the parent
      self.onChange({ activeFilters: self.activeFilters });
    }

    /**
     * Get the activePinFields from the activeFilters; these are the pin fields in the URL as pin_field, and not those
     * that are tied to the pin type as pin_type.pin_fields
     * @return {Object} activePinFields
     */
    function getActivePinFields() {
      var activePinFields = {};
      if (self.activeFilters && self.activeFilters.pin_field) {
        for (var i = 0; i < self.activeFilters.pin_field.length; i++) {
          var key = self.activeFilters.pin_field[i].name.toLowerCase();
          var value = self.activeFilters.pin_field[i];
          activePinFields[key] = value;
        }
      }
      return activePinFields;
    }

    /**
     * Handle filters added from outside of the filter bar
     *
     *
     * @param {Object} event    Event object
     * @param {Object} filter   Filter object - Must include keys: left, right, key and value
     */
    function onExternalFilterAdd(event, filter) {
      if (angular.isEmpty(filter)) return;

      // Add active filter
      self.activeFilters[filter.key] = filter.value;

      self.onChange({ activeFilters: self.activeFilters });
      displayFilter(filter, filter.left, filter.right);

      // Add filter to query string
      updateUrl();
    }

    function onUrlChange($event, url) {
      if (internalLocationChange) {
        internalLocationChange = false;
        return;
      }

      var dest = RedirectService.getDestination();
      var fromQuery = dest.indexOf("?") ? dest.split("?")[1] : null;

      var params = {
        $event: $event,
        url: url,
        toParams: $location.search(),
        fromParams: HttpService.parseQueryString(fromQuery),
      };

      $q.resolve(self.onLocationChange(params)).then(function (config) {
        if (config) self.filters = config;
        self.activeFilters = null;
        reset();
      });
    }

    /**
     * Parse the active (url) filters
     *
     * @return {Object} Active filters
     */
    function parseActiveFilters() {
      if (!self.activeFilters) return;
      var activeQueryKeys = Object.keys(self.activeFilters);
      for (var a = 0; a < activeQueryKeys.length; ++a) {
        var queryKey = activeQueryKeys[a];
        if (self.customFilters.indexOf(queryKey) > -1) continue;
        var value = self.activeFilters[queryKey];
        if (queryKey === "pin_field" && Array.isArray(value)) {
          var valueLength = value.length;
          for (var i = 0; i < valueLength; i++) {
            var key = value[i].name.toLowerCase();
            var thisValue = value[i];
            if (!self.filters[key]) {
              // e.g. the key may be "serial number" but we need "serial_number"
              self.availableFilters.forEach(function (availableFilter) {
                if (availableFilter.name.toLowerCase() === key) {
                  key = availableFilter.key;
                }
              });
            }
            // find the correct filter from availableFilters or self.filters
            parseFilter(key, thisValue);
          }
        } else {
          parseFilter(queryKey, value);
        }
      }
    }

    /**
     * Populate visible (not hidden) filter options
     */
    function parseFilterConfig() {
      var optionKeys = Object.keys(self.filters);
      self.availableFilters = [];
      for (var o = 0; o < optionKeys.length; ++o) {
        var option = self.filters[optionKeys[o]];
        if (!option.hidden) self.availableFilters.push(option);
      }
    }

    /**
     * Populate url parameters
     *
     * @return {Object}     Url parameters
     */
    function parseUrlParams() {
      var urlObject = self.updateUrl ? $location.search() : {};

      // parsing these here is necessary for page refresh with loaded filters
      if (urlObject.pin_field && !Array.isArray(urlObject.pin_field)) {
        // convert the pin_field data in the url from a string to an array
        urlObject.pin_field = JSON.parse(urlObject.pin_field);
      }
      if (urlObject.pin_type && !angular.isObject(urlObject.pin_type)) {
        // convert the pin_field data in the url from a string to an object
        urlObject.pin_type = JSON.parse(urlObject.pin_type);
      }

      return urlObject;
    }

    /**
     * Determine which of the filter options are marked as custom
     *
     * @return {Array} List of custom filter keys
     */
    function parseCustomFilters() {
      var custom = [];
      var keys = Object.keys(self.filters);
      for (var i = 0; i < keys.length; ++i) {
        var k = keys[i];
        if (self.filters[k].custom) custom.push(k);
      }
      return custom;
    }

    /**
     * Delete an active pin field filter
     *
     * @param {String} key  Filter key
     */
    function deleteActivePinFieldFilter(key) {
      var pinTypeFilter = self.activeFilters.pin_type;

      // Handle updates to self.activeFilters, self.activePinFields, and self.selectedPinType
      if (
        pinTypeFilter &&
        Object.prototype.hasOwnProperty.call(self.activePinFields, key) &&
        self.filters[key].requiresPinType
      ) {
        // Example: Room List "floor_type" filter
        // Parse the pin type filter
        if (angular.isString(pinTypeFilter))
          pinTypeFilter = JSON.parse(pinTypeFilter);
        var pinField = self.activePinFields[key];
        // Remove pin field from pin type filter
        if (
          pinTypeFilter.pin_fields.length &&
          pinTypeFilter.pin_fields.length > 1
        ) {
          var index = pinTypeFilter.pin_fields.indexOf(pinField);
          pinTypeFilter.pin_fields.splice(index, 1);
        } else {
          delete pinTypeFilter.pin_fields;
        }
        self.activeFilters.pin_type = pinTypeFilter;

        // remove pin field from internal map
        delete self.activePinFields[key];
      } else if (
        Object.prototype.hasOwnProperty.call(self.activePinFields, key) &&
        !self.filters[key].requiresPinType
      ) {
        // it's a pinField that spans pintypes
        // Example: Asset List "manufacturer" filter
        deleteActiveFilter("pin_field", key);
        // remove pin field from internal map
        delete self.activePinFields[key];
      } else {
        // it's a regular filter
        deleteActiveFilter(key);
      }
      updateUrl();
    }

    /**
     * Delete an active filter. Will handle deleting all child filters from grouped
     * filters.
     *
     * @param {String}   key   Either a filter key or a child query key
     * @param {String}   [pinFieldName]   Optional pin_field name to remove the correct pin_field from the array
     */
    function deleteActiveFilter(key, pinFieldName) {
      if (Object.prototype.hasOwnProperty.call(self.activeFilters, key)) {
        if (key === "pin_field" && pinFieldName) {
          // make sure we only remove the activeFilter.pin_field that applies to this pinFieldName
          for (var i = 0; i < self.activeFilters.pin_field.length; i++) {
            if (
              self.activeFilters.pin_field[i].name.toLowerCase() ===
              self.filters[pinFieldName].name.toLowerCase()
            ) {
              // remove it from the array
              self.activeFilters.pin_field.splice(i, 1);
              if (!self.activeFilters.pin_field.length) {
                // if the activeFilter pin_field array is empty, go ahead and delete the key.
                delete self.activeFilters.pin_field;
              }
              break;
            }
          }
        } else {
          delete self.activeFilters[key];
        }
        updateUrl(key, null);
      } else {
        // Delete child filters of parent filters
        deleteChildFilters(key);
      }
    }

    /**
     * Given a parent filter key, delete all child filters from the active filter
     * list. Ideally, only one child filter should be active at a time, but this
     * will ensure that any/all child filters of the parent filter have been removed
     * from our active filters.
     *
     * @param  {String}   filterKey   The parent filter key
     */
    function deleteChildFilters(filterKey) {
      var filter = findFilterByKey(filterKey);
      var childQueryKeysObject = filter.childQueryKeys;

      if (!childQueryKeysObject) return;

      var childQueryLabels = Object.keys(childQueryKeysObject);
      for (var i = 0; i < childQueryLabels.length; ++i) {
        var label = childQueryLabels[i];
        var queryKey = childQueryKeysObject[label];

        delete self.activeFilters[queryKey];
      }
    }

    /**
     * Request child entity options for a filter
     *
     * @param  {Object} filter        Filter object
     * @param  {String} filterKey     Filter key
     * @param  {Object} value         Filter value
     *
     * @return {Promise<Object|Error>}  Promise that resolves with entities
     */
    function requestEntities(filter, filterKey, value) {
      var params;
      switch (filterKey) {
        case "building":
        case "issue_type":
        case "type":
        case "trade":
        case "level":
        case "maintenance_type":
          if (value) params = { _id: value };
          break;
        case "room":
          params = {
            level: self.activeFilters.level,
          };
          if (value) params._id = value;
          break;
        case "asset":
          params = {
            level: self.activeFilters.level,
            room: self.activeFilters.room,
          };
          if (value) params._id = value;
          break;
        default:
          break;
      }
      if (params && value) params.limit = 1;
      return $q.resolve(filter.values(params));
    }

    /**
     * Parse range string
     *
     * @param {String} range        Url range parameter
     * @param {String} start        [Optional] start delimeter
     * @param {String} end          [Optional] end delimeter
     *
     * @return {Array}              Array containing min (0)
     *                              and max (1) values
     */
    function parseRange(range, start, end) {
      var min = null;
      var max = null;

      if (angular.isString(range) && range.indexOf(",") > 0) {
        var parts = range.split(",");

        start = start ? start : "$gte";
        end = end ? end : "$lte";

        for (var i = 0; i < parts.length - 1; ++i) {
          var p = parts[i];
          if (p === start) {
            min = parts[i + 1];
          } else if (p === end) {
            max = parts[i + 1];
          }
        }
      }

      return [min, max];
    }

    /**
     * Parse pin type filter
     *
     * @param  {Object} filter  Current filter
     * @param  {String} key     Filter key
     * @param  {String} value   Filter value
     */
    function parsePinTypeFilter(filter, key, value) {
      self.selectedPinType = value._id;

      return $q
        .resolve(filter.values())
        .then(function (values) {
          var pinValue;
          for (var i = 0; i < values.length; ++i) {
            if (values[i]._id === value._id) {
              pinValue = values[i];
              break;
            }
          }
          if (pinValue) {
            displayFilter(filter, filter.name, pinValue.name);
          }
          return pinValue;
        })
        .catch(function () {
          deleteActiveFilter(key);
        });
    }

    function parseAccountFilter(filter, key, value) {
      $q.resolve(filter.values())
        .then(function (values) {
          for (var i = 0; i < values.length; ++i) {
            var account = values[i];
            if (account && account._id === value) {
              displayFilter(filter, filter.name, account.email);
            }
          }
        })
        .catch(function () {
          deleteActiveFilter(key);
        });
    }

    /**
     * Parse entity filter
     *
     * @param  {Object} filter      Current filter
     * @param  {String} filterKey   Filter key
     * @param  {String} value       Filter value
     */
    function parseEntityFilter(filter, filterKey, value) {
      requestEntities(filter, filterKey, value)
        .then(function (results) {
          if (results.length) {
            var entity = results[0];

            // Display/append parent category if the selected filter has one
            var rightDisplay = entity.name;
            if (filter.isGrouped) {
              var groupByKey = filter.groupByKey;
              rightDisplay += " (" + entity[groupByKey] + ")";
            }
            displayFilter(filter, filter.name, rightDisplay);
          } else {
            deleteActiveFilter(filterKey);
          }
        })
        .catch(function () {
          deleteActiveFilter(filterKey);
        });
    }

    /**
     * Parse enum filter
     *
     * @param  {Object} filter  Current filter
     * @param  {String} key     Filter key
     * @param  {String} value   Filter value
     */
    function parseEnumFilter(filter, key, value) {
      $q.resolve(filter.values())
        .then(function (values) {
          for (var i = 0; i < values.length; ++i) {
            var option = values[i];
            if (option && option.value === value) {
              displayFilter(filter, filter.name, option.alias);
            }
          }
        })
        .catch(function () {
          deleteActiveFilter(key);
        });
    }

    /**
     * Parse pin field filter
     *
     * @param  {Object} filter  Current filter
     * @param  {String} key     Filter key
     * @param  {String} value   Filter value
     */
    function parsePinFieldFilter(filter, key, value) {
      if (!angular.isObject(value)) {
        value = JSON.parse(decodeURIComponent(value));
      }
      self.activePinFields[filter.key] = value;

      var left = filter.name;
      var right;
      if (value.data_type) {
        switch (value.data_type) {
          case "integer":
          case "float":
            if (value.start && value.end) {
              right = String(value.start) + " - " + String(value.end);
            } else if (value.start) {
              right = "Minimim " + value.start;
            } else if (value.end) {
              right = "Maximum " + value.end;
            }
            break;
          default:
            right = value.value;
            break;
        }
      } else {
        right = value.value;
      }
      displayFilter(filter, left, right);
    }

    /**
     * Parse nested pin fields for a pin value
     *
     * @param {Object}  pinValue    Pin value
     * @param {Array}   pinFields   List of pin fields
     */
    function parseNestedPinFields(pinValue, pinFields) {
      if (!pinValue) return;
      for (var i = 0; i < pinFields.length; ++i) {
        var qField = pinFields[i];
        for (var j = 0; j < pinValue.fields.length; ++j) {
          var vField = pinValue.fields[j];
          if (vField && vField._id === qField._id) {
            var filter = {
              key: vField.name.toLowerCase().replace(" ", "_"),
              name: vField.name,
            };
            qField.data_type = vField.data_type;
            parsePinFieldFilter(filter, null, qField);
            break;
          }
        }
      }
    }

    /**
     * Parse number filter
     *
     * @param  {Object} filter  Current filter
     * @param  {String} key     Filter key
     * @param  {String} value   Filter value
     */
    function parseNumberFilter(filter, key, value) {
      var left = filter.name;
      var right;

      var greaterThan = value.indexOf("$gt,") > -1;
      var lessThan = value.indexOf("$lt,") > -1;

      var range;
      if (greaterThan && lessThan) {
        range = parseRange(value, "$gt", "$lt");
      } else if (greaterThan) {
        range = parseRange(value, "$gt", "$lte");
      } else if (lessThan) {
        range = parseRange(value, "$gte", "$lt");
      } else {
        range = parseRange(value);
      }

      var min = range[0];
      var max = range[1];

      try {
        if (greaterThan) min = parseInt(min, 10) + 1;
        if (lessThan) max = parseInt(max, 10) - 1;
      } catch (err) {
        // Bad value
        deleteActiveFilter(key);
        return;
      }

      if (min && max) {
        right = String(min) + " - " + String(max);
      } else if (min) {
        left = " Minimum " + left;
        right = min;
      } else if (max) {
        left = " Maximum " + left;
        right = max;
      } else {
        right = value;
      }

      displayFilter(filter, left, right);
    }

    /**
     * Parse date
     *
     * @param {Date} date   Date to parse
     *
     * @return {String}     Formatted date string
     */
    function parseDate(date) {
      if (!date) return date;
      var time = parseInt(date, 10);
      var parsed;
      if (angular.isNumber(time) && isFinite(time)) {
        parsed = new moment(time, "x");
      } else {
        parsed = new moment(date);
      }
      return parsed.utc().format("l");
    }

    /**
     * Parse date filter
     *
     * @param  {Object} filter  Current filter
     * @param  {String} key     Filter key
     * @param  {String} value   Filter value
     */
    function parseDateFilter(filter, key, value) {
      var left = filter.name;
      var right;

      var greaterThan = value.indexOf("$gt,") > -1;
      var lessThan = value.indexOf("$lt,") > -1;

      var range;
      if (greaterThan && lessThan) {
        range = parseRange(value, "$gt", "$lt");
      } else if (greaterThan) {
        range = parseRange(value, "$gt", "$lte");
      } else if (lessThan) {
        range = parseRange(value, "$gte", "$lt");
      } else {
        range = parseRange(value);
      }

      var min = range[0];
      var max = range[1];

      var minDate = null;
      var maxDate = null;

      try {
        minDate = parseDate(min);
        maxDate = parseDate(max);
        // Set display
        if (minDate && maxDate) {
          if (minDate === maxDate) {
            right = minDate;
          } else {
            right = minDate + " - " + maxDate;
          }
        } else if (minDate) {
          left += greaterThan ? " after" : " on or after";
          right = minDate;
        } else if (maxDate) {
          left += lessThan ? " before" : " on or before";
          right = maxDate;
        }
      } catch (err) {
        // Bad value
        deleteActiveFilter(key);
        return;
      }

      displayFilter(filter, left, right);
    }

    /**
     * Parse filter
     *
     * @param  {String} key     Filter key or query key
     * @param  {String} value   Filter value
     */
    function parseFilter(key, value) {
      var filterKey = parseFilterKey(key);
      var filter = findFilterByKey(filterKey);

      // Verify filter exists
      if (!filter) {
        deleteActiveFilter(filterKey);
        return;
      }

      switch (filter.type) {
        case "pintype":
          var parsePinType = parsePinTypeFilter(filter, filterKey, value);
          if (angular.isString(value)) value = JSON.parse(value);
          if (Object.prototype.hasOwnProperty.call(value, "pin_fields")) {
            parsePinType.then(function (pinValue) {
              parseNestedPinFields(pinValue, value.pin_fields);
            });
          }
          break;
        case "account":
          parseAccountFilter(filter, filterKey, value);
          break;
        case "entity":
          parseEntityFilter(filter, filterKey, value);
          break;
        case "enum":
          parseEnumFilter(filter, filterKey, value);
          break;
        case "pinfield":
          parsePinFieldFilter(filter, filterKey, value);
          break;
        case "number":
          parseNumberFilter(filter, filterKey, value);
          break;
        case "date":
          parseDateFilter(filter, filterKey, value);
          break;
        case "text":
          displayFilter(filter, filter.name, value);
          break;
        case "id": {
          var displayValue = filter.prefix + value;
          displayFilter(filter, filter.name, displayValue);
          break;
        }
        default:
          $log.warn(
            "<abx-filter-bar>: Ignoring unrecognized filter '" + filterKey + "'"
          );
          break;
      }
    }

    /**
     * Parse a given key used by the filter bar and return its query key.
     *
     * For ungrouped filters, just return the given key. However, for grouped filters
     * the child query key must be parsed from the parent filter key. This
     * function handles both cases.
     *
     * // NOTE: Will only work when the selectedValue is an ID
     * // NOTE: Only tested (for grouped filters) on entities
     *
     * @param  {String}   filterKey         The key used for display and grouping purposes
     * @param  {String}   selectedValueId   The ID of the currently selected filter value
     * @return {String}                     The key that will be used in actual
     *                                      filters/queries for a filter
     */
    function parseQueryKey(filterKey, selectedValueId) {
      var filter = findFilterByKey(filterKey);
      var groupByKey = filter.groupByKey; // The key that the filter is grouped by

      if (!groupByKey) return filterKey;

      var selectedValueObject = findSelectedFilterObjectById(selectedValueId);
      var selectedGroup = selectedValueObject[groupByKey]; // Selected filter value's parent group
      if (filter.isGrouped) {
        // Grouped filter, find specific query key
        return filter.childQueryKeys[selectedGroup];
      }

      $log.warn(
        "<abx-filter-bar>: " +
          filterKey +
          " config was provided a groupByKey, " +
          "but no mapping of groups to query keys (childQueryKeys).\n" +
          "Querying on `" +
          filterKey +
          "` for all of this filter's values."
      );
      return filterKey;
    }

    /**
     * Reverse of the function above. Parses a parent filter key from a
     * given child key. If the given key is found to already be a parent
     * filter key, just return it.
     *
     * @param  {String}   key   Either a filter or query key
     * @param  {String}   [pinFieldName]   Optional pinField name; when filtering on a pinfield across pintypes
     * (e.g. Manufacturer), the key will be "pin_field", so the pinFieldName allows us to identify the correct filter
     * @return {String}         The parsed filter key
     */
    function parseFilterKey(key, pinFieldName) {
      var filter;
      if (pinFieldName) {
        filter = self.filters.pin_field[pinFieldName];
      } else {
        filter = self.filters[key];
      }
      if (filter) return key; // Given a filter key

      // Given a child query key -- find its parent filter key
      for (var i = 0; i < self.availableFilters.length; ++i) {
        var currFilter = self.availableFilters[i];

        // Don't check ungrouped filters (since they have no child filters)
        if (!currFilter.isGrouped) continue;

        var childQueryKeysObject = currFilter.childQueryKeys;
        var childQueryLabels = Object.keys(childQueryKeysObject);
        for (var j = 0; j < childQueryLabels.length; ++j) {
          var label = childQueryLabels[j];
          var currQueryKey = childQueryKeysObject[label];

          if (key === currQueryKey) return currFilter.key;
        }
      }

      $log.error("<abx-filter-bar>: no filter found for query key " + key);
    }

    /**
     * Finds a filter in the available ones given a key.
     *
     * For ungrouped filters, this is a simple index into our available filters.
     * However, for grouped filters, we may be provided with a query key
     * whose parent (filter) key is different, so we need to go through our
     * available filters and find the parent of the child query key we were
     * provided.
     *
     * The most obvious need for this is when the filter bar is given a set of
     * filters (e.g. from query params in a URL). One query key might be a
     * child of a parent filter; we don't know which of our available filters
     * is the parent filter, so we have to find it.
     *
     * Example:
     * We are given a `maintenance_type` query param -- there is no
     * `maintenance_type` filter, but there is a parent `type` filter --
     * that is the filter that we need to parse and return.
     *
     * @param  {String}   key   Either a filter key or a query key
     * @return {String}         The filter associated with the given key, if found
     */
    function findFilterByKey(key) {
      var filter = self.filters[key.toLowerCase()];
      if (filter) return filter;

      // Given key is a query key -- find the parent filter
      var filterKey = parseFilterKey(key);
      if (filterKey) return self.filters[filterKey];

      // Filter with given key doesn't exist
    }

    /**
     * Given an ID of a filter value, find the filter value object in the list
     * of the currently selected filter's values.
     *
     * It is required for a filter category to be selected to call this function
     *
     * @param  {String}   id   The id of the filter value to be found
     * @return {Object}        The filter value with the given id, if found
     */
    function findSelectedFilterObjectById(filterValueId) {
      for (var i = 0; i < self.acceptableValues.length; ++i) {
        var selectedFilterValue = self.acceptableValues[i];

        if (selectedFilterValue._id === filterValueId)
          return selectedFilterValue;
      }
    }

    /**
     * Disply active filter
     *
     * @param  {Object} filter  Current filter
     * @param  {String} left    Left side (key)
     * @param  {String} right   Right side (value)
     */
    function displayFilter(filter, left, right) {
      if (filter.hidden) return;
      self.activeFilterDisplay[filter.key] = {
        left: left,
        right: right,
      };
      self.showActiveFilters = true;
    }

    /**
     * Build range query string value containing `$gte` and/or `$lte`.
     * If min equals max, min is returned for exact matching
     *
     * @param  {String|Number} min  Minimum value
     * @param  {String|Number} max  Maximum value
     *
     * @return {String}             Query string value
     */
    function buildRange(min, max) {
      if (min === max) {
        return min;
      } else if (min && max) {
        return "$gte," + min + ",$lte," + max;
      } else if (min) {
        return "$gte," + min;
      } else if (max) {
        return "$lte," + max;
      }
    }

    /**
     * Update a active pin field filter
     *
     * @param  {Object} field       New pin field data
     * @param  {String} dataType    [Optional] data type
     */
    function updatePinFieldFilter(key, pinField, pinValue) {
      var value;
      // Find existing pin type filter
      if (
        Object.prototype.hasOwnProperty.call(self.activeFilters, "pin_type")
      ) {
        value = self.activeFilters.pin_type;
        if (angular.isString(value)) value = JSON.parse(value);
      } else if (
        Object.prototype.hasOwnProperty.call(self.filterValue, "pin_type")
      ) {
        value = { _id: self.filterValue.pin_type._id };
      } else {
        value = { _id: pinValue.pin_type._id };
      }
      // Remove existing filter
      if (Object.prototype.hasOwnProperty.call(self.activePinFields, key)) {
        deleteActivePinFieldFilter(key);
      }
      // Create pin field array
      if (!Object.prototype.hasOwnProperty.call(value, "pin_fields")) {
        value.pin_fields = [];
      }
      switch (pinField.data_type) {
        case "integer":
        case "float":
          pinValue._id = self.filterValue._id;
          pinValue.data_type = pinField.dataType;
          if (pinValue.start) pinValue.start = parseInt(pinValue.start, 10);
          if (pinValue.end) pinValue.end = parseInt(pinValue.end, 10);
          value.pin_fields.push(pinValue);
          break;
        default:
          value.pin_fields.push({
            _id: pinValue._id,
            value: pinValue.name,
          });
          break;
      }
      return value;
    }

    /**
     * Request pin types
     *
     * @param {Object}  params  Query parameters
     *
     * @return {Promise<Object|Error>}  Promise that resolves with pin types
     */
    function getPinTypes(params) {
      return $q(function (resolve, reject) {
        if (!Object.prototype.hasOwnProperty.call(self.filters, "pin_type")) {
          $log.error("<abx-filter-bar>: missing 'pin_type' filter config");
          return reject("Pin type filter not found");
        }
        return resolve(self.filters.pin_type.values(params));
      });
    }

    /**
     * Get a pin field from a pin type
     *
     * @param  {Object} pinType     Pin type
     * @param  {String} name        Pin field name
     * @return {Object}             Pin field
     */
    function getPinField(pinType, name) {
      for (var i = 0; i < pinType.fields.length; ++i) {
        var pinField = pinType.fields[i];
        if (pinField.name === name) {
          return pinField;
        }
      }
      return null;
    }

    function getAcceptablePinFieldValues(field) {
      getPinTypes()
        .then(function (results) {
          var options = [];
          // Add each nested field according to the selected option
          for (var i = 0; i < results.length; ++i) {
            var pinType = results[i];
            if (self.selectedPinType && pinType._id !== self.selectedPinType)
              continue;
            var pinField = getPinField(pinType, field.name);
            // Skip over hidden fields
            if (pinField.is_hidden) continue;
            for (var j = 0; j < pinField.acceptable_enum_values.length; ++j) {
              options.push({
                _id: pinField._id,
                name: pinField.acceptable_enum_values[j],
                pin_type: {
                  _id: pinType._id,
                  name: pinType.name,
                },
              });
            }
          }
          self.acceptableValues = options;
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function updateUrl(queryKey, value) {
      if (!self.updateUrl) return;
      internalLocationChange = true;
      if (queryKey) {
        $location.search(queryKey, value);
      } else {
        var queryString = HttpService.buildQueryString(self.activeFilters);
        if (queryString[0] === "?") {
          queryString = queryString.substr(1);
        }
        $location.search(queryString);
      }
    }

    function addActiveFilter(filterKey, queryKey, value) {
      var filter = self.filters[filterKey];

      // For grouped filters, remove filter completely to ensure we don't select two
      // child filters of the same parent filter
      if (filter.isGrouped) {
        deleteActiveFilter(filterKey);
      }

      // allows us to add more than one pin_field to activeFilters
      if (queryKey === "pin_field") {
        if (!self.activeFilters[queryKey]) {
          // if it doesn't exist yet, define it as an array
          self.activeFilters[queryKey] = [];
        }
        // loop over all of the pinFields to determine whether to add it or replace it
        var alreadyExists = false;
        self.activeFilters[queryKey].forEach(function (pinFieldFilter) {
          if (pinFieldFilter.name === value.name) {
            alreadyExists = true;
            // replace the value with the new value
            pinFieldFilter.value = value.value;
          }
        });
        if (!alreadyExists) {
          self.activeFilters[queryKey].push(value);
        }
      } else {
        self.activeFilters[queryKey] = value;
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Add filter
     */
    function addFilter() {
      var filterKey; // Key used by the filter bar for displaying and grouping purposes
      var queryKey; // Key used in actual query strings for filtering
      var value;

      if (self.selected.custom) {
        if (
          Object.prototype.hasOwnProperty.call(
            self.customParsers,
            self.selected.key
          )
        ) {
          var parser = self.customParsers[self.selected.key];
          var parsed = parser(self.filterValue);
          if (!parsed) return;
          filterKey = parsed.key;
          value = parsed.value;
        } else {
          // Invalid config
          $log.error(
            "<abx-filter-bar>: '" +
              filterKey +
              "' filter is custom but no parser is defined"
          );
          return;
        }
      } else {
        filterKey = self.selected.key;
        switch (self.selected.type) {
          case "pintype":
            value = {
              _id: self.filterValue._id,
            };
            self.selectedPinType = self.filterValue._id;
            break;
          case "pinfield":
            switch (self.selected.field.type) {
              case "enum":
              case "number":
                filterKey = "pin_type";
                value = updatePinFieldFilter(
                  self.selected.key,
                  self.selected.field,
                  self.filterValue.value
                );
                self.selectedPinType = value._id;
                getAcceptablePinFieldValues(self.selected.field);
                break;
              case "text":
                queryKey = "pin_field";
                value = self.filterValue;
                break;
              default:
                value = self.filterValue;
                break;
            }
            break;
          case "entity":
            value = self.filterValue;

            // If a floor or room is selected, clear children filters
            var hasRoom = Object.prototype.hasOwnProperty.call(
              self.activeFilters,
              "room"
            );
            var hasAsset = Object.prototype.hasOwnProperty.call(
              self.activeFilters,
              "asset"
            );
            if (hasRoom && filterKey === "level") {
              deleteActiveFilter("room");
              delete self.activeFilterDisplay.room;
            }
            if (hasAsset && (filterKey === "level" || filterKey === "room")) {
              deleteActiveFilter("asset");
              delete self.activeFilterDisplay.asset;
            }
            break;
          case "date":
            var minDate = self.filterValue.$gte
              ? moment(self.filterValue.$gte).utc().startOf("day")
              : null;
            var maxDate = self.filterValue.$lte
              ? moment(self.filterValue.$lte).utc().endOf("day")
              : null;

            var minTime = minDate ? minDate.valueOf() : null;
            var maxTime = maxDate ? maxDate.valueOf() : null;

            if (minDate && maxDate) {
              var diff = maxDate.diff(minDate, "days", true);
              if (diff < 0) {
                // Swap date times
                var tmpTime = minTime;
                minTime = maxTime;
                maxTime = tmpTime;
                // Swap display values
                var tmpValue = angular.copy(self.filterValue.$gte);
                self.filterValue.$gte = angular.copy(self.filterValue.$lte);
                self.filterValue.$lte = tmpValue;
              }
            }
            // Set query value
            value = buildRange(minTime, maxTime);
            break;
          case "account":
            value = self.filterValue._id;
            break;
          case "number":
            var min = self.filterValue.$gte;
            var max = self.filterValue.$lte;

            try {
              min = parseInt(min, 10);
              max = parseInt(max, 10);
            } catch (err) {
              // Bad input
              return;
            }

            // Swap values if in the wrong order
            if (min && max && min > max) {
              // Swap numbers
              var tmp = min;
              min = max;
              max = tmp;
              // Swap display values
              self.filterValue.$gte = min;
              self.filterValue.$lte = max;
            }
            // Set query value
            value = buildRange(min, max);
            break;
          default:
            value = self.filterValue;
        }
      }

      queryKey = queryKey || parseQueryKey(filterKey, self.filterValue);
      addActiveFilter(filterKey, queryKey, value);

      self.onChange({ activeFilters: self.activeFilters });
      parseFilter(filterKey, value);

      // Add filter to query string
      updateUrl();
    }

    /**
     * Determine if the filter bar input is invalid (i.e. empty)
     *
     * @return {Boolean}
     */
    function isInvalid() {
      if (angular.isEmpty(self.filterValue)) return true;
      if (Object.prototype.hasOwnProperty.call(self.filterValue, "value")) {
        return angular.isEmpty(self.filterValue.value);
      }
    }

    /**
     * Remove filter
     *
     * @param  {String} filterKey   Filter key
     */
    function removeFilter(filterKey) {
      // get the config so we know what we're dealing with
      var config = self.filters[filterKey];

      if (!config) return;

      // Update self.activeFilterDisplay
      deleteActiveFilterDisplay(filterKey);

      // Update self.activeFilters
      if (config.type === "pinfield" || config.type === "pintype") {
        // this will update self.activePinFields and selectedPinType as well and will call deleteActiveFilter
        deleteActivePinFieldFilter(config.key);
      } else {
        deleteActiveFilter(config.key);
      }

      self.onChange({ activeFilters: self.activeFilters });
      self.showActiveFilters = !angular.isEmpty(self.activeFilterDisplay);
      updateUrl();
    }

    /**
     * Delete active filter display chip for the given filterKey
     * @param {String}  filterKey - Filter key
     */
    function deleteActiveFilterDisplay(filterKey) {
      delete self.activeFilterDisplay[filterKey];
      // delete pinfields associated with the pintype filter
      if (filterKey === "pin_type") {
        self.selectedPinType = null;
        if (self.selected) getAcceptablePinFieldValues(self.selected.field);
        // remove associated pin_field filter display
        for (var key in self.activePinFields) {
          var filterConfig = self.filters[key];
          if (!filterConfig) continue;
          if (filterConfig.requiresPinType) {
            delete self.activeFilterDisplay[filterConfig.key];
          }
        }
      }
    }

    /**
     * Clear all active filters
     */
    function clearFilters() {
      self.activeFilters = {};
      self.activeFilterDisplay = {};
      self.showActiveFilters = false;
      self.selectedPinType = null;
      onSelectFilterOption(self.selected);
      self.onChange({ activeFilters: self.activeFilters });
      updateUrl();
    }

    /**
     * Handle filter option selection
     *
     * @param  {Object} option  Selected option
     */
    function onSelectFilterOption(option) {
      // unset filterValue every time filter is changed
      self.filterValue = null;

      if (angular.isEmpty(option)) return;

      self.acceptableValues = [];

      switch (option.type) {
        case "account":
        case "pintype":
        case "enum":
          self.loading = true;
          $q.resolve(option.values())
            .then(function (results) {
              self.acceptableValues = results;
            })
            .finally(function () {
              self.loading = false;
            });
          break;
        case "entity":
          self.loading = true;
          requestEntities(option, option.key)
            .then(function (results) {
              self.acceptableValues = results;
            })
            .finally(function () {
              self.loading = false;
            });
          break;
        case "pinfield":
          self.filterValue = {
            value: null,
          };
          switch (option.field.type) {
            case "text":
              self.filterValue.name = option.name;
              break;
            case "number":
              self.filterValue.name = option.name;
              self.loading = true;
              getPinTypes()
                .then(function (results) {
                  if (results.length) {
                    var pinType = results[0];
                    var pinField = getPinField(pinType, option.field.name);
                    self.filterValue._id = pinField._id;
                    self.filterValue.pin_type = {
                      _id: pinType._id,
                      name: pinType.name,
                    };
                  }
                })
                .finally(function () {
                  self.loading = false;
                });
              break;
            case "enum":
              self.loading = true;
              getAcceptablePinFieldValues(option.field);
              break;
            default:
              $log.error(
                "<abx-filter-bar>: pin field type '" +
                  option.field.type +
                  "' is not supported"
              );
              break;
          }
          break;
        case "number":
        case "date":
          self.filterValue = {
            $gte: null,
            $lte: null,
            minDate: option.minDate,
            maxDate: option.maxDate,
          };
          break;
        default:
      }
    }
  }
})();
