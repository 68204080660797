(function () {
  angular
    .module("akitabox.ui.dialogs.document.tag")
    .factory("TagDocumentDialog", TagDocumentDialog);

  function TagDocumentDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/document/tag/tag-file.dialog.html",
      controller: "TagDocumentDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
      multiple: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    function show(options) {
      if (!options || !options.locals) {
        $log.error("TagDocumentDialog: no options local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options.locals.documents) {
        $log.error("TagDocumentDialog: no documents local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
