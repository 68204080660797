(function () {
  angular
    .module("akitabox.core.services.asset", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.assembly",
      "akitabox.core.services.filter",
      "akitabox.core.services.floor",
      "akitabox.core.services.http",
      "akitabox.core.services.pin",
      "akitabox.core.services.pinType",
      "akitabox.core.services.room",
      "akitabox.core.services.virtualRepeat",
    ])
    .factory("AssetService", AssetService);

  /* @ngInject */
  function AssetService(
    // Angular
    $log,
    $q,
    // Constants
    models,
    PIN_FIELD_DATA_TYPES,
    // Services
    AssemblyService,
    HttpService,
    FilterService,
    FloorService,
    PinService,
    PinTypeService,
    RoomService,
    VirtualRepeatService
  ) {
    /**
     * Asset service
     *
     * @type {Object}
     */
    var service = {
      // Routes
      buildListRoute: buildListRoute,
      buildDetailRoute: buildDetailRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getBFF: getBFF,
      getById: getById,
      getByOrganization: getByOrganization,
      getAll: getAll,
      getAllBFF: getAllBFF,
      getAllTypeAheadByBuilding: getAllTypeAheadByBuilding,
      getAllByOrganization: getAllByOrganization,
      getByBFFOrganization: getByBFFOrganization,
      getByBFFBuilding: getByBFFBuilding,
      getValueById: getValueById,
      getAllValues: getAllValues,
      getFloor: getFloor,
      getRoom: getRoom,
      populatePinType: populatePinType,
      populateFloor: populateFloor,
      populateRoom: populateRoom,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrganization: getStatsByOrganization,

      // BFF
      getTypeAheads,
      getAllTypeAheads,
      // Update
      updateById: updateById,
      updateValue: updateValue,
      updateValues: updateValues,
      updateValueByDataType: updateValueByDataType,
      setLocation: setLocation,
      updateRoom: updateRoom,
      updateFloor: updateFloor,
      recommission: recommission,
      decommission: decommission,
      classify: classify,
      updateQuantity: updateQuantity,
      // Delete
      deleteAsset: deleteAsset,
      // Virtual Repeat
      getVirtualRepeat: getVirtualRepeat,
      // Filters
      getBuildingListFilter: getBuildingListFilter,
      setBuildingListFilter: setBuildingListFilter,
      // Helpers
      /**
       * NOTE: Additional helpers for asset pin values found in
       * PinValueService
       */
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Build base asset route
     *
     * @param  {String} buildingId    Building ID
     * @return {String}               Route
     * @see {@link buildBaseBFFRoute} For the BFF version.
     */
    function buildBaseRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.ASSET.ROUTE_PLURAL
      );
    }

    /**
     * Build base asset route for BFF
     *
     * @param  {String} buildingId    Building ID
     * @return {String}               Route
     * @see {@link buildBaseRoute} For the BFF version.
     */
    function buildBaseBFFRoute(organizationId) {
      return (
        "/organizations/" + organizationId + "/web/" + models.ASSET.ROUTE_PLURAL
      );
    }

    function buildTypeheadBffRoute(organizationId) {
      return `/${models.ORGANIZATION.ROUTE_PLURAL}/${organizationId}/web/assets_typeahead`;
    }

    /**
     * Build asset or asset pin value detail route
     *
     * @param  {String} buildingId      Building ID
     * @param  {String} assetId         Asset ID
     * @param  {String} [pinValueId]    Pin value ID
     * @return {String}                 Route
     */
    function buildDetailRoute(buildingId, assetId, pinValueId) {
      var base = buildBaseRoute(buildingId);
      // Pin value detail
      if (!angular.isEmpty(pinValueId)) {
        return (
          base +
          "/" +
          assetId +
          "/" +
          models.PIN_VALUE.ROUTE_PLURAL +
          "/" +
          pinValueId
        );
      }
      // Asset detail
      return base + "/" + assetId;
    }

    /**
     * Build asset or asset pin value list route
     *
     * @param  {String} buildingId  Building ID
     * @param  {String} [assetId]   Asset ID
     * @return {String}             route
     */
    function buildListRoute(buildingId, assetId) {
      var base = buildBaseRoute(buildingId);
      // Pin value list
      if (!angular.isEmpty(assetId))
        return base + "/" + assetId + "/" + models.PIN_VALUE.ROUTE_PLURAL;
      // Asset list
      return base;
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" + organizationId + "/" + models.ASSET.ROUTE_PLURAL
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    /**
     * Create an asset
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 data        New asset data
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with created asset
     */
    function create(buildingId, data, params) {
      var route = buildListRoute(buildingId);
      return PinService.create(data, route, params);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    /**
     * Get a list of assets
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * @see {@link getBFF} for the BFF version.
     */
    function get(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      return PinService.get(buildingId, route, params, options);
    }

    /**
     * Get a list of assets using BFF
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * @see {@link get} for the non-BFF version.
     */
    function getBFF(buildingId, params, options, organization) {
      var route = buildBaseBFFRoute(organization);
      return PinService.get(buildingId, route, params, options);
    }

    /**
     * Get a list of all assets
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * @see {@link getAllBFF} for the BFF version.
     */
    function getAll(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      return PinService.getAll(buildingId, route, params, options);
    }

    /**
     * Get a list of all assets using BFF
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * @see {@link getAll} for the non-BFF version.
     */
    function getAllBFF(buildingId, params, options, organization) {
      var route = buildBaseBFFRoute(organization);
      return PinService.getAll(buildingId, route, params, options);
    }

    /**
     * Get a list of all assets using BFF typeAhead
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @param  {String}                 [organization] Organization ID
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * Note: This function is also aimed at BFF and it doesn't have a non-BFF version.
     */
    function getAllTypeAheadByBuilding(
      buildingId,
      params,
      options,
      organization
    ) {
      var route = buildTypeheadBffRoute(organization);
      return PinService.getAll(buildingId, route, params, options);
    }

    /**
     * Get an asset by ID
     *
     * @param  {String}                 buildingId                Building ID
     * @param  {String}                 assetId                   Asset ID
     * @param  {Object}                 [params]                  Query params
     * @param  {Object}                 [options]                 Option to include certain fields
     * @param  {Boolean}                [options.includePinType]  Replace asset's pinType with
     *                                                              populated pin type object
     * @param  {Boolean}                [options.includeFloor]    Attach populated floor object
     * @param  {Boolean}                [options.includeRoom]     Attach populated room object
     * @return {Promise<Object|Error>}                            Promise that resolves with asset
     *
     * Note: this function doesn't have a BFF version.
     */
    function getById(buildingId, assetId, params, options) {
      // Make sure include_values and our options' includes agree with each other
      var willNeedValues =
        options && (options.includeFloor || options.includeRoom);
      var includeValues = params && params.include_values;
      if (willNeedValues && !includeValues) {
        // Can't include floor or room if we're not populating values
        $log.error(
          "AssetService: params.include_values must be true if trying to include " +
            "floor or room"
        );
        return $q.reject(new Error("Error retrieving asset"));
      }

      var route = buildDetailRoute(buildingId, assetId);
      var asset;
      return PinService.getById(assetId, route, params)
        .then(function (_asset) {
          asset = _asset;
          if (options) {
            // Populate fields specified in `options`
            var requests = [];

            if (options.includePinType) {
              requests.push(populatePinType(asset));
            }
            if (options.includeFloor) {
              requests.push(populateFloor(asset));
            }
            if (options.includeRoom) {
              requests.push(populateRoom(asset));
            }

            return $q.all(requests);
          }
        })
        .then(function () {
          return asset;
        });
    }

    function populateRoom(asset) {
      return getRoom(asset.building, asset.values).then(function (room) {
        asset.room = room;
        return asset;
      });
    }

    function populateFloor(asset) {
      return getFloor(asset.building, asset.values).then(function (floor) {
        asset.level = floor;
        return asset;
      });
    }

    function populatePinType(asset) {
      return PinTypeService.getById(asset.building, asset.pinType).then(
        function (pinType) {
          asset.pinType = pinType;
          return asset;
        }
      );
    }
    /**
     * Get a list of assets by organization
     *
     * @param  {String}                 organizationId  Organization ID
     * @param  {Object}                 [params]        Query params
     * @param  {Object}                 [options]       Option to includePinType as a populated object
     * @return {Promise<Array>}                         Promise that resolves with asset list
     *
     * @see {@link getByBFFOrganization} for a BFF version (with no `options` parameter).
     */
    function getByOrganization(organizationId, params, options) {
      var route = buildOrganizationListRoute(organizationId);
      return PinService.getByOrganization(route, params, options);
    }

    /**
     * Get a list of assets by organization using BFF
     *
     * @param  {String}                 organizationId  Organization ID
     * @param  {Object}                 [params]        Query params
     * @return {Promise<Array>}                         Promise that resolves with asset list
     *
     * @see {@link getByOrganization} for a non-BFF version (with additional `options` parameter).
     */
    function getByBFFOrganization(organizationId, params) {
      const route = buildBaseBFFRoute(organizationId);
      return HttpService.get(route, params);
    }

    /**
     * Get a list of all organization assets
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * Note: this function doesn't have a BFF version.
     */
    function getAllByOrganization(organizationId, params, options) {
      var route = buildOrganizationListRoute(organizationId);
      return PinService.getAllByOrganization(route, params, options);
    }

    /**
     * Note: this function doesn't have a non-BFF version.
     */
    function getByBFFBuilding(organizationId, buildingId, params) {
      const route = buildBaseBFFRoute(organizationId);
      const newParams = {
        building: `$in,${buildingId}`,
        ...params,
      };
      return HttpService.get(route, newParams);
    }

    /**
     * Get an asset pin value by ID
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @param  {String}                 pinValueId  Pin value ID
     * @return {Promise<Object|Error>}              Promise that resolves with pin value
     */
    function getValueById(buildingId, assetId, pinValueId) {
      var route = buildDetailRoute(buildingId, assetId, pinValueId);
      return HttpService.get(route);
    }

    /**
     * Get a list of pin values
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @return {Promise<Object|Error>}              Promise that resolves with pin value list
     */
    function getAllValues(buildingId, assetId) {
      var route = buildListRoute(buildingId, assetId);
      return HttpService.getAll(route);
    }

    /**
     * Get a floor from the pinValues
     *
     * @param {String}                  buildingId      Building ID
     * @param {Object}                  pinValues       Asset PinValues
     * @return {Promise<Object|Error>}                  Promise that resolves with the floor
     */
    function getFloor(buildingId, pinValues) {
      for (var valueKey in pinValues) {
        var value = pinValues[valueKey];
        if (value.is_level_pin_value) {
          if (angular.isEmpty(value.value)) break;
          return FloorService.getById(buildingId, value.value);
        }
      }
      return $q.resolve(null);
    }

    /**
     * Get a room from the pinValues
     *
     * @param {String}                  buildingId      Building ID
     * @param {Object}                  pinValues       Asset PinValues
     * @return {Promise<Object|Error>}                  Promise that resolves with the room
     */
    function getRoom(buildingId, pinValues) {
      var params = {
        include_values: true,
      };
      for (var valueKey in pinValues) {
        var value = pinValues[valueKey];
        if (value.is_room_pin_value) {
          if (angular.isEmpty(value.value)) break;
          return RoomService.getById(buildingId, value.value, params);
        }
      }
      return $q.resolve(null);
    }

    /**
     * Get stats for a building
     * @param {String} buldingId Building ID
     * @param {Object} params Params
     */
    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getStatsByOrganization(organizationId, params) {
      var route = "/stats" + buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    /**
     * Note: This function is aimed at BFF and it doesn't have a non-BFF version.
     */
    function getTypeAheads(organizationId, params) {
      const route = buildTypeheadBffRoute(organizationId);
      return HttpService.get(route, params);
    }

    /**
     * Note: This function is aimed at BFF and it doesn't have a non-BFF version.
     */
    function getAllTypeAheads(organizationId, params) {
      const route = buildTypeheadBffRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    /******************* *
     *   Update
     * ****************** */

    /**
     * Update an asset
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @param  {Object}                 data        Updated asset data
     * @param  {Object}                 [params]    Query params to include values
     * @param  {Object}                 [asset]     Original asset being
     *     updated. Pass this in if you want to retain its populated fields.
     * @return {Promise<Object|Error>}              Promise that resolves with updated asset
     */
    function updateById(buildingId, assetId, data, params, asset) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.updateById(data, route, params, asset);
    }

    /**
     * Update an asset pin value
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @param  {String}                 pinValueId  Pin value ID
     * @param  {Object}                 data        Updated pin value data
     * @return {Promise<Object|Error>}              Promise that resolves with updated pin value
     */
    function updateValue(buildingId, assetId, pinValueId, data) {
      var route = buildDetailRoute(buildingId, assetId, pinValueId);
      return PinService.updateValue(data, route);
    }

    function updateValues(buildingId, assetId, data) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.updateValues(data, route);
    }

    /**
     * Update an asset pin value by its data type.
     *
     * This is necessary because updates to the floor and room needs special handling. If the value
     * being updated does not need special handling, just do the standard update.
     *
     * @param  {String}                 buildingId      Building ID
     * @param  {String}                 dataType        Data type of value to update
     * @param  {String}                 assetId         Asset ID
     * @param  {String}                 valueId         Pin value ID
     * @param  {*}                      newValue        New pin value (just the value, not a pin value model)
     *                                                  Valid to send in full objects for data type that
     *                                                  are entities (e.g. floors or rooms)
     * @param  {Object}                 [options.pin]   The asset model being modified. Pass in to preserve
     *                                                  populated asset fields on room/level changes.
     * @return {Promise<Object|Error>}                  Promise that resolves with updated pin value
     */
    function updateValueByDataType(
      buildingId,
      dataType,
      assetId,
      valueId,
      newValue,
      options
    ) {
      if (!options) {
        options = {};
      }
      var data = {
        // For values that come in as model objects, the pin value expects to get
        // the ID
        value: newValue && newValue._id ? newValue._id : newValue,
      };

      if (dataType === PIN_FIELD_DATA_TYPES.FLOOR) {
        return service
          .updateFloor(buildingId, assetId, data)
          .then(optionallyPreserveAssetFields);
      } else if (dataType === PIN_FIELD_DATA_TYPES.ROOM) {
        return service
          .updateRoom(buildingId, assetId, data)
          .then(optionallyPreserveAssetFields);
      }

      return service.updateValue(buildingId, assetId, valueId, data);

      function optionallyPreserveAssetFields(updatedAsset) {
        if (options.pin) {
          return PinService.copyPopulatedPinProps(updatedAsset, options.pin);
        }
        return updatedAsset;
      }
    }

    /**
     * Update an asset's location (coordinates on floor plan)
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @param  {Object}                 data        New asset location data
     * @param  {Object}                 [params]    Query params to include values
     * @param  {Object}                 [asset]     Original asset being
     *     updated. Pass this in if you want to retain its populated fields.
     * @return {Promise<Object|Error>}              Promise that resolves with updated asset
     */
    function setLocation(buildingId, assetId, data, params, asset) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.setLocation(data, route, params, asset);
    }

    /**
     * Update an asset's room
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @param  {Object}                 data        New asset room data
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with updated asset
     */
    function updateRoom(buildingId, assetId, data, params) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.updateRoom(data, route, params);
    }

    /**
     * Update an asset's floor
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @param  {Object}                 data        New asset floor data
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error}               Promise that resolves with updated asset
     */
    function updateFloor(buildingId, assetId, data, params) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.updateFloor(data, route, params);
    }

    /**
     * Recommission an asset.
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     */
    function recommission(buildingId, assetId) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.recommission(route);
    }

    /**
     * Decommission an asset
     * @param {String} buildingId - building's id
     * @param {String} assetId - asset's id
     * @param {Date} decommissionDate - date to decommission
     * @return {Promise<Asset>} decommissioned asset
     */
    function decommission(buildingId, assetId, decommissionDate) {
      var route = buildDetailRoute(buildingId, assetId);
      return PinService.decommission(route, decommissionDate);
    }

    /**
     * Update an asset's quantity
     * @param {String} buildingId - building's id
     * @param {String} assetId - asset's id
     * @param {Number} quantity - new quantity
     */
    function updateQuantity(buildingId, assetId, quantity) {
      var route = buildDetailRoute(buildingId, assetId);
      const body = {
        action: "updateQuantity",
        quantity: quantity,
      };
      return HttpService.put(route, body);
    }

    /**
     * Classify an asset
     * @param {String} buildingId - building's id
     * @param {String} assetId - asset's id
     * @param {CatalogItem} catalogItem - item to classify the asset as, or null to remove classification
     * @param {Number} quantity - quantity for the asset/cost line
     * @param {Date} assessedDueDate - assessed due date for cost line
     * @param {Date} scheduledDueDate - scheduled due date for cost line
     * @return {Promise<Asset>} decommissioned asset
     */
    function classify(
      buildingId,
      assetId,
      catalogItem,
      quantity,
      assessedDueDate,
      scheduledDueDate
    ) {
      var route = buildDetailRoute(buildingId, assetId);
      var body;
      if (catalogItem && quantity !== undefined && quantity !== null) {
        var unitCostCents = catalogItem.unit_cost_cents;
        var unitCost = unitCostCents === null ? 0 : unitCostCents / 100;
        var laborUnitCostCents = catalogItem.labor_unit_cost_cents;
        var laborUnitCost =
          laborUnitCostCents === null ? 0 : laborUnitCostCents / 100;
        var equipmentUnitCostCents = catalogItem.equipment_unit_cost_cents;
        var equipmentUnitCost =
          equipmentUnitCostCents === null ? 0 : equipmentUnitCostCents / 100;
        var materialsUnitCostCents = catalogItem.materials_unit_cost_cents;
        var materialsUnitCost =
          materialsUnitCostCents === null ? 0 : materialsUnitCostCents / 100;
        body = {
          action: "classify",
          abx_catalog: catalogItem.catalog,
          abx_catalog_item: catalogItem.id,
          quantity: quantity,
          quantity_unit: catalogItem.quantity_unit,
          size: catalogItem.size,
          size_unit: catalogItem.size_unit,
          suggested_cost: unitCost,
          suggested_labor_cost: laborUnitCost,
          suggested_equipment_cost: equipmentUnitCost,
          suggested_materials_cost: materialsUnitCost,
          suggested_interval: catalogItem.frequency,
          description: catalogItem.name,
          uniformat: catalogItem.item_id,
          scheduled_due_date: scheduledDueDate,
          assessed_due_date: assessedDueDate,
        };
      } else {
        body = {
          action: "classify",
          abx_catalog: null,
          abx_catalog_item: null,
        };
      }
      return HttpService.put(route, body);
    }

    /******************* *
     *   Delete
     * ****************** */

    /**
     * Delete an asset
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 assetId     Asset ID
     * @return {Promise<Object|Error}               Promise
     */
    function deleteAsset(buildingId, assetId) {
      var route = buildDetailRoute(buildingId, assetId);
      return HttpService.remove(route);
    }

    /******************* *
     *   Virtual Repeat
     * ****************** */

    /**
     * Build a virtual repeat for fetching assets
     *
     * @param  {String} buildingId      Building ID
     * @param  {Number} fetchLimit      Query limit
     * @param  {Object} [query]         Query object
     * @param  {Object} [options]       Options to pass along to GET call
     * @return {Object}                 Virtual repeat object prototype
     * @param  {String} organizationId  Organization ID
     */
    function getVirtualRepeat(organizationId, fetchLimit, query, options) {
      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service.getByBFFOrganization(organizationId, params, options);
      }
    }

    /******************* *
     *   Filters
     * ****************** */

    /**
     * Retrieve cached building asset list filter
     *
     * @param  {String} buildingId  Building ID
     * @return {Object}             Filter object
     */
    function getBuildingListFilter(buildingId) {
      return FilterService.getBuildingFilter(buildingId, "asset");
    }

    /**
     * Cache the provided building asset list filter
     *
     * @param {String} buildingId   Building ID
     * @param {Object} filter       Filter object
     */
    function setBuildingListFilter(buildingId, filter) {
      FilterService.setBuildingFilter(buildingId, "asset", filter);
    }
  }
})();
