(function () {
  angular
    .module("akitabox.desktop.directives.list.inspectionProgram", [
      "akitabox.core.constants",
      "akitabox.core.toast",
      "akitabox.core.services.flag",
      "akitabox.core.services.shadow",
      "akitabox.core.services.user",
      "angular.filter",
    ])
    .controller(
      "AbxInspectionProgramListController",
      InspectionProgramListController
    )
    .directive("abxInspectionProgramList", AbxInspectionProgramListDirective);

  /* @ngInject */
  function AbxInspectionProgramListDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/list/inspection-program/inspection-program-list.html",
      controller: "AbxInspectionProgramListController",
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        status: "=abxStatus",
        parentFetch: "&abxFetch",
        parentFetchAll: "&abxFetchAll",
      },
    };
  }

  /* @ngInject */
  function InspectionProgramListController(
    // Angular
    $scope,
    // Services
    BuildingService,
    InspectionProgramService,
    OrganizationService,
    UserService
  ) {
    var self = this;

    // Attributes
    self.permissions = UserService.getPermissions();
    self.organization = OrganizationService.getCurrent();
    self.buildings = {};

    // Functions
    self.getListActions = getListActions;
    self.getInspectionPrograms = getInspectionPrograms;
    self.getAllInspectionPrograms = getAllInspectionPrograms;
    self.getFrequencySummary = getFrequencySummary;
    self.getActiveBuildingAssignees = getActiveBuildingAssignees;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watchCollection("vm.models", function (models) {
        if (models && !self.building) {
          BuildingService.populateBuildings(self.buildings, models);
        }
      });
    }

    function getFrequencySummary(inspectionProgram) {
      return InspectionProgramService.getFrequencySummary(inspectionProgram);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getListActions(items) {
      // TODO: Implemnt this function when this list is fleshed out.
      // The definition is left here to hopefully be a helpful skeleton.
      var actions = [];
      return actions;
    }

    /**
     * Extracts assignees for the current building (if there is one). Returns
     * an empty array if there is no active building, or it has no component
     * on the current IP (shouldn't be possible)
     * @param {InspectionProgram} inspectionProgram
     * @return {Assignee[]}
     */
    function getActiveBuildingAssignees(inspectionProgram) {
      if (self.building) {
        var component = inspectionProgram.inspection_components.find(function (
          component
        ) {
          return component.building === self.building._id;
        });
        return component ? component.assignees : [];
      } else {
        return [];
      }
    }

    function getInspectionPrograms(skip, limit) {
      return self.parentFetch({
        skip: skip,
        limit: limit,
      });
    }

    function getAllInspectionPrograms(limit) {
      return self.parentFetchAll({
        limit: limit,
      });
    }
  }
})();
