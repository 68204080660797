(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("ManagedMultipleValueFilter", function (ManagedModelFieldFilter) {
      /**
       * @param {object} options
       * @param {() => string} options.getBuildingId - A function that should return the
       *  building ID to use for fetching enum options/chip text.
       * @param {() => object} options.getEnumOptions - A function that should return
       *  the a list of assignable identities.
       * @param {() => object} [options.modelValueToChipText] - Model Value To chip text override
       * @param {() => object} [options.filterValueToModelValue] - Filter value to model value override
       * @param {() => object} [options.modelValueToFilterValue] - Model value to Filter value override
       */
      function ManagedMultipleValueFilter(manager, options) {
        if (!options) {
          throw new Error(
            "ManagedMultipleValueFilter: Options must be provided"
          );
        }

        options.isMultiChip = true;

        ManagedModelFieldFilter.call(this, manager, options);
      }

      // ManagedMultipleValueFilter extends ManagedModelFieldFilter
      ManagedMultipleValueFilter.prototype = Object.create(
        ManagedModelFieldFilter.prototype
      );

      /**
       * Provide models for a given filter value string
       * @param {string} values
       * @return { Promise<Identity> }
       */
      ManagedMultipleValueFilter.prototype.filterValueToModelValue = function (
        values
      ) {
        return values.split(",").slice(1);
      };

      /**@
       * @param {object={}} params - What to pass into the mongo query as the filters
       *
       * Fetch enum options for this filter.
       * @return {Promise<[]>} - An array of enum options, one for each room.
       *
       * @param {object} params
       * @param {boolean} [params.noLimit]
       */

      ManagedMultipleValueFilter.prototype.applyFilter = function (
        filterValue,
        inputModel
      ) {
        var filterConfig = this;
        var existingFilterValue = this.getFilterValue(filterConfig.manager);

        if (!existingFilterValue) {
          filterValue = "$in," + filterValue;
        } else {
          var parts = existingFilterValue.split(",");
          parts.push(filterValue);
          filterValue = parts.join(",");
        }

        ManagedModelFieldFilter.prototype.applyFilter.call(
          filterConfig,
          filterValue,
          inputModel
        );
      };

      ManagedMultipleValueFilter.prototype.removeFilter = function (
        inputModel
      ) {
        var filterConfig = this;
        var newFilterValue = filterConfig
          .getFilterValue(filterConfig.manager)
          .split(",")
          .filter(function (filterValue) {
            return filterValue !== inputModel;
          })
          .join(",");

        if (newFilterValue === "$in") {
          // nothing left, remove entirely
          filterConfig.manager.removeModelFieldFilter(
            filterConfig.getQueryField(inputModel)
          );
          return;
        }

        // update and remove the desired filter portion
        ManagedModelFieldFilter.prototype.applyFilter.call(
          filterConfig,
          newFilterValue,
          inputModel
        );
      };

      return ManagedMultipleValueFilter;
    });
})();
