(function () {
  /**
   * @ngdoc component
   * @name abxTabs
   *
   * @description
   * <abx-tabs abx-active-tab="1">
   *  <abx-tab></abx-tab>
   *  <abx-tab></abx-tab>
   * </abx-tabs>
   * <abx-tab-content>Your dynamic content here</abx-tab-content>
   */
  angular.module("akitabox.ui.components.tabs", []).component("abxTabs", {
    bindings: {
      activeTab: "<abxActiveTab",
    },
    transclude: true,
    controller: AbxTabsController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/tabs/tabs.component.html",
  });

  /* @ngInject */
  function AbxTabsController($timeout, $scope, $element) {
    var self = this;

    self.$onInit = function () {
      $scope.$watch("vm.activeTab", setActiveTab);

      $timeout(function () {
        var $items = $element.find("abx-tab");

        for (var i = 0; i < $items.length - 1; ++i) {
          var item = $items[i];

          // adds an element to space out the tabs
          angular.element(item).after("<abx-tab-spacer></abx-tab-spacer>");
        }
      });
    };

    function setActiveTab(newValue, oldValue) {
      $timeout(function () {
        var $items = $element.find("abx-tab");

        if (!$items.length) return;

        var oldSelectedTab = $items[oldValue];
        if (oldSelectedTab) {
          angular.element(oldSelectedTab).removeClass("abx-selected");
        }
        var newSelectedTab = $items[newValue];
        angular.element(newSelectedTab).addClass("abx-selected");
      });
    }
  }
})();
