(function () {
  angular.module("akitabox.ui.directives.activityList", [
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.filters.trustAs",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.note",
    "akitabox.core.services.request",
    "akitabox.core.services.organization",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.note.delete",
    "akitabox.ui.dialogs.workOrderLog.delete",
    "akitabox.ui.dialogs.workOrderLog.createUpdate",
  ]);
})();
