(function () {
  angular
    .module("akitabox.desktop.inspectionProgram.detail")
    .controller(
      "InspectionProgramChecklistDetailsController",
      InspectionProgramChecklistDetailsController
    );

  /** @ngInject */
  function InspectionProgramChecklistDetailsController(
    // Angluar
    $q,
    $scope,
    // AkitaBox
    models,
    //Events
    EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE,
    // Resolves
    organization,
    inspectionProgram,
    checklist,
    // Services
    AttachmentService,
    EnvService,
    FloorService,
    NoteService,
    PinTypeService,
    HttpService,
    WorkOrderService
  ) {
    var self = this;

    // Public Attributes
    self.inspectionProgram = inspectionProgram;
    self.loading = true;
    self.organization = organization;
    self.selectedStop = checklist;
    self.workOrder = null;
    self.pinType = null;
    self.pinFloor = null;
    self.noteData = {};
    self.attachmentData = {};
    self.planViewUrl = getPlanViewUrl();

    self.getPinType = getPinType;
    self.getNumNotes = getNumNotes;
    self.getNumAttachments = getNumAttachments;
    self.getNumWorkOrders = getNumWorkOrders;

    // Functions

    self.$onInit = function () {
      // Get the breadcrumbs set
      $scope.$emit(EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE, {
        page: "ip-checklist-details",
        checklist: checklist,
      });

      self.loading = true;

      var workOrderRequest = WorkOrderService.getById(
        checklist.building._id,
        checklist.task
      ).then(function (workOrder) {
        if (workOrder) {
          self.workOrder = workOrder;
        }
      });
      var pinTypeRequest = getPinType(checklist);
      var attachmentsRequest = fetchAttachmentsData();
      var notesRequest = fetchNotesData();
      var requests = [
        workOrderRequest,
        pinTypeRequest,
        attachmentsRequest,
        notesRequest,
      ];

      $q.all(requests).then(function () {
        self.loading = false;
      });
    };

    function getPlanViewUrl() {
      var pin = checklist.room || checklist.asset;
      var level;
      if (!pin || !pin.level) {
        if (checklist.level) {
          level = checklist.level._id;
        } else {
          return null;
        }
      } else {
        level = pin.level;
      }

      var route =
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        checklist.building._id +
        "/" +
        models.FLOOR.ROUTE_PLURAL +
        "/" +
        level;
      var params = {
        round_task: checklist.task,
        stop: checklist._id,
      };

      var query = HttpService.buildQueryString(params);
      return EnvService.getPlanViewUrl(route, query);
    }

    // ------------------------
    //   Utilities
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Get data for selected Stop
     * @param {Object} stop
     */
    function getPinType(stop) {
      var pin = stop.room || stop.asset;
      if (pin) {
        return PinTypeService.getById(pin.building, pin.pinType).then(function (
          pinType
        ) {
          self.pinType = pinType;
        });
      }
    }

    function getNumAttachments(stop) {
      if (self.attachmentData) {
        return self.attachmentData[stop._id] || 0;
      }
      return 0;
    }

    function getNumNotes(stop) {
      if (self.noteData) {
        return self.noteData[stop._id] || 0;
      }
      return 0;
    }

    function getNumWorkOrders(stop) {
      return stop.reactive_inspection_tasks.length || 0;
    }

    function fetchAttachmentsData() {
      return AttachmentService.getStatsByBuilding(
        self.selectedStop.building._id,
        {
          entity_type: "checklist",
          unwind_field: "links",
          group_field: "links.entity_id",
        }
      ).then(function (stats) {
        // Create a lookup map of attachment counts by checklist ID
        self.attachmentData = stats.reduce(function (map, stat) {
          map[stat._id] = stat.result;
          return map;
        }, {});
      });
    }

    function fetchNotesData() {
      return NoteService.getStatsByBuilding(self.selectedStop.building._id, {
        checklist: "$ne,null",
        group_field: "checklist",
      }).then(function (stats) {
        // Create a lookup map of note counts by checklist ID
        self.noteData = stats.reduce(function (map, stat) {
          map[stat._id] = stat.result;
          return map;
        }, {});
      });
    }

    // ------------------------
    //   Private Functions
    // ------------------------
  }
})();
