import { reactFromAngular } from "../reactFromAngular";

// TODO
type Org = any;
type Building = any;
// TODO: Finish this interface declaration and add binding configs
// to sidebar component when we start using the <AngularShell> component
export interface AbxSideBarProps {
  abxOrganization: Org;
  abxBuilding: Building | null;
}
export const AbxSideBar = reactFromAngular<AbxSideBarProps>("abxSideBar");
