import { useCallback, useMemo, useState } from "react";
import { Autocomplete, Paper, TextField } from "@mui/material";
import {
  GridFilterOperator,
  GridRenderHeaderFilterProps,
  GridSingleSelectColDef,
  useGridApiContext,
  ValueOptions,
} from "@mui/x-data-grid-pro";

export const SearchableSingleSelectFilter = (
  props: GridRenderHeaderFilterProps
) => {
  const { item, inputRef } = props;
  const apiRef = useGridApiContext();
  const column = apiRef.current.getColumn(item.field) as GridSingleSelectColDef;
  const headerName = column.headerName;
  const valueOptions = useMemo(
    () =>
      typeof column.valueOptions === "function"
        ? column.valueOptions({ field: item.field, row: {} })
        : column.valueOptions || [],
    [column, item.field]
  );
  const [value, setValue] = useState<ValueOptions | null>(
    valueOptions.find((option: any) => option.value === item.value) || null
  );
  const [inputValue, setInputValue] = useState("");

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | { value: string; label: string } | null
    ) => {
      if (typeof newValue === "string") {
        setValue({ value: "", label: newValue });
        apiRef.current.deleteFilterItem({ ...item, value: "" });
      } else {
        setValue(newValue);
        apiRef.current.upsertFilterItem({
          ...item,
          value: newValue?.value || "",
          operator: "is",
        });
      }
    },
    [apiRef, item]
  );
  const [open, setOpen] = useState(false);

  const CustomPaper = (props: any) => {
    return <Paper style={{ width: 300 }} elevation={8} {...props} />;
  };
  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      fullWidth
      options={valueOptions}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={headerName}
          variant="standard"
          onFocus={(event: any) => {
            event.stopPropagation();
          }}
          value={(value: any) => value?.label || ""}
          inputRef={inputRef as React.MutableRefObject<HTMLInputElement>}
        />
      )}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event: any, newInputValue: any) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option: any) => {
        return option?.label || "";
      }}
      isOptionEqualToValue={(option: any, value) =>
        option.value === value.value
      }
      groupBy={(option: any) =>
        option.group ? option.group : option.label[0].toUpperCase()
      }
      slots={{ paper: CustomPaper }}
    />
  );
};

export const getTypeaheadSelectOperator = (
  valueOptions: any
): GridFilterOperator[] => {
  return [
    {
      label: "Is",
      value: "is",
      headerLabel: "Is",
      getValueAsString: (filterValue) => {
        const option = valueOptions.find(
          (option: any) => option.value === filterValue
        );
        return option ? option.label : filterValue;
      },
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value) {
          return null;
        }
        return ({ value }) => value === filterItem.value;
      },
      InputComponent: SearchableSingleSelectFilter,
      InputComponentProps: { type: "singleSelect" },
    },
  ];
};
