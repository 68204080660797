(function () {
  angular
    .module("akitabox.desktop.workOrder.detail")
    .controller("WorkOrderRequestTabController", WorkOrderRequestTabController);

  /* @ngInject */
  function WorkOrderRequestTabController(
    $timeout,
    building,
    workOrder,
    RequestService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.rendering = true;
    self.building = building;
    self.workOrder = workOrder;
    self.request = null;

    init();

    function init() {
      var requestId = workOrder.request._id;
      RequestService.getById(building._id, requestId)
        .then(function (request) {
          self.request = request;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
          $timeout(function () {
            self.rendering = false;
          }, 500);
        });
    }
  }
})();
