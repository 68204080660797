/** @see https://mui.com/x/react-data-grid/getting-started/#typescript */
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { ComponentType, FC, FunctionComponent, ReactNode } from "react";
import { PropsOf, ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { WebClientSWRConfig } from "./WebClientSWRConfig";
import { GlobalSnackbar } from "./consecutive-snackbar/GlobalSnackbar";

const headerConfig = {
  fontFamily: ["Montserrat", "sans-serif"].join(","),
};

export const theme = createTheme({
  typography: {
    fontFamily: `Open Sans, sans-serif`,
    h1: {
      ...headerConfig,
    },
    h2: {
      ...headerConfig,
    },
    h3: {
      ...headerConfig,
    },
    h4: {
      ...headerConfig,
    },
    h5: {
      ...headerConfig,
    },
    h6: {
      ...headerConfig,
    },
  },
  zIndex: {
    modal: 9999,
  },
});

export const WebClientContexts: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => (
  <EmotionThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>
      <GlobalSnackbar>{children}</GlobalSnackbar>
    </MuiThemeProvider>
  </EmotionThemeProvider>
);

export function withWebClientContexts<T extends ComponentType<any>>(
  Component: T
) {
  const result: FC<PropsOf<T>> = (props) => (
    <WebClientContexts>
      <WebClientSWRConfig>
        <Component {...props} />
      </WebClientSWRConfig>
    </WebClientContexts>
  );

  return result;
}
