(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.details.floor
   */
  angular
    .module("akitabox.desktop.directives.details.floor", [
      "ui.router",
      "akitabox.constants",
      "akitabox.ui",
      "akitabox.core.router",
      "akitabox.core.services.asset",
      "akitabox.core.services.floor",
      "akitabox.core.services.room",
      "akitabox.core.services.tagCategory",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.document.select",
    ])
    .directive("abxFloorDetails", AbxFloorDetailsDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.details.floor
   * @name AbxFloorDetailsDirective
   *
   * @description
   * `<abx-floor-details>` displays the details of a floor and allows for editing
   * of fields based on the current user's permissions
   *
   * @param {Object}      ng-model            The component's model (floor)
   * @param {Object}      ng-model-options    Allows tuning of the way in which
   *                                          the `ng-model` is updated
   * @param {expression}  ng-change           Expression evaluated when the model
   *                                          value changes
   * @param {Object}      abx-building        Building that the floor belongs to
   *
   * @usage
   * <hljs lang="html">
   *   <abx-floor-details
   *       ng-model="vm.floor"
   *       ng-change="vm.onFloorChanged(floor)"
   *       abx-building="vm.building">
   *   </abx-floor-details>
   * </hljs>
   *
   * @ngInject
   */
  function AbxFloorDetailsDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/details/floor/floor-details.html",
      require: ["abxFloorDetails", "ngModel"],
      controller: AbxFloorDetailsController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=abxBuilding",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Readonly
      vm.readonly = angular.isDefined(attrs.readonly);

      // Add external change listener
      vm.ngModelCtrl.$formatters.push(onExternalChange);

      /**
       * Handle external changes to the model value
       *
       * @param  {Object} value   New model value
       * @return {Object}         Formatted model value
       */
      function onExternalChange(value) {
        vm.floor = angular.copy(value);
        vm.init();
        return value;
      }
    }
  }

  /**
   * Controller for abx-floor-details
   *
   * @ngInject
   */
  function AbxFloorDetailsController(
    // Angular
    $q,
    $timeout,
    $state,
    // Dialogs,
    SelectDocumentDialog,
    // Services
    AssetService,
    FloorService,
    RoomService,
    Router,
    TagCategoryService,
    ToastService,
    UserService
  ) {
    var self = this;

    // ------------------------
    //   Attributes
    // ------------------------
    var userPermissions = UserService.getPermissions();

    self.building = angular.isDefined(self.building) ? self.building : null;
    self.readonly = true;
    self.floor = null;
    self.canEdit = false;
    self.canEditFloorPlan = false;
    self.assetCount = "...";
    self.roomCount = "...";
    self.assetLink = null;
    self.roomLink = null;
    self.rendering = true;
    self.buildingUrl = null;

    // Functions
    self.init = init;
    self.updateFloor = updateFloor;
    self.showSelectDocumentDialog = showSelectDocumentDialog;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Initialize the directive, fetch room / asset counts and permissions
     */
    function init() {
      getRoomCount();
      getAssetCount();
      self.buildingUrl = getBuildingUrl();
      if (!self.readonly) getPermissions();
      // Setup links
      var stateParams = {
        buildingId: self.building._id,
        floorId: self.floor._id,
      };
      self.assetLink = Router.href("app.floor.assets", stateParams);
      self.roomLink = Router.href("app.floor.rooms", stateParams);

      $timeout(function () {
        self.rendering = false;
      }, 500);
    }

    /**
     * Update the floor
     */
    function updateFloor() {
      var data = { name: self.floor.name };
      return FloorService.update(self.building._id, self.floor._id, data)
        .then(function (floor) {
          self.floor = floor;
          var model = angular.extend(self.ngModelCtrl.$modelValue, self.floor);
          self.ngModelCtrl.$setViewValue(model);
        })
        .catch(ToastService.showError);
    }

    /**
     * Shows the select document dialog. Called when the "Add Floor Plan" button is clicked.
     */
    function showSelectDocumentDialog() {
      TagCategoryService.getAll(self.building._id)
        .then(function (tagCategories) {
          var locals = {
            building: self.building,
            tagCategories: tagCategories,
            followLink: false,
          };
          return SelectDocumentDialog.show({ locals: locals });
        })
        .then(function (document) {
          return FloorService.updateDocument(
            self.building._id,
            self.floor._id,
            document._id
          );
        })
        .then(function (floor) {
          self.floor = floor;
          var model = angular.extend(self.ngModelCtrl.$modelValue, self.floor);
          self.ngModelCtrl.$setViewValue(model);
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Fetch the amount of rooms on the floor.
     * Also build the corresponding link to the room list page
     */
    function getRoomCount() {
      var params = {
        level: self.floor._id,
        count: true,
      };
      RoomService.get(self.building._id, params)
        .then(function (data) {
          self.roomCount = data.count;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.roomCount = "Click here to view rooms";
        });
    }

    /**
     * Fetch the amount of assets on the floor.
     * Also build the corresponding link to the asset list page
     */
    function getAssetCount() {
      var params = {
        level: self.floor._id,
        count: true,
      };
      AssetService.get(self.building._id, params)
        .then(function (data) {
          self.assetCount = data.count;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.assetCount = "Click here to view assets";
        });
    }

    /**
     * Fetch the current user's permissions for the floor
     */
    function getPermissions() {
      self.canEdit = userPermissions.is_administrator;
      self.canEditFloorPlan = userPermissions.is_administrator;
    }

    /**
     * Generates url to the building dashboad
     */
    function getBuildingUrl() {
      return $state.href("app.building.detail", {
        buildingId: self.building._id,
      });
    }
  }
})();
