(function () {
  angular.module("akitabox.desktop.inspectionProgram.detail").run(stateBlock);

  /* @ngInject */
  function stateBlock(models, Router) {
    Router.configureStates([
      {
        state: "app.building.inspectionProgram.overview",
        config: getOverviewConfig(),
      },
      {
        state: "app.inspectionProgram.overview",
        config: getOverviewConfig(),
      },
      {
        state: "app.building.inspectionProgram.details",
        config: getBuildingDetailSectionConfig(),
      },
      {
        state: "app.inspectionProgram.details",
        config: getDetailSectionConfig(),
      },
      {
        state: "app.building.inspectionProgram.checklistDetails",
        config: getBuildingChecklistDetailsConfig(models),
      },
      {
        state: "app.inspectionProgram.checklistDetails",
        config: getChecklistDetailsConfig(models),
      },
    ]);
  }

  function getOverviewConfig() {
    return {
      url: "/overview?filters",
      templateUrl:
        "app/desktop/modules/inspection-program/detail/overview/inspection-program-overview.template.html",
      controller: "InspectionProgramOverviewController",
      controllerAs: "vm",
      data: {
        pageTitle: "Inspection Program Detail",
      },
      reloadOnSearch: false,
    };
  }

  function getDetailSectionConfig() {
    return {
      url: "/details?filters",
      templateUrl:
        "app/desktop/modules/inspection-program/detail/details-section/inspection-program-details-section.template.html",
      controller: "InspectionProgramDetailsSectionController",
      controllerAs: "vm",
      data: {
        pageTitle: "Inspection Program Detail",
      },
    };
  }

  function getBuildingDetailSectionConfig() {
    return {
      url: "/details?filters",
      templateUrl:
        "app/desktop/modules/inspection-program/detail/details-section/inspection-program-details-section.template.html",
      controller: "InspectionProgramDetailsSectionController",
      controllerAs: "vm",
      data: {
        pageTitle: "Inspection Program Detail",
      },
    };
  }

  function getChecklistDetailsConfig(models) {
    return {
      url: "/" + models.CHECKLIST.ROUTE_PLURAL + "/:checklistId",
      templateUrl:
        "app/desktop/modules/inspection-program/detail/checklist-details/inspection-program-checklist-details.template.html",
      controller: "InspectionProgramChecklistDetailsController",
      controllerAs: "vm",
      data: {
        pageTitle: "Stop Details",
      },
      resolve: {
        checklist: getChecklist,
      },
    };
  }

  function getBuildingChecklistDetailsConfig(models) {
    return {
      url: "/" + models.CHECKLIST.ROUTE_PLURAL + "/:checklistId",
      templateUrl:
        "app/desktop/modules/inspection-program/detail/checklist-details/inspection-program-checklist-details.template.html",
      controller: "InspectionProgramChecklistDetailsController",
      controllerAs: "vm",
      data: {
        pageTitle: "Stop Details",
      },
      resolve: {
        checklist: getChecklist,
      },
    };
  }

  /** @ngInject */
  function getChecklist(ChecklistService, organization, $stateParams) {
    return ChecklistService.getById(organization._id, $stateParams.checklistId);
  }
})();
