(function () {
  angular
    .module("akitabox.ui.dialogs.qrConfirmAssign")
    .factory("QrConfirmAssignDialog", QrConfirmAssignDialogService);

  function QrConfirmAssignDialogService($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/qr-confirm-assign/qr-confirm-assign.dialog.html",
      controller: "QrConfirmAssignDialogController",
      controllerAs: "vm",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * QrConfirmAssignDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}  options - Dialog options
     * @param {Object}  [options.qrCode] - QR Code to assign to the Pin
     * @param {Object}  [options.pin] - Pin being assigned the QR Code
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
