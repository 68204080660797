(function () {
  angular
    .module("akitabox.ui.dialogs.qrScanner")
    .controller("QrScannerDialogController", QrScannerDialogController);

  /* @ngInject */
  function QrScannerDialogController(
    // Angular
    $scope,
    $timeout,
    $window,
    $location,
    $element,
    // Material
    $mdDialog,
    // Dialogs
    QrManualEntryDialog,
    // Services
    media,
    ShadowService,
    QRCodeService,
    EnvService
  ) {
    var self = this;

    // Valid QR code regex
    var validQrCode =
      /^((http[s]?:)?\/\/)?((?:\w+\.)?((?:akitabox)|(?:\w+-abx)))\.com(:[0-9]*)?\/qrcode\/[a-zA-Z0-9]*/i;
    // Regex that matches API QR code image URL
    var qrCodeImageRegex = /^((http[s]?:)?\/\/)?(api\.).*(\/image)$/i;
    // Regex that matches parts of QR Code image URL
    var qrCodeImageUrlParts = /^((http[s]?:)?\/\/)|api\.|\/image/g;
    // Regex that matches url beginning with protocol http[s] or //
    var prefixedUrlRegex = /^(https?|\/\/)/i;

    // Attributes
    self.scanned = false;
    self.loading = false;
    self.success = false;
    self.denied = false;
    self.supported = media.isSupported();
    self.channel = {
      constraints: media.QR_SCANNER_CONSTRAINTS,
    };
    self.status = {
      active: true,
      on: false,
    };

    // Functions
    self.cancel = cancel;
    self.parseUrl = parseUrl;
    self.onError = onError;
    self.reset = reset;
    self.onManualEntryClickHandler = onManualEntryClickHandler;

    init();

    // ------------------------
    //   Public Functions
    // ------------------------

    function onManualEntryClickHandler() {
      QrManualEntryDialog.show({ multiple: true }).then(function (qrCode) {
        $mdDialog.hide(qrCode);
      });
    }

    function parseUrl(url) {
      /**
       * Determine if url is a link to one of our QR code images instead of route
       *
       * Added for [WEBAPP-6754](https://akitabox.atlassian.net/browse/WEBAPP-6754)
       */
      var isImageUrl = qrCodeImageRegex.test(url);

      self.scanned = true;
      self.status.active = false;
      self.success = validQrCode.test(url);

      if (isImageUrl) {
        // Remove parts from URL that link to the image instead of route.
        // Normalizes incorrect image URL to expected QR code route format
        url = url.replace(qrCodeImageUrlParts, "");
      }

      if (self.success && self.redirect === false) {
        // Just send back the qr code's id if we don't want it to re-direct after scanning it
        var qrCodeId = url.split("/").pop();
        if (self.returnModel === true) {
          self.loading = true;
          QRCodeService.getById(qrCodeId)
            .then(function (qrCode) {
              ShadowService.sendEvent("qr-code", "scan", "success");
              return $mdDialog.hide(qrCode);
            })
            .catch(function () {
              self.success = false;
              self.loading = false;
              ShadowService.sendEvent("qr-code", "scan", "fail");
            });
        } else {
          return $mdDialog.hide(qrCodeId);
        }
      } else if (self.success) {
        var host = $location.host();

        if (host) {
          var domainParts = EnvService.extractDomainParts(host);
          host = domainParts.domain + "." + domainParts.suffix;
        }

        var localhost = host === "local-abx.com";
        var sameDomain = new RegExp("^(m.)?" + host + "/.*", "i").test(url);
        var localDomain = /^(.*\.)?local-abx\.com(:[0-9]*)?\/.*/i.test(url);

        // Rewrite the port if the scanned QR Code was a local QR Code image URL.
        if (isImageUrl && localDomain) {
          var port = sameDomain ? ":" + $location.port() : ":3007";
          url = url.replace(/:[0-9]*/g, port);
        }

        // Determine if the scanned url is a local route
        if (sameDomain || (localhost && localDomain)) {
          var parts = url.split("/");
          parts.shift();
          $location.url("/" + parts.join("/"));
          $mdDialog.hide();
        } else {
          $timeout(function () {
            var redirectUrl = prefixedUrlRegex.test(url) ? url : "//" + url;
            $window.location.href = redirectUrl;
          }, 1000);
        }
      } else if (!self.success) {
        ShadowService.sendEvent("qr-code", "scan", "fail");
      }
    }

    function onError(err) {
      self.denied =
        err &&
        (err.name === media.PERMISSION_DENIED ||
          err.name === media.PERMISSION_DISMISSED);
    }

    function reset() {
      self.scanned = false;
      self.success = false;
      self.status.active = true;
      init();
    }

    /**
     * Closes the dialog
     */
    function cancel() {
      ShadowService.sendEvent("qr-code", "cancel-scanner");
      $mdDialog.cancel();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      var unwatchCamera = $scope.$watch("vm.status.on", function (on) {
        if (on) {
          ShadowService.sendEvent("qr-code", "open-scanner");
          addOptics();
          unwatchCamera();
        }
      });
    }

    function addOptics() {
      // Add optics element to video feed
      var opticsElement =
        '<div class="qr-optics-container"><div class="qr-optics"></div></div>';
      $timeout(function () {
        var $video = $element.find("video");
        if ($video.length) $video.parent().append(opticsElement);
      });
    }
  }
})();
