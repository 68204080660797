(function () {
  angular
    .module("akitabox.desktop.media-viewer")
    .controller(
      "MediaViewerContainerController",
      MediaViewerContainerController
    );

  /* @ngInject */
  function MediaViewerContainerController(
    // Angular
    $window,
    $rootScope,
    // Constants
    EVENT_MEDIA_ROTATE,
    // UI Router
    $stateParams,
    // Services
    ToastService,
    UserService,
    // Dialogs
    DownloadDocumentDialog,
    // Resolves
    building,
    document
  ) {
    var self = this;

    var permissions = UserService.getPermissions();

    // Attributes
    self.building = building;
    self.document = document;
    self.canRotate = permissions.document.rotate;

    // Functions
    self.onRotate = onRotate;
    self.onDownload = onDownload;

    // =================
    // Life Cycle
    // =================
    self.$onInit = () => {
      const { inline } = $stateParams;
      if (
        // enforce inline view
        inline === "true" &&
        // ensure PURL exists
        self.document &&
        self.document.public_url
      ) {
        $window.location.replace(self.document.public_url);
      }
    };

    // =================
    // Public Functions
    // =================
    function onRotate() {
      $rootScope.$broadcast(EVENT_MEDIA_ROTATE);
    }

    function onDownload() {
      var locals = {
        docs: [self.document],
      };
      DownloadDocumentDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }
  }
})();
