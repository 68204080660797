(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("ManagedModelFieldFilter", ManagedModelFieldFilterClassFactory);

  /**
   * @ngInject
   * @return The ManagedModelFieldFilter class
   */
  function ManagedModelFieldFilterClassFactory(
    FilterBarManager,
    ManagedFilterConfiguration
  ) {
    /**
     * @class
     * @param {FilterBarManager} manager - The manager that owns this configuration.
     * @param {object} config - Configuration options. See
     *  `ManagedFilterConfiguration` class for documentation of base class options.
     * @param {string} [config.queryField] - The field of the query that this filter
     *  modifies. Only one of `queryField` and `getQueryFiled` is reguired.
     * @param {(model?) => string} [config.getQueryField] - A getter function
     *  alternative to `queryField`. If there is a model (eg: during filter
     *  application), it is passed in here. During other workflows, there will
     *  be no model (eg: `manager.applyQuery`, `onRemove` workflows). One of
     *  `getQueryField` and `queryField` is required.
     */
    function ManagedModelFieldFilter(manager, config) {
      // super(manager, config);
      ManagedFilterConfiguration.call(this, manager, config);

      if (
        (typeof config.queryField !== "string" || !config.queryField.length) &&
        (!config.getQueryField || typeof config.getQueryField !== "function")
      ) {
        throw new Error(
          "ManagedModelFieldFilter: One of `getQueryField` (function), or `queryField` (string) must be provided"
        );
      }

      /** @member { string } */
      this.queryField = config.queryField;
      this.getQueryField = function (model) {
        return this.queryField || config.getQueryField.call(this, model);
      };
    }
    // ManagedModelFieldFilter extends ManagedFilterConfiguration
    ManagedModelFieldFilter.prototype = Object.create(
      ManagedFilterConfiguration.prototype
    );
    ManagedModelFieldFilter.prototype.constructor = ManagedModelFieldFilter;

    ManagedModelFieldFilter.prototype.applyFilter = function (
      filterValue,
      model
    ) {
      this.manager.addModelFieldFilter(this.getQueryField(model), filterValue);
    };

    ManagedModelFieldFilter.prototype.removeFilter = function () {
      this.manager.removeModelFieldFilter(this.getQueryField());
    };

    ManagedModelFieldFilter.prototype.getFilterValue = function (manager) {
      return manager.getModelFieldFilter(this.getQueryField());
    };

    return ManagedModelFieldFilter;
  }
})();
