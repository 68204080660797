(function () {
  angular.module("akitabox.ui.dialogs.message.create", [
    "akitabox.core.constants",
    "akitabox.core.services.flag",
    "akitabox.core.services.user",
    "akitabox.core.services.note",
    "akitabox.core.toast",
    "ngMaterial",
  ]);
})();
