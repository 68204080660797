(function () {
  angular.module("akitabox.core").config(configBlock);

  /* @ngInject */
  function configBlock($httpProvider, CacheFactoryProvider) {
    // allow sending cookies in CORS requests
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common["x-response-type"] = "json";

    // Set custom defaults for angular-cache
    angular.extend(CacheFactoryProvider.defaults, {
      maxAge: 1000 * 60 * 60, // Expire cache items after one hour
    });
  }
})();
