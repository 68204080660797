(function () {
  angular
    .module("akitabox.core.services.indexedDB", [])
    .factory("IndexedDBService", IndexedDBService);

  function IndexedDBService($window, $q) {
    var indexedDB = $window.indexedDB;
    var db = null;

    var service = {
      // Functions
      open: open,
      put: put,
      getById: getById,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Opens a DB for the current connection
     */
    function open() {
      if (isDBOpen()) {
        return $q.resolve();
      }

      var deferred = $q.defer();
      // Incrementing the version will push any changes made in 'onupgradeneeded'
      // Current version PRODUCTION = 1;
      var version = 2;
      var request = indexedDB.open("AbxFilesData", version);

      request.onupgradeneeded = function () {
        db = request.result;

        db.onerror = function () {
          deferred.reject("Error loading cached file connection");
        };

        // If delete all stores to reset
        var storeNames = db.objectStoreNames;
        for (var i = 0; i < storeNames.length; i++) {
          db.deleteObjectStore(storeNames[i]);
        }

        db.createObjectStore("files", {
          keyPath: "id",
        });
      };

      request.onsuccess = function () {
        db = request.result;
        deferred.resolve();
      };

      request.onerror = function () {
        deferred.reject("Unable to open cached file connection");
      };

      return deferred.promise;
    }

    /**
     * Updates the database with the provided files
     * If no files are left in the database collection, deletes it
     * @param {string} buildingId
     * @param {object} options
     * @param {string} options.parentId - the ID of the parent model
     * @param {string} options.modelType - the type of pin of the parent
     * @param {string} options.uploadType - the type of file we're uploading (e.g. photos)
     * @param {array<File>} files
     */
    function put(buildingId, options, files) {
      if (!isDBOpen()) {
        return $q.reject("File cache connection is not open yet");
      }

      if (!buildingId || typeof buildingId !== "string") {
        return $q.reject("Invalid building ID");
      }

      var id;
      try {
        id = getId(options);
      } catch (err) {
        return $q.reject(err);
      }

      var deferred = $q.defer();

      var store = db.transaction(["files"], "readwrite").objectStore("files");

      // If there are no files remaining, delete the DB entry
      if (!files.length) {
        var deleteRequest = store.delete(id);

        deleteRequest.onsuccess = function () {
          deferred.resolve();
        };

        deleteRequest.onerror = function () {
          deferred.reject("Unable to reset cached file collection");
        };
      } else {
        var dataToSave = {
          id: id,
          _id: options.parentId,
          buildingId: buildingId,
          files: files,
          modelType: options.modelType,
          uploadType: options.uploadType,
        };

        var putRequest = store.put(dataToSave);

        putRequest.onsuccess = function () {
          deferred.resolve();
        };

        putRequest.onerror = function () {
          deferred.reject("Unable to cache file");
        };
      }

      return deferred.promise;
    }

    /**
     * Updates the database with the provided files
     * If no files are left in the database collection, deletes it
     * @param {object} options
     * @param {string} options.parentId - the ID of the parent model
     * @param {string} options.modelType - the type of pin of the parent
     * @param {string} options.uploadType - the type of file we're uploading (e.g. photos)
     */
    function getById(options) {
      if (!isDBOpen()) {
        return $q.reject("File cache connection is not open yet");
      }

      var id;
      try {
        id = getId(options);
      } catch (err) {
        return $q.reject(err);
      }

      var deferred = $q.defer();

      var store = db.transaction(["files"], "readwrite").objectStore("files");

      var storeRequest = store.get(id);
      storeRequest.onsuccess = function () {
        deferred.resolve(storeRequest.result);
      };
      storeRequest.onerror = function () {
        deferred.reject("Unable to retrieve cached files");
      };

      return deferred.promise;
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Checks if the DB is already open or not
     */
    function isDBOpen() {
      return !!db;
    }

    /**
     * Returns the parsed entry ID given the modelType, uploadType, and parentId
     * @param {Object} options
     */
    function getId(options) {
      var modelType = options.modelType;
      var uploadType = options.uploadType;
      var parentId = options.parentId;

      if (!modelType || typeof modelType !== "string") {
        throw new Error("Invalid model type");
      }
      if (!uploadType || typeof uploadType !== "string") {
        throw new Error("Invalid upload type");
      }
      if (!parentId || typeof parentId !== "string") {
        throw new Error("Invalid parent ID");
      }

      return modelType + "-" + uploadType + ":" + parentId;
    }
  }
})();
