(function () {
  angular
    .module("akitabox.ui.dialogs.job.create")
    .controller("CreateScriptDialogController", CreateScriptDialogController);

  /* @ngInject */
  function CreateScriptDialogController(
    // Angular
    $q,
    $timeout,
    // Material
    $mdDialog,
    // Services
    FloorService,
    AdminJobService,
    PinTypeService,
    ToastService,
    // Akitabox
    models,
    // Constants
    BUILDING_SETUP_TYPES,
    BUILDING_SETUP_STAIRCASE,
    BULK_DELETE_ROOMS_ASSETS_STAIRCASE,
    SET_PIN_LOCATION_STAIRCASE,
    SET_TREE_VALUES_STAIRCASE,
    GENERATE_QRCODES_STAIRCASE,
    PRIORITY_LIST
  ) {
    var self = this;

    // Pin location import file counts
    var filenameCounts = {};

    // Attributes
    self.loading = true;
    self.creating = false;
    self.scripts = null;
    self.script = null;

    // Content toggles
    self.showBuildingSetup = false;
    self.showSetPinLocation = false;

    // Selected file
    self.file = null;

    // Building setup
    self.buildingSetupType = null;
    self.buildingSetupTypes = BUILDING_SETUP_TYPES;
    self.buildingSetup = BUILDING_SETUP_STAIRCASE;
    self.supportedCSVFileTypes = [
      "text/csv",
      "text/plain",
      "application/vnd.ms-excel",
    ];
    self.supportedDWFXFileTypes = ["dwfx", "model/vnd.dwfx+xps"];

    // Extra mechanical building setup fields
    self.mechSetup = {
      profitCenterName: null,
      profitCenterCode: null,
      tradeName: null,
      tradeCode: null,
    };

    // Pin location
    self.pinLocationSetup = SET_PIN_LOCATION_STAIRCASE;
    self.pinLocationImports = [];
    self.floor = null;
    self.floors = null;
    self.loadingFloors = true;

    // Delete Room Or Assets
    self.showBulkDeleteRoomsAssets = false;
    self.bulkDeleteRoomsAssets = BULK_DELETE_ROOMS_ASSETS_STAIRCASE;
    self.bulkDeleteRoomsAssetsFile;
    self.bulkDeleteRoomsAssetsTypes = [
      {
        model: "asset",
        name: "Asset",
      },
      {
        model: "room",
        name: "Room",
      },
    ];
    // Default to first type
    self.bulkDeleteRoomsAssetsType = self.bulkDeleteRoomsAssetsTypes[0];
    self.onBulkDeleteTypeChange = onBulkDeleteTypeChange;

    // Generate QR Codes
    self.generateQRCodes = GENERATE_QRCODES_STAIRCASE;
    self.qrCodeQuantity = 1000;

    // Tree Acceptable Values
    self.treeValues = SET_TREE_VALUES_STAIRCASE;
    self.treeValueImports = [];
    self.tree = null;
    self.trees = null;
    self.loadingTrees = true;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.submit = submit;
    self.isFormInvalid = isFormInvalid;
    self.onScriptSelected = onScriptSelected;
    self.onFileSelected = onFileSelected;
    self.onFileInvalid = ToastService.showError;
    self.onFileChanged = onFileChanged;
    self.removeImport = removeImport;
    self.onFloorSelected = onFloorSelected;
    self.onFloorChanged = onFloorChanged;
    self.onTreeSelected = onTreeSelected;
    self.onTreeChanged = onTreeChanged;
    self.isDuplicated = isDuplicated;

    init();

    function init() {
      AdminJobService.getScripts({ type: models.STAIRCASE.TYPES.SCRIPT })
        .then(function (scripts) {
          self.scripts = scripts;
          sortScripts();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function submit() {
      if (self.creating) {
        return; // Don't submit if we are already submitting
      }

      self.creating = true;
      var isBuildingSetup = self.script.id === BUILDING_SETUP_STAIRCASE;
      var isPinLocation = self.script.id === SET_PIN_LOCATION_STAIRCASE;
      var isTreeValues = self.script.id === SET_TREE_VALUES_STAIRCASE;
      var isGenerateQRCodes = self.script.id === GENERATE_QRCODES_STAIRCASE;
      var isBulkDeleteRoomsAssets =
        self.script.id === BULK_DELETE_ROOMS_ASSETS_STAIRCASE;

      if (isPinLocation) {
        return createPinLocationJobs().finally(onComplete);
      } else if (isTreeValues) {
        return createTreeValueJobs().finally(onComplete);
      } else if (isBulkDeleteRoomsAssets) {
        return createBulkDeleteRoomsAssetsJobs().finally(onComplete);
      }

      var data = {
        staircase_id: self.script.id,
      };
      if (isBuildingSetup) {
        var isMechanical =
          self.buildingSetupType.id === BUILDING_SETUP_TYPES.mechanical.id;
        var extras = {
          aramarkType: self.buildingSetupType.id,
        };
        if (isMechanical) angular.extend(extras, self.mechSetup);
        data.data = extras;
      } else if (isGenerateQRCodes) {
        data.data = {
          quantity: self.qrCodeQuantity,
        };
      }

      return AdminJobService.create(null, self.building._id, data)
        .then(function (job) {
          // To be consistent, return an array
          return $mdDialog.hide([job]);
        })
        .catch(ToastService.showError)
        .finally(onComplete);

      function onComplete() {
        self.creating = false;
      }
    }

    function createBulkDeleteRoomsAssetsJobs() {
      return AdminJobService.create(null, self.building._id, {
        staircase_id: BULK_DELETE_ROOMS_ASSETS_STAIRCASE,
        file: self.bulkDeleteRoomsAssetsFile,
        data: {
          type: self.bulkDeleteRoomsAssetsType.model,
        },
      })
        .then(function (job) {
          $mdDialog.hide([job]);
        })
        .catch(ToastService.showError);
    }

    /**
     * Make a job for each selected file/floor combo
     */
    function createPinLocationJobs() {
      var requests = self.pinLocationImports.map(function (item) {
        return AdminJobService.create(null, self.building._id, {
          staircase_id: SET_PIN_LOCATION_STAIRCASE,
          file: item.file,
          data: {
            documentId: item.floor.document._id,
          },
        });
      });

      return $q
        .all(requests)
        .then(function (jobs) {
          return $mdDialog.hide(jobs);
        })
        .catch(ToastService.showError);
    }

    /**
     * Make a job for each selected file/tree combo
     */
    function createTreeValueJobs() {
      var requests = self.treeValueImports.map(function (item) {
        return AdminJobService.create(null, self.building._id, {
          staircase_id: SET_TREE_VALUES_STAIRCASE,
          file: item.file,
          data: {
            column_name: item.tree.excel_col_name,
          },
        });
      });

      return $q
        .all(requests)
        .then(function (jobs) {
          return $mdDialog.hide(jobs);
        })
        .catch(ToastService.showError);
    }

    /**
     * Toggle form content based on selected script
     *
     * @param {Object} script   Selected script
     */
    function onScriptSelected(script) {
      // make sure we hide/show the correct part of the html
      self.showBuildingSetup = script.id === BUILDING_SETUP_STAIRCASE;
      self.showSetPinLocation = script.id === SET_PIN_LOCATION_STAIRCASE;
      self.showGenerateQRCodes = script.id === GENERATE_QRCODES_STAIRCASE;
      self.showSetTreeValues = script.id === SET_TREE_VALUES_STAIRCASE;
      self.showBulkDeleteRoomsAssets =
        script.id === BULK_DELETE_ROOMS_ASSETS_STAIRCASE;
      // Set Pin Locations
      if (self.showSetPinLocation) {
        // Fetch floors with floor plans
        var params = { document: "$ne,null" };
        return FloorService.getAll(self.building._id, params)
          .then(function (floors) {
            self.floors = floors;
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.loadingFloors = false;
          });
      }
      // Set Tree Acceptable Values
      if (self.showSetTreeValues) {
        // Fetch all building trees
        return PinTypeService.getAll(self.building._id)
          .then(function (pinTypes) {
            var trees = [];
            for (var i = 0; i < pinTypes.length; ++i) {
              var treeFields = pinTypes[i].fields.filter(function (field) {
                return field.data_type === "tree";
              });
              Array.prototype.push.apply(trees, treeFields);
            }
            self.trees = trees;
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.loadingTrees = false;
          });
      }
    }

    /**
     * Set file to selected
     *
     * @param {File}  file  Selected file
     */
    function onFileSelected(file) {
      self.file = file;
      var isPinLocation = self.script.id === SET_PIN_LOCATION_STAIRCASE;
      var isTreeValues = self.script.id === SET_TREE_VALUES_STAIRCASE;
      if (isPinLocation && self.floor) {
        addPinLocationImport(self.file, self.floor);
      } else if (isTreeValues && self.tree) {
        addTreeValueImport(self.file, self.tree);
      } else if (self.script.id === BULK_DELETE_ROOMS_ASSETS_STAIRCASE) {
        self.bulkDeleteRoomsAssetsFile = file;
      }
    }

    /**
     * Handle change of import file
     *
     * @param {File}    file    New file
     * @param {Number}  $index  Index of changed item
     * @param {Array}   imports List of selected import items
     */
    function onFileChanged(file, $index, imports) {
      if ($index < 0 || $index >= imports.length) return;
      var item = imports[$index];
      removeFileCount(item.file);
      item.file = file;
      addFileCount(file);
    }

    /**
     * Set floor to selected
     *
     * @param {Object} floor Selected floor
     */
    function onFloorSelected(floor) {
      self.floor = floor;
      if (self.floor && self.file) {
        addPinLocationImport(self.file, self.floor);
      }
    }

    /**
     * Handle pin location floor change
     *
     * @param {Object} floor  [description]
     * @param {Number} $index [description]
     */
    function onFloorChanged(floor, $index) {
      if ($index < 0 || $index >= self.pinLocationImports.length) return;
      self.pinLocationImports[$index].floor = floor;
    }

    function onTreeSelected(tree) {
      self.tree = tree;
      if (self.tree && self.file) {
        addTreeValueImport(self.file, self.tree);
      }
    }

    function onTreeChanged(tree, $index) {
      if ($index < 0 || $index >= self.treeValueImports.length) return;
      self.treeValueImports[$index].tree = tree;
    }

    function onBulkDeleteTypeChange(type) {
      self.bulkDeleteRoomsAssetsType = type;
    }

    /**
     * Determine if form is invalid based on selected script
     * If setting pin locations, a file and floor must be selected.
     * If running building setup, a setup type and file must be selected
     *
     * @return {Boolean} False if form is valid, true if not
     */
    function isFormInvalid() {
      if (self.showSetPinLocation) {
        return !self.pinLocationImports.length;
      } else if (self.showBuildingSetup) {
        return !self.buildingSetupType;
      } else if (self.showSetTreeValues) {
        return !self.treeValueImports.length;
      } else if (self.showBulkDeleteRoomsAssets) {
        return !self.bulkDeleteRoomsAssetsFile;
      }
      return false;
    }

    /**
     * Determine if a file is a duplicate, that is,
     * it already exists in the list of selected import files
     *
     * @param {File}   file    The file
     *
     * @return {Boolean}    Whether the file is a duplicate
     */
    function isDuplicated(file) {
      var count = filenameCounts[file.name];
      return count && count > 1;
    }

    function addPinLocationImport(file, floor) {
      self.pinLocationImports.push({
        file: file,
        floor: floor,
      });
      addFileCount(file);
      // Reset selections
      self.file = null;
      self.floor = null;
    }

    function addTreeValueImport(file, tree) {
      self.treeValueImports.push({
        file: file,
        tree: tree,
      });
      addFileCount(file);
      // Reset selections
      self.file = null;
      self.tree = null;
    }

    function removeImport($index, imports) {
      if ($index < 0 || $index >= imports.length) return;
      var file = imports[$index].file;
      imports.splice($index, 1);
      if (isDuplicated(file)) {
        filenameCounts[file.name]--;
      } else {
        delete filenameCounts[file.name];
      }
    }

    /**
     * Add file to filename counts
     *
     * @param {File}   file    The file to add
     */
    function addFileCount(file) {
      var count = filenameCounts[file.name];
      if (count) {
        filenameCounts[file.name]++;
      } else {
        filenameCounts[file.name] = 1;
      }
    }

    /**
     * Remove file from filename counts
     *
     * @param {File}    file     The file to remove
     */
    function removeFileCount(file) {
      var count = filenameCounts[file.name];
      if (count) {
        // Decrement the file count
        filenameCounts[file.name]--;
        // Remove if less than one
        if (filenameCounts[file.name] < 1) {
          delete filenameCounts[file.name];
        }
      }
    }

    /**
     * Sort scripts using a pre-defined priority list.
     *
     * If a script is not in the priority list, it will be placed after all scripts that are
     * in the priority list.
     *
     * A priority of 1 is higher than a priority of 2
     */
    function sortScripts() {
      // Sort scripts using the above priority list
      self.scripts.sort(function (a, b) {
        var aName = a.name.toLowerCase();
        var bName = b.name.toLowerCase();

        if (PRIORITY_LIST[aName] && !PRIORITY_LIST[bName]) return -1;
        if (!PRIORITY_LIST[aName] && PRIORITY_LIST[bName]) return 1;
        if (PRIORITY_LIST[bName] < PRIORITY_LIST[aName]) return 1;
        if (PRIORITY_LIST[bName] > PRIORITY_LIST[aName]) return -1;

        return 0;
      });
    }
  }
})();
