(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.buildingChart
   *
   * @param {Object} building - building to build the chart with
   */
  angular
    .module("akitabox.ui.components.reportingCharts.buildingChart", [
      "akitabox.constants",
      "akitabox.core.services.chart",
      "akitabox.core.services.flag",
      "akitabox.ui.directives.stackChart",
      "akitabox.core.services.organization",
    ])
    .component("abxBuildingChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
        modelName: "@abxModelName",
      },
      controller: AbxBuildingController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/building-chart/building-chart.component.html",
    });

  /** @ngInject */
  function AbxBuildingController(
    // Angular
    $log,
    $timeout,
    // Constants
    models,
    MINIMUM_RENDER_DELAY,
    // Services
    ChartService,
    ShadowService,
    OrganizationService
  ) {
    var self = this;
    var extraSRParams = {};
    var config = {};
    var GA_CATEGORY = "reporting";
    var organization = OrganizationService.getCurrent();

    // Attributes
    self.idTag = "building-chart";
    self.data = [];
    self.groupKey = "_id";
    self.loading = true;
    self.showChart = false;
    self.isWorkOrderChart = false;
    self.hoverLabel = self.modelName ? models.SERVICE_REQUEST.PLURAL : "";
    self.yAxisOptions = [
      "# Completed",
      "# Open",
      "# Overdue",
      "# Scheduled",
      "Avg. Duration (Days)",
      "Cost",
      "Hours Logged",
    ];
    //Time Code is only enabled with Hours Logged selected
    self.disabledStackOptions = [];
    self.activeYAxisOption = self.yAxisOptions[0];
    self.isWorkOrderChart = self.modelName === models.WORK_ORDER.MODEL;
    self.stackOptions = [];
    self.selectedStack = "---";
    self.hoverLabel = "";
    self.excludeStackOptions = ["Building", "Assignee", "Work Performed By"];
    if (!organization.show_time_codes) {
      self.excludeStackOptions.push("Time Code");
    }
    self.defaultStackOption = {
      model: "---",
      value: "---",
    };

    self.onYAxisChange = onYAxisChange;
    self.onStackChange = onStackChange;
    self.onStackHover = onStackHover;
    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxBuildingChart: abx-buildings is required");
        return;
      }
      updateDisabledStackOptions("");
    };

    self.$onChanges = function (changes) {
      if (changes.buildings || changes.modelName) {
        config = ChartService.getChartConfig(self.buildings, self.modelName);
        if (self.isWorkOrderChart) {
          self.stackOptions = ChartService.getStackOptions(
            self.modelName,
            self.buildings.length > 1,
            ["Age"],
            self.excludeStackOptions
          );
        } else {
          self.stackOptions = ChartService.getStackOptions(
            self.modelName,
            self.buildings.length > 1,
            null,
            self.excludeStackOptions
          );
        }
      }
      if (changes.startDate || changes.endDate) {
        if (self.isWorkOrderChart) {
          self.yAxisFilterOptions = ChartService.getYAxisConfig(
            self.yAxisOptions,
            self.startDate,
            self.endDate
          );
          self.yAxisConfig = self.yAxisFilterOptions[self.activeYAxisOption];
          self.hoverLabel = self.yAxisConfig.label;
        } else {
          extraSRParams.completed_date = ChartService.buildDateString(
            self.startDate,
            self.endDate
          );
          self.hoverLabel = "Service Requests";
        }
      }
      if (changes.filters && self.yAxisConfig) {
        updateFilters(self.yAxisConfig.lookupModel);
      }
      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings ||
        changes.modelName
      ) {
        calculateStats();
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    function onStackHover($event) {
      self.highlightedStack = $event.value;
    }

    function onStackChange($event) {
      ShadowService.setSelectedOption($event.value);
      ShadowService.sendEvent(GA_CATEGORY, "switch-stack-by", self.idTag);
      self.selectedStack = $event.model;
      updateFilters(self.yAxisConfig.lookupModel);
      calculateStats();
    }

    function onYAxisChange($event) {
      ShadowService.setSelectedOption($event.value);
      ShadowService.sendEvent(GA_CATEGORY, "switch-y-axis", self.idTag);
      self.activeYAxisOption = $event.model;
      self.yAxisConfig = self.yAxisFilterOptions[self.activeYAxisOption];
      self.hoverLabel = self.yAxisConfig.label;
      updateFilters(self.yAxisConfig.lookupModel);
      updateDisabledStackOptions(self.yAxisConfig.label);

      if (
        self.activeYAxisOption === "Cost" ||
        self.activeYAxisOption === "Hours Logged"
      ) {
        /**
         * reset the selected stack by option if user choses a combintion of Cost|Logged Hours
         * with Age as a stack
         * We don't do this stats calculation on our back-end currently
         */
        self.stackOptions = ChartService.getStackOptions(
          models.WORK_ORDER.MODEL,
          self.buildings.length > 1,
          null,
          self.excludeStackOptions
        );
        if (self.selectedStack === "Age") {
          onStackChange(self.defaultStackOption);
        }
      } else {
        self.stackOptions = ChartService.getStackOptions(
          models.WORK_ORDER.MODEL,
          self.buildings.length > 1,
          ["Age"],
          self.excludeStackOptions
        );
      }

      calculateStats();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function setShowChart() {
      var options = {
        startDate: self.startDate,
        endDate: self.endDate,
        yAxis: self.activeYAxisOption,
      };
      self.showChart = ChartService.shouldShowChart(
        self.modelName,
        ChartService.CHART_TYPE_BAR,
        self.data,
        options
      );
    }

    // Calculate the workOrder stats for the given date
    function calculateStats() {
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      )
        return;
      self.loading = true;
      self.showChart = false;

      var params;
      // model to append in the lookup stage
      var lookupModel;
      // model to use for the stats function
      var overrideModel;
      if (self.yAxisConfig && self.yAxisConfig.overrideModel) {
        lookupModel = self.yAxisConfig.lookupModel;
        overrideModel = self.yAxisConfig.overrideModel;
        params = {
          group_field: lookupModel + ".building",
          buildings: config.buildingInString,
          lookup_field: lookupModel,
        };

        // reset config to use the overrideModel
        config = ChartService.getChartConfig(self.buildings, overrideModel);
      } else {
        params = {
          group_field: "building",
          buildings: config.buildingInString,
        };

        // reset config to use the original model
        config = ChartService.getChartConfig(self.buildings, self.modelName);
      }

      var paramsToAdd = self.isWorkOrderChart
        ? self.yAxisConfig.params
        : extraSRParams;

      params = angular.extend(params, self.filters, paramsToAdd);

      config
        .statsFunction(config.parentId, params)
        .then(function (results) {
          var data = results.map(function (building) {
            var buildingName = config.buildingMap[building._id]
              ? config.buildingMap[building._id]
              : null;
            building._id = buildingName;
            return building;
          });

          self.legendValues = [];

          if (!params.stack_field) {
            self.data = self.isWorkOrderChart
              ? self.yAxisConfig.transform(data)
              : data;
            return;
          }

          if (params.stack_field === "intent") {
            self.stackSort = ChartService.sortIntent;
          } else if (self.filters.stack_field === "age_group") {
            self.stackSort = ChartService.sortAge;
          } else {
            self.stackSort = undefined; // sort function must either be undefined or function
          }

          return ChartService.parseStackData(
            data,
            params.stack_field,
            params.stack_field === "building" ? config.buildingMap : null,
            self.isWorkOrderChart ? self.yAxisConfig.transform : null,
            self.buildings
          );
        })
        .then(function (stackData) {
          if (!stackData) {
            // no stack_field provided, self.data is already set above
            return;
          }
          if (params.stack_field === "time_code") {
            self.legendValues = ChartService.filterTimeCodeLabel(
              stackData.keys
            ).sort();
            self.data = ChartService.transformTimeCodeData(stackData.data);
          } else {
            self.legendValues = stackData.keys.sort(self.stackSort);
            self.data = stackData.data;
          }
        })
        .then(function () {
          return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
        })
        .finally(function () {
          self.loading = false;
          setShowChart();
        });
    }

    function updateFilters(lookupModel) {
      self.filters = ChartService.updateStackFilter(
        self.selectedStack,
        self.filters,
        lookupModel
      );
    }

    /**
     * Disable certain stack options based on the currently selected
     * y-axis option
     *
     * @param {String} yAxisLabel name of the current y-axis config
     */
    function updateDisabledStackOptions(yAxisLabel) {
      self.disabledStackOptions = [];
      if (yAxisLabel !== "Hours Logged") {
        self.disabledStackOptions.push("Time Code");
        if (self.selectedStack === "Time Code") {
          self.onStackChange(self.defaultStackOption);
        }
      }
    }
  }
})();
