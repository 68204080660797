(function () {
  angular
    .module("akitabox.ui.dialogs.assetExportJob")
    .controller(
      "AssetExportJobDialogController",
      AssetExportJobDialogController
    );

  /* @ngInject */
  function AssetExportJobDialogController(
    // Angular
    $timeout,
    // Material
    $mdDialog,
    // Services
    JobService,
    ToastService,
    BuildingService,
    OrganizationService
  ) {
    const self = this;

    // Attributes
    self.creating = false;
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.exportListURL = `/buildings/${self.building._id}/exports`;
    self.jobCreated = false;
    self.dialogTimer = 8000;
    self.timeout = null;

    // Functions
    self.closeDialog = closeDialog;
    self.exportAssetDetails = exportAssetDetails;
    self.exportAssetPhotoUrl = exportAssetPhotoUrl;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Execute export asset details
     */
    function exportAssetDetails() {
      const scriptData = {
        staircase_id: "export-asset-details-staircase",
      };
      executeJob(scriptData);
    }

    function closeDialog() {
      $mdDialog.hide();
      $timeout.cancel(self.timeout);
    }

    /**
     * Execute export photo url
     */
    function exportAssetPhotoUrl() {
      const scriptData = {
        staircase_id: "export-asset-photo-url-staircase",
      };
      executeJob(scriptData);
    }

    // ==================
    // Private Functions
    // ==================

    function executeJob(scriptData) {
      if (self.creating) return;
      self.creating = true;

      if (self.filters) {
        scriptData.data = {
          filters: self.filters,
        };
      }

      JobService.create(
        null,
        self.building._id,
        self.organization._id,
        scriptData
      )
        .then(() => {
          self.jobCreated = true;
          startTimerToCloseDialog();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.creating = false;
        });
    }

    function startTimerToCloseDialog() {
      self.timeout = $timeout(() => {
        self.closeDialog();
      }, self.dialogTimer);
    }
  }
})();
