(function () {
  angular.module("akitabox.desktop.schedule.detail", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.document",
    "akitabox.core.services.flag",
    "akitabox.core.services.floor",
    "akitabox.core.services.schedule",
    "akitabox.core.services.note",
    "akitabox.core.services.recentActivity",
    "akitabox.core.services.room",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.workOrdersBff",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.editLocation",
    "akitabox.ui.dialogs.schedule",
    "akitabox.ui.dialogs.assign.assign",
  ]);
})();
