import moment from "moment-timezone";
(function () {
  angular
    .module("akitabox.core.lib.moment", [])
    .factory("moment", MomentService);

  /* @ngInject */
  function MomentService($window) {
    if (!moment) throw new Error("moment is not available");
    return moment;
  }
})();
