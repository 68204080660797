(function () {
  angular
    .module("akitabox.core.services.user", ["akitabox.core.services.http"])
    .factory("UserService", UserService);

  /** @ngInject */
  function UserService($q, $log, HttpService) {
    var service = {
      init: init,
      getCurrent: getCurrent,
      getPermissions: getPermissions,
      get: get,
      getAll: getAll,
      getByIdentity: getByIdentity,
    };

    var currentUser;
    var permissions = getDefaultPermissionGroup();

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Initialize, get user and permissions in an organization
     *
     * @param {String} organizationId Organization ID
     *
     * @return {Promise<Object|Error>}  Resolves with user
     */
    function init(organizationId) {
      var route = "/organizations/" + organizationId + "/user";
      return HttpService.get(route).then(function (user) {
        if (user) {
          currentUser = user;
          permissions = user.permission_group;
        } else {
          $log.error("Unable to get user");
        }
        return currentUser;
      });
    }

    /**
     * Get current user
     *
     * @return {Object}   Current user
     */
    function getCurrent() {
      return currentUser;
    }

    /**
     * Get current user's permissions
     *
     * @return {Object}   Current user's permissions
     */
    function getPermissions() {
      return permissions;
    }

    /**
     *  Get a list of users for the current organization
     *
     * @param {String} organizationId   Organization ID
     * @param {Object} params           additional query params
     *
     * @return {Promise<Array<User>}    Resolves with users matching the query
     */
    function get(organizationId, params) {
      var route = "/organizations/" + organizationId + "/users";
      return HttpService.get(route, params);
    }

    /**
     *  Get a list of users for the current organization
     *
     * @param {String} organizationId   Organization ID
     * @param {Object} params           additional query params
     *
     * @return {Promise<Array<User>}    Resolves with users matching the query
     */
    function getAll(organizationId, params) {
      var route = "/organizations/" + organizationId + "/users";
      return HttpService.getAll(route, params);
    }

    function getByIdentity(organizationId, identityId, params) {
      var route = "/organizations/" + organizationId + "/users";
      params = params || {};
      params.identity = identityId;
      return HttpService.get(route, params).then(function (users) {
        if (users.length) return users[0];
        return $q.reject("User not found");
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Return a permission group with default permissions
     *
     * @return {Object}   Default permission group
     */
    function getDefaultPermissionGroup() {
      return {
        is_administrator: false,
        // Models
        asset: {
          read: true,
        },
        comment: {
          read: true,
        },
        document: {
          read: true,
        },
        future_task: {
          read: true,
        },
        markup: {
          read: true,
        },
        qrcode: {},
        request: {
          read: true,
        },
        room: {
          read: true,
        },
        tag: {
          read: true,
        },
        task: {
          read: true,
        },
        work_order_log: {
          read: true,
        },
      };
    }
  }
})();
