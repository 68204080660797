(function () {
  angular
    .module("akitabox.desktop.directives.tags", ["akitabox.core"])
    .directive("abxTags", AbxTagsDirective);

  function AbxTagsDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/tags/tags.html",
      controller: AbxTagsController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        tags: "=",
        onClick: "&?",
        onChange: "&?",
      },
    };

    function postLink($scope) {
      $scope.$emit("$viewContentLoaded");
    }
  }

  function AbxTagsController() {
    var self = this;

    // Attributes
    self.allowClick = angular.isFunction(self.onClick);
    self.allowRemove = angular.isFunction(self.onChange);

    // Functions
    self.remove = remove;
    self.select = select;

    function remove($index) {
      var removed = self.tags.splice($index, 1)[0];
      self.onChange({ tags: self.tags, removed: removed });
    }

    function select($index) {
      var tag = self.tags[$index];
      if (self.allowClick) self.onClick({ tag: tag });
    }
  }
})();
