(function () {
  angular
    .module("akitabox.core.services.association", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "akitabox.core.services.organization",
    ])
    .factory("AssociationService", AssociationService);

  /** @ngInject **/
  function AssociationService(models, HttpService, OrganizationService) {
    var service = {
      // Routes
      buildOrganizationBaseRoute: buildOrganizationBaseRoute,
      buildOrganizationDetailRoute: buildOrganizationDetailRoute,
      // Create
      create: create,
      // Update
      update: update,
      // Delete
      remove: remove,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      // Messages
      getMessageAboutMissingRelationships,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildOrganizationBaseRoute(orgId) {
      return (
        OrganizationService.buildDetailRoute(orgId) +
        "/" +
        models.ASSOCIATION.ROUTE_PLURAL
      );
    }

    function buildOrganizationDetailRoute(orgId, attachmentId) {
      var base = buildOrganizationBaseRoute(orgId);

      // Attachment detail
      return base + "/" + attachmentId;
    }

    /******************* *
     *   Create
     * ****************** */

    function create(orgId, data, params) {
      var route = buildOrganizationBaseRoute(orgId);
      return HttpService.create(route, data, {}, params);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(orgId, associationId, data) {
      var route = buildOrganizationDetailRoute(orgId, associationId);
      return HttpService.patch(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(orgId, associationId) {
      var route = buildOrganizationDetailRoute(orgId, associationId);
      return HttpService.remove(route);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(orgId, params) {
      var route = buildOrganizationBaseRoute(orgId);
      return HttpService.get(route, params);
    }

    function getAll(orgId, params) {
      var route = buildOrganizationBaseRoute(orgId);
      return HttpService.getAll(route, params);
    }

    function getById(orgId, associationId, params) {
      var route = buildOrganizationDetailRoute(orgId, associationId);
      return HttpService.getById(route, params);
    }

    /******************* *
     *   Messages
     * ****************** */

    function getMessageAboutMissingRelationships(
      relationshipsWithoutPermissionCount,
      buildingsWithoutPermissionCount
    ) {
      const pluralizedRelationship =
        "relationship" + (relationshipsWithoutPermissionCount === 1 ? "" : "s");
      const pluralizedBuilding =
        "building" + (buildingsWithoutPermissionCount === 1 ? "" : "s");
      const message = `There are ${relationshipsWithoutPermissionCount} additional ${pluralizedRelationship} in ${buildingsWithoutPermissionCount} ${pluralizedBuilding} that you don't have access to`;
      return message;
    }
  }
})();
