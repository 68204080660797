/**
 * @file
 * This file contains the basics for bootstrapping the angularjs
 * application from within react.
 */
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { bootstrap } from "../../app.bootstrap";
import { getENV } from "../../utils/getEnv";

/**
 * Type for the Angular App context.
 */
export interface AngularAppContextType {
  injector: angular.auto.IInjectorService | null;
}

/**
 * Context that provides access to the legacy AngularJS application.
 */
export const AngularAppContext = createContext<AngularAppContextType>({
  injector: null,
});

/**
 * This hook can be used to spawn the AngularJS legacy application.
 * Only one component should use this hook, and it should not dismount.
 * Creates a new instance of the angularjs app and loads it into a new
 * app `div` under the react root.
 *
 * @return `null` until the angularjs application is bootstrapped, and then
 * it will return the angularjs injector.
 */
export const useCreateAngularApp = () => {
  const [injector, setInjector] =
    useState<angular.auto.IInjectorService | null>(null);

  // on mount, instantiate the angularjs app and store the injector
  useEffect(() => {
    (async () => {
      // This needs to be done manually instead of via a jsx element. Spawning
      // angularjs into a JSX `<div>` causes react to blow up badly.
      // <div id="app" ui-view></div>
      const angularContainer = document.createElement("div");
      angularContainer.setAttribute("ui-view", "");
      angularContainer.setAttribute("id", "app");
      document.getElementById("react-app")?.appendChild(angularContainer);

      // We are injecting the GoogleMapsApi Script here
      // since we were having issues in inject it on angular app
      const envName = getENV();
      let googleBrowserKey = "";
      switch (envName) {
        case "LOCAL":
          googleBrowserKey = "AIzaSyDCZF2zMrbQDEmVaFubYg4VlHcca5GvkZA";
          break;
        case "TEMP":
          googleBrowserKey = "AIzaSyCis-ISfz-QeEeOJSc9zlQ8N0BqegCHirw";
          break;
        case "BETA":
          googleBrowserKey = "AIzaSyBAr81HO323SXMkB-L1eB3rBnVmzqYzmqs";
          break;
        case "PRODUCTION":
          googleBrowserKey = "AIzaSyDSG-vCwAB1fOrb9KT9nNCcOsACQ9I0s4Q";
          break;
        default:
          googleBrowserKey = "AIzaSyDCZF2zMrbQDEmVaFubYg4VlHcca5GvkZA";
          break;
      }
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `//maps.googleapis.com/maps/api/js?key=${googleBrowserKey}&libraries=drawing,places`;
      document.body.appendChild(googleMapsScript);
      const result = await bootstrap();
      setInjector(result);
    })();
  }, []);

  return injector;
};

/**
 * This hook provides access to the legacy AngularJS application's
 * root injector. This will error if the application has not beeen
 * set up yet, so the bulk of the application is held off from rendering
 * until the angularjs app is ready.
 *
 * @returns The root injector for the AngularJS application.
 */
export const useAngularApp = (): angular.auto.IInjectorService => {
  const { injector } = useContext(AngularAppContext);
  if (!injector) {
    throw new Error("Angular application must be initialized.");
  }
  return injector;
};

/**
 * This hook sets up subscriptions necessary to synchronize the
 * state of the angularjs `angular-ui-router` router, and the react
 * `react-router-dom` router.
 *
 * Location changes made outside of react are basically invisible to
 * `react-router-dom`, so this hook subscribes to every angularjs navigation
 * using either `$state.go` (aka `Router.go`) or `$location.url`. When
 * those navigation events happen, the path, query parameters, and
 * history state are replaced with identical ones so the react router
 * will properly update and we will render the apropriate route.
 */
export const useAngularReactRouterSync = () => {
  const injector = useAngularApp();
  const navigate = useNavigate();

  useEffect(() => {
    const $rootScope = injector.get("$rootScope");

    // The AngularJS app ignores (and removes) unused query params.
    // This results in some problems here, in that query strings get
    // preserved awkwardly when transitioning between angular and react.
    // TODO: fix that

    return $rootScope.$on(
      "$locationChangeSuccess",
      (_event, newUrl: string, _oldUrl: string, newState: string) => {
        const { pathname, search } = new URL(newUrl);
        navigate(`${pathname}${search}`, {
          state: newState,
          replace: true,
        });
      }
    );
  }, [injector, navigate]);
};
