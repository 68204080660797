(function () {
  angular
    .module("akitabox.ui.dialogs.filePreview", [
      "ngMaterial",
      "akitabox.core.services.associatedText",
      "akitabox.core.services.document",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.core.services.asset",
      "akitabox.core.services.organization",
      "akitabox.core.services.pin",
      "akitabox.core.services.recognizedText",
      "akitabox.core.services.room",
      "akitabox.ui.dialogs",
      "akitabox.ui.dialogs.document.tag",
    ])
    .controller("FilePreviewDialogController", FilePreviewDialogController);

  /* @ngInject */
  function FilePreviewDialogController(
    // Angular
    $log,
    $scope,
    $q,
    $document,
    $timeout,
    $cookies,
    $rootScope,
    // Material
    $mdDialog,
    //Constants
    EVENT_MEDIA_REFRESH,
    EVENT_MEDIA_ZOOM_IN,
    EVENT_MEDIA_ZOOM_OUT,
    EVENT_MEDIA_ZOOM_RESET,
    EVENT_PIN_REFRESH,
    CHECK_TEXT_RECOGNITION_TEXT_TOGGLE_COOKIE,
    // Services
    AssetService,
    AssociatedTextService,
    AttachmentService,
    ConfirmationDialog,
    DocumentService,
    DownloadDocumentDialog,
    OrganizationService,
    RecognizedTextService,
    RoomService,
    TagDocumentDialog,
    ToastService,
    // Models
    models
  ) {
    var self = this;

    // Constants
    var OCR_CONTENT_ID = "ocr-content";
    var OCR_TEXT_CONTAINER_CLASS = "pre-process-ocr-text-container";
    var OCR_TEXT_CANVAS_CLASS = "pre-process-ocr-text-canvas";
    var ABX_DESCRIPTION_ATTR = "abx-description";
    var ABX_ID_ATTR = "abx-text-id";
    var OCR_TEXT_CLASS = "pre-process-ocr-text-box";
    var OCR_TEXT_SELECTED_CLASS = "selected";
    var PIN_VALUES_ACCEPTED = [
      "string",
      "paragraph",
      "number",
      "float",
      "url",
      "int",
    ];

    const organization = OrganizationService.getCurrent();

    // Private Attributes
    let textBoxes = [];
    let $textContainer;
    let $textCanvas;

    // Attributes
    self.document = null;
    self.selectedText = "";
    self.showControls = false;
    self.loading = true;
    self.ocrDisabled = true;
    self.ocrLoading = true;
    self.annotationShift = 0;
    self.imageDimensions = null;
    self.imageOrientation = 0;
    self.imageRotation = 0;
    self.orientRotation = 0; // 90, 180, 270
    self.textRotation = 0;
    self.textShiftX = 0;
    self.textShiftY = 0;
    self.autoAlignAnnotations = true;
    self.validPinFields = [];
    self.organization = OrganizationService.getCurrent();
    self.recognizedTexts = [];
    self.pinUpdated = {};
    self.lastValueSelected = {};
    self.undoTimeout;
    self.attachments = [];
    self.annotatedDocument;
    self.annotatedDocuments = {};
    self.totalOfAttachments = 1;
    self.currentAttachmentPosition = 1;
    self.entityType = self.pin.pinType.protected_type.toLowerCase();
    self.initialDocumentId = self.documentId;
    self.supportMultipleFiles = self.supportMultipleFiles || false;
    self.hasAnnotations = false;
    self.showAnnotations = false;
    self.showOCR =
      $cookies.get(CHECK_TEXT_RECOGNITION_TEXT_TOGGLE_COOKIE) === "true";
    self.selectedTextRecognitionIds = [];
    self.isPDF = false;
    self.isUnsupportedFile = false;
    self.documentOrder = self.documentOrder || [];
    self.imageData;
    self.previousPinValue = {};

    // Functions
    self.cancel = $mdDialog.cancel;
    self.getRecognizedTextData = getRecognizedTextData;
    self.addTextBoxes = addTextBoxes;
    self.onRotate = onRotate;
    self.onInputChange = onInputChange;
    self.onInputClear = onInputClear;
    self.onClickLabel = onClickLabel;
    self.zoomIn = zoomIn;
    self.zoomOut = zoomOut;
    self.reset = reset;
    self.undoChange = undoChange;
    self.editTags = editTags;
    self.editDocumentName = editDocumentName;
    self.cancelEditDocumentName = cancelEditDocumentName;
    self.saveDocumentName = saveDocumentName;
    self.documentNameUpdated = documentNameUpdated;
    self.fetchAttachmentsDocument = fetchAttachmentsDocument;
    self.onDocumentPagination = onDocumentPagination;
    self.onDownload = onDownload;
    self.toggleAnnotations = toggleAnnotations;
    self.toggleTextBoxes = toggleTextBoxes;
    self.onDelete = onDelete;
    self.onInputBlur = onInputBlur;
    self.onInputSelected = onInputSelected;
    self.regenerateOCR = regenerateOCR;

    // Get Ocr data when <ocr-content></ocr-content> is on the DOM
    $scope.$watch(document.getElementById(OCR_CONTENT_ID), init);

    // =================
    // Public Functions
    // =================

    function init() {
      if (!self.pin) {
        self.cancel();
        ToastService.showError(
          "Error opening modal, initial parameters not defined"
        );
      } else {
        if (self.initialDocumentId) {
          fetchAttachmentsDocument();
          filterPinFields(self.pin.pinType.fields);
        } else {
          //This will load the values from a pin, when this is loaded from a bff
          getPinValues(self.pin).then((values) => {
            self.pin.values = values;
            fetchAttachmentsDocument();
            filterPinFields(self.pin.pinType.fields);
          });
        }
      }
    }

    /**
     * Retrieve OCR data to display on the image
     */
    function getRecognizedTextData(document, reprocessOCR = false) {
      self.selectedText = "";
      self.recognizedTexts = [];
      self.ocrLoading = true;
      self.ocrDisabled = false;

      const params = {};
      if (reprocessOCR) {
        params["regenerate"] = true;
      }

      return RecognizedTextService.getByDocumentId(
        organization._id,
        document._id,
        params
      )
        .then((data) => {
          self.recognizedTexts = data;
          // force the image to refresh here so we can get the image rendered
          // with the ocr text
          $scope.$broadcast(EVENT_MEDIA_REFRESH);
        })
        .catch((error) => {
          $log.error(error.message);
          ToastService.showError("Failed to get annotations for the image");
        })
        .finally(() => {
          self.ocrLoading = false;
          self.ocrDisabled = !self.recognizedTexts.length;
          self.showOCR = Boolean(self.recognizedTexts.length);
        });
    }

    function filterPinFields(fields) {
      const validFields = fields.filter((field) =>
        PIN_VALUES_ACCEPTED.includes(field.data_type)
      );
      if (!validFields.length) return;

      self.validPinFields = filterPinValues(validFields, self.pin.values);
    }

    /**
     *
     * @param {pinFields} pinFields Array of pin fields that will be displayed in the ui
     * @param {array} pinValues Values that will be displayed next to the pin field
     * @returns A custom array of pin fields, pin value and assetId
     */
    function filterPinValues(pinFields, pinValues) {
      return pinFields.map((field) => {
        return {
          ...field,
          pin_value: pinValues[field._id].value,
          pin_value_id: pinValues[field._id]._id,
          is_editing_field: false,
        };
      });
    }

    /**
     * Create text text boxes and add them to the DOM
     *
     * @param {Object}  Event {dimensions, rotation, orientation}
     */
    function addTextBoxes($event) {
      // Add text container
      const ocrContent = document.getElementById(OCR_CONTENT_ID);
      const text = self.recognizedTexts ? self.recognizedTexts : [];
      if (!ocrContent || !text.length) {
        return;
      }

      const prevTextContainers = document.querySelectorAll(
        `.${OCR_TEXT_CONTAINER_CLASS}`
      );
      if (prevTextContainers.length) {
        prevTextContainers.forEach(($e) => $e.remove());
      }
      var textContainer = document.createElement("div");
      textContainer.setAttribute("class", OCR_TEXT_CONTAINER_CLASS);
      $textContainer = angular.element(textContainer);

      var dimensions = $event.dimensions;
      self.imageOrientation = $event.orientation;
      self.imageRotation = $event.rotation;
      self.imageDimensions = dimensions;

      var imageWidth = dimensions.width;
      var imageHeight = dimensions.height;

      // radii for every text box (rect)
      const radii = 4;
      // gives extra space in every text box (rect)
      const offsetSize = 8;
      const offsetPosition = 4;
      // create canvas element with same size as image
      const prevTextCanvases = document.querySelectorAll(
        `.${OCR_TEXT_CANVAS_CLASS}`
      );
      if (prevTextCanvases.length) {
        prevTextCanvases.forEach(($e) => $e.remove());
      }
      const textCanvas = document.createElement("canvas");
      textCanvas.classList.add(OCR_TEXT_CANVAS_CLASS);
      textCanvas.width = imageWidth;
      textCanvas.height = imageHeight;
      // elevate canvas element on top of image
      textCanvas.style.top = "0";
      textCanvas.style.left = "0";
      textCanvas.style.position = "absolute";
      textCanvas.style.zIndex = 1;

      // put canvas in DOM within ocr section in file preview
      ocrContent.appendChild(textCanvas);

      let textCanvasCtx;
      if (textCanvas.getContext) {
        textCanvasCtx = textCanvas.getContext("2d");
        // opaque black for background rect
        textCanvasCtx.fillStyle = "rgba(0, 0, 0, 0.26)";
        textCanvasCtx.fillRect(0, 0, imageWidth, imageHeight);
        // keep background rect where it doesn't overlap with new shapes
        textCanvasCtx.globalCompositeOperation = "destination-out";
        textCanvasCtx.fillStyle = "rgba(255, 255, 255, 1)";
      }

      for (var i = 0; i < text.length; i++) {
        const { _id, top, left, bottom, right, text: value } = text[i];
        const { x, y, width, height } = {
          x: Number(left) - offsetPosition,
          y: Number(top) - offsetPosition,
          width: Number(right - left) + offsetSize,
          height: Number(bottom - top) + offsetSize,
        };

        if (textCanvasCtx) {
          // "punch holes" in background rect
          textCanvasCtx.beginPath();
          textCanvasCtx.roundRect(x, y, width, height, radii);
          textCanvasCtx.fill();
        }

        var el = document.createElement("div");

        // Styles
        if (width < 0) {
          el.style.left = `${x + width}px`;
          el.style.width = `${0 - width}px`;
        } else {
          el.style.left = `${x}px`;
          el.style.width = `${width}px`;
        }
        if (height < 0) {
          el.style.top = `${y + height}px`;
          el.style.height = `${0 - height}px`;
        } else {
          el.style.top = `${y}px`;
          el.style.height = `${height}px`;
        }
        el.style.borderRadius = `${radii}px`;
        el.classList.add(OCR_TEXT_CLASS);

        // Attributes
        el.setAttribute("id", "ocr-box-id-" + i);
        el.setAttribute(ABX_DESCRIPTION_ATTR, value);
        el.setAttribute(ABX_ID_ATTR, _id);

        el.onclick = onBoxClick;

        textBoxes.push(el);
        textContainer.appendChild(el);
      }

      $textCanvas = angular.element(textCanvas);
      if ($textCanvas && !self.showOCR) {
        $textCanvas.hide();
      }

      if ($textContainer) {
        $textContainer.css({
          position: "fixed",
          top: 0,
          left: 0,
          width: imageWidth,
          height: imageHeight,
          "z-index": 1,
        });
      }

      ocrContent.appendChild(textContainer);

      $scope.$apply(function () {
        self.showControls = false;
      });
      //Align the grids if the image is rotated
      rotateHighlightBoxes(self.document.rotation);
    }

    function toggleAnnotations() {
      if (self.annotatedDocument) {
        self.loading = true;
        self.showAnnotations = !self.showAnnotations;
        return $timeout(function () {
          self.loading = false;
        }, 100);
      }
    }

    /**
     * Toggle text on/off.
     * @param {boolean | undefined} on forces the toggle value. leave as undefined if toggling behavior is needed
     */
    function toggleTextBoxes(on) {
      if (self.isPDF) return $q.resolve();

      // Allow user to pass in toggle value or default to
      // actual toggling
      if (on === true) {
        self.showOCR = true;
      } else if (on === false) {
        self.showOCR = false;
      } else {
        self.showOCR = !self.showOCR;
      }

      // save value to our cookies
      $cookies.put(CHECK_TEXT_RECOGNITION_TEXT_TOGGLE_COOKIE, self.showOCR);

      if (self.showOCR) {
        if (self.textRotation !== self.document.rotation) {
          rotateHighlightBoxes(self.document.rotation);
        }
        if ($textCanvas) $textCanvas.show();
      } else {
        clearSelectedBoxes();
        self.selectedText = "";
        if ($textCanvas) $textCanvas.hide();
      }

      return $timeout(() => reset(), 100);
    }

    /**
     * Reprocess OCR and Toggle green text boxes on/off.
     */
    function regenerateOCR() {
      if (self.isPDF) return;

      if ($textCanvas) $textCanvas.hide();

      return getRecognizedTextData(self.document, true).then(() => {
        $scope.$broadcast(EVENT_MEDIA_REFRESH);
        return $timeout(() => reset(), 100);
      });
    }

    /**
     * Handle image rotated
     *
     * @param {Object}  Event {dimensions, rotation}
     */
    function onRotate(direction, $event) {
      const rotationDirection = direction === "left" ? -90 : 90;
      let rotation;
      if (self.document.rotation === 0 && direction === "left") {
        rotation = 360 + rotationDirection;
      } else {
        rotation = self.document.rotation + rotationDirection;
      }
      rotate(rotation);
    }

    /**
     * Set selected text to box description
     *
     * @param {Event} $event Event
     */
    function onBoxClick($event) {
      if (!self.showOCR) {
        toggleTextBoxes(true);
      }
      $scope.$apply(function () {
        $event.target.classList.toggle(OCR_TEXT_SELECTED_CLASS);

        var description = $event.target.getAttribute(ABX_DESCRIPTION_ATTR);
        self.selectedTextRecognitionIds.push(
          $event.target.getAttribute(ABX_ID_ATTR)
        );

        const valueExist = self.selectedText
          .trim()
          .split(" ")
          .includes(description);
        if (valueExist)
          self.selectedText = self.selectedText
            .trim()
            .split(" ")
            .filter((val) => val !== description)
            .join(" ");
        else
          self.selectedText +=
            self.selectedText.length === 0 ? description : " " + description;
      });
    }

    /**
     * This function will update the pin value, according to what
     * @param {pinValue} field The pin that will be updated
     */
    function onClickLabel(pinField) {
      const value = self.selectedText;
      self.lastValueSelected = pinField;
      saveChange(pinField, value);
    }

    function undoChange() {
      clearSelectedBoxes();
      self.showUndo = false;
      if (self.pin.pinType.is_room) {
        updateRoomValue(
          self.previousPinValue,
          self.previousPinValue.pin_value,
          true
        );
      } else {
        updateAssetValue(
          self.previousPinValue,
          self.previousPinValue.pin_value,
          true
        );
      }
    }

    function saveChange(pinField, value) {
      clearSelectedBoxes();
      self.previousPinValue = { ...self.lastValueSelected };
      if (self.pin.pinType.is_room) {
        updateRoomValue(pinField, value);
      } else {
        updateAssetValue(pinField, value);
      }
    }

    function editDocumentName() {
      self.newDocumentName = "";
      self.isEditing = true;
    }

    function cancelEditDocumentName() {
      self.newDocumentName = "";
      self.isEditing = false;
    }

    function saveDocumentName() {
      self.isSaving = true;
      DocumentService.rename(
        self.document.building,
        self.document._id,
        self.newDocumentName
      )
        .then((doc) => {
          self.document = doc;
          self.isEditing = false;
          if (self.supportMultipleFiles) {
            self.document = doc;
            $rootScope.$broadcast("attachments:update", {
              entityId: self.pin._id,
            });
          } else {
            $scope.$emit(EVENT_PIN_REFRESH);
          }
        })
        .catch(ToastService.showError)
        .finally(() => {
          self.isSaving = false;
        });
    }

    function documentNameUpdated(event) {
      self.newDocumentName = event.value;
    }

    function onInputBlur(e, pinField) {
      const value = e.target.value;
      if (
        self.lastValueSelected.pin_value === undefined || //is undefined when you update a field go to another and click undo
        value === self.lastValueSelected.pin_value.toString()
      ) {
        pinField.is_editing_field = false;
        self.lastValueSelected = {};
        return;
      }
      saveChange(pinField, value);
    }

    function onInputSelected(pinField) {
      if (pinField._id === self.lastValueSelected._id) return;
      self.lastValueSelected = { ...pinField };
      pinField.is_editing_field = true;
    }

    // =================
    // Private Functions
    // =================
    function rotateHighlightBoxes(rotation) {
      self.textRotation = rotation;
      if (self.textRotation === 360) {
        self.textRotation = 0;
      }
      const rotate = { transform: `rotate(${self.textRotation}deg)` };
      [$textContainer, $textCanvas].forEach(($el) => {
        if ($el) $el.css(rotate);
      });
    }

    function onInputChange($event) {
      self.selectedText = $event.value;
      if (!$event.value) {
        self.selectedTextRecognitionIds = [];
      }
    }

    function onInputClear() {
      clearSelectedBoxes();
      self.selectedText = "";
      self.selectedTextRecognitionIds = [];
    }

    function updateRoomValue(pinField, value, isUndo) {
      const data = { value: value };
      RoomService.updateValue(
        self.pin.building,
        self.pin._id,
        pinField.pin_value_id,
        data
      )
        .then((pinResult) => {
          if (!isUndo) {
            showUndo(pinField, pinResult.value);
          }
          $scope.$emit(EVENT_PIN_REFRESH);
          return RoomService.getAllValues(pinField.building, self.pin._id);
        })
        .then((values) => {
          self.validPinFields = filterPinValues(self.validPinFields, values);
        })
        .catch((error) => {
          ToastService.showError(error);
          pinField.is_editing_field = false;
          pinField.pin_value = self.previousPinValue.pin_value;
        });
    }

    function updateAssetValue(pinField, value, isUndo) {
      if (pinField.data_type === "float" || pinField.data_type === "int") {
        value = value.replace(",", "");
      }
      const data = { value: value };
      AssetService.updateValue(
        self.pin.building,
        self.pin._id,
        pinField.pin_value_id,
        data
      )
        .then((pinResult) => {
          if (!isUndo) {
            showUndo(pinField, pinResult.value);
          }
          $scope.$emit(EVENT_PIN_REFRESH);
          if (self.selectedTextRecognitionIds.length) {
            associateTexts(self.pin._id, pinField);
          }
          $scope.$emit(EVENT_PIN_REFRESH);
          return AssetService.getAllValues(self.pin.building, self.pin._id);
        })
        .then((values) => {
          self.validPinFields = filterPinValues(
            self.validPinFields,
            values,
            self.pin._id
          );
        })
        .catch((error) => {
          ToastService.showError(error);
          pinField.is_editing_field = false;
          pinField.pin_value = self.previousPinValue.pin_value;
        });
    }

    function associateTexts(assetId, pin) {
      let requests = [];
      self.selectedTextRecognitionIds.forEach((selectedTextID) => {
        requests.push(associateText(assetId, pin, selectedTextID));
      });

      $q.all(requests)
        .then(() => {
          self.selectedTextRecognitionIds = [];
        })
        .catch(ToastService.showError);
    }

    function associateText(assetId, pin, recognizedTextId) {
      const data = {
        recognized_text: recognizedTextId,
        asset: assetId,
        label: pin.name,
      };
      return AssociatedTextService.create(data);
    }

    function rotate(rotation) {
      // we need to always turn off these boxes when rotating or else it looks weird
      if ($textCanvas && self.showOCR) {
        $textCanvas.hide();
      }

      DocumentService.rotate(
        self.document.building,
        self.document._id,
        rotation % 360
      ).then((_document) => {
        self.document = _document;
        if (self.supportMultipleFiles) {
          self.attachments[self.currentAttachmentPosition - 1].document =
            _document;
        }
        rotateImage(rotation);
        if (self.showOCR) {
          rotateHighlightBoxes(rotation);
          $timeout(() => {
            if ($textCanvas) $textCanvas.show();
          }, 500);
        }
      });
    }

    function clearSelectedBoxes() {
      textBoxes = textBoxes.map(function ($box) {
        $box.classList.remove(OCR_TEXT_SELECTED_CLASS);
        return $box;
      });
    }

    function rotateImage(rotation) {
      let imageElement = $document.find("#ocr-content"); //$("#ocr-content");
      const degrees = rotation === 360 ? 0 : rotation;
      imageElement.find("img").css("transform", "rotate(" + degrees + "deg)");
    }

    function zoomIn() {
      $scope.$broadcast(EVENT_MEDIA_ZOOM_IN);
    }

    function zoomOut() {
      $scope.$broadcast(EVENT_MEDIA_ZOOM_OUT);
    }

    function reset() {
      $scope.$broadcast(EVENT_MEDIA_ZOOM_RESET);
    }

    function showUndo(pin, value) {
      self.showUndo = true;
      self.pinUpdated = {
        ...self.lastValueSelected,
        value: value,
        pin_value: value,
      };
      self.selectedText = "";
      if (self.undoTimeout) {
        //remove previous timeout before create a new one
        $timeout.cancel(self.undoTimeout);
      }
      self.undoTimeout = $timeout(function () {
        self.showUndo = false;
      }, 5000);
    }

    function editTags() {
      const options = {
        documents: [self.document],
        multiple: true,
      };

      TagDocumentDialog.show({ locals: options }).then(function (docs) {
        self.document = docs[0];
      });
    }

    function onDownload() {
      var locals = {
        docs: [self.document],
      };
      DownloadDocumentDialog.show({ locals: locals, multiple: true }).catch(
        ToastService.showError
      );
    }

    function fetchAttachmentsDocument() {
      if (
        angular.isString(self.entityType) &&
        !angular.isEmpty(self.entityType)
      ) {
        // fetch for the attachments of the PIN, populate the self.attachments
        const params = {
          entity_id: self.pin._id,
          entity_type: self.entityType,
        };

        if (self.supportMultipleFiles) {
          AttachmentService.getAll(self.pin.building, params)
            .then(function (_attachments) {
              if (!angular.isEmpty(_attachments)) {
                self.attachments = [];
                self.annotatedDocuments = {};
                for (let attachment of _attachments) {
                  const document = attachment.document;
                  if (!document) {
                    continue;
                  }
                  const docAnnotated = document.document_annotated;
                  if (docAnnotated) {
                    self.annotatedDocuments[docAnnotated] = attachment.document;
                  } else {
                    self.attachments.push(attachment);
                  }
                }

                // If there is a document order, apply it to the attachments
                if (
                  self.documentOrder.length > 0 &&
                  self.documentOrder.length >= self.attachments.length
                ) {
                  const sortedAttachments = [];

                  self.documentOrder.forEach((documentId) => {
                    const attachment = self.attachments.find(
                      (attachment) => attachment.document._id === documentId
                    );
                    if (attachment) {
                      sortedAttachments.push(attachment);
                    }
                  });

                  if (self.attachments.length === sortedAttachments.length) {
                    self.attachments = sortedAttachments;
                  }
                }
                self.totalOfAttachments =
                  self.attachments.length === 0 ? 1 : self.attachments.length;

                // The initial document (the thumbnail that user clicked)
                // will be the first displayed
                const attachmentIndex = self.attachments.findIndex(
                  (attachment) =>
                    attachment.document._id === self.initialDocumentId
                );
                if (attachmentIndex >= 0) {
                  self.currentAttachmentPosition = attachmentIndex + 1;
                  self.document = self.attachments[attachmentIndex].document;
                } else {
                  self.document = self.attachments[0].document;
                }

                return loadDocument(self.document);
              }
            })
            .catch(function (error) {
              self.totalOfAttachments = 1;
              ToastService.showError(error);
            })
            .finally(function () {
              self.loading = false;
              if (self.document) {
                getRecognizedTextData(self.document);
              }
            });
        } else {
          self.totalOfAttachments = 1;
          DocumentService.getById(self.pin.building, self.initialDocumentId)
            .then(loadDocument)
            .catch(function (error) {
              ToastService.showError(error);
            })
            .finally(function () {
              self.loading = false;
              if (self.document) {
                getRecognizedTextData(self.document);
              }
            });
        }
      } else {
        $log.error(
          "FilePreviewController: entityType must defined " +
            "to enable image carousel"
        );
        ToastService.showError("Error to handle image");
      }
    }

    function onDocumentPagination(moveForward) {
      self.loading = true;
      let currentDocument = self.document;
      const move = moveForward ? 1 : -1;
      const attachmentCurrentIndex = self.attachments.findIndex(
        (_attachment) => _attachment.document._id === currentDocument._id
      );

      if (attachmentCurrentIndex >= 0) {
        // Check if it's the last item of the carousel. If yes, return to
        let newIndex =
          attachmentCurrentIndex + move === self.attachments.length
            ? 0
            : attachmentCurrentIndex + move;
        // If it should return to the last position
        newIndex = newIndex === -1 ? self.attachments.length - 1 : newIndex;
        self.document = { ...self.attachments[newIndex].document };
        self.currentAttachmentPosition = newIndex + 1;
        loadDocument(self.document)
          .catch(ToastService.showError)
          .finally(function () {
            self.loading = false;
            getRecognizedTextData(self.document);
          });
      } else {
        ToastService.showError("Document selected is not available");
      }
    }

    function loadDocument(document) {
      if (!document.is_supported) {
        self.isUnsupportedFile = true;
        self.annotatedDocument = null;
        self.recognizedTexts = [];
        self.ocrDisabled = true;
        self.ocrLoading = false;
      } else {
        self.isUnsupportedFile = false;
        self.ocrDisabled = false;
      }

      if (isPDF(document.extension)) {
        return $timeout(function () {
          self.isPDF = true;
          self.annotatedDocument = null;
          self.recognizedTexts = [];
          self.ocrDisabled = true;
          self.ocrLoading = false;
        }, 100).then(function () {
          $scope.$broadcast(EVENT_MEDIA_REFRESH);
        });
      }

      // force the image to refresh here so we don't have to wait for the ocr text
      // to come back from the api to load the image
      $timeout(() => $scope.$broadcast(EVENT_MEDIA_REFRESH), 0);

      if ($textContainer && $textContainer.length) {
        $textContainer.remove();
      }

      if ($textCanvas && $textCanvas.length) {
        $textCanvas.remove();
      }

      self.document = document;
      self.annotatedDocument = self.annotatedDocuments[document._id];
      self.showAnnotations = !!self.annotatedDocument;

      return $q.resolve();
    }

    function isPDF(extension) {
      return extension.toLowerCase().contains("pdf");
    }

    function onDelete() {
      ConfirmationDialog.show({
        title: `${self.document.path}`,
        prompt: "Delete the Photo?",
        confirmText: "Ok",
        onConfirm: () => {
          if (self.supportMultipleFiles) {
            const attachment =
              self.attachments[self.currentAttachmentPosition - 1];
            if (!attachment) {
              return ToastService.showError(
                new Error("Cannot remove non-existent attachment")
              );
            }
            self.loading = true;
            const update = {
              action: "removeLink",
              entity_id: self.pin._id,
            };
            // Remove Remotely
            return AttachmentService.update(
              self.pin.building,
              attachment._id,
              update
            )
              .catch(ToastService.showError)
              .finally(function () {
                if (self.attachments.length === 1) {
                  self.cancel();
                } else {
                  // If the selected photo is the last,
                  // it must move forward the carousel
                  const forwardPagination =
                    self.currentAttachmentPosition === 1 ? true : false;

                  const indexOfAttachmentToDelete =
                    self.currentAttachmentPosition - 1;

                  onDocumentPagination(forwardPagination);

                  // Remove Locally
                  self.attachments.splice(indexOfAttachmentToDelete, 1);
                  self.currentAttachmentPosition = forwardPagination
                    ? self.currentAttachmentPosition - 1
                    : self.currentAttachmentPosition;
                  self.totalOfAttachments =
                    self.attachments.length === 0 ? 1 : self.attachments.length;
                }

                $rootScope.$broadcast("attachments:update", {
                  entityId: self.pin._id,
                });
              });
          }
        },
      });
    }

    function getPinValues(asset) {
      return AssetService.getAllValues(asset.building, asset._id);
    }
  }
})();
