(() => {
  /**
   * @component
   *
   * @property { CostLineCustomizationFormData } abxFormData
   * @property { ({ model: CostLineCustomizationFormData, valid: boolean }) => void } abxOnFormDataChange
   * @property { "Ea" | "SF" | "LF" } abxUnitOverride
   * @property { boolean } abxDisabled
   */
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineCustomizationForm", {
      bindings: {
        formData: "<abxFormData",
        onFormDataChange: "<abxOnFormDataChange",
        unitOverride: "<?abxUnitOverride",
        disabled: "<?abxDisabled",
      },
      controllerAs: "vm",
      controller: AbxCostLineCustomizationFormController,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/cost-line-customization-form/cost-line-customization-form.component.html",
    });

  /**
   * @typedef { object } CostLineCustomizationFormData
   * @property { string } unit
   * @property { number } costPerUnit
   * @property { number } quantity
   * @property { number } baseCost
   */

  /**
   * @class AbxCostLineCustomizationFormController
   * @property { CostLineCustomizationFormData } formData
   * @constructor
   * @ngInject
   **/
  function AbxCostLineCustomizationFormController() {
    this.UNITS = ["Ea", "LF", "SF"];

    /** @type { CostLineCustomizationFormData } */
    const defaultFormData = {
      baseCost: 0,
      quantity: 1,
      costPerUnit: 0,
      unit: this.UNITS[0],
    };

    this.$onChanges = (changes) => {
      if (changes.formData) {
        if (!this.formData || angular.isEmpty(this.formData)) {
          this.onFormDataChange({
            model: { ...defaultFormData },
            invalid: false,
          });
        } else {
          this.formData = { ...this.formData };
          this.validityMap.costPerUnit = isValidCost(this.formData.costPerUnit);
          this.validityMap.baseCost = isValidCost(this.formData.baseCost);
          this.validityMap.quantity = isValidQuantity(this.formData.quantity);
        }
      }
    };

    /**
     * Tracks the validity of each input
     */
    const validityMap = {
      unit: true,
      quantity: true,
      costPerUnit: true,
      baseCost: true,
    };

    /**
     * @param { keyof CostLineCustomizationFormData } field
     * @param { object } $event - AbxInput change $event
     */
    this.handleFieldChange = (field, { model, invalid }) => {
      const formData = { ...this.formData, [field]: model };

      validityMap[field] = !invalid;

      // If any form data values are missing or invalid, we'll use defaults
      const validQuantity = Math.max(1, formData.quantity) || 1;
      const validBaseCost = Math.max(0, formData.baseCost) || 0;
      const validCostPerUnit = Math.max(formData.costPerUnit, 0) || 0;

      if (field === "costPerUnit") {
        formData.baseCost = validCostPerUnit * validQuantity;
      } else if (field === "quantity") {
        formData.baseCost = validCostPerUnit * validQuantity;
      } else if (field === "baseCost") {
        formData.costPerUnit = validBaseCost / validQuantity;
      }

      this.onFormDataChange({ model: formData, invalid: this.isInvalid() });
    };

    this.validityMap = validityMap;

    this.isInvalid = () =>
      Object.keys(validityMap)
        .map((k) => validityMap[k])
        .some((v) => !v);

    this.getQuantityUnitDisplayValue = (quantityUnit) => {
      switch (quantityUnit) {
        case "ea":
          return "Ea";
        case "sf":
          return "SF";
        case "lf":
          return "LF";
        default:
          return quantityUnit;
      }
    };

    /**
     * @param { number } quantity
     * @returns { boolean }
     */
    function isValidQuantity(quantity) {
      return (
        typeof quantity === "number" &&
        !Number.isNaN(quantity) &&
        quantity >= 1 &&
        // ie11 support is not a goal for FCA
        Number.isInteger(quantity)
      );
    }

    /**
     * @param { number } cost
     * @returns { boolean }
     */
    function isValidCost(cost) {
      return typeof cost === "number" && !Number.isNaN(cost) && cost >= 0;
    }
  }
})();
