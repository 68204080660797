(function () {
  angular
    .module("akitabox.ui.components.cardToggle", ["akitabox.core.services.env"])
    .component("abxCardToggle", {
      bindings: {
        direction: "@?abxDirection",
      },
      controller: AbxCardToggleController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/card-toggle/card-toggle.component.html",
    });

  function AbxCardToggleController() {
    var self = this;

    // Attributes
    self.direction = angular.isDefined(self.direction)
      ? self.direction
      : "down";
  }
})();
