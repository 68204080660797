import { LinearProgress } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Color } from "./colors";
import { stylesheet } from "./stylesheet";

export interface LoadingBarProps {
  /**
   * Controls whether the bar is displayed.
   */
  loading: boolean;
}

/**
 * App loading bar displayed during route transitions. Fullwidth,
 * displayed fixed at the top of the screen.
 */
export const LoadingBar: FunctionComponent<LoadingBarProps> = ({ loading }) => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    if (!loading) {
      const timeout = setTimeout(() => setShown(false), 300);
      return () => clearTimeout(timeout);
    } else {
      setShown(true);
    }
  }, [loading]);
  return shown ? <LinearProgress css={ss.root} /> : <></>;
};

const ss = stylesheet({
  root: {
    position: "fixed",
    top: 0,
    width: "100%",
    /** Controls the color of the moving progress indicator bars. */
    "& .MuiLinearProgress-bar": {
      backgroundColor: Color.MidnightExpressLight,
    },
  },
});
