(function () {
  angular
    .module("akitabox.core.lib.zendesk", [])
    .factory("zendesk", ZendeskService);

  /* @ngInject */
  function ZendeskService($window) {
    if (angular.isDefined($window.zE)) {
      return $window.zE;
    }
    throw new Error("zendesk (zE) is not available");
  }
})();
