(function () {
  angular
    .module("akitabox.ui.components.dashboardTasks", [
      "ui.router",
      "akitabox.core.constants",
      "akitabox.core.router",
      "akitabox.core.services.identity",
      "akitabox.core.services.workOrder",
      "akitabox.core.services.request",
      "akitabox.core.services.session",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.components.collapsibleCard",
    ])
    .component("abxDashboardTasks", {
      bindings: {
        buildings: "<abxBuildings",
      },
      controller: AbxDashboardTasksController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/dashboard-tasks/dashboard-tasks.component.html",
    });

  function AbxDashboardTasksController(
    // Angular
    $q,
    $rootScope,
    $scope,
    $timeout,
    // Third party
    moment,
    raf,
    // Constants
    DASHBOARD_CARDS,
    WORK_ORDER_FILTER_OPTIONS,
    WORK_ORDER_STATUS_OPEN,
    SERVICE_REQUEST_STATUS_NEW,
    SERVICE_REQUEST_STATUS_OPEN,
    // Events,
    EVENT_REFRESH_DASHBOARD,
    EVENT_REFRESH_DASHBOARD_WO_COMPLETE,
    EVENT_REFRESH_DASHBOARD_ERROR,
    EVENT_REQUEST_CREATE,
    EVENT_WORK_ORDER_CREATE,
    // Services
    Router,
    IdentityService,
    WorkOrderService,
    RequestService,
    SessionService,
    ToastService,
    UserService
  ) {
    var self = this;

    var userPerms = UserService.getPermissions();
    self.permissions = {
      canOnlyViewMyWO: userPerms.task.read_own && !userPerms.task.read_all,
    };

    // Constants
    var MY_WORK_ORDERS = 0;
    var OPEN_WORK_ORDERS = 1;
    var NEW_SERVICE_REQUESTS = 2;
    var OPEN_SERVICE_REQUESTS = 3;
    var IS_ORG_VIEW = self.buildings.length > 1;
    var QUERY_ID = IS_ORG_VIEW
      ? self.buildings[0].organization
      : self.buildings[0]._id;
    var getWorkOrders = IS_ORG_VIEW
      ? WorkOrderService.getByOrganization
      : WorkOrderService.getByBuildingId;
    var getRequests = IS_ORG_VIEW
      ? RequestService.getByOrganizationWithPopulatedTask
      : RequestService.getWithPopulatedTask;
    var getRequestsCount = IS_ORG_VIEW
      ? RequestService.getByOrganization
      : RequestService.get;

    var today = moment().utc().endOf("day").toDate();

    // Private Attributes
    var buildingMap = {};
    var spacer = document.getElementsByClassName(
      "abx-dashboard-tasks__spacer"
    )[0];

    // Public Attributes
    self.counts = [null, null, null, null];
    self.filter = null;
    self.showWONameColumn = false;
    self.toggleOptions = {
      workOrder: [
        {
          value: "wo-my",
          icon: "person",
          label: "Mine",
          section: MY_WORK_ORDERS,
          count: self.counts[0],
        },
        {
          value: "wo-open",
          icon: "group",
          label: "Total",
          section: OPEN_WORK_ORDERS,
          count: self.counts[1],
        },
      ],
      request: [
        {
          value: "sr-new",
          icon: "notifications_active",
          label: "New",
          section: NEW_SERVICE_REQUESTS,
          count: self.counts[2],
        },
        {
          value: "sr-open",
          icon: "announcement",
          label: "In Progress",
          section: OPEN_SERVICE_REQUESTS,
          count: self.counts[3],
        },
      ],
    };
    self.intentFilterOptions = WORK_ORDER_FILTER_OPTIONS;
    self.selectedToggle = self.toggleOptions.workOrder[0].value;
    self.selectedSection = MY_WORK_ORDERS;
    self.showWorkOrderList = false;
    self.showRequestList = false;
    self.disableFilters = false;
    self.disableViewAll = true;
    self.workOrders = null;
    self.serviceRequests = null;
    self.myOverdueWorkOrderCount = null;
    self.totalOverdueWorkOrderCount = null;
    self.userId = IdentityService.getUserId();

    // Functions
    self.onFilterChange = onFilterChange;
    self.onToggleChange = onToggleChange;
    self.refresh = refresh;
    self.viewAll = viewAll;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (self.permissions.canOnlyViewMyWO) {
        self.toggleOptions.workOrder.pop();
      }

      // Get saved state
      var cardStates = SessionService.getDashboardCardStates();
      var tasksState = cardStates[DASHBOARD_CARDS.TASKS] || {};
      self.selectedSection = tasksState.activeSection || MY_WORK_ORDERS;
      self.selectedToggle = findToggle(self.selectedSection);
      self.filter = tasksState.filter || self.filter;

      mapBuildings();

      $timeout(function () {
        selectSection(self.selectedSection);
      });
    };

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_REFRESH_DASHBOARD, function (ev, eventArgs) {
      self.refresh(eventArgs);
    });
    $scope.$on(EVENT_REQUEST_CREATE, onRequestCreate);
    $scope.$on(EVENT_WORK_ORDER_CREATE, onWorkOrderCreate);

    // ------------------------
    //   Public Functions
    // ------------------------

    function onToggleChange($event) {
      self.selectedToggle = $event.value;
      var section = findSection($event.value);
      if (section !== null) {
        selectSection(section);
      }
    }

    function refresh(eventArgs) {
      self.workOrders = null;
      self.serviceRequests = null;
      self.disableFilters = false;
      saveState();

      toggleSpacer().then(function () {
        switch (self.selectedSection) {
          case MY_WORK_ORDERS:
            getMyWorkOrders();
            getMyOverdueWorkOrdersCount();
            break;
          case OPEN_WORK_ORDERS:
            getOpenWorkOrders();
            getTotalOverdueWorkOrdersCount();
            break;
          case NEW_SERVICE_REQUESTS:
            self.disableFilters = true;
            getNewServiceRequests();
            break;
          case OPEN_SERVICE_REQUESTS:
            self.disableFilters = true;
            getOpenServiceRequests();
            break;
        }

        var myWOCountRequest = getMyWorkOrdersCount();
        var newSRCountRequest = getNewServiceRequestsCount();
        var openWOCountRequest = getOpenWorkOrdersCount();
        var openSRCountRequest = getOpenServiceRequestsCount();
        if (eventArgs) {
          $q.all([
            myWOCountRequest,
            newSRCountRequest,
            openWOCountRequest,
            openSRCountRequest,
          ])
            .then(function () {
              $rootScope.$broadcast(
                EVENT_REFRESH_DASHBOARD_WO_COMPLETE,
                eventArgs
              );
            })
            .catch(function (error) {
              $rootScope.$broadcast(
                EVENT_REFRESH_DASHBOARD_ERROR,
                angular.extend({}, eventArgs, { error: error })
              );
            });
        }
      });
    }

    function saveState() {
      var cardStates = SessionService.getDashboardCardStates();
      var currentState = cardStates[DASHBOARD_CARDS.TASKS] || {};
      angular.extend(currentState, {
        activeSection: self.selectedSection,
        filter: self.filter,
      });
      cardStates[DASHBOARD_CARDS.TASKS] = currentState;
      SessionService.setDashboardCardStates(cardStates);
    }

    function onFilterChange($event) {
      var intent = self.filter ? self.filter.intent : null;
      var eventIntent = $event.value;
      if (eventIntent === intent) return;
      self.filter = { intent: eventIntent };
      saveState();
      self.refresh();
    }

    /**
     * Redirects to the Work Orders/Service Requests
     * page with appropriate filters.
     */
    function viewAll(overdue) {
      var destinationState = null;
      var params = null;
      switch (self.selectedSection) {
        case MY_WORK_ORDERS:
          destinationState = "app.workOrders";
          params = Object.assign({}, self.filter, {
            status: WORK_ORDER_STATUS_OPEN,
          });
          params.assignees = "$in," + self.userId;
          break;
        case OPEN_WORK_ORDERS:
          destinationState = "app.workOrders";
          params = Object.assign({}, self.filter, {
            status: WORK_ORDER_STATUS_OPEN,
          });
          break;
        case NEW_SERVICE_REQUESTS:
          destinationState = "app.requests";
          params = {
            status: SERVICE_REQUEST_STATUS_NEW,
          };
          break;
        case OPEN_SERVICE_REQUESTS:
          destinationState = "app.requests";
          params = {
            status: SERVICE_REQUEST_STATUS_OPEN,
          };
          break;
      }
      if (overdue) {
        params = angular.extend({}, params, {
          due_date: "$lte," + moment(today.valueOf()).subtract(1, "days").utc(),
        });
      }
      Router.go(destinationState, params);
    }

    /* API METHODS */

    /****************************
     *      MY WORK ORDERS      *
     ****************************/

    function getMyWorkOrders() {
      var params = angular.extend(
        {},
        { limit: 10 },
        { status: WORK_ORDER_STATUS_OPEN },
        self.filter
      );
      params.assignees = "$in," + self.userId;
      // TODO: Remove this and allow 'inspection as filter type'
      if (params.intent && params.intent === "preventive")
        params.intent = "$in,preventive,inspection";

      var promise;

      if (IS_ORG_VIEW) {
        promise = WorkOrderService.getByOrganization(QUERY_ID, params);
      } else {
        promise = WorkOrderService.getByBuildingId(QUERY_ID, params);
      }

      return promise
        .then(function (workOrders) {
          // Iterate over work orders and buildings
          self.workOrders = workOrders.map(setBuildings);
          populateBuilding();
          return;

          // Set Building of workOrder to be the full object
          function setBuildings(workOrder) {
            for (var building in self.buildings) {
              if (building._id !== workOrder.building) break;
              workOrder.building = building;
              break;
            }
            return workOrder;
          }
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.workOrders = [];
          return;
        });
    }

    function getMyWorkOrdersCount() {
      var params = angular.extend(
        {},
        { count: true },
        { status: WORK_ORDER_STATUS_OPEN },
        self.filter
      );
      params.assignees = "$in," + self.userId;
      // TODO: Remove this and allow 'inspection as filter type'
      if (params.intent && params.intent === "preventive")
        params.intent = "$in,preventive,inspection";

      var promise;

      if (IS_ORG_VIEW) {
        promise = WorkOrderService.getByOrganization(QUERY_ID, params);
      } else {
        promise = WorkOrderService.getByBuildingId(QUERY_ID, params);
      }

      return promise
        .then(function (response) {
          self.counts[MY_WORK_ORDERS] = response.count;
          return;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.counts[MY_WORK_ORDERS] = 0;
          return;
        });
    }

    function getMyOverdueWorkOrdersCount() {
      var params = angular.extend(
        {},
        { count: true },
        { due_date: "$lt," + today.valueOf() },
        { status: WORK_ORDER_STATUS_OPEN },
        self.filter
      );
      var promise;

      params.assignees = "$in," + self.userId;
      // TODO: Remove this and allow 'inspection as filter type'
      if (params.intent && params.intent === "preventive")
        params.intent = "$in,preventive,inspection";

      if (IS_ORG_VIEW) {
        promise = WorkOrderService.getByOrganization(QUERY_ID, params);
      } else {
        promise = WorkOrderService.getByBuildingId(QUERY_ID, params);
      }

      return promise
        .then(function (response) {
          self.myOverdueWorkOrderCount = response.count;
          return;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.myOverdueWorkOrderCount = 0;
          return;
        });
    }

    /****************************
     *    OPEN WORK ORDERS      *
     ****************************/

    function getOpenWorkOrders() {
      var query = { status: WORK_ORDER_STATUS_OPEN };
      var params = angular.extend({}, { limit: 10 }, query, self.filter);

      // TODO: Remove this and allow 'inspection as filter type'
      if (params.intent && params.intent === "preventive")
        params.intent = "$in,preventive,inspection";

      return getWorkOrders(QUERY_ID, params)
        .then(setWorkOrders)
        .catch(onWorkOrderError);
    }

    function getOpenWorkOrdersCount() {
      var query = { status: WORK_ORDER_STATUS_OPEN };
      var params = angular.extend({}, { count: true }, query, self.filter);

      // TODO: Remove this and allow 'inspection as filter type'
      if (params.intent && params.intent === "preventive")
        params.intent = "$in,preventive,inspection";

      return getWorkOrders(QUERY_ID, params)
        .then(function (results) {
          self.counts[OPEN_WORK_ORDERS] = results.count;
          return;
        })
        .catch(onWorkOrderError);
    }

    function getTotalOverdueWorkOrdersCount() {
      var query = { status: WORK_ORDER_STATUS_OPEN };
      var params = angular.extend(
        {},
        { count: true },
        { due_date: "$lt," + today.valueOf() },
        query,
        self.filter
      );
      // TODO: Remove this and allow 'inspection as filter type'
      if (params.intent && params.intent === "preventive")
        params.intent = "$in,preventive,inspection";

      return getWorkOrders(QUERY_ID, params)
        .then(function (results) {
          self.totalOverdueWorkOrderCount = results.count;
          return;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.totalOverdueWorkOrderCount = 0;
          return;
        });
    }

    /****************************
     *   NEW SERVICE REQUESTS   *
     ****************************/

    function getNewServiceRequests() {
      var query = { status: SERVICE_REQUEST_STATUS_NEW };
      var params = angular.extend({}, { limit: 10 }, query);

      return getRequests(QUERY_ID, params)
        .then(function (serviceRequests) {
          self.serviceRequests = serviceRequests;
          for (var i = 0; i < self.serviceRequests.length; ++i) {
            var request = self.serviceRequests[i];
            request.building = buildingMap[request.building];
            getRequestPermissions(request);
          }
          return;
        })
        .catch(onServiceRequestError);
    }

    function getNewServiceRequestsCount() {
      var query = { status: SERVICE_REQUEST_STATUS_NEW };
      var params = angular.extend({}, { count: true }, query);

      return getRequestsCount(QUERY_ID, params)
        .then(function (results) {
          self.counts[NEW_SERVICE_REQUESTS] = results.count;
          return;
        })
        .catch(onServiceRequestError);
    }

    /****************************
     *   OPEN SERVICE REQUESTS  *
     ****************************/

    function getOpenServiceRequests() {
      var query = { status: SERVICE_REQUEST_STATUS_OPEN };
      var params = angular.extend({}, { limit: 10 }, query);

      getRequests(QUERY_ID, params)
        .then(function (serviceRequests) {
          self.serviceRequests = serviceRequests;
          for (var i = 0; i < self.serviceRequests.length; ++i) {
            var request = self.serviceRequests[i];
            request.building = buildingMap[request.building];
            getRequestPermissions(request);
          }
          return;
        })
        .catch(onServiceRequestError);
    }

    function getOpenServiceRequestsCount() {
      var query = { status: SERVICE_REQUEST_STATUS_OPEN };
      var params = angular.extend({}, { count: true }, query);

      getRequestsCount(QUERY_ID, params)
        .then(function (results) {
          self.counts[OPEN_SERVICE_REQUESTS] = results.count;
          return;
        })
        .catch(onServiceRequestError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Sets the active tab in the
     * Work Orders & Requests table and
     * updates the table data.
     *
     * @param {Number} section
     */
    function selectSection(section) {
      self.selectedSection = section;
      self.showWorkOrderList = section < 2;
      self.showRequestList = !self.showWorkOrderList;
      self.showWONameColumn = section === OPEN_SERVICE_REQUESTS;
      self.refresh();
    }

    function setWorkOrders(workOrders) {
      self.workOrders = workOrders;
      populateBuilding();
      return;
    }

    function onServiceRequestError(err) {
      ToastService.showError(err);
      self.serviceRequests = [];
      return;
    }

    function onWorkOrderError(err) {
      ToastService.showError(err);
      self.workOrders = [];
      return;
    }

    function mapBuildings() {
      for (var i = 0; i < self.buildings.length; ++i) {
        buildingMap[self.buildings[i]._id] = self.buildings[i];
      }
    }

    function populateBuilding() {
      for (var i = 0; i < self.workOrders.length; ++i) {
        var workOrder = self.workOrders[i];
        workOrder.building = buildingMap[workOrder.building];
      }
    }

    function toggleSpacer() {
      return $q(function (resolve) {
        if (!spacer) {
          return resolve();
        }
        // Temporarily disable transition
        var spacerTransition = spacer.style.transition;
        spacer.style.transition = "";

        raf(function () {
          // Reinstate the transition
          spacer.style.transition = spacerTransition;
          raf(function () {
            if (
              self.selectedSection === MY_WORK_ORDERS ||
              self.selectedSection === OPEN_WORK_ORDERS
            ) {
              // If one of the work order tabs are selected, show the spacer
              spacer.style.removeProperty("height");
            } else {
              // Else hide the spacer
              spacer.style.height = "2px";
            }
            return $timeout(resolve, 500);
          });
        });
      });
    }

    /**
     * Handle work order creation event
     *
     * @param {Event}       $event      Angular event
     * @param {WorkOrder[]} workOrders  List of new work orders
     */
    function onWorkOrderCreate($event, workOrders) {
      if (workOrders) {
        getMyWorkOrdersCount();
        getOpenWorkOrdersCount();
        self.refresh();
      }
    }

    /**
     * Handle work order creation event
     *
     * @param {Event}     $event    Angular event
     * @param {Request[]} requests  List of new service requests
     */
    function onRequestCreate($event, requests) {
      if (requests) {
        getNewServiceRequestsCount();
        getOpenServiceRequestsCount();
        self.refresh();
      }
    }

    /**
     * Gets the permissions for a given service request
     *
     * @param {Object} request
     */
    function getRequestPermissions(request) {
      var permissions = UserService.getPermissions();
      request.hasPermission = {
        canCreate: permissions.task.create,
        canDeny: permissions.request.deny,
      };
    }

    function findSection(value) {
      var workOrderOptions = self.toggleOptions.workOrder;
      var requestOptions = self.toggleOptions.request;

      var section = findSectionByValue(workOrderOptions, value);
      if (section === null) {
        section = findSectionByValue(requestOptions, value);
      }

      return section;

      function findSectionByValue(options, value) {
        for (var i = 0; i < options.length; ++i) {
          var opt = options[i];
          if (opt.value === value) {
            return opt.section;
          }
        }
        return null;
      }
    }

    function findToggle(section) {
      var workOrderOptions = self.toggleOptions.workOrder;
      var requestOptions = self.toggleOptions.request;

      var value = findValueBySection(workOrderOptions, section);
      if (value === null) {
        value = findValueBySection(requestOptions, section);
      }

      return value;

      function findValueBySection(options, section) {
        for (var i = 0; i < options.length; ++i) {
          var opt = options[i];
          if (opt.section === section) {
            return opt.value;
          }
        }
        return null;
      }
    }
  }
})();
