(function () {
  angular.module("akitabox.ui.dialogs.document.tag", [
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.services.env",
    "akitabox.core.services.tag",
    "akitabox.core.services.tagCategory",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "angular.filter",
  ]);
})();
