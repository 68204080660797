import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";

import { DesktopRouter } from "./react/legacy/DesktopRouter";

/**
 * Installing MUI X license key (version `Pro`)
 * @see https://mui.com/x/introduction/licensing/#how-to-install-the-key
 */
LicenseInfo.setLicenseKey(
  "17b6e5511912ed492840961f6a9ff31bTz0xMDIyMzksRT0xNzY1MDYxNzQyMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

// initialize react
const root = createRoot(getElementByIdOrThrow("react-app"));
root.render(<DesktopRouter />);

function getElementByIdOrThrow(id: string) {
  const result = document.getElementById(id);
  if (!result) {
    throw new Error(`Element #${id} not found`);
  }
  return result;
}
