import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import {
  GridColumnMenuColumnsItem,
  GridColumnMenuContainer,
  GridColumnMenuFilterItem,
  GridColumnMenuProps,
  GridColumnMenuSortItem,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
} from "@mui/x-data-grid";
import {
  DataGridPro,
  getGridSingleSelectOperators,
  getGridDateOperators,
  GridColDef,
  GridSortModel,
  useGridApiContext,
  GridColumnMenuPinningItem,
} from "@mui/x-data-grid-pro";
import { stylesheet } from "../stylesheet";

import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  Snackbar,
  Typography,
} from "@mui/material";

import { useRouteLoaderData } from "react-router";

import { OrganizationResponse, PinTypeResponse } from "@akitabox/api-client";
import { api } from "../api";
import { useInfiniteCall } from "../hooks/useInfiniteCall";

import { FilterTerms } from "../asset-list/types";
import ListPagination from "../components/ListPagination";
import { getTypeaheadSelectOperator } from "../components/SearchableSingleSelectFilter";
import { DateRangeHeaderFilter } from "../components/DateRangeHeaderFilter";
import ActiveViewToolbar from "../work-orders/ActiveViewToolbar";
import ExportDrawer from "./ExportDrawer";
import GridToolbar from "../work-orders/GridToolbar";
import { makeUseServiceCall } from "../hooks/useServiceCall";

import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { CalendarToday } from "@mui/icons-material";

const ss = stylesheet({
  dataGridWrapper: {
    ".MuiDataGrid-virtualScroller": {
      overflowY: "visible!important" as any,
    },
    background: "#ffffff",
    paddingTop: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "scroll",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100% - 64px)", // Adjust based on the height of the toolbar
  },
  dataGridContainer: {
    flex: 1,
    overflow: "auto",
  },
  sideDrawerContainer: {
    overflow: "auto",
    position: "relative",
    zIndex: 0,
  },
  relativeDrawer: {
    position: "relative",
  },
});

interface InspectionChecklistDataGridFilters {
  number?: string;
  name?: string;
  priority?: string;
  status?: string;
  checklist_template?: string;
  building?: string;
  [key: string]: any; // Add this line to allow dynamic keys
}

export interface InspectionChecklistListProps {
  filters?: any;
  onFilterChange?: (filters: InspectionChecklistDataGridFilters) => void;
}

const singleSelectIsOperator = getGridSingleSelectOperators().filter(
  (operator) => operator.value === "is"
);
const singleSelectIsAnyOfOperator = getGridSingleSelectOperators().filter(
  (operator) => operator.value === "isAnyOf"
);

const dateSelectOperator = getGridDateOperators().filter(
  (operator) => operator.value === "before" || operator.value === "after"
);
const betweenDateRangeFilterOperator: GridFilterOperator = {
  label: "Between",
  value: "between",
  headerLabel: "Between",
  getApplyFilterFn: (filterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }
    const [startRaw, endRaw] = filterItem.value.split(",");
    const start = moment(startRaw || null);
    const end = moment(endRaw || null);
    if (!start.isValid() || !end.isValid()) {
      return null;
    }
    return (rawValue) => {
      const value = moment(rawValue);
      return value.isValid() && value.isAfter(start) && value.isBefore(end);
    };
  },
  InputComponent: DateRangeHeaderFilter,
  InputComponentProps: { type: "date" },
};
const combinedDateFilterOperators: GridFilterOperator[] = [
  betweenDateRangeFilterOperator,
  ...dateSelectOperator,
];

const CHECKLIST_STATUS_OPTIONS = [
  { value: "FAIL", label: "Failed" },
  { value: "FAIL_LINKED_EXTERNAL_WO", label: "Failed - Linked External WO" },
  { value: "FAIL_LINKED_CLOSED_WO", label: "Failed - Linked Closed WO" },
  { value: "FAIL_LINKED_OPEN_WO", label: "Failed - Linked Open WO" },
  { value: "IN_PROGRESS", label: "Incomplete" },
  { value: "PASS", label: "Passed" },
  { value: "SKIP", label: "Skipped" },
];

interface GridCellExpandProps {
  value: string;
  width: number;
}

function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const calculateDateRange = (rangeType: string) => {
  let startDate, endDate;
  switch (rangeType) {
    case "this_month":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month").utc().endOf("day");
      break;
    case "last_week":
      startDate = moment().subtract(1, "week").startOf("week");
      endDate = moment().subtract(1, "week").endOf("week").utc().endOf("day");
      break;
    case "last_month":
      startDate = moment().subtract(1, "months").startOf("month");
      endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .utc()
        .endOf("day");
      break;
    case "last_3_months":
      startDate = moment().subtract(3, "months").startOf("month");
      endDate = moment().endOf("month").utc().endOf("day");
      break;
    case "last_6_months":
      startDate = moment().subtract(6, "months").startOf("month");
      endDate = moment().endOf("month").utc().endOf("day");
      break;
    case "last_12_months":
      startDate = moment().subtract(12, "months").startOf("month");
      endDate = moment().endOf("month").utc().endOf("day");
      break;
    default:
      startDate = moment().startOf("month");
      endDate = moment().endOf("month").utc().endOf("day");
  }
  return { startDate, endDate };
};

const applyDateRangeFilter = (
  rangeType: string,
  colDef: any,
  filterModel: any,
  upsertFilterItem: any,
  hideMenu: any
) => {
  const { startDate, endDate } = calculateDateRange(rangeType);
  // Find existing filter item
  const existingFilter = filterModel.items.find(
    (item: GridFilterItem) => item.field === colDef.field
  );
  if (existingFilter) {
    upsertFilterItem({
      ...existingFilter,
      value: `${startDate.format("YYYY-MM-DD")},${endDate.format(
        "YYYY-MM-DD"
      )}`,
    });
  } else {
    upsertFilterItem({
      field: colDef.field,
      operator: "between",
      id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      value: `${startDate.format("YYYY-MM-DD")},${endDate.format(
        "YYYY-MM-DD"
      )}`,
    });
  }
  hideMenu();
};

const DateRangeSelectors = (
  props: GridColumnMenuProps & { filterModel: any }
) => {
  const apiRef = useGridApiContext();

  const { open, colDef, hideMenu, filterModel } = props;
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu,
  };
  const menuItems = [
    <Divider key="divider" />,
    <MenuItem
      key="this_month"
      onClick={() =>
        applyDateRangeFilter(
          "this_month",
          colDef,
          filterModel,
          apiRef.current.upsertFilterItem,
          hideMenu
        )
      }
    >
      <ListItemIcon>
        <CalendarToday fontSize="small" />
      </ListItemIcon>
      <ListItemText>This Month</ListItemText>
    </MenuItem>,
    <MenuItem
      key="last_month"
      onClick={() =>
        applyDateRangeFilter(
          "last_month",
          colDef,
          filterModel,
          apiRef.current.upsertFilterItem,
          hideMenu
        )
      }
    >
      <ListItemIcon>
        <CalendarToday fontSize="small" />
      </ListItemIcon>
      <ListItemText>Last Month</ListItemText>
    </MenuItem>,
    <MenuItem
      key="last_3_months"
      onClick={() =>
        applyDateRangeFilter(
          "last_3_months",
          colDef,
          filterModel,
          apiRef.current.upsertFilterItem,
          hideMenu
        )
      }
    >
      <ListItemIcon>
        <CalendarToday fontSize="small" />
      </ListItemIcon>
      <ListItemText>Last 3 Months</ListItemText>
    </MenuItem>,
    <MenuItem
      key="last_6_months"
      onClick={() =>
        applyDateRangeFilter(
          "last_6_months",
          colDef,
          filterModel,
          apiRef.current.upsertFilterItem,
          hideMenu
        )
      }
    >
      <ListItemIcon>
        <CalendarToday fontSize="small" />
      </ListItemIcon>
      <ListItemText>Last 6 Months</ListItemText>
    </MenuItem>,
    <MenuItem
      key="last_12_months"
      onClick={() =>
        applyDateRangeFilter(
          "last_12_months",
          colDef,
          filterModel,
          apiRef.current.upsertFilterItem,
          hideMenu
        )
      }
    >
      <ListItemIcon>
        <CalendarToday fontSize="small" />
      </ListItemIcon>
      <ListItemText>Last 12 Months</ListItemText>
    </MenuItem>,
  ];
  return (
    <GridColumnMenuContainer hideMenu={hideMenu} open={open} colDef={colDef}>
      <GridColumnMenuSortItem {...itemProps} />
      <GridColumnMenuPinningItem {...itemProps} />
      <GridColumnMenuFilterItem {...itemProps} />
      {colDef.field === "inspection.due_date" && menuItems}
      <Divider />
      <GridColumnMenuColumnsItem {...itemProps} />
    </GridColumnMenuContainer>
  );
};

const GridCellExpand = function GridCellExpand(props: GridCellExpandProps) {
  const { width, value } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef<HTMLDivElement | null>(null);
  const cellValue = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    if (cellValue.current) {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    }
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      if (nativeEvent.key === "Escape") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper?.current?.offsetHeight || 0 - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

function getStatus(status: string): { icon: string; color: string } {
  switch (status) {
    case "PASS":
      return {
        icon: "check",
        color: "green",
      };
    case "IN_PROGRESS":
      return {
        icon: "donut_large",
        color: "grey",
      };
    default:
      return {
        icon: "error_outline",
        color: "red",
      };
  }
}

const calculateDefaultDateRange = () => {
  const startDate = moment().subtract(12, "months").startOf("month");
  const endDate = moment().add(3, "months").endOf("month");
  return { startDate, endDate };
};

export const InspectionChecklistList: FunctionComponent<
  InspectionChecklistListProps
> = () => {
  const { organization } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
  };

  // const [exportColumns, setExportColumns] = useState<string[]>([]);
  const allowedPageSizes = [25, 50, 100];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: allowedPageSizes[0],
  });
  const [filters, setFilters] = useState<InspectionChecklistDataGridFilters>();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "inspection.due_date", sort: "desc" },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  useEffect(() => {
    const { startDate, endDate } = calculateDefaultDateRange();
    setFilterModel({
      items: [
        {
          field: "inspection.due_date",
          operator: "between",
          value: `${startDate.format("YYYY-MM-DD")},${endDate.format(
            "YYYY-MM-DD"
          )}`,
        },
      ],
    });
  }, []);
  const {
    data: checklistResponse,
    isLoading,
    isValidating,
    mutate,
  } = useInfiniteCall(api.web.getChecklists, () => [
    {
      organizationId: organization._id,
      status: filters?.status,
      complianceCode: filters?.compliance_code,
      inspectionProgram: filters && filters["inspection_program"],
      inspectionProgramName: filters && filters["inspection_program.name"],
      checklistTemplate: filters && filters["checklist_template"],
      inspectionStartDate: filters && filters["inspection.start_date"],
      inspectionDueDate: filters && filters["inspection.due_date"],
      completedDate: filters?.completed_date,
      completedUserDisplayName:
        filters && filters["completed_user.display_name"],
      building: filters && filters["building"],
      pinTypeName: filters && filters["pinType"],
      level: filters && filters["level"],
      roomNumber: filters && filters["room.number"],
      assetName: filters && filters["asset.name"],
      sortBy: sortModel.map((item) => `${item.field},${item.sort}`).join(","),
      skip: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    },
  ]);
  const checklists = useMemo(() => {
    if (!checklistResponse) {
      return [];
    }
    const flattenedItems = checklistResponse.flatMap((item) => item.data);

    return flattenedItems;
  }, [checklistResponse]);
  const { data: checklistCountReponse, isLoading: isCounting } =
    makeUseServiceCall(api.web.getChecklistsCount)({
      organizationId: organization._id,
      status: filters?.status,
      complianceCode: filters?.compliance_code,
      inspectionProgram: filters && filters["inspection_program"],
      inspectionProgramName: filters && filters["inspection_program.name"],
      checklistTemplate: filters && filters["checklist_template"],
      inspectionStartDate: filters && filters["inspection.start_date"],
      inspectionDueDate: filters && filters["inspection.due_date"],
      completedDate: filters?.completed_date,
      completedUserDisplayName:
        filters && filters["completed_user.display_name"],
      building: filters && filters["building"],
      pinTypeName: filters && filters["pinType"],
      level: filters && filters["level"],
      roomNumber: filters && filters["room.number"],
      assetName: filters && filters["asset.name"],
    });
  const checklistCount = useMemo(() => {
    if (!checklistCountReponse) return 0;
    return checklistCountReponse.data.count;
  }, [checklistCountReponse]);

  const { data: buildingResponse } = makeUseServiceCall(
    api.buildings.getByOrganization
  )({
    organizationId: organization._id,
    skip: 0,
    limit: 1000,
  });
  const buildings = useMemo(() => {
    return buildingResponse ? buildingResponse.data : [];
  }, [buildingResponse]);
  const buildingOptions = useMemo(() => {
    return buildings
      .map((building: any) => ({
        label: building.name,
        value: building._id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [buildings]);

  const { data: checklistTemplatesResponse } = makeUseServiceCall(
    api.checklistTemplates.get
  )({
    organizationId: organization._id,
    limit: 1000,
  });
  const checklistTemplates = useMemo(() => {
    if (!checklistTemplatesResponse) {
      return [];
    }
    return checklistTemplatesResponse.data;
  }, [checklistTemplatesResponse]);
  const checklistTemplatesOptions = useMemo(() => {
    return checklistTemplates
      .map((checklistTemplate: any) => ({
        value: checklistTemplate._id,
        label: checklistTemplate.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [checklistTemplates]);

  const { data: inspectionProgramQueryResponse } = makeUseServiceCall(
    api.inspectionPrograms.get
  )({
    organizationId: organization._id,
    number: filters?.number,
    name: filters?.name,
    priority: filters?.priority,
    status: filters?.status,
    checklistTemplate: filters?.checklist_template,
    assignees: filters?.assignees,
    building: filters?.building,
    limit: 1000,
  });
  const inspectionPrograms = useMemo(() => {
    if (!inspectionProgramQueryResponse) {
      return [];
    }
    return inspectionProgramQueryResponse.data || [];
  }, [inspectionProgramQueryResponse]);

  const { data: pinTypesCountResponse } = makeUseServiceCall(
    api.pinTypes.count
  )({
    organizationId: organization._id,
  });
  const pinTypesCount = useMemo(() => {
    return pinTypesCountResponse ? pinTypesCountResponse.data.count : 0;
  }, [pinTypesCountResponse]);

  const [pinTypes, setPinTypes] = useState<PinTypeResponse[]>([]);
  useEffect(() => {
    const fetchAllPinTypes = async () => {
      if (pinTypesCountResponse) {
        let allPinTypes: PinTypeResponse[] = [];
        while (allPinTypes.length < pinTypesCount) {
          const { data: pinTypesResponse } =
            await api.pinTypes.getByOrganization({
              organizationId: organization._id,
              skip: allPinTypes.length,
              limit: 1000,
            });
          allPinTypes = [...allPinTypes, ...pinTypesResponse];
        }
        setPinTypes(allPinTypes);
      }
    };
    fetchAllPinTypes();
  }, [pinTypesCountResponse, organization._id, pinTypesCount]);
  const pinTypeOptions = useMemo(() => {
    return pinTypes.reduce(
      (acc: { label: string; value: string }[], pinType) => {
        const pinTypeName = `${pinType.name}`;
        // Check if the pinType.name already exists in the accumulator
        if (!acc.find((option) => option.value === pinTypeName)) {
          // If it doesn't exist, add it to the accumulator
          acc.push({
            label: pinTypeName,
            value: pinTypeName,
          });
        }

        return acc;
      },
      []
    );
  }, [pinTypes]);

  const { data: orgLevelsResponse } = makeUseServiceCall(
    api.levels.byOrganization
  )({
    organizationId: organization._id,
    skip: 0,
    limit: 1000,
  });
  const orgLevels = useMemo(() => {
    return orgLevelsResponse ? orgLevelsResponse.data : [];
  }, [orgLevelsResponse]);
  const orgLevelsOptions = useMemo(() => {
    function getBuildingName(level: any) {
      return buildings.find((building) => building._id === level.building)
        ?.name;
    }
    const levelOptions = orgLevels
      .map((level) => ({
        label: level.name,
        value: level._id,
        building: level.building,
        group: getBuildingName(level),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    if (filterModel) {
      const items = filterModel.items;
      const buildingFilter = items.find(({ field }) => field === "building");
      if (buildingFilter && buildingFilter.value) {
        const buildingId = buildingFilter.value;
        if (buildingId) {
          return levelOptions.filter(
            (option) => option.building === buildingId
          );
        }
      }
    }
    return levelOptions;
  }, [orgLevels, buildings, filterModel]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<{
    [key: string]: boolean;
  }>({});
  const onColumnVisibilityChange = (newModel: { [key: string]: boolean }) => {
    setColumnVisibilityModel((prevModel) => {
      const hasChanges = Object.keys(newModel).some(
        (key) => newModel[key] !== prevModel[key]
      );
      return {
        ...(hasChanges ? newModel : prevModel),
      };
    });
    const columnVisibilityHasChanges = Object.keys(columnVisibilityModel).some(
      (key) => !columnVisibilityModel[key]
    );
    setIsFilterChanged(columnVisibilityHasChanges);
  };

  const baseColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "inspection_program",
        headerName: "IP Number",
        width: 150,
        filterable: true,
        sortable: false,
        type: "singleSelect",
        filterOperators: singleSelectIsOperator,
        valueOptions: inspectionPrograms.map((option) => ({
          value: option._id,
          label: `IP-${option.number}`,
        })),
        renderCell: (params) => {
          const inspectionProgramNumber =
            params.row?.inspection_program?.display_number;
          return (
            <a
              href={`/inspection_programs/${params.row?.inspection_program?._id}/overview`}
            >
              {inspectionProgramNumber}
            </a>
          );
        },
      },
      {
        field: "inspection_program.name",
        headerName: "Inspection Program Name",
        width: 275,
        filterable: true,
        sortable: false,
        renderCell: (params) => {
          const inspectionProgramName = params.row?.inspection?.name;
          return (
            <a
              href={`/inspection_programs/${params.row?.inspection?.inspection_program}/overview`}
            >
              {inspectionProgramName}
            </a>
          );
        },
      },
      {
        field: "checklist_template",
        headerName: "Checklist Name",
        width: 300,
        filterable: true,
        sortable: false,
        type: "singleSelect",
        filterOperators: [
          ...singleSelectIsAnyOfOperator,
          ...getTypeaheadSelectOperator(checklistTemplatesOptions),
        ],
        valueOptions: checklistTemplatesOptions,
        renderCell: (params) => {
          return params.row?.checklist_template?.name || "";
        },
      },
      {
        field: "compliance_code",
        headerName: "Compliance Code",
        width: 150,
        filterable: true,
        sortable: false,
      },
      {
        field: "inspection.start_date",
        headerName: "Start Date",
        width: 200,
        filterable: true,
        sortable: false,
        type: "date",
        filterOperators: dateSelectOperator,
        renderCell: (params) => {
          if (!params.row?.inspection?.start_date) {
            return "";
          }
          return new Date(
            params.row?.inspection?.start_date
          ).toLocaleDateString();
        },
      },
      {
        field: "inspection.due_date",
        headerName: "Due Date",
        width: 200,
        filterable: true,
        type: "date",
        renderCell: (params) => {
          if (!params.row?.inspection?.due_date) {
            return "";
          }
          return new Date(
            params.row?.inspection?.due_date
          ).toLocaleDateString();
        },
        filterOperators: combinedDateFilterOperators,
        defaultOperator: betweenDateRangeFilterOperator,
      },
      {
        field: "status",
        headerName: "Checklist Status",
        width: 150,
        filterable: true,
        sortable: false,
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators(),
        valueOptions: CHECKLIST_STATUS_OPTIONS,
        renderCell: (params) => {
          const checklist_status = params.row?.status_display || "";
          const checklistId = params.row?._id;
          const statusIcon = getStatus(params?.row?.status);
          const iconName = statusIcon.icon;
          const iconColor = statusIcon.color;
          return (
            <a
              href={`/inspection_programs/${params.row?.inspection?.inspection_program}/checklists/${checklistId}`}
            >
              <span>{checklist_status}</span>
              <i css={{ color: iconColor }} className={`material-icons md-18`}>
                {iconName}
              </i>
            </a>
          );
        },
      },
      {
        field: "skip_reason",
        headerName: "Skip Reason",
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const skipReasons = params.row?.skip_reasons || [];
          const skipReason = skipReasons.length > 0 ? skipReasons[0].text : "";
          return (
            <GridCellExpand
              value={skipReason || ""}
              width={params.colDef.computedWidth}
            />
          );
        },
      },
      {
        field: "completed_date",
        headerName: "Completed Date",
        width: 150,
        filterable: true,
        type: "date",
        valueGetter: (row: any) => {
          return row?.completed_date ? new Date(row?.completed_date) : null;
        },
        renderCell: (params) => {
          if (!params.row?.completed_date) {
            return "";
          }
          const completedDate = new Date(
            params.row?.completed_date
          ).toLocaleDateString();
          const urlFilters: any = {
            iterations: [params.row?.inspection?._id],
            buildings: [params.row?.building?._id],
          };
          const urlParams = encodeURIComponent(JSON.stringify(urlFilters));
          return (
            <a
              href={`/inspection_programs/${params.row?.inspection?.inspection_program}/details?filters=${urlParams}`}
            >
              {completedDate}
            </a>
          );
        },
        filterOperators: combinedDateFilterOperators,
      },
      {
        field: "completed_user.display_name",
        headerName: "Completed By",
        width: 150,
        filterable: true,
        sortable: false,
        renderCell: (params) => {
          return params.row?.completed_user?.display_name || "";
        },
      },
      {
        field: "building",
        headerName: "Building Name",
        width: 300,
        type: "singleSelect",
        filterable: true,
        sortable: false,
        filterOperators: [
          ...singleSelectIsAnyOfOperator,
          ...getTypeaheadSelectOperator(checklistTemplatesOptions),
        ],
        valueOptions: buildingOptions,
        renderCell: (params) => {
          return params.row?.building?.name || "";
        },
      },
      {
        field: "pin_type.name",
        headerName: "Category Name",
        width: 300,
        type: "singleSelect",
        filterable: true,
        sortable: false,
        filterOperators: [
          ...singleSelectIsAnyOfOperator,
          ...getTypeaheadSelectOperator(checklistTemplatesOptions),
        ],
        valueOptions: pinTypeOptions,
        renderCell: (params) => {
          return params.row?.pin_type?.name || "";
        },
      },
      {
        field: "level",
        headerName: "Floor Name",
        width: 150,
        type: "singleSelect",
        filterable: true,
        sortable: false,
        filterOperators: [
          ...singleSelectIsAnyOfOperator,
          ...getTypeaheadSelectOperator(orgLevelsOptions),
        ],
        valueOptions: orgLevelsOptions,
        renderCell: (params) => {
          return params.row?.level?.name || "";
        },
      },
      {
        field: "room.number",
        headerName: "Room Number",
        width: 150,
        filterable: true,
        sortable: false,
        renderCell: (params) => {
          return params.row?.room?.number || "";
        },
      },
      {
        field: "asset.name",
        headerName: "Asset Name",
        width: 150,
        filterable: true,
        sortable: false,
        renderCell: (params) => {
          return params.row?.asset?.name || "";
        },
      },
    ],
    [
      checklistTemplatesOptions,
      buildingOptions,
      pinTypeOptions,
      orgLevelsOptions,
      inspectionPrograms,
    ]
  );

  const columns = useMemo(() => {
    return baseColumns.map((column) => ({
      ...column,
      hide: !columnVisibilityModel[column.field],
    }));
  }, [baseColumns, columnVisibilityModel]);

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  useEffect(() => {
    // Detect change to filterModel
    const items = filterModel.items ? filterModel.items : [];
    const newFilters: InspectionChecklistDataGridFilters = {};

    const building = items.find(({ field }) => field === "building");
    if (building && building.value) {
      if (Array.isArray(building.value) && building.operator === "isAnyOf") {
        // For each value in the array of building.value, get the id of the pinType
        // and join them together with a comma
        const ids = building.value?.join(",");
        newFilters.building = `$in,${ids}`;
      } else {
        const ids = building.value;
        newFilters.building =
          building.operator === "not" ? `$nin,${ids}` : `$in,${ids}`;
      }
    }

    const status = items.find(({ field }) => field === "status");
    if (status && status.value) {
      if (Array.isArray(status.value) && status.operator === "isAnyOf") {
        // For each value in the array of status.value, get the id of the pinType
        // and join them together with a comma
        const ids = status.value?.join(",");
        newFilters.status = `$in,${ids}`;
      } else {
        const ids = status.value;
        newFilters.status =
          status.operator === "not" ? `$nin,${ids}` : `$in,${ids}`;
      }
    }

    const start_date = items.find(
      ({ field }) => field === "inspection.start_date"
    );
    if (start_date && start_date.value) {
      let operator = "";
      if (start_date.operator === "before") {
        operator = `$lt,`;
      } else if (start_date.operator === "onOrBefore") {
        operator = `$lte,`;
      } else if (start_date.operator === "after") {
        operator = `$gt,`;
      } else if (start_date.operator === "onOrAfter") {
        operator = `$gte,`;
      }
      if (typeof start_date.value === "string") {
        start_date.value = new Date(start_date.value);
      }
      newFilters["inspection.start_date"] =
        operator + start_date.value.getTime();
    }

    const due_date = items.find(({ field }) => field === "inspection.due_date");
    if (due_date && due_date.value) {
      let operator = "";
      if (
        due_date.operator === "between" &&
        typeof due_date.value === "string"
      ) {
        const [start, end] = due_date.value.split(",");
        newFilters["inspection.due_date"] = `$gt,${new Date(
          start
        ).getTime()},$lt,${moment(end)
          .endOf("day")
          .utc()
          .add(1, "days")
          .startOf("day")
          .valueOf()}`;
      } else {
        if (due_date.operator === "before") {
          operator = `$lt,`;
        } else if (due_date.operator === "onOrBefore") {
          operator = `$lte,`;
        } else if (due_date.operator === "after") {
          operator = `$gt,`;
        } else if (due_date.operator === "onOrAfter") {
          operator = `$gte,`;
        }
        if (typeof due_date.value === "string") {
          due_date.value = new Date(due_date.value);
        }
        newFilters["inspection.due_date"] = operator + due_date.value.getTime();
      }
    }

    const completed_date = items.find(
      ({ field }) => field === "completed_date"
    );
    if (completed_date && completed_date.value) {
      let operator = "";
      if (completed_date.operator === "before") {
        operator = `$lt,`;
      } else if (completed_date.operator === "onOrBefore") {
        operator = `$lte,`;
      } else if (completed_date.operator === "after") {
        operator = `$gt,`;
      } else if (completed_date.operator === "onOrAfter") {
        operator = `$gte,`;
      }
      if (typeof completed_date.value === "string") {
        completed_date.value = new Date(completed_date.value);
      }
      newFilters["completed_date"] = operator + completed_date.value.getTime();
    }

    const pinType = items.find(({ field }) => field === "pin_type.name");
    if (pinType && pinType.value) {
      if (Array.isArray(pinType.value) && pinType.operator === "isAnyOf") {
        // For each value in the array of pinType.value, get the id of the pinType
        // and join them together with a comma
        const ids = pinType.value?.join(",");
        newFilters.pinType = `$in,${ids}`;
      } else {
        const ids = pinType.value;
        newFilters.pinType =
          pinType.operator === "not" ? `$nin,${ids}` : `$in,${ids}`;
      }
    }

    const checklistTemplate = items.find(
      ({ field }) => field === "checklist_template"
    );
    if (checklistTemplate && checklistTemplate.value) {
      if (
        Array.isArray(checklistTemplate.value) &&
        checklistTemplate.operator === "isAnyOf"
      ) {
        // For each value in the array of checklistTemplate.value, get the id of the checklistTemplate
        // and join them together with a comma
        const ids = checklistTemplate.value?.join(",");
        newFilters.checklist_template = `$in,${ids}`;
      } else {
        const ids = checklistTemplate.value;
        newFilters.checklist_template =
          checklistTemplate.operator === "not" ? `$nin,${ids}` : `$in,${ids}`;
      }
    }

    const exclude = [
      "building",
      "status",
      "inspection.start_date",
      "inspection.due_date",
      "completed_date",
      "pinType",
      "checklist_template",
    ];
    baseColumns
      .filter((column) => column.filterable && !exclude.includes(column.field))
      .map((column) => {
        const filter = items.find(({ field }) => field === column.field);
        if (filter && filter.value) {
          newFilters[column.field] = filter.value;
        }
      });

    setFilters(newFilters);
  }, [baseColumns, filterModel]);

  useEffect(() => {
    const terms: FilterTerms[] = [];
    const filterHasChanges = filterModel?.items?.length > 0;
    setIsFilterChanged(filterHasChanges);

    filterModel?.items?.forEach(({ field, value, operator }) => {
      if (!value) {
        return;
      }
      let description = "";
      let valueDescription = value;
      if (field === "inspection_program") {
        description = `IP`;
        valueDescription = inspectionPrograms.find(
          (program) => program._id === value
        )?.number;
      } else if (field === "inspection_program.name") {
        description = `IP Name`;
      } else if (field === "checklist_template") {
        description = `Checklist`;
        if (Array.isArray(value)) {
          const checklistLabels = value.map((value) => {
            return checklistTemplates.find((template) => template._id === value)
              ?.name;
          });
          valueDescription = `${checklistLabels?.join(", ")}`;
        } else {
          valueDescription = checklistTemplates.find(
            (template) => template._id === value
          )?.name;
        }
      } else if (field === "compliance_code") {
        description = `Compliance Code`;
      } else if (field === "inspection.start_date" && value) {
        description = `Start Date`;
        valueDescription = new Date(value).toLocaleDateString();
      } else if (field === "inspection.due_date" && value) {
        description = `Due Date`;
        if (operator === "between" && value.includes(",")) {
          const [start, end] = value.split(",");
          valueDescription = `${moment(start).format("YYYY-MM-DD")} - ${moment(
            end
          ).format("YYYY-MM-DD")}`;
        } else {
          valueDescription = moment(value).format("YYYY-MM-DD");
        }
      } else if (field === "status") {
        description = `Status`;
        if (Array.isArray(value)) {
          const statusLabels = value.map((value) => {
            const statusLabel = CHECKLIST_STATUS_OPTIONS.find(
              (status) => status.value === value
            )?.label;
            return statusLabel ? statusLabel : "";
          });
          valueDescription = `${statusLabels?.join(", ")}`;
        } else {
          const statusLabel = CHECKLIST_STATUS_OPTIONS.find(
            (status) => status.value === value
          )?.label;
          valueDescription = `${statusLabel ? statusLabel : ""}`;
        }
      } else if (field === "completed_date") {
        description = `Completed Date`;
        valueDescription = new Date(value).toLocaleDateString();
      } else if (field === "completed_user.display_name") {
        description = `Completed By`;
      } else if (field === "building") {
        description = `Building`;
        if (Array.isArray(value)) {
          const buildingNames = value.map((value) => {
            const building = buildings.find(
              (building) => building._id === value
            );
            return building ? building.name : "";
          });
          valueDescription = `${buildingNames?.join(", ")}`;
        } else {
          const building = buildings.find((building) => building._id === value);
          valueDescription = `${building ? building.name : ""}`;
        }
      } else if (field === "pin_type.name") {
        description = `Category`;
        // const pinType = pinTypes.find((pinType) => pinType._id === value);
        // valueDescription = pinType ? pinType.name : "";
        if (Array.isArray(value)) {
          const pinTypeNames = value.map((value) => {
            const pinType = pinTypes.find((pinType) => pinType._id === value);
            return pinType ? pinType.name : "";
          });
          valueDescription = `${pinTypeNames?.join(", ")}`;
        } else {
          const pinType = pinTypes.find((pinType) => pinType._id === value);
          valueDescription = `${pinType ? pinType.name : ""}`;
        }
      } else if (field === "level") {
        description = `Floor`;
        const levelName = orgLevels.find((level) => level._id === value)?.name;
        valueDescription = levelName ? levelName : "";
      } else if (field === "room.number") {
        description = `Room`;
      } else if (field === "asset.name") {
        description = `Asset`;
      } else {
        description = field.charAt(0).toUpperCase() + field.slice(1);
      }

      if (!valueDescription) {
        valueDescription = value;
      }

      if (operator === "equals") {
        description = `${description}: ${valueDescription}`;
      } else if (operator === "is" || operator === "isAnyOf") {
        description = `${description}: ${valueDescription}`;
      } else if (operator === "contains") {
        description = `${description} Contains: ${valueDescription}`;
      }

      if (
        operator === "after" ||
        operator === "before" ||
        operator === "onOrAfter" ||
        operator === "onOrBefore"
      ) {
        if (operator === "after") {
          description = `${description}: After`;
        } else if (operator === "before") {
          description = `${description}: Before`;
        }
        description = `${description} ${new Date(
          valueDescription
        ).toLocaleDateString()}`;
      } else if (operator === "between") {
        description = `${description}: Between ${valueDescription}`;
      }
      terms.push({
        [field]: value,
        filterItem: field,
        description,
      });
    });
    setVisibleFilterTerms(terms);
  }, [
    buildings,
    checklistTemplates,
    filterModel,
    inspectionPrograms,
    orgLevels,
    pinTypes,
  ]);

  const [showHeaderFilters, setShowHeaderFilters] = useState<boolean>(false);
  const handleToggleHeaderFilters = () => {
    setShowHeaderFilters((prev) => !prev);
  };
  const [visibleFilterTerms, setVisibleFilterTerms] = useState<FilterTerms[]>(
    []
  );
  const [showExportDrawer, setShowExportDrawer] = useState(false);
  const handleExportClick = () => {
    setShowExportDrawer(true);
  };

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box css={ss.dataGridWrapper}>
        <ActiveViewToolbar
          viewContext="checklists"
          organizationId={organization._id}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          filterModel={filterModel}
          visibleFilterTerms={visibleFilterTerms}
          isFilterChanged={isFilterChanged}
          setFilterModel={setFilterModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          setOpenToast={setOpenToast}
          setToastMessage={setToastMessage}
        />
        <Box css={ss.container}>
          <DataGridPro
            getRowId={(row) => row._id}
            rows={checklists}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityChange}
            filterMode="server"
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => {
              setFilterModel(newFilterModel);
            }}
            rowCount={checklistCount}
            loading={isLoading || isValidating || isCounting}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            headerFilters={showHeaderFilters}
            slots={{
              toolbar: () => (
                <GridToolbar
                  onToggleHeaderFilters={handleToggleHeaderFilters}
                  showHeaderFilters={showHeaderFilters}
                  onExportClick={handleExportClick}
                />
              ),
              pagination: () => (
                <ListPagination
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  handleRefresh={() => {
                    mutate();
                  }}
                />
              ),
              columnMenu: (props) => (
                <DateRangeSelectors {...props} filterModel={filterModel} />
              ),
            }}
            autosizeOptions={{
              columns: ["frequency"],
              includeOutliers: true,
              outliersFactor: 1.5,
            }}
          />
          {showExportDrawer ? (
            <Box css={ss.sideDrawerContainer}>
              <ExportDrawer
                open={showExportDrawer}
                onClose={() => {
                  setShowExportDrawer(false);
                }}
                filters={filters}
                organization={organization}
                exportRows={checklists}
              />
            </Box>
          ) : null}
        </Box>
        <Snackbar
          open={openToast}
          autoHideDuration={5000}
          onClose={(_event: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === "clickaway") {
              return;
            }

            setOpenToast(false);
          }}
          message={toastMessage}
        ></Snackbar>
      </Box>
    </LocalizationProvider>
  );
};
