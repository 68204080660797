(function () {
  /**
   * @ngdoc component
   * @name abxPinTypeFilters
   *
   * @param {Object} activeFilters - Currently active pin type filters.
   *     Should be a mapping of pin type IDs to their filter objects.
   * @param {Object} floor - Floor to filter within
   * @param {Function} onFilterChange - To be invoked when one of its filters
   *     is activated, deactivated, or modified
   * @param {Function} onItemSubfilterClick - To be invoked when a pin type
   *     filter item's subfilter button is clicked. Should be invoked with:
   *     event.pinTypeId.
   * @param {Number} page - Page of the floor plan to view
   * @param {Object[]} pinTypes - Pin types to filter with
   *
   * @description
   * A list of interactable pin types that the user can use to filter with.
   */
  angular.module("akitabox.planView").component("abxPinTypeFilters", {
    bindings: {
      activeFilters: "<abxActiveFilters",
      floor: "<abxFloor",
      onFilterChange: "&abxOnFilterChange",
      onItemSubfilterClick: "&abxOnItemSubfilterClick",
      page: "<abxPage",
      pinTypes: "<abxPinTypes",
    },
    controller: AbxPinTypeFiltersController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/pin-type-filters/pin-type-filters.component.html",
  });

  function AbxPinTypeFiltersController() {
    var self = this;

    // Functions
    self.pinTypeComparator = pinTypeComparator;
    self.hasPins = hasPins;
    self.doesNotHavePins = doesNotHavePins;

    /**
     * Orders pinTypes so that rooms come first, and the list is alphabetical.
     * @param {Object} a - A pinType
     * @param {Object} b - A pinType
     * @return {Number} - The result of comparing a and b.
     */
    function pinTypeComparator(a, b) {
      var isRoom = function (pinType) {
        return pinType.is_room;
      };
      a = a.value;
      b = b.value;
      if (isRoom(a) && !isRoom(b)) {
        return -1;
      } else if (!isRoom(a) && isRoom(b)) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    }

    /**
     * Returns true if the pinType has pins on this floor
     * @param {Object}  pinType
     * @return {boolean}
     */
    function hasPins(pinType) {
      return pinType.pins_count === undefined || pinType.pins_count > 0;
    }

    /**
     * Returns true if the pinType does not have pins on this floor
     * @param {Object}  pinType
     * @return {boolean}
     */
    function doesNotHavePins(pinType) {
      return pinType.pins_count === 0;
    }
  }
})();
