(function () {
  angular.module("akitabox.desktop.request.detail").run(states);

  /* @ngInject */
  function states(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/request/detail/overview/request-overview.html",
          controller: "RequestOverviewTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Service Request Overview",
          },
        };
      },
      asset: function () {
        return {
          url: "/asset",
          templateUrl:
            "app/desktop/modules/request/detail/asset/request-asset.html",
          controller: "RequestAssetTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Service Request Asset",
          },
        };
      },
      details: function () {
        return {
          url: "/details",
          templateUrl:
            "app/desktop/modules/request/detail/details/request-details.html",
          controller: "RequestDetailsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Service Request Details",
          },
        };
      },
      floor: function () {
        return {
          url: "/floor",
          templateUrl:
            "app/desktop/modules/request/detail/floor/request-floor.html",
          controller: "RequestFloorTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Service Request Floor",
          },
        };
      },
      room: function () {
        return {
          url: "/room",
          templateUrl:
            "app/desktop/modules/request/detail/room/request-room.html",
          controller: "RequestRoomTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Service Request Room",
          },
        };
      },
      workOrder: function () {
        return {
          url: "/work-order",
          templateUrl:
            "app/desktop/modules/request/detail/work-order/request-work-order.html",
          controller: "RequestWorkOrderTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Service Request WO",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.request.overview",
        config: config.overview(),
      },
      {
        state: "app.building.request.asset",
        config: config.asset(),
      },
      {
        state: "app.building.request.details",
        config: config.details(),
      },
      {
        state: "app.building.request.floor",
        config: config.floor(),
      },
      {
        state: "app.building.request.room",
        config: config.room(),
      },
      {
        state: "app.building.request.workOrder",
        config: config.workOrder(),
      },
      {
        state: "app.request.overview",
        config: config.overview(),
      },
      {
        state: "app.request.asset",
        config: config.asset(),
      },
      {
        state: "app.request.details",
        config: config.details(),
      },
      {
        state: "app.request.floor",
        config: config.floor(),
      },
      {
        state: "app.request.room",
        config: config.room(),
      },
      {
        state: "app.request.workOrder",
        config: config.workOrder(),
      },
    ]);
  }
})();
