(function () {
  /**
   * @ngdoc component
   * @name abxMultiRoundStopItem
   *
   * @param {Object} task   Round work order
   * @param {Object} floor  Current floor
   * @param {Object} pin    Selected pin
   *
   * @description
   * Stop List Item for round task stop information
   */
  angular.module("akitabox.planView").component("abxMultiRoundStopItem", {
    bindings: {
      stops: "<abxStops",
      // allowReopen: "<?abxAllowReopen",
      workOrder: "<abxWorkOrder",
      onClose: "&abxOnClose",
    },
    controller: AbxMultiRoundStopItemController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/multi-round-stop-item/multi-round-stop-item.component.html",
  });

  /** @ngInject */
  function AbxMultiRoundStopItemController(
    // Angular
    $q,
    $scope,
    $timeout,
    // AkitaBox
    models,
    // Constants
    EVENT_ROUND_TASK_PIN_COLOR_UPDATE,
    MINIMUM_RENDER_DELAY,
    // Dialogs
    AlertDialog,
    SkipStopDialog,
    // Services
    BuildingService,
    ChecklistService,
    FeatureFlagService,
    OrganizationService,
    ToastService,
    UserService
  ) {
    var self = this;

    // Constants
    var QUESTION_TYPES = models.CHECKLIST_QUESTION.TYPES;
    var NON_QUESTION_CHECKLIST_ITEM_TYPES =
      models.CHECKLIST_QUESTION.NON_QUESTION_CHECKLIST_ITEM_TYPES;

    // Attributes
    self.loading = true;
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.user = UserService.getCurrent();
    self.permissions = UserService.getPermissions();
    self.canUpdate = false;
    self.title = null;
    self.status = null;
    self.items = [];
    self.errors = [];
    self.succeededCompleteStops = [];
    self.additionalStops = [];
    self.percentDone = 0;
    self.completing = false;
    self.reopening = false;
    self.editingSignature = false;
    self.passFailOptions = getPassFailOptions();
    self.workPerformedBy = null;
    self.workOrderOpen = !isWorkOrderClosed();
    self.stopNames = [];
    self.dirty = false;

    // Functions
    self.isOptDisabled = isOptDisabled;
    self.isDisabled = isDisabled;
    self.completeDisabled = completeDisabled;
    self.complete = complete;
    self.setValue = setValue;
    self.showStopInfoDialog = showStopInfoDialog;
    self.handleClose = handleClose;

    // Skipping stop functions
    self.skipping = false;
    self.isSkippable = isSkippable;
    self.skip = skip;
    self.isSkipped = isSkipped;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      // If the user can answer questions or complete the checklist they can add messages/attachments
      self.canUpdate =
        canAnswer(self.user.identity) || canComplete(self.user.identity);
    };

    self.$onChanges = function (changes) {
      if (changes.stops && self.stops) {
        self.loading = true;
        var firstStop = self.stops[0];
        self.title = getStopName(firstStop);
        self.status = firstStop.status;
        self.items = [];
        for (var i = 0; i < firstStop.items.length; i++) {
          self.items[i] = angular.copy(firstStop.items[i]);
        }
        if (self.stops.length > 1) {
          self.title = "Bulk Editing " + self.stops.length + " Items";
          for (var n = 0; n < self.stops.length; n++) {
            self.stopNames.push(getStopName(self.stops[n]));
          }
        }
        $timeout(function () {
          self.loading = false;
        }, MINIMUM_RENDER_DELAY);
      }
    };

    // =================
    // Public Functions
    // =================

    function isDisabled() {
      return self.workOrder.is_scheduled;
    }

    function isOptDisabled() {
      if (
        self.isDisabled() ||
        self.isSkipped() ||
        !canAnswer(self.user.identity)
      ) {
        return true;
      }
      return false;
    }

    /**
     * Disable if any questions in stop.items
     * do not have a value.
     */
    function completeDisabled() {
      if (self.completing) return true;
      if (self.editingSignature) return true;

      // Check if user can complete
      if (!canComplete(self.user.identity)) {
        // They need all these perms to be able to complete without being an assignee
        return true;
      }

      for (var i = 0; i < self.items.length; ++i) {
        var item = self.items[i];
        var isItemAQuestion =
          NON_QUESTION_CHECKLIST_ITEM_TYPES.indexOf(item.type) === -1;
        if (isItemAQuestion) {
          if (item.type === QUESTION_TYPES.TEXTBOX) {
            if (
              item.enum_options[0].is_passing &&
              (!item.value || !item.value.length)
            ) {
              return true;
            }
          } else if (
            (item.type !== QUESTION_TYPES.ENUM_MULTI_VALUE &&
              item.value === null) ||
            item.value.length === 0
          ) {
            return true;
          }
        }
      }

      return false;
    }

    function complete() {
      self.completing = true;
      self.percentDone = 0;
      var percentOfOneRequest = 100 / (self.stops.length * 2);
      var answerRequests = [];
      self.errors = [];
      self.succeededCompleteStops = [];

      for (var i = 0; i < self.stops.length; i++) {
        var currentStop = self.stops[i];
        var answers = [];

        for (var j = 0; j < self.items.length; j++) {
          var questionToAnswerId = currentStop.items[j]._id;
          var currentItem = self.items[j];

          var isItemAQuestion =
            NON_QUESTION_CHECKLIST_ITEM_TYPES.indexOf(currentItem.type) === -1;
          if (!isItemAQuestion) {
            continue;
          }
          answers.push({
            question: questionToAnswerId,
            value: currentItem.value,
          });
        }

        (function (currentStop, answers, i) {
          let answerRequest;
          // Remove if condition and else block when JIT Checklists FF is removed
          if (FeatureFlagService.isEnabled("jit_checklists")) {
            return ChecklistService.updateMultipleAnswersByBuildingWorkOrder(
              self.building._id,
              currentStop.task,
              currentStop.asset ? currentStop.asset._id : null,
              currentStop.room ? currentStop.room._id : null,
              currentStop.level ? currentStop.level._id : null,
              answers
            )
              .catch(function (err) {
                handleError(err, currentStop, false);
              })
              .finally(function () {
                self.percentDone += percentOfOneRequest;
              })
              .then(function () {
                return ChecklistService.completeByBuildingWorkOrder(
                  self.building._id,
                  currentStop.task,
                  currentStop.asset ? currentStop.asset._id : null,
                  currentStop.room ? currentStop.room._id : null,
                  currentStop.level ? currentStop.level._id : null
                );
              })
              .then(function (checklist) {
                self.stops[i] = checklist;
                self.succeededCompleteStops.push(checklist);
              })
              .catch(function (err) {
                handleError(err, currentStop, true);
              })
              .finally(function () {
                self.percentDone += percentOfOneRequest;
              });
          } else {
            answerRequest = ChecklistService.updateMultipleAnswers(
              self.organization._id,
              currentStop._id,
              answers
            )
              .catch(function (err) {
                handleError(err, currentStop, false);
              })
              .finally(function () {
                self.percentDone += percentOfOneRequest;
              })
              .then(function () {
                return ChecklistService.complete(
                  self.organization._id,
                  currentStop._id
                );
              })
              .then(function (checklist) {
                self.stops[i] = checklist;
                self.succeededCompleteStops.push(checklist);
              })
              .catch(function (err) {
                handleError(err, currentStop, true);
              })
              .finally(function () {
                self.percentDone += percentOfOneRequest;
              });
          }

          answerRequests.push(answerRequest);
        })(currentStop, answers, i);
      }

      return $q.all(answerRequests).finally(function () {
        // omit stops that errored
        $timeout(function () {
          self.completing = false;
          updatePinStatusAndColors(self.succeededCompleteStops);
          if (!self.errors.length) {
            self.onClose({ stops: self.succeededCompleteStops });
          }
        }, MINIMUM_RENDER_DELAY);
      });
    }

    function setValue(question, option) {
      self.dirty = true;

      switch (question.type) {
        case QUESTION_TYPES.ENUM_SINGLE_VALUE:
          // Unset value if it's already set to the selected value
          if (question.value === option.display_value) {
            question.value = null;
          } else {
            question.value = option.display_value;
          }
          break;
        case QUESTION_TYPES.ENUM_SINGLE_VALUE_RADIO:
          // Unset value if it's already set to the selected value
          if (question.value === option.display_value) {
            question.value = null;
          } else {
            question.value = option.display_value;
          }
          break;
        case QUESTION_TYPES.ENUM_MULTI_VALUE:
          // Unset value if it's already set to the selected value
          // See if it's in the value array, if yes, remove it, otherwise put it in
          var alreadyIn = false;
          for (var i = 0; i < question.value.length; i++) {
            if (question.value[i] === option.display_value) {
              question.value.splice(i, 1);
              alreadyIn = true;
              break;
            }
          }
          if (!alreadyIn) question.value.push(option.display_value);
          break;
        case QUESTION_TYPES.SIGNATURE:
          break;
      }
    }

    function showStopInfoDialog() {
      AlertDialog.show({
        locals: {
          title: "Bulk Editing " + self.stops.length + " Items",
          listContents: self.stopNames,
          ok: "close",
        },
      });
    }

    function handleClose() {
      if (!self.completing && self.errors.length) {
        self.onClose({ stops: self.succeededCompleteStops });
        return;
      } else if (self.dirty) {
        AlertDialog.show({
          locals: {
            title: "Cancel Bulk Edit",
            textContent:
              "Leaving this checklist will discard your changes. Are you sure you want to leave?",
            ok: "discard changes",
            cancel: "go back",
          },
        }).then(function (action) {
          if (!action) {
            self.onClose();
          }
        });
      } else {
        self.onClose();
      }
    }

    // ===================
    // Private Functions
    // ===================

    function handleError(err, stop, completing) {
      var pin = stop.asset || stop.room;
      var errorText = err.statusText;
      if (err.data && err.data.error && err.data.error.message) {
        errorText = err.data.error.message;
      }
      var prefix = completing ? "Could not complete " : "Could not answer ";
      self.errors.push({
        stop: prefix + pin.name,
        error: errorText,
      });
    }

    function updatePinStatusAndColors(stops) {
      for (var i = 0; i < stops.length; i++) {
        var stop = stops[i];
        var pin = stop.asset || stop.room;
        if (!pin) {
          continue;
        }

        var inspectionStatus = "";
        switch (stop.status) {
          case models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO:
          case models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO:
          case models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO:
          case models.CHECKLIST.STATUSES.FAIL:
            inspectionStatus = "fail";
            break;
          case models.CHECKLIST.STATUSES.PASS:
            inspectionStatus = "pass";
            break;
          case models.CHECKLIST.STATUSES.SKIP:
            inspectionStatus = "skip";
            break;
          default:
            break;
        }

        var eventData = {
          pinId: pin._id,
          color: pin.color,
          inspection_status: inspectionStatus,
        };
        $scope.$emit(EVENT_ROUND_TASK_PIN_COLOR_UPDATE, eventData);
      }
    }

    function getStopName(stop) {
      var floor = stop.level;
      var pin = stop.asset || stop.room;

      return pin ? pin.name : floor.name;
    }

    function isAssignee(identity) {
      var identities = self.workOrder.assignees;
      for (var i = 0; i < identities.length; i++) {
        if (identities[i]._id === identity._id) {
          return true;
        }
      }
      return false;
    }

    function isWorkOrderClosed() {
      return Boolean(self.workOrder.closed_date);
    }

    function canAnswer(identity) {
      if (isAssignee(identity) && self.permissions.checklist.answer_own) {
        //  always has perms if they are an assignee
        return true;
      } else if (
        !isAssignee(identity) &&
        self.permissions.checklist.answer_any
      ) {
        return true;
      }
      return false;
    }

    function canComplete(identity) {
      if (isAssignee(identity) && self.permissions.checklist.complete_own) {
        //  always has perms if they are an assignee
        return true;
      } else if (
        !isAssignee(identity) &&
        self.permissions.checklist.complete_any
      ) {
        return true;
      }
      return false;
    }

    function getPassFailOptions() {
      return Object.keys(models.CHECKLIST_QUESTION.VALUES).map(function (
        value
      ) {
        return models.CHECKLIST_QUESTION.VALUES[value];
      });
    }

    function skip() {
      var skipped;
      SkipStopDialog.show({
        locals: {
          checklists: self.stops,
        },
      })
        .then(function (skippedChecklists) {
          skipped = skippedChecklists;
          var skipReasons = skippedChecklists[0].skip_reasons;
          var skippedStatus = skippedChecklists[0].status;
          for (var i = 0; i < self.stops.length; i++) {
            var stop = self.stops[i];
            stop.skip_reasons = skipReasons;
            stop.status = skippedStatus;
          }
          self.onClose({ stops: self.stops });
        })
        .catch(ToastService.showError)
        .finally(function () {
          updatePinStatusAndColors(skipped);
        });
    }

    function isSkippable() {
      var userCanSkip =
        // user can skip any stop, doesn't need to be assignee
        self.user.permission_group.checklist.skip_any ||
        // user has to be an assignee to skip
        (self.user.permission_group.checklist.skip_own &&
          self.workOrder.assignees.map(function (assignee) {
            return assignee._id === self.user.identity._id;
          }).length > 0);
      return (
        self.status === models.CHECKLIST.STATUSES.IN_PROGRESS && userCanSkip
      );
    }

    function isSkipped() {
      return self.status === models.CHECKLIST.STATUSES.SKIP;
    }
  }
})();
