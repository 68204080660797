(function () {
  angular.module("akitabox.ui.dialogs.document.editDocumentArray", [
    "akitabox.core.constants",
    "akitabox.core.toast",
    "akitabox.core.services.asset",
    "akitabox.core.services.document",
    "akitabox.core.services.filter",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.room",
    "angular.filter",
  ]);
})();
