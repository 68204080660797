(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.http
   */
  angular
    .module("akitabox.core.services.http", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.auth",
      "akitabox.core.services.env",
      "akitabox.core.services.cacheHelpers",
      "akitabox.core.services.token",
    ])
    .factory("HttpService", HttpService);

  /**
   * @ngdoc service
   * @name HttpService
   * @module akitabox.core.services.http
   *
   * @description
   * Http helper service that provides consistent request functionality
   */

  /* @ngInject */
  function HttpService(
    // Angular
    $q,
    $http,
    $log,
    // Services
    EnvService,
    CacheHelpers,
    TokenService
  ) {
    var service = {
      buildQueryString: buildQueryString,
      parseQueryString: parseQueryString,
      getConfig: getConfig,
      create: post,
      get: get,
      getById: getById,
      getAll: getAll,
      getAllWithCache: getAllWithCache,
      options: options,
      patch: patch,
      post: post,
      put: put,
      remove: remove,
      onError,
    };

    return service;

    /**
     * Build query string with parameters
     *
     * @param  {Object} params  Query parameters
     * @return {String}         Query string
     */
    function buildQueryString(params) {
      if (angular.isEmpty(params)) return "";

      var qString;
      var paramList = [];
      var paramKeys = Object.keys(params).sort();

      for (var i = 0; i < paramKeys.length; ++i) {
        var key = paramKeys[i];
        var val = params[key];
        if (!angular.isEmpty(val)) {
          var stringVal = angular.isObject(val) ? JSON.stringify(val) : val;
          paramList.push(
            encodeURIComponent(key) + "=" + encodeURIComponent(stringVal)
          );
        }
      }

      qString = paramList.length ? "?" + paramList.join("&") : "";

      return qString;
    }

    /**
     * Parse query string into parameters
     *
     * @param  {String} params  Query string
     * @return {Object}         Query params
     */
    function parseQueryString(query) {
      var params = {};

      if (!angular.isEmpty(query)) {
        // Remove question mark
        if (query[0] === "?") query = query.substring(1);
        var pairs = query.indexOf("&") > -1 ? query.split("&") : [query];
        for (var i = 0; i < pairs.length; ++i) {
          var p = pairs[i];
          // Short circuit if missing equals
          if (p.indexOf("=") === -1) continue;
          var parts = p.split("=");
          var key = decodeURIComponent(parts[0]);
          var value = decodeURIComponent(parts[1]);
          params[key] = value;
        }
      }

      return params;
    }

    /**
     * Handle request success
     *
     * @param  {Object} res     Response
     * @return {Object}         Response data
     */
    function onSuccess(res) {
      if (Object.prototype.hasOwnProperty.call(res, "data")) {
        return res.data;
      }
      return null;
    }

    /**
     * Handle response error
     *
     * @param  {Object}     err     Error
     * @return {Promise}            Rejected promise with error
     */
    function onError(err) {
      var online = navigator.onLine;
      if (!online) {
        err.message =
          "We were not able to complete your request. Please check your internet connection and try again.";
      }
      replaceKeys(err, "password", "REDACTED");
      $log.error(err);
      return $q.reject(err);
    }

    /**
     * Censor certain keys on an object recursively. Traverses the object & its
     * children, replacing any value at `key` with `replacement`. Mutates the
     * object that is passed in.
     *
     * @param { object } obj The object to replace keys on
     * @param { string | number | symbol } key The key to redact values for
     * @param { any } replacement The value to replace sensitive values with
     * @param { number } [depth=0] Current recursion depth
     * @param { number } [maxDepth=50] Maximum recursion depth
     */
    function replaceKeys(obj, key, replacement, depth = 0, maxDepth = 50) {
      if (!obj || depth > maxDepth) {
        return;
      }
      if (typeof obj === "object") {
        if (obj[key] !== undefined) {
          obj[key] = replacement;
        }
        const keys = Object.keys(obj);
        for (const curKey of keys) {
          if (curKey === key) {
            continue;
          }
          try {
            replaceKeys(obj[curKey], key, replacement, ++depth, maxDepth);
          } catch (err) {
            // probably hit a max callstack error here
            $log.error(err);
            return;
          }
        }
      }
    }

    /**
     * Get request
     *
     * @param  {String}     route     API route
     * @param  {Object}     [params]  Request parameters
     * @param  {Object}     [cache]   Cache object for a route's entity
     * @return {Promise}              Request promise
     */
    function get(route, params, cache) {
      var cachedValues = cache && cache.get("all");
      if (cachedValues && !CacheHelpers.hasCustomFilters(params)) {
        var keys = Object.keys(cachedValues);
        if (!keys.length) return $q.resolve([]);

        // Grab just the values from the cache (as an array)
        var retValues = keys.map(function (key) {
          return cachedValues[key];
        });

        // Handle skip and limit if given
        if (params) {
          var skip = params.skip || 0;
          var limit = params.limit || retValues.length;
          retValues = retValues.slice(skip, skip + limit);
        }

        return $q.resolve(retValues);
      }

      route += buildQueryString(params);
      var request = $http
        .get(EnvService.getApiUrl(route), getConfig())
        .then(onSuccess);

      // Special handling if searching for a particular thing
      // return "404" if none or more than one found
      if (
        params &&
        Object.prototype.hasOwnProperty.call(params, "_id") &&
        params._id.indexOf("$in") < 0
      ) {
        request = request
          .then(function (results) {
            if (!results.length || results.length > 1) {
              $log.error("Found " + results.length + " with ID: " + params._id);
              return $q.reject({
                status: 404,
                error: {
                  message: "Not Found (by client)",
                },
              });
            }
            return results[0];
          })
          .catch(function (err) {
            if (err.status === 400) {
              err = {
                status: 404,
                error: err.error,
              };
            }
            return $q.reject(err);
          });
      }

      return request.catch(onError);
    }

    /**
     * Get request, by ID
     *
     * @param  {String}  route     API route
     * @param  {String}  id        ID of model to query for
     * @param  {Object}  [params]  Request parameters
     * @param  {Object}  [cache]   Cache object for a route's entity
     * @return {Promise}           Request promise
     */
    function getById(route, id, params, cache) {
      var useCache =
        cache && cache.get("all") && !CacheHelpers.hasCustomFilters(params);
      if (useCache) {
        var cachedValue = cache.get("all")[id];
        if (cachedValue) return $q.resolve(cachedValue);
      }

      // Can't use cache; go to API
      return get(route, params);
    }

    /**
     * Get all objects from a collection
     *
     * @param {String} route     API route
     * @param {Object} [params]  Request parameters
     * @param {Object} [cache]   Cache object for a route's entity
     *
     * @returns {*}
     */
    function getAll(route, params, cache) {
      var useCache = cache && !CacheHelpers.hasCustomFilters(params);
      if (useCache && cache.get("all")) {
        return getAllWithCache(cache);
      } else if (useCache && cache.get("allRequest")) {
        // Initial "all" request for the cache still pending; return it
        return cache.get("allRequest");
      }

      params = angular.extend({}, params);
      params.limit = params.limit || 1000;
      params.skip = params.skip || 0;

      var results;

      function fetch() {
        // Need to use defer for notify
        var deferred = $q.defer();
        $http
          .get(
            EnvService.getApiUrl(route + buildQueryString(params)),
            getConfig()
          )
          .then(onSuccess)
          .then(function (data) {
            if (angular.isArray(data)) {
              if (!angular.isDefined(results)) {
                results = [];
              }
              if (data.length) {
                Array.prototype.push.apply(results, data);
                // Notify the caller with current results
                deferred.notify(results);
              }
            } else if (angular.isObject(data)) {
              // pinValues will be an object
              if (!angular.isDefined(results)) {
                results = {};
              }
              if (Object.keys(data).length) {
                results = angular.extend(results, data);
                // Notify the caller with current results
                deferred.notify(results);
              }
            }
            // Increase skip
            params.skip += params.limit;
            var arrayMoreToFetch =
              angular.isArray(results) && results.length === params.skip;
            var objectMoreToFetch =
              angular.isObject(results) &&
              Object.keys(results).length === params.skip;
            if (arrayMoreToFetch || objectMoreToFetch) {
              // Fetch more
              deferred.resolve(fetch());
            } else {
              // We have everything
              deferred.resolve(results);
            }
          })
          .catch(function (err) {
            $log.error(err);
            deferred.reject(err);
          });

        // Return the promise
        return deferred.promise;
      }

      var request = fetch().then(function (results) {
        if (useCache) {
          CacheHelpers.setAll(cache, results);
        }

        return results;
      });
      if (cache) {
        cache.put("allRequest", request);
      }
      return request;
    }

    /**
     * Returned the cached values for 'all' of a route's values (no
     * filters)
     *
     * @param {Object} cache      Cache to retrieve data from
     * @param {Object} [route]    API route
     * @param {Object} [params]   Request parameters
     */
    function getAllWithCache(cache, route, params) {
      var cachedValues = cache.get("all");
      if (cachedValues) {
        var keys = Object.keys(cachedValues);
        if (!keys.length) return $q.resolve([]);

        // Grab just the values from the cache (as an array)
        var retValues = keys.map(function (key) {
          return cachedValues[key];
        });

        return $q.resolve(retValues);
      } else if (cache.get("allRequest")) {
        // Initial "all" request for the cache still pending; return it
        return cache.get("allRequest");
      }

      // No cached values yet, do initial fetch
      return getAll(route, params, cache);
    }

    /**
     * POST request
     *
     * @param  {String}     route     API route
     * @param  {Object}     data      Request data
     * @param  {Object}     [config]  Request config
     * @param  {Object}     [params]  Query params
     * @param  {Object}     [cache]   Route's cache. Should only be passed in when creating an
     *                                  entity
     * @return {Promise}              Request promise
     */
    function post(route, data, config, params, cache) {
      route += buildQueryString(params);

      if (!config) {
        config = getConfig();
      } else if (!config.headers) {
        config.headers = {
          Authorization: TokenService.getAuthHeader(),
        };
      }

      if (!config.headers.Authorization) {
        config.headers.Authorization = TokenService.getAuthHeader();
      }

      var request = $http
        .post(EnvService.getApiUrl(route), data, config)
        .then(onSuccess)
        .catch(onError);

      if (cache) {
        request.then(function (newModel) {
          CacheHelpers.updateModel(cache, newModel, newModel._id);
          return newModel;
        });
      }

      return request;
    }

    /**
     * Remove (DELETE) request
     *
     * @param  {String}     route    API route
     * @param  {Object}     [id]     Entity to remove's id
     * @param  {Object}     [cache]  Route's cache
     * @return {Promise}             Request promise
     */
    function remove(route, id, cache) {
      var request = $http
        .delete(EnvService.getApiUrl(route), getConfig())
        .then(onSuccess)
        .catch(onError);

      if (cache) {
        request.then(function (data) {
          CacheHelpers.removeModel(cache, id);
          return data;
        });
      }

      return request;
    }

    /**
     * Patch request
     *
     * @param  {String}     route    API route
     * @param  {Object}     data     Request data
     * @param  {Object}     [params] Query params
     * @param  {Object}     [cache]  Route's cache
     * @return {Promise}             Request promise
     */
    function patch(route, data, params, cache) {
      route += buildQueryString(params);
      var request = $http
        .patch(EnvService.getApiUrl(route), data, getConfig())
        .then(onSuccess)
        .catch(onError);

      if (cache) {
        request.then(function (updatedModel) {
          CacheHelpers.updateModel(cache, updatedModel, updatedModel._id);
          return updatedModel;
        });
      }

      return request;
    }

    /**
     * Put request
     *
     * @param  {String}     route    API route
     * @param  {Object}     data     Request data
     * @param  {Object}     [params] Query params
     * @param  {Object}     [cache]  Route's cache
     * @return {Promise}             Request promise
     */
    function put(route, data, params, cache) {
      route += buildQueryString(params);
      var request = $http
        .put(EnvService.getApiUrl(route), data, getConfig())
        .then(onSuccess)
        .catch(onError);

      if (cache) {
        request.then(function (updatedModel) {
          CacheHelpers.updateModel(cache, updatedModel, updatedModel._id);
          return updatedModel;
        });
      }

      return request;
    }

    /**
     * Options request
     *
     * @param route
     */
    function options(route) {
      var config = {
        method: "OPTIONS",
        url: EnvService.getApiUrl(route),
      };
      return $http(config).catch(onError);
    }

    /**
     * creates a config for http requests that include the auth bearer token
     * @return {Object} config object
     */
    function getConfig() {
      return {
        headers: { Authorization: TokenService.getAuthHeader() },
      };
    }
  }
})();
