(function () {
  /**
   * @ngdoc component
   * @name abxBuilding
   *
   * @param {Object}    model       Initial place data model
   * @param {Function}  onChange    Invoked when the user selects an option
   * @param {Boolean}   [disabled]  Disable the input, defaults to false
   * @param {Boolean}   [required]  Visually marks the input as required and
   *                                adds validation
   *
   * @description
   * Building input that pulls suggestions from a users buildings
   */
  angular
    .module("akitabox.ui.components.input.building", [
      "akitabox.ui.components.typeAheadInput",
    ])
    .component("abxBuilding", {
      bindings: {
        model: "<abxModel",
        disabled: "<abxDisabled",
        onBlur: "&abxOnBlur",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        value: "<abxValue",
      },
      controller: AbxBuildingController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/input/components/building/building.component.html",
    });

  /* @ngInject */
  function AbxBuildingController(
    BuildingService,
    OrganizationService,
    ToastService
  ) {
    var self = this;

    // Private
    var organization = OrganizationService.getCurrent();
    var buildingsRequest;

    // Attributes
    self.suggestions = []; // Options objects consumable by the typeahead
    self.loading = false;

    // Functions
    self.handleFocus = handleFocus;

    // =================
    // Public Functions
    // =================

    /**
     * Handle input focus
     */
    function handleFocus() {
      self.onFocus();

      if (!buildingsRequest) {
        buildingsRequest = getBuildings();
      }
    }

    // =================
    // Public Functions
    // =================
    /**
     * Fetch buildings and populate self.suggestions
     * with them. Toasts on error.
     *
     * If there is a current organization set in the application,
     * get that organization's buildings. Otherwise, just get the
     * user's buildings.
     *
     * @return {Promise} - A promise that resolves with the buildings once
     *    self.suggestions has been populated.
     */
    function getBuildings() {
      self.loading = true;

      var params = { sort_by: "name,asc" };

      var buildingsRequest = organization
        ? BuildingService.getAllByOrganization(organization._id, params)
        : BuildingService.getAll(params);
      return buildingsRequest
        .then(function (buildings) {
          createEnumObjects(buildings);
          return buildings;
        })
        .catch(function () {
          self.suggestions = [];
          ToastService.showError();
        })
        .finally(function () {
          self.loading = false;
        });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Update self.suggestions with the given buildings, thus providing
     * all of the buildings as options to the typeahead.
     *
     * @param {Object[]} buildings - The buildings to provide to the typeahead
     */
    function createEnumObjects(buildings) {
      self.suggestions = [];
      for (var building = 0; building < buildings.length; building++) {
        self.suggestions.push(createValueObject(buildings[building]));
      }
    }

    /**
     * Helper function to create a valueObject for the typeahead
     *
     * @param {Object} building - The building to create a valueObject for
     * @return {Object} - A valueObject populated with the building as the value,
     *    the building's name as the label, and the building's mongo id as id.
     */
    function createValueObject(building) {
      if (!building) {
        return {
          model: null,
          value: null,
        };
      }
      return {
        model: building,
        value: building.name,
      };
    }
  }
})();
