(function () {
  angular
    .module("akitabox.core.services.schedule", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.http",
      "akitabox.core.services.timeZone",
    ])
    .factory("ScheduleService", ScheduleService);

  /** @ngInject */
  function ScheduleService(moment, models, HttpService, TimeZoneService) {
    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getByOrganization: getByOrganization,
      getById: getById,
      getAll: getAll,
      getAllByOrganization: getAllByOrganization,
      getFrequencySummary: getFrequencySummary,
      // Update
      update: update,
      cancel: cancel,
      reopen: reopen,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, scheduleId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + scheduleId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL
      );
    }

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildListRoute(buildingId);
      // Set date to be milliseconds to avoid confusion
      if (data.start_date) {
        data.start_date = new Date(data.start_date).valueOf();
      }
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getAll(buildingId, params, useCache) {
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, params, useCache);
    }

    function getAllByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, scheduleId, params) {
      var route = buildDetailRoute(buildingId, scheduleId);
      return HttpService.getById(route, scheduleId, params);
    }

    function getFrequencySummary(schedule, utcOffsetInput, building) {
      // default utcOffset to current offset from TZ service
      var utcOffset;
      if (utcOffsetInput || utcOffsetInput === 0) {
        utcOffset = utcOffsetInput;
      } else {
        if (building) {
          utcOffset = TimeZoneService.getCurrentUTCOffset(building);
        } else {
          utcOffset = TimeZoneService.getCurrentUTCOffset();
        }
      }

      var summary = "Repeats every ";

      // Determine period and interval
      if (schedule.period === 1) {
        // Use singular period
        summary += schedule.interval.slice(0, -1).toLowerCase();
      } else {
        summary += schedule.period + " " + schedule.interval.toLowerCase();
      }

      // If weeks, add selected weekdays
      if (schedule.interval === "weeks") {
        var weekdays = [];
        if (schedule.is_weekly_sunday) weekdays.push("Sunday");
        if (schedule.is_weekly_monday) weekdays.push("Monday");
        if (schedule.is_weekly_tuesday) weekdays.push("Tuesday");
        if (schedule.is_weekly_wednesday) weekdays.push("Wednesday");
        if (schedule.is_weekly_thursday) weekdays.push("Thursday");
        if (schedule.is_weekly_friday) weekdays.push("Friday");
        if (schedule.is_weekly_saturday) weekdays.push("Saturday");

        switch (weekdays.length) {
          case 1:
            summary += " on " + weekdays[0];
            break;
          case 2:
            summary += " on " + weekdays[0] + " and " + weekdays[1];
            break;
          case 7:
            summary += ", every day ";
            break;
          default:
            var lastDay = weekdays[weekdays.length - 1];
            summary +=
              " on " + weekdays.slice(0, -1).join(", ") + " and " + lastDay;
        }
      }

      // Add start date
      if (utcOffset) {
        summary +=
          " starting " +
          moment(schedule.start_date)
            .utcOffset(utcOffset)
            .format("MMMM D, YYYY");
      } else {
        summary +=
          " starting " + moment.utc(schedule.start_date).format("MMMM D, YYYY");
      }

      return summary;
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, scheduleId, data) {
      var route = buildDetailRoute(buildingId, scheduleId);
      return HttpService.put(route, data);
    }

    function cancel(buildingId, scheduleId) {
      var data = { action: "cancel" };
      var route = buildDetailRoute(buildingId, scheduleId);
      return HttpService.patch(route, data);
    }

    function reopen(buildingId, scheduleId) {
      var data = { action: "reopen" };
      var route = buildDetailRoute(buildingId, scheduleId);
      return HttpService.patch(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, scheduleId) {
      var route = buildDetailRoute(buildingId, scheduleId);
      return HttpService.remove(route);
    }
  }
})();
