import {
  MouseEventHandler,
  FunctionComponent,
  useMemo,
  useState,
  useCallback,
} from "react";
import { AbxDataGrid, AbxGridColDef } from "../abx-data-grid/AbxDataGrid";
import { api } from "../../api";
import { Link, useRouteLoaderData } from "react-router-dom";
import { OrganizationResponse } from "@akitabox/api-client";
import { makeUseServiceCall } from "../../hooks/useServiceCall";
import { ReportTemplateResponse } from "@akitabox/api-client";
import { Box, Button } from "@mui/material";
import { CreateReportTemplateDialog } from "../../dialogs/CreateReportTemplateDialog";
import { useApiMutation } from "../../hooks/useApiMutation";
import {
  GridActionsCellItem,
  GridCellModes,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CancelIcon from "@mui/icons-material/Close";
import { AxiosError } from "axios";
import { DeleteReportTemplateDialog } from "../../dialogs/DeleteReportTemplateDialog";
import { useInfiniteCall } from "../../hooks/useInfiniteCall";
import { useGlobalSnackbar } from "../../consecutive-snackbar/GlobalSnackbar";

export const ReportTemplateList: FunctionComponent = () => {
  const { simple } = useGlobalSnackbar();
  const { organization } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
  };
  const dataGridApi = useGridApiRef();

  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [reportTemplateMarkedForDeletion, setReportTemplateMarkedForDeletion] =
    useState<ReportTemplateResponse | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const {
    data: reportTemplateResponse,
    isLoading,
    isValidating,
    mutate: refreshReportTemplates,
  } = useInfiniteCall(api.reportTemplates.getByOrganization, () => [
    {
      organizationId: organization._id,
      sort: "cre_date,desc,name,asc",
      skip: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    },
  ]);

  const reportTemplates = useMemo(() => {
    if (!reportTemplateResponse) return [];
    const flattenItems = reportTemplateResponse.flatMap((item) => item.data);
    const dedupedFlattenedItems = flattenItems.reduce<ReportTemplateResponse[]>(
      (previous, current) => {
        const hasItem = previous.some((item) => item._id === current._id);
        if (!hasItem) previous.push(current);
        return previous;
      },
      []
    );
    return dedupedFlattenedItems;
  }, [reportTemplateResponse]);

  const {
    data: reportTemplateCountResponse,
    isLoading: isCounting,
    mutate: updateReportTemplateCount,
  } = makeUseServiceCall(api.reportTemplates.count)({
    organizationId: organization._id,
  });

  const reportTemplateCount = useMemo(() => {
    return reportTemplateCountResponse?.data.count || 0;
  }, [reportTemplateCountResponse]);

  const { trigger: updateReportTemplate } = useApiMutation(
    api.reportTemplates.updateById
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      // sets all rows back to edit mode
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

      // focus the name cell since its the only editable cell for now
      dataGridApi.current.setCellFocus(id, "name");
    },
    [dataGridApi, rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      // sets all rows back to view mode
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      // change row back to view mode and ignore changes
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const columns = useMemo<AbxGridColDef<ReportTemplateResponse>[]>(() => {
    const defaultColumns: AbxGridColDef<ReportTemplateResponse>[] = [
      {
        field: "name",
        type: "string",
        headerName: "Name",
        abxGridColType: "basic",
        minWidth: 150,
        editable: true,
        cellClassName: "abx-data-grid-cell",
        renderCell: ({ row, value }) => {
          return (
            <Link
              css={{ cursor: "pointer", textDecoration: "unset" }}
              to={{ pathname: `/report_builder/templates/${row._id}` }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        field: "context",
        type: "string",
        headerName: "Context",
        abxGridColType: "basic",
        minWidth: 150,
        valueFormatter(value: string) {
          if (value === "organization") {
            return "All Buildings";
          } else if (value === "building") {
            return "Single Building";
          } else {
            return "";
          }
        },
      },
      {
        field: "last_mod_date",
        type: "date",
        headerName: "Last Modified",
        abxGridColType: "basic",
        minWidth: 150,
        valueFormatter(value?: string) {
          return value ? new Date(value).toLocaleDateString() : "";
        },
      },
      {
        field: "cre_date",
        type: "date",
        headerName: "Created Date",
        abxGridColType: "basic",
        minWidth: 150,
        valueFormatter(value: string) {
          return value ? new Date(value).toLocaleDateString() : "";
        },
      },
      {
        field: "actions",
        type: "actions",
        abxGridColType: "action",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: (params: GridRowParams<ReportTemplateResponse>) => {
          const { row: reportTemplate } = params;
          const isInEditMode =
            rowModesModel[reportTemplate._id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key="save-action"
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(reportTemplate._id)}
              />,
              <GridActionsCellItem
                key="cancel-action"
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(reportTemplate._id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key="edit-action"
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(reportTemplate._id)}
              color="inherit"
            />,
            <GridActionsCellItem
              key="delete-action"
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                setReportTemplateMarkedForDeletion(reportTemplate);
                setShowDeleteDialog(true);
              }}
              color="inherit"
            />,
          ];
        },
      },
    ];

    return defaultColumns;
  }, [handleCancelClick, handleEditClick, handleSaveClick, rowModesModel]);

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = () => {
    setShowCreateDialog(true);
  };

  return (
    <Box overflow={"hidden"}>
      <Box
        css={(theme) => ({
          display: "flex",
          flexDirection: "row",
          padding: theme.spacing(1),
          alignContent: "flex-end",
          justifyContent: "flex-end",
        })}
      >
        <Button variant="contained" onClick={onClickHandler}>
          Add Template
        </Button>
      </Box>
      <AbxDataGrid
        apiRef={dataGridApi}
        getRowId={(row: ReportTemplateResponse) => row._id}
        columns={columns}
        rows={reportTemplates}
        rowCount={reportTemplateCount}
        pageSizeOptions={[paginationModel.pageSize]}
        paginationModel={paginationModel}
        pagination
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        loading={isLoading || isCounting || isValidating}
        height={reportTemplateCount > 0 ? "calc(100% - 52.5px)" : "200px"}
        rowModesModel={rowModesModel}
        editMode="row"
        processRowUpdate={async (
          updated: ReportTemplateResponse,
          old: ReportTemplateResponse
        ) => {
          if (old.name === updated.name) {
            return;
          }

          const updatedReportTemplate = await updateReportTemplate({
            args: {
              organizationId: organization._id,
              reportTemplateId: updated._id,
              reportTemplate: {
                name: updated.name,
              },
            },
          });

          // we have to return the updated row data so our mui-data-grid can update the row
          return updatedReportTemplate.data;
        }}
        onProcessRowUpdateError={(error) => {
          if (error instanceof AxiosError) {
            simple("Failed to update report template", { severity: "error" });
          }
        }}
        css={(_theme) => ({
          ".abx-data-grid-cell": {
            input: {
              "&:focus": {
                outline: 1,
              },
              backgroundColor: "#eee",
            },
          },
        })}
        onCellKeyDown={(params, event) => {
          if (
            event.key === "Enter" &&
            params.cellMode === GridCellModes.Edit &&
            params.field === "name"
          ) {
            setRowModesModel({
              ...rowModesModel,
              [params.id]: { mode: GridRowModes.View },
            });
          }
        }}
        /** turn on when we work on future tickets */
        disableColumnFilter
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableColumnSorting
        disableColumnSelector
        disableMultipleColumnsFiltering
        disableMultipleColumnsSorting
        disableMultipleRowSelection
      />
      <CreateReportTemplateDialog
        organizationId={organization._id}
        open={showCreateDialog}
        onClose={(reportTemplate) => {
          if (reportTemplate) {
            simple(
              `Successfully created report template: ${reportTemplate.name}`
            );
            refreshReportTemplates();
          }
          setShowCreateDialog(false);
        }}
      />
      {reportTemplateMarkedForDeletion && (
        <DeleteReportTemplateDialog
          reportTemplate={reportTemplateMarkedForDeletion}
          open={showDeleteDialog}
          onClose={(deleted) => {
            if (deleted) {
              refreshReportTemplates(
                (current) => {
                  if (!current) return;

                  const updatedCurrent = current.map((reportTemplate) => {
                    const data = reportTemplate.data.filter(
                      (item) => item._id !== reportTemplateMarkedForDeletion._id
                    );

                    return {
                      ...reportTemplate,
                      data,
                    };
                  });

                  updateReportTemplateCount();

                  return [...updatedCurrent];
                },
                { revalidate: false }
              );
            }

            setShowDeleteDialog(false);
            setReportTemplateMarkedForDeletion(null);
          }}
        />
      )}
    </Box>
  );
};
