(function () {
  angular
    .module("akitabox.desktop.room.detail")
    .controller("RoomDetailsTabController", RoomDetailsTabController);

  /* @ngInject */
  function RoomDetailsTabController($timeout, building, room) {
    var self = this;

    self.room = room;
    self.building = building;
    self.rendering = true;

    $timeout(function () {
      self.rendering = false;
    }, 1000);
  }
})();
