(function () {
  angular
    .module("akitabox.core.services.flag", ["akitabox.core.services.http"])
    .factory("FeatureFlagService", FeatureFlag);

  /** @ngInject */
  function FeatureFlag($log, HttpService) {
    var FLAGS_KEY = "akitabox_flags";
    /**
     * Account service
     *
     * @type {Object}
     */
    var service = {
      init: init,
      isEnabled: isEnabled,
    };

    var featureFlags;

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Get all flags
     *
     * @return {Promise<Object|Error>}  Promise that resolves enabled flags
     */
    function _getAllFlags() {
      return HttpService.get("/flags").catch(function (error) {
        $log.error(error);
        return {};
      });
    }

    /**
     * Get local feature flags
     */
    function _get() {
      if (!featureFlags) {
        return JSON.parse(sessionStorage.getItem(FLAGS_KEY)) || {};
      }

      return featureFlags;
    }

    /**
     * Set local feature flags
     *
     * @param {Object} flags Feature flags
     */
    function _set(flags) {
      if (!flags) {
        featureFlags = {};
      }

      sessionStorage.setItem(FLAGS_KEY, JSON.stringify(flags));
      featureFlags = flags;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Fetch and store the feature flags
     *
     * @returns {Object} Feature flags
     */
    function init() {
      return _getAllFlags().then(function (flags) {
        _set(flags);
        return flags;
      });
    }

    /**
     * Determine if a feature flag is enabled
     *
     * @param  {String} key Flag name
     */
    function isEnabled(key) {
      var flags = _get();
      return flags[key];
    }
  }
})();
