/**
 * Downloads file via browser by the following steps:
 * - Creates a temporary DOM anchor tag node
 * - Sets its attributes to download a file from the given API URL
 * - Appends it to the document body
 * - Triggers a click event to initiate the download
 * - Removes the anchor tag node from the document body
 *
 * @note Expects the `apiURL` to return a `200 - OK` with `Content-Disposition` header
 * @param {URL} apiURL - Represents the location to fetch/download the file
 */
export function downloadFile(apiURL: URL) {
  const anchorTag = document.createElement("a");
  anchorTag.target = "_blank";
  anchorTag.rel = "noreferrer";
  anchorTag.href = apiURL.toString();
  document.body.appendChild(anchorTag);
  anchorTag.click();
  document.body.removeChild(anchorTag);
}
