(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.button
   */
  angular
    .module("akitabox.ui.directives.button", ["ngMaterial", "akitabox.core"])
    .directive("abxButton", AbxButtonDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.button
   * @name AbxButtonDirective
   *
   * @desciption
   * `<abx-button>` is a button directive with custom support for icons and styles
   *
   * @usage
   * <hljs lang="html">
   *   <abx-button class="btn-primary" icon="add">
   *     Add
   *   </abx-button>
   * </hljs>
   */
  function AbxButtonDirective() {
    return {
      restrict: "E",
      replace: true,
      transclude: true,
      link: postLink,
      templateUrl: function (elem, attrs) {
        return attrs.abxAnchor === "true"
          ? "app/core/ui/directives/button/anchor.html"
          : "app/core/ui/directives/button/button.html";
      },
    };

    function postLink($scope, $element, attrs) {
      // UI elements
      var $icon = $element.find("i");

      // Pass through classes
      $element.addClass(attrs.class);

      if ($icon.length > 1) {
        $icon[0].remove();
      } else {
        // Add icon
        var icon = attrs.icon;
        if (angular.isDefined(icon)) {
          $icon.html(icon);
        } else {
          $icon.remove();
        }
      }

      // Set initial disabled state
      $element[0].disabled = angular.isDefined(attrs.disabled) || false;

      // Add default type if not specified
      if (angular.isEmpty(attrs.type)) $element.attr("type", "button");

      $scope.$emit("$viewContentLoaded");
    }
  }
})();
