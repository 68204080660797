(function () {
  angular
    .module("akitabox.ui.dialogs.document.editDocumentArray")
    .factory("EditDocumentArrayDialog", EditDocumentArrayDialog);

  /**
   * @ngInject
   */
  function EditDocumentArrayDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/document/edit-file-list/edit-file-list.dialog.html",
      controller: "EditDocumentArrayDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Shows the edit document array dialog
     *
     * @param  {Object}  options                    Dialog options
     * @param  {Object}  options.locals             Dialog local variables
     * @param  {Object}  options.locals.building    A building model
     * @param  {Array}   options.locals.filters     A set of tag filters (FilterService.tagConfig())
     * @param  {Object}  options.locals.pin         An pin model
     * @param  {Object}  options.locals.isRoom      Given pin is a room
     * @param  {Object}  options.locals.isAsset     Given pin is an asset
     * @param  {Object}  options.locals.pinValue    A pin value object of type tag_filter
     * @param  {Array}   options.locals.selectedDocuments   Populated documents corresponding to the
     *                                                      pinValue's list of document IDs
     * @param {Boolean}  options.locals.skipPinValueUpdate  If true, do not PUT the pinValue after edit
     *
     * @return  {Promise<Object|Error>}    Promise that resolves with the tag filter dialog
     */
    function show(options) {
      if (!options || !options.locals || !options.locals.building) {
        $log.error("EditDocumentArrayDialog: no building local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options || !options.locals || !options.locals.filters) {
        $log.error("EditDocumentArrayDialog: no filters local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options || !options.locals || !options.locals.pin) {
        $log.error("EditDocumentArrayDialog: no pin local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options || !options.locals || !options.locals.pinValue) {
        $log.error("EditDocumentArrayDialog: no pinValue local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (
        !options ||
        !options.locals ||
        (options.locals.isAsset && options.locals.isRoom) ||
        (!options.locals.isAsset && !options.locals.isRoom)
      ) {
        $log.error(
          "EditDocumentArrayDialog: Only one of " +
            "isAsset and isRoom can be true"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }

      // Convert empty selectedDocuments into empty array
      if (angular.isEmpty(options.locals.selectedDocuments)) {
        options.locals.selectedDocuments = [];
      }

      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
