(function () {
  angular.module("akitabox.desktop.workOrder.organizationList", [
    "ui.router",
    "akitabox.core.constants",
    "akitabox.core.services.building",
    "akitabox.core.services.filter",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.workOrdersBff",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.print",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
