(function () {
  /**
   * @ngdoc component
   * @name abxConditionInput
   *
   * @param {Condition} condition - The current Condition to be displayed
   * @param {Assembly} [assembly] - The Assembly who's condition is being displayed
   */
  angular
    .module("akitabox.ui.components.conditionInput", [
      "akitabox.constants",
      "akitabox.core.services.condition",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.assemblyCondition",
      "akitabox.ui.dialogs.assetCondition",
    ])
    .component("abxConditionInput", {
      bindings: {
        condition: "<abxCondition",
        assembly: "<abxAssembly",
        asset: "<abxAsset",
        hideLabel: "<abxHideLabel",
        onSave: "&abxOnSave",
      },
      controller: ConditionInputController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/condition-input/condition-input.component.html",
    });

  /* @ngInject */
  function ConditionInputController(
    // AkitaBox
    models,
    // Services
    ConditionService,
    AssetConditionService,
    ToastService,
    UserService,
    // Dialogs
    AssemblyConditionDialog,
    AssetConditionDialog
  ) {
    var self = this;

    var CONDITION_GRADES_VERBIAGE = models.CONDITION.CONDITION_GRADES_VERBIAGE;

    // Attributes
    self.conditionDisplay = null;
    self.permissions = UserService.getPermissions();

    // Functions
    self.showConditionDialog = showConditionDialog;
    // =================
    // Lifecycle
    // =================
    self.$onInit = function () {
      if (self.condition) {
        self.conditionDisplay =
          CONDITION_GRADES_VERBIAGE[self.condition.condition_rating];
      }
    };

    self.$onChanges = function (changes) {
      if (changes.asset || changes.assembly) {
        if (self.asset) {
          self.parentModelType = "asset";
          self.parentModel = self.asset;
          self.assetId = self.asset._id;
          self.buildingId = self.asset.building._id || self.asset.building;
        } else {
          self.parentModelType = "assembly";
          self.parentModel = self.assembly;
        }
      }
      if (changes.condition && self.condition) {
        self.conditionDisplay =
          CONDITION_GRADES_VERBIAGE[self.condition.condition_rating];
      } else {
        self.condition = null;
      }
    };

    // =================
    // Public Functions
    // =================
    function showConditionDialog() {
      var building = self.parentModel.building;
      var buildingId = building._id || building;
      var service =
        self.parentModelType === "assembly"
          ? AssemblyConditionDialog
          : AssetConditionDialog;
      service
        .show({
          locals: {
            buildingId: buildingId,
            assembly: self.assembly,
            assetId: self.assetId,
          },
        })
        .then(function (updatedData) {
          if (self.parentModelType === "assembly") {
            // updatedData is the parent
            self.assembly = updatedData;
            self.parentModel = updatedData;
            getConditions(
              self.parentModel.building._id || self.parentModel.building,
              self.parentModel._id
            );
          } else {
            // updatedData is the condition
            self.assetId = updatedData.asset;
            self.buildingId = updatedData.building;
            getConditions(self.buildingId, self.assetId);
          }
          if (self.onSave) {
            self.onSave();
          }
        });
    }

    // =================
    // Private Functions
    // =================
    /**
     * Get conditions belonging to an asset or assembly.
     * @param {*} parent
     */
    function getConditions(buildingId, parentId) {
      var service =
        self.parentModelType === "assembly"
          ? ConditionService
          : AssetConditionService;
      var params = {
        sort: "date_recorded,desc",
      };
      if (self.installationDate) {
        params.date_recorded = "$gte," + self.installationDate.getTime();
      }
      return service
        .getAll(buildingId, parentId, params)
        .then(function (conditions) {
          if (conditions.length) {
            self.historicalConditions = conditions;
            var condition = conditions[0];
            self.condition = condition;
            self.conditionDisplay =
              CONDITION_GRADES_VERBIAGE[condition.condition_rating];
          } else {
            self.condition = null;
            self.conditionDisplay = null;
            self.historicalConditions = null;
          }
        })
        .catch(ToastService.showError);
    }
  }
})();
