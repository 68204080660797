(function () {
  angular.module("akitabox.desktop.components.filterBarManager", [
    "akitabox.core.services.asset",
    "akitabox.core.services.room",
    "akitabox.core.services.floor",
    "akitabox.core.services.flag",
    "akitabox.core.services.organization",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.user",
    "akitabox.core.services.roomsBff",
  ]);
})();
