(function () {
  /**
   * @ngdoc component
   * @name abxMarkupLayerListItem
   *
   * @param {Object} markupLayer - iiterable markup layer
   *
   * @description
   * Markup layer visual representation
   */
  angular.module("akitabox.planView").component("abxMarkupLayerListItem", {
    bindings: {
      markupLayer: "<",
      canDelete: "<?",
      canAdminOnly: "<?",
      canEdit: "<?",
      canSelect: "<?",
      selectedLayer: "<",
      layerType: "@",
    },
    transclude: {
      subtitleSlot: "span",
    },
    controller: abxMarkupLayerListItemController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-dropdowns/markup-layer-list-item/markup-layer-list-item.html",
  });

  /* @ngInject */
  function abxMarkupLayerListItemController(
    // Akitabox
    EditMarkupDialog,
    DeleteMarkupDialog,
    MarkupService,
    ShadowService
  ) {
    var self = this;
    var GA_CATEGORY = "markup";

    // Functions
    self.toggleLayerVisibility = toggleLayerVisibility;
    self.toggleAdminVisibility = toggleAdminVisibility;
    self.editLayer = editLayer;
    self.removeLayer = removeLayer;
    self.setActiveLayer = setActiveLayer;

    // Function Defiinitions

    /**
     * Toggle the visibility of a markup layer
     */
    function toggleLayerVisibility() {
      MarkupService.toggleMarkup(
        self.markupLayer,
        !self.markupLayer.$isVisible
      );

      if (self.layerType === "collaborator") {
        if (self.markupLayer.$isVisible) {
          ShadowService.sendEvent(
            GA_CATEGORY,
            "toggle-collaborator-markup",
            "on",
            null
          );
        } else {
          ShadowService.sendEvent(
            GA_CATEGORY,
            "toggle-collaborator-markup",
            "off",
            null
          );
        }
      } else if (self.layerType === "user") {
        if (self.markupLayer.$isVisible) {
          ShadowService.sendEvent(
            GA_CATEGORY,
            "toggle-my-visibility",
            "on",
            null
          );
        } else {
          ShadowService.sendEvent(
            GA_CATEGORY,
            "toggle-my-visibility",
            "off",
            null
          );
        }
      }
    }

    /**
     * Toggle status of markup as admin_only
     */
    function toggleAdminVisibility() {
      MarkupService.toggleAdmin(
        self.markupLayer,
        !self.markupLayer.admin_only
      ).then(function (_markup) {
        self.markupLayer.admin_only = _markup.admin_only;
        return;
      });
    }

    /**
     * Open edit layer dialog
     */
    function editLayer() {
      var locals = {
        markup: self.markupLayer,
      };

      EditMarkupDialog.show({
        locals: locals,
      });

      ShadowService.sendEvent(GA_CATEGORY, "edit-name", null, null);
    }

    /**
     * Open remove layer dialog
     */
    function removeLayer() {
      var locals = {
        markup: self.markupLayer,
      };
      DeleteMarkupDialog.show({
        locals: locals,
      });

      ShadowService.sendEvent(GA_CATEGORY, "delete", null, null);
    }

    /**
     * Set selected layer as active layer
     */
    function setActiveLayer() {
      if (self.canSelect) {
        MarkupService.selectMarkup(self.markupLayer);
        ShadowService.sendEvent(GA_CATEGORY, "select-markup", null, null);
      }
    }
  }
})();
