(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller(
      "FloorClosedWorkOrdersTabController",
      FloorClosedWorkOrdersTabController
    );

  /* @ngInject */
  function FloorClosedWorkOrdersTabController(
    // Constants
    WORK_ORDER_STATUS_CLOSED,
    // Resolve
    building,
    floor,
    // Services
    OrganizationService,
    WorkOrdersBFFService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.floor = floor;
    self.status = WORK_ORDER_STATUS_CLOSED;
    self.workOrders = null;
    self.createOptions = getCreateOptions();
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;

    // ------------------------
    //   Private Functions
    // ------------------------

    function fetchWorkOrders(skip, limit) {
      var closedParams = {
        status: self.status,
        level: self.floor._id,
        skip: skip,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        closedParams
      )
        .then(function (workOrders) {
          if (angular.isArray(self.workOrders)) {
            self.workOrders = self.workOrders.concat(workOrders);
          } else {
            self.workOrders = workOrders;
          }
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllWorkOrders(limit) {
      var closedParams = {
        status: self.status,
        level: self.floor._id,
        skip: 0,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        closedParams
      )
        .then(function (workOrders) {
          self.workOrders = workOrders;
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getCreateOptions() {
      return {
        locals: {
          floors: [floor],
        },
      };
    }
  }
})();
