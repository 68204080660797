(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.fab
   */
  angular
    .module("akitabox.ui.directives.fab", ["akitabox.core.utils"])
    .directive("abxFab", AbxFabDirective);
  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.fab
   * @name AbxFabDirective
   *
   * @description
   * `<abx-fab>` is a floating action button that optionally show secondary actions on click.
   *
   * @usage
   * <hljs lang="html">
   *
   * <abx-fab class="md-primary" icon="add" items="vm.fabActions" aria-label="Add..."></abx-fab>
   *
   * </hljs>
   */
  /** @ngInject */
  function AbxFabDirective(
    $compile,
    $http,
    $log,
    $rootScope,
    $timeout,
    $templateCache,
    DomService
  ) {
    return {
      restrict: "E",
      link: postLink,
      scope: {
        icon: "@",
        items: "=",
        loading: "<",
      },
    };

    function postLink($scope, $element, attrs) {
      // Fab Positions
      var FAB_POS_TOP_RIGHT = "top right";
      var FAB_POS_TOP_LEFT = "top left";
      var FAB_POS_BOTTOM_RIGHT = "bottom right";
      var FAB_POS_BOTTOM_LEFT = "bottom left";

      // Templates
      var TEMPLATE_SINGLE = "app/core/ui/directives/fab/fab-single.html";
      var TEMPLATE_MULTIPLE = "app/core/ui/directives/fab/fab-multiple.html";

      // Determine if the user desires the fab to take the primary action on the first click
      var USE_ONE_CLICK = angular.isDefined(attrs.abxFabOneClick);

      // Pass through HTML attributes
      if (!angular.isEmpty(attrs.ariaLabel)) $scope.ariaLabel = attrs.ariaLabel;

      // Validate items exist
      if (angular.isEmpty($scope.items)) {
        return $log.error("<abx-fab>: at least one item is required");
      }

      // Position the fab on the page
      $scope.position = attrs.position;
      $scope.direction = "up";
      switch ($scope.position) {
        case "top-right":
          $scope.direction = "down";
          $element.addClass(FAB_POS_TOP_RIGHT);
          break;
        case "top-left":
          $scope.direction = "down";
          $element.addClass(FAB_POS_TOP_LEFT);
          break;
        case "bottom-right":
          $element.addClass(FAB_POS_BOTTOM_RIGHT);
          break;
        case "bottom-left":
          $element.addClass(FAB_POS_BOTTOM_LEFT);
          break;
        default:
          $scope.position = "bottom-right";
          $element.addClass(FAB_POS_BOTTOM_RIGHT);
      }

      // Compile template
      if (USE_ONE_CLICK) {
        compile(TEMPLATE_SINGLE);
      } else {
        compile(TEMPLATE_MULTIPLE);
      }

      // Find the fab trigger
      var $trigger;

      // The top level element of this directive
      var $fab;
      // The list of actions that pop-up when clicking the fab
      var $fabActions;

      // Externally hidden e.g. abx-pin-value-list input card
      // Counts times hidden in order to only show once the same number of show events are fired
      var extHidden = 0;

      // Scope attributes
      $scope.isOpen = false;
      $scope.showTooltips = false;
      $scope.primary = $scope.items[0];
      // $scope.secondary = $scope.items.splice(1);

      /**
       * Initialize the FAB
       */
      function init() {
        // Get the fab button trigger element that will be shown/hidden on scroll
        $trigger = getTrigger();
        // Get the top level element for this fab directive, used for hiding/showing fab/fab-actions
        $fab = angular.element(DomService.getParent($trigger[0], "abx-fab"));
        $fabActions = $element.find("MD-FAB-ACTIONS");
        // Watch open/closed
        $scope.$watch("isOpen", onToggle);
        // Watch for fab events and show/hide triggger
        $rootScope.$on("fab:show", onShowEvent);
        $rootScope.$on("fab:hide", onHideEvent);
      }

      /**
       * Handle FAB open and closed events
       *
       * @param {Boolean} open    Whether the FAB is open
       */
      function onToggle(newValue, oldValue) {
        if (newValue === oldValue) return;
        if (newValue) {
          // Show overlay and tooltips
          $element.addClass("active");
          $scope.showTooltips = true;
          /**
           * user is trying to interact with md-fab-actions here, so remove this
           * class to show them
           */
          if ($fabActions) $fabActions.removeClass("display-none");
        } else {
          // Hide overlay and tooltips
          $scope.showTooltips = false;
          // Wait to remove background overlay to avoid pass-through clicks
          $timeout(function () {
            $element.removeClass("active");
          }, 250);

          /**
           * md-fab-actions need to be hidden here so user can click on elements
           * behind them. Since they aren't even visibly shown at this stage, we
           * add this class to properly hide them to not block click events
           */
          if ($fabActions) $fabActions.addClass("display-none");
        }
      }

      /**
       * Compile the directive template
       *
       * @param  {String} templateUrl Template URL
       */
      function compile(templateUrl) {
        $http
          .get(templateUrl, { cache: $templateCache })
          .then(function (response) {
            if (response.status === 200) {
              $element.html(response.data);
              $compile($element.contents())($scope);
              $timeout(function () {
                init();
              });
            } else {
              logTemplateError(templateUrl);
              return;
            }
          })
          .catch(function () {
            logTemplateError(templateUrl);
            return;
          });
      }

      /**
       * Log template error
       *
       * @param  {String} templateUrl Template URL
       */
      function logTemplateError(templateUrl) {
        $log.error(
          "<abx-activity-list-item> unable to retrieve '" + templateUrl + "'"
        );
      }

      /**
       * Hide the FAB tigger
       */
      function hideTrigger() {
        $trigger.addClass("hidden");

        /**
         * The rest of this code just makes sure we truly hide the fab when
         * they are hidden, so we don't block any clicks behind the fab
         */
        $fab.addClass("display-none");
      }

      /**
       * Show the FAB trigger
       */
      function showTrigger() {
        $trigger.removeClass("hidden");

        /**
         * Rest of this code is removing the display-none class to show the fab
         * but keeping it on the md-fab-actions to hide it until someone clicks
         * on the fab. This is needed because the fab actions block a portion
         * of click area while they're hidden, which we don't want.
         */
        // make sure fab actions are truly hidden (not blocking click events)
        if ($fabActions) $fabActions.addClass("display-none");
        // make sure we show the fab
        if ($fab) $fab.removeClass("display-none");
      }

      /**
       * Handle hide event
       */
      function onHideEvent() {
        extHidden++;
        hideTrigger();
      }

      /**
       * Handle show event
       */
      function onShowEvent() {
        extHidden--;
        if (extHidden < 0) extHidden = 0;
        if (!extHidden) showTrigger();
      }

      /**
       * Get the FAB trigger element
       *
       * @return {Object}     Trigger element
       */
      function getTrigger() {
        if (USE_ONE_CLICK) return angular.element($element.children()[0]);
        return $element.find("md-fab-trigger");
      }
    }
  }
})();
