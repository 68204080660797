(function () {
  angular
    .module("akitabox.ui.components.typeAheadDynamicInput")
    .component("abxTypeAheadDynamic", {
      bindings: {
        disabled: "<abxDisabled",
        groupKey: "<abxGroupKey",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        onBlur: "&abxOnBlur",
        options: "<abxOptions",
        value: "<abxValue",
        loading: "<?abxLoading",
        blurOnSelect: "<?abxBlurOnSelect",
        fetchOptions: "&abxFetchOptions",
        placeholder: "<?abxPlaceholder",
        parseOptions: "&abxParseOptions",
        allowTextValue: "<abxAllowTextValue",
      },
      controller: AbxTypeAheadDynamicController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/type-ahead-dynamic/type-ahead-dynamic.component.html",
    });

  function AbxTypeAheadDynamicController($q) {
    var self = this;
    //Functions
    self.fetchAllOptions = fetchAllOptions;
    self.handleFetchOptions = handleFetchOptions;
    self.handleChange = handleChange;
    self.debouncedFetchOptions = angular.debounce(debouncedFetchOptions, 250);
    self.handleSelect = handleSelect;

    function handleFetchOptions(event) {
      $q.resolve(self.fetchOptions({ $event: event })).finally(function () {
        // clear loading state
        self.loading = false;
      });
    }

    function handleSelect(event) {
      self.onSelect({ $event: event });
      if (event.model) {
        self.options = [{ model: event.model, value: event.value }];
      }
    }

    function debouncedFetchOptions(event) {
      self.loading = true;
      self.handleFetchOptions(event);
    }

    function fetchAllOptions() {
      self.loading = true;
      self.handleFetchOptions({ value: self.value, noLimit: true });
    }

    /**
     * This is debounced to avoid requerying after each onChange fire
     */
    function handleChange(event) {
      self.onChange({ $event: event });
      self.debouncedFetchOptions(event);
    }
  }
})();
