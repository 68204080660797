(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("FilterBarManager", FilterBarManagerClassFactory);

  /**
   * @typedef ManagedFilterConfiguration<InputType>
   * @template InputType - Input type for this input configuration. For example,
   *  a simple string filter might be `string` while the room filter might
   *  use entire `room` objects as its input type.
   * A managed filter configuration is a specialized type of FilterOption that
   * follows an interface allowing it to be used with a FilterBarManager.
   * @see FilterBarComponent#FilterOption
   * @see FilterBarComponent#InputConfiguration
   * @extends FilterOption
   * @member { string } displayName
   * @member { InputConfiguration } inputConfiguration - The input configuration
   *  for when this filter is selected.
   * @member { (inputValue: InputType) => void } onApply - Function to be run when this filter is
   *  applied. Invoked with the input value. Should add chips and filter values
   *  to the manager as appropriate. Will be invoked with proper `this` context.
   * @member { ($event) => void } onInputChange - Function to be run when this
   *  filter's input is updated. Should update the input configuration's model
   *  and value members as appropriate.
   * @member { (manager) => InputType[]} getInputValue - This function is utilized
   *  when loading an existing query into the filter bar manager. It is invoked
   *  after the manager has loaded the query, and it should return an input value
   *  that could be passed to this input configuration's `onApply` function to
   *  create the filter value that is already loaded into the manager. This
   *  function should return an empty array when there are no values to apply,
   *  otherwise, one apply-value per array element.
   * @member { () => any } [onSelect] - Function to be run when this filter option
   *  is selected.
   * @member { () => any } [onAdd] - Callback invoked when the filter
   *  configuration is added to the manager. May return a promise. If the promise
   *  rejects, the config will not be added.
   *
   * @example
   *  var manager; // = new FilterBarManager(...)
   *  const simpleFilter = {
   *    manager: manager,
   *    displayName: "Mock",
   *    inputConfiguration: {
   *      type: "string",
   *      model: ""
   *    },
   *    onApply: function(val) {
   *      // onApply is invoked with `this === simpleFilter`
   *      this.manager.addModelFieldFilter("mockField", val);
   *      var chip = {
   *        left: "Mock Field",
   *        right: val,
   *        onClick: function() {
   *          manager.removeChip(chip);
   *          manager.removeModelFieldFilter("mockField");
   *        }
   *      }
   *    },
   *    onInputChange: function(event) {
   *      this.inputConfiguration.model = event.model;
   *    },
   *    getInputValue: function(manager) {
   *      return [manager.getModelFieldFilter("mockField")];
   *    }
   *  }
   */
  /**
   * Factory function for the FilterBarManager class.
   * @return The FilterBarManager class.
   * @example
   *  function ControllerFunction(FilterBarManager) {
   *   var manager = new FilterBarManager();
   *  }
   * @ngInject
   */
  function FilterBarManagerClassFactory($timeout, $q, $log) {
    /**
     * @class
     * @param [config]
     * @param [config.onFilterChange] - fn to run after a filter is cleared,
     *  applied, or a query is loaded using applyQuery.
     */
    function FilterBarManager(config) {
      /**
       * Simple key, value map for model field filters. Added to query as
       * ?key=value
       * @type { {[modelField: string]: string} }
       */
      this.modelFieldFilters = {};

      /**
       * Simple key, value map for pin field filters. Added to query as
       * ?pin_field=[{key, value}, {key, value}]
       * @type { {[pinField: string]: string} }
       */
      this.pinFieldFilters = {};

      /**
       * string value holding the id of the pin type
       * ?pin_type={ _id: value}
       * @type {string}
       */
      this.pinTypeFilter = undefined;

      /**
       * filter that works together with the pin type filter. A map of pin fields
       * with the key being the pin field's id. The value is either a string, which
       * generates queries like `{_id: "5ce...", value: "value"}` (`value="value"`), or an object
       * which will be spread adjacent to the `_id` field, for queries like
       * `{_id: "5c3...", start: "1", end: "2"}` (`value={start: "1", end: "2"}`)
       * @type {Object<string, string|object>}
       * @example
       * {
       *  // query
       *  // {_id: "123490505fd940d", value: "Dishwasher"}
       *  "123490505fd940d": "Dishwasher",
       *  // {_id: "43ca4f7890036ee", value: "Carpet"}
       *  "43ca4f7890036ee": { value: "Carpet" } //
       *  // {_id: "987654321fd940d", start: "200", end: "350"}
       *  "987654321fd940d": { start: "200", end: "350" },
       * }
       */
      this.pinValueFilters = {};

      /**
       * Array of currently active chips. Can be used for the `abx-chips` binding
       * of the filter-bar.
       * @type { Array<{left: string; right: string; onClick: () => void}> }
       */
      this.chips = [];

      /**
       * Array of currently available filter configurations. Can be used for the
       * `abx-filter-options` binding of the filter-bar.
       */
      this.filterConfigurations = [];

      /**
       * The currently selected filter configuration. This object's
       * `inputConfiguration` can be used for the `abx-input-configuration` binding
       * of the filter-bar.
       * @member
       * @type { ManagedFilterConfiguration<any> }
       */
      this.currentFilterConfiguration = null;

      // applyFilter is bound since it is intended to be invoked in a context-free
      // fashion as the `abx-on-filter-select` binding to the filter-bar component.
      this.applyFilter = this.applyFilter.bind(this);

      this.config = config || {};

      /**
       * Internal value to allowing asynchronously added filters to be properly ordered.
       * @type {number}
       * @private
       */
      this._insertionAttempt = 0;

      /**
       * Internal queue of filter configurations currently being added to this manager.
       * Useful for methods that need to wait until all filters have been added before
       * proceeding, such as applyQuery
       * @type {Array}
       * @private
       */
      this.__filterAdditions = [];

      /**
       * Optional function that takes a window query string and applies
       * special-case logic to it for the complete parameters sent to the
       * api
       */
      this.modifyQuery = this.config.modifyQuery || function () {};

      /**
       * The route that is used for counting the total number of items matching these filters
       */
      this.countRoute = this.config.countRoute || undefined;
    }

    /**
     * Sort filter dropdown configurations.
     * @param {object} sortFunction - Custom sort function.
     * @return { void }
     */
    FilterBarManager.prototype.sortConfigurations = function (
      sortFunction = null
    ) {
      if (!sortFunction) {
        sortFunction = (a, b) => {
          if (a.displayName.toLowerCase() > b.displayName.toLowerCase())
            return 1;
          else if (a.displayName.toLowerCase() < b.displayName.toLowerCase())
            return -1;
          else return 0;
        };
      }
      this.filterConfigurations.sort(sortFunction);
    };

    /**
     * Add a new managed filter configuration to this manager.
     * Note: all filter configuration display name must be unique within the same instance of that filter bar
     * @param {object} filterConfiguration - The managed filter configuration to add.
     * @return { Promise<void> }
     */
    FilterBarManager.prototype.addFilterConfiguration = function (
      filterConfiguration
    ) {
      if (!filterConfiguration) {
        return $q.reject(Error("Invalid filter configuration"));
      }
      var manager = this;
      var addPromise;
      filterConfiguration._sortOrder = manager._insertionAttempt++;
      var invokeOnAdd = function () {
        if (filterConfiguration.onAdd) {
          return $q.resolve(filterConfiguration.onAdd());
        } else {
          return $q.resolve();
        }
      };
      addPromise = invokeOnAdd()
        .then(function () {
          var currentConfigurations = manager.filterConfigurations.slice();
          var nameAlreadyInUse = currentConfigurations.some(function (
            ownFilterConfiguration
          ) {
            return (
              ownFilterConfiguration.displayName ===
              filterConfiguration.displayName
            );
          });

          if (nameAlreadyInUse) {
            const nameAlredyInUseError = new Error(
              `Filter Configuration with this display name ${filterConfiguration.displayName} already added.`
            );
            nameAlredyInUseError.name = "FilterNameAlreadyInUseError";
            return $q.reject(nameAlredyInUseError);
          } else {
            currentConfigurations.push(filterConfiguration);
            currentConfigurations.sort(function (a, b) {
              return a._sortOrder - b._sortOrder;
            });
            manager.filterConfigurations = currentConfigurations;
          }
        })
        .catch((err) => {
          if (err.name !== "FilterNameAlreadyInUseError") {
            // We don't need to log this error, it is being handled well
            $log.error(err.stack);
          }
          filterConfiguration.onAddFilterError(err);
          return $q.reject(err);
        });

      manager.__filterAdditions.push(addPromise);
      return addPromise;
    };

    /**
     * Update the input model for the filter input. Defers to the current filter
     * configuration's onInputChange hook to update the input configuration's
     * model and value as necessary.
     */
    FilterBarManager.prototype.handleFilterInputChange = function ($event) {
      if (this.currentFilterConfiguration) {
        this.currentFilterConfiguration.onInputChange($event);
      }
    };

    /**
     * Add a filter chip.
     * @param {object} chip - The chip to add
     * @param {string} chip.left - The left text for the chip (eg: filter name)
     * @param {string} chip.right - The right text for the chip (eg: filter value)
     * @param {function} chip.onClick - The handler fn for clicking a chip
     * @return {void}
     */
    FilterBarManager.prototype.addChip = function (chip) {
      if (this.chips.indexOf(chip) >= 0) throw new Error("Chip already added");
      // fire off the filter update event after removal
      chip.onClick = notifyFilterChangeAfter(chip.onClick, this);
      this.chips = this.chips.slice(0);
      this.chips.push(chip);
    };

    /**
     * Remove a filter chip.
     * @param {object} chip - The chip to remove. Must be present in this.chips,
     *  determined by === identity.
     * @return {void}
     */
    FilterBarManager.prototype.removeChip = function (chip) {
      var newChips = this.chips.filter(function (c) {
        return c !== chip;
      });
      if (this.chips.length === newChips.length) {
        throw new Error("Cannot remove chip that is not present.");
      }
      this.chips = newChips;
    };

    FilterBarManager.prototype.removeChips = function (chipsToRemove) {
      var newChips = this.chips.filter(function (c) {
        return chipsToRemove.indexOf(c) === -1;
      });

      if (this.chips.length === newChips.length) {
        throw new Error("Cannot remove chips that are not present.");
      }

      this.chips = newChips;
    };

    /**
     * Simulates clicking of all current chips. Only notifies of a single
     * filter change.
     */
    FilterBarManager.prototype.clearAllFilters = function () {
      this.chips.forEach(function (chip) {
        chip.onClick({ skipNotify: true });
      });
      notifyFilterChange(this);
    };

    /**
     * Apply a filter using the provided configuration and value by invoking the
     * filter configuration's onApply handler.
     * @param {?} value - The value to apply
     * @param {object} filterConfiguration - The filterConfiguration to apply the
     *  value to.
     * @return {void}
     */
    FilterBarManager.prototype.applyFilter = function (
      value,
      filterConfiguration
    ) {
      var self = this;
      // apply the filter and await
      var applyValue = function () {
        return $q.resolve(filterConfiguration.onApply(value));
      };
      // notify but don't await
      var notifyOfChange = function () {
        notifyFilterChange(self);
      };

      return applyValue().then(notifyOfChange);
    };

    /**
     * Set the currently "selected" filter, updating the currentFilterConfiguration
     * member.
     * @param {object} filterConfiguration - One of the filter configurations in
     *  the `filterConfigurations` member array.
     * @return {Promise<void>}
     */
    FilterBarManager.prototype.setSelectedFilter = function (
      filterConfiguration
    ) {
      if (this.filterConfigurations.indexOf(filterConfiguration) === -1) {
        // Filters must be added using addFilterConfiguration before being set
        // as active.
        return $q.reject(new Error("Unknown filter configuration."));
      }
      var self = this;
      var previousFilterConfiguration = this.currentFilterConfiguration;
      this.currentFilterConfiguration = filterConfiguration;
      if (typeof this.currentFilterConfiguration.onSelect === "function") {
        return $q
          .resolve(this.currentFilterConfiguration.onSelect())
          .catch(function () {
            // If anything fails, revert them back to their previous filter
            self.currentFilterConfiguration = previousFilterConfiguration;
          });
      }
      return $q.resolve();
    };

    /**
     * Add a model field filter to this manager using the provided key and value.
     * Model field filters are the simple filters that are represented in the
     * querystring as `?${key}=${value}`. This method is appropriate for adding
     * any filter that directly queries a model field.
     * @param {string} key - The query key to add
     * @param {string} value - The query value to set
     */
    FilterBarManager.prototype.addModelFieldFilter = function (key, value) {
      this.modelFieldFilters[key] = value;
    };

    /**
     * Remove the model field filter at the specified key.
     * @param {string} key - The query key to remove
     * @return {void}
     */
    FilterBarManager.prototype.removeModelFieldFilter = function (key) {
      delete this.modelFieldFilters[key];
    };

    /**
     * Get the current field filter at the specified key.
     * @param {string} key - The query key to look up
     * @return The value
     */
    FilterBarManager.prototype.getModelFieldFilter = function (key) {
      return this.modelFieldFilters[key];
    };

    /**
     * Sets the pin type filter
     * @param {string} pinTypeId - id of the pin type to filter on
     */
    FilterBarManager.prototype.setPinTypeFilter = function (pinTypeId) {
      this.pinTypeFilter = pinTypeId;
    };

    /**
     * Removes the pin type filter
     */
    FilterBarManager.prototype.removePinTypeFilter = function () {
      delete this.pinTypeFilter;
    };

    /**
     * Returns the if of the pin type being filtered on
     * @return {string}
     */
    FilterBarManager.prototype.getPinTypeFilter = function () {
      return this.pinTypeFilter;
    };

    /**
     * Adds the cross pin field filter to the manager
     * @param {string} pinFieldId - id of the value's pin field
     * @param {string | object} value - The filter value to add. If this is an
     *  object, the object's keys will be spread alongside the pinField ID in
     *  the query (ex: `{start: "1", end: "2"}` for a range). If it is a string,
     *  it will be added as the "value" property of its pin field query.
     */
    FilterBarManager.prototype.addPinValueFilter = function (
      pinFieldId,
      value
    ) {
      this.pinValueFilters[pinFieldId] = value;
    };

    /**
     * Removes the indicated pin value filter
     * @param {string} pinFieldId
     */
    FilterBarManager.prototype.removePinValueFilter = function (pinFieldId) {
      delete this.pinValueFilters[pinFieldId];
    };

    /**
     * Gets the indicated pin value filter's value
     * @param {string} pinFieldId
     * @return {string | object}
     */
    FilterBarManager.prototype.getPinValueFilter = function (pinFieldId) {
      return this.pinValueFilters[pinFieldId];
    };

    /**
     * Fetch the entire list of pin value filters.
     * @return {object} An object whose keys are pinField IDs, and values
     *  are filter values (strings or objects).
     */
    FilterBarManager.prototype.getPinValueFilters = function () {
      return this.pinValueFilters;
    };

    /**
     * Helps turn our map of pin value filters into an array of pin value objects
     * @return {[{ _id: string, value: string }]}
     */
    FilterBarManager.prototype.transformPinValueFiltersToArray = function () {
      var arrayForm = [];
      for (var key in this.pinValueFilters) {
        var filterValue = this.pinValueFilters[key];
        if (typeof filterValue === "object") {
          arrayForm.push(angular.extend({ _id: key }, filterValue));
        } else {
          arrayForm.push({ _id: key, value: this.pinValueFilters[key] });
        }
      }
      return arrayForm;
    };

    /**
     * Add a pin field filter to this manager using the provided key and value.
     * Pin field filters are the simple filters that are represented in the
     * querystring as `?${key}=${value}`. This method is appropriate for adding
     * any filter that directly queries a pin field.
     * @param {string} key - The query key to add
     * @param {string} value - The query value to set
     */
    FilterBarManager.prototype.addPinFieldFilter = function (key, value) {
      this.pinFieldFilters[key] = value;
    };

    /**
     * Remove the pin field filter at the specified key.
     * @param {string} key - The query key to remove
     * @return {void}
     */
    FilterBarManager.prototype.removePinFieldFilter = function (key) {
      delete this.pinFieldFilters[key];
    };

    /**
     * Get the current field filter at the specified key.
     * @param {string} key - The query key to look up
     * @return The value
     */
    FilterBarManager.prototype.getPinFieldFilter = function (key) {
      return this.pinFieldFilters[key];
    };
    /**
     * Generate the query object for the currently applied filters.
     */
    FilterBarManager.prototype.getQuery = function () {
      // apply model field filters to the top level of the query
      var result = angular.copy(this.modelFieldFilters);

      if (Object.keys(this.pinFieldFilters).length) {
        // Add any existing pin field filters to the query object
        var pin_field = [];
        for (var key in this.pinFieldFilters) {
          if (Object.prototype.hasOwnProperty.call(this.pinFieldFilters, key)) {
            pin_field.push({
              name: key,
              value: this.pinFieldFilters[key],
            });
          }
        }

        // transform the resulting obj into a stringified json object
        // so $location.search() can interpret it
        result.pin_field = JSON.stringify(pin_field);
      }

      if (this.pinTypeFilter && Object.keys(this.pinValueFilters).length) {
        result.pin_type = JSON.stringify({
          _id: this.pinTypeFilter,
          pin_fields: this.transformPinValueFiltersToArray(),
        });
      } else if (this.pinTypeFilter) {
        result.pin_type = JSON.stringify({ _id: this.pinTypeFilter });
      }

      return result;
    };

    /**
     * Load the filter bar manager/dependent filter configuration states from
     * a query object. This function should only be invoked once all filter
     * configurations have been added.
     *
     * Invokes onFilterChange once after all filters are loaded.
     * @param { object } query - The query object to parse. Should be a key-value
     *  map (often `$location.search()`)
     */
    FilterBarManager.prototype.applyQuery = function (query) {
      var self = this;

      return $q
        .all(self.__filterAdditions)
        .catch((err) => {
          // Duplicate name error bubbles up to this level, so we need to catch it before .then() to avoid filters not being set
          // TODO: https://akitabox.atlassian.net/browse/WEBAPP-13538
        })
        .then(function () {
          for (var key in query) {
            switch (key) {
              case "pin_field": {
                try {
                  var pinFields = query.pin_field;

                  if (typeof pinFields === "string") {
                    // The usual value of query will be $location.search(), which
                    // doesn't parse deep objects and leaves them as strings, so
                    // we have to do the parsing ourselves
                    pinFields = JSON.parse(query.pin_field);
                  }

                  for (var p = 0; p < pinFields.length; p++) {
                    var pinField = pinFields[p];
                    var keys = Object.keys(pinField);

                    if (keys.indexOf("name") < 0 || keys.indexOf("value") < 0) {
                      // make sure there is a name and value
                      throw new Error("Malformed pin field filters detected");
                    }
                    self.addPinFieldFilter(pinField.name, pinField.value);
                  }
                } catch (err) {
                  // query string for pin_field was mistyped in some way, don't
                  // process any of them
                  self.pinFieldFilters = {};
                }
                break;
              }
              case "pin_type": {
                try {
                  var pinType = query.pin_type;
                  if (typeof pinType === "string") {
                    // The usual value of query will be $location.search(), which
                    // doesn't parse deep objects and leaves them as strings, so
                    // we have to do the parsing ourselves
                    pinType = JSON.parse(pinType);
                  } else {
                    // locally caught, but we just wanna make sure to reset the
                    // pin type and pin value filters
                    throw new Error("Invalid pin type query string");
                  }

                  self.setPinTypeFilter(pinType._id);

                  if (pinType.pin_fields && pinType.pin_fields.length) {
                    // add each pin value filter into our manager
                    for (var i = 0; i < pinType.pin_fields.length; i++) {
                      var value = pinType.pin_fields[i];
                      // just "_id" and "value"
                      if (
                        Object.keys(value).length === 2 &&
                        "_id" in value &&
                        "value" in value
                      ) {
                        self.addPinValueFilter(value._id, value.value);
                      } else {
                        var filterValueObject = angular.copy(value);
                        delete filterValueObject._id;
                        self.addPinValueFilter(value._id, filterValueObject);
                      }
                    }
                  }
                } catch (err) {
                  // reset these back to their initial values if any errors occurs
                  delete self.pinTypeFilter;
                  self.pinValueFilters = {};
                }
                break;
              }
              default: {
                self.addModelFieldFilter(key, query[key]);
                break;
              }
            }
          }
          /**
           * @type {Array<Promise<Array>>}
           */
          var inputValuePromises = [];
          /**
           * Fetch a filter's input values array
           * @return {Promise<Array>}
           */
          var getInputValue = function (filterConfiguration) {
            var safeGetValue = function () {
              try {
                return $q.resolve(filterConfiguration.getInputValue(self));
              } catch (err) {
                return $q.reject(err);
              }
            };
            return safeGetValue()
              .then(function (inputValues) {
                if (!Array.isArray(inputValues)) {
                  $log.error(
                    "Unexpected input value for filter (" +
                      filterConfiguration.displayName +
                      "). getInputValue should return an array"
                  );
                  return [];
                }
                return inputValues;
              })
              .catch(function (err) {
                $log.error(
                  "FilterBarManager: Failed to load existing value for: " +
                    filterConfiguration.displayName +
                    " filter"
                );
                $log.error(err.stack);
                return [];
              });
          };

          // first, save off all of the input values
          for (var k = 0; k < self.filterConfigurations.length; k++) {
            var filterConfiguration = self.filterConfigurations[k];
            inputValuePromises.push(getInputValue(filterConfiguration));
          }
          return $q
            .all(inputValuePromises)
            .then(function (inputValueSets) {
              // once all of the input values are ready, we reset the state of
              // the manager. This causes any unmanaged values to be discarded
              // since they will not be applied again
              self.modelFieldFilters = {};
              self.pinFieldFilters = {};
              self.pinValueFilters = {};

              for (var k = 0; k < inputValueSets.length; k++) {
                var inputValueSet = inputValueSets[k];
                if (!inputValueSet.length) {
                  continue;
                }
                var filterConfiguration = self.filterConfigurations[k];
                for (var valueIndex in inputValueSet) {
                  var inputValue = inputValueSet[valueIndex];
                  filterConfiguration.onApply(inputValue);
                }
              }
              return notifyFilterChange(self);
            })
            .finally(function () {
              self.__filterAdditions = [];
            });
        });
    };

    // Private Functions
    /**
     * Decorates a provided function to invoke onFilterChange afterwords.
     * @param {() => any} fn - The function to modify
     * @param {FilterBarManager} manager - The filter bar manager instance.
     * @return {resultFn} A new function that will invoke the provided function and then
     *  fire onFilterChange.
     */
    function notifyFilterChangeAfter(fn, manager) {
      /**
       * @param {object} options
       * @param {boolean} [options.skipNotify=false] If true, onFilterChange
       *  will not be fired for this particular invocation.
       * @return {void}
       */
      var resultFn = function (options) {
        if (!options) {
          options = {};
        }
        fn();
        if (!options.skipNotify) {
          notifyFilterChange(manager);
        }
      };
      return resultFn;
    }

    /**
     * Invoke the manager's onFilterChange callback, if one is present.
     * @param {FilterBarManager} manager - The manager instance
     * @return {Promise<void>} - A promise that resolves after onFilterChange
     *  returns (or resolves if a promise was returned).
     */
    function notifyFilterChange(manager) {
      if (manager.config.onFilterChange) {
        return new $q(function (resolve, reject) {
          $timeout(function () {
            $q.resolve(manager.config.onFilterChange())
              .then(function () {
                resolve();
              })
              .catch(function () {
                reject();
              });
          });
        });
      }
    }

    return FilterBarManager;
  }
})();
