(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.uniformatClassificationInput
   */
  angular
    .module("akitabox.ui.directives.uniformatClassificationInput", [
      "akitabox.core.services.costLine",
    ])
    .directive(
      "abxUniformatClassificationInput",
      AbxUniformatClassificationDirective
    );

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.uniformatClassificationInput
   * @name AbxUniformatClassificationDirective
   *
   * @description
   * `<abx-uniformat-classification-input>` displays the cost-data fin field of an asset in editable/non-editable form, depending the user's permissions
   *
   * @ngInject
   */
  function AbxUniformatClassificationDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/uniformat-classification-field/uniformat-classification-field.html",
      // require: ["^^?form", "^?abxPinValueList"], //IDK what these mean
      controller: AbxUniformatClassificationController,
      bindToController: true,
      controllerAs: "vm",
      scope: {
        model: "=?abxModel", // linked entity
        onSaveParent: "<abxOnSave",
        readonly: "=abxReadOnly",
        building: "=building",
        assembly: "<abxAssembly",
      },
      link: function (scope) {
        scope.Utils = {
          numTruthyValuesInObj: function (obj) {
            return (
              obj &&
              Object.keys(obj).filter(function (key) {
                return obj[key];
              }).length
            );
          },
        };
      },
    };
  }

  /**
   * Controller for AbxAssetDetails directive
   *
   * @ngInject
   */
  function AbxUniformatClassificationController(
    // Angular
    $q,
    $timeout,
    $state,
    // Services
    CostDataService,
    CostLineService,
    ToastService
  ) {
    var self = this;

    // --------------------
    //   Attributes
    // --------------------

    self.formName = "uniformat_ii";
    self.building = angular.isDefined(self.building) ? self.building : null;
    self.canEdit = false;
    self.isInEditMode = false;
    self.value = {
      code: null,
      description: null,
    };
    self.isValid = false;
    self.uniformatSearchText = null;
    self.invalidUniformat = false;

    // Functions
    self.init = init;
    self.selectDivisionOnLevel = selectDivisionOnLevel;
    self.onEdit = onEdit;
    self.onCancel = onCancel;
    self.onSave = onSave;
    self.onUniformatSearchBlur = onUniformatSearchBlur;
    self.originalDivisionCodeTree = [];

    init();

    function init() {
      /**
       * An array that represents a path through the division code tree.
       * Level i+1 represents the child nodes of the chosen node of level i.
       *
       * Each element in the array is an object that looks like this:
       * {
       *    nodes: <Node[]>,
       *    selectedNode: <NODE>, // ex. {divisionCode: "D", description: "Services", childCount: 4}
       * }
       */
      self.divisionCodeTree = [];

      self.allCostLines = null;
      self.selectedCostLines = {};
    }

    /**
     * Check if the uniformat code is valid so the `Save` button can be enabled.
     * The form is valid if all 4 levels of the divisionCodeTree are populated and the final
     * level of the tree has a `selectedNode`.
     * If the form is valid, get cost lines for the assembly.
     *
     * Additionally, set self.value to null if a user selects `None` so we can remove the assembly
     */
    function checkFormValidity() {
      // the input is valid if the user has selected `None` from the parent dropdown,
      // or if all 4 levels of the divisionCodeTree have a `selectedNode`
      var noneIsSelected =
        self.divisionCodeTree.length === 1 &&
        self.divisionCodeTree[0].selectedNode === null;

      if (noneIsSelected) {
        // deleting an assembly, remove the code and description from self
        self.value = {
          code: null,
          description: null,
        };
        self.allCostLines = null;
        return;
      }
      self.isValid =
        (self.divisionCodeTree.length === 4 &&
          self.divisionCodeTree[3] &&
          self.divisionCodeTree[3].selectedNode) ||
        noneIsSelected;

      if (self.isValid && !noneIsSelected) {
        var divisionCode = self.divisionCodeTree[3].selectedNode.divisionCode;
        getCostLinesOfDivision(divisionCode).then(function (costLines) {
          self.allCostLines = costLines;
          self.selectedCostLines = {};
          if (self.assembly.uniformat === divisionCode) {
            // get existing cost lines for this assembly
            getExistingCostLines().then(function (costLines) {
              // add these to the lineNumber -> boolean structure
              for (var i = 0; i < costLines.length; i += 1) {
                self.selectedCostLines[costLines[i].uniformat] = true;
              }
            });
          }
        });
      } else {
        self.allCostLines = null;
        self.selectedCostLines = {};
      }
    }

    /**
     * This function is called when the user clicks "Edit"
     */
    function onEdit() {
      self.isInEditMode = true;

      // if we are given an assembly with a uniformat code, and the divisionCodeTree is not yet populated
      // fill it out here
      if (
        self.assembly &&
        self.assembly.uniformat &&
        !self.divisionCodeTree.length
      ) {
        self.divisionCodeTree = [null, null, null, null];
        var uniformat = self.assembly.uniformat;
        var index = 3;
        prePopulateDivisionTree(uniformat, index);
      } else if (
        self.assembly &&
        self.assembly.uniformat &&
        self.divisionCodeTree.length
      ) {
        // else if we already have an assembly and divisionCodeTree, don't re-query the RS Means API
        return;
      } else {
        // otherwise we don't have an assembly, get the top level divisions
        getTopLevelDivisions();
      }
    }

    /**
     * Called when the user clicks the "Cancel" button
     */
    function onCancel() {
      self.isInEditMode = false;
      // if the originalTree is populated, replace the current tree with the original tree
      if (self.originalDivisionCodeTree.length > 0) {
        self.divisionCodeTree = self.originalDivisionCodeTree;
      }

      checkFormValidity();
    }

    /**
     * This is called when the user clicks "Save".
     * The save button is only enabled if the user has entered a complete (4-level)
     * division code, or if the user selects "None" from the top level.
     *
     * @param {{code<String>, description<String>}} value  value to save.
     *
     */
    function onSave(value) {
      var costLineIds = {
        cost_lines: Object.keys(self.selectedCostLines).filter(function (key) {
          return self.selectedCostLines[key];
        }),
      };
      var assignedValue = Object.assign({}, costLineIds, value);
      self.isInEditMode = false;
      self.onSaveParent(assignedValue, self.assembly.type, self.assembly._id);
    }

    /**
     * Called when the user triggers a blur event from the uniformat search
     * input box. Query RS Means API and populate the tree based on the provided value
     * @param {Object} $event
     */
    function onUniformatSearchBlur($event) {
      var uniformat = $event.newValue;
      self.invalidUniformat = false;
      // save the original tree in case a user starts filling out a new tree, then clicks cancel
      self.originalDivisionCodeTree = self.divisionCodeTree;

      attemptToPopulateTree(uniformat);
    }

    /**
     * Attempt to populate the divisionCodeTree for a given uniformat code.
     * Dynamically determines which levels of the tree should be populated based
     * on the length of the uniformat code
     * @param {String} uniformat
     */
    function attemptToPopulateTree(uniformat) {
      // level of the divisionCodeTree
      var level;
      // user clicked in then clicked out, don't attempt to populate a null value
      if (!uniformat) {
        return;
      }
      var uniformatLength = uniformat.length;
      // truncated uniformat code corresponding to a length that is acceptable for a level of the divisionCodeTree
      var truncatedUniformat;

      // to determine which level of the tree we are on, check the length of the provided
      // uniformat and round down, truncating the uniformat code
      // length 1 = root
      // length 3 = 1st level
      // length 5 = 2nd level
      // length 8 = 3rd level (leaf node)

      switch (true) {
        case uniformatLength >= 8:
          level = 3;
          truncatedUniformat = uniformat.substring(0, 8);
          break;
        case uniformatLength >= 5:
          truncatedUniformat = uniformat.substring(0, 5);
          level = 2;
          break;
        case uniformatLength >= 3:
          level = 1;
          truncatedUniformat = uniformat.substring(0, 3);
          break;
        default:
          truncatedUniformat = uniformat.substring(0, 1);
          level = 0;
          break;
      }

      // clear the current tree. Reference to the previous tree is saved in self.originalDivisionCodeTree
      self.divisionCodeTree = [null, null, null, null];
      var isPartialTree = level < 3;
      // mark input as invalid if user entered a uniformat code with length not corresponding to a valid
      // division
      if (truncatedUniformat.length < uniformatLength) {
        self.invalidUniformat = true;
      }
      prePopulateDivisionTree(truncatedUniformat, level);
      if (isPartialTree) {
        // if we only loaded a partial tree, get the next level of child nodes
        return getChildDivisionsOfDivision(truncatedUniformat).then(function (
          childDivisions
        ) {
          self.divisionCodeTree[level + 1] = {
            nodes: childDivisions,
            selectedNode: null,
          };
        });
      }
    }

    function getTopLevelDivisions() {
      return CostDataService.getTopLevelDivisions(
        self.building._id,
        self.assembly.type
      )
        .then(function (divisions) {
          self.divisionCodeTree = [
            {
              nodes: divisions,
              selectedNode: null,
            },
          ];
        })
        .catch(function (err) {
          self.canEdit = false;
          ToastService.showError(err);
        })
        .finally(function () {
          checkFormValidity();
        });
    }

    /**
     * Given a uniformat, get the details for the devision to set the code
     * and description
     * @param {uniformat} uniformat
     */
    function getDivisionDetails(uniformat) {
      return CostDataService.getDivisionDetails(
        self.building._id,
        self.assembly.type,
        uniformat
      ).then(function (divisionDetail) {
        return divisionDetail;
      });
    }
    /**
     * Given a uniformat division code, get all children of that node
     *
     * @param {String} divisionCode
     */
    function getChildDivisionsOfDivision(divisionCode) {
      return CostDataService.getDivisionChildren(
        self.building._id,
        self.assembly.type,
        divisionCode
      )
        .then(function (divisions) {
          return divisions;
        })
        .catch(function (err) {
          self.canEdit = false;
        });
    }

    function selectDivisionOnLevel(index) {
      // Trim our tree levels to have nothing beyond this level
      self.divisionCodeTree.length = index + 1;

      // Find what we just selected
      var selectedNode = self.divisionCodeTree[index].selectedNode;
      // If we selected "Select One" (null), don't do anything further
      if (!selectedNode) {
        checkFormValidity();
        return;
      }
      // Get the division Code of the newly selected node
      var newDivisionCode = selectedNode.divisionCode;
      // copy value to the text input and mark input as valid
      self.uniformatSearchText = newDivisionCode;
      self.invalidUniformat = false;

      // Get Child Divisions of the selected division code
      getChildDivisionsOfDivision(newDivisionCode)
        .then(function (childDivisions) {
          if (childDivisions && childDivisions.length) {
            // save child divisions as next level options
            self.divisionCodeTree[index + 1] = {
              nodes: childDivisions,
              selectedNode: null,
            };
          } else {
            // If no children exist, we have reached a leaf division
            // save this division as our value
            self.value = {
              code: newDivisionCode,
              description: selectedNode.description,
            };
          }
        })
        .finally(function () {
          checkFormValidity();
        });
    }

    /**
     * Given a uniformat code, work backwards up the tree using the `parentId` from a division
     * This will populate the self.divisionCodeTree array where each element in the array is an
     * object of the form
     *
     * {
     *    nodes: <Node[]>,
     *    selectedNode: <NODE>, // ex. {divisionCode: "D", description: "Services", childCount: 4}
     * }
     * @param {String} uniformat    uniformat code for some level of the tree
     * @param {Number} startIndex   level of the tree to start populating
     *
     * For example, if startIndex = 2, we will attempt to populate the third level of the divisionCodeTree,
     * and then work backwards up the tree to populate level 1 and level 0.
     */
    function prePopulateDivisionTree(uniformat, startIndex) {
      var index = startIndex;
      var parentNodeId;
      // set the selectedNode at this index
      getDivisionDetails(uniformat)
        .then(function (divisionDetail) {
          self.divisionCodeTree[index] = {
            selectedNode: divisionDetail,
          };
          if (index === 3) {
            // set self.value for the leaf node
            self.value = {
              code: self.divisionCodeTree[index].selectedNode.divisionCode,
              description:
                self.divisionCodeTree[index].selectedNode.description,
            };
            self.uniformatSearchText = self.value.code;
          }

          return divisionDetail.parentId;
        })
        .then(function (parentId) {
          // if there is no parent, then we've reached the top of the tree and we need to populate the top level
          if (!angular.isDefined(parentId)) {
            return CostDataService.getTopLevelDivisions(
              self.building._id,
              self.assembly.type
            );
          }

          // otherwise we are not at the top, and we can fetch the child nodes of the parent
          // to set the `nodes` for the current level
          parentNodeId = parentId;
          return getChildDivisionsOfDivision(parentId);
        })
        .then(function (nodes) {
          self.divisionCodeTree[index].nodes = nodes;
          index--;
          // recursively continue populating lower levels of the divisionCodeTree
          if (index >= 0) {
            prePopulateDivisionTree(parentNodeId, index);
          } else return;
        })
        .catch(function (err) {
          self.invalidUniformat = true;
          if (err && err.status === 404 && uniformat.length > 1) {
            // if we get a 404 back from RS Means, try again to populate a lower division of the tree
            // reduce length of uniformat by 1 so attemptToPopulateTree will try to populate the next
            // lowest level of the tree
            attemptToPopulateTree(uniformat.substring(0, uniformat.length - 1));
          } else if (err && err.status === 404 && uniformat.length > 0) {
            // only have a single character that did not match the RS Means API, repopulate the top
            // level divisions
            getTopLevelDivisions();
          }
          return err;
        })
        .finally(function () {
          checkFormValidity();
        });
    }

    function getCostLinesOfDivision(divisionCode) {
      return CostDataService.getCostLines(
        self.building._id,
        self.assembly.type,
        divisionCode
      )
        .then(function (costLines) {
          return costLines;
        })
        .catch(function (err) {
          self.canEdit = false;
          ToastService.showError(err);
        });
    }

    function getExistingCostLines() {
      return CostLineService.get(self.building._id, {
        assembly: self.assembly._id,
      })
        .then(function (costLines) {
          return costLines;
        })
        .catch(function (err) {
          self.canEdit = false;
          ToastService.showError(err);
        });
    }
  }
})();
