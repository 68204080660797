import {
  ReactNode,
  ElementRef,
  useCallback,
  useState,
  useEffect,
  createContext,
} from "react";
import { OrganizationResponse } from "@akitabox/api-client";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { useAngularApp } from "./angularApp";
import { AbxAppHeader } from "./legacy-components/AbxAppHeader";
import { AbxSideBar } from "./legacy-components/AbxSideBar";
import { getHref } from "../../utils/getHref";
import { AttachmentBuildingDialog } from "../attachment-building-dialog/AttachmentBuildingDialog";
import { BulkAddDialog } from "../bulk-add-dialog/BulkAddDialog";
import { bulkAddDialogOpened } from "../bulk-add-dialog/BulkAddDialogService";
import { ListContext } from "../asset-list/ListContext";

const ALL_BUILDINGS_GROUP = {
  _id: null,
  name: "All Buildings",
  all: true,
};

export type ShellDOMContextType = {
  contentRef: ElementRef<"div"> | null;
};

export const ShellDOMContext = createContext<ShellDOMContextType>({
  contentRef: null,
});

/**
 * Angular shell layout component. Renders and controls the sidebar and app
 * header.
 *
 * @note This component was left in an **unfinished** state after initial workshop
 * development, and will have functionality added in future tickets.
 */
export const AngularShell = ({ children }: { children?: ReactNode }) => {
  const injector = useAngularApp();
  const navigate = useNavigate();
  const [refreshList, setRefreshList] = useState(false);
  const { organization } = useLoaderData() as {
    organization: OrganizationResponse;
  };

  const [contentRef, setContentRef] =
    useState<ShellDOMContextType["contentRef"]>(null);

  const [building, setBuilding] = useState<any>(null);
  const [loadingAngularServices, setLoadingAngularServices] = useState(true);
  const [isAttachmentDialogOpen, setIsAttachmentDialogOpen] = useState(false);
  const [isBulkAddDialogOpen, setIsBulkAddDialogOpen] = useState(false);
  bulkAddDialogOpened.subscribe(() => {
    setIsBulkAddDialogOpen(true);
  });

  useEffect(() => {
    if (!injector || !organization) return;

    const organizationService = injector.get<any>("OrganizationService");
    const featureFlagService = injector.get<any>("FeatureFlagService");
    const buildingService = injector.get<any>("BuildingService");
    const userService = injector.get<any>("UserService");

    injector.get("$rootScope").$apply(() => {
      (async () => {
        const { pathname, search } = window.location;
        const path = `${pathname}${search}`;
        try {
          await Promise.all([
            featureFlagService.init(),
            buildingService.init(path),
            organizationService.init(path),
            userService.init(organization._id),
          ]);
        } finally {
          setLoadingAngularServices(false);
        }
      })();
    });
  }, [injector, organization]);

  const onBuildingSelect = useCallback(
    (event: any) => {
      const { model: building, organization: eventOrg } = event;
      setBuilding(building);

      if (organization._id !== eventOrg._id) {
        const { subdomain_key } = eventOrg;
        const dest = `${getHref(subdomain_key)}/buildings/${building._id}`;
        window.location.assign(dest);
      } else {
        navigate(`/buildings/${building._id}`);
      }
    },
    [navigate, organization]
  );

  // these are not building groups, but kind of pseudo-buildings
  // used to refer to the All Buildings state and nothing else
  // as far as i can tell
  const [group, setGroup] = useState(ALL_BUILDINGS_GROUP);
  const onGroupChange = useCallback(
    (event: any) => {
      const { model: group, organization: eventOrg } = event;
      setBuilding(null);
      setGroup(group);

      if (organization._id !== eventOrg._id) {
        const { subdomain_key } = eventOrg;
        const dest = getHref(subdomain_key);
        window.location.assign(dest);
      }
    },
    [organization]
  );

  // Controls the initial open-ness of the header switcher
  const isOpen = useCallback(() => !building && !group, [building, group]);

  const onAdd = useCallback(
    async (_event: any) => {
      switch (_event.model) {
        case "request":
          return await injector.get<any>("CreateRequestDialog").show({
            locals: {
              building,
              organization,
            },
          });
        case "task":
          return await injector.get<any>("CreateWorkOrderDialog").show({
            locals: {
              organization,
              building,
            },
          });
        case "future_task":
          return await injector.get<any>("CreateScheduleDialog").show({
            locals: {
              building,
              organization,
            },
          });
        case "level":
          return await injector.get<any>("CreateFloorDialog").show({
            locals: {
              building,
              organization,
            },
          });
        case "room":
          return await injector.get<any>("CreateRoomDialog").show({
            locals: {
              building,
              organization,
            },
          });
        case "asset":
          return await injector
            .get<any>("CreateAssetDialog")
            .show({
              locals: {
                building,
                organization,
              },
            })
            .then(() => {
              setRefreshList(true);
            });
        case "document":
          setIsAttachmentDialogOpen(true);
          return;
        case "inspection_program":
          return await injector.get<any>("CreateInspectionProgramDialog").show({
            locals: {
              building,
              organization,
            },
          });
        default:
          return;
      }
    },
    [building, injector, organization]
  );

  return (
    <ListContext.Provider value={{ refreshList, setRefreshList }}>
      <ShellDOMContext.Provider value={{ contentRef }}>
        {/**
         * Making sure the required container element exist in the DOM
         *
         * Sisense.js bootstraps a div element with the ID "sisenseApp" which in turn
         * enables the creation of various overlay DOM elements, such as tooltips
         * @see https://sisense.dev/guides/embedding/sisense.js.html#angularjs-react-support-in-sisensejs
         * sets the z-index to 99999 to ensure that sisense overlays such as filter dialogs are not hidden
         */}
        <div id="sisenseApp" style={{ zIndex: "99999" }} />
        <div className="app">
          {!loadingAngularServices && (
            <>
              <AbxAppHeader
                injector={injector}
                abxAppTitle="ListView"
                abxIsOpen={isOpen}
                abxOnAdd={onAdd}
                abxOrganization={organization}
                abxIsMobile={false}
                abxBuilding={building}
                abxGroup={group}
                abxOnBuildingChange={onBuildingSelect}
                abxOnGroupChange={onGroupChange}
                abxPrimaryText={building?.name || group?.name || null}
                abxSuperText={organization.name}
              />
              <div id="content-container">
                <AbxSideBar
                  injector={injector}
                  abxOrganization={organization}
                  abxBuilding={building}
                />
                <div id="content" ref={(ref) => setContentRef(ref)}>
                  {children ? children : <Outlet />}
                </div>
              </div>
            </>
          )}
        </div>
        <AttachmentBuildingDialog
          organization={organization._id}
          onClose={() => setIsAttachmentDialogOpen(false)}
          open={isAttachmentDialogOpen}
        />
        <BulkAddDialog
          model={"assets"}
          organizationId={organization._id}
          open={isBulkAddDialogOpen}
          onClose={() => setIsBulkAddDialogOpen(false)}
        />
      </ShellDOMContext.Provider>
    </ListContext.Provider>
  );
};
