(function () {
  /**
   * @ngdoc filter
   * @name abxStringSeparatorFilter
   *
   * @description
   * Formats a source string to separate defined subsets of the string
   * with a separator string. The source string will be separated from right-to-left,
   * including any trailing values at the beginning of the source string.
   *
   * Example: {{ "1234567" | abxStringSeparator }} will display "1-234-567"
   *
   * @param {String} input - The source string to be formatted.
   * @param {String} [separatorStr="-"] - The string value to use as the separator.
   * @param {Number} [stepLimit=3] - The number of characters to include in each subset.
   * @return {String|*} the formatted string (or unformatted non-string input).
   */

  angular
    .module("akitabox.core.filters.stringSeparator", [])
    .filter("abxStringSeparator", abxStringSeparatorFilter);

  function abxStringSeparatorFilter() {
    return function (input, separatorStr, stepLimit) {
      if (typeof input === "string") {
        var parts = [];
        separatorStr = separatorStr || "-";
        stepLimit = stepLimit || 3;

        for (var i = input.length; i > 0; i -= stepLimit) {
          var start = Math.max(0, i - stepLimit);
          var end = i - start;
          parts.push(input.substr(start, end));
        }

        input = parts.reverse().join(separatorStr);
      }

      return input;
    };
  }
})();
