(function () {
  angular
    .module("akitabox.ui.dialogs.job.viewStepLog")
    .controller("ViewStepLogDialogController", ViewStepLogDialogController);

  /* @ngInject */
  function ViewStepLogDialogController(
    // Material
    $mdDialog
  ) {
    var self = this;

    self.onlyShowErrors = false;

    self.cancel = $mdDialog.cancel;
  }
})();
