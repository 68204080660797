(function () {
  angular
    .module("akitabox.ui.dialogs.job.create", [
      "ngMaterial",
      "akitabox.core.constants",
      "akitabox.core.toast",
      "akitabox.core.services.file",
      "akitabox.core.services.floor",
      "akitabox.core.services.http",
      "akitabox.core.services.job",
      "akitabox.core.services.pinType",
      "akitabox.ui.directives.fileChange",
    ])
    .constant("PRIORITY_LIST", {
      /**
       * Priority list that sorts the run script dropdown.
       * If changing these, you need to include the changes in the tests.
       *
       * Low int values (e.g. 1) are sorted higher in the dropdown
       * than high int values (e.g. 99)
       */
      "whole building setup": 1,
      "set pin locations from .dwfx file": 2,
      "generate qr codes": 3,
      "aramark tma room setup": 4,
      "aramark mechanical pin type setup": 5,
      "education asset pin type setup": 6,
      "education room setup": 7,
      "facilities fit pin type setup": 8,
      "regenerate thumbnails": 9,
      "set acceptable tree values": 10,
      "bulk delete rooms/assets": 11,
    });
})();
