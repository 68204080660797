(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("ManagedPinTypeFilter", ManagedPinTypeFilterClassFactory);

  /**
   * @ngInject
   * @return The ManagedPinTypeFilter class
   */
  function ManagedPinTypeFilterClassFactory(
    FilterBarManager,
    ManagedFilterConfiguration,
    PinTypeService
  ) {
    /**
     * @class
     * @param { FilterBarManager } manager - The manager that owns this configuration.
     * @param { object } config - Configuration options. See
     *  `ManagedFilterConfiguration` class for documentation of base class options
     */
    function ManagedPinTypeFilter(manager, config) {
      if (!config) config = {};

      if (typeof config.getBuildingId !== "function") {
        throw new Error(
          "ManagedPinTypeFilter: getBuildingId must be a function"
        );
      } else if (
        config.protected_type &&
        config.protected_type !== "Asset" &&
        config.protected_type !== "Room"
      ) {
        throw new Error(
          "ManagedPinTypeFilter: protected_type must be 'Asset' or 'Room'"
        );
      }

      // super(manager, config);
      config.inputType = "typeahead";
      ManagedFilterConfiguration.call(this, manager, config);

      /** @member { string } */
      this.queryField = "pin_type";
      /** @member { string| undefined } */
      this.protected_type = config.protected_type || undefined;
      /** @member { Function } */
      this.getBuildingId = config.getBuildingId;
      /** @member { Array<ManagedPinValueFilter> } */
      this.assocatedConfigs = [];
    }
    // ManagedPinTypeFilter extends ManagedFilterConfiguration
    ManagedPinTypeFilter.prototype = Object.create(
      ManagedFilterConfiguration.prototype
    );
    ManagedPinTypeFilter.prototype.constructor = ManagedPinTypeFilter;

    /**
     * See description in base class
     * @param { string } filterValue - pin type id
     */
    ManagedPinTypeFilter.prototype.applyFilter = function (filterValue) {
      this.manager.setPinTypeFilter(filterValue);
    };

    /**
     * Removes the pin type filter along with all the filters that were
     * dependent on it
     */
    ManagedPinTypeFilter.prototype.removeFilter = function () {
      this.manager.removePinTypeFilter();

      for (var i = 0; i < this.assocatedConfigs.length; i++) {
        var config = this.assocatedConfigs[i];
        config.invalidateEnumOptions();
        config.onRemove();
      }
    };

    /**
     * See description in base class
     * @param { FilterBarManager } manager
     * @return { string|undefined }
     */
    ManagedPinTypeFilter.prototype.getFilterValue = function (manager) {
      return manager.getPinTypeFilter();
    };

    /**
     * Creates a parent -> child relation between passed in config and this
     * config. Primarily used for when removing the pin type filter, removes
     * all its "child" filters (this.associatedConfigs)
     * @param { ManagedPinValueFilter } config
     */
    ManagedPinTypeFilter.prototype.addAssociatedConfiguration = function (
      config
    ) {
      this.assocatedConfigs.push(config);
    };

    /**
     * See description in base class
     * @return { Promise<[{ model: PinType, value: string }]> }
     */
    ManagedPinTypeFilter.prototype.getEnumOptions = function () {
      var params = {};

      if (this.protected_type && this.protected_type.length) {
        params.protected_type = this.protected_type;
      }

      return PinTypeService.getAll(this.getBuildingId(), params).then(function (
        pinTypes
      ) {
        return pinTypes.map(function (pinType) {
          return {
            model: pinType,
            value: pinType.name,
          };
        });
      });
    };

    /**
     * See description in base class
     * @param { PinType } model - a pin type
     * @param { string } model._id -  the id of the pin type
     * @return { string } - the pin type id
     */
    ManagedPinTypeFilter.prototype.modelValueToFilterValue = function (model) {
      return model._id;
    };

    /**
     * See description in base class
     * @param { PinType } model - a pin type
     * @param { string } model.name - the name of the pin type
     * @return { string }
     */
    ManagedPinTypeFilter.prototype.modelValueToChipText = function (model) {
      return model.name;
    };

    /**
     * See description in base class
     * @param {string} filterValue - the pin type id
     * @return { Promise<PinType> }
     */
    ManagedPinTypeFilter.prototype.filterValueToModelValue = function (
      filterValue
    ) {
      return PinTypeService.getById(this.getBuildingId(), filterValue).then(
        function (pinType) {
          if (pinType) {
            return [pinType];
          }

          return pinType;
        }
      );
    };

    return ManagedPinTypeFilter;
  }
})();
