(function () {
  angular.module("akitabox.core", [
    // Third party
    "angular-cache",
    "angular-duration-format",
    "angular-storage",
    // Core
    "akitabox.core.constants",
    "akitabox.core.filters",
    "akitabox.core.lib",
    "akitabox.core.router",
    "akitabox.core.services",
    "akitabox.core.toast",
    "akitabox.core.utils",
    // Modules
    "akitabox.core.auth",
    "akitabox.core.pinValue",
    "akitabox.core.root",
    "akitabox.core.srpRedirect",
  ]);
})();
