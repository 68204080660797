(function () {
  /**
   * @ngdoc component
   * @name abxMobileHeaderSwitcher
   *
   * @param {Object}    [building]         Current building
   * @param {Boolean}   isShown            Whether this component is currently shown
   *     Allows us to completely remove the map element (instead of just
   *     hiding it), as it has many watchers that slow down the app
   * @param {Function}  onBuildingChange   Building change callback
   * @param {Function}  selectAllBuildings Select all buildings callback
   *
   * @description
   * A component to be used in conjunction with the app header to switch the building and floors
   *
   * @controls {Object[]} buildings - All buildings the current user has access
   *     to
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .component("abxMobileHeaderSwitcher", {
      bindings: {
        organization: "<?abxOrganization",
        building: "<?abxBuilding",
        isShown: "<abxIsShown",
        onOrganizationChange: "&abxOnOrganizationChange",
        onBuildingChange: "&abxOnBuildingChange",
        showBuildings: "<?abxShowBuildings",
      },
      controller: AbxMobileHeaderSwitcherController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/components/mobile-header-switcher/mobile-header-switcher.component.html",
    });

  /* @ngInject */
  function AbxMobileHeaderSwitcherController(
    // Services
    BuildingService,
    OrganizationService,
    ToastService,
    Utils
  ) {
    var self = this;

    // Attributes
    self.buildings = null;
    self.organizations = null;
    self.loadingBuildings = false;

    // Functions
    self.onOrganizationSelect = onOrganizationSelect;
    self.onBuildingSelect = onBuildingSelect;
    self.onChangeList = onChangeList;

    // ------------------------
    //   Lifecycle
    // ------------------------
    self.$onInit = function () {
      getOrganizations();
    };

    self.$onChanges = function (changes) {
      if (changes.organization && self.organization) {
        self.buildings = [];
        getBuildings(self.organization);
      }
    };

    // =================
    // Public Functions
    // =================

    function onOrganizationSelect($event) {
      var organization = $event.model;
      self.building = null;
      self.showBuildings = Utils.isSameModel(self.organization, organization);
      self.organization = organization;
      self.onOrganizationChange({ $event: $event });
    }

    /**
     * Handles actions when the user selects a building from the map
     *
     * @param {Object}  $event    Selection event
     */
    function onBuildingSelect($event) {
      self.building = $event.model;
      $event.organization = self.organization;
      self.onBuildingChange({ $event: $event });
    }

    function onChangeList() {
      self.showBuildings = false;
    }

    // =================
    // Private Functions
    // =================

    /**
     * Get all the buildings
     */
    function getBuildings(organization) {
      self.loadingBuildings = true;
      var params = {
        archive_date: "null",
      };
      if (organization) {
        params.organization = organization._id;
      }
      return BuildingService.getAll(params)
        .then(function (buildings) {
          self.buildings = buildings;
          return self.buildings;
        })
        .catch(function (err) {
          ToastService.showError("Unable to get buildings");
          self.buildings = [];
          return self.buildings;
        })
        .finally(function () {
          self.loadingBuildings = false;
        });
    }

    /**
     * Get all the organizations
     */
    function getOrganizations() {
      var params = {
        deactivated_date: "null",
      };
      return OrganizationService.getAll(params)
        .then(function (organizations) {
          self.organizations = organizations;
          return self.organizations;
        })
        .catch(function (err) {
          ToastService.showError("Unable to get organizations");
          self.organizations = [];
          return self.organizations;
        });
    }
  }
})();
