(function () {
  angular
    .module("akitabox.ui.dialogs.document.restore")
    .factory("RestoreDocumentDialog", RestoreDocumentDialog);

  /* @ngInject */
  function RestoreDocumentDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/document/restore/restore-file.dialog.html",
      controller: "RestoreDocumentDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.documents` is required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error}               Promise that resolves with the restored documents
     */
    function show(options) {
      // Verify we have documents locals
      if (!options || !options.locals || !options.locals.documents) {
        $log.error(
          "RestoreDocumentDialog: you must pass in documents via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
