(function () {
  angular
    .module("akitabox.core.services.room", [
      "akitabox.constants",
      "akitabox.core.constants",
      "akitabox.core.services.filter",
      "akitabox.core.services.floor",
      "akitabox.core.services.http",
      "akitabox.core.services.pin",
      "akitabox.core.services.pinType",
      "akitabox.core.services.virtualRepeat",
      "akitabox.core.services.flag",
    ])
    .factory("RoomService", RoomService);

  // In case you ever wanted to order an $80 well-done
  // steak.

  /* @ngInject */
  function RoomService(
    // Angular
    $log,
    $q,
    // Constants
    models,
    PIN_FIELD_DATA_TYPES,
    // Services
    HttpService,
    FilterService,
    FloorService,
    PinService,
    PinTypeService,
    VirtualRepeatService
  ) {
    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getBFF: getBFF,
      getById: getById,
      getByOrganization: getByOrganization,
      getByBFFRoomsOrganization: getByBFFRoomsOrganization,
      getAll: getAll,
      getAllBFF: getAllBFF,
      getAllByOrganization: getAllByOrganization,
      getValueById: getValueById,
      getAllValues: getAllValues,
      getFloor: getFloor,
      getFloorBFF: getFloorBFF,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrganization: getStatsByOrganization,
      // Populate
      populatePinType: populatePinType,
      populateFloor: populateFloor,
      // Update
      updateById: updateById,
      updateValue: updateValue,
      updateValueByDataType: updateValueByDataType,
      setLocation: setLocation,
      updateFloor: updateFloor,
      recommission: recommission,
      decommission: decommission,
      // Delete
      deleteRoom: deleteRoom,
      // Virtual Repeat
      getVirtualRepeat: getVirtualRepeat,
      // Filters
      getBuildingListFilter: getBuildingListFilter,
      setBuildingListFilter: setBuildingListFilter,
      // Helpers
      /**
       * NOTE: Additional helpers for room pin values found in
       * PinValueService
       */
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildListRoute(buildingId, roomId) {
      var base = buildBaseRoute(buildingId);
      // Value list
      if (!angular.isEmpty(roomId))
        return base + "/" + roomId + "/" + models.PIN_VALUE.ROUTE_PLURAL;
      // Room list
      return base;
    }

    /**
     * Build base organization list room route
     *
     * @param  {String} buildingId    Building ID
     * @return {String}               Route
     * @see {@link buildBFFRoomsOrganizationListRoute} For the BFF version.
     */
    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" + organizationId + "/" + models.ROOM.ROUTE_PLURAL
      );
    }

    /**
     * Build base organization list room route
     *
     * @param  {String} buildingId    Building ID
     * @return {String}               Route
     * @see {@link buildOrganizationListRoute} For the non-BFF version.
     */
    function buildBFFRoomsOrganizationListRoute(organizationId) {
      var BFFweb = "web";
      return (
        "/organizations/" +
        organizationId +
        "/" +
        BFFweb +
        "/" +
        models.ROOM.ROUTE_PLURAL
      );
    }

    function buildBaseRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.ROOM.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, roomId, roomValueId) {
      var base = buildBaseRoute(buildingId);
      // Value detail
      if (!angular.isEmpty(roomValueId)) {
        return (
          base +
          "/" +
          roomId +
          "/" +
          models.PIN_VALUE.ROUTE_PLURAL +
          "/" +
          roomValueId
        );
      }
      // Room detail
      return base + "/" + roomId;
    }

    /******************* *
     *   Create
     * ****************** */

    /**
     * Create a room
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 data        New room data
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with created room
     */
    function create(buildingId, data, params) {
      var route = buildListRoute(buildingId);
      return PinService.create(data, route, params);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    /**
     * @see {@link getBFF} for the BFF version.
     */
    function get(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      return PinService.get(buildingId, route, params, options);
    }

    /**
     * @see {@link get} for the non-BFF version.
     */
    function getBFF(buildingId, params, options, organizationId) {
      var route = buildBFFRoomsOrganizationListRoute(organizationId);
      return PinService.get(buildingId, route, params, options);
    }

    /**
     * @see {@link getAllBFF} for the BFF version.
     */
    function getAll(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      return PinService.getAll(buildingId, route, params, options);
    }

    /**
     * @see {@link getAll} for the non-BFF version.
     */
    function getAllBFF(buildingId, params, options, organizationId) {
      var route = buildBFFRoomsOrganizationListRoute(organizationId);
      return PinService.getAll(buildingId, route, params, options);
    }

    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getStatsByOrganization(organizationId, params) {
      var route = "/stats" + buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    /**
     * Get a room by ID
     *
     * @param  {String}                 buildingId                Building ID
     * @param  {String}                 id                        Room ID
     * @param  {Object}                 [params]                  Query params
     * @param  {Object}                 [options]                 Option to include certain fields
     * @param  {Boolean}                [options.includePinType]  Replace asset's pinType with
     *                                                              populated pin type object
     * @param  {Boolean}                [options.includeFloor]    Attach populated floor object
     * @return {Promise<Object|Error>}                            Promise that resolves with asset
     *
     * Note: this function doesn't have a BFF version.
     */
    function getById(buildingId, id, params, options) {
      // Make sure include_values and our options' includes agree with each other
      var willNeedValues = options && options.includeFloor;
      var includingValues = params && params.include_values;
      if (willNeedValues && !includingValues) {
        // Can't include floor or room if we're not populating values
        $log.error(
          "RoomService: params.include_values must be true if trying to include " +
            "floor"
        );
        return $q.reject();
      }

      var route = buildDetailRoute(buildingId, id);
      var room;
      var request = PinService.getById(id, route, params).then(function (
        _room
      ) {
        room = _room;

        if (options) {
          // Populate fields specified in `options`
          var requests = [];

          if (options.includePinType) {
            requests.push(populatePinType(room));
          }
          if (options.includeFloor) {
            requests.push(populateFloor(room));
          }

          return $q.all(requests);
        }
      });

      return request.then(function () {
        return room;
      });
    }

    /**
     * Get a list of rooms by organization
     *
     * @param  {String}                 organizationId  Organization ID
     * @param  {Object}                 [params]        Query params
     * @param  {Object}                 [options]       Option to includePinType as a populated object
     * @return {Promise<Array>}                         Promise that resolves with asset list
     *
     * @see {@link getByBFFRoomsOrganization} for the BFF version.
     */
    function getByOrganization(organizationId, params, options) {
      var route = buildOrganizationListRoute(organizationId);
      return PinService.getByOrganization(route, params, options);
    }

    /**
     * Get a list of rooms by organization using BFF
     *
     * @param  {String}                 organizationId  Organization ID
     * @param  {Object}                 [params]        Query params
     * @param  {Object}                 [options]       Option to includePinType as a populated object
     * @return {Promise<Array>}                         Promise that resolves with asset list
     *
     * @see {@link getByOrganization} for the non-BFF version.
     */
    function getByBFFRoomsOrganization(organizationId, params, options) {
      var route = buildBFFRoomsOrganizationListRoute(organizationId);
      return PinService.getByOrganization(route, params, options);
    }

    function populatePinType(room) {
      return PinTypeService.getById(room.building, room.pinType).then(function (
        pinType
      ) {
        room.pinType = pinType;
        return room;
      });
    }

    function populateFloor(room) {
      return getFloor(room.building, room.values).then(function (floor) {
        room.level = floor;
        return room;
      });
    }

    /**
     * Get a list of all organization rooms
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with asset list
     *
     * Note: this function doesn't have a BFF version.
     */
    function getAllByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return PinService.getAllByOrganization(route, params);
    }

    function getValueById(buildingId, roomId, roomValueId) {
      var route = buildDetailRoute(buildingId, roomId, roomValueId);
      return HttpService.get(route);
    }

    function getAllValues(buildingId, roomId) {
      var route = buildListRoute(buildingId, roomId);
      return HttpService.getAll(route);
    }

    /**
     * Get a floor from the pinValues
     *
     * @param {String}                  buildingId      Building ID
     * @param {Object}                  pinValues       Room PinValues
     * @return {Promise<Object>}                        Promise that resolves with the floor
     *
     * @see {@link getFloorBFF} for a BFF-related version (different parameters).
     */
    function getFloor(buildingId, pinValues) {
      for (var valueKey in pinValues) {
        var value = pinValues[valueKey];
        if (value.is_level_pin_value) {
          if (angular.isEmpty(value.value)) break;
          return FloorService.getById(buildingId, value.value);
        }
      }
      return $q.resolve(null);
    }

    /**
     * Get a floor from the pinValues when using BFF room data
     *
     * @param {String}                  buildingId      Building ID
     * @param {Object}                  roomData        Room data
     * @return {Promise<Object>}                        Promise that resolves with the floor
     *
     * @see {@link getFloorBFF} for a non-BFF-related version (different parameters).
     */
    function getFloorBFF(buildingId, roomData) {
      return FloorService.getById(
        buildingId,
        roomData.level._id || roomData.level
      );
    }

    /******************* *
     *   Update
     * ****************** */
    /**
     * Update a room
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 roomId      Room ID
     * @param  {Object}                 data        Updated room data
     * @param  {Object}                 [params]    Query params to include values
     * @param  {Object}                 [room]      Original room being updated.
     *     Pass this in if you want it to retain its populated fields.
     * @return {Promise<Object|Error>}              Promise that resolves with updated room
     */
    function updateById(buildingId, roomId, data, params, room) {
      var route = buildDetailRoute(buildingId, roomId);
      return PinService.updateById(data, route, params, room);
    }

    /**
     * Update a room pin value
     *
     * @param  {String}                 buildingId      Building ID
     * @param  {String}                 roomId          Room ID
     * @param  {String}                 roomValueId     Pin value ID
     * @param  {Object}                 data            Updated pin value data
     * @return {Promise<Object|Error>}                  Promise that resolves with updated pin value
     */
    function updateValue(buildingId, roomId, roomValueId, data) {
      var route = buildDetailRoute(buildingId, roomId, roomValueId);
      return PinService.updateValue(data, route);
    }

    /**
     * Update a room pin value by its data type.
     *
     * This is necessary because updates to a room's floor needs special handling. If the value
     * being updated does not need special handling, just do the standard update.
     *
     * @param  {String}                 buildingId      Building ID
     * @param  {String}                 dataType        Data type of value to update
     * @param  {String}                 roomId          Room ID
     * @param  {String}                 valueId         Pin value ID
     * @param  {*}                      newValue        New pin value (just the value, not a pin value model)
     *                                                  Valid to send in full objects for data type that
     *                                                  are entities (e.g. floors)
     * @return {Promise<Object|Error>}                  Promise that resolves with updated pin value
     */
    function updateValueByDataType(
      buildingId,
      dataType,
      roomId,
      valueId,
      newValue
    ) {
      var data = {
        // For values that come in as model objects, the pin value expects to get
        // the ID
        value: newValue && newValue._id ? newValue._id : newValue,
      };

      if (dataType === PIN_FIELD_DATA_TYPES.FLOOR) {
        return updateFloor(buildingId, roomId, data);
      }

      return updateValue(buildingId, roomId, valueId, data);
    }

    /**
     * Update a room's location (coordinates on floor plan)
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 roomId     Room ID
     * @param  {Object}                 data        New room location data
     * @param  {Object}                 [params]    Query params to include values
     * @param  {Object}                 [room]      Original room being updated.
     *     Pass this in if you want it to retain its populated fields.
     * @return {Promise<Object|Error>}              Promise that resolves with updated room
     */
    function setLocation(buildingId, roomId, data, params, room) {
      var route = buildDetailRoute(buildingId, roomId);
      return PinService.setLocation(data, route, params, room);
    }

    /**
     * Update a room's floor
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 roomId      Room ID
     * @param  {Object}                 data        New room floor data
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with updated room
     */
    function updateFloor(buildingId, roomId, data, params) {
      var route = buildDetailRoute(buildingId, roomId);
      return PinService.updateFloor(data, route, params);
    }

    /**
     * Recommission a room.
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 roomId      Room ID
     */
    function recommission(buildingId, roomId) {
      var route = buildDetailRoute(buildingId, roomId);
      return PinService.recommission(route);
    }

    /**
     * Decommission a room
     * @param {String} buildingId - building's id
     * @param {String} roomId - rooms's id
     * @param {Date} decommissionDate - date to decommission
     * @return {Promise<Room>} decommissioned room
     */
    function decommission(buildingId, roomId, decommissionDate) {
      var route = buildDetailRoute(buildingId, roomId);
      return PinService.decommission(route, decommissionDate);
    }

    /******************* *
     *   Delete
     * ****************** */

    function deleteRoom(buildingId, roomId) {
      var route = buildDetailRoute(buildingId, roomId);
      return HttpService.remove(route);
    }

    /******************* *
     *   Virtual Repeat
     * ****************** */

    function getVirtualRepeat(
      buildingId,
      fetchLimit,
      query,
      options,
      organizationId
    ) {
      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service.getBFF(buildingId, params, options, organizationId);
      }
    }

    /******************* *
     *   Filters
     * ****************** */

    /**
     * Retrieve cached building room list filter
     *
     * @param  {String} buildingId
     * @return {Object}
     */
    function getBuildingListFilter(buildingId) {
      return FilterService.getBuildingFilter(buildingId, "room");
    }

    /**
     * Cache the provided building room list filter
     *
     * @param {String} buildingId
     * @param {Object} filter
     */
    function setBuildingListFilter(buildingId, filter) {
      FilterService.setBuildingFilter(buildingId, "room", filter);
    }
  }
})();
