(function () {
  angular
    .module("akitabox.desktop.admin.job.accountList")
    .controller("AccountJobListController", AccountJobListController);

  /* @ngInject */
  function AccountJobListController(
    // AkitaBox
    models,
    // Services
    AdminJobService,
    ToastService,
    // Dialogs
    CreateJobDialog,
    // Resolve,
    account
  ) {
    var self = this;

    // Attributes
    self.jobs = null;
    self.title = "account " + models.JOB.PLURAL;
    self.description = '"Geoff is my Neoffew" -Teodd Heoffmaster';
    self.actions = [];
    self.permissions = {
      canRunScript: false,
      canCancel: false,
    };

    // Functions
    self.createImport = createImport;
    self.fetchJobs = fetchJobs;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      self.permissions.canRunScript = account.site_permissions.can_run_scripts;
      self.permissions.canCancel = self.permissions.canRunScript;
      if (self.permissions.canRunScript) {
        self.actions.push({
          text: "Import",
          icon: "cloud_upload",
          onClick: createImport,
        });
      }
      if (self.permissions.canRunScript) {
        self.actions.push({
          text: "Export",
          icon: "get_app",
          onClick: createExport,
        });
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function createImport() {
      CreateJobDialog.show("accountImport")
        .then(function (job) {
          if (!job) return;
          if (angular.isArray(self.jobs)) {
            self.jobs.unshift(job);
          } else {
            self.jobs = [job];
          }
        })
        .catch(ToastService.showError);
    }

    function createExport() {
      CreateJobDialog.show("accountExport")
        .then(function (job) {
          if (!job) return;
          if (angular.isArray(self.jobs)) {
            self.jobs.unshift(job);
          } else {
            self.jobs = [job];
          }
        })
        .catch(ToastService.showError);
    }

    function fetchJobs(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
      };

      return AdminJobService.getAccountJobs(account._id, params).then(function (
        jobs
      ) {
        if (angular.isArray(self.jobs)) {
          self.jobs = self.jobs.concat(jobs);
        } else {
          self.jobs = jobs;
        }
        return jobs.length;
      });
    }
  }
})();
