(function () {
  angular
    .module("akitabox.desktop.inspectionProgram.detail")
    .controller(
      "InspectionProgramDetailController",
      InspectionProgramDetailController
    );

  /** @ngInject */
  function InspectionProgramDetailController(
    // Angular
    $scope,
    $rootScope,
    // Events
    EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE,
    EVENT_INSPECTION_PROGRAM_UPDATE,
    EVENT_INSPECTION_PROGRAM_DETAIL_LOAD,
    EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD,
    // Resolve
    inspectionProgram,
    // Services
    BuildingService,
    Router,
    ToastService,
    UserService,
    // Dialogs
    CancelInspectionProgramDialog,
    CreateInspectionProgramDialog,
    ReactivateInspectionProgramDialog
  ) {
    var self = this;

    var userPerms = UserService.getPermissions();

    self.building = BuildingService.getCurrent();
    self.inspectionProgram = inspectionProgram;
    self.isLoading = true;
    self.overviewLoading = true;
    self.detailsLoading = true;
    self.headerTitle = getHeaderTitle();
    self.breadcrumbs = null;
    self.headerTitle = "";
    self.showCancelDialog = showCancelDialog;
    self.showReactivateDialog = showReactivateDialog;
    self.showEditDialog = showEditDialog;
    self.permissions = getPermissions();

    self.$onInit = function () {
      $scope.$on(EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE, refreshBreadcrumbs);
      $scope.$on(
        EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD,
        function ($event, data) {
          if (data) {
            self.overviewLoading = data.isLoading;
            self.isLoading = self.detailsLoading || self.overviewLoading;
          }
        }
      );
      $scope.$on(EVENT_INSPECTION_PROGRAM_DETAIL_LOAD, function ($event, data) {
        if (data) {
          self.detailsLoading = data.isLoading;
          self.isLoading = self.detailsLoading || self.overviewLoading;
        }
      });
      getBreadcrumbs();
      getHeaderTitle();
    };

    function refreshBreadcrumbs($event, data) {
      if (data) {
        self.page = data.page;
        self.checklist = data.checklist;
      }
      getBreadcrumbs();
      getHeaderTitle();
    }

    function getPermissions() {
      return {
        canActivate:
          userPerms.inspection_program.activate &&
          userPerms.inspection_program.deactivate,
        canUpdate: userPerms.inspection_program.update,
      };
    }

    function getBreadcrumbs() {
      var breadcrumbs = [];
      var listPage = {
        href: getInspectionListRoute(),
        title: "Inspection Programs",
      };

      listPage.icon = "dns";
      var overviewPage = {
        href: getInspectionOverviewRoute(),
        title: self.inspectionProgram.display_name,
      };

      var detailsPage = {
        href: getInspectionDetailsRoute(),
        title: "Details",
      };

      switch (self.page) {
        case "ip-overview":
          breadcrumbs.push(listPage);
          break;
        case "ip-details":
          breadcrumbs.push(listPage, overviewPage);
          break;
        case "ip-checklist-details":
          breadcrumbs.push(listPage, overviewPage, detailsPage);
          break;
      }

      self.breadcrumbs = breadcrumbs;
    }

    function getHeaderTitle() {
      if (self.checklist) {
        var pin = self.checklist.asset || self.checklist.room;
        if (pin) {
          self.headerTitle = pin.name;
        }
        return;
      }
      self.headerTitle = self.inspectionProgram.display_name;
    }

    function getInspectionListRoute() {
      return Router.href("app.inspectionPrograms");
    }

    function getInspectionOverviewRoute() {
      return Router.href("app.inspectionProgram.overview", {
        inspectionProgramId: self.inspectionProgram._id,
      });
    }

    function getInspectionDetailsRoute() {
      return Router.href("app.inspectionProgram.overview", {
        inspectionProgramId: self.inspectionProgram._id,
      });
    }

    function showCancelDialog() {
      var locals = {
        inspectionPrograms: [self.inspectionProgram],
      };

      return CancelInspectionProgramDialog.show({ locals: locals }).then(
        function () {
          self.inspectionProgram.status = "inactive";
        }
      );
    }

    function showEditDialog() {
      var locals = {
        inspectionProgram: self.inspectionProgram,
      };
      CreateInspectionProgramDialog.show({ locals: locals })
        .then(function (inspectionProgram) {
          $rootScope.$broadcast(EVENT_INSPECTION_PROGRAM_UPDATE, {
            inspectionProgram: inspectionProgram,
          });
          ToastService.showSimple("Successfully updated inspection program.");
        })
        .catch(ToastService.showError);
    }

    function showReactivateDialog() {
      var locals = {
        inspectionPrograms: [self.inspectionProgram],
      };

      return ReactivateInspectionProgramDialog.show({ locals: locals }).then(
        function () {
          $scope.$evalAsync((self.inspectionProgram.status = "active"));
        }
      );
    }
  }
})();
