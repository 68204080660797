(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.fileChange
   */
  angular
    .module("akitabox.ui.directives.fileChange", [])
    .directive("abxOnFileChange", AbxOnFileChangeDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.fileChange
   *
   * @description
   * `abx-on-file-change` is an attribute directive that allows a user to
   * subscribe to a file input's `change` event
   *
   * @usage
   * <hljs lang="html">
   *   <input type="file" accept=".csv" abx-on-file-change="vm.onFileChange(files)" />
   * </hljs>
   */
  function AbxOnFileChangeDirective($log, $timeout) {
    return {
      restrict: "A",
      link: postLink,
      scope: {
        onChange: "&abxOnFileChange",
      },
    };

    function postLink($scope, $element) {
      var $input = $element[0];
      if ($input.tagName !== "INPUT") {
        $log.error("abx-on-file-change: element must an input");
        return;
      }
      if (
        !Object.prototype.hasOwnProperty.call($input.attributes, "type") ||
        $input.attributes.type.value !== "file"
      ) {
        $log.error('abx-on-file-change: element must be of type "file"');
        return;
      }
      // Attach change event listener
      $element.on("change", function () {
        $timeout(function () {
          $scope.onChange({ files: $input.files });
          $input.value = ""; // clear input files
        });
      });
    }
  }
})();
