(function () {
  angular
    .module("akitabox.planView", [
      "ngMaterial",
      "akitabox.core",
      "akitabox.core.services.checklist",
      "akitabox.core.services.flag",
      "akitabox.core.services.identity",
      "akitabox.core.services.markup",
      "akitabox.core.services.organization",
      "akitabox.core.services.user",
      "akitabox.core.services.customerAccount",
      "akitabox.planView.dialogs",
      "akitabox.ui",
      /* Constants */
      "akitabox.constants",
      /* Third-party */
      "ui.router",
    ])
    .constant("MODES", {
      DEFAULT: "default",
      ASSIGN_QR_CODE: "assignQrCode",
      PIN_PLACEMENT: "pinPlacement",
      SEARCH_RESULTS: "searchResults",
      PREVIOUS: "previous",
      INSPECTION: "inspection",
    })
    .constant("VERSION_MODES", {
      DEFAULT: "current",
      PREVIOUS: "prev_rev",
    })
    .constant("PV_GA", {
      CATEGORY: "planview",
      ACTIONS: {
        SEARCH_MULTIPLE: "search-multiple",
        SEARCH_SINGLE: "search-single",
        FILTER: "filter",
        SUBFILTER: "subfilter",
        PIN_DETAIL: "pin-detail",
        COPY_PIN: "copy-pin",
        MOVE_PIN: "move-pin",
        DELETE_PIN: "delete-pin",
        VIEW_MORE_DETAILS: "view-more-details",
      },
    })
    .constant("MARKUP_TOOL", {
      ACTIONS: {
        DRAWER_TOOL_SELECTED: "draw-tool-selected",
        CLOSE_MARKUP_DRAWER: "close-markup-drawer",
        SELECT_MARKUP: "select-markup",
        TOGGLE_MARKUP_VISIBILITY: "toggle-markup-visibility",
      },
      AUTOSAVE_STATE: {
        SYNCED: "autosave-synced",
        SAVING: "autosave-saving",
        FAILED: "autosave-failed",
        NO_INTERNET: "autsave-no-internet",
      },
    })
    .constant(
      "INVALID_URL_LENGTH_MSG",
      "The browser you are using cannot support the AkitaBox link you are trying to use. Please upgrade to a modern browser to use this link."
    )
    .constant(
      "IGNORE_FILTER_MSG",
      "The browser you are using cannot support the number of filters that you are trying to apply. Please upgrade to a modern browser to support more filtering."
    );
})();
