(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.cache
   */
  angular
    .module("akitabox.core.services.cache", ["angular-storage"])
    .factory("CacheService", CacheService);

  /** @ngInject */
  /**
   * @ngdoc factory
   * @module akitabox.core.services.cache
   * @name CacheService
   *
   * @description
   * Service that retrieves, sets, and clears cached data
   */
  function CacheService(store) {
    var service = {
      clear: clear,
      get: get,
      set: set,
    };

    return service;

    /**
     * Clear the cache at the provided key
     * @param {String} key  Cache key
     */
    function clear(key) {
      store.remove(key);
    }

    /**
     * Return the cache at the provided key
     * @param {String} key  Cache key
     * @return {Object}     Cache value at key
     */
    function get(key) {
      return store.get(key);
    }

    /**
     * Set the cache at the provided key
     * @param {string}  key     Cache key
     * @param {Object}  value   Value to cache at key
     */
    function set(key, value) {
      store.set(key, value);
    }
  }
})();
