(function () {
  angular.module("akitabox.desktop.asset.detail").run(stateBlock);

  /* @ngInject */
  function stateBlock(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/asset/detail/overview/asset-overview.html",
          controller: "AssetOverviewTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Asset Overview",
          },
        };
      },
      details: function () {
        return {
          url: "/details",
          templateUrl:
            "app/desktop/modules/asset/detail/details/asset-details.html",
          controller: "AssetDetailsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Asset Details",
          },
        };
      },
      openWorkOrders: function () {
        return {
          url: "/open-work-orders",
          templateUrl:
            "app/desktop/modules/asset/detail/open-work-orders/asset-open-work-orders.html",
          controller: "AssetOpenWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Asset Open WOs",
          },
        };
      },
      closedWorkOrders: function () {
        return {
          url: "/closed-work-orders",
          templateUrl:
            "app/desktop/modules/asset/detail/closed-work-orders/asset-closed-work-orders.html",
          controller: "AssetClosedWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Asset Closed WOs",
          },
        };
      },
      schedules: function () {
        return {
          url: "/maintenance-schedules",
          templateUrl:
            "app/desktop/modules/asset/detail/schedules/asset-schedules.html",
          controller: "AssetSchedulesTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Asset Schedules",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.asset.overview",
        config: config.overview(),
      },
      {
        state: "app.building.asset.details",
        config: config.details(),
      },
      {
        state: "app.building.asset.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.building.asset.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.building.asset.schedules",
        config: config.schedules(),
      },
      {
        state: "app.asset.overview",
        config: config.overview(),
      },
      {
        state: "app.asset.details",
        config: config.details(),
      },
      {
        state: "app.asset.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.asset.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.asset.schedules",
        config: config.schedules(),
      },
    ]);
  }
})();
