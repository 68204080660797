(function () {
  angular
    .module("akitabox.ui.dialogs.inspectionProgram.create")
    .factory("CreateInspectionProgramDialog", CreateInspectionProgramDialog);

  /* @ngInject */
  function CreateInspectionProgramDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/inspection-program/create/create-inspection-program.dialog.html",
      controller: "CreateInspectionProgramDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog.
     * @param { object } options
     * @param { object } options.locals
     * @param { object } options.inspectionProgram - An inspection program to edit
     *
     * @return {Promise<Object>}   Promise that resolves with the newly created/updated inspection program
     */
    function show(options, ga) {
      var dialogOptions = angular.extend({}, modal, options);
      if (dialogOptions.locals.inspectionProgram) {
        dialogOptions.locals.editMode = true;
      } else {
        dialogOptions.locals.editMode = false;
      }
      return $mdDialog.show(dialogOptions);
    }
  }
})();
