(function () {
  angular
    .module("akitabox.desktop.insights", [
      "akitabox.core.services.env",
      "akitabox.core.services.user",
    ])
    .controller("InsightsController", InsightsController);

  /* @ngInject */
  function InsightsController(
    $window,
    $scope,
    OrganizationService,
    EnvService,
    UserService
  ) {
    const sisenseEnvs = new Map([
      ["local", "https://akitabox-dev3.sisense.com"],
      ["temp", "https://akitabox-dev2.sisense.com"],
      ["beta", "https://akitabox-dev1.sisense.com"],
      ["production", "https://akitabox.sisense.com"],
    ]);
    const envName = EnvService.getEnvName();
    const organization = OrganizationService.getCurrent();
    const user = UserService.getCurrent();
    let filtersApplied = false;
    // use fields from the dev3 dashboard unless we are in a nonlocal environment
    const filterJaql = {
      table: "dim_buildings",
      dim: `[dim_buildings.organization_name]`,
      datatype: "text",
      filter: {
        multiSelection: true,
        explicit: true,
        members: [organization.name],
      },
      column: "organization_name",
      title: "Organization Name",
    };

    function dashboardLoadedHandler() {
      const canEdit = user ? user.sisense_role === "designer" : false;
      const { SisenseFrame, enums } = $window["sisense.embed"];
      const defaultDashboard = "64e4fcde281a2e00324f1526";

      const sisenseFrame = new SisenseFrame({
        url: sisenseEnvs.get(envName),
        dashboard: defaultDashboard,
        settings: {
          showRightPane: true,
          showLeftPane: true,
          showToolbar: true,
        },
        editMode: canEdit,
        element: document.getElementById("sisense-iframe"),
      });

      let orgFilter = JSON.stringify({
        jaql: filterJaql,
      });

      sisenseFrame.render().then(() => {
        sisenseFrame.dashboard.on(enums.DashboardEventType.LOADED, function () {
          // dashboard loaded event is triggered on PDF export, so we need to check if the filter
          // has been applied to avoid the default filters overwriting the org filter
          if (!filtersApplied) {
            // Apply the org filter and persist it for the current user session
            sisenseFrame.dashboard.applyFilters(orgFilter, true);
            filtersApplied = true;
          }
        });

        // when a different dashboard is loaded set boolean to false so the org filter is applied again
        sisenseFrame.dashboard.on(
          enums.DashboardEventType.UNLOADED,
          function () {
            filtersApplied = false;
          }
        );
      });
    }

    let script = document.createElement("script");
    script.id = "sisense-embed-script";
    script.src = `${sisenseEnvs.get(envName)}/js/frame.js`;
    script.onload = dashboardLoadedHandler;

    document.body.appendChild(script);
    $scope.$on("$destroy", function () {
      script.parentNode.removeChild(script);
    });
  }
})();
