(function () {
  angular.module("akitabox.desktop.document.detail").run(states);

  /* @ngInject */
  function states(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/document/detail/overview/document-overview.html",
          controller: "DocumentOverviewController",
          controllerAs: "vm",
          data: {
            pageTitle: "File Overview",
          },
        };
      },
      versionHistory: function () {
        return {
          url: "/version-history",
          templateUrl:
            "app/desktop/modules/document/detail/version-history/document-version-history.html",
          controller: "DocumentVersionHistoryController",
          controllerAs: "vm",
          data: {
            pageTitle: "File Version History",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.document.overview",
        config: config.overview(),
      },
      {
        state: "app.building.document.versionHistory",
        config: config.versionHistory(),
      },
      {
        state: "app.document.overview",
        config: config.overview(),
      },
      {
        state: "app.document.versionHistory",
        config: config.versionHistory(),
      },
    ]);
  }
})();
