(function () {
  angular
    .module("akitabox.core.services.inspectionProgram", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.http",
      "akitabox.core.services.timeZone",
    ])
    .factory("InspectionProgramService", InspectionProgramService);

  function InspectionProgramService(
    moment,
    // AkitaBox
    models,
    // Services
    HttpService,
    TimeZoneService
  ) {
    var service = {
      // Utilities
      getFrequencySummary: getFrequencySummary,
      calcDueDates: calcDueDates,
      // Create
      create: create,
      // Retrieve
      getById: getById,
      getByOrganization: getByOrganization,
      getAllByOrganization: getAllByOrganization,
      getChecklistTemplates: getChecklistTemplates,
      getChecklistAssociations: getChecklistAssociations,
      // Update
      activate: activate,
      deactivate: deactivate,
      update: update,
      sparseUpdate: sparseUpdate,
      updateSequence: updateSequence,
    };

    return service;

    function getById(orgId, inspectionProgramId) {
      var route = buildDetailRoute(orgId, inspectionProgramId);
      return HttpService.getById(route, inspectionProgramId);
    }

    /**
     * Update an Inspection Program
     * @param {string} orgId - The ID of the organization that contains the
     *    inspection program
     * @param {string} inspectionProgramId - The ID of the inspection program to
     *    update
     * @param {object} data - The updates to apply to the inspection program
     *
     * @returns {Promise<InspectionProgram>} The updated inspection program
     */
    function update(orgId, inspectionProgramId, data) {
      var augmentedData = angular.extend({}, data, { action: "update" });
      var route = buildDetailRoute(orgId, inspectionProgramId);
      return HttpService.patch(route, augmentedData);
    }

    /**
     * Perform a set of updates on an IP including patching it,
     * adding, or removing associations.
     * See InspectionProgramController for type definitions for the bodies.
     * Available update sequence methods: "sparseUpdate", "insertChecklistAssociations", "removeChecklistAssociations"
     * @param { string } orgId
     * @param { string } inspectionProgramId
     * @param { object[] } sequence
     * @param { {
     *  skipInspectionRegeneration: boolean
     * } } [options]
     * @returns { Promise }
     */
    function updateSequence(orgId, inspectionProgramId, sequence, options) {
      options = options || {};
      var data = {
        action: "updateSequence",
        sequence: sequence,
      };
      if (options.skipInspectionRegeneration === true) {
        data.skip_inspection_regeneration = true;
      }
      var route = buildDetailRoute(orgId, inspectionProgramId);
      return HttpService.patch(route, data);
    }

    /**
     * Update an inspection
     * @param {string} orgId
     * @param {string} inspectionProgramId
     * @param {Object} data
     * @returns
     */
    function sparseUpdate(orgId, inspectionProgramId, data) {
      var augmentedData = angular.extend({}, data, { action: "sparseUpdate" });
      var route = buildDetailRoute(orgId, inspectionProgramId);
      return HttpService.patch(route, augmentedData);
    }

    /**
     * Get an array of inspection programs within an org
     *
     * @param {String} orgId     the org id in which to fetch inspection programs
     * @param {Object} params    an object of query string parameters
     */
    function getByOrganization(orgId, params) {
      var route = buildBaseRoute(orgId);
      return HttpService.get(route, params);
    }

    function getAllByOrganization(orgId, params) {
      var route = buildBaseRoute(orgId);
      return HttpService.getAll(route, params);
    }

    /**
     * Get an array of checklist templates used by the specified inspection program
     * @param { string } orgId - Organization ID in which to fetch
     * @param { string } inspectionProgramId - ID of the inspection program to fetch checklist templates for
     * @param { {[key: string]: string } } params - Query string params
     */
    function getChecklistTemplates(orgId, inspectionProgramId, params) {
      var route =
        buildDetailRoute(orgId, inspectionProgramId) + "/checklist_templates";

      return HttpService.getAll(route, params);
    }

    /**
     * Get an array of inspection stop checklist template associations used by
     * the specified inspection program.
     * @param { string } orgId - Organization ID in which to fetch
     * @param { string } inspectionProgramId - ID of the inspection program
     * @param { {[key: string]: string } } params - Query string params
     */
    function getChecklistAssociations(orgId, inspectionProgramId, params) {
      var route = buildAssociationListRoute(orgId, inspectionProgramId);
      return HttpService.getAll(route, params);
    }

    // -------------------------
    //   Private Utils
    // ------------------------
    function buildBaseRoute(orgId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        orgId +
        "/" +
        models.INSPECTION_PROGRAM.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(orgId, inspectionProgramId) {
      var base = buildBaseRoute(orgId);
      return base + "/" + inspectionProgramId;
    }

    function buildAssociationListRoute(orgId, inspectionProgramId) {
      var base = buildDetailRoute(orgId, inspectionProgramId);
      return base + "/associations";
    }

    // ------------------------
    //   Utilities
    // ------------------------
    function getFrequencySummary(inspectionProgram, utcOffsetInput) {
      // default utcOffset to current offset from TZ service
      var utcOffset;
      if (utcOffsetInput || utcOffsetInput === 0) {
        utcOffset = utcOffsetInput;
      } else {
        utcOffset = TimeZoneService.getCurrentUTCOffset();
      }

      var summary = "Repeats every ";

      // Determine period and interval
      if (inspectionProgram.period === 1) {
        // Use singular period
        summary += inspectionProgram.interval.slice(0, -1).toLowerCase();
      } else {
        summary +=
          inspectionProgram.period +
          " " +
          inspectionProgram.interval.toLowerCase();
      }

      // If weeks, add selected weekdays
      if (inspectionProgram.interval === "weeks") {
        var weekdays = [];
        if (inspectionProgram.is_weekly_sunday) weekdays.push("Sunday");
        if (inspectionProgram.is_weekly_monday) weekdays.push("Monday");
        if (inspectionProgram.is_weekly_tuesday) weekdays.push("Tuesday");
        if (inspectionProgram.is_weekly_wednesday) weekdays.push("Wednesday");
        if (inspectionProgram.is_weekly_thursday) weekdays.push("Thursday");
        if (inspectionProgram.is_weekly_friday) weekdays.push("Friday");
        if (inspectionProgram.is_weekly_saturday) weekdays.push("Saturday");

        switch (weekdays.length) {
          case 1:
            summary += " on " + weekdays[0];
            break;
          case 2:
            summary += " on " + weekdays[0] + " and " + weekdays[1];
            break;
          case 7:
            summary += ", every day ";
            break;
          default:
            var lastDay = weekdays[weekdays.length - 1];
            summary +=
              " on " + weekdays.slice(0, -1).join(", ") + " and " + lastDay;
        }
      }

      // Add start date
      if (utcOffset) {
        summary +=
          " starting " +
          moment(inspectionProgram.start_date)
            .utcOffset(utcOffset)
            .format("MMMM D, YYYY");
      } else {
        summary +=
          " starting " +
          moment.utc(inspectionProgram.start_date).format("MMMM D, YYYY");
      }

      return summary;
    }

    function calcDueDates(inspectionProgram) {
      if (!inspectionProgram) return [];

      var inspectionDueDates = [];

      var startDate = moment.utc(inspectionProgram.start_date);
      var YEAR_FROM_NOW = moment.utc().add(1, "years");
      if (startDate.isLeapYear() && startDate.format("MMDD") === "0209") {
        YEAR_FROM_NOW.subtract(1, "day");
      }

      var maxStartDate = moment.utc(startDate);
      while (maxStartDate.isBefore(YEAR_FROM_NOW)) {
        var plusOneInterval = calcSubsequentStartDate(maxStartDate.toDate());
        maxStartDate = moment.utc(plusOneInterval);
      }

      while (startDate.isSameOrBefore(maxStartDate, "day")) {
        var dueDate = moment
          .utc(startDate)
          .add(inspectionProgram.days_until_due, "days")
          .toDate();
        inspectionDueDates.push(dueDate);
        var nextStartDate = calcSubsequentStartDate(startDate.toDate());
        startDate = moment.utc(nextStartDate);
      }

      return inspectionDueDates;

      function calcSubsequentStartDate(date) {
        var m = moment.utc(date);
        if (inspectionProgram.interval === "weeks") {
          var weekday = m.day();
          weekday++;
          var nextDayThisWeek;

          switch (weekday) {
            // Sunday
            case 0:
              if (inspectionProgram.is_weekly_sunday) {
                nextDayThisWeek = 0;
                break;
              }
            // Monday
            case 1:
              if (inspectionProgram.is_weekly_monday) {
                nextDayThisWeek = 1;
                break;
              }
            // Tuesday
            case 2:
              if (inspectionProgram.is_weekly_tuesday) {
                nextDayThisWeek = 2;
                break;
              }
            // Wednesday
            case 3:
              if (inspectionProgram.is_weekly_wednesday) {
                nextDayThisWeek = 3;
                break;
              }
            // Thursday
            case 4:
              if (inspectionProgram.is_weekly_thursday) {
                nextDayThisWeek = 4;
                break;
              }
            // Friday
            case 5:
              if (inspectionProgram.is_weekly_friday) {
                nextDayThisWeek = 5;
                break;
              }
            // Saturday
            case 6:
              if (inspectionProgram.is_weekly_saturday) {
                nextDayThisWeek = 6;
                break;
              }
          }

          if (nextDayThisWeek > -1) {
            // Move to a later day this week
            m.day(nextDayThisWeek);
          } else {
            // Otherwise increment by <period> weeks
            m.add(inspectionProgram.period, "weeks");
            // Set to the first repeated day of the week
            if (inspectionProgram.is_weekly_sunday) {
              m.day(0);
            } else if (inspectionProgram.is_weekly_monday) {
              m.day(1);
            } else if (inspectionProgram.is_weekly_tuesday) {
              m.day(2);
            } else if (inspectionProgram.is_weekly_wednesday) {
              m.day(3);
            } else if (inspectionProgram.is_weekly_thursday) {
              m.day(4);
            } else if (inspectionProgram.is_weekly_friday) {
              m.day(5);
            } else if (inspectionProgram.is_weekly_saturday) {
              m.day(6);
            }
          }
        } else {
          m.add(inspectionProgram.period, inspectionProgram.interval);
        }
        return m.toDate();
      }
    }

    // ------------------------
    //   Update
    // ------------------------

    function activate(inspectionProgramId, orgId) {
      var data = { action: "activate" };
      var route = buildDetailRoute(orgId, inspectionProgramId);
      return HttpService.put(route, data);
    }

    function deactivate(inspectionProgramId, orgId) {
      var data = { action: "deactivate" };
      var route = buildDetailRoute(orgId, inspectionProgramId);
      return HttpService.put(route, data);
    }

    // ------------------------
    //   Create
    // ------------------------

    function create(orgId, data) {
      var route = buildBaseRoute(orgId);
      return HttpService.post(route, data);
    }
  }
})();
