(function () {
  /**
   * @ngdoc component
   * @name abxYAxisLabel
   *
   * @param {String} text - text to display in the y-axis
   */
  angular
    .module("akitabox.ui.components.reportingCharts.yAxisLabel", [])
    .component("abxYAxisLabel", {
      bindings: {
        text: "<abxText",
      },
      controller: YAxisLabelController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/y-axis-label/y-axis-label.component.html",
    });

  function YAxisLabelController() {}
})();
