(function () {
  angular
    .module("akitabox.ui.dialogs.workOrderLog.createUpdate")
    .factory("WorkOrderLogDialog", WorkOrderLogDialog);

  /* @ngInject */
  function WorkOrderLogDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/work-order-log/create-update/work-order-log.dialog.html",
      controller: "WorkOrderLogDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    function show(options, ga) {
      var locals = options.locals;

      if (locals.workOrder) {
        locals.workOrders = [locals.workOrder];
      }

      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
