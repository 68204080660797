(function () {
  angular.module("akitabox.ui.dialogs.document.select", [
    "ngMaterial",
    "akitabox.core.constants",
    "akitabox.core.services.document",
    "akitabox.core.services.filter",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.document.upload",
  ]);
})();
