(function () {
  angular
    .module("akitabox.ui.components.input.asset", [
      "akitabox.core.services.asset",
      "akitabox.core.services.room",
      "akitabox.core.services.organization",
      "akitabox.core.toast",
      "akitabox.core.utils",
      "akitabox.ui.components.typeAheadInput",
    ])
    .component("abxAsset", {
      bindings: {
        model: "<abxModel",
        value: "<abxValue",
        buildingId: "<abxBuildingId",
        floorId: "<abxFloorId",
        roomId: "<?abxRoomId",
        onChange: "&abxOnChange",
        onSelect: "&abxOnSelect",
        onBlur: "&abxOnBlur",
        onFocus: "&abxOnFocus",
        disabled: "<?abxDisabled",
        required: "<?abxRequired",
        blurOnEnter: "<?abxBlurOnEnter",
        placeholder: "<abxPlaceholder",
      },
      controller: AbxAssetController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/input/components/asset/asset.component.html",
    });

  function AbxAssetController(
    // Angular
    $q,
    // Services
    AssetService,
    PinValueService,
    ToastService,
    OrganizationService,
    Utils
  ) {
    var self = this;

    // Private
    var assetsRequest;

    // Attributes
    self.loading = false;
    self.assetOptions = [];
    self.blurOnEnter = self.blurOnEnter || false;
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.handleFocus = handleFocus;
    self.getAssets = getAssets;

    // =================
    // Life Cycle
    // =================

    /**
     * Handle changes from parent bindings
     *
     * @param {Object}    changes       changes object with currentValue and previousValue for each binding
     */
    self.$onChanges = function (changes) {
      const modelChanged = Utils.hasChanged(changes.model);
      var buildingChanged = Utils.hasChanged(changes.buildingId);
      let modelLevelId = self.model && self.model.level;
      if (modelLevelId && modelLevelId._id) modelLevelId = modelLevelId._id;
      var floorChanged =
        Utils.hasChanged(changes.floorId) &&
        (self.model ? changes.floorId.currentValue !== modelLevelId : true);
      let modelRoomId = self.model && self.model.room;
      if (modelRoomId && modelRoomId._id) modelRoomId = modelRoomId._id;
      var roomChanged =
        Utils.hasChanged(changes.roomId) &&
        (self.model ? changes.roomId.currentValue !== modelRoomId : true);
      // On building/floor/room change, make the next focus do a fresh rooms request
      if (buildingChanged || floorChanged || roomChanged) {
        assetsRequest = undefined;
        if (self.model && !modelChanged) {
          getAssetValues(self.model).then(function (values) {
            self.model.values = values;
            var buildingId = self.model.building;
            const floorId = self.model?.level?._id;
            var roomId = self.model.room
              ? self.model.room._id
              : PinValueService.getRoomId(self.model.values);
            var diffBuilding =
              buildingChanged && !Utils.isSame(buildingId, self.buildingId);
            var diffFloor =
              floorChanged && !Utils.isSame(floorId, self.floorId);
            var diffRoom = roomChanged && !Utils.isSame(roomId, self.roomId);
            // If model's building, floor, or room is different,
            // clear out input and trigger changes
            if (diffBuilding || diffFloor || diffRoom) {
              self.assetOptions = [];
              var $event = {
                model: null,
                value: null,
              };
              self.onChange({ $event: $event });
            }
          });
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Fetch the assets for this input's given floor.
     *
     * @param {object} [$event]
     * @param {boolean} [$event.noLimit]
     * @param {string} [$event.value]
     *
     * @return {Promise} - Resolves hen assets have been fetched and parsed
     */
    function getAssets($event) {
      // set event default to empty objects to avoid errors
      !$event && ($event = {});

      var params = {
        building: self.buildingId,
        level: self.floorId,
      };

      // asset only have name. It doesn't have a number to use it as a filter.
      $event.value && (params.$or = `name=${$event.value}`);

      if (self.roomId) {
        params.room = self.roomId;
      }

      var request = $event.noLimit
        ? AssetService.getAllBFF(
            self.buildingId,
            params,
            {},
            self.organization._id
          )
        : AssetService.getBFF(
            self.buildingId,
            params,
            {},
            self.organization._id
          );

      self.loading = true;
      return request
        .then(function (assets) {
          if (angular.isEmpty(assets)) {
            self.assetOptions = [];
            return;
          }
          self.assetOptions = assets.map(function (asset) {
            return {
              model: asset,
              value: asset.name,
            };
          });
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.assetOptions = [];
        })
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Handle input focus
     */
    function handleFocus() {
      self.onFocus();
      if (!assetsRequest) {
        assetsRequest = getAssets();
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * Get asset pin values
     *
     * @param {Object} asset  Asset to get pin values for
     *
     * @return {Promise<Object|Error>} Resolves with pin values object
     */
    function getAssetValues(asset) {
      const building = asset.building
        ? asset.building._id || asset.building
        : self.buildingId;
      if (!building) return $q.resolve({});
      return $q(function (resolve) {
        if (asset) {
          if (asset.values) {
            resolve(asset.values);
          } else {
            resolve(AssetService.getAllValues(building, asset._id));
          }
        } else {
          resolve({});
        }
      });
    }
  }
})();
