(function () {
  angular
    .module("akitabox.desktop.request.detail")
    .controller("RequestAssetTabController", RequestAssetTabController);

  /* @ngInject */
  function RequestAssetTabController(
    building,
    request,
    AssetService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.building = building;
    self.request = request;
    self.asset = null;

    init();

    function init() {
      var assetId = request.asset._id;
      var params = { include_values: true };
      var options = {
        includePinType: true,
        includeFloor: true,
        includeRoom: true,
      };
      AssetService.getById(building._id, assetId, params, options)
        .then(function (asset) {
          self.asset = asset;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }
  }
})();
