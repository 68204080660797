(function () {
  angular
    .module("akitabox.ui.dialogs.qrCode.manualEntry")
    .factory("QrManualEntryDialog", QrManualEntryDialog);
  function QrManualEntryDialog($mdDialog, $mdMedia) {
    var path = "app/core/ui/dialogs/qr-code/manual-entry";
    var modal = {
      templateUrl: path + "/qr-manual-entry.dialog.html",
      controller: "QrManualEntryDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @return {Promise<String>} Promise that resolves with a qr code model's
     *    id. Rejects if dialog is cancelled.
     */
    function show(options) {
      if (!options) {
        options = {};
      }

      var dialogOptions = angular.extend({}, modal, options);

      return $mdDialog.show(dialogOptions);
    }
  }
})();
