(function () {
  angular
    .module("akitabox.ui.dialogs.workOrderLog.createUpdate")
    .controller("WorkOrderLogDialogController", WorkOrderLogDialogController);

  /* @ngInject */
  function WorkOrderLogDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    WorkOrderLogService,
    ToastService,
    IdentityService,
    UserService,
    OrganizationService
  ) {
    var self = this;

    var _permissions = UserService.getPermissions();
    self.organization = OrganizationService.getCurrent();
    // Attributes
    self.formData = {};
    self.saving = false;
    self.errors = [];
    self.showErrorPage = false;
    self.showAttachments = false;
    self.buildingId = null; // Only used when logging work for single work order

    // Functions
    self.cancel = $mdDialog.cancel;
    self.save = save;
    self.handleFilesChange = handleFilesChange;
    self.isDisabled = isDisabled;
    self.attachments = [];
    self.getCurrentUser = getCurrentUser;

    init();

    function init() {
      if (self.workOrders && self.workOrders.length === 1) {
        self.showAttachments = _permissions.task.add_attachment;
        self.buildingId = getBuildingId(self.workOrders[0]);
      }
      if (self.workOrderLog) {
        self.editing = true;
        self.formData = self.workOrderLog;
      } else {
        // default work_performed_by to current user
        getCurrentUser();
        // default the date to the current date
        self.formData.work_performed_date = new Date();
      }
      self.requireTimeCode = self.organization.require_time_codes;
      self.formData.timeCode = self.timeCode;
    }

    function handleFilesChange(files) {
      self.attachments = files;
    }

    function save() {
      if (self.editing) {
        update();
      } else {
        create();
      }
    }

    function getCurrentUser() {
      IdentityService.getCurrent().then(function (user) {
        // default the form to show the current user for work performed by
        self.formData.work_performed_by = user;
        return;
      });
    }

    /**
     * returns a model's building _id, meant to account for the possibility of population
     * @param {Task | WorkOrderLog} model
     */
    function getBuildingId(model) {
      return Object.prototype.hasOwnProperty.call(model.building, "_id")
        ? model.building._id
        : model.building;
    }

    /**
     * Determine if the form is disabled
     *
     * @return {Boolean}  True if disabled, false if not
     */
    function isDisabled() {
      return (
        self.saving ||
        !self.formData.work_performed_by ||
        !self.formData.work_performed_date ||
        !self.formData.work_minutes ||
        !self.formData.work_minutes > 0 ||
        self.formData.cost < 0 ||
        !self.formData.actions_taken ||
        (self.requireTimeCode && !self.formData.timeCodeSelected) ||
        !allUploadsComplete()
      );
    }

    function allUploadsComplete() {
      return self.attachments.reduce(function (prev, current) {
        return prev && current.complete;
      }, true);
    }

    /**
     * Create a new work order log
     */
    function create() {
      if (self.saving) return;
      self.saving = true;

      // get work_performed_by id if it is an object
      if (
        self.formData.work_performed_by &&
        self.formData.work_performed_by._id
      ) {
        self.formData.work_performed_by = self.formData.work_performed_by._id;
      }

      if (
        self.formData.timeCodeSelected &&
        self.formData.timeCodeSelected._id
      ) {
        self.formData.time_code = self.formData.timeCodeSelected._id;
      }

      if (self.attachments.length > 0) {
        self.formData.attachments = self.attachments.map(function (attachment) {
          return attachment._document._id;
        });
      }

      var createdLogs = [];
      var creationPromises = self.workOrders.map(function (workOrder) {
        var formData = Object.assign({}, self.formData);
        formData.task = workOrder._id;
        var buildingId = getBuildingId(workOrder);
        return WorkOrderLogService.create(buildingId, formData)
          .then(function (workOrderLog) {
            createdLogs.push(workOrderLog);
          })
          .catch(function (err) {
            handleError(err, workOrder);
          });
      });

      $q.all(creationPromises).finally(function () {
        self.showErrorPage = !!self.errors.length;
        self.saving = false;
        if (!self.showErrorPage) {
          $mdDialog.hide(createdLogs);
        }
      });
    }

    function handleError(err, workOrder) {
      var message = "Unknown error occurred";
      if (err.data && err.data.error) {
        message = err.data.error.message;
      } else if (err.statusText) {
        message = err.statusText;
      }
      self.errors.push({
        task: workOrder,
        message: message,
      });
    }

    /**
     * Updates an existing work order log
     */
    function update() {
      if (self.saving) return;
      self.saving = true;

      // set work_performed_by to the id
      if (self.formData.work_performed_by) {
        self.formData.work_performed_by = self.formData.work_performed_by._id;
      }
      if (self.formData.timeCodeSelected) {
        self.formData.time_code = self.formData.timeCodeSelected._id;
      }
      WorkOrderLogService.update(
        getBuildingId(self.workOrderLog),
        self.workOrderLog._id,
        self.formData
      )
        .then(function (workOrderLog) {
          $mdDialog.hide(workOrderLog);
          ToastService.showSimple("Successfully updated work order log");
        })
        .catch(function (err) {
          ToastService.showError(err);
        })
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
