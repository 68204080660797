(function () {
  angular.module("akitabox.ui.dialogs.schedule.create", [
    "angular.filter",
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.flag",
    "akitabox.core.services.organization",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.room",
    "akitabox.core.services.schedule",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.trade",
    "akitabox.core.services.user",
    "akitabox.ui.components.multipleAssigneeInput",
  ]);
})();
