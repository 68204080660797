(function () {
  angular
    .module("akitabox.core.services.floor", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("FloorService", FloorService);

  /** @ngInject */
  function FloorService(
    // Constants
    models,
    // Services
    HttpService,
    // Third-party
    CacheFactory
  ) {
    var cache;
    var buildingIdUsedForCache;

    init();

    function init() {
      cache = CacheFactory.get("floors");
      if (!cache) {
        cache = CacheFactory("floors");
      }
    }

    var service = {
      // Routes
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      buildDetailRoute: buildDetailRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getById: getById,
      getAll: getAll,
      getByOrganization: getByOrganization,
      getAllByOrganization: getAllByOrganization,
      // Update
      update: update,
      updateDocument: updateDocument,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function buildBaseRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.FLOOR.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, floorId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + floorId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        "/" +
        models.FLOOR.ROUTE_PLURAL
      );
    }

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildListRoute(buildingId);
      return HttpService.post(route, data, undefined, undefined, cache);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params, cache);
    }

    function getAll(buildingId, params, useCache) {
      if (buildingId !== buildingIdUsedForCache) {
        cache.removeAll();
      }

      var route = buildListRoute(buildingId);

      if (useCache === false) {
        return HttpService.getAll(route, params, null);
      } else {
        return HttpService.getAll(route, params, cache).then(function (floors) {
          buildingIdUsedForCache = buildingId;

          return floors;
        });
      }
    }

    function getByOrganization(organizationId, params) {
      cache.removeAll();
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params, cache);
    }

    function getAllByOrganization(organizationId, params) {
      cache.removeAll();
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, id, params) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params, cache);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, id, data) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.put(route, data, undefined, cache);
    }

    function updateDocument(buildingId, id, documentId, optionalData) {
      var data = {
        action: "updateDocument",
        document: documentId,
      };
      if (!angular.isEmpty(optionalData)) {
        data = angular.extend({}, optionalData, data);
      }
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data, undefined, cache);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, id) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.remove(route, id, cache);
    }
  }
})();
