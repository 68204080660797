import { IState, IStateService } from "angular-ui-router";

/**
 * Get the full URL pathname for a given angularjs state
 * @param angularState The angular state
 * @param $state The angularjs $state service
 * @returns The full pathname for the given state. For example,
 *  providing the `app.building.detail` route would return
 *  `/buildings/:buildingId`
 */
export function getRouteFromAngularState(
  angularState: IState | undefined,
  $state: IStateService
): string {
  // base case, top-level route
  if (!angularState || angularState.url === "^") {
    return "";
  } else {
    if (!angularState.url) {
      return getRouteFromAngularState(
        getParentState(angularState, $state),
        $state
      );
    }
    if (typeof angularState.url !== "string") {
      // the types say `url` can be an IURLMatcher. This doesn't happen in practice
      // right now.
      throw new Error("Unexpected state encountered during router setup");
    }

    return (
      getRouteFromAngularState(getParentState(angularState, $state), $state) +
      angularState.url.split("?")[0]
    );
  }
}

/**
 * Get parent ui-router state from a particular state.
 * @param angularState The child angular state
 * @param $state The angularjs $state service
 * @returns The parent ui-router state if one exists.
 */
function getParentState(
  angularState: IState,
  $state: IStateService
): IState | undefined {
  return $state.get("^", angularState);
}
