(function () {
  /**
   * @ngdoc component
   * @name abxBannerMessages
   *
   * @param {Array} bannerMessages - Array of banner message objects
   *
   * @description
   * A component to be display banner messages above the app header
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .component("abxBannerMessages", {
      bindings: {
        bannerMessages: "<?abxBannerMessages",
      },
      controller: AbxBannerMessagesController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/components/banner-messages/banner-messages.component.html",
    });
  /** @ngInject */
  function AbxBannerMessagesController(markdown) {
    var self = this;

    self.$onInit = $onInit;
    var md;
    function $onInit() {
      md = new markdown({
        html: true,
        linkify: true,
        typographer: true,
        breaks: true,
      });
    }

    self.getMessage = getMessage;

    // =================
    // Public Functions
    // =================

    function getMessage(bannerMessage) {
      return md.render(bannerMessage.message);
    }
  }
})();
