(function () {
  angular
    .module("akitabox.ui.dialogs.floor.create")
    .service("CreateFloorDialog", CreateFloorDialog);

  /* @ngInject */
  function CreateFloorDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/floor/create/create-floor.dialog.html",
      controller: "CreateFloorDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * CreateFloorDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.building` is required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error>}               Promise that resolves with the newly created floor
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
