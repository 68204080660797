(function () {
  angular
    .module("akitabox.planView.dialogs.skipStop")
    .service("SkipStopDialog", SkipStopDialog);

  /* @ngInject */
  function SkipStopDialog($mdDialog, $q) {
    var modal = {
      templateUrl:
        "app/desktop/modules/plan-view/dialogs/skip-stop/skip-stop.dialog.html",
      controller: "SkipStopDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: true,
    };

    var service = {
      show: show,
    };

    return service;

    // ===== PUBLIC FUNCTIONS ========================================

    /**
     *
     * @param {{}} options
     * @param {{}} options.locals
     * @param {Checklist} options.locals.checklist
     * @param {String} [options.locals.reasonId]
     * @param {String} [options.locals.reasonText]
     * @return {Promise<void|Error>}
     */
    function show(options) {
      if (!options) options = {};
      if (!options.locals) options.locals = {};
      if (!options.locals.checklists)
        return $q.reject(new Error("options.locals.checklists is required"));
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
