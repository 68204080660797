(function () {
  angular.module("akitabox.planView").factory("PlanViewUtils", PlanViewUtils);

  /** @ngInject */
  function PlanViewUtils(
    // Angular
    $filter,
    $q,
    // Helpers
    Utils,
    // Services
    RoomService
  ) {
    var service = {
      getPinTypeSubfilters: getPinTypeSubfilters,
    };

    return service;

    // =================
    // Public Functions
    // =================

    /**
     * Get a list of subfilters for the given pin type filter. Helpful to
     * display the subfilters in a user-friendly format.
     *
     * @param {Object} pinTypeFilter - Entire filter for the pin type
     * @param {Object[]} [pinTypeFilter.pin_fields] - Filters for the pin type's
     *     pin fields
     * @param {String} [pinTypeFilter.name] - Text to subfilter on the pins of
     *     the given pin type's name
     * @return {Object[]} - List of subfilters. Will be of form:
     *     { fieldAlias, valueText }.
     */
    function getPinTypeSubfilters(pinTypeFilter, pinType) {
      var pinFieldFilters = pinTypeFilter.pin_fields || [];
      var pinFieldSubfilterRequests = [];
      pinFieldFilters.forEach(function (pinFieldFilter) {
        var pinField = Utils.findModelById(pinType.fields, pinFieldFilter._id);

        var request = $q
          .resolve(getPinFieldFilterValueText(pinFieldFilter, pinField))
          .then(function (valueText) {
            var fieldAlias = pinField.name;
            if (fieldAlias === "Room Number") {
              fieldAlias = "Room";
            }

            var subfilter = {
              fieldAlias: fieldAlias,
              valueText: valueText,
              pinField: pinField,
            };
            return subfilter;
          });
        pinFieldSubfilterRequests.push(request);
      });

      return $q
        .all(pinFieldSubfilterRequests)
        .then(function (pinFieldSubfilters) {
          var subfilters = pinFieldSubfilters;

          if (pinTypeFilter.name) {
            var nameSubfilter = getNameSubfilter(pinTypeFilter.name, pinType);
            subfilters.push(nameSubfilter);
          }

          if (pinTypeFilter.number) {
            var numberSubfilter = getNumberSubfilter(
              pinTypeFilter.number,
              pinType
            );
            subfilters.push(numberSubfilter);
          }

          return subfilters;
        });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Get the display text for the given date filter
     *
     * @param {Object} filter - Date filter
     * @return {String} - Display text for the given filter
     */
    function getDateFilterValueText(filter) {
      var minText = $filter("date")(filter.start, "MM/dd/yyyy");
      var maxText = $filter("date")(filter.end, "MM/dd/yyyy");

      return getRangeText(minText, maxText);
    }

    /**
     * Get the subfilter for a name field
     *
     * @param {String} filterValue - Value for the name subfilter
     * @param {Object} pinType - Pin type the name subfilter is to be
     *     responsible for
     * @return {Object} - Name subfilter
     */
    function getNameSubfilter(filterValue, pinType) {
      var PIN_CONSTANTS = Utils.getPinConstants(pinType.protected_type);
      return {
        fieldAlias: PIN_CONSTANTS.FIELDS.NAME,
        valueText: filterValue,
        attribute: "name",
      };
    }

    /**
     * Get the subfilter for a number field
     *
     * @param {String} filterValue - Value for the number subfilter
     * @param {Object} pinType - Pin type the number subfilter is to be
     *     responsible for
     * @return {Object} - Name subfilter
     */
    function getNumberSubfilter(filterValue, pinType) {
      var PIN_CONSTANTS = Utils.getPinConstants(pinType.protected_type);
      return {
        fieldAlias: PIN_CONSTANTS.FIELDS.NUMBER,
        valueText: filterValue,
        attribute: "number",
      };
    }

    /**
     * Get the display value text to display for the given pin field filter
     *
     * @param {Object} filter - Pin field filter
     * @param {Object} pinField - Pin field model the filter is for
     * @return {String} - Human-readable value text for the filter
     */
    function getPinFieldFilterValueText(filter, pinField) {
      switch (pinField.data_type) {
        case "float":
        case "int":
          return getRangeText(filter.start, filter.end);

        case "date":
          return getDateFilterValueText(filter);

        case "boolean":
          if (filter.value === "") {
            return "not set";
          }
          return Utils.getBooleanAlias(filter.value);

        case "room":
          if (filter.valueAlias) {
            return filter.valueAlias;
          }
          var roomId = filter.value;
          return RoomService.getById(pinField.building, roomId).then(function (
            room
          ) {
            return room.number;
          });

        default:
          return filter.value;
      }
    }

    /**
     * Get the display value text for the given minimum and maximum values
     *
     * @param {Number} [min] - Minimum for the range
     * @param {Number} [max] - Maximum for the range
     * @return {String} - Formatted text for the range
     */
    function getRangeText(min, max) {
      var hasMin = !angular.isEmpty(min);
      var hasMax = !angular.isEmpty(max);

      if (hasMin && hasMax) {
        // Don't need to show a range with two equal values
        if (min === max) {
          return String(min);
        } else {
          return min + " - " + max;
        }
      } else if (hasMin) {
        return "≥ " + min;
      } else if (hasMax) {
        return "≤ " + max;
      }
    }
  }
})();
