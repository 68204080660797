(function () {
  angular.module("akitabox.ui.components.assetStats", [
    "akitabox.core.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.pin",
    "akitabox.core.services.pinType",
    "akitabox.core.toast",
    "akitabox.ui.directives.donutChart",
  ]);
})();
