(function () {
  angular.module("akitabox.desktop.building").run(stateBlock);

  /**
   * @ngInject
   */
  function stateBlock(Router) {
    Router.configureStates(getStates());
  }

  function getStates() {
    return [
      {
        state: "app.building",
        config: {
          abstract: true,
          url: "/buildings/:buildingId",
          templateUrl: "app/desktop/modules/building/building.html",
          controller: "BuildingController",
          controllerAs: "vm",
          resolve: {
            building: getBuilding,
            initializeCaches: initializeCaches,
          },
        },
      },
      {
        state: "app.building.detail",
        config: {
          url: "",
          templateUrl:
            "app/desktop/modules/building/detail/building-detail.html",
          controller: "BuildingDetailController",
          controllerAs: "vm",
        },
      },
    ];
  }

  /**
   * @ngInject
   */
  function getBuilding(
    $rootScope,
    $stateParams,
    organization,
    BuildingService,
    CacheFactory,
    OrganizationService,
    RedirectService,
    ToastService
  ) {
    CacheFactory.clearAll();
    return BuildingService.getById($stateParams.buildingId)
      .then(function (building) {
        $rootScope.$broadcast("building:change", building);
        BuildingService.setCurrent(building);
        if (organization && organization._id === building.organization) {
          return building;
        }
        return OrganizationService.redirectToBuildingOrg(building);
      })
      .catch(ToastService.showError);
  }

  /**
   * Initialize certain caches on page load for a given building. Calling each
   * service's `getAll` will automatically take care of putting the fetched
   * results in each entity's respective cache.
   *
   * @ngInject
   */
  function initializeCaches(
    // Resolves
    account,
    building,
    // Services
    FloorService,
    PinTypeService,
    ToastService
  ) {
    FloorService.getAll(building._id).catch(ToastService.showError);
    PinTypeService.getAll(building._id).catch(ToastService.showError);
  }
})();
