(function () {
  angular.module("akitabox.desktop.room").run(states);

  /* @ngInject */
  function states(models, Router) {
    var LIST_QUERY_PARAMS = models.ROOM.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.rooms",
        config: {
          url: "/" + models.ROOM.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl: "app/desktop/modules/room/building-list/room-list.html",
          controller: "RoomListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Rooms",
          },
          reloadOnSearch: true,
          resolve: {
            decommissioned: getDecommissioned,
          },
        },
      },
      {
        state: "app.building.room",
        config: {
          url: "/" + models.ROOM.ROUTE_PLURAL + "/:roomId",
          templateUrl: "app/desktop/modules/room/detail/room-detail.html",
          controller: "RoomDetailController",
          controllerAs: "vm",
          resolve: {
            room: getRoom,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.rooms",
        config: {
          url: "/" + models.ROOM.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/room/organization-list/org-room-list.html",
          controller: "OrganizationRoomListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Rooms",
          },
          reloadOnSearch: true,
          resolve: {
            decommissioned: getDecommissioned,
          },
        },
      },
      {
        state: "app.room",
        config: {
          url: "/" + models.ROOM.ROUTE_PLURAL + "/:roomId",
          templateUrl: "app/desktop/modules/room/detail/room-detail.html",
          controller: "RoomDetailController",
          controllerAs: "vm",
          resolve: {
            room: getOrganizationRoom,
            building: getBuilding,
            redirect: redirect,
          },
        },
      },
    ]);
  }

  /** @ngInject */
  function getOrganizationRoom(
    $stateParams,
    account,
    organization,
    RoomService
  ) {
    var params = {
      include_values: true,
      _id: $stateParams.roomId,
    };
    return RoomService.getByOrganization(organization._id, params)
      .then(function (room) {
        return RoomService.populatePinType(room);
      })
      .then(function (room) {
        return RoomService.populateFloor(room);
      });
  }

  /** @ngInject */
  function getBuilding($stateParams, account, room, BuildingService) {
    return BuildingService.getById(room.building);
  }

  /** @ngInject */
  function getRoom($stateParams, account, building, RoomService) {
    var params = {
      include_values: true,
    };
    var options = {
      includePinType: true,
      includeFloor: true,
    };
    return RoomService.getById(
      building._id,
      $stateParams.roomId,
      params,
      options
    );
  }

  /** @ngInject */
  function redirect(
    $stateParams,
    $state,
    account,
    organization,
    building,
    room,
    RedirectService
  ) {
    var destination = RedirectService.getDestination();
    var roomTab = organization.show_tasks ? "overview" : "details";
    if (
      destination.match(
        /^.*\/(closed-work-orders|open-work-orders|maintenance-schedules)$/
      )
    ) {
      if (!organization.show_tasks) {
        return $state.go("root.404");
      }
    } else if (
      destination.match(/^\/([a-zA-Z0-9_]*\/){2}rooms\/[a-zA-Z0-9]+$/)
    ) {
      return $state.go("app.building.room." + roomTab, $stateParams);
    } else if (destination.match(/\/rooms\/[a-zA-Z0-9]+$/)) {
      return $state.go("app.room." + roomTab, $stateParams);
    }
  }

  /* @ngInject */
  function getDecommissioned($stateParams) {
    return $stateParams.decommissioned || false;
  }
})();
