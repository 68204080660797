import type { Theme } from "@emotion/react";
import { Box, SxProps } from "@mui/material";
import { FunctionComponent, memo } from "react";
import type { CSSObject } from "@emotion/styled";
import {
  GridColDef,
  GridRowModel,
  DataGridPro as DataGrid,
  DataGridProProps as DataGridProps,
} from "@mui/x-data-grid-pro";

import { Color } from "../../colors";
import { stylesheet } from "../../stylesheet";

export const AbxDataGridTestids = { Root: "AbxDataGridRoot" } as const;

export type AbxGridColDef<AbxGridRowModel extends GridRowModel = any> =
  GridColDef<AbxGridRowModel> & {
    field: keyof AbxGridRowModel | "actions";
    /** ABX custom property used to merge grid col definitions */
    abxGridColType: "basic" | "action";
  };

export interface AbxDataGridProps extends DataGridProps {
  columns: AbxGridColDef[];
  /** Useful for list components in main `ListView` page */
  forceMinHeight?: boolean;
  /** CSS `minHeight` to be set on the `DataGrid` wrapper */
  minHeight?: CSSObject["minHeight"];
  /** CSS fixed `height` to be set on the `DataGrid` wrapper */
  height?: CSSObject["height"];
}

/** Grid col definition for any `Basic` text column  */
const basicGridCol: Omit<GridColDef, "field"> = {
  flex: 1,
  align: "left",
  resizable: true,
  headerAlign: "left",
};

/** Grid col definition for the `Actions` column */
const actionGridCol: Omit<GridColDef, "field"> = {
  ...basicGridCol,
  maxWidth: 145,
  align: "center",
  hideable: false,
  sortable: false,
  groupable: false,
  filterable: false,
  disableExport: true,
  disableReorder: true,
  headerName: "Actions",
  headerAlign: "center",
  disableColumnMenu: true,
};

const CustomDataGrid: FunctionComponent<AbxDataGridProps> = ({
  forceMinHeight,
  minHeight,
  height,
  ...props
}) => {
  const dataGridProps: DataGridProps = {
    ...props,
    columns: props.columns.map((col) => {
      const { abxGridColType, ...rest } = col;

      switch (abxGridColType) {
        case "basic":
          return { ...basicGridCol, ...rest };
        case "action":
          return { ...actionGridCol, ...rest };
      }
    }),
  };

  let sx: SxProps<Theme> | undefined;
  if (minHeight) {
    sx = { minHeight };
  } else if (forceMinHeight) {
    // arbitrary number that makes list fits well in `ListView` page
    sx = { minHeight: "630px" };
  }

  return (
    <Box css={[ss.dataGridWrapper, { height }]} sx={sx}>
      <DataGrid
        {...dataGridProps}
        getRowId={(row) => row._id ?? row.id}
        data-testid={AbxDataGridTestids.Root}
      />
    </Box>
  );
};

const ss = stylesheet({
  // overrides mui default styles in specific places of the grid
  dataGridWrapper: {
    backgroundColor: Color.White,
    "& .MuiDataGrid-cell": { outline: "none !important" },
    "& .MuiDataGrid-footerContainer p": { marginBottom: 0 },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: `#F5F5F5 !important`,
    },
    "& .MuiDataGrid-columnHeadersInner": {
      "> :first-of-type .MuiDataGrid-columnHeader": {
        border: "none !important",
        outline: "none !important",
        textTransform: "uppercase",
        "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold !important" },
      },
      "> .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader": {
        border: "none !important",
        outline: "none !important",
        "& .MuiFormControl-root": { flex: "1 0 auto" },
      },
    },
  },
});

/**
 * Wrapper around MUI `DataGrid` component that provides the actual
 * data grid with abx styles and basic functionalities as well as
 * the capability to fully customize/override its default definitions
 *
 * @param props - Extended version of `DataGridProps` from MUI
 * @returns `<AbxDataGrid />` function component
 */
export const AbxDataGrid = memo(CustomDataGrid);
