(function () {
  angular.module("akitabox.desktop.room", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.core.services.floor",
    "akitabox.core.services.room",
    "akitabox.desktop.room.detail",
    "akitabox.desktop.room.list",
    "akitabox.desktop.room.organizationList",
  ]);
})();
