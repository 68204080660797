(function () {
  angular
    .module("akitabox.core.lib.leaflet", [])
    .factory("Leaflet", LeafletService);

  /**
   * Loads leaflet.js into the Angular environment
   *
   * @ngInject
   */
  function LeafletService($window) {
    if (!angular.isDefined($window.L))
      throw new Error("Leaflet is not available");
    return $window.L;
  }
})();
