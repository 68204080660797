(function () {
  angular.module("akitabox.desktop.asset.detail", [
    "ui.router",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.workOrdersBff",
    "akitabox.core.toast",
    "akitabox.ui.components.decommissionedHeader",
    "akitabox.ui.dialogs.delete",
    "akitabox.ui.dialogs.asset.create",
    "akitabox.ui.dialogs.asset.delete",
    "akitabox.ui.dialogs.decommission",
    "akitabox.ui.dialogs.document.download",
    "akitabox.ui.dialogs.editLocation",
    "akitabox.ui.dialogs.filePreview",
    "akitabox.ui.dialogs.request.create",
    "akitabox.ui.dialogs.schedule.create",
    "akitabox.ui.dialogs.workOrder.create",
    "akitabox.ui.dialogs.export",
    "akitabox.desktop.components.relatedWorkOrderCard",
  ]);
})();
