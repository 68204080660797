import { createContext, useContext } from "react";

export type ListContextType = {
  refreshList: boolean;
  setRefreshList: (value: boolean) => void;
};

export const ListContext = createContext<ListContextType | undefined>(
  undefined
);

export const useListContext = () => {
  const context = useContext(ListContext);
  if (!context) {
    throw new Error("useListContext must be used within an List Provider");
  }
  return context;
};
