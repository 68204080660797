(function () {
  angular
    .module("akitabox.ui.dialogs.document.editTagFilter")
    .factory("EditTagFilterDialog", EditTagFilterDialog);

  /* @ngInject */
  function EditTagFilterDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/document/edit-tag-filter/edit-tag-filter.dialog.html",
      controller: "EditTagFilterDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.building` is required
     *
     * @param  {Object}  options                      Dialog options
     * @param  {Object}  options.locals               Dialog local variables
     * @param  {Object}  options.locals.building      A building model
     * @param  {Array}   options.locals.filters       A set of tag filters (FilterService.tagConfig())
     * @param  {Object}  options.locals.pin           An pin model
     * @param  {Object}  options.locals.isRoom        Given pin is a room
     * @param  {Object}  options.locals.isAsset       Given pin is an asset
     * @param  {Object}  options.locals.pinValue      A pin value object of type tag_filter
     * @param  {Boolean} options.locals.skipPinValueUpdate - Don't make API
     *     request on edit. Instead, send the new value back to the calling
     *     controller.
     *
     * @return  {Promise<Object|Error>}  Promise that resolves with the tag filter dialog
     */
    function show(options) {
      // Verify we have a building local
      if (!options || !options.locals || !options.locals.building) {
        $log.error("EditTagFilterDialog: no building local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options || !options.locals || !options.locals.filters) {
        $log.error("EditTagFilterDialog: no filters local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options || !options.locals || !options.locals.pin) {
        $log.error("EditTagFilterDialog: no pin local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (!options || !options.locals || !options.locals.pinValue) {
        $log.error("EditTagFilterDialog: no pinValue local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      } else if (
        !options ||
        !options.locals ||
        (options.locals.isAsset && options.locals.isRoom) ||
        (!options.locals.isAsset && !options.locals.isRoom)
      ) {
        $log.error(
          "EditTagFilterDialog: Only one of " + "isAsset and isRoom can be true"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }

      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
