(function () {
  angular
    .module("akitabox.core.services.recentActivity")
    .factory("RecentActivityFeed", RecentActivityFeedClassFactory);

  /** @ngInject */
  function RecentActivityFeedClassFactory($q, CachedActivityList) {
    /**
     * @callback FetchCallback
     * Callback invoked to fetch additional items for the activity feed.
     * @param { string } key - The acitivty type/cache key to fetch
     * @param { Object } fetchOptions - Timestamp cutoffs for fetching options
     * @param { Date } fetchOptions.newerThan - If set, only fetch items newer than
     *  the specified date
     * @param { Date } fetchOptions.olderThan - If set, only fetch items older than
     *  the specified date.
     * @param { number } fetchOptions.skip - For pagination
     * @return { Promise<Object[]> } Should return a promise which resolves with
     *  activity objects. They must have their activityType and time fields set.
     *  Should fetch one limit's-worth of items, starting after the specified
     *  timestamp. If oldestTime is null, should fetch the first limit's-worth
     *  of items.
     */

    /**
     * @class RecentActivityFeed
     * Class that facilitates maintaining a lazily-loaded queue of activity items.
     * Provides utilities for paginated access.
     * @param { string[] } keys - The activity types/cache keys to use.
     * @param { number } limit - The expected (maximum) number of results to fetch
     *    at any one time per cache key. Also controls the size of returned pages
     * @param { FetchCallback } fetchFn - Function that should
     *  fetch an additional page of results for the provided cache key.
     */
    function RecentActivityFeedClass(keys, limit, fetchFn) {
      var self = this;
      /** @type { Object.<string, CachedActivityList> } */
      this.cachedResults = {};

      // initialize empty caches for each activity type
      keys.forEach(function (key) {
        self.cachedResults[key] = new CachedActivityList();
      });

      /** @type { string[] } */
      this.keys = keys;

      /** @type { function } */
      this.fetchFn = fetchFn;

      /** @type { number } */
      this.limit = limit;

      /** @type { Date } */
      this.newestFetchedTime = null;
    }

    /**
     * @method
     * Perform an initial fetch for all activity types. Does not reset
     * any existing data, so not capable of being used as a reset.
     *
     * @return { Promise<void> } A promise that resolves when the feed is ready
     *  to be read from.
     */
    RecentActivityFeedClass.prototype.init = function init() {
      var self = this;
      return $q
        .all(
          self.keys.map(function (key) {
            return self._fetch(key);
          })
        )
        .then(function () {
          return;
        });
    };

    /**
     * @method
     * @return { boolean } true if there are any more possible results to load.
     */
    RecentActivityFeedClass.prototype.hasMore = function hasMore() {
      var self = this;
      // a list has more if it has any items in it still, or its hasMore flag is true
      return self.keys
        .map(function (key) {
          return (
            self.cachedResults[key].hasMore ||
            self.cachedResults[key].items.length
          );
        })
        .some(function (hasMore) {
          return hasMore;
        });
    };

    /**
     * Load all items from before this list's first fetch. May result in
     * more than the normal limit being returned.
     */
    RecentActivityFeedClass.prototype.loadNewItems = function loadNewItems() {
      var self = this;
      var fetchCutoff = self.newestFetchedTime;

      return $q.all(self.keys.map(fetchAll)).then(function (resultSets) {
        var results = [];
        resultSets.forEach(function (resultSet) {
          if (resultSet.length === 0) return;
          results = results.concat(resultSet);
        });

        return results.sort(function (a, b) {
          var aTime = a.time;
          var bTime = b.time;
          if (aTime < bTime) return 1;
          if (aTime.getTime() === bTime.getTime()) return 0;
          return -1;
        });
      });

      /**
       * Fetch all new items for the provided key
       * @param { string } key
       */
      function fetchAll(key) {
        var allResults = [];

        return self
          .fetchFn(key, { newerThan: fetchCutoff })
          .then(handleResultPage);

        /**
         * Handle a single page of results. Recursively handles pages until
         * nothing new is left
         * @param { Object } fetchResult
         * @param { Object[] } fetchResult.items
         */
        function handleResultPage(fetchResult) {
          var page = fetchResult.items;
          for (var i = 0; i < page.length; i++) {
            var item = page[i];
            if (item.time > self.newestFetchedTime) {
              self.newestFetchedTime = item.time;
            }
          }
          allResults = allResults.concat(page);

          if (!fetchResult.hasMore) {
            return allResults;
          } else {
            // fetch next page from window, and recurse
            return self
              .fetchFn(key, {
                newerThan: fetchCutoff,
                skip: allResults.length,
              })
              .then(handleResultPage);
          }
        }
      }
    };

    /**
     * @method
     * @private
     */
    RecentActivityFeedClass.prototype._fetch = function _fetch(key) {
      var self = this;

      return self
        .fetchFn(key, {
          olderThan: self.cachedResults[key].oldestTime,
          skip: self.cachedResults[key].numOldestItems,
        })
        .then(function (fetchResult) {
          var page = fetchResult.items;
          var hasMore = fetchResult.hasMore;

          var cachedList = self.cachedResults[key];
          for (var i = 0; i < page.length; i++) {
            var item = page[i];
            if (item.time > self.newestFetchedTime) {
              self.newestFetchedTime = item.time;
            }
            cachedList.addItem(item);
          }
          cachedList.hasMore = hasMore;
          return page;
        });
    };

    /**
     * @method
     * Get the newest ${limit} items from the feed, and remove them from the
     * feed. May perform additional fetches as necessary.
     *
     * @return { Promise<Object[]> } A page of results from the feed. Provided
     *  in order of newest to oldest. Length will be at most this feed's page limit.
     */
    RecentActivityFeedClass.prototype.popNewestPage = function popNewestPage() {
      var self = this;
      var result = [];
      var handlePopFinish = function handlePopFinish(item) {
        if (item) {
          result.push(item);
        }
        if (result.length === self.limit || !item) {
          return $q.resolve(result);
        } else {
          return self.popNewestItem().then(handlePopFinish);
        }
      };

      return self.popNewestItem().then(handlePopFinish);
    };

    /**
     * @method
     * Get the newest activity item and remove it from this feed. May cause
     * additional fetches as necessary.
     *
     * @return { Promise<Object | null> } A promise which will resolve with the newest
     *  item in the activity feed. Resolves null if there are no additional items.
     */
    RecentActivityFeedClass.prototype.popNewestItem = function popNewestItem() {
      var self = this;

      // 1. select the newest item from the cache
      var newestItem = this._getNewestCacheItem();

      var cachesToUpdate = [];
      if (!newestItem.item) {
        // 2a. Nothing in the cache, fetch all caches that aren't exhausted
        cachesToUpdate = self.keys.filter(function (key) {
          return self.cachedResults[key].hasMore;
        });
      } else {
        // 2b. if the newest item is older than the oldest item in any other list
        // we may need to do some refetching to ensure we aren't missing anything

        cachesToUpdate = this._findPotentiallyNewerKeys(newestItem.time);
      }

      // no item found and no caches to update
      // this means we've exhausted everything
      if (!cachesToUpdate.length && !newestItem.item) {
        return $q.resolve(null);
      }

      var requests;
      // no caches to update, and we've found an item to return, just give it back
      if (!cachesToUpdate.length && newestItem.item) {
        // we have a complete enough picture of items to avoid refetching
        // remove and return the item we looked up
        return $q.resolve(
          this.cachedResults[newestItem.key].removeNewestItem()
        );
      } else if (cachesToUpdate.length) {
        // we need to update all of the caches in cachesToUpdate and then
        // start over
        requests = cachesToUpdate.map(function (key) {
          return self._fetch(key);
        });

        return $q.all(requests).then(function () {
          return self.popNewestItem();
        });
      }
    };

    /**
     * @private
     * @method
     * Method used to help determine if additional data needs to be fetched
     * to ensure no data is skipped when gaps exist between data being married
     * together from different collections.
     *
     * Identifies all caches whose current oldest item is newer than the provided
     * timestamp & has more to fetch. If this is the case, the cache may need
     * to have an additional fetch performed before selecting the newest cache item
     * since we can't be sure if the fetch would produce a newer "newest" item.
     * @param { Date } timestamp The timestamp cutoff
     * @return { string[] } An array of all cache keys that may have entries nwer
     *  than the timestamp.
     */
    RecentActivityFeedClass.prototype._findPotentiallyNewerKeys =
      function _findPotentiallyNewerKeys(timestamp) {
        var self = this;
        return Object.keys(self.cachedResults).reduce(function (
          resultKeys,
          key
        ) {
          var cachedList = self.cachedResults[key];
          var oldestTime = cachedList.oldestTime;
          if (!cachedList.hasMore) {
            return resultKeys;
          }

          // the timestamp is older (further down the list) than the oldest (last) item
          // in this current list
          if (oldestTime > timestamp && cachedList.hasMore) {
            var newResults = angular.copy(resultKeys);
            newResults.push(key);

            return newResults;
          }
          return resultKeys;
        },
        []);
      };

    /**
     * @typedef { Object } cacheSearchResult An object containing a found cache
     *  item and metadata. All fields will be populated, or all fields will be null
     *  indicating no item was located.
     * @property { Date | null } time The timestamp of the returned item
     * @property { Object | null } item The found item in the cache
     * @property { string | null} key The key for the cache list that contains the item
     */

    /**
     * @private
     * @method
     * Get the newest item from amongst the current cached items.
     * @return { cacheSearchResult }
     * Returns an all-nulls cache search result when item is not found.
     */
    RecentActivityFeedClass.prototype._getNewestCacheItem =
      function _getNewestCacheItem() {
        var self = this;
        return Object.keys(self.cachedResults).reduce(
          function (result, key) {
            var cachedList = self.cachedResults[key];
            // list is empty, no newest item here
            if (!cachedList.items.length) {
              return result;
            }
            var newestItem = cachedList.items[0];

            // either the first one we're looking at, or newer than
            // our known best candidate, update newest info
            if (!result.time || newestItem.time > result.time) {
              return {
                item: newestItem,
                time: newestItem.time,
                key: key,
              };
            }
            return result;
          },
          {
            time: null,
            item: null,
            key: null,
          }
        );
      };

    return RecentActivityFeedClass;
  }
})();
