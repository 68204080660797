/*
 * When displaying this dialog, you must provide the following locals:
 *
 *  DeleteMarkupDialog.show({
 *    locals: {
 *      building: {Building},
 *      floor: {Floor},
 *      markup: {Markup}
 *    },
 *  });
 *
 */

(function () {
  angular
    .module("akitabox.planView.dialogs.markup.delete", [
      "ngMaterial",
      "akitabox.core.constants",
      "akitabox.core.router",
      "akitabox.core.services.markup",
    ])
    .controller("DeleteMarkupDialogController", DeleteMarkupDialogController);

  /* @ngInject */
  function DeleteMarkupDialogController(
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Constants
    models,
    // Services
    ToastService,
    MarkupService,
    ShadowService
  ) {
    var self = this;
    var GA_CATEGORY = "markup";

    // Attributes
    self.saving = false;
    self.model = models.MARKUP.PLURAL;

    // Functions
    self.cancel = cancelDelete;
    self.delete = deleteMarkup;

    function deleteMarkup() {
      self.saving = true;
      return MarkupService.removeMarkup(self.markup)
        .then(function () {
          $mdDialog.cancel();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
          ShadowService.sendEvent(GA_CATEGORY, "confirm-delete", null, null);
        });
    }

    function cancelDelete() {
      ShadowService.sendEvent(GA_CATEGORY, "cancel-delete", null, null);
      $mdDialog.cancel();
    }
  }
})();
