(function () {
  angular.module("akitabox.desktop.room.detail", [
    "ui.router",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.room",
    "akitabox.core.services.document",
    "akitabox.core.services.pin",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.workOrdersBff",
    "akitabox.ui.components.decommissionedHeader",
    "akitabox.ui.dialogs.delete",
    "akitabox.ui.dialogs.asset.create",
    "akitabox.ui.dialogs.decommission",
    "akitabox.ui.dialogs.editLocation",
    "akitabox.ui.dialogs.filePreview",
    "akitabox.ui.dialogs.request.create",
    "akitabox.ui.dialogs.room.create",
    "akitabox.ui.dialogs.room.delete",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.schedule.create",
    "akitabox.ui.dialogs.workOrder.create",
    "akitabox.desktop.components.relatedWorkOrderCard",
  ]);
})();
