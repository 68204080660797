(function () {
  angular
    .module("akitabox.ui.dialogs.assemblyCondition")
    .controller(
      "AssemblyConditionDialogController",
      AssemblyConditionDialogController
    );

  /** @ngInject */
  function AssemblyConditionDialogController(
    // Material
    $mdDialog,
    // Services
    AssemblyService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.saveEnabled = false;
    self.condition = null;
    self.assessmentDate = null;
    self.headerText = "Update Assembly Condition";

    // Functions
    self.cancel = $mdDialog.cancel;
    self.updateAssemblyCondition = updateAssemblyCondition;
    self.onRatingChange = onRatingChange;
    self.onAssessmentDateChange = onAssessmentDateChange;

    // ------------------------
    //   Public Functions
    // ------------------------

    function onRatingChange($event) {
      self.condition = $event.model;
      self.saveEnabled = isSaveEnabled($event);
    }

    function onAssessmentDateChange($event) {
      self.assessmentDate = $event.model;
      self.saveEnabled = isSaveEnabled($event);
    }

    /**
     * Update the model
     */
    function updateAssemblyCondition() {
      if (self.saving) return;
      self.saving = true;

      var data = {
        action: "updateCondition",
        condition_rating: self.condition,
        date_recorded: self.assessmentDate,
      };

      AssemblyService.update(self.buildingId, self.assembly._id, data)
        .then($mdDialog.hide)
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function isSaveEnabled($event) {
      if ($event.invalid) {
        return false;
      }
      return Boolean(self.condition && self.assessmentDate);
    }
  }
})();
