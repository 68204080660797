(function () {
  angular
    .module("akitabox.core.services.pin", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.http",
      "akitabox.core.services.pinType",
    ])
    .factory("PinService", PinService);

  /**
   * @ngInject
   */
  function PinService($q, HttpService, PinTypeService) {
    /**
     * Pin service
     *
     * @type {Object}
     */
    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getById: getById,
      getAll: getAll,
      getByOrganization: getByOrganization,
      getAllByOrganization: getAllByOrganization,
      // Update
      updateById: updateById,
      updateValue: updateValue,
      updateValues: updateValues,
      setLocation: setLocation,
      updateRoom: updateRoom,
      updateFloor: updateFloor,
      recommission: recommission,
      decommission: decommission,
      // Helpers
      includePinType: includePinType,
      copyPopulatedPinProps: copyPopulatedPinProps,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    /**
     * Create a pin
     *
     * @param  {Object}                 data        New pin data
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with created pin
     */
    function create(data, route, params) {
      var config = {};
      return HttpService.post(route, data, config, params);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    /**
     * Get a list of pins
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with pin list
     */
    function get(buildingId, route, params, options) {
      var request = HttpService.get(route, params);

      if (options && options.includePinType) {
        request = request.then(function (pins) {
          return includePinType(buildingId, pins);
        });
      }
      return request;
    }

    /**
     * Get a list of pins by organization
     *
     * @param  {String}                 route           Route sent from AssetService or RoomService
     * @param  {Object}                 [params]        Query params
     * @param  {Object}                 [options]       Option to includePinType as a populated object
     * @return {Promise<Object|Error>}                  Promise that resolves with pin list
     */
    function getByOrganization(route, params, options) {
      var request = HttpService.get(route, params);

      if (options && options.includePinType) {
        request = request.then(function (pins) {
          return includePinType(null, pins);
        });
      }
      return request;
    }

    /**
     * Get a pin by ID
     *
     * @param  {String}                 pinId       Pin ID
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params
     * @return {Promise<Object|Error>}              Promise that resolves with pin
     */
    function getById(pinId, route, params) {
      return HttpService.getById(route, pinId, params);
    }

    /**
     * Get a list of all pins
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with pin list
     */
    function getAll(buildingId, route, params, options) {
      var request = HttpService.getAll(route, params);

      if (options && options.includePinType) {
        request = request.then(function (pins) {
          return includePinType(buildingId, pins);
        });
      }
      return request;
    }

    /**
     * Get a list of all pins
     *
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params
     * @param  {Object}                 [options]   Option to includePinType as a populated object
     * @return {Promise<Object|Error>}              Promise that resolves with pin list
     */
    function getAllByOrganization(route, params, options) {
      var request = HttpService.getAll(route, params);

      if (options && options.includePinType) {
        request = request.then(function (pins) {
          return includePinType(null, pins);
        });
      }
      return request;
    }

    /******************* *
     *   Update
     * ****************** */

    /**
     * Update a pin. Save off any values we've populated client-side, and
     * re-attach them to the updated pin. This is OK to do as none of those
     * populated fields can be updated through this function since:
     *   a) `room` and `level` are buried in `values`, not directly on the
     *        model. Updates to these need to be made through `updateValue`.
     *   b) Changing a pin's `pinType` is currently unsupported on the API.
     *
     * @param  {Object}                 data        Updated pin data
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params to include values
     * @param  {Object}                 [pin]       Original pin being updated.
     *     Pass this in if you want it to retain its populated fields.
     * @return {Promise<Object|Error>}              Promise that resolves with the updated pin
     */
    function updateById(data, route, params, pin) {
      return HttpService.put(route, data, params).then(function (updatedPin) {
        if (pin) {
          return copyPopulatedPinProps(updatedPin, pin);
        } else {
          return angular.extend({}, updatedPin);
        }
      });
    }

    /**
     * Update a pin value
     *
     * @param  {Object}                 data        Updated pin value data
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @return {Promise<Object|Error>}              Promise that resolves with the updated pin value
     */
    function updateValue(data, route) {
      return HttpService.put(route, data);
    }

    function updateValues(data, route) {
      data.action = "updateValues";
      return HttpService.patch(route, data).then(function (updatedPin) {
        return angular.extend({}, updatedPin);
      });
    }

    /**
     * Update a pin's location (coordinates on floor plan)
     * @param  {Object}                 data        New pin location data
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params to include values
     * @param  {Object}                 [pin]       Original pin being updated.
     *     Pass this in if you want it to retain its populated fields.
     * @return {Promise<Object|Error>}              Promise that resolves with an updated pin
     */
    function setLocation(data, route, params, pin) {
      var populatedFields = {};
      if (pin) {
        populatedFields = {
          level: pin.level,
          pinType: pin.pinType,
        };
        // Rooms don't need this.
        if (pin.room) {
          populatedFields.room = pin.room;
        }
      }

      data.action = "setLocation";
      return HttpService.patch(route, data, params).then(function (updatedPin) {
        return angular.extend({}, updatedPin, populatedFields);
      });
    }

    /**
     * Update a pin's room
     *
     * @param  {Object}                 data        New pin room data
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with the updated pin
     */
    function updateRoom(data, route, params) {
      data.action = "updateRoom";
      return HttpService.patch(route, data, params);
    }

    /**
     * Update a pin's floor
     *
     * @param  {Object}                 data        New pin floor data
     * @param  {String}                 route       Route sent from AssetService or RoomService
     * @param  {Object}                 [params]    Query params to include values
     * @return {Promise<Object|Error>}              Promise that resolves with the updated pin
     */
    function updateFloor(data, route, params) {
      data.action = "updateLevel";
      return HttpService.patch(route, data, params);
    }

    /**
     * Recommission a (previously decommissioned) pin
     *
     * @param { string }                 route    Route for the pin (from
     *                                            AssetService or RoomService)
     */
    function recommission(route) {
      var body = { action: "recommission" };
      return this.updateById(body, route);
    }

    /**
     * Decommission a room/asset
     * @param {String} route - the route to the room/asset we want to decommission
     * @param {Date} date - the date to decommission
     * @return {Promise<Room|Asset>} the decommissioned room/asset
     */
    function decommission(route, date) {
      var body = { action: "decommission", decommissioned_date: date };
      return this.updateById(body, route);
    }

    /******************* *
     *   Helpers
     * ****************** */

    /**
     * Copy properties from a pin object that may have been attached by the
     * front-end. Includes pin.level, pin.pinType, and pin.room
     *
     * @param {Object} destinationPin - The pin to copy properties to.
     * @param {Object} sourcePin - The pin to copy properties from.
     *
     * @return {Object} The modified destinationPin.
     */
    function copyPopulatedPinProps(destinationPin, sourcePin) {
      var populatedFields = {};
      var populatedPropKeys = ["level", "pinType", "room"];
      populatedPropKeys.forEach(function (propKey) {
        if (Object.prototype.hasOwnProperty.call(sourcePin, propKey)) {
          populatedFields[propKey] = sourcePin[propKey];
        }
      });
      return angular.extend({}, destinationPin, populatedFields);
    }

    /**
     * Include populated pinType on the pin(s)
     *
     * @param  {String} buildingId  Building ID
     * @param  {Array}  pins        Pins
     * @return {Promise<Array>}     Pins with attached pinTypes
     */
    function includePinType(buildingId, pins) {
      var requests = [];
      for (var i = 0; i < pins.length; ++i) {
        var pin = pins[i];
        var building = buildingId || pin.building;
        if (angular.isString(pin.pinType)) {
          var request = PinTypeService.getById(building, pin.pinType).then(
            (function (currentPin) {
              return function (pinType) {
                currentPin.pinType = pinType;
                return currentPin;
              };
            })(pin)
          );
          requests.push(request);
        } else {
          requests.push($q.resolve(pin));
        }
      }
      return $q.all(requests);
    }
  }
})();
