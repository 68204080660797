(function () {
  angular
    .module("akitabox.ui.dialogs.document.selectBuilding")
    .controller(
      "SelectBuildingForUploadDialogController",
      SelectBuildingForUploadDialogController
    );

  /* @ngInject */
  function SelectBuildingForUploadDialogController(
    // Material
    $mdDialog,
    $window,
    // Services
    EnvService,
    // Constants
    models
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.building = null;
    self.model = models.DOCUMENT.PLURAL;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.select = select;
    self.changeBuilding = changeBuilding;

    function changeBuilding($event) {
      self.building = $event.model;
    }

    function select() {
      if (!self.building) return;
      $mdDialog.hide();
      var url =
        EnvService.getCurrentBaseUrl() +
        "/buildings/" +
        self.building._id +
        "/upload";

      if (EnvService.getEnvName() === EnvService.LOCAL.ENV) {
        url = url.replace(":3007", ":3000");
      }

      $window.open(url, "_blank");
    }
  }
})();
