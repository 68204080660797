(function () {
  angular
    .module("akitabox.ui.dialogs.schedule.editFrequency")
    .factory("EditScheduleFrequencyDialog", EditScheduleFrequencyDialog);

  /* @ngInject */
  function EditScheduleFrequencyDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/schedule/edit-frequency/edit-schedule-frequency.dialog.html",
      controller: "EditScheduleFrequencyDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.schedule` is required
     *
     * @param {Object}  options                   Dialog options
     * @param {Object}  options.locals            Dialog local variables
     * @param {Object}  options.locals.schedule   The schedule to be edited
     *
     * @return {Promise<Object|Error}   Promise, resolves with updated schedule
     */
    function show(options) {
      // Verify we have a schedule local
      if (!options || !options.locals || !options.locals.schedule) {
        $log.error("EditScheduleFrequencyDialog: no schedule local provided");
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
