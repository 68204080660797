(function () {
  angular
    .module("akitabox.ui.dialogs.document.tag")
    .controller("TagDocumentDialogController", TagDocumentDialogController);

  function TagDocumentDialogController(
    // Angular
    $mdDialog,
    $q,
    $window,
    // Constants
    models,
    // Services
    BuildingService,
    EnvService,
    TagService,
    TagCategoryService,
    TagCategoryValueService,
    ToastService,
    UserService
  ) {
    var self = this;
    var permissions = UserService.getPermissions();

    if (angular.isEmpty(self.documents)) {
      ToastService.showError("No documents selected");
      $mdDialog.cancel();
    } else if (!angular.isArray(self.documents)) {
      self.documents = [self.documents];
    }

    // Attributes
    self.isAdmin = permissions.is_administrator || false;
    self.formData = {};
    self.saving = false;
    self.addingValue = false;
    self.progress = 0;
    self.multiple = self.documents ? self.documents.length > 1 : false;
    self.valuePlaceholder = "No tag category provided";

    // immediately gets common tags upon opening of the dialog
    self.commonTags = getCommonTags();

    // Functions
    self.close = close;
    self.create = create;
    self.fetchTagCategories = fetchTagCategories;
    self.onCategoryChange = onCategoryChange;
    self.removeTag = removeTag;
    self.toggleValue = toggleValue;
    self.createTagValue = createTagValue;
    self.openEditCategories = openEditCategories;

    init();

    function init() {
      // Set edit categories link
      if (self.documents && self.documents[0].building) {
        BuildingService.getById(self.documents[0].building).then(function (
          building
        ) {
          var link = EnvService.getBaseUrl(
            building.uri + "/settings/building_tag_edit"
          );
          if (EnvService.getEnvName() === EnvService.LOCAL.ENV) {
            link = link.replace(":3007", ":3000");
          }

          self.editCategoriesLink = link;
        });
      }
    }

    /**
     * Called on changes to the form's category field. Handles setting the value
     * field's placeholder.
     */
    function onCategoryChange() {
      var selectedCategory = self.formData.category;
      if (selectedCategory) {
        if (selectedCategory.values.length) {
          self.valuePlaceholder =
            "Enter a " + models.TAG_CATEGORY_VALUE.SINGULAR;
        } else {
          self.valuePlaceholder =
            '"' +
            selectedCategory.cat_alias +
            '" ' +
            "does not have any associated " +
            models.TAG_CATEGORY_VALUE.PLURAL;
        }
      } else {
        self.valuePlaceholder =
          "No " + models.TAG_CATEGORY.SINGULAR + " provided";
        self.addingValue = false;
      }

      // Clear out the category tag value field
      if (self.formData.value) {
        self.formData.value = null;
      }
    }

    /**
     * Get all available tag categories to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Available tag categories
     */
    function fetchTagCategories() {
      return TagCategoryService.getAll(self.documents[0].building).catch(
        ToastService.showError
      );
    }

    /**
     * Removes a tag from one or multiple documents. If multiple documents, removes one of the collection's common
     * tags.
     * @param tags An array of the remaining tags
     * @param removed The tag that was removed by the user
     */
    function removeTag(tags, removed) {
      // sets saving to true for the loading indicator and computes the increment value
      self.saving = true;
      var progressIncrement = 100 / self.documents.length;

      var promiseArray = [];

      // loops through each document that is selected to find the tag that needs to be removed
      for (var k = 0; k < self.documents.length; k++) {
        var doc = self.documents[k];
        // loops through each tag in the document
        for (var i = 0; i < doc.tags.length; i++) {
          var currTag = doc.tags[i];
          // since different documents' tags have different IDs, match the tag based on whether the category and
          // values match
          if (
            removed.cat_name === currTag.cat_name &&
            removed.value_name === currTag.value_name
          ) {
            // if it does, send a request to remove it
            promiseArray[k] = TagService.remove(
              doc.building,
              doc._id,
              currTag._id
            )
              // then increment the progress bar and return the removed tag
              .then(
                (function (storedI, storedK) {
                  return function () {
                    self.progress += progressIncrement;
                    return self.documents[storedK].tags[storedI];
                  };
                })(i, k)
              );
          }
        }
      }

      $q.all(promiseArray)
        .then(function (removedTags) {
          var removedTag = removedTags[0];
          // looping through the documents again to remove from local variable
          for (var l = 0; l < self.documents.length; l++) {
            var doc = self.documents[l];
            for (var j = 0; j < doc.tags.length; j++) {
              var currTag = doc.tags[j];
              if (
                removedTag.cat_name === currTag.cat_name &&
                removedTag.value_name === currTag.value_name
              ) {
                doc.tags.splice(j, 1);
              }
            }
          }
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Toggles between typing in a new value and selecting from the dropdown. Upon switch, should reset both
     * the newly typed value and the value selected from the dropdown.
     */
    function toggleValue() {
      self.addingValue = !self.addingValue;
      self.formData.value = "";
      self.formData.newValue = "";
    }

    /**
     * Create a new TagValue
     */
    function createTagValue() {
      var data = {
        value_alias: self.formData.newValue,
      };
      TagCategoryValueService.create(
        self.documents[0].building,
        self.formData.category._id,
        data
      )
        .then(function (tagValue) {
          // clear out the newValue since it's now saved
          self.formData.newValue = null;
          self.addingValue = false;
          // add it to the category dropdown
          self.formData.category.values.unshift(tagValue);
          // set the chosen value to the newly returned tagValue
          self.formData.value = tagValue;
          // go through the rest of the create process as normal
          self.create();
        })
        .catch(ToastService.showError);
    }

    /**
     * Creates a new tag based on the data that is provided by the user in the form on the dialog.
     */
    function create() {
      // Returns if invalid input
      if (!self.formData.value) return;

      var selectedCategory = self.formData.category;
      var selectedValue = self.formData.value;

      // Begins loading indicator
      self.saving = true;
      var progressIncrement = 100 / self.documents.length;

      // Sets up data to send to the server for creation of a new tag. The category comes from the category
      // dropdown, and the value comes from the chosen tag value
      var data = {
        cat_alias: selectedCategory.cat_alias,
        value_alias: selectedValue.value_alias,
      };

      // If a tag is created on multiple documents, it should only create it on the documents that don't already
      // have that tag. This initializes an array that will track which documents to create the tag on.
      var tagExistence = [];

      for (var j = 0; j < self.documents.length; j++) {
        tagExistence[j] = tagExistsInDoc(data, self.documents[j]);
      }

      var promiseArray = [];

      // For each document, if the tag doesn't already exist in the document, send the request and then add
      // the tag to the local variable.
      for (var i = 0; i < self.documents.length; i++) {
        if (tagExistence[i]) continue;
        promiseArray[i] = TagService.create(
          self.documents[0].building,
          self.documents[i]._id,
          data
        ).then(
          (function (storedI) {
            return function (tag) {
              self.documents[storedI].tags.push(tag);
              return tag;
            };
          })(i)
        );
      }

      // After the request is finished, add the tag to the array of common tags
      $q.all(promiseArray)
        .then(function (tags) {
          var index = tagExistence.indexOf(false);
          var newTag = tags[index];
          if (index > -1) {
            self.commonTags.push(newTag);
          }
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.progress += progressIncrement;
          self.saving = false;
        });
    }

    /**
     * helper function to find whether or not a tag already exists inside a document.
     * @param data The tag info
     * @param doc The document to search
     * @returns {boolean} alreadyExists Whether or not the tag exists
     */
    function tagExistsInDoc(data, doc) {
      var alreadyExists = false;
      for (var j = 0; j < doc.tags.length; j++) {
        var tag = doc.tags[j];
        if (
          data.cat_alias === tag.cat_alias &&
          data.value_alias === tag.value_alias
        ) {
          alreadyExists = true;
          break;
        }
      }
      return alreadyExists;
    }

    /**
     * Function that upon creation of the dialog finds each common tag between the selected documents and adds them
     * to an array for the UI to display.
     * @returns {Array} commonTags The array of tags that are common between all the docuements.
     */
    function getCommonTags() {
      // Handles invalid input
      if (!self.documents || self.documents.length === 0) return [];

      // Creates deep copy so the original local variable isn't modified.
      var documents = angular.copy(self.documents);

      // Initializes the common tags to be whatever tags are in the first document. This is the biggest the array
      // can possibly be.
      var commonTags = documents[0].tags;

      // loop on all documents to get their tags
      for (var i = 1; i < documents.length; i++) {
        var document = documents[i];

        // if there are no common tags at any point we can stop looking
        if (commonTags.length === 0 || !document.tags) {
          commonTags = [];
          break;
        }

        var tempTags = [];
        for (var j = 0; j < document.tags.length; j++) {
          var tag = document.tags[j];

          // push the tags that are common among documents
          for (var k = 0; k < commonTags.length; k++) {
            if (
              tag.cat_name === commonTags[k].cat_name &&
              tag.value_name === commonTags[k].value_name
            ) {
              tempTags.push(tag);
            }
          }
        }

        commonTags = tempTags;
      }
      return commonTags;
    }

    /**
     * closes the dialog and returns the modified documents to the list
     */
    function close() {
      $mdDialog.hide(self.documents);
    }

    /**
     * Open the settings page to edit Tag Categories
     */
    function openEditCategories() {
      $window.open(self.editCategoriesLink, "_blank");
    }
  }
})();
