(function () {
  angular
    .module("akitabox.ui.dialogs.asset.delete")
    .controller("DeleteAssetDialogController", DeleteAssetDialogController);

  /* @ngInject */
  function DeleteAssetDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    AssetService,
    FeatureFlagService,
    OrganizationService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since assetIDs is passed in through locals
    self.multiple = self.assetIDs ? self.assetIDs.length > 1 : false;

    self.buildingId = self.building ? self.building._id : self.buildingId;

    self.organization = OrganizationService.getCurrent();

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteAsset = deleteAsset;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete assets
     */
    function deleteAsset() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.assetIDs.length) * 100;
      var promiseArray = [];

      // Delete the assets
      for (var i = 0; i < self.assetIDs.length; i++) {
        promiseArray[i] = AssetService.deleteAsset(
          self.buildingId,
          self.assetIDs[i]
        )
          // when we delete a asset, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.assetIDs[storedI];
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedAssetIDs) {
          $mdDialog.hide(deletedAssetIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
