(function () {
  /**
   * @ngdoc component
   * @name abxDecommissionHeader
   *
   * @description
   * A banner that indicates that a pin is decommissioned. Includes an option to
   * recommission the pin and an option to change the pin's decommissioned date
   *
   * @param {Room || Asset} pin - the decommissioned pin
   * @param {Function} onChangeDate - Change listener to be invoked when the
   *     decommission date of the pin is modified.
   * @param {Function} onRecommission - Change listener to be invoked when the
   *     decommissioned pin is recommissioned.
   *
   * @example
   * <abx-decommissioned-header
   *   abx-pin="vm.asset" ~or~ "vm.room"
   *   abx-on-change-pin="vm.handleChangePin(newPin)"
   * >
   * </abx-decommissioned-header>
   */

  angular
    .module("akitabox.ui.components.decommissionedHeader", [
      "akitabox.ui.dialogs.changeDecommissionDate",
      "akitabox.ui.dialogs.recommission",
    ])
    .component("abxDecommissionedHeader", {
      bindings: {
        pin: "<abxPin",
        onChangeDate: "&?abxOnChangeDate",
        onRecommission: "&?abxOnRecommission",
        canRecommission: "<abxCanRecommission",
        canEditDecommissionDate: "<abxCanEditDecommissionDate",
        readOnly: "@?abxReadOnly",
      },
      controller: DecommissionedHeaderController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/decommissioned-header/decommissioned-header.component.html",
    });

  function DecommissionedHeaderController(
    // Dialogs
    ChangeDecommissionDateDialog,
    RecommissionDialog,
    // Services
    ToastService,
    // Third Party
    moment
  ) {
    var self = this;

    if (!self.pin.pinType) {
      throw new Error(
        "AbxDecommissionedHeader: abx-pin does not have a valid pin type"
      );
    } else if (!self.pin.decommissioned_date) {
      throw new Error(
        "AbxDecommissionedHeader: abx-pin has to be decommissioned"
      );
    }

    var decommissionDate = moment(self.pin.decommissioned_date);
    var isAsset = self.pin.pinType.is_asset;

    // Functions
    self.isReadOnly = isReadOnly;
    self.getFormattedDate = getFormattedDate;
    self.editDecommissionDate = editDecommissionDate;
    self.recommission = recommission;

    // // =================
    // // ng Life Cycle Functions
    // // =================
    self.$onChanges = function (changes) {
      // ignores the first initialization change
      if (changes.pin && !changes.pin.isFirstChange()) {
        var pin = changes.pin.currentValue;
        // Any time the pin changes we need to...
        // 1. Update the flag that indicates whether this is an asset or room
        if (!pin.pinType) {
          throw new Error(
            "AbxDecommissionedHeader: abx-pin does not have a valid pin type"
          );
        }
        isAsset = pin.pinType.is_asset;

        // 2. Update the decommission date
        decommissionDate = moment(pin.decommissioned_date);
      }
    };

    // // =================
    // // Public Functions
    // // =================

    /**
     * Checks if the component is read only or not
     * This function was only made because using the result of the fn directly
     * did not always update the template correctly
     */
    function isReadOnly() {
      return self.readOnly !== undefined;
    }

    /**
     * Returns the formatted version of the decommission date
     * @return {String} "Jan 1, 2020"
     */
    function getFormattedDate(format) {
      return decommissionDate.format(format || "MMM D, YYYY");
    }

    /**
     * Allows editing of the decommission date
     */
    function editDecommissionDate() {
      var locals = {};
      if (isAsset) locals.asset = self.pin;
      else locals.room = self.pin;

      ChangeDecommissionDateDialog.show({ locals: locals }).then(function (
        updatedPin
      ) {
        decommissionDate = moment(updatedPin.decommissioned_date);
        if (typeof self.onChangeDate === "function") {
          self.onChangeDate({
            date: updatedPin.decommissioned_date,
            model: updatedPin,
          });
        }
      });
    }

    /**
     * Allows recommissioning of the room/asset
     */
    function recommission() {
      var localsKey = isAsset ? "assets" : "rooms";
      var locals = {};
      locals[localsKey] = [self.pin];

      RecommissionDialog.show({ locals: locals })
        .then(function (recommissionedPins) {
          if (
            typeof self.onRecommission === "function" &&
            recommissionedPins &&
            recommissionedPins.length
          ) {
            self.onRecommission({
              model: recommissionedPins[0],
            });
          }
        })
        .catch(ToastService.showError);
    }
  }
})();
