(function () {
  /**
   * @ngdoc component
   * @name abxInspectionProgramInstructionItem
   *
   * @param {string} stepNumber - The number of the step
   * @param {string} instructionTitle - The title header of the instruction
   * @param {string} instructionDescription - The body of the instruction
   *
   * @description
   * List items for inspection program instructions
   */
  angular
    .module("akitabox.desktop.inspectionProgram")
    .component("abxInspectionProgramInstructionItem", {
      bindings: {
        stepNumber: "<abxStepNumber",
        instructionTitle: "<abxInstructionTitle",
        instructionDescription: "<abxInstructionDescription",
        linkDialog: "<?abxLinkDialog",
        linkList: "<?abxLinkList",
        createInspectionProgramButton: "<abxCreateInspectionProgramButton",
        linkInspectionProgramDialog: "<?abxLinkInspectionProgramDialog",
      },
      controller: AbxInspectionProgramInstructionItemController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/modules/inspection-program/inspection-program-instruction/inspection-program-instruction-item.component.html",
    });

  function AbxInspectionProgramInstructionItemController() {}
})();
