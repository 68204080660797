(function () {
  /**
   * @ngdoc component
   * @name abxPinTypeahead
   *
   * @description
   * Pin search. Shows inline styling for options that includes pin type
   * information for each pin. Takes in a fetch function instead of being
   * responsible for fetching itself. Fetch function will get called on
   * user input, debounced by 500 ms. No fetch will occur until the user inputs
   * at least one character.
   *
   * @param {Object} [selectedPin] - Currently selected pin. Puts the pin's name
   *     pre-filled in the search input.
   * @param {Function} fetch - Function used to fetch pins. Can be async or not.
   * @param {Function} onPinChange - Change listener to be invoked when the
   *     selected pin changes. This will get called on *any* change to the input
   *     itself (so, if a pin is selected and a user types a character, this
   *     function will now get invoked with no pin, since a pin is no longer
   *     selected).
   * @param {String} [label] - Input label
   * @param {Boolean} [required] - Mark the input as required
   * @param {Boolean} [disabled] - Disable input interaction
   *
   * @callback fetch
   * @param {String} query - Query for pins' name
   *
   * @callback onPinChange
   * @param {Object} $event
   * @param {Object} [$event.pin] - Newly selected pin, if any
   */
  angular
    .module("akitabox.ui.components.pinTypeahead", [
      "akitabox.core.constants",
      "akitabox.core.directives.pinType",
      "akitabox.core.toast",
    ])
    .component("abxPinTypeahead", {
      bindings: {
        selectedPin: "<abxSelectedPin",
        fetch: "&abxFetch",
        onPinChange: "&abxOnPinChange",
        label: "@?abxLabel",
        required: "<?abxRequired",
        disabled: "<?abxDisabled",
        placeholder: "@abxPlaceholder",
      },
      transclude: {
        labelRight: "?labelRight",
      },
      controller: PinTypeaheadController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/pin-typeahead/pin-typeahead.component.html",
    });

  function PinTypeaheadController(
    // Angular
    $q,
    // Constants
    COMPONENT_STATE,
    // Services
    ToastService
  ) {
    var self = this;

    // Attributes
    self.selectedPin = null;
    self.state = COMPONENT_STATE.default;

    // Functions
    self.getPins = getPins;
    self.handleSelectedPinChange = handleSelectedPinChange;

    // =================
    // Public Functions
    // =================

    /**
     * Get pins with provided fetch function with the given query
     *
     * @param {String} [query] - Query given to parent fetch
     * @return {Promise<Object[]>} - Resolves with matching pins, if any
     */
    function getPins(query) {
      self.state = COMPONENT_STATE.loading;
      return $q
        .resolve(self.fetch({ query: query }))
        .catch(function (err) {
          ToastService.showError("Failed to search. Please try again.");
          return $q.reject(err);
        })
        .finally(function () {
          self.state = COMPONENT_STATE.default;
        });
    }

    /**
     * Handle any changes to the selected pin. Notifies the parent of the
     * newly selected pin. Will get invoked on any change to the input. If the
     * input is changing without explicitly selecting a pin, will send up
     * no pin.
     */
    function handleSelectedPinChange() {
      self.onPinChange({
        $event: { pin: self.selectedPin },
      });
    }
  }
})();
