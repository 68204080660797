/**
 * AngularJS service for fetching public-facing alert/banner messages.
 */
(function () {
  angular
    .module("akitabox.core.services.bannerMessage")
    .factory("PublicBannerMessageService", PublicBannerMessageService);

  /** @ngInject */
  function PublicBannerMessageService(
    // Angular
    $http,
    $q
  ) {
    return {
      /**
       * @return { Promise<{message: string}> | null } The current public banner message
       */
      get: function getPublicBannerMessage() {
        return $http
          .get("/public-banner-message.json")
          .then(function (res) {
            return res.data;
          })
          .catch(function (err) {
            if (err.status === 404) {
              return null;
            } else {
              return $q.reject(err);
            }
          });
      },
    };
  }
})();
