(function () {
  angular
    .module("akitabox.ui.components.recordConditionForm", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.condition",
      "akitabox.core.toast",
      "akitabox.ui.components.input",
    ])
    .component("abxRecordConditionForm", {
      bindings: {
        assembly: "<abxAssembly",
        assetId: "<abxAssetId",
        buildingId: "<abxBuildingId",
        onRatingChange: "&abxOnRatingChange",
        onDateChange: "&abxOnDateChange",
      },
      controller: AbxRecordConditionFormController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/record-condition-form/record-condition-form.component.html",
    });

  /** @ngInject */
  function AbxRecordConditionFormController(
    // AkitaBox
    models,
    // Libraries
    moment,
    // Services
    AssetConditionService,
    ConditionService,
    ToastService
  ) {
    var self = this;

    var CONDITION_GRADES_VERBIAGE = models.CONDITION.CONDITION_GRADES_VERBIAGE;
    var CONDITION_GRADES_SHORT_DESCRIPTIONS =
      models.CONDITION.CONDITION_GRADES_SHORT_DESCRIPTIONS;
    var CONDITION_GRADES_LONG_DESCRIPTIONS =
      models.CONDITION.CONDITION_GRADES_LONG_DESCRIPTIONS;

    // Attributes
    self.rating = null;
    self.date = new Date();
    self.conditionRatings = getConditionRatings();
    self.conditionHistory = [];
    self.conditionShortDescription = null;
    self.conditionLongDescription = null;
    self.conditionInputLabel = self.assembly
      ? "Assembly Condition"
      : "Asset Condition";
    self.conditionHistoryLabel = self.assembly
      ? "Assembly Condition History"
      : "Asset Condition History";
    self.maxDate = moment().endOf("day").toDate();

    // Functions
    self.onChange = onChange;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      // Notify parent date was set to today/now
      self.onDateChange({ $event: { model: self.date } });
      getConditionHistory();
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function onChange($event, field) {
      switch (field) {
        case "rating":
          self.rating = $event.model;
          self.conditionTitle = CONDITION_GRADES_VERBIAGE[self.rating];
          var short = CONDITION_GRADES_SHORT_DESCRIPTIONS[self.rating];
          var long = CONDITION_GRADES_LONG_DESCRIPTIONS[self.rating];
          self.conditionShortDescription = short ? "(" + short + ")" : null;
          self.conditionLongDescription = long ? long : null;
          self.onRatingChange({ $event: $event });
          break;
        case "date":
          var dateSelected = $event.model;
          var isValidDate = false;

          try {
            isValidDate = !isNaN(dateSelected.getTime());
          } catch (err) {
            isValidDate = false;
          }

          // Give a timestamp for retrieval of most current condition
          if (isValidDate) {
            var now = new Date();
            dateSelected.setHours(
              now.getHours(),
              now.getMinutes(),
              now.getSeconds(),
              now.getMilliseconds()
            );
            $event.model = dateSelected;
            self.onDateChange({ $event: $event });
          }

          self.date = dateSelected;

          break;
      }
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getConditionRatings() {
      return Object.keys(CONDITION_GRADES_VERBIAGE).map(function (key) {
        return {
          model: key,
          value: CONDITION_GRADES_VERBIAGE[key],
        };
      });
    }

    function getConditionHistory() {
      let parentId;
      let buildingId;
      if (self.assembly) {
        const parentModel = self.assembly;
        parentId = parentModel._id;
        buildingId = parentModel.building._id || parentModel.building;
      } else {
        parentId = self.assetId;
        buildingId = self.buildingId;
      }
      var service = self.assembly ? ConditionService : AssetConditionService;
      return service
        .getAll(buildingId, parentId, {
          sort: "date_recorded,desc",
        })
        .then(function (conditions) {
          if (conditions.length) {
            self.conditionHistory = [];

            for (var i = 0; i < conditions.length; ++i) {
              var condition = conditions[i];
              var conditionRating =
                CONDITION_GRADES_VERBIAGE[condition.condition_rating];
              var dateRecorded = condition.date_recorded;

              if (conditionRating && dateRecorded) {
                self.conditionHistory.push({
                  conditionRating: conditionRating,
                  dateRecorded: moment(dateRecorded).format("L"),
                  task: condition.task,
                });
              }
            }
          }
        })
        .catch(ToastService.showError);
    }
  }
})();
