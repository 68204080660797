(function () {
  angular
    .module("akitabox.core.services.timeZone", [
      "akitabox.constants",
      "akitabox.core.services.building",
      "akitabox.core.lib.moment",
      "akitabox.core.services.organization",
    ])
    .factory("TimeZoneService", TimeZoneService);

  /* @ngInject */
  function TimeZoneService(
    // AkitaBox
    BuildingService,
    OrganizationService,
    // Libraries
    moment
  ) {
    var service = {
      // Retrieve
      getCurrentTimeZone: getCurrentTimeZone,
      getCurrentUTCOffset: getCurrentUTCOffset,
      getCurrentUTCOffsetAbbr: getCurrentUTCOffsetAbbr,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Retrieve
     ******************* */

    function getCurrentTimeZone(building) {
      var currentBuilding = BuildingService.getCurrent();
      if (building) {
        return building.time_zone;
      }
      if (currentBuilding) {
        return currentBuilding.time_zone;
      }
      var currentOrganization = OrganizationService.getCurrent();
      if (currentOrganization) {
        return currentOrganization.time_zone;
      }
      // default case
      return "UTC";
    }

    function getCurrentUTCOffset(building) {
      var currentBuilding = BuildingService.getCurrent();
      if (building && !currentBuilding) {
        return getCurrentUtfOffSet(building.time_zone);
      }
      if (currentBuilding && !building) {
        return getCurrentUtfOffSet(currentBuilding.time_zone);
      }
      if (currentBuilding) {
        return getCurrentUtfOffSet(currentBuilding.time_zone);
      }

      var currentOrganization = OrganizationService.getCurrent();
      if (currentOrganization) {
        return currentOrganization.time_zone_offset;
      }
      // default case
      return "";
    }

    function getCurrentUTCOffsetAbbr(building) {
      var currentBuilding = BuildingService.getCurrent();
      if (currentBuilding && !building) {
        const nowMoment = moment().tz(currentBuilding.time_zone);
        const abbreviation = nowMoment.format("z");
        return abbreviation;
      }
      if (building) {
        const nowMoment = moment().tz(building.time_zone);
        const abbreviation = nowMoment.format("z");
        return abbreviation;
      }
      // default case
      return "";
    }

    function getCurrentUtfOffSet(time_zone) {
      if (!time_zone) {
        return "";
      }
      const nowMoment = moment().tz(time_zone);
      const utcOffsetMinutes = nowMoment.utcOffset();
      const utcOffsetMinutesMagnitude = Math.abs(utcOffsetMinutes);
      // parse offset minutes into an hour format (ex. "+09:00", or "-05:00")
      const offsetSign = utcOffsetMinutes < 0 ? "-" : "+";
      const offsetHour = Math.floor(utcOffsetMinutesMagnitude / 60);
      const offsetMinutes = utcOffsetMinutesMagnitude % 60;

      const offsetHourDisplay =
        offsetHour < 10 ? `0${offsetHour}` : `${offsetHour}`;
      const offsetMinutesDisplay =
        offsetMinutes < 10 ? `0${offsetMinutes}` : `${offsetMinutes}`;
      return `${offsetSign}${offsetHourDisplay}:${offsetMinutesDisplay}`;
    }
  }
})();
