(function () {
  angular
    .module("akitabox.ui.dialogs.document.upload")
    .controller(
      "UploadDocumentDialogController",
      UploadDocumentDialogController
    );

  /* @ngInject */
  function UploadDocumentDialogController(
    // Angular
    $element,
    $filter,
    // Material
    $mdDialog,
    $mdToast,
    // Services
    DocumentService,
    EnvService,
    FileService,
    ToastService,
    Utils,
    IndexedDBService,
    models
  ) {
    var self = this;

    // Attributes
    var offlineCacheEnabled = !!self.indexedDBOptions;
    self.showOfflineCacheWarning = false;
    self.loadingFromCache = offlineCacheEnabled;
    self.allowedFileTypes =
      models.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.join(",");

    self.uploader = null;
    self.hasErrors = null;
    self.uploads = null;
    self.erroredItems = [];

    self.cancel = cancel;
    self.removeItem = removeItem;
    self.retry = retry;
    self.startUpload = startUpload;
    self.getFileType = getFileType;
    self.onClickUpload = onClickUpload;
    self.hideOfflineCacheWarning = hideOfflineCacheWarning;

    init();

    // Public Methods

    function cancel() {
      // if the user has had a combination of success and failed items, but chooses to close without retrying, we
      // need to send back the successful items;
      if (self.uploads.length) {
        $mdDialog.hide(self.uploads);
      } else {
        $mdDialog.cancel();
      }
    }

    function hideOfflineCacheWarning() {
      self.showOfflineCacheWarning = false;
    }

    /**
     * Method to remove one item from the uploaer.queue
     *
     * @param item
     */
    function removeItem(item) {
      if (!self.allowMultiple) {
        // IF this is only a single upload, clear the input as well
        $element.find("#file").val("");
      }

      Utils.removeElement(item);

      if (offlineCacheEnabled) {
        updateIndexedDB();
      }
    }

    /**
     * Method to retry any uploads that previously failed
     */
    function retry() {
      self.hasErrors = false;

      // clean up the queue and prepare to retry
      self.uploader.queue = [];
      // loop through all failed items and prepare them to try again;
      for (var i = 0; i < self.erroredItems.length; i++) {
        self.erroredItems[i].withCredentials = true;
        self.erroredItems[i].status = {
          text: "Ready",
          className: "text-uppercase",
        };
        self.erroredItems[i].isSuccess = false;
        self.erroredItems[i].isUploaded = false;
        self.uploader.queue.push(self.erroredItems[i]);
      }

      startUpload();
    }

    /**
     * Method to execute the uploader object's .uploadAll() function
     */
    function startUpload() {
      // disable the ability to click the Choose Files "button" that's not a button
      angular
        .element($element.find("#abx-file-uploader"))
        .addClass("no-pointer-events");
      angular.element($element.find("#chooseFilesSpan")).addClass("disabled");
      self.uploader.uploadAll();
    }

    /**
     * Method to return the file type of a File object
     *
     * @param {File} file
     * @returns {String}
     */
    function getFileType(file) {
      return file.type.split("/")[1];
    }

    // Private Methods

    function init() {
      if (!self.building && !self.building._id) return;
      if (offlineCacheEnabled) {
        IndexedDBService.open(self.building._id)
          .then(function () {
            self.showOfflineCacheWarning = true;
            IndexedDBService.getById(self.indexedDBOptions)
              .then(function (data) {
                if (data && data.files && data.files.length) {
                  self.uploader.addToQueue(data.files);
                }
              })
              .catch(function (err) {
                ToastService.showError(err);
              })
              .finally(function () {
                self.loadingFromCache = false;
              });
          })
          .catch(function (err) {
            ToastService.showError(err);
          });
      }

      initUploader();
    }

    /**
     * Method to initialize the uploader object with some settings
     */
    function initUploader() {
      self.uploads = [];
      self.hasErrors = false;
      self.uploader = FileService.createUploader(
        self.building.cre_account,
        self.building._id
      );
      var revision =
        self.revision !== null && self.revision !== undefined
          ? self.revision
          : true;
      self.uploader.url =
        EnvService.getApiUrl() +
        DocumentService.buildBaseRoute(self.building._id) +
        "?revision=" +
        revision;

      self.autoUpload = self.autoUpload || false;
      self.queueLimit = self.queueLimit || 100;
      self.withCredentials = true;

      // Setup filters if provided
      if (angular.isArray(self.filters) && self.filters.length) {
        for (var i = 0; i < self.filters.length; i++) {
          self.uploader.filters.push(self.filters[i]);
        }
      }

      self.uploader.filters.push({
        name: "validFileType",
        fn: (file) => {
          const ext = `.${file.name.split(".").pop()?.toLowerCase()}`;
          if (!ext) {
            return false;
          }
          return models.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.indexOf(ext) > -1;
        },
      });

      /**
       * onAfterAddingFile function(item) :: Fires after adding a single file to the queue
       */
      self.uploader.onAfterAddingFile = function (item) {
        if (!self.allowMultiple && self.uploader.queue.length >= 2) {
          // This is strictly a one file upload, so we must make sure we remove the other item as well
          self.uploader.queue[0].remove();
        }

        item.withCredentials = true;
        item.status = {
          text: "Ready",
          className: "text-uppercase",
        };
      };

      /**
       * onWhenAddingFileFailed function(item, filter, options) :: When adding a file failed
       */
      if (angular.isFunction(self.onWhenAddingFileFailed)) {
        self.uploader.onWhenAddingFileFailed = self.onWhenAddingFileFailed;
      } else {
        // use the default to catch errors
        self.uploader.onWhenAddingFileFailed = function (
          item,
          filter,
          options
        ) {
          var $parent = $element.find("md-dialog-content");
          var text;
          var position = "bottom right";
          var toastOptions;

          switch (filter.name) {
            case "maxFileSize":
              text =
                "File exceeds limit of " +
                $filter("byteFmt")(FileService.MAX_FILE_SIZE, 2);
              toastOptions = $mdToast
                .simple()
                .textContent(text)
                .parent($parent)
                .position(position);
              break;
            case "validFileType":
              text = "Invalid file type";
              toastOptions = $mdToast
                .simple()
                .textContent(text)
                .parent($parent)
                .position(position);
              break;
            default:
              break;
          }

          // Toast wont show if you pass undefined options, so this is fine
          // We can always try to show toast
          ToastService.show(toastOptions, true);
          // Clear the input
          angular.element(document.querySelector("#abx-file-uploader")).val("");
        };
      }

      /**
       * onAfterAddingAll function(addedItems) :: Fires after adding all the dragged or selected files to the queue
       * Only update DB once all selected files have been added to the queue
       */
      if (offlineCacheEnabled) {
        self.uploader.onAfterAddingAll = function () {
          updateIndexedDB();
          if (angular.isFunction(self.onAfterAddingAll))
            self.onAfterAddingAll();
        };
      } else if (angular.isFunction(self.onAfterAddingAll)) {
        self.uploader.onAfterAddingAll = self.onAfterAddingAll;
      }

      /**
       * onBeforeUploadItem function(item) :: Fires before uploading an item.
       */
      self.uploader.onBeforeUploadItem = function (item) {
        item.status = {
          text: "Uploading",
          className: "fg-gray text-uppercase",
        };
      };

      /**
       * onProgressItem function(item, progress) :: On file upload progress
       */
      if (angular.isFunction(self.onProgressItem)) {
        self.uploader.onProgressItem = self.onProgressItem;
      }

      /**
       * onSuccessItem function(item, response, status, headers) :: On file successfully uploaded
       */
      self.uploader.onSuccessItem = function (item, response, status, headers) {
        item.status = {
          text: "Complete",
          className: "fg-success text-uppercase",
        };
      };

      /**
       * onErrorItem function(item, response, status, headers) :: On upload error
       */
      self.uploader.onErrorItem = function (item, response, status, headers) {
        self.hasErrors = true;
        if (response && response.error) {
          item.status = {
            text: "FAILED - " + (response.error.text || response.error.name),
            className: "fg-warn text-uppercase",
          };
        }
        self.erroredItems.push(item);
      };

      /**
       * onCancelItem function(item, response, status, headers) :: On cancel uploading
       */
      if (angular.isFunction(self.onCancelItem)) {
        self.uploader.onCancelItem = self.onCancelItem;
      }

      /**
       * onCompleteItem function(item, response, status, headers) :: On file upload complete
       *  - (independently of the success of the operation)
       */
      self.uploader.onCompleteItem = function (
        item,
        response,
        status,
        headers
      ) {
        if (angular.isArray(response) && response.length) {
          self.uploads.push(response[0]);
        } else if (angular.isArray(response) && !response.length) {
          // Nothing was uploaded because these files were already uploaded
          self.uploader.onErrorItem(item, {
            error: {
              text: "Already Uploaded",
            },
          });
        }
      };

      /**
       * onProgressAll function(progress) :: On upload queue progress
       */
      if (angular.isFunction(self.onProgressAll)) {
        self.uploader.onProgressAll = self.onProgressAll;
      }

      /**
       * onCompleteAll function() :: On all loaded when uploading an entire queue, or on file loaded when uploading
       * a single independent file
       */
      self.uploader.onCompleteAll = function () {
        angular
          .element($element.find("#abx-file-uploader"))
          .removeClass("no-pointer-events");
        angular
          .element($element.find("#chooseFilesSpan"))
          .removeClass("disabled");

        var finishCompleteAll = function () {
          if (self.hasErrors) {
            ToastService.showSimple("Some files failed to upload");
          } else {
            $mdDialog.hide(self.uploads);
          }
        };

        if (offlineCacheEnabled) {
          updateIndexedDB().then(finishCompleteAll);
        } else {
          finishCompleteAll();
        }
      };
    }

    function updateIndexedDB() {
      function getFilesFromQueue() {
        return self.uploader.queue
          .filter(function (item) {
            return !item.isSuccess;
          })
          .map(function (item) {
            return item._file;
          });
      }

      return IndexedDBService.put(
        self.building._id,
        self.indexedDBOptions,
        getFilesFromQueue()
      ).catch(function (err) {
        ToastService.showError(err);
      });
    }

    function onClickUpload() {
      angular.element($element.find("#abx-file-uploader")).click();
    }
  }
})();
