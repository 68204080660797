(function () {
  angular
    .module("akitabox.desktop.floor.list")
    .controller("FloorListController", FloorListController);

  /* @ngInject */
  function FloorListController(
    // Angular
    $location,
    $q,
    $scope,
    $timeout,
    // Constants
    EVENT_FLOOR_CREATE,
    // Services
    FloorService,
    ToastService,
    FilterBarManager,
    ManagedModelFieldFilter,
    UserService,
    // Resolves
    building,
    // Dialogs
    CreateFloorDialog,
    ExportDialog
  ) {
    var self = this;
    var permissions = UserService.getPermissions();

    // Attributes
    self.floors = null;
    self.building = building;
    self.subtitle = { name: "Floors" };
    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.movedFloor = null;
    self.permissions = getDefaultPermissions();

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // update the list
        changeFilters(query);
      },
    });

    var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Name",
      queryField: "name",
    });

    var hasFloorPlanConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Has Floor Plan",
        queryField: "document",
        inputType: "boolean",
        modelValueToFilterValue: function (model) {
          if (model) return "$ne,null";
          return "null";
        },
        modelValueToChipText: function (model) {
          if (model) return "Yes";
          return "No";
        },
        filterValueToModelValue: function (queryStringValue) {
          if (queryStringValue === "$ne,null") return [true];
          return [false];
        },
      }
    );

    self.filterBarManager.addFilterConfiguration(nameConfig);
    self.filterBarManager.addFilterConfiguration(hasFloorPlanConfig);

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // Functions
    self.addFloor = addFloor;
    self.changeFilters = changeFilters;
    self.fetchFloors = fetchFloors;
    self.fetchAllFloors = fetchAllFloors;
    self.disableReorder = disableReorder;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_FLOOR_CREATE, onFloorCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getDefaultPermissions() {
      return {
        canAddFloor: false,
        canReorderFloor: permissions.is_administrator,
      };
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportFloors,
        },
      ];
    }

    function exportFloors() {
      var locals = {
        route: FloorService.buildListRoute(building._id),
        filters: self.activeFilters,
        excelOnly: true,
      };

      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function addFloor() {
      CreateFloorDialog.show({ locals: { building: building } })
        .then(function (floors) {
          // We know we're only getting one back, so take the first item in the returned array
          var newFloor = floors[0];

          if (angular.isArray(self.floors)) {
            self.floors.push(newFloor);
          } else {
            self.floors = [newFloor];
          }

          $timeout(function () {
            $scope.$broadcast("list:updateSelectedIndices", floors.length);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function disableReorder() {
      return !(!self.permissions.canReorderFloor || self.activeFilters);
    }

    function fetchFloors(skip, limit) {
      var doFetch = function () {
        var params = {
          skip: skip,
          limit: limit,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return FloorService.get(building._id, filters).then(function (floors) {
          if (angular.isArray(self.floors)) {
            self.floors = self.floors.concat(floors);
          } else {
            self.floors = floors;
          }

          return floors.length;
        });
      };

      return $q.resolve(this.filterInitPromise).then(doFetch);
    }

    function fetchAllFloors(limit) {
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return FloorService.getAll(building._id, filters).then(function (
          floors
        ) {
          self.floors = floors;
          return floors.length;
        });
      };

      return $q.resolve(this.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = angular.isEmpty(activeFilters, true)
        ? null
        : activeFilters;
      self.floors = null;
      $scope.$broadcast("list:refresh");
    }

    /**
     * Handle floor creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Floor[]} floors    List of new floors
     */
    function onFloorCreate($event, floors) {
      // We can only create one floor at a time
      var floor = floors[0];
      if (floor.building === self.building._id) {
        if (!self.floors) {
          self.floors = [];
        }
        self.floors.push(floor);
        $timeout(function () {
          $scope.$broadcast("list:refreshClickEvents");
        });
      }
    }
  }
})();
