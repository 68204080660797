(function () {
  angular
    .module("akitabox.ui.directives.activityList")
    .directive("abxActivityListItem", AbxActivityListItemDirective);

  /* @ngInject */
  function AbxActivityListItemDirective(
    // Angular
    $http,
    $compile,
    $templateCache,
    $log,
    $state,
    //model
    models,
    // Dialogs
    DeleteNoteDialog,
    DeleteWorkOrderLogDialog,
    WorkOrderLogDialog,
    // Services
    AssetService,
    NoteService,
    OrganizationService,
    Router,
    ShadowService,
    TimeZoneService,
    ToastService,
    UserService
  ) {
    return {
      restrict: "E",
      link: postLink,
      scope: {
        model: "=",
        building: "=",
        workOrder: "<?abxWorkOrder",
        dateFormat: "@",
        saveNote: "&?",
        isSaving: "=?",
        currentUser: "=?",
        refresh: "=?",
        delete: "&?",
        users: "<abxUsers",
      },
    };

    function postLink($scope, $element) {
      var permissions = UserService.getPermissions();
      // Constants
      var DEFAULT_DATE_FORMAT = "MMM d, yyyy h:mm a";
      var SHORT_DATE_FORMAT = "MMM d, yyyy";
      var ACTIVITY_TYPE_NOTE = "note";
      var ACTIVITY_TYPE_USER_ACTION = "userAction";
      var ACTIVITY_TYPE_WORK_ORDER = "workOrder";
      var ACTIVITY_TYPE_WORK_ORDER_LOG = "workOrderLog";
      var ACTIVITY_TYPE_CREATED_ENTITY = "createdEntity";
      var LOCATION_TYPES = ["level", "room", "asset"];

      // IDs
      var buildingId;
      var modelId;

      // Attributes
      $scope.utcOffset = TimeZoneService.getCurrentUTCOffset();
      $scope.organization = OrganizationService.getCurrent();
      $scope.readonly = true;
      $scope.link = null;
      $scope.canEdit = false;
      $scope.canDelete = false;
      $scope.editing = false;
      $scope.isExpanded = false;

      // Functions
      $scope.save = save;
      $scope.editable = editable;
      $scope.isNoteCreator = isNoteCreator;
      $scope.deletable = deletable;
      $scope.showDeleteDialog = showDeleteDialog;
      $scope.showDeleteLogDialog = showDeleteLogDialog;
      $scope.showLogWorkDialog = showLogWorkDialog;
      $scope.toggleContent = toggleContent;
      $scope.buildAttachmentReference = buildAttachmentReference;
      $scope.buildMessageReference = buildMessageReference;
      $scope.buildWorkOrderReference = buildWorkOrderReference;
      $scope.buildWorkOrderLinkReference = buildWorkOrderLinkReference;
      $scope.revertChange = revertChange;
      $scope.parseText = parseText;
      $scope.startEditing = function () {
        $scope.editing = true;
      };
      $scope.getTimeCodeActivityTitle = function () {
        if (!$scope.model.time_code) return "Not provided";

        return (
          $scope.model.time_code.code + " - " + $scope.model.time_code.name
        );
      };

      // ------------------------
      //   Watchers
      // ------------------------

      // Watch currentUser
      var unwatch = $scope.$watch("currentUser", function (currentUser) {
        if (currentUser) {
          init();
          unwatch();
        }
      });

      // ------------------------
      //   Initialization
      // ------------------------

      function init() {
        // Verify the model exists
        if (angular.isEmpty($scope.model)) {
          return $log.error("<abx-activity-list-item>: model is required");
        }

        // TODO:
        // Set account display name in case we were only given on an ID on a created entity event
        if (!$scope.model.cre_account && $scope.model.account) {
          var account = $scope.model.account;
          UserService.getByIdentity($scope.organization._id, account)
            .then(function (user) {
              $scope.model.cre_account = user.identity;
              $scope.model.account_display = user.identity.display_name;
            })
            .finally(callback());
        } else if ($scope.model.cre_account) {
          $scope.model.account_display = $scope.model.cre_account.display_name;
          callback();
        } else {
          callback();
        }
      }

      function callback() {
        if ($scope.model.text) {
          $scope.model.text = parseInitialText($scope.model.text);
        }

        modelId = $scope.model._id;
        var activityType = $scope.model.activityType;
        var creator = $scope.model.cre_account;

        // Fetch activity template and re-compile
        fetchTemplate(activityType);
        // Default date format
        if (angular.isEmpty($scope.dateFormat))
          $scope.dateFormat = DEFAULT_DATE_FORMAT;
        if (angular.isEmpty($scope.dateFormatShort))
          $scope.dateFormatShort = SHORT_DATE_FORMAT;

        // Find building and building account IDs
        if (!angular.isEmpty($scope.model.building)) {
          buildingId = $scope.model.building;
          // Build link to referenced data
          $scope.link = getLink(activityType);
        }

        if (activityType === ACTIVITY_TYPE_NOTE) {
          // can send email if there is a request, there is a requester email, and the note has not been sent
          $scope.canSendEmail = canSendNoteToRequester($scope.model);

          // Check to see if the activity (note) is editable by the current user
          // user can delete any activities (notes) that they created OR any notes in a building they own or admin
          var ownedNotes =
            creator &&
            $scope.currentUser &&
            creator._id === $scope.currentUser._id;
          // allow the owner and anyone with remove_any_internal perms to delete messages
          $scope.canDelete =
            permissions.comment.remove_any_internal || ownedNotes;
          $scope.readonly = !ownedNotes;
        } else if (activityType === ACTIVITY_TYPE_WORK_ORDER_LOG) {
          if (
            permissions.work_order_log.update_any &&
            permissions.work_order_log.remove_any
          ) {
            $scope.canEdit = true;
            $scope.canDelete = true;
          } else {
            var currentUser = UserService.getCurrent();
            var workPerformedBy = $scope.model.work_performed_by;
            var identityId = currentUser.identity._id;
            var performedByUser =
              workPerformedBy === identityId ||
              workPerformedBy._id === identityId;
            var assignees = $scope.workOrder.assignees.map(function (assignee) {
              if (assignee._id) return assignee._id;
              return assignee;
            });
            var isAssigned = assignees.indexOf(identityId) > -1;
            // Edit (update) permissions
            if (permissions.work_order_log.update_any) {
              $scope.canEdit = true;
            } else if (performedByUser) {
              if (permissions.work_order_log.update_own_log_on_any_task) {
                $scope.canEdit = true;
              } else {
                $scope.canEdit =
                  permissions.work_order_log.update_own_log_on_own_task &&
                  isAssigned;
              }
            } else {
              $scope.canEdit = false;
            }
            // Delete (remove) permissions
            if (permissions.work_order_log.remove_any) {
              $scope.canDelete = true;
            } else if (performedByUser) {
              if (permissions.work_order_log.remove_own_log_on_any_task) {
                $scope.canDelete = true;
              } else {
                $scope.canDelete =
                  permissions.work_order_log.remove_own_log_on_own_task &&
                  isAssigned;
              }
            } else {
              $scope.canDelete = false;
            }
          }
        }
      }

      // ------------------------
      //   Private Functions
      // ------------------------

      function parseInitialText(text) {
        return text
          .replaceAll(`"mention">@`, `"mention">`) //this prevent to add extra @ when is already loaded
          .replaceAll(`class="mention">`, `class="mention">@`);
      }

      function fetchTemplate(type) {
        var templateUrl =
          "app/core/ui/directives/activity-list/templates/" + type + ".html";
        $http
          .get(templateUrl, {
            cache: $templateCache,
          })
          .then(function (response) {
            if (response.status === 200) {
              $element.html(response.data);
              $compile($element.contents())($scope);
            } else {
              $log.error(
                "<abx-activity-list-item> unable to retrieve '" +
                  templateUrl +
                  "'"
              );
            }
          })
          .catch(function () {
            $log.error(
              "<abx-activity-list-item> unable to retrieve '" +
                templateUrl +
                "'"
            );
          });
      }

      function getLink(type) {
        switch (type) {
          case ACTIVITY_TYPE_WORK_ORDER:
            return Router.href("app.workOrder", {
              buildingId: buildingId,
              workOrderId: $scope.model._id,
            });
          case ACTIVITY_TYPE_NOTE:
          case ACTIVITY_TYPE_USER_ACTION:
          case ACTIVITY_TYPE_CREATED_ENTITY:
          default:
            return null;
        }
      }

      function buildEntityLink(entity_type, entity_id, entity) {
        var href = null;
        var title = null;
        switch (entity_type) {
          case "level":
            title = entity ? entity.name : "Floor";
            href = Router.href("app.floor", {
              buildingId: buildingId,
              floorId: entity_id,
            });
            break;
          case "room":
            title = entity ? entity.display_name : "Room";
            href = Router.href("app.room", {
              buildingId: buildingId,
              roomId: entity_id,
            });
            break;
          case "asset":
            title = entity ? entity.name : "Asset";
            href = Router.href("app.asset", {
              buildingId: buildingId,
              assetId: entity_id,
            });
            break;
          case "task":
            title = entity ? entity.name : "Work Order";
            href = Router.href("app.workOrder", {
              buildingId: buildingId,
              workOrderId: entity_id,
            });
            break;
        }

        return '<a href="' + href + '">' + title + "</a>";
      }

      function buildHyperLink(url) {
        var href = url.startsWith("http") ? url : "http://" + url;
        var title = url;
        return '<a href="' + href + '" target="_blank">' + title + "</a>";
      }

      // ------------------------
      //   Public Functions
      // ------------------------

      function buildAttachmentReference() {
        var links = $scope.model.links;
        var workOrder = $scope.workOrder;

        if (links && links.length) {
          for (var i = 0; i < links.length; ++i) {
            var link = links[i];
            // If work order activity list, find location link
            if (workOrder) {
              if (LOCATION_TYPES.indexOf(link.entity_type) > -1) {
                var entityLink = buildEntityLink(
                  link.entity_type,
                  link.entity_id,
                  link.entity
                );
                return "to " + entityLink;
              }
            }
            // Otherwise find work order link
            else if (link.entity_type === "task") {
              var taskLink = buildEntityLink(
                "task",
                link.entity_id,
                link.entity
              );
              return "during " + taskLink;
            }
          }
        }

        return "";
      }

      function buildMessageReference() {
        var model = $scope.model;
        var workOrder = $scope.workOrder;
        if (model.task) {
          if (workOrder) {
            for (var i = 0; i < LOCATION_TYPES.length; ++i) {
              var entity_type = LOCATION_TYPES[i];
              var entity = $scope.model[entity_type];
              if (entity && entity._id) {
                var entityLink = buildEntityLink(
                  entity_type,
                  entity._id,
                  entity
                );
                return "to " + entityLink;
              }
            }
          } else {
            var taskLink = buildEntityLink("task", model.task._id, model.task);
            return "during " + taskLink;
          }
        }

        return "";
      }

      function buildWorkOrderReference() {
        var model = $scope.model;
        var link = buildEntityLink("task", model._id, model);
        var ref = "created " + link;
        var locationLinks = model.round.reduce(function (list, stop) {
          var link = null;
          if (stop.room) {
            link = buildEntityLink("room", stop.room._id, stop.room);
          } else if (stop.asset) {
            link = buildEntityLink("asset", stop.asset._id, stop.asset);
          } else if (stop.level) {
            link = buildEntityLink("level", stop.level._id, stop.level);
          }
          if (link) {
            list.push(link);
          }
          return list;
        }, []);

        if (locationLinks.length) {
          return ref + " for " + locationLinks.join(", ");
        }

        return ref;
      }

      function buildWorkOrderLinkReference() {
        var model = $scope.model;
        var link = buildHyperLink(model.url);
        var ref = "added an external link to a work order: " + link;
        return ref;
      }

      function canSendNoteToRequester() {
        return (
          $scope.model.request &&
          $scope.model.request.requester_email &&
          !(
            $scope.model.email_recipients &&
            $scope.model.email_recipients.length > 0
          )
        );
      }

      function isNoteCreator(note) {
        var creAccount = note.cre_account;
        var currentUser = $scope.currentUser;
        return creAccount && creAccount._id === currentUser._id;
      }

      function editable(note) {
        var hasBeenSent = note.is_public;
        var isCreator = isNoteCreator(note);
        var canEdit = !hasBeenSent && isCreator;

        // Disallow editing messages on closed inspection work orders
        if ($scope.workOrder && $scope.workOrder.inspection) {
          return canEdit && !$scope.workOrder.closed_date;
        }

        return canEdit;
      }

      function deletable(note) {
        var hasBeenSent = note.is_public;
        var isCreator = isNoteCreator(note);
        var canDelete = !hasBeenSent && ($scope.canDelete || isCreator);

        // Disallow editing messages on closed inspection work orders
        if ($scope.workOrder && $scope.workOrder.inspection) {
          return canDelete && !$scope.workOrder.closed_date;
        }

        return canDelete;
      }

      function showDeleteDialog(note) {
        var locals = {
          noteIDs: [note._id],
          building: note.building,
        };

        return DeleteNoteDialog.show({ locals: locals })
          .then(function () {
            $element.remove();
            $scope.delete({ itemID: $scope.model._id });
          })
          .catch(ToastService.showError);
      }

      function showDeleteLogDialog(log) {
        DeleteWorkOrderLogDialog.show({
          locals: {
            buildingId: log.building,
            workOrderLog: log,
          },
        })
          .then(function () {
            $element.remove();
            ShadowService.sendEvent("workorder", "delete-log");
          })
          .catch(ToastService.showError);
      }

      function showLogWorkDialog(log) {
        WorkOrderLogDialog.show({
          locals: {
            workOrder: $scope.model,
            workOrderLog: log,
            hideAttachments: true,
          },
        }).then(function () {
          ShadowService.sendEvent("workorder", "edit-log");
          // update the UI to show changes to the WO Log
          if (angular.isFunction($scope.refresh)) {
            $scope.refresh();
          }
        });
      }

      function save() {
        var data = {
          text: parseText($scope.model.text),
        };
        return NoteService.update(buildingId, modelId, data).then(function () {
          $scope.editing = false;
          $scope.refresh();
        });
      }

      function revertChange(userAction) {
        if (!$scope.model.canRevert) return;
        let isPinValue = userAction.diff.pin_value;
        let isPinValues = userAction.body.action
          ? userAction.body.action === "updateValues"
          : false;
        let request;

        if (isPinValue) {
          const data = { value: userAction.diff.pin_value.old.value };
          const valueId = userAction.diff.pin_value.old._id;

          request = AssetService.updateValue(
            userAction.building,
            userAction.asset._id,
            valueId,
            data
          );
        } else if (isPinValues) {
          const restoredValues = Object.entries(userAction.body.values).reduce(
            (acc, [key]) => {
              const assetValue = userAction.diff.asset.old.values.find(
                (value) => value._id === key
              );
              if (assetValue) {
                acc[key] = assetValue.value;
              }
              return acc;
            },
            {}
          );
          const newData = { values: restoredValues };

          request = AssetService.updateValues(
            userAction.building,
            userAction.asset._id,
            newData
          );
        } else {
          const difference = getChanges(userAction);
          request = getUndoRequest(difference, userAction);
        }

        request
          .then(() => {
            ToastService.showSimple("Change reverted");
          })
          .catch(ToastService.showError);
      }

      function getUndoRequest(diff, userAction) {
        switch (diff.action) {
          case models.ASSET.ACTIONS.UPDATE_ROOM:
            return AssetService.updateRoom(
              userAction.building,
              userAction.asset._id,
              {
                value: diff.oldValue,
              }
            );
          case models.ASSET.ACTIONS.UPDATE_FLOOR:
            return AssetService.updateFloor(
              userAction.building,
              userAction.asset._id,
              {
                value: diff.oldValue,
              }
            );
          case models.ASSET.ACTIONS.SET_LOCATION:
            return AssetService.setLocation(
              userAction.building,
              userAction.asset._id,
              {
                page: userAction.asset.page,
                percentX: diff.oldValue.percentX,
                percentY: diff.oldValue.percentY,
              },
              {},
              userAction.asset
            );
          default:
            //name
            return AssetService.updateById(
              userAction.building,
              userAction.asset._id,
              {
                name: diff.oldValue,
              },
              {},
              userAction.asset
            );
        }
      }

      /**
       * @param {Object} userAction userAction object
       * @returns The value according to the action body, name action is returned by default
       */
      function getChanges(userAction) {
        switch (userAction.body.action) {
          case models.ASSET.ACTIONS.UPDATE_ROOM:
            return {
              oldValue: userAction.diff.asset.old.room,
              newValue: userAction.diff.asset.new.room,
              fieldChanged: "Room",
              action: userAction.body.action,
            };
          case models.ASSET.ACTIONS.UPDATE_CONDITION:
            return {
              oldValue: userAction.diff.asset.old.condition,
              newValue: userAction.diff.asset.new.condition,
              fieldChanged: "Condition",
              action: userAction.body.action,
            };
          case models.ASSET.ACTIONS.UPDATE_FLOOR:
            return {
              oldValue: userAction.diff.asset.old.level,
              newValue: userAction.diff.asset.new.level,
              fieldChanged: "Floor",
              action: userAction.body.action,
            };
          case models.ASSET.ACTIONS.DECOMMISSION:
            return {
              oldValue: userAction.diff.asset.old.is_decommissioned,
              newValue: userAction.diff.asset.new.is_decommissioned,
              fieldChanged: "Decommission",
              action: userAction.body.action,
            };
          case models.ASSET.ACTIONS.RECOMMISSION:
            return {
              oldValue: userAction.diff.asset.old.is_decommissioned,
              newValue: userAction.diff.asset.new.is_decommissioned,
              fieldChanged: "Recommission",
              action: userAction.body.action,
            };
          case models.ASSET.ACTIONS.SET_LOCATION:
            return {
              oldValue: {
                percentX: userAction.diff.asset.old.percentX,
                percentY: userAction.diff.asset.old.percentY,
                page: userAction.diff.asset.old.page,
              },
              newValue: {
                percentX: userAction.diff.asset.new.percentX,
                percentY: userAction.diff.asset.new.percentY,
                page: userAction.diff.asset.new.page,
              },
              fieldChanged: "Location",
              action: userAction.body.action,
            };
          default:
            return {
              oldValue: userAction.diff.asset.old.name,
              newValue: userAction.diff.asset.new.name,
              fieldChanged: "Name",
              action: "name",
            };
        }
      }

      function toggleContent() {
        // Toggle Expanded
        $scope.isExpanded = !$scope.isExpanded;

        // Find the reference to the collapsible drawer
        var $content = $element.find("div.activity-list-item__content");

        // When collapsed
        if ($scope.isExpanded) {
          // Calculate the height of child elements
          var newElementHeight = 32; // i.e 16px top padding + 16px bottom padding)
          var children = $content[0].children;
          for (var i = 0; i < children.length; ++i) {
            var child = children[i];
            newElementHeight += child.scrollHeight;
          }
          $content[0].style.height = newElementHeight + "px";
          $content.addClass("activity-list-item__content--expanded");
        } else {
          $content[0].style.height = 0;
          $content.removeClass("activity-list-item__content--expanded");
        }
      }

      function parseText(text) {
        let textReplaced = text.replaceAll("\n", "<br>").replaceAll(/\s/g, " ");
        const oldMentions = textReplaced.matchAll(
          /(<div[a-zA-Z="\s]+>@[a-zA-Z\s]+<\/div>)/g
        );
        const mentionsAdded = textReplaced.matchAll(/(@[A-Za-z]+\s[A-Za-z]+)/g);
        textReplaced = textReplaced.replaceAll("</div>", "");
        for (const mention of oldMentions) {
          const userMentioned = $scope.users.find((user) =>
            mention[0].includes(user.identity.display_name)
          );
          textReplaced = textReplaced.replace(
            `<div class="mention">@${userMentioned.identity.display_name}`,
            `<@${userMentioned._id}>`
          );
        }
        for (const mention of mentionsAdded) {
          const userMentioned = $scope.users.find((user) =>
            mention[0].includes(user.identity.display_name)
          );
          textReplaced = textReplaced.replace(
            `@${userMentioned.identity.display_name}`,
            `<@${userMentioned._id}>`
          );
        }
        return textReplaced.replaceAll("<br>", "\n");
      }
    }
  }
})();
