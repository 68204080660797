(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costAdjustment")
    .controller(
      "AssetCostAdjustmentDialogController",
      AssetCostAdjustmentDialogController
    );

  /* @ngInject */
  function AssetCostAdjustmentDialogController(
    $q,
    $mdDialog,
    $timeout,
    // Service
    CostAdjustmentService,
    CostAdjustmentRuleService,
    CostLineService,
    OrganizationService,
    ToastService,
    Utils,
    // Dialog
    ConfirmationDialog,
    // Locals
    assets
  ) {
    const self = this;

    //Public variables
    /**
     * The list of cost adjustments to display
     * @type { CostAdjustment[] }
     */
    self.costAdjustments = [];
    /**
     * Assets that are currently having their cost adjustments
     * managed.
     * @type { Asset[] }
     */
    self.assets = assets;

    /**
     * @typedef { object } FormData
     * @property { string } description
     * @property { string } adjustment_unit
     * @property { number | null } adjustment_value
     */
    /**
     * Cost adjustment form data
     * @type { FormData }
     */
    self.formData = {};
    /**
     * @type { number | null }
     */
    self.baseCost = null;
    /**
     * True while the dialog is fetching cost adjustments
     * @type { boolean }
     */
    self.isLoading = true;
    /**
     * True while a save action is underway
     * @type { boolean }
     */
    self.isSaving = false;
    self.isUpdating = false;
    /**
     * Progress bar % to display. 0 for empty bar, 1 for full.
     * @type { number }
     */
    self.progress = 0;

    /**
     * @public
     * @type { boolean } This variable remove the message when you commit and the form is dirty.
     */
    self.isRequired = true;
    self.isDeleting = false;

    /**
     * True when dialog is open for only one asset
     * @type { boolean }
     */
    self.isSingleAsset = assets.length === 1;

    /**
     * @type { Organization }
     */
    self.organization = OrganizationService.getCurrent();
    /**
     * @type { number | null }
     */
    self.totalCostAdjustment = self.baseCost;
    /**
     * @type { CostAdjustment | {} }
     */
    self.deletedCostAdjustment = {};

    /**
     * @public
     * @type { object[] } Store assets with failed requests to retry them later
     */
    self.assetsWithFailedRequests = [];

    /**
     * @public
     * @type { string } Store error message when a request fails to display it
     *  in an error banner inside the dialog
     */
    self.errorMessage = null;

    /**
     * @public
     * @type { CostAdjustment } Cost adjustment object that was edited & saved.
     * Persists beyond `self.costAdjustmentToEdit` so that it can be used
     * for controlling the "√ Saved" icon.
     */
    self.editedCostAdjustment = null;

    /**
     * @public
     * @type { CostAdjustment } Cost adjustment object that will be edited and saved.
     * Set when the edit button is clicked and controls whether edit mode is enabled.
     */
    self.costAdjustmentToEdit = null;

    if (self.isSingleAsset) self.baseCost = self.assets[0].cost;

    /**
     * @public
     * @typedef { object } editForm
     * @property { string } description Cost adjustment description
     * @property { number } adjustmentValue Cost adjustment value (percentage | fixed)
     */

    /**
     * @type { editForm }
     */
    self.editForm = {};

    //Private variables
    /**
     * @type { object }
     * @property { boolean } description Validity of description input
     * @property { boolean } adjustmentValue Validity of adjustment value input
     * @property { boolean } isDirty Whether or not edit form has been changed
     */
    let editFormValidityMap = {
      description: true,
      adjustmentValue: true,
      isDirty: false,
    };

    /**
     * @type { number }
     */
    let sumAdjustmentPercent = 0;

    /**
     * @type { number }
     */
    let sumAdjustmentFixed = 0;

    //Private functions
    /**
     * @private
     * @param { CostAdjustment } costAdjustment Cost adjustment to display saved icon for.
     */
    const displaySavedIcon = (costAdjustment) => {
      self.editedCostAdjustment = costAdjustment;
      $timeout(() => {
        self.editedCostAdjustment = null;
      }, 2000);
    };

    /**
     * @private Returns the baseCost multiplied by the sum of the adjustmentPercent and 1, plus the sum of fixed adjustments.
     * @returns {Number} Cost adjustment on top of the base cost of the item
     */
    function calculateCostAdjustment() {
      return self.baseCost
        ? self.baseCost * (sumAdjustmentPercent + 1) + sumAdjustmentFixed
        : 0.0;
    }

    /**
     * @private The function returns true if all the assets are in the same building
     * @returns {Boolean} A boolean value.
     */
    function assetsInSameBuilding() {
      return self.assets.every((asset) =>
        self.assets.every(
          (nextAssets) => asset.building === nextAssets.building
        )
      );
    }

    /**
     * @private It returns an array of all the unique pin types in the assets array
     * @returns {String[]} An array of all the pinType ids.
     */
    function getAllAssetsPinTypes() {
      return self.assets.reduce((pinTypes, asset) => {
        const { pinType } = asset;

        if (pinTypes.length === 0 || !pinTypes.includes(pinType._id)) {
          pinTypes.push(pinType._id);
        }

        return pinTypes;
      }, []);
    }

    /**
     * @private Calculates the cost adjustment for a single asset or multiple assets
     * @param {CostAdjustmentRule} rule - The cost adjustment rule object
     * @returns {CostAdjustmentRule} An adjustment object
     */
    function getAdjustmentByRule(rule) {
      let adjustment;

      if (self.isSingleAsset) {
        sumAdjustmentPercent += rule.adjustment_percent;
        adjustment = {
          ...rule,
          costAdjustment: calculateCostAdjustment(),
        };
      } else {
        adjustment = {
          ...rule,
          costAdjustment: rule.adjustment_percent,
        };
      }

      return adjustment;
    }

    //Public functions
    self.add = handleAddClick;
    self.onChange = onChange;
    self.onUnitChange = onUnitChange;
    self.retryFailedRequests = handleRetryFailedRequests;
    self.closeDialog = handleCloseDialog;

    self.handleDeleteClick = handleDeleteClick;
    self.deleteCostAdjustment = deleteCostAdjustment;
    self.populateSingleCostAdjustment = populateSingleCostAdjustment;
    self.populateCommonCostAdjustments = populateCommonCostAdjustments;

    init();

    /**
     * Initializes cost adjustment dialog.
     * @returns { Promise<CostLine | undefined> }
     */
    function init() {
      self.isSaving = false;
      self.isUpdating = false;
      self.isDeleting = false;

      self.formData = {
        description: "",
        adjustment_unit: "%",
        adjustment_value: null,
      };
      if (self.isSingleAsset) {
        return populateSingleCostAdjustment();
      }
      return populateCommonCostAdjustments();
    }

    /**
     * Handles when a user clicks close button during the processing of this dialog
     */
    function handleCloseDialog() {
      return $mdDialog.hide(self.successfulAction);
    }

    /**
     * Handles when a user clicks the retry button after some request to create cost adjusment fails
     */
    function handleRetryFailedRequests() {
      self.errorMessage = null;

      switch (true) {
        case self.isSaving:
          createCostAdjustment(self.assetsWithFailedRequests);
          break;
        case self.isUpdating:
          updateCommonCostAdjustments(self.assetsWithFailedRequests);
          break;
        case self.isDeleting:
          self.handleDeleteRetry();
          break;
      }
    }

    /**
     * It takes in an array of cost adjustment rules and an array of assets pin types (_ids). Then, returns an
     * array of cost adjustment rules that have either building rules or category rules that are common
     * among all assets pin types
     * @param {CostAdjustmentRule[]} rules - The rules that are returned from the API
     * @param {String[]} assetsPinTypes - An array of pin types (_ids) that are associated with the assets
     * @returns {CostAdjustmentRule[]} An filtered array of cost adjustment rules.
     */
    function filterCostAdjustmentRules(rules, assetsPinTypes) {
      let buildingAndOrgRules = rules
        .filter((costAdjustmentRule) => !costAdjustmentRule.pin_type)
        .filter((rule) => {
          if (!rule.building) return true;
          else {
            return rule.building._id === self.assets[0].building;
          }
        });

      const categoryRules = rules.filter(
        (rule) =>
          rule.pin_type &&
          assetsPinTypes.every(
            (assetsPinType) => rule.pin_type._id === assetsPinType
          )
      );

      return [...buildingAndOrgRules, ...categoryRules];
    }

    function populateCostAdjustmentRules() {
      // Don't proceed if all selected assets are not in the same building
      // Impossible to have common cost adjustment rules if selected assets are in different buildings
      if (!assetsInSameBuilding()) return;

      const assetsPinTypes = getAllAssetsPinTypes();

      return CostAdjustmentRuleService.getAll(self.organization._id, {
        sort: "cre_date,asc",
      }).then((costAdjustmentRules) => {
        if (
          !Array.isArray(costAdjustmentRules) ||
          costAdjustmentRules.length === 0
        )
          return;

        const adjustments = [];
        const filteredRules = filterCostAdjustmentRules(
          costAdjustmentRules,
          assetsPinTypes
        );

        for (const rule of filteredRules) {
          const adjustment = getAdjustmentByRule(rule);

          if (!rule.building && !rule.pin_type && rule.organization) {
            adjustments.push({
              ...adjustment,
              scope: `All assets`,
              isOrganizationRule: true,
            });
          } else if (!rule.pin_type) {
            adjustments.push({
              ...adjustment,
              scope: `All assets`,
              isBuildingRule: true,
            });
          } else {
            adjustments.push({
              ...adjustment,
              scope: `All ${rule.pin_type.name}`,
              isAssetRule: true,
            });
          }
        }

        if (self.isSingleAsset) {
          self.totalCostAdjustment = calculateCostAdjustment();
        }

        return self.costAdjustments.push(...adjustments);
      });
    }

    function populateSingleCostAdjustment() {
      if (!self.organization) {
        self.closeDialog();
        ToastService.showError(
          "abxAssetCostAdjustment: organization is required"
        );
        return;
      }

      self.isLoading = true;
      sumAdjustmentPercent = 0;
      sumAdjustmentFixed = 0;
      return CostLineService.getOneByAsset(self.assets[0])
        .then((costLine) => {
          self.baseCost =
            costLine && costLine.total_cost ? costLine.total_cost : 0;
          return CostAdjustmentService.get(self.organization._id, {
            asset: self.assets[0]._id,
            sort: "adjustment_percent,desc",
          });
        })
        .then((resultCostAdjustments) => {
          if (!resultCostAdjustments) return;
          let costAdjustmentItems = [];
          const scope = "Only this asset";

          resultCostAdjustments.forEach(function (adjustmentItem) {
            sumAdjustmentPercent += adjustmentItem.adjustment_percent || 0;
            sumAdjustmentFixed += adjustmentItem.adjustment_fixed || 0;
            costAdjustmentItems.push({
              ...adjustmentItem,
              costAdjustment: calculateCostAdjustment(),
              scope,
            });
          });
          self.costAdjustments = costAdjustmentItems;
          self.totalCostAdjustment = calculateCostAdjustment();
          return populateCostAdjustmentRules();
        })
        .catch(ToastService.showError)
        .finally(() => {
          self.isLoading = false;
        });
    }

    function populateCommonCostAdjustments() {
      if (!self.organization) {
        self.closeDialog();
        ToastService.showError(
          "abxAssetCostAdjustment: organization is required"
        );
        return;
      }

      self.isLoading = true;
      const ids = self.assets.map((asset) => {
        return asset._id;
      });

      return CostAdjustmentService.getCommon(self.organization._id, {
        asset: ids,
      })
        .then((commonCostAdjustments) => {
          self.costAdjustments = commonCostAdjustments.map(
            (costAdjustment) => ({
              _id: createCommonCostAdjustmentId(
                costAdjustment.adjustment_percent,
                costAdjustment.description
              ),
              scope: `${self.assets.length} Assets`,
              ...costAdjustment,
            })
          );

          return populateCostAdjustmentRules();
        })
        .catch(ToastService.showError)
        .finally(() => {
          self.isLoading = false;
        });
    }

    /**
     * Creates cost adjustment for all the specified assets
     * @private
     * @param { object[] } assets
     */
    function createCostAdjustment(assets) {
      self.progress = 0;
      const progressIncrement = 1 / assets.length;
      self.assetsWithFailedRequests = [];
      self.isSaving = true;
      self.isRequired = false;

      const allPromises = [];

      let adjustmentData = {};
      if (self.formData.adjustment_unit === "%") {
        // convert adjustment_percent to decimal before making API call
        adjustmentData.adjustment_percent =
          self.formData.adjustment_value / 100;
      } else if (self.formData.adjustment_unit === "$") {
        // convert adjustment_fixed to nearest rounded int before making API call
        adjustmentData.adjustment_fixed = parseFloat(
          Number(self.formData.adjustment_value).toFixed(2)
        );
      }

      assets.forEach((asset) => {
        const createPromise = CostAdjustmentService.create(
          self.organization._id,
          {
            asset: asset._id,
            description: self.formData.description,
            ...adjustmentData,
          }
        )
          .then((_res) => {
            self.progress += progressIncrement;
          })
          .catch((err) => {
            ToastService.showError(err);
            self.assetsWithFailedRequests.push(asset);
          });

        allPromises.push(createPromise);
      });

      allSettled(allPromises).then((resp) => {
        if (self.assetsWithFailedRequests.length > 0) {
          self.errorMessage = getErrorMessage(
            self.assetsWithFailedRequests,
            self.assets
          );
        } else {
          init();
          self.successfulAction = true;
        }
      });
    }

    /**
     * Handles the submit action of the form when the add button is clicked
     * @public
     */
    function handleAddClick() {
      if (
        isNaN(self.formData.adjustment_value) ||
        !self.formData.description ||
        self.isSaving
      )
        return;

      createCostAdjustment(self.assets);
    }

    function updateSingleCostAdjustment() {
      self.assetsWithFailedRequests = [];
      self.isUpdating = true;
      const costAdjustmentToEdit = self.costAdjustmentToEdit;

      const editedAdjustment = {};
      const { adjustmentValue } = self.editForm;

      if (costAdjustmentToEdit.adjustment_percent !== null) {
        editedAdjustment.adjustment_percent = adjustmentValue / 100;
      } else if (costAdjustmentToEdit.adjustment_fixed !== null) {
        editedAdjustment.adjustment_fixed = parseFloat(
          Number(adjustmentValue).toFixed(2)
        );
      }

      const updatePromise = CostAdjustmentService.update(
        self.organization._id,
        costAdjustmentToEdit._id,
        {
          description: self.editForm.description,
          ...editedAdjustment,
        }
      ).catch(() => {
        const asset = {
          _id: costAdjustmentToEdit.asset || costAdjustmentToEdit.asset._id,
        };
        self.assetsWithFailedRequests.push(asset);
      });

      return allSettled([updatePromise]).then((resp) => {
        if (self.assetsWithFailedRequests.length > 0) {
          self.errorMessage = `Error, could not update ${
            self.assetsWithFailedRequests.length
          } ${self.assetsWithFailedRequests.length === 1 ? "asset" : "assets"}`;
        } else {
          self.successfulAction = true;
          init().then(() => {
            displaySavedIcon(self.costAdjustmentToEdit);
            self.costAdjustmentToEdit = null;
            self.isUpdating = false;
          });
        }
      });
    }

    function updateCommonCostAdjustments(assets) {
      const { costAdjustmentToEdit } = self;
      self.assetsWithFailedRequests = [...assets];

      self.progress = 0;
      const progressIncrement = 1 / assets.length;

      self.isUpdating = true;

      const completeAsset = (asset) => {
        self.assetsWithFailedRequests = self.assetsWithFailedRequests.filter(
          (failedAsset) => failedAsset._id !== asset._id
        );
      };

      /**
       * Array of functions to perform update actions. Each
       * will fetch & update the cost adjustment for one asset.
       *
       * Once complete, it will remove the asset from the failed list.
       * @type { () => Promise<void> }
       */
      const series = assets.map((asset) => {
        return () =>
          CostAdjustmentService.getCommonAdjustmentInstance(
            self.organization._id,
            asset._id,
            costAdjustmentToEdit
          ).then((costAdjustments) => {
            self.progress += progressIncrement / 2;
            if (!costAdjustments.length) {
              completeAsset(asset);
              return;
            }
            const editedAdjustment = {};
            const [costAdjustment] = costAdjustments;
            const { adjustmentValue } = self.editForm;

            if (costAdjustment.adjustment_percent !== null) {
              editedAdjustment.adjustment_percent = adjustmentValue / 100;
            } else if (costAdjustment.adjustment_fixed !== null) {
              editedAdjustment.adjustment_fixed = parseFloat(
                Number(adjustmentValue).toFixed(2)
              );
            }
            return CostAdjustmentService.update(
              self.organization._id,
              costAdjustment._id,
              {
                description: self.editForm.description,
                ...editedAdjustment,
              }
            ).then(() => {
              self.progress += progressIncrement / 2;
              completeAsset(asset);
            });
          });
      });

      const seriesPromise = series.reduce((p, fn) => p.then(fn), $q.resolve());

      return seriesPromise
        .then(init, (err) => {
          ToastService.showError(err);
          self.errorMessage = getErrorMessage(
            self.assetsWithFailedRequests,
            self.assets
          );
          self.editedCostAdjustment = null;
          throw err;
        })
        .then(() => {
          self.isUpdating = false;
          self.successfulAction = true;
          // Created a new _id base on what was the change,
          // to ensure the _id will match after the list is updated
          // and SavedIcon will be in the right place
          const costAdjustmentToDisplay = angular.copy(
            self.costAdjustmentToEdit
          );
          costAdjustmentToDisplay._id = createCommonCostAdjustmentId(
            self.editForm.adjustmentValue,
            self.editForm.description
          );
          displaySavedIcon(costAdjustmentToDisplay);
          self.costAdjustmentToEdit = null;
        });
    }

    function getErrorMessage(failedAssets, assets) {
      const failedAssetCount = failedAssets.length;
      const totalAssetCount = assets.length;
      const modelName = totalAssetCount === 1 ? "asset" : "assets";
      const errorMessage = `Error, could not update ${failedAssetCount} out of ${totalAssetCount} ${modelName}`;
      return errorMessage;
    }
    function handleDeleteClick(costAdjustment) {
      ConfirmationDialog.show({
        title:
          this.assets.length > 1
            ? `Remove cost adjustment from all ${self.assets.length} assets?`
            : `Remove cost adjustment from ${self.assets[0].name}`,
        prompt:
          self.assets.length > 1
            ? `Are you sure you want remove the cost adjustment from each of these ${self.assets.length} assets?`
            : `Are you sure you want to remove ${self.assets[0].name}'s cost adjustment?`,
        isDestructive: true,
        confirmText: "Remove",
        onConfirm: () => {
          handleDelete(costAdjustment);
        },
        customClass: "delete-cost-adjustment-dialog",
      });
    }

    function handleDelete(costAdjustment) {
      if (self.isDeleting) return;
      self.progress = 0;

      self.isDeleting = true;
      self.deletedCostAdjustment = costAdjustment;
      const deleteFn = self.isSingleAsset
        ? deleteCostAdjustment
        : deleteCommonCostAdjustment;

      const refreshFn = self.isSingleAsset
        ? populateSingleCostAdjustment
        : populateCommonCostAdjustments;

      let didError;
      deleteFn(costAdjustment)
        .then(() => {
          // We should only call refreshFn in cases that we're not
          // handling already by deleteFn (in this case, for single assets we are already)
          if (!self.isSingleAsset) {
            refreshFn();
          }
          self.successfulAction = true;
        })
        .catch((err) => {
          ToastService.showError(err);
          didError = true;
        })
        .finally(() => {
          if (didError && !self.isSingleAsset) {
            self.isDeleting = true;
          } else {
            self.isDeleting = false;
          }
          self.deletedCostAdjustment = {};
        });
    }

    function deleteCommonCostAdjustment(
      commonAdjustment,
      assets = self.assets
    ) {
      // used for retries, array of assets that still need to have their cost
      // adjustments fetched
      let assetsToFetch = [...assets];

      const progressIncrement = 1 / assets.length;

      const deleteCostAdjustment = (costAdjustment) => {
        if (!costAdjustment) {
          return;
        }
        return CostAdjustmentService.remove(
          self.organization._id,
          costAdjustment._id
        ).then(() => {
          self.successfulAction = true;
        });
      };

      const deleteAdjustmentForAsset = (asset) => {
        return CostAdjustmentService.getCommonAdjustmentInstance(
          self.organization._id,
          asset._id,
          commonAdjustment
        )
          .then((costAdjustments) => {
            self.progress += progressIncrement / 2;

            if (costAdjustments.length) {
              return costAdjustments[0];
            }
          })
          .then(deleteCostAdjustment)
          .then(() => {
            self.progress += progressIncrement / 2;
            assetsToFetch = assetsToFetch.filter((el) => el._id !== asset._id);
          });
      };

      const series = [];
      for (const asset of assets) {
        series.push(() => deleteAdjustmentForAsset(asset));
      }

      let promise = $q.resolve();
      for (const fn of series) {
        promise = promise.then((...args) => fn(...args));
      }

      promise = promise.catch((err) => {
        const failedAssetCount = assetsToFetch.length;
        const totalAssetCount = assets.length;
        const modelName = failedAssetCount === 1 ? "asset" : "assets";
        self.errorMessage = `Error, could not update ${failedAssetCount} out of ${totalAssetCount} ${modelName}`;
        self.handleDeleteRetry = () => {
          self.progress = 0;
          return deleteCommonCostAdjustment(commonAdjustment, assetsToFetch)
            .then(populateCommonCostAdjustments)
            .then(() => {
              self.isDeleting = false;
              self.errorMessage = null;
            });
        };
        throw err;
      });

      return promise;
    }

    /**
     * Allows us to process all the promise calls without exiting on a single
     * rejection. Check out Promise.allSettled for more implementation details
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
     *
     * @param {Array<Promise<*>>} promises
     * @return {Promise<void>}
     */
    function allSettled(promises) {
      const results = promises.map((promise) => {
        return promise.then(
          (result) => {
            return {
              status: "fulfilled",
              value: result,
            };
          },
          (err) => {
            return {
              status: "rejected",
              reason: err,
            };
          }
        );
      });

      return $q.all(results);
    }

    /**
     * Generate a virtual _id for a common cost adjustment.
     *
     * @param {number} Cost adjustment value
     * @param {string} Cost adjustment description
     *
     * @return {number} hash code
     */
    function createCommonCostAdjustmentId(adjustment, description) {
      return Utils.hashCode(`${adjustment}${description}`);
    }

    /**
     * Saves cost adjustment edit.
     * @public
     */
    self.handleSaveClick = () => {
      if (self.isSingleAsset) {
        return updateSingleCostAdjustment();
      }
      return updateCommonCostAdjustments(self.assets);
    };

    /**
     * Updates validity map and cost adjustment value (percentage | fixed) input state on input change.
     * @public
     * @param { object } event The change event
     */
    self.handleAdjustmentChange = (event) => {
      self.editForm.adjustmentValue = parseFloat(event.model);
      editFormValidityMap.adjustmentValue = !event.invalid;
      editFormValidityMap.isDirty = true;
    };

    /**
     * Updates validity map and cost adjustment description input state on input change.
     * @param { object } event The change event
     */
    self.handleDescriptionChange = (event) => {
      self.editForm.description = event.model;
      editFormValidityMap.description = !event.invalid;
      editFormValidityMap.isDirty = true;
    };

    function onUnitChange({ model }) {
      self.formData.adjustment_unit = model;
    }

    function onChange($event, field) {
      self.formData[field] = $event.model;
      self.isRequired = true;
    }

    function deleteCostAdjustment(costAdjustment) {
      self.isDeleting = true;
      self.isUpdating = false;
      self.deletedCostAdjustment = costAdjustment;
      return CostAdjustmentService.remove(
        self.organization._id,
        costAdjustment._id
      )
        .then(() => {
          self.successfulAction = true;
          populateSingleCostAdjustment();
        })
        .catch(ToastService.showError)
        .finally(() => {
          self.isDeleting = false;
          self.deletedCostAdjustment = {};
          self.errorMessage = null;
        });
    }

    /**
     * Sets currently editing cost adjustment, sets edit form to input values, and resets edit form validity.
     * @public
     * @param { CostAdjustment } costAdjustment Cost adjustment to enable edit mode for.
     */
    self.handleEditClick = (costAdjustment) => {
      editFormValidityMap = {
        description: true,
        adjustmentValue: true,
        isDirty: false,
      };
      self.costAdjustmentToEdit = costAdjustment;

      const adjustmentData = {};
      if (costAdjustment.adjustment_percent !== null) {
        adjustmentData.adjustmentValue = Utils.roundNumber(
          costAdjustment.adjustment_percent * 100,
          2
        );
      } else if (costAdjustment.adjustment_fixed !== null) {
        adjustmentData.adjustmentValue = costAdjustment.adjustment_fixed;
      }

      self.editForm = {
        description: costAdjustment.description,
        ...adjustmentData,
      };
      self.errorMessage = null;
      self.isUpdating = false;
    };

    /**
     * Cancels edit mode for `self.costAdjustmentToEdit`.
     * @public
     */
    self.handleCancelEditClick = () => {
      self.costAdjustmentToEdit = null;
    };

    /**
     * Shows/hides updated cost for `costAdjustment`.
     * @public
     * @param { CostAdjustment } costAdjustment Cost adjustment to show/hide updated cost for.
     * @returns { boolean }
     */
    self.showUpdatedCost = (costAdjustment) => {
      const isInEditMode =
        self.costAdjustmentToEdit?._id === costAdjustment._id;
      const hasBaseCost = Boolean(self.baseCost);
      return !isInEditMode && hasBaseCost;
    };

    /**
     * Determines validity of edit form.
     * @public
     * @returns { boolean }
     */
    self.isEditFormValid = () => {
      return (
        editFormValidityMap.isDirty &&
        editFormValidityMap.description &&
        editFormValidityMap.adjustmentValue
      );
    };

    self.roundNumber = Utils.roundNumber;
  }
})();
