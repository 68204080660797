(function () {
  angular
    .module("akitabox.ui.dialogs.inspectionProgram.cancel")
    .controller(
      "CancelInspectionProgramDialogController",
      CancelInspectionProgramDialogController
    );

  /* @ngInject */
  function CancelInspectionProgramDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    InspectionProgramService,
    OrganizationService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.cancel = $mdDialog.cancel;
    self.confirm = confirm;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Cancel inspection program
     */
    function confirm() {
      self.saving = true;

      var program = self.inspectionPrograms[0];

      if (self.building && !self.buildingId) {
        self.buildingId = self.building._id;
      }

      InspectionProgramService.deactivate(program._id, self.organization._id)
        .then(function (canceledinspectionProgramID) {
          $mdDialog.hide(canceledinspectionProgramID);
        })
        .catch(ToastService.showError)
        .finally(function (canceledinspectionProgramID) {
          self.saving = false;
          return canceledinspectionProgramID;
        });
    }
  }
})();
