import { getENV, ENV } from "./getEnv";

/**
 * Gets the appropriate Sisense URL
 * based on the current environment
 *
 * @returns Sisense URL
 */
export function getInsightsEnv(env = getENV()) {
  const sisenseEnvs = new Map<ENV, string>([
    ["LOCAL", "https://akitabox-dev3.sisense.com"],
    ["TEMP", "https://akitabox-dev2.sisense.com"],
    ["BETA", "https://akitabox-dev1.sisense.com"],
    ["PRODUCTION", "https://akitabox.sisense.com"],
  ]);

  const sisenseEnv = sisenseEnvs.get(env);
  if (!sisenseEnv) {
    throw new Error(`Invalid "${env}" environment for sisense`);
  }

  return sisenseEnv;
}
