(function () {
  angular
    .module("akitabox.core.services.roundTemplate", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("RoundTemplateService", RoundTemplateService);

  /* @ngInject */
  function RoundTemplateService(models, HttpService) {
    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildDetailRoute: buildDetailRoute,
      buildStopsRoute: buildStopsRoute,
      // Retrieve
      getAll: getAll,
      getById: getById,
      getStops: getStops,
      getStopLocation: getStopLocation,
      getStopLocationId: getStopLocationId,
      getStopLocationType: getStopLocationType,
      stopLocationsAreEqual: stopLocationsAreEqual,
      // Update
      setStops: setStops,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildStopsRoute(buildingId, roundTemplateId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.ROUND_TEMPLATE.ROUTE_PLURAL +
        "/" +
        roundTemplateId +
        "/" +
        models.ROUND_TEMPLATE_STOP.ROUTE_PLURAL
      );
    }

    function buildBaseRoute(orgId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        orgId +
        "/" +
        models.ROUND_TEMPLATE.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(orgId, roundTemplateId) {
      var base = buildBaseRoute(orgId);
      return base + "/" + roundTemplateId;
    }

    /******************* *
     *   Retrieve
     * ****************** */

    /**
     * Gets all RoundTemplates that match the provided params
     *
     * @param {String} orgId  Org id for the Round Templates
     * @param {Object} params Object containing additional filters
     */
    function getAll(orgId, params) {
      var route = buildBaseRoute(orgId);
      return HttpService.getAll(route, params);
    }

    /**
     * Gets a RoundTemplate by its ID
     *
     * @param {String} orgId            org id for the Round Template
     * @param {String} roundTemplateId  ID of the Round Template
     * @param {Object} params           Object containing additional filters
     */
    function getById(orgId, roundTemplateId, params) {
      var route = buildDetailRoute(orgId, roundTemplateId);
      return HttpService.getById(route, roundTemplateId, params);
    }

    function getStops(buildingId, roundTemplateId, params) {
      var route = buildStopsRoute(buildingId, roundTemplateId);
      return HttpService.getById(route, roundTemplateId + "/stops", params);
    }

    /**
     * Update stops for a round template
     * @param { string } buildingId
     * @param { string } roundTemplateId
     * @param { RoundTemplateStop[] } stops
     * @return { Promise<void> }
     */
    function setStops(buildingId, roundTemplateId, stops) {
      var route = buildStopsRoute(buildingId, roundTemplateId);
      var stopsToSend = [];
      for (var i = 0; i < stops.length; i++) {
        // if stops come in populated, depopulate them
        var stop = stops[i];
        var stopToSend = {};
        ["asset", "room", "level"].forEach(function (field) {
          var location = stop[field];
          if (location) {
            stopToSend[field] = location._id || location;
          }
        });
        stopsToSend.push(stopToSend);
      }
      return HttpService.post(route, stopsToSend).then(function () {});
    }

    function getStopLocation(stop) {
      return stop.asset || stop.room || stop.level;
    }

    function getStopLocationId(stop) {
      var location = getStopLocation(stop);
      if (!location) {
        return location;
      }
      return location._id || location;
    }

    function getStopLocationType(stop) {
      if (stop.asset) return "asset";
      if (stop.room) return "room";
      return "level";
    }

    function stopLocationsAreEqual(stopA, stopB) {
      return (
        getStopLocationType(stopA) === getStopLocationType(stopB) &&
        getStopLocationId(stopA) === getStopLocationId(stopB)
      );
    }
  }
})();
