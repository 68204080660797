(function () {
  angular
    .module("akitabox.core.services.workOrdersBff", [
      "akitabox.core.services.http",
    ])
    .factory("WorkOrdersBFFService", WorkOrdersBFFService);

  function WorkOrdersBFFService(
    // Libraries
    moment,
    //Services
    HttpService
  ) {
    var services = {
      getByOrganization: getByOrganization,
      getByBuilding: getByBuilding,
      getAllByBuilding: getAllByBuilding,
      addCalculatedProperties,
    };

    return services;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute(organizationId) {
      return `organizations/${organizationId}/web/work_orders`;
    }

    function buildListRoute(organizationId) {
      return buildBaseRoute(organizationId);
    }

    function getStatusQuery(params) {
      if (angular.isEmpty(params) || angular.isEmpty(params.status)) {
        return params;
      }

      // we don't want to edit the original params as this can break our logic later
      var paramCopy = angular.copy(params);

      var tomorrow = moment().utc().endOf("day");

      if (
        paramCopy.status === "$in,scheduled,open" ||
        paramCopy.status === "$in,open,scheduled"
      ) {
        paramCopy.status = "open";
      } else if (params.status === "scheduled") {
        paramCopy.status = "open";
        if (angular.isEmpty(paramCopy.start_date)) {
          paramCopy.start_date = "$gt," + tomorrow.valueOf();
        }
      } else if (
        paramCopy.status === "open" &&
        angular.isEmpty(paramCopy.start_date)
      ) {
        paramCopy.start_date = "$lte," + tomorrow.valueOf();
      }

      return paramCopy;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getByOrganization(organizationId, params) {
      const route = buildBaseRoute(organizationId);
      return HttpService.get(route, getStatusQuery(params));
    }

    function getByBuilding(organizationId, buildingId, params) {
      const route = buildBaseRoute(organizationId);
      const newParams = {
        building: `$in,${buildingId}`,
        ...params,
      };
      return HttpService.get(route, getStatusQuery(newParams));
    }

    function getAllByBuilding(organizationId, buildingId, params, useCache) {
      var route = buildListRoute(organizationId);
      const newParams = {
        building: `$in,${buildingId}`,
        ...params,
      };
      return HttpService.getAll(route, getStatusQuery(newParams), useCache);
    }

    function addCalculatedProperties(workOrders) {
      return workOrders.map((wo) => {
        const is_overdue_date = new Date(wo.due_date) < new Date();
        const is_scheduled_date = new Date(wo.start_date) > new Date();
        const is_open = wo.status === "open";
        const is_canceled = wo.status === "canceled";
        return {
          ...wo,
          display_number: `WO-${wo.number}`,
          is_canceled: is_canceled,
          is_overdue: is_open && is_overdue_date,
          is_scheduled: is_open && is_scheduled_date,
        };
      });
    }
  }
})();
