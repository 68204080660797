(function () {
  angular
    .module("akitabox.ui.dialogs.assetCondition")
    .controller(
      "AssetConditionDialogController",
      AssetConditionDialogController
    );

  /** @ngInject */
  function AssetConditionDialogController(
    // Material
    $mdDialog,
    // Services
    AssetConditionService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.saveEnabled = false;
    self.condition = null;
    self.assessmentDate = null;
    self.headerText = "Update Asset Condition";

    // Functions
    self.cancel = $mdDialog.cancel;
    self.updateAssetCondition = updateAssetCondition;
    self.onRatingChange = onRatingChange;
    self.onAssessmentDateChange = onAssessmentDateChange;

    // ------------------------
    //   Public Functions
    // ------------------------

    function onRatingChange($event) {
      self.condition = $event.model;
      self.saveEnabled = isSaveEnabled($event);
    }

    function onAssessmentDateChange($event) {
      self.assessmentDate = $event.model;
      self.saveEnabled = isSaveEnabled($event);
    }

    /**
     * Update the model
     */
    function updateAssetCondition() {
      if (self.saving) return;
      self.saving = true;

      var data = {
        condition_rating: self.condition,
        date_recorded: self.assessmentDate,
      };

      AssetConditionService.create(self.buildingId, self.assetId, data)
        .then($mdDialog.hide)
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function isSaveEnabled($event) {
      if ($event.invalid) {
        return false;
      }
      return Boolean(self.condition && self.assessmentDate);
    }
  }
})();
