(function () {
  angular.module("akitabox.core.utils").factory("media", MediaService);

  /* @ngInject */
  function MediaService($q, $window) {
    var service = {
      // Constants
      AUDIO: "audioinput",
      VIDEO: "videoinput",
      PERMISSION_DENIED: "PermissionDeniedError",
      PERMISSION_DISMISSED: "PermissionDismissedError",
      // Media constraint to be used when requesting a stream for QR code
      // scanning.
      QR_SCANNER_CONSTRAINTS: {
        video: {
          // Request environment (rear) facing camera
          facingMode: { ideal: "environment" },
        },
      },

      // Functions
      getMedia: getMedia,
      getDevices: getDevices,
      stopStream: stopStream,
      isSupported: isSupported,
    };

    /**
     * Get the appropriate "getUserMedia" function for this device.
     * @return {(constraints, onSuccess, onError) => void} A callback-accepting
     * function that mimics getUserMedia. Returns undefined if the current
     * environment does not support getUserMedia.
     */
    function getGetUserMedia() {
      var getMedia;
      // prefer the new(er) mediaDevices API
      if (
        $window.navigator.mediaDevices &&
        $window.navigator.mediaDevices.getUserMedia
      ) {
        // calbackify the mediaDevices promise-returning getUserMedia function
        getMedia = function (constraints, onSuccess, onError) {
          $window.navigator.mediaDevices
            .getUserMedia(constraints)
            .catch(onError)
            .then(onSuccess);
        };
      } else {
        // check for legacy options
        getMedia =
          $window.navigator.getUserMedia ||
          $window.navigator.webkitGetUserMedia ||
          $window.navigator.mozGetUserMedia ||
          $window.navigator.msGetUserMedia;
        if (getMedia) {
          getMedia = getMedia.bind($window.navigator);
        }
      }
      return getMedia;
    }

    var _getMedia = getGetUserMedia();

    return service;

    /**
     * Determine if user media is supported by the current browser
     *
     * @return {Boolean} True if supported, false if not
     */
    function isSupported() {
      return angular.isFunction(_getMedia);
    }

    /**
     * Get user media stream
     *
     * @param {MediaStreamContraints}   constraints     Stream contraints
     *
     * @return {Promise<MediaStream|Error>}     Stream
     */
    function getMedia(constraints) {
      if ($window.navigator.mediaDevices && _getMedia) {
        var deferred = $q.defer();
        _getMedia(constraints, deferred.resolve, deferred.reject);
        return deferred.promise;
      }
      return $q.reject("Media devices unavailable");
    }

    /**
     * Get list of available media devices
     *
     * @param {String}  kind    [Optional] Kind filter
     *
     * @return {Promise<Array|Error>}   List of devices
     */
    function getDevices(kind) {
      if (
        $window.navigator &&
        $window.navigator.mediaDevices &&
        $window.navigator.mediaDevices.enumerateDevices
      ) {
        var enumerate = navigator.mediaDevices.enumerateDevices();
        if (enumerate.then) {
          if (kind) {
            return enumerate.then(function (devices) {
              return devices.filter(function (device) {
                return device.kind === kind;
              });
            });
          }
          return enumerate;
        }
      }
      return $q.reject("Media devices unavailable");
    }

    /**
     * Stop a stream.
     * @param {MediaStream} stream - The stream to stop.
     */
    function stopStream(stream) {
      var tracks = stream.getTracks();
      for (var i = 0; i < tracks.length; i++) {
        tracks[i].stop();
      }
    }
  }
})();
