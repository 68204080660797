(function () {
  angular
    .module("akitabox.ui.components.workOrderCalendar")
    .component("abxWorkOrderCalendar", {
      bindings: {
        buildingId: "<?abxBuildingId",
        config: "<?abxConfig",
        filters: "<abxWoFilters",
        calendarName: "@abxName", // uses this binding because value shouldn't change at all
        organizationId: "<?abxOrganizationId",
      },
      controller: AbxWorkOrderCalendarComponentController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/work-order-calendar/work-order-calendar.component.html",
    });

  /** @ngInject */
  function AbxWorkOrderCalendarComponentController(
    // Angular
    $timeout,
    // Libraries
    moment,
    uiCalendarConfig,
    // Dialogs
    WorkOrderDetailDialog,
    // Services
    OrganizationService,
    ToastService,
    UserService,
    WorkOrderService
  ) {
    var self = this;

    self.$selectedEventPopover;
    self.calendarConfig;
    self.config = self.config || {};
    self.eventSources = [];
    self.fetching = undefined;
    self.loading = true;
    self.userPermissions = UserService.getPermissions();

    self.$onInit = function () {
      // Setup fullcalendar.js config options
      setupFullCalendarConfig(self.config);
      self.loading = false;
      if (!self.userPermissions) {
        UserService.init(OrganizationService.getCurrent()._id).then(function (
          user
        ) {
          self.userPermissions = UserService.getPermissions();
        });
      }

      $timeout(function () {
        // this is required for the calendar to render on initial page laod
        uiCalendarConfig.calendars[self.calendarName].fullCalendar("render");
      }, 0);
    };

    self.debouncedUpdateWorkOrders = angular.debounce(updateWorkOrders, 300);

    /**
     * Initializes self.calendarConfig which is used to create the instance of fullcalendar
     * @param {{}} config - fullcalendar.js config object
     * @return void
     */
    function setupFullCalendarConfig(config) {
      if (!config.header) config.header = {};
      if (!config.buttonText) config.buttonText = {};

      var canDragAndDrop =
        self.userPermissions && self.userPermissions.task.update;

      self.calendarConfig = {
        height: config.height || 550,
        eventStartEditable: canDragAndDrop || false,
        droppable: canDragAndDrop || false,
        header: {
          left: config.header.left || "today",
          center: config.header.center || "prev title next",
          right: config.header.right || "basicDay basicWeek month",
          // these display options may be more useful once we have work orders that aren't all day "agendaWeek agendaDay"
          // Leaving them here as a comment because the documentation is poor and hard to find
        },
        buttonText: {
          today: config.buttonText.today || "Today",
          month: config.buttonText.month || "Month",
          week: config.buttonText.week || "Week",
          day: config.buttonText.day || "Day",
        },
        displayEventTime: config.displayEventTime || false,
        timezone: config.timezone || "UTC",
        defaultView: config.defaultView || "month",
        defaultDate: config.defaultDate || new Date(),
        // eventLimit seems to have an off-by-one error. Limit 4 actually shows 3.
        eventLimit: config.eventlimit || 11,
        views: {
          basicDay: {
            eventLimit: 21,
          },
          basicWeek: {
            eventLimit: 21,
          },
        },
        //showNonCurrentDates: false, this is only for v3.3 and up...smh
        fixedWeekCount: false,
        eventClick: function (calendarEvent, mouseEvent, calendarView) {
          var slimWorkOrder = calendarEvent.workOrder;
          WorkOrderDetailDialog.show({
            locals: {
              workOrder: function () {
                return WorkOrderService.getById(
                  slimWorkOrder.building,
                  slimWorkOrder._id
                );
              },
            },
          });
        },
        eventRender: function (calendarEvent, $el, calendarView) {},
        eventMouseover: function (calendarEvent, mouseEvent, calendarView) {},
        eventMouseout: function (calendarEvent, mouseEvent, calendarView) {},
        /**
         * Event that fires AFTER you've dragged and dropped an event to a different date
         * https://fullcalendar.io/docs/v3/eventDrop#v2
         * @param {fullcalendar.Event} calendarEvent - the event object that was dragged and dropped
         * @param {fullcalendar.Duration} dateDelta - the time difference between the orginal date and the date being dropped on https://fullcalendar.io/docs/v3/moment-duration#v2
         * @param {function} undoFn - fn to revert the chanages
         * @param {jquery|undefined} jqueryEvent - the jquery event, most times undefined
         * @param {{}} emptyObj - literally an empty object...all the damn time
         * @param {fullcalendar.View} calendarView - the currnet fullcalendar view object
         */
        eventDrop: function (
          calendarEvent,
          dateDelta,
          undoFn,
          jqueryEvent,
          emptyObj,
          calendarView
        ) {
          var workOrder = calendarEvent.workOrder;

          // make sure this WO can still be moved
          if (workOrder.is_canceled || workOrder.is_completed) {
            ToastService.showSimple(
              "Cannot move " +
                workOrder.display_number +
                " because it is already completed/canceled"
            );
            return undoFn();
          }

          var startDate = moment(workOrder.start_date);
          var dueDate = moment(workOrder.due_date);
          var daysBetween = dateDelta.days();
          if (daysBetween < 0) {
            startDate.subtract(Math.abs(daysBetween), "days");
            dueDate.subtract(Math.abs(daysBetween), "days");
          } else {
            startDate.add(Math.abs(daysBetween), "days");
            dueDate.add(Math.abs(daysBetween), "days");
          }

          var buildingId = workOrder.building._id || workOrder.building;
          WorkOrderService.update(buildingId, workOrder._id, {
            start_date: startDate.toDate(),
            due_date: dueDate.toDate(),
          })
            .then(function (updatedWorkOrder) {
              ToastService.showSimple(
                "Updated work order " +
                  workOrder.display_number +
                  " start and due date"
              );
            })
            .catch(function (err) {
              ToastService.showSimple(
                "An error occured. Moving work order back to its original date"
              );
              undoFn();
            });
        },
        eventResize: function (resizeEvent) {},
        // Dunno why there is a 2nd (duplicate) calendar view object passed in, no docs anywhere
        viewRender: function (calendarView, $calendar, anotherCalendarView) {
          var rangeStart = calendarView.start;
          var rangeEnd = calendarView.end;

          self.debouncedUpdateWorkOrders({
            start_date: "$lte," + rangeEnd.valueOf(),
            due_date: "$gte," + rangeStart.valueOf(),
            status: "$nin,completed,canceled",
          });
        },
      };
    }

    function updateWorkOrders(filters) {
      var fetch;
      var queryString = angular.extend(
        {
          projection: "slim",
        },
        self.filters,
        filters
      );
      if (self.buildingId) {
        fetch = WorkOrderService.getAllByBuildingId(
          self.buildingId,
          queryString
        );
      } else if (self.organizationId) {
        fetch = WorkOrderService.getAllByOrganization(
          self.organizationId,
          queryString
        );
      }

      // remove all current events first
      self.eventSources.shift();

      // Then fetch
      self.fetching = true;
      return fetch
        .then(transformWOsToEvents)
        .then(addEventsToCalendar)
        .catch(ToastService.showError)
        .finally(function () {
          self.fetching = false;
        });

      function transformWOsToEvents(workOrders) {
        return workOrders.reduce(function (accumulator, workOrder) {
          var backgroundColor = "#1C5076";
          var event = {
            id: workOrder._id,
            allDay: false,
            start: new Date(workOrder.start_date),
            end: new Date(workOrder.due_date),
            title: workOrder.display_number + " | " + workOrder.subject,
            editable: false,
            workOrder: workOrder,
            backgroundColor: backgroundColor,
            textColor: getMatchingFontColor(backgroundColor),
          };
          accumulator.push(event);
          return accumulator;
        }, []);
      }

      function addEventsToCalendar(events) {
        self.eventSources.push(events);
      }
    }

    /* eslint-disable no-unused-vars */
    function getRandomColor() {
      var colors = [
        "#1C5076",
        "#2D8DD9",
        "#99CFEA",
        "#2F9462",
        "#94CB17",
        "#AAB96D",
        "#FDD835",
        "#FF960B",
        "#FF4F00",
        "#BE0000",
        "#F094AA",
        "#A67FD1",
        "#6B3793",
        "#999999",
        "#D9D9D9",
      ];

      return colors[getRandomInt(colors.length)];
    }
    /* eslint-enable no-unused-vars */

    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }

    function getMatchingFontColor(color) {
      var rgb = hexToRgb(color);
      // Calculate the perceptive luminance (aka luma) - human eye favors green color...
      var luma = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
      // Return black for bright colors, white for dark colors
      return luma > 0.5 ? "#000000" : "#FFFFFF";
    }

    function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    }
  }
})();
