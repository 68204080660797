(function () {
  angular
    .module("akitabox.desktop.admin.job.devList")
    .controller("DevJobListController", DevJobListController);

  /* @ngInject */
  function DevJobListController(
    // AkitaBox
    models,
    // Services
    AdminJobService,
    ToastService,
    // Dialogs
    CreateJobDialog,
    // Resolve,
    account
  ) {
    var self = this;

    // Attributes
    self.jobs = null;
    self.title = "developer " + models.JOB.PLURAL;
    self.description = '"fart fart fart fart" - Robbie Steinbock';
    self.actions = [];
    self.permissions = {
      canRunScript: false,
      canCancel: false,
    };

    // Functions
    self.fetchJobs = fetchJobs;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      self.permissions.canRunScript =
        account.site_permissions.can_run_env_scripts;
      self.permissions.canCancel = self.permissions.canRunScript;
      if (self.permissions.canRunScript) {
        self.actions.push({
          text: "Run Script",
          icon: "directions_run",
          onClick: runScript,
        });
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function runScript() {
      CreateJobDialog.show("dev")
        .then(function (job) {
          if (!job) return;
          if (angular.isArray(self.jobs)) {
            self.jobs.unshift(job);
          } else {
            self.jobs = [job];
          }
        })
        .catch(ToastService.showError);
    }

    function fetchJobs(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
      };

      return AdminJobService.getEnvJobs(params).then(function (jobs) {
        if (angular.isArray(self.jobs)) {
          self.jobs = self.jobs.concat(jobs);
        } else {
          self.jobs = jobs;
        }
        return jobs.length;
      });
    }
  }
})();
