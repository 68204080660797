(function () {
  angular.module("akitabox.core.auth", [
    "akitabox.core.router",
    "akitabox.core.auth.login",
    "akitabox.core.auth.logout",
    "akitabox.core.auth.register",
    "akitabox.core.auth.sendValidation",
    "akitabox.core.auth.lockedOut",
    "templates",
    "ui.router",
  ]);
})();
