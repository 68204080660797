(function () {
  angular
    .module("akitabox.desktop.building.admin.job")
    .controller("BuildingJobListController", BuildingJobListController);

  /* @ngInject */
  function BuildingJobListController(
    // Angular
    $rootScope,
    // AkitaBox
    models,
    // Services
    FeatureFlagService,
    AdminJobService,
    ToastService,
    // Dialogs
    CreateJobDialog,
    // Resolve,
    account,
    building
  ) {
    var self = this;

    // Attributes
    self.jobs = null;
    self.building = building;
    self.title = models.JOB.PLURAL;
    self.subtitle = { name: models.JOB.PLURAL };
    self.actions = [];
    self.permissions = {
      canRunScript: false,
      canCancel: false,
    };

    // Functions
    self.runScript = runScript;
    self.createExport = createExport;
    self.fetchJobs = fetchJobs;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      self.permissions.canRunScript = account.site_permissions.can_run_scripts;
      self.permissions.canCancel = self.permissions.canRunScript;
      // Determine if user can run scripts
      if (self.permissions.canRunScript) {
        self.actions.push(
          {
            text: "Import",
            icon: "cloud_upload",
            onClick: createImport,
          },
          {
            text: "Export",
            icon: "get_app",
            onClick: createExport,
          },
          {
            text: "Run Script",
            icon: "directions_run",
            onClick: runScript,
          }
        );
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function runScript() {
      CreateJobDialog.show("script", { locals: { building: building } })
        .then(function (jobs) {
          if (angular.isArray(self.jobs)) {
            Array.prototype.unshift.apply(self.jobs, jobs);
          } else {
            self.jobs = jobs;
          }
        })
        .catch(ToastService.showError);
    }

    function createExport() {
      CreateJobDialog.show("export", { locals: { building: building } })
        .then(function (jobs) {
          if (!jobs) return;
          if (angular.isArray(self.jobs)) {
            Array.prototype.unshift.apply(self.jobs, jobs);
          } else {
            self.jobs = jobs;
          }
        })
        .catch(ToastService.showError);
    }

    function createImport() {
      CreateJobDialog.show("import", { locals: { building: building } })
        .then(function (jobs) {
          if (angular.isArray(self.jobs)) {
            Array.prototype.unshift.apply(self.jobs, jobs);
          } else {
            self.jobs = jobs;
          }
        })
        .catch(ToastService.showError);
    }

    function fetchJobs(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
      };

      return AdminJobService.getBuildingJobs(building._id, params).then(
        function (jobs) {
          if (angular.isArray(self.jobs)) {
            self.jobs = self.jobs.concat(jobs);
          } else {
            self.jobs = jobs;
          }
          return jobs.length;
        }
      );
    }
  }
})();
