(function () {
  angular
    .module("akitabox.core.directives.sref", [
      "ui.router",
      "akitabox.core.services.building",
    ])
    .directive("abxSref", AbxSrefDirective);

  /* @ngInject */
  function AbxSrefDirective($compile, $state, BuildingService) {
    return {
      restrict: "A",
      link: postLink,

      /**
       * `terminal` and `priority` here will ensure that directives below
       * `priority` 1 will not be compiled. We do this since we need to compile
       * the element this directive is on at the end of `postLink`, and this
       * prevents it from compiling the element twice. Without this, a bug
       * occurs where the element is compiled twice, and any time `postLink`
       * is called and we call `$compile` ourselves, a duplicate element
       * is rendered, instead of replacing the original element we were
       * manipulating.
       *
       * @see https://docs.angularjs.org/guide/compiler#double-compilation-and-how-to-avoid-it
       */
      terminal: true,
      priority: 1,
    };

    function postLink($scope, $element, $attrs, $ctrl, $transclude) {
      var sref = angular.copy($attrs.abxSref);
      var building = BuildingService.getCurrent();
      var isAppState = sref.indexOf("app") > -1;

      var ref = parseStateRef(sref, $state.current.name);
      var params = ref.paramExpr
        ? angular.copy($scope.$eval(ref.paramExpr))
        : {};

      var options;
      if (params.options) {
        options = params.options;
        delete params.options;
      }

      var ignoreBuilding = options && options.ignoreBuilding ? true : false;

      if (isAppState && building && !ignoreBuilding) {
        // Add building param if not in params
        if (!params.buildingId) {
          angular.extend(params, { buildingId: building._id });
        }

        // Translate state name
        var stateName = ref.state;
        if (stateName.indexOf("app.building.") >= 0) {
          sref = stateName;
        } else {
          sref = stateName.replace(/^app\./, "app.building.");
        }
        // Dashboard may not exist depending on list flags, use detail
        if (sref === "app.building.dashboard") {
          sref = "app.building.detail";
        }
        if (params) {
          sref += "(" + JSON.stringify(params) + ")";
        }
      }

      $element.attr("ui-sref", sref);

      /**
       * Compile the element so that the newly added `ui-sref` directive can
       * properly generate an `href` attribute on the element.
       *
       * Here we compile only directives of `priority < 1` (third argument to
       * `$compile`). This is because above we set the `priority` of the
       * `abx-sref` directive to `1` and set it to be terminal, therefore
       * skipping directives of `priority < 1` on initial compilation. So here,
       * we compile those directives since we originally skipped them.
       */
      $compile($element, $transclude, 1)($scope);
    }

    function parseStateRef(ref, current) {
      var preparsed = ref.match(/^\s*({[^}]*})\s*$/);
      var parsed;
      if (preparsed) ref = current + "(" + preparsed[1] + ")";
      parsed = ref.replace(/\n/g, " ").match(/^([^(]+?)\s*(\((.*)\))?$/);
      if (!parsed || parsed.length !== 4) {
        throw new Error("Invalid state ref '" + ref + "'");
      }
      return {
        state: parsed[1],
        paramExpr: parsed[3] || null,
      };
    }
  }
})();
