(function () {
  angular
    .module("akitabox.desktop.directives.linkButton", [
      "ui.bootstrap",
      "akitabox.core",
    ])
    .directive("abxLinkButton", AbxLinkButtonDirective);

  function AbxLinkButtonDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/link-button/link-button.html",
      transclude: true,
      link: postLink,
      scope: {
        disabled: "=?abxDisabled",
        icon: "@?",
      },
    };

    function postLink($scope, $elem) {
      $scope.icon = angular.isEmpty($scope.icon) ? null : $scope.icon;
      $scope.$watch("disabled", function (disabled) {
        if (disabled) {
          $elem.css("pointer-events", "none");
        } else {
          $elem.css("pointer-events", "auto");
        }
      });
    }
  }
})();
