(function () {
  angular
    .module("akitabox.desktop.admin.job.detail")
    .controller("JobDetailController", JobDetailController);

  /* @ngInject */
  function JobDetailController(
    // Third-party
    $q,
    // Akitabox
    models,
    // Resolve
    account,
    building,
    job,
    // Services
    IdentityService,
    OrganizationService,
    ToastService,
    UserService
  ) {
    var self = this;

    // Private Attributes
    var ALL_STEPS = 0;
    var JOB_ERRORS = 3;
    var assignees = null;
    var userRequest = null;

    // Attributes
    self.job = job;
    self.building = building;
    self.title = "Admin";
    self.subtitle = { name: models.JOB.SINGULAR };
    self.description =
      "this is my job, there are many like it but this one is mine";
    self.permissions = {
      canCancel: false,
    };
    self.tabIndex = self.job.status === "errored" ? JOB_ERRORS : ALL_STEPS;
    self.stepViews = ["showAll", "showChanged", "showErrors"];
    self.selectedStepView = self.stepViews[0];

    // Functions
    self.getOwner = getOwner;
    self.selectTab = selectTab;

    init();

    function init() {
      self.permissions = {
        canCancel: account.site_permissions.can_run_scripts,
      };
      if (self.building) {
        var organization = OrganizationService.getCurrent();
        userRequest = UserService.getAll(organization._id, {
          identity: "$ne,null",
        })
          .then(function (users) {
            assignees = users.reduce(function (accumulator, user) {
              var identity = user.identity;
              accumulator[identity._id] = identity;
              return accumulator;
            }, {});
          })
          .catch(ToastService.showError);
      }
    }

    function getOwner(identityId) {
      // Find the account in building assignees if we have them
      if (assignees) {
        var identity = assignees[identityId];
        if (identity) {
          return $q.resolve(identity);
        }
        return $q.reject("Assignee not found");
      }
      if (userRequest) {
        return userRequest.then(function () {
          return assignees[identityId];
        });
      }
      // Attempt to fetch account information
      return IdentityService.getById(identityId).then(function (identity) {
        return identity;
      });
    }

    function selectTab(index) {
      self.selectedStepView = index < JOB_ERRORS ? self.stepViews[index] : null;
    }
  }
})();
