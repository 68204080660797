/**
 * Singleton service class that holds the sisense app instance
 */
class InsightsService {
  #sisenseApp: any = null;

  get sisenseApp() {
    return this.#sisenseApp;
  }

  set sisenseApp(app: any) {
    this.#sisenseApp = app;
  }
}

export default new InsightsService();
