/**
 * Execute an async function, ignoring its returned promise. Useful for
 * asynchronous work in react components (eg doing async work in event handlers
 * and useEffect callbacks where asynchronous completion is signalled using
 * react state).
 *
 * Short-hand for `(async () => {...})()`
 * @param fn
 */
export function fireAndforget(fn: () => Promise<any>) {
  fn();
}
