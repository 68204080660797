import { ButtonView } from "@ckeditor/ckeditor5-ui";
import { Plugin } from "@ckeditor/ckeditor5-core";

export class FilesWidgetButton extends Plugin {
  init() {
    const editor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add("abxFiles", () => {
      // The button will be an instance of ButtonView.
      const button = new ButtonView();

      button.set({
        icon: `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M220 816q-24 0-42-18t-18-42V236q0-24 18-42t42-18h245l60 60h335q24 0 42 18t18 42v460q0 24-18 42t-42 18H220Zm0-60h640V296H500l-60-60H220v520Zm590 180H100q-24 0-42-18t-18-42V296h60v580h710v60ZM334 645h412L614 469 504 615l-79-86-91 116ZM220 756V236v520Z"/></svg>`,
        label: "Files",
        tooltip: true,
      });
      button.on("execute", () => {
        const config = editor.config.get("abxFiles");
        if (config) {
          config.setShowFilesDialog(true);
        }
      });
      return button;
    });
  }
}
