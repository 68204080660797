(function () {
  angular
    .module("akitabox.ui.dialogs.editLocation")
    .controller("EditLocationDialogController", EditLocationDialogController);

  /** @ngInject */
  function EditLocationDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    $scope,
    // Services
    AssetService,
    ChecklistTemplateService,
    OrganizationService,
    RoundTemplateService,
    InspectionService,
    InspectionProgramService,
    FloorService,
    RoomService,
    ScheduleService,
    ToastService,
    UserService,
    WorkOrderService
  ) {
    var self = this;

    self.organization = OrganizationService.getCurrent();
    self.multiLocationEnabled = self.organization.show_inspections;

    var config = {
      task: {
        service: WorkOrderService,
        showRoom: true,
        showAsset: true,
        "append-inspection-stop": {
          update: handleAppendInspectionStop,
        },
      },
      future_task: {
        service: ScheduleService,
        showRoom: true,
        showAsset: true,
      },
      asset: {
        service: AssetService,
        showRoom: true,
        showAsset: false,
      },
      room: {
        service: RoomService,
        showRoom: false,
        showAsset: false,
      },
    };

    self.saving = false;
    self.changesMade = false;
    self.permissions = getPermissions();
    self.locationIsDuplicated = false;
    self.checklistTemplates = [];
    self.selectedChecklistTemplate;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.updateLocation = updateLocation;
    self.onChange = onChange;
    self.setLocationChangesMade = setLocationChangesMade;
    self.handleAddLocationClick = handleAddLocationClick;
    self.handleRemoveLocationClick = handleRemoveLocationClick;
    self.handleAppendInspectionStop = handleAppendInspectionStop;
    self.handleChecklistTemplateChange = handleChecklistTemplateChange;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, fetch floors, prepopulate fields if they were passed in via locals
     */
    function init() {
      var modelConfig = config[self.modelType];

      if (!self.building || !modelConfig) {
        return;
      }

      if (self.type && modelConfig[self.type]) {
        self.update = self.update || modelConfig[self.type].update;
      }

      self.update = self.update || modelConfig.service.update;

      self.showRoom = modelConfig.showRoom;
      self.showAsset = modelConfig.showAsset;

      // use to track the starting values of floor room asset
      self.initialIds = {};

      if (self.modelType === "task") {
        if (self.multiLocationEnabled) {
          self.locations = angular.copy(self.model.round);
          // populate the locations
          for (var i = 0; i < self.locations.length; i++) {
            var location = self.locations[i];
            if (location.asset) {
              location.room = location.asset.room || null;
              location.floor = location.asset.level || null;
            } else if (location.room) {
              location.asset = null;
              location.floor = location.room.level || null;
            } else if (location.level) {
              location.asset = null;
              location.room = null;
              location.floor = location.level;
            }
            self.locations[i] = location;
          }
          if (self.type === "append-inspection-stop") {
            self.locations.push({});
            self.loadingChecklistTemplates = true;
            var ipChecklistTemplateId;

            // Get all the possible checklists they can add the new stop to
            InspectionProgramService.getById(
              self.organization._id,
              self.model.inspection.inspection_program
            )
              .then(function (inspectionProgram) {
                ipChecklistTemplateId =
                  inspectionProgram.checklist_template._id ||
                  inspectionProgram.checklist_template;

                return ChecklistTemplateService.getAll(
                  self.organization._id,
                  {}
                );
              })
              .then(function (checklistTemplates) {
                for (var k = 0; k < checklistTemplates.length; k++) {
                  var checklistTemplate = checklistTemplates[k];
                  if (checklistTemplate._id === ipChecklistTemplateId) {
                    self.selectedChecklistTemplate = checklistTemplate;
                  }
                  self.checklistTemplates.push({
                    model: checklistTemplate,
                    value: checklistTemplate.name,
                  });
                }
                self.loadingChecklistTemplates = false;
              });
          }
        } else {
          self.floor = WorkOrderService.getLevel(self.model);
          self.asset = WorkOrderService.getAsset(self.model);
          self.room = WorkOrderService.getRoom(self.model);
        }
      } else {
        typeof self.model.level === "string"
          ? setFloorObject(self.model.level)
          : (self.floor = self.model.level);
        typeof self.model.room === "string"
          ? setRoomObject(self.model.room)
          : (self.room = self.model.room);
        typeof self.model.asset === "string"
          ? setAssetObject(self.model.asset)
          : (self.asset = self.model.asset);
      }
      self.initialIds["floor"] = self.floor ? self.floor._id : undefined;
      self.initialIds["room"] = self.room ? self.room._id : undefined;
      self.initialIds["asset"] = self.asset ? self.asset._id : undefined;

      /**
       * Seems to be no other solution to actively watch this without setting objectEquality -> true
       * We can wait until they click "Create" to tell them they have duplicate locations, but it seems
       * too late already by that point. Also using $watchCollection here seems to not work with an array
       * of objects, since it only compares the objects inside of an array via shallow comparison
       */
      $scope.$watch(
        "dialog.locations",
        function (watchedLocations) {
          if (!watchedLocations || watchedLocations.length <= 1)
            return (self.locationIsDuplicated = false);

          /**
           * This portion of the code does not have anything to do with duplication checks, it is here
           * because when a user is adding a stop, we need to make sure they've selected at leat a room
           * or an asset. There is no other place in the controller that can actively check so I've dumped
           * it in there with the duplication check logic
           */
          if (self.type === "append-inspection-stop") {
            var lastStop = watchedLocations[watchedLocations.length - 1];
            if (!lastStop.asset && !lastStop.room) {
              // turn off ability to "save" if there is no room/asset to this new stop
              // I'm re-usng changesMade because it has no use in this type === "append-inspection-stop", and I don't
              // want to add another variable to the ng-disabled for the save button, there's already like 5
              self.changesMade = false;
            } else {
              self.changesMade = true;
            }
          }

          var hasDuplicates = false;
          var duplicateLocations = {};
          for (var i = 0; i < watchedLocations.length; i++) {
            var watchedLocation = watchedLocations[i];
            var watchedAsset = watchedLocation.asset
              ? watchedLocation.asset._id
              : "_";
            var watchedFloor = watchedLocation.floor
              ? watchedLocation.floor._id
              : "_";
            var watchedRoom = watchedLocation.room
              ? watchedLocation.room._id
              : "_";
            var hash = watchedAsset + watchedFloor + watchedRoom;
            if (duplicateLocations[hash]) {
              // if we already have this location, mark it as duplicated
              self.locations[i].duplicated = true;
              hasDuplicates = true;
            } else {
              // otherwise, allow blank locations or add the new unique location
              // to our map for tracking
              if (hash === "___") {
                continue;
              }
              duplicateLocations[hash] = [i];
              self.locations[i].duplicated = false;
            }
          }
          self.locationIsDuplicated = hasDuplicates;
        },
        true
      );
    }

    function setRoomWithAsset() {
      if (self.asset.room && self.asset.room._id) {
        self.room = self.asset.room;
        self.room.building = self.asset.building;
      } else {
        return AssetService.getRoom(self.building._id, self.asset.values).then(
          function (room) {
            self.room = room || null;
          }
        );
      }
    }

    function setFloorWithAsset() {
      if (self.asset.level && self.asset._id) {
        FloorService.getById(
          self.asset.building._id || self.asset.building,
          self.asset.level._id || self.asset.level
        ).then(function (level) {
          self.floor = level || null;
        });
      } else {
        return AssetService.getFloor(self.building._id, self.asset.values).then(
          function (floor) {
            self.floor = floor || null;
          }
        );
      }
    }

    function setFloorWithRoom() {
      if (self.room.level) {
        self.floor = self.room.level;
        self.floor.building = self.room.building;
      } else {
        return RoomService.getFloor(self.building._id, self.room.values).then(
          function (floor) {
            self.floor = floor || null;
          }
        );
      }
    }

    function getPermissions() {
      var permissions = UserService.getPermissions();
      switch (self.modelType) {
        case "asset":
          return {
            canEditFloor: permissions.asset.update_level,
            canEditRoom: permissions.asset.update_room,
            canEditAsset: false,
          };
        case "room":
          return {
            canEditFloor: permissions.room.update_level,
            canEditRoom: false,
            canEditAsset: false,
          };
        default:
          return {
            canEditFloor: true,
            canEditRoom: true,
            canEditAsset: true,
          };
      }
    }

    function setFloorObject(levelId) {
      return FloorService.getById(self.building._id, levelId).then(function (
        floor
      ) {
        self.floor = floor || null;
      });
    }

    function setRoomObject(roomId) {
      return RoomService.getById(self.building._id, roomId).then(function (
        room
      ) {
        self.room = room || null;
      });
    }

    function setAssetObject(assetId) {
      return AssetService.getById(self.building._id, assetId).then(function (
        asset
      ) {
        self.asset = asset || null;
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function setLocationChangesMade() {
      self.changesMade = true;
    }

    function handleAddLocationClick() {
      self.locations.push({});
    }

    function handleRemoveLocationClick(location) {
      if (self.locations.length > 1) {
        var indexToRemove = self.locations.indexOf(location);
        self.locations.splice(indexToRemove, 1);
      } else {
        self.locations = [{}];
      }
      setLocationChangesMade();
    }

    function checkRoomField() {
      const roomLevelId = self.room && (self.room.level._id || self.room.level);
      if (self.room && (!self.floor || roomLevelId !== self.floor._id)) {
        self.room = null;
      }
    }

    function checkAssetField() {
      const assetRoomId =
        self.asset && (self.asset.room._id || self.asset.room);
      if (self.asset && (!self.room || assetRoomId !== self.room._id)) {
        self.asset = null;
      }
    }

    function onChange($event, field) {
      // Block saving until validation after change
      self.changesMade = false;
      switch (field) {
        case "asset":
          self.asset = $event.model;
          if (self.asset && self) {
            if (!self.room) setRoomWithAsset();
            if (!self.floor) setFloorWithAsset();
          }
          break;
        case "room":
          self.room = $event.model;
          if (self.room) {
            setFloorWithRoom();
          }
          checkAssetField();
          break;
        case "floor":
          self[field] = $event.model;
          checkRoomField();
          checkAssetField();
          break;
      }

      // Enable saving if any field is different from its start value after a change
      for (var key in self.initialIds) {
        var selfKeyId = self[key] ? self[key]._id : undefined;
        if (selfKeyId !== self.initialIds[key]) {
          self.changesMade = true;
        }
      }
    }

    /**
     * Update the model
     */
    function updateLocation() {
      if (self.saving) return;
      self.saving = true;

      var data = {};
      if (self.modelType === "task") {
        if (self.type === "append-inspection-stop") {
          data.updateRoundTemplate = self.updateRoundTemplate;
        }
        // parse locations into round
        var round = [];
        if (self.locations) {
          for (var i = 0; i < self.locations.length; i++) {
            var location = self.locations[i];
            if (location.asset) round.push({ asset: location.asset });
            else if (location.room) round.push({ room: location.room });
            else if (location.floor) round.push({ level: location.floor });
          }

          data.round = round;
          var lastRound = data.round[data.round.length - 1];
          if (
            self.type === "append-inspection-stop" &&
            lastRound &&
            (lastRound.asset || lastRound.room || lastRound.level)
          ) {
            // We can count on the last stop being the new one, so we modify it here
            // Helps use tell the API which checklsit template this new stop should be
            // attached to
            data.round[data.round.length - 1].checklist_template =
              self.selectedChecklistTemplate._id;
          }
        } else {
          // only one round
          if (self.asset) {
            data.round = [{ asset: self.asset._id }];
          } else if (self.room) {
            data.round = [{ room: self.room._id }];
          } else if (self.floor) {
            data.round = [{ level: self.floor._id }];
          }
        }
      } else {
        data.level = null;
        data.room = null;
        data.asset = null;
        if (self.floor) {
          data.level = self.floor._id;
          data.populatedFloor = self.floor;
        }
        if (self.room) {
          data.room = self.room._id;
          data.populatedRoom = self.room;
        }
        if (self.asset) data.asset = self.asset._id;
      }
      self
        .update(self.building._id, self.model._id, data)
        .then(function (updatedModel) {
          $mdDialog.hide(updatedModel);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Update handler for appending a stop to inspection tasks
     * @param { string } buildingId
     * @param { string } workOrderId
     * @param { object } data
     * @param { boolean } data.updateRoundTemplate - If true, the round template associated
     *  with the building & IP for this WO should be updated as well
     * @param { boolean } data.round - The new round for the task
     */
    function handleAppendInspectionStop(buildingId, workOrderId, data) {
      return WorkOrderService.setRound(buildingId, workOrderId, {
        round: data.round,
      }).then(function (upatedWorkOrder) {
        if (data.updateRoundTemplate) {
          var inspectionId = self.model.inspection._id || self.model.inspection;
          return InspectionService.getById(self.organization._id, inspectionId)
            .then(function (inspection) {
              return InspectionProgramService.getById(
                self.organization._id,
                inspection.inspection_program._id ||
                  inspection.inspection_program
              );
            })
            .then(function (inspectionProgram) {
              if (
                inspectionProgram.checklist_template !==
                self.selectedChecklistTemplate._id
              ) {
                var newRound = data.round[data.round.length - 1];
                var newStop = newRound.asset || newRound.room || newRound.level;
                var stopLocationType =
                  RoundTemplateService.getStopLocationType(newRound);
                var association = {
                  building: self.model.building._id || self.model.building,
                  checklist_template: self.selectedChecklistTemplate._id,
                  inspection_program: {},
                };
                association[stopLocationType] = newStop;

                // Add the new association.
                return InspectionProgramService.updateSequence(
                  self.organization._id,
                  inspectionProgram._id,
                  [
                    {
                      action: "insertChecklistAssociations",
                      associations: [association],
                    },
                  ],
                  {
                    // setting the stops on the RT will regenerate the stops
                    skipInspectionRegeneration: true,
                  }
                ).then(function () {
                  return inspectionProgram;
                });
              }
            })
            .then(function (inspectionProgram) {
              // find the inspection component responsible for this work order's building
              var inspectionComponent =
                inspectionProgram.inspection_components.find(function (c) {
                  return (
                    c.building === self.model.building._id ||
                    self.model.building
                  );
                });
              /**
               * Since this call can take quite a while to complete, we do not want to wait for this
               * because it is just updating things that aren't directly related to what the user
               * will be interacting with on screen
               */
              RoundTemplateService.getStops(
                buildingId,
                inspectionComponent.round_template
              ).then(function (stops) {
                stops.push(data.round[data.round.length - 1]);
                return RoundTemplateService.setStops(
                  buildingId,
                  inspectionComponent.round_template,
                  stops
                );
              });
            })
            .then(function () {
              return upatedWorkOrder; // using this from a higher scope
            });
        } else {
          return upatedWorkOrder;
        }
      });
    }

    function handleChecklistTemplateChange($event) {
      self.selectedChecklistTemplate = $event.model;
    }
  }
})();
