(function () {
  angular.module("akitabox.desktop.asset").run(stateBlock);

  /** @ngInject **/
  function stateBlock(models, Router) {
    var LIST_QUERY_PARAMS = models.ASSET.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.assets",
        config: {
          url: "/" + models.ASSET.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/asset/building-list/asset-list.html",
          controller: "AssetListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Assets",
          },
          reloadOnSearch: true,
          resolve: {
            decommissioned: getDecommissioned,
          },
        },
      },
      {
        state: "app.building.asset",
        config: {
          url: "/" + models.ASSET.ROUTE_PLURAL + "/:assetId",
          templateUrl: "app/desktop/modules/asset/detail/asset-detail.html",
          controller: "AssetDetailController",
          controllerAs: "vm",
          resolve: {
            asset: getAsset,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.assets",
        config: {
          url: "/" + models.ASSET.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/asset/organization-list/org-asset-list.html",
          controller: "OrganizationAssetListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Assets",
          },
          reloadOnSearch: true,
          resolve: {
            decommissioned: getDecommissioned,
          },
        },
      },
      {
        state: "app.asset",
        config: {
          url: "/" + models.ASSET.ROUTE_PLURAL + "/:assetId",
          templateUrl: "app/desktop/modules/asset/detail/asset-detail.html",
          controller: "AssetDetailController",
          controllerAs: "vm",
          resolve: {
            asset: getOrganizationAsset,
            building: getBuilding,
            redirect: redirect,
          },
        },
      },
    ]);
  }

  /** @ngInject **/
  function getAsset($q, $stateParams, account, building, AssetService) {
    var params = {
      include_values: true,
    };
    var options = {
      includePinType: true,
      includeFloor: true,
      includeRoom: true,
    };
    return AssetService.getById(
      building._id,
      $stateParams.assetId,
      params,
      options
    );
  }

  /** @ngInject **/
  function getOrganizationAsset(
    $q,
    $stateParams,
    account,
    organization,
    AssetService
  ) {
    var params = {
      include_values: true,
      _id: $stateParams.assetId,
    };
    return AssetService.getByOrganization(organization._id, params)
      .then(AssetService.populatePinType)
      .then(AssetService.populateFloor)
      .then(AssetService.populateRoom);
  }

  /** @ngInject */
  function getBuilding($stateParams, account, asset, BuildingService) {
    return BuildingService.getById(asset.building);
  }

  /** @ngInject **/
  function redirect(
    $stateParams,
    $state,
    account,
    organization,
    building,
    asset,
    RedirectService
  ) {
    var destination = RedirectService.getDestination();
    var assetTab = organization.show_tasks ? "overview" : "details";

    if (
      destination.match(
        /^.*\/(closed-work-orders|open-work-orders|maintenance-schedules)$/
      )
    ) {
      if (!organization.show_tasks) {
        return $state.go("root.404");
      }
    } else if (
      destination.match(/^\/([a-zA-Z0-9_]*\/){2}assets\/[a-zA-Z0-9]+$/)
    ) {
      return $state.go("app.building.asset." + assetTab, $stateParams);
    } else if (destination.match(/\/assets\/[a-zA-Z0-9]+$/)) {
      return $state.go("app.asset." + assetTab, $stateParams);
    }
  }

  /* @ngInject */
  function getDecommissioned($stateParams) {
    return $stateParams.decommissioned || false;
  }
})();
