import { ButtonProps, Button, CircularProgress } from "@mui/material";
import { FC, useRef } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { stylesheet } from "../../stylesheet";

export interface UploadButtonProps extends ButtonProps<"label"> {
  /**
   * Called after a file is selected or dropped onto the button.
   * @param files The file that was selected
   */
  onFileSelect: (files: File[]) => void;
  isLoading: boolean;
  canUploadMultipleFiles: boolean;
  accept?: string;
}

/**
 * Component for displaying a big ol' upload button. Acts like an
 * `<input type="file">`.
 */
export const UploadButton: FC<UploadButtonProps> = ({
  onFileSelect: onChange,
  isLoading,
  accept = "",
  canUploadMultipleFiles,
  ...props
}) => {
  /**
   * Reference to the `input[type=file]` element, used for accessing
   * selected files since file inputs are uncontrolled.
   */
  const fileInputRef = useRef<HTMLInputElement | null>();

  return (
    <Button
      startIcon={!isLoading && <UploadIcon />}
      disabled={isLoading}
      component="label"
      css={ss.root}
      {...props}
    >
      {isLoading ? (
        <CircularProgress
          variant="indeterminate"
          size="1.5rem"
          css={{ color: `rgba(0,0,0,0.26)` }}
        />
      ) : (
        "UPLOAD"
      )}
      <input
        disabled={isLoading}
        ref={(el) => (fileInputRef.current = el)}
        type="file"
        accept={accept}
        onChange={() => onChange(Array.from(fileInputRef.current?.files ?? []))}
        css={ss.fileInput}
        multiple={canUploadMultipleFiles}
      />
    </Button>
  );
};

const ss = stylesheet({
  root: {
    fontWeight: "bold",
  },
  /**
   * Applied to the actual `input[type=file]`
   */
  fileInput: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
});
