(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.dialogs.schedule.editFrequency
   */
  angular
    .module("akitabox.ui.dialogs.schedule.editFrequency")
    .controller(
      "EditScheduleFrequencyDialogController",
      EditScheduleFrequencyDialogController
    );

  /**
   * @ngdoc controller
   * @module akitabox.ui.dialogs.schedule.editFrequency
   * @name EditScheduleFrequencyDialogController
   *
   * @description
   * Controller for the maintenance schedule edit frequency dialog
   *
   * @ngInject
   */
  function EditScheduleFrequencyDialogController(
    // Angular
    $timeout,
    // Material
    $mdDialog,
    // Libraries
    moment,
    // Services
    ScheduleService,
    TimeZoneService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.saving = false;
    self.progress = 0;
    self.showProgress = false;
    self.formData = {
      period: self.schedule.period,
      interval: self.schedule.interval,
      is_weekly_sunday: self.schedule.is_weekly_sunday,
      is_weekly_monday: self.schedule.is_weekly_monday,
      is_weekly_tuesday: self.schedule.is_weekly_tuesday,
      is_weekly_wednesday: self.schedule.is_weekly_wednesday,
      is_weekly_thursday: self.schedule.is_weekly_thursday,
      is_weekly_friday: self.schedule.is_weekly_friday,
      is_weekly_saturday: self.schedule.is_weekly_saturday,
      start_date: self.schedule.start_date,
      days_until_due: self.schedule.days_until_due,
    };

    // Schedule
    self.scheduleInvalid = false;
    self.enabledDays = [];
    self.intervals = getIntervalOptions();
    self.showWeekdays = self.formData.interval === "weeks" || false;
    var nowMoment = moment();
    if (self.utcOffset) {
      nowMoment.utcOffset(self.utcOffset);
    }
    var todayMoment = nowMoment.clone().startOf("day");
    self.today = todayMoment.toDate();
    self.tomorrow = todayMoment.clone().add(1, "days").toDate();
    if (self.utcOffset) {
      self.startDate = moment(self.formData.start_date)
        .utcOffset(self.utcOffset)
        .toDate();
    } else {
      self.startDate = new Date(
        moment.utc(self.formData.start_date).format("L")
      );
    }
    self.dueDate = moment(self.startDate)
      .add(self.formData.days_until_due, "days")
      .toDate();
    self.minDueDate = angular.copy(self.tomorrow);

    // Functions
    self.cancel = $mdDialog.cancel;
    self.save = save;
    self.onChange = onChange;
    self.onSelectWeekday = onSelectWeekday;
    self.isDisabled = isDisabled;
    self.isDateDisabled = isDateDisabled;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, build default summary text, fetch floors
     */
    function init() {
      if (self.formData.interval === "weeks") {
        disableAllDays();
      } else {
        resetWeekdays();
      }
      // Generate initial summary text
      buildSummary();

      if (self.formData.is_weekly_sunday) self.enabledDays[0] = true;
      if (self.formData.is_weekly_monday) self.enabledDays[1] = true;
      if (self.formData.is_weekly_tuesday) self.enabledDays[2] = true;
      if (self.formData.is_weekly_wednesday) self.enabledDays[3] = true;
      if (self.formData.is_weekly_thursday) self.enabledDays[4] = true;
      if (self.formData.is_weekly_friday) self.enabledDays[5] = true;
      if (self.formData.is_weekly_saturday) self.enabledDays[6] = true;
    }

    /**
     * Get scheduling interval options
     *
     * @return {Object} Map of interval options
     */
    function getIntervalOptions() {
      return {
        days: "Days",
        weeks: "Weeks",
        months: "Months",
        years: "Years",
      };
    }

    /**
     * Get the frequency summary based on the selected scheduling options
     *
     * @return {String} Summary text
     */
    function getFrequencySummary() {
      if (self.scheduleInvalid || !self.startDate || !self.formData.period) {
        return "Unavailable with selected options";
      }

      var schedule = {
        start_date: self.startDate,
        period: self.formData.period,
        interval: self.formData.interval,
        is_weekly_sunday: self.formData.is_weekly_sunday,
        is_weekly_monday: self.formData.is_weekly_monday,
        is_weekly_tuesday: self.formData.is_weekly_tuesday,
        is_weekly_wednesday: self.formData.is_weekly_wednesday,
        is_weekly_thursday: self.formData.is_weekly_thursday,
        is_weekly_friday: self.formData.is_weekly_friday,
        is_weekly_saturday: self.formData.is_weekly_saturday,
      };

      return ScheduleService.getFrequencySummary(schedule, self.utcOffset);
    }

    /**
     * Reset all weekday boolean flags
     */
    function resetWeekdays() {
      self.formData.is_weekly_sunday = false;
      self.formData.is_weekly_monday = false;
      self.formData.is_weekly_tuesday = false;
      self.formData.is_weekly_wednesday = false;
      self.formData.is_weekly_thursday = false;
      self.formData.is_weekly_friday = false;
      self.formData.is_weekly_saturday = false;
      enableAllDays();
    }

    /**
     * Enable all weekdays for the date pickers
     */
    function enableAllDays() {
      self.enabledDays = [true, true, true, true, true, true, true];
    }

    /**
     * Disable all weekdays for the date pickers
     */
    function disableAllDays() {
      self.enabledDays = [false, false, false, false, false, false, false];
    }

    /**
     * Build summary text
     */
    function buildSummary(value) {
      if (value) self.formData.period = value;
      $timeout(function () {
        self.freqSummary = getFrequencySummary();
      });
    }

    /**
     * Handle schedule interval change
     *
     * @param  {String} interval    Selected interval
     */
    function onIntervalChange(interval) {
      self.formData.interval = interval;
      self.showWeekdays = interval === "weeks";
      if (self.showWeekdays) {
        var weekday = moment().weekday();
        // Disable all days
        disableAllDays();
        // Enable today
        onSelectWeekday(weekday, true);
        switch (weekday) {
          case 0:
            self.formData.is_weekly_sunday = true;
            break;
          case 1:
            self.formData.is_weekly_monday = true;
            break;
          case 2:
            self.formData.is_weekly_tuesday = true;
            break;
          case 3:
            self.formData.is_weekly_wednesday = true;
            break;
          case 4:
            self.formData.is_weekly_thursday = true;
            break;
          case 5:
            self.formData.is_weekly_friday = true;
            break;
          case 6:
            self.formData.is_weekly_saturday = true;
            break;
        }
      } else {
        resetWeekdays();
      }
      buildSummary(1);
    }

    /**
     * Determine if the form is disabled
     *
     * @return {Boolean}  True if disabled, false if not
     */
    function isDisabled() {
      return (
        self.saving ||
        self.scheduleInvalid ||
        !self.startDate ||
        !self.dueDate ||
        self.formData.days_until_due < 0 ||
        self.startDate < self.today ||
        self.dueDate <= self.startDate ||
        self.formData.days_until_due === 0
      );
    }

    /**
     * Handle start date selection, update due date and set days until due
     */
    function onSelectStartDate(startDay) {
      self.startDate = startDay;
      var nextDay = moment(self.startDate).add(1, "days").toDate();
      self.minDueDate = nextDay;
      if (self.dueDate <= self.startDate) {
        self.dueDate = angular.copy(nextDay);
        self.formData.days_until_due = 1;
      } else {
        var start = moment(self.startDate);
        var end = moment(self.dueDate);
        self.formData.days_until_due = end.diff(start, "days");
      }
      buildSummary();
    }

    /**
     * Handle due date selection and set days until due
     */
    function onSelectDueDate(dueDate) {
      self.dueDate = dueDate;
      var start = moment(self.startDate);
      var end = moment(self.dueDate);
      self.formData.days_until_due = end.diff(start, "days");
      buildSummary();
    }

    /**
     * Handle duration (days until due) change and update due date accordingly
     *
     * @param  {Number} duration    Seleted duration
     */
    function onDurationChange(duration) {
      self.formData.days_until_due = duration;
      var start = moment(self.startDate);
      self.dueDate = start.add(duration, "days").toDate();
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function onChange($event, field) {
      const model = $event.model;
      switch (field) {
        case "repeatsEvery":
          buildSummary(model);
          break;
        case "intervals":
          onIntervalChange(model.toLowerCase());
          break;
        case "startDate":
          onSelectStartDate(model);
          break;
        case "daysUntilDue":
          onDurationChange(model);
          break;
        case "dueDate":
          onSelectDueDate(model);
          break;
        default:
          break;
      }
    }

    /**
     * Save the new schedule frequency
     */
    function save() {
      if (self.saving) return;
      self.saving = true;
      self.formData.start_date = angular.copy(self.startDate);

      ScheduleService.update(
        self.schedule.building,
        self.schedule._id,
        self.formData
      )
        .then(function (schedule) {
          $mdDialog.hide(schedule);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Handle weekday checkbox selection
     *
     * @param  {Number}     day         Selected day of the week
     * @param  {Boolean}    checked     If checkbox is checked
     */
    function onSelectWeekday(day, checked) {
      self.enabledDays[day] = checked;
      // Determine the next valid start date based on selected weekdays
      self.scheduleInvalid = true;
      var todayEnabled = self.enabledDays[self.today.getDay()];
      if (todayEnabled) {
        // Set start date to today
        self.startDate = angular.copy(self.today);
        self.scheduleInvalid = false;
      } else {
        // Find the next valid day for start date
        var futureDate = moment();
        for (var i = 1; i <= 7; ++i) {
          futureDate.add(1, "days");
          if (self.enabledDays[futureDate.weekday()]) {
            self.startDate = futureDate.toDate();
            self.scheduleInvalid = false;
            break;
          }
        }
      }
      // Update duration, end date, and summary
      self.formData.days_until_due = 1;
      onDurationChange(1);
      buildSummary();
    }

    function isDateDisabled(day) {
      if (!self.showWeekdays) return true;
      return self.enabledDays[day.getDay()];
    }
  }
})();
