(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.list.floor
   */
  angular
    .module("akitabox.desktop.directives.list.floor", [
      "akitabox.core.toast",
      "akitabox.core.services.building",
      "akitabox.core.services.flag",
      "akitabox.core.services.floor",
      "akitabox.core.services.organization",
      "akitabox.core.services.tagCategory",
      "akitabox.core.services.tagCategory",
      "akitabox.core.toast",
      "akitabox.core.services.user",
      "akitabox.ui.dialogs.floor.create",
      "akitabox.ui.dialogs.schedule.create",
      "akitabox.ui.dialogs.request.create",
      "akitabox.ui.dialogs.workOrder.create",
      "akitabox.ui.dialogs.floor.delete",
      "akitabox.ui.dialogs.document.select",
      "dndLists",
    ])
    .directive("abxFloorList", AbxFloorListDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.list.floor
   * @name AbxFloorListDirective
   *
   * @description
   * `abx-floor-list` contains a list of floor and actions to be performed on them
   *
   * @usage
   * <hljs lang="html">
   *
   * <abx-floor-list
   *     abx-building="vm.building"
   *     abx-models="vm.floors"
   *     abx-fetch="vm.fetchFloors"
   *     abx-fetch-all"vm.fetchAllFloors"
   * </abx-floor-list>
   *
   * </hljs>
   */
  function AbxFloorListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/floor/floor-list.html",
      controller: FloorListController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        parentFetch: "&abxFetch",
        parentFetchAll: "&abxFetchAll",
        allowAdd: "&?abxAllowAdd",
        allowReorder: "&?abxAllowReorder",
      },
    };
  }

  /* @ngInject */
  function FloorListController(
    // Angular
    $element,
    $q,
    $scope,
    $timeout,
    // Dialogs
    CreateRequestDialog,
    CreateScheduleDialog,
    CreateWorkOrderDialog,
    DeleteFloorDialog,
    SelectDocumentDialog,
    // Services
    BuildingService,
    FeatureFlagService,
    FloorService,
    OrganizationService,
    TagCategoryService,
    ToastService,
    UserService
  ) {
    var self = this;
    var permissions = UserService.getPermissions();

    // Attributes
    self.permissions = getDefaultPermissions();
    self.organization = OrganizationService.getCurrent();
    self.saving = false;

    // Functions
    self.getListActions = getListActions;
    self.getFloors = getFloors;
    self.getAllFloors = getAllFloors;
    self.editFloorPlan = editFloorPlan;
    self.floorDragStart = floorDragStart;
    self.floorDropped = floorDropped;
    self.floorMoved = floorMoved;
    self.buildings = {};

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      self.buildings = [self.building];

      self.permissions.canAddSchedule = permissions.future_task.create;
      self.permissions.canAddRequest = permissions.request.create;
      self.permissions.canAddWorkOrder = permissions.task.create;
      self.permissions.canDeleteFloor = permissions.is_administrator;
      self.permissions.canEditFloorPlan = permissions.is_administrator;

      $scope.$watchCollection("vm.models", function (models) {
        if (models && !self.building) {
          BuildingService.populateBuildings(self.buildings, models);
        }
      });
    }

    function getDefaultPermissions() {
      var perms = {};
      if (self.buildingId) {
        perms[self.buildingId] = {
          canAddRequest: false,
          canAddSchedule: false,
          canAddWorkOrder: false,
          canDeleteFloor: false,
          canEditFloorPlan: false,
        };
      }

      return perms;
    }

    /**
     * Called when a floor drag action begins
     */
    function floorDragStart() {
      // Set the current selected floors
      self.selectedFloorIndices = [];
      var floorElems = $element[0].querySelectorAll("abx-list-item");
      for (var i = 0; i < floorElems.length; ++i) {
        var $floorItem = angular.element(floorElems[i]);
        if ($floorItem.hasClass("selected")) self.selectedFloorIndices.push(i);
      }
    }

    /**
     * Called when a floor is moved to any new location
     */
    function floorMoved($index) {
      self.movedFloor = self.models[$index];

      // Remove the floor from the list while dragging. Necessary for `dndLists`
      self.models.splice($index, 1);
    }

    /**
     * Called when a floor is dropped in a new location
     */
    function floorDropped() {
      if (!self.movedFloor) return;

      var newOrder = -1;
      for (var i = 0; i < self.models.length; ++i) {
        if (self.models[i]._id === self.movedFloor._id) {
          newOrder = i;
        }
        self.models[i].order = i;
      }

      if (newOrder === -1) {
        // Floor not moved. Selected floors will be preserved
        return $timeout(function () {
          $scope.$broadcast(
            "list:setSelectedIndices",
            self.selectedFloorIndices
          );
          $scope.$broadcast("list:refreshClickEvents");
        });
      }

      if (newOrder > self.movedFloor.order) {
        newOrder++;
      }

      const data = {
        order: newOrder,
      };

      self.saving = true;
      FloorService.update(self.building._id, self.movedFloor._id, data)
        .then(() => {
          ToastService.showSimple("Floors successfully reordered");
        })
        .catch(function (err) {
          ToastService.showError(err);
        })
        .finally(function () {
          self.movedFloor = null;
          $timeout(function () {
            // Reset selected floors for simplicity
            $scope.$broadcast("list:setSelectedIndices", []);
            $scope.$broadcast("list:refreshClickEvents");
          });
          self.saving = false;
        });
    }

    function createServiceRequest(items) {
      var locals;
      var floor = self.models[items[0]];
      var building = self.building
        ? self.building
        : self.buildings[floor.building];
      locals = {
        building: building,
        floor: floor,
      };
      return CreateRequestDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function createWorkOrder(items) {
      var locals;
      var floors = items.map(function (index) {
        return self.models[index];
      });
      var building = self.building
        ? self.building
        : self.buildings[floors[0].building];
      locals = {
        building: building,
        floors: floors,
      };

      return CreateWorkOrderDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function createSchedule(items) {
      var locals;
      var floors = items.map(function (index) {
        return self.models[index];
      });
      var building = self.building
        ? self.building
        : self.buildings[floors[0].building];
      locals = {
        building: building,
        floors: floors,
      };

      return CreateScheduleDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function deleteFloors(items) {
      var model = self.models[items[0]];
      var building = self.building;
      if (!building && self.buildings) {
        building = self.buildings[model.building];
      }

      var floorIDs = items.map(function (item) {
        return self.models[item]._id;
      });
      var locals = {
        floorIDs: floorIDs,
        building: building,
      };
      return DeleteFloorDialog.show({ locals: locals })
        .then(function (deletedIDs) {
          // update models to remove the deleted floors
          if (self.models.length === deletedIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (floor) {
              return deletedIDs.indexOf(floor._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------
    function editFloorPlan(items, $event) {
      // Prevent list actions from showing up
      if ($event) $event.stopImmediatePropagation();

      var model = self.models[items[0]];
      var building = self.building;

      if (!building && self.buildings) {
        building = self.buildings[model.building];
      }

      var locals = {
        building: building,
        title: "Edit Floor Plan",
        followLink: false,
      };

      return TagCategoryService.getAll(model.building)
        .then(function (tagCategories) {
          locals.tagCategories = tagCategories;
          return SelectDocumentDialog.show({ locals: locals });
        })
        .then(function (document) {
          return FloorService.updateDocument(
            model.building,
            self.models[items[0]]._id,
            document._id
          );
        })
        .then(function (updatedFloor) {
          self.models[items[0]] = updatedFloor;
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
            $scope.$broadcast("list:setSelectedIndices", items);
          });
        })
        .catch(ToastService.showError);
    }

    function getListActions(items) {
      var permissions = self.permissions;
      var isWorkOrderOrg = self.organization.show_tasks;

      // List actions in order
      var editFloorPlanAction = {
        text: "Edit Floor Plan",
        icon: "image",
        onClick: editFloorPlan,
      };
      var addRequestAction = {
        text: "Add Service Request",
        icon: "announcement",
        onClick: createServiceRequest,
      };
      var addWorkOrderAction = {
        text: "Add Work Order",
        icon: "build",
        onClick: createWorkOrder,
      };
      var addScheduleAction = {
        text: "Add Maintenance Schedule",
        icon: "insert_invitation",
        onClick: createSchedule,
      };
      var deleteFloorAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteFloors,
      };

      var actions = [];

      // Allow editing of floor plan and adding requests only if one floor is selected
      if (items.length === 1) {
        if (permissions.canEditFloorPlan) actions.push(editFloorPlanAction);
        if (permissions.canAddRequest && isWorkOrderOrg) {
          actions.push(addRequestAction);
        }
      }

      // Add other create actions
      var allSameBuilding;
      // building list or only one item, same bldg by definition
      if (self.building || items.length === 1) {
        allSameBuilding = true;
      } else {
        // org list and multiple items, check if all same bldg
        var floors = items.map(function (index) {
          return self.models[index];
        });
        allSameBuilding = floors.every(function (floor) {
          return floor.building === floors[0].building;
        });
      }
      if (isWorkOrderOrg && allSameBuilding) {
        if (permissions.canAddWorkOrder) actions.push(addWorkOrderAction);
        if (permissions.canAddSchedule) actions.push(addScheduleAction);
      }

      // Add delete action
      if (items.length === 1 && permissions.canDeleteFloor) {
        actions.push(deleteFloorAction);
      }

      return actions;
    }

    function getFloors(skip, limit) {
      // Call the parent's fetch function to get a page of floors
      return self.parentFetch({
        skip: skip,
        limit: limit,
      });
    }

    function getAllFloors(limit) {
      // Call the parent's fetch all function to get all the floors
      return self.parentFetchAll({
        limit: limit,
      });
    }
  }
})();
