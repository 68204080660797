(function () {
  angular
    .module("akitabox.desktop.room.organizationList")
    .controller(
      "OrganizationRoomListController",
      OrganizationRoomListController
    );

  /* @ngInject */
  function OrganizationRoomListController(
    // Angular
    $scope,
    $timeout,
    $location,
    $q,
    // Constants
    EVENT_ROOM_CREATE,
    // Dialogs
    ExportDialog,
    // Services
    OrganizationService,
    PinService,
    RoomService,
    ToastService,
    FeatureFlagService,
    SessionService,
    // Filtering
    ManagedBuildingFilter,
    ManagedModelFieldFilter,
    FilterBarManager,
    // Resolve
    decommissioned
  ) {
    var self = this;
    var organization = OrganizationService.getCurrent();

    // Attributes
    self.organization = organization;
    self.rooms = [];
    self.decommissioned = decommissioned;

    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.selectedRoom = null;
    self.classes = { sidenav: "org-room-list-sidenav" };
    self.isListViewSidebarEnabled =
      FeatureFlagService.isEnabled("listview_sidebar") || false;
    self.showSidebar = SessionService.getRoomListSidebarToggleState() || false;
    self.hiddenActions = {
      move: true,
      delete: true,
      duplicate: true,
      overviewLink: true,
      planViewLink: false,
    };

    // Functions
    self.changeFilters = changeFilters;
    self.fetchRooms = fetchRooms;
    self.fetchAllRooms = fetchAllRooms;
    self.clearSelectedRoom = clearSelectedRoom;
    self.updateSelectedRoom = updateSelectedRoom;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ROOM_CREATE, onRoomCreate);
    $scope.$on("room_selected", onRoomSelection);
    $scope.$on("close_sidebar", clearSelectedRoom);
    $scope.$watch("vm.showSidebar", onToggleSidebar);

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?decommissioned=${decommissioned} portion of the query
        query.decommissioned = decommissioned;
        // update the list
        changeFilters(query);
      },
    });
    var numberConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Number",
      queryField: "number",
    });

    var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Name",
      queryField: "name",
    });

    var placedOnFloorConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Placed on Floor Plan",
        queryField: "percentX",
        inputType: "boolean",
        modelValueToFilterValue: function (placedOnFloor) {
          return placedOnFloor ? "$ne,null" : "null";
        },
        modelValueToChipText: function (placedOnFloor) {
          return placedOnFloor ? "Yes" : "No";
        },
        filterValueToModelValue: function (queryParameter) {
          return [queryParameter === "$ne,null"];
        },
      }
    );

    var buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
      getOrganizationId: function () {
        return self.organization._id;
      },
    });

    self.filterBarManager.addFilterConfiguration(nameConfig);
    self.filterBarManager.addFilterConfiguration(numberConfig);
    self.filterBarManager.addFilterConfiguration(placedOnFloorConfig);
    self.filterBarManager.addFilterConfiguration(buildingConfig);

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // ------------------------
    //   Private Functions
    // ------------------------

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportRooms,
        },
      ];
    }

    function exportRooms() {
      var locals = {
        route: RoomService.buildOrganizationListRoute(organization._id),
        filters: self.activeFilters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    /**
     * Handle room creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Room[]} rooms    List of new rooms
     */
    function onRoomCreate($event, rooms) {
      // We can only create one room at a time
      var room = rooms[0];
      PinService.includePinType(room.building, [room]).then(function (results) {
        self.rooms.unshift(results[0]);
        $timeout(function () {
          $scope.$broadcast("list:refreshClickEvents");
        });
      });
    }

    function onToggleSidebar() {
      SessionService.setRoomListSidebarToggleState(self.showSidebar);
    }

    function onRoomSelection(_event, room) {
      const shouldShowSidebar =
        self.isListViewSidebarEnabled && self.showSidebar;
      if (shouldShowSidebar && room) {
        self.selectedRoom = null;
        RoomService.getById(
          room.building._id || room.building,
          room._id,
          { include_values: true },
          { includePinType: true, includeFloor: true }
        )
          .then((model) => {
            self.selectedRoom = model;
          })
          .catch(ToastService.showError);
      } else {
        self.selectedRoom = null;
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------
    function clearSelectedRoom() {
      self.selectedRoom = null;
    }

    function updateSelectedRoom($event) {
      const { field, pinField, newValue } = $event;
      const { _id, values, building } = self.selectedRoom;
      if (field === "values") {
        const pinValue = values[pinField._id];
        RoomService.updateValueByDataType(
          building,
          pinField.data_type,
          _id,
          pinValue._id,
          newValue,
          { pin: self.selectedRoom }
        ).catch(ToastService.showError);
      } else {
        RoomService.updateById(
          building,
          _id,
          { [field]: newValue },
          {},
          self.selectedRoom
        ).catch(ToastService.showError);
      }
    }

    function fetchRooms(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }
      var doFetch = function () {
        var params;
        params = {
          skip: skip,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var options = {
          includePinType: true,
        };

        var filters = angular.extend({}, params, self.activeFilters);
        const request = RoomService.getByBFFRoomsOrganization(
          organization._id,
          filters,
          options
        );

        return request.then((rooms) => {
          if (angular.isArray(self.rooms)) {
            self.rooms = self.rooms.concat(rooms);
          } else {
            self.rooms = rooms;
          }
          return rooms.length;
        });
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllRooms(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(
          self.activeFilters,
          "decommissioned"
        )
      ) {
        $location.search("decommissioned", decommissioned);
      }
      var doFetch = function () {
        var params;
        params = {
          skip: 0,
          limit: limit,
          include_values: true,
          decommissioned: decommissioned,
        };

        var options = {
          includePinType: true,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return RoomService.getAllByOrganization(
          organization._id,
          filters,
          options
        ).then(function (rooms) {
          self.rooms = rooms;
          return rooms.length;
        });
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.rooms = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
