(function () {
  angular.module("akitabox.desktop.directives", [
    "ngSanitize",
    "akitabox.desktop.directives.breadcrumbHeader",
    "akitabox.desktop.directives.buildingHeader",
    "akitabox.desktop.directives.dateField",
    "akitabox.desktop.directives.detailHeader",
    "akitabox.desktop.directives.details",
    "akitabox.desktop.directives.documentListField",
    "akitabox.desktop.directives.filterBar",
    "akitabox.desktop.directives.focus",
    "akitabox.desktop.directives.linkButton",
    "akitabox.desktop.directives.list",
    "akitabox.desktop.directives.noteInput",
    "akitabox.desktop.directives.pinValueList",
    "akitabox.desktop.directives.progressBar",
    "akitabox.desktop.directives.simpleList",
    "akitabox.desktop.directives.stepList",
    "akitabox.desktop.directives.tags",
  ]);
})();
