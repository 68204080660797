(function () {
  angular
    .module("akitabox.ui.directives.attachmentUpload")
    .controller("AbxAttachmentUploadController", AbxAttachmentUploadController);

  // @ngInject
  function AbxAttachmentUploadController(DocumentService) {
    var self = this;
    self.uploadFile = uploadFile;

    function uploadFile(fileToUpload) {
      fileToUpload.complete = false;
      fileToUpload.progress = 0;
      fileToUpload.error = null;

      fileToUpload.document = DocumentService.uploadAttachment(
        self.buildingId,
        fileToUpload.file,
        function (e) {
          fileToUpload.progress = (e.loaded / e.total) * 100;
        }
      )
        .then(function (documents) {
          var document = documents[0];
          if (self.onUploadComplete) {
            self.onUploadComplete()(document);
          }

          return (fileToUpload._document = document);
        })
        .catch(function (errorResponse) {
          if (errorResponse === "File is over the 50 MB limit") {
            fileToUpload.error = new Error(errorResponse);
          } else {
            fileToUpload.error = errorResponse.data.error;
          }
        })
        .finally(function () {
          fileToUpload.complete = true;
        });
    }
  }
})();
