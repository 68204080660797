(function () {
  angular.module("akitabox.ui.components", [
    "akitabox.ui.components.appHeader",
    "akitabox.ui.components.assetCostEstimation",
    "akitabox.ui.components.assetDetailsSidebar",
    "akitabox.ui.components.assetLifeCycle",
    "akitabox.ui.components.blockRadio",
    "akitabox.ui.components.cardToggle",
    "akitabox.ui.components.checklistSummary",
    "akitabox.ui.components.checklistSummaryFilters",
    "akitabox.ui.components.collapsibleCard",
    "akitabox.ui.components.conditionInput",
    "akitabox.ui.components.dashboard",
    "akitabox.ui.components.dashboardStats",
    "akitabox.ui.components.dashboardTasks",
    "akitabox.ui.components.dateInput",
    "akitabox.ui.components.dateTimeInput",
    "akitabox.ui.components.decommissionedHeader",
    "akitabox.ui.components.documentListInput",
    "akitabox.core.services.flag",
    "akitabox.ui.components.floorCard",
    "akitabox.ui.components.floorCardContainer",
    "akitabox.ui.components.input",
    "akitabox.ui.components.inspectionProgramDetails",
    "akitabox.ui.components.label",
    "akitabox.ui.components.lifeCycle",
    "akitabox.ui.components.location",
    "akitabox.ui.components.locationButtons",
    "akitabox.ui.components.locationMap",
    "akitabox.ui.components.missingOrganization",
    "akitabox.ui.components.multipleAssigneeInput",
    "akitabox.ui.components.pinDetails",
    "akitabox.ui.components.pinFieldFilters",
    "akitabox.ui.components.pinSearch",
    "akitabox.ui.components.pinTypeahead",
    "akitabox.ui.components.pinValueInput",
    "akitabox.ui.components.preloaders",
    "akitabox.ui.components.qrcodeInput",
    "akitabox.ui.components.radioButtonGroup",
    "akitabox.ui.components.recordConditionForm",
    "akitabox.ui.components.reportingCharts",
    "akitabox.ui.components.reportingKpi",
    "akitabox.ui.components.roomAssetRelationship",
    "akitabox.ui.components.roundInfo",
    "akitabox.ui.components.serviceRequestList",
    "akitabox.ui.components.sideBar",
    "akitabox.ui.components.simpleDropdown",
    "akitabox.ui.components.staticFloorPlan",
    "akitabox.ui.components.tabs",
    "akitabox.ui.components.toggleIcon",
    "akitabox.ui.components.topBanner",
    "akitabox.ui.components.treeInput",
    "akitabox.ui.components.typeAheadInput",
    "akitabox.ui.components.typeAheadDynamicInput",
    "akitabox.ui.components.workOrderCalendar",
    "akitabox.ui.components.workOrderCard",
    "akitabox.ui.components.workOrderList",
    "akitabox.ui.components.workOrderLogForm",
    "akitabox.ui.components.catalogItemsSelection",
  ]);
})();
