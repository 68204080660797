(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller("FloorSchedulesTabController", FloorSchedulesTabController);

  /* @ngInject */
  function FloorSchedulesTabController(
    // Angular
    $scope,
    // Constants
    EVENT_SCHEDULE_CREATE,
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
    // Services
    ScheduleService,
    ToastService,
    // Resolves
    building,
    floor
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.floor = floor;
    self.schedules = null;
    self.status = MAINTENANCE_SCHEDULE_STATUS_ACTIVE;
    self.createOptions = getCreateOptions();

    // Functions
    self.fetchSchedules = fetchSchedules;
    self.fetchAllSchedules = fetchAllSchedules;
    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_SCHEDULE_CREATE, onScheduleCreate);

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchSchedules(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
        level: self.floor._id,
        status: MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
      };

      return ScheduleService.get(building._id, params)
        .then(function (schedules) {
          if (angular.isArray(self.schedules)) {
            self.schedules = self.schedules.concat(schedules);
          } else {
            self.schedules = schedules;
          }
          return schedules.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllSchedules(limit) {
      var params = {
        skip: 0,
        limit: limit,
        level: self.floor._id,
        status: MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
      };

      return ScheduleService.getAll(building._id, params)
        .then(function (schedules) {
          self.schedules = schedules;
          return schedules.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getCreateOptions() {
      return {
        locals: {
          building: building,
          floors: [floor],
        },
      };
    }

    /**
     * Handle schedule creation event
     *
     * @param {Event}       $event      Angular event
     * @param {Schedule[]}  schedules   List of new schedules
     */
    function onScheduleCreate($event, schedules) {
      for (var i = 0; i < schedules.length; ++i) {
        var schedule = schedules[i];
        if (!schedule.level) {
          continue;
        }
        var floorId = Object.prototype.hasOwnProperty.call(
          schedule.level,
          "_id"
        )
          ? schedule.level._id
          : schedule.level;
        if (floorId === self.floor._id) {
          if (!self.schedules) {
            self.schedules = [];
          }
          self.schedules.unshift(schedules[i]);
        }
      }
    }
  }
})();
