(function () {
  angular
    .module("akitabox.desktop.directives.list.workOrder", [
      "akitabox.core.constants",
      "akitabox.core.toast",
      "akitabox.core.services.flag",
      "akitabox.core.services.shadow",
      "akitabox.core.services.user",
      "akitabox.ui.dialogs.bulkAssign",
      "akitabox.ui.dialogs.print",
      "akitabox.core.services.timeZone",
      "akitabox.ui.dialogs.workOrder",
      "akitabox.ui.dialogs.workOrderLog",
      "angular.filter",
    ])
    .controller("AbxWorkOrderListController", WorkOrderListController)
    .directive("abxWorkOrderList", AbxWorkOrderListDirective);

  /* @ngInject */
  function AbxWorkOrderListDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/list/work-order/work-order-list.html",
      controller: "AbxWorkOrderListController",
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        status: "=abxStatus",
        parentFetch: "&abxFetch",
        parentFetchAll: "&abxFetchAll",
        parentFetchLimit: "=abxFetchLimit",
        changeSort: "&abxChangeSort",
        showStartDateColumn: "=abxShowStartDateColumn",
        createOptions: "=?abxCreateOptions",
      },
    };
  }

  /* @ngInject */
  function WorkOrderListController(
    // Angular
    $scope,
    $timeout,
    // Constants
    WORK_ORDER_STATUS_CANCELED,
    WORK_ORDER_STATUS_COMPLETED,
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_SCHEDULED_OPEN,
    PRIORITIES,
    moment,
    // Dialogs
    BulkAssignDialog,
    CancelWorkOrderDialog,
    CompleteWorkOrderDialog,
    DeleteWorkOrderDialog,
    WorkOrderLogDialog,
    PrintDialog,
    ReopenWorkOrderDialog,
    RescheduleWorkOrderDialog,
    // Services
    ShadowService,
    WorkOrderService,
    BuildingService,
    FeatureFlagService,
    OrganizationService,
    SessionService,
    TimeZoneService,
    ToastService,
    UserService
  ) {
    var self = this;
    var openStatuses = [
      WORK_ORDER_STATUS_OPEN,
      WORK_ORDER_STATUS_SCHEDULED,
      WORK_ORDER_STATUS_SCHEDULED_OPEN,
    ];
    var GA_CATEGORY = "workorder";

    var permissions = UserService.getPermissions();

    // Attributes
    self.PRIORITIES = PRIORITIES;
    self.permissions = getDefaultPermissions();
    self.isOpen = openStatuses.indexOf(self.status) > -1;
    self.isScheduled = self.status === WORK_ORDER_STATUS_SCHEDULED;
    self.isOrganizationScheduleList = !self.building && self.isScheduled;
    self.displayStartDateColumn =
      !self.building &&
      !self.isScheduled &&
      self.isOpen &&
      self.showStartDateColumn;
    self.organization = OrganizationService.getCurrent();
    self.buildings = {};
    self.utcOffset = TimeZoneService.getCurrentUTCOffset(self.building);
    self.sortBy = getInitialSortColumn();
    self.sortOrder = getInitialSortOrder();
    self.sortIcon =
      self.sortOrder === "asc" ? "keyboard_arrow_down" : "keyboard_arrow_up";
    self.isRefreshing = false;

    // Functions
    self.getListActions = getListActions;
    self.getWorkOrders = getWorkOrders;
    self.getAllWorkOrders = getAllWorkOrders;
    self.selectAllGA = selectAllGA;
    self.getAsset = WorkOrderService.getAsset;
    self.getRoom = WorkOrderService.getRoom;
    self.getLevel = WorkOrderService.getLevel;
    self.sort = sort;
    self.buildingTzOffset = buildingTzOffset;

    self.getLevels = WorkOrderService.getLevels;
    self.hasMultipleStops = function (workOrder) {
      return workOrder.num_round_stops > 1;
    };

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watch("vm.status", function (value) {
        if (value) {
          self.isOpen = openStatuses.indexOf(self.status) > -1;
          self.isScheduled = self.status === WORK_ORDER_STATUS_SCHEDULED;
        }
      });
      $scope.$watch("vm.showStartDateColumn", function (value) {
        self.displayStartDateColumn =
          !self.building &&
          !self.isScheduled &&
          self.isOpen &&
          self.showStartDateColumn;

        if (self.displayStartDateColumn && self.sortBy === "due_date") {
          self.sort("start_date");
        } else if (
          !self.displayStartDateColumn &&
          self.sortBy === "start_date" &&
          !self.building
        ) {
          self.sort("due_date");
        }
      });
      $scope.$watchCollection("vm.models", function (models) {
        if (models && !self.building) {
          BuildingService.populateBuildings(self.buildings, models);
        }
      });
      $scope.$on("list:refresh", function () {
        self.isRefreshing = true;
      });
      $scope.$on("list:refreshComplete", function () {
        self.isRefreshing = false;
      });
    }

    function getInitialSortColumn() {
      var showStartDate = self.displayStartDateColumn && !self.building;
      var column = SessionService.getWorkOrderSortColumn(
        showStartDate,
        self.status
      );

      return column;
    }

    function getInitialSortOrder() {
      var order = SessionService.getWorkOrderSortOrder(self.status);

      return order;
    }

    function getDefaultPermissions() {
      // (WEBAPP-9092) remove
      return {
        canComplete: false,
        canCancel: false,
        canAssign: false,
        canReopen: false,
        canDelete: false,
        canReschedule: false,
        canLogWork: false,
      };
    }

    function completeWorkOrders(items) {
      var locals;
      var workOrders = items.map(function (index) {
        return self.models[index];
      });
      locals = {
        workOrders: workOrders,
      };

      // Send a Bulk completion event to GA
      ShadowService.sendEvent(GA_CATEGORY, "bulk-complete");

      return CompleteWorkOrderDialog.show({ locals: locals })
        .then(function (completedWorkOrders) {
          var completedWorkOrderIDs = completedWorkOrders.map(function (
            workOrder
          ) {
            return workOrder._id;
          });
          // update models to remove the relevant work orders
          if (self.models.length === completedWorkOrders.length) {
            self.models.length = 0;
          } else if (completedWorkOrders.length > 0) {
            self.models = self.models.filter(function (listWorkOrder) {
              return completedWorkOrderIDs.indexOf(listWorkOrder._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function cancelWorkOrders(items) {
      var locals = {
        workOrders: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };

      // Send a Bulk Cancelation event to GA
      ShadowService.sendEvent(GA_CATEGORY, "bulk-cancel");

      return CancelWorkOrderDialog.show({ locals: locals })
        .then(function (canceledWorkOrderIDs) {
          // update self.models to remove the relevant work orders
          if (self.models.length === canceledWorkOrderIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (workOrder) {
              return canceledWorkOrderIDs.indexOf(workOrder._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function reopenWorkOrders(items) {
      var locals = {
        workOrders: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };

      return ReopenWorkOrderDialog.show({ locals: locals })
        .then(function (removedWorkOrderIDs) {
          // update self.models to remove the relevant work orders
          if (self.models.length === removedWorkOrderIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (workOrder) {
              return removedWorkOrderIDs.indexOf(workOrder._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function printWorkOrders(items) {
      var printWorkOrderIDs = items.map(function (item) {
        return self.models[item]._id;
      });
      var workOrderListRoute = self.building
        ? WorkOrderService.buildListRoute(self.building._id)
        : WorkOrderService.buildOrganizationListRoute(self.organization._id);
      var locals = {
        route: workOrderListRoute,
        selected: printWorkOrderIDs,
      };

      return PrintDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    function deleteWorkOrders(items) {
      var locals = {
        workOrders: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };

      return DeleteWorkOrderDialog.show({ locals: locals })
        .then(function (deletedWorkOrderIDs) {
          // update models to remove the relevant work orders
          if (self.models.length === deletedWorkOrderIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (listWorkOrder) {
              return deletedWorkOrderIDs.indexOf(listWorkOrder._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function rescheduleWorkOrders(items) {
      var workOrdersToReschedule = items.map(function (item) {
        return self.models[item];
      });
      var locals = {
        workOrders: workOrdersToReschedule,
      };

      return RescheduleWorkOrderDialog.show({ locals: locals })
        .then(function (rescheduledWorkOrders) {
          for (var i = 0; i < rescheduledWorkOrders.length; i++) {
            // replace old work orders with rescheduled ones
            self.models.splice(items[i], 1, rescheduledWorkOrders[i]);
          }

          // resort the list by due date
          self.models.sort(function (a, b) {
            var aDate = moment(a.due_date);
            var bDate = moment(b.due_date);

            if (aDate > bDate) {
              return 1;
            } else if (aDate < bDate) {
              return -1;
            } else {
              return 0;
            }
          });
          $timeout(function () {
            $scope.$broadcast("list:setSelectedIndices", []);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function logWorkOnWorkOrders(items) {
      var locals = {
        workOrders: [],
      };
      for (var i = 0; i < items.length; i++) {
        locals.workOrders.push(self.models[items[i]]);
      }
      return WorkOrderLogDialog.show({
        locals: locals,
      })
        .then(function () {
          ShadowService.sendEvent("workorder", "add-log", "list", items.length);
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function buildingTzOffset(building, date) {
      if (!building.time_zone) {
        building = self.building ? self.building : self.buildings[building];
      }
      const timeZone = moment(date).tz(building.time_zone);
      const utcOffsetMinutes = timeZone.utcOffset();
      const utcOffsetMinutesMagnitude = Math.abs(utcOffsetMinutes);
      // parse offset minutes into an hour format (ex. "+09:00", or "-05:00")
      const offsetSign = utcOffsetMinutes < 0 ? "-" : "+";
      const offsetHour = Math.floor(utcOffsetMinutesMagnitude / 60);
      const offsetMinutes = utcOffsetMinutesMagnitude % 60;

      const offsetHourDisplay =
        offsetHour < 10 ? `0${offsetHour}` : `${offsetHour}`;
      const offsetMinutesDisplay =
        offsetMinutes < 10 ? `0${offsetMinutes}` : `${offsetMinutes}`;
      var tzOffset = `${offsetSign}${offsetHourDisplay}:${offsetMinutesDisplay}`;

      const realOffsetMoment = moment(tzOffset, "Z");

      var time = moment(date)
        .subtract(realOffsetMoment.hours(), "hours")
        .subtract(realOffsetMoment.minutes(), "minutes");
      var formattedTime = time.format("MMM D, YYYY");

      return formattedTime;
    }

    function sort(sortBy) {
      if (sortBy === "closed") {
        if (self.status === WORK_ORDER_STATUS_COMPLETED) {
          sortBy = "completed_date";
        } else if (self.status === WORK_ORDER_STATUS_CANCELED) {
          sortBy = "canceled_date";
        }
      }
      self.sortOrder =
        self.sortBy !== sortBy
          ? "asc"
          : self.sortOrder === "desc"
          ? "asc"
          : "desc";
      self.sortBy = sortBy;
      self.sortIcon =
        self.sortOrder === "asc" ? "keyboard_arrow_down" : "keyboard_arrow_up";
      self.changeSort({ sortBy: self.sortBy, sortOrder: self.sortOrder });
    }

    function getListActions(items) {
      var completeAction = {
        text: "Complete",
        icon: "check_circle_outline",
        onClick: completeWorkOrders,
      };
      var cancelAction = {
        text: "Cancel",
        icon: "close",
        onClick: cancelWorkOrders,
      };
      var assignAction = {
        text: "Assign",
        icon: "person",
        onClick: bulkAssignWorkOrders,
      };
      var reopenAction = {
        text: "Reopen",
        icon: "refresh",
        onClick: reopenWorkOrders,
      };
      var printAction = {
        text: "Print",
        icon: "print",
        onClick: printWorkOrders,
      };
      var deleteAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteWorkOrders,
      };
      var rescheduleAction = {
        text: "Reschedule",
        icon: "insert_invitation",
        onClick: rescheduleWorkOrders,
      };
      var logWorkAction = {
        text: "Log Work",
        icon: "access_time",
        onClick: logWorkOnWorkOrders,
      };

      var actions = [];

      var canComplete = false;
      var canCancel = false;
      var canAssign = false;
      var canReopen = false;
      var canDelete = false;
      var canReschedule = false;
      var canLogWork = false;

      var isAssignedToAll = false;

      isAssignedToAll = isUserAssigneedToAll(items);
      canComplete = permissions.task.complete_any || isAssignedToAll;
      canCancel = permissions.task.cancel;
      canAssign = permissions.task.assign;
      canReopen = permissions.task.reopen;
      canDelete = permissions.task.remove;
      canReschedule = permissions.task.update;
      if (
        permissions.work_order_log.create_any ||
        permissions.work_order_log.create_own_log_on_any_task
      ) {
        canLogWork = true;
      } else if (isAssignedToAll) {
        canLogWork = true;
      }

      if (self.isOpen) {
        if (canComplete && !self.isScheduled) {
          actions.push(completeAction);
        }
        if (canCancel) {
          actions.push(cancelAction);
        }
        if (canAssign) {
          actions.push(assignAction);
        }
        if (canReschedule) {
          actions.push(rescheduleAction);
        }
        if (canLogWork) {
          actions.push(logWorkAction);
        }
      } else {
        if (canReopen) {
          actions.push(reopenAction);
        }
        if (canDelete) {
          actions.push(deleteAction);
        }
      }

      actions.push(printAction);

      return actions;
    }

    function getWorkOrders(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
      };

      if (self.sortBy !== null) {
        params.sort_by = self.sortBy;
      }

      return self.parentFetch(params);
    }

    function getAllWorkOrders(limit) {
      var params = {
        limit: limit,
      };

      if (self.sortBy !== null) {
        params.sort_by = self.sortBy;
      }

      return self.parentFetchAll(params);
    }

    /**
     * A callback function to pass into abx-list directive. It triggers in the
     * preSelectAll hook, right before an actual selection occurs, and notifies GA of the
     * select all event.
     */
    function selectAllGA() {
      ShadowService.sendEvent(GA_CATEGORY, "select-all");
    }

    /**
     * Handles spawning the bulk assign dialog to help users assign in batches
     *
     * @param {Array<number>} items - array of the indexes for the selected items in the list
     *
     * @return {Promise<void>}
     */
    function bulkAssignWorkOrders(items) {
      var options;
      var workOrdersToAssign = items.map(function (item) {
        return self.models[item];
      });
      var buildings = workOrdersToAssign.map(function (wo) {
        return wo.building;
      });
      options = {
        locals: {
          // this needs to be passed directly, do not pass in a copy or the
          // dialog will not be able to update the assignees correctly within
          // this list
          buildings: buildings,
          workOrders: self.models,
          selectedIndexes: items,
          showTextNotificationByEmail: true,
        },
      };
      return BulkAssignDialog.show(options)
        .catch(ToastService.showError)
        .finally(function () {
          $timeout(function () {
            $scope.$broadcast("list:setSelectedIndices", items);
            $scope.$broadcast("list:refreshClickEvents");
          });
        });
    }

    function isUserAssigneedToAll(items) {
      var user = UserService.getCurrent();
      var identityId = user.identity._id;
      var isAssignedToAll = true;
      for (var i = 0; i < items.length; ++i) {
        var workOrder = self.models[items[i]];
        var assignees = workOrder.assignees
          ? workOrder.assignees.map(function (assignee) {
              if (assignee._id) return assignee._id;
              return assignee;
            })
          : [];
        if (
          workOrder.is_scheduled ||
          !assignees.length ||
          assignees.indexOf(identityId) < 0
        ) {
          isAssignedToAll = false;
          break;
        }
      }

      return isAssignedToAll;
    }
  }
})();
