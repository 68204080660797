(function () {
  angular.module("akitabox.ui.components.inspectionProgramDetails", [
    "akitabox.constants",
    "akitabox.core.services.chart",
    "akitabox.core.services.flag",
    "akitabox.core.services.identity",
    "akitabox.core.services.request",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.user",
    "akitabox.core.utils",
    "akitabox.ui.dialogs.inspectionProgram.create",
  ]);
})();
