(function () {
  /**
   * AbxMarkupToolButton behavioral directive.
   *
   * Activates the specified markup tool when click, fires the selection
   * event, and applies .selected class.
   */

  angular
    .module("akitabox.planView")
    .directive("abxMarkupToolButton", AbxMarkupToolButton);

  /* @ngInject */
  function AbxMarkupToolButton(
    // Constants
    MARKUP_TOOL,
    // Angular
    $rootScope,
    // Services
    ShadowService,
    abxMarkupTools
  ) {
    return {
      restrict: "A",
      scope: true,
      link: function ($scope, $element, attrs) {
        var toolName = attrs.abxMarkupToolButton;
        if (!abxMarkupTools[toolName]) {
          throw new Error("Invalid MarkupTool: " + toolName);
        }
        var toolClass = abxMarkupTools[toolName];

        $element[0].addEventListener("click", activateTool);

        $scope.$on("$destroy", function () {
          $element[0].removeEventListener("click", activateTool);
        });

        $scope.$watch(
          function () {
            return abxMarkupTools.activeTool;
          },
          function (value) {
            if (value === toolName) {
              $element.addClass("selected");
            } else {
              $element.removeClass("selected");
            }
          }
        );

        function activateTool() {
          var currentTool = abxMarkupTools.activeTool;
          var GA_CATEGORY = "markup";
          var GA_LABEL; // on/off for selecting/deselecting a tool

          abxMarkupTools.reset();

          var selectingATool = currentTool !== toolName;
          if (selectingATool) {
            new toolClass();
            abxMarkupTools.activeTool = toolName;
            GA_LABEL = "on";
          } else {
            // deselecting the tool
            GA_LABEL = "off";
          }

          ShadowService.sendEvent(
            GA_CATEGORY,
            "toggle-" + toolName.toLowerCase(),
            GA_LABEL,
            null
          );

          $rootScope.$broadcast(
            MARKUP_TOOL.ACTIONS.DRAWER_TOOL_SELECTED,
            selectingATool
          );
        }
      },
    };
  }
})();
