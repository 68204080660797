(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.donutChart
   *
   * @param {Array} data - data to populate the chart
   * @param {String} idTag - unique #id for the chart
   * @param {Array} colors - an array of colors for the chart (has default)
   */
  angular
    .module("akitabox.ui.directives.lineChart", [
      "ngMaterial",
      "akitabox.core",
      "akitabox.core.lib.d3",
      "akitabox.core.services.chart",
    ])
    .directive("abxLineChart", AbxLineChartDirective);

  /* @ngInject */
  function AbxLineChartDirective(
    $log,
    $window,
    d3,
    DEFAULT_CHART_COLOR_RANGE,
    ChartService
  ) {
    return {
      restrict: "E",
      scope: {
        data: "<abxData",
        visibleData: "<?abxVisibleData",
        idTag: "<abxIdTag",
        colors: "<?abxColors",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        hoverLabels: "<abxHoverLabels",
      },
      link: postlink,
    };
    function postlink($scope, $element) {
      if (!$scope.data) {
        return $log.error("<abx-line-chart>: abx-data must be provided");
      }

      if (!$scope.idTag) {
        return $log.error("<abx-line-chart>: abx-id-tag must be provided");
      }

      // Constants
      var CHART_SUFFIX = "-line-chart";
      var HEIGHT = 300;
      var VERTICAL_MARGIN = 20;
      var HORIZONTAL_MARGIN = 40;
      var INNER_PADDING = 20;
      var ROTATE_LABEL_CLASS = "label-container-rotate";
      var RADIUS = 4;

      // Attributes
      var timeFormat = "%b %d, %Y";
      var idTag = $scope.idTag;

      // Defaults
      var colorRange = $scope.colors || DEFAULT_CHART_COLOR_RANGE;
      var color = d3.scaleOrdinal().range(colorRange);
      var hoverLabels = $scope.hoverLabels || [];
      var defaultVisibleData = [];
      for (var i = 0; i < $scope.data.length; ++i) {
        defaultVisibleData[i] = true;
      }
      var visibleData = $scope.visibleData || defaultVisibleData;

      // Functions
      var debouncedOnResize = angular.debounce(onResize, 50);

      // Watchers

      $scope.$watchCollection("data", function (newData, oldData) {
        drawChart(newData);
      });

      $scope.$watchCollection("visibleData", function (newData, oldData) {
        if (angular.equals(newData, oldData)) return;
        drawChart($scope.data);
      });

      $scope.$watch("startDate", function (newDate, oldDate) {
        if (oldDate.getDate() === newDate.getDate()) return;
        drawChart($scope.data);
      });

      $scope.$watch("endDate", function (newDate, oldDate) {
        if (oldDate.getDate() === newDate.getDate()) return;
        drawChart($scope.data);
      });

      $scope.$watch("hoverLabels", function (labels) {
        hoverLabels = labels || [];
        drawChart($scope.data);
      });

      angular.element($window).on("resize", debouncedOnResize);
      $scope.$watch(
        function () {
          var $parent = $element[0].parentElement;
          return {
            width: $parent ? $parent.clientWidth : 0,
            height: $parent ? $parent.clientHeight : 0,
          };
        },
        debouncedOnResize,
        true
      );

      // Private

      function drawChart(newData) {
        d3.select("#" + idTag + CHART_SUFFIX).remove();
        if (!newData || newData.length <= 0) return;
        if (
          $scope.startDate.toString() === "Invalid Date" ||
          $scope.endDate.toString() === "Invalid Date"
        )
          return;

        var xDomainMax;
        var timeDifference = ChartService.buildTimeDifference(
          $scope.startDate,
          $scope.endDate
        );

        if (timeDifference.days >= 60) timeFormat = "%b %Y";
        if (timeDifference.years >= 3) timeFormat = "%Y";

        var svg = d3
          .select($element[0])
          .append("svg")
          .attr("id", idTag + CHART_SUFFIX)
          .attr("width", "100%")
          .attr("height", HEIGHT);

        var g = svg.append("g");

        var margin = {
          top: VERTICAL_MARGIN,
          right: VERTICAL_MARGIN,
          bottom: HORIZONTAL_MARGIN,
          left: HORIZONTAL_MARGIN,
        };

        var width = $element[0].offsetWidth;
        var height = HEIGHT - margin.top - margin.bottom;

        g = svg
          .append("g")
          .attr(
            "transform",
            "translate(" + margin.left + "," + margin.top + ")"
          );

        // Set up the X and Y domains
        var x = d3
          .scaleUtc()
          .domain([$scope.startDate, $scope.endDate])
          .range([INNER_PADDING, width - HORIZONTAL_MARGIN - INNER_PADDING]);

        xDomainMax = x($scope.endDate);

        var y = d3.scaleLinear().rangeRound([height, 0]);

        y.domain([
          0,
          d3.max(newData, function (array) {
            return d3.max(array, function (d) {
              return d.y;
            });
          }),
        ]).nice();

        // Set up the X and Y axis
        var xAxis = d3
          .axisBottom(x)
          .ticks(timeDifference.days)
          .tickFormat(d3.utcFormat(timeFormat));

        var yAxis = d3.axisRight(y).tickSize(width).ticks(3);

        if (timeDifference.days >= 60) {
          xAxis.ticks(timeDifference.months);
        }

        g.append("g")
          .attr("class", "axis")
          .attr("transform", "translate(0," + height + ")")
          .call(customXAxis);

        g.append("g").attr("class", "axis").call(customYAxis);

        var line = d3
          .line()
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          });

        for (var i = 0; i < newData.length; i++) {
          var n = newData[i];
          if (!visibleData[i]) continue;

          // Draw Line Chart
          g.append("path")
            .datum(n)
            .attr("id", idTag + CHART_SUFFIX + "-path-" + i)
            .attr("class", "line")
            .style("stroke", color(i))
            .attr("d", line);

          g.selectAll(".dot" + i)
            .data(n)
            .enter()
            .append("circle")
            .attr("class", "dot" + i)
            .attr("fill", color(i))
            .attr("cx", function (d) {
              return x(d.x);
            })
            .attr("cy", function (d) {
              return y(d.y);
            })
            .attr("r", RADIUS)
            .attr("data-index", function () {
              return i;
            })
            .attr("id", function (d) {
              return (
                idTag + CHART_SUFFIX + "-dot-" + (d.x ? d.x.valueOf() : "")
              );
            })
            .on("mouseover", function (d, index) {
              // Mouse Out for IE
              onMouseOut();

              var i = d3.select(this).attr("data-index");
              var label = hoverLabels[i];

              // Increase path stroke width
              var path = d3.select("#" + idTag + CHART_SUFFIX + "-path-" + i);
              path.style("stroke-width", "3px");

              // Get all the dots with matching x-values and increase their size
              var dots = d3
                .selectAll(
                  "#" +
                    idTag +
                    CHART_SUFFIX +
                    "-dot-" +
                    (d.x ? d.x.valueOf() : "")
                )
                .attr("r", RADIUS * 1.5);
              var dotNodes = dots._groups[0];

              // Find the maxY value
              var maxY = y(0);
              for (var j = 0; j < dotNodes.length; j++) {
                var dot = dotNodes[j];
                var yCoord = parseInt(d3.select(dot).attr("cy"));
                if (yCoord < maxY) maxY = yCoord;
              }

              //Add Horizontal Hover Line
              g.append("line")
                .style("stroke", "#333333")
                .style("stroke-width", "1px")
                .attr("id", idTag + CHART_SUFFIX + "-hover-line")
                .attr("x1", 0)
                .attr("y1", y(d.y))
                .attr("x2", x(d.x))
                .attr("y2", y(d.y));

              // Add Vertical Hover Line
              g.append("line")
                .style("stroke", "#333333")
                .style("stroke-width", "1px")
                .attr("id", idTag + CHART_SUFFIX + "-hover-line")
                .attr("x1", x(d.x))
                .attr("y1", y(0))
                .attr("x2", x(d.x))
                .attr("y2", maxY);

              // Bring the dots to the front
              for (var n = 0; n < dotNodes.length; n++) {
                var dotNode = dotNodes[n];
                dotNode.parentNode.appendChild(dotNode);
              }

              onMouseOver(d, label, index + 1);
            })
            .on("mouseout", function (d) {
              onMouseOut();
            });
        }

        // X axis style
        function customXAxis(g) {
          g.call(xAxis);
          g.select(".domain").remove();
          g.selectAll(".tick").style("display", "none").attr("id", "x-axis");
          g.select(".tick:first-of-type").style("display", "block");
          g.select(".tick:last-of-type").style("display", "block");
          g.selectAll(".tick text").attr("dy", 15);
        }

        // Y axis style
        function customYAxis(g) {
          g.call(yAxis);
          g.select(".domain").remove();
          g.selectAll(".tick:not(:first-of-type) line").attr("stroke", "#eee");
          g.selectAll(".tick:first-of-type line").attr("stroke", "#999");
          g.select(".tick:first-of-type text").style("display", "none");
          g.selectAll(".tick text").attr("x", -40).attr("dy", 0);
        }

        function onMouseOver(d, label, index) {
          var yValue = d.y;
          var xValue = x(d.x);

          // Show tick
          g.select("#x-axis.tick:nth-of-type(" + index + ")").style(
            "display",
            "block"
          );

          // Hide first/ticks if too near
          if (xValue > xDomainMax * 0.8) {
            g.select("#x-axis.tick:last-of-type").style("display", "none");
          }

          if (xValue < xDomainMax * 0.1 && xValue !== x($scope.startDate)) {
            g.select("#x-axis.tick:first-of-type").style("display", "none");
          }

          var yCoord = y(d.y) - 300;
          var xCoord = xValue + 15 + RADIUS + HORIZONTAL_MARGIN;
          var labelClass = "";
          var barLabelContainer = d3
            .select($element[0])
            .append("div")
            .attr("id", idTag + CHART_SUFFIX + "-container")
            .attr("class", "chart-hover");

          // Flip if the label is too far right
          if (xValue > xDomainMax * 0.75) {
            labelClass = ROTATE_LABEL_CLASS;
            xCoord = xValue - 15 - RADIUS + HORIZONTAL_MARGIN;
          }

          // Label Container
          var labelContainer = barLabelContainer
            .append("div")
            .attr("class", "label-container " + labelClass)
            .style("position", "absolute")
            .style("top", yCoord + "px")
            .style("left", xCoord + "px")
            .style("border-left", "none");

          // Label Text
          labelContainer
            .append("span")
            .attr("class", "label-number")
            .html(yValue)
            .append("span")
            .attr("class", "label-name")
            .html(" " + label);
        }

        // Mouse out behavior
        function onMouseOut() {
          for (var j = 0; j < length; j++) {
            var path = d3.select("#" + idTag + CHART_SUFFIX + "-path-" + j);

            // Reset Stroke Width
            path.style("stroke-width", "2px");
            // Reset Radius
            d3.selectAll("circle").attr("r", RADIUS);
            // Romve Hover lines
            d3.selectAll("#" + idTag + CHART_SUFFIX + "-hover-line").remove();
          }

          d3.selectAll("#" + idTag + CHART_SUFFIX + "-container").remove();

          // Reset tick marks
          g.selectAll("#x-axis.tick").style("display", "none");
          g.select(".tick:first-of-type").style("display", "block");
          g.select(".tick:last-of-type").style("display", "block");
        }
      }

      /**
       * Check if we can display full text action buttons
       */
      function onResize() {
        drawChart($scope.data);
      }
    }
  }
})();
