(function () {
  angular.module("akitabox.ui.dialogs.editLocation", [
    "ngMaterial",
    "akitabox.core.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.flag",
    "akitabox.core.services.organization",
    "akitabox.core.services.room",
    "akitabox.core.services.schedule",
    "akitabox.core.services.user",
    "akitabox.core.services.workOrder",
    "akitabox.core.toast",
  ]);
})();
