(function () {
  angular.module("akitabox.desktop.building.admin").run(stateBlock);

  /** @ngInject **/
  function stateBlock(Router) {
    Router.configureStates([
      {
        state: "app.building.admin",
        config: {
          url: "/admin",
          abstract: true,
          templateUrl: "app/desktop/modules/building/admin/building-admin.html",
        },
      },
    ]);
  }
})();
