(function () {
  angular
    .module("akitabox.ui.dialogs.recommission")
    .factory("RecommissionDialog", RecommissionDialog);

  /* @ngInject */
  function RecommissionDialog($log, $mdDialog, $mdMedia, $q) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/recommission/recommission.dialog.html",
      controller: "RecommissionDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    return {
      show: show,
    };

    /**
     * @param {object} options
     * @param {object} options.locals
     * @param {Asset[]} options.locals.assets - asset to recommission
     * @param {Room[]} options.locals.rooms - room to recommission
     * @return {Promise<Room[]|Asset[]>} the recommissioned rooms/assets
     */
    function show(options) {
      var error = new Error("Sorry, we weren't able to complete your request");
      var hasErrored = false;

      if (!options) {
        $log.error("RecommissionDialog: no options provided");
        hasErrored = true;
      } else if (!options.locals) {
        $log.error("RecommissionDialog: no locals provided");
        hasErrored = true;
      } else if (
        (!options.locals.assets || !options.locals.assets.length) &&
        (!options.locals.rooms || !options.locals.rooms.length)
      ) {
        $log.error("RecommissionDialog: no rooms/assets provided");
        hasErrored = true;
      }

      if (hasErrored) {
        return $q.reject(error);
      }

      return $mdDialog.show(angular.extend({}, modal, options));
    }
  }
})();
