(function () {
  /**
   * @ngdoc factory
   * @name TreeLevelFactory
   *
   * @description
   * Factory for tree levels. See more information on them in their constructor
   * below.
   */
  angular
    .module("akitabox.ui.components.treeInput")
    .factory("TreeLevelFactory", TreeLevelFactory);

  function TreeLevelFactory(
    // Helpers
    Utils
  ) {
    /**
     * Instantiate and return a new tree level.
     */
    function create(options) {
      return new TreeLevel(options);
    }

    /**
     * Constructs a level in a tree.
     *
     * A tree level represents a specific level/generation in a tree. It houses
     * selectable nodes/options, a currently selected node, and a label that
     * corresponds to the input it will be associated with.
     *
     * Tree level instances are part of a linked list. They each hold references
     * to the level that came before them and the level that came after them to
     * help facilitate some of the special logic that our tree data structure
     * and inputs require.
     *
     * @constructor
     *
     * @param {Object} options - Fields to set on creation
     * @param {Object} [options.parentLevel] - Level that is above this one in the
     *     tree. This will not exist for the first level, as the root node of a
     *     tree is not considered a level.
     * @param {Object} [options.childLevel] - Level that is below this one in the
     *     tree.
     * @param {Object} [options.selectedNode] - Selected node for the level.
     *     NOTE: Do not pass this in before the entire tree is constructed.
     *     Instead, construct the entire tree, then call `level.selectNode`.
     *     This will then do any associated logic with selecting a node and
     *     surrounding levels.
     * @param {String} [options.label] - Label for the input associated with this
     *     level. If omitted, the label will be the title specified by the nodes
     *     in the parent level.
     */
    function TreeLevel(options) {
      if (!options.label && !options.parentLevel) {
        throw new Error("TreeLevel: Must be given label or parentLevel");
      }

      this.enumOptions = [];

      this.parentLevel = options.parentLevel || null;
      this.childLevel = options.childLevel || null;

      this.selectedNode = options.selectedNode || null;
      this.selectableNodes = options.selectableNodes || [];

      this.label =
        options.label || this.parentLevel.selectableNodes[0].child_title;

      var selectableNodes =
        options.selectableNodes || this.parentLevel.getChildren();
      this.setSelectableNodes(selectableNodes);
    }

    // =================
    // Getter Functions
    // =================

    /**
     * Get all selectable nodes for the next level.
     * - If we have a selected node, return that node's children.
     * - If we don't have a selected node, return all of our selectable nodes'
     *     combined children (i.e. our parent level's selected node's
     *     grandchildren)
     *
     * @return {Object[]} - Selectable child nodes
     */
    TreeLevel.prototype.getChildren = function () {
      if (this.selectedNode) {
        return this.selectedNode.children;
      } else {
        return this.selectableNodes.reduce(appendChildren, []);
      }

      /**
       * Append the children of a given node to an accumulating set of nodes.
       *
       * @param {Object[]} accumulatedNodes - Accumulated nodes to append to
       * @param {Object} parent - Parent node of children to append
       */
      function appendChildren(accumulatedNodes, parent) {
        var children = parent.children.map(attachParent);
        Array.prototype.push.apply(accumulatedNodes, children);
        return accumulatedNodes;

        /**
         * Attach the parent of each node for future use.
         */
        function attachParent(child) {
          child.parent = parent;
          child.group = parent.alias;
          return child;
        }
      }
    };

    /**
     * Get a selectable node by its name.
     *
     * @return {Object} - Node, if found
     */
    TreeLevel.prototype.getNodeByName = function (name) {
      return Utils.find(this.selectableNodes, function (node) {
        return node.name === name;
      });
    };

    /**
     * Get the currently selected value. In our trees, that means the selected
     * node's `name` field.
     *
     * @return {String} - Value of currently selected node
     */
    TreeLevel.prototype.getSelectedValue = function () {
      if (this.selectedNode) return this.selectedNode.name;
    };

    TreeLevel.prototype.hasOneSelectableNode = function () {
      return this.selectableNodes.length === 1;
    };

    /**
     * See if this is the first level in its tree. The root node does not count
     * as a level, so its children are considered to be the first level.
     *
     * @return {Boolean}
     */
    TreeLevel.prototype.isFirstLevel = function () {
      return !this.parentLevel;
    };

    /**
     * See if the currently selected node's parent node is selected in the level
     * above.
     *
     * @return {Boolean}
     */
    TreeLevel.prototype.isParentSelected = function () {
      return Boolean(
        this.selectedNode &&
          this.parentLevel &&
          nodesAreEqual(this.selectedNode.parent, this.parentLevel.selectedNode)
      );
    };

    /**
     * @return {Boolean} - True iff this level should be hidden from the user.
     *     In the back end, this is determine on the node, so evaluate the
     *     first of its selectable nodes.
     */
    TreeLevel.prototype.isHidden = function () {
      return this.selectableNodes.length && this.selectableNodes[0].is_hidden;
    };

    /**
     * See if this level is the last in its tree. Since
     *     our trees are guaranteed to have the same depth at every branch, just
     *     check to see if our first selectable node has any children.
     *
     * @return {Boolean}
     */
    TreeLevel.prototype.isLastLevel = function () {
      return angular.isEmpty(this.selectableNodes[0].children);
    };

    /**
     * See if the currently selected node is valid based on the current state of
     * the level. It is valid iff:
     * - There is no selected node
     *       OR
     * - The parent level has a selected node
     * - This level's selected node is among its selectable nodes
     *
     * @return {Boolean} - True iff the currently selected node is valid
     */
    TreeLevel.prototype.isSelectedNodeValid = function () {
      var hasSelected = this.selectedNode;
      var selectedIsSelectable =
        this.selectedNode && this.getNodeByName(this.selectedNode.name);
      return Boolean(
        !hasSelected || (this.isParentSelected() && selectedIsSelectable)
      );
    };

    // =================
    // Setters
    // =================

    /**
     * Clear the currently selected node.
     */
    TreeLevel.prototype.clearSelectedNode = function () {
      this.selectNode(null);
    };

    /**
     * Select a node.
     *
     * @param {Object} node - Node to select
     * @param {Object} [options] - Additional options
     * @param {Boolean} [options.dontUpdateParent] - Skip selecting the parent
     *     node of the new node. This helps with avoiding infinite logic of
     *     selecting the parent, then the child, then the parent, etc.
     */
    TreeLevel.prototype.selectNode = function (node, options) {
      options = options || {};

      if (nodesAreEqual(node, this.selectedNode)) return;

      if (!options.dontUpdateParent && node && node.parent) {
        this.parentLevel.selectNode(node.parent);
      }

      this.selectedNode = node;

      this.updateDescendantLevels();
    };

    /**
     * Select a node by its name.
     */
    TreeLevel.prototype.selectNodeByName = function (name) {
      var node = this.getNodeByName(name);
      this.selectNode(node);
    };

    /**
     * Set the selectable nodes.
     */
    TreeLevel.prototype.setSelectableNodes = function (nodes) {
      this.selectableNodes = nodes;
      this.updateEnumOptions();
    };

    /**
     * Update the state of any levels below this one based on this level's
     * current state.
     */
    TreeLevel.prototype.updateDescendantLevels = function () {
      if (!this.childLevel) return;

      var children = this.getChildren();
      this.childLevel.setSelectableNodes(children);
      this.childLevel.updateSelectedNode();
      this.childLevel.updateDescendantLevels();
    };

    /**
     * Set the level's enum options based on the currently selected node. In other
     * words, translate the selectable nodes into a format that can be usable
     * by an input component.
     */
    TreeLevel.prototype.updateEnumOptions = function () {
      this.enumOptions = this.selectableNodes.map(getNodeOptions);

      function getNodeOptions(node) {
        return {
          model: node.name,
          value: node.alias,
          category: node.parent && node.parent.alias,
        };
      }
    };

    /**
     * Set this level's selected node based on the current state of this level
     */
    TreeLevel.prototype.updateSelectedNode = function () {
      if (this.hasOneSelectableNode()) {
        // Select the node without updating the parent to avoid unterminating
        // parent/child updating (infinite back and forth)
        this.selectNode(this.selectableNodes[0], { dontUpdateParent: true });
      } else if (!this.isFirstLevel() && !this.isSelectedNodeValid()) {
        this.clearSelectedNode();
      }
    };

    /**
     * Given two nodes, determine equality based on their value (stored as the
     * `name` field).
     *
     * @return {Boolean}
     */
    function nodesAreEqual(node1, node2) {
      if (!node1 && !node2) return true;
      if (!node1 || !node2) return false;
      return node1.name === node2.name;
    }

    var factory = {
      create: create,
    };

    return factory;
  }
})();
