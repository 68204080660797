(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.map
   */
  angular
    .module("akitabox.ui.directives.map", [
      "uiGmapgoogle-maps",
      "akitabox.core.services.building",
      "akitabox.core.services.env",
      "akitabox.core.services.flag",
      "akitabox.core.toast",
    ])
    .directive("abxMap", AbxMapDirective);

  /**
   * @ngdoc directive
   * @name AbxMapDirective
   * @module akitabox.ui.directives.map
   * @restrict E
   *
   * @param {Object[]} [models]   Models to display, defaults to all buildings
   * @param {String}   [idKey]    Unique model key
   * @param {String}   [nameKey]  Model key that is the name (title)
   * @param {Object}   [selected] Selected model
   * @param {Function} [onClick]  Handle marker click
   * @param {Object}   [options]  Map options, see https://developers.google.com/maps/documentation/javascript/reference#MapOptions
   * @param {Function} [fit]      Whether to fit map to markers, defaults to false
   *
   * @description
   * `abx-map` represents a Google map element that displays the locations of
   * models and info windows (on hover) containing model name
   *
   */
  function AbxMapDirective() {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/map/map.html",
      controller: MapController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        models: "<?abxModels",
        idKey: "<?abxIdKey",
        nameKey: "<?abxNameKey",
        selected: "<?abxSelected",
        onClick: "&?abxOnClick",
        options: "<?abxOptions",
        fit: "&?abxFit",
      },
    };
  }

  /* @ngInject */
  function MapController(
    // Angular
    $scope,
    $timeout,
    $attrs,
    // Third-party
    uiGmapGoogleMapApi,
    // Services
    BuildingService,
    EnvService,
    FeatureFlagService,
    ToastService
  ) {
    var self = this;

    // Zoom levels
    var DEFAULT_ZOOM_LEVEL = 4;
    var HIGHLIGHT_ZOOM_LEVEL = 16;
    // Default options
    var DEFAULT_MAP_OPTIONS = {
      fullscreenControl: false,
      streetViewControl: false,
      clickableIcons: false,
    };
    // Center on United States
    var DEFAULT_MAP_CENTER = { latitude: 37.09024, longitude: -95.712891 };
    // Pin Icons
    var UNSELECTED_PIN_ICON = EnvService.getBaseUrl(
      "/img/pin_icons/building_pin-unselected.svg"
    );
    var SELECTED_PIN_ICON = EnvService.getBaseUrl(
      "/img/pin_icons/building_pin-selected.svg"
    );
    // Info window
    var DEFAULT_INFO_WINDOW_OPTIONS = {
      title: "Not Available",
      show: false,
      options: {
        disableAutoPan: true,
        pixelOffset: null,
        boxClass: "abx-info-window",
        closeBoxURL: EnvService.getBaseUrl("/img/transparent-pixel.png"),
        closeBoxMargin: "-1000px -1000px",
        maxWidth: 0,
        zIndex: null,
        isHidden: false,
        pane: "floatPane",
        enableEventPropagation: false,
      },
    };

    // Private attributes
    var infoWindowOffset;

    // Attributes
    self.render = false;
    self.idKey = angular.isEmpty(self.idKey) ? "_id" : self.idKey;
    self.nameKey = angular.isEmpty(self.nameKey) ? "name" : self.nameKey;
    self.map = {
      center: DEFAULT_MAP_CENTER,
      zoom: DEFAULT_ZOOM_LEVEL,
      options: angular.extend({}, DEFAULT_MAP_OPTIONS, self.options),
    };
    self.fitting = fitting;
    self.events = {
      click: onMarkerClick,
      mouseover: onMouseOver,
      mouseout: onMouseOut,
    };
    self.hoverInfoWindow = null;
    self.selectedInfoWindow = null;

    init();

    function init() {
      setPinIcons();

      $scope.$watch(
        "vm.selected",
        function (newModel, oldModel) {
          if (!newModel) return;
          self.selected = newModel;
          var same = compareModels(newModel, oldModel);
          if (same) {
            self.selected = findModel(newModel[self.idKey]);
          } else if (oldModel) {
            var oldModelFromModels = findModel(oldModel[self.idKey]);
            oldModelFromModels.icon_url = UNSELECTED_PIN_ICON;
          }
          // Wait to select model
          // Render will select model
          if (self.render) {
            selectModel(self.selected);
          }
        },
        true // need to watch location properties
      );

      $scope.$watchCollection("vm.models", function (models) {
        if (models && models.length) {
          uiGmapGoogleMapApi.then(function (maps) {
            setPinIcons();
            $timeout(function () {
              initMapObjects(maps);
              render();
            });
          });
        } else {
          self.map = {
            center: DEFAULT_MAP_CENTER,
            zoom: DEFAULT_ZOOM_LEVEL,
            options: angular.extend({}, DEFAULT_MAP_OPTIONS, self.options),
          };
          render();
        }
      });

      $scope.$on("map:panToBuilding", panToModel);
      $scope.$on("map:reloadBuilding", reloadBuilding);
    }

    function fitting() {
      if (!angular.isDefined($attrs.abxFit)) {
        return false;
      }
      return self.models.length === 1 ? false : self.fit();
    }

    /**
     * Compare two models via idKey
     *
     * @param {Object} a Model
     * @param {Object} b Another model
     */
    function compareModels(a, b) {
      if (a && b) {
        return a[self.idKey] === b[self.idKey];
      }
      return a === b;
    }

    function initMapObjects(maps) {
      infoWindowOffset = new maps.Size(20, -30);
      self.hoverInfoWindow = createInfoWindow();
      if (self.models.length === 1) {
        panToModel(null, self.models[0]);
      }
    }

    function onMarkerClick(marker, eventName, model) {
      if (self.selected) {
        self.selected.icon_url = UNSELECTED_PIN_ICON;
      }
      if (self.onClick) {
        self.onClick({
          $event: {
            marker: marker,
            model: model,
          },
        });
      }
    }

    function selectModel(model) {
      if (model && model.coordinates) {
        model.icon_url = SELECTED_PIN_ICON;
        showSelectedInfoWindow(model);
      }
      if (self.models && self.models.length === 1) {
        panToModel(null, model);
      }
    }

    function panToModel(event, model, zoom) {
      if (typeof model === "string") {
        model = findModel(model);
      }
      if (model && model.coordinates) {
        self.map.center = angular.copy(model.coordinates);
        self.map.zoom = zoom ? zoom : HIGHLIGHT_ZOOM_LEVEL;
      }
    }

    function reloadBuilding(event, buildingId) {
      var building = findModel(buildingId);
      if (building) {
        BuildingService.getById(building.cre_account, building._id)
          .then(function (data) {
            building = data;
          })
          .catch(ToastService.showError);
      }
    }

    function findModel(id) {
      if (!self.models) return null;

      for (var i = 0; i < self.models.length; ++i) {
        var model = self.models[i];
        if (model[self.idKey] === id) {
          return model;
        }
      }
      return null;
    }

    function setPinIcons() {
      // Set all model icons to unselected
      for (var i = 0; i < self.models.length; ++i) {
        self.models[i].icon_url = UNSELECTED_PIN_ICON;
      }
    }

    function createInfoWindow(config) {
      var infoWindow = angular.extend({}, DEFAULT_INFO_WINDOW_OPTIONS, config);
      if (!infoWindow.options) {
        infoWindow.options = {};
      }
      infoWindow.options.pixelOffset = infoWindowOffset;
      return infoWindow;
    }

    function onMouseOver(marker, eventName, model) {
      // only show one info window, preferring selected
      if (!self.selected || self.selected[self.idKey] !== model[self.idKey]) {
        showHoverInfoWindow(model);
      }
    }

    function onMouseOut() {
      self.hoverInfoWindow.show = false;
    }

    function showHoverInfoWindow(model) {
      showInfoWindow(model, false);
    }

    function showSelectedInfoWindow(model) {
      showInfoWindow(model, true);
    }

    function showInfoWindow(model, selected) {
      var infoWindow;
      if (selected) {
        if (!self.selectedInfoWindow) {
          self.selectedInfoWindow = createInfoWindow();
        }
        infoWindow = self.selectedInfoWindow;
      } else {
        if (!self.hoverInfoWindow) {
          self.hoverInfoWindow = createInfoWindow();
        }
        infoWindow = self.hoverInfoWindow;
      }
      infoWindow.coordinates = model.coordinates;
      infoWindow.title = model[self.nameKey];
      infoWindow.show = true;
    }

    function render() {
      self.render = false;
      $timeout(function () {
        self.render = true;
      }).then(function () {
        if (self.selected) {
          selectModel(self.selected);
        }
      });
    }
  }
})();
