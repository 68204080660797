(function () {
  angular
    .module("akitabox.core.services.customerAccount", [
      "akitabox.core.services.organization",
      "akitabox.core.services.http",
    ])
    .factory("CustomerAccountService", CustomerAccountService);

  /* @ngInject */
  function CustomerAccountService($q, OrganizationService, HttpService) {
    return {
      getActiveTheme,
    };

    /**
     * @typedef {Object} AbxTheme
     * @property {string} primary - The primary color of the theme
     * @property {string} on_primary - The color of text on the primary color
     * @property {string} favicon_name = The name of the favicon
     * @property {string} favicon_url = The url of the favicon
     * @property {string} header_logo_name = The name of the logo designated for the heaer
     * @property {string} header_logo_url = The url of the header logo
     * @property {string} primary_logo_name = The name of the primary logo
     * @property {string} primary_logo_url = The url of the primary logo
     */

    /**
     *
     * @returns {Promise<AbxTheme | null>} Promise that resolves with the active theme
     */
    function getActiveTheme() {
      return $q((resolve, reject) => {
        const org = OrganizationService.getCurrent();
        if (org) {
          resolve(org);
        } else {
          OrganizationService.init().then(() => {
            resolve(OrganizationService.getCurrent());
          });
        }
      }).then((organization) => {
        if (organization.customer_account) {
          return _getActiveTheme(organization.customer_account);
        } else {
          return null;
        }
      });
    }

    function _getActiveTheme(customer_account) {
      return HttpService.get(
        `/customer_accounts/${customer_account}/active_theme`
      )
        .then((response) => response.data)
        .catch((err) => {
          return $q.resolve(null);
        });
    }
  }
})();
