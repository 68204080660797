(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.create")
    .factory("CreateWorkOrderDialog", CreateWorkOrderDialog);

  /* @ngInject */
  function CreateWorkOrderDialog($log, $q, $mdDialog, ShadowService, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/work-order/create/create-work-order.dialog.html",
      controller: "CreateWorkOrderDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.building` is required
     *
     * It is unnecessary to pass in a location AND its parent location. For example, if you are passing in an asset for
     * the maintenance schedule to be associated with, you don't need to provide the room or the floor, as those will
     * be parsed intelligently by the dialog. The same applies for if a service request is passed in -- no additional
     * locals need to be passed in (other than building). If multiple locations are provided, but they do not agree
     * with each other (e.g. the provided asset is not part of the provided room), the more specific location will take
     * precedence.
     *
     * @param {Object}     options                  Dialog options
     * @param {Object}     options.locals           Dialog local variables
     * @param {Object}     options.locals.building  Associated building object
     * @param {Object[]}   options.locals.assets    List of asset objects
     * @param {Object[]}   options.locals.rooms     List of room objects
     * @param {Object[]}   options.locals.floors    List of floor objects
     * @param {Object[]}   options.locals.request   Associated service request object
     * @param {Object}     [ga]                     Google Analytics Event options
     * @param {boolean}    [ga.silent]              True if you don't want to send a GA Event.
     * @param {string}     [ga.category]            Optional, overrides default of 'workorder'.
     * @param {string}     [ga.action]              Optional, overrides default of 'create-workorder'
     * @param {string}     [ga.label]               Optional, won't send unless provided
     * @param {integer}    [ga.value]               Optional, won't send unless provided
     *
     * @return {Promise<Object|Error}   Promise that resolves with the newly created building
     */
    function show(options, ga) {
      ga = ga || {}; // Default it to an empty object if not passed in.
      // Send an event if the caller provided us with GA event information.
      if (!ga.silent) {
        // Allow callee to override the defaults for category and action.
        var category = ga.category || "workorder";
        var action = ga.action || "create-workorder";

        ShadowService.sendEvent(category, action, ga.label, ga.value);
      }

      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
