(function () {
  angular.module("akitabox.desktop.schedule.organizationList", [
    "akitabox.core.constants",
    "akitabox.core.services.building",
    "akitabox.core.services.filter",
    "akitabox.core.services.schedule",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.export",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
