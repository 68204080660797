(function () {
  angular
    .module("akitabox.ui.directives.importFileSelect")
    .controller("AbxImportFileSelectController", AbxImportFileSelectController);

  /**
   * @ngdoc controller
   * @module akitabox.ui.directives.importFileSelect
   * @name AbxImportFileSelectController
   *
   * @description
   * Controller for the `<abx-import-file-select>` directive
   *
   * @ngInject
   */
  function AbxImportFileSelectController($log, FileService) {
    var self = this;

    // Attributes
    self.file = self.file || null;
    self.type = self.type || null;
    self.options = self.options || [];
    self.filePlaceholder = self.filePlaceholder || "Choose a file";
    self.typePlaceholder = self.typePlaceholder || "Choose an import type";

    // Acceptable/supported file types
    self.accept = self.accept || "";
    self.supported = self.supported || [];
    self.orderBy = self.orderBy || "name";

    // Additional options
    self.inputId = angular.isDefined(self.inputId)
      ? self.inputId
      : "abx-upload-file";
    self.hideClearBtn = false;
    self.autoClear = false;

    // Functions
    self.clear = clear;
    self.onFileSelected = onFileSelected;
    self.onTypeSelected = onTypeSelected;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Clear both the file and type
     */
    function clear() {
      if (angular.isFunction(self.onClear)) {
        self.onClear();
      }
      reset();
    }

    /**
     * Validate file and inform parent of change
     *
     * @param {Array} files     All files selected from browser
     *                          We only expect one file here
     */
    function onFileSelected(files) {
      if (!files.length) return;
      var file = files[0];
      if (!file) return;

      // Validate file
      var err = null;

      if (!isValidFileType(file)) {
        err = "File type is not supported";
      } else if (file.size > FileService.MAX_FILE_SIZE) {
        err = "File is too big (>50mb)";
      }

      if (err) {
        $log.error("abx-import-file-select: " + err);
        self.onFileInvalid({ err: err });
      } else {
        self.file = file;
        self.onFileChange({ file: self.file });
        if (self.autoClear && self.file && self.type) {
          reset();
        }
      }
    }

    /**
     * Check to see if a file is a valid file type
     * @param {Object}  file - a file to check for a valid type
     * @return {boolean}
     */
    function isValidFileType(file) {
      var fileTypeIsValid =
        file.type &&
        self.supported.length &&
        self.supported.indexOf(file.type) !== -1;
      // this happens when the Windows Registry fails to map the extension to the mime type
      var fileTypeIsWhiteListed = !file.type && extensionIsSupported(file);
      if (fileTypeIsValid || fileTypeIsWhiteListed) {
        return true;
      }
      return false;
    }

    /**
     * Check to see if a file extension is whitelisted
     * @param {Object}  file - a file to check for a valid extension
     * @return {boolean}
     */
    function extensionIsSupported(file) {
      var extension = file.name.split(".").pop();
      switch (extension) {
        case "csv":
          return (
            self.supported.length && self.supported.indexOf("text/csv") !== -1
          );
        case "dwfx":
          return self.supported.length && self.supported.indexOf("dwfx") !== -1;
        default:
          return false;
      }
    }

    /**
     * Inform parent of type change
     *
     * @param {Object} type     Selected type
     */
    function onTypeSelected(type) {
      self.type = type;
      self.onTypeChange({ type: self.type });
      if (self.autoClear && self.file && self.type) {
        reset();
      }
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Clear out both inputs
     */
    function reset() {
      self.file = null;
      self.type = null;
    }
  }
})();
