(function () {
  angular
    .module("akitabox.ui.dialogs.displayText")
    .controller("DisplayTextDialogController", DisplayTextDialogController);

  /* @ngInject */
  function DisplayTextDialogController(
    // Angular
    // Material
    $mdDialog
  ) {
    var self = this;
    // Attributes
    // Functions
    self.cancel = $mdDialog.cancel;

    // ------------------------
    //   Public Functions
    // ------------------------
  }
})();
