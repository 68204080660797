/* globals $ */
(function () {
  angular
    .module("akitabox.desktop.directives.list")
    .directive("abxList", AbxList);

  /* ngInject */
  function AbxList($mdDialog, $q, $timeout, ToastService, Utils) {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/list.html",
      transclude: {
        "list-header": "?abxListHeader",
      },
      controller: AbxListController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        onSelect: "&?abxOnSelect",
        preSelectAll: "&?abxPreSelectAll",
        /**
         * @typedef {Object} ListAction
         * @prop {Object} classes - holds all the classes we want to decorate the actions with
         * @prop {String} classes.icon - additional classes to decorcate the icon
         * @prop {String} text - text for the button
         * @prop {String} icon - name of the material icon to use
         * @prop {() => ListAction[] } abxActions
         */
        getActions: "&?abxActions",
        fetch: "&?abxFetch",
        fetchAll: "&?abxFetchAll",
        fetchCountAll: "&?abxFetchCountAll",
        skip: "=?abxFetchSkip",
        limit: "=?abxFetchLimit",
        clickable: "=?abxClickable",
      },
    };

    function postLink($scope, $elem, attrs, vm) {
      vm.attachClickEvent = attachClickEvent;
      vm.hasToolbar = angular.isUndefined(attrs.abxNoToolbar);
      vm.selectable = angular.isUndefined(attrs.abxNoSelect);

      const defaultFetchAllLimit = 100;
      const defaultFetchLimit = vm.limit;
      var $list = $elem.find("md-content");
      var MAGIC_SCROLL_VALUE = 100;

      vm.list = $list;
      vm.actionsDisabled = false;

      // If they did not transclude in a header, we need to remove the place holder from the DOM
      if (!$elem.find("abx-list-header").length) {
        var $toolbars = $elem.children();

        for (var i = $toolbars.length - 1; i >= 0; i--) {
          if ($toolbars[i].tagName === "MD-TOOLBAR") {
            Utils.removeElement(angular.element($toolbars[i]));
          }
        }
      }

      $list.on("scroll", angular.debounce(onScroll, 300));
      $scope.$on("list:refreshClickEvents", function () {
        if (vm.clickable) {
          attachClickEvent($list);
        }
      });
      $scope.$on("list:updateSelectedIndices", function (event, numItemsAdded) {
        updateSelectedIndices(numItemsAdded);
      });
      $scope.$on("list:setSelectedIndices", function (event, indices) {
        setSelectedIndices(indices);
      });
      $scope.$on("list:refreshListActions", showActions);

      if (angular.isDefined(attrs.abxFetch)) {
        $scope.$on("list:refresh", function () {
          vm.selectedItems = [];
          vm.showActions = false;
          vm.length = 0;
          vm.skip = 0;
          vm.moreToLoad = angular.isFunction(vm.fetch);
          fetchUntilListIsFilled($list).then(function () {
            if (vm.clickable) attachClickEvent($list);
          });
        });
        fetchUntilListIsFilled($list).then(function () {
          if (vm.clickable) attachClickEvent($list);
        });
      }

      function updateSelectedIndices(numItemsAdded) {
        for (var i = 0; i < vm.selectedItems.length; ++i) {
          vm.selectedItems[i] += numItemsAdded;
        }
      }

      function setSelectedIndices(indices) {
        vm.selectedItems = angular.copy(indices);
        if (vm.selectedItems.length) {
          showActions();
        } else {
          hideActions();
        }
        styleSelectedItems();
      }

      function styleSelectedItems() {
        var $items = $list.find("abx-list-item");
        for (var i = 0; i < $items.length; ++i) {
          if (vm.selectedItems.indexOf(i) > -1) {
            // Item should be selected
            angular.element($items[i]).addClass("selected");
          } else {
            // Item should not be selected
            angular.element($items[i]).removeClass("selected");
          }
        }
      }

      function onScroll(e) {
        // check if we want to fetch more
        var $e = e.currentTarget;

        if (
          $e.scrollHeight - $e.scrollTop <=
            $e.clientHeight + MAGIC_SCROLL_VALUE &&
          !vm.fetching &&
          vm.moreToLoad
        ) {
          // Make sure we aren't already fetching and new the bottom of the content before we
          // fetch for more items
          fetchItems().then(function () {
            if (vm.clickable) attachClickEvent($elem);
          });
        } else if (vm.clickable) {
          // Just change the event attachments, don't fetch unless they've scrolled down far enough
          attachClickEvent($elem);
        }
      }

      function calculateListHeight($list) {
        var height = 0;
        var $listItems = $list.find("abx-list-item");

        if ($listItems && $listItems.length) {
          for (var i = 0; i < $listItems.length; i++) {
            var $listItem = angular.element($listItems[i]);

            height += parseInt(
              window.getComputedStyle($listItem[0]).height.slice(0, -2),
              10
            );
          }
        }

        return height;
      }

      function attachClickEvent($list) {
        var offsets = $list[0].getBoundingClientRect();
        var listTopOffset = offsets.top || 0;
        listTopOffset -= MAGIC_SCROLL_VALUE;

        var listBottomOffset = offsets.bottom || 0;
        listBottomOffset += MAGIC_SCROLL_VALUE;

        var $items = $list.find("abx-list-item");

        for (var i = 0; i < $items.length; i++) {
          var $item = angular.element($items[i]);
          var bounds = $item[0].getBoundingClientRect();
          var itemTopOffset = bounds.top;
          var itemBottomOffset = bounds.bottom;

          if (itemTopOffset > listBottomOffset) {
            // We are iterating through items that are complete below the list view port, stop!!!
            break;
          } else if (itemBottomOffset < listTopOffset) {
            // If the item is completely above or below the viewable portion of the list
            // don't attach any events to it
            continue;
          }

          // We need a new scope to keep reference to the correct i
          (function (index) {
            // Always clear the click event first
            $item.off("click");
            $item.on("click", function (e) {
              // Disable click event for links (or elements wrapped with links)
              if ($(e.target).is("a") || $(e.target).parents("a").length)
                return;

              var $e = angular.element(e.currentTarget);
              var ctrlClick = e.ctrlKey || e.metaKey || false;
              var shiftClick = e.shiftKey || false;

              // Anything they click on a single item, we must turn off the selectAll flag
              vm.selectAll = false;

              if (!ctrlClick && !shiftClick) {
                var tempArray = [];
                // User made a normal click without shift or ctrl/cmd,
                // deselect all current items
                for (var l = vm.selectedItems.length - 1; l >= 0; l--) {
                  if (vm.selectedItems[l] === index) {
                    // We don't want to clean up the current item they selected, because our code will
                    // do it eventually anyways further down
                    tempArray.push(vm.selectedItems[l]);
                  } else {
                    angular
                      .element($items[vm.selectedItems[l]])
                      .removeClass("selected");
                  }
                }

                vm.selectedItems = tempArray;
              } else if (shiftClick) {
                // User attempted a shift + click
                // Add all items between prevSelectedItem the selectedItem
                var start;
                var finish;

                // We wanna make sure we're always shift select by traversing down the list, even
                // if they selected upwards
                if (!vm.prevSelectedItem) {
                  // They shift selected on a blank list, just default they're previous selected item to
                  // the first item in the list

                  // This is the only case where we want to include the first item, because we don't have
                  // a previously selected item
                  start = 0;
                  finish = index;
                } else if (vm.prevSelectedItem > index) {
                  // They shift selected in an upwards motion, so make sure we start at the
                  // top of list (which is selectedItem, the item they ended the shift select on)
                  start = index + 1;
                  finish = vm.prevSelectedItem;
                } else if (vm.prevSelectedItem < index) {
                  // This is the easy case of them shift selecting downwards, so just traverse the list
                  // normally

                  // We don't start on the previously selected item, because that's already in the
                  // selectedItems list
                  start = vm.prevSelectedItem + 1;
                  // finish is never included, so we want to make sure we set that item as the end of the
                  // loop, because it will be added by itself near the end of this code block
                  finish = index;
                }

                // This loop will grab all items between the two items at either end of the selection
                // avoiding any items that are already selected
                for (var k = start; k < finish; k++) {
                  var $innerItem = angular.element($items[k]);
                  var innerItemAlreadySelected =
                    $innerItem.hasClass("selected");

                  if (innerItemAlreadySelected) {
                    // This item is already in our selectedItems list, go to the next item
                    continue;
                  }

                  vm.selectedItems.push(k);
                  $innerItem.addClass("selected"); // Mark item as selected
                }
              }

              // Needs to be defined here because the code above can/will modify the selected state of the
              // item
              var hasSelected = $e.hasClass("selected");

              if (!shiftClick && hasSelected) {
                // User ctrl clicked on an already selected item, Remove this item from the list
                var removeIndex = vm.selectedItems.indexOf(index);

                if (removeIndex !== -1) {
                  vm.selectedItems.splice(removeIndex, 1);
                  $e.removeClass("selected");
                }

                if (vm.selectedItems.length === 0) {
                  // Make sure if that item was the last item on the list, we set prevSelectedItem to null
                  // indicating that the list has no items selected currently
                  vm.prevSelectedItem = null;
                }
              } else if (!hasSelected) {
                // Normal workflow of making an unselected item ==> selected
                vm.selectedItems.push(index);
                vm.prevSelectedItem = index;
                $e.addClass("selected");
              }

              // Make sure we show/hide the batch actions allowed to take with these items
              if (vm.selectedItems.length) {
                showActions();
              } else {
                hideActions();
              }

              // Make sure we run the click function if they supplied one
              if (angular.isFunction(vm.onSelect)) {
                vm.onSelect({
                  index: index,
                  deselected: hasSelected || vm.selectedItems.length > 1,
                });
              }
            });
          })(i);
        }
      }

      function showActions() {
        if (!vm.selectable) return;

        var actions = [];

        if (angular.isFunction(vm.getActions)) {
          actions = vm.getActions({ items: vm.selectedItems });
        }

        if (angular.isFunction(vm.fetchAll)) {
          // Only show this action if they passed in a fetch all function as well
          actions.unshift({
            text: "Select All",
            icon: "format_list_bulleted",
            onClick: selectAll,
          });
        }

        vm.actions = actions;

        if (actions.length) {
          $scope.$evalAsync(function () {
            vm.showActions = true;
          });
        }
      }

      function hideActions() {
        $scope.$evalAsync(function () {
          vm.showActions = false;
        });
      }

      function fetchUntilListIsFilled($list) {
        return fetchItems().then(function (length) {
          vm.length += length;
          return $timeout(function () {
            // We make sure before we go pull more data that there is more data to even pull
            if (length === vm.limit) {
              var listHeight = calculateListHeight($list);
              var maxListHeight = parseInt(
                window.getComputedStyle($list[0]).height.slice(0, -2),
                10
              );

              if (listHeight <= maxListHeight) {
                return fetchUntilListIsFilled($list);
              }
            }

            return $q.resolve(length); // in case we want to chain more promises
          });
        });
      }

      function fetchItems() {
        if (!vm.moreToLoad) {
          return $q.resolve(0);
        }

        if (vm.fetching) return $q.resolve();

        vm.fetching = true;

        return vm
          .fetch({ skip: vm.skip, limit: vm.limit })
          .then(function (length) {
            if (length >= vm.limit) {
              // Whenever the length of a fetched collection is the limit, we know we should update skip
              // because there should be more data to pull
              vm.skip += vm.limit;
            } else {
              // There are no more items to be pulled
              vm.moreToLoad = false;
            }

            return length;
          })
          .catch(function (err) {
            ToastService.showError(err, true);
          })
          .finally(function () {
            vm.fetching = false;
          });
      }

      function selectAllListItems() {
        return $timeout(function () {
          var $items = $elem.find("abx-list-item");

          vm.selectedItems = [];

          for (var i = 0; i < $items.length; i++) {
            if (vm.selectAll) {
              vm.selectedItems.push(i);
              angular.element($items[i]).addClass("selected");
            } else {
              angular.element($items[i]).removeClass("selected");
            }
          }

          if (vm.selectAll) {
            $mdDialog.hide();
            showActions();
          } else {
            hideActions();
          }

          vm.limit = defaultFetchLimit;

          if (vm.clickable) attachClickEvent($elem);
        });
      }

      function enableActions() {
        $timeout(function () {
          vm.actionsDisabled = false;
        }, 500);
      }

      async function selectAll() {
        const countAllDoc = angular.isFunction(vm.fetchCountAll)
          ? await vm.fetchCountAll()
          : 0;
        const getProgress = (number) =>
          Math.floor((number / countAllDoc) * 100);
        let countItemsLoaded = $elem.find("abx-list-item").length || 0;

        vm.limit = defaultFetchAllLimit;
        vm.progress = getProgress(countItemsLoaded);
        // fetch all the items, then mark them as selected
        vm.selectAll = !vm.selectAll;

        if (vm.selectAll) {
          if (angular.isFunction(vm.preSelectAll)) {
            vm.preSelectAll();
          }
          vm.actionsDisabled = true;
          $mdDialog.show({
            templateUrl:
              "app/desktop/directives/list/templates/select-all-dialog.html",
            parent: $elem.find("md-content"),
            escapeToClose: false,
            clickOutsideToClose: false,
            locals: {
              progress: vm.progress,
            },
            controllerAs: "dialog",
            controller: [
              "progress",
              function (progress) {
                const self = this;
                self.progress = progress;

                const doFetch = () => {
                  let deferred = $q.defer();
                  fetchItems()
                    .then((dataCount) => {
                      countItemsLoaded += dataCount;
                      self.progress = getProgress(countItemsLoaded);
                      if (dataCount === vm.limit) {
                        // Fetch more
                        deferred.resolve(doFetch());
                      } else {
                        // We have everything
                        deferred.resolve();
                      }
                    })
                    .catch(function (err) {
                      deferred.reject(err);
                    });
                  return deferred.promise;
                };

                doFetch()
                  .then(() => {
                    return selectAllListItems();
                  })
                  .finally(() => {
                    enableActions();
                  });
              },
            ],
          });
        } else {
          selectAllListItems().then(() => {
            enableActions();
          });
        }
      }
    }
  }

  /* @ngInject */
  function AbxListController(ToastService, $timeout, $rootScope) {
    var self = this;

    self.length = 0;
    self.skip = self.skip || 0;
    self.limit = self.limit || 50;
    self.selectAll = false;
    self.fetching = false;
    // there are no more items to load if they don't pass in a function to fetch those items
    self.moreToLoad = angular.isFunction(self.fetch);
    self.selectedItems = [];
    self.prevSelectedItem = null;
    self.showActions = false;
    self.actions = null;
    self.clickable = angular.isDefined(self.clickable) ? self.clickable : true;

    self.executeAction = executeAction;

    function executeAction(index) {
      var action = self.actions[index];
      var initialListLength = self.list.find("abx-list-item").length;

      if (self.actionsDisabled) return;

      if (index === 0 && action) {
        // first action is always select all, which takes no params, so we make sure it is called like so.
        action.onClick();
      } else if (action) {
        action
          .onClick(self.selectedItems)
          .then(function () {
            // timeout is required to allow the DOM to update the list elements
            $timeout(function () {
              self.attachClickEvent(self.list);

              // Crudely hide list actions if the list shrank. Includes cases in which only some items'
              // removal was successful. Not necessarily desired behavior, but protects against cases where
              // selectedItems indices remain actionable after their removal
              var newListLength = self.list.find("abx-list-item").length;
              if (newListLength < initialListLength) {
                self.selectedItems = [];
                self.showActions = false;
              }
            });
          })
          .catch(function (err) {
            // since we will end up here when we are cancel the dialogs, we have to check to see if there is
            // actually an error.
            if (err) {
              ToastService.showError(err);
            }
          });
      }
      // closes the sidebar in ListView
      // when any action gets executed
      $rootScope.$broadcast("close_sidebar");
    }
  }
})();
