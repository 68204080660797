(function () {
  angular.module("akitabox.ui.dialogs.changeDecommissionDate", [
    "ngMaterial",
    "akitabox.core.lib.moment",
    "akitabox.core.services.asset",
    "akitabox.core.services.identity",
    "akitabox.core.services.room",
    "akitabox.ui.components.input",
  ]);
})();
