(function () {
  angular
    .module("akitabox.desktop.directives.breadcrumbHeader", [
      "akitabox.core.router",
    ])
    .directive("abxBreadcrumbHeader", AbxBreadcrumbHeader);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.breadcrumbHeader
   * @name AbxBreadcrumbHeaderDirective
   *
   * @restrict E
   *
   * @description
   * `<abx-breadcrumb-header>` provides breadcrumb navigation and action buttons on an item detail page.
   *
   * @usage
   *  <abx-breadcrumb-header abx-breadcrumbs="vm.breadcrumbs" abx-title="The Title">
   *    <abx-button ng-click="alert("wow")">A Button</abx-button>
   *  </abx-breadcrumb-header>
   *
   * @ngInject
   */
  function AbxBreadcrumbHeader() {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/breadcrumb-header/breadcrumb-header.html",
      controller: AbxBreadcrumbHeaderController,
      controllerAs: "vm",
      transclude: true,
      bindToController: true,
      scope: {
        building: "=abxBuilding",
        breadcrumbs: "=abxBreadcrumbs",
        title: "@abxTitle",
        prefix: "@?abxPrefix",
      },
    };
  }

  /**
   * @ngdoc controller
   * @module akitabox.desktop.directives.breadcrumbHeader
   * @name AbxBreadcrumbHeaderController
   *
   * @description
   * Controller for the breadcrumb header directive
   *
   * @ngInject
   */
  function AbxBreadcrumbHeaderController() {
    // Functions

    init();

    function init() {}
  }
})();
