(function () {
  /**
   * @ngdoc component
   * @name abxAsyncSelectInputComponent
   *
   * @param {Boolean} disabled      If input is disabled
   * @param {Function} onChange     Invoked when the search text changes
   * @param {Function} onSelect     Invoked when value(s) selected or cleared
   * @param {Function} onFocus      Invoked when the input is focused
   *
   * @description
   * Asynchronous (multi) select input
   */
  angular
    .module("akitabox.ui.components.input.asyncSelect", ["akitabox.core.toast"])
    .component("abxAsyncSelect", {
      bindings: {
        model: "<abxModel",
        disabled: "<abxDisabled",
        onChange: "&abxOnChange",
        onSelect: "&abxOnSelect",
        onFocus: "&abxOnFocus",
      },
      controller: AbxAsyncSelectController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/input/components/async-select/async-select.component.html",
    });

  function AbxAsyncSelectController(
    // Angular
    $q,
    $timeout,
    // Services
    ToastService
  ) {
    var self = this;

    // Private
    var MINIMUM_RENDER_TIME = 750;
    var optionsRequest;
    var availableOptions = [];

    // Attributes
    self.loading = false;
    self.options = [];
    self.searchText = null;
    self.selected = [];

    // Functions
    self.handleChange = angular.debounce(handleChange, 250);
    self.handleFocus = handleFocus;
    self.handleSelect = handleSelect;

    // =================
    // Public Functions
    // =================

    function handleChange(searchText) {
      optionsRequest = getOptions(searchText);
    }

    /**
     * Handle input focus
     */
    function handleFocus() {
      if (!optionsRequest) {
        optionsRequest = getOptions(null);
      }
    }

    function handleSelect() {
      var $event = { model: self.model, valid: true };
      self.onSelect({ $event: $event });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Fetch the async options for the current building.
     *
     * @return {Promise} - Resolves when options have been fetched and parsed
     */
    function getOptions(searchText) {
      self.loading = true;
      self.options = [];
      var $event = { model: self.model, value: searchText };
      return $q
        .resolve(self.onChange({ $event: $event }))
        .then(parseOptions)
        .then(function (options) {
          return $timeout(function () {
            self.options = options;
          }, MINIMUM_RENDER_TIME);
        })
        .catch(function (err) {
          self.options = [];
          ToastService.showError(err);
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function parseOptions(options) {
      // Available options includes selected but not visble
      availableOptions = availableOptions.filter(isSelected);
      Array.prototype.push.apply(availableOptions, options.filter(notSelected));
      return options;
    }

    function isSelected(option) {
      for (var i = 0; i < self.model.length; ++i) {
        var item = self.model[i];
        if (isEqual(item, option)) {
          return true;
        }
      }
      return false;
    }

    function notSelected(option) {
      return !isSelected(option);
    }

    function isEqual(model, value) {
      if (value.model) {
        return angular.equals(model, value.model);
      }
      return angular.equals(model, value);
    }
  }
})();
