(function () {
  /**
   * @ngdoc component
   * @name abxLocationMap
   *
   * @param {Object} floor - Floor to display in the floor card
   * @param {Function} onSelect - To be invoked when a user has selected floor.
   *     If a floor does not have a floor plan, this will not be invoked until
   *     the user assigns one (through a dialog shown by this component).
   * @param {Object} building - Building for the floor responsible for.
   *     Necessary for the dialog that will be shown to the user to assign a
   *     floor plan to a floor.
   *
   * @description
   * A component to display a single floor in a card style
   */
  angular
    .module("akitabox.ui.components.locationMap", [
      "akitabox.constants",
      "akitabox.core.services.env",
      "akitabox.core.services.http",
      "akitabox.core.services.document",
      "akitabox.core.services.organization",
      "akitabox.core.utils",
      "ui.router",
    ])
    .component("abxLocationMap", {
      bindings: {
        model: "<abxModel",
        building: "<abxBuilding",
        floor: "<abxFloor",
        room: "<?abxRoom",
        asset: "<?abxAsset",
        linkText: "@?abxLinkText",
      },
      controller: AbxLocationMapController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/location-map/location-map.component.html",
    });

  function AbxLocationMapController(
    // Angular
    $window,
    // AkitaBox
    models,
    // Services
    ChecklistService,
    DocumentService,
    EnvService,
    HttpService,
    InspectionService,
    OrganizationService,
    Utils
  ) {
    var self = this;

    // Attributes
    self.document = null;

    self.linkText = angular.isDefined(self.linkText)
      ? self.linkText
      : "View on Floor Plan";

    // Functions
    self.handleClick = handleClick;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onChanges = function (changes) {
      if (changes.model) {
        var previousModel = changes.model.previousValue;
        if (!Utils.isSameModel(previousModel, self.model)) {
          self.isInspectionWo = self.model.source === "inspection";
          if (self.isInspectionWo) {
            self.linkText = "View Inspection";
          }
          parseModel(self.model);
        }
      }
      if (changes.floor) {
        var previousFloor = changes.floor.previousValue;
        var hasChanges = !Utils.isSameModel(previousFloor, self.floor);
        if (hasChanges) {
          parseModel(self.model);
        }
        if (self.isInspectionWo) {
          var isClosed = self.model.is_completed || self.model.is_canceled;
          /** If the inspection wo is closed, linkText can stay as is
           *  if (not started) { linkText = "Start Inspection" }
           *  if (in progress || any checklist items have been answered) { linkText = "Continue Inspection"}
           *  */

          if (!isClosed) {
            var organization = OrganizationService.getCurrent();
            ChecklistService.getByWorkOrderId(
              organization._id,
              self.model._id
            ).then(function (checklists) {
              var inProgress = false;
              var completedChecklistStops = 0;
              // Iterate over the items in the checklist
              for (var i = 0; i < checklists.length; ++i) {
                var checklist = checklists[i];
                // If this checklist is in progress
                if (
                  checklist.status === models.CHECKLIST.STATUSES.IN_PROGRESS
                ) {
                  // Determine if any checklist item (question) has been answered
                  for (var j = 0; j < checklist.items.length; ++j) {
                    var item = checklist.items[j];
                    if (item.value) {
                      inProgress = true;
                      break;
                    }
                  }
                  // Stop iterating if we already know we're in progress
                  if (inProgress) {
                    break;
                  }
                } else {
                  // This checklist (stop) has been completed
                  // so the inspection is in progress
                  inProgress = true;
                  completedChecklistStops++;
                }
              }
              if (inProgress && completedChecklistStops < checklists.length) {
                self.linkText = "Continue Inspection";
              } else if (!inProgress) {
                self.linkText = "Start Inspection";
              }
            });
          }
        }
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handle clicks to the entire component and navigate to the floor plan
     */
    function handleClick() {
      var url;
      if (self.isInspectionWo) {
        url = InspectionService.getInspectionUrl(
          self.building._id,
          self.floor._id,
          self.model._id
        );
      } else {
        var route =
          "/buildings/" + self.building._id + "/levels/" + self.floor._id;

        var params = {};
        var query;

        if (self.asset) {
          params.asset = self.asset._id;
          query = HttpService.buildQueryString(params);
        } else if (self.room) {
          params.room = self.room._id;
          query = HttpService.buildQueryString(params);
        }

        url = EnvService.getPlanViewUrl(route, query);
      }

      $window.open(url, "_blank");
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function parseModel(model) {
      if (self.floor && self.building) {
        if (self.floor.document) {
          if (
            Object.prototype.hasOwnProperty.call(self.floor.document, "_id")
          ) {
            self.document = self.floor.document;
          } else {
            fetchDocument(self.building._id, self.floor.document);
          }
          // Need to null out document if new floor doesn't have one, OR if new floor doesn't exist
        } else {
          self.document = null;
        }
      } else {
        self.document = null;
      }

      if (Object.prototype.hasOwnProperty.call(model, "pinType")) {
        if (model.pinType.is_asset) {
          self.room = null;
          self.asset = model;
        } else if (model.pinType.is_room) {
          self.asset = null;
          self.room = model;
        }
      }
    }

    /**
     * Get document and attach to self.document
     *
     * @param {String} buildingID the building ID
     *
     * @param {String} id document ID
     *
     * @return {Promise}  Promise with document
     */
    function fetchDocument(buildingID, id) {
      return DocumentService.getById(buildingID, id).then(function (document) {
        self.document = document;
        return document;
      });
    }
  }
})();
