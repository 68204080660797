(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.treeInput
   */
  angular
    .module("akitabox.ui.directives.treeInput", [])
    .directive("abxTreeInput", AbxTreeInputDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.treeInput
   * @name AbxTreeInputDirective
   * @restrict E
   *
   * @description
   * `abx-tree-input` creates a dropdown menu to allow for selection of the tree options
   *
   * @usage
   * <hljs lang="html">
   *   <abx-tree-input
   *      id="system"
   *      name="system"
   *      ng-model="vm.system"
   *      abx-label="System"
   *      abx-tree="vm.tree">
   *   </abx-tree-input>
   * </hljs>
   *
   * @ngInject
   */
  function AbxTreeInputDirective($timeout) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/tree-input/tree-input.html",
      require: ["abxTreeInput", "ngModel"],
      controller: AbxTreeInputController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        label: "@abxLabel",
        tree: "=abxTree",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Attributes
      // eslint-disable-next-line no-unused-vars
      var visibleNodes = 0;

      // UI elements
      var $inputs;
      var placeholders = [];

      // Functions
      vm.setModelValue = setModelValue;
      vm.setPlaceholders = setPlaceholders;

      init();

      /**
       * Initialize the element, verify building is provided,
       * watch properties, and add event listeners
       */
      function init() {
        attrs.$set("abx-custom-input", "");
        attrs.$set("placeholder", "");

        vm.selected = vm.ngModelCtrl.$modelValue
          ? angular.copy(vm.ngModelCtrl.$modelValue)
          : [];

        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        $scope.$watch("vm.tree", function (tree) {
          if (!angular.isEmpty(tree)) {
            vm.loading = false;
            visibleNodes = vm.generateTree();
            vm.populateTree();
          }
        });

        attachPropertyWatchers();

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and create placeholders
          $inputs = $element.find("input");
          for (var i = 0; i < $inputs.length; ++i) {
            placeholders.push("Enter " + vm.nodes[i].label.toLowerCase());
          }
          setPlaceholders("Not provided");
        });
      }

      function onExternalChange(value) {
        vm.selected = value ? angular.copy(value) : [];
        vm.populateTree();
        return value;
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Readonly
        $scope.$watch(function () {
          return $element[0].attributes.readonly;
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          var p = $element[0].attributes.placeholder;
          return p ? p.value : "";
        }, setPlaceholders);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
      }

      /**
       * Set readonly
       *
       * @param {String} value    Attribute value
       */
      function setReadonly(value) {
        vm.readonly = Boolean(value);
      }

      /**
       * Set disabled, if building doesn't exist value is ignored
       * and disabled is set to true
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        vm.disabled = value;
      }

      /**
       * Set input placeholder, if building doesn't exist value is ignored
       * and the placeholder is set to indicate that building is required
       *
       * @param {String} value    Attribute value
       */
      function setPlaceholders(value) {
        if ($inputs) {
          if (angular.isEmpty(value) && !vm.readonly) {
            for (var i = 0; i < $inputs.length; ++i) {
              var input = angular.element($inputs[i]);
              var placeholder;
              if (vm.nodes[i].disabled) {
                placeholder = "Disabled until value above is selected";
              } else {
                placeholder = placeholders[i];
              }
              input.attr("placeholder", placeholder);
            }
          } else {
            $inputs.attr("placeholder", value);
          }
        }
      }
    }
  }
  /**
   * Controller for abx-tree-input
   *
   * @ngInject
   */
  function AbxTreeInputController() {
    var self = this;

    // Attributes
    self.loading = true;
    self.query = [];
    self.disabled = false;
    self.readonly = true;
    self.required = false;
    self.nodes = []; // Default tree structure
    self.options = []; // Default options by level
    self.selected = null;

    // Functions
    self.querySearch = querySearch;
    self.selectedItemChange = selectedItemChange;
    self.generateTree = generateTree;
    self.populateTree = populateTree;

    /**
     * Search for buildling trees based on query, if query is blank
     * all trees will be returned
     *
     * @param  {String} query   Search text
     *
     * @return {Array}          List of trees
     */
    function querySearch(depth, query) {
      var nodes = self.nodes[depth];
      if (angular.isEmpty(query)) return nodes.options;
      return filterQuery(query, nodes.options);
    }

    /**
     * Handle tree value selection
     *
     * @param {Integer} depth   Tree depth or index of node
     * @param {Object}  node    Selected node value
     */
    function selectedItemChange(depth, node) {
      if (node) {
        if (self.selected.length > 1) {
          // Clear child values below the selected one
          // if they don't align with the new parent
          var start = depth + 1;
          var parent = node;
          for (var i = depth + 1; i < self.selected.length; ++i) {
            var child;
            // loop through children of parent in tree to find the index at which we should clear the inputs
            // since some might still match the parent.
            for (var j = 0; j < parent.children.length; ++j) {
              if (parent.children[j].name === self.selected[i]) {
                child = parent.children[j];
                break;
              }
            }
            if (!child) break;
            start = i;
          }
          if (start < self.selected.length - 1) prune(start);
        }

        if (depth === 1 && !self.selected[0]) {
          // auto fill the root node
          self.selected[depth - 1] = getNodeValue(0, node);
        } else if (depth > 1) {
          // auto fill the missing node
          self.selected[depth - 1] = getNodeValue(
            depth - 1,
            node,
            self.nodes[depth - 2].value
          );
        }

        self.selected[depth] = node.name;
      } else {
        // Empty / cleared
        prune(depth);
      }

      self.setPlaceholders();
      self.setModelValue(self.selected);
    }

    /**
     * Filter matches based on a query string
     *
     * @param  {String} query       Query string
     * @param  {Array} matches      List of possible matches
     * @return {Array}              Filtered list of matches
     */
    function filterQuery(query, matches) {
      return matches.filter(function (match) {
        return match.alias.match(new RegExp(query, "i"));
      });
    }

    function populateTree() {
      var tree = self.nodes;
      var selectedValuesHaveChanged = false;

      // Iterate through the tree
      // Always starting at the root node
      for (var i = 0; i < tree.length; ++i) {
        var value = self.selected[i];
        var valueNode = null;
        var node = tree[i];
        var parent = null;
        var options = [];

        // Every option by default has ALL possible options for its dropdown already
        // Check if we need to filter down the options
        // This is only the case if the node before it already has a selected value
        if (i === 0) {
          options = self.tree;
        } else if (tree[i - 1] && tree[i - 1].value) {
          parent = tree[i - 1].value;
          options = parent.children;
        } else if (tree[i - 2] && tree[i - 2].value) {
          // We are trying to get options for a node that is 2 levels below a selected value
          // So we start at the node that we know has the value
          parent = tree[i - 2].value;

          for (var a = 0; a < parent.children.length; ++a) {
            options = options.concat(parent.children[a].children);
          }
        } else if (i === 1 && !value) {
          // This is a special case for when the entire tree is completely empty
          // the 2nd drop down will need to be filtered to the first dropdown's children node
          parent = {
            children: self.tree,
          };

          for (var b = 0; b < parent.children.length; ++b) {
            options = options.concat(parent.children[b].children);
          }
        }

        if (value) {
          // This node has a selected value, assign it
          for (var k = 0; k < options.length; ++k) {
            if (value === options[k].name) {
              valueNode = options[k];
              break;
            }
          }
        } else if (options.length === 1) {
          valueNode = options[0];
          self.selected[i] = valueNode.name;
          selectedValuesHaveChanged = true;
        }

        node.options = options;
        node.value = valueNode;
        node.disabled = isNodeDisabled(node);
      }

      if (selectedValuesHaveChanged) {
        self.setModelValue(self.selected);
      }
    }

    /**
     * Generate the initial tree nodes
     *
     * @return {Number}     Number of visible nodes
     */
    function generateTree() {
      var node = self.tree[0];
      var count = 0;
      var label = self.label;
      var visibleNodes = 0;

      self.nodes = []; // store default structure of dropdown
      self.options = []; // store all default options by level

      while (node) {
        var options = getNodeOptions(count);

        self.nodes.push({
          depth: count,
          disabled: true,
          label: label,
          options: options,
          parent: null,
          value: null,
        });
        self.options[count] = options; // used later when we need to show all options for a level

        if (isNodeHidden(self.nodes[count])) {
          self.nodes[count].hidden = true;
        } else {
          visibleNodes++;
        }

        if (node.child_title.length > 0) {
          count++;
          label = node.child_title;
          node = node.children[0];
        } else {
          node = null;
        }
      }

      return visibleNodes;
    }

    /**
     * Determine if a node is hidden
     *
     * @param  {Object}  node   Tree node
     *
     * @return {Boolean}        True if hidden, false if not
     */
    function isNodeHidden(node) {
      if (node.options.length > 0) {
        return node.options[0].is_hidden;
      }

      return false;
    }

    /**
     * Determine if a node is disabled
     *
     * @param  {Object}  node   Tree node
     *
     * @return {Boolean}        True if disabled, false if not
     */
    function isNodeDisabled(node) {
      if (node.depth === 0 || node.options.length > 1) {
        return false;
      }
      return true;
    }

    /**
     * Get node options
     *
     * @param  {Number} depth   Tree depth
     *
     * @return {Array}          List of options
     */
    function getNodeOptions(depth) {
      if (depth <= 0) {
        return self.tree.map(function (child) {
          child.parent = self.label;
          return child;
        });
      }

      var nodes = self.nodes[depth - 1].options;
      var options = [];

      for (var i = 0; i < nodes.length; ++i) {
        var option = nodes[i];

        options = options.concat(
          option.children.map(function (child) {
            child.parent = option.alias;
            return child;
          })
        );
      }

      return options;
    }

    /**
     * Find a node value
     *
     * @param  {Number} depth       Tree depth
     * @param  {Object} node        Tree node
     * @param  {Object} parentNode  [Option] parent node
     *
     * @return {String}             Node value
     */
    function getNodeValue(depth, node, parentNode) {
      var options = [];

      if (parentNode) {
        options = self.options[depth];
      } else {
        options = self.options[0];
      }

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (parentNode) {
          if (option.parent === parentNode.alias) {
            for (var k = 0; k < option.children.length; k++) {
              var child = option.children[k];

              if (child.name === node.name && node.parent === child.parent) {
                return option.name;
              }
            }
          }
        } else if (option.alias === node.parent) {
          return option.name;
        }
      }

      return null;
    }

    /**
     * Remove tree nodes at a depth
     *
     * @param  {Number} depth   Tree depth
     */
    function prune(depth) {
      self.selected.splice(depth, self.selected.length - depth);
    }
  }
})();
