(function () {
  angular.module("akitabox.ui.dialogs.room", [
    "akitabox.ui.dialogs.room.create",
    "akitabox.ui.dialogs.room.delete",
    "akitabox.core.services.room",
    "akitabox.core.toast",
    "ngMaterial",
    "akitabox.core.constants",
    "akitabox.ui.dialogs.bulkEdit.pinField",
  ]);
})();
