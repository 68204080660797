(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.buildingHeader
   */
  angular
    .module("akitabox.desktop.directives.buildingHeader", [
      "ui.router",
      "akitabox.core",
      "akitabox.core.services.map",
    ])
    .directive("abxBuildingHeader", AbxBuildingHeaderDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.desktop.directives.buildingHeader
   * @name AbxBuildingHeaderDirective
   * @restrict E
   *
   * @description
   * `<abx-building-header>` is a header directive that displays information about
   * a particular building and provides useful action buttons
   */
  /* @ngInject */
  function AbxBuildingHeaderDirective($window, $timeout, $mdTheming) {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/building-header/building-header.html",
      replace: true,
      controller: AbxBuildingHeaderController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        title: "=?abxTitle",
        description: "=?",
        actions: "=?",
        building: "=?",
        subtitle: "=?",
      },
    };

    /**
     * Post link function that hides title and button containers in order to accurately
     * calculate their widths. This function also verifies that all action buttons have an icon.
     */
    function postLink(scope, element, attrs, vm) {
      var titleWidth = 0;
      var btnContainerWidth = 0;
      var renderedButtons = 0;

      // UI elements
      var titleElement = element.children()[0];
      var btnContainer = element.children()[1];

      // Apply theme class to the element
      $mdTheming(element);

      // Hide UI elements
      hideTitle();
      hideBtnContainer();

      var throttledOnResize = angular.debounce(onResize, 50);

      // Watchers
      scope.$on("$destoy", cleanUp);
      scope.$on("$viewContentLoaded", onContentLoaded);
      angular.element($window).on("resize", throttledOnResize);

      // Check that all buttons have an icon
      for (var i = 0; i < vm.actions.length; ++i) {
        if (angular.isEmpty(vm.actions[i].icon)) {
          throw new Error(
            "<abx-building-header> action buttons must have icons"
          );
        }
      }

      if (vm.actions.length === 0) onActionButtonsRendered();

      /**
       * Check if full text buttons have enough space to be shown
       *
       * @return {Boolean} True if there is enough space, false if not
       */
      function shouldShowBtnText() {
        return element[0].offsetWidth - titleWidth >= btnContainerWidth;
      }

      /**
       * Increment rendered button count and check if all buttons have been rendered
       */
      function onContentLoaded() {
        renderedButtons++;
        if (renderedButtons === vm.actions.length) {
          $timeout(function () {
            onActionButtonsRendered();
            onResize();
          });
        }
      }

      /**
       * Calculate element widths, invoke resize, and show title and button containers
       */
      function onActionButtonsRendered() {
        titleWidth = titleElement.offsetWidth;
        btnContainerWidth = btnContainer.offsetWidth;
        showTitle();
        showBtnContainer();
      }

      /**
       * Check if we can display full text action buttons
       */
      function onResize() {
        scope.$apply(function () {
          vm.showBtnText = shouldShowBtnText();
        });
      }

      /**
       * Remove scope watchers
       */
      function cleanUp() {
        angular.element($window).off("resize", throttledOnResize);
      }

      /**
       * Show title element
       */
      function showTitle() {
        angular.element(titleElement).css({
          visibility: "visible",
          overflow: "hidden",
        });
      }

      /**
       * Show button container element
       */
      function showBtnContainer() {
        angular.element(btnContainer).css({ visibility: "visible" });
      }

      /**
       * Hide title element
       */
      function hideTitle() {
        angular.element(titleElement).css({
          visibility: "hidden",
          overflow: "visible",
        });
      }

      /**
       * Hide button container element
       */
      function hideBtnContainer() {
        angular.element(btnContainer).css({ visibility: "hidden" });
      }
    }
  }
})();

/**
 * @ngdoc controller
 * @module akitabox.desktop.directives.buildingHeader
 * @name AbxBuildingHeaderController
 *
 * @description
 * This controller watches for
 */
/* @ngInject */
function AbxBuildingHeaderController($state, $stateParams, MapService) {
  var self = this;

  // Attributes
  self.showBtnText = true;
  self.actions = self.actions ? self.actions : [];

  if (!self.description) self.description = getDescription();

  // Functions
  self.onActionClicked = onActionClicked;
  self.onClose = onClose;
  self.goToState = goToState;

  /**
   * Invoke the `onClick` function of the action button that was clicked
   *
   * @param {Number} index Index of the button that was clicked
   */
  function onActionClicked(index) {
    self.actions[index].onClick();
  }

  function onClose() {
    $state.go("app.dashboard");
  }

  function goToState() {
    if (!self.subtitle || !self.subtitle.state || !self.subtitle.state.name) {
      return;
    }

    $state.go(
      self.subtitle.state.name,
      self.subtitle.state.params || $stateParams
    );
  }

  function getDescription() {
    return MapService.formatDescription(self.building);
  }
}
