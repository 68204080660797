const d3 = require("d3");
(function () {
  angular.module("akitabox.core.lib.d3", []).factory("d3", D3Service);

  /* @ngIject */
  function D3Service($window) {
    if (!angular.isDefined(d3)) throw new Error("d3 is not available");
    return d3;
  }
})();
