(function () {
  angular
    .module("akitabox.desktop.workOrder.detail")
    .controller("WorkOrderDetailController", WorkOrderDetailController);

  /* @ngInject */
  function WorkOrderDetailController(
    // Angular
    $scope,
    $window,
    $state,
    // Third-party
    $stateParams,
    HistoryService,
    // Events
    EVENT_RESCHEDULE_WORK_ORDER,
    // Resolves
    building,
    workOrder,
    // Services
    BuildingService,
    ChecklistService,
    EnvService,
    InspectionService,
    OrganizationService,
    UserService,
    WorkOrderService,
    ToastService,
    // Constants
    models,
    // Dialogs
    WorkOrderLogDialog,
    CancelWorkOrderDialog,
    CompleteWorkOrderDialog,
    ReopenWorkOrderDialog,
    RescheduleWorkOrderDialog,
    DeleteWorkOrderDialog,
    AssignDialog,
    TimeCodeService
  ) {
    var self = this;

    // Attributes
    self.workOrder = workOrder;
    self.building = building;
    self.canLogWork = false;
    self.canDelete = false;
    var user = UserService.getCurrent();
    self.subtitle = {
      name: "Work Orders",
      state: { name: "app.workOrders", params: $stateParams },
    };
    var permissions = UserService.getPermissions();
    self.tabs = getTabs();

    var workOrderListRoute = getWorkOrderListRoute();
    self.breadcrumbs = getBreadcrumbs();
    self.isInspectionWo = false;
    self.isOpenOrScheduled = false;
    self.isCanceled = false;
    self.inspectionFloor = null;

    self.menuItems = [];
    self.timeCodes = [];
    self.showMenu = false;
    self.toggleMenu = function () {
      self.showMenu = !self.showMenu;
    };
    self.showLogWorkDialog = showLogWorkDialog;
    self.showCompleteDialog = showCompleteDialog;
    self.showReopenDialog = showReopenDialog;
    self.showDeleteDialog = showDeleteDialog;

    var reactiveHtml =
      '<span class="work-order-intent__reactive"><span class="reactive-triangle"></span><span class="reactive-label">R</span></span>';
    var preventiveHtml = '<span class="work-order-intent__preventive">P</span>';

    // Run
    init();

    function init() {
      var organization = OrganizationService.getCurrent();
      self.organization = organization;
      $scope.$watchCollection("vm.workOrder", function () {
        self.tabs = getTabs();
      });
      self.canLogWork = WorkOrderService.canLogWork(self.workOrder, user);
      self.canDelete = permissions.task.remove;
      self.isOpenOrScheduled =
        self.workOrder.status === "open" ||
        self.workOrder.status === "scheduled";
      self.isCanceled = self.workOrder.status === "canceled";
      self.isInspectionWo = self.workOrder.source === "inspection";
      self.breadcrumbs = getBreadcrumbs();
      self.menuItems = [];
      if (self.isOpenOrScheduled) {
        if (permissions.task.assign) {
          self.menuItems.push({
            title: "Reassign",
            icon: "person",
            onClick: showReassignDialog,
          });
        }
        if (permissions.task.update) {
          self.menuItems.push({
            title: "Reschedule",
            icon: "event",
            onClick: showRescheduleDialog,
          });
        }
        if (permissions.task.cancel) {
          self.menuItems.push({
            title: "Cancel",
            icon: "cancel",
            onClick: showCancelDialog,
          });
        }
      } else if (!self.isOpenOrScheduled && self.isInspectionWo) {
        if (permissions.task.reopen) {
          self.menuItems.push({
            title: "Reopen",
            icon: "refresh",
            onClick: showReopenDialog,
          });
        }
      }
      self.headerPrefixHtml =
        self.workOrder.intent === "reactive" ? reactiveHtml : preventiveHtml;

      // generate inspections link
      if (self.isInspectionWo) {
        return ChecklistService.getByWorkOrderId(
          organization._id,
          self.workOrder._id
        ).then(function (checklists) {
          self.inspectionFloor = InspectionService.getInspectionFloor(
            checklists,
            self.workOrder
          );
        });
      }
    }

    function getBreadcrumbs() {
      return [
        {
          href: workOrderListRoute,
          title: "Work Orders",
          icon: "build",
        },
      ];
    }

    function getWorkOrderListRoute() {
      var currentBuilding = BuildingService.getCurrent();
      var buildingUriSegment = currentBuilding ? currentBuilding.uri : "";
      return (
        EnvService.getCurrentBaseUrl() +
        buildingUriSegment +
        "/" +
        models.WORK_ORDER.ROUTE_PLURAL
      );
    }

    function showLogWorkDialog() {
      WorkOrderLogDialog.show({
        locals: {
          workOrder: self.workOrder,
        },
      });
    }

    function showCompleteDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };
      CompleteWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          return workOrders[0];
        })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    function showReopenDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };
      ReopenWorkOrderDialog.show({ locals: locals })
        .then(function (workOrderIDs) {
          return WorkOrderService.getById(self.building._id, workOrderIDs[0]);
        })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    function showDeleteDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };
      DeleteWorkOrderDialog.show({ locals: locals })
        .then(function () {
          ToastService.showSimple("Work Order deleted.");
          HistoryService.back();
        })
        .catch(ToastService.showError);
    }

    function showReassignDialog() {
      var locals = {
        buildingId: self.building._id,
        workOrderId: self.workOrder._id,
      };

      return AssignDialog.show({ locals: locals })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    function showRescheduleDialog() {
      var locals = {
        buildingId: self.building._id,
        workOrders: [self.workOrder],
      };
      RescheduleWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          var rescheduledWorkOrder = workOrders[0];
          updateWorkOrder(rescheduledWorkOrder);
          $scope.$broadcast(EVENT_RESCHEDULE_WORK_ORDER, {
            startDate: rescheduledWorkOrder.start_date,
            endDate: rescheduledWorkOrder.due_date,
          });
        })
        .catch(ToastService.showError);
    }

    function showCancelDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };

      return CancelWorkOrderDialog.show({ locals: locals })
        .then(function (workOrderId) {
          return WorkOrderService.getById(self.building._id, workOrderId);
        })
        .then(updateWorkOrder)
        .catch(ToastService.showError);
    }

    function updateWorkOrder(updatedWorkOrder) {
      self.workOrder = angular.extend(self.workOrder, updatedWorkOrder);
      init();
    }

    function getTabs() {
      var overview = {
        title: "Overview",
        state: "app.workOrder.overview",
      };
      var details = {
        title: "Details",
        state: "app.workOrder.details",
      };
      var schedule = {
        title: "Maintenance Schedule",
        state: "app.workOrder.schedule",
      };
      var request = {
        title: "Service Request",
        state: "app.workOrder.request",
      };
      var floor = { title: "Floor", state: "app.workOrder.floor" };
      var room = { title: "Room", state: "app.workOrder.room" };
      var asset = { title: "Asset", state: "app.workOrder.asset" };

      var tabsToReturn = [overview, details];
      if (workOrder.future_task) tabsToReturn.push(schedule);
      if (workOrder.request) tabsToReturn.push(request);
      if (WorkOrderService.getLevel(workOrder)) tabsToReturn.push(floor);
      if (WorkOrderService.getRoom(workOrder)) tabsToReturn.push(room);
      if (WorkOrderService.getAsset(workOrder)) tabsToReturn.push(asset);

      return tabsToReturn;
    }
  }
})();
