import { bulkAddDialogClosed } from "../../../../react/bulk-add-dialog/BulkAddDialogService";
import {
  attachmentDialogOpened,
  attachmentDialogClosed,
} from "../../../../react/attachment-dialog/AttachmentDialogService";
(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.list.asset
   */
  angular
    .module("akitabox.desktop.directives.list.asset", [
      "akitabox.core.services.building",
      "akitabox.core.services.env",
      "akitabox.core.services.flag",
      "akitabox.core.services.organization",
      "akitabox.core.services.pinValue",
      "akitabox.core.services.request",
      "akitabox.core.services.schedule",
      "akitabox.core.services.user",
      "akitabox.core.services.workOrder",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.delete",
      "akitabox.ui.dialogs.asset.delete",
      "akitabox.ui.dialogs.asset.costLineSelection",
      "akitabox.ui.dialogs.asset.lifeCycle",
      "akitabox.ui.dialogs.bulkDelete.asset",
      "akitabox.ui.dialogs.bulkEdit.pinField",
      "akitabox.ui.dialogs.asset.costAdjustment",
      "akitabox.ui.dialogs.decommission",
      "akitabox.ui.dialogs.filePreview",
      "akitabox.ui.dialogs.recommission",
      "akitabox.ui.dialogs.request.create",
      "akitabox.ui.dialogs.workOrder.create",
      "akitabox.ui.dialogs.schedule.create",
    ])
    .directive("abxAssetList", AbxAssetListDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.list.asset
   * @name AbxAssetList
   *
   * @restrict E
   *
   * @description
   * `<abx-asset-list>` is a list of assets in the current building. Once selected, the batch actions appear.
   *
   * @usage
   *  <abx-asset-list
   *          building="vm.buildings"
   *          models="vm.assets"
   *          abx-fetch="vm.fetchAssets(skip, limit)"
   *          abx-fetch-all="vm.fetchAllAssets(limit)"
   *          abx-create-options="vm.createOptions">
   *  </abx-asset-list>
   *
   * @ngInject
   */
  function AbxAssetListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/asset/asset-list.html",
      controller: AssetListController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        showBffMode: "=abxShowBffMode",
        fetch: "&abxFetch",
        fetchAll: "&abxFetchAll",
        allowAdd: "&?abxAllowAdd",
        createOptions: "=?abxCreateOptions",
        decommissioned: "<?abxDecommissioned",
        buildingPinTypes: "=?abxPinTypes",
      },
    };
  }

  /* @ngInject */
  function AssetListController(
    // Angular
    $q,
    $scope,
    $timeout,
    // Dialogs
    AssetCostAdjustmentDialog,
    AssetCostLineSelectionDialog,
    AssetLifeCycleDialog,
    CreateRequestDialog,
    CreateWorkOrderDialog,
    CreateScheduleDialog,
    DecommissionDialog,
    DeleteDialog,
    FilePreviewDialog,
    BulkEditPinFieldDialog,
    BulkDeleteAssetDialog,
    RecommissionDialog,
    // Services
    BuildingService,
    FeatureFlagService,
    OrganizationService,
    PinValueService,
    ToastService,
    UserService
  ) {
    var self = this;
    var permissions = UserService.getPermissions();

    // Attributes
    self.permissions = getDefaultPermissions();
    self.organization = OrganizationService.getCurrent();
    self.buildings = {};

    // Functions
    self.fetchAssets = fetchAssets;
    self.fetchAllAssets = fetchAllAssets;
    self.getFloorPinValue = PinValueService.getFloorPinValue;
    self.getRoomPinValue = PinValueService.getRoomPinValue;
    self.getPinValueByFieldName = PinValueService.getByFieldName;
    self.createCostText = createCostText;
    self.checkIfShowFca = checkIfShowFca;
    self.openFilePreviewDialog = openFilePreviewDialog;

    // ------------------------
    //   Private Functions
    // ------------------------

    const init = () => {
      if (!this.building) {
        $scope.$watchCollection("vm.models", (models) => {
          if (models && !this.building && !self.showBffMode) {
            BuildingService.populateBuildings(this.buildings, models);
          }
        });
      }
      this.permissions.canAddSchedule = permissions.future_task.create;
      this.permissions.canAddRequest = permissions.request.create;
      this.permissions.canAddWorkOrder = permissions.task.create;
      this.permissions.canDeleteAsset = permissions.asset.remove;
      this.permissions.canDecommissionAsset = permissions.asset.decommission;
      this.permissions.canRecommissionAsset = permissions.asset.recommission;
      this.permissions.canUpdateAsset = permissions.asset.update;
      this.permissions.canManageCostAdjustments =
        permissions.cost_adjustment.create &&
        permissions.cost_adjustment.remove &&
        permissions.cost_adjustment.update;
      const unsubscribe = bulkAddDialogClosed.subscribe(() => {
        $timeout(() => {
          unsubscribe();
          self.models = null;
          $scope.$broadcast("list:refresh");
        });
      });
    };

    init();

    function getDefaultPermissions() {
      return {
        canAddRequest: false,
        canAddSchedule: false,
        canAddWorkOrder: false,
        canUpdateAsset: false,
        canDeleteAsset: false,
      };
    }

    function createServiceRequest(item) {
      var serviceRequestAsset = self.models[item];
      var locals;
      var building = self.building
        ? self.building
        : self.buildings[serviceRequestAsset.building];

      // If we do not have self.buildings populated in this case, we can use the
      // orgBuildings array from BuildingService
      if (!building) {
        building = BuildingService.orgBuildings.find((building) => {
          return building._id === serviceRequestAsset.building;
        });
      }
      locals = {
        building: building,
        asset: serviceRequestAsset,
        floor: serviceRequestAsset.level,
        room: serviceRequestAsset.room,
      };

      return CreateRequestDialog.show({
        locals: locals,
      }).catch(ToastService.showError);
    }

    function createWorkOrder(items) {
      var locals;
      var assets = items.map(function (index) {
        return self.models[index];
      });
      var building = self.building
        ? self.building
        : self.buildings[assets[0].building];

      // If we do not have self.buildings populated in this case, we can use the
      // orgBuildings array from BuildingService
      if (!building) {
        building = BuildingService.orgBuildings.find((building) => {
          return building._id === assets[0].building;
        });
      }

      locals = {
        building: building,
        assets: assets,
      };

      if (assets.length === 1) {
        locals = { ...locals, floor: assets[0].level, room: assets[0].room };
      }

      return CreateWorkOrderDialog.show({
        locals: locals,
      }).catch(ToastService.showError);
    }

    function showCostLineSelectionDialog(items) {
      const assets = items.map((i) => self.models[i]);
      const locals = {};
      if (assets.length >= 1) {
        locals.assets = assets;
      } else {
        return;
      }

      return AssetCostLineSelectionDialog.show({ locals })
        .catch(ToastService.showError)
        .then((refreshAssets) => {
          if (refreshAssets) {
            self.models = null;
            $scope.$broadcast("list:refresh");
          }
        });
    }

    function showAssetLifeCycleDialog(items) {
      const assets = items.map((i) => self.models[i]);
      const locals = {};
      if (assets.length >= 1) {
        locals.assets = assets;
      } else {
        return;
      }

      return AssetLifeCycleDialog.show({ locals })
        .catch(ToastService.showError)
        .then((refreshAssets) => {
          if (refreshAssets) {
            self.models = null;
            $scope.$broadcast("list:refresh");
          }
        });
    }

    function createSchedule(items) {
      var locals;
      var assets = items.map(function (index) {
        return self.models[index];
      });
      var building = self.building
        ? self.building
        : self.buildings[assets[0].building];

      // If we do not have self.buildings populated in this case, we can use the
      // orgBuildings array from BuildingService
      if (!building) {
        building = BuildingService.orgBuildings.find((building) => {
          return building._id === assets[0].building;
        });
      }

      locals = {
        building: building,
        assets: assets,
      };

      if (assets.length === 1) {
        locals = { ...locals, floor: assets[0].level, room: assets[0].room };
      }

      return CreateScheduleDialog.show({
        locals: locals,
      }).catch(ToastService.showError);
    }

    /**
     * Opens the recommission dialog
     * @param {Number[]} assetIndexes - the indexes of the selected assets
     * @return {Promise<void>}
     */
    function decommissionAsset(assetIndexes) {
      // There should only be 1 index passed in ever
      var asset = self.models[assetIndexes[0]];
      var locals = {
        asset: asset,
      };

      return DecommissionDialog.show({
        locals: locals,
      })
        .then(function (decommissionedAsset) {
          if (decommissionedAsset) {
            // remove it from the list if it was decommissioned
            self.models.splice(assetIndexes[0], 1);
          }
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens the dialog to delete an asset
     * @param {Number[]} roomIndexes - the index of the selected asset
     * @return {Promise<void>}
     */
    function deleteAssets(assetIndexes) {
      var locals = {};
      // There should only be 1 index passed in ever
      locals.asset = self.models[assetIndexes[0]];
      return DeleteDialog.show({
        locals: locals,
      })
        .then(function (deletedAssetId) {
          // update self.models to remove the relevant asset
          self.models = self.models.filter(function (asset) {
            return asset._id !== deletedAssetId;
          });
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens the recommission dialog
     * @param {Number[]} assetIndexes - the indexes of the selected assets
     * @return {Promise<void>}
     */
    function recommissionAssets(assetIndexes) {
      var assets = assetIndexes.map(function (index) {
        return self.models[index];
      });
      var locals = {
        assets: assets,
      };

      return RecommissionDialog.show({ locals: locals })
        .then(function (recommissionedAssets) {
          // Remove the assets from this list
          if (recommissionedAssets && recommissionedAssets.length) {
            var recomissionedIds = recommissionedAssets.map(function (asset) {
              return asset._id;
            });
            self.models = self.models.filter(function (asset) {
              return recomissionedIds.indexOf(asset._id) === -1;
            });
            $timeout(function () {
              $scope.$broadcast("list:refreshClickEvents");
            });
          }
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens the dialog to bulk edit selected assets
     * @param {Number[]} assetIndexes - list of selected assets indexes
     * @return {Promise<void>}
     */
    function bulkEditAssets(assetIndexes) {
      const assets = assetIndexes.map(function (index) {
        return self.models[index];
      });

      const building = self.building
        ? self.building._id
        : self.buildings[assets[0].building]._id;

      const locals = {
        selectedItems: assets,
        buildingId: building,
        model: "assets",
        pinTypes: self.buildingPinTypes,
      };

      return BulkEditPinFieldDialog.show({ locals: locals })
        .then(function () {
          $timeout(function () {
            self.models = null;
            $scope.$broadcast("list:refresh");
          });
        })
        .catch(ToastService.showError);
    }

    function openCostAdjustmentDialog(assetIndexes) {
      const assets = assetIndexes.map(function (index) {
        return self.models[index];
      });

      return AssetCostAdjustmentDialog.show({ locals: { assets } })
        .catch(ToastService.showError)
        .then((refreshAssets) => {
          if (refreshAssets) {
            self.models = null;
            $scope.$broadcast("list:refresh");
          }
        });
    }

    function openBulkDeleteAssetDialog(assetIndexes) {
      const assets = assetIndexes.map(function (index) {
        return {
          _id: self.models[index]._id,
          name: self.models[index].name,
          buildingId: self.models[index].building,
        };
      });

      const locals = {
        assets,
        model: "assets",
      };

      return BulkDeleteAssetDialog.show({ locals: locals })
        .then(function () {
          $timeout(function () {
            self.models = null;
            $scope.$broadcast("list:refresh");
          });
        })
        .catch(ToastService.showError);
    }

    function openAttachmentDialog(assetIndexes) {
      const entityType = "asset";
      const entityIds = assetIndexes.map((index) => self.models[index]._id);
      const {
        building: { _id: building },
        organization: { _id: organization },
      } = self;

      attachmentDialogOpened.next({
        building,
        entityIds,
        entityType,
        organization,
      });

      const unsubscribe = attachmentDialogClosed.subscribe(() => {
        $timeout(() => {
          unsubscribe();
          self.models = null;
          $scope.$broadcast("list:refresh");
        });
      });

      return $q.resolve();
    }

    function openFilePreviewDialog($event, asset) {
      $event.stopPropagation();
      if (!asset.has_attachments) return;
      return FilePreviewDialog.show({
        pin: asset,
        supportMultipleFiles: true,
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------
    this.onSelect = (index, deselected) => {
      const model = deselected ? null : self.models[index];
      $scope.$emit("asset_selected", model);
    };

    this.getListActions = (items) => {
      var addRequestAction = {
        text: "Add Service Request",
        icon: "announcement",
        onClick: createServiceRequest,
      };
      var addWorkOrderAction = {
        text: "Add Work Order",
        icon: "build",
        onClick: createWorkOrder,
      };
      var addScheduleAction = {
        text: "Add Maintenance Schedule",
        icon: "insert_invitation",
        onClick: createSchedule,
      };
      const setBaseCostAction = {
        text: "Set Base Cost",
        icon: "attach_money",
        onClick: showCostLineSelectionDialog,
      };
      const assetLifeCycleAction = {
        text: "Life Cycle & Cost",
        icon: "attach_money",
        onClick: showAssetLifeCycleDialog,
      };
      var deleteAssetAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteAssets,
      };
      var decommissionAction = {
        text: "Decommission",
        icon: "location_off",
        onClick: decommissionAsset,
      };
      var recommissionAction = {
        text: "Recommission",
        icon: "pin_drop",
        onClick: recommissionAssets,
      };
      var bulkEditAssetsAction = {
        text: "Edit Fields",
        icon: "edit",
        onClick: bulkEditAssets,
      };
      const costAdjustmentAction = {
        text: "Manage Cost Adjustments",
        icon: "insert_chart",
        onClick: openCostAdjustmentDialog,
      };
      const bulkDeleteAssetsAction = {
        text: "Bulk Delete",
        icon: "delete_sweep",
        onClick: openBulkDeleteAssetDialog,
      };
      const bulkAttachDetachFiles = {
        text: "Photos & Files",
        icon: "attach_file",
        onClick: openAttachmentDialog,
      };

      var actions = [];

      var isDecommissioned = false;
      var asset = this.models[items[0]];
      if (this.decommissioned === "true" || asset.is_decommissioned) {
        isDecommissioned = true;
      }
      var allSameBuilding;
      // building list or only one item, same bldg by definition
      if (this.building || items.length === 1) {
        allSameBuilding = true;
      } else {
        // org list and multiple items, check if all same bldg
        var assets = items.map((index) => {
          return this.models[index];
        });
        allSameBuilding = assets.every((asset) => {
          return asset.building === assets[0].building;
        });
      }

      if (isDecommissioned) {
        if (this.permissions.canRecommissionAsset) {
          actions.push(recommissionAction);
        }
      } else {
        // Add SR, WO, MS actions
        if (this.organization.show_tasks) {
          // Allow service request creation if one asset is selected
          if (items.length === 1 && this.permissions.canAddRequest) {
            actions.push(addRequestAction);
          }

          // SR/WO/MS creation
          if (items.length && allSameBuilding) {
            if (this.permissions.canAddWorkOrder) {
              actions.push(addWorkOrderAction);
            }
            if (this.permissions.canAddSchedule) {
              actions.push(addScheduleAction);
            }
          }
        }
        // Bulk edit fields if same building
        if (
          this.permissions.canUpdateAsset &&
          this.building &&
          items.length > 0
        ) {
          actions.push(bulkEditAssetsAction);
        }
        // Add FCA actions
        if (items.length && this.organization.show_fca) {
          if (FeatureFlagService.isEnabled("asset_life_cycle")) {
            actions.push(assetLifeCycleAction);
          } else {
            actions.push(setBaseCostAction);
          }
          if (this.permissions.canManageCostAdjustments) {
            actions.push(costAdjustmentAction);
          }
        }
        // Photos & Files
        if (this.building && items.length > 0 && items.length < 100) {
          actions.push(bulkAttachDetachFiles);
        }
        // Decommission
        if (items.length === 1 && this.permissions.canDecommissionAsset) {
          actions.push(decommissionAction);
        }
      }

      // Add delete or bulk delete action
      if (this.permissions.canDeleteAsset) {
        if (items.length === 1) {
          actions.push(deleteAssetAction);
        } else if (items.length > 1) {
          actions.push(bulkDeleteAssetsAction);
        }
      }

      return actions;
    };

    function fetchAssets(skip, limit) {
      return self.fetch({
        skip: skip,
        limit: limit,
      });
    }

    function fetchAllAssets(limit) {
      return self.fetchAll({
        limit: limit,
      });
    }

    /**
     * Creates cost text to be displayed in asset listview.
     * @param asset - the asset the cost belongs to
     * @returns
     */
    function createCostText(asset) {
      if (!asset.costlines || asset.costlines.length === 0) {
        return "-";
      } else {
        const costLine = asset.costlines[0];
        if (costLine.cost === null) {
          return "-";
        } else {
          return `${costLine.total_cost_with_adjustments}`;
        }
      }
    }

    function checkIfShowFca() {
      const showFcaFeatureOption =
        self.organization && self.organization.show_fca;
      return showFcaFeatureOption;
    }
  }
})();
