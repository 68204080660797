(function () {
  angular
    .module("akitabox.ui.dialogs.request.deny")
    .controller("DenyRequestDialogController", DenyRequestDialogController);

  /* @ngInject */
  function DenyRequestDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    RequestService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since requests is passed in through locals
    self.multiple = self.requests ? self.requests.length > 1 : false;
    self.reasonDenied = null;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deny = deny;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Deny service requests
     */
    function deny() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.requests.length) * 100;
      var promiseArray = [];

      // Deny the service requests
      for (var i = 0; i < self.requests.length; i++) {
        var building =
          self.requests[i].building._id || self.requests[i].building;
        promiseArray[i] = RequestService.deny(building, self.requests[i]._id, {
          reason_denied: self.reasonDenied,
        }).then(function (deniedRequest) {
          self.determinateValue += increment;
          return deniedRequest._id;
        });
      }

      $q.all(promiseArray)
        .then(function (deniedRequestIDs) {
          $mdDialog.hide(deniedRequestIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
