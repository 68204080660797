(function () {
  angular
    .module("akitabox.core.services.pinValue", [
      "akitabox.core.constants",
      "akitabox.core.services.document",
      "akitabox.core.services.http",
      "akitabox.core.services.floor",
      "akitabox.core.services.room",
      "akitabox.core.services.tree",
      "akitabox.core.services.flag",
    ])
    .factory("PinValueService", PinValueService);

  var MILLISECONDS_IN_MINUTE = 60000;

  /**
   * Service for pin values. Service functions that actually make a call to a
   * pin's `/values` route will be found in their respective services. This
   * service is only here to provide common helper functions for pin values.
   *
   * @ngInject
   */
  function PinValueService(
    // Angular
    $log,
    $q,
    // Constants
    PIN_FIELD_DATA_TYPES,
    // Services
    DocumentService,
    FloorService,
    RoomService,
    TreeService
  ) {
    var service = {
      getByDataType: getByDataType,
      getByFieldName: getByFieldName,
      getFieldOptions: getFieldOptions,
      getFloorPinValue: getFloorPinValue,
      getRoomPinValue: getRoomPinValue,
      getFloorId: getFloorId,
      getRoomId: getRoomId,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Gets a "full" pin value given its type and raw pin value object.
     *
     * - For "levels" and "rooms", this means retrieving the populated object.
     * - For "dates", this means converting the date into the user's time zone.
     * - For "document_arrays", this means retrieving all specified documents.
     * - For "tag_filters", this means retrieving all documents that match the specified tag
     *   filters.
     * - For all other data types, the empty string will be converted to `null`
     *
     * @param {String} buildingId       buildingId
     * @param {String} type             data type of the pinValue
     * @param {Object} pinValue         pinValue object
     * @return {Promise<*>}             Resolves with requested data
     */
    function getByDataType(buildingId, type, pinValue) {
      return $q(function (resolve) {
        switch (type) {
          case PIN_FIELD_DATA_TYPES.FLOOR:
            if (angular.isEmpty(pinValue.value)) {
              // Back end returns an empty string for "empty" floor pin values, so convert
              // those into `null` (which is a bit more intuitive)
              return resolve(null);
            }
            return resolve(FloorService.getById(buildingId, pinValue.value));
          case PIN_FIELD_DATA_TYPES.ROOM:
            if (angular.isEmpty(pinValue.value)) {
              // Back end returns an empty string for "empty" room pin values, so convert
              // those into `null` (which is a bit more intuitive)
              return resolve(null);
            }
            return resolve(
              RoomService.getById(buildingId, pinValue.value, {
                include_values: true,
              })
            );
          case PIN_FIELD_DATA_TYPES.DATE:
            if (angular.isEmpty(pinValue.value)) {
              return resolve(null);
            }
            // We do not want to apply the browser timezone (WEBAPP-3259)
            var date = new Date(pinValue.value);
            var userTimezoneOffset =
              date.getTimezoneOffset() * MILLISECONDS_IN_MINUTE;
            // adding back the browser's timezone offset
            return resolve(new Date(date.getTime() + userTimezoneOffset));
          case PIN_FIELD_DATA_TYPES.DOCUMENT_ARRAY:
            if (angular.isEmpty(pinValue.value)) {
              // Empty value is an array in this case, and should be returned as such
              return resolve(pinValue.value);
            }
            var requests = [];
            var documentIDs = angular.copy(pinValue.value);
            documentIDs.forEach(function (documentId) {
              var promise = DocumentService.getById(
                buildingId,
                documentId
              ).catch(function (err) {
                // Swallow 404's. See WEBAPP-2895
                if (err && err.status === 404) {
                  return undefined;
                } else {
                  // preserve any other errors
                  return $q.reject(err);
                }
              });
              requests.push(promise);
            });
            return $q
              .all(requests)
              .then(function (requestResults) {
                // trim off any 404'd requests
                return requestResults.filter(function (result) {
                  return result !== undefined;
                });
              })
              .then(resolve);
          case PIN_FIELD_DATA_TYPES.TAG_FILTER:
            var filters = angular.copy(pinValue.value);
            if (angular.isEmpty(filters)) {
              // If the user does not give any filters, just return an empty array
              // instead of fetching for documents
              return resolve([]);
            }

            var params = {};
            for (var i = 0; i < filters.length; ++i) {
              var f = filters[i];
              params["tags_" + f.cat_name] = f.value_name;
            }
            return resolve(DocumentService.getAll(buildingId, params));
          default:
            if (pinValue.value === "") {
              return resolve(null);
            }
            return resolve(pinValue.value);
        }
      });
    }

    function getFieldOptions(field) {
      return $q(function (resolve) {
        switch (field.data_type) {
          case PIN_FIELD_DATA_TYPES.BOOLEAN:
            var options = [
              { display: "Yes", value: true },
              { display: "No", value: false },
            ];
            return resolve(options);
          case PIN_FIELD_DATA_TYPES.TREE:
            return resolve(TreeService.getByPinField(field));
          default:
            return resolve(field.acceptable_enum_values);
        }
      });
    }

    /**
     * Get the floor pin value object from a set of pin values
     *
     * @param  {Object} values   Pin values
     * @return {Object}          Floor pin value
     *
     */
    function getFloorPinValue(values) {
      if (angular.isEmpty(values)) return;

      var keys = Object.keys(values);
      for (var i = 0; i < keys.length; ++i) {
        var value = values[keys[i]];
        if (value.is_level_pin_value) {
          return value;
        }
      }
    }

    /**
     * Get the room pin value object from a set of pin values
     *
     * @param  {Object} values   Pin values
     * @return {Object}          Room pin value
     */
    function getRoomPinValue(values) {
      if (angular.isEmpty(values)) return;

      var keys = Object.keys(values);
      for (var i = 0; i < keys.length; ++i) {
        var value = values[keys[i]];
        if (value.is_room_pin_value) {
          return value;
        }
      }
    }

    /**
     * Find the pin value from a set for the pin field with the given name.
     *
     * @param {String}   fieldName   Field name of value to find
     * @param {Object[]} fields      Pin fields associated with the given values
     * @param {Object}   values      Pin values set
     * @return {Object}              Matching pin value
     */
    function getByFieldName(fieldName, fields, values) {
      if (!fieldName || !angular.isArray(fields) || angular.isEmpty(values)) {
        return;
      }

      for (var i = 0; i < fields.length; ++i) {
        var field = fields[i];
        var value = values[field._id];

        // Values' keys need to agree with pin type's fields
        if (!value) {
          $log.error(
            "PinValueService.getByFieldName: given fields don't match up with given values' pin fields"
          );
          return;
        }

        if (field.name === fieldName) {
          return value;
        }
      }
    }

    /**
     * Get the floor ID from a set of pin values
     *
     * @param  {Object} values   Pin values
     * @return {String}          Floor ID (or null if there is none)
     */
    function getFloorId(values) {
      var floorPinValue = getFloorPinValue(values);
      return floorPinValue && !angular.isEmpty(floorPinValue.value)
        ? floorPinValue.value
        : null;
    }

    /**
     * Get the room ID from a set of pin values
     *
     * @param  {Object} values   Pin values
     * @return {String}          Room ID (or null if there is one)
     */
    function getRoomId(values) {
      var roomPinValue = getRoomPinValue(values);
      return roomPinValue && !angular.isEmpty(roomPinValue.value)
        ? roomPinValue.value
        : null;
    }
  }
})();
