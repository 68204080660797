(function () {
  angular
    .module("akitabox.ui.directives.importFileSelect")
    .directive("abxImportFileSelect", AbxImportFileSelectDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.importFileSelect
   * @name AbxImportFileSelect
   *
   * @description
   * `<abx-import-file-select>` is a component that contains a file select
   * and type dropdown.
   *
   * @param {String}  [abx-input-id]              File select element ID
   * @param {String}  abx-accept                  Accept file extensions of the
   *                                              form ".csv, .txt"
   * @param {String}  [abx-file-placeholder]      File placeholder
   * @param {String}  [abx-type-placeholder]      Type placeholder
   * @param {Array}   abx-supported-file-types    List of supported file mime-types
   * @param {Array}   abx-options                 List of import options
   * @param {Object}  abx-import-file             Selected file
   * @param {Object}  abx-import-type             Selected type
   * @param {Boolean} abx-hide-clear-button       Hide clear button
   * @param {Boolean} abx-auto-clear              Auto clear form once both file
   *                                              and type is selected
   *
   * @param {function(File)}  abx-on-import-file-change   File changed
   * @param {function(type)}  abx-on-import-type-change   Type changed
   * @param {function(err)}   abx-on-file-invalid         File invalid
   * @param {function()}      abx-on-clear                Clear button clicked
   *
   * @param {function(File, type): boolean} abx-is-duplicated     File and type
   *                                                              is a duplicate
   *
   * @usage
   * <hljs lang="html">
   *   <abx-import-file-select
   *       class="main-import-file-select"
   *       abx-accept=".csv"
   *       abx-supported-file-types="dialog.supportedFileTypes"
   *       abx-options="dialog.importTypes"
   *       abx-on-import-file-change="dialog.onFileSelected(file)"
   *       abx-on-import-type-change="dialog.onTypeSelected(type)"
   *       abx-on-file-error="dialog.onFileError(err)"
   *       abx-hide-clear-button
   *       abx-auto-clear
   *       >
   *   </abx-import-file-select>
   * </hljs>
   */
  function AbxImportFileSelectDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/import-file-select/import-file-select.html",
      controller: "AbxImportFileSelectController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        inputId: "@abxInputId",
        accept: "@abxAccept",
        filePlaceholder: "@abxFilePlaceholder",
        typePlaceholder: "@abxTypePlaceholder",
        supported: "=?abxSupportedFileTypes",
        options: "=abxOptions",
        file: "<?abxImportFile",
        type: "<?abxImportType",
        onFileChange: "&abxOnImportFileChange",
        onTypeChange: "&abxOnImportTypeChange",
        onFileInvalid: "&abxOnFileInvalid",
        onClear: "&abxOnClear",
        isDuplicated: "&abxIsDuplicated",
        orderBy: "@abxOrderBy",
      },
    };

    function postLink($scope, $element, $attrs, vm) {
      vm.hideClearBtn = angular.isDefined($attrs.abxHideClearButton);
      vm.autoClear = angular.isDefined($attrs.abxAutoClear);
    }
  }
})();
