/**
 * @file This service presents a public API intended to be called from
 * outside of the angularjs ecosystem. It is consumed by the react application
 * to manipulate the state of the angularjs.
 */
angular
  .module("akitabox.core.utils")
  .service("externalUtilsService", ExternalUtilsService)
  .run(setupAppRenderingFlag);

/** @ngInject */
function ExternalUtilsService($rootScope) {
  this.$rootScope = $rootScope;
}

/** @ngInject */
function setupAppRenderingFlag($rootScope) {
  $rootScope.appRenderingEnabled = false;
}

/**
 * Enable or disable the entire application's rendering.
 * @param { boolean } [force] if provided, sets app
 * renderingness to this value */
ExternalUtilsService.prototype.toggleRendering = function (force) {
  if (force !== undefined && this.$rootScope.appRenderingEnabled === force) {
    return;
  }

  if (force === undefined) {
    this.$rootScope.appRenderingEnabled = !this.$rootScope.appRenderingEnabled;
  } else {
    this.$rootScope.appRenderingEnabled = force;
  }
  this.$rootScope.$digest();
};
