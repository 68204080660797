(function () {
  angular
    .module("akitabox.desktop.workOrder.detail")
    .controller("WorkOrderDetailsTabController", WorkOrderDetailsTabController);

  /* @ngInject */
  function WorkOrderDetailsTabController(
    // Angular
    $timeout,
    // Resolve
    building,
    workOrder
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.workOrder = workOrder;
    self.rendering = true;

    $timeout(function () {
      self.rendering = false;
    }, 1000);
  }
})();
