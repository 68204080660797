(function () {
  angular
    .module("akitabox.desktop.workOrder.detail")
    .controller("WorkOrderRoomTabController", WorkOrderRoomTabController);

  /* @ngInject */
  function WorkOrderRoomTabController(
    $timeout,
    building,
    workOrder,
    RoomService,
    ToastService,
    WorkOrderService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.rendering = true;
    self.building = building;
    self.workOrder = workOrder;
    self.room = null;

    init();

    function init() {
      var roomId = WorkOrderService.getRoom(self.workOrder)._id;
      var params = { include_values: true };
      var options = {
        includePinType: true,
        includeFloor: true,
      };
      RoomService.getById(building._id, roomId, params, options)
        .then(function (room) {
          self.room = room;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
          $timeout(function () {
            self.rendering = false;
          }, 500);
        });
    }
  }
})();
