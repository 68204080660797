(function () {
  angular
    .module("akitabox.ui.dialogs.print")
    .controller("PrintDialogController", PrintDialogController);

  /* @ngInject */
  function PrintDialogController(
    moment,
    $mdDialog,
    ShadowService,
    FeatureFlagService,
    FileService,
    HttpService
  ) {
    var self = this;

    var WORK_ORDER_PRINT_LIMIT = 100;
    var printOptsLoaded = 0;

    // Attributes
    self.printingDisabled = true;
    self.printOptions = null;
    self.filters = angular.isDefined(self.filters) ? self.filters : {};
    self.selectedPrintOption = null;
    self.selectedLayoutOption = "full";

    // Functions
    self.cancel = $mdDialog.cancel;
    self.generatePDF = generatePDF;

    init();

    // Private Functions

    function init() {
      if (self.selected) {
        self.printingDisabled = false;
      } else {
        self.printOptions = getPrintOptions();
        for (var i = 0; i < self.printOptions.length; ++i) {
          getPrintOptionCount(self.printOptions[i]).then(onPrintOptionLoaded);
        }
      }
    }

    function getPrintOptions() {
      // Printing closed work orders is not supported
      var unsupportedStatuses = ["completed, canceled"];
      if (unsupportedStatuses.indexOf(self.status) > -1) return null;

      var dayStart = new moment().startOf("day").utc();
      var dayEnd = new moment().endOf("day").utc();
      var weekStart = new moment().startOf("week").utc();
      var weekEnd = new moment().endOf("week").utc();
      var monthStart = new moment().startOf("month").utc();
      var monthEnd = new moment().endOf("month").utc();

      // Current filters option is included in the dialog
      return [
        {
          title: "Opening Today",
          description: "Print all work orders that open today",
          filters: {
            start_date: buildDateRange(dayStart, dayEnd),
          },
        },
        {
          title: "Opening This Week",
          description: "Print all work orders that open this week",
          filters: {
            start_date: buildDateRange(weekStart, weekEnd),
          },
        },
        {
          title: "Opening This Month",
          description: "Print all work orders that open this month",
          filters: {
            start_date: buildDateRange(monthStart, monthEnd),
          },
        },
        {
          title: "All Displayed",
          description:
            "Print all work orders currently in the (filtered) list shown",
          filters: self.filters,
        },
      ];

      function buildDateRange(start, end) {
        return "$gte," + start.valueOf() + ",$lte," + end.valueOf();
      }
    }

    function onPrintOptionLoaded() {
      printOptsLoaded++;
      if (printOptsLoaded === self.printOptions.length) {
        for (var i = 0; i < self.printOptions.length; ++i) {
          var option = self.printOptions[i];
          if (option.count <= WORK_ORDER_PRINT_LIMIT && option.count > 0) {
            self.selectedPrintOption = option;
            self.printingDisabled = false;
            return;
          }
        }
      }
    }

    function getPrintOptionCount(option) {
      var params = angular.extend({}, option.filters, {
        count: true,
      });
      return HttpService.get(self.route, params)
        .then(function (res) {
          option.count = res.count;
        })
        .catch(function () {
          // Disable print option and allow promise to resolve
          option.count = null;
        });
    }

    function buildSelectedFilters() {
      return {
        _id:
          self.selected.length === 1
            ? self.selected[0]
            : "$in," + self.selected.join(","),
      };
    }

    // Public Functions

    function generatePDF() {
      var filters = {};
      var count;
      if (self.selected) {
        filters = buildSelectedFilters();
        count = self.selected.length;
      } else if (self.selectedPrintOption) {
        filters = self.selectedPrintOption.filters;
        count = self.selectedPrintOption.count;
      }
      var params = angular.extend({}, filters, {
        export: "print",
        limit: WORK_ORDER_PRINT_LIMIT,
        include_values: true,
        split: true,
      });
      if (self.selectedLayoutOption === "compact") {
        params.compact = true;
      }
      var layout = self.selectedLayoutOption === "compact" ? "compact" : "full";
      ShadowService.sendEvent("workorder", "print", layout, count);
      var route = self.route + HttpService.buildQueryString(params);
      FileService.open(route);
      $mdDialog.hide();
    }
  }
})();
