/**
 * @file Color constants to be referenced as we move away from Sass.
 * Should be kept in line with the entries in `../style/_colors.scss`.
 */

export enum Color {
  White = "#ffffff",
  Black = "#000000",
  MediumGray = "#999999",
  Blue = "#2d8dd9",
  MidnightExpress = "#21263A",
  MidnightExpressLight = "#238DD9",
  BorderGray = "#D9D9D9",
  DarkGray = "#666666",
  LightGray = "#EEEEEE",
}
