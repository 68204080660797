(function () {
  angular.module("akitabox.ui.directives", [
    "akitabox.ui.directives.autocomplete",
    "akitabox.ui.directives.accountAutocomplete",
    "akitabox.ui.directives.activityList",
    "akitabox.ui.directives.animateChange",
    "akitabox.ui.directives.assetInput",
    "akitabox.ui.directives.button",
    "akitabox.ui.directives.donutChart",
    "akitabox.ui.directives.lineChart",
    "akitabox.ui.directives.attachmentContainer",
    "akitabox.ui.directives.attachmentThumbnail",
    "akitabox.ui.directives.attachmentUpload",
    "akitabox.ui.directives.bldgThumbnail",
    "akitabox.ui.directives.datepicker",
    "akitabox.ui.directives.documentThumbnail",
    "akitabox.ui.directives.fab",
    "akitabox.ui.directives.fileChange",
    "akitabox.ui.directives.floorInput",
    "akitabox.ui.directives.importFileSelect",
    "akitabox.ui.directives.field",
    "akitabox.ui.directives.inputSection",
    "akitabox.ui.directives.job",
    "akitabox.ui.directives.lifeCycleChart",
    "akitabox.ui.directives.map",
    "akitabox.ui.directives.pagination",
    "akitabox.ui.directives.placesAutocomplete",
    "akitabox.ui.directives.roomInput",
    "akitabox.ui.directives.priorityIcon",
    "akitabox.ui.directives.sideMenu",
    "akitabox.ui.directives.simpleAutocomplete",
    "akitabox.ui.directives.stackChart",
    "akitabox.ui.directives.step",
    "akitabox.ui.directives.treeInput",
    "akitabox.ui.directives.uniformatClassificationInput",
    "akitabox.ui.directives.url",
  ]);
})();
