(function () {
  angular
    .module(
      "akitabox.ui.components.serviceRequestList.serviceRequestQuickActions",
      ["akitabox.ui.components.input"]
    )
    .component("abxSrQuickActions", {
      bindings: {
        serviceRequest: "<abxServiceRequest",
        building: "<abxBuilding",
        onServiceRequestUpdate: "&",
      },
      controller: AbxServiceRequestQuickActionsController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/service-request-list/components/service-request-quick-actions/service-request-quick-actions.component.html",
    });

  function AbxServiceRequestQuickActionsController(
    // Angular
    $q,
    // Dialogs
    CreateWorkOrderDialog,
    DenyRequestDialog,
    // Services
    Router,
    ToastService,
    TradeService,
    WorkOrderService
  ) {
    var self = this;

    self.createWorkOrder = createWorkOrder;
    self.openCreateWorkOrderDialog = openCreateWorkOrderDialog;
    self.denyRequest = denyRequest;

    self.defaultTrade = null;
    self.newWorkOrder = null;
    self.loading = true;
    self.disableAcceptButton = true;

    self.$onInit = function () {
      self.serviceRequestIssueType = self.serviceRequest.issue_type
        ? self.serviceRequest.issue_type.name
        : "N/A";
      self.serviceRequestLevelName = self.serviceRequest.level
        ? self.serviceRequest.level.name
        : "N/A";
      self.serviceRequestRoomName = self.serviceRequest.room
        ? self.serviceRequest.room.display_name
        : "N/A";
      getTrade();
    };

    function getTrade() {
      if (
        !self.serviceRequest.issue_type ||
        !self.serviceRequest.issue_type.trade
      ) {
        self.loading = false;
        self.disableAcceptButton = self.building.require_trades;
        return;
      }
      self.loading = true;

      TradeService.getById(
        self.building._id,
        self.serviceRequest.issue_type.trade
      )
        .then(function (trade) {
          self.defaultTrade = trade;
          self.disableAcceptButton = false;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function createWorkOrder() {
      self.loading = true;
      return WorkOrderService.createFromRequest(
        self.building,
        self.serviceRequest,
        self.defaultTrade
      )
        .then(function (workOrder) {
          self.newWorkOrder = workOrder;
          self.onServiceRequestUpdate({ serviceRequest: self.serviceRequest });
          ToastService.complex()
            .text("Successfully created work order")
            .action("View", goToNewWorkOrder)
            .show();
        })
        .catch(onError)
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Go to the newly created work order's detail page
     */
    function goToNewWorkOrder() {
      var stateParams = {
        buildingId: self.building._id,
        workOrderId: self.newWorkOrder._id,
      };
      Router.go("app.workOrder", stateParams);
    }

    function openCreateWorkOrderDialog() {
      var request = self.serviceRequest;
      var locals = {
        building: self.building,
        request: request,
      };
      return CreateWorkOrderDialog.show({ locals: locals })
        .then(function () {
          self.onServiceRequestUpdate({ serviceRequest: self.serviceRequest });
        })
        .catch(onError);
    }

    function denyRequest(items) {
      var locals = {
        requests: [self.serviceRequest],
      };
      return DenyRequestDialog.show({ locals: locals })
        .then(function () {
          self.onServiceRequestUpdate({ serviceRequest: self.serviceRequest });
        })
        .catch(onError);
    }

    function onError(err) {
      ToastService.showError(err);
      return $q.reject(err);
    }
  }
})();
