(function () {
  angular
    .module("akitabox.desktop.asset.detail")
    .controller(
      "AssetOpenWorkOrdersTabController",
      AssetOpenWorkOrdersTabController
    );

  /* @ngInject */
  function AssetOpenWorkOrdersTabController(
    // Angular
    $scope,
    // Constants
    EVENT_WORK_ORDER_CREATE,
    WORK_ORDER_STATUS_SCHEDULED_OPEN,
    // Dialogs
    ExportDialog,
    // Services
    OrganizationService,
    ToastService,
    WorkOrderService,
    WorkOrdersBFFService,
    // Resolve
    building,
    asset
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.asset = asset;
    self.status = WORK_ORDER_STATUS_SCHEDULED_OPEN;
    self.workOrders = null;
    self.createOptions = getCreateOptions();
    self.fabActions = getFabActions();
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_WORK_ORDER_CREATE, onWorkOrderCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportWorkOrders,
        },
      ];
    }

    function exportWorkOrders() {
      var locals = {
        route: WorkOrderService.buildListRoute(building._id),
        filters: {
          status: self.status,
          asset: asset._id,
        },
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    function fetchWorkOrders(skip, limit) {
      var params = {
        status: WORK_ORDER_STATUS_SCHEDULED_OPEN,
        asset: asset._id,
        skip: skip,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      )
        .then(function (workOrders) {
          if (angular.isArray(self.workOrders)) {
            self.workOrders = self.workOrders.concat(workOrders);
          } else {
            self.workOrders = workOrders;
          }
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllWorkOrders(limit) {
      var params = {
        status: WORK_ORDER_STATUS_SCHEDULED_OPEN,
        asset: asset._id,
        skip: 0,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      )
        .then(function (workOrders) {
          self.workOrders = workOrders;
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getCreateOptions() {
      return {
        locals: {
          floors: asset.level ? [asset.level] : null,
          rooms: asset.room ? [asset.room] : null,
          assets: [asset],
        },
      };
    }

    /**
     * Handle work order creation event
     *
     * @param {Event}   $event      Angular event
     * @param {WorkOrder[]} workOrders  List of new work orders
     */
    function onWorkOrderCreate($event, workOrders) {
      for (var i = 0; i < workOrders.length; ++i) {
        var workOrder = workOrders[i];
        if (!workOrder.asset) {
          continue;
        }
        var assetId = Object.prototype.hasOwnProperty.call(
          workOrder.asset,
          "_id"
        )
          ? workOrder.asset._id
          : workOrder.asset;
        if (assetId === self.asset._id) {
          if (!self.workOrders) {
            self.workOrders = [];
          }
          self.workOrders.unshift(workOrders[i]);
        }
      }
    }
  }
})();
