(function () {
  angular
    .module("akitabox.ui.dialogs.job.cancel")
    .controller("CancelJobDialogController", CancelJobDialogController);

  /* @ngInject */
  function CancelJobDialogController(
    // Material
    $mdDialog,
    // Services
    ToastService,
    AdminJobService
  ) {
    var self = this;

    // Attributes
    self.saving = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.confirm = confirm;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Cancel job
     */
    function confirm() {
      self.saving = true;

      // Cancel the job
      AdminJobService.cancel(self.jobId)
        .then($mdDialog.hide)
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
