import { DocumentResponse } from "@akitabox/api-client";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { FunctionComponent, useCallback, useState } from "react";
import { ListItem, Stack, Typography, Box, Checkbox } from "@mui/material";

import { Color } from "../../colors";
import { stylesheet } from "../../stylesheet";
import { AttachmentDialogTestids, BulkActionData } from "../AttachmentDialog";
import { ImageSkeleton } from "../../image-skeleton/ImageSkeleton";

export interface FileSectionProps {
  selected?: boolean;
  file: DocumentResponse;
  attachedToSome: boolean;
  attachedToEvery: boolean;
  bulkAction?: BulkActionData["action"];
  onFileSelection: (file: DocumentResponse) => void;
  onBulkSelection: (action: BulkActionData["action"]) => void;
}

/**
 * Renders a list item with the given file thumbnail, name and size
 * @param `FileSectionProps`
 * @returns FunctionComponent
 */
export const FileSection: FunctionComponent<FileSectionProps> = ({
  file,
  selected,
  bulkAction,
  attachedToSome,
  attachedToEvery,
  onFileSelection,
  onBulkSelection,
}) => {
  const [thumbnailErrored, setThumbnailErrored] = useState(false);

  // memoizing callback that can cause <ImageSkeleton /> to re render images
  const onThumbnailError = useCallback(() => setThumbnailErrored(true), []);

  const renderCheckbox = useCallback(() => {
    if (!attachedToSome) {
      return (
        <Checkbox
          checked={!!selected}
          onChange={() => onFileSelection(file)}
          data-testid={AttachmentDialogTestids.DocumentCheckbox}
        />
      );
    }

    if (!attachedToEvery) {
      return (
        <Checkbox
          checked={bulkAction === "attach"}
          indeterminate={bulkAction === undefined}
          data-testid={AttachmentDialogTestids.SomeDocumentCheckbox}
          onChange={() => {
            if (bulkAction === undefined) {
              onBulkSelection("attach");
            } else {
              onBulkSelection(bulkAction === "detach" ? "attach" : "detach");
            }
          }}
        />
      );
    }

    return (
      <Checkbox
        checked={bulkAction === undefined}
        data-testid={AttachmentDialogTestids.EveryDocumentCheckbox}
        onChange={() => {
          onBulkSelection(bulkAction === undefined ? "detach" : "clear");
        }}
      />
    );
  }, [
    file,
    selected,
    bulkAction,
    attachedToSome,
    attachedToEvery,
    onFileSelection,
    onBulkSelection,
  ]);

  const fileNameDisplay = `${file.name}${file.extension || ""}`;

  return (
    <ListItem
      divider
      css={ss.fileListItem}
      data-testid={AttachmentDialogTestids.DocumentRow(fileNameDisplay)}
    >
      <Stack>
        <Box marginRight={(theme) => theme.spacing(1)}>{renderCheckbox()}</Box>
      </Stack>
      <Stack spacing={2} direction="row" alignItems="flex-start">
        <Box css={ss.thumbnailWrapper}>
          {/** thumbnail */}
          {thumbnailErrored ? (
            <AttachFileIcon
              css={ss.thumbnailPlaceholder}
              data-testid={AttachmentDialogTestids.AttachmentPlaceholder}
            />
          ) : (
            <ImageSkeleton
              alt={file.path}
              css={ss.thumbnail}
              onError={onThumbnailError}
              src={file.public_thumbnail_url_medium}
              SkeletonProps={{ variant: "rectangular" }}
              data-testid={AttachmentDialogTestids.DocumentThumbnail}
            />
          )}
        </Box>
        <Stack>
          <Box maxWidth="600px">
            {/* file name */}
            <Typography
              css={ss.fileNameText}
              data-testid={AttachmentDialogTestids.AttachmentFileName}
            >
              <span title={fileNameDisplay}>{fileNameDisplay}</span>
            </Typography>
          </Box>
          <Box>
            {/* file size */}
            <Typography
              css={ss.fileSizeText}
              data-testid={AttachmentDialogTestids.AttachmentFileSize}
            >
              {file._size_display}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </ListItem>
  );
};

const ss = stylesheet({
  fileListItem: (theme) => ({ padding: `${theme.spacing(1)} 0` }),
  thumbnailPlaceholder: {
    fontSize: "4rem",
    color: Color.MediumGray,
  },
  thumbnailWrapper: {
    display: "flex",
    minWidth: "133px",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `0 0 0 1px ${Color.MediumGray}`,
    "&:has(svg)": { backgroundColor: Color.LightGray },
  },
  thumbnail: {
    height: "100px",
    maxWidth: "133px",
    objectFit: "contain",
    // 4:3 means width = height * 1.33
    aspectRatio: "4 / 3",
  },
  fileNameText: {
    fontWeight: 600,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  fileSizeText: {
    color: Color.DarkGray,
    fontSize: "0.94rem",
    fontStyle: "italic",
  },
});
