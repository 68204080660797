(function () {
  angular
    .module("akitabox.core")
    .controller("UpgradeBrowserController", UpgradeBrowserController);

  function UpgradeBrowserController(browsers) {
    var self = this;

    self.browsers = browsers;
  }
})();
