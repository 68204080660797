(function () {
  angular
    .module("akitabox.core.services.bannerMessage")
    .factory("BannerMessageService", BannerMessageService);

  /** @ngInject */
  function BannerMessageService(
    // Constants
    models,
    // Services
    HttpService,
    $q
  ) {
    var service = {
      // Routes
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Retrieve
      get: get,
      getByOrganization: getByOrganization,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function buildBaseRoute() {
      return "/" + models.BANNER_MESSAGE.ROUTE_PLURAL;
    }

    function buildListRoute() {
      return buildBaseRoute();
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        buildBaseRoute()
      );
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(params) {
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }
  }
})();
