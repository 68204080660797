(function () {
  angular
    .module("akitabox.ui.dialogs.floor.delete")
    .controller("DeleteFloorDialogController", DeleteFloorDialogController);

  /* @ngInject */
  function DeleteFloorDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    FloorService,
    OrganizationService
  ) {
    var self = this;

    var organization = OrganizationService.getCurrent();

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since floorIDs is passed in through locals
    self.multiple = self.floorIDs ? self.floorIDs.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteFloor = deleteFloor;

    var warningStart =
      "Deleting this floor will permanently remove all its data from AkitaBox. You cannot delete this floor if there are linked";
    self.warningMessage = organization.show_tasks
      ? warningStart +
        " rooms, assets, service requests, work orders, or maintenance schedules."
      : warningStart + " rooms or assets.";
    self.warningEnd = "Are you sure you want to delete this floor?";

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete floors
     */
    function deleteFloor() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.floorIDs.length) * 100;
      var promiseArray = [];

      // Delete the floors
      for (var i = 0; i < self.floorIDs.length; i++) {
        promiseArray[i] = FloorService.remove(
          self.building._id,
          self.floorIDs[i]
        )
          // when we delete a floor, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.floorIDs[storedI];
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedFloorIDs) {
          $mdDialog.hide(deletedFloorIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
