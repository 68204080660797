(function () {
  angular
    .module("akitabox.ui.dialogs.updateIdentityNames")
    .factory("UpdateIdentityNamesDialog", UpdateIdentityNamesDialog);

  /* @ngInject */
  function UpdateIdentityNamesDialog(
    // Angular
    $mdDialog,
    $mdMedia,
    $timeout,
    $cookies,
    // Constants
    CHECK_IDENTITY_NAMES_COOKIE,
    // Services
    EnvService,
    FeatureFlagService,
    IdentityService
  ) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/update-identity-names/update-identity-names.dialog.html",
      controller: "UpdateIdentityNamesDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * PrintDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * If the user does not have name information saved in their identity,
     * show the updateName dialog after they login
     *
     * Checks for cookie
     *  if exists
     *  -> if current identity has NO name information saved
     *    -> shows the dialog to collect name information
     *
     * @return {Promise<Object|Error}
     */
    function show() {
      // See SAML route `post` logic and the localAuth `authenticate` logic
      var checkIdentityName = $cookies.get(CHECK_IDENTITY_NAMES_COOKIE);
      if (checkIdentityName) {
        var domain =
          "." + EnvService.getBaseUrl().split("://")[1].split(":")[0];

        return IdentityService.getCurrent().then(function (identity) {
          if (identity && (!identity.first_name || !identity.last_name)) {
            modal.locals = {
              first_name: identity.first_name,
              last_name: identity.last_name,
              title: identity.title,
              company_name: identity.company_name,
            };

            $timeout(function () {
              $mdDialog.show(modal);
              $cookies.remove(CHECK_IDENTITY_NAMES_COOKIE, {
                domain: domain,
              });
            });
          } else {
            // ensure cookie is removed if dialog is not shown
            $cookies.remove(CHECK_IDENTITY_NAMES_COOKIE, { domain: domain });
          }
        });
      }
    }
  }
})();
