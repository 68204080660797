(function () {
  angular
    .module("akitabox.core.services.document", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.building",
      "akitabox.core.services.env",
      "akitabox.core.services.http",
      "akitabox.core.services.file",
    ])
    .factory("DocumentService", DocumentService);

  /** @ngInject */
  function DocumentService(
    $http,
    $log,
    $q,
    models,
    HttpService,
    BuildingService,
    EnvService,
    FileService,
    TokenService
  ) {
    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildDetailRoute: buildDetailRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Retrieve
      get: get,
      getByOrganization: getByOrganization,
      getAll: getAll,
      getAllByOrganization: getAllByOrganization,
      getById: getById,
      getOcrById: getOcrById,
      getRevisions: getRevisions,
      getRevisionById: getRevisionById,
      count,
      // Update
      rename: rename,
      rotate: rotate,
      unarchive: unarchive,
      archive: archive,
      revert: revert,
      // Delete
      remove: remove,
      // Misc
      createUploader: createUploader,
      download: download,
      uploadAttachment: uploadAttachment,
      downloadFromPurl: downloadFromPurl,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute(buildingId) {
      return (
        BuildingService.buildDetailRoute(buildingId) +
        "/" +
        models.DOCUMENT.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, documentId, revisionId) {
      var base = buildBaseRoute(buildingId);
      // Revision
      if (!angular.isEmpty(revisionId)) {
        return (
          base +
          "/" +
          documentId +
          "/" +
          models.REVISION.ROUTE_PLURAL +
          "/" +
          revisionId
        );
      }
      // Document detail
      return base + "/" + documentId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" + organizationId + "/" + models.DOCUMENT.ROUTE_PLURAL
      );
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getAll(buildingId, params) {
      // Don't fetch deleted documents. Overridable by params (delete_date : $any)
      params = angular.extend({ delete_date: "null" }, params);
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, params);
    }

    function getAllByOrganization(organizationId, params) {
      params = angular.extend({ delete_date: "null" }, params);
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, documentId, params) {
      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.getById(route, documentId, params);
    }

    function getOcrById(buildingId, documentId, params) {
      var route = buildDetailRoute(buildingId, documentId) + "/ocr";
      return HttpService.getById(route, documentId, params);
    }

    /**
     * Gets ALL the revisions for this document, because we don't have the skip and limit functionality here
     *
     * @param buildingId
     * @param documentId
     * @param params
     */
    function getRevisions(buildingId, documentId, params) {
      params = angular.extend({}, params);

      var route =
        buildDetailRoute(buildingId, documentId) +
        "/" +
        models.REVISION.ROUTE_PLURAL;
      return HttpService.get(route, params);
    }

    function getRevisionById(buildingId, documentId, revisionId) {
      var route = buildDetailRoute(buildingId, documentId, revisionId);
      return HttpService.get(route);
    }

    /******************* *
     *   Update
     * ****************** */

    function rename(buildingId, documentId, name) {
      var data = {
        action: "rename",
        name: name,
      };

      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.patch(route, data);
    }

    /**
     * Saves the new rotation for the document without creating a commit
     *
     * @param buildingId
     * @param documentId
     * @param {Number} rotation
     * @return {*}
     */
    function rotate(buildingId, documentId, rotation) {
      var data = {
        action: "rotate",
        rotation: rotation,
      };

      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.patch(route, data);
    }

    function unarchive(buildingId, documentId) {
      var data = { action: "unarchive" };
      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.patch(route, data);
    }

    function archive(buildingId, documentId) {
      var data = { action: "archive" };
      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.patch(route, data);
    }

    function revert(buildingId, documentId, commitId) {
      var data = {
        action: "revert",
        commit: commitId,
      };
      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.patch(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, documentId) {
      var route = buildDetailRoute(buildingId, documentId);
      return HttpService.remove(route);
    }

    /******************* *
     *   Misc
     * ****************** */

    function createUploader(buildingId) {
      var route = buildListRoute(buildingId);
      return FileService.createUploader(route);
    }

    function uploadAttachment(buildingId, file, onProgress) {
      var route = buildListRoute(buildingId);
      return FileService.upload(
        file,
        route,
        { attachment: true },
        onProgress
      ).then(function (response) {
        return response.data;
      });
    }

    function download(buildingId, documentId, revisionId) {
      var route = buildDetailRoute(buildingId, documentId, revisionId) + "/raw";
      return FileService.download(route);
    }

    function downloadFromPurl(document) {
      const { public_url, path } = getDocumentInformation(document);
      return FileService.downloadFromPurl(public_url, path);
    }

    function getDocumentInformation(document) {
      let path = "Untitled";
      const { public_url, document_image } = document;

      if (document_image) {
        // This is a revision, so download that revision instead
        path = document_image.path;
      } else {
        path = document.path;
      }

      return { public_url, path };
    }

    function count(buildingId, params = {}) {
      if (!buildingId) {
        return $q.reject(new Error("DocumentService: buildingId required"));
      }

      const route = buildListRoute(buildingId);
      params = angular.extend({ count: true }, params);

      return HttpService.get(route, params).then(function (response) {
        return response.count;
      });
    }
  }
})();
