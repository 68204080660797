(function () {
  angular
    .module("akitabox.desktop.document.list")
    .controller("DocumentListController", DocumentListController);

  /* @ngInject */
  function DocumentListController(
    // Angular
    $location,
    $q,
    $scope,
    $window,
    // Resolve
    archived,
    building,
    tagCategories,
    // Services
    DocumentService,
    FilterBarManager,
    ManagedModelFieldFilter,
    // Constants
    SUPPORTED_FILE_TYPES
  ) {
    var self = this;

    // Attributes
    self.documents = null;
    self.building = building;
    self.archived = archived;
    self.activeFilters = null;
    self.subtitle = getSubtitle();

    // Functions
    self.changeFilters = changeFilters;
    self.fetchDocs = fetchDocs;
    self.fetchAllDocs = fetchAllDocs;
    self.fetchCountAllDocs = fetchCountAllDocs;
    self.clickTag = clickTag;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watch("vm.archived", setArchived);

      // create a manager
      self.filterBarManager = new FilterBarManager({
        onFilterChange: function () {
          var query = self.filterBarManager.getQuery();
          // update the list
          changeFilters(query);
        },
      });

      var stringEnumOption = function (str) {
        return {
          model: str,
          value: str,
        };
      };

      var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "Name",
        queryField: "name",
      });

      var fileTypeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "File Type",
        queryField: "extension",
        inputType: "typeahead",
        getEnumOptions: function () {
          return SUPPORTED_FILE_TYPES.map(stringEnumOption);
        },
        modelValueToChipText: function (model) {
          return model;
        },
        modelValueToFilterValue: function (model) {
          return model;
        },
        filterValueToModelValue: function (model) {
          return [model];
        },
      });

      self.filterBarManager.addFilterConfiguration(nameConfig);
      self.filterBarManager.addFilterConfiguration(fileTypeConfig);

      // Iterate through the tag categories to create filters for
      // each of them
      tagCategories.forEach(function (tagCategory) {
        var tagCategoryFilter = new ManagedModelFieldFilter(
          self.filterBarManager,
          {
            displayName: tagCategory.cat_alias,
            queryField: "tags_" + tagCategory.cat_name,
            inputType: "typeahead",
            placeholder: "Select a value",
            getEnumOptions: function () {
              // Should return an array of the corresponding tag category values
              // Make sure we show the dropdown values as the value_alias prop
              // and give the filter access to the entire tag category value object
              return tagCategory.values.map(function (tagCategoryValue) {
                return {
                  model: tagCategoryValue,
                  value: tagCategoryValue.value_alias,
                };
              });
            },
            modelValueToFilterValue: function (tagCategoryValue) {
              return tagCategoryValue.value_name;
            },
            filterValueToModelValue: function (tagCategoryValueName) {
              return $q(function (resolve, reject) {
                for (var k = 0; k < tagCategory.values.length; k++) {
                  var tagCategoryValue = tagCategory.values[k];
                  if (tagCategoryValue.value_name === tagCategoryValueName) {
                    return resolve([tagCategoryValue]);
                  }
                }
                return reject(new Error("No value found"));
              });
            },
            modelValueToChipText: function (tagCategoryValue) {
              return tagCategoryValue.value_alias;
            },
          }
        );
        // add filters to manager
        self.filterBarManager.addFilterConfiguration(tagCategoryFilter);
      });

      // apply any filters from the query string
      self.filterInitPromise = self.filterBarManager.applyQuery(
        $location.search()
      );
    }

    function setArchived(newArchived) {
      if (angular.isBoolean(newArchived)) {
        self.archived = newArchived;
      } else if (newArchived) {
        self.archived = newArchived === "true";
      }

      self.subtitle = getSubtitle();
    }

    function getSubtitle() {
      return { name: self.archived ? "Archived Files" : "Active Files" };
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchDocs(skip, limit) {
      var doFetch = function () {
        // Add archived to url if not preset in active filters
        if (
          self.activeFilters &&
          !Object.prototype.hasOwnProperty.call(self.activeFilters, "archived")
        ) {
          $location.search("archived", archived);
        }
        var params = {
          skip: skip,
          limit: limit,
          archived: archived,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return DocumentService.get(building._id, filters).then(function (docs) {
          if (angular.isArray(self.documents)) {
            self.documents = self.documents.concat(docs);
          } else {
            self.documents = docs;
          }
          return docs.length;
        });
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllDocs(limit) {
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          archived: archived,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return DocumentService.getAll(building._id, filters).then(function (
          docs
        ) {
          self.documents = docs;
          return docs.length;
        });
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchCountAllDocs(skip, limit) {
      const doFetch = function () {
        var params = {
          archived: archived,
        };

        const filters = angular.extend({}, params, self.activeFilters);

        return DocumentService.count(building._id, filters);
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.documents = null;
      $scope.$broadcast("list:refresh");
    }

    function clickTag(tag) {
      var tagFilter = {
        key: "tags_" + tag.cat_name,
        value: tag.value_name,
        left: tag.cat_alias,
        right: tag.value_alias,
      };

      $scope.$broadcast("abxFilterBar:addFilter", tagFilter);
    }
  }
})();
