(function () {
  /**
   * @ngdoc component
   * @name abxPinFieldFilters
   *
   * @param {Object} currentFilters - Current pin field filter values for the given
   *     pin type. This object should map pin field IDs to their filter values.
   *     These could be set, but not necessarily applied. In other words, these
   *     are the values that should be displayed in the inputs for each pin
   *     field filter.
   * @param {String} floorId - Floor to limit filter values on. Specifically,
   *     this is required for filtering on rooms, as our room inputs aren't
   *     enabled unless they have a floor. If this binding is omitted, inputs
   *     that rely on floor will not be usable.
   * @param {Function} onChange - To be invoked when any pin field filter input
   *     is changed (blurred) to notify parent of the new pin type filter.
   *     Should be invoked with: $event.filters (new current filters).
   * @param {Object} pinType - Pin type to sub-filter on
   *
   * @description
   * A component for a user to sub-filter on a pin type, by filtering
   * more specifically on its pin fields.
   *
   * Example use case: A maintenance technician wants to see all of the
   * mechanical assets (pin type filter) whose ID includes "AHU" (pin field
   * filter) and whose manufacture date is past 10/25/1995 (pin field filter).
   */
  angular
    .module("akitabox.ui.components.pinFieldFilters")
    .component("abxPinFieldFilters", {
      bindings: {
        currentFilters: "<abxCurrentFilters",
        floorId: "<abxFloorId",
        onChange: "&abxOnChange",
        pinType: "<abxPinType",
        disabled: "<?abxDisabled",
      },
      controller: AbxPinFieldFiltersController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/pin-field-filters/pin-field-filters.component.html",
    });

  function AbxPinFieldFiltersController(
    // Angular
    $q,
    // Services
    BuildingService
  ) {
    var self = this;

    // Attributes
    self.filterablePinFields = [];

    // Functions
    self.buildTemplateUrl = buildTemplateUrl;
    self.getBuildingId = getBuildingId;
    self.handleFilterChange = handleFilterChange;

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.pinType) {
        filterPinFields();
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Gets the template URL for the given input type.
     *
     * @param {String} type - Type of input to display
     */
    function buildTemplateUrl(pinField) {
      var type = pinField.data_type;
      switch (type) {
        case "int":
        case "float":
        case "date":
          type = "range";
          break;
        default:
          type = "default";
      }

      return (
        "app/core/ui/components/pin-field-filters/templates/" +
        type +
        "-filter.html"
      );
    }

    /**
     * Get the current building's id for room inputs.
     *
     * @return {String} The current building's id.
     */
    function getBuildingId() {
      return BuildingService.getCurrent()._id;
    }

    /**
     * Handle filter change events.
     *
     * @param {Object} event - Filter change event
     * @param {*} event.newValue - New value for the filter
     * @param {Boolean} event.invalid - True if new filter value is invalid
     * @param {Object} pinField - Pin field of filter being changed
     */
    function handleFilterChange(event, pinField) {
      var newFilters = angular.copy(self.currentFilters);
      newFilters[pinField._id] = event.newValue;

      var newValue = newFilters[pinField._id];
      var isBooleanNoneOption =
        pinField.data_type === "boolean" && newValue === "";
      if (angular.isEmpty(newValue) && !isBooleanNoneOption) {
        // We don't want to filter with empty values in most cases (they'll
        // match nothing)
        delete newFilters[pinField._id];
      }

      self.onChange({
        $event: { filters: newFilters, invalid: event.invalid },
      });
      return $q.resolve();
    }

    // =================
    // Private Functions
    // =================

    /**
     * Filter out pin fields of the pin type that are not filterable.
     */
    function filterPinFields() {
      if (!self.pinType) {
        self.filterablePinFields = [];
        return;
      }

      var disabledDataTypes = ["document_array", "level", "tag_filter", "tree"];

      self.filterablePinFields = self.pinType.fields.filter(function (
        pinField
      ) {
        var isDisabled = disabledDataTypes.some(function (disabledDataType) {
          return pinField.data_type === disabledDataType;
        });

        return !isDisabled;
      });
    }
  }
})();
