(function () {
  angular
    .module("akitabox.ui.dialogs.autocomplete")
    .controller("AutocompleteDialogController", AutocompleteDialogController);

  /* @ngInject */
  function AutocompleteDialogController(
    // Material
    $mdDialog,
    $element,
    $scope,
    $timeout
  ) {
    var self = this;

    // Constants
    var DEFAULT_SEARCH_DELAY = 500;

    // Attributes
    self.delay = self.delay || DEFAULT_SEARCH_DELAY;
    self.items = self.items || [];
    self.loading = false;
    self.minLength = self.minLength || 0;
    self.saving = false;
    self.searchText = $scope.searchText || "";
    self.showNoResults = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.clear = clear;
    self.selectItem = selectItem;

    init();

    function init() {
      $scope.$watch(
        "dialog.searchText",
        angular.debounce(onSearchTextChange, self.delay, false)
      );
      focusSearchInput();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function onSearchTextChange() {
      $scope.searchText = self.searchText;

      self.loading = true;
      self.showNoResults = false;

      if (
        self.minLength === 0 ||
        (self.searchText && self.searchText.length >= self.minLength)
      ) {
        fetchResults()
          .then(function (results) {
            self.items = results;
          })
          .finally(function () {
            self.loading = false;
            self.showNoResults = !self.items.length;
            focusSearchInput();
          });
      } else {
        self.loading = false;
      }
    }

    function fetchResults() {
      return $timeout(function () {
        // self.itemExpr will either be an array of the fetched items or a promise, which will
        // resolve into those items
        var items = $scope.$eval(self.itemExpr);

        if (!items) {
          // if we can't eval self.itemExpr, fall back onto doing it with the parent scope
          items = $scope.$parent.$eval(self.itemExpr);

          if (!items) {
            items = [];
          }
        }

        var isPromise = Boolean(items.then);

        if (isPromise) {
          return items.then(function (results) {
            return results;
          });
        } else {
          return items;
        }
      });
    }

    function focusSearchInput() {
      var $searchInput = $element.find("input")[0];

      $timeout(function () {
        $searchInput.focus();
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function clear() {
      self.searchText = "";
    }

    function selectItem(item) {
      $mdDialog.hide(item);
    }
  }
})();
