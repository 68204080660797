(function () {
  angular.module("akitabox.core").run(stateBlock);

  /**
   * @ngInject
   */
  function stateBlock(
    // Services
    Router
  ) {
    Router.configureStates(getStates());
  }

  function getStates() {
    return [
      // Admin pages
      {
        state: "legacy",
        config: {
          abstract: true,
          url: "/admin",
        },
      },
      {
        state: "legacy.accounts",
        config: {
          url: "/accounts",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.applications",
        config: {
          url: "/apps",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.account",
        config: {
          url: "/accounts/:accountId",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.projects",
        config: {
          url: "/projects",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.project",
        config: {
          url: "/projects/:accountId/:buildingId",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.buildings",
        config: {
          url: "/buildings",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.building",
        config: {
          url: "/buildings/:buildingId",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.building.settings",
        config: {
          url: "/buildings/:buildingId/settings",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.scripts",
        config: {
          url: "/scripts",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.userActions",
        config: {
          url: "/user_actions",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.apps",
        config: {
          url: "/apps",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "legacy.app",
        config: {
          url: "/apps/:appId",
          resolve: {
            redirect: redirectToAPI,
          },
        },

        // Settings pages
      },
      {
        state: "settings",
        config: {
          url: "/settings",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "settings.details",
        config: {
          url: "/details",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "settings.password",
        config: {
          url: "/password",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "settings.email",
        config: {
          url: "/email",
          resolve: {
            redirect: redirectToAPI,
          },
        },

        // Building Settings Page
      },
      {
        state: "building-settings",
        config: {
          url: "/buildings/:buildingId/settings",
          resolve: {
            redirect: redirectToAPI,
          },
        },
      },
      {
        state: "srpRedirect",
        config: {
          url: "/srp-redirect",
          templateUrl: "app/core/modules/srp-redirect/srp-redirect.html",
          controller: "SrpRedirectController",
          controllerAs: "vm",
          data: {
            pageTitle: "SRP Redirect",
          },
        },
      },
    ];
  }

  /**
   * Function which forwards the url to the api to render
   *
   * @param $location
   * @param RedirectService
   *
   * @ngInject
   */
  function redirectToAPI($location, RedirectService) {
    // Resolve Legacy Urls
    var adminBuildingsPrefix = /^(\/admin\/(projects))/;
    var url = $location.url();
    var prefix = url.match(adminBuildingsPrefix);
    url = url.replace(adminBuildingsPrefix, "");

    RedirectService.findRedirect(url).then(function (_redirect) {
      var route = _redirect || url;
      if (prefix && prefix[0]) route = "/admin" + route;
      RedirectService.redirectToAPI(route);
    });
  }
})();
