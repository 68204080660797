(function () {
  angular.module("akitabox.desktop.inspectionProgram").run(stateBlock);

  /* @ngInject */
  function stateBlock(models, Router) {
    var LIST_QUERY_PARAMS = models.INSPECTION_PROGRAM.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.inspectionProgram",
        config: {
          url:
            "/" +
            models.INSPECTION_PROGRAM.ROUTE_PLURAL +
            "/:inspectionProgramId",
          templateUrl:
            "app/desktop/modules/inspection-program/detail/inspection-program-detail.template.html",
          controller: "InspectionProgramDetailController",
          controllerAs: "vm",
          resolve: {
            inspectionProgram: getInspectionProgram,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.inspectionProgram",
        config: {
          url:
            "/" +
            models.INSPECTION_PROGRAM.ROUTE_PLURAL +
            "/:inspectionProgramId",
          templateUrl:
            "app/desktop/modules/inspection-program/detail/inspection-program-detail.template.html",
          controller: "InspectionProgramDetailController",
          controllerAs: "vm",
          resolve: {
            inspectionProgram: getInspectionProgram,
            building: getBuilding,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.building.inspectionPrograms",
        config: {
          url:
            "/" +
            models.INSPECTION_PROGRAM.ROUTE_PLURAL +
            "?" +
            LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/inspection-program/building-list/building-inspection-program-list.html",
          controller: "BuildingInspectionProgramListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Inspection Programs",
          },
          reloadOnSearch: true,
          resolve: {
            status: getStatus,
            permission: checkInspectionPermissions,
          },
        },
      },
      {
        state: "app.inspectionPrograms",
        config: {
          url:
            "/" +
            models.INSPECTION_PROGRAM.ROUTE_PLURAL +
            "?" +
            LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/inspection-program/organization-list/org-inspection-program-list.html",
          controller: "OrganizationInspectionProgramListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Inspection Programs",
          },
          reloadOnSearch: true,
          resolve: {
            status: getStatus,
            permission: checkInspectionPermissions,
          },
        },
      },
    ]);
  }

  /** @ngInject */
  function getInspectionProgram(
    $state,
    $stateParams,
    InspectionProgramService,
    organization
  ) {
    if (organization.show_inspections) {
      return InspectionProgramService.getById(
        organization._id,
        $stateParams.inspectionProgramId
      );
    }

    return $state.go("root.404");
  }

  /** @ngInject */
  function getBuilding() {
    /**
     * Unless you're accessing the detail page from a building list route, we
     * don't want to resolve a default building for an inspection program
     */
    return null;
  }

  /** @ngInject */
  function getStatus($stateParams, INSPECTION_PROGRAM_STATUS_ACTIVE) {
    return $stateParams.status || INSPECTION_PROGRAM_STATUS_ACTIVE;
  }

  /** @ngInject */
  function checkInspectionPermissions($state, organization, user, UserService) {
    var permissions = UserService.getPermissions();

    if (permissions.inspection_program.read && organization.show_inspections) {
      return true;
    }

    return $state.go("root.404");
  }

  /** @ngInject */
  function redirect($state, $stateParams, RedirectService, inspectionProgram) {
    // 404 if IP is not found
    if (!inspectionProgram) {
      return $state.go("root.404");
    }
    var destination = RedirectService.getDestination();
    // destination ends with "/inspection_programs/:inspectionProgramId" and possibly
    // a trailing slash
    var ipOverview = destination.match(/inspection_programs\/[a-zA-Z0-9]+\/?$/);
    if (ipOverview) {
      // redirect to /overview, replace history last record
      return $state.go(this.self.name + ".overview", $stateParams);
    }
  }
})();
