(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.documentTable
   */
  angular
    .module("akitabox.desktop.directives.documentListField", [
      "akitabox.core.constants",
      "akitabox.core.services.asset",
      "akitabox.core.services.document",
      "akitabox.core.services.pinValue",
      "akitabox.core.services.room",
      "akitabox.core.services.tag",
      "akitabox.core.services.tagCategory",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.document.editDocumentArray",
      "akitabox.ui.dialogs.document.editTagFilter",
      "akitabox.ui.dialogs.document.upload",
      "angular.filter",
    ])
    .controller(
      "AbxDocumentListFieldController",
      AbxDocumentListFieldController
    )
    .directive("abxDocumentListField", AbxDocumentListFieldDirective);

  /**
   * ngdoc directive
   * @module akitabox.desktop.directives.documentListField
   * @name AbxDocumentListFieldDirective
   * @description
   * `<abx-document-list-field>` is a field for showing a table of documents
   * @param
   *   `abx-initial-show-limit`  The limit of documents shown on load
   *   `abx-show-increment`      The number of documents appended to the shown list when "Show
   *                             more" button is clicked
   *
   * @ngInject
   */
  function AbxDocumentListFieldDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/document-list-field/document-list-field.html",
      controller: "AbxDocumentListFieldController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        model: "=abxModel",
        onChange: "&abxOnChange",
        parentModel: "<abxParentModel",
        building: "<abxBuilding",
        isAsset: "<abxIsAsset",
        isRoom: "<abxIsRoom",
        documents: "=abxDocuments",
        initialShowLimit: "<abxInitialShowLimit",
        showIncrement: "<abxShowIncrement",
        isEditable: "<abxEditable",
        isUploadable: "<abxUploadable",
        label: "@",
        type: "@",
      },
    };

    function postLink($scope, $elem, attrs, vm) {
      vm.disabled = angular.isDefined(attrs.disabled);
      vm.readonly = angular.isDefined(attrs.readonly);
      vm.required = angular.isDefined(attrs.required);
      vm.showActions = angular.isDefined(attrs.showActions)
        ? attrs.showActions !== "false"
        : true;
      vm.editable = !vm.readonly && !vm.disabled;

      evaluateShowActions();

      // Disabled
      $scope.$watch(function () {
        var disabled = $elem[0].attributes.disabled;
        return disabled ? disabled.value : null;
      }, setDisabled);

      /**
       * Determine whether to show action buttons
       */
      function evaluateShowActions() {
        vm.showActions =
          (!angular.isDefined(attrs.showActions) ||
            attrs.showActions === "true") &&
          vm.editable;
      }

      function setDisabled(disabled) {
        vm.disabled = Boolean(disabled);
        vm.editable = !vm.readonly && !vm.disabled;
        evaluateShowActions();
      }
    }
  }

  /**
   * @ngInject
   */
  function AbxDocumentListFieldController(
    // Angular
    $element,
    $filter,
    $log,
    $scope,
    $timeout,
    $q,
    // Services
    AssetService,
    DocumentService,
    FilterService,
    PinValueService,
    RoomService,
    TagService,
    TagCategoryService,
    ToastService,
    UserService,
    // Dialogs
    EditDocumentArrayDialog,
    EditTagFilterDialog,
    UploadDocumentDialog
  ) {
    var self = this;
    var _permissions = UserService.getPermissions();

    // Attributes
    self.disabled = false;
    self.editable = true;
    self.showButtons = self.isAsset
      ? _permissions.asset.add_attachment
      : _permissions.room.add_attachment;
    self.loadingMoreItems = false;
    self.shownDocuments = [];
    self.initialShowLimit = self.initialShowLimit || 10;
    self.showIncrement = self.showIncrement || self.initialShowLimit;
    self.moreToShow = false;
    self.loadingFullList = true;
    self.showActions = true;

    // Functions
    self.edit = edit;
    self.upload = upload;
    self.showMoreDocuments = showMoreDocuments;

    init();

    function init() {
      // Attribute validation
      var invalid = false;
      if (
        self.initialShowLimit <= 0 ||
        !angular.isNumber(self.initialShowLimit)
      ) {
        $log.error(
          "<abx-document-list-field>: initial-show-limit must be a positive number"
        );
        invalid = true;
      }
      if (self.showIncrement <= 0 || !angular.isNumber(self.showIncrement)) {
        $log.error(
          "<abx-document-list-field>: show-increment must be a positive number"
        );
        invalid = true;
      }
      if ((self.isAsset && self.isRoom) || (!self.isAsset && !self.isRoom)) {
        $log.error(
          "<abx-document-list-field>: Only one of is-asset and is-room " +
            "can be true"
        );
      }
      if (invalid) return;

      // Tag filter and document_array are resolved asynchronously (externally), so we have to
      // wait for that to resolve (just once)
      if (self.type === "tag_filter" || self.type === "document_array") {
        var removeWatcher = $scope.$watch("vm.documents", function (newValue) {
          if (angular.isArray(newValue)) {
            removeWatcher();
            setDocuments(newValue);
          }
        });
      } else {
        setDocuments(self.documents);
      }
    }

    /**
     * Filters the directive's document list. Specifically, it filters out documents that have been
     * deleted externally, but persist in the current entity's pin value list.
     */
    function filterDocuments() {
      self.documents = $filter("filter")(self.documents, { deleted: "!true" });
    }

    /**
     * Groups documents together by status (active first, then archived), then alphabetizes
     * each of the respective groups.
     */
    function orderDocuments() {
      self.documents = $filter("orderBy")(self.documents, ["archived", "path"]);
    }

    /**
     * Shows more documents in the list
     *
     * @param {Number}  numDocsToAppend  The number of hidden docs to add to the shown list
     */
    function showMoreDocuments(numDocsToAppend) {
      self.loadingMoreItems = true;
      $timeout(function () {
        // Show more
        // Use a .5 second delay to imply a fetch (even though we have them all
        // available immediately)
        self.shownDocuments = self.documents.slice(
          0,
          self.shownDocuments.length + numDocsToAppend
        );

        if (self.documents.length === self.shownDocuments.length) {
          // No more to show
          self.moreToShow = false;
        } else {
          self.moreToShow = true;
        }

        self.loadingMoreItems = false;
      }, 500);
    }

    /**
     * Sets the list of documents to the documents given. Handles showing the new list, how many in
     * the new list to show, and whether there is now more documents to show. Filters and groups
     * the documents as well.
     *
     * The amount of documents shown will be equal to the amount that was shown before the change was
     * made, rounded up to the nearest showIncrement interval
     */
    function setDocuments(documents) {
      if (!documents) {
        documents = [];
      }

      // Set loading full list item's height to current list height to avoid jitter
      var $listBody = $element.find(".document-list-body");
      if ($listBody.length) {
        var listHeight = $element.find(".document-list-body").innerHeight();
        $element.find(".loading-full-list-container").innerHeight(listHeight);
      }

      // Fake a re-fetch (helps avoid flicker)
      self.loadingFullList = true;
      self.documents = documents;
      filterDocuments();
      orderDocuments();

      var numDocumentsShown = self.shownDocuments.length;
      var upperBound = 0;
      if (numDocumentsShown < self.initialShowLimit) {
        upperBound = self.initialShowLimit;
      } else if (numDocumentsShown % self.initialShowLimit === 0) {
        upperBound = numDocumentsShown;
      } else {
        upperBound =
          numDocumentsShown +
          (self.showIncrement - (numDocumentsShown % self.initialShowLimit));
      }

      self.shownDocuments = self.documents.slice(0, upperBound);

      if (self.documents.length === self.shownDocuments.length) {
        // No more to show
        self.moreToShow = false;
      } else {
        self.moreToShow = true;
      }

      // Fake re-fetch is over
      $timeout(function () {
        self.loadingFullList = false;
      }, 500);
    }

    function edit() {
      var locals = {
        title: "Edit " + self.label,
        building: self.building,
        pin: { _id: self.parentModel },
        pinValue:
          self.type === "document_array"
            ? angular.copy(self.model)
            : self.model,
      };

      if (self.type === "tag_filter") {
        if (self.isAsset) {
          locals.isAsset = true;
        } else if (self.isRoom) {
          locals.isRoom = true;
        }

        return TagCategoryService.getAll(self.building._id)
          .then(function (tagCategories) {
            locals.filters = FilterService.getTagConfig(tagCategories);
            return EditTagFilterDialog.show({ locals: locals });
          })
          .then(function (updatedModel) {
            self.model = updatedModel;
            self.onChange({ value: self.model, save: false });
            // Use new filters to reset the documents
            if (angular.isEmpty(updatedModel.value)) {
              setDocuments([]);
            } else {
              var formattedFilters = {};

              for (var i = 0; i < updatedModel.value.length; i++) {
                var filter = updatedModel.value[i];

                formattedFilters["tags_" + filter.cat_name] = filter.value_name;
              }

              var params = angular.extend({}, formattedFilters);

              DocumentService.getAll(self.building._id, params).then(function (
                documents
              ) {
                setDocuments(documents);
              });
            }
          });
      } else if (self.type === "document_array") {
        locals.pin = { _id: self.parentModel };
        locals.selectedDocuments = angular.copy(self.documents);

        if (self.isAsset) {
          locals.isAsset = true;
        } else if (self.isRoom) {
          locals.isRoom = true;
        }

        return TagCategoryService.getAll(self.building._id)
          .then(function (tagCategories) {
            locals.filters = FilterService.getDocumentConfig(tagCategories);
            return EditDocumentArrayDialog.show({ locals: locals });
          })
          .then(function (selectedDocuments) {
            // the model requires the value to be a list of IDs, not populated documents
            var selectedDocumentIds = selectedDocuments.map(function (
              document
            ) {
              return document._id;
            });
            self.model.value = selectedDocumentIds;

            setDocuments(selectedDocuments);
            self.onChange({ value: self.model, save: false });
          })
          .catch(ToastService.showError);
      }
    }

    function upload() {
      var locals = {
        building: self.building,
        allowMultiple: true,
        revision: self.label !== "Photos",
      };

      if (self.type === "tag_filter") {
        if (!self.model.value.length) {
          return ToastService.showSimple(
            "You must assign TAGS to this field first via the Edit button before uploading."
          );
        }

        return UploadDocumentDialog.show({ locals: locals })
          .then(function (documents) {
            var promises = [];
            var promise;

            for (var i = 0; i < self.model.value.length; i++) {
              var tag = self.model.value[i];
              for (var j = 0; j < documents.length; j++) {
                promise = TagService.create(
                  self.building._id,
                  documents[j]._id,
                  tag
                );
              }
              promises.push(promise);
            }

            return $q.all(promises).then(function (newTags) {
              for (var k = 0; k < documents.length; k++) {
                documents[k].tags = newTags;
                self.documents.unshift(documents[k]);
                self.shownDocuments.unshift(documents[k]);
                // Increment so initialShowAmount since we're manipulating the offset by unshifting
                // regardless of the initial show limit
                self.initialShowLimit += 1;
              }

              self.onChange({ value: self.model, save: false });
            });
          })
          .catch(ToastService.showError);
      } else if (self.type === "document_array") {
        return UploadDocumentDialog.show({ locals: locals })
          .then(function (documents) {
            if (!documents || (documents && !documents.length)) {
              return $q.reject("Error uploading document!");
            }
            for (var i = 0; i < documents.length; i++) {
              if (
                Object.prototype.hasOwnProperty.call(self.model.value, "value")
              ) {
                self.model.value.value.push(document[i]._id);
              } else {
                self.model.value.push(documents[i]._id);
              }
            }

            var updatePromise;
            var data = {
              value: self.model.value,
            };

            if (self.isAsset) {
              updatePromise = AssetService.updateValue(
                self.building._id,
                self.parentModel,
                self.model._id,
                data
              );
            } else if (self.isRoom) {
              updatePromise = RoomService.updateValue(
                self.building._id,
                self.parentModel,
                self.model._id,
                data
              );
            } else {
              updatePromise = PinValueService.update(
                self.building.cre_account,
                self.building._id,
                self.parentModel,
                self.model._id,
                data
              );
            }

            return updatePromise
              .then(function (updatedModel) {
                // we still need the pinField populated for edit function, assume it's the same
                // pinField as before
                var pinField = angular.copy(self.model.pinField);
                self.model = updatedModel;
                self.model.pinField = pinField;

                self.onChange({ value: self.model, save: false });

                if (updatedModel.value && updatedModel.value.length) {
                  var params = { _id: "$in," + updatedModel.value.join(",") };

                  return DocumentService.getAll(
                    updatedModel.building,
                    params
                  ).then(function (documents) {
                    setDocuments(documents);
                  });
                }
              })
              .catch(function (err) {
                // remove the document(s) we just added, because there was an error adding it/them
                var indexToStart =
                  self.model.value.length - self.documents.length;
                var quantityToRemove = self.documents.length;

                self.model.value.splice(indexToStart, quantityToRemove);
                return $q.reject(err);
              });
          })
          .catch(ToastService.showError);
      }
    }
  }
})();
