(function () {
  angular.module("akitabox.core.utils").factory("vendor", vendorFactory);

  /**
   * @ngdoc factory
   * @module akitabox.core.utils
   * @name vendorFactory
   * @description
   * Service for detecting and obtaining vendor properties
   */
  /* @ngInject */
  function vendorFactory($window, $document) {
    var service = {
      prefix: getVendorPrefix(),
      getProperty: getProperty,
    };

    var prefixTestElement = $document[0].createElement("div");

    return service;

    /**
     * Retrieve vendor prefixes
     *
     * @return {Object} Map of vendor prefixes by scope
     */
    function getVendorPrefix() {
      var styles = $window.getComputedStyle($document[0].documentElement, "");
      var prefix = (Array.prototype.slice
        .call(styles)
        .join("")
        .match(/-(moz|webkit|ms)-/) ||
        (styles.OLink === "" && ["", "o"]))[1];
      var dom = "WebKit|Moz|MS|O".match(new RegExp("(" + prefix + ")", "i"))[1];
      return {
        DOM: dom,
        CSS: "-" + prefix + "-",
        JS: prefix[0].toUpperCase() + prefix.substring(1),
      };
    }

    /**
     * Get vendor prefixed style property
     *
     * @param  {String} name Property name
     * @return {String}      Vendor property name
     */
    function getProperty(name) {
      var prefix = service.prefix.CSS + name;
      var lowercase = toCamelCase(prefix);
      var uppercase =
        lowercase.charAt(0).toUpperCase() + lowercase.substring(1);
      if (hasStyleProperty(name)) return name;
      if (hasStyleProperty(prefix)) return prefix;
      if (hasStyleProperty(uppercase)) return uppercase;
      if (hasStyleProperty(lowercase)) return lowercase;
      return name;
    }

    /**
     * Verify an element has a particular style property
     *
     * @param  {String}  property Style property
     * @return {Boolean}          True if valid, false if not
     */
    function hasStyleProperty(property) {
      angular.isDefined(prefixTestElement.style[property]);
    }

    /**
     * Convert a hyphen separated string to camel case
     *
     * @param  {String} input Input string
     * @return {String}       Camel case string
     */
    function toCamelCase(input) {
      return input.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
    }
  }
})();
