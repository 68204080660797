(function () {
  angular
    .module("akitabox.desktop.request.detail")
    .controller("RequestDetailController", RequestDetailController);

  /** @ngInject */
  function RequestDetailController(
    // Constants
    models,
    // Angular
    $scope,
    $state,
    $stateParams,
    // Libraries
    moment,
    // Services
    Router,
    BuildingService,
    EnvService,
    RequestService,
    ToastService,
    TradeService,
    UserService,
    WorkOrderService,
    // Dialogs
    CreateWorkOrderDialog,
    DeleteRequestDialog,
    DenyRequestDialog,
    ReopenRequestDialog,
    // Resolves
    building,
    request
  ) {
    var self = this;

    // Attributes
    self.request = request;
    self.building = building;
    self.subtitle = {
      name: "Service Requests",
    };
    self.showTrade = self.building ? self.building.show_trades : false;
    self.requireTrade = self.building ? self.building.require_trades : false;
    self.showType = self.building.show_issue_types;
    self.requireType = self.building.require_issue_types;
    self.defaultTrade = null;
    self.newWorkOrder = null;
    self.loadingCreateWOButton = true;

    var user = UserService.getCurrent();
    var permissions = UserService.getPermissions();
    var serviceRequestListRoute = getServiceRequestListRoute();
    self.breadcrumbs = getBreadcrumbs();
    self.openCreateWorkOrderDialog = openCreateWorkOrderDialog;
    self.createWorkOrder = createWorkOrder;
    self.denyServiceRequest = denyServiceRequest;
    self.deleteServiceRequest = deleteServiceRequest;
    self.reopenServiceRequest = reopenServiceRequest;
    self.permissions = getPermissions();
    self.getDefaultTrade = getDefaultTrade;
    self.tabs = getTabs();

    // Run
    init();

    function init() {
      $scope.$watchCollection("vm.request", function () {
        self.tabs = getTabs();
        getDefaultTrade();
      });
    }

    function getTabs() {
      var overview = {
        title: "Overview",
        state: "app.request.overview",
      };
      var details = { title: "Details", state: "app.request.details" };
      var workOrder = {
        title: "Work Order",
        state: "app.request.workOrder",
      };
      var floor = { title: "Floor", state: "app.request.floor" };
      var room = { title: "Room", state: "app.request.room" };
      var asset = { title: "Asset", state: "app.request.asset" };

      var tabs = [overview, details];

      if (request.task) {
        var identityId = user.identity._id;
        var assignees = self.request.task.assignees.map(function (assignee) {
          if (assignee._id) return assignee._id;
          return assignee;
        });
        if (permissions.task.read_all) {
          tabs.push(workOrder);
        } else if (assignees.indexOf(identityId) > -1) {
          tabs.push(workOrder);
        }
      }

      if (request.level) tabs.push(floor);
      if (request.room) tabs.push(room);
      if (request.asset) tabs.push(asset);

      return tabs;
    }

    function getBreadcrumbs() {
      return [
        {
          href: serviceRequestListRoute,
          title: "Service Requests",
          icon: "announcement",
        },
      ];
    }

    function getDefaultTrade() {
      if (
        !self.showType ||
        !self.showTrade ||
        !self.request.issue_type ||
        !self.request.issue_type.trade
      ) {
        self.loadingCreateWOButton = false;
        return;
      }
      self.loadingCreateWOButton = true;
      TradeService.getById(self.building._id, self.request.issue_type.trade)
        .then(function (trade) {
          self.defaultTrade = trade;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loadingCreateWOButton = false;
        });
    }

    function getServiceRequestListRoute() {
      var currentBuilding = BuildingService.getCurrent();
      var buildingUriSegment = currentBuilding ? currentBuilding.uri : "";
      return (
        EnvService.getCurrentBaseUrl() +
        buildingUriSegment +
        "/" +
        models.SERVICE_REQUEST.ROUTE_PLURAL
      );
    }

    function updateRequest(newRequest) {
      if (newRequest) {
        self.request = angular.copy(newRequest, self.request);
        init();
        // the user's permissions haven't changed, but request's status has
        self.permissions = getPermissions();
      }
    }

    function openCreateWorkOrderDialog() {
      var locals = {
        building: self.building,
        request: self.request,
      };

      CreateWorkOrderDialog.show({ locals: locals })
        .then(function () {
          return RequestService.getById(building._id, self.request._id);
        })
        .then(updateRequest)
        .catch(ToastService.showError);
    }

    function createWorkOrder() {
      self.loadingCreateWOButton = true;
      WorkOrderService.createFromRequest(
        self.building,
        self.request,
        self.defaultTrade
      )
        .then(function (workOrder) {
          self.newWorkOrder = workOrder;
          ToastService.complex()
            .text("Successfully created work order")
            .action("View", goToNewWorkOrder)
            .show();
        })
        .then(function () {
          return RequestService.getById(building._id, self.request._id);
        })
        .then(updateRequest)
        .catch(ToastService.showError)
        .finally(function () {
          self.loadingCreateWOButton = false;
        });
    }

    /**
     * Go to the newly created work order's detail page
     */
    function goToNewWorkOrder() {
      var stateParams = {
        buildingId: $stateParams.buildingId ? self.building._id : null,
        workOrderId: self.newWorkOrder._id,
      };
      Router.go("app.workOrder", stateParams);
    }

    function denyServiceRequest() {
      var locals = {
        requests: [self.request],
      };

      DenyRequestDialog.show({ locals: locals })
        .then(function () {
          return RequestService.getById(building._id, self.request._id);
        })
        .then(updateRequest)
        .catch(ToastService.showError);
    }

    function deleteServiceRequest() {
      var locals = {
        requests: [self.request],
      };

      return DeleteRequestDialog.show({ locals: locals })
        .then(function () {
          $state.go(
            "app.building.requests",
            {
              buildingId: self.building._id,
            },
            {
              reload: true,
            }
          );
        })
        .catch(ToastService.showError);
    }

    function reopenServiceRequest() {
      var locals = {
        requests: [self.request],
      };

      ReopenRequestDialog.show({ locals: locals })
        .then(function () {
          return RequestService.getById(building._id, self.request._id);
        })
        .then(updateRequest)
        .catch(ToastService.showError);
    }

    function getPermissions() {
      var isOpen = self.request.is_open;
      var isDenied = self.request.is_denied;
      var isCompleted = self.request.is_completed;
      var canViewWorkOrder = false;
      if (permissions.task.read_all) {
        canViewWorkOrder = true;
      } else if (self.request.task) {
        var identityId = user.identity._id;
        var assignees = self.request.task.assignees;
        canViewWorkOrder = assignees.indexOf(identityId) > -1;
      }
      return {
        canCreateWorkOrder:
          permissions.task.create && !isOpen && !isDenied && !isCompleted,
        canViewWorkOrder: canViewWorkOrder,
        canDeny:
          permissions.request.deny && !isDenied && !isOpen && !isCompleted,
        canDelete: permissions.request.remove && isDenied,
        canReopen: permissions.request.reopen && isDenied,
      };
    }
  }
})();
