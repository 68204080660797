(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.bldgThumbnail
   */
  angular
    .module("akitabox.ui.directives.bldgThumbnail", [
      "akitabox.core.constants",
      "akitabox.core.services.document",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.core.utils",
    ])
    .directive("abxBldgThumbnail", AbxBldgThumbnailDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.bldgThumbnail
   * @name AbxBldgThumbnailDirective
   * @description
   * `<abx-bldg-thumbnail>` is a directive that shows an editable image
   *
   * @ngInject
   */
  function AbxBldgThumbnailDirective(
    $log,
    $q,
    $timeout,
    MINIMUM_RENDER_DELAY,
    DocumentService,
    DomService,
    ToastService,
    UserService
  ) {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/bldg-thumbnail/bldg-thumbnail.directive.html",
      link: postLink,
      scope: {
        building: "<abxBuilding",
        size: "<?abxSize",
        fit: "<?abxFit",
        onClick: "&?abxOnClick",
      },
    };

    function postLink($scope, $elem) {
      if (!$scope.building) {
        return $log.error("abx-building is required");
      }

      var permissions = UserService.getPermissions();

      $scope.loading = true;
      $scope.canUpdate = permissions.is_administrator;

      var DEFAULT_SIZE = 180;
      var IMAGE_PADDING = 4; // allows space for box-shadow

      $scope.thumbnailUrl = null;

      if (!$scope.size) $scope.size = DEFAULT_SIZE;

      var thumbnailImage = null;

      $scope.dimensions = {
        width: $scope.size - IMAGE_PADDING + "px",
        height: $scope.size - IMAGE_PADDING + "px",
      };
      $scope.dimensions["min-width"] = $scope.dimensions.width;
      $scope.dimensions["min-height"] = $scope.dimensions.height;

      var WIDTH_PX = $scope.width + "px";
      var HEIGHT_PX = $scope.height + "px";

      $elem.css({
        width: WIDTH_PX,
        height: HEIGHT_PX,
        "min-width": WIDTH_PX,
        "max-width": WIDTH_PX,
        "min-height": HEIGHT_PX,
        "max-height": HEIGHT_PX,
        display: "flex",
      });

      $timeout(function () {
        if ($scope.building.thumbnail) {
          getImage($scope.building.thumbnail);
        } else {
          $scope.loading = false;
        }
      });

      $scope.$watch("fit", function (fit) {
        updateImage(thumbnailImage, fit);
      });

      $scope.$watch("building.thumbnail", function (documentId, oldDocumentId) {
        if ($scope.loading && documentId === oldDocumentId) return;
        if (!angular.isString(documentId) || !documentId.length) {
          $scope.thumbnailUrl = null;
          return;
        }
        getImage(documentId);
      });

      function updateImage(image, fit) {
        if (image) {
          var $img = $elem.find("img");
          $img.attr("src", image.src);
          var size = $scope.size - IMAGE_PADDING;
          var styles = DomService.getThumbnailStyles(image, size, fit);
          $img.css(styles);
        }
      }

      function getImage(documentId) {
        $scope.loading = true;
        DocumentService.getById($scope.building._id, documentId)
          .then(function (document) {
            $scope.document = document;
            $scope.thumbnailUrl = document.public_thumbnail_url_medium;
            return loadImage($scope.thumbnailUrl);
          })
          .then(function (image) {
            thumbnailImage = image;
            updateImage(image, $scope.fit);
          })
          .then(function () {
            return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
          })
          .catch(ToastService.showError)
          .finally(function () {
            $scope.loading = false;
          });
      }
    }

    function loadImage(url) {
      return $q(function (resolve, reject) {
        var image = new Image();

        image.onload = function () {
          return resolve(this);
        };
        image.onerror = function (err) {
          return reject(err);
        };
        image.src = url;
      });
    }
  }
})();
