(function () {
  angular.module("akitabox.desktop.schedule", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.ui.dialogs.schedule",
    "akitabox.desktop.schedule.detail",
    "akitabox.desktop.schedule.list",
    "akitabox.desktop.schedule.organizationList",
  ]);
})();
