(function () {
  /**
   * @ngdoc component
   * @name abxStaticFloorPlan
   *
   * @description
   * <abx-static-floor-plan> displays a floor plan with pins
   */
  angular
    .module("akitabox.ui.components.staticFloorPlan", [
      "akitabox.core.services.document",
      "akitabox.core.services.env",
      "akitabox.core.toast",
      "akitabox.core.utils",
    ])
    .component("abxStaticFloorPlan", {
      bindings: {
        floor: "<abxFloor",
        page: "<?abxPage",
        pins: "<?abxPins",
        pinTypes: "<?abxPinTypes",
        pinColors: "<?abxPinColors",
        pinSize: "<?abxPinSize",
        showPinTypeIcons: "<?abxShowPinTypeIcons",
      },
      controller: AbxStaticFloorPlan,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/static-floor-plan/static-floor-plan.component.html",
    });

  /* @ngInject */
  function AbxStaticFloorPlan(
    $element,
    $timeout,
    $window,
    $q,
    $log,
    $scope,
    DocumentService,
    EnvService,
    MathService,
    FeatureFlagService,
    ToastService
  ) {
    var self = this;

    var debouncedOnResize = angular.debounce(onResize, 250);

    // Attributes
    self.loading = true;
    self.document = null;
    self.image = null;

    self.page = angular.isDefined(self.page) ? self.page : 1;
    self.showPinTypeIcons = angular.isDefined(self.showPinTypeIcons)
      ? self.showPinTypeIcons
      : true;
    self.pinSize = angular.isDefined(self.pinSize) ? self.pinSize : 24;

    self.pinIcons = [];
    self.dimensions = {};

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$postLink = function () {
      self.dimensions = getDimensions();
      angular.element($window).on("resize", debouncedOnResize);
      $scope.$watch(
        function () {
          var parentElement = $element[0].parentElement;
          return {
            width: parentElement.clientWidth,
            height: parentElement.clientHeight,
          };
        },
        debouncedOnResize,
        true
      );
    };

    self.$onChanges = function (changes) {
      if (changes.floor && self.floor) {
        loadFloorPlan()
          .then(function () {
            if (self.pins) {
              if (self.pinTypes && self.pinColors) {
                /**
                 * This timeout is a band-aid fix for safari-based browsers because it reports its resources
                 * as "loaded" *before* they actually render on the screen. This screws up `loadImage(url)` below.
                 * To properly calculate where the pins go we need the plan rendered, and we
                 * don't have any easy or reliable way to wait until render so for now we just wait an arbitrary
                 * amount of time and hope it's enough. Another *potential* solution is commented out below.
                 * It only seemed to work about 70% of the time though, presumably the watched variable changes too fast
                 * to trigger a refiring of `placePins()`
                 */
                // $scope.$watch(function() {
                //   var $container = angular.element(
                //     $element[0].querySelector(".abx-static-floor-plan__img-container")
                //   );
                //   var containerBounds = $container[0].getBoundingClientRect();
                //   return containerBounds.width;
                // }, placePins);
                $timeout(placePins, 1250);
                return;
              }
              $log.info(
                "<abx-static-floor-plan>: missing abx-pin-types or abx-pin-colors"
              );
            }
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.loading = isLoading();
          });
      }
      if (changes.pinTypes && self.pinTypes) {
        // Create a map of pin types by _id for constant look-up time
        self.pinTypes = self.pinTypes.reduce(function (map, pinType) {
          map[pinType._id] = pinType;
          return map;
        }, {});
      }
      self.loading = isLoading();
    };

    // ------------------------
    //   Private Functions
    // ------------------------

    function isLoading() {
      if (self.pins) {
        return !(self.pinTypes && self.image);
      }
      return !self.image;
    }

    function onResize() {
      var newDimensions = getDimensions();
      if (!angular.equals(self.dimensions, newDimensions)) {
        self.dimensions = newDimensions;
        if (self.image && self.pins && self.pinTypes) {
          placePins();
        }
      }
    }

    function loadFloorPlan() {
      if (!self.floor.document) {
        return $q.reject("No floor plan found");
      }
      var buildingId = self.floor.building._id || self.floor.building;
      var documentId = self.floor.document._id || self.floor.document;
      return DocumentService.getById(buildingId, documentId)
        .then(function (document) {
          self.document = document;
          var imageUrl = document.public_thumbnail_url_display;
          return loadImage(imageUrl);
        })
        .then(function (image) {
          self.image = image;
          $element.find("img").attr("src", image.src);
        });
    }

    function loadImage(url) {
      return $q(function (resolve, reject) {
        var image = new Image();
        image.onload = function () {
          return resolve(this);
        };
        image.onerror = function (err) {
          return reject(err);
        };
        image.src = url;
      });
    }

    function getDimensions() {
      var element = $element[0];
      return {
        width: element.clientWidth,
        height: element.clientHeight,
      };
    }

    function placePins() {
      removePins();
      var $container = angular.element(
        $element[0].querySelector(".abx-static-floor-plan__img-container")
      );
      var containerBounds = $container[0].getBoundingClientRect();
      for (var i = 0; i < self.pins.length; ++i) {
        var pin = self.pins[i];
        if (!pin.percentX || !pin.percentY) continue;
        var pinType = self.pinTypes[pin.pinType];
        var pinColor = self.pinColors[pin._id];
        // Create pin element
        var $pin = $window.document.createElement("div");
        $pin.classList.add("abx-static-floor-plan__pin", "fa", "fa-stack");
        angular.element($pin).css({
          width: self.pinSize + "px",
          height: self.pinSize + "px",
          "line-height": self.pinSize + "px",
        });
        // Add pin type icon
        if (self.showPinTypeIcons) {
          var $pinIcon = $window.document.createElement("i");
          $pinIcon.classList.add(
            "pin-icon",
            "fa-stack-1x",
            "fa-inverse",
            pinType.icon
          );
          $pin.append($pinIcon);
        }
        // Add pin
        $container.append($pin);
        self.pinIcons.push($pin);
        // Determine pin position
        var radius = $pin.getBoundingClientRect().height / 2;
        var yOrigin =
          self.image.height < containerBounds.height
            ? (containerBounds.height - self.image.height) / 2 - radius
            : -radius;
        var xOrigin =
          self.image.width < containerBounds.width
            ? (containerBounds.width - self.image.width) / 2 - radius
            : -radius;
        var rotatedPercentages = MathService.rotateAroundPoint(
          0.5,
          0.5,
          pin.percentX,
          pin.percentY,
          360 - self.document.rotation
        );
        var xDelta = containerBounds.width * rotatedPercentages.x;
        var yDelta = containerBounds.height * rotatedPercentages.y;
        // Position pin
        angular.element($pin).css({
          left: xOrigin + xDelta + "px",
          top: yOrigin + yDelta + "px",
          background: pinColor,
        });
      }
    }

    function removePins() {
      if (self.pinIcons) {
        for (var i = 0; i < self.pinIcons.length; ++i) {
          self.pinIcons[i].remove();
        }
      }
      self.pinIcons = [];
    }
  }
})();
