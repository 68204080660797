(function () {
  angular.module("akitabox.desktop.job").run(states);

  /* @ngInject */
  function states(models, Router) {
    Router.configureStates([
      {
        state: "app.exports",
        config: {
          url: "/exports",
          templateUrl: "app/desktop/modules/job/export-list/export-list.html",
          controller: "ExportListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Exports",
          },
          reloadOnSearch: true,
        },
      },
      {
        state: "app.building.exports",
        config: {
          url: "/exports",
          templateUrl: "app/desktop/modules/job/export-list/export-list.html",
          controller: "ExportListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Exports",
          },
        },
      },
    ]);
  }
})();
