(function () {
  /**
   * @ngdoc component
   * @name abxLocationButtons
   *
   * @description
   * <abx-location-buttons> displays location information for a model
   */
  angular
    .module("akitabox.ui.components.locationButtons", [
      "akitabox.core.services.flag",
      "akitabox.core.services.helpers",
    ])
    .component("abxLocationButtons", {
      bindings: {
        model: "<abxModel",
        loading: "<abxLoading",
        building: "<abxBuilding",
        floor: "<abxFloor",
        room: "<abxRoom",
        asset: "<abxAsset",
      },
      controller: AbxLocationButtons,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/location-buttons/location-buttons.component.html",
    });

  /* @ngInject */
  function AbxLocationButtons(ServiceHelpers) {
    var self = this;

    // Attributes
    self.empty = false;
    self.roomDisplayName = self.room ? self.room.display_name : null;
    // Flag to show the decommissioned icon when a room/asset has become decommissioned
    self.decommissioned =
      (!!self.room && self.room.is_decommissioned) ||
      (!!self.asset && self.asset.is_decommissioned);

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onChanges = function (changes) {
      if ((changes.model || changes.loading) && self.model) {
        self.empty = isEmpty();
      }
      if (!self.empty) {
        self.roomDisplayName = self.room ? self.room.display_name : null;
      }

      if (changes.room || changes.asset) {
        self.decommissioned =
          (!!self.room && self.room.is_decommissioned) ||
          (!!self.asset && self.asset.is_decommissioned);
      }
    };

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Check if there is no location information available
     *
     * @return {Boolean} True if empty, false if not
     */
    function isEmpty() {
      return !(self.building || self.floor || self.room || self.asset);
    }
  }
})();
