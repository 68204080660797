(function () {
  angular
    .module("akitabox.desktop.reporting")
    .controller("ReportingController", ReportingController);

  /* @ngInject */
  function ReportingController(
    // Angular
    $element,
    $location,
    $timeout,
    // Akitabox
    models,
    // Libraries
    moment,
    // Services
    BuildingService,
    ChartService,
    OrganizationService,
    SessionService,
    ToastService,
    ShadowService,
    Utils
  ) {
    var self = this;

    var building = BuildingService.getCurrent();

    var tabNumberFilters = [null, "preventive", "reactive", null];

    var dateFilterConfig = ChartService.getDateFilterConfig(true);
    var dateFilterOptionsMap = dateFilterConfig.map;

    var GA_CATEGORY = "reporting";

    var BUILDING_FILTER_KEYS = [
      "assignees",
      "workers",
      "priority",
      "trade_name",
      "type_name",
    ];

    var filtersToApply = 0;
    var appliedFilterCount = 0;

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.buildings = null;
    self.simpleFilters = {}; // to be parsed (eg. "2019-04-20" --> Date(...))
    self.parsedFilters = {}; // parsed, sent to filter bar
    self.filters = {}; // API filters, used by stats/charts
    self.filtersLoaded = false;
    self.activeTab = 0;
    self.showTypes = false;
    self.filterBarDisabled = false;
    self.reactiveCreatedFromOptions = [
      "Service Requests and Self-Identified",
      "Service Requests",
      "Self-Identified",
    ];
    self.preventiveCreatedFromOptions = [
      "Maintenance Schedules and Self-Identified",
      "Maintenance Schedules",
      "Self-Identified",
    ];
    self.currentReactiveCreatedFromText = self.reactiveCreatedFromOptions[0];
    self.currentPreventiveCreatedFromText =
      self.preventiveCreatedFromOptions[0];
    self.showReactiveCreatedFromSelect = false;
    self.showPreventiveCreatedFromSelect = false;
    self.entityType = models.WORK_ORDER.MODEL;
    self.showWoCharts = false;
    self.showSrCharts = false;
    self.showTimeCodeCharts = false;
    self.fabActions = getFabActions();
    self.invalidRange = true;
    self.loadingPrint = false;

    // Dates
    self.isCustomDate = false;
    self.dateFilterOptions = dateFilterConfig.options;
    self.activeDateFilterLabel = self.dateFilterOptions[2];

    var activeDateFilter = dateFilterOptionsMap[self.activeDateFilterLabel];

    // (DRL 1)
    // Start and end (dates)
    function initialFilters() {
      const initialFilters = getFilters();
      if (initialFilters && !initialFilters.range) {
        // If there is no filters, set the default start date
        const start = initialFilters.startDate
          ? moment(initialFilters.startDate)
          : activeDateFilter.startDate;

        // If there is no filters, set the default end date
        const end = initialFilters.endDate
          ? moment(initialFilters.endDate)
          : activeDateFilter.endDate;

        self.startDate = start;
        self.endDate = end;
        self.dateRange = {
          start: self.startDate,
          end: self.endDate,
        };
      } else if (initialFilters && initialFilters.range) {
        self.activeDateFilterLabel = initialFilters.range;

        if (initialFilters.range === "Custom") {
          self.startDate = moment(initialFilters.startDate);
          self.endDate = moment(initialFilters.endDate);
          self.dateRange = {
            start: self.startDate,
            end: self.endDate,
          };
          return;
        }

        self.isCustomDate = false;
        const model = {
          model: self.activeDateFilterLabel,
          value: self.activeDateFilterLabel,
          invalid: false,
          clearInput: false,
        };
        onDateFilterChange(model);
      }
    }

    // Date range lifecycle (DRL)

    // 1. Initial from chart config ("Last 30 Days")
    //   1.1) startDate = moment().utc()...startOf("day").toDate()
    //   1.2) endDate = moment().utc()...endOf("day").toDate()
    //   1.3) dateRange.start = startDate
    //   1.4) dateRange.end = endDate

    // 2. Parse dates from simpleFilters (queryString)
    //   2.1) startDate = moment(simpleFilters.startDate).toDate()
    //   2.2) endDate = moment(simpleFilters.endDate).toDate()
    //   2.3) call onDateRangeChange

    // 2'. No query string dates
    //   2'.1) trigger onDateFilterChange
    //   2'.2) startDate = moment().utc()...startOf("day")
    //   2'.3) endDate = moment().utc()...endOf("day")
    //   2'.4) dateRange.start = startDate
    //   2'.5) dateRange.end = endDate
    //   2'.6) call updateFilters with startDate/endDate = undefined

    // 3. Handle dateRange change event (onDaterRangeChange - 2.3)
    //   3.1) dateRange = $event.newValue (which is dateRange, no change)
    //   3.2) newSD = moment(dateRange.start).utc().startOf("day")
    //   3.3) newED = moment(dateRange.start).utc().endOf("day")
    //   3.4) startDate = newSD
    //   3.5) endDate = newED
    //   3.6) call updateFilters with startDate.format / endDate.format

    // 4. Update filters (updateFilters - 3.6)
    //   4.1) update simpleFilters with changes (formatted dates)
    //   4.2) store reporting filters
    //   4.3) update queryString

    // 5. Date change
    //   5.1) $event.model = new Date(value), triggers onChange (abx-date-input)
    //   5.2) fieldModels[1].model = $event.model (abx-range-input)
    //   5.3) newValue = getValueObject() (abx-range-input)
    //   5.4) newValue.start = fieldModels[0].model (abx-range-input)
    //   5.5) newValue.end = moment(fieldModels[1].model).endOf("day").toDate() (abx-range-input) *NOT .utc()*
    //   5.6) fieldModels[1].model = newValue.end (abx-range-input)
    //   5.7) calls onChange, not handled by reporting.controller (abx-range-input)

    // 6. Date blur
    //   6.1) $event.value = $event.model (abx-input)
    //   6.1) calls onBlur($event) (abx-input)
    //   6.2) updateFieldModel($event, 1), fieldModels[1].model = $event.newValue (abx-range-input)
    //   6.3) newValue = getValueObject() (abx-range-input)
    //   6.4) repeat 5.4
    //   6.5) repeat 5.5, newValue.end = moment(fieldModels[1].model).endOf("day").toDate() (abx-range-input) *NOT .utc()*
    //   6.6) repeat 5.6, fieldModels[1].end = newValue.end (abx-range-input)
    //   6.7) calls onBlur, handled by reporting.controller (abx-range-input)

    // 7. Handle date change (onDateRangeChange), repeat 3
    // 8. Update filters, repeat 4

    // Functions
    self.setActiveTab = setActiveTab;
    self.onReactiveCreatedFromChange = onReactiveCreatedFromChange;
    self.onPreventiveCreatedFromChange = onPreventiveCreatedFromChange;
    self.onFiltersChange = onFiltersChange;
    self.onDateFilterClosed = onDateFilterClosed;
    self.onDateFilterChange = onDateFilterChange;
    self.onDateRangeChange = onDateRangeChange;

    init();

    function init() {
      if (building) {
        self.buildings = [building];
        self.showTypes =
          building.show_issue_types || building.show_maintenance_types;
        ChartService.getTimeCodes().finally(function () {
          //Loaded after time codes in case they take longer to be retrieved
          initialFilters();
          applyFilters();
        });
        return;
      }
      self.filterBarDisabled = true;
      BuildingService.getAllByOrganization(self.organization._id)
        .then(function (buildings) {
          self.buildings = buildings;
          return ChartService.getTimeCodes();
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.buildings = [];
        })
        .finally(function () {
          initialFilters();
          applyFilters();
        });
    }

    function getFabActions() {
      return [
        {
          icon: "print",
          label: "Print",
          action: print,
        },
      ];
    }

    function print() {
      self.loadingPrint = true;
      var $containers = $element.find(".chart-container");
      var $charts = [];
      for (var i = 0; i < $containers.length; ++i) {
        var $chart = angular.element($containers[i].parentElement);
        $chart.addClass("abx-chart--print");
        $charts.push($chart);
      }

      ShadowService.sendEvent(GA_CATEGORY, "print", null, null);

      $timeout(function () {
        self.loadingPrint = false;
        return Utils.print(5000);
      }, 3000).then(function () {
        for (var i = 0; i < $charts.length; ++i) {
          $charts[i].removeClass("abx-chart--print");
        }
      });
    }

    // =================
    // Public Functions
    // =================

    function setActiveTab(newActiveTab) {
      if (newActiveTab && angular.isString(newActiveTab)) {
        newActiveTab = parseInt(newActiveTab);
      }
      if (newActiveTab === self.activeTab) {
        return;
      }
      self.activeTab = newActiveTab;
      var intent = tabNumberFilters[newActiveTab];
      self.showReactiveCreatedFromSelect = intent === "reactive";
      self.showPreventiveCreatedFromSelect = intent === "preventive";

      setShowCharts();
      resetCreatedFromFilters();

      if (newActiveTab === 3) {
        // Enable filters if building shows issue types
        self.filterBarDisabled = !building || !building.show_issue_types;
        self.entityType = models.SERVICE_REQUEST.MODEL;
        delete self.filters.intent;
      } else {
        // Enable filters if we have a building
        self.filterBarDisabled = !building;
        self.entityType = models.WORK_ORDER.MODEL;
        if (intent) {
          self.filters.intent = intent;
        } else {
          delete self.filters.intent;
        }
      }
      self.filters = angular.extend({}, self.filters);
      updateFilters({ tab: newActiveTab });
    }

    function onReactiveCreatedFromChange($event) {
      self.currentReactiveCreatedFromText = $event.model;
      if (self.currentReactiveCreatedFromText === "Service Requests") {
        self.filters.request = "$ne,null";
      } else if (self.currentReactiveCreatedFromText === "Self-Identified") {
        self.filters.request = "null"; // must be strings for query string
      } else {
        delete self.filters.request;
      }

      self.filters = angular.extend({}, self.filters);
    }

    function onPreventiveCreatedFromChange($event) {
      self.currentPreventiveCreatedFromText = $event.model;
      if (self.currentPreventiveCreatedFromText === "Maintenance Schedules") {
        self.filters.future_task = "$ne,null";
      } else if (self.currentPreventiveCreatedFromText === "Self-Identified") {
        self.filters.future_task = "null";
      } else {
        delete self.filters.future_task;
      }

      self.filters = angular.extend({}, self.filters);
    }

    function onFiltersChange($event) {
      if ($event.assignee) {
        updateFilter("workers", []);
        updateFilter("assignees", $event["assignee"]);
      }
      if ($event["work performed by"]) {
        updateFilter("assignees", []);
        updateFilter("workers", $event["work performed by"]);
      }
      if ($event.priority) {
        updateFilter("priority", $event.priority);
      }
      if ($event.trade) {
        updateFilter("trade_name", $event.trade);
      }
      if ($event.type) {
        updateFilter("type_name", $event.type);
      }

      if (!self.filtersLoaded) {
        appliedFilterCount++;
        if (appliedFilterCount === filtersToApply) {
          self.filtersLoaded = true;
          setShowCharts();
          setShowIssueTypes();
        }
      }
    }

    function onDateFilterChange($event) {
      // (DRL 2')
      if (self.isCustomDate) self.dateFilterOptions.pop(); // Remove Custom Date Filter

      self.isCustomDate = false;
      self.activeDateFilterLabel = $event.model;
      activeDateFilter = dateFilterOptionsMap[self.activeDateFilterLabel];
      self.startDate = activeDateFilter.startDate; // (DRL 2'.2)
      self.endDate = activeDateFilter.endDate; // (DRL 2'.3)
      self.dateRange = {
        start: self.startDate, // (DRL 2'.4)
        end: self.endDate, // (DRL 2'.5)
      };
      // (DRL 2'.6)
      updateFilters({
        range: $event.model,
        startDate: self.startDate,
        endDate: self.endDate,
      });
      ShadowService.setSelectedOption($event.model);
      ShadowService.sendEvent(GA_CATEGORY, "switch-date-range");
    }

    function onDateFilterClosed() {
      if (self.isCustomDate) {
        addCustomDate();
      }
    }

    function onDateRangeChange($event) {
      // (DRL 3) (DRL 7)
      if (!$event.newValue) {
        return;
      }

      self.dateRange = $event.newValue; // (DRL 3.1) (DRL 7.1)

      /**
       * Use the *String objects to grab the date before it's modified further
       * and use these to update the query string
       */

      var startDate = moment(self.dateRange.start);
      var startDateString = startDate.format("YYYY-MM-DD");
      var endDate = moment(self.dateRange.end);
      var endDateString = endDate.format("YYYY-MM-DD");

      var timeDifference = ChartService.buildTimeDifference(startDate, endDate);

      // (DRL 3.2) (DRL 7.2)
      var newSD = startDate.startOf("day").toDate();
      // (DRL 3.3) (DRL 7.3)
      var newED = endDate.endOf("day").toDate();

      var whichInput =
        self.startDate !== newSD ? "select-start-date" : "select-end-date";

      self.invalidRange = $event.invalid || timeDifference.years > 999;
      if (self.invalidRange !== true) {
        self.startDate = newSD; // (DRL 3.4) (DRL 7.4)
        self.endDate = newED; // (DRL 3.5) (DRL 7.5)
        // (DRL 3.6) (DRL 7.6)
        updateFilters({
          range: self.isCustomDate ? "Custom" : self.activeDateFilterLabel,
          startDate: startDateString,
          endDate: endDateString,
        });
      }
      ShadowService.sendEvent(GA_CATEGORY, whichInput, null, null);
      if (self.isCustomDate) {
        addCustomDate();
      }
    }

    // =================
    // Private Functions
    // =================

    function updateFilters(changes) {
      // (DRL 4) (DRL 8)
      self.simpleFilters = angular.extend(self.simpleFilters, changes); // (DRL 4.1) (DRL 8.1)
      // Save filters to session
      saveFilters(self.simpleFilters); // (DRL 4.2) (DRL 8.2)
      // Update url (DRL 4.3) (DRL 8.3)
      Object.keys(self.simpleFilters).forEach(function (name) {
        var filterValue = self.simpleFilters[name];
        if (filterValue && filterValue.length > 0) {
          $location.search(name, filterValue);
        } else {
          $location.search(name, null);
        }
      });
    }

    function applyFilters() {
      var filters = getFilters();
      filtersToApply = 0;

      if (filters) {
        if (self.buildings.length === 1) {
          BUILDING_FILTER_KEYS.forEach(function (key) {
            if (Object.prototype.hasOwnProperty.call(filters, key)) {
              filtersToApply++;
            }
          });
        }

        self.simpleFilters = filters;
        saveFilters(filters);

        if (typeof self.simpleFilters.tab !== "undefined") {
          setActiveTab(self.simpleFilters.tab);
        }

        // (DRL 2)
        var dateRange = {
          start: self.dateRange.start,
          end: self.dateRange.end,
        };
        var anyDates = false;
        if (typeof self.simpleFilters.startDate !== "undefined") {
          self.startDate = moment(self.simpleFilters.startDate).toDate(); // (DRL 2.1)
          dateRange.start = self.startDate;
          anyDates = true;
        }
        if (typeof self.simpleFilters.endDate !== "undefined") {
          self.endDate = moment(self.simpleFilters.endDate).toDate(); // (DRL 2.2)
          dateRange.end = self.endDate;
          anyDates = true;
        }
        // (DRL 2)
        if (anyDates) {
          self.isCustomDate = true;
          self.onDateRangeChange({ newValue: dateRange }); // DRL 2.3
        } else if (typeof self.simpleFilters.range !== "undefined") {
          // (DRL 2')
          var rangeOption = self.simpleFilters.range;
          self.onDateFilterChange({ model: rangeOption }); // DRL 2'.1
        }

        var initFilters = {};
        BUILDING_FILTER_KEYS.forEach(function (key) {
          if (typeof self.simpleFilters[key] !== "undefined") {
            initFilters[key] = self.simpleFilters[key];
          }
        });

        if (Object.keys(initFilters).length > 0) {
          self.parsedFilters = initFilters;
        }
      }

      if (filtersToApply === 0) {
        self.filtersLoaded = true;
        setShowCharts();
        setShowIssueTypes();
      }
    }

    function getFilters() {
      var urlFilters = $location.search();
      if (angular.isEmpty(urlFilters)) {
        var savedFilters = SessionService.getReportingFilters();
        var filters = angular.extend({}, savedFilters);
        if (self.buildings && self.buildings.length > 1) {
          BUILDING_FILTER_KEYS.forEach(function (key) {
            if (Object.prototype.hasOwnProperty.call(filters, key)) {
              delete filters[key];
            }
          });
        }
        return filters;
      }
      return urlFilters;
    }

    function saveFilters(filters) {
      if (self.buildings && self.buildings.length > 1) {
        // Extend filters instead of overriding to preserve building filters
        var currentFilters = SessionService.getReportingFilters();
        angular.extend(currentFilters, filters);
        SessionService.setReportingFilters(currentFilters);
      } else {
        SessionService.setReportingFilters(filters);
      }
    }

    function resetCreatedFromFilters() {
      delete self.filters.request;
      delete self.filters.future_task;
      if (self.showPreventiveCreatedFromSelect) {
        onPreventiveCreatedFromChange({
          model: self.currentPreventiveCreatedFromText,
        });
      } else if (self.showReactiveCreatedFromSelect) {
        onReactiveCreatedFromChange({
          model: self.currentReactiveCreatedFromText,
        });
      } else {
        self.filters = angular.extend({}, self.filters);
      }
    }

    function updateFilter(key, values) {
      // Update simple (url) filters
      var simpleUpdate = {};
      simpleUpdate[key] = values;
      updateFilters(simpleUpdate);

      // Update api filters
      var filterUpdate = values.length ? "$in," + values.join(",") : null;
      var changes = self.filters[key] !== filterUpdate;
      if (filterUpdate && changes) {
        self.filters[key] = filterUpdate;
        self.filters = angular.extend({}, self.filters);
      } else if (changes) {
        delete self.filters[key];
        self.filters = angular.extend({}, self.filters);
      }
    }

    function addCustomDate() {
      var options = self.dateFilterOptions;
      var length = options.length;
      if (options[length - 1] !== "Custom") options.push("Custom");
      self.activeDateFilterLabel = "Custom";
    }

    function setShowIssueTypes() {
      for (var i = 0; i < self.buildings.length; i++) {
        if (
          self.buildings[i].show_issue_types ||
          self.buildings[i].show_maintenance_types
        ) {
          self.showTypes = true;
          return;
        }
      }
    }

    function setShowCharts() {
      self.showWoCharts = self.activeTab !== 3;
      self.showSrCharts = self.activeTab === 3;
      self.showTimeCodeCharts = self.organization.show_time_codes;
    }
  }
})();
