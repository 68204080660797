import { Plugin } from "@ckeditor/ckeditor5-core";
import {
  FileLoader,
  FileRepository,
  UploadAdapter,
} from "@ckeditor/ckeditor5-upload";
import { api } from "../../api";
import { FileResponse } from "@akitabox/api-client";
import axios, { AxiosResponse } from "axios";

class AbxUploadAdapter implements UploadAdapter {
  private organizationId: string;
  private loader: FileLoader;

  constructor(loader: FileLoader, organizationId: string) {
    this.loader = loader;
    this.organizationId = organizationId;
  }

  // Starts the upload process.
  async upload() {
    let fileToUpload: File;
    let fileId: string;

    try {
      const file = await this.loader.file;
      if (!file) {
        return Promise.reject(new Error("Cannot upload"));
      }
      fileToUpload = file;
      // POST /organization/:organization/files with body: { name: <> }
      const response: AxiosResponse<FileResponse> = await api.files.fileCreate({
        organization: this.organizationId,
        file: {
          name: file.name,
        },
      });

      // PUT file to returned upload PURL
      const data = new FormData();
      data.append("file", fileToUpload, fileToUpload.name);

      const { _id, upload_url } = response.data;

      fileId = _id;
      await axios.put(upload_url as string, fileToUpload, {
        headers: {
          "Content-Type": fileToUpload.type,
        },
        onUploadProgress: (progress) => {
          if (progress.total) {
            this.loader.uploadTotal = progress.total;
          }

          if (progress.loaded) {
            this.loader.uploaded = progress.loaded;
          }
        },
      });

      // PATCH /organization/:organization/files/:file with body: { action: "completeUpload" }
      const completeUploadResponse: AxiosResponse<FileResponse> =
        await api.files.completeUpload({
          organization: this.organizationId,
          file: fileId,
          fileAction: { action: "completeUpload" },
        });

      return Promise.resolve({
        default: completeUploadResponse.data.public_thumbnail_url_display,
      });
    } catch (err) {
      return Promise.reject(new Error("Cannot upload"));
    }
  }
}

export class AbxUploadAdapterPlugin extends Plugin {
  static get requires() {
    return [FileRepository];
  }

  static get pluginName() {
    return "AbxUploadAdapter";
  }

  init() {
    const options = this.editor.config.get("abxUpload");
    if (!options) {
      return;
    }
    this.editor.plugins.get(FileRepository).createUploadAdapter = (loader) => {
      return new AbxUploadAdapter(loader, options.organizationId);
    };
  }
}
