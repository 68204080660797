(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller("ScheduleDetailsTabController", ScheduleDetailsTabController);

  /* @ngInject */
  function ScheduleDetailsTabController($timeout, building, schedule) {
    var self = this;

    self.schedule = schedule;
    self.building = building;
    self.rendering = true;

    $timeout(function () {
      self.rendering = false;
    }, 1000);
  }
})();
