import { FC, useState } from "react";
import { useSubject } from "../../../util/useSubject";
import {
  AttachmentDialog,
  AttachmentDialogProps,
} from "../attachment-dialog/AttachmentDialog";
import {
  attachmentDialogClosed,
  AttachmentDialogCloseData,
  attachmentDialogOpened,
} from "../attachment-dialog/AttachmentDialogService";
import { BulkAddDialog } from "../bulk-add-dialog/BulkAddDialog";
import { BulkAddDialogProps } from "../bulk-add-dialog/BulkAddDialogTypes";
import {
  bulkAddDialogOpened,
  bulkAddDialogClosed,
} from "../bulk-add-dialog/BulkAddDialogService";

/**
 * This component is used to facilitate spawning new react dialogs
 * from the legacy angularjs application.
 */
export const LegacyDialogConnector: FC = () => {
  /** Controls whether the attachment dialog is rendered at all */
  const [renderAttachmentDialog, setRenderAttachmentDialog] = useState(false);
  /** Props to render the attachment dialog with */
  const [attachmentDialogProps, setAttachmentDialogProps] =
    useState<AttachmentDialogProps | null>(null);
  /** Data to be sent w/ the next attachment dialog close notification. */
  const [attachmentDialogCloseData, setAttachmentDialogCloseData] =
    useState<AttachmentDialogCloseData>({ uploads: [] });
  /** Controls whether the bulk add dialog is rendered at all */
  const [renderBulkAddDialog, setRenderBulkAddDialog] = useState(false);
  /** Props to render the bulk add dialog with */
  const [bulkAddDialogProps, setBulkAddDialogProps] =
    useState<BulkAddDialogProps | null>(null);

  useSubject(attachmentDialogOpened, (openOptions) => {
    setAttachmentDialogProps({
      ...openOptions,
      open: true,
      skipCreatingAttachments: openOptions.entityIds === undefined,
    });
    setRenderAttachmentDialog(true);
  });

  useSubject(bulkAddDialogOpened, (openOptions) => {
    setBulkAddDialogProps({ ...openOptions, open: true });
    setRenderBulkAddDialog(true);
  });

  // When the dialog isn't being shown, we don't render it at all
  // to forcibly reset the state of the dialog
  if (renderAttachmentDialog && attachmentDialogProps) {
    return (
      <AttachmentDialog
        {...attachmentDialogProps}
        TransitionProps={{
          onExited: () => {
            setRenderAttachmentDialog(false);
            setAttachmentDialogProps(null);
            attachmentDialogClosed.next(attachmentDialogCloseData);
            setAttachmentDialogCloseData({ uploads: [] });
          },
        }}
        onClose={(uploads) => {
          setAttachmentDialogCloseData({ uploads });
          setAttachmentDialogProps((props) => {
            if (!props) {
              return props;
            }
            return { ...props, open: false };
          });
        }}
        canUploadMultipleFiles
      />
    );
  }

  if (renderBulkAddDialog && bulkAddDialogProps) {
    return (
      <BulkAddDialog
        {...bulkAddDialogProps}
        onClose={() => {
          setBulkAddDialogProps((props) => {
            if (!props) return props;
            return { ...props, open: false };
          });
        }}
        TransitionProps={{
          onExited: () => {
            setBulkAddDialogProps(null);
            setRenderBulkAddDialog(false);
            bulkAddDialogClosed.next();
          },
        }}
      />
    );
  }

  return <></>;
};
