(function () {
  angular
    .module("akitabox.planView.dialogs.printFloorPlan", ["ngMaterial"])
    .controller(
      "PrintFloorPlanDialogController",
      PrintFloorPlanDialogController
    );

  /* @ngInject */
  function PrintFloorPlanDialogController(
    // Material
    $mdDialog
  ) {
    var self = this;

    self.cancel = $mdDialog.cancel;
    self.print = $mdDialog.hide;
  }
})();
