(function () {
  angular.module("akitabox", ["akitabox.desktop"]);
  angular.module("akitabox.desktop", [
    /* Angular */
    "ngAnimate",
    "ngSanitize",
    "ngMaterial",
    "ngMessages",
    // inject:ngMock
    /* Third party */
    "720kb.tooltips",
    "dndLists",
    "angular.filter",
    "angular-inview",
    "angular-storage",
    "angularFileUpload",
    "chrome-autofill-fix",
    "infinite-scroll",
    "ngFileSaver",
    "ui.bootstrap",
    "ui.router",
    "uiGmapgoogle-maps",
    /* AkitaBox */
    "akitabox.constants",
    "akitabox.ui",
    /* Core */
    "akitabox.core",
    "akitabox.core.directives",
    "akitabox.core.services.customerAccount",
    "akitabox.desktop.directives",
    /* Features */
    "akitabox.desktop.admin",
    "akitabox.desktop.asset",
    "akitabox.desktop.building",
    "akitabox.desktop.dashboard",
    "akitabox.desktop.document",
    "akitabox.desktop.schedule",
    "akitabox.desktop.floor",
    "akitabox.desktop.inspectionProgram",
    "akitabox.desktop.insights",
    "akitabox.desktop.job",
    "akitabox.desktop.media-viewer",
    "akitabox.desktop.room",
    "akitabox.desktop.reporting",
    "akitabox.desktop.request",
    "akitabox.desktop.workOrder",
    /* PlanView */
    "akitabox.planView",
    "templates",
  ]);
})();
