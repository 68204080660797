(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.reschedule")
    .factory("RescheduleWorkOrderDialog", RescheduleWorkOrderDialog);

  /** @ngInject */
  function RescheduleWorkOrderDialog($log, $q, $mdDialog, $mdMedia) {
    var config = {
      templateUrl:
        "app/core/ui/dialogs/work-order/reschedule/reschedule-work-order.dialog.html",
      controller: "RescheduleWorkOrderDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      config.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, required options:
     *     `options.locals.buildingId`
     *
     * @param  {Object}                 options     Dialog options
     *
     * @return {Promise<Object|Error}               Promise that resolves with
     *                                              the updated building
     */
    function show(options) {
      if (
        !options ||
        !options.locals ||
        !options.locals.workOrders ||
        !options.locals.workOrders.length > 0
      ) {
        $log.error(
          "RescheduleWorkOrderDialog: you must pass in a work order via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      angular.extend(config, options);
      return $mdDialog.show(config);
    }
  }
})();
