(function () {
  angular.module("akitabox.ui.components.label").component("abxLabelPaste", {
    bindings: {
      class: "@abxClass",
      label: "@abxLabel",
      value: "=abxPinValue",
      anchorText: "@abxAnchorText",
      onClickAnchor: "&abxOnClick",
      onInputBlur: "&abxInputBlur",
      onInputSelected: "&abxInputSelected",
      inputPlaceholder: "@abxInputPlaceholder",
      isEditingField: "<abxEditingField",
    },
    controller: AbxLabelPasteController,
    controllerAs: "vm",
    templateUrl:
      "app/core/ui/components/label-paste/label-paste.component.html",
  });

  /* @ngInject */
  function AbxLabelPasteController() {
    var self = this;
    self.className = `label-paste ${this.class}`;
  }
})();
