import React, { useState, FunctionComponent } from "react";
import {
  Button,
  Popover,
  Paper,
  Box,
  TextField,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { Edit, FilterList } from "@mui/icons-material";
import { StoredView } from "../asset-list/types";

interface SelectViewButtonProps {
  storedViews: StoredView[];
  handleApplyView: (view: StoredView) => void;
  handleOpenEditViewsDialog: () => void;
  handleResetVisibleFilterTerms: () => void;
}
// export const AssetList: FunctionComponent<AssetListProps> = () => {

const SelectViewButton: FunctionComponent<SelectViewButtonProps> = ({
  storedViews,
  handleApplyView,
  handleOpenEditViewsDialog,
  handleResetVisibleFilterTerms,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [searchText, setSearchText] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleListItemButtonClick = (id: string): void => {
    const view = storedViews.find((view: any) => view.id === id);
    if (view) {
      handleResetVisibleFilterTerms();
      handleApplyView(view);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        onClick={handleClick}
        disabled={storedViews?.length === 0}
        sx={{ whiteSpace: "nowrap" }}
      >
        Select View
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ width: 450, padding: "10px" }}
      >
        <Paper
          sx={{
            width: 400,
            maxHeight: "80vh",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box>
            <TextField
              placeholder="Search Views"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              fullWidth
            />
          </Box>
          <List sx={{ overflow: "auto", flex: "1 1 auto" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">Saved Views</Typography>
              <Tooltip title="Edit Views">
                <IconButton onClick={handleOpenEditViewsDialog} size="small">
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
            {storedViews?.length === 0 ? (
              <Typography
                sx={{ marginLeft: 2, mt: 2, mb: 2, fontSize: "0.875rem" }}
              >
                Save your views and select here
              </Typography>
            ) : (
              storedViews
                ?.filter((view: any) =>
                  view.name?.toLowerCase().includes(searchText?.toLowerCase())
                )
                .map((view: { name: string; id: string }, index: number) => (
                  <ListItemButton
                    key={index}
                    onClick={() => handleListItemButtonClick(view.id)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f5f5f5", // Change background color on hover
                      },
                    }}
                  >
                    <ListItemIcon>
                      <FilterList />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                        overflow: "hidden", // Hide overflowed text
                        textOverflow: "ellipsis",
                      }}
                      primary={view.name}
                    />
                  </ListItemButton>
                ))
            )}
          </List>
        </Paper>
      </Popover>
    </div>
  );
};

export default SelectViewButton;
