/**
 * Encodes a given text string using base64 encoding (handles the "Unicode Problem")
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 *
 * @param {string} text - Represents the `text` that you want to encode
 * @returns The base64 encoded string representation
 */
export function encodeText(text: string) {
  const bytes = new TextEncoder().encode(text);
  const str = Array.from(bytes, (code) => String.fromCodePoint(code)).join("");
  return btoa(str);
}
