(function () {
  /**
   * @ngdoc component
   * @name abxSidebarHeader
   *
   * @param {String} [icon] - Material Icons textual name of icon to show
   * @param {String} [iconColor] - Color to be used for pinType icon. Only used when
   *   [pinType] is provided.
   * @param {Object} [pinType] - When provided, renders an abx-pin-type with icon set
   *   to pinType.icon.
   * @param {Function} onClose - Called on close button click
   * @param {String} title - Title text for the header
   *
   * @description
   * Header for a sidebar display
   */
  angular.module("akitabox.planView").component("abxSidebarHeader", {
    bindings: {
      icon: "@?abxIcon",
      iconColor: "<?abxIconColor",
      pinType: "<?abxPinType",
      onClose: "&abxOnClose",
      title: "@abxTitle",
    },
    transclude: true,
    controller: AbxSidebarHeaderController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/sidebar-header/sidebar-header.component.html",
  });

  /** @ngInject */
  function AbxSidebarHeaderController(EnvService) {
    var self = this;

    // Attributes
    self.pinTypeSettingsUrl;

    self.$onChanges = function (changes) {
      if (changes.pinType && self.pinType) {
        self.pinTypeSettingsUrl =
          EnvService.getCurrentBaseUrl() +
          "/organization/settings/categories/" +
          self.pinType._id +
          "/fields";

        if (EnvService.getEnvName() === EnvService.LOCAL.ENV) {
          self.pinTypeSettingsUrl = self.pinTypeSettingsUrl.replace(
            ":3007",
            ":3000"
          );
        }
      }
    };
  }
})();
