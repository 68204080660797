(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineSelectionListItem", {
      bindings: {
        costLine: "<abxCostLine",
        selectedCostLine: "<abxSelectedCostLine",
      },
      controllerAs: "vm",
      controller: AbxCostLineSelectionListItemController,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/list-item/cost-line-selection-list-item.component.html",
      require: {
        context: "^abxCostLineSelectionContext",
      },
    });

  /* @ngInject */
  function AbxCostLineSelectionListItemController() {}
})();
