(function () {
  angular
    .module("akitabox.ui.dialogs.assetExportJob")
    .factory("AssetExportJobDialog", AssetExportJobDialog);

  /* @ngInject */
  function AssetExportJobDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/asset-export-job/asset-export-job.dialog.html",
      controller: "AssetExportJobDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * CreateFloorDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}          options                  Dialog options
     * @param {Object}          options.locals           Locals scope variables
     * @param {Object}          options.locals.filters   [Optional] Query filter parameters
     * @return {Promise<Object|Error}
     */
    function show(options) {
      const dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
