(function () {
  angular
    .module("akitabox.ui.dialogs.workOrderLog.delete")
    .controller(
      "DeleteWorkOrderLogDialogController",
      DeleteWorkOrderLogDialogController
    );

  /* @ngInject */
  function DeleteWorkOrderLogDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    WorkOrderLogService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.buildingId = self.building ? self.building._id : self.buildingId;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteWorkOrderLog = deleteWorkOrderLog;

    function deleteWorkOrderLog() {
      self.saving = true;

      WorkOrderLogService.remove(self.buildingId, self.workOrderLog._id)
        .then(function () {
          $mdDialog.hide();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
