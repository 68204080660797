(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.progressBubble
   */
  angular
    .module("akitabox.desktop.directives.progressBar", [
      "akitabox.core.services.timeZone",
    ])
    .directive("abxProgressBar", AbxProgressBar)
    .directive("abxProgressBubble", AbxProgressBubble);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.progressBar
   * @name AbxProgressBar
   * @restrict E
   *
   * @description
   * `<abx-progress-bar>` is a directive that displays the progress of request/work order through
   * its various stages
   *
   * @usage
   * <hljs lang="html">
   * <abx-progress-bar></abx-progress-bar>
   * </hljs>
   */

  /* @ngInject */
  function AbxProgressBar(
    $compile,
    $http,
    $templateCache,
    $log,
    TimeZoneService
  ) {
    return {
      restrict: "E",
      template: '<ng-include src="templateUrl"></ng-include>',
      link: postLink,
      scope: {
        abxModel: "=",
        requestState: "=?",
        workOrderState: "=?",
        abxBuilding: "=", // add a new attribute to the isolate scope and bind it to a scope property
      },
    };

    function postLink(scope, element) {
      if (scope.abxModel) {
        const building = scope.abxBuilding;
        scope.utcOffset = TimeZoneService.getCurrentUTCOffset(building);
        const buildingTzAbbr =
          TimeZoneService.getCurrentUTCOffsetAbbr(building);
        scope.timezoneAbbreviation = buildingTzAbbr;

        var hasRequesterEmail = Object.prototype.hasOwnProperty.call(
          scope.abxModel,
          "requester_email"
        );
        var isRequest = hasRequesterEmail;
        var watchGroup = [
          "abxModel.status",
          "abxModel.is_new",
          "abxModel.is_denied",
          "abxModel.is_scheduled",
          "abxModel.is_open",
          "abxModel.is_overdue",
          "abxModel.is_canceled",
          "abxModel.is_completed",
        ];

        generateBubbles(isRequest, scope.abxModel, scope, element);
        scope.$watchGroup(watchGroup, function () {
          generateBubbles(isRequest, scope.abxModel, scope, element);
        });
      } else {
        $log.error("abxModel is unassigned");
      }
    }

    /**
     * Function to generate the correct progress bubbles for the provided request/work order
     * @param {boolean} isRequest        Boolean determining of ngModel is a request object
     * @param {object} model             The actual request/work order object
     * @param {Scope} scope              angular's $scope object
     * @param {angular.element} element  abx-progress-bar DOM element
     */
    function generateBubbles(isRequest, model, scope, element) {
      $http
        .get(buildTemplateUrl(isRequest, model), { cache: $templateCache })
        .then(function (response) {
          if (response.status === 200) {
            if (isRequest) {
              scope.request = scope.abxModel;
              scope.workOrder = scope.abxModel.task;
            } else {
              scope.workOrder = scope.abxModel;
              scope.request = scope.abxModel.request;
            }

            element.html(response.data);
            $compile(element.contents())(scope);
          } else {
            throw new Error("Template not found for <abx-progress-bar>");
          }
        });
    }

    /**
     * Function to generate the path to the corresponding template for the provide request/work order
     * @param {boolean} isRequest  Boolean determining of ngModel is a request object
     * @param {object} model       The actual request/work order object
     * @returns {string}           The path to the template
     */
    function buildTemplateUrl(isRequest, model) {
      var name;
      var template = {
        new: "new-request",
        denied: "denied-request",
        scheduled: "scheduled-work-order",
        open: "open-work-order",
        overdue: "overdue-work-order",
        canceled: "canceled-work-order",
        completed: "completed-work-order",
      };

      if (isRequest && model.is_new) {
        name = template.new;
      } else if (isRequest && model.is_denied) {
        name = template.denied;
      } else if (
        (isRequest && model.is_open && model.task && model.task.is_scheduled) ||
        model.is_scheduled
      ) {
        name = template.scheduled;
      } else if (
        (isRequest && model.is_open && model.task && model.task.is_open) ||
        model.is_open
      ) {
        name = template.open;
      } else if (
        (isRequest && model.is_open && model.task && model.task.is_overdue) ||
        model.is_overdue
      ) {
        name = template.overdue;
      } else if (model.is_canceled) {
        name = template.canceled;
      } else if (model.is_completed) {
        name = template.completed;
      }

      return "app/desktop/directives/progress-bar/templates/" + name + ".html";
    }
  }

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.progressBubble
   * @name AbxProgressBubble
   * @restrict E
   *
   * @description
   * `<abx-progress-bubble>` is a directive that displays a single state of progress for a request/work order
   *
   * @usage
   * <hljs lang="html">
   * <abx-progress-bubble></abx-progress-bubble>
   * </hljs>
   */
  function AbxProgressBubble() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/progress-bar/progress-bubble.html",
      transclude: true,
      scope: {
        classes: "@?",
        checkMark: "=?",
        date: "@?",
        state: "=?",
      },
    };
  }
})();
