import { FunctionComponent, useEffect } from "react";
import { useAngularApp } from "./angularApp";
import { LegacyDialogConnector } from "./LegacyDialogConnector";
/**
 * When this component is rendered, the angularjs application will be displayed.
 * Note this just toggles the `display` css rule for the angular app, and
 * whether it renders any html. It does not destroy or create the application,
 * and will not disable most high-level angularjs behaviours like route handlers
 * or disable any behaviours.
 *
 * There should only ever be a single instance of this component rendered.
 * Mounting additional copies of this component won't work right and the
 * angular app may become stuck in a desynched state.
 */
export const ShowAngularApp: FunctionComponent = () => {
  const injector = useAngularApp();

  useEffect(() => {
    const externalUtilsService = injector.get<any>("externalUtilsService");
    const rootElement = getElementByIdOrThrow("react-app");
    externalUtilsService.toggleRendering(true);
    rootElement.classList.remove("react-mode");
    return () => {
      externalUtilsService.toggleRendering(false);
      rootElement.classList.add("react-mode");
    };
  }, [injector]);

  return <LegacyDialogConnector />;
};

function getElementByIdOrThrow<T extends HTMLElement>(id: string): T {
  const result = document.getElementById(id);
  if (!result) {
    throw new Error(`Element #${id} not found`);
  }
  return result as T;
}
