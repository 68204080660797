import { api } from "../../api";
import { BulkAddImportStats } from "../BulkAddDialogTypes";

/**
 * Generator function that asynchronously yields progress updates for a import process.
 * Uses the `sleep` function to simulate a continuous polling behavior (each 2 seconds)
 *
 * @param {string} importId - The id of the import you want to track progress
 */
export async function* importProgress(
  importId: string
): AsyncGenerator<BulkAddImportStats> {
  while (true) {
    await sleep();

    const { data: response } = await api.imports.importRowsStats({ importId });
    const {
      rows: total,
      errored_rows,
      canceled_rows,
      completed_rows,
      percent_errored,
      percent_completed,
    } = response.data;

    if (canceled_rows > 0) {
      // ends iteration on cancel
      break;
    }

    const processed = completed_rows + errored_rows;
    const progress = (percent_completed + percent_errored) * 100;
    yield { total, processed, progress: Number(progress.toFixed()) };

    if (processed === total) {
      // ends iteration on finish
      break;
    }
  }
}

function sleep(seconds = 2) {
  return new Promise<void>(function (resolve) {
    setTimeout(resolve, seconds * 1000);
  });
}
