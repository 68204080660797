import { Snackbar, SnackbarCloseReason, SnackbarProps } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";

/**
 * Snackbar props that can be configured by the parent component
 */
interface ConsecutiveSnackbarOverrideProps
  extends Omit<SnackbarProps, "open" | "onClose" | "TransitionProps"> {}

export interface ConsecutiveSnackbarProps extends SnackbarQueue {
  testId?: string;
}

/**
 * Snackbar component used to display consecutive snackbars in a
 * visually pleasing manner. Snackbars will display one-by-one, dismissing
 * after a delay (or manually), or when a new snackbar is added.
 *
 * @param props see {@link useSnackbarQueue}
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   const queue = useSnackbarQueue();
 *
 *   return (
 *     <>
 *       <ConsecutiveSnackbar queue={queue} />
 *       <button onClick={() => queue.append({title: "new alert"})}>add alert</Button>
 *     </>
 *   );
 * }
 * ```
 */
export const ConsecutiveSnackbar: FC<ConsecutiveSnackbarProps> = ({
  testId,
  queue,
  shift,
  open,
  close,
  isOpen,
}) => {
  const [curSnackbarProps, setCurSnackbarProps] =
    useState<ConsecutiveSnackbarOverrideProps>();

  useEffect(() => {
    if (queue.length && !curSnackbarProps) {
      setCurSnackbarProps({ ...queue[0] });
      shift();
      open();
    } else if (queue.length && curSnackbarProps && isOpen) {
      close();
    }
  }, [queue, isOpen, shift, open, close, curSnackbarProps]);

  return (
    <Snackbar
      {...curSnackbarProps}
      data-testid={testId}
      open={isOpen}
      onClose={close}
      TransitionProps={{
        onExited: () => {
          setCurSnackbarProps(undefined);
        },
      }}
    />
  );
};

/**
 * Queue for use with the {@link ConsecutiveSnackbar}
 */
export interface SnackbarQueue {
  /**
   * The ordered list of props for each snackbar to display
   */
  queue: ConsecutiveSnackbarOverrideProps[];
  /**
   * Add a snackbar to the queue
   * @param item Props for the snackbar
   */
  append(item: ConsecutiveSnackbarOverrideProps): void;
  /**
   * Remove the front item from the queue.
   */
  shift(): void;
  /**
   * A boolean indicating whether there is currently a snackbar
   * being displayed.
   */
  isOpen: boolean;
  /**
   * Open the snackbar
   */
  open: () => void;
  /**
   * Close the snackbar
   */
  close: (event?: Event | SyntheticEvent, reason?: SnackbarCloseReason) => void;
}

/**
 * Create a queue for use with the {@link ConsecutiveSnackbar} component
 * @returns A snackbar queue with various utility functions. All of
 *  the functions are gauranteed to have a stable identitiy. The identity
 *  of the entire queue object will change when any of its data values
 *  change.
 */
export function useSnackbarQueue(): SnackbarQueue {
  const [queue, setQueue] = useState<ConsecutiveSnackbarOverrideProps[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const open = useMemo<SnackbarQueue["open"]>(() => () => setIsOpen(true), []);
  const close = useMemo<SnackbarQueue["close"]>(
    () => (_event, reason?: SnackbarCloseReason) => {
      if (reason === "clickaway") return;
      setIsOpen(false);
    },
    []
  );
  const append = useMemo<SnackbarQueue["append"]>(
    () => (item) => setQueue((q) => [...q, item]),
    []
  );
  const shift = useMemo<SnackbarQueue["shift"]>(
    () => () => setQueue(([_, ...q]) => q),
    []
  );
  return useMemo(
    () => ({
      queue,
      append,
      shift,
      isOpen,
      open,
      close,
    }),
    [queue, append, shift, isOpen, open, close]
  );
}
