(function () {
  angular
    .module("akitabox.core.services.auth", [
      "akitabox.core.services.http",
      "akitabox.core.services.identity",
      "akitabox.core.services.env",
      "akitabox.core.services.token",
    ])
    .factory("AuthService", AuthService);

  /* @ngInject */
  function AuthService(
    // Angular
    $cookies,
    $location,
    $q,
    $http,
    $log,
    // Constants
    USER_COOKIE,
    // Services
    IdentityService,
    HttpService,
    EnvService,
    TokenService
  ) {
    var service = {
      forgotPassword: forgotPassword,
      getPortalAccessToken: getPortalAccessToken,
      isAuthenticated: isAuthenticated,
      federate: federate,
      login: login,
      logout: logout,
      logoutPortal: logoutPortal,
      register: register,
      sendValidation: sendValidation,
    };

    return service;

    /**
     * User has forgotten their password
     *
     * @param  {String}     email   Email address
     * @return {Promise}
     */
    function forgotPassword(email) {
      // Validate email is not empty
      if (angular.isEmpty(email)) return $q.reject("No email provided");
      return HttpService.post("/forgot-password", { email: email });
    }

    /**
     * Get an access token (after you clear out any stale ones)
     */
    function getPortalAccessToken(portalId) {
      // Validate portalId is not empty
      if (angular.isEmpty(portalId)) return $q.reject("No portalId provided");

      // clear any invalid access_token cookies before getting a new one
      return logoutPortal()
        .then(function () {
          return HttpService.post("/portal/" + portalId);
        })
        .catch(function (error) {
          return logout().then(function () {
            return HttpService.post("/portal/" + portalId);
          });
        });
    }

    /**
     * Check if a user cookie exists or if the url
     * contains an access_token
     *
     * @return {Boolean} True if exists, false if not
     */
    function isAuthenticated() {
      var TOKEN_REGEX = /access_token=([A-z0-9]+)/;
      var uri = $location.url();
      return Boolean(
        $cookies.get(USER_COOKIE) ||
          TokenService.getAuthToken() ||
          TOKEN_REGEX.test(uri)
      );
    }

    /**
     * Find if an email is required to login with SAML
     *
     * @param  {String}     email               Email address
     * @return {Promise}
     */
    function federate(email) {
      // Validate email is not empty
      if (angular.isEmpty(email)) return $q.reject("No email provided");

      var body = {
        email: email,
      };

      return HttpService.post("/federate", body).then(function (data) {
        return data;
      });
    }

    function onSuccess(res) {
      var data = res.data;
      var authHeader = res.headers("Authorization");

      if (data && data.account) {
        IdentityService.setCurrent(data.account);
      }

      if (authHeader && authHeader !== "") {
        TokenService.setAuthToken(authHeader);
      }

      return data;
    }

    /**
     * Log a user in
     *
     * @param  {String}     email               Email address
     * @param  {String}     password            Password
     * @param  {String}     [validation_token]  Validation Token
     * @return {Promise}
     */
    function login(email, password, validation_token) {
      // Validate email is not empty
      if (angular.isEmpty(email)) return $q.reject("No email provided");
      // Validate password is not empty
      if (angular.isEmpty(password)) return $q.reject("No password provided");

      var body = {
        email: email,
        password: password,
      };

      if (!angular.isEmpty(validation_token)) {
        body.validation_token = validation_token;
      }

      return $http
        .post(EnvService.getApiUrl("/login"), body)
        .then(onSuccess)
        .catch(HttpService.onError);
    }

    /**
     * Log a user out
     *
     * @return {Promise}
     */
    function logout() {
      return HttpService.post("/logout").then(function (data) {
        IdentityService.setCurrent(null);

        return data;
      });
    }

    /**
     * Clear portal access token, but not logged in user access token
     */
    function logoutPortal() {
      return HttpService.post("/logout-app");
    }

    /**
     * Register a user
     *
     * @param  {String}     email          Email address
     * @param  {String}     password       Password
     * @param  {object}     additionalInfo Object containing additional account information
     * @param  {String}     additionalInfo.firstName
     * @param  {String}     additionalInfo.lastName
     * @param  {String}     additionalInfo.companyName
     * @param  {String}     additionalInfo.title
     * @return {Promise}
     */
    function register(email, password, additionalInfo) {
      // Validate email is not empty
      if (angular.isEmpty(email)) return $q.reject("No email provided");
      // Validate password is not empty
      if (angular.isEmpty(password)) return $q.reject("No password provided");

      if (!additionalInfo) {
        additionalInfo = {};
      }

      var payload = {
        email: email,
        password: password,
      };

      if (additionalInfo.firstName) {
        payload.first_name = additionalInfo.firstName;
      }

      if (additionalInfo.lastName) {
        payload.last_name = additionalInfo.lastName;
      }

      if (additionalInfo.companyName) {
        payload.company_name = additionalInfo.companyName;
      }

      if (additionalInfo.title) {
        payload.title = additionalInfo.title;
      }

      return HttpService.post("/register", payload);
    }

    /**
     * Request a validation email be send to verfy a user
     *
     * @param  {String}     email   Email address
     * @return {Promise}
     */
    function sendValidation(email) {
      // Validate email is not empty
      if (angular.isEmpty(email)) return $q.reject("No email provided");
      return HttpService.post("/send-validation", { email: email });
    }
  }
})();
