(function () {
  angular
    .module("akitabox.desktop.dashboard")
    .controller("DashboardController", DashboardController);

  /* @ngInject */
  function DashboardController(
    // Services
    BuildingService,
    FeatureFlagService,
    OrganizationService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.buildings = null;

    init();

    function init() {
      var params = {
        archive_date: "null",
        organization: self.organization._id,
      };
      BuildingService.getAll(params)
        .then(function (buildings) {
          self.buildings = buildings;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.buildings = [];
        });
    }
  }
})();
