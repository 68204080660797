(function () {
  angular.module("akitabox.desktop.room.detail").run(states);

  /* @ngInject */
  function states(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/room/detail/overview/room-overview.html",
          controller: "RoomOverviewTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Room Overview",
          },
        };
      },
      assets: function () {
        return {
          url: "/assets",
          templateUrl:
            "app/desktop/modules/room/detail/assets/room-assets.html",
          controller: "RoomAssetsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Room Assets",
          },
        };
      },
      closedWorkOrders: function () {
        return {
          url: "/closed-work-orders",
          templateUrl:
            "app/desktop/modules/room/detail/closed-work-orders/room-closed-work-orders.html",
          controller: "RoomClosedWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Room Closed WOs",
          },
        };
      },
      details: function () {
        return {
          url: "/details",
          templateUrl:
            "app/desktop/modules/room/detail/details/room-details.html",
          controller: "RoomDetailsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Room Details",
          },
        };
      },
      schedules: function () {
        return {
          url: "/maintenance-schedules",
          templateUrl:
            "app/desktop/modules/room/detail/schedules/room-schedules.html",
          controller: "RoomSchedulesTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Room Schedules",
          },
        };
      },
      openWorkOrders: function () {
        return {
          url: "/open-work-orders",
          templateUrl:
            "app/desktop/modules/room/detail/open-work-orders/room-open-work-orders.html",
          controller: "RoomOpenWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Room Open WOs",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.room.overview",
        config: config.overview(),
      },
      {
        state: "app.building.room.assets",
        config: config.assets(),
      },
      {
        state: "app.building.room.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.building.room.details",
        config: config.details(),
      },
      {
        state: "app.building.room.schedules",
        config: config.schedules(),
      },
      {
        state: "app.building.room.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.room.overview",
        config: config.overview(),
      },
      {
        state: "app.room.assets",
        config: config.assets(),
      },
      {
        state: "app.room.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.room.details",
        config: config.details(),
      },
      {
        state: "app.room.schedules",
        config: config.schedules(),
      },
      {
        state: "app.room.openWorkOrders",
        config: config.openWorkOrders(),
      },
    ]);
  }
})();
