(function () {
  /**
   * @ngdoc component
   * @name abxChecklistSummaryFilters
   *
   */
  angular
    .module("akitabox.ui.components.checklistSummaryFilters", [
      "akitabox.core.services.building",
      "akitabox.core.services.inspection",
      "akitabox.core.services.organization",
    ])
    .component("abxChecklistSummaryFilters", {
      bindings: {
        inspectionProgram: "<abxInspectionProgram",
        inspections: "<abxInspections",
        onFilterChange: "&?abxOnFilterChange",
      },
      controller: AbxChecklistSummaryFilters,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/checklist-summary-filters/checklist-summary-filters.component.html",
    });

  /* @ngInject */
  function AbxChecklistSummaryFilters(
    // Angular
    $q,
    $log,
    $stateParams,
    // Services
    BuildingService,
    InspectionService,
    OrganizationService
  ) {
    var self = this;

    // Public Attributes
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.selectedFilters = {
      iterations: [],
      buildings: [],
    };
    self.filterOptions = {
      iterations: [],
      buildings: [],
    };

    // Functions
    self.onSummaryDropDownSelect = onSummaryDropDownSelect;

    self.$onInit = function () {
      if (!self.inspectionProgram) {
        $log.error("inspectionProgram is required");
        return;
      }

      if (!self.organization) {
        $log.error("organization is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.inspections && self.inspections) {
        fetchAllFilterData();
      }
    };

    function fetchAllFilterData() {
      if (self.inspections.length < 1) {
        // Break out of the promise chain if there no inspections to display
        return $q.reject("No inspections found");
      }

      const buildingIds = [];

      for (const inspection of self.inspections) {
        const iterationName = InspectionService.getInspectionName(
          inspection,
          inspection.inspection_program.interval
        );
        for (const buildingId of inspection.buildings) {
          if (buildingIds.indexOf(buildingId) === -1) {
            buildingIds.push(buildingId);
          }
        }

        var option = {
          model: inspection,
          value: iterationName,
        };
        self.filterOptions.iterations.push(option);
      }

      return BuildingService.getAllByOrganization(self.organization._id, {
        _id: buildingIds.join(","),
      })
        .then(function (buildings) {
          // add an 'all' option if multiple
          var allOption = {
            model: { _id: "all" },
            value: "All Buildings",
          };

          if (buildings.length > 1) {
            self.filterOptions.buildings.push(allOption);
          }

          for (var i = 0; i < buildings.length; i++) {
            var building = buildings[i];
            var option = {
              model: building,
              value: building.name,
            };
            self.filterOptions.buildings.push(option);
          }
        })
        .then(function () {
          var defaultIterations;
          var defaultBuildings;

          // default most recent iteration
          if (self.filterOptions.iterations.length > 1) {
            defaultIterations = [self.filterOptions.iterations[1].model];
          } else {
            defaultIterations = [self.filterOptions.iterations[0].model];
          }

          // default the current building(s)
          if (self.building) {
            defaultBuildings = [self.building];
          } else {
            defaultBuildings = [self.filterOptions.buildings[0].model];
          }

          // apply query params
          if ($stateParams.filters) {
            var filters = JSON.parse(decodeURIComponent($stateParams.filters));
            if (filters.iterations) {
              defaultIterations = [];
              filters.iterations.forEach(function (selectedIteration) {
                self.filterOptions.iterations.forEach(function (iteration) {
                  if (selectedIteration === iteration.model._id) {
                    defaultIterations.push(iteration.model);
                  }
                });
              });
            }

            if (filters.buildings) {
              defaultBuildings = [];
              filters.buildings.forEach(function (selectedBuilding) {
                self.filterOptions.buildings.forEach(function (building) {
                  if (selectedBuilding === building.model._id) {
                    defaultBuildings.push(building.model);
                  }
                });
              });
            }
          }

          onSummaryDropDownSelect({ model: defaultIterations }, "iterations");
          onSummaryDropDownSelect({ model: defaultBuildings }, "buildings");
        });
    }

    /**
     * Set selected buildings/iterations
     * @param {*} $event change event
     * @param {String} filter the filter being changed, 'iterations' or 'buildings'
     */
    function onSummaryDropDownSelect($event, filter) {
      var model = $event.model;
      var allOptionInModel = false;
      for (var i = 0; i < model.length; i++) {
        var option = model[i];
        if (option._id === "all") {
          allOptionInModel = true;
          break;
        }
      }
      var allOptionInSelectedFilters = false;
      for (var j = 0; j < self.selectedFilters[filter].length; j++) {
        var selectedFilter = self.selectedFilters[filter][j];
        if (selectedFilter._id === "all") {
          allOptionInSelectedFilters = true;
          break;
        }
      }
      var allOptionCurrentlySelected =
        allOptionInSelectedFilters &&
        self.selectedFilters[filter].length ===
          self.filterOptions[filter].length;
      var allItemsSelected =
        !allOptionInSelectedFilters &&
        model.length === self.filterOptions[filter].length - 1;

      if (allOptionInModel && !allOptionCurrentlySelected) {
        // If "all" was just selected, select all items
        self.selectedFilters[filter] = [];
        for (var k = 0; k < self.filterOptions[filter].length; k++) {
          self.selectedFilters[filter].push(
            self.filterOptions[filter][k].model
          );
        }
      } else if (allOptionInModel && allOptionCurrentlySelected) {
        // if "all" was previously selected, but now something else was, remove "all"
        model = model.filter(function (selectedFilter) {
          return selectedFilter._id !== "all";
        });
        self.selectedFilters[filter] = model;
      } else if (!allOptionInModel && allOptionCurrentlySelected) {
        // If all was previously selected, and is now unselected, clear everything
        model = [];
        self.selectedFilters[filter] = model;
      } else if (allItemsSelected && filter === "buildings") {
        // If all items have been selected, check the 'all' option too
        model.push({ _id: "all" });
        self.selectedFilters[filter] = model;
      } else {
        self.selectedFilters[filter] = model;
      }

      if (angular.isFunction(self.onFilterChange)) {
        self.onFilterChange({
          filter: filter,
          filters: self.selectedFilters[filter],
        });
      }
    }
  }
})();
