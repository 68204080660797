(function () {
  angular
    .module("akitabox.ui.dialogs.qrCode.manualEntry")
    .controller("QrManualEntryDialogController", QrManualEntryDialogController);

  /* @ngInject */
  function QrManualEntryDialogController(
    $mdDialog,
    QRCodeService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.disableSearch = true;
    self.loading = false;
    self.model = "";
    self.showError = false;

    // Public Constants
    self.ERROR_MESSAGES = { NOT_FOUND: "QR code not found." };

    // Functions
    self.cancel = $mdDialog.cancel;
    self.handleInputChange = handleInputChange;
    self.handleSearchClick = handleSearchClick;

    // ------------------------
    //   Public Functions
    // ------------------------
    function handleInputChange($event) {
      clearError();
      self.model = $event.model;

      if (haveValidSearch()) {
        enableSearch();
      } else {
        disableSearch();
      }
    }

    /**
     * Handle user clicking the search button.
     */
    function handleSearchClick() {
      startLoading();
      return QRCodeService.getById(self.model)
        .then(function (qrCode) {
          stopLoading();
          $mdDialog.hide(qrCode);
        })
        .catch(function (response) {
          stopLoading();
          if (response && response.status === 404) {
            displayErrorMessage(self.ERROR_MESSAGES.NOT_FOUND);
          } else {
            ToastService.showError(response);
          }
        });
    }

    // ------------------------
    //   Private Functions
    // ------------------------
    /**
     * See if we have a valid code to search for. Relies on `abx-input` to
     * only send up a defined model if the input has a value with a valid
     * QR code format.
     *
     * @return {Boolean} - Current search value is valid
     */
    function haveValidSearch() {
      return Boolean(self.model);
    }

    /**
     * Disable the search button.
     */
    function disableSearch() {
      self.disableSearch = true;
    }

    /**
     * Enable the search button.
     */
    function enableSearch() {
      self.disableSearch = false;
    }

    /**
     * Display the given error message in the dialog.
     */
    function displayErrorMessage(message) {
      self.errorMessage = message;
    }

    /**
     * Clear any currently displayed error messages.
     */
    function clearError() {
      self.errorMessage = "";
    }

    /**
     * Display the loading indicator.
     */
    function startLoading() {
      self.loading = true;
    }

    /**
     * Hide the loading indicator.
     */
    function stopLoading() {
      self.loading = false;
    }
  }
})();
