(function () {
  angular
    .module("akitabox.core.services.attachment", [
      "akitabox.constants",
      "akitabox.core.services.building",
      "akitabox.core.services.http",
      "akitabox.core.services.organization",
    ])
    .factory("AttachmentService", AttachmentService);

  /** @ngInject **/
  function AttachmentService(
    models,
    HttpService,
    BuildingService,
    OrganizationService
  ) {
    var service = {
      // Routes
      buildBuildingBaseRoute: buildBuildingBaseRoute,
      buildBuildingDetailRoute: buildBuildingDetailRoute,
      buildOrganizationBaseRoute: buildOrganizationBaseRoute,
      buildOrganizationDetailRoute: buildOrganizationDetailRoute,
      // Create
      create: create,
      // Update
      update: update,
      // Delete
      remove: remove,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrg: getStatsByOrg,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBuildingBaseRoute(buildingId) {
      return (
        BuildingService.buildDetailRoute(buildingId) +
        "/" +
        models.ATTACHMENT.ROUTE_PLURAL
      );
    }

    function buildBuildingDetailRoute(buildingId, attachmentId) {
      var base = buildBuildingBaseRoute(buildingId);

      // Attachment detail
      return base + "/" + attachmentId;
    }

    function buildOrganizationBaseRoute(orgId) {
      return (
        OrganizationService.buildDetailRoute(orgId) +
        "/" +
        models.ATTACHMENT.ROUTE_PLURAL
      );
    }

    function buildOrganizationDetailRoute(orgId, attachmentId) {
      var base = buildOrganizationBaseRoute(orgId);

      // Attachment detail
      return base + "/" + attachmentId;
    }

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data, params) {
      var route = buildBuildingBaseRoute(buildingId);
      return HttpService.create(route, data, {}, params);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, attachmentId, data) {
      var route = buildBuildingDetailRoute(buildingId, attachmentId);
      return HttpService.patch(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, attachmentId) {
      var route = buildBuildingDetailRoute(buildingId, attachmentId);
      return HttpService.remove(route);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params) {
      var route = buildBuildingBaseRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getAll(buildingId, params) {
      params = Object.assign({ delete_date: "null" }, params);
      var route = buildBuildingBaseRoute(buildingId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, attachmentId, params) {
      var route = buildBuildingDetailRoute(buildingId, attachmentId);
      return HttpService.getById(route, params);
    }

    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildBuildingBaseRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getStatsByOrg(orgId, params) {
      var route = "/stats" + buildOrganizationBaseRoute(orgId);
      return HttpService.get(route, params);
    }
  }
})();
