(function () {
  angular
    .module("akitabox.ui.dialogs.floor.create")
    .controller("CreateFloorDialogController", CreateFloorDialogController);

  /* @ngInject */
  function CreateFloorDialogController(
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Constants
    models,
    // Services
    Router,
    FloorService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.formData = { building: self.building };
    self.saving = false;
    self.newFloor = null;
    self.model = models.FLOOR.PLURAL;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.isDisabled = isDisabled;

    self.onChange = onChange;

    function onChange($event, key) {
      self.formData[key] = $event.model;
    }

    function create() {
      if (self.saving) return;
      self.saving = true;
      var data = angular.copy(self.formData);

      FloorService.create(self.formData.building._id, data)
        .then(function (floor) {
          self.newFloor = floor;
          $mdDialog.hide([floor]);
          ToastService.complex()
            .text("Successfully created floor")
            .action("View", goToNewFloor)
            .show();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    function isDisabled() {
      return !self.formData.name || !self.formData.building || self.saving;
    }
    /**
     * Go to the newly created floor's detail page
     */
    function goToNewFloor() {
      var stateParams = {
        buildingId: $stateParams.buildingId ? self.formData.building._id : null,
        floorId: self.newFloor._id,
      };
      Router.go("app.floor", stateParams);
    }
  }
})();
