(function () {
  angular.module(
    "akitabox.core.ui.dialogs.inspectionProgram.create.checklistTemplateOverrideSection.list",
    [
      "akitabox.ui.components.preloaders",
      "akitabox.ui.components.input",
      "ngMaterial",
    ]
  );
})();
