import { useState, useEffect, FunctionComponent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Box,
  TextField,
  IconButton,
  ListItemSecondaryAction,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, ContentCopy, Info } from "@mui/icons-material";
import { StoredView } from "./types";

export interface FilterTerms {
  [key: string]: string;
  filterItem: string;
  description: string;
}

export interface EditViewsDialogProps {
  open: boolean;
  views: StoredView[];
  onClose: () => void;
  onSaveViews: (views: StoredView[]) => void;
}

const EditViewsDialog: FunctionComponent<EditViewsDialogProps> = ({
  open,
  views,
  onClose,
  onSaveViews,
}) => {
  const [changes, setChanges] = useState(false);
  const [editedViews, setEditedViews] = useState<StoredView[]>(views);
  useEffect(() => {
    setEditedViews(views);
  }, [views]);

  const handleRenameView = (index: number, newName: string) => {
    const updatedViews = [...editedViews];
    updatedViews[index].name = newName;
    setEditedViews(updatedViews);
    setChanges(true);
  };

  const handleDeleteView = (index: number) => {
    const updatedViews = editedViews.filter((_, i) => i !== index);
    setEditedViews(updatedViews);
    setChanges(true);
  };

  const handleCopyView = (index: number) => {
    const updatedViews = [...editedViews];
    const viewToCopy = updatedViews[index];
    const id = Math.random().toString(36);

    updatedViews.push({
      ...viewToCopy,
      id,
      name: `${viewToCopy.name} (copy)`,
    });
    setEditedViews(updatedViews);
    setChanges(true);
  };

  const handleCancel = () => {
    setEditedViews(views);
    setChanges(false);
    onClose();
  };

  const handleSave = () => {
    onSaveViews(editedViews);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Views</DialogTitle>
      <DialogContent>
        <List>
          {editedViews.length > 0 ? (
            editedViews.map((view: any, index: number) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box sx={{ flexGrow: 1, pr: 8 }}>
                  <TextField
                    fullWidth
                    value={view.name}
                    onChange={(e) => handleRenameView(index, e.target.value)}
                  />
                  <Box sx={{ mt: 1, color: "text.secondary" }}>
                    {view.visibleFilterTerms?.map(
                      (term: FilterTerms, termIndex: number) => {
                        return (
                          <Chip key={termIndex} label={term.description} />
                        );
                      }
                    )}
                  </Box>
                  <Box sx={{ mt: 1, color: "text.secondary" }}>
                    <Tooltip
                      title={Object.keys(view.columnVisibilityModel)
                        .filter((key) => view.columnVisibilityModel[key])
                        // Filter out the Actions column
                        .filter((column) => !column.startsWith("Actions"))
                        .map((column) => column.replace("values.", ""))
                        .map(
                          (column) =>
                            column.charAt(0).toUpperCase() +
                            column.slice(1).toLowerCase()
                        )
                        .join(", ")}
                    >
                      <Chip
                        icon={<Info />}
                        label={`${
                          Object.keys(view.columnVisibilityModel)
                            .filter((key) => view.columnVisibilityModel[key])
                            .filter((column) => !column.startsWith("Actions"))
                            .length
                        } columns`}
                      />
                    </Tooltip>
                  </Box>
                </Box>
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleDeleteView(index)}>
                    <Delete />
                  </IconButton>
                  <IconButton onClick={() => handleCopyView(index)}>
                    <ContentCopy />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1">
              Save your views and manage here
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={editedViews.length === 0 && !changes}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditViewsDialog;
