(function () {
  /**
   * @ngdoc component
   * @name abxTopBanner
   *
   * @description
   * A component to display a banner at the top center of another component
   */
  angular.module("akitabox.ui.components.topBanner").component("abxTopBanner", {
    bindings: {},
    transclude: true,
    controller: AbxTopBannerController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/top-banner/top-banner.component.html",
  });

  function AbxTopBannerController() {}
})();
