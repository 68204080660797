import * as paper from "paper";
(function () {
  angular.module("akitabox.core.lib.paper", []).factory("paper", PaperService);

  /* @ngInject */
  function PaperService($window) {
    if (!angular.isDefined($window.paper)) {
      if (paper) {
        return paper;
      } else {
        throw new Error("paper is not available");
      }
    }
    return $window.paper;
  }
})();
