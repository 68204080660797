(function () {
  angular
    .module("akitabox.ui.dialogs.export")
    .controller("ExportDialogController", ExportDialogController);

  /* @ngInject */
  function ExportDialogController(
    $mdDialog,
    FeatureFlagService,
    FileService,
    HttpService
  ) {
    var self = this;

    // Attributes
    self.csvDisabled = false;
    self.exporting = false;
    self.exportingAssets = false;
    self.exportingRooms = false;
    self.failed = false;
    self.truncated = false;
    self.filters = angular.isDefined(self.filters) ? self.filters : {};
    self.type = "";
    self.orgExport = false;
    self.doneWithOrgExport = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.exportXLSX = exportXLSX;
    self.exportCSV = exportCSV;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      self.orgExport = self.route.indexOf("/organizations") > -1;
      self.exportingAssets = self.route.indexOf("/assets") > -1;
      self.exportingRooms = self.route.indexOf("/rooms") > -1;
      self.csvDisabled = self.exportingAssets || self.exportingRooms;
      // TODO: don't export immediately once flag is removed
      var csvAllowed = FeatureFlagService.isEnabled("csv_export");
      if (!csvAllowed) {
        // Export xlsx immediately if it's the only option
        exportXLSX();
      }

      /**
       * Allow org level room and asset exports, keep
       * buioding level exports disasbled
       */
      if (
        self.orgExport &&
        csvAllowed &&
        self.route.indexOf("/buildings") === -1
      ) {
        self.csvDisabled = false;
      }
    }

    function requestExport(type, filters) {
      self.exporting = true;
      self.page = 1;
      var params = angular.extend({}, filters, {
        export: type,
      });
      var route = self.route + HttpService.buildQueryString(params);
      if (self.type.toLowerCase() === "csv" && self.orgExport) {
        self.doneWithOrgExport = false;
        HttpService.get(route, {}, null)
          .then(function () {
            self.doneWithOrgExport = true;
          })
          .catch(function () {
            self.failed = true;
          })
          .finally(function () {
            self.exporting = false;
          });
      } else {
        FileService.download(route)
          .then(function (result) {
            self.failed = false;
            if (!result.truncated) {
              $mdDialog.hide();
            } else {
              self.truncated = true;
            }
          })
          .catch(function () {
            self.failed = true;
          })
          .finally(function () {
            self.exporting = false;
          });
      }
    }

    function buildSelectedFilters() {
      return {
        _id:
          self.selected.length === 1
            ? self.selected[0]
            : "$in," + self.selected.join(","),
      };
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function exportXLSX() {
      self.type = "xlsx";
      if (self.selected) {
        requestExport("xlsx", buildSelectedFilters());
      } else {
        requestExport("xlsx", self.filters);
      }
    }

    function exportCSV() {
      self.type = "csv";
      if (self.selected) {
        requestExport("csv", buildSelectedFilters());
      } else {
        requestExport("csv", self.filters);
      }
    }
  }
})();
