(function () {
  angular
    .module("akitabox.desktop.media-viewer")
    .directive("abxImageViewer", AbxImageViewer);

  /* @ngInject */
  function AbxImageViewer(
    // Angular
    $document,
    $timeout,
    // AkitaBox
    models,
    // Contants
    EVENT_MEDIA_REFRESH,
    EVENT_MEDIA_ROTATE,
    EVENT_MEDIA_ZOOM_IN,
    EVENT_MEDIA_ZOOM_OUT,
    EVENT_MEDIA_ZOOM_RESET,
    // Services
    DocumentService,
    ServiceHelpers,
    Utils
  ) {
    return {
      restrict: "A",
      scope: {
        image: "=abxImageViewer",
        scroll: "<?abxScroll",
        onRender: "&?abxOnRender",
        onRotate: "&?abxOnRotate",
      },
      link: postLink,
    };

    function postLink($scope, $element, $attrs) {
      var imageDocument = $scope.image;
      var imageId = imageDocument._id;
      var buildingId = imageDocument.building;

      var image;
      var imageRotation = 0;
      var panOffset;
      var rotating = false;

      let deRegisterRotate;
      let deRegisterRender;
      let deRegisterZoomin;
      let deRegisterZoomout;
      let deRegsiterZoomReset;

      var showResetControl = !angular.isDefined($attrs.abxHideReset);
      var showZoomControl = !angular.isDefined($attrs.abxHideZoom);
      var showRotateControl = !angular.isDefined($attrs.abxHideRotate);

      function render() {
        ServiceHelpers.getDocumentData($scope.image, false).then((imageData) =>
          handleImageLoad(imageData)
        );
      }

      function handleImageLoad(imageData) {
        const { blob, rotation, orientation, rendering } = imageData;
        if (rendering === "3d") {
          render3dImage(blob);
        } else {
          render2dImage(blob, rotation, orientation);
        }
      }

      render();

      function render3dImage(imageBlob) {
        var parentDimensions = getParentDimensions();
        var imageUrl = URL.createObjectURL(imageBlob);

        var iframe = $document[0].createElement("iframe");
        iframe.setAttribute("allow", "fullscreen");
        iframe.setAttribute("allowFullscreen", "true");
        iframe.height = parentDimensions.height;
        iframe.width = parentDimensions.width;
        iframe.src = "/pannellum.htm?autoLoad=true&panorama=" + imageUrl;

        $element[0].appendChild(iframe);
      }

      function render2dImage(imageBlob, rotation, orientation) {
        var options = {
          rotation: rotation,
          scaleFactor: rotation === 0 || rotation === 180 ? 1 : 0.9,
          translateX: 0,
          translateY: 0,
          zoomInterval: 0.75,
        };

        image = new Image();
        image.src = URL.createObjectURL(imageBlob);
        image.setAttribute("draggable", false);
        image.addEventListener("load", fitToViewAndAppend);

        if (typeof deRegisterRotate === "function") {
          deRegisterRotate();
        }
        deRegisterRotate = $scope.$on(EVENT_MEDIA_ROTATE, rotate);

        if (typeof deRegisterZoomin === "function") {
          deRegisterZoomin();
        }
        deRegisterZoomin = $scope.$on(EVENT_MEDIA_ZOOM_IN, zoomIn);

        if (typeof deRegisterZoomout === "function") {
          deRegisterZoomout();
        }
        deRegisterZoomout = $scope.$on(EVENT_MEDIA_ZOOM_OUT, zoomOut);

        if (typeof deRegsiterZoomReset === "function") {
          deRegsiterZoomReset();
        }
        deRegsiterZoomReset = $scope.$on(EVENT_MEDIA_ZOOM_RESET, resetPosition);

        if (typeof deRegisterRender === "function") {
          deRegisterRender();
        }
        deRegisterRender = $scope.$on(EVENT_MEDIA_REFRESH, render);

        function fitToViewAndAppend() {
          var dimensions = setDimensions(options);

          // Remove old image before refreshing with new image
          const $prevImage = $element.find("img");
          if ($prevImage.length) {
            $prevImage.remove();
          }

          // Add image
          $element.append(image);
          rotateImage(rotation);

          onRender(dimensions, rotation, orientation);

          if (
            models.DOCUMENT.MEDIA_VIEWER_ROTATEABLE_TYPES.includes(
              imageDocument.extension.toLowerCase()
            )
          ) {
            addControlContainer();
          }
        }

        function setDimensions(options) {
          var initialScale = 1;

          var rotationRequiresHeightAndWidthSwap =
            rotation && rotation % 180 > 0;
          var parentDimensions = getParentDimensions();

          image.height = image.naturalHeight;
          image.width = image.naturalWidth;

          var maxHeight, maxWidth;
          if (rotationRequiresHeightAndWidthSwap) {
            maxHeight = even(parentDimensions.width);
            maxWidth = even(parentDimensions.height);
          } else {
            maxHeight = parentDimensions.height;
            maxWidth = parentDimensions.width;
          }

          var heightRatio = maxHeight / image.naturalHeight;
          var widthRatio = maxWidth / image.naturalWidth;

          if (widthRatio < heightRatio) {
            initialScale = widthRatio;
          } else {
            initialScale = heightRatio;
          }

          // Adjust scale based on the image size
          var scale = initialScale * options.scaleFactor;
          var translateX = options.translateX;
          var translateY = options.translateY;

          var transform =
            " scale(" +
            scale +
            ")" +
            " translate(" +
            translateX / scale +
            "px ," +
            translateY / scale +
            "px)";
          $element.css("transform", transform);

          return {
            width: image.width,
            height: image.height,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            scale: scale,
          };
        }

        function rotateImage(rotation) {
          imageRotation = rotation;
          $element
            .find("img")
            .css("transform", "rotate(" + imageRotation + "deg)");
        }

        function addControlContainer() {
          var controlContainer = $document[0].createElement("div");
          controlContainer.className = "control-container";
          $element[0].parentElement.appendChild(controlContainer);

          // Always on controls
          panControl();

          // Attach controls
          if (showRotateControl) {
            controlContainer.appendChild(rotateControl());
          }
          if (showResetControl) {
            controlContainer.appendChild(resetPositionControl());
          }
          if (showZoomControl) {
            controlContainer.appendChild(zoomInControl());
            controlContainer.appendChild(zoomOutControl());
          }
        }

        function panControl() {
          var panMode = false;
          var defaultTransition = $element[0].style.transition;
          var startCoordinates = {};
          panOffset = { x: 0, y: 0 };

          // Event
          function pan(event) {
            var e = event;

            if (!panMode) {
              return;
            }

            // Distingush between touch and mouse events
            if (event.touches && event.touches.length) {
              e = event.touches[0];
            }

            options.translateX = e.clientX - startCoordinates.x + panOffset.x;
            options.translateY = e.clientY - startCoordinates.y + panOffset.y;

            // Remove animation
            $element[0].style.transition = "none";

            setDimensions(options);
          }

          function panStart(event) {
            var e = event;

            // Distingush between touch and mouse events
            if (event.touches && event.touches.length) {
              e = event.touches[0];
            }

            panMode = true;
            startCoordinates.x = e.clientX;
            startCoordinates.y = e.clientY;
          }

          function panStop(e) {
            panOffset.x = options.translateX;
            panOffset.y = options.translateY;
            panMode = false;

            // Add default animation back
            $element[0].style.transition = defaultTransition;
          }

          // Attach events - Desktop
          $element[0].parentElement.addEventListener("mousedown", panStart);
          $element[0].parentElement.addEventListener("mousemove", pan);
          $element[0].parentElement.addEventListener("mouseleave", panStop);
          $element[0].parentElement.addEventListener("mouseup", panStop);

          // Attach events - Touch
          $element[0].parentElement.addEventListener("touchstart", panStart);
          $element[0].parentElement.addEventListener("touchmove", pan);
          $element[0].parentElement.addEventListener("touchcancel", panStop);
          $element[0].parentElement.addEventListener("touchend", panStop);
        }

        function rotateControl() {
          var rotateButton = $document[0].createElement("div");
          rotateButton.className = "rotate";
          rotateButton.addEventListener("click", rotate);
          return rotateButton;
        }

        function resetPositionControl() {
          var resetButton = $document[0].createElement("div");
          resetButton.className = "reset-position";
          resetButton.addEventListener("click", resetPosition);
          return resetButton;
        }

        function zoomInControl() {
          var zoomInButton = $document[0].createElement("div");
          zoomInButton.className = "zoom-in";

          // Apply zoom based scroll delta in Y direction
          function wheelListener(e) {
            if (e.deltaY < 0 && $scope.scroll) {
              zoomIn();
            }
          }

          // Throttle the event so it's not as sensitive
          var throttledWheelListener = Utils.throttle(wheelListener, 50);

          // Attach events
          zoomInButton.addEventListener("click", zoomIn);
          $document[0].addEventListener("wheel", throttledWheelListener);

          return zoomInButton;
        }

        function zoomOutControl() {
          var zoomOutButton = $document[0].createElement("div");
          zoomOutButton.className = "zoom-out";

          // Apply zoom based scroll delta in Y direction
          function wheelListener(e) {
            if (e.deltaY > 0 && $scope.scroll) {
              zoomOut();
            }
          }

          // Throttle the event so it's not as sensitive
          var throttledWheelListener = Utils.throttle(wheelListener, 50);

          // Attach events
          zoomOutButton.addEventListener("click", zoomOut);
          $document[0].addEventListener("wheel", throttledWheelListener);

          return zoomOutButton;
        }

        function zoomIn() {
          options.scaleFactor /= options.zoomInterval;
          setDimensions(options);
        }

        function zoomOut() {
          options.scaleFactor *= options.zoomInterval;
          setDimensions(options);
        }

        function rotate() {
          if (rotating) {
            return;
          }
          rotating = true;

          // Save old rotation incase document fails to rotate
          var currentRotation = imageRotation;

          // Set desired rotation
          imageRotation = imageRotation + 90;

          // Rotate locally
          var dimensions = setDimensions(options);
          rotateImage(imageRotation);
          onRotate(dimensions, imageRotation);

          // Rotate remotely
          DocumentService.rotate(buildingId, imageId, imageRotation % 360)
            .catch(function () {
              // Revert the rotation
              imageRotation = currentRotation;
            })
            .finally(function () {
              // Enforce true rotation
              var dimensions = setDimensions(options);
              rotateImage(imageRotation);
              onRotate(dimensions, imageRotation);
              rotating = false;
            });
        }

        function resetPosition() {
          options.translateX = 1;
          options.translateY = 1;
          panOffset = { x: 0, y: 0 };
          var rotation = options.rotation;
          options.scaleFactor = rotation === 0 || rotation === 180 ? 1 : 0.9;
          setDimensions(options);
        }
      }

      // css rotate() requires even dimensions to prevent image blurriness
      function even(maxDimension) {
        return maxDimension - (maxDimension % 2);
      }

      function getParentDimensions() {
        var parentElement = $element[0].parentElement;

        return {
          height: parentElement.clientHeight,
          width: parentElement.clientWidth,
        };
      }

      function onRender(dimensions, rotation, orientation) {
        if ($scope.onRender) {
          $scope.onRender({
            $event: {
              dimensions: dimensions,
              rotation: rotation,
              orientation: orientation,
            },
          });
        }
      }

      function onRotate(dimensions, rotation) {
        if ($scope.onRotate) {
          $scope.onRotate({
            $event: {
              dimensions: dimensions,
              rotation: rotation,
            },
          });
        }
      }
    }
  }
})();
