(function () {
  /**
   * @ngdoc component
   * @name abxQrCodeInput
   *
   * @param {Object} model - Asset or Room with a populated pinType and optionally populated QrCode
   * @param {Function} [onSave] - Function to call on save
   * @param {Boolean} [readOnly] - Property associated with displaying the Qr Code number
   *    as readonly text
   * @param {Boolean} [disabled] - Property to disable the QR Code input
   */
  angular
    .module("akitabox.ui.components.qrcodeInput", [
      "akitabox.constants",
      "akitabox.core.services.qrcode",
      "akitabox.core.services.shadow",
      "akitabox.core.toast",
      "akitabox.core.utils",
      "akitabox.ui.dialogs.qrScanner",
      "akitabox.ui.components.input",
    ])
    .component("abxQrCodeInput", {
      bindings: {
        model: "<abxModel",
        onSave: "&?abxOnSave",
        readOnly: "<?abxReadOnly",
        disabled: "<?abxDisabled",
      },
      controller: QRCodeInputController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/qrcode-input/qrcode-input.component.html",
    });

  /* @ngInject */
  function QRCodeInputController(
    // Services
    QRCodeService,
    ShadowService,
    ToastService,
    Utils,
    // Dialogs
    QrScannerDialog,
    // Utils
    media
  ) {
    var self = this;

    // Attributes
    self.enableSave = false;

    // Functions
    self.handleChange = handleChange;
    self.handleSave = handleSave;
    self.handleScan = handleScan;

    // Custom Errors
    var associatedCodeError = "This QR is already associated";
    var invalidCodeError = "Invalid QR Code";

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.model) {
        // clear out error messages any time the model changes
        clearErrorMessages();

        if (changes.model.currentValue && changes.model.currentValue.qrCode) {
          // The ng-model of the input will be a copy of the self.model's qrCode
          self.value = changes.model.currentValue.qrCode._code;
        } else {
          self.value = null;
        }

        if (self.model && !angular.isObject(self.model.pinType)) {
          throw new Error("QR Code Input: PinType is required");
        }
      }
    };

    // =================
    // Public Functions
    // =================
    /**
     * Handle input change
     * @param {Object}  $event - propagated event
     * @param {String}  $event.value - input value
     * @param {String}  [$event.model] - model value
     * @param {Boolean} [$event.invalid] - true iff generic string input is invalid
     */
    function handleChange($event) {
      clearErrorMessages();
      self.value = $event.value;

      var haveNewCode = !Utils.isSame(getOriginalCode(), self.value);
      if (angular.isDefined(self.value) && haveNewCode) {
        enableSave();
      } else {
        disableSave();
      }
    }

    /**
     * Save QR code
     */
    function handleSave() {
      startLoading();
      var association = {};
      var type = self.model.pinType.is_asset ? "asset" : "room";
      association[type] = self.model._id;

      associateQRCode(association).finally(function () {
        ShadowService.sendEvent("qr-code", "save-qr-id", self.value);
        stopLoading();
        disableSave();
      });
    }

    /**
     * Scan QR code
     */
    function handleScan() {
      media
        .getMedia(media.QR_SCANNER_CONSTRAINTS)
        .then(function (stream) {
          media.stopStream(stream);
          return QrScannerDialog.show({ redirect: false, returnModel: true });
        })
        .then(function (qrCode) {
          handleChange({ value: qrCode._code });
        })
        .catch(function (err) {
          if (err) {
            ToastService.showError("No camera available");
          }
        });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Associate QR Code with current model
     * @param {Object}  association - room/asset with pinID (e.g. { room: roomID }) to associate with the qr code
     */
    function associateQRCode(association) {
      var originalCode = getOriginalCode();
      return QRCodeService.replace(originalCode, self.value, association)
        .then(function () {
          // Product/Design wanted a toast on success
          ToastService.showError("Successfully Saved!");
        })
        .catch(function (err) {
          if (err.status === 400) {
            self.errorMessages.push(associatedCodeError);
          }
          if (err.status === 403) {
            self.errorMessages.push(associatedCodeError);
          }
          if (err.status === 404) {
            self.errorMessages.push(invalidCodeError);
          }
        });
    }

    /**
     * Get the original, currently associated QR code with the given model.
     *
     * @return {String} - Original code, iff it exists
     */
    function getOriginalCode() {
      return self.model && self.model.qrCode && self.model.qrCode._code
        ? self.model.qrCode._code
        : null;
    }

    /**
     * Clear any currently displayed error messages
     */
    function clearErrorMessages() {
      self.errorMessages = [];
    }

    /**
     * Display the loading indicator
     */
    function startLoading() {
      self.loading = true;
    }

    /**
     * Hide the loading indicator
     */
    function stopLoading() {
      self.loading = false;
    }

    /**
     * Enable the save button
     */
    function enableSave() {
      self.enableSave = true;
    }

    /**
     * Disable the save button
     */
    function disableSave() {
      self.enableSave = false;
    }
  }
})();
