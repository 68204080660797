(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineSelectionSection", {
      bindings: {
        catalog: "<abxCatalog",
        asset: "<abxAsset",
        setInvalid: "<abxSetInvalid",
        onCostLineDataChange: "<abxOnCostLineDataChange",
        existingCostLine: "<abxExistingCostLine",
      },
      controllerAs: "vm",
      controller: AbxCostLineSelectionSectionController,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/selection-section/cost-line-selection-section.component.html",
    });

  /* @ngInject */
  function AbxCostLineSelectionSectionController($q, CostDataService) {
    const _private = {};
    // =================
    // Properties
    // =================

    /**
     * @public
     * @type { CostLine[] }
     * Cost lines to display in the selection list
     */
    this.costLines = [];

    /**
     * @public
     * @type { object }
     * Cost line customization form data.
     */
    this.formData = {};

    /**
     * @public
     * @type { CostLine | null }
     * Currently selected cost line, if any
     */
    this.selectedCostLine = null;

    /**
     * @public
     * @type { string }
     * Currently selected division's uniformat
     */
    this.selectedDivisionUniformat = "";

    /**
     * @public
     * @type { string }
     * Model for the search input in the tree.
     */
    this.searchQuery = "";

    /**
     * @public
     * @type { boolean }
     * True when the selection list is loading.
     */
    this.listIsLoading = true;

    // =================
    // Lifecycle
    // =================
    this.$onChanges = function (changes) {
      if (changes.asset && this.catalog) {
        _private.refreshCostLines();
      }

      if (changes.existingCostLine || changes.catalog) {
        if (this.existingCostLine) {
          this.selectedDivisionUniformat = _private.getDivisionFromUniformat(
            this.existingCostLine.uniformat
          );
          this.searchQuery = this.existingCostLine.uniformat;
          _private.populateFormFromExistingCostLine();
        } else {
          this.selectedDivisionUniformat = "";
        }
        _private.refreshCostLines();
      }
    };

    // ================
    // Utils
    // ================

    /**
     * Get the division code from a uniformat. Used to control the default
     * search query when loading the dialog with an existing cost line.
     * @param {string} uniformat
     */
    _private.getDivisionFromUniformat = (uniformat) =>
      uniformat.substring(0, 8);

    _private.selectExistingCostLine = () => {
      if (!this.costLines || !this.existingCostLine) {
        return;
      }
      this.selectedCostLine = this.costLines.find(
        (cl) =>
          cl.id.toLowerCase() === this.existingCostLine.uniformat.toLowerCase()
      );
    };

    /**
     * Populate customization form data from `existingCostLine`
     * @returns
     */
    _private.populateFormFromExistingCostLine = () => {
      if (!this.existingCostLine) {
        return;
      }
      this.formData = {
        quantity: this.existingCostLine.quantity,
        baseCost: this.existingCostLine.total_cost,
        costPerUnit: this.existingCostLine.unit_cost,
        unit: this.existingCostLine.quantity_unit,
      };
    };

    // This value is used to invalidate previous fetches in the event
    // that we're already refetching by the time the results come back
    _private.costLineFetchCount = 0;

    /**
     * Invalidate the cost line list
     * @returns { Promise<void> }
     */
    _private.refreshCostLines = () => {
      let fetchPromise = $q.resolve([]);
      if (!this.selectedDivisionUniformat && this.searchQuery) {
        if (!CostDataService.looksLikeRSMeansUniformat(this.searchQuery)) {
          fetchPromise = CostDataService.searchCostLines(
            this.asset.building,
            this.catalog,
            this.searchQuery
          );
        } else {
          return $q.resolve();
        }
      } else if (this.selectedDivisionUniformat) {
        fetchPromise = CostDataService.getCostLines(
          this.asset.building,
          this.catalog,
          this.selectedDivisionUniformat
        );
      }

      const invalidation = ++_private.costLineFetchCount;
      this.setInvalid(true);
      this.listIsLoading = true;
      return fetchPromise.then(
        (costLines) => {
          if (invalidation < _private.costLineFetchCount) {
            return;
          }
          this.costLines = costLines;
          this.listIsLoading = false;

          this.selectedCostLine = null;
          _private.selectExistingCostLine();
          _private.emitCostLineData();
        },
        (err) => {
          if (invalidation !== _private.costLineFetchCount) {
            return;
          }
          this.listIsLoading = false;
        }
      );
    };

    /**
     * Notify the parent of changes to the relevant bits of the model for this
     * component.
     */
    _private.emitCostLineData = () => {
      this.onCostLineDataChange({
        model: {
          uniformat: this.selectedCostLine?.id,
          frequency: this.selectedCostLine?.frequency,
          description: this.selectedCostLine?.description,
          baseCost: this.formData.baseCost,
          quantity: this.formData.quantity,
          costPerUnit: this.formData.costPerUnit,
          unit: this.selectedCostLine?.unitOfMeasure,
        },
        invalid: false,
      });
    };

    // =================
    // Event Handlers
    // =================

    /**
     * @public
     * @param { CostLine | null } costLine
     * Change the currently selected cost line
     * @return { void }
     */
    this.handleCostLineSelect = (costLine) => {
      this.setInvalid(!costLine);
      this.selectedCostLine = costLine;
      if (costLine) {
        this.formData = {
          baseCost: costLine.baseCosts.totalOpCost,
          quantity: 1,
          costPerUnit: costLine.baseCosts.totalOpCost,
          unit: costLine.unitOfMeasure,
        };
      } else {
        this.formData = {};
      }
      _private.emitCostLineData();
    };

    /**
     * @public
     * Change the form data for the selected cost line
     * @param { object } event
     * @return { void }
     */
    this.handleFormDataChange = ({ model, invalid }) => {
      this.formData = model;
      _private.emitCostLineData();
    };

    /**
     * @public
     * Handle changes to the currently selected cost division in the tree
     * @param { object } event
     * @param { string } event.model The uniformat of the selected
     *  division.
     */
    this.handleDivisionChange = ({ model }) => {
      if (model !== this.selectedDivisionUniformat) {
        this.selectedDivisionUniformat = model;
        this.searchQuery = model;
        _private.refreshCostLines();
      }
    };

    /**
     * @public
     * Handle changes to the search input in the tree
     * @param { object } event
     * @param { string } event.model User search input
     */
    this.handleSearchQueryChange = ({ model }) => {
      this.searchQuery = model;
      if (!CostDataService.looksLikeRSMeansUniformat(model)) {
        this.selectedDivisionUniformat = "";
        _private.refreshCostLines();
      }
    };
  }
})();
