(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller(
      "FloorOpenWorkOrdersTabController",
      FloorOpenWorkOrdersTabController
    );

  /* @ngInject */
  function FloorOpenWorkOrdersTabController(
    // Angular
    $scope,
    // Constants
    EVENT_WORK_ORDER_CREATE,
    WORK_ORDER_STATUS_SCHEDULED_OPEN,
    // Resolve
    building,
    floor,
    // Services
    OrganizationService,
    WorkOrdersBFFService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.floor = floor;
    self.status = WORK_ORDER_STATUS_SCHEDULED_OPEN;
    self.workOrders = null;
    self.createOptions = getCreateOptions();
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_WORK_ORDER_CREATE, onWorkOrderCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function fetchWorkOrders(skip, limit) {
      var openParams = {
        status: self.status,
        level: self.floor._id,
        skip: skip,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        openParams
      )
        .then(function (workOrders) {
          if (angular.isArray(self.workOrders)) {
            self.workOrders = self.workOrders.concat(workOrders);
          } else {
            self.workOrders = workOrders;
          }
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllWorkOrders(limit) {
      var openParams = {
        status: self.status,
        level: self.floor._id,
        skip: 0,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        openParams
      )
        .then(function (workOrders) {
          self.workOrders = workOrders;
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getCreateOptions() {
      return {
        locals: {
          floors: [floor],
        },
      };
    }

    /**
     * Handle work order creation event
     *
     * @param {Event}       $event      Angular event
     * @param {WorkOrder[]} workOrders  List of new work orders
     */
    function onWorkOrderCreate($event, workOrders) {
      for (var i = 0; i < workOrders.length; ++i) {
        var workOrder = workOrders[i];
        if (!workOrder.level) {
          continue;
        }
        var floorId = Object.prototype.hasOwnProperty.call(
          workOrder.level,
          "_id"
        )
          ? workOrder.level._id
          : workOrder.level;
        if (floorId === self.floor._id) {
          if (!self.workOrders) {
            self.workOrders = [];
          }
          self.workOrders.unshift(workOrders[i]);
        }
      }
    }
  }
})();
