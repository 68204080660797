(function () {
  angular.module("akitabox.desktop.schedule.list", [
    "akitabox.core.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.filter",
    "akitabox.core.services.floor",
    "akitabox.core.services.maintenanceType",
    "akitabox.core.services.room",
    "akitabox.core.services.flag",
    "akitabox.core.services.schedule",
    "akitabox.core.services.trade",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.schedule.create",
    "akitabox.desktop.components.filterBar",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
