import { getENV } from "./getEnv";

/**
 * Returns CKEditor license key, based on current env
 * @returns license key
 */
export function getCKEditorKey(): string {
  const env = getENV();
  return env && env === "PRODUCTION"
    ? "UnZaRGYxQktCelkxL2Y4Nlc4R2theVJ0ZWZjWGtQNTN4OTZZSU9VNTZPUncwNWx3cDllQ2VGM3otTWpBeU5UQTFNak09"
    : "emkzNFNGVUVPQVZPMVloMDZmRk1XalF5OWc0WGRqbE14V2phSWxWT3h5NzRmOTJvNFd5NlRpUlItTWpBeU5UQTFNak09";
}
