(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("ManagedPinValueFilter", ManagedPinValueFilterClassFactory);

  /**
   * @ngInject
   * @return The ManagedPinValueFilter class
   */
  function ManagedPinValueFilterClassFactory(
    FilterBarManager,
    ManagedFilterConfiguration,
    PinTypeService,
    $q
  ) {
    /**
     * @class
     * @extends ManagedFilterConfiguration
     * @param {FilterBarManager} manager - The manager that owns this configuration.
     * @param {object} config - Configuration options. See
     *  `ManagedFilterConfiguration` class for documentation of base class options.
     * @param {string} config.pinFieldName - The name of the pinField that this
     *  filter should query against
     * @param {() => string} config.getBuildingId - A function that should return
     *  the building ID to use for fetching pin metadata. Can be provided here, or
     *  as an override in a subclass.
     * @param {() => string | Promise<string>} [config.getPinTypeId] - A function
     *  that should return the pinType ID to use when fetching pin fields.
     *  Defaults to reading the pinType filter from the manager. Can be provided
     *  here, or overridden in a subclass.
     *  NOTE: Either getPinTypeId or pinTypeConfig must be supplied. Reading
     *  from the manager fails in the workflow where the last pin value filter
     *  is applied with a new value and it has no pinTypeConfig, as the pin type
     *  filter is cleared during removal of the old filter value..
     * @param {ManagedFilterConfiguration} [config.pinTypeConfig] - If not
     *  supplied, the pin type filter will be cleared when all pin value filters
     *  are removed.
     *  NOTE: Either getPinTypeId or pinTypeConfig must be supplied. See above
     *  for details.
     * @param { "Room" | "Asset" } [config.protected_type] - The classification
     *  of pinType to filter for. Defaults to none (all PinTypes in the building
     *  are considered).
     */
    function ManagedPinValueFilter(manager, config) {
      // super(manager, config);
      ManagedFilterConfiguration.call(this, manager, config);

      if (typeof (config.getBuildingId || this.getBuildingId) !== "function") {
        throw new Error(
          "ManagedPinValueFilter: getBuildingId must be provided"
        );
      }

      if (!config.getPinTypeId && !config.pinTypeConfig) {
        throw new Error(
          "ManagedPinValueFilter: At least one of getPinTypeId, pinTypeConfig must be provided"
        );
      }

      if (config.protected_type) {
        if (
          config.protected_type !== "Room" &&
          config.protected_type !== "Asset"
        ) {
          throw new Error("Invalid protected_type. Must be ROOM or ASSET");
        }
        /** @member { "Room" | "Asset" } */
        this.protected_type = config.protected_type;
      }

      if (config.getBuildingId) {
        /** @member {() => string} */
        this.getBuildingId = config.getBuildingId;
      }

      if (config.getPinTypeId) {
        this.getPinTypeId = config.getPinTypeId;
      }

      /** @member { string } */
      this.pinFieldName = config.pinFieldName;

      if (config.pinTypeConfig) {
        /** @member { ManagedFilterConfiguration | undefined } */
        this.pinTypeConfig = config.pinTypeConfig;
      }

      /**
       * @member
       * @type { string }
       * The ID for the managed pin field. Updated when filter is applied.
       * Used to remove the filter.
       */
      this.pinFieldId = undefined;
    }
    // ManagedPinValueFilter extends ManagedFilterConfiguration
    ManagedPinValueFilter.prototype = Object.create(
      ManagedFilterConfiguration.prototype
    );
    ManagedPinValueFilter.prototype.constructor = ManagedPinValueFilter;

    ManagedPinValueFilter.prototype.applyFilter = function (filterValue) {
      var self = this;
      return this._fetchPinField().then(function (pinField) {
        if (self.getPinTypeId && !self.pinTypeConfig) {
          self.manager.setPinTypeFilter(self.getPinTypeId());
        }
        if (!self.manager.getPinTypeFilter()) {
          throw new Error(
            "Error applying " +
              self.displayName +
              " filter. Missing pin category."
          );
        }
        self.manager.addPinValueFilter(pinField._id, filterValue);
        self.pinFieldId = pinField._id;
      });
    };

    ManagedPinValueFilter.prototype.removeFilter = function () {
      this.manager.removePinValueFilter(this.pinFieldId);
      if (!this.pinTypeConfig) {
        // no pin type config, clear pin type filter if this is the last one
        // removed
        var pinValueFilters = this.manager.getPinValueFilters();
        if (Object.keys(pinValueFilters).length === 0) {
          this.manager.setPinTypeFilter(undefined);
        }
      }
    };

    ManagedPinValueFilter.prototype.getFilterValue = function (manager) {
      return this._fetchPinField()
        .then(function (pinField) {
          return manager.getPinValueFilter(pinField._id);
        })
        .catch(function () {
          // no pinfield, no filter value
          return undefined;
        });
    };

    /**
     * Runs a one-time asynchronous check to determine if this PinField
     * is hidden. Resolves if visible. Otherwise, rejects.
     * @return {Promise}
     */
    ManagedPinValueFilter.prototype.onAdd = function () {
      var self = this;

      return self._fetchPinTypes().then(function (pinTypes) {
        var getPinField = self._getPinField.bind(self);
        var isPresentAndEnabled = function (pinField) {
          if (!pinField) {
            return false;
          }
          return !pinField.is_hidden;
        };
        // if any pin type has the field and it is not hidden, then keep this
        // filter
        var enabled = pinTypes
          .map(getPinField)
          .map(isPresentAndEnabled)
          .some(function (result) {
            return result;
          });
        if (enabled) {
          return true;
        } else {
          // Do not show filter, but no error occurred
          return $q.reject(false);
        }
      });
    };

    /**
     * Fetch the pin type ID to use when fetching pin fields. This method may
     * be overridden via the constructor options. Defaults to reading the pinType
     * filter from the manager.
     * @return { string } The pinType ID to use, or undefined if noe is not set.
     */
    ManagedPinValueFilter.prototype.getPinTypeId = function () {
      return this.manager.getPinTypeFilter();
    };

    /**
     * @protected
     * Select this filter's pinField from the provided pinType.
     * @return { PinField } The pinfield with the same name as this.pinFieldName
     */
    ManagedPinValueFilter.prototype._getPinField = function (pinType) {
      var self = this;
      return pinType.fields.find(function (pinField) {
        return pinField.name === self.pinFieldName;
      });
    };

    /**
     * @protected
     * Fetch pin types. Filtered by building, protected_type, and (if set), the
     * current pin type filter.
     * @return { Array<PinType> }
     */
    ManagedPinValueFilter.prototype._fetchPinTypes = function () {
      var self = this;
      var params = {};
      if (self.protected_type) {
        params.protected_type = self.protected_type;
      }

      var pinTypeId = self.getPinTypeId();
      if (pinTypeId) {
        return PinTypeService.getById(self.getBuildingId(), pinTypeId).then(
          function (pinType) {
            return [pinType];
          }
        );
      } else {
        return PinTypeService.getAll(self.getBuildingId(), params);
      }
    };

    /**
     * @protected
     * Fetch the (single) pin field for this filter on the current pin type.
     * Will reject if there is no single pin type ID available.
     * @return { Promise<PinField> } The fetched pinField. Rejects with the
     * API error, if any. Rejects with `ERR_NO_PIN_TYPE_FILTER_SET` if there is
     * no pinType filter set on the manager. Rejects with `ERR_NOT_FOUND` if the
     * pinField or pinType was not found.
     */
    ManagedPinValueFilter.prototype._fetchPinField = function () {
      var self = this;
      var buildingId = this.getBuildingId();
      var pinTypeId = this.getPinTypeId();
      if (!pinTypeId) {
        return $q.reject(new Error("No pin type filter set"));
      }

      return PinTypeService.getFieldByName(
        buildingId,
        pinTypeId,
        self.pinFieldName
      ).then(function (pinField) {
        if (!pinField) {
          return $q.reject(
            new Error("Pin field not found: " + self.pinFieldName)
          );
        }
        return pinField;
      });
    };

    return ManagedPinValueFilter;
  }
})();
