const markdown = require("markdown-it");
(function () {
  angular
    .module("akitabox.core.lib.markdown", [])
    .factory("markdown", MarkdownService);

  /* @ngInject */
  function MarkdownService() {
    if (!angular.isDefined(markdown))
      throw new Error("markdown is not available");
    return markdown;
  }
})();
