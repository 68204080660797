import { FunctionComponent, SyntheticEvent, useMemo, useState } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";

import { Color } from "../colors";
import { stylesheet } from "../stylesheet";
import { ReportBlockResponse } from "@akitabox/api-client";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { api } from "../api";
import { ReportBlockContext } from "./ReportBlockSelectionDataGrid";

export interface ReportBlockSelectionDataTemplateProps {
  organizationId: string;
  selectedContext?: ReportBlockContext;
  selectedReportBlock?: ReportBlockResponse;
  onReportBlockChange: (reportBlock: ReportBlockResponse | null) => void;
  onContextChange: React.Dispatch<
    React.SetStateAction<ReportBlockContext | "">
  >;
}

export const ReportBlockSelectionDataTemplate: FunctionComponent<
  ReportBlockSelectionDataTemplateProps
> = ({
  organizationId,
  selectedReportBlock,
  selectedContext,
  onContextChange,
  onReportBlockChange,
}) => {
  const [context, setContext] = useState<ReportBlockContext | "">(
    selectedContext || ""
  );
  const [reportBlock, setReportBlock] = useState<ReportBlockResponse | null>(
    selectedReportBlock || null
  );

  const { data: reportBlocksResponse } = makeUseServiceCall(
    api.reportBlocks.getByOrganization
  )({
    organizationId,
    ...((context && { context }) || {}),
  });

  const reportBlocks = useMemo(() => {
    if (reportBlocksResponse) {
      return reportBlocksResponse.data;
    }
    return [];
  }, [reportBlocksResponse]);

  return (
    <Paper variant="outlined" elevation={0} css={ss.wrapper}>
      <FormControl fullWidth>
        <InputLabel id="context-label">
          {context ? "Context" : "Pick a context"}
        </InputLabel>
        <Select
          labelId="context-label"
          value={context}
          required
          onChange={(e) => {
            const value = e.target.value;
            let context: ReportBlockContext | "" = "";
            if (value === "asset" || value === "building") {
              context = value;
            }

            setContext(context);
            onContextChange(context);

            // clear out the report block input
            setReportBlock(null);
            onReportBlockChange(null);
          }}
        >
          <MenuItem value="asset">Assets</MenuItem>
          <MenuItem value="building">Buildings</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth css={(theme) => ({ marginTop: theme.spacing(2) })}>
        <Autocomplete<ReportBlockResponse>
          id="report-block-input"
          options={reportBlocks}
          disabled={!context}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={context ? "Block" : "Context is required"}
            />
          )}
          value={reportBlock}
          onChange={(_e: SyntheticEvent, value) => {
            setReportBlock(value);
            onReportBlockChange(value);
          }}
        />
      </FormControl>
    </Paper>
  );
};

const ss = stylesheet({
  higherRootBox: { height: "200px" },
  smallerRootBox: { width: "135px" },
  box: { backgroundColor: Color.MediumGray },
  singleBox: { gridTemplateColumns: "repeat(1, 1fr)" },
  rowRootBox: { flexDirection: "row", width: "220px" },
  detailsBox: { gridTemplateColumns: "repeat(2, 1fr)" },
  radioInput: (theme) => ({ margin: `0 ${theme.spacing(1)}` }),
  gridBox: {
    gridTemplateColumns: "repeat(4, 1fr)",
    "& div": { minHeight: "20px" },
  },
  wrapper: (theme) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: theme.spacing(2),
  }),
  boxWrapper: (theme) => ({
    display: "grid",
    flex: "1 0 auto",
    gap: theme.spacing(1),
  }),
  rootBox: (theme) => ({
    display: "flex",
    flexDirection: "column",
    width: "180px",
    height: "135px",
    boxShadow: `0 0 0 2px ${Color.MediumGray}`,
    padding: `${theme.spacing(6)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    position: "relative",
    gap: theme.spacing(1),
    ":before": {
      content: '"NAME"',
      backgroundColor: Color.MediumGray,
      color: Color.White,
      fontWeight: 600,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      padding: theme.spacing(1),
      fontSize: theme.typography.fontSize,
    },
  }),
});
