(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.chartLegend
   *
   * @param {Object} data - data to display in the legend
   */
  angular
    .module("akitabox.ui.components.reportingCharts.chartLegend", [
      "akitabox.core.services.chart",
      "akitabox.core.services.flag",
    ])
    .component("abxChartLegend", {
      bindings: {
        onChange: "&abxOnChange",
        onStackHover: "&abxOnStackHover",
        options: "<abxOptions",
        disabledOptions: "<?abxDisabledOptions",
        values: "<abxValues",
        model: "<abxModel",
        idTag: "<abxIdTag",
        yAxisLabel: "<?abxYAxisLabel",
      },
      controller: AbxChartLegendController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/chart-legend/chart-legend.component.html",
    });

  /** @ngInject */
  function AbxChartLegendController(ChartService) {
    var self = this;

    // Attributes
    self.hasOptions = false;
    self.showStackWarning = false;
    self.stackWarning = null;

    // Functions
    self.isAssigneeModel = isAssigneeModel;
    self.normalize = ChartService.normalize;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (self.options && self.options.length) {
        if (self.options.length === 1) {
          self.hasOptions = self.options[0] !== "---";
        } else {
          self.hasOptions = true;
        }
      }
    };

    self.$onChanges = function () {
      var yAxisLabel = self.yAxisLabel;

      var yAxisCountField =
        yAxisLabel === "Completed" ||
        yAxisLabel === "Open" ||
        yAxisLabel === "Overdue" ||
        yAxisLabel === "Scheduled";

      /**
       * When the stackOptions are changed, determine if a warning should be
       * shown indicating that the totals may not be accurate.
       */
      self.stackWarning = null;
      self.showStackWarning = function () {
        if (
          self.model === "Work Performed By" &&
          (yAxisCountField || self.idTag === "status-chart")
        ) {
          /**
           * This is the case when stacking by Work Performed By and using a WO "Count" field
           * on the y-axis. Additionally, show the warning if stacking by Work Performed By
           * on the Status Chart (because it inherently displays WO counts on the y-axis)
           */
          self.stackWarning =
            'Bar totals may not reflect the true number of work orders when stacking by "Work Performed By"';
          return true;
        } else if (self.model === "Assignee" && self.idTag === "status-chart") {
          /**
           * The numbers will look like they've doubled when stacking by assignee
           * So we have to warn the users of that
           */
          self.stackWarning =
            'Bar totals may not reflect the true number of work orders when stacking by "Assignee"';
          return true;
        }

        return false;
      };
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function isAssigneeModel() {
      return self.model === "Assignee" || self.model === "Work Performed By";
    }
  }
})();
