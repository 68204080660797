(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.list.document
   */
  angular
    .module("akitabox.desktop.directives.list.document", [
      "angular.filter",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.document",
    ])
    .directive("abxDocumentList", AbxDocumentListDirective);

  /**
   * @ngdoc directive
   * @module akitabox.mobile.directives.list.document
   * @name AbxDocumentList
   *
   * @restrict E
   *
   * @description
   * `<abx-document-list>` is a list of either active or archived documents that belong to the current building.
   * It shows the batch actions bar upon selection.
   *
   *  @usage
   *  <abx-document-list
   *          models="vm.documents"
   *          abx-fetch="vm.fetchDocs(skip, limit)"
   *          abx-fetch-all="vm.fetchAllDocs(limit)"
   *          abx-fetch-count-all="vm.fetchCountAllDocs()"
   *          archived="vm.archived"
   *          building="vm.building">
   *  </abx-document-list>
   *
   * @ngInject
   */
  function AbxDocumentListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/document/document-list.html",
      controller: DocumentListController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        models: "=abxModels",
        fetch: "&abxFetch",
        fetchAll: "&abxFetchAll",
        fetchCountAll: "&?abxFetchCountAll",
        onClickTag: "&abxOnClickTag",
        archived: "<abxArchived",
        building: "=?abxBuilding",
        allowAdd: "&?abxAllowAdd",
      },
    };
  }

  /* @ngInject */
  function DocumentListController(
    // Angular
    $scope,
    $timeout,
    // Dialogs
    ArchiveDocumentDialog,
    DeleteDocumentDialog,
    DownloadDocumentDialog,
    RestoreDocumentDialog,
    TagDocumentDialog,
    // Services
    BuildingService,
    UserService,
    ToastService
  ) {
    var self = this;
    var userPermissions = UserService.getPermissions();

    // Attributes
    self.archived = self.archived === true || self.archived === "true";
    self.permissions = getDefaultPermissions();
    self.buildings = {};

    // Functions
    self.getListActions = getListActions;
    self.fetchDocs = fetchDocs;
    self.fetchAllDocs = fetchAllDocs;
    self.clickTag = clickTag;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      if (!self.building) {
        $scope.$watchCollection("vm.models", function (models) {
          if (models && !self.building) {
            BuildingService.populateBuildings(self.buildings, models);
          }
        });
      }
      self.permissions.canDelete = userPermissions.document.remove;
      self.permissions.canTag = userPermissions.tag.create && !self.archived;
      self.permissions.canRestore = userPermissions.document.unarchive;
      self.permissions.canArchive = userPermissions.document.archive;
    }

    function getDefaultPermissions() {
      return {
        canTag: false,
        canArchive: false,
        canRestore: false,
        canDelete: false,
      };
    }

    function downloadDocuments(items) {
      var docs = [];

      for (var i = 0; i < items.length; i++) {
        docs.push(self.models[items[i]]);
      }

      var locals = {
        docs: docs,
      };

      return DownloadDocumentDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function tagDocuments(items) {
      var docs = [];

      for (var i = 0; i < items.length; i++) {
        docs.push(self.models[items[i]]);
      }

      var locals = {
        documents: docs,
      };

      return TagDocumentDialog.show({ locals: locals })
        .then(function (docs) {
          for (var i = 0; i < docs.length; i++) {
            self.models.splice(items[i], 1, docs[i]);
          }
          $timeout(function () {
            $scope.$broadcast("list:setSelectedIndices", items);
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function archiveDocuments(items) {
      var locals = {
        documents: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };
      return ArchiveDocumentDialog.show({ locals: locals })
        .then(function (archiveDocumentIDs) {
          // update self.models to remove the archived documents
          self.models = self.models.filter(function (document) {
            return archiveDocumentIDs.indexOf(document._id) < 0;
          });
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function restoreDocuments(items) {
      var locals = {
        documents: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };
      return RestoreDocumentDialog.show({ locals: locals })
        .then(function (restoreDocumentIDs) {
          // update self.models to remove the restored documents
          if (self.models.length === restoreDocumentIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (document) {
              return restoreDocumentIDs.indexOf(document._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function deleteDocuments(items) {
      var locals = {
        documents: items.map(function (item) {
          return {
            _id: self.models[item]._id,
            building: self.models[item].building,
          };
        }),
      };
      return DeleteDocumentDialog.show({ locals: locals })
        .then(function (deletedDocuments) {
          var deletedDocumentIDs = deletedDocuments.map(function (
            deletedDocument
          ) {
            return deletedDocument._id;
          });
          // update self.models to remove the deleted documents
          if (self.models.length === deletedDocuments.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (document) {
              return deletedDocumentIDs.indexOf(document._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getListActions(items) {
      var tagAction = {
        text: "Tag",
        icon: "local_offer",
        onClick: tagDocuments,
      };
      var archiveAction = {
        text: "Archive",
        icon: "archive",
        onClick: archiveDocuments,
      };
      var restoreAction = {
        text: "Restore",
        icon: "unarchive",
        onClick: restoreDocuments,
      };
      var deleteAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteDocuments,
      };
      var actions = [
        {
          text: "Download",
          icon: "get_app",
          onClick: downloadDocuments,
        },
      ];

      // Add tag if user has permission
      if (self.permissions.canTag && (self.building || items.length === 1)) {
        actions.push(tagAction);
      }

      // Add archive if not archived and restore + delete if document is archived
      if (self.archived && self.permissions.canRestore) {
        actions.push(restoreAction);
      } else if (self.permissions.canArchive) {
        actions.push(archiveAction);
      }
      if (self.archived && self.permissions.canDelete) {
        actions.push(deleteAction);
      }

      return actions;
    }

    function fetchDocs(skip, limit) {
      return self.fetch({
        skip: skip,
        limit: limit,
      });
    }

    function fetchAllDocs(limit) {
      return self.fetchAll({
        limit: limit,
      });
    }

    function clickTag(tag) {
      return self.onClickTag({
        tag: tag,
      });
    }
  }
})();
