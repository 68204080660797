(function () {
  angular
    .module("akitabox.core.services.catalog", [
      "angular-cache",
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("CatalogService", CatalogService);

  /* @ngInject */
  function CatalogService(
    // AkitaBox
    models,
    HttpService
  ) {
    const service = { getAll };
    return service;

    function buildListRoute() {
      return `/${models.CATALOG.ROUTE_PLURAL}`;
    }

    /**
     * Fetches all catalogs
     *
     * @param {object} params - an object of query string parameters
     */
    function getAll(params = {}) {
      return HttpService.getAll(buildListRoute(), params);
    }
  }
})();
