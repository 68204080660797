(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedEnumPinValueFilter",
      ManagedEnumPinValueFilterClassFactory
    );

  /**
   * @ngInject
   * @return The ManagedEnumPinValueFilter class
   */
  function ManagedEnumPinValueFilterClassFactory(
    FilterBarManager,
    ManagedPinValueFilter,
    $q
  ) {
    /**
     * @typedef ManagedEnumPinValueModel
     * @member { string } value - The enum value itself
     * @member { string } pinField - The pin field ID
     * @member { string } pinType - The pin type ID
     */
    /**
     * @typedef ManagedEnumPinValueOption
     * @member { string } group - The pin type name to group options on
     * @member { string } value - The acceptable enum value
     * @member { ManagedEnumPinValueModel } model
     */
    /**
     * @class
     * Class handling enum pin values. Presents a grouped typeahead of acceptable
     * enum options.
     * @extends ManagedPinValueFilterConfiguration
     * @param {FilterBarManager} manager - The manager that owns this configuration.
     * @param {object} config - Configuration options. See
     *  `ManagedPinValueFilterConfiguration` class for documentation of super
     *  class options.
     * @param {ManagedFilterConfiguration} [pinTypeConfig] - Related pin type
     *  configuration for this filter. If supplied, the pin type filter will
     *  be applied when this filter is applied.0
     * Note that the input type (typeahead) and related methods/options are
     * provided by this class and do not need to be supplied as config options.
     */
    function ManagedEnumPinValueFilter(manager, config) {
      // don't need to check for manager here, it will blow up in the super call
      if (!config) config = {};

      config = angular.extend({}, config);
      config.inputType = "typeahead";
      config.optionsGroupKey = "group";
      if (!config.getPinTypeId) {
        config.getPinTypeId = this.getPinTypeId;
      }

      ManagedPinValueFilter.call(this, manager, config);
    }
    // ManagedEnumPinValueFilter extends ManagedPinValueFilter
    ManagedEnumPinValueFilter.prototype = Object.create(
      ManagedPinValueFilter.prototype
    );
    ManagedEnumPinValueFilter.prototype.constructor = ManagedEnumPinValueFilter;

    /**
     * @return {Array<{}>}
     */
    ManagedEnumPinValueFilter.prototype.getEnumOptions = function () {
      var self = this;
      return this._fetchPinTypes().then(function (pinTypes) {
        return pinTypes.reduce(function (resultArray, pinType) {
          return resultArray.concat(self._getAcceptableEnumOptions(pinType));
        }, []);
      });
    };

    /**
     * Apply filter to the manager.
     * @param { { pinFieldId: string, pinTypeId: string, value: string } } filterValue
     * @return { Promise<{}>| undefined }
     */
    ManagedEnumPinValueFilter.prototype.applyFilter = function (filterValue) {
      var self = this;
      var applyThisFilter = function () {
        // need to set this pin field so we can properly remove this
        // filter later when needed
        self.pinFieldId = filterValue.pinFieldId;
        self.manager.addPinValueFilter(
          filterValue.pinFieldId,
          filterValue.value
        );
      };

      if (this.manager.getPinTypeFilter() === filterValue.pinTypeId) {
        // the associated pin type filter has already been applied, just do
        // the normal application of this filter
        applyThisFilter();
      } else {
        // The associated pin type filter has not been applied yet, do that
        // first, then apply this filter
        this.manager.setPinTypeFilter(filterValue.pinTypeId);
        if (this.pinTypeConfig) {
          return this.pinTypeConfig
            .getInputValue(this.pinTypeConfig.manager)
            .then(function (inputValue) {
              return self.pinTypeConfig.onApply(inputValue[0]);
            })
            .then(function () {
              applyThisFilter();
            });
        } else {
          applyThisFilter();
        }
      }
    };

    /**
     * Transform the model to a filter value
     * @param { ManagedEnumPinValueModel } inputModel
     * @return { { pinFieldId: string, value: string } }
     */
    ManagedEnumPinValueFilter.prototype.modelValueToFilterValue = function (
      inputModel
    ) {
      return {
        pinTypeId: inputModel.pinType,
        pinFieldId: inputModel.pinField,
        value: inputModel.value,
      };
    };

    /**
     * Create an input model option for the given enum value. Rejects if no
     * pin type is available.
     * @return { ManagedEnumPinValueModel }
     */
    ManagedEnumPinValueFilter.prototype.filterValueToModelValue = function (
      enumValue
    ) {
      var self = this;
      var pinTypeId = this.getPinTypeId();
      if (!pinTypeId) {
        return $q.reject(new Error("No pin type available to load value from"));
      }
      return this._fetchPinTypes().then(function (pinTypes) {
        var pinType = pinTypes[0];
        var pinField = self._getPinField(pinType);
        return [
          self._createEnumOption(
            pinType.name,
            pinType._id,
            pinField._id,
            enumValue
          ).model,
        ];
      });
    };

    /**
     * Enum options themselves are used as chip text.
     * @param { ManagedEnumPinValueModel } inputModel
     * @return { string }
     */
    ManagedEnumPinValueFilter.prototype.modelValueToChipText = function (
      inputModel
    ) {
      return inputModel.value;
    };

    /**
     * @protected
     * Get an array of enum options representing the acceptable enum values of
     * the managed pin field of `pinType`.
     * @return { ManagedEnumPinValueOption[] }
     */
    ManagedEnumPinValueFilter.prototype._getAcceptableEnumOptions = function (
      pinType
    ) {
      var self = this;
      var pinField = this._getPinField(pinType);
      // pinField is not present on the type or is hidden
      if (!pinField || pinField.is_hidden) {
        return [];
      }
      return pinField.acceptable_enum_values.map(function (value) {
        return self._createEnumOption(
          pinType.name,
          pinType._id,
          pinField._id,
          value
        );
      });
    };

    /**
     * @protected
     * Create a single enum option object.
     * @param { string } pinTypeName
     * @param { string } pinTypeId
     * @param { string } pinFieldId
     * @param { string } value
     * @return { ManagedEnumPinValueOption[] }
     */
    ManagedEnumPinValueFilter.prototype._createEnumOption = function (
      pinTypeName,
      pinTypeId,
      pinFieldId,
      value
    ) {
      return {
        group: pinTypeName,
        model: {
          value: value,
          pinField: pinFieldId,
          pinType: pinTypeId,
        },
        value: value,
      };
    };

    return ManagedEnumPinValueFilter;
  }
})();
