import { getFromCookies } from "./getFromCookies";

/**
 * Used to access the current org subdomain
 * @param host Location host, defaults to `window.location.host`
 * @returns The application's first subdomain. In most cases,
 * this will be an akitabox client organization's subdomain.
 * @example
 * // URL: "http://myorg.beta-abx.com"
 * getSubdomain(); // "myorg"
 */
export function getSubdomain(host = window.location.host) {
  const hostParts = host.split(".");
  if (hostParts.length < 3) {
    // try to get subdomain from cookies
    return getFromCookies("subdomain");
  }
  // safe to say we have the subdomain in the URL host
  return hostParts[0];
}
