(function () {
  angular
    .module("akitabox.ui.dialogs.export")
    .factory("ExportDialog", ExportDialog);

  /* @ngInject */
  function ExportDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/export/export.dialog.html",
      controller: "ExportDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * CreateFloorDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}          options                 Dialog options
     * @param {Object}          options.locals          Locals scope variables
     * @param {String}          options.locals.route    Base request route for document
     * @param {Object}          options.locals.filters  [Optional] Query filter parameters
     * @param {Array}           options.locals.selected [Optional] List of selected entity IDs to export
     * @param {Array|Boolean}   options.locals.print    [Optional] List of print options
     *                                                             Defaults to `false`
     *
     * @return {Promise<Object|Error}       Promise that resolves with the newly created floor
     */
    function show(options) {
      var genericErrorMsg = "Sorry, we weren't able to complete your request.";
      // Verify we have a base route
      if (!options || !options.locals) {
        $log.error("ExportDialog: no locals provided");
        return $q.reject(genericErrorMsg);
      } else if (!options.locals.route) {
        $log.error("ExportDialog: no route local provided");
        return $q.reject(genericErrorMsg);
      } else if (options.locals.selected && !options.locals.selected.length) {
        $log.error("ExportDialog: empty selected local was provided");
        return $q.reject(genericErrorMsg);
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
