(function () {
  angular
    .module("akitabox.core.toast", ["akitabox.core.utils"])
    .provider("ToastService", ToastServiceProvider);

  /* The ToastService returns an object with three methods
   * for showing pertinent messages to the user:
   *      showSimple(message): Immediately notifies the user with `message`
   *                       being the text of the message.
   *
   *      complex(): Returns an object with the below methods. Allows
   *        |        You to construct a more complex message and then
   *        |        send it. All methods (except show()) return the
   *        |        object for method chaining.
   *        |
   *        ->action(name, callback, highlightClass):
   *        |
   *        |     Add an action titled `name` to the toast.
   *        |     On click, `callback` is run.
   *        |
   *        ->text(message): Set the message text
   *        |
   *        ->show():        Show the toast
   *
   *        showError(response): Immediately notifies the user with `message`
   *                           being the response error or a generic message
   */

  /* @ngInject */
  function ToastServiceProvider($injector) {
    this.$get = $injector.has("$mdToastProvider")
      ? MobileToastService
      : DesktopToastService;
    /* This template supports SYNCHRONOUS link button behavior. This is the only way to open a link
     * in a new tab without triggering chrome's popup-blocker. It's the same as the default, except it
     * decorates the toast.resolve triggered by the action button's ng-click in a way that won't screw
     * with the standard behavior when it's not needed.
     */
    var linkToastTemplate =
      '<md-toast md-theme="{{ toast.theme }}" ng-class="{\'md-capsule\': toast.capsule}">' +
      '  <div class="md-toast-content">' +
      '    <span class="md-toast-text" role="alert" aria-relevant="all" aria-atomic="true">' +
      "      {{ toast.content }}" +
      "    </span>" +
      '    <md-button class="md-action" ng-if="toast.action" ng-click="toast.openLink(toast.resolve)" ' +
      '        ng-class="highlightClasses">' +
      "      {{ toast.action }}" +
      "    </md-button>" +
      "  </div>" +
      "</md-toast>";

    /* MobileToastServices implements the toast functionality
     * using $mdToast. Unfortunately, that means that you
     * can only have one action per toast. To have more, we'd need
     * a custom template.*/
    /* @ngInject */
    function MobileToastService($log, $mdToast, Utils, $q, $window) {
      var throttledShow = Utils.throttle($mdToast.show, 3500);
      var throttledShowSimple = Utils.throttle($mdToast.showSimple, 3500);

      return {
        show: show,
        showSimple: showSimple,
        complex: complex,
        showError: showError,
      };

      function show(options, throttled) {
        if (throttled) {
          return throttledShow(options);
        }

        return $mdToast.show(options);
      }

      function showSimple(message, throttled) {
        if (throttled) {
          return throttledShowSimple(message);
        }
        return $mdToast.showSimple(message);
      }

      /**
       *
       * @param [options]
       * @param [options.link]        Describes what link to open, and how
       * @param [options.link.url]    The url to open (synchronously)
       * @param [options.link.target] The target param for window.open, either _self or _blank. Defaults _blank
       */
      function complex(options) {
        options = angular.isObject(options) ? options : {};
        var locals = angular.isObject(options.locals) ? options.locals : {};
        var delayInMilliseconds = 7500;
        // hideDelay sets the display duration in milliseconds
        var toastOptions = { locals: locals, hideDelay: delayInMilliseconds };
        if (options && angular.isObject(options.link)) {
          var target = options.link.target ? options.link.target : "_blank";
          var url = options.link.url ? options.link.url : null;
          if (url) toastOptions.template = linkToastTemplate;

          locals.openLink = function (resolveToast) {
            if (angular.isString(url)) {
              $window.open(url, target);
            }

            return resolveToast();
          };
        }

        var chain = {
          _base: $mdToast.simple(toastOptions),
          _callback: undefined,
          text: text,
          action: action,
          show: show,
        };

        return chain;

        // Change the text of the toast.
        function text(message) {
          this._base = this._base.textContent(message);
          return this;
        }

        // Add an action button to the toast.
        function action(name, callback, highlightClass) {
          // Can't have more than one action
          if (angular.isDefined(this._callback)) {
            return $log.error("Toast cannot have more than one action");
          }

          this._base = this._base.action(name);
          if (highlightClass)
            this._base.highlightAction(true).highlightClass(highlightClass);
          this._callback = callback;
          return this;
        }

        // Show the toast.
        function show() {
          var callback = this._callback;
          return $mdToast.show(this._base).then(function (value) {
            // Don't bother if there isn't a callback
            if (angular.isDefined(callback) && value === "ok") callback();
            return value;
          });
        }
      }

      function showError(error, throttled) {
        // Check to see if we have an error, since closing a dialog will return undefined to .catch
        if (angular.isDefined(error)) {
          // Show message if provided one
          if (angular.isString(error)) return showSimple(error);

          var message;
          if (error && error.message) {
            // Thrown error
            message = error.message;
          } else if (
            error &&
            error.data &&
            error.data.error &&
            error.data.error.message
          ) {
            // HTTP error response
            message = error.data.error.message;
          } else if (error && !navigator.onLine) {
            message =
              "We were not able to complete your request. Please check your internet connection and try again.";
          } else {
            // Default
            message = "Sorry, we weren't able to complete your request.";
          }
          showSimple(message, throttled);
        }
        return $q.reject(error);
      }
    }

    /* @ngInject */
    function DesktopToastService($log, $q) {
      return {
        showSimple: function () {},
        complex: function () {},
        showError: showError,
      };

      function showError(error) {
        if (angular.isDefined(error)) {
          $log.error(error);
        }
        return $q.reject(error);
      }
    }
  }
})();
