angular.module("akitabox.ui.dialogs").controller(
  "ConfirmationDialogController",
  /**
   * @class
   * @ngInject
   */
  function AbxConfirmationDialogController($mdDialog, $q, $sce) {
    // ===========
    // Locals (Provided via calls to the service)
    // ===========

    /**
     * Callback invoked when the confirm button is clicked. If this function
     * rejects, the dialog will not be closed. If it resolves, it will.
     * @type { () => Promise<void> }
     */
    this.onConfirm;

    /**
     * Dialog title
     * @type { string }
     */
    this.title;

    /**
     * Dialog text
     * @type { string }
     */
    this.prompt = $sce.trustAsHtml(this.prompt);

    /**
     * Whether or not this action is destructive.
     * @type { boolean }
     */
    this.isDestructive;

    /**
     * Text to display on the confirm button.
     * @type { string }
     */
    this.confirmText;

    /**
     * Custom class added to the root el of the dialog.
     * @type { string }
     */
    this.customClass;

    // ===========
    // State
    // ===========

    /**
     * @type { boolean }
     */
    this.saving = false;

    // ===========
    // Public Methods
    // ===========

    /**
     * @public
     * @type { () => void }
     */
    this.cancel = () => $mdDialog.cancel();

    /**
     * @public
     * @type { () => Promise<void> }
     */
    this.handleConfirmClick = () => {
      this.saving = true;
      $q.resolve(this.onConfirm()).then(
        (result) => {
          this.saving = false;
          return $mdDialog.hide(result);
        },
        (_err) => {
          this.saving = false;
        }
      );
    };
  }
);
