(function () {
  angular
    .module("akitabox.core.services.recognizedText", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.organization",
      "akitabox.core.services.env",
      "akitabox.core.services.http",
      "akitabox.core.services.file",
    ])
    .factory("RecognizedTextService", RecognizedTextService);

  /** @ngInject */
  function RecognizedTextService($http, $log, $q, models, HttpService) {
    var service = {
      // Routes
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Retrieve
      getByDocumentId: getByDocumentId,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
    // *   Routes
    ******************** */

    function buildBaseRoute() {
      return "/" + models.RECOGNIZED_TEXT.ROUTE_PLURAL;
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        buildBaseRoute()
      );
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function getByDocumentId(organizationId, documentId, params) {
      var route = buildOrganizationListRoute(organizationId);
      params = Object.assign({ document: documentId }, params);
      return HttpService.getAll(route, params);
    }
  }
})();
