(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller("FloorRoomsTabController", FloorRoomsTabController);

  /* @ngInject */
  function FloorRoomsTabController(
    // Angular
    $scope,
    // Constants
    EVENT_ROOM_CREATE,
    // Services
    PinService,
    PinValueService,
    RoomService,
    ToastService,
    // Resolve
    building,
    floor
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.floor = floor;
    self.rooms = null;
    self.createOptions = getCreateOptions();

    // Functions
    self.fetchRooms = fetchRooms;
    self.fetchAllRooms = fetchAllRooms;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ROOM_CREATE, onRoomCreate);

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchRooms(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
        include_values: true,
        level: self.floor._id,
      };

      var options = {
        includePinType: true,
      };

      return RoomService.get(building._id, params, options)
        .then(function (rooms) {
          if (angular.isArray(self.rooms)) {
            self.rooms = self.rooms.concat(rooms);
          } else {
            self.rooms = rooms;
          }
          return rooms.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllRooms(limit) {
      var params = {
        skip: 0,
        limit: limit,
        include_values: true,
        level: self.floor._id,
      };

      var options = {
        includePinType: true,
      };

      return RoomService.getAll(building._id, params, options)
        .then(function (rooms) {
          self.rooms = rooms;
          return rooms.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getCreateOptions() {
      return {
        locals: {
          building: building,
          floor: floor,
        },
      };
    }

    /**
     * Handle room creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Room[]} rooms    List of new rooms
     */
    function onRoomCreate($event, rooms) {
      // We can only create one room at a time
      var room = rooms[0];
      var floorId = PinValueService.getFloorId(room.values);
      if (floorId === self.floor._id) {
        PinService.includePinType(room.building, [room]).then(function (
          results
        ) {
          if (!self.rooms) {
            self.rooms = [];
          }
          self.rooms.unshift(results[0]);
        });
      }
    }
  }
})();
