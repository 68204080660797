(function () {
  angular
    .module("akitabox.ui.dialogs.changeDecommissionDate")
    .controller(
      "ChangeDecommissionDateDialogController",
      ChangeDecommissionDateDialogController
    );

  /* @ngInject */
  function ChangeDecommissionDateDialogController(
    // Angular
    $mdDialog,
    // Utils
    moment,
    // Services
    AssetService,
    IdentityService,
    RoomService
  ) {
    var self = this;

    // Fields
    /**
     * @prop {Asset|null} asset - the asset that is being decommission
     */
    /**
     * @prop {Date|null} decommissionDate - date of the decommissioning
     */
    self.decommissionDate = self.asset
      ? new Date(self.asset.decommissioned_date)
      : new Date(self.room.decommissioned_date);
    /**
     * @prop {Object} error - Holds errors to show withn the dialog if any happens
     * @prop {Error|null} error.dateInput - holds any error that occurs during date selection process
     * @prop {Error|null} error.saving - holds any error that occurs when saving the date to our DB
     */
    self.error = {
      dateInput: null,
      saving: null,
    };
    /**
     * @prop {Date|null} maximumDate - the furthest a decommission date can be set to
     */
    self.maximumDate = new Date(); // always the current date
    /**
     * @prop {Room|null} room - the room that is being decommission
     */
    /**
     * @prop {Boolean} saving - flag to indicate if dialog is saving date to db or not
     */
    self.saving = false;

    // methods
    self.changeDecommissionDate = changeDecommissionDate;
    self.isSavingDiabled = isSavingDiabled;
    self.onCloseClickHandler = $mdDialog.cancel;
    self.onChangeDecommissionDateHandler = onChangeDecommissionDateHandler;

    /**
     * Save the new decommission date to the database
     */
    function changeDecommissionDate() {
      var body = {
        decommissioned_date: self.decommissionDate,
        decommissioned_by: IdentityService.getCurrent()._id,
      };
      var savingFn = self.asset
        ? AssetService.updateById(
            self.asset.building,
            self.asset._id,
            body,
            {},
            self.asset
          )
        : RoomService.updateById(
            self.room.building,
            self.room._id,
            body,
            {},
            self.room
          );
      self.saving = true;

      savingFn
        .then(function (updatedModel) {
          $mdDialog.hide(updatedModel);
        })
        .catch(function (err) {
          self.error.saving = err;
        })
        .finally(function () {
          self.saving = false;
        });
    }

    function isSavingDiabled() {
      return self.saving || self.error.dateInput;
    }

    /**
     * Updates the decommission date whenever the user changes it
     * @param {Object} event
     * @param {Date} event.model - the actual piced date object
     * @param {String} event.value - string format of the date
     * @param {Boolean} event.invalid - true if not a valid date, false otherwise
     */
    function onChangeDecommissionDateHandler(event) {
      if (event.invalid) {
        var error = new Error("Decommission date is invalid");
        if (moment(event.model).isAfter(moment(self.decommissionDate))) {
          error = new Error("Decommission date cannot be in the future");
        }
        self.error.dateInput = error;
      } else {
        self.error.dateInput = null;
        self.decommissionDate = new Date(event.model);
      }
    }
  }
})();
