(function () {
  angular
    .module("akitabox.ui.dialogs.qrCode.pinSearch")
    .controller("QrPinSearchDialogController", QrPinSearchDialogController);

  /* @ngInject */
  function QrPinSearchDialogController(
    // Angular
    $q,
    // Constants
    COMPONENT_STATE,
    PIN_MODELS,
    // Material
    $mdDialog,
    // Services
    AssetService,
    BuildingService,
    QRCodeService,
    RoomService,
    ShadowService,
    ToastService,
    UserService,
    OrganizationService
  ) {
    var self = this;

    // Private
    var _pinCache = null;
    var _permissions = UserService.getPermissions();

    // Attributes
    self.building = BuildingService.getCurrent();
    self.pin = null;
    self.pinModelOptions = PIN_MODELS;
    self.pinModel = "asset"; // Determines what kinds of pins to search for
    self.state = COMPONENT_STATE.default;
    self.canAssociate = _permissions.qrcode.associate;
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.assign = assign;
    self.close = close;
    self.handleBuildingChange = handleBuildingChange;
    self.handlePinChange = handlePinChange;
    self.handlePinModelChange = handlePinModelChange;
    self.getPins = getPins;
    self.isSearchDisabled = isSearchDisabled;
    self.isPinAssociated = isPinAssociated;
    self.canSubmit = canSubmit;
    self.canClose = canClose;

    // =================
    // Public Functions
    // =================

    /**
     * Assign the given QR code with the currently selected pin.
     */
    function assign() {
      var pin = self.pin;
      var existingCode = pin && pin.qrCode ? pin.qrCode._code : null;
      var association = {};
      association[self.pinModel] = pin._id;
      self.state = COMPONENT_STATE.saving;
      QRCodeService.replace(existingCode, self.code, association)
        .then(function () {
          if (existingCode) {
            ShadowService.sendEvent(
              "qr-code",
              "replace-from-find",
              self.pinModel
            );
          } else {
            ShadowService.sendEvent(
              "qr-code",
              "assign-from-find",
              self.pinModel
            );
          }
          $mdDialog.hide(pin);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.state = COMPONENT_STATE.default;
        });
    }

    /**
     * Closes the dialog
     */
    function close() {
      ShadowService.sendEvent("qr-code", "cancel-find");
      $mdDialog.cancel();
    }

    /**
     * Handle building change event.
     *
     * @param {Object} $event
     * @param {Object} [$event.model] - Newly selected building, if any
     */
    function handleBuildingChange($event) {
      if (!$event.model) {
        return;
      }
      self.building = $event.model;
      self.pin = null;
      clearPinCache();
    }

    /**
     * Handle pin change event.
     *
     * @param {Object} $event
     * @param {Object} [$event.pin] - Newly selected pin, if any
     */
    function handlePinChange($event) {
      self.pin = $event.pin;
    }

    /**
     * Handle pin model change event.
     *
     * @param {Object} $event
     * @param {String} $event.selected - Newly selected pin model
     */
    function handlePinModelChange($event) {
      self.pinModel = $event.selected;
      self.pin = null;
      clearPinCache();
    }

    /**
     * Fetch for pins with the current building and pin model using the given
     * query. If none of that data (building, pin model, query) has changed
     * since the last time this function was called, just use the request from
     * that last call to avoid duplicate fetches.
     */
    function getPins(query) {
      if (shouldUsePinCache(query)) {
        return _pinCache.request;
      }

      var params = {
        building: self.building._id,
        limit: 100,
      };
      var options = { includePinType: true };
      var Service = self.pinModel === "asset" ? AssetService : RoomService;
      if (query) {
        self.pinModel === "asset"
          ? (params.$or = `name=${query}`)
          : (params.$or = `name=${query};number=${query}`);
      }
      var request = Service.getBFF(
        self.building._id,
        params,
        options,
        self.organization._id
      ).catch(function (err) {
        clearPinCache();
        return $q.reject(err);
      });
      cachePins(query, request);
      return request;
    }

    function isSearchDisabled() {
      return !self.building || !self.pinModel;
    }

    function isPinAssociated() {
      return self.pin && self.pin.qrCode;
    }

    function canSubmit() {
      return self.pin && self.state !== COMPONENT_STATE.saving;
    }

    function canClose() {
      return self.state !== COMPONENT_STATE.saving;
    }

    // =================
    // Private Functions
    // =================

    function shouldUsePinCache(query) {
      return _pinCache && _pinCache.query === query;
    }

    function clearPinCache() {
      _pinCache = null;
    }

    function cachePins(query, request) {
      _pinCache = {
        query: query,
        request: request,
      };
    }
  }
})();
