(function () {
  /**
   * @component
   * Component for displaying a form to collect data for an Work Order.
   * Contains all location-specific data used for work orders.
   *
   */
  angular
    .module("akitabox.core.ui.dialogs.workOrder.create.locationSection")
    .component("abxWorkOrderLocationSection", {
      bindings: {
        location: "<abxLocation",
        building: "=abxBuilding",
        setLocationsChanged: "<?abxSetLocationsChanged",
      },
      controller: AbxWorkOrderLocationSectionController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/dialogs/work-order/create/work-order-location-section/work-order-location-section.html",
    });

  /** @ngInject */
  function AbxWorkOrderLocationSectionController(
    $q,
    Utils,
    FloorService,
    OrganizationService,
    RoomService
  ) {
    var self = this;
    self.loading = true;
    self.onChange = onChange;

    const _organization = OrganizationService.getCurrent();

    /**
     * Set all location fields if they are not already set
     */
    self.$onInit = function () {
      if (self.location.asset) {
        if (!self.location.room && !self.location.floor) {
          setFloorWithAsset()
            .then(setRoomWithAsset)
            .finally(function () {
              self.loading = false;
            });
        } else {
          self.loading = false;
        }
      } else if (self.location.room) {
        if (!self.location.floor) {
          setFloorWithRoom().finally(function () {
            self.loading = false;
          });
        } else {
          self.loading = false;
        }
      } else {
        self.loading = false;
      }
    };
    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handle field change
     *
     * @param {Object} $event Change event
     * @param {String} type Type of update (e.g name, address)
     */
    function onChange($event, field) {
      if (self.loading) return;
      var changed = !Utils.isSameModel(self.location[field], $event.model);
      if (changed && self.setLocationsChanged) {
        self.setLocationsChanged(true);
      }
      switch (field) {
        case "asset":
          self.location.asset = $event.model;
          if (changed && self.location.asset) {
            if (!self.location.room) {
              const asset = self.location.asset;
              if (asset && asset.room && asset.room._id) {
                var params = {
                  _id: asset.room._id,
                };
                RoomService.getBFF(
                  asset.building._id,
                  params,
                  {},
                  _organization._id
                ).then(function (room) {
                  if (room) {
                    self.location.asset.room = room;
                    setRoomWithAsset();
                  }
                });
              }
            }
            if (!self.location.floor) setFloorWithAsset();
          }
          break;
        case "room":
          self.location.room = $event.model;
          if (changed && self.location.room && !self.location.floor) {
            setFloorWithRoom();
          }
          checkAssetField();
          break;
        case "floor":
          self.location.floor = $event.model;
          checkRoomField();
          checkAssetField();
          break;
      }
    }

    function checkRoomField() {
      const roomLevelId =
        self.location.room &&
        (self.location.room.level._id || self.location.room.level);
      if (
        self.location.room &&
        (!self.location.floor || roomLevelId !== self.location.floor._id)
      ) {
        self.location.room = null;
      }
    }

    function checkAssetField() {
      const assetRoomId =
        self.location.asset &&
        (self.location.asset.room._id || self.location.asset.room);
      if (
        self.location.asset &&
        (!self.location.room || assetRoomId !== self.location.room._id)
      ) {
        self.location.asset = null;
      }
    }

    function setRoomWithAsset() {
      return $q(function (resolve, reject) {
        var asset = self.location.asset;
        if (asset.room && asset.room._id) {
          if (asset.room._id) {
            self.location.room = asset.room;
            resolve();
          } else {
            if (asset.room._id) {
              RoomService.getById(
                asset.building._id || asset.building,
                asset.room
              )
                .then(function (room) {
                  self.location.room = room || null;
                  resolve();
                })
                .catch(reject);
            } else {
              // we have room object but it`s empty
              resolve();
            }
          }
        } else {
          resolve();
        }
      });
    }

    function setFloorWithAsset() {
      return $q(function (resolve, reject) {
        var asset = self.location.asset;
        if (asset.level && asset.level._id) {
          FloorService.getById(
            asset.building._id || asset.building,
            asset.level._id || asset.level
          )
            .then(function (level) {
              self.location.floor = level || null;
              resolve();
            })
            .catch(reject);
        } else {
          resolve();
        }
      });
    }

    function setFloorWithRoom() {
      return $q(function (resolve, reject) {
        var room = self.location.room;
        if (room.level) {
          if (room.level._id) {
            self.location.floor = room.level;
            resolve();
          } else {
            FloorService.getById(
              room.building._id || room.building,
              room.level._id || room.level
            )
              .then(function (level) {
                self.location.floor = level || null;
                resolve();
              })
              .catch(reject);
          }
        } else {
          resolve();
        }
      });
    }
  }
})();
