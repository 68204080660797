(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.roomInput
   */
  angular
    .module("akitabox.ui.directives.roomInput", [
      "akitabox.constants",
      "akitabox.core.services.room",
      "akitabox.core.services.pinType",
      "akitabox.core.services.roomsBff",
      "akitabox.core.toast",
    ])
    .directive("abxRoomInput", AbxRoomInputDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.roomInput
   * @name AbxRoomInputDirective
   * @restrict E
   *
   * @description
   * `abx-room-input` creates a dropdown menu to allow for selection of the rooms
   *
   * @usage
   * <hljs lang="html">
   *   <abx-room-input
   *      id="room"
   *      name="room"
   *      ng-model="vm.room"
   *      abx-label="Room"
   *      abx-building="vm.building"
   *      abx-floor="vm.floor">
   *   </abx-room-input>
   * </hljs>
   *
   * @ngInject
   */
  function AbxRoomInputDirective(
    $q,
    $timeout,
    models,
    RoomService,
    ToastService
  ) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/room-input/room-input.html",
      require: ["abxRoomInput", "ngModel"],
      controller: AbxRoomInputController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        label: "@?abxLabel",
        building: "=abxBuilding",
        floor: "=abxFloor",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Attributes
      var noPlaceholder = angular.isDefined(attrs.abxNoPlaceholder);
      var placeholder = noPlaceholder
        ? vm.label
        : attrs.placeholder || "Enter a " + models.ROOM.SINGULAR;
      var originalPlaceholder = placeholder;

      // UI elements
      var $input;

      // Functions
      vm.setModelValue = setModelValue;
      vm.getDisplayName = getDisplayName;

      init();

      /**
       * Initialize the element, verify building is provided,
       * watch properties, and add event listeners
       */
      function init() {
        attrs.$set("abx-custom-input", "");

        // Watch for model changes
        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        // Watch dependency changes
        $scope.$watch("vm.building", onDependencyChange);
        $scope.$watch("vm.floor", function (newValue, oldValue) {
          onDependencyChange(newValue, oldValue, "level");
        });

        attachPropertyWatchers();

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and handle events
          $input = angular.element($element.find("input")[0]);

          setPlaceholder(originalPlaceholder);
          $input.on("blur", onBlur);
        });
      }

      /**
       * Determines if model should be set to null
       *
       * @param {Object} newValue     New dependency value
       * @param {Object} oldValue     Old dependency value
       * @param {String} type         [Optional] Type that changed
       */
      function onDependencyChange(newValue, oldValue, type) {
        // If editable, set disabled and reset placeholder
        if ($input && !vm.readonly) {
          setDisabled();
          setPlaceholder(originalPlaceholder);
        }

        if (newValue === oldValue) return;

        var hasChanged = true;
        if (newValue && oldValue) {
          if (
            Object.prototype.hasOwnProperty.call(newValue, "_id") &&
            Object.prototype.hasOwnProperty.call(oldValue, "_id")
          ) {
            hasChanged = newValue._id !== oldValue._id;
          }
        }

        if (hasChanged) {
          if (vm.room && type && newValue && newValue._id) {
            var isChild = false;
            // Find parent pin value
            for (var pinValue in vm.room.values) {
              if (newValue._id === vm.room.values[pinValue].value) {
                isChild = true;
                break;
              }
            }
            if (!isChild) {
              clearModel();
              clearMatches();
              setReadonly(vm.readonly);
            }
          } else {
            clearModel();
            clearMatches();
            setReadonly(vm.readonly);
          }
        }
      }

      /**
       * Gets the initial display name for a room
       *
       * @param {object} room
       */
      function getDisplayName(room) {
        return $q.resolve(room.display_name);
      }
      /**
       * Handle external value changes
       *
       * @param  {*} value    New value
       * @return {*}          Parsed value
       */
      function onExternalChange(value) {
        vm.roomLoaded = false;
        vm.room = value;
        if (!vm.room) return;

        // only get the room if the existing room does not have values or if they are an array instead of an object
        if (!vm.room.values || angular.isArray(vm.room.values)) {
          RoomService.getById(vm.building._id, vm.room._id, {
            include_values: true,
          })
            .then(function (roomWithValues) {
              // roomWithValues may be undefined, in which case, just use vm.room because it already has values
              vm.room = roomWithValues || vm.room;
              return value;
            })
            .catch(ToastService.showError)
            .finally(function () {
              vm.roomLoaded = true;
            });
        }
      }

      /**
       * Clear the ngModel (room)
       */
      function clearModel() {
        vm.room = null;
        setModelValue(null);
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Readonly
        $scope.$watch(function () {
          return $element[0].attributes.readonly;
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          return $element.attr("placeholder");
        }, setPlaceholder);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
      }

      /**
       * Handle input blur events
       */
      function onBlur() {
        vm.ngModelCtrl.$setTouched();
      }

      /**
       * Set readonly
       *
       * @param {String} value    Attribute value
       */
      function setReadonly(value) {
        vm.readonly = Boolean(value);
        // Show / hide input
        if (vm.readonly && vm.room) {
          hideInput();
        } else {
          showInput();
        }
      }

      /**
       * Set disabled, if building or floor doesn't exist value is ignored
       * and disabled is set to true
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        if (vm.building && vm.floor && !vm.floor.is_level_pin_value) {
          vm.disabled = angular.isEmpty(value) ? false : value !== "false";
        } else {
          vm.disabled = true;
          setPlaceholder();
        }
      }

      /**
       * Set input placeholder, if building or floor doesn't exist value is
       * ignored and the placeholder is set to indicate they are required
       *
       * @param {String} value    Attribute value
       */
      function setPlaceholder(value) {
        if (
          (vm.building && vm.floor && !vm.floor.is_level_pin_value) ||
          vm.readonly
        ) {
          placeholder = value;
        } else if (!vm.floor || vm.floor.is_level_pin_value) {
          placeholder = "Floor required";
        } else if (!vm.building) {
          placeholder = "Building required";
        }
        if (noPlaceholder) {
          if (placeholder) vm.label = placeholder;
        } else {
          $element.attr("placeholder", placeholder);
          if ($input) $input.attr("placeholder", placeholder);
        }
      }

      /**
       * Show input element
       */
      function hideInput() {
        if ($input) $input.css("visibility", "hidden");
      }

      /**
       * Hide input element
       */
      function showInput() {
        if ($input) $input.css("visibility", "visible");
      }

      /**
       * Clear the autcompletes list of matches
       */
      function clearMatches() {
        vm.mdAutocompleteCtrl.matches.length = 0;
      }
    }
  }

  /* @ngInject */
  function AbxRoomInputController($q, RoomsBFFService, ToastService) {
    var self = this;

    var allRooms = null;
    var roomRequest = null;
    var lastFloorId = self.floor ? self.floor._id : null;

    // Attributes
    self.label = self.label || "Room";
    self.loading = false;
    self.query = null;
    self.disabled = false;
    self.readonly = true;
    self.required = false;

    // Functions
    self.querySearch = querySearch;
    self.selectedItemChange = selectedItemChange;

    /**
     * Search for buildling rooms based on query, if query is blank
     * all rooms will be returned. Will only query when necessary, i.e.
     * not when the floor hasn't changed since we last queried
     *
     * @param  {String} query   Search text
     * @return {Array}          List of rooms
     */
    function querySearch(query) {
      if (!self.building || !self.floor) return $q.resolve([]);

      self.query = query;

      if (!hasFloorChanged()) {
        // Filter and return on all rooms if we've already fetched them
        if (allRooms) {
          return setMatches(query);
        }

        // Use the outstanding request, if we have one
        if (self.loading) {
          return roomRequest;
        }
      }

      var params = {
        // This used to be false, but now we need the room name AND the room number
        include_values: true,
        building: self.building._id,
      };

      var floorId = self.floor ? self.floor._id : null;

      // Add floor query param if available
      if (floorId) params.level = floorId;

      // Update previous floor
      lastFloorId = floorId;

      self.loading = true;
      var rooms;
      roomRequest = RoomsBFFService.getAllTypeAheads(
        self.building.organization,
        params
      )
        .then(function (_rooms) {
          rooms = _rooms;
          if (angular.isEmpty(rooms)) return null;
          return rooms;
        })
        .then(function (pinFieldId) {
          var roomsWithNumbers = rooms.map(function (room) {
            room.displayName = room.display_name;
            return room;
          });
          if (hasFloorChanged()) return querySearch(self.query);
          allRooms = roomsWithNumbers;
          return setMatches(self.query);
        })
        .catch(function (err) {
          ToastService.showError(err);
          return [];
        })
        .finally(function () {
          self.loading = false;
        });

      return roomRequest;
    }

    /**
     * Handle room select
     *
     * @param  {Object} item    Selected item (room)
     */
    function selectedItemChange(item) {
      self.roomLoaded = true;
      self.setModelValue(item);
    }

    /**
     * Filter matches based on a query string
     *
     * @param  {String} query       Query string
     * @param  {Array} matches      List of possible matches
     * @return {Array}              Filtered list of matches
     */
    function filterQuery(query, matches) {
      return matches.filter(function (match) {
        return match.displayName.match(new RegExp(query, "i"));
      });
    }

    /**
     * Sets and returns the autcomplete matches
     *
     * @param {String} query    Query search text
     *
     * @return {Array}          List of asset matches
     */
    function setMatches() {
      if (angular.isEmpty(self.query)) {
        self.mdAutocompleteCtrl.matches = angular.copy(allRooms);
      } else {
        self.mdAutocompleteCtrl.matches = filterQuery(self.query, allRooms);
      }
      return self.mdAutocompleteCtrl.matches;
    }

    /**
     * Determine if the floor has changed
     *
     * @return {Boolean}    True if changed, false if not
     */
    function hasFloorChanged() {
      return self.floor ? lastFloorId !== self.floor._id : lastFloorId;
    }
  }
})();
