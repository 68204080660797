(function () {
  angular.module(
    "akitabox.core.ui.dialogs.inspectionProgram.create.inspectionComponentSection",
    [
      "akitabox.core.ui.dialogs.inspectionProgram.create.checklistTemplateOverrideSection",
      "akitabox.core.services.organization",
      "akitabox.core.services.trade",
      "akitabox.core.services.type",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.core.utils",
    ]
  );
})();
