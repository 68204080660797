(function () {
  angular
    .module("akitabox.core.auth.lockedOut")
    .controller("LockedOutController", LockedOutController);

  /* @ngInject */
  function LockedOutController(
    // Third party
    $state,
    $stateParams
  ) {
    var self = this;

    // Attributes
    self.email = $stateParams.email;

    if (!self.email) {
      // this state should never be reached without an email in the querystring
      $state.go("auth.login");
    }
  }
})();
