(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.detail")
    .controller(
      "WorkOrderDetailDialogController",
      WorkOrderDetailDialogController
    );

  /** @ngInject */
  function WorkOrderDetailDialogController(
    $mdDialog,
    BuildingService,
    ToastService,
    $q
  ) {
    var self = this;

    self.loading = true;

    self.shouldTypes = false;
    self.shouldTrades = false;
    self.assigneesText = "Loading...";
    self.locationText = "Loading...";
    self.woHref = self.workOrder.uri;

    // Methods
    self.close = $mdDialog.cancel;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      var needToLoad = typeof self.workOrder === "function";
      self.loading = needToLoad;
      var woPromise = $q.resolve();
      if (needToLoad) {
        woPromise = $q
          .resolve(self.workOrder())
          .then(function (workOrder) {
            self.workOrder = workOrder;
          })
          .catch(function (err) {
            ToastService.showError(err);
            $mdDialog.cancel();
          });
      }
      woPromise.then(initFields).finally(function () {
        self.loading = false;
      });
    }

    /**
     * Initialize the dialog, fetch floors and building assignees
     */
    function initFields() {
      // Determine whether to show type & trade
      var typeField = self.workOrder.is_reactive
        ? "show_issue_types"
        : "show_maintenance_types";
      var building = self.workOrder.building;
      if (building && typeof building === "object") {
        self.shouldShowTypes = building[typeField];
        self.shouldShowTrades = building.show_trades;
      } else if (building && typeof building === "string") {
        BuildingService.getById(building)
          .then(function (_building) {
            self.shouldShowTypes = _building[typeField];
            self.shouldShowTrades = _building.show_trades;
          })
          .catch(ToastService.showError);
      }
      // Get display value of assignee(s)
      populateAssigneesText();
      // Get display value of location(s)
      populateLocationText();
    }

    function populateAssigneesText() {
      var assignees = [];
      var count = 0;
      while (self.workOrder.assignees[count] && count <= 2) {
        var assignee = self.workOrder.assignees[count];
        assignees.push(assignee.display_name || assignee.email);
        count++;
      }
      var assigneesText = undefined;
      if (assignees.length) assigneesText = assignees.join("\n");
      if (self.workOrder.assignees.length > 3)
        assigneesText += "\n +" + self.workOrder.assignees.length - 3 + " more";

      self.assigneesText = assigneesText;
    }

    function populateLocationText() {
      var locationText = "";
      var locationTextPieces = [];

      if (!self.workOrder.round.length) locationText = "None";
      else if (self.workOrder.round.length === 1) {
        var stop = self.workOrder.round[0];
        var asset = null;
        var floor = null;
        var room = null;

        /**
         * The stop will always only point to one location, which should then contain all its
         * connected locations.  Below we do just that
         */
        if (stop.asset) {
          asset = stop.asset;
          if (stop.asset.room) {
            room = stop.asset.room;
          }
          if (stop.asset.level) {
            floor = stop.asset.level;
          }
        } else if (stop.room) {
          room = stop.room;
          if (stop.room.level) {
            floor = stop.room.level;
          }
        } else if (stop.level) {
          floor = stop.level;
        }

        if (floor) {
          locationTextPieces.push("Floor: " + floor.name);
        }
        if (room) {
          locationTextPieces.push("Room: " + room.display_name); // can't get room name here, only number
        }
        if (asset) {
          locationTextPieces.push("Asset: " + asset.name);
        }

        if (locationTextPieces && locationTextPieces.length) {
          locationText = locationTextPieces.join("\n");
        }
      } else {
        var alreadyAddedFloors = {};
        var alreadyAddedRooms = {};
        var locations = self.workOrder.round.reduce(
          function (accumulator, stop) {
            var level;
            var room;
            var asset;

            /**
             * The stop will always only point to one location, which should then contain all its
             * connected locations.  Below we do just that
             */
            if (stop.asset) {
              level = stop.asset.level;
              room = stop.asset.room;
              asset = stop.asset;
            }
            if (stop.room) {
              level = stop.room.level;
              room = stop.room;
            }
            if (stop.level) {
              level = stop.level;
            }

            if (asset) {
              if (typeof accumulator.asset === "number") {
                accumulator.asset++;
              } else if (!accumulator.asset) {
                accumulator.asset = 2;
              } else {
                accumulator.asset = asset.name;
              }
            }

            if (room) {
              if (
                typeof accumulator.room === "number" &&
                !alreadyAddedRooms[(level ? level._id : "") + room._id]
              ) {
                /**
                 * We keep adding to the room count because that's what we show in the ui instead of the room number
                 * when there are more than 1 room
                 */
                accumulator.room++;
              } else if (
                !accumulator.room &&
                !alreadyAddedRooms[(level ? level._id : "") + room._id]
              ) {
                /**
                 * There is now more than 1 more connected to this wO, so we start tallying instead of
                 * storing the room
                 */
                accumulator.room = 2;
              } else {
                // This is the first room, so we keep it as a room, just show we can show the name later
                accumulator.room = room.display_name;
              }
              alreadyAddedRooms[(level ? level._id : "") + room._id] = true;
            }

            if (level) {
              if (
                typeof accumulator.level === "number" &&
                !alreadyAddedFloors[level._id]
              ) {
                accumulator.level++;
              } else if (!accumulator.level && !alreadyAddedFloors[level._id]) {
                accumulator.level = 2;
              } else {
                accumulator.level = level.name;
              }
              alreadyAddedFloors[level._id] = true;
            }

            return accumulator;
          },
          { level: null, room: null, asset: null }
        );

        if (locations.level) {
          var floorLabel = locations.level > 0 ? "Floors: " : "Floor: ";
          locationTextPieces.push(floorLabel + locations.level);
        }
        if (locations.room) {
          var roomLabel = locations.room > 0 ? "Rooms: " : "Room: ";
          locationTextPieces.push(roomLabel + locations.room);
        }
        if (locations.asset) {
          var assetLabel = locations.asset > 0 ? "Assets: " : "Asset: ";
          locationTextPieces.push(assetLabel + locations.asset);
        }
        if (locationTextPieces && locationTextPieces.length) {
          locationText = locationTextPieces.join("\n");
        }
      }

      self.locationText = locationText;
    }
  }
})();
