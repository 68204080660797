(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.assetStats
   *
   * @param {Object} building   Building to build the chart with
   */
  angular
    .module("akitabox.ui.components.assetStats")
    .component("abxAssetStats", {
      bindings: {
        building: "<abxBuilding",
        onViewAll: "&?abxOnViewAll",
        allowClick: "&?abxAllowClick",
      },
      controller: AbxAssetStatsController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/asset-stats/asset-stats.component.html",
    });

  /* @ngInject */
  function AbxAssetStatsController(
    // Angular
    $q,
    $scope,
    $attrs,
    $log,
    // Constants
    EVENT_ASSET_CREATE,
    // Services
    AssetService,
    PinTypeService,
    Router,
    ToastService,
    Utils
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.data = [];
    self.showViewAll = !angular.isEmpty($attrs.abxOnViewAll);
    self.allowClick = Utils.parseExpression(self, "allowClick", false);

    // Functions
    self.onClick = self.allowClick ? onClick : angular.noop;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.building) {
        $log.error("abxAssetStats: abx-building is required");
        return;
      }
      getData(self.building);
    };

    self.$onChanges = function (changes) {
      if (changes.building) {
        getData(self.building);
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ASSET_CREATE, onAssetCreate);

    // ------------------------
    //   Public Functions
    // ------------------------

    function onClick($event) {
      var data = $event.data;
      if (self.building && self.building._id && data._id) {
        Router.go("app.building.assets", {
          buildingId: self.building._id,
          pin_type: JSON.stringify({ _id: data._id }),
        });
      }
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getData(building) {
      self.loading = true;
      return $q
        .all([
          AssetService.getStatsByBuilding(building._id, {
            group_field: "pinType",
          }),
          PinTypeService.getAll(building._id),
        ])
        .then(function (results) {
          var stats = results[0];
          var pinTypes = results[1];
          return buildData(stats, pinTypes);
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.data = [];
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function buildData(stats, pinTypes) {
      var pinTypesMap = {};
      pinTypes.forEach(function (pinType) {
        pinTypesMap[pinType._id] = pinType.name;
      });

      self.defaultText = {
        name: "Total Assets",
        value: 0,
      };
      self.data = stats
        .filter(function (stat) {
          return stat.result;
        })
        .map(function (stat) {
          self.defaultText.value += stat.result;
          return {
            name: pinTypesMap[stat._id],
            value: stat.result,
            _id: stat._id,
          };
        })
        .slice(0, 12);
    }

    /**
     * Handle asset creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Asset[]} assets    List of new assets
     */
    function onAssetCreate($event, assets) {
      // We can only create one asset at a time
      var asset = assets[0];
      if (asset.building === self.building._id) {
        getData(self.building);
      }
    }
  }
})();
