(function () {
  angular.module("akitabox.ui.dialogs.qrCode.associate", [
    "ngMaterial",
    "akitabox.core.services.flag",
    "akitabox.core.services.qrcode",
    "akitabox.core.services.shadow",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.asset.create",
    "akitabox.ui.dialogs.room.create",
    "akitabox.ui.dialogs.qrCode.pinSearch",
  ]);
})();
