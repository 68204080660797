(function () {
  angular.module("akitabox.core.utils").factory("MathService", MathService);

  /* @ngInject */
  function MathService() {
    var service = {
      // Constants
      // Functions
      convertAndRotatePinCoords: convertAndRotatePinCoords,
      unconvertAndUnrotatePinCoords: unconvertAndUnrotatePinCoords,
      convertPercentToLatLng: convertPercentToLatLng,
      convertLatLngToPercent: convertLatLngToPercent,
      rotateAroundPoint: rotateAroundPoint,
    };

    return service;

    /**
     * Helper function that combines the work of:
     *   * converting X/Y percents to cartesian coordinates for a given image
     *   * rotating the X/Y coord pair, clockwise, around the image center by a given number of degrees
     *
     * @param {Object} pinData
     * @param {Number} pinData.percentX
     * @param {Number} pinData.percentY
     * @param {Object} imageDimensions
     * @param {Number} imageDimensions.width
     * @param {Number} imageDimensions.height
     * @param {Number} degrees  How many degrees to rotate the pinData clockwise by
     * @return {[Number, Number]} Returns the Lat/Lng pair. [y,x]/[lat/lng] is the leaflet format
     */
    function convertAndRotatePinCoords(pinData, imageDimensions, degrees) {
      // Rotate using percents first, this way it doesn't matter whether the image is a square
      var rotated = service.rotateAroundPoint(
        0.5,
        0.5,
        pinData.percentX,
        pinData.percentY,
        -degrees
      );

      return service.convertPercentToLatLng(
        rotated.x,
        rotated.y,
        imageDimensions
      );
    }

    /**
     * Helper function that combines the work of:
     *   * converting cartesian coordinates to X/Y percents for a given image
     *   * rotating the X/Y pair, counter-clockwise, around the image center by a given number of degrees
     * @param {Object}  latLng
     * @param {Number}  latLng.lat
     * @param {Number}  latLng.lng
     * @param {Object} imageDimensions
     * @param {Number} imageDimensions.width
     * @param {Number} imageDimensions.height
     * @param {Number} degrees  How many degrees to rotate the latLng counterclockwise by
     * @return {{x: Number, y: Number}}  The new X and Y percents
     */
    function unconvertAndUnrotatePinCoords(latLng, imageDimensions, degrees) {
      var percents = service.convertLatLngToPercent(
        latLng.lng,
        latLng.lat,
        imageDimensions
      );
      // Rotate using percents last, this way it doesn't matter whether the image is a square
      return service.rotateAroundPoint(
        0.5,
        0.5,
        percents[0],
        percents[1],
        degrees
      );
    }

    /**
     * Convert the pin's x and y coords to fit into the correct dimensions of the floor plan image
     * @param {Number} percentX
     * @param {Number} percentY
     * @param {Object} imageDimensions
     * @param {Number} imageDimensions.width
     * @param {Number} imageDimensions.height
     * @return {[Number, Number]} Returns the Lat/Lng pair. [y,x]/[lat/lng] is the leaflet format
     */
    function convertPercentToLatLng(percentX, percentY, imageDimensions) {
      var lat = (1 - percentY) * imageDimensions.height;
      var lng = percentX * imageDimensions.width;
      return [lat, lng];
    }

    /**
     * Convert the lat and lng to a percentX and percentY
     * @param {Number}  lng   longitude
     * @param {Number}  lat   latitute; must be positive value
     * @param {Object} imageDimensions
     * @param {Number} imageDimensions.width
     * @param {Number} imageDimensions.height
     * @return {[Number, Number]} Returns percentX and percentY.
     */
    function convertLatLngToPercent(lng, lat, imageDimensions) {
      var percentY = 1 - lat / imageDimensions.height;

      var percentX = lng / imageDimensions.width;

      return [percentX, percentY];
    }

    /**
     * Rotate an arbitrary point around any other arbitrary point. Used for rotating pins.
     * Assumes that the coordinates are on a square grid.
     *
     * @param centerX  X coordinate to rotate around
     * @param centerY  y coordinate to rotate around
     * @param x        x coordinate to rotate
     * @param y        y coordinate to rotate
     * @param angle    How many degrees to rotate {x,y} clockwise by
     * @return {{x: Number, y: Number}}  The new X and Y coordinates after rotating `angle` degrees around
     */
    function rotateAroundPoint(centerX, centerY, x, y, angle) {
      var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians);
      var rotatedX = cos * (x - centerX) + sin * (y - centerY) + centerX;
      var rotatedY = cos * (y - centerY) - sin * (x - centerX) + centerY;

      return { x: rotatedX, y: rotatedY };
    }
  }
})();
