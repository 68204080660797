(function () {
  angular.module("akitabox.desktop.document.organizationList", [
    "akitabox.core.services.building",
    "akitabox.core.services.document",
    "akitabox.core.services.filter",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.document",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
