/*
 * When displaying this dialog, you must provide the following locals:
 *
 *  EditMarkupDialog.show({
 *    locals: {
 *      markup: {Markup}
 *    },
 *  });
 *
 */

(function () {
  angular
    .module("akitabox.planView.dialogs.markup.edit", [
      "ngMaterial",
      "akitabox.core.constants",
      "akitabox.core.router",
      "akitabox.core.services.markup",
    ])
    .controller("EditMarkupDialogController", EditMarkupDialogController);

  /* @ngInject */
  function EditMarkupDialogController(
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Constants
    models,
    // Services
    ToastService,
    ShadowService,
    MarkupService
  ) {
    var self = this;
    var GA_CATEGORY = "markup";

    // Attributes
    self.formData = {
      name: self.markup.name,
    };
    self.saving = false;
    self.model = models.MARKUP.PLURAL;

    // Functions
    self.cancel = cancel;
    self.onChange = onChange;
    self.update = updateMarkup;

    function onChange($event, key) {
      self.formData[key] = $event.model;
    }

    function cancel() {
      ShadowService.sendEvent(GA_CATEGORY, "cancel-name", null, null);
      $mdDialog.cancel();
    }

    function updateMarkup() {
      self.saving = true;

      return MarkupService.update(
        self.markup.building,
        self.markup.level,
        self.markup._id,
        self.formData
      )
        .then(function (_markup) {
          // Refactor when need to scale
          self.markup.name = _markup.name;
          $mdDialog.cancel();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
          ShadowService.sendEvent(GA_CATEGORY, "save-name", null, null);
        });
    }
  }
})();
