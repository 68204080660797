(function () {
  angular.module("akitabox.desktop.insights").run(states);

  /* @ngInject */
  function states(models, Router) {
    Router.configureStates([
      {
        state: "app.insights",
        config: {
          url: "/insights",
          templateUrl: "app/desktop/modules/insights/insights.html",
          controller: "InsightsController",
          controllerAs: "vm",
          data: {
            pageTitle: "Insights",
          },
        },
      },
      {
        state: "app.building.insights",
        config: {
          url: "/insights",
          templateUrl: "app/desktop/modules/insights/insights.html",
          controller: "InsightsController",
          controllerAs: "vm",
          data: {
            pageTitle: "Insights",
          },
        },
      },
    ]);
  }
})();
