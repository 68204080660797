(function () {
  angular
    .module("akitabox.core.services.recentActivity")
    .factory("CachedActivityList", CachedActivityListFactory);

  /** @ngInject */
  function CachedActivityListFactory() {
    /**
     * @class CachedActivityList
     * Class used to track activity items of a particular kind. Has utility methods
     * for maintaining a list of sorted items and some metadata. There will be
     * one CachedActivityList per activity type.
     */
    function CachedActivityList() {
      /**
       * @private
       * @type { {time: Date }[] }
       * Sorted list of items in this cache.
       */
      this.items = [];

      /**
       * @public
       * @type { boolean } Flag indicating if there are potentially more items
       * to fetch. Must be set by client code, not automatically maintained.
       */
      this.hasMore = true;

      /**
       * @readonly
       * @type { Date }
       * The timestamp of the oldest item in this list. Automatically maintained.
       */
      this.oldestTime = null;

      /**
       * @readonly
       * @type { number }
       * The number of sequential items that have entered the cache with the current
       * oldestTime.
       */
      this.numOldestItems = 0;
    }

    /**
     * @method
     * Add an item to the list.
     * @param { {time: Date } } item An activity item with its time field set.
     * @return { void }
     */
    CachedActivityList.prototype.addItem = function addItem(item) {
      // item is older than the current oldest item (or there has never been
      // anything in the cache)
      if (!this.oldestTime || item.time < this.oldestTime) {
        this.items.push(item);
        this.oldestTime = item.time;
        this.numOldestItems = 1;
      }
      // item is exactly as old as the current oldest item
      else if (item.time.getTime() === this.oldestTime.getTime()) {
        this.items.push(item);
        this.numOldestItems++;
      }
      // item goes somewhere in the middle
      else {
        // traverse the list, find the first item that is older
        // than the new item, add it right before there
        var foundIndex = -1;
        for (var i = 0; i < this.items.length; i++) {
          var curItem = this.items[i];
          if (curItem.time < item.time) {
            foundIndex = i;
            break;
          }
        }
        if (foundIndex !== -1) {
          this.items.splice(foundIndex, 0, item);
        }
      }
    };

    /**
     * @method
     * Pop the newest item from the list.
     * @return { Object | null } The newest item in the list or null
     */
    CachedActivityList.prototype.removeNewestItem =
      function removeNewestItem() {
        return this.items.shift() || null;
      };

    return CachedActivityList;
  }
})();
