(function () {
  angular
    .module("akitabox.desktop.room.detail")
    .controller("RoomDetailController", RoomDetailController);

  /* @ngInject */
  function RoomDetailController(
    // Angular
    $scope,
    $stateParams,
    // Resolve
    building,
    room,
    organization,
    // Services
    FeatureFlagService,
    RoomService,
    UserService
  ) {
    var self = this;

    // Attributes
    self.room = room;
    self.building = building;
    self.organization = organization;
    self.permissions = getDefaultPermissions();
    self.subtitle = {
      name: "Rooms",
      state: { name: "app.rooms", params: $stateParams },
    };
    self.tabs = getTabs();

    //functions
    self.onRoomChange = onRoomChange;

    init();

    function init() {
      var permissions = UserService.getPermissions();
      self.permissions.canDecommission = permissions.room.decommission;
      self.permissions.canRecommission = permissions.room.recommission;
    }

    function getDefaultPermissions() {
      return {
        canRecommission: false,
        canDecommission: false,
      };
    }

    function getTabs() {
      var overview = { title: "Overview", state: "app.room.overview" };
      var details = { title: "Details", state: "app.room.details" };
      var assets = { title: "Assets", state: "app.room.assets" };
      var openWorkOrders = {
        title: "Open Work Orders",
        state: "app.room.openWorkOrders",
      };
      var closedWorkOrders = {
        title: "Closed Work Orders",
        state: "app.room.closedWorkOrders",
      };
      var schedules = {
        title: "Maintenance Schedules",
        state: "app.room.schedules",
      };

      var tabsToReturn = [overview, details, assets];
      if (organization.show_tasks)
        tabsToReturn.push(openWorkOrders, closedWorkOrders);
      if (!self.room.is_decommissioned) {
        tabsToReturn.push(schedules);
      }
      return tabsToReturn;
    }

    function onRoomChange(updatedRoom) {
      self.room = updatedRoom;
      self.tabs = getTabs();
      $scope.$broadcast("room-updated", { room: updatedRoom });
    }
  }
})();
