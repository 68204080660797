(function () {
  angular.module("akitabox.ui.dialogs.workOrder.create", [
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.flag",
    "akitabox.core.services.floor",
    "akitabox.core.services.issueType",
    "akitabox.core.services.organization",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.room",
    "akitabox.core.services.shadow",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.trade",
    "akitabox.core.services.type",
    "akitabox.core.services.user",
    "akitabox.core.services.workOrder",
    "akitabox.core.toast",
    "akitabox.ui.components.input",
    "angular.filter",
    "akitabox.ui.components.multipleAssigneeInput",
    "akitabox.core.ui.dialogs.workOrder.create.locationSection",
  ]);
})();
