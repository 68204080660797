(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.constants
   */
  var module = angular.module("akitabox.core.constants", [
    "akitabox.core.utils",
  ]);

  // =================
  // Events
  // =================

  /**
   * Refresh dashboard content
   *
   * @type {String}
   */
  var EVENT_REFRESH_DASHBOARD = "dashboard:refresh";
  module.constant("EVENT_REFRESH_DASHBOARD", EVENT_REFRESH_DASHBOARD);

  /**
   * Dashboard stats content refresh completed
   *
   * @type {String}
   */
  var EVENT_REFRESH_DASHBOARD_STATS_COMPLETE = "dashboard:refreshStatsComplete";
  module.constant(
    "EVENT_REFRESH_DASHBOARD_STATS_COMPLETE",
    EVENT_REFRESH_DASHBOARD_STATS_COMPLETE
  );

  /**
   * Dashboard work order content refresh completed
   *
   * @type {String}
   */
  var EVENT_REFRESH_DASHBOARD_WO_COMPLETE = "dashboard:refreshWOComplete";
  module.constant(
    "EVENT_REFRESH_DASHBOARD_WO_COMPLETE",
    EVENT_REFRESH_DASHBOARD_WO_COMPLETE
  );

  /**
   * Dashboard work order content refresh completed
   *
   * @type {String}
   */
  var EVENT_REFRESH_DASHBOARD_ERROR = "dashboard:refreshError";
  module.constant(
    "EVENT_REFRESH_DASHBOARD_ERROR",
    EVENT_REFRESH_DASHBOARD_ERROR
  );

  /**
   * Create a new inspection program event name
   *
   * @type {String}
   */
  var EVENT_INSPECTION_PROGRAM_CREATE = "inspection_program:create";
  module.constant(
    "EVENT_INSPECTION_PROGRAM_CREATE",
    EVENT_INSPECTION_PROGRAM_CREATE
  );

  /**
   * Create a new asset event name
   *
   * @type {String}
   */
  var EVENT_ASSET_CREATE = "asset:create";
  module.constant("EVENT_ASSET_CREATE", EVENT_ASSET_CREATE);

  /**
   * Pin duplicate event name
   *
   * @type {String}
   */
  var EVENT_PIN_DUPLICATE = "pin:duplicate";
  module.constant("EVENT_PIN_DUPLICATE", EVENT_PIN_DUPLICATE);

  /**
   * Pin duplicate event name
   *
   * @type {String}
   */
  var EVENT_PIN_REFRESH = "pin:refresh";
  module.constant("EVENT_PIN_REFRESH", EVENT_PIN_REFRESH);

  /**
   * Add a new floor to a building event name
   *
   * @type {String}
   */
  var EVENT_FLOOR_ADD = "floor:add";
  module.constant("EVENT_FLOOR_ADD", EVENT_FLOOR_ADD);

  /**
   * Create a new floor event name
   *
   * @type {String}
   */
  var EVENT_FLOOR_CREATE = "floor:create";
  module.constant("EVENT_FLOOR_CREATE", EVENT_FLOOR_CREATE);

  /**
   * Floor plan rotation event name
   *
   * @type {String}
   */
  var EVENT_FLOOR_ROTATE = "floor:rotate";
  module.constant("EVENT_FLOOR_ROTATE", EVENT_FLOOR_ROTATE);

  /**
   * Floor plan selection event name
   *
   * @type {String}
   */
  var EVENT_FLOOR_NEW_PAGE_SELECTED = "floor:newPageSelected";
  module.constant(
    "EVENT_FLOOR_NEW_PAGE_SELECTED",
    EVENT_FLOOR_NEW_PAGE_SELECTED
  );

  /**
   * Log Work event name.
   *
   * @type {String}
   */
  var EVENT_LOG_WORK = "workOrderLog:createUpdateDelete";
  module.constant("EVENT_LOG_WORK", EVENT_LOG_WORK);

  /**
   * Refresh media event name
   * @description forces a re-render on the image
   *
   * @type {String}
   */
  var EVENT_MEDIA_REFRESH = "media:refresh";
  module.constant("EVENT_MEDIA_REFRESH", EVENT_MEDIA_REFRESH);

  /**
   * Rotate media event name
   *
   * @type {String}
   */
  var EVENT_MEDIA_ROTATE = "media:rotate";
  module.constant("EVENT_MEDIA_ROTATE", EVENT_MEDIA_ROTATE);

  var EVENT_MEDIA_ZOOM_IN = "media:zoomIn";
  module.constant("EVENT_MEDIA_ZOOM_IN", EVENT_MEDIA_ZOOM_IN);

  var EVENT_MEDIA_ZOOM_OUT = "media:zoomOut";
  module.constant("EVENT_MEDIA_ZOOM_OUT", EVENT_MEDIA_ZOOM_OUT);

  var EVENT_MEDIA_ZOOM_RESET = "media:zoomReset";
  module.constant("EVENT_MEDIA_ZOOM_RESET", EVENT_MEDIA_ZOOM_RESET);

  /**
   * Name of event for the successful scanning of a QR Code.
   *
   * @type {String}
   */
  var EVENT_QR_CODE_SCANNED = "qrCode:scanned";
  module.constant("EVENT_QR_CODE_SCANNED", EVENT_QR_CODE_SCANNED);

  /**
   * Create a new request event name
   *
   * @type {String}
   */
  var EVENT_REQUEST_CREATE = "request:create";
  module.constant("EVENT_REQUEST_CREATE", EVENT_REQUEST_CREATE);

  /**
   * Create a new room event name
   *
   * @type {String}
   */
  var EVENT_ROOM_CREATE = "room:create";
  module.constant("EVENT_ROOM_CREATE", EVENT_ROOM_CREATE);

  /**
   * Create a new schedule event name
   *
   * @type {String}
   */
  var EVENT_SCHEDULE_CREATE = "schedule:create";
  module.constant("EVENT_SCHEDULE_CREATE", EVENT_SCHEDULE_CREATE);

  /**
   * Create new work order event name
   *
   * @type {String}
   */
  var EVENT_WORK_ORDER_CREATE = "task:create";
  module.constant("EVENT_WORK_ORDER_CREATE", EVENT_WORK_ORDER_CREATE);

  /**
   * Make <abx-type-ahead /> re-run its fn to re-position its dropdown menu items
   */
  var EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION =
    "update:abx_type_ahead_options_location";
  module.constant(
    "EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION",
    EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION
  );

  /* Inspection program navigation event
   *
   * @type {String}
   */
  var EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE =
    "inspection_program_detail:navigate";
  module.constant(
    "EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE",
    EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE
  );

  /* Inspection program overview load event
   *
   * @type {String}
   */
  var EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD =
    "inspection_program:overview_load";
  module.constant(
    "EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD",
    EVENT_INSPECTION_PROGRAM_OVERVIEW_LOAD
  );

  /* Inspection program detail load event
   *
   * @type {String}
   */
  var EVENT_INSPECTION_PROGRAM_DETAIL_LOAD = "inspection_program:detail_load";
  module.constant(
    "EVENT_INSPECTION_PROGRAM_DETAIL_LOAD",
    EVENT_INSPECTION_PROGRAM_DETAIL_LOAD
  );

  /* Inspection program update event
   *
   * @type {String}
   */
  var EVENT_INSPECTION_PROGRAM_UPDATE = "inspection_program_update";
  module.constant(
    "EVENT_INSPECTION_PROGRAM_UPDATE",
    EVENT_INSPECTION_PROGRAM_UPDATE
  );

  /* Stop selection event name
   *
   * @type {String}
   */
  var EVENT_ROUND_TASK_STOP_SELECTED = "stop:selected";
  module.constant(
    "EVENT_ROUND_TASK_STOP_SELECTED",
    EVENT_ROUND_TASK_STOP_SELECTED
  );

  /* Event fired when a pin is selected in the round app,
   * Used to open up the corresponding stop from the sidebar stop list
   *
   * @type {String}
   */
  var EVENT_ROUND_TASK_PIN_SELECTED = "round_task:pin_selected";
  module.constant(
    "EVENT_ROUND_TASK_PIN_SELECTED",
    EVENT_ROUND_TASK_PIN_SELECTED
  );

  /**
   * Changes the color of the over arching pins that are shown on top of the floor plan
   */
  var EVENT_ROUND_TASK_PIN_COLOR_UPDATE = "update:round_task_pin_color";
  module.constant(
    "EVENT_ROUND_TASK_PIN_COLOR_UPDATE",
    EVENT_ROUND_TASK_PIN_COLOR_UPDATE
  );

  /**
   * Not meant for global use.
   * Event to get the round list item to talk to the round sidebar
   */
  var EVENT_ROUND_TASK_MOVE_STOP = "round_task:move_stop";
  module.constant("EVENT_ROUND_TASK_MOVE_STOP", EVENT_ROUND_TASK_MOVE_STOP);

  /**
   * Not meant for global use.
   * Wrapper event for bulk edit to send multiple events at once
   */
  var EVENT_ROUND_TASK_MOVE_MULTI_STOP = "round_task:move_multi_stop";
  module.constant(
    "EVENT_ROUND_TASK_MOVE_MULTI_STOP",
    EVENT_ROUND_TASK_MOVE_MULTI_STOP
  );

  var EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES =
    "round_task:reapply_visibility_toggles";
  module.constant(
    "EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES",
    EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES
  );

  /**
   * Event when a work order is rescheduled in the details breadcrumbs header
   */
  var EVENT_RESCHEDULE_WORK_ORDER = "task:reschedule";
  module.constant("EVENT_RESCHEDULE_WORK_ORDER", EVENT_RESCHEDULE_WORK_ORDER);

  /**
   * Event when pin counts have been attached to pin types
   */
  var EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED = "plan_view:pin_counts_attached";
  module.constant(
    "EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED",
    EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED
  );

  /**
   * Event when a bulk edit stop is selected in PlanView
   *
   * @type {String}
   */
  var EVENT_BULK_EDIT_STOP_SELECTED = "plan_view:bulk_edit_stop_selected";
  module.constant(
    "EVENT_BULK_EDIT_STOP_SELECTED",
    EVENT_BULK_EDIT_STOP_SELECTED
  );

  /**
   * Event when a bulk edit toggle all clicked
   *
   * @type {String}
   */
  var EVENT_BULK_EDIT_TOGGLE_ALL = "plan_view:bulk_edit_toggle_all";
  module.constant("EVENT_BULK_EDIT_TOGGLE_ALL", EVENT_BULK_EDIT_TOGGLE_ALL);

  /**
   * The git commit hash
   *
   * @type {string}
   */
  var APP_COMMIT = window.ABX_COMMIT || "local";

  module.constant("APP_COMMIT", APP_COMMIT);

  /**
   * Appcues acount ID
   *
   * @type {String}
   */
  var APPCUES_ID = "34509";

  module.constant("APPCUES_ID", APPCUES_ID);

  /**
   * Dashboard card names
   *
   * @type {Object}
   */
  var DASHBOARD_CARDS = {
    WORK_SUMMARY: "work_summary",
    TASKS: "tasks",
    BUILDING_INFO: "buildingInfo",
  };

  module.constant("DASHBOARD_CARDS", DASHBOARD_CARDS);

  /**
   * Inspection Program card names
   *
   * @type {Object}
   */
  var INSPECTION_PROGRAM_CARDS = {
    DETAILS: "details",
    SUMMARY: "summary",
  };

  module.constant("INSPECTION_PROGRAM_CARDS", INSPECTION_PROGRAM_CARDS);

  /**
   * Portal application ID
   *
   * @type {string}
   */
  var PORTAL_ID = "portal";

  module.constant("PORTAL_ID", PORTAL_ID);

  /**
   * Portal application secret
   *
   * @type {{LOCAL: string, BETA: string, PRODUCTION: string}}
   */
  var PORTAL_SECRET = {
    LOCAL: "portal_secret",
    BETA: "goByHQlOrs7gsfu6OCHZhAXp11Ote5jL",
    TEMP: "goByHQlOrs7gsfu6OCHZhAXp11Ote5jL",
    PRODUCTION: "z7jRxCmmV8jKfqvDCiWIvs4lt17RHv8n",
  };

  module.constant("PORTAL_SECRET", PORTAL_SECRET);

  /**
   * User cookie key
   *
   * @type {String}
   */
  var USER_COOKIE = "akitabox_user";

  module.constant("USER_COOKIE", USER_COOKIE);

  /**
   * The name of the cookie indicating a user has been authenticated and logged in
   * and that we need to check their identity for first_name and last_name so that
   * they are prompted for an update if said names have not been provided
   *
   * set to 'true' if the identity needs to be checked
   *
   * @type {string}
   */
  var CHECK_IDENTITY_NAMES_COOKIE = "check_identity_names";

  module.constant("CHECK_IDENTITY_NAMES_COOKIE", CHECK_IDENTITY_NAMES_COOKIE);

  /**
   * The name of the cookie indicating a user has the text boxes toggled
   *
   * set to 'true' if they toggle on
   *
   * @type {string}
   */
  var CHECK_TEXT_RECOGNITION_TEXT_TOGGLE_COOKIE =
    "check_text_recognition_text_toggle";

  module.constant(
    "CHECK_TEXT_RECOGNITION_TEXT_TOGGLE_COOKIE",
    CHECK_TEXT_RECOGNITION_TEXT_TOGGLE_COOKIE
  );

  /**
   * The name of the cookie indicating whether the user
   * wishes to view the mobile or desktop site on his/her
   * mobile device.
   *
   * set to 'desktop' if the users prefers the desktop site on mobile device
   * set to 'mobile' if the user prefers the desktop site on mobile device
   *
   * @type {string}
   */
  var MOBILE_PREFERENCE_COOKIE = "akitabox_mobile_pref";

  module.constant("MOBILE_PREFERENCE_COOKIE", MOBILE_PREFERENCE_COOKIE);

  /**
   * URL for saml users to redirect them to where they were going
   * @type {string}
   */
  var SAML_LOGIN_REDIRECT = "saml_login_redirect";

  module.constant("SAML_LOGIN_REDIRECT", SAML_LOGIN_REDIRECT);

  /**
   * Cookie name for tracking the last used subdomain
   * @type {string}
   */
  var RECENT_SUBDOMAIN_COOKIE = "subdomain";

  module.constant("RECENT_SUBDOMAIN_COOKIE", RECENT_SUBDOMAIN_COOKIE);

  /**
   * Recents cookie key
   * @type {String}
   */
  var KEY_RECENT_COOKIE = "recents";

  module.constant("KEY_RECENT_COOKIE", KEY_RECENT_COOKIE);

  /**
   * Component states
   *
   * @type {Object}
   */
  var COMPONENT_STATE = {
    default: 1,
    success: 2,
    error: 3,
    disabled: 4,
    loading: 5,
    editing: 6,
    saving: 7,
  };

  module.constant("COMPONENT_STATE", COMPONENT_STATE);

  /**
   * Regex pattern to make sure passwords are strong enough
   *  - must contain at least one lower case letter
   *  - must contain at least one upper case letter
   *  - must contain at least one number
   *
   * @type {RegExp}
   */
  var PASSWORD_REQUIREMENTS_PATTERN =
    /^(?!.*password)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$/;

  module.constant(
    "PASSWORD_REQUIREMENTS_PATTERN",
    PASSWORD_REQUIREMENTS_PATTERN
  );

  /**
   * Regex pattern to make sure an input is a valid phone number
   *
   * @type {RegExp}
   */
  var PHONE_VALIDATION_PATTERN = /^(\d{7}|\d{10}|\d{11})$/;

  module.constant("PHONE_VALIDATION_PATTERN", PHONE_VALIDATION_PATTERN);

  /**
   * Google Analytics Tracking ID
   *
   * @type {string}
   */
  var GA_TRACKING_ID = "UA-54928705-1";

  module.constant("GA_TRACKING_ID", GA_TRACKING_ID);

  /**
   * Building types
   *
   * @type {Array}
   */
  var BUILDING_TYPES = [
    "Apartment",
    "Commercial",
    "Education",
    "Government",
    "Health",
    "House",
    "Industrial",
    "Retail Store",
    "Site",
    "Warehouse",
  ];

  module.constant("BUILDING_TYPES", BUILDING_TYPES);

  /**
   * Active maintenance schedule status
   *
   * @type {String}
   */
  var MAINTENANCE_SCHEDULE_STATUS_ACTIVE = "active";

  module.constant(
    "MAINTENANCE_SCHEDULE_STATUS_ACTIVE",
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE
  );

  /**
   * Canceled maintenance schedule status
   *
   * @type {String}
   */
  var MAINTENANCE_SCHEDULE_STATUS_CANCELED = "canceled";

  module.constant(
    "MAINTENANCE_SCHEDULE_STATUS_CANCELED",
    MAINTENANCE_SCHEDULE_STATUS_CANCELED
  );

  /**
   * Prefix for message indicating the user is not allowed to perform an action
   *
   * @type {String}
   */
  var NOT_ALLOWED_ACTION_PREFIX = "You don't have permission to ";

  module.constant("NOT_ALLOWED_ACTION_PREFIX", NOT_ALLOWED_ACTION_PREFIX);

  /**
   * Prefix for message indicating the user is not allowed to view a list page
   *
   * @type {String}
   */
  var NOT_ALLOWED_VIEW_LIST_PREFIX = "You don't have permission to view ";

  module.constant("NOT_ALLOWED_VIEW_LIST_PREFIX", NOT_ALLOWED_VIEW_LIST_PREFIX);

  /**
   * Prefix for message indicating the user is not allowed to view a detail page
   *
   * @type {String}
   */
  var NOT_ALLOWED_VIEW_DETAIL_PREFIX =
    "You don't have permission to view this ";

  module.constant(
    "NOT_ALLOWED_VIEW_DETAIL_PREFIX",
    NOT_ALLOWED_VIEW_DETAIL_PREFIX
  );

  /**
   * Types of Aramark building setups users can run
   *
   * @type {Object}
   */
  var BUILDING_SETUP_TYPES = {
    facilities_fit: {
      name: "Facilities Fit",
      id: "facilities_fit",
    },
    tma: {
      name: "Web TMA",
      id: "tma",
    },
    mechanical: {
      name: "Aramark Mechanical",
      id: "mechanical",
    },
    education: {
      name: "Education",
      id: "education",
    },
  };

  module.constant("BUILDING_SETUP_TYPES", BUILDING_SETUP_TYPES);

  /**
   * Setting pin location from .dwfx script ID
   *
   * @type {String}
   */
  var SET_PIN_LOCATION_STAIRCASE = "set-pin-location-dwfx-staircase";

  module.constant("SET_PIN_LOCATION_STAIRCASE", SET_PIN_LOCATION_STAIRCASE);

  /**
   * Generate unassociated QR Codes script ID
   *
   * @type {String}
   */
  var GENERATE_QRCODES_STAIRCASE = "generate-qrcodes-staircase";

  module.constant("GENERATE_QRCODES_STAIRCASE", GENERATE_QRCODES_STAIRCASE);

  /**
   * List of scripts that may cause serious issues
   *
   * @type {Array}
   */
  var CRITICAL_SCRIPTS = ["delete-building-staircase"];

  module.constant("CRITICAL_SCRIPTS", CRITICAL_SCRIPTS);

  /**
   * Building setup staircase ID
   *
   * @type {String}
   */
  var BUILDING_SETUP_STAIRCASE = "aramark-building-setup";

  module.constant("BUILDING_SETUP_STAIRCASE", BUILDING_SETUP_STAIRCASE);

  /**
   * Set tree acceptable values script ID
   *
   * @type {String}
   */
  var SET_TREE_VALUES_STAIRCASE = "set-tree-values-staircase";

  module.constant("SET_TREE_VALUES_STAIRCASE", SET_TREE_VALUES_STAIRCASE);

  /**
   * Delete room or assets script ID
   *
   * @type {String}
   */
  var BULK_DELETE_ROOMS_ASSETS_STAIRCASE = "bulk-delete-rooms-assets-staircase";

  module.constant(
    "BULK_DELETE_ROOMS_ASSETS_STAIRCASE",
    BULK_DELETE_ROOMS_ASSETS_STAIRCASE
  );

  /**
   * Active inspection program status
   *
   * @type {String}
   */
  var INSPECTION_PROGRAM_STATUS_ACTIVE = "active";

  module.constant(
    "INSPECTION_PROGRAM_STATUS_ACTIVE",
    INSPECTION_PROGRAM_STATUS_ACTIVE
  );

  /**
   * Canceled inspection program status
   *
   * @type {String}
   */
  var INSPECTION_PROGRAM_STATUS_CANCELED = "inactive";

  module.constant(
    "INSPECTION_PROGRAM_STATUS_CANCELED",
    INSPECTION_PROGRAM_STATUS_CANCELED
  );

  /**
   * Work order intent: inspection
   *
   * @type {String}
   */
  var INTENT_INSPECTION_PROGRAM = "inspection";

  module.constant("INTENT_INSPECTION_PROGRAM", INTENT_INSPECTION_PROGRAM);

  /**
   * Priorities
   *
   * @type {Object}
   */
  var PRIORITIES = {
    low: "Low",
    medium: "Medium",
    high: "High",
    emergency: "Emergency",
  };

  module.constant("PRIORITIES", PRIORITIES);

  /**
   * Priorities Array
   *
   * @type {Object}
   */
  var PRIORITIES_ARRAY = ["low", "medium", "high", "emergency"];

  module.constant("PRIORITIES_ARRAY", PRIORITIES_ARRAY);

  /**
   * Sources
   *
   * @type {Object}
   */
  var SOURCES = {
    request: "Service Requests",
    future_task: "Maintenance Schedules",
    reactive_self_identified: "Reactive Self-Identified",
    preventive_self_identified: "Preventive Self-Identified",
  };

  module.constant("SOURCES", SOURCES);

  /**
   * Open service request status
   *
   * @type {String}
   */
  var SERVICE_REQUEST_STATUS_OPEN = "open";

  module.constant("SERVICE_REQUEST_STATUS_OPEN", SERVICE_REQUEST_STATUS_OPEN);

  /**
   * Open service request status
   *
   * @type {String}
   */
  var SERVICE_REQUEST_STATUS_NEW = "new";

  module.constant("SERVICE_REQUEST_STATUS_NEW", SERVICE_REQUEST_STATUS_NEW);

  /**
   * Open service request status
   *
   * @type {String}
   */
  var SERVICE_REQUEST_STATUS_OPEN_NEW = "$in,open,new";

  module.constant(
    "SERVICE_REQUEST_STATUS_OPEN_NEW",
    SERVICE_REQUEST_STATUS_OPEN_NEW
  );

  /**
   * Completed service request status
   *
   * @type {String}
   */
  var SERVICE_REQUEST_STATUS_COMPLETED = "completed";

  module.constant(
    "SERVICE_REQUEST_STATUS_COMPLETED",
    SERVICE_REQUEST_STATUS_COMPLETED
  );

  /**
   * Denied service request status
   *
   * @type {String}
   */
  var SERVICE_REQUEST_STATUS_DENIED = "denied";

  module.constant(
    "SERVICE_REQUEST_STATUS_DENIED",
    SERVICE_REQUEST_STATUS_DENIED
  );

  /**
   * Closed service request status
   *
   * @type {String}
   */
  var SERVICE_REQUEST_STATUS_CLOSED = "$in,completed,denied";

  module.constant(
    "SERVICE_REQUEST_STATUS_CLOSED",
    SERVICE_REQUEST_STATUS_CLOSED
  );

  /**
   * Supported file types
   *
   * @type {Array}
   */
  var SUPPORTED_FILE_TYPES = [
    "doc",
    "docx",
    "dwfx",
    "jpg",
    "jpeg",
    "mp3",
    "mp4",
    "pdf",
    "png",
    "rvt",
    "xls",
    "xlsx",
    "zip",
  ];

  module.constant("SUPPORTED_FILE_TYPES", SUPPORTED_FILE_TYPES);

  /**
   * Keys used in storing information about a user's session
   */
  var SESSION_KEYS = {
    SESSION_PREFIX: "user_session_",
    SELECTED_BUILDING_KEY: "selected_building_id",
    SELECTED_ORGANIZATION_KEY: "selected_organization_id",
    SELECTED_PLANVIEW_FILTERS: "selected_planview_filters",
    DASHBOARD_CARD_STATES: "dashboard_card_states",
    REPORTING_FILTERS: "reporting_filters",
    INSPECTION_PROGRAM_INSTRUCTION_DRAWER: "ip_instruction_drawer_state",
    WO_CALENDAR_KEY: "wo_calendar_state",
    START_DATE_COLUMN_KEY: "show_start_date_column",
    SAVED_WORK_ORDER_SORT_COLUMN: "saved_work_order_sort_column",
    SAVED_WORK_ORDER_SORT_ORDER: "saved_work_order_sort_order",
    SELECTED_COST_ORGANIZATION_KEY: "selected_cost_organization_id",
    ROOM_LIST_SIDEBAR_TOGGLE_STATE: "room_list_sidebar_toggle_state",
    ASSET_LIST_SIDEBAR_TOGGLE_STATE: "asset_list_sidebar_toggle_state",
  };

  module.constant("SESSION_KEYS", SESSION_KEYS);

  /**
   * Supported file types that can be converted into thumbnails
   *
   * @type {[*]}
   */
  var SUPPORTED_IMAGE_FILE_TYPES = [
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/png",
    "text/plain",
    "application/pdf",
  ];

  module.constant("SUPPORTED_IMAGE_FILE_TYPES", SUPPORTED_IMAGE_FILE_TYPES);

  /**
   * Thumbnail (building) fit options
   *
   * @type {[*]}
   */
  var THUMBNAIL_FIT_OPTIONS = [
    {
      value: "smallest",
      label: "Default",
    },
    {
      value: "width",
      label: "Width",
    },
    {
      value: "height",
      label: "Height",
    },
    {
      value: "all",
      label: "Full Image",
    },
  ];

  var WORK_ORDER_FILTER_OPTIONS = [
    {
      value: null,
      label: "All",
    },
    {
      value: "reactive",
      label: "Reactive",
      html:
        "<span class='work-order-intent__reactive'>" +
        "<span class='reactive-triangle'></span>" +
        "<span class='reactive-label'>R</span></span>",
    },
    {
      value: "preventive",
      label: "Preventive",
      html: "<span class='work-order-intent__preventive'>P</span>",
    },
  ];

  module.constant("THUMBNAIL_FIT_OPTIONS", THUMBNAIL_FIT_OPTIONS);
  module.constant("WORK_ORDER_FILTER_OPTIONS", WORK_ORDER_FILTER_OPTIONS);

  /**
   * Issue type intent
   *
   * @type {String}
   */
  var INTENT_ISSUE_TYPE = "Reactive";
  module.constant("INTENT_ISSUE_TYPE", INTENT_ISSUE_TYPE);

  /**
   * Maintenance type intent
   *
   * @type {String}
   */
  var INTENT_MAINTENANCE_TYPE = "Preventive";
  module.constant("INTENT_MAINTENANCE_TYPE", INTENT_MAINTENANCE_TYPE);

  /**
   *
   * @type {Object}
   */
  var INTENTS = {
    reactive: "Reactive",
    preventive: "Preventive",
  };

  module.constant("INTENTS", INTENTS);

  /**
   * Open work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_OPEN = "open";

  module.constant("WORK_ORDER_STATUS_OPEN", WORK_ORDER_STATUS_OPEN);

  /**
   * Scheduled work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_SCHEDULED = "scheduled";

  module.constant("WORK_ORDER_STATUS_SCHEDULED", WORK_ORDER_STATUS_SCHEDULED);

  /**
   * Overdue work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_OVERDUE = "overdue";

  module.constant("WORK_ORDER_STATUS_OVERDUE", WORK_ORDER_STATUS_OVERDUE);

  /**
   * Scheduled work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_SCHEDULED_OPEN = "$in,scheduled,open";

  module.constant(
    "WORK_ORDER_STATUS_SCHEDULED_OPEN",
    WORK_ORDER_STATUS_SCHEDULED_OPEN
  );

  /**
   * Closed work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_CLOSED = "$in,completed,canceled";

  module.constant("WORK_ORDER_STATUS_CLOSED", WORK_ORDER_STATUS_CLOSED);

  /**
   * Completed work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_COMPLETED = "completed";

  module.constant("WORK_ORDER_STATUS_COMPLETED", WORK_ORDER_STATUS_COMPLETED);

  /**
   * Completed work order status
   *
   * @type {String}
   */
  var WORK_ORDER_STATUS_CANCELED = "canceled";

  module.constant("WORK_ORDER_STATUS_CANCELED", WORK_ORDER_STATUS_CANCELED);

  /**
   * Enumerable string for the `data_type` field found on pin fields
   */
  var PIN_FIELD_DATA_TYPES = {
    BOOLEAN: "boolean",
    DATE: "date",
    DOCUMENT_ARRAY: "document_array",
    ENUM: "enum",
    FLOAT: "float",
    FLOOR: "level",
    INT: "int",
    PARAGRAPH: "paragraph",
    ROOM: "room",
    STRING: "string",
    TAG_FILTER: "tag_filter",
    TREE: "tree",
    URL: "url",
  };

  module.constant("PIN_FIELD_DATA_TYPES", PIN_FIELD_DATA_TYPES);

  /**
   * User-facing names of the different pin models
   */
  var PIN_MODELS = ["asset", "room"];

  module.constant("PIN_MODELS", PIN_MODELS);

  /**
   * Our standard image sizes
   *
   * @type {[String]}
   */
  var IMAGE_SIZES = {
    MINI: "mini",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
  };

  module.constant("IMAGE_SIZES", IMAGE_SIZES);

  /**
   * Enumerable string of KeyboardEvent.keyCodes being used by in app
   */
  var KEY_CODES = {
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
  };

  module.constant("KEY_CODES", KEY_CODES);

  /**
   * States
   *
   * @type {Object}
   */
  var STATES = {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  module.constant("STATES", STATES);

  /**
   * Array of colors that we use for charts
   *
   * @type {Array}
   */
  var DEFAULT_CHART_COLOR_RANGE = [
    "#285D85",
    "#2D8DD9",
    "#99CFEA",
    "#2F9462",
    "#94CB17",
    "#AAB96D",
    "#FDD835",
    "#FF960B",
    "#FF4F00",
    "#BE0000",
    "#F094AA",
    "#A67FD1",
    "#6B3793",
    "#999999",
    "#D9D9D9",
  ];

  module.constant("DEFAULT_CHART_COLOR_RANGE", DEFAULT_CHART_COLOR_RANGE);

  /**
   * Minimum time (in ms) to delay when rendering
   *
   * @type {Number}
   */
  var MINIMUM_RENDER_DELAY = 750; // ms

  module.constant("MINIMUM_RENDER_DELAY", MINIMUM_RENDER_DELAY);

  /** @ngInject **/
  function DatadogConfigFactory(EnvService) {
    const abxEnvToDatadogEnvMap = {
      production: "prod",
    };
    const envName = EnvService.getEnvName();

    const dataDogEnv =
      abxEnvToDatadogEnvMap[envName] || abxEnvToDatadogEnvMap.production;
    /**
     * DataDog Rum Config
     *
     * @type {Object}
     */
    const datadogWebClientConfig = {
      applicationId: "4b662c09-3e9a-447d-b72c-80073108bafb",
      clientToken: "pub4e97b5baa4eec677dc1fc29c41361a6c",
      site: "datadoghq.com",
      service: "web-client",
      env: dataDogEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      defaultPrivacyLevel: "mask-user-input",
      trackUserInteractions: true,
    };
    return datadogWebClientConfig;
  }

  module.factory("DATADOG_WEB_CLIENT_CONFIG", DatadogConfigFactory);
})();
