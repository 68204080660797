(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.detail")
    .service("WorkOrderDetailDialog", WorkOrderDetailDialog);

  /** @ngInject */
  function WorkOrderDetailDialog($q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/work-order/detail/work-order-detail.dialog.html",
      controller: "WorkOrderDetailDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
      multiple: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {{}} options - Dialog options
     * @param {{}} options.locals - options used by the dialog that binds to itself (this)
     * @param {WorkOrder | () => Promise<WorkOrder>} [options.locals.workOrder] - The work
     *  order to show details for, or a getter function.
     * @return {Promise<Object[]|Error>}
     */
    function show(options) {
      if (!options || !options.locals || !options.locals.workOrder)
        return $q.reject("options.locals.workOrder is required");

      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
