/**
 * Decodes a base64 encoded string into a regular text string (handles the "Unicode Problem")
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 *
 * @param {string} text - Represents the encoded `text` that needs to be decoded
 * @returns The decoded string representation
 */
export function decodeText(text: string) {
  const str = atob(text);
  const bytes = Uint8Array.from(str, (char) => char.codePointAt(0) as number);
  return new TextDecoder().decode(bytes);
}
