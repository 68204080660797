(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedLevelFilter",
      function (ManagedFilterHelpers, ManagedModelFieldFilter, FloorService) {
        /**
         * @class
         * A level (floor) filter can be used with any query that has a `level`
         * field expecting a level (floor) id. It may be paired with up to one
         * asset filter and one room filter, which will cause their enum options
         * to be invalidated when this filter value changes.
         * @param {object} options
         * @param {() => string} getBuildingId - A function that should return the
         *  building ID to use for fetching enum options/chip text.
         */
        function ManagedLevelFilter(manager, options) {
          if (!options || typeof options.getBuildingId !== "function") {
            throw new Error(
              "Invalid options, getBuildingId must be a function."
            );
          }

          /**
           * @member
           * @type {() => string}
           */
          this.getBuildingId = options.getBuildingId;

          /**
           * @member
           * @type { ManagedFilterConfiguration | undefined }
           * The room configuration (if any) that depends on this filter.
           */
          this.roomConfig = undefined;

          /**
           * @member
           * @type { ManagedFilterConfiguration | undefined}
           * The asset configuration (if any) that depends on this filter.
           */
          this.assetConfig = undefined;

          var filterConfigurationOptions = {
            displayName: "Floor",
            queryField: "level",
            inputType: "typeahead",
            modelValueToFilterValue: ManagedFilterHelpers.modelToId,
            modelValueToChipText: ManagedFilterHelpers.modelToName,
          };

          ManagedModelFieldFilter.call(
            this,
            manager,
            filterConfigurationOptions
          );
        }
        // ManagedLevelFilter extends ManagedModelFieldFilter
        ManagedLevelFilter.prototype = Object.create(
          ManagedModelFieldFilter.prototype
        );
        ManagedLevelFilter.prototype.constructor = ManagedLevelFilter;

        /**
         * Fetch enum options for this filter. Fetches all levels (floors) for a
         * the building.
         * @return {Promise<[]>} - An array of enum options for this filter.
         */
        ManagedLevelFilter.prototype.getEnumOptions = function () {
          return FloorService.getAll(this.getBuildingId()).then(
            ManagedFilterHelpers.mapModelsToEnumOption
          );
        };

        /**
         * Fetch a level (floor) by its id.
         * @return {Promise<Level>}
         */
        ManagedLevelFilter.prototype.filterValueToModelValue = function (id) {
          return FloorService.getById(this.getBuildingId(), id).then(function (
            floor
          ) {
            return [floor];
          });
        };

        /**
         * Invalidate the enum options of dependent filters when this filter
         * is cleared.
         */
        ManagedLevelFilter.prototype.afterRemove = function () {
          this.roomConfig && this.roomConfig.invalidateEnumOptions();
          this.assetConfig && this.assetConfig.invalidateEnumOptions();
        };

        /**
         * Couple this filter configuration to a room configuration. Makes this
         * filter configuration fire any necessary events to maintain the
         * asset/level/room hierarchy when filtering.
         * @param [roomConfig] - The room configuration to pair with this
         *  filter configuration.
         * @return { void }
         */
        ManagedLevelFilter.prototype.setRoomConfig = function (roomConfig) {
          this.roomConfig = roomConfig;
        };

        /**
         * Couple this filter configuration to an asset configuration. Makes this
         * filter configuration fire any necessary events to maintain the
         * asset/level/room hierarchy when filtering.
         * @param [assetConfig] - The asset configuration to pair with this
         *  filter configuration.
         * @return { void }
         */
        ManagedLevelFilter.prototype.setAssetConfig = function (assetConfig) {
          this.assetConfig = assetConfig;
        };

        return ManagedLevelFilter;
      }
    );
})();
