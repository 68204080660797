(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller(
      "ScheduleOpenWorkOrdersTabController",
      ScheduleOpenWorkOrdersTabController
    );

  /* @ngInject */
  function ScheduleOpenWorkOrdersTabController(
    // Resolves
    building,
    schedule,
    // Services
    OrganizationService,
    WorkOrdersBFFService,
    // Constants
    WORK_ORDER_STATUS_SCHEDULED_OPEN
  ) {
    var self = this;

    var defaultParams = {
      status: WORK_ORDER_STATUS_SCHEDULED_OPEN,
      future_task: schedule._id,
    };

    // Attributes
    self.building = building;
    self.schedule = schedule;
    self.workOrders = null;
    self.status = WORK_ORDER_STATUS_SCHEDULED_OPEN;
    self.organization = OrganizationService.getCurrent();

    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;

    init();

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchWorkOrders(skip, limit) {
      self.loading = true;
      var params = angular.extend({}, defaultParams, {
        skip: skip,
        limit: limit,
      });

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      ).then(function (workOrders) {
        if (angular.isArray(self.workOrders)) {
          self.workOrders = self.workOrders.concat(workOrders);
        } else {
          self.workOrders = workOrders;
        }
        return workOrders.length;
      });
    }

    function fetchAllWorkOrders(limit) {
      self.loading = true;
      var params = angular.extend({}, defaultParams, {
        skip: 0,
        limit: limit,
      });

      return WorkOrdersBFFService.getAllByBuilding(
        self.organization._id,
        building._id,
        params
      ).then(function (workOrders) {
        self.workOrders = workOrders;
        return workOrders.length;
      });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      var params = angular.extend({}, defaultParams, { count: true });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      ).then(function (data) {
        self.totalWorkOrders = data.count;
      });
    }
  }
})();
