(function () {
  angular
    .module("akitabox.ui.dialogs.printInspectionProgram")
    .controller(
      "PrintInspectionProgramDialogController",
      PrintInspectionProgramDialogController
    );

  /* @ngInject */
  function PrintInspectionProgramDialogController(
    // Material
    $mdDialog
  ) {
    var self = this;

    self.cancel = $mdDialog.cancel;
    self.print = $mdDialog.hide;
  }
})();
