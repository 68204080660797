(function () {
  angular.module("akitabox.desktop.asset.list", [
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.room",
    "akitabox.core.services.filter",
    "akitabox.core.services.floor",
    "akitabox.core.services.pin",
    "akitabox.core.services.pinType",
    "akitabox.core.services.flag",
    "akitabox.ui.dialogs.asset",
    "akitabox.desktop.components.filterBar",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
