(function () {
  /**
   * @ngdoc component
   * @name abxPlanViewApp
   *
   * @controls {*[]} pinFilters - Active filters for pins
   * @controls {Object} building - Current building
   * @controls {Object} floor - Current floor
   * @controls {Number} page - Page of the floor plan to view
   * @controls {Object[]} pinTypes - All pin types for the selected building
   * @controls {Object} shownPins - Currently shown pins on the floor plan
   * @controls {Object} selectedPin - Currently selected pin
   *
   * @description
   * Wrapper component for the entire PlanView application.
   */
  angular.module("akitabox.planView").component("abxPlanViewApp", {
    controller: AbxPlanViewAppController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/plan-view-app/plan-view-app.component.html",
  });

  /** @ngInject */
  function AbxPlanViewAppController(
    // Angular
    $location,
    $log,
    $q,
    $rootScope,
    $scope,
    $timeout,
    $window,
    // Third-party
    $state,
    $stateParams,
    // AkitaBox
    models,
    // Constants
    EVENT_FLOOR_ADD,
    EVENT_FLOOR_NEW_PAGE_SELECTED,
    EVENT_PIN_DUPLICATE,
    EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED,
    EVENT_QR_CODE_SCANNED,
    EVENT_ROUND_TASK_STOP_SELECTED,
    EVENT_ROUND_TASK_PIN_SELECTED,
    EVENT_ROUND_TASK_PIN_COLOR_UPDATE,
    EVENT_ROUND_TASK_MOVE_STOP,
    EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES,
    INVALID_URL_LENGTH_MSG,
    IGNORE_FILTER_MSG,
    MODES,
    PV_GA,
    VERSION_MODES,
    MARKUP_TOOL,
    // Libraries
    zendesk,
    // Helpers
    ServiceHelpers,
    Utils,
    // Dialogs
    AlertDialog,
    AssociateQrCodeDialog,
    CreateAssetDialog,
    CreateFloorDialog,
    CreateInspectionProgramDialog,
    CreateRequestDialog,
    CreateRoomDialog,
    CreateScheduleDialog,
    CreateWorkOrderDialog,
    LegacyFacFilterDialog,
    UpdateIdentityNamesDialog,
    PinFieldFloorConfirmationDialog,
    // Services
    AssetService,
    BuildingService,
    ChecklistService,
    EnvService,
    FeatureFlagService,
    FloorService,
    IdentityService,
    InspectionProgramService,
    OrganizationService,
    PinTypeService,
    PinValueService,
    RedirectService,
    RoomService,
    SessionService,
    ShadowService,
    ToastService,
    UserService,
    WorkOrderService,
    WorkOrderRoundService,
    CustomerAccountService
  ) {
    var self = this;
    self.organization = OrganizationService.getCurrent();

    // Constants
    var DEFAULT_PAGE = 1;
    var ASSET_PROTECTED_TYPE = "Asset";
    var ROOM_PROTECTED_TYPE = "Room";

    // Private
    var _PinService; // Service to use for pin API calls
    var _permissions = UserService.getPermissions();
    /**
     * Map used to track which checklist status' pins are currently visible.
     * Initially, all are visible.
     */
    var checklistStatusVisibilityMap = Object.keys(
      models.CHECKLIST.STATUSES
    ).reduce(function (map, key) {
      map[models.CHECKLIST.STATUSES[key]] = true;
      return map;
    }, {});

    // Attributes
    self.building = BuildingService.getCurrent();
    self.permissions = getPermissions();
    self.pinFilters = {
      pin_types: {},
    };
    self.pins = {};
    self.shownPins = [];
    self.floorStops = [];
    self.allStops = [];
    self.modeStack = getInitialModeStack();
    self.unplacedPins = [];
    self.decommissionedPins = [];
    self.versionMode = VERSION_MODES.DEFAULT;
    self.pinSearch = {
      multiSearch: null,
      singleSelect: null,
    };
    self.openUnplacedPinsOnInit = false;
    self.hasUnplacedPins = false;
    self.isRedirecting = false;
    self.switcherOpen = false;
    self.isUploadingAttachments = false;

    // Initial evaluation of building and floor from URL on page load is done
    self.hasInitialized = false;

    // Functions
    self.clearSelectedPin = clearSelectedPin;
    self.getPinsByName = getPinsByName;
    self.handlePinSearchItemSelect = handlePinSearchItemSelect;
    self.handleSearchIconClick = handleSearchIconClick;
    self.handleSelectedPinDelete = handleSelectedPinDelete;
    self.handleVersionSelected = handleVersionSelected;
    self.onAdd = onAdd;
    self.setFloor = setFloor;
    self.setMode = setMode;
    self.setPinTypeFilter = setPinTypeFilter;
    self.setPrimarySelectedPin = setPrimarySelectedPin;
    self.setMultiSelectedPin = setMultiSelectedPin;
    self.handleDocumentChange = handleDocumentChange;
    self.updateSelectedPin = updateSelectedPin;
    self.getCurrentMode = getCurrentMode;
    self.isPlacing = isPlacing;
    self.isInspection = isInspection;
    self.handleRoundTaskChange = handleRoundTaskChange;
    self.handleChecklistStatusVisibilityToggle =
      handleChecklistStatusVisibilityToggle;
    self.getIsChecklistStatusVisible = getIsChecklistStatusVisible;
    self.handleAttachmentUploadStart = handleAttachmentUploadStart;
    self.handleAttachmentUploadsFinished = handleAttachmentUploadsFinished;
    self.getPinIcon = getPinIcon;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      IdentityService.getCurrent()
        .then(function (identity) {
          var prefillData = {
            email: {
              value: identity.email,
              readOnly: true,
            },
          };
          var firstName = identity.first_name;
          var lastName = identity.last_name;
          if (firstName && firstName.length) {
            prefillData.name = {
              value: firstName,
            };
            if (lastName && lastName.length) {
              prefillData.name.value += " " + lastName;
              prefillData.name.readOnly = true;
            }
          }
          zendesk("webWidget", "prefill", prefillData);
        })
        .then(CustomerAccountService.getActiveTheme)
        .then((theme) => {
          // If the customer account has no active theme, show the help button
          if (!theme) {
            var helpIconElement = document.querySelector("#help-button");
            if (helpIconElement) {
              helpIconElement.style.display = "flex";
            }
          }
        });

      if (
        !Utils.validateUrlLength() ||
        $stateParams.pin_types === "undefined"
      ) {
        // Alert the user and discard the query string if the URL is too long
        // for their browser. `pin_types` becomes "undefined" in IE when
        // redirected from login
        AlertDialog.show({
          locals: {
            title: "Oops!",
            textContent: INVALID_URL_LENGTH_MSG,
          },
        }).then(function () {
          $location.search("");
          $window.location.reload();
        });
        // Skip any other initialization logic. Prevent filters from being
        // saved for future page loads
        return;
      }

      // Prevent the sidebar from opening briefly and closing when loading
      // in with search results.
      if (urlHasStoredSearch()) {
        setSearchResultsMode();
      }

      initUrlModels().then(function () {
        // Show an Alert telling the user why their legacy FAC filters were removed
        if ($stateParams.legacyFilter === "true") {
          return LegacyFacFilterDialog.show().then(function () {
            // Remove the query string flag marker that triggers this dialog as the user has confirmed it.
            $location.search("legacyFilter", null);
            delete $stateParams.legacyFilter;
          });
        }
      });

      $scope.$on(EVENT_PIN_DUPLICATE, duplicatePin);
      $scope.$on(EVENT_QR_CODE_SCANNED, handleQrCodeScan);
      $scope.$on(EVENT_ROUND_TASK_STOP_SELECTED, setSelectedStop);
      $scope.$on(EVENT_ROUND_TASK_MOVE_STOP, handleStopChecklistStatusChange);
      $scope.$on(
        EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES,
        reapplyChecklistVisibilityToggles
      );

      // reload the page when the user hits the back or forward button
      window.addEventListener("popstate", function () {
        $window.location.reload();
      });

      // Attempt to show the UpdateIdentityNamesDialog, see method for logic
      UpdateIdentityNamesDialog.show();

      self.isUploadingAttachments = false;
    };

    $rootScope.$on(EVENT_FLOOR_NEW_PAGE_SELECTED, function ($event, data) {
      self.page = data.page;
      $stateParams.page = self.page;
      $location.search("page", self.page);

      if (self.selectedPin) {
        var selectedPinIsOnPage = isPinOnCurrentPage(self.selectedPin);
        var isPlacingPin = getCurrentMode() === MODES.PIN_PLACEMENT;
        if (!selectedPinIsOnPage && !isPlacingPin) {
          self.selectedPin = null;
        }
      }

      // if switching page while in search mode
      if (getCurrentMode() === MODES.SEARCH_RESULTS) {
        initSearch();
      } else {
        setShownPins();
      }
      // make sure pin type counts always match the page
      attachPinTypeCounts(self.pinTypes);
    });

    // =================
    // Public Functions
    // =================

    /**
     * Set the current floor. Since the building for the app will only change
     * when a floor has been changed, set the building at the same time.
     *
     * If the given data is equivalent to the respective state data for
     * the component, this function is a no-op.
     *
     * @param {Object} [event.floor] - New floor
     * @param {Object} [event.building] - New building associated with
     *     floor change
     * @param {Object} [event.organization] - New organization associated with
     *     floor change
     * @param {String} [event.floorId] - New floor's ID
     * @param {String} [event.buildingId] - New building's ID
     * @param {Boolean} [event.ignoreShownPins] - Ignore fetching and showing
     *     the pins for the new floor. An example use case is when coming to a
     *     new floor with a search-selected pin -- in this case, we know that's
     *     the only pin that should be showing, so we can defer fetching the
     *     new floor's pins until after that search is cleared.
     * @param {String} [event.orgRedirect] - URL to redirect to if the
     *     organization has changed. This is necessary, since the subdomain
     *     will be changing and we can't simply change the state of the app.
     * @return {Promise<>} - Resolves when the necessary state changes, if any,
     *     are finished. Does not wait until subsequent operations (i.e.
     *     fetching new pins) are finished to resolve.
     */
    function setFloor(event) {
      var floor = event.floor;
      var building = event.building;
      var floorId = event.floorId || event.floor._id;
      var buildingId = event.buildingId || event.building._id;

      var floorHasChanged = !self.floor || floorId !== self.floor._id;
      var buildingHasChanged =
        !self.building || buildingId !== self.building._id;

      if (!buildingHasChanged && !floorHasChanged) {
        return $q.resolve();
      }

      var organization = event.organization;
      if (!Utils.isSameModel(organization, self.organization)) {
        RedirectService.redirectToOrganization(
          organization,
          "/plan/buildings/" + buildingId + "/levels/" + floorId,
          event.newTab
        );
        return;
      }

      var requests = [];
      if (!floor && floorHasChanged) {
        getFloor();
      }
      if (!building && buildingHasChanged) {
        getBuilding();
      }

      return $q.all(requests).then(function () {
        if (event.newTab) {
          var url = $state.href(
            "planView.building.floor",
            {
              buildingId: building._id,
              floorId: floor._id,
            },
            { inherit: false }
          );
          $window.open(url, "_blank");
          return;
        }
        setSearchStateParams(null);

        if (self.isInspection()) {
          // reset all the selections, they are changing the floor
          self.floor = floor;
          self.stop = null;
          self.selectedPin = null;
          return $state
            .go("planView.building.floor", {
              buildingId: self.building._id,
              floorId: self.floor._id,
              stop: null,
            })
            .then(initRound)
            .then(reapplyChecklistVisibilityToggles);
        }

        if (floorHasChanged) {
          if (floor && !floor.document) {
            ToastService.showError("No floor plan for this floor");
            return;
          }
          setPageToDefault();

          self.floor = floor;
          clearSelectedPin();
          self.setMode({ mode: MODES.DEFAULT }); // TODO: only place we do this in this component
        }
        if (buildingHasChanged) {
          if (event.organization) {
            self.organization = event.organization;
          }
          self.building = building;
          OrganizationService.setCurrent(self.organization);
          BuildingService.setCurrent(self.building);
          clearPinData();

          setPinTypes(self.building._id).then(function () {
            if (!event.ignoreShownPins) {
              setShownPins();
            }
          });
        } else if (floorHasChanged) {
          if (!event.ignoreShownPins) {
            setShownPins();
          }
          attachPinTypeCounts(self.pinTypes);
        }

        if (self.floor && self.building) {
          self.unplacedPins = [];
          getUnplacedPins();

          // re-query for the decommissioned pins since the floor or building might have changed
          self.decommissionedPins = [];
          getDecommissionedPins();

          // Update the URL
          return $state.go("planView.building.floor", {
            buildingId: self.building._id,
            floorId: self.floor._id,
          });
        }
      });

      function getFloor() {
        var floorRequest = FloorService.getById(buildingId, floorId).then(
          function (_floor) {
            floor = _floor;
          }
        );
        requests.push(floorRequest);
      }

      function getBuilding() {
        var buildingRequest = BuildingService.getById(buildingId).then(
          function (_building) {
            building = _building;
          }
        );
        requests.push(buildingRequest);
      }
    }

    /**
     * Updates the controller's floor with a new document object.
     *
     * @param {Object} event
     * @param {Object} event.document New or updated document object values.
     * @param {Object} event.floor The floor to associate these changes with.
     */
    function handleDocumentChange(event) {
      var document = event.document || {};
      event.floor.document = document;
      if (Utils.isSameModel(event.floor, self.floor)) {
        self.floor = angular.copy(self.floor);
      }
    }

    /**
     * Handler for pin deletion.  Updates pin count and clears
     * the selected pin.
     */
    function handleSelectedPinDelete() {
      if (!self.selectedPin) {
        return;
      }
      var selectedPin = self.selectedPin;

      var pinType = getPinTypeById(selectedPin.pinType._id);
      if (!pinType) {
        return;
      }
      removeFromUnplacedPins(self.selectedPin);
      self.clearSelectedPin();
      pinType.pins_count--;

      // When in search results mode, skip refetching anything and just
      // naively cut the pin out of the list.
      if (getCurrentMode() === MODES.SEARCH_RESULTS) {
        self.shownPins = self.shownPins.filter(function (pin) {
          return pin._id !== selectedPin._id;
        });
      } else {
        refreshShownPinsByPinType(pinType); // updates pins on map
        if (isFinite(pinType.shown_pins_count)) {
          pinType.shown_pins_count--;
        }
      }
    }

    /**
     *  Search our pin types and return the matching type or null
     *
     * @param {String} pinTypeId the _id of the pin type sought
     */
    function getPinTypeById(pinTypeId) {
      if (!self.pinTypes) return;
      var pinType = self.pinTypes.filter(function (pt) {
        return pt._id === pinTypeId;
      });

      return pinType.length ? pinType[0] : null;
    }

    /**
     * Sets the active filter for a given pin type in our active filters.
     *
     * @param {Object} event - Propagated event.
     * @param {Object} [event.filter] - Filter object to use in API requests for
     *     filtering on the given pin type.
     * @param {String} [event.pinType] - Pin type to modify filter of.
     *
     * @return {Promise}
     */
    function setPinTypeFilter(event) {
      var filter = event.filter;
      var pinType = event.pinType;

      if (filter && pinType && isPinTypeValid(pinType._id)) {
        // Validate URL length only if we're possibly growing the query string
        // length (i.e. adding a pin type filter that wasn't there before)
        if (!validateFilterChange()) return $q.resolve();

        self.pinFilters.pin_types[pinType._id] = filter;
      } else if (pinType) {
        delete self.pinFilters.pin_types[pinType._id];
      }

      self.pinFilters.pin_types = angular.extend({}, self.pinFilters.pin_types);

      // Update the filters saved by the session service
      var buildingId = getBuildingId();
      SessionService.setPlanViewFilters(buildingId, self.pinFilters.pin_types);

      // Update the filters displayed in the URL
      updateQueryStringFilters();

      if (!pinType) return $q.resolve();

      var isActivatingFilter = Boolean(filter);
      pinType.is_fetching_pins = isActivatingFilter;
      return getPinsByPinTypeFilter(pinType, filter).finally(function () {
        if (pinTypeFilterIsActive(filter, pinType._id)) {
          pinType.is_fetching_pins = false;
        }
      });
    }

    /**
     * Fetches room number/asset names that match searchQuery
     *
     * @param {String} searchQuery Used to query on a pins name
     * @returns {Promise<Object[]>} Pins that match the query
     */
    function getPinsByName(searchQuery) {
      var floorId = self.floor ? self.floor._id : null;
      var roomParams = {
        skip: 0,
        limit: 100,
        level: floorId,
        page: self.page,
        $or: `name=${searchQuery};number=${searchQuery}`,
      };

      var assetParams = {
        skip: 0,
        limit: 100,
        level: floorId,
        name: searchQuery,
        page: self.page,
      };
      var options = {
        includePinType: true,
      };

      var roomRequest = RoomService.getByBFFRoomsOrganization(
        self.organization._id,
        roomParams,
        options
      );
      var assetRequest = AssetService.getByBFFOrganization(
        self.organization._id,
        assetParams
      );

      var filteredUnplacedPins = self.unplacedPins.filter(function (pin) {
        return pin.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
      });
      filteredUnplacedPins = angular.copy(filteredUnplacedPins);

      // Filter out the matching decommissioned pins to show in the search result
      var filteredDecommissionedPins = self.decommissionedPins.filter(function (
        pin
      ) {
        return (
          pin.name.toLowerCase().indexOf(searchQuery.toLocaleLowerCase()) > -1
        );
      });
      filteredDecommissionedPins = angular.copy(filteredDecommissionedPins);
      return $q
        .all([roomRequest, assetRequest])
        .then(function (data) {
          // merge all 3 types of pins so they show up in the search results
          return addPlacement(data[0]).concat(
            addPlacement(data[1]),
            filteredUnplacedPins,
            filteredDecommissionedPins
          );
        })
        .catch(function (error) {
          ToastService.showError(error);
          return $q.reject(error);
        });
    }

    function addPlacement(pins) {
      return pins.map((pin) => {
        return {
          ...pin,
          is_placed_on_document: pin && pin.percentX && pin.percentY,
        };
      });
    }

    /**
     * Handle behavior after explicitly selecting a room or asset from the
     * global search
     *
     * @param {Object} $event        $event object
     * @param {Object} $event.pin    the chosen pin
     */
    function handlePinSearchItemSelect($event) {
      ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.SEARCH_SINGLE);
      setSearchSelectedPin($event);
    }

    /**
     * Clears the selected pin.
     */
    function clearSelectedPin() {
      self.setPrimarySelectedPin({ pin: null });
    }

    /**
     * Changes which pin is selected. Is a no-op if the same pin model (i.e.
     * matching IDs) is already selected.
     *
     * @param {Object}  event
     * @param {Object}  event.pin - Newly selected pin. Should be null if
     *     clearing the selected pin.
     * @param {Boolean} fromSelectedStop - true if function is called as the result
     *      of selecting a stop from the round sidebar, false otherwise
     */
    function setPrimarySelectedPin(event, fromSelectedStop) {
      if (self.isUploadingAttachments) {
        AlertDialog.show({
          locals: {
            title: "File Upload in Progress",
            textContent: "Please wait until the files are done uploading!",
            isShort: true,
          },
        });
        return;
      }

      if (event.pin !== null) {
        const isBuildingPropObject =
          event.pin.building && typeof event.pin.building === "object";
        if (isBuildingPropObject) {
          event.pin.building = event.pin.building._id;
          event.pin.values = parseValueProperty(event.pin);
        }
      }

      // if a user clicks a pin on a floorplan and we are in Round mode, emit
      // an event so it can select the correct stop in the sidebar
      if (self.isInspection() && !fromSelectedStop) {
        var pin = event.pin;
        var selectedStop = null;
        if (pin) {
          for (var i = 0; i < self.floorStops.length; ++i) {
            var stop = self.floorStops[i];
            var stopPin = stop.room || stop.asset;
            if (!stopPin) continue;
            if (pin._id === stopPin._id) {
              selectedStop = stop;
              break;
            }
          }
        }
        $scope.$broadcast(EVENT_ROUND_TASK_PIN_SELECTED, {
          pin: pin,
          stop: selectedStop,
        });
        $scope.$broadcast(EVENT_ROUND_TASK_STOP_SELECTED, {
          stop: selectedStop,
        });
      }
      // Validate the URL length only if this would increase the query string
      // length (i.e. there was no selected pin in it, and now there would be)
      if (event.pin && !self.selectedPin && !validateFilterChange()) return;

      if (self.selectedPin && !Utils.isSameModel(self.selectedPin, event.pin)) {
        // Delete pin values that were needed for the old selected pin
        delete self.selectedPin.values;
      }
      setPinQueryParams(event.pin);

      self.selectedPin = event.pin;
      // if a pin has been explicetly selected as the primary pin, the we clear all multi-selected pins
      self.multiSelectedPins = self.selectedPin ? [self.selectedPin] : [];

      _PinService = self.selectedPin
        ? ServiceHelpers.parsePinService(self.selectedPin.pinType)
        : null;
    }

    function setMultiSelectedPin(event) {
      var pin = event.pin;
      if (!pin) {
        return;
      }

      // Find the index at which `pin` resides in `self.multiSelectedPins`
      // `.indexOf()` isn't reliable here because this is an array of objects
      var index = null;
      if (self.multiSelectedPins) {
        for (var i = 0; i < self.multiSelectedPins.length; i++) {
          if (self.multiSelectedPins[i]._id === pin._id) {
            index = i;
            break;
          }
        }
      }
      var pinAlreadyMultiSelected = index !== null;

      if (pinAlreadyMultiSelected) {
        // remove pin from array
        self.multiSelectedPins.splice(index, 1);
        if (!self.multiSelectedPins.length) {
          // unselect primary selected pin all pins have been deselected
          self.setPrimarySelectedPin({ pin: null });
        }
      } else {
        // add the pin to the selected array
        if (!self.multiSelectedPins) {
          self.multiSelectedPins = [];
        }
        if (!self.selectedPin) {
          self.setPrimarySelectedPin({ pin: pin });
        } else {
          self.multiSelectedPins.push(pin);
        }
      }

      // Using `slice` creates a new array object and allows
      // downstream usages of `$onChanges` to be triggered
      self.multiSelectedPins = self.multiSelectedPins.slice();
    }

    /**
     * Update the selected pin with the given data.
     *
     * @param {Object|Array} event - Propagated event; a single object or an array of objects with `field` & `newValue` properties
     * @param {String} event.field - Field to update on the pin
     * @param {*} event.newValue - New value to assign
     * @param {Object} [event.pinField] - Pin field of the pin value being
     *     updated. Should be present iff `event.field` is "values" (i.e. we
     *     are updating a pin value).
     */
    function updateSelectedPin(event) {
      var request;
      if (event.field === "values") {
        // Updating one of the pins' `values`
        request = updatePinValue(
          self.selectedPin,
          event.pinField,
          event.newValue
        );
      } else {
        request = updatePinAttribute(self.selectedPin, event);
      }

      return request
        .then(function (pin) {
          if (pin.is_placed_on_document) {
            placePin(pin);
          }
          return pin;
        })
        .catch(ToastService.showError);
    }

    function setSelectedStop($event, data) {
      self.stop = data.stop;

      if (!self.stop) {
        delete $stateParams.stop;
        delete $stateParams.asset;
        delete $stateParams.room;
        delete $stateParams.level;
        setPrimarySelectedPin({ pin: null }, { fromSelectedStop: true });
        return;
      }

      var pin = getChecklistPin(self.stop);
      var level = self.stop.level;
      let levelId;
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        levelId = level;
        if (!levelId && self.stop.room) levelId = self.stop.room.level;
        if (!levelId && self.stop.asset) levelId = self.stop.asset.level;
      } else {
        levelId = level && level._id;
      }

      let stopParam;
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        const location = self.stop.room || self.stop.asset || self.stop.level;
        var paramKey = location.pinType.protected_type.toLowerCase();
        stopParam = {
          [paramKey]: location._id,
        };
      } else {
        stopParam = {
          stop: self.stop._id,
        };
      }
      // sometimes we can select pins that are on a floor with no floor plan
      // in that case, don't change state
      if (levelId && levelId !== self.floor._id) {
        // We need to get the document to populate
        return initFloor(levelId)
          .then(function () {
            // Update the URL
            return $state.go("planView.building.floor", {
              buildingId: level.building,
              floorId: levelId,
              ...stopParam,
            });
          })
          .then(initRound)
          .then(reapplyChecklistVisibilityToggles);
      }

      Object.assign($stateParams, stopParam);
      if (pin) {
        setPrimarySelectedPin({ pin: pin }, { fromSelectedStop: true });
      }
    }

    /**
     * Handle the results from the room/asset search and show the pins on the floorplan
     * @param {String} $event.action - One of "close", "search".
     * @param {String} $event.queryText - The searched text.
     * @param {String} $event.queryResults - The search results.
     * @see abx-pin-search.onSearchIconClick
     */
    function handleSearchIconClick($event) {
      // Validate the URL length only if this would increase the query string
      // length (i.e. we're modifying the search query params to something
      // non-empty)
      if ($event.action !== "close" && !validateFilterChange()) {
        return $q.reject();
      }

      clearSelectedPin();
      if ($event.action === "close") {
        setShownPins();
        setMode({ mode: MODES.DEFAULT });
        setSearchStateParams(null);
      } else if ($event.action === "search") {
        ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.SEARCH_MULTIPLE);
        setMode({ mode: MODES.SEARCH_RESULTS });
        setSearchStateParams($event.queryText);

        if (!$event.queryResults || !$event.queryResults.length) {
          self.shownPins = [];
        } else {
          // Filter out unplaced pins
          self.shownPins = $event.queryResults.filter(function (pin) {
            return pin.is_placed_on_document;
          });
        }
      }

      return $q.resolve($event.queryResults);
    }

    /**
     * Call setVersionMode with the correct mode when the version is changed in the floor plan component
     *
     * @param {Object} $event event from child component
     * @param {Object} $event.revision the revision that has been selected
     * @param {Boolean} $event.current true if this is the document's current revision, false otherwise
     */
    function handleVersionSelected($event) {
      if ($event.current) {
        setVersionMode(VERSION_MODES.DEFAULT);
      } else {
        setVersionMode(VERSION_MODES.PREVIOUS);
      }
    }

    /** Handle the addition of new models
     *
     * @param {Object}  $event                event object
     * @param {String}  $event.model  model that was chosen
     */
    function onAdd($event) {
      create($event.model);
    }

    /**
     * Set the mode of the plan view app. Is a no-op if that mode is already
     * the active mode (i.e. at the top of the mode stack)
     *
     * @param {Object}  $event
     * @param {MODE}    $event.mode  intended mode; use constant from plan-view module
     */
    function setMode($event) {
      var mode = $event && $event.mode ? $event.mode : null;

      if (mode === getCurrentMode()) {
        return;
      }

      if (getCurrentMode() === MODES.PIN_PLACEMENT) {
        $log.warn(
          "Plan-View-App: Do not push onto modeStack when in pin placement mode, you will regret it"
        );
      }

      switch (mode) {
        case MODES.SEARCH_RESULTS:
          setSearchResultsMode();
          break;
        case MODES.PIN_PLACEMENT:
          setPinPlacementMode();
          break;
        case MODES.PREVIOUS:
          setPreviousMode();
          break;
        case MODES.ASSIGN_QR_CODE:
          setAssociateQrCodeMode();
          break;
        case MODES.DEFAULT:
        default:
          setDefaultMode();
          break;
      }
    }

    /**
     * Get the current mode of the app
     * @return {String} current mode
     */
    function getCurrentMode() {
      return self.modeStack[self.modeStack.length - 1];
    }

    function isPlacing() {
      return self.getCurrentMode() === MODES.PIN_PLACEMENT;
    }

    function isInspection() {
      return self.getCurrentMode() === MODES.INSPECTION;
    }

    /**
     * Update the data we augment round pins with when stops are completed.
     * @param {event} _$event - Unused
     * @param {{from: string, to: string, stop: string}} data
     */
    function handleStopChecklistStatusChange(_$event, data) {
      var stop = data.stop;
      var newStatus = data.to;
      var pinId =
        (stop.asset && stop.asset._id) || (stop.room && stop.room._id);
      if (pinId) {
        self.pins[pinId].checklist_status = newStatus;
      }
    }

    /**
     * Event handler for toggling visibility of pins in inspection
     * mode based on their associated checklist's status.
     * @param { string | string[] } status - The status to toggle visibility for
     *  If multiple statuses are provided, they will each be toggled individually
     * @return { void }
     */
    function handleChecklistStatusVisibilityToggle(status) {
      // handle array case by iteration
      if (Array.isArray(status)) {
        return status.forEach(self.handleChecklistStatusVisibilityToggle);
      }

      // if true, we are currently showing and will be hiding this status'
      // pins
      var hidePins = self.getIsChecklistStatusVisible(status);
      checklistStatusVisibilityMap[status] = !hidePins;
      if (hidePins) {
        // hiding some pins
        self.shownPins = self.shownPins.filter(function (pin) {
          return pin.checklist_status !== status;
        });
      } else {
        // showing previously hidden pins
        var newShownPins = [];
        for (var i = 0; i < self.shownPins.length; i++) {
          newShownPins.push(self.shownPins[i]);
        }
        var pinIds = Object.keys(self.pins);
        for (i = 0; i < pinIds.length; i++) {
          var pin = self.pins[pinIds[i]];
          if (pin.is_placed_on_document && pin.checklist_status === status) {
            newShownPins.push(pin);
          }
        }
        self.shownPins = newShownPins;
      }
    }

    /**
     * This function is only meant to be called when the floor plan changes and the
     * visiblity toggles within the sidebar need to be re-applied because a new floor
     * plan means new pins to show.
     *
     * This function will show all pins that are currently toggled to be shown based
     * on their checklist status and hide all pins that ar currently toggled to be hidden
     * base on their checklist status
     *
     * (IN_PROGRESS, SKIP, PASS, etc...)
     *
     * This requires all pins to already have their .checklist_status prop set
     */
    function reapplyChecklistVisibilityToggles() {
      var newShownPins = [];
      var pinIds = Object.keys(self.pins);

      for (var i = 0; i < pinIds.length; i++) {
        var pin = self.pins[pinIds[i]];

        if (
          checklistStatusVisibilityMap[pin.checklist_status] &&
          pin.is_placed_on_document
        ) {
          newShownPins.push(pin);
        }
      }
      self.shownPins = newShownPins;
    }

    /**
     * Getter for inspection mode. Used to determine if pins with checklists
     * of a specific status are currently visible on the floor plan.
     * @param { string | string[] } status - The status (or statuses)
     *  to check visibility for.
     * @return { boolean } true if the provided status is currently visible
     *  on the floor plan. In the event of more than one status,
     *  returns true if every status is visible.
     */
    function getIsChecklistStatusVisible(status) {
      if (Array.isArray(status)) {
        return status.every(function (s) {
          return getIsChecklistStatusVisible(s);
        });
      }
      return checklistStatusVisibilityMap[status] === true;
    }

    // =================
    // Private Functions
    // =================

    function getChecklistPin(checklist) {
      var pin = checklist.asset || checklist.room;

      // checklists don't require a pin, they can have a floor
      if (!pin) {
        return null;
      }

      var pinId = pin._id ? pin._id : pin;
      return self.pins[pinId];
    }

    /**
     * Get app permissions
     * @return {Object} permissions
     */
    function getPermissions() {
      return {
        canPlaceAssets: _permissions.asset.set_location,
        canPlaceRooms: _permissions.room.set_location,
      };
    }

    function getInitialModeStack() {
      if ($stateParams.round_task && self.organization.show_inspections) {
        return [MODES.INSPECTION];
      }
      return [MODES.DEFAULT];
    }

    /**
     * Create a model
     *
     * @param {String} model    Model name (singular)
     * @param {Object} locals   Create dialog locals
     */
    function create(model, locals) {
      if (!locals) {
        locals = {};
      }
      locals.building = self.building;
      locals.floor = self.floor;
      locals.hideToast = true;
      var options = { locals: locals };
      switch (model) {
        case models.ROOM.MODEL:
          return CreateRoomDialog.show(options).then(function (rooms) {
            var room = rooms[0];
            placeNewPin(room);
          });
        case models.ASSET.MODEL:
          return CreateAssetDialog.show(options).then(function (assets) {
            var asset = assets[0];
            placeNewPin(asset);
          });
        case models.DOCUMENT.MODEL:
          // return UploadDocumentDialog.show(options);
          var url =
            EnvService.getCurrentBaseUrl() +
            "/buildings/" +
            self.building._id +
            "/upload";

          if (EnvService.getEnvName() === EnvService.LOCAL.ENV) {
            url = url.replace(":3007", ":3000");
          }

          return $window.open(url, "_blank");
        case models.FLOOR.MODEL:
          return CreateFloorDialog.show(options).then(function () {
            $scope.$emit(EVENT_FLOOR_ADD);
          });
        case models.SERVICE_REQUEST.MODEL:
          return CreateRequestDialog.show(options);
        case models.MAINTENANCE_SCHEDULE.MODEL:
          return CreateScheduleDialog.show(options);
        case models.WORK_ORDER.MODEL:
          return CreateWorkOrderDialog.show(options);
        case models.INSPECTION_PROGRAM.MODEL:
          return CreateInspectionProgramDialog.show(options);
        default:
          return $q.reject("invalid model");
      }
    }

    /**
     * Set a given pin to be the "search-selected" pin for the application.
     *
     * This means:
     * - Putting the pin's name in the search bar (if not already there)
     * - Setting the pin as the selected pin
     * - Hiding all other pins on the floor plan
     * - Updating the URL to reflect this state
     *
     * @param {Object} $event - $event object
     * @param {Object} $event.pin - the chosen pin
     */
    function setSearchSelectedPin($event) {
      var pin = $event.pin;

      self.pinSearch = {
        multiSearch: null,
        singleSelect: pin,
      };
      setMode({ mode: MODES.SEARCH_RESULTS });

      // unplaced pins will not have a page; should retain current page
      if (pin.page) {
        setPage(pin.page);
      }

      setSearchStateParams(pin);
      selectAndShowOnly(pin);

      function selectAndShowOnly(pin) {
        setPrimarySelectedPin({ pin: pin });

        if (pin.is_placed_on_document) {
          self.shownPins = [pin];
        } else {
          self.shownPins = [];
        }
      }
    }

    /**
     * Duplicate a pin
     *
     * @param {Object} $event   Angular event
     * @param {Object} data     Event data
     * @param {Object} data.pin Pin to duplicate
     */
    function duplicatePin($event, data) {
      var pin = data.pin;
      var model;
      var protectedType = pin.pinType.protected_type;
      if (protectedType === ASSET_PROTECTED_TYPE) {
        model = models.ASSET.SINGULAR;
      } else if (protectedType === ROOM_PROTECTED_TYPE) {
        model = models.ROOM.SINGULAR;
      }
      var duplicate = angular.copy(pin);
      duplicate.name = "Copy of " + pin.name;
      create(model, duplicate).then(function () {
        ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.COPY_PIN);
      });
    }

    /**
     *
     * @param {String} pinType the pin type we're getting updated counts/filters for
     */
    function refreshShownPinsByPinType(pinType) {
      var pinTypeFilter = self.pinFilters.pin_types[pinType._id];
      // Update shown pins for this pinType to keep count in sync with pins
      getPinsByPinTypeFilter(pinType, pinTypeFilter).then(function () {
        // Update pin count for this pinType
        return attachPinTypeCounts([pinType], {
          preserveShownPinsCount: true,
        });
      });
    }

    /**
     * Set the currently viewed page. Trigger the state transition (to update
     * the URL) if the page has changed
     *
     * @param {Number} page - Page to set to
     * @return {Promise<>} - Resolves when any invoked state transition is
     *     completed
     */
    function setPage(page) {
      if (page === self.page) {
        return $q.resolve();
      }

      self.page = page;
      if (page !== 1) {
        $stateParams.page = page;
      } else {
        delete $stateParams.page;
      }
      return $state.go($state.current);
    }

    /**
     * Stores the given search target in stateParams and updates the URL.
     *
     * @param {String|Object} searchTarget - The query or pin searched. Falsy to
     *    clear search state param.
     */
    function setSearchStateParams(searchTarget) {
      if (!searchTarget) {
        delete $stateParams.search;
      } else if (angular.isString(searchTarget)) {
        Utils.setQueryParam("search", JSON.stringify(searchTarget));
      } else {
        Utils.setQueryParam("search", {
          pin: searchTarget._id,
          model: searchTarget.pinType.protected_type,
        });
      }
      return $state.go($state.current);
    }

    /**
     * Sets the version mode of the plan view application.
     * When the version being viewed is not "current," some editing functionality of the app should be disabled
     * @param {VERSION_MODE} mode version mode to transition to
     */
    function setVersionMode(mode) {
      switch (mode) {
        case VERSION_MODES.PREVIOUS:
          self.versionMode = VERSION_MODES.PREVIOUS;
          break;
        default:
          self.versionMode = VERSION_MODES.DEFAULT;
          break;
      }
    }

    /**
     * Set mode to PIN_PLACEMENT
     */
    function setPinPlacementMode() {
      // only allow pin placement mode if user has permission to update the pin
      if (canPlacePin(self.selectedPin.pinType)) {
        self.modeStack.push(MODES.PIN_PLACEMENT);
        // Close the markup tool drawer and deactivate any tools
        $rootScope.$broadcast(MARKUP_TOOL.ACTIONS.CLOSE_MARKUP_DRAWER);
      }
    }

    /**
     * Set mode to SEARCH_RESULTS;
     */
    function setSearchResultsMode() {
      self.modeStack.push(MODES.SEARCH_RESULTS);
    }

    /**
     * Set mode to DEFAULT; show pins that match filters
     */
    function setDefaultMode() {
      // clear out all modes and reset to default mode
      self.modeStack = [MODES.DEFAULT];
    }

    /**
     * Exit a mode and return to the previous mode
     */
    function setPreviousMode() {
      if (self.modeStack.length > 1) {
        self.modeStack.pop();
      } else {
        $log.error(
          "Plan-View-App: Attempted to revert to previous state in modeStack, but none found"
        );
      }
    }

    /**
     * Set mode to ASSIGN_QR_CODE
     */
    function setAssociateQrCodeMode() {
      self.modeStack.push(MODES.ASSIGN_QR_CODE);
    }

    /**
     * Place/show the given pin. If the same pin model (matching IDs) is
     * already shown, replace it with the given pin
     *
     * @param {Object} pin - Pin to place/replace
     */
    function placePin(pin) {
      var index;
      for (var i = 0; i < self.shownPins.length; i++) {
        if (pin._id === self.shownPins[i]._id) {
          index = i;
          break;
        }
      }

      var temp = angular.copy(self.shownPins);
      if (index >= 0) {
        temp.splice(index, 1, pin);
      } else {
        // It's a new pin, so add it to the shownPins list.
        temp.push(pin);
        // Update pinType counts
        var pinType = getPinTypeById(pin.pinType._id);
        attachPinTypeCounts([pinType], {
          preserveShownPinsCount: true,
        }).then(function () {
          if (!angular.isEmpty(pinType.shown_pins_count)) {
            pinType.shown_pins_count++;
          }
        });
      }
      self.shownPins = temp;
    }

    /**
     * Get the icon string value from the PinType within the pin object,
     * which could be only the pin type id or an object, looking for it
     * inside the cached pin types in this component(no new request is made)
     *
     * @param {Object} pin - Pin object
     */
    function getPinIcon(pin) {
      let pinTypeId = pin.pinType;
      if (pinTypeId && angular.isObject(pinTypeId)) {
        pinTypeId = pinTypeId._id;
      }
      const pinType = getPinTypeById(pinTypeId);
      if (pinType) {
        return pinType.icon;
      }
    }

    /**
     * Remove all pin count information from the given pinTypes and reattach
     * the number of assets on this floor and page associated with each of the
     * pinTypes as pinType.pins_count.
     *
     * @param {Object[]} pinTypes - Array of pin type(s) to attach counts to.
     * @param {Object} [options]
     * @param {Boolean} [options.preserveShownPinsCount=false] - True to preserve the
     *    shown_pins_count on the pin types. Otherwise, shown_pins_count will be
     *    deleted off of the pintypes.
     * @return {Promise<Object>} - A promise which resolves with the pinType after
     *    the pins_count has been attached.
     */
    function attachPinTypeCounts(pinTypes, options) {
      var defaultOptions = {
        preserveShownPinsCount: false,
      };
      if (!options) options = {};
      options = angular.extend({}, defaultOptions, options);

      // object mapping pintype id's to shown_pins_count values
      var shownPinsCountHash = {};

      if (options.preserveShownPinsCount) {
        // save off shown_pins_count values so they can be
        // reattached after fetching the counts.
        pinTypes.forEach(function (pinType) {
          shownPinsCountHash[pinType._id] = pinType.shown_pins_count;
        });
      }

      // remove all existing pintype count information.
      deletePinTypeCounts(pinTypes);
      var pinTypeHash = {};
      var params = {
        group_field: "pinType",
        page: self.page,
        documentId: self.floor.document._id,
      };
      var promises = {
        assets: AssetService.getStatsByBuilding(self.building._id, params),
        rooms: RoomService.getStatsByBuilding(self.building._id, params),
      };

      for (var i = 0; i < pinTypes.length; i++) {
        pinTypeHash[pinTypes[i]._id] = pinTypes[i];
      }

      return $q.all(promises).then(function (results) {
        results = [].concat(results.assets, results.rooms);
        var pinCountHash = {};
        for (var i = 0; i < results.length; i++) {
          if (results[i]._id) {
            pinCountHash[results[i]._id] = results[i].result;
          }
        }
        // The stats route omits pinTypes with zero assets/rooms
        // so iterate over our types and assign zeroes where we got no results.
        for (var pinTypeId in pinTypeHash) {
          pinTypeHash[pinTypeId].pins_count = pinCountHash[pinTypeId] || 0;
          if (options.preserveShownPinsCount) {
            pinTypeHash[pinTypeId].shown_pins_count =
              shownPinsCountHash[pinTypeId];
          }
        }
        $scope.$broadcast(EVENT_PLAN_VIEW_PIN_COUNTS_ATTACHED, {
          pinTypes: pinTypes,
        });
        return pinTypes;
      });
    }

    /**
     * Detach all pin type counts from the given pin types.
     * @param {Object[]} pinTypes - The pinTypes to modify, will be modified in-place.
     * @return {Object[]} The modified pinTypes array.
     */
    function deletePinTypeCounts(pinTypes) {
      pinTypes.forEach(function (pinType) {
        delete pinType.pins_count;
        delete pinType.shown_pins_count;
      });
      return pinTypes;
    }

    /**
     * Helper function to determine whether or not we have met our dependencies
     * needed to fetch pins_count values.
     *
     * @returns {Boolean}
     */
    function canFetchPinTypeCounts() {
      return (
        self.building &&
        self.floor &&
        self.floor.document &&
        angular.isNumber(self.page) &&
        isFinite(self.page)
      );
    }

    function canPlacePin(pinType) {
      return pinType.is_room
        ? self.permissions.canPlaceRooms
        : self.permissions.canPlaceAssets;
    }

    /**
     * Clear all state data that has to do with pins and pin types and update
     * the query string for the cleared data.
     */
    function clearPinData() {
      self.pinFilters = {
        pin_types: {},
      };
      self.shownPins = [];
      self.pinTypes = [];
      updateQueryStringFilters();
    }

    /**
     * Clear all shown pins with the given pin type.
     */
    function clearShownPinsByPinType(pinType) {
      self.shownPins = self.shownPins.filter(function (pin) {
        return pin.pinType._id !== pinType._id;
      });
      delete pinType.shown_pins_count;
    }

    /**
     * Given a pin type and a filter, fetch all of the pins for that pin type
     * and filter, and modify the shown pins for this fetch.
     *
     * It is valid for either the pin type or the filter to not exist. If the
     * filter does not exist, all pins for the given pin type will be cleared.
     *
     * @param {Object} [pinType] - Pin type to make pins fetch for
     * @param {Object} [filter] - Filter object to use for specific filtering
     *     on the pin type (i.e. filtering on the pin type's pin fields).
     */
    function getPinsByPinTypeFilter(pinType, filter) {
      if (!pinType || !filter || !isPinTypeValid(pinType._id)) {
        if (pinType) clearShownPinsByPinType(pinType);
        return $q.resolve([]);
      }

      var params = {
        documentId: self.floor.document._id,
        page: self.page,
        pin_type: angular.copy(filter),
      };

      var numberFilter = params.pin_type.number;
      if (numberFilter) {
        params.number = numberFilter;
        delete params.pin_type.number;
      }

      var nameFilter = params.pin_type.name;
      if (nameFilter) {
        params.name = nameFilter;
        delete params.pin_type.name;
      }

      var options = {
        includePinType: true,
      };

      // Here is the fetch method that brings the initial filters.
      var Service = ServiceHelpers.parsePinService(pinType);
      return Service.getAllBFF(
        self.building._id,
        params,
        options,
        self.organization._id
      )
        .then(function (pins) {
          if (pinTypeFilterIsActive(filter, pinType._id)) {
            clearShownPinsByPinType(pinType);
            Array.prototype.push.apply(self.shownPins, pins);
            pinType.shown_pins_count = pins.length;
          }

          return pins;
        })
        .catch(ToastService.showError);
    }

    /**
     * Parse the URL, and initialize any models from it.
     */
    function initUrlModels() {
      var initialization = $q
        .all([initBuilding(), initFloor()])
        .then(function () {
          self.hasInitialized = true;
        });

      // These Async ops are ones that we do NOT need to wait for during initialization.
      initPage();

      switch (self.getCurrentMode()) {
        case MODES.INSPECTION:
          return initialization
            .then(function () {
              return setPinTypes(self.building._id);
            })
            .then(initRound)
            .catch(ToastService.showError)
            .finally(function () {
              // Listen to emitted events
              $scope.$on(
                EVENT_ROUND_TASK_PIN_COLOR_UPDATE,
                function (event, data) {
                  var pin;
                  // Make sur we update the global list of pins as well
                  // These are the pins that are always available, not always shown
                  if (self.pins[data.pinId]) {
                    self.pins[data.pinId].color = data.color;
                    self.pins[data.pinId].inspection_status =
                      data.inspection_status;
                  }

                  for (var i = 0; i < self.shownPins.length; i++) {
                    if (self.shownPins[i]._id === data.pinId) {
                      pin = self.shownPins[i];
                      break;
                    }
                  }

                  if (pin) {
                    pin.color = data.color;
                    pin.inspection_status = data.inspection_status;
                    placePin(pin);
                  }
                }
              );
            });
        case MODES.DEFAULT:
        default:
          initSelectedPin();
          getUnplacedPins();

          // do the initial grab for the decommissioned pins
          getDecommissionedPins();

          return initialization
            .then(function () {
              return setPinTypes(getBuildingId());
            })
            .then(initSearch) // this should take care of itself w/o search
            .then(function (hasStoredSearch) {
              if (!hasStoredSearch && self.floor) {
                if (!self.floor.document) {
                  return $state.go(
                    "planView.building",
                    {
                      buildingId: self.floor.building,
                    },
                    { reload: true }
                  );
                }
                return setShownPins();
              }
            });
      }
    }

    /**
     * Fetch the building specified in the URL.
     */
    function initBuilding() {
      if (self.building) {
        return $q.resolve(self.building);
      } else if (!$stateParams.buildingId) {
        return;
      }

      return BuildingService.getById($stateParams.buildingId)
        .then(function (building) {
          self.building = building;
          BuildingService.setCurrent(building);
          if (!self.organization) {
            return OrganizationService.redirectToBuildingOrg(building);
          }
          return building;
        })
        .catch(ToastService.showError);
    }

    /**
     * Fetch the floor specified in the URL.
     */
    function initFloor(floorId) {
      var buildingId = getBuildingId();
      floorId = floorId || $stateParams.floorId;
      // Don't have a floor in the URL, so don't get one
      if (!buildingId || !floorId) return;

      return FloorService.getById(buildingId, floorId)
        .then(function (floor) {
          self.floor = floor;
          return floor;
        })
        .catch(ToastService.showError);
    }

    function handleRoundTaskChange(task) {
      self.task = task;
    }

    /**
     * Push all round stops into the showPins array to make sure the
     * floor plan displays them and only them
     */
    function initRound() {
      var taskId = $stateParams.round_task;
      var buildingId = getBuildingId();
      var levelId = $stateParams.floorId;
      return WorkOrderService.getById(buildingId, taskId)
        .then(function (task) {
          self.task = task;
          if (FeatureFlagService.isEnabled("jit_checklists")) {
            // Note: the filter by level is done in the front-end, below.
            // The full list is useful to avoid having to re-fetch stop in the round-sidebar component.
            return WorkOrderRoundService.get(
              self.organization._id,
              self.task._id
            );
          } else {
            return ChecklistService.getAll(self.organization._id, {
              task: taskId,
              level: levelId,
            });
          }
        })
        .then(function (_checklists) {
          let checklists = _checklists;
          self.shownPins = [];
          self.pins = {};
          self.floorStops = checklists;
          if (FeatureFlagService.isEnabled("jit_checklists")) {
            self.allStops = checklists;
            self.floorStops = checklists.filter((checklist) => {
              const location =
                checklist.room || checklist.asset || checklist.level;
              return location.level === levelId;
            });
            checklists = self.floorStops;
          }
          if (!checklists.length) {
            return ToastService.showSimple(
              "There are no stops on the selected floor plan"
            );
          }
          var selectedChecklistId = $stateParams.stop;
          const selectedChecklistLocationId =
            $stateParams.asset || $stateParams.room || $stateParams.level;
          // Create map of pin types by ID
          var pinTypeLookup = self.pinTypes.reduce(function (map, pinType) {
            map[pinType._id] = pinType;
            return map;
          }, {});
          for (var i = 0; i < checklists.length; ++i) {
            var checklist = checklists[i];
            // Set selected stop
            if (FeatureFlagService.isEnabled("jit_checklists")) {
              const location =
                checklist.room || checklist.asset || checklist.level;
              if (location._id === selectedChecklistLocationId) {
                self.stop = checklist;
              }
            } else if (checklist._id === selectedChecklistId) {
              self.stop = checklist;
            }
            // Set pin type, color, and add to floor plan if on selected floor
            var pin = checklist.asset || checklist.room;
            if (pin) {
              if (!FeatureFlagService.isEnabled("jit_checklists")) {
                pin.level = checklist.level;
              }
              pin.pinType = pin.pinType._id
                ? pin.pinType
                : pinTypeLookup[pin.pinType];
              // set the color and add the class to set the pass/fail icon
              pin.inspection_status =
                ChecklistService.getStatusClass(checklist);
              pin.checklist_status = checklist.status.toUpperCase();
              // the checklist has the color of the room/asset populated, so we use that since the pins
              // don't normally come with it populated
              pin.color = checklist.asset
                ? checklist.asset.color
                : checklist.room.color;
              if (pin.level && pin.is_placed_on_document) {
                self.shownPins.push(pin);
              }
              self.pins[pin._id] = pin;
            }
          }
          if (self.stop) {
            /**
             * We do not want setSelectedStop() to reload the entire app in this isntace if
             * the selected stop is not on the current floor, but rather on a different floor
             * so we use this option: forceStatChangeOverride
             * It is okay to have stops that don't belong on the floor to be selected
             */
            setSelectedStop(null, {
              stop: self.stop,
            });
          }
        })
        .then(function () {
          if (self.task.inspection.inspection_program) {
            return InspectionProgramService.getById(
              self.organization._id,
              self.task.inspection.inspection_program
            ).then(function (inspectionProgram) {
              self.inspectionProgram = inspectionProgram;
            });
          } else {
            return;
          }
        });
    }

    /**
     * Evaluates the query string for a search to be restored and stores it in
     * self.pinSearch, fetching pin data if necessary.
     *
     * @return {Promise<Boolean>} true iff there was a search to restore to.
     */
    function initSearch() {
      if (!urlHasStoredSearch()) {
        return $q.resolve(false);
      }
      var searchParam = Utils.getParsedQueryParam("search");
      if (urlHasStoredSearchString()) {
        // searchParam is a search query, search it
        self.pinSearch = {
          singleSelect: null,
          multiSearch: searchParam,
        };
        return $q.resolve(true);
      } else if (urlHasStoredSearchPin()) {
        if (searchParam.model.toLowerCase() === "room") {
          _PinService = RoomService;
        } else if (searchParam.model.toLowerCase() === "asset") {
          _PinService = AssetService;
        }
        var options = {
          includePinType: true,
        };
        var params = {};
        let getPinById = _PinService.getAllBFF(
          getBuildingId(),
          params,
          options,
          self.organization._id
        );
        return getPinById.then(function (pin) {
          const pin_ = addPlacement(pin).find((p) => {
            return p._id === searchParam.pin;
          });
          setSearchSelectedPin({ pin: pin_ });
          return true;
        });
      } else {
        return $q.resolve(false);
      }
    }

    /**
     * Determine if the current query params include a valid search.
     */
    function urlHasStoredSearch() {
      return urlHasStoredSearchPin() || urlHasStoredSearchString();
    }
    /**
     * Determine if the current query params include valid search text.
     * @return {boolean}
     */
    function urlHasStoredSearchString() {
      var searchParam = Utils.getParsedQueryParam("search");
      if (!searchParam) {
        return false;
      }
      if (angular.isString(searchParam)) {
        return true;
      }
      return false;
    }

    /**
     * Determine if the current query params include a valid searched pin.
     * @return {boolean}
     */
    function urlHasStoredSearchPin() {
      var searchParam = Utils.getParsedQueryParam("search");
      if (!searchParam) {
        return false;
      }
      if (searchParam.pin && searchParam.model) {
        return true;
      }
      return false;
    }

    /**
     * Evaluates the query string for a specified pin to be selected. The keys
     * in the query string can be any type of pin model (e.g. "room", "asset").
     */
    function initSelectedPin() {
      var pinId = $stateParams.asset || $stateParams.room;

      var isAsset = $stateParams.asset;
      var isRoom = $stateParams.room;

      // Determine which service/endpoint to use
      if (isAsset) _PinService = AssetService;
      else if (isRoom) _PinService = RoomService;
      else return $q.resolve(); // Don't have any pins in the query params

      var params = {
        _id: pinId,
      };
      var options = {
        includePinType: true,
      };

      var buildingId = getBuildingId();
      return _PinService
        .getAllBFF(buildingId, params, options, self.organization._id)
        .then(function (pins) {
          if (pins.length === 1) {
            self.setPrimarySelectedPin({ pin: pins[0] });
          }
        })
        .catch(function (err) {
          setPinQueryParams(null);

          ToastService.showError(err);
        });
    }

    /**
     * Initialize the page of the floor plan to view. Parse it from the query
     * param, or use the default value.
     */
    function initPage() {
      if ($stateParams.page) {
        self.page = parseInt($stateParams.page, 10);
      } else {
        setPageToDefault();
      }
    }

    /**
     * Parse and return the current pin type filters from the url.
     */
    function parsePinTypeFilters() {
      var pinTypeFilters = Utils.getParsedQueryParam("pin_types");
      pinTypeFilters = pinTypeFilters || {};

      // Confirm that some of the pin type filters loaded from the URL query are valid
      var hasValid = false;
      for (var k in pinTypeFilters) {
        if (isPinTypeValid(k)) {
          hasValid = true;
        }
      }

      if (!hasValid) {
        // When no valid filters have loaded from the URL query string, check the
        // session storage service for saved filters for this building
        var buildingId = getBuildingId();
        var storedFilters = SessionService.getPlanViewFilters(buildingId);
        for (k in storedFilters) {
          pinTypeFilters[k] = storedFilters[k];
        }
      }

      for (var pinTypeId in pinTypeFilters) {
        parseDateFilters(pinTypeFilters[pinTypeId]);
      }

      return pinTypeFilters;

      /**
       * Given a pinTypeFilter, convert all of its date type pinFieldFilters to
       * use Date objects instead of the String they come through as from the
       * URL. Modifies the pinTypeFilter in-place.
       * @param {Object} pinTypeFilter - The pinTypeFilter to modify
       */
      function parseDateFilters(pinTypeFilter) {
        var pinTypeId = pinTypeFilter._id;
        if (!pinTypeFilter.pin_fields) {
          // No work to be done on this pinType
          return;
        }
        pinTypeFilter.pin_fields.forEach(function (pinFieldFilter) {
          var pinFieldId = pinFieldFilter._id;
          var pinFieldDataType = getPinFieldDataType(pinTypeId, pinFieldId);
          if (pinFieldDataType === "date") {
            parseDateFilter(pinFieldFilter);
          }
        });
      }

      /**
       * Given a pin field filter, attempt to convert its value(s) to Date
       * objects. If this fails, the key will be deleted. Modifies the given
       * pinFieldFilter in-place.
       * @param {*} pinFieldFilter - The filter to modify
       */
      function parseDateFilter(pinFieldFilter) {
        var FILTER_VALUE_KEYS = ["start", "end", "value"];

        FILTER_VALUE_KEYS.forEach(function (key) {
          if (Object.prototype.hasOwnProperty.call(pinFieldFilter, key)) {
            try {
              pinFieldFilter[key] = new Date(pinFieldFilter[key]);
            } catch (e) {
              delete pinFieldFilter[key];
            }
          }
        });
      }

      /**
       * Get the pinField's data type.
       * @param {String} pinTypeId  - The pinType to which the pinField belongs.
       * @param {String} pinFieldId - The pinField to look up.
       */
      function getPinFieldDataType(pinTypeId, pinFieldId) {
        var pinType = Utils.findModelById(self.pinTypes, pinTypeId);
        var pinField = Utils.findModelById(pinType.fields, pinFieldId);
        return pinField.data_type;
      }
    }

    /**
     * Parse filters that relate to pins from the query string, and fetch
     * and show the pins based on those filters.
     *
     * This function should only be called when `self.page` and `self.floor`
     * have been stabilized.
     */
    function setShownPins() {
      self.shownPins = [];

      var pinTypeFilters = parsePinTypeFilters();

      for (var pinTypeId in pinTypeFilters) {
        var pinTypeFilter = pinTypeFilters[pinTypeId];
        var pinType =
          pinTypeFilter && Utils.findModelById(self.pinTypes, pinTypeId);
        self.setPinTypeFilter({
          filter: pinTypeFilter,
          pinType: pinType,
        });
      }

      if (self.selectedPin) {
        var shouldShowPin = isPinOnCurrentPage(self.selectedPin);
        var pinIsShown = pinTypeFilters[self.selectedPin.pinType._id];
        if (shouldShowPin && !pinIsShown) {
          // Show the selected pin if it won't be shown by the current filters
          self.shownPins.push(self.selectedPin);
        }
      }
    }

    /**
     * See if the given pin is placed on the current page of the current floor
     * plan.
     *
     * This function should only be called when `self.page` and `self.floor`
     * have been stabilized.
     *
     * @param {Object} pin - Pin to evaluate
     * @return {Boolean} - Pin is placed on currently viewed page
     */
    function isPinOnCurrentPage(pin) {
      if (!self.floor) return false;

      return (
        pin.page === self.page && pin.documentId === self.floor.document._id
      );
    }

    /**
     * Check if there is at least one unplaced pin for rooms/assets
     * to quickly determine if unplaced pin component should be shown
     */
    function checkForUnplacedPins() {
      var buildingId = getBuildingId();
      var floorId = getFloorId();

      if (!buildingId || !floorId) {
        return;
      }

      var params = {
        level: floorId,
        percentX: "null",
        percentY: "null",
        page: "null",
        include_values: false,
        count: true,
        limit: 1,
      };

      var options = {
        includePinType: false,
      };

      var roomCountRequest = RoomService.getAll(buildingId, params, options);
      var assetCountRequest = AssetService.getAll(buildingId, params, options);

      $q.all([roomCountRequest, assetCountRequest])
        .then(function (pinCounts) {
          if (pinCounts) {
            if (pinCounts[0].count >= 1 || pinCounts[1].count >= 1) {
              self.hasUnplacedPins = true;
            } else {
              self.hasUnplacedPins = false;
            }
          } else {
            self.hasUnplacedPins = false;
          }
        })
        .catch(ToastService.showError);
    }

    /**
     * Fetch all unplaced pins that are associated with this floor
     */
    function getUnplacedPins() {
      var buildingId = getBuildingId();
      var floorId = getFloorId();

      if (!buildingId || !floorId) {
        return;
      }

      // Quickly check if there are unplaced pins before fetching full list
      checkForUnplacedPins();

      var params = {
        level: floorId,
        percentX: "null",
        percentY: "null",
        page: "null",
      };
      var options = {
        includePinType: true,
      };

      var roomRequest = RoomService.getAllBFF(
        buildingId,
        params,
        options,
        self.organization._id
      );
      var assetRequest = AssetService.getAllBFF(
        buildingId,
        params,
        options,
        self.organization._id
      );

      $q.all([roomRequest, assetRequest])
        .then(function (twoSetsOfPins) {
          self.unplacedPins = twoSetsOfPins[0].concat(twoSetsOfPins[1]);
        })
        .catch(ToastService.showError);
    }

    /**
     * Fetches all decommissioned pins on this floor
     * Populates the self.decommissionedPins prop
     */
    function getDecommissionedPins() {
      var buildingId = getBuildingId();
      var floorId = getFloorId();

      if (!buildingId || !floorId) {
        return;
      }

      var params = {
        level: floorId,
        decommissioned: "true",
      };
      var options = {
        includePinType: true,
      };

      var roomRequest = RoomService.getAllBFF(
        buildingId,
        params,
        options,
        self.organization._id
      );
      var assetRequest = AssetService.getAllBFF(
        buildingId,
        params,
        options,
        self.organization._id
      );

      $q.all([roomRequest, assetRequest])
        .then(function (twoSetsOfPins) {
          self.decommissionedPins = twoSetsOfPins[0].concat(twoSetsOfPins[1]);
        })
        .catch(ToastService.showError);
    }

    /**
     * Checks if a given pin type is valid/available from our currently stored
     * pin types.
     *
     * @param {String} pinTypeId
     * @return {Boolean} - True iff the given pin type is valid
     */
    function isPinTypeValid(pinTypeId) {
      return self.pinTypes.some(function (pinType) {
        return pinType._id === pinTypeId;
      });
    }

    /**
     * Reset the page to its default value.
     */
    function setPageToDefault() {
      self.page = DEFAULT_PAGE;
      delete $stateParams.page;
    }

    /**
     * Fetch the pin types for the given building. Additionally fetch and
     * attach counts to the pin types fetched.
     *
     * @param {String} buildingId - ID of building to fetch pin types for
     */
    function setPinTypes(buildingId) {
      if (!buildingId) {
        self.pinTypes = [];
        return $q.resolve(self.pinTypes);
      }

      return PinTypeService.getAll(buildingId)
        .then(function (pinTypes) {
          self.pinTypes = pinTypes;
          if (canFetchPinTypeCounts()) {
            attachPinTypeCounts(pinTypes);
          }

          return pinTypes;
        })
        .catch(ToastService.showError);
    }

    /**
     * Update pin filters in the query string with our currently active filters.
     */
    function updateQueryStringFilters() {
      if (!Object.keys(self.pinFilters.pin_types).length) {
        delete $stateParams.pin_types;
      } else {
        Utils.setQueryParam("pin_types", self.pinFilters.pin_types);
      }

      $state.go($state.current);
    }

    /**
     * Handles adding a new pin to the shownPins array & switching mode to let the user place it
     *
     * @param {Object} pin - Newly created pin
     */
    function placeNewPin(pin) {
      // If the pin isn't on this floor, display a toast and short circuit.
      if (pin && !pinIsOnFloor(pin, self.floor)) return pinCreatedToast(pin);

      addToUnplacedPins(pin);
      // only allow pin placement mode if we are on the current version of the floor plan
      if (self.versionMode === VERSION_MODES.DEFAULT) {
        setPrimarySelectedPin({ pin: pin });
        self.setMode({ mode: MODES.PIN_PLACEMENT });
      }
    }

    /**
     * Creates and displays a toast for newly created pins. Works for both assets and rooms.
     *
     * @param {Object} pin
     */
    function pinCreatedToast(pin) {
      var type = pin.pinType.protected_type.toLowerCase();

      return ToastService.complex({
        link: {
          url: pin.uri,
          target: "_blank",
        },
      })
        .text("Successfully created " + type)
        .action("View")
        .show();
    }

    /**
     * Puts the ID of the given selected pin in the query string
     *
     * @param {Object} selectedPin - Currently selected pin
     */
    function setPinQueryParams(selectedPin) {
      // Clear existing params for pins
      delete $stateParams.asset;
      delete $stateParams.room;

      if (selectedPin) {
        // Have to differentiate between pin models so we can fetch the pin from
        // the correct endpoint when a page is first loaded
        var paramKey = selectedPin.pinType.protected_type.toLowerCase();

        $stateParams[paramKey] = selectedPin._id;
      }

      $state.go($state.current);
    }

    /**
     * Evaluates and returns if the given pin is on the given floor.
     */
    function pinIsOnFloor(pin, floor) {
      if (!pin || !floor) return false;

      var pinFloorId = PinValueService.getFloorId(pin.values);

      return pinFloorId === floor._id;
    }

    /**
     * For a pin, saves the given fields/attributes with the given new values. Expects _PinService to have been set.
     *
     * @param {Object} pin - Pin to update
     * @param {Object|Array} event - Single object or Array of objects containing the field/value to update
     * @param {String} event.field - Key of field being updated
     * @param {*} event.newValue - New value for the field
     */
    function updatePinAttribute(pin, event) {
      var data = {};
      var isSetLocation = false;

      if (angular.isArray(event)) {
        for (var i = 0; i < event.length; i++) {
          data[event[i].field] = event[i].newValue;

          if (event[i].field === "percentX" || event[i].field === "percentY") {
            isSetLocation = true;
          }
        }
      } else {
        data[event.field] = event.newValue;
      }

      var params = {};

      var buildingId = Object.prototype.hasOwnProperty.call(pin.building, "_id")
        ? pin.building._id
        : pin.building;
      if (isSetLocation) {
        // Handle the mode/unplaced management prior to the request so it doesn't hinder rapid pin placement
        // remove it from unplacedPins if it was in that array
        var wasRemoved = removeFromUnplacedPins(pin);
        setPreviousMode();

        return _PinService
          .setLocation(buildingId, pin._id, data, params, pin)
          .then(function (updatedPin) {
            // We can assume that this is a move action, rather than initial placement,
            // if the pin was NOT removed from the unplaced pins list.
            // (I.E. not the first time it was placed)
            if (!wasRemoved) {
              ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.MOVE_PIN);
            }
            // Make sure children are informed of the selectedPin's changes
            return (self.selectedPin = updatedPin);
          })
          .catch(function (err) {
            if (wasRemoved) {
              // oops, better put it back
              addToUnplacedPins(pin);
            }
            return $q.reject(err);
          });
      }

      return _PinService
        .updateById(buildingId, pin._id, data, params, pin)
        .then(function (updatedPin) {
          var pinIsStillSelected = Utils.isSameModel(
            self.selectedPin,
            updatedPin
          );
          if (!pinIsStillSelected) {
            return updatedPin;
          }
          // Make sure children are informed of the selectedPin's changes
          self.setPrimarySelectedPin({ pin: updatedPin });
          return updatedPin;
        });
    }

    /**
     * Remove a given pin from the unplacedPins array
     * @param {Object}  pin
     * @return {boolean} if the pin was found and removed from unplacedPins
     */
    function removeFromUnplacedPins(pin) {
      var wasRemoved = false;
      for (var i = 0; i < self.unplacedPins.length; i++) {
        if (Utils.isSameModel(pin, self.unplacedPins[i])) {
          self.unplacedPins.splice(i, 1);
          wasRemoved = true;
          break;
        }
      }
      if (!self.unplacedPins || !self.unplacedPins.length) {
        self.hasUnplacedPins = false;
      }
      return wasRemoved;
    }

    /**
     * Add a given pin to the unplacedPins array
     * @param {Object}  pin
     */
    function addToUnplacedPins(pin) {
      self.unplacedPins.push(pin);
      self.hasUnplacedPins = true;
    }

    /**
     * For a pin, saves the pin value associated with the given pin field with
     * the given new value. Expects _PinService to have been set.
     *
     * @param {Object} pin - Pin to update
     * @param {Object} pinField - Pin field of pin value to update
     * @param {*} newValue - New value for the pin value. This should be the
     *     `value` field on a pin value model, _not_ the pin value model/object
     *     itself.
     * @returns {Promise<Object>} A promise which resolves to the modified pin.
     */
    function updatePinValue(pin, pinField, newValue) {
      var pinValue = pin.values[pinField._id];
      var pinType = getPinTypeById(pin.pinType._id);
      var buildingId = Object.prototype.hasOwnProperty.call(pin.building, "_id")
        ? pin.building._id
        : pin.building;
      return _PinService
        .updateValueByDataType(
          buildingId,
          pinField.data_type,
          pin._id,
          pinValue._id,
          newValue,
          { pin: pin }
        )
        .then(function (updatedPinValue) {
          var resultPin;
          if (pinField.can_change_pin_color) {
            return handlePinColorUpdate(
              buildingId,
              pin._id,
              pinField._id,
              updatedPinValue
            );
          }
          var pinIsStillSelected = Utils.isSameModel(self.selectedPin, pin);
          if (!pinIsStillSelected) {
            return pin;
          }
          if (pinField.data_type === "room" || pinField.data_type === "level") {
            // Room and Level changes return an entire pin.
            resultPin = updatedPinValue;
            self.selectedPin.values[pinField._id] =
              resultPin.values[pinField._id];

            if (pinField.data_type === "level") {
              const floorValue = newValue._id || newValue;

              // Prompts floor change dialog if has value
              if (floorValue) {
                PinFieldFloorConfirmationDialog.show({
                  onConfirmStay: () => {
                    // Refresh shown pins for this pinType
                    refreshShownPinsByPinType(pinType);

                    if (!resultPin.is_placed_on_document) {
                      clearSelectedPin();
                      return resultPin;
                    }
                  },
                  onConfirmLeave: () => {
                    // Clear query params in order to avoid referencing old data
                    $location.search("room", null);
                    $location.search("asset", null);
                    $location.path(
                      `/plan/buildings/${self.building._id}/levels/${floorValue}`
                    );
                    $timeout(() => $window.location.reload());
                  },
                });
              } else {
                // Refresh shown pins for this pinType
                refreshShownPinsByPinType(pinType);

                if (!resultPin.is_placed_on_document) {
                  clearSelectedPin();
                  return resultPin;
                }
              }
            }
          } else {
            // We received just a value back, so apply the new value.
            self.selectedPin.values[pinField._id] = updatedPinValue;
          }
          return self.selectedPin;
        });
    }

    /**
     * Re-fetch the pin and update selectedPin with the new color values
     *
     * @param {String} buildingId - building ID
     * @param {String} pinId - pin ID whose value is changing
     * @param {Object} pinFieldId - ID of the pinField that is being updated
     * @param {Object} updatedPinValue - pin value model object
     * @returns {Promise<Object>} selected pin (has values) or updated pin (same but without values)
     */
    function handlePinColorUpdate(
      buildingId,
      pinId,
      pinFieldId,
      updatedPinValue
    ) {
      // fetch the whole new pin because color is on the pin
      return _PinService.getById(buildingId, pinId).then(function (updatedPin) {
        // make sure the selectedPin hasn't changed before we update it's properties
        if (Utils.isSameModel(self.selectedPin, updatedPin)) {
          self.selectedPin.color = updatedPin.color;
          self.selectedPin.values[pinFieldId] = updatedPinValue;
          // Make a copy of the object, so child components will be notified
          // of this change
          self.selectedPin = angular.copy(self.selectedPin);
        }
        return self.selectedPin;
      });
    }

    /**
     * Helper function to get the building id from self or stateParams
     * @return {String} - the ID of the current building
     */
    function getBuildingId() {
      return self.building ? self.building._id : $stateParams.buildingId;
    }

    /**
     * Helper function to get the floor id from self or stateParams
     * @return {String} - the ID of the current floor
     */
    function getFloorId() {
      return self.floor ? self.floor._id : $stateParams.floorId;
    }

    /**
     * Check to see if the given filter for the pin type is the active one
     *
     * @param {Object} filter - Filter to check
     * @param {String} pinTypeId - ID of pin type the filter is for
     */
    function pinTypeFilterIsActive(filter, pinTypeId) {
      var activeFilter = self.pinFilters.pin_types[pinTypeId];
      return filter === activeFilter;
    }

    /**
     * Handle when a valid QR code was scanned from the app header.
     *
     * @param {Object} $event - AngularJS event object
     * @param {Object} data - Custom event data
     * @param {Object} data.qrCode - QR code model associated with the one
     *     scanned
     */
    function handleQrCodeScan($event, data) {
      var qrCode = data.qrCode;
      self.qrCode = qrCode; // has to be exposed so plan view content can display QR code number in banner
      var asset = qrCode.asset;
      var room = qrCode.room;
      var pin = asset || room;
      var workOrder = qrCode.task;

      if (!qrCode.is_associated) {
        var locals = {
          qrCode: qrCode,
          building: self.building,
          floor: self.floor,
          showPlanViewActions: true,
          hidePinCreateToast: true,
        };
        return AssociateQrCodeDialog.show({ locals: locals }).then(
          handleAssociationEvent
        );
      }

      if (pin) {
        var pinModel = asset ? "asset" : "room";
        if (pin.is_placed_on_document) {
          goToPlacedPin(pin, pinModel, qrCode.redirect_url);
        } else {
          goToListViewDetail(pin, pinModel);
        }
      } else if (workOrder) {
        goToListViewDetail(workOrder, "task");
      }

      function handleAssociationEvent($event) {
        switch ($event.actionTaken) {
          case "create":
            var createdPin = $event.room || $event.asset;
            placeNewPin(createdPin);
            break;
          case "search":
            var pin = $event.asset || $event.room;
            var pinModel = $event.asset ? "asset" : "room";
            if (pin.is_placed_on_document) {
              goToPlacedPin(pin, pinModel, pin.uri);
            } else {
              ToastService.complex()
                .text("Successfully associated")
                .action("Go to " + pinModel, function () {
                  goToListViewDetail(pin, pinModel, true);
                })
                .show();
            }
            break;
          case "assign":
            assignQRCodeToPin();
            break;

          default:
            break;
        }
      }

      function assignQRCodeToPin() {
        if (self.selectedPin) {
          // ensure no pin is selected so the user can select fresh pin
          clearSelectedPin();
        }
        setMode({ mode: MODES.ASSIGN_QR_CODE });
      }
    }

    /**
     * Go to a pin's floor plan, and set it as the search-selected pin
     */
    function goToPlacedPin(pin, pinModel, url) {
      var Service = pinModel === "asset" ? AssetService : RoomService;
      var params = { include_values: true };
      var options = { includePinType: true };

      /**
       * Create a [deffered object](https://docs.angularjs.org/api/ng/service/$q#the-deferred-api)
       * that will be resolved with either:
       *  a) The current organization if `pin` is in the same building or if `pin.building` is in the same org.
       *  b) A different organization if `pin.building` is in a different org.
       */
      var deferred = $q.defer();

      // pin.building may be different,
      // e.g. for a pin scanned from a QR code that's in a different building
      if (pin.building !== self.building._id) {
        // Get the full building to check if it's in the same org
        BuildingService.getById(pin.building).then(function (building) {
          // If it's in the same org, it's ok to move on
          if (self.organization._id === building.organization) {
            deferred.resolve(self.organization);
          } else {
            // If it's in a different org, get the new org and resolve
            OrganizationService.getById(building.organization).then(
              deferred.resolve
            );
          }
        });
      } else {
        // Pin is in the same building; continue with current org
        deferred.resolve(self.organization);
      }

      deferred.promise.then(function (organization) {
        Service.getById(pin.building, pin._id, params, options).then(function (
          _populatedPin
        ) {
          var populatedPin = _populatedPin;
          var floorId = PinValueService.getFloorId(populatedPin.values);

          if (organization._id === self.organization._id) {
            // If same org, set the floor and then the search selected pin
            return self
              .setFloor({
                floorId: floorId,
                buildingId: populatedPin.building,
                ignoreShownPins: true,
                orgRedirect: url,
                organization: organization,
              })
              .then(function () {
                setSearchSelectedPin({ pin: populatedPin });
              });
          } else {
            // Pin is in different org:
            // Set search params and then floor (which triggers redirect to new org subdomain).
            if (populatedPin.page) {
              setPage(populatedPin.page);
            }
            setSearchStateParams(populatedPin).then(function () {
              return self.setFloor({
                floorId: floorId,
                buildingId: populatedPin.building,
                ignoreShownPins: true,
                orgRedirect: url,
                organization: organization,
              });
            });
          }
        });
      });
    }

    /**
     * Go to the a model's detail in the ListView
     */
    function goToListViewDetail(model, modelType, newTab) {
      var destinationState;
      var params = {};

      switch (modelType) {
        case "asset":
          destinationState = "app.asset";
          params.assetId = model._id;
          break;
        case "room":
          destinationState = "app.room";
          params.roomId = model._id;
          break;
        case "task":
          destinationState = "app.task";
          params.taskId = model._id;
          break;
      }

      if (destinationState) {
        var href = $state.href(destinationState, params);
        if (newTab) {
          $window.open(href, "_blank");
        } else {
          $window.open(href, "_self");
        }
      }
    }

    /**
     * Naively infer if another added filter would cause the URL to be too long.
     * If so, alert the user that they can't add any more filters, and return
     * `false` so that the caller can bail the actual filter add operation.
     *
     * The primary use case this is for IE users. Without it, IE will break the
     * app if the URL gets to something longer than we can handle. This
     * implementation is a little more graceful for the user.
     *
     * @return {boolean} - True iff adding a filter was determined to be safe
     *     for the browser.
     */
    function validateFilterChange() {
      if (Utils.validateUrlLength()) {
        return true;
      } else {
        AlertDialog.show({
          locals: {
            title: "Oops!",
            textContent: IGNORE_FILTER_MSG,
          },
        });
        return false;
      }
    }

    function handleAttachmentUploadStart() {
      self.isUploadingAttachments = true;
    }

    function handleAttachmentUploadsFinished() {
      self.isUploadingAttachments = false;
    }

    /**
     * Recreates the "values" property for PIN to be detail
     */
    function parseValueProperty(pin) {
      const { values, building } = pin;
      const newValuesObj = {};

      for (const keyValue in values) {
        if (Object.prototype.hasOwnProperty.call(values, keyValue)) {
          const isString = (str) =>
            typeof str === "string" || str instanceof String;
          const valueProp = values[keyValue];
          const is_null_or_undefined =
            valueProp.value === null || valueProp.value === undefined;
          const is_empty =
            is_null_or_undefined ||
            ((Array.isArray(valueProp.value) || isString(valueProp.value)) &&
              valueProp.value.length);

          newValuesObj[valueProp.pinFieldId] = {
            building,
            is_empty,
            is_level_pin_value: valueProp.pinFieldDataType === "level",
            is_null_or_undefined,
            is_room_pin_value: valueProp.pinFieldDataType === "room",
            pinField: valueProp.pinFieldId,
            value: valueProp.value,
            _id: valueProp._id,
          };
        }
      }

      return newValuesObj;
    }
  }
})();
