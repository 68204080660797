(function () {
  angular
    .module("akitabox.ui.dialogs.updateIdentityNames")
    .controller(
      "UpdateIdentityNamesDialogController",
      UpdateIdentityNamesDialogController
    );

  /* @ngInject */
  function UpdateIdentityNamesDialogController(
    // Material
    $mdDialog,
    // Services
    ToastService,
    IdentityService
  ) {
    var self = this;

    // Attributes
    self.loading = false;
    self.saving = false;
    self.changesMade = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.handleChange = handleChange;
    self.updateIdentity = updateIdentity;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handles change events for all the inputs
     *
     * @param {*} $event
     * @param {String} field - What input is sending the event
     */
    function handleChange($event, field) {
      self[field] = $event.value;
    }

    /**
     * Sends the collection information through identity service
     * to update
     */
    function updateIdentity() {
      if (self.saving || !self.first_name || !self.last_name) return;
      self.saving = true;

      var data = {};
      if (self.first_name) data.first_name = self.first_name;
      if (self.last_name) data.last_name = self.last_name;
      if (self.title) data.title = self.title;
      if (self.company_name) data.company_name = self.company_name;

      IdentityService.update(data)
        .then(function () {
          $mdDialog.hide();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
