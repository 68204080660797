(function () {
  angular
    .module("akitabox.ui.dialogs.schedule.create")
    .factory("CreateScheduleDialog", CreateScheduleDialog);

  /* @ngInject */
  function CreateScheduleDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/schedule/create/create-schedule.dialog.html",
      controller: "CreateScheduleDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * It is unnecessary to pass in a location AND its parent location. For example, if you are passing in an asset for
     * the maintenance schedule to be associated with, you don't need to provide the room or the floor, as those will
     * be parsed intelligently by the dialog. If multiple locations are provided, but they do not agree with each other
     * (e.g. the provided asset is not part of the provided room), the more specific location will take precedence.
     *
     * @param {Object}     options                 Dialog options
     * @param {Object}     options.locals          Dialog local variables
     * @param {Object[]}   options.locals.assets   List of asset objects
     * @param {Object[]}   options.locals.rooms    List of rooms objects
     * @param {Object[]}   options.locals.floors   List of floor objects
     *
     * @return {Promise<Object|Error}   Promise that resolves with the newly created maintenance schedule
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
