(function () {
  angular
    .module("akitabox.ui.dialogs.message.create")
    .factory("CreateMessageDialog", CreateMessageDialog);

  /* @ngInject */
  function CreateMessageDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/message/create/create-message.dialog.html",
      controller: "CreateMessageDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * CreateMessageDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.itemId`, `options.locals.type`, and `options.locals.building` are required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error}               Promise that resolves with the denied requests
     */
    function show(options) {
      // Verify we have an item ID and building locals
      if (
        !options ||
        !options.locals ||
        !options.locals.itemId ||
        !options.locals.building ||
        !options.locals.type
      ) {
        $log.error(
          "CreateMessageDialog: you must pass in itemId and building via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
