(function () {
  angular.module("akitabox.desktop.inspectionProgram", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.checklistTemplate",
    "akitabox.core.services.inspectionProgram",
    "akitabox.core.services.trade",
    "akitabox.core.services.maintenanceType",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.inspectionProgram",
    "akitabox.desktop.components.filterBarManager",
    "akitabox.desktop.inspectionProgram.detail",
  ]);
})();
