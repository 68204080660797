import { Filter } from "@sisense/sdk-data";
import { SisenseContextProvider, WidgetById } from "@sisense/sdk-ui";
import { FunctionComponent, useEffect, useState } from "react";

export interface SisenseWidgetProps {
  sisenseEnv: string;
  onAfterRender: (divNode: HTMLDivElement) => void;
  widgetOid: string;
  dashboardOid: string;
  filters: Filter[];
  height: number;
  width: number;
}

export const SisenseWidget: FunctionComponent<SisenseWidgetProps> = ({
  sisenseEnv,
  onAfterRender,
  widgetOid,
  dashboardOid,
  filters,
  height,
  width,
}) => {
  const [ref, setRef] = useState<HTMLDivElement>();

  useEffect(() => {
    let onAfterRenderCalled = false;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" && ref) {
          const noResultsDiv = Array.from(
            ref.querySelectorAll("div") || []
          ).find((div) => div.textContent?.includes("No Results"));
          if (noResultsDiv) {
            if (!onAfterRenderCalled) {
              onAfterRender(ref);
              onAfterRenderCalled = true;
            }
            observer.disconnect();
            return;
          }

          if (ref.querySelector(".highcharts-pane-group")) {
            // Give the chart some extra time to fully render
            setTimeout(() => {
              if (!onAfterRenderCalled) {
                onAfterRender(ref);
                onAfterRenderCalled = true;
              }
            }, 750);
            observer.disconnect();
          }
        }
      });
    });

    if (ref) {
      observer.observe(ref, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref]);
  return (
    <>
      <SisenseContextProvider url={sisenseEnv} ssoEnabled={true}>
        <div
          ref={(a) => {
            if (a) setRef(a);
          }}
        >
          <WidgetById
            dashboardOid={dashboardOid}
            widgetOid={widgetOid}
            filters={filters}
            styleOptions={{
              height: height,
              width: width,
            }}
          ></WidgetById>
        </div>
      </SisenseContextProvider>
    </>
  );
};
