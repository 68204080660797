(function () {
  angular
    .module("akitabox.core.services.associatedText", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("AssociatedTextService", AssociatedTextService);

  /* @ngInject */
  function AssociatedTextService(
    // Constants
    models,
    // Services
    HttpService
  ) {
    var service = {
      // Routes
      buildDetailRoute: buildDetailRoute,
      // Create
      create: create,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ******************
     */

    function buildDetailRoute() {
      return "/" + models.ASSOCIATED_TEXT.ROUTE_PLURAL;
    }

    /******************* *
     *   Create
     * ****************** */

    /**
     * Create a text association
     *
     * @param  {data}                 data        {organization, asset, recognized_text, label}
     * @param  {Object}               [params]     Query params to include values
     * @return {Promise<Object|Error>}            Promise that resolves with created associated text
     */
    function create(data, params) {
      var route = buildDetailRoute();
      return HttpService.create(route, data, params);
    }
  }
})();
