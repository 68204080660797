(function () {
  angular
    .module("akitabox.ui.dialogs.asset.create")
    .service("CreateAssetDialog", CreateAssetDialog);

  /* @ngInject */
  function CreateAssetDialog($q, $mdDialog, $mdMedia, RoomService) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/asset/create/create-asset.dialog.html",
      controller: "CreateAssetDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
      multiple: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * CreateAssetDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}    options                       Dialog options
     * @param {Object}    [options.locals.building]     Building to create asset under
     * @param {Object}    [options.locals.floor]        A single floor object (not required if passing in room)
     * @param {Object|string} [options.locals.room]     A single room object or its id
     * @param {Object}    [options.locals.hideToast]    Option to hide the toast that displays when the asset is created
     * @return {Promise<Object[]|Error>}                Promise that resolves with the newly created assets.
     *                                                  Their pin types will come as populated
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      var room = dialogOptions.locals.room;
      var buildingId = dialogOptions.locals.building
        ? dialogOptions.locals.building._id || dialogOptions.locals.building
        : null;
      var promise;
      if (buildingId && room && typeof room === "string") {
        promise = RoomService.getById(buildingId, room).then(function (room) {
          dialogOptions.locals.room = room;
        });
      } else {
        promise = $q.resolve();
      }
      return promise.then(function () {
        return $mdDialog.show(dialogOptions);
      });
    }
  }
})();
