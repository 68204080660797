/**
 * Returns a specific property if it exists
 * @param obj Any object
 * @param path Multi-level property
 * @returns The last level property indicated on Path parameter
 * @example
 * const myObject: unknown = {
 *   foo: {
 *     bar: {
 *      baz: 42,
 *    },
 *  },
 *  };
 * const property1 = getMultiLevelPropertyValue(myObject, "foo.bar.baz"); //24
 * const property2 = getMultiLevelPropertyValue(myObject, "foo.bar.bar"); //undefined
 */
export function getMultiLevelPropertyValue(obj: unknown, path: string): any {
  if (typeof obj === "object" && obj !== null) {
    const properties = path.split(".");
    let currentObj: any = obj;

    for (const prop of properties) {
      if (
        typeof currentObj !== "object" ||
        currentObj === null ||
        !(prop in currentObj)
      ) {
        return undefined;
      }
      currentObj = currentObj[prop];
    }

    return currentObj;
  }

  return undefined;
}
