(function () {
  angular
    .module("akitabox.ui.dialogs.document.thumbnailFit")
    .controller("ThumbnailFitDialogController", ThumbnailFitDialogController);

  /* @ngInject */
  function ThumbnailFitDialogController(
    // Libraries
    $mdDialog,
    // Constants
    THUMBNAIL_FIT_OPTIONS,
    // Services
    BuildingService
  ) {
    var self = this;

    // Attributes
    self.fit = self.building.thumbnail_fit;
    self.fitOptions = THUMBNAIL_FIT_OPTIONS;
    self.data = angular.extend({}, self.building, {
      thumbnail: self.document._id,
    });
    self.saving = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.onFitChange = onFitChange;
    self.save = save;

    // =================
    // Public Functions
    // =================

    function onFitChange($event) {
      self.fit = $event.value;
    }

    function save() {
      self.saving = true;
      return saveThumbnail(self.fit).finally(function () {
        self.saving = false;
      });
    }

    // =================
    // Private Functions
    // =================

    function saveThumbnail(fit) {
      var data = {
        thumbnail: self.document._id,
      };
      if (fit) {
        data.thumbnail_fit = fit;
      }
      return BuildingService.update(self.building._id, data).then(
        $mdDialog.hide
      );
    }
  }
})();
