import type { constants } from "./constants";
import { getDomain } from "./getDomain";

export type DesktopEnv = typeof constants.APPS.DESKTOP.ENV;
export type ENV = keyof DesktopEnv;

/**
 * Returns current environment, based on url domain
 * @returns system environment
 */
export function getENV(): ENV {
  const domain = getDomain();

  switch (domain) {
    case "akitabox.com":
      return "PRODUCTION";

    case "beta-abx.com":
      return "BETA";

    case "test-abx.com":
      return "TEMP";

    default:
      return "LOCAL";
  }
}
