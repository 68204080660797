(function () {
  angular.module("akitabox.core.auth.login", [
    "ngCookies",
    "ui.router",
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.account",
    "akitabox.core.services.auth",
    "akitabox.core.services.env",
    "akitabox.core.services.organization",
    "akitabox.core.services.shadow",
    "akitabox.core.services.user",
  ]);
})();
