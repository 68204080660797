(function () {
  angular
    .module("akitabox.core.services.tag", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("TagService", TagService);

  /** @ngInject */
  function TagService(models, HttpService) {
    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute(buildingId, documentId) {
      var buildingUri = "/" + models.BUILDING.ROUTE_PLURAL + "/" + buildingId;
      return (
        buildingUri +
        "/" +
        models.DOCUMENT.ROUTE_PLURAL +
        "/" +
        documentId +
        "/" +
        models.TAG.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, documentId, tagId) {
      var base = buildBaseRoute(buildingId, documentId);
      return base + "/" + tagId;
    }

    function buildListRoute(buildingId, documentId) {
      return buildBaseRoute(buildingId, documentId);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, documentId, data) {
      var route = buildListRoute(buildingId, documentId);
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, documentId, params) {
      var route = buildListRoute(buildingId, documentId);
      return HttpService.get(route, params);
    }

    function getAll(buildingId, documentId, params) {
      var route = buildListRoute(buildingId, documentId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, documentId, tagId, params) {
      var route = buildDetailRoute(buildingId, documentId, tagId);
      return HttpService.getById(route, tagId, params);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, documentId, tagId) {
      var route = buildDetailRoute(buildingId, documentId, tagId);
      return HttpService.remove(route);
    }
  }
})();
