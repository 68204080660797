(function () {
  angular
    .module(
      "akitabox.core.ui.dialogs.inspectionProgram.create.checklistTemplateOverrideSection.list"
    )
    .component("abxChecklistTemplateOverrideList", {
      bindings: {
        pinTypes: "<abxPinTypes",
        stops: "<abxStops",
        checklistTemplate: "<abxChecklistTemplate",
        onChecklistTemplateChange: "&?abxOnChecklistTemplateChange",
        showChecklistTemplateSelection: "<?abxShowChecklistTemplateSelection",
        selectedStops: "<abxSelectedStops",
        onSelectedStopsChange: "&?abxOnSelectedStopsChange",
        title: "@abxTitle",
        showSearch: "<abxShowSearch",
        onAddClick: "&?abxOnAddClick",
        enableAddButton: "<?abxEnableAddButton",
        onRemoveStopClick: "&?abxOnRemoveStopClick",
        checklistTemplates: "<abxChecklistTemplates",
        showRemoveButton: "<?abxShowRemoveButton",
        onChecklistTemplateRemove: "&?abxOnChecklistTemplateRemove",
        groupByPinType: "<?abxGroupByPinType",
        loading: "<abxLoading",
      },
      controller: AbxChecklistTemplateOverrideListController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/dialogs/inspection-program/create/checklist-template-override-section/checklist-template-override-list/checklist-template-override-list.template.html",
    });

  /** @ngInject */
  function AbxChecklistTemplateOverrideListController() {
    var self = this;

    self.collapsedPinTypes = {};
    self.searchText = "";

    self.stopRows = [];
    self.visibleStopRows = [];

    self.selectedPinTypes = {};

    self.getStopPinId = getStopPinId;
    self.requireChecklistTemplate = false;
    self.requireChecklistTemplateMessage = undefined;

    self.$onChanges = function $onChanges(changes) {
      if (changes.stops || changes.pinTypes) {
        if (self.groupByPinType) {
          self.stopRows = [];
          var pinTypeIds = Object.keys(self.pinTypes);
          pinTypeIds.push("level");

          var pinTypeLists = {};
          pinTypeIds.forEach(function (pinTypeId) {
            pinTypeLists[pinTypeId] = [];
          });
          self.stops.forEach(function (stop) {
            if (stop.level) {
              pinTypeLists.level.push(stop);
            } else {
              var pin = getStopPin(stop);
              if (pinTypeLists[pin.pinType]) {
                pinTypeLists[pin.pinType].push(stop);
              }
            }
          });

          pinTypeIds.forEach(function (pinTypeId) {
            var stops = pinTypeLists[pinTypeId];
            if (!stops.length) {
              delete self.selectedPinTypes[pinTypeId];
              return;
            }
            var text =
              pinTypeId === "level" ? "Floor" : self.pinTypes[pinTypeId].name;
            self.stopRows.push({
              pinType: pinTypeId,
              text: text,
            });
            stops.forEach(function (stop) {
              self.stopRows.push(stop);
            });
          });
        } else {
          self.stopRows = self.stops;
        }
        self.visibleStopRows = self.stopRows;

        if (
          !self.checklistTemplate ||
          (self.visibleStopRows.length && !self.checklistTemplate)
        ) {
          self.requireChecklistTemplate = true;
          self.requireChecklistTemplateMessage =
            "A Checklist Template is required"; // it wont actually show...because there'sno room for an error message but we get the red outline at least
        } else if (self.visibleStopRows.length) {
          self.requireChecklistTemplate = true;
        } else {
          self.requireChecklistTemplate = false;
          self.requireChecklistTemplateMessage = undefined;
        }

        if (self.loading) {
          self.showLoadingIndicator = true;
        } else {
          self.showLoadingIndicator = false;
        }

        self.selectedPinTypes = {};
        self.handleSearchTextChange({ model: self.searchText });
      }

      if (changes.checklistTemplate && changes.checklistTemplate.currentValue) {
        self.requireChecklistTemplateMessage = undefined;
      }
    };

    self.handleSearchTextChange = function handleSearchTextChange($event) {
      var searchText = $event.model;
      self.searchText = searchText;
      self.visibleStopRows = [];

      // Anytime the search text changes we wanna reset the collapsed rows
      self.collapsedPinTypes = {};

      if (!searchText) {
        self.visibleStopRows = self.stopRows;
        return;
      }

      // We want to track the parent pin type so that we can add the pin type to the list
      // of visible stops if it has a child that's matched by the search text OR if the
      // pin type itself is matched by the search text, then we want to add its children
      var currentParentPinType = null;
      var haveAddedParentPinTypeBecauseOfChild = false;
      var haveAddedParentPinTypeBecauseOfSearch = false;
      for (var i = 0; i < self.stopRows.length; i++) {
        var stopRowItem = self.stopRows[i];
        var isPinType = !!stopRowItem.pinType;

        // If a pin type is the current stopRowItem, that means we have a new pin type to track
        if (isPinType) {
          haveAddedParentPinTypeBecauseOfChild = false;
          haveAddedParentPinTypeBecauseOfSearch = false;
          currentParentPinType = stopRowItem;
        } else if (haveAddedParentPinTypeBecauseOfSearch) {
          // If we have a new stop to search on and we've already added the parent pin type because
          // of search, then we want to add its child
          self.visibleStopRows.push(stopRowItem);
          continue;
        }

        var stop = getStopPin(stopRowItem);

        var textToSearchAgainst = stopRowItem.text || stop.name;
        var regex = new RegExp(searchText, "i");
        if (textToSearchAgainst.match(regex)) {
          // If we have search matched a pin type, add it and then mark it so we can add its children
          if (isPinType) {
            self.visibleStopRows.push(stopRowItem);
            haveAddedParentPinTypeBecauseOfSearch = true;
          } else {
            // Otherwise, add the parent pin type if we haven't already, and then add the stop
            if (!haveAddedParentPinTypeBecauseOfChild) {
              self.visibleStopRows.push(currentParentPinType);
              haveAddedParentPinTypeBecauseOfChild = true;
            }

            self.visibleStopRows.push(stopRowItem);
          }
        } else if (stop && self.selectedStops[stop._id]) {
          delete self.selectedStops[stop._id];
        }
      }
      // Need to remove any now invisible stops from the selected stops
      self.onSelectedStopsChange({ $event: { model: self.selectedStops } });
    };

    self.handleRowClick = function handleSelectClick(stopRow, event) {
      if (self.selectedStops) {
        // list with check boxes
        event.preventDefault();
        if (self.isStop(stopRow)) {
          var pinOrLevelId = self.getStopPinId(stopRow);
          if (self.selectedStops[pinOrLevelId]) {
            delete self.selectedStops[pinOrLevelId];
          } else {
            self.selectedStops[pinOrLevelId] = true;
          }
        } else {
          var pinTypeToSelect = getStopPinType(stopRow);
          selectAll(pinTypeToSelect);
          var isTogglingOn = !self.selectedPinTypes[pinTypeToSelect];
          if (!isTogglingOn) {
            delete self.selectedPinTypes[pinTypeToSelect];
          } else {
            self.selectedPinTypes[pinTypeToSelect] = true;
          }
        }
        self.onSelectedStopsChange({ $event: { model: self.selectedStops } });
      } else {
        // list with back buttons
        self.onRemoveStopClick({ $event: stopRow });
      }
    };

    self.getRowText = function getRowText(stopRow) {
      return stopRow.text || getStopPin(stopRow).name;
    };

    self.getIcon = function getIcon(stopRow) {
      var pin = getStopPin(stopRow);
      var pinTypeId = pin ? pin.pinType : stopRow.pinType;
      if (pinTypeId === "level" || !pinTypeId) {
        return "layers";
      } else if (self.pinTypes && self.pinTypes[pinTypeId]) {
        return self.pinTypes[pinTypeId].icon;
      }
    };

    self.getColor = function getColor(stopRow) {
      var pin = getStopPin(stopRow);
      if (!pin) {
        if (stopRow.pinType === "level") {
          // level header row
          return "#FF0000";
        }
        return self.pinTypes[stopRow.pinType].default_color;
      }
      if (pin.color) {
        return pin.color;
      } else if (pin.pinType) {
        return self.pinTypes[pin.pinType].default_color;
      } else {
        // level, pin.pinType does not exist
        return "#FF0000";
      }
    };

    self.getAssetsCount = function getAssetsCount() {
      return self.stops.reduce(function (accumulator, stop) {
        if (stop.asset) accumulator++;
        return accumulator;
      }, 0);
    };

    self.getRoomsCount = function getRoomsCount() {
      return self.stops.reduce(function (accumulator, stop) {
        if (stop.room) accumulator++;
        return accumulator;
      }, 0);
    };

    self.isStop = function isStop(stopRow) {
      return !!getStopPin(stopRow);
    };

    self.isStopVisible = function isStopVisible(stopRow) {
      if (!self.isStop(stopRow)) {
        throw new Error("Must be a stop to check visibility");
      }

      var stopPinId = getStopPinId(stopRow);
      for (var i = 0; i < self.visibleStopRows.length; i++) {
        var stopRowToCheckAgainst = self.visibleStopRows[i];
        if (!self.isStop(stopRowToCheckAgainst)) {
          continue;
        }
        if (getStopPinId(stopRowToCheckAgainst) === stopPinId) {
          return true;
        }
      }

      return false;
    };

    self.handleCollapseToggle = function handleCollapseToggle(stopRow, $event) {
      // Make sure this click doesn't go throw this icon and trigger outer clicks on the row
      $event.stopPropagation();
      if (self.isStop(stopRow)) return; // we don't care to toggle on stop clicks

      if (self.collapsedPinTypes[stopRow.pinType]) {
        delete self.collapsedPinTypes[stopRow.pinType];
      } else {
        self.collapsedPinTypes[stopRow.pinType] = true;
      }
    };

    self.isCollapsed = function isCollapsed(stopRow) {
      if (!self.isStop(stopRow)) {
        return false;
      }

      // TODO: When we add Floors into this equation, we need to handle that here, floors don't ahve pin types, so we will breakkkk
      var stop = getStopPin(stopRow);
      return Boolean(self.collapsedPinTypes[stop.pinType]);
    };

    function selectAll(pinTypeId) {
      self.stops.forEach(function (stop) {
        if (getStopPinType(stop) === pinTypeId) {
          if (!self.selectedPinTypes[pinTypeId] && self.isStopVisible(stop)) {
            self.selectedStops[getStopPinId(stop)] = true;
          } else {
            delete self.selectedStops[getStopPinId(stop)];
          }
        }
      });
    }

    function getStopPinId(stopRow) {
      if (!self.isStop(stopRow)) {
        throw new Error("Must be a stop to fetch pin / level ID");
      }
      return getStopPin(stopRow)._id;
    }

    function getStopPinType(stopRow) {
      var pin = getStopPin(stopRow);
      return pin ? pin.pinType || "level" : stopRow.pinType;
    }

    function getStopPin(stop) {
      return stop.room || stop.level || stop.asset;
    }
  }
})();
