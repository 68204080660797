import { Plugin } from "@ckeditor/ckeditor5-core";
import { Widget, toWidget } from "@ckeditor/ckeditor5-widget";
import { InsertInsightsBlockPromptCommand } from "../commands/InsertInsightsBlockPrompt.command";
import { InsightsWidgetType } from "../../report-builder/insights/types";

export class InsightsBlockPromptConversion extends Plugin {
  static get requires() {
    /**
     * Additionally, you need to ensure that the Widget plugin is loaded.
     * If you omit it, the elements in the view will have all the classes
     * (like ck-widget) but there will be no “behaviors” loaded (for example, clicking a widget will not select it).
     */
    return [Widget];
  }

  init() {
    if (this.editor) {
      this.defineSchema();
      this.defineConverters();
      this.editor.commands.add(
        "insertInsightsBlockPrompt",
        new InsertInsightsBlockPromptCommand(this.editor)
      );
    }
  }

  private defineSchema() {
    const schema = this.editor?.model.schema;

    schema.register("abxInsightsBlockPrompt", {
      // Behaves like a self-contained block object (e.g. a block image)
      // allowed in places where other blocks are allowed (e.g. directly in the root).
      // https://ckeditor.com/docs/ckeditor5/latest/framework/architecture/editing-engine.html#schema
      isObject: true,
      allowIn: "$root",
      allowAttributes: [
        "abxInsightsWidgetTitle",
        "abxInsightsWidgetId",
        "abxInsightsDashboardId",
        "abxInsightsWidgetType",
        "abxInsightsFilters",
        "abxInsightsHeight",
        "abxInsightsWidth",
        "abxInsightsShowIndicatorTitles",
        "uniqueElementId",
      ],
    });
  }

  private defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for("downcast").elementToStructure({
      model: "abxInsightsBlockPrompt",
      view: (modelElement, { writer }) => {
        let description = modelElement.getAttribute(
          "abxInsightsWidgetTitle"
        ) as string;
        if (!description) {
          description = "Title not found";
        }

        let widgetId = modelElement.getAttribute(
          "abxInsightsWidgetId"
        ) as string;
        if (!widgetId) {
          widgetId = "Widget ID not found";
        }

        let dashboardId = modelElement.getAttribute(
          "abxInsightsDashboardId"
        ) as string;
        if (!dashboardId) {
          dashboardId = "Dashboard ID not found";
        }

        let widgetType = modelElement.getAttribute("abxInsightsWidgetType") as
          | InsightsWidgetType
          | undefined;
        if (!widgetType) {
          widgetType = InsightsWidgetType.CHART;
        }

        let filters = modelElement.getAttribute("abxInsightsFilters") as string;
        if (!filters) {
          filters = "";
        }

        let height = modelElement.getAttribute("abxInsightsHeight") as string;
        if (
          !height ||
          widgetType === InsightsWidgetType.INDICATOR ||
          widgetType === InsightsWidgetType.TABLE ||
          widgetType === InsightsWidgetType.PIVOT
        ) {
          height = "No height";
        }

        let width = modelElement.getAttribute("abxInsightsWidth") as string;
        if (
          !width ||
          widgetType === InsightsWidgetType.INDICATOR ||
          widgetType === InsightsWidgetType.TABLE ||
          widgetType === InsightsWidgetType.PIVOT
        ) {
          width = "No width";
        }

        let showIndicatorTitles = modelElement.getAttribute(
          "abxInsightsShowIndicatorTitles"
        ) as boolean;
        if (!showIndicatorTitles) {
          showIndicatorTitles = false;
        }

        const uniqueElementId = modelElement.getAttribute("uniqueElementId");

        const filterLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__filter-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Filters"),
            ]),
          ]
        );
        const filterValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__filter-value col-70" },
          [writer.createText(filters)]
        );
        const filterRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__filter" },
          [filterLabel, filterValue]
        );

        const widgetIdLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__widget-id-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Widget ID"),
            ]),
          ]
        );
        const widgetIdValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__widget-id-value col-70" },
          [writer.createText(widgetId)]
        );
        const widgetIdRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__widget-id" },
          [widgetIdLabel, widgetIdValue]
        );

        const dashboardIdLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__dashboard-id-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Dashboard ID"),
            ]),
          ]
        );

        const dashboardIdValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__dashboard-id-value col-70" },
          [writer.createText(dashboardId)]
        );

        const dashboardIdRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__dashboard-id" },
          [dashboardIdLabel, dashboardIdValue]
        );

        const widgetTypeLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__widget-type-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Widget Type"),
            ]),
          ]
        );

        const widgetTypeValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__widget-type-value col-70" },
          [writer.createText(`${widgetType}`)]
        );

        const widgetTypeRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__widget-type" },
          [widgetTypeLabel, widgetTypeValue]
        );

        let showTitleLabel;
        let showTitleValue;
        let showTitleRow;

        if (
          widgetType === InsightsWidgetType.INDICATOR ||
          widgetType === InsightsWidgetType.TABLE ||
          widgetType === InsightsWidgetType.PIVOT
        ) {
          showTitleLabel = writer.createContainerElement(
            "td",
            { class: "abx-insights-block-prompt__show-title-label col-30" },
            [
              writer.createContainerElement("strong", {}, [
                writer.createText("Show Widget Title"),
              ]),
            ]
          );
          showTitleValue = writer.createContainerElement(
            "td",
            { class: "abx-insights-block-prompt__show-title-value col-70" },
            [writer.createText(`${showIndicatorTitles}`)]
          );

          showTitleRow = writer.createContainerElement(
            "tr",
            { class: "abx-insights-block-prompt__show-title" },
            [showTitleLabel, showTitleValue]
          );
        }

        const heightLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__height-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Height"),
            ]),
          ]
        );

        const heightValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__height-value col-70" },
          [writer.createText(`${height}`)]
        );

        const heightRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__height" },
          [heightLabel, heightValue]
        );

        const widthLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__width-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Width"),
            ]),
          ]
        );

        const widthValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__width-value col-70" },
          [writer.createText(`${width}`)]
        );

        const widthRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__width" },
          [widthLabel, widthValue]
        );

        const descriptionLabel = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__description-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Description"),
            ]),
          ]
        );
        const descriptionValue = writer.createContainerElement(
          "td",
          { class: "abx-insights-block-prompt__description-value col-70" },
          [writer.createText(description)]
        );
        const descriptionRow = writer.createContainerElement(
          "tr",
          { class: "abx-insights-block-prompt__description" },
          [descriptionLabel, descriptionValue]
        );

        const tableHead = writer.createContainerElement(
          "thead",
          { class: "abx-insights-block-prompt__header" },
          [
            writer.createContainerElement("tr", {}, [
              writer.createContainerElement("th", { colspan: 2 }, [
                writer.createText("Insert Insights content"),
                writer.createContainerElement(
                  "span",
                  { class: "abx-insights-block-prompt__header-description" },
                  [
                    writer.createText(
                      " - This Insights widget will be rendered as an image and inserted when creating a Report from this Template"
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        );

        let tableBody;

        if (showTitleRow) {
          tableBody = writer.createContainerElement(
            "tbody",
            { class: "abx-insights-block-prompt__body" },
            [
              descriptionRow,
              dashboardIdRow,
              widgetIdRow,
              filterRow,
              heightRow,
              widthRow,
              widgetTypeRow,
              showTitleRow,
            ]
          );
        } else {
          tableBody = writer.createContainerElement(
            "tbody",
            { class: "abx-insights-block-prompt__body" },
            [
              descriptionRow,
              dashboardIdRow,
              widgetIdRow,
              filterRow,
              heightRow,
              widthRow,
              widgetTypeRow,
            ]
          );
        }

        const table = writer.createContainerElement(
          "table",
          { class: "abx-insights-block-prompt", "data-id": uniqueElementId },
          [tableHead, tableBody]
        );

        return toWidget(table, writer, { label: "insights block prompt" });
      },
    });

    // since we built elementToStructure for downcast, we need to build structureToElement for upcast
    conversion.for("upcast").add((dispatcher) => {
      // look at every table element in the view, since our custom element is a table
      dispatcher.on("element:table", (_evt, data, conversionApi) => {
        const {
          consumable,
          writer,
          safeInsert,
          convertChildren,
          updateConversionResult,
        } = conversionApi;
        const { viewItem: table } = data;

        const tableFilter = {
          name: true,
          classes: "abx-insights-block-prompt",
        };

        if (!consumable.test(table, tableFilter)) {
          // make sure the table we found is actually our custom element
          return;
        }

        // Check if there is only one child.
        if (table.childCount !== 2) {
          return;
        }

        // Get children elements
        const tHead = table.getChild(0);
        const tBody = table.getChild(1);
        const dataId = table.getAttribute("data-id");

        // get description
        const description = tBody?.getChild(0)?.getChild(1)?.getChild(0)?.data;
        const dashboardId = tBody?.getChild(1)?.getChild(1)?.getChild(0)?.data;
        const widgetId = tBody?.getChild(2)?.getChild(1)?.getChild(0)?.data;
        const filters = tBody?.getChild(3)?.getChild(1)?.getChild(0)?.data;
        const height = tBody?.getChild(4)?.getChild(1)?.getChild(0)?.data;
        const width = tBody?.getChild(5)?.getChild(1)?.getChild(0)?.data;
        const widgetType = tBody?.getChild(6)?.getChild(1)?.getChild(0)?.data;
        const showIndicatorTitle = tBody
          ?.getChild(7)
          ?.getChild(1)
          ?.getChild(0)?.data;

        // Create model element.
        let modelElement;
        if (showIndicatorTitle) {
          modelElement = writer.createElement("abxInsightsBlockPrompt", {
            abxInsightsWidgetTitle: description,
            abxInsightsDashboardId: dashboardId,
            abxInsightsWidgetId: widgetId,
            abxInsightsFilters: filters || "",
            abxInsightsHeight: height || "",
            abxInsightsWidth: width || "",
            abxInsightsWidgetType: widgetType || "",
            abxInsightsShowIndicatorTitles: showIndicatorTitle || false,
            uniqueElementId: dataId,
          });
        } else {
          modelElement = writer.createElement("abxInsightsBlockPrompt", {
            abxInsightsWidgetTitle: description,
            abxInsightsDashboardId: dashboardId,
            abxInsightsWidgetId: widgetId,
            abxInsightsFilters: filters || "",
            abxInsightsHeight: height || "",
            abxInsightsWidth: width || "",
            abxInsightsWidgetType: widgetType || "",
            uniqueElementId: dataId,
          });
        }

        // Insert element on a current cursor location.
        if (!safeInsert(modelElement, data.modelCursor)) {
          return;
        }

        // Consume the main outer wrapper element.
        consumable.consume(table, tableFilter);
        // Consume the thead element.
        consumable.consume(tHead, {
          name: true,
          classes: "abx-insights-block-prompt__header",
        });
        // Consume the tbody element.
        consumable.consume(tBody, {
          name: true,
          classes: "abx-insights-block-prompt__body",
        });

        // Handle children conversion inside thead element.
        convertChildren(tHead, modelElement);
        // Handle children conversion inside tbody element.
        convertChildren(tBody, modelElement);

        // Necessary function call to help setting model range and cursor
        // for some specific cases when elements being split.
        updateConversionResult(modelElement, data);
      });
    });
  }
}
