import { sanitize } from "dompurify";

/**
 * Sanitizes raw HTML input to prevent XSS attacks
 * and replaces `&amp;` with `&` if specified
 *
 * @param {string} rawHTML - string that needs to be sanitized
 * @param [replaceAmpersand=true] - whether to replace `&amp;` with `&` in the sanitized HTML
 * @returns a sanitized version of the input `rawHTML` string with any potentially malicious or
 * unwanted HTML tags and attributes removed using the `DOMPurify` library
 */
export function sanitizeXSS(rawHTML: string, replaceAmpersand = true) {
  const sanitized = sanitize(rawHTML);
  if (replaceAmpersand) {
    return sanitized.replace(/&amp;/g, "&");
  }
  return sanitized;
}
