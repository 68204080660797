(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.recent
   */
  angular
    .module("akitabox.core.services.recent", [
      "akitabox.core.constants",
      "akitabox.core.lib.moment",
      "ngCookies",
    ])
    .factory("RecentService", RecentService);

  /**
   * @ngdoc factory
   * @module akitabox.core.services.recent
   * @name RecentService
   *
   * @description
   * This service handles the 'recent' lists, which give the user
   * a list of entities that they have accessed recently.
   * Used for the sidemenu on mobile.
   *
   */
  function RecentService($cookies, moment, KEY_RECENT_COOKIE) {
    var service = {
      visitBuilding: visitBuilding,
      visitWorkOrder: visitWorkOrder,
      getRecentBuildings: getRecentBuildings,
      getRecentWorkOrders: getRecentWorkOrders,
      clearCookie: clearCookie,
      clearCookieAt: clearCookieAt,
    };

    // Let recents expire in a month
    var expires = moment().add(1, "months").toDate();

    return service;

    /**
     * Store an object in this cookie.
     *
     * @param {Object} obj Object to store as the value of the cookie.
     */
    function storeCookie(obj) {
      $cookies.putObject(KEY_RECENT_COOKIE, obj, { expires: expires });
    }

    /**
     * Grab the object associated with the cookie. Define it if it has
     * not been defined yet.
     *
     * @returns {Object} Cookie object.
     */
    function retrieveCookie() {
      var cookie = $cookies.getObject(KEY_RECENT_COOKIE);

      // Define the cookie object if it hasn't been defined yet.
      if (!angular.isDefined(cookie)) {
        cookie = {
          buildings: [],
          tasks: [],
        };

        storeCookie(cookie);
      }

      return cookie;
    }

    function add(type, obj) {
      var cookie = retrieveCookie();
      var list = cookie[type];

      // Check to see if the item is already in the list.
      var index;
      list.forEach(function (v, i) {
        if (v._id === obj._id) index = i;
      });

      // If it's already there, remove it
      if (angular.isDefined(index)) {
        list.splice(index, 1);
      }

      // Insert the object at the start of the array
      list.splice(0, 0, obj);

      // Make sure the list is at max three items
      // This will only be true if we just added a fourth item.
      if (list.length > 3) list.pop();

      cookie[type] = list;
      storeCookie(cookie);
    }

    function remove(type, id) {
      var cookie = retrieveCookie();
      var list = cookie[type];

      // Check to see if the item is already in the list.
      var index = -1;
      list.forEach(function (v, i) {
        if (v._id === id) index = i;
      });

      // If the item is found, remove it and update the list
      if (index !== -1) {
        list.splice(index, 1);
        cookie[type] = list;
        storeCookie(cookie);
      }
    }

    /**
     * Call this when the user visits a building.
     *
     * @param {Object} building
     */
    function visitBuilding(building) {
      // Only store name, account, _id, and delinquent status for buildings
      var obj = {
        _id: building._id,
        account: building.cre_account,
        name: building.name,
      };

      add("buildings", obj);
    }

    /**
     * Call this when the user visits a work order.
     *
     * @param {Object} workOrder
     */
    function visitWorkOrder(workOrder) {
      // store a limited set of properties off of the work order to minimize
      // cookie size.
      var obj = {
        subject: workOrder.subject,
        number: workOrder.number,
        name: workOrder.name,
        building: workOrder.building,
        _id: workOrder._id,
      };

      add("tasks", obj);
    }

    function getRecentBuildings() {
      return retrieveCookie().buildings;
    }

    function getRecentWorkOrders() {
      return retrieveCookie().tasks;
    }

    function clearCookie() {
      $cookies.remove(KEY_RECENT_COOKIE);
    }

    /**
     * Removes a specific object stored in the cookie
     *
     * @param {string} type  should be tasks/building, indicates which cookie to clear
     * @param {string} id    the id of the object we want to remove from the cookie
     */
    function clearCookieAt(type, id) {
      if (type && id) {
        remove(type, id);
      }
    }
  }
})();
