(function () {
  angular
    .module("akitabox.ui.dialogs.association")
    .factory("AssociationDialog", AssociationDialog);

  /** @ngInject */
  function AssociationDialog($log, $mdDialog, $mdMedia, $q) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/association/association.dialog.html",
      controller: "AssociationDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    return {
      show: show,
    };

    /**
     * @param options
     * @param options.locals
     * @param {Asset|Room} options.locals.model - the upstream entity
     * @param {Array<Task>>} options.locals.modelType -  the upstream entity's model type (asset/room)
     * @param {Array<Association>>} options.locals.associations - current associations existing on this model
     *
     * @return {Promise<void>}
     */
    function show(options) {
      var error = new Error("Sorry, we weren't able to complete your request");
      var hasErrored = false;

      if (!options) {
        $log.error("AssociationDialog: no options provided");
        hasErrored = true;
      } else if (!options.locals) {
        $log.error("AssociationDialog: no locals provided");
        hasErrored = true;
      } else if (!options.locals.model) {
        $log.error("AssociationDialog: invalid model provided");
        hasErrored = true;
      } else if (
        !options.locals.modelType ||
        (options.locals.modelType !== "Room" &&
          options.locals.modelType !== "Asset")
      ) {
        $log.error("AssociationDialog: invalid modelType provided");
        hasErrored = true;
      } else if (
        options.locals.associations &&
        !Array.isArray(options.locals.associations)
      ) {
        $log.error("AssociationDialog: invalid associations provided");
        hasErrored = true;
      }

      if (hasErrored) {
        return $q.reject(error);
      }

      return $mdDialog.show(angular.extend({}, modal, options));
    }
  }
})();
