(function () {
  angular
    .module("akitabox.ui.dialogs.delete")
    .factory("DeleteDialog", DeleteDialog);

  /* @ngInject */
  function DeleteDialog($log, $mdDialog, $mdMedia, $q) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/delete/delete.dialog.html",
      controller: "DeleteDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    return {
      show: show,
    };

    /**
     * @param {object} options
     * @param {object} options.locals
     * @param {Asset} [options.locals.asset] - The Asset to delete
     * @param {Room} [options.locals.room] - The Room to delete
     * @return {Promise<void>}
     */
    function show(options) {
      var hasErrored = false;

      if (!options) {
        $log.error("DeleteDialog: no options provided");
        hasErrored = true;
      } else if (!options.locals) {
        $log.error("DeleteDialog: no locals provided");
        hasErrored = true;
      } else if (!options.locals.asset && !options.locals.room) {
        $log.error("DeleteDialog: no room/asset provided");
        hasErrored = true;
      }

      if (hasErrored) {
        return $q.reject("Sorry, we weren't able to complete your request.");
      }

      return $mdDialog.show(angular.extend({}, modal, options));
    }
  }
})();
