(function () {
  angular
    .module("akitabox.ui.components.workOrderList.workOrderQuickActions", [
      "akitabox.ui.components.input",
      "akitabox.ui.dialogs.print",
      "akitabox.ui.dialogs.workOrder",
      "akitabox.ui.dialogs.workOrderLog",
    ])
    .component("abxWoQuickActions", {
      bindings: {
        workOrder: "<abxWorkOrder",
        building: "<?abxBuilding",
        onWorkOrderUpdate: "&",
      },
      controller: AbxWorkOrderQuickActionsController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/work-order-list/components/work-order-quick-actions/work-order-quick-actions.component.html",
    });

  function AbxWorkOrderQuickActionsController(
    // Angular
    $q,
    $log,
    $timeout,
    $window,
    // Constants
    MINIMUM_RENDER_DELAY,
    // Dialogs
    CancelWorkOrderDialog,
    CompleteWorkOrderDialog,
    PrintDialog,
    RescheduleWorkOrderDialog,
    WorkOrderLogDialog,
    // Services
    ChecklistService,
    EnvService,
    HttpService,
    InspectionService,
    ShadowService,
    ToastService,
    UserService,
    WorkOrderService,
    models,
    AssignDialog
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.permissions = {};
    self.disabled = true;
    self.fromInspection = self.workOrder.source === "inspection";
    self.inspectionFloor = null;

    //Functions
    self.print = print;
    self.showCancelDialog = showCancelDialog;
    self.showRescheduleDialog = showRescheduleDialog;
    self.showCompleteDialog = showCompleteDialog;
    self.continueInspection = continueInspection;
    self.showLogWorkDialog = showLogWorkDialog;
    self.getBuildingId = getBuildingId;
    self.showAssignDialog = showAssignDialog;

    self.$onInit = function () {
      getWorkOrder().then(function (workOrder) {
        self.workOrder = workOrder;
        self.fromInspection = self.workOrder.source === "inspection";
        if (self.fromInspection) {
          ChecklistService.getByWorkOrderId(
            self.workOrder.building.organization,
            self.workOrder._id
          ).then(function (checklists) {
            self.inspectionFloor = InspectionService.getInspectionFloor(
              checklists,
              self.workOrder
            );
          });
        }
        self.assignees = angular.copy(self.workOrder.assignees);
        self.workOrderAssetName = self.workOrder.asset
          ? self.workOrder.asset.name
          : "N/A";
        self.workOrderFloorName = self.workOrder.level
          ? self.workOrder.level.name
          : "N/A";
        getPermissions();
      });
    };

    function getWorkOrder() {
      // Check if already populated
      if (
        self.workOrder &&
        self.workOrder.building &&
        self.workOrder.building._id
      ) {
        return $q.resolve(self.workOrder);
      }

      // Assume self.workOrder is an unpopulated work order
      if (!self.building) {
        $log.error(
          "Must pass in abx-building-id if abx-work-order is a work order _id"
        );
        return $q.reject();
      }

      return WorkOrderService.getById(self.building._id, self.workOrder._id);
    }

    function getPermissions() {
      var permissions = UserService.getPermissions();
      var currentUser = UserService.getCurrent();

      var isOwn = self.workOrder.assignees
        .map(function (assignee) {
          return assignee._id;
        })
        .includes(currentUser.identity._id);

      self.permissions.canComplete = isOwn
        ? // Completing tasks you are assigned to should always be enabled
          true
        : permissions.task.complete_any;
      self.permissions.canCancel = permissions.task.cancel;
      self.permissions.canAssign = permissions.task.assign;
      self.permissions.canUpdate = permissions.task.update;

      var canLogOnAny =
        permissions.work_order_log.create_own_log_on_any_task ||
        permissions.work_order_log.create_any;

      self.permissions.canLogWork = isOwn
        ? // Log-ing for self should always be enabled
          true
        : // For tasks we're not assigned to:
          canLogOnAny;

      self.loading = false;
    }

    function getBuildingId(workOrder) {
      return Object.prototype.hasOwnProperty.call(workOrder.building, "_id")
        ? workOrder.building._id
        : workOrder.building;
    }

    function print() {
      var buildingId = getBuildingId(self.workOrder);
      var route = WorkOrderService.buildListRoute(buildingId);
      var locals = {
        selected: [self.workOrder._id],
        route: route,
      };

      return PrintDialog.show({ locals: locals });
    }

    function showCancelDialog() {
      var buildingId = getBuildingId(self.workOrder);
      var locals = {
        workOrders: [{ _id: self.workOrder._id, building: buildingId }],
      };

      return CancelWorkOrderDialog.show({ locals: locals })
        .then(function (workOrderId) {
          return WorkOrderService.getById(buildingId, workOrderId);
        })
        .then(updateWorkOrder)
        .catch(onError);
    }

    function showRescheduleDialog() {
      var buildingId = getBuildingId(self.workOrder);
      var locals = {
        buildingId: buildingId,
        workOrders: [self.workOrder],
      };
      RescheduleWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          return WorkOrderService.getById(buildingId, workOrders[0]._id);
        })
        .then(updateWorkOrder)
        .catch(onError);
    }

    function showLogWorkDialog() {
      WorkOrderLogDialog.show({
        locals: {
          workOrder: self.workOrder,
        },
      })
        .then(function () {
          ShadowService.sendEvent("workorder", "add-log", "dashboard");
        })
        .catch(onError);
    }

    function showCompleteDialog() {
      var locals = {
        workOrders: [self.workOrder],
      };

      return CompleteWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          updateWorkOrder(workOrders[0]);
          return;
        })
        .catch(onError);
    }

    function continueInspection() {
      var url = InspectionService.getInspectionUrl(
        self.workOrder.building._id,
        self.inspectionFloor._id,
        self.workOrder._id
      );
      $window.open(url, "_blank");
    }

    function updateWorkOrder(newWorkOrder) {
      self.workOrder = angular.extend(self.workOrder, newWorkOrder);
      self.onWorkOrderUpdate({ workOrder: self.workOrder });
    }

    function onError(err) {
      ToastService.showError(err);
      return $q.reject(err);
    }

    function showAssignDialog() {
      var locals = {
        buildingId: self.workOrder.building._id,
        workOrderId: self.workOrder._id,
      };

      return AssignDialog.show({ locals: locals })
        .then(function (updatedWorkOrder) {
          updateWorkOrder(updatedWorkOrder);
          return;
        })
        .catch(onError);
    }
  }
})();
