(function () {
  angular.module("akitabox.ui.dialogs.qrCode.pinSearch", [
    "akitabox.core.constants",
    "akitabox.core.filters.stringSeparator",
    "akitabox.core.toast",
    "akitabox.core.services.asset",
    "akitabox.core.services.building",
    "akitabox.core.services.room",
    "akitabox.core.services.qrcode",
    "akitabox.core.services.organization",
    "akitabox.ui.components.blockRadio",
    "akitabox.ui.components.pinTypeahead",
    "angular.filter",
    "ngMaterial",
  ]);
})();
