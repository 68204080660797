(function () {
  angular.module(
    "akitabox.core.ui.dialogs.inspectionProgram.create.inspectionDetailSection",
    [
      "akitabox.core.constants",
      "akitabox.core.services.checklistTemplate",
      "akitabox.core.services.organization",
    ]
  );
})();
