(function () {
  angular.module("akitabox.desktop.floor.detail", [
    "ui.router",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.floor",
    "akitabox.core.services.note",
    "akitabox.core.services.pin",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.recentActivity",
    "akitabox.core.services.tagCategory",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.workOrdersBff",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.document.select",
    "akitabox.ui.dialogs.floor.create",
    "akitabox.ui.dialogs.schedule.create",
    "akitabox.ui.dialogs.workOrder.create",
    "akitabox.desktop.components.relatedWorkOrderCard",
  ]);
})();
