(function () {
  /**
   * @ngdoc component
   * @name abxRoundStopItemQuestionList
   *
   * @description
   * Question List Item for round task stop
   */
  angular
    .module("akitabox.planView")
    .component("abxRoundStopItemQuestionList", {
      bindings: {
        questions: "<abxQuestions",
        setValue: "<abxSetValue",
        isOptDisabled: "<abxIsOptDisabled",
        isDisabled: "<abxIsDisabled",
      },
      controller: AbxRoundStopItemQuestionListController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/modules/plan-view/components/round-stop-item-question-list/round-stop-item-question-list.component.html",
    });

  /** @ngInject */
  function AbxRoundStopItemQuestionListController(
    // Angular
    $timeout,
    // Third-Party
    markdown,
    moment,
    // AkitaBox
    models,
    // Constants
    MINIMUM_RENDER_DELAY
  ) {
    var self = this;

    var QUESTION_TYPES = models.CHECKLIST_QUESTION.TYPES;

    var md = new markdown({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
    });

    // Attributes
    self.loading = true;
    self.editingSignature = false;
    self.passFailOptions = getPassFailOptions();

    // Functions
    self.setValueWrapper = setValueWrapper;
    self.getButtonClass = getButtonClass;
    self.checkEnumIfSelected = checkEnumIfSelected;
    self.getText = getText;
    self.getMessage = getMessage;
    self.updateSignature = updateSignature;
    self.updateTextbox = updateTextbox;
    self.formatSignatureTimestamp = formatSignatureTimestamp;
    self.toggleAddSubmenu = function () {
      self.showAddSubmenu = !self.showAddSubmenu;
    };

    // =================
    // Life Cycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.questions && self.questions) {
        // Construct Question Index Map
        // This is needed so that questions can be numbered in
        // a continuous list even though some checklist items are not questions
        self.questionIndexMap = {};
        var questionIndex = 0;
        for (var i = 0; i < self.questions.length; ++i) {
          var question = self.questions[i];
          if (question.type !== QUESTION_TYPES.HEADER) {
            self.questionIndexMap[question._id] = questionIndex;
            questionIndex += 1;
          }
        }
        $timeout(function () {
          self.loading = false;
        }, MINIMUM_RENDER_DELAY);
      }
    };

    // =================
    // Public Functions
    // =================

    function getText(item) {
      if (item.markdown) {
        return md.render(item.text);
      }
      return item.text;
    }

    function getMessage(item) {
      if (item.markdown) {
        return md.render(item.message);
      }
      return item.message;
    }

    function getButtonClass(item, option) {
      switch (item.type) {
        case QUESTION_TYPES.ENUM_SINGLE_VALUE_RADIO:
          if (item.value === option.display_value) {
            return option.is_passing ? "btn-pass" : "btn-fail";
          }
          break;
        case QUESTION_TYPES.ENUM_SINGLE_VALUE:
        case QUESTION_TYPES.ENUM_MULTI_VALUE:
          return option.is_passing ? "chkbx-pass" : "chkbx-fail";
      }

      return "btn-secondary";
    }

    function checkEnumIfSelected(item, option) {
      switch (item.type) {
        case QUESTION_TYPES.ENUM_SINGLE_VALUE:
          // Value is still a string, so just check it if matches
          return item.value === option.display_value;
        case QUESTION_TYPES.ENUM_MULTI_VALUE:
          // Value is an array of strings, check if the option is in the array
          for (var i = 0; i < item.value.length; i++) {
            if (item.value[i] === option.display_value) {
              return true;
            }
          }
          return false;
      }
    }

    function updateSignature(item, event) {
      item.value = event.value;
    }

    function updateTextbox(item, event) {
      item.value = event.value;
    }

    function formatSignatureTimestamp(timestamp) {
      var formattedTimestamp = moment(timestamp).format(
        "[Recorded on] MMM Do, YYYY [at] h:mm A"
      );
      return formattedTimestamp;
    }

    function setValueWrapper(item, option) {
      // handle anything specific to this component here when setValue called
      self.setValue(item, option);
      self.editingSignature = false;
    }

    // ===================
    // Private Functions
    // ===================

    function getPassFailOptions() {
      return Object.keys(models.CHECKLIST_QUESTION.VALUES).map(function (
        value
      ) {
        return models.CHECKLIST_QUESTION.VALUES[value];
      });
    }
  }
})();
