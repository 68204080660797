(function () {
  angular
    .module("akitabox.core.services.condition")
    .factory("ConditionService", ConditionService);

  /** @ngInject */
  function ConditionService(models, HttpService) {
    /**
     * Condition service
     *
     * @type {Object}
     */
    var service = {
      // Routes
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      // CRUD
      get: get,
      getAll: getAll,
      create: create,
      remove: remove,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildDetailRoute(buildingId, assemblyId, conditionId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/assemblies/" +
        assemblyId +
        "/conditions/" +
        conditionId
      );
    }

    function buildListRoute(buildingId, assemblyId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/assemblies/" +
        assemblyId +
        "/conditions"
      );
    }

    /**
     * Get an array of assemblies for an assembly
     *
     * @param {String} buildingId     Building ID in which to assembly exists
     * @param {String} assemblyId     Assembly ID for which to fetch assemblies
     * @param {Object} params         An object of query string parameters
     */
    function get(buildingId, assemblyId, params) {
      var route = buildListRoute(buildingId, assemblyId);
      return HttpService.get(route, params);
    }

    /**
     * Get all assembly conditions
     *
     * @param {String} buildingId     Building ID in which to assembly exists
     * @param {String} assemblyId     Assembly ID for which to fetch assemblies
     * @param {Object} params         Query params
     */
    function getAll(buildingId, assemblyId, params) {
      var route = buildListRoute(buildingId, assemblyId);
      var conditions = [];
      return HttpService.getAll(route, params)
        .then(function () {
          return conditions;
        })
        .finally(angular.noop, function (data) {
          angular.copy(data, conditions);
        });
    }

    /**
     * Creates a new condition
     *
     * @param {String} buildingId     Building ID in which to assembly exists
     * @param {String} assemblyId     Assembly ID for which to create assembly
     * @param {Object} data           Object containing fields to set on the new condition
     */
    function create(buildingId, assemblyId, data) {
      var route = buildListRoute(buildingId, assemblyId);
      return HttpService.post(route, data);
    }

    /**
     * Hard-delete a condition
     *
     * @param {String} buildingId     Building ID in which to assembly exists
     * @param {String} assemblyId     Assembly ID for which to fetch assemblies
     * @param {String} conditionId    Condition to remove
     */
    function remove(buildingId, assemblyId, conditionId) {
      var route = buildDetailRoute(buildingId, assemblyId, conditionId);
      return HttpService.remove(route);
    }
  }
})();
