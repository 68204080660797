(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.autocomplete
   */
  angular
    .module("akitabox.ui.directives.autocomplete")
    .directive("abmAutocomplete", AbmAutocompleteDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.autocomplete
   * @name AbmAutocompleteDirective
   *
   * @description
   * `abm-autocomplete` is an input directive that displays an autocomplete dialog on click
   *
   * @usage
   * <hljs lang="html">
   *
   * <abm-autocomplete
   *        placeholder="Click to change"
   *        abm-input-id="state"
   *        abm-input-name="state"
   *        abm-items="state in vm.querySearch(vm.searchText)"
   *        abm-item-text="state.display"
   *        abm-search-text="vm.searchText"
   *        abm-selected-item="vm.formData.state"
   *        abm-selected-item-change="vm.onStateChange(item)"
   *        abm-min-length="1"
   *        abm-delay="500">
   * </abm-automplete>
   *
   * </hljs>
   *
   * @ngInject
   */
  function AbmAutocompleteDirective(
    $compile,
    $mdDialog,
    $q,
    Utils,
    AutocompleteDialog
  ) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/autocomplete/autocomplete.html",
      transclude: true,
      replace: true,
      link: postLink,
      scope: {
        inputId: "@?abmInputId",
        inputName: "@?abmInputName",
        placeholder: "@placeholder",
        items: "@abmItems",
        itemText: "@abmItemText",
        itemDisabled: "@?abmItemDisabled",
        groupBy: "@?abmGroupBy",
        orderBy: "@?abmOrderBy",
        selectedItem: "=?abmSelectedItem",
        itemChange: "&?abmSelectedItemChange",
        searchText: "=?abmSearchText",
        minLength: "=?abmMinLength",
        clearButton: "&?abmClearButton",
        showDialogOnClear: "=?abmShowDialogOnClear",
        delay: "=?abmDelay",
        loading: "&?abmLoading",
      },
    };

    function postLink($scope, $element, attrs) {
      var itemParts = $scope.items.split(/ in /i);
      var templateTag = $element.find("abm-item-template").detach();
      var html = templateTag.length ? templateTag.html() : $element.html();

      // Html5 attributes
      $scope.disabled = angular.isDefined(attrs.disabled);
      $scope.required = angular.isDefined(attrs.required);

      // Custom attributes
      $scope.editable = true;
      $scope.selectedItemText = null;
      $scope.clearButton =
        angular.isDefined(attrs.abmClearButton) && $scope.clearButton();

      // Functions
      $scope.clear = clear;

      // Open dialog on click
      $element.on("click", openDialog);

      // Add clear button
      if ($scope.clearButton) addClearButton();

      // Watch ngDisabled
      $scope.$watch("ngDisabled()", function (value) {
        if (angular.isDefined(value)) evaluateDisabled();
      });

      // Watch ngRequired
      $scope.$watch("ngRequired()", function (value) {
        if (angular.isDefined(value)) $scope.required = value;
      });

      // Watch selected item
      $scope.$watch("selectedItem", function (value) {
        $scope.selectedItemText = getSelectedItemText();
        if (angular.isFunction($scope.itemChange))
          $scope.itemChange({ item: value });
        evaluateDisabled();
      });

      // Add loading indicator
      if (angular.isDefined(attrs.abmLoading)) {
        var $progress = angular.element(
          "<md-progress-linear " +
            'ng-show="loading()" ' +
            'class="abm-input-progress md-accent" ' +
            'md-mode="indeterminate">' +
            "</md-progress-linear>"
        );
        angular.element($element.parent()).append($compile($progress)($scope));
        $scope.$watch("loading()", function () {
          evaluateDisabled();
          if ($scope.disabled) {
            $element.attr("disabled", "disabled");
          } else {
            $element.removeAttr("disabled");
          }
          var $input = $element[0];
          var height = $input.offsetHeight ? $input.offsetHeight - 1 : 29;
          $progress.css("top", height + "px");
        });
      }

      /**
       * Evaluate whether the input should be disabled based on ngDisabled and loading
       */
      function evaluateDisabled() {
        var loading = angular.isDefined($scope.loading)
          ? $scope.loading()
          : false;
        $scope.disabled = attrs.ngDisabled
          ? loading || $scope.$parent.$eval(attrs.ngDisabled)
          : loading;
      }

      /**
       * Add clear button to input
       */
      function addClearButton() {
        var $button = angular.element(
          '<md-icon class="material-icons fg-dark abm-autocomplete-clear-button" ' +
            'ng-show="selectedItem && !disabled" ng-click="clear()">' +
            "clear" +
            "</md-icon>"
        );
        $element.css("padding-right", "26px");
        $button = $compile($button)($scope);
        angular.element($element.parent()).append($button);
      }

      /**
       * Clear selected item
       */
      function clear() {
        $scope.selectedItem = null;
        if ($scope.showDialogOnClear) {
          openDialog();
        }
      }

      /**
       * Open the autocomplete dialog
       *
       * @param  {Object} $event Click event
       */
      function openDialog($event) {
        // Disallow click if element is not editable
        if (!$scope.editable) {
          $event.preventDefault();
          return;
        }

        AutocompleteDialog.show({
          scope: $scope,
          locals: {
            /* eslint-disable quote-props */
            groupBy: $scope.groupBy,
            itemName: itemParts[0], // list item name
            itemExpr: itemParts[1], // expression the returns list items
            itemField: $scope.itemText,
            itemTemplate: html,
            minLength: $scope.minLength,
            orderBy: $scope.orderBy,
            /* eslint-enable quote-props */
          },
        }).then(function (item) {
          $scope.selectedItem = item;
        });
      }

      /**
       * Get selected item text (display value)
       *
       * @return {String}     Item text
       */
      function getSelectedItemText() {
        var itemText = null;
        if ($scope.itemText && $scope.itemText.indexOf(".") > -1) {
          itemText = $scope.itemText.split(".").slice(1).join(".");
        }
        return itemText
          ? Utils.getNamespacedValue($scope.selectedItem, itemText)
          : $scope.selectedItem;
      }
    }
  }
})();
