(function () {
  /**
   * @ngdoc filter
   *
   * https://stackoverflow.com/a/12728924
   */
  angular
    .module("akitabox.core.filters.tel", [])
    .filter("abxTel", AbxTelFilter);

  function AbxTelFilter() {
    return function (raw) {
      if (!raw) {
        return "";
      }

      // Trim and remove empty space and +().-_ characters
      var value = raw
        .toString()
        .trim()
        .replace(/(\s+|[+().\-_])/g, "");

      if (value.match(/[^0-9]/)) {
        return raw;
      }

      var country, city, number;
      switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
          country = "1";
          city = value.slice(0, 3);
          number = value.slice(3);
          break;
        case 11: // +CPPP####### -> C (PPP) ###-####
          country = value[0];
          city = value.slice(1, 4);
          number = value.slice(4);
          break;
        case 12: // +CCCPP####### -> CCC (PP) ###-####
          country = value.slice(0, 3);
          city = value.slice(3, 5);
          number = value.slice(5);
          break;
        default:
          return raw;
      }

      if (country === "1") {
        country = "";
      }

      number = number.slice(0, 3) + "-" + number.slice(3);

      return (country + " (" + city + ") " + number).trim();
    };
  }
})();
