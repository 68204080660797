(function () {
  const dataDogBrowserRum = require("@datadog/browser-rum");

  /**
   * @ngdoc module
   * @name akitabox.core.services.shadow
   */
  angular
    .module("akitabox.core.services.shadow", [
      "akitabox.core.constants",
      "akitabox.core.lib.appcues",
      "akitabox.core.services.identity",
      "akitabox.core.services.auth",
      "akitabox.core.services.env",
    ])
    .factory("ShadowService", ShadowService);

  /**
   * @ngdoc factory
   * @module akitabox.core.services.shadow
   * @name ShadowService
   *
   * @description
   * This service manages application state change
   */

  /* @ngInject */
  function ShadowService(
    $window,
    $q,
    $log,
    GA_TRACKING_ID,
    AppcuesService,
    IdentityService,
    AuthService,
    EnvService,
    DATADOG_WEB_CLIENT_CONFIG
  ) {
    // Dimension constants. Important for readability since they are indexed rather than hashed.
    var USER_DIMENSION_EMAIL_DOMAIN = "dimension1";
    var NETWORK_STATUS_DIMENSION = "dimension2";
    var ORGANIZATION_DIMENSION = "dimension3";
    var SELECTED_OPTION_DIMENSION = "dimension4";

    var service = {
      init: init,
      onLocationChange: onLocationChange,
      setOrganization: setOrganization,
      setSelectedOption: setSelectedOption,
      setPage: setPage,
      setUser: setUser,
      sendEvent: sendEvent,
    };

    return service;

    function init() {
      const results = [];
      if (ddrEnabled()) {
        results.push(initDDR());
      }

      if (gaEnabled()) {
        results.push(initGA());
      }

      return $q.all(results).then(() => undefined);
    }

    /**
     * To be called after routers change location. Services can
     * be notified of page changes here if they are dependent on
     * the url in the address bar already being changed (ex. Appcues)
     *
     * @param {String} newUrl url of the new location
     */
    function onLocationChange(newUrl) {
      var routesToIgnore = ["/logout"];
      var containsLocationToIgnore =
        routesToIgnore.filter(function (url) {
          return newUrl.indexOf(url) >= 0;
        }).length > 0;

      if (AuthService.isAuthenticated() && !containsLocationToIgnore) {
        AppcuesService.getInstance().then(function (Appcues) {
          if (Appcues) {
            Appcues.start();
            // Identify user to Appcues
            IdentityService.getCurrent().then(function (user) {
              if (user && user._id && user.email) {
                var currentTime = new Date();
                Appcues.identify(user._id, {
                  email: user.email,
                  currentDayOfWeek: currentTime.getDay(),
                  currentDayOfMonth: currentTime.getDate(),
                  currentHour: currentTime.getHours(),
                  currentMonth: currentTime.getMonth(),
                });
              }
            });
          }
        });
      }
    }

    /**
     * Sets the Organization Id dimension on page load
     *
     * @param organization
     */
    function setOrganization(organization) {
      if (!gaEnabled()) return;
      var dimensionValue = !angular.isEmpty(organization)
        ? organization._id
        : null;

      $window.ga("set", ORGANIZATION_DIMENSION, dimensionValue);
    }

    /**
     * Sets the selected option dimension being tracked
     *
     * @param {string} option
     */
    function setSelectedOption(option) {
      if (!gaEnabled()) {
        return;
      }
      $window.ga("set", SELECTED_OPTION_DIMENSION, option);
    }

    /**
     * To be called when a router changes the page displayed to the user.
     * Services can be notified of page changes here if they are *not*
     * dependent on the address bar url reflecting the new page or if they
     * need to know the title of the page.
     *
     * @param {any} url page url
     * @param {any} title page title
     */
    function setPage(url, title) {
      if (gaEnabled() && url && title) {
        sendGAPageview(url, title);
      }
    }

    // Private functions
    function initGA() {
      $window.ga("create", GA_TRACKING_ID, "auto");

      // Create dimension to track offline events
      $window.ga("set", NETWORK_STATUS_DIMENSION, "online");
    }

    // Private functions
    function initDDR() {
      if (!$window.executedDdr) {
        $window.executedDdr = true;
        return IdentityService.getCurrent()
          .then((user) => {
            dataDogBrowserRum.datadogRum.init(DATADOG_WEB_CLIENT_CONFIG);
            dataDogBrowserRum.datadogRum.setUser({ email: user.email });
            dataDogBrowserRum.datadogRum.startSessionReplayRecording();
          })
          .catch((err) => {
            $log.error("Data Dog RUM initialization error");
            $log.error(err);
            throw err;
          });
      } else {
        return $q.resolve();
      }
    }
    /**
     * Sends a pageview hit with page data to Google Analytics.
     *
     * @param {String} url      The page's url
     * @param {String} [title]  The page's title
     */
    function sendGAPageview(url, title) {
      var matches = url.match(/(http[s]?:\/\/)([^/\s]+)(.*)/);

      if (!matches) return; // url is invalid needs to be http://.....

      var page = matches[3];

      $window.ga("set", "location", url);
      $window.ga("set", "page", page);
      if (!angular.isEmpty(title)) {
        $window.ga("set", "title", title);
      }
      $window.ga("send", "pageview");
    }

    /**
     * A helper method that checks whether Google Analytics is currently enabled,
     * and whether or not we're in production. GA should only be used if this returns
     * True.
     *
     * @returns {boolean}
     */
    function gaEnabled() {
      return (
        Object.prototype.hasOwnProperty.call($window, "ga") &&
        EnvService.getEnvName() === "production"
      );
    }

    /**
     * A helper method that checks whether Datadog RUM Analytics is currently enabled,
     * and whether or not we're in production. DDR should only be used if this returns
     * True.
     *
     * @returns {boolean}
     */
    function ddrEnabled() {
      const envName = EnvService.getEnvName();
      return envName === "production";
    }

    /**
     * Sends an Event to GA.
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/events#event_fields
     *
     * @param {String} category   Typically the object that was interacted with (e.g. 'Video')
     * @param {String} action     The type of interaction (e.g. 'play')
     * @param {String} [label]    Useful for categorizing events (e.g. 'Fall Campaign')
     * @param {String} [value]    A numeric value associated with the event (e.g. 42)
     */
    function sendEvent(category, action, label, value) {
      if (!angular.isString(category)) {
        return $log.error(
          "ShadowService: Callee did not pass in a valid category"
        );
      } else if (!angular.isString(action)) {
        return $log.error(
          "ShadowService: Callee did not pass in a valid action"
        );
      } else if (!gaEnabled()) {
        // No need to log this one.
        return;
      }

      var eventPayload = {
        hitType: "event",
        eventCategory: category,
        eventAction: action,
      };
      if (angular.isString(label)) {
        eventPayload.eventLabel = label;
      }
      if (angular.isString(value)) {
        eventPayload.eventValue = value;
      }

      $window.ga("send", eventPayload);
    }

    /**
     * Sets the User-Scoped dimension, Email Domain. Only needs to be set once,
     * further sets override the current value. All page hits will automatically
     * be decorated with this dimension, by GA, for reporting.
     *
     * @param emailDomain
     */
    function setUser(user) {
      if (
        angular.isEmpty(user.emailDomain) &&
        angular.isEmpty(user.email) &&
        angular.isEmpty(user._id)
      ) {
        return $log.error(
          "Callee did not pass in a valid emailDomain, email, or id"
        );
      }
      if (!angular.isEmpty(user.emailDomain) && gaEnabled()) {
        $window.ga("set", USER_DIMENSION_EMAIL_DOMAIN, user.emailDomain);
      }
      if (!angular.isEmpty(user.email) && !angular.isEmpty(user._id)) {
        AppcuesService.getInstance().then(function (Appcues) {
          if (Appcues) {
            var currentTime = new Date();
            Appcues.identify(user._id, {
              email: user.email,
              currentDayOfWeek: currentTime.getDay(),
              currentDayOfMonth: currentTime.getDate(),
              currentHour: currentTime.getHours(),
              currentMonth: currentTime.getMonth(),
            });
          }
        });
      }
    }
  }
})();
