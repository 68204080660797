import { ReportBlockResponse } from "@akitabox/api-client";
import { Command } from "@ckeditor/ckeditor5-core";
import { Writer } from "@ckeditor/ckeditor5-engine";
import { ReportBlockSelectionDataGridFilters } from "../../report-block-dialog/ReportBlockSelectionDataGrid";

export type InsertReportBlockPromptCommandParams = [
  string,
  ReportBlockResponse,
  ReportBlockSelectionDataGridFilters
];

export class InsertReportBlockPromptCommand extends Command {
  execute(params: InsertReportBlockPromptCommandParams) {
    this.editor?.model.change((writer) => {
      // Insert <abxReportBlockPrompt>*</abxReportBlockPrompt> at the current selection position
      // in a way that will result in creating a valid model structure.
      this.editor.model.insertObject(createReportBlock(writer, params));
    });
  }

  refresh() {
    // refresh is routinely called by the editor
    // here we want to check if the command should be enabled or disabled depending on where
    // the user is in the document
    const model = this.editor?.model;
    const selection = model.document.selection;
    const firstPos = selection.getFirstPosition();
    if (firstPos) {
      const allowedIn = model.schema.findAllowedParent(
        firstPos,
        "abxReportBlockPrompt"
      );
      this.isEnabled = allowedIn !== null;
    } else {
      this.isEnabled = false;
    }
  }
}

function createReportBlock(
  writer: Writer,
  params: InsertReportBlockPromptCommandParams
) {
  const [description, reportBlock, filter] = params; // Destructure the params array

  let queryStringDisplay = "";
  if (Object.keys(filter).length) {
    queryStringDisplay = JSON.stringify(filter);
  }

  const reportBlockPrompt = writer.createElement("abxReportBlockPrompt", {
    abxDescription: description,
    abxReportBlockName: reportBlock?.name,
    abxReportBlockId: reportBlock?._id,
    abxQueryString: queryStringDisplay,
  });

  return reportBlockPrompt;
}
