(function () {
  angular
    .module("akitabox.ui.components.pinSearch", [
      "akitabox.core",
      "akitabox.core.directives.pinType",
      "akitabox.core.services.cancellable",
      "akitabox.core.services.flag",
    ])
    .constant("PIN_SEARCH_MODES", {
      DEFAULT: "default",
      EDITING: "editing",
      ACTIVELYSEARCHING: "activelySearching",
    });
})();
