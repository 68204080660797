(function () {
  angular.module("akitabox.desktop.schedule.detail").run(states);

  /* @ngInject */
  function states(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/schedule/detail/overview/schedule-overview.html",
          controller: "ScheduleOverviewTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Overview",
          },
        };
      },
      asset: function () {
        return {
          url: "/assets",
          templateUrl:
            "app/desktop/modules/schedule/detail/asset/schedule-asset.html",
          controller: "ScheduleAssetTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Asset",
          },
        };
      },
      details: function () {
        return {
          url: "/details",
          templateUrl:
            "app/desktop/modules/schedule/detail/details/schedule-details.html",
          controller: "ScheduleDetailsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Details",
          },
        };
      },
      floor: function () {
        return {
          url: "/floors",
          templateUrl:
            "app/desktop/modules/schedule/detail/floor/schedule-floor.html",
          controller: "ScheduleFloorTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Floor",
          },
        };
      },
      room: function () {
        return {
          url: "/rooms",
          templateUrl:
            "app/desktop/modules/schedule/detail/room/schedule-room.html",
          controller: "ScheduleRoomTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Room",
          },
        };
      },
      openWorkOrders: function () {
        return {
          url: "/open-work-orders",
          templateUrl:
            "app/desktop/modules/schedule/detail/open-work-orders/schedule-open-work-orders.html",
          controller: "ScheduleOpenWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Open WOs",
          },
        };
      },

      closedWorkOrders: function () {
        return {
          url: "/closed-work-orders",
          templateUrl:
            "app/desktop/modules/schedule/detail/closed-work-orders/schedule-closed-work-orders.html",
          controller: "ScheduleClosedWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Maintenance Schedule Closed WOs",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.schedule.overview",
        config: config.overview(),
      },
      {
        state: "app.building.schedule.asset",
        config: config.asset(),
      },
      {
        state: "app.building.schedule.details",
        config: config.details(),
      },
      {
        state: "app.building.schedule.floor",
        config: config.floor(),
      },
      {
        state: "app.building.schedule.room",
        config: config.room(),
      },
      {
        state: "app.building.schedule.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.building.schedule.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.schedule.overview",
        config: config.overview(),
      },
      {
        state: "app.schedule.asset",
        config: config.asset(),
      },
      {
        state: "app.schedule.details",
        config: config.details(),
      },
      {
        state: "app.schedule.floor",
        config: config.floor(),
      },
      {
        state: "app.schedule.room",
        config: config.room(),
      },
      {
        state: "app.schedule.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.schedule.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
    ]);
  }
})();
