(function () {
  angular
    .module("akitabox.core.services.tagCategory", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("TagCategoryService", TagCategoryService);

  /** @ngInject */
  function TagCategoryService(
    // Constants
    models,
    // Services
    HttpService,
    // Third-party
    CacheFactory
  ) {
    var cache;

    init();

    function init() {
      cache = CacheFactory.get("tagCategories");
      if (!cache) {
        cache = CacheFactory("tagCategories");
      }
    }

    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute(buildingId) {
      var buildingUri = "/" + models.BUILDING.ROUTE_PLURAL + "/" + buildingId;
      return buildingUri + "/" + models.TAG_CATEGORY.ROUTE_PLURAL;
    }

    function buildDetailRoute(buildingId, categoryId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + categoryId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildListRoute(buildingId);
      return HttpService.create(route, data, undefined, undefined, cache);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params, cache);
    }

    function getAll(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, params, cache);
    }

    function getById(buildingId, categoryId, params) {
      var route = buildDetailRoute(buildingId, categoryId);
      return HttpService.getById(route, categoryId, params);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, categoryId) {
      var route = buildDetailRoute(buildingId, categoryId);
      return HttpService.remove(route, categoryId, cache);
    }
  }
})();
