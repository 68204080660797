(function () {
  angular
    .module("akitabox.desktop.schedule.organizationList")
    .controller(
      "OrganizationScheduleListController",
      OrganizationScheduleListController
    );

  /* @ngInject */
  function OrganizationScheduleListController(
    // Angular
    $location,
    $scope,
    $timeout,
    $q,
    // Constants
    EVENT_SCHEDULE_CREATE,
    // Dialogs
    ExportDialog,
    // Services
    ScheduleService,
    ToastService,
    UserService,
    // Resolve
    organization,
    status,
    // Filtering
    ManagedBuildingFilter,
    FilterBarManager,
    ManagedModelFieldFilter,
    ManagedAssigneesFilter
  ) {
    var self = this;

    // Attributes
    self.schedules = null;
    self.organization = organization;
    self.status = status;
    self.actions = getHeaderActions();
    self.fabActions = getFabActions();
    self.activeFilters = null;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // update the list
        changeFilters(query);
      },
    });

    var subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "subject",
    });

    var idConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      inputType: "number",
      prefix: "MS-",
      modelValueToChipText: function (inputValue) {
        return "MS-" + inputValue;
      },
    });

    var priorityConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Priority",
      queryField: "priority",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Low", "Medium", "High", "Emergency"];
      },
      modelValueToFilterValue: function (priority) {
        return priority.toLowerCase();
      },
      modelValueToChipText: function (priority) {
        return priority.charAt(0).toUpperCase() + priority.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        return [queryStringValue];
      },
    });

    var UNASSIGNED_USER = {
      _id: "null",
      identity: {
        _id: "null",
        display_name: "Unassigned",
      },
    };

    var assigneesConfig = new ManagedAssigneesFilter(self.filterBarManager, {
      getEnumOptions: function () {
        return fetchAssignees().then(function (assignees) {
          var models = assignees.map(function (assignee) {
            var value = assignee.email;
            var identity = assignee.identity;

            if (identity) {
              value =
                identity.display_name !== identity.email
                  ? identity.display_name + " | " + identity.email
                  : identity.display_name;
            }

            return {
              model: assignee,
              value: value,
            };
          });

          // Add `Unassigned` as a typeahead option
          models.unshift({
            model: UNASSIGNED_USER,
            value: UNASSIGNED_USER.identity.display_name,
          });

          return models;
        });
      },
      modelValueToFilterValue: function (assignee) {
        var identity = assignee.identity;
        return identity._id || identity;
      },
      modelValueToChipText: function (assignee) {
        return assignee.identity.display_name;
      },
      filterValueToModelValue: function (assigneeQueryValue) {
        var models = [];
        // assigneeQueryValue is a string
        var accountIdList = assigneeQueryValue.split(",").slice(1);
        // Remove duplicates
        accountIdList = accountIdList.filter(function (_accountId, index) {
          return accountIdList.indexOf(_accountId) === index;
        });
        var userQuery = ["$in"];
        for (var i = 0; i < accountIdList.length; i++) {
          var accountId = accountIdList[i];
          if (accountId === "null") {
            models.push(UNASSIGNED_USER);
          } else {
            userQuery.push(accountId);
          }
        }

        return UserService.getAll(self.organization._id, {
          identity: userQuery.join(","),
          sort: "firstOrEmail,asc",
        }).then(function (users) {
          Array.prototype.push.apply(models, users);
          return models;
        });
      },
    });

    var buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
      getOrganizationId: function () {
        return self.organization._id;
      },
    });

    self.filterBarManager.addFilterConfiguration(subjectConfig);
    self.filterBarManager.addFilterConfiguration(idConfig);
    self.filterBarManager.addFilterConfiguration(priorityConfig);
    self.filterBarManager.addFilterConfiguration(assigneesConfig);
    self.filterBarManager.addFilterConfiguration(buildingConfig);

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // Functions
    self.changeFilters = changeFilters;
    self.fetchSchedules = fetchSchedules;
    self.fetchAllSchedules = fetchAllSchedules;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_SCHEDULE_CREATE, onScheduleCreate);

    // ------------------------
    //   Private Functions
    // ------------------------

    function getHeaderActions() {
      return [
        {
          icon: "get_app",
          text: "Export",
          onClick: exportSchedules,
        },
      ];
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportSchedules,
        },
      ];
    }

    function exportSchedules() {
      var filters = angular.extend({}, { status: status }, self.activeFilters);
      var locals = {
        route: ScheduleService.buildOrganizationListRoute(
          self.organization._id
        ),
        filters: filters,
        excelOnly: true,
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    /**
     * Handle schedule creation event
     *
     * @param {Event}       $event      Angular event
     * @param {Schedule[]}  schedules   List of new schedules
     */
    function onScheduleCreate($event, schedules) {
      if (!self.schedules) {
        self.schedules = [];
      }
      Array.prototype.unshift.apply(self.schedules, schedules);
      $timeout(function () {
        $scope.$broadcast("list:refreshClickEvents");
      });
    }

    function fetchAssignees() {
      return UserService.getAll(self.organization._id, {
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
      }).catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchSchedules(skip, limit) {
      var doFetch = function () {
        // Add status to url if not preset in active filters
        if (
          self.activeFilters &&
          !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
        ) {
          $location.search("status", status);
        }

        var params = {
          skip: skip,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return ScheduleService.getByOrganization(organization._id, filters)
          .then(function (schedules) {
            if (angular.isArray(self.schedules)) {
              self.schedules = self.schedules.concat(schedules);
            } else {
              self.schedules = schedules;
            }
            return schedules.length;
          })
          .catch(ToastService.showError);
      };

      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllSchedules(limit) {
      var doFetch = function () {
        // Add status to url if not preset in active filters
        if (
          self.activeFilters &&
          !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
        ) {
          $location.search("status", status);
        }

        var params = {
          skip: 0,
          limit: limit,
          status: status,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return ScheduleService.getAllByOrganization(organization._id, filters)
          .then(function (schedules) {
            self.schedules = schedules;
            return schedules.length;
          })
          .catch(ToastService.showError);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.schedules = null;
      $scope.$broadcast("list:refresh");
    }
  }
})();
