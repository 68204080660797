(function () {
  angular
    .module("akitabox.ui.components.toggleIcon", ["akitabox.core.services.env"])
    .component("abxToggleIcon", {
      bindings: {
        icon: "@?abxIcon",
        size: "@?abxSize",
        direction: "@?abxDirection",
        iconClass: "@?abxIconClass",
      },
      controller: AbxToggleIconController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/toggle-icon/toggle-icon.component.html",
    });

  function AbxToggleIconController() {
    var self = this;

    // Attributes
    self.icon = angular.isDefined(self.icon) ? self.icon : "expand_more";
    self.size = angular.isDefined(self.size) ? self.size : "24";
    self.direction = angular.isDefined(self.direction)
      ? self.direction
      : "down";
    self.classes = getClasses();

    // =================
    // Life Cycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.iconClass || changes.direction) {
        self.classes = getClasses();
      }
    };

    // =================
    // Private Functions
    // =================

    function getClasses() {
      var classes = ["md-" + self.size];
      if (self.direction === "up") {
        classes.push("expanded");
      }
      if (self.iconClass) {
        classes.push(self.iconClass);
      }
      return classes;
    }
  }
})();
