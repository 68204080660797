(function () {
  angular
    .module("akitabox.ui.dialogs.assign.assign")
    .factory("AssignDialog", AssignDialog);

  /* @ngInject */
  function AssignDialog($log, $q, $mdDialog) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/assign/assign.dialog.html",
      controller: "AssignDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    return {
      show: show,
    };

    /**
     * Show dialog, `options.locals.buildingId`, is required
     * Strictly one of `options.locals.workOrderId` and `options.locals.scheduleId` is required
     *
     * @param  {object}     options - Dialog options
     * @param  {object}     options.locals
     * @param  {ObjectId}   [options.locals.workOrderId] - Optional Task Id
     * @param  {ObjectId}   [options.locals.scheduleId] - Optional Maintenance Schedule Id
     * @param  {ObjectId}   options.locals.buildingId - Building Id

     * @return {Promise<WorkOrder|MaintenanceSchedule>}
     */
    function show(options) {
      var errored = false;

      if (!options) {
        $log.error("AssignWorkOrderDialog: options is required");
        errored = true;
      } else if (!options.locals) {
        $log.error("AssignWorkOrderDialog: options.locals is required");
        errored = true;
      } else if (!options.locals.buildingId) {
        $log.error(
          "AssignWorkOrderDialog: options.locals.buildingId is required"
        );
        errored = true;
      } else if (!options.locals.workOrderId && !options.locals.scheduleId) {
        $log.error(
          "AssignWorkOrderDialog: options.locals.workOrderId or options.locals.scheduleId is required"
        );
        errored = true;
      } else if (options.locals.workOrderId && options.locals.scheduleId) {
        $log.error(
          "AssignWorkOrderDialog: cannot provide both options.locals.workOrderId and options.locals.schedulId"
        );
        errored = true;
      }

      if (errored) {
        return $q.reject(
          new Error("Sorry, we weren't able to complete your request.")
        );
      }

      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
