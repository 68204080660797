import { FunctionComponent, useEffect, useState } from "react";
import { ImportRowResponse } from "@akitabox/api-client";
import { GridRowScrollEndParams, GridSlots } from "@mui/x-data-grid-pro";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";

import { api } from "../../api";
import { getENV } from "../../../utils/getEnv";
import { constants } from "../../../utils/constants";
import { BulkAddStepProps } from "../BulkAddDialogTypes";
import { downloadFile } from "../../../utils/downloadFile";
import { useInfiniteCall } from "../../hooks/useInfiniteCall";
import { capitalizeText } from "../../../utils/capitalizeText";
import {
  AbxDataGrid,
  AbxGridColDef,
} from "../../lists/abx-data-grid/AbxDataGrid";

const limit = 50;
const columns: AbxGridColDef<ImportRowResponse>[] = [
  {
    type: "string",
    hideable: false,
    sortable: false,
    filterable: false,
    field: "row_number",
    headerName: "Row #",
    abxGridColType: "basic",
  },
  {
    type: "string",
    sortable: false,
    filterable: false,
    field: "post_body",
    headerName: "Name",
    abxGridColType: "basic",
    renderCell: ({ row }) =>
      row.post_body?.name ?? row.original_row?.["Asset Name"],
  },
  {
    type: "string",
    field: "status",
    sortable: false,
    filterable: false,
    headerName: "Status",
    abxGridColType: "basic",
    renderCell: ({ row }) => capitalizeText(row.status),
  },
  {
    type: "number",
    sortable: false,
    filterable: false,
    field: "error_message",
    abxGridColType: "basic",
    headerName: "Error Message",
  },
];

export const ShowResults: FunctionComponent<BulkAddStepProps> = ({
  importId,
}) => {
  const [numImportErrors, setNumImportErrors] = useState(0);
  const shouldFetchImportRows = importId !== null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (importId !== null) {
          const response = await api.imports.importRowsStats({
            importId: importId,
          });
          setNumImportErrors(response.data.data.errored_rows);
        }
      } catch (error) {
        // swallow
      }
    };

    // Call the async function
    fetchData();
  }, [importId]);

  const {
    setSize,
    isLoading,
    data: importRowsResponse,
  } = useInfiniteCall(
    api.imports.getRows,
    (skip, previous) => {
      const startKey = previous?.data.start_key;
      return [{ skip, limit, startKey, importId: importId as string }];
    },
    shouldFetchImportRows
  );

  const getImportRowsData = (): {
    count: number;
    hasMore: boolean;
    rows: ImportRowResponse[];
  } => {
    if (!importRowsResponse) return { rows: [], count: 0, hasMore: false };

    const lastImportRow = importRowsResponse.at(-1)?.data;
    const hasMore = !!lastImportRow?.start_key;
    const count = lastImportRow?.count ?? 0;

    const rows = importRowsResponse.reduce<ImportRowResponse[]>(
      (rows, { data: importRow }) => {
        if (Array.isArray(importRow.data)) {
          return rows.concat(importRow.data);
        }
        return rows;
      },
      []
    );

    return { rows, count, hasMore };
  };

  const { rows, count, hasMore } = getImportRowsData();

  const handleScrollEnd = ({ visibleRowsCount }: GridRowScrollEndParams) => {
    if (hasMore && visibleRowsCount > 0) {
      setSize((size) => size + 1);
    }
  };

  const handleFileDownload = () => {
    if (!importId) return;

    const env = getENV();
    const { URL_BASE } = constants.APPS.CORE.ENV[env];
    const apiURL = URL_BASE.replace(/:\d+$/, "");
    const path = `/my_imports/${importId}/rows_csv`;

    const erroredParams = new URLSearchParams({ status: "errored" });
    const csvImportRowsURL = new URL(path, apiURL);
    csvImportRowsURL.search = erroredParams.toString();
    downloadFile(csvImportRowsURL);
  };

  const downloadButtonText = `${
    numImportErrors
      ? `DOWNLOAD ERRORED RESULTS (${numImportErrors})`
      : "NO ERRORS"
  }`;

  return (
    <Box css={(theme) => ({ padding: theme.spacing(2) })}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        css={(theme) => ({ padding: `0 ${theme.spacing(2)}` })}
      >
        <Typography fontWeight="bold">Results</Typography>
        <Button
          disabled={numImportErrors === 0}
          color="primary"
          disableElevation
          variant="contained"
          onClick={handleFileDownload}
          endIcon={numImportErrors > 0 ? <FileDownloadIcon /> : null}
        >
          {downloadButtonText}
        </Button>
      </Stack>
      <Box css={(theme) => ({ padding: theme.spacing(2) })}>
        <AbxDataGrid
          rows={rows}
          height="400px"
          rowCount={count}
          columns={columns}
          loading={isLoading}
          rowSelection={false}
          disableColumnPinning
          onRowsScrollEnd={handleScrollEnd}
          slots={{
            loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
          }}
        />
      </Box>
    </Box>
  );
};
