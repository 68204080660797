(function () {
  angular
    .module("akitabox.core")
    .run(runBlock)
    .run(attachModels)
    .run(attachPatterns)
    .run(attachEnvService)
    .run(attachFeatureFlags)
    .run(attachVersion)
    .run(attachConstants);

  /* @ngInject */
  function attachModels($rootScope, models) {
    $rootScope.MODEL = models;
  }

  function attachPatterns($rootScope, patterns) {
    var PATTERN = {};
    var keys = Object.keys(patterns);
    for (var i = 0; i < keys.length; ++i) {
      var key = keys[i];
      PATTERN[key] = new RegExp(patterns[key], "i");
    }
    $rootScope.PATTERN = PATTERN;
  }

  function attachEnvService($rootScope, EnvService) {
    $rootScope.env = EnvService;
  }

  /* @ngInject */
  function attachFeatureFlags($rootScope, FeatureFlagService) {
    $rootScope.isEnabled = FeatureFlagService.isEnabled;
  }

  /* @ngInject */
  function attachVersion($rootScope, APP_COMMIT) {
    $rootScope.APP_COMMIT = APP_COMMIT;
  }

  /* @ngInject */
  function attachConstants($rootScope, COMPONENT_STATE) {
    $rootScope.COMPONENT_STATE = COMPONENT_STATE;
  }

  /* @ngInject */
  function runBlock($timeout, ShadowService) {
    // Extend angular
    angular.debounce = debounce;
    angular.isEmpty = isEmpty;
    angular.isBoolean = isBoolean;

    // Set up Google Analytics if we're in prod
    ShadowService.init();

    /**
     * @description
     * Returns a function that, as long as it continues to be invoked, will not be triggered
     *
     * @param {Function}  func          Function to be called
     * @param {Integer}   delay         Milliseconds to delay invoking function
     * @param {Boolean}   immediate     Trigger the function on the leading edge
     */
    function debounce(func, delay, immediate) {
      var timeout;
      return function () {
        var context = this;
        var args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        };
        var callNow = immediate && !timeout;
        $timeout.cancel(timeout);
        timeout = $timeout(later, delay);
        if (callNow) {
          func.apply(context, args);
        }
      };
    }

    /**
     * @description
     * Determines if a value is undefined, null, blank, or all of its values are empty
     *
     * @param  {*}         Value reference to check
     * @return {Boolean}   True if value is empty
     */
    function isEmpty(value, deep) {
      if (!angular.isDefined(deep)) deep = true;
      if (angular.isDefined(value)) {
        if (typeof value === "boolean") {
          return false;
        } else if (angular.isNumber(value)) {
          return !isFinite(value);
        } else if (angular.isString(value)) {
          return value.trim().length === 0;
        } else if (angular.isArray(value)) {
          return value.length === 0;
        } else if (angular.isDate(value)) {
          return false;
        } else if (angular.isObject(value)) {
          if (!angular.equals(value, {})) {
            var keys = Object.keys(value);
            if (!deep) return keys.length === 0;
            var empty = true;
            for (var i = 0; i < keys.length; ++i) {
              if (!isEmpty(value[keys[i]])) {
                empty = false;
                break;
              }
            }
            return empty;
          }
        }
      }
      return true;
    }

    function isBoolean(value) {
      return typeof value === "boolean";
    }
  }
})();
