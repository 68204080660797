import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Refresh } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const DataGridPagination = ({
  handleRefresh,
}: {
  handleRefresh: () => void;
}) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: "5%" }}>
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        showFirstButton
        showLastButton
      />
      <IconButton onClick={handleRefresh}>
        <Refresh />
      </IconButton>
    </div>
  );
};

export default DataGridPagination;
