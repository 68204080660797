(function () {
  angular.module("akitabox.planView").component("abxMarkupWidthSelector", {
    bindings: {
      onStrokeWidthSelect: "<?abxOnStrokeWidthSelect",
    },
    controller: AbxMarkupWidthSelectorController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-dropdowns/markup-width-selector/markup-width-selector.component.html",
  });

  function AbxMarkupWidthSelectorController(abxMarkupTools, ShadowService) {
    var self = this;
    var GA_CATEGORY = "markup";

    var isEraserSelected = abxMarkupTools.activeTool === "EraserTool";
    // Attributes
    self.sizeList = generateWidthSize(9).reverse();
    if (isEraserSelected) {
      self.currentlySelectedIndex = self.sizeList.indexOf(
        abxMarkupTools.eraserStrokeWidth
      );
    } else {
      self.currentlySelectedIndex = self.sizeList.indexOf(
        abxMarkupTools.strokeWidth
      );
    }

    // Function
    self.selectSize = selectSize;
    self.getSizeClass = getSizeClass;
    self.$onInit = init;

    function init() {
      self.selectSize(self.currentlySelectedIndex);
    }

    function selectSize(index) {
      var event = {};
      if (self.currentlySelectedIndex !== index) {
        // send event with the newly selected size
        ShadowService.sendEvent(
          GA_CATEGORY,
          "select-width",
          self.sizeList[index],
          null
        );
      }
      self.currentlySelectedIndex = index;
      self.selected = false;
      var width = self.sizeList[index];
      event.strokeWidth = width;
      if (isEraserSelected) {
        // set eraser stroke width
        abxMarkupTools.eraserStrokeWidth = width;
      } else {
        // set tool stroke width
        abxMarkupTools.strokeWidth = width;
      }
      if (self.onStrokeWidthSelect) {
        self.onStrokeWidthSelect(event);
      }
    }

    function getSizeClass(index) {
      return "size-" + (self.sizeList.length - index);
    }

    /**
     * Function that generates a mathematical curve.
     * Each value on the curve corresponds to the width size.
     *
     * When adjusting the number of possible widths, adjust the value in
     * the scss file.
     *
     * @param {Number} sizeCount  Total number of widths
     * @returns {Array} return a list of width values
     */
    function generateWidthSize(sizeCount) {
      var GOLDEN_RATIO = 1.61803398875;
      var sizeList = [];

      for (var i = 0; i < sizeCount; i++) {
        // Defined a value for each size
        var widthSize = Math.pow(1 * GOLDEN_RATIO, i - 1);
        sizeList.push(widthSize);
      }

      return sizeList;
    }
  }
})();
