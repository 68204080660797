import { ListItem, Typography, Box } from "@mui/material";
import { FunctionComponent } from "react";

import { AttachmentDialogTestids } from "../AttachmentDialog";

export interface EmptyItemProps {
  className?: string;
}

/**
 * Renders a list item with the empty text
 * @param `EmptyItemProps`
 * @returns FunctionComponent
 */
export const EmptyItem: FunctionComponent<EmptyItemProps> = ({ className }) => {
  return (
    <ListItem className={className}>
      <Box>
        {/* empty list text */}
        <Typography
          variant="body2"
          data-testid={AttachmentDialogTestids.FileListEmpty}
        >
          No files to show
        </Typography>
      </Box>
    </ListItem>
  );
};
