(function () {
  /**
   * @ngdoc component
   * @name abxUnplacedPins
   *
   * @param {Boolean} [isOpenOnInitUnplacedPins] - override the isOpen property on initialization
   * @param {Boolean} [isOpenOnInitMarkupTool] - override the isOpen property on initialization
   * @param {Boolean} [disabledUnplacedPins] - close and disable component
   * @param {Boolean} [disabledMarkupTool] - close and disable component
   *
   * @description
   * Tab for unplaced pins
   */
  angular.module("akitabox.planView").component("abxDrawerSidebar", {
    bindings: {
      isOpenOnInitMarkupTool: "<?abxIsOpenOnInitMarkupTool",
      disabledMarkupTool: "<?abxDisabledMarkupTool",

      isOpenOnInitUnplacedPins: "<?abxIsOpenOnInitUnplacedPins",
      disabledUnplacedPins: "<?abxDisabledUnplacedPins",
      onPlacePin: "&abxOnPlacePin",
      hasUnplacedPins: "<abxHasUnplacedPins",
      unplacedPins: "<abxUnplacedPins",
      getPinIcon: "<abxGetPinIcon",
    },
    controller: AbxDrawerSidebarController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/drawer-sidebar/drawer-sidebar.component.html",
  });

  function AbxDrawerSidebarController(
    // Services
    OrganizationService
  ) {
    var self = this;

    self.organization = OrganizationService.getCurrent();
  }
})();
