(function () {
  angular
    .module("akitabox.ui.dialogs.bulkAdd.relationships")
    .factory("BulkAddRelationshipsDialog", BulkAddRelationshipsDialog);

  /* @ngInject */
  function BulkAddRelationshipsDialog($log, $mdDialog, $mdMedia, $q) {
    const modal = {
      templateUrl:
        "app/core/ui/dialogs/bulk-add/relationships/bulk-add-relationships.dialog.html",
      controller: "BulkAddRelationshipsDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    return { show: show };

    /**
     * Show bulk add dialog for assets&rooms relationships, required params:
     * - `options.locals.model`
     * - `options.locals.selectedItems`
     * - `options.locals.building`
     * - `options.locals.floor`
     *
     * @param {Object} options Dialog options
     * @return {Promise<Object|Error>} Promise that resolves with the edited items
     */
    function show(options) {
      if (
        !options ||
        !options.locals ||
        !options.locals.model ||
        !options.locals.selectedItems ||
        !options.locals.building ||
        !options.locals.floor ||
        (options.locals.model !== "assets" && options.locals.model !== "rooms")
      ) {
        $log.error(
          `
          BulkAddRelationshipsDialog: you must pass in model, selectedItems, building and floor via locals.
          The model param only must be either "assets" or "rooms"
          `
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }

      return $mdDialog.show(angular.extend({}, modal, options));
    }
  }
})();
