(function () {
  angular
    .module("akitabox.ui.dialogs.document.delete")
    .controller(
      "DeleteDocumentDialogController",
      DeleteDocumentDialogController
    );

  /* @ngInject */
  function DeleteDocumentDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    DocumentService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since documents is passed in through locals
    self.multiple = self.documents ? self.documents.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteDocument = deleteDocument;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete documents
     */
    function deleteDocument() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.documents.length) * 100;
      var promiseArray = [];

      // Delete the documents
      for (var i = 0; i < self.documents.length; i++) {
        promiseArray[i] = DocumentService.remove(
          self.documents[i].building,
          self.documents[i]._id
        )
          // when we delete a document, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.documents[storedI];
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedDocumentIDs) {
          $mdDialog.hide(deletedDocumentIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
