import { IStateService, IState } from "angular-ui-router";
import { FunctionComponent, useState, useEffect } from "react";
import {
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import {
  useCreateAngularApp,
  AngularAppContextType,
  AngularAppContext,
} from "./angularApp";
import type { Router } from "@remix-run/router";
import { ShowAngularApp } from "./ShowAngularApp";
import { WebClientApp } from "../WebClientApp";
import { getRouteFromAngularState } from "./angularUiRouterUtils";
import { WebClientSWRConfig } from "../WebClientSWRConfig";

/**
 * Top level initialization component responsible for setting up the angularjs
 * application as well as the react router.
 *
 * AngularJS routes will result in the angularjs app being displayed, and
 * react routes will hide the angularjs app. This allows the angularjs app
 * to remain unaware of the react routes. Additionally, the react app can
 * display a 404 page at the right times since it is aware of the angularjs
 * routes.
 */
export const LegacyAwareRouter: FunctionComponent<{
  reactRoutes: JSX.Element[];
}> = ({ reactRoutes }) => {
  const [isLoading, setIsLoading] = useState(true);
  // react router instance to be used by the application.
  const [router, setRouter] = useState<Router | null>(null);
  // bootstrap the angularjs app
  const injector = useCreateAngularApp();
  // angular app context to share the injector with the rest of the app.
  // this allows react to hook into angularjs injectables.
  const [angularContext, setAngularContext] = useState<AngularAppContextType>({
    injector,
  });

  // This effect will run on mount, and will run again once the angularjs
  // application is bootstrapped.
  useEffect(() => {
    setAngularContext({ injector });
    if (!injector) {
      return;
    }
    const $state = injector.get<IStateService>("$state");
    const states: IState[] = injector.get<any>("Router").getStates();
    const angularRoutes = states
      .map((state) => getRouteFromAngularState(state, $state))
      .filter((url) => url !== "")
      .map((url) => {
        return (
          <Route
            loader={async ({ request }) => {
              const { pathname, search } = new URL(request.url);
              /**
               * This loader fires off the angularjs navigation and waits for
               * it to finish before displaying the angular app. This allows for
               * displaying the react app and its progress bar while transitioning
               * into an angularjs app.
               *
               * The entire load is only awaited if a route transition starts
               * in the angular app due to this location change.
               */
              injector
                .get("$location")
                .path(`${pathname}`)
                .search(
                  search && search.startsWith("?") ? search.substring(1) : ""
                );
              // enqueue a rootscope digest to give the angular app a chance
              // to respond to this change
              await new Promise<void>((resolve) =>
                setTimeout(() => {
                  injector.get("$rootScope").$digest();
                  resolve();
                }, 0)
              );
              // after digest, determine if we are waiting or loading
              return new Promise<void>((resolve) => {
                setTimeout(() => {
                  /**
                   * We just resolve the loader now if the navigation has
                   * been superceeded. This indicates some kind of redirect
                   * happened in the angular app.
                   */
                  if (window.location.href !== request.url) {
                    return resolve();
                  }

                  // make sure we're not waiting for an event that will never come
                  if (injector.get<any>("$rootScope").showProgressBar) {
                    injector
                      .get("$rootScope")
                      .$on("abxHideProgressBar", () => resolve());
                  } else {
                    resolve();
                  }
                }, 50);
              });
            }}
            path={url}
            element={<ShowAngularApp />}
            key={url}
          />
        );
      });
    // initialize the react router now that we have all of the
    // angular routes.
    setRouter(
      createBrowserRouter(
        createRoutesFromElements(
          <Route
            path="/"
            element={
              <WebClientApp>
                <Outlet />
              </WebClientApp>
            }
            id="root"
          >
            {/*
            angular routes come first, taking priority. See the
            reactRouting.provider module to control whether angularjs
            or react controls a given route based on a feature flag.
            
            **Do not switch the order of these spreads**, if your react route
            is not showing, the angularjs app is probably displaying an empty
            app over-top of it because the route has been registered in both
            angularjs and react-client.
            */}
            {...angularRoutes}
            {...reactRoutes}
          </Route>
        )
      )
    );
    setIsLoading(false);
  }, [injector, reactRoutes]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const splash = document.getElementById("splash-screen");
    if (splash) {
      if (splash.remove) {
        splash.remove();
      } else if (splash.parentNode !== null) {
        splash.parentNode.removeChild(splash);
      } else {
        splash.setAttribute("display", "none");
      }
    }
  }, [isLoading]);

  return (
    <AngularAppContext.Provider value={angularContext}>
      <WebClientSWRConfig>
        {injector && router && <RouterProvider router={router} />}
      </WebClientSWRConfig>
    </AngularAppContext.Provider>
  );
};
