(function () {
  angular
    .module("akitabox.ui.dialogs.bulkEdit.pinField")
    .controller(
      "BulkEditPinFieldDialogController",
      BulkEditPinFieldDialogController
    );

  /* @ngInject */
  function BulkEditPinFieldDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    RoomService,
    ToastService,
    AssetService,
    // Root Constants
    models
  ) {
    // Constants
    const FIELD_RESTRICTIONS = [
      "is_hidden",
      "is_required",
      "is_unique",
      "is_not_editable",
    ];
    const UNSUPPORTED_DATA_TYPES = ["tree", "document_array", "tag_filter"];

    this.loading = false;
    this.saving = false;
    this.retry = false;
    this.determinateValue = 0;
    this.determinateIncrement = 0;
    this.fieldsInCommon = [];
    this.data = { values: {} };
    this.toBeCleared = {};
    this.payload = [];
    this.pin = {
      building: this.buildingId,
      values: {},
    };
    this.isSavingFloors = false;
    this.isSavingRooms = false;
    this.isSavingInstallationDates = false;
    this.isSavingPinValues = false;
    this.floor = null;
    this.hasFloorChanged = false;
    this.shouldClearFloor = false;
    this.room = null;
    this.installationDate = null;
    this.hasInstallationDateChanged = false;
    this.installationDateValid = true;
    this.hasRoomChanged = false;
    this.shouldClearRoom = false;

    // =================
    // Private Functions
    // =================

    // Pointers for index reference when doing bulk action
    let fieldsIndex = 0;
    let itemsIndex = 0;

    /**
     * Takes a string and returns a lowercase version of that string.
     * @param {String} string - The string to be converted to lowercase.
     * @returns {String} The lowercase converted string.
     */
    const lower = (string) => string.toLowerCase();

    /**
     * After loading all common fields, loops over them and update
     * each field by setting an initial value of empty
     */
    const initValues = () => {
      const getEmptyPinValue = (pinField) => ({
        building: pinField.building,
        pinField: pinField._id,
        value: null,
      });

      for (const common of this.fieldsInCommon) {
        const temp = getEmptyPinValue(common);
        this.pin.values[common._id] = temp;
        this.toBeCleared[common._id] = false;
      }
    };

    /**
     * Sort common fields by lowest order among each custom fields orders
     * @param {Array.<Array.<Object>>} itemsFields - An array of arrays of objects (fields).
     */
    const sortCommonFields = (itemsFields) => {
      let lowestOrderInFields = {};

      for (const itemFields of itemsFields) {
        lowestOrderInFields = itemFields.reduce((accumulator, current) => {
          const _order = current.order;
          const _name = lower(current.name);

          if (!Object.prototype.hasOwnProperty.call(accumulator, _name)) {
            accumulator[_name] = _order;
          } else {
            accumulator[_name] =
              accumulator[_name] > _order ? _order : accumulator[_name];
          }

          return accumulator;
        }, {});
      }

      this.fieldsInCommon = this.fieldsInCommon.map((common) => {
        const _name = lower(common.name);
        common.order = lowestOrderInFields[_name];
        return common;
      });
    };

    /**
     * Loops over the list of selected items removing items that have same pin type.
     * Then, returns all custom fields from the remaining items in the list.
     * @returns {Array.<Array.<Object>>} An array of arrays of objects (fields).
     */
    const getFieldsFromItems = () => {
      const uniquePinTypes = this.selectedItems.reduce(
        (accumulator, current) => {
          if (accumulator.length === 0) {
            // First time, add current
            accumulator.push(current);
            return accumulator;
          }

          if (
            accumulator.every(
              (accumulated) => accumulated.pinType._id !== current.pinType._id
            )
          ) {
            // Only add current if "pinType._id" was not previously added
            accumulator.push(current);
          }

          return accumulator;
        },
        []
      );

      return uniquePinTypes.map((item) => {
        const pinType = this.pinTypes.find(
          (pin) => item.pinType.name === pin.name
        );

        return pinType ? pinType.fields : [];
      });
    };

    /**
     * Loops over the list of fields for each selected items.
     * Groups all mutual values from each enum field.
     * Then, it updates all enum common fields to have the mutual values.
     * @param {Array.<Array.<Object>>} itemsFields - An array of arrays of objects (fields).
     */
    const updateCommonFieldsEnums = (itemsFields) => {
      const getEnums = (fields) =>
        fields.filter((field) => field.data_type === "enum");

      const mutualEnumFieldValues = {};

      for (const itemFields of itemsFields) {
        const enumsFields = getEnums(itemFields);

        for (const enumField of enumsFields) {
          const fieldName = lower(enumField.name);
          const fieldValues = enumField.acceptable_enum_values;

          if (
            !Object.prototype.hasOwnProperty.call(
              mutualEnumFieldValues,
              fieldName
            )
          ) {
            // First time, add enum field values
            mutualEnumFieldValues[fieldName] = [...fieldValues];
            continue;
          }

          // Overrides with values that are mutual
          mutualEnumFieldValues[fieldName] = mutualEnumFieldValues[
            fieldName
          ].filter((v) => fieldValues.includes(v));
        }
      }

      this.fieldsInCommon = this.fieldsInCommon.map((common) => {
        if (common.data_type === "enum") {
          const fieldName = lower(common.name);
          common.acceptable_enum_values = mutualEnumFieldValues[fieldName];
        }

        return common;
      });
    };

    /**
     * Updates all common fields restrictions in a way that, if any of the fields has the
     * restriction set to true, the corresponding common field restriction will be set to true as well.
     * @param {Array.<Array.<Object>>} itemsFields - An array of arrays of objects (fields).
     */
    const updateCommonFieldsRestrictions = (itemsFields) => {
      this.fieldsInCommon = this.fieldsInCommon.map((common) => {
        const restrictions = FIELD_RESTRICTIONS.reduce(
          (previous, restriction) => {
            previous[restriction] = itemsFields.some((itemFields) => {
              return itemFields.some((itemField) => {
                const sameField =
                  itemField.name === common.name &&
                  itemField.data_type === common.data_type;

                if (restriction === "is_not_editable") {
                  // The "is_editable" restriction in fields works backwards
                  return sameField && !itemField.is_editable;
                }

                return sameField && itemField[restriction];
              });
            });
            return previous;
          },
          {}
        );

        const newCommonField = {
          ...common,
          ...restrictions,
          is_editable: !restrictions.is_not_editable,
        };

        // Don't use "is_not_editable"
        delete newCommonField.is_not_editable;

        return newCommonField;
      });
    };

    /**
     * Loops over the list of fields for each selected items. Sets the fields in common based
     * on all items fields that has same name, data_type and acceptable_enum_values (for enums).
     * @param {Array.<Array.<Object>>} itemsFields - An array of arrays of objects (fields).
     */
    const setCommonFields = (itemsFields) => {
      for (const itemFields of itemsFields) {
        if (this.fieldsInCommon.length === 0) {
          // First time, add fields
          this.fieldsInCommon = itemFields;
          continue;
        }

        this.fieldsInCommon = itemFields.filter((itemField) =>
          this.fieldsInCommon.some((common) => {
            const sameName = itemField.name === common.name;
            const sameDataType = itemField.data_type === common.data_type;

            if (itemField.data_type === "enum") {
              const contains = (a, b) =>
                Array.isArray(a) &&
                Array.isArray(b) &&
                a.some((v) => b.some((_v) => v === _v));

              const hasSomeAcceptableValues = contains(
                itemField.acceptable_enum_values,
                common.acceptable_enum_values
              );

              // Only add the enum fields that has same name, data_type and at least one of acceptable_enum_values
              return sameName && sameDataType && hasSomeAcceptableValues;
            }

            // Only add the fields that has same name and data_type
            return sameName && sameDataType;
          })
        );
      }
    };

    /**
     * If model is `rooms`, return RoomService, otherwise return AssetService
     * @returns {Object} `RoomService` or `AssetService`
     */
    const getService = () => {
      if (this.model === "rooms") {
        return RoomService;
      }

      return AssetService;
    };

    /**
     * Gets the fields from the selected items, sets the common fields, updates the restrictions
     * and enums of the common fields, sorts the common fields and set initial values
     */
    const init = () => {
      const fields = getFieldsFromItems();
      setCommonFields(fields);
      updateCommonFieldsRestrictions(fields);
      updateCommonFieldsEnums(fields);
      sortCommonFields(fields);
      initValues();
    };

    /**
     * Takes an array of objects and, for each object, makes a call to model's service `getAllValues` method.
     * When all calls are done, it updates all selected items by setting pin values into it according to result set
     * @param {Array.<Object>} itemsWithoutValues - An array of items that doesn't have `values`.
     */
    const addValuesInItems = (itemsWithoutValues) => {
      const service = getService();

      let promises = [];
      for (const field of itemsWithoutValues) {
        promises.push(service.getAllValues(this.buildingId, field._id));
      }

      this.loading = true;

      $q.all(promises).then((resultSet) => {
        this.selectedItems = this.selectedItems.map((selectedItem) => {
          if (selectedItem.values) return selectedItem;

          return {
            ...selectedItem,
            values: resultSet.shift(),
          };
        });

        this.loading = false;
        init();
      });
    };

    /**
     * If there are items without pin values, add values to those items, otherwise initialize the component
     */
    const onInit = () => {
      const itemsWithoutValues = this.selectedItems.filter(
        (field) => !field.values
      );

      if (itemsWithoutValues.length > 0) {
        addValuesInItems(itemsWithoutValues);
      } else {
        init();
      }
    };

    /**
     * It returns a number that represents the percentage of the progress bar.
     * @returns {Number} Increment percentage
     */
    const getDeterminateIncrement = () => {
      const amountOfItems = this.selectedItems.length;
      const amountOfChanges = Object.keys(this.data.values).length;
      const total = amountOfItems * amountOfChanges;
      return (1 / total) * 100;
    };

    /**
     * Aggregates all common fields that were changed to the
     * corresponding pin field in the current model pin type
     * and returns a data with references for models, fields and values
     * @returns {Array.<Object>} An array of objects.
     */
    const getAggregatedPayload = () => {
      const result = [];

      for (const item of this.selectedItems) {
        // At this point, "this.data.values" maps all common fields that were changed (and it's values)
        // It is a key/object pair value that looks like this: { [key: string]: { dataType: string, value: string } }
        // The keys corresponds to the common fields' names

        const aggregatedFields = Object.keys(this.data.values).reduce(
          (accumulator, commonFieldName) => {
            const commonField = this.data.values[commonFieldName];
            const commonFieldDataType = commonField.dataType;

            // Finding pin field in current item pin type based on current common field
            const pin = this.pinTypes.find(
              (pin) => item.pinType.name === pin.name
            );
            const pinField = pin.fields.find((field) => {
              const fieldName = lower(field.name);
              const fieldDataType = field.data_type;

              return (
                commonFieldName === fieldName &&
                commonFieldDataType === fieldDataType
              );
            });

            // Finding field value based on current common field
            const bffItem = item.values[pinField.name];

            // Make sure pinTypes were obtained by calling bff
            // ListView is using BFF but PlaView is not using
            const value = bffItem ? bffItem._id : item.values[pinField._id];

            accumulator.push({
              ...commonField,
              itemValueId: value._id || value,
              pinField: pinField,
            });

            return accumulator;
          },
          []
        );

        result.push({
          id: item._id,
          fields: aggregatedFields,
        });
      }

      return result;
    };

    /**
     * Sets the fieldsIndex to 0, increments the itemsIndex by 1
     * and checks if the payload has more items to process.
     * @returns {Boolean} A boolean value.
     */
    const hasMoreItems = () => {
      fieldsIndex = 0;
      itemsIndex += 1;
      return !!this.payload[itemsIndex];
    };

    /**
     * Increments the fieldsIndex by 1 and checks if the payload has more fields to process.
     * @returns {Boolean} A boolean value.
     */
    const hasMoreFieldsInItem = () => {
      fieldsIndex += 1;
      return !!this.payload[itemsIndex].fields[fieldsIndex];
    };

    /**
     * It increments the determinateValue by the determinateIncrement.
     */
    const incrementProgress = () => {
      this.determinateValue += this.determinateIncrement;
    };

    /**
     * Returns the name of the model in either singular or plural form.
     * Depends on the number of selected items.
     * @param {Boolean} [forcePlural=false] - If true, returns the plural version
     * @returns {String} The model name of the selected items.
     */
    const getModelName = (forcePlural = false) => {
      const plural = this.selectedItems.length > 1 || forcePlural;
      const form = plural ? "PLURAL" : "SINGULAR";

      if (this.model === "rooms") {
        return models.ROOM[form];
      }

      return models.ASSET[form];
    };

    /**
     * Ends the bulk editing fields workflow:
     * - Resets index pointers
     * - Resets loading progress
     * - Shows toast message
     * - Hide/close the dialog
     */
    const done = () => {
      fieldsIndex = 0;
      itemsIndex = 0;

      this.saving = false;
      this.determinateValue = 0;
      this.determinateIncrement = 0;
      this.isSavingPinValues = false;

      ToastService.showSimple(
        `Successfully edited ${this.selectedItems.length} ${getModelName()}`
      );

      $mdDialog.hide({ model: this.model, changes: this.data.values });
    };

    const bulkEditFloor = (item) => {
      const method =
        this.model === "rooms"
          ? RoomService.updateFloor
          : AssetService.updateFloor;

      const changes = {};
      if (this.shouldClearFloor) {
        changes.level = null;
      } else if (this.floor) {
        changes.level = this.floor._id;
      }

      const shouldEditFloor = Object.keys(changes).length > 0;
      if (!shouldEditFloor) return $q.resolve();

      const request = method(this.buildingId, item._id, changes);
      return request.then(() => {
        itemsIndex++;
        if (itemsIndex < this.selectedItems.length) {
          const nextItem = this.selectedItems[itemsIndex];
          return bulkEditFloor(nextItem);
        } else {
          itemsIndex = 0;
          this.isSavingFloors = false;
        }
      });
    };

    const bulkEditRoom = (item) => {
      const changes = {};
      if (this.shouldClearRoom) {
        changes.room = null;
      } else if (this.room) {
        changes.room = this.room._id;
      }

      const shouldEditRoom = Object.keys(changes).length > 0;
      if (!shouldEditRoom) return $q.resolve();

      const request = AssetService.updateRoom(
        this.buildingId,
        item._id,
        changes
      );
      return request.then(() => {
        itemsIndex++;
        if (itemsIndex < this.selectedItems.length) {
          const nextItem = this.selectedItems[itemsIndex];
          return bulkEditRoom(nextItem);
        } else {
          itemsIndex = 0;
          this.isSavingRooms = false;
        }
      });
    };

    const bulkEditInstallationDate = (item) => {
      const changes = {};
      if (this.shouldClearInstallationDate) {
        changes.installation_date = null;
      } else if (this.installationDate) {
        const installdate = new Date(this.installationDate, 0, 2);
        changes.installation_date = installdate;
      }

      const shouldEditInstallationDate = Object.keys(changes).length > 0;
      if (!shouldEditInstallationDate) return $q.resolve();

      const request = AssetService.updateById(
        this.buildingId,
        item._id,
        changes
      );
      return request.then(() => {
        itemsIndex++;
        if (itemsIndex < this.selectedItems.length) {
          const nextItem = this.selectedItems[itemsIndex];
          return bulkEditInstallationDate(nextItem);
        } else {
          itemsIndex = 0;
          this.isSavingInstallationDates = false;
        }
      });
    };

    /**
     * Takes an item (from model) and a field. Then, update the field's value on the model.
     * Calls itself again (recursively) until all items and fields are processed.
     * @param {Object} item - The current item being processed
     * @param {Object} field - The field object that contains values to be updated
     */
    const bulkEditAction = (item, field) => {
      const method =
        this.model === "rooms"
          ? RoomService.updateValueByDataType
          : AssetService.updateValueByDataType;
      const request = method(
        this.buildingId,
        field.dataType,
        item.id,
        field.itemValueId,
        field.value
      );

      const handleError = (error) => {
        this.retry = true;
        this.saving = false;
        ToastService.showError(error);
      };

      const next = () => {
        const nextItem = this.payload[itemsIndex];
        const nextField = nextItem.fields[fieldsIndex];
        bulkEditAction(nextItem, nextField);
      };

      const process = () => {
        incrementProgress();

        if (hasMoreFieldsInItem()) {
          next();
        } else if (hasMoreItems()) {
          next();
        } else {
          done();
        }
      };

      request.then(process).catch(handleError);
    };

    /**
     * It returns true if there are changes and all the changes are valid
     * @returns {Boolean} A boolean value.
     */
    const hasValidChanges = () => {
      const values = Object.keys(this.data.values);
      const hasChanges = values.length > 0;
      const changesAreValid = values.every(
        (value) => this.data.values[value].valid
      );

      return hasChanges && changesAreValid;
    };

    const updateFloors = () => {
      if (!this.hasFloorChanged) {
        return $q.resolve();
      }
      this.isSavingFloors = true;
      return bulkEditFloor(this.selectedItems[itemsIndex])
        .then(() => {
          itemsIndex = 0;
          this.isSavingFloors = false;
        })
        .catch((error) => {
          this.retry = true;
          return ToastService.showError(error);
        });
    };

    const updateRooms = () => {
      if (this.model === "rooms" || !this.hasRoomChanged) {
        return $q.resolve();
      }
      this.isSavingRooms = true;
      return bulkEditRoom(this.selectedItems[itemsIndex])
        .then(() => {
          itemsIndex = 0;
          this.isSavingRooms = false;
        })
        .catch((error) => {
          this.retry = true;
          return ToastService.showError(error);
        });
    };

    const updateInstallationDates = () => {
      if (
        this.model === "installationDate" ||
        !this.hasInstallationDateChanged
      ) {
        return $q.resolve();
      }
      this.isSavingInstallationDates = true;
      return bulkEditInstallationDate(this.selectedItems[itemsIndex])
        .then(() => {
          itemsIndex = 0;
          this.isSavingRooms = false;
        })
        .catch((error) => {
          this.retry = true;
          return ToastService.showError(error);
        });
    };

    const updateFields = () => {
      if (!hasValidChanges()) {
        return done();
      }
      this.isSavingPinValues = true;
      // Custom fields
      this.payload = getAggregatedPayload();
      this.determinateIncrement = getDeterminateIncrement();
      // Calling bulkEditAction with first item and field
      bulkEditAction(this.payload[0], this.payload[0].fields[0]);
    };

    /**
     * The function is called when the user clicks the "Save" button. It sets the `saving` property to
     * `true` and then calls the `bulkEditAction` function with the first item and field in the
     * payload
     */
    const onSave = () => {
      this.saving = true;
      updateFloors()
        .then(updateRooms)
        .then(updateInstallationDates)
        .then(updateFields);
    };

    /**
     * It takes a fieldId and a newValue, and updates the pin.values object with the new value
     * @param {String} fieldId - The id of the field you want to update.
     * @param {Object} newValue - The new value of the field.
     */
    const updatePinFieldValues = (fieldId, newValue) => {
      this.pin.values[fieldId] = {
        ...this.pin.values[fieldId],
        value: newValue,
      };
    };

    /**
     * Set the value to the value of the field
     * @param {Object} $event - The event object that was triggered.
     * @param {Object} changed - The field that was changed.
     */
    const onValueChange = ($event, changed) => {
      let value = $event.model;
      const fieldName = lower(changed.name || changed.value);
      const fieldId = changed._id;

      if (Object.prototype.hasOwnProperty.call(this.data.values, fieldName)) {
        delete this.data.values[fieldName];
      }

      updatePinFieldValues(fieldId, value);
      this.toBeCleared[fieldId] = false;
      this.data.values[fieldName] = {
        dataType: changed.data_type,
        value: value,
        valid: !$event.invalid,
      };
    };

    /**
     * Marks/unmarks the corresponding field to be cleared (or not).
     * @param {Object} changed - The field that was changed.
     */
    const onClear = (changed) => {
      const fieldId = changed._id;
      const fieldName = lower(changed.name);

      if (Object.prototype.hasOwnProperty.call(this.data.values, fieldName)) {
        delete this.data.values[fieldName];
      }

      if (!this.toBeCleared[fieldId]) return;

      updatePinFieldValues(fieldId, null);
      this.data.values[fieldName] = {
        dataType: changed.data_type,
        value: null,
        valid: true,
      };
    };

    const onFloorChange = ($event) => {
      if ($event.model || $event.clearInput) {
        this.floor = $event.model || null;
        this.hasFloorChanged = this.floor !== null;
        this.shouldClearFloor = false;
      }
    };

    const onClearFloor = () => {
      this.floor = null;
      this.hasFloorChanged = !!this.shouldClearFloor;
    };

    const onRoomChange = ($event) => {
      if ($event.model || $event.clearInput) {
        this.room = $event.model || null;
        this.hasRoomChanged = this.room !== null;
        this.shouldClearRoom = false;
      }
    };

    const onInstallationDateChange = ($event) => {
      const newDate = $event.model || null;
      if (newDate < 1000 || newDate > 9999) {
        this.installationDateValid = false;
        return;
      }
      if ($event.model || $event.clearInput) {
        this.installationDate = newDate;
        this.installationDateValid = true;
        this.hasInstallationDateChanged = this.installationDate !== null;
        this.shouldClearInstallationDate = false;
      }
    };

    const onClearRoom = () => {
      this.room = null;
      this.hasRoomChanged = !!this.shouldClearRoom;
    };

    const onClearInstallationDate = () => {
      this.installationDate = null;
      this.installationDateValid = true;
      this.hasInstallationDateChanged = !!this.shouldClearInstallationDate;
    };

    /**
     * When the user clicks the retry button, we set the retry flag to false, and the saving flag to
     * true. Then we call the bulkEditAction function with the same data that was used when the
     * function failed
     */
    const onRetry = () => {
      this.retry = false;
      this.saving = true;

      if (this.isSavingFloors) {
        return updateFloors().then(updateRooms).then(updateFields);
      } else if (this.isSavingRooms) {
        return updateRooms().then(updateFields);
      } else if (this.isSavingPinValues) {
        // Calling bulkEditAction with data at same state from last time (when failed)
        const currentItem = this.payload[itemsIndex];
        const currentField = currentItem.fields[fieldsIndex];
        bulkEditAction(currentItem, currentField);
      }
    };

    /**
     * If the data type of the field is not in the list of unsupported data types, then return true.
     * @param {Object} field - The field object that we're checking to see if it's supported.
     * @returns {Boolean} A boolean value.
     */
    const isFieldSupported = (field) =>
      UNSUPPORTED_DATA_TYPES.indexOf(field.data_type) < 0;

    const hasChanges = () => {
      return (
        this.hasFloorChanged ||
        this.hasRoomChanged ||
        (this.hasInstallationDateChanged && this.installationDateValid) ||
        hasValidChanges()
      );
    };

    // =================
    // Public Functions
    // =================

    this.save = onSave;
    this.$onInit = onInit;
    this.onClear = onClear;
    this.onRetry = onRetry;
    this.getModelName = getModelName;
    this.hasChanges = hasChanges;
    this.onValueChange = onValueChange;
    this.isFieldSupported = isFieldSupported;
    this.cancel = $mdDialog.cancel;
    this.onFloorChange = onFloorChange;
    this.onClearFloor = onClearFloor;
    this.onRoomChange = onRoomChange;
    this.onInstallationDateChange = onInstallationDateChange;
    this.onClearRoom = onClearRoom;
    this.onClearInstallationDate = onClearInstallationDate;
  }
})();
