angular
  .module("akitabox.ui.dialogs")
  .service("PinFieldFloorConfirmationDialog", PinFieldFloorConfirmationDialog);

/* @ngInject */
function PinFieldFloorConfirmationDialog($mdDialog, $mdMedia) {
  const modalOptions = {
    templateUrl:
      "app/core/ui/dialogs/pin-type/floor-edit-confirmation/pin-field-floor-confirm.dialog.template.html",
    controller: "PinFieldFloorConfirmationDialogController",
    controllerAs: "vm",
    bindToController: true,
    parent: angular.element(document.body),
    closeTo: angular.element(document.body),
    clickOutsideToClose: false,
    escapeToClose: false,
    focusOnOpen: false,
    multiple: true,
  };

  // Enable fullscreen mode while on mobile
  if ($mdMedia("sm") || $mdMedia("xs")) {
    modalOptions.fullscreen = true;
  }

  return { show };

  // ------------------------
  //   Public Functions
  // ------------------------

  /**
   * Show dialog
   *
   * @param { object } options Dialog options
   * @param { object } options.locals Locals for the dialog controller. See
   *    dialog controller for detailed documentation of locals.
   * @param { () => Promise<any> } options.onConfirmStay Callback invoked
   *    when the dialog is confirmed with the stay button. Reject to keep the dialog open,
   *    resolve to close the dialog and resolve the `show` promise
   *    with its result.
   * @param { () => Promise<any> } options.onConfirmLeave Callback invoked
   *    when the dialog is confirmed with the stay button. Reject to keep the dialog open,
   *    resolve to close the dialog and resolve the `show` promise
   *    with its result.
   */
  function show(options) {
    var dialogOptions = {
      ...modalOptions,
      ...options,
      locals: {
        ...options.locals,
      },
    };
    return $mdDialog.show(dialogOptions);
  }
}
