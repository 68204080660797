(function () {
  /**
   * @ngdoc component
   * @name abxPinSearch
   *
   * @param {Object} building - (Deprecated) Selected building.
   *    Building and floor are currently used to clear the search when we switch
   *    floorplans. This can be done using the current-search binding now.
   * @param {Object} currentSearch - Current search to be executed. Has two properties:
   *    currentSearch.singleSelect (an object) or currentSearch.multiSearch (a string).
   *    Updating this binding will fire the same callbacks as the user actually
   *    searching.
   * @param {Function} fetch - Callback to fetch pins based on a text query.
   * @param {Object} floor - (Deprecated) Selected floor.
   * @param {Function} onPinSearchItemSelect - Callback invoked when a room or
   *    asset is selected from the search results list.
   * @param {Function} onSearchIconClick - Callback to handle spyglass and close
   *    click, and enter keypress.
   * @param {Boolean} [disabled] Flag to disable component.
   *
   * @description
   * Component that presents a typeahead to users that can be used to quickly
   * filter for and select individual rooms or assets. Querying for results
   * is a responsibility of the parent component via the fetch binding.
   *
   * @callback onSearchIconClick
   * Callback invoked when the search icon is clicked (spyglass or close button)
   *    or when enter is pressed to execute a search.
   * @param {Object} $event
   * @param {String} $event.action - One of "close", "search"
   * @param {Object[]} $event.queryResults - The currently searched pins.
   * @param {String} $event.queryText - The current query text.
   *
   * @callback fetch
   * Callback invoked to fetch search results.
   * @param {String} queryText - The search query.
   * @return {Object[] | Promise<Object[]>} - The resulting pins from the query.
   *    Result pins must contain populated pinTypes.
   *
   * @callback onPinSearchItemSelect
   * Callback invoked to notify parent components of a specific pin selection
   * from the search results list.
   * @param {Object} $event
   * @param {Object} $event.pin - The selected pin (returned from fetch).
   */
  angular.module("akitabox.ui.components.pinSearch").component("abxPinSearch", {
    bindings: {
      building: "<abxBuilding",
      currentSearch: "<?abxCurrentSearch",
      floor: "<abxFloor",
      fetch: "&abxFetch",
      disabled: "<?abxDisabled",
      onPinSearchItemSelect: "&abxOnPinSearchItemSelect",
      onSearchIconClick: "&abxOnSearchIconClick",
    },
    controller: AbxPinSearchController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/pin-search/pin-search.component.html",
  });

  /* @ngInject */
  function AbxPinSearchController(
    $element,
    $q,
    PIN_SEARCH_MODES,
    CancellableService,
    ToastService,
    ServiceHelpers,
    Utils
  ) {
    var self = this;
    var debouncedFetch = angular.debounce(fetchSearchList, 300, false);
    var debouncedBlur = angular.debounce(handleBlur, 300, false);

    // Functions
    self.debouncedFetchSearchList = function () {
      self.loading = true;
      debouncedFetch();
    };

    self.debouncedBlur = function () {
      self.isFocused = false;
      debouncedBlur();
    };

    self.debouncedHandleSearchKeyUp = angular.debounce(
      handleSearchKeyUp,
      300,
      false
    );

    self.showSearchResults = showSearchResults;
    self.onSelect = onSelect;
    self.handleSpyglassClick = handleSpyglassClick;
    self.handleCloseClick = handleCloseClick;
    self.handleFocus = handleFocus;
    self.searchIsActive = searchIsActive;

    // Attributes
    self.isFocused = false;
    self.icon = "spyglass"; // choose between "spyglass" or "close"
    // mode: choose between DEFAULT, EDITING, or ACTIVELYSEARCHING;
    // DEFAULT mode: the input is cleared
    // EDITING mode: the user is typing in the input;
    // ACTIVELYSEARCHING mode: iff the user selected a pin, clicked the search icon, or hit enter
    self.mode = PIN_SEARCH_MODES.DEFAULT;
    self.revertMode = PIN_SEARCH_MODES.DEFAULT;
    self.pinSearchQuery = null;
    self.revertValue = null;

    self.MIN_QUERY_LENGTH = 1;
    self.pinCountsByType = {};

    // handle cancelling earlier requests if user fetches multiple times
    var CANCEL_REASON = {};
    var initPipeline = CancellableService.createPipeline(CANCEL_REASON);

    // =================
    // Life Cycle
    // =================
    self.$onChanges = function (changes) {
      if (changes.currentSearch) {
        if (!angular.isEmpty(self.currentSearch)) {
          if (angular.isString(self.currentSearch.multiSearch)) {
            executeSearchQuery(self.currentSearch.multiSearch);
          } else {
            selectPin(self.currentSearch.singleSelect);
          }
        }
      } else if (
        changes.floor &&
        !Utils.isSameModel(self.floor, changes.floor.previousValue)
      ) {
        resetSearchMode();
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Update attributes and notify the parent on focus
     */
    function handleFocus() {
      self.revertValue = angular.copy(self.pinSearchQuery);
      self.revertMode = angular.copy(self.mode);
      var attributes = {
        isFocused: true,
      };
      updateAttributes(attributes);
    }

    /**
     * Check if user pressed Enter key and simulate a spyglass click if they did
     *
     * @param {Object}  $event    $event object
     */
    function handleSearchKeyUp($event) {
      // the user pressed enter
      if ($event && ($event.which === 13 || $event.keyCode === 13)) {
        executeSearchQuery(self.pinSearchQuery).then(function () {
          document.activeElement.blur(); // force the input to lose focus to remove annoying blinking cursor
        });
      }
    }

    /**
     * @return true iff the search results box should be displayed
     */
    function showSearchResults() {
      if (!self.pinSearchQuery || !self.queriedPins) return false;

      return (
        self.isFocused && self.pinSearchQuery.length >= self.MIN_QUERY_LENGTH
      );
    }

    /**
     * Notify the the parent when a pin is selected from the dropdown list
     * @param {Object}  pin   pin that was chosen from the dropdown
     */
    function onSelect(pin) {
      const { pinType, building, _id: pinId } = pin;
      // Safety check to handle { _id } within building
      const buildingId = building._id || building;

      const PinService = ServiceHelpers.parsePinService(
        pinType || { protected_type: "Asset" }
      );

      // Using getById here to fetch extra data based on BFF response
      PinService.getById(
        buildingId,
        pinId,
        // Making sure we have all pin (asset || room) fields loaded
        {
          include_values: true,
        },
        {
          includePinType: true,
          includeFloor: true,
          includeRoom: true,
        }
      )
        .then((pin) => {
          self.onPinSearchItemSelect({ $event: { pin } });
        })
        .catch(ToastService.showError);
    }

    /**
     * Handle when the user clicks the spyglass icon
     */
    function handleSpyglassClick() {
      if (self.isFocused) {
        executeSearchQuery(self.pinSearchQuery);
      } else {
        $element.find("input").focus();
      }
    }

    /**
     * Handles controller cleanup and notifies the parent when the user clicks the close icon
     */
    function handleCloseClick() {
      if (self.disabled) {
        // this happens in plan view when a search is active and the user then moves the pin, so we need to short
        // circuit here
        return;
      }
      self.pinSearchQuery = null;
      self.revertValue = null;

      handlePinSearchQueryChange(self.pinSearchQuery).then(function () {
        // tell the parent
        self.onSearchIconClick({
          $event: { queryResults: self.queriedPins, action: "close" },
        });

        var attributes = {
          isFocused: false,
          icon: "spyglass",
          mode: PIN_SEARCH_MODES.DEFAULT,
        };
        updateAttributes(attributes);
      });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Executes on the given query. Notifies the parent of results
     * and updates the component's UI state.
     *
     * @return {Promise} That resolves when work is complete.
     */
    function executeSearchQuery(query) {
      return handlePinSearchQueryChange(query).then(function () {
        if (!self.pinSearchQuery) {
          // clear out the search
          self.onSearchIconClick({
            $event: { queryResults: self.queriedPins, action: "close" },
          });

          var emptySearchAttributes = {
            isFocused: false,
            icon: "spyglass",
            mode: PIN_SEARCH_MODES.DEFAULT,
          };

          updateAttributes(emptySearchAttributes);
        } else {
          self.onSearchIconClick({
            $event: {
              queryResults: self.queriedPins,
              action: "search",
              queryText: self.pinSearchQuery,
            },
          });

          if (!self.queriedPins || !self.queriedPins.length) {
            ToastService.showError("No Results");
          }

          var attributes = {
            isFocused: false,
            icon: "close",
            mode: PIN_SEARCH_MODES.ACTIVELYSEARCHING,
          };

          updateAttributes(attributes);
        }
      });
    }

    /**
     * Determine if component value and mode should revert and update attributes
     */
    function handleBlur() {
      if (self.mode === PIN_SEARCH_MODES.EDITING) {
        // revert
        self.pinSearchQuery = angular.copy(self.revertValue);
        fetchSearchList().then(function () {
          self.mode = angular.copy(self.revertMode);
          setBlurAttributes();
        });
      } else {
        setBlurAttributes();
      }
    }

    /**
     * Set the component attributes on blur
     */
    function setBlurAttributes() {
      var icon =
        self.mode === PIN_SEARCH_MODES.ACTIVELYSEARCHING ? "close" : self.icon;

      var attributes = {
        isFocused: false,
        icon: icon,
      };

      updateAttributes(attributes);
    }

    /**
     * Handler for text change events in search input. Uses the provided fetch
     * function to collect search results. Results are then compound-sorted by
     * pinType name and pin name.
     *
     * Updates self.queriedPins
     * Attaches isFirst to self.queriedPins as appropriate
     *
     * @returns {Promise} Promise that resolves when self.queriedPins has been updated
     */
    function fetchSearchList() {
      self.mode = PIN_SEARCH_MODES.EDITING;
      self.icon = "spyglass";

      if (!self.pinSearchQuery) {
        self.queriedPins = null;
        self.loading = false;
        return $q.resolve();
      }
      self.loading = true;
      var searchResults = self.fetch({ searchQuery: self.pinSearchQuery });

      return initPipeline
        .switchTo(
          CancellableService.executeSeries([
            function () {
              return searchResults;
            },
          ])
        )
        .then(function (results) {
          self.queriedPins = results;

          // no results or no pinTypes
          if (!self.queriedPins.length || !self.queriedPins[0].pinType) return;

          sortPins(self.queriedPins);
          populatePinTypeMetadata(self.queriedPins);
        })
        .catch(function (err) {
          if (err !== CANCEL_REASON) {
            self.queriedPins = [];
          }
        })
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Select a single pin. Query for other pin results that match the given
     * pin's name.
     *
     * @param {Object} pin - Pin to select
     * @return {Promise<>} - Resolves when finished
     */
    function selectPin(pin) {
      return handlePinSearchQueryChange(pin.name).then(function () {
        var attributes = {
          isFocused: false,
          icon: "close",
          mode: PIN_SEARCH_MODES.ACTIVELYSEARCHING,
        };
        updateAttributes(attributes);
      });
    }

    /**
     * Update controller attributes to keep things in sync
     *
     * @param {Object}    attributes                        attributes object
     * @param {Boolean}   [attributes.isFocused]            value to set self.isFocused
     * @param {String}    [attributes.icon]                 value to set self.icon
     * @param {String}    [attributes.mode]                 value to set self.mode
     */
    function updateAttributes(attributes) {
      angular.extend(self, attributes);
    }

    /**
     * Keep queried pins in sync with pinSearchQuery
     *
     * @param pinSearchQuery
     * @return {Promise}  Promise that resolves when self.queriedPins has been updated
     */
    function handlePinSearchQueryChange(pinSearchQuery) {
      self.pinSearchQuery = pinSearchQuery;
      // keep the queriedPins in sync with pinSearchQuery
      return fetchSearchList();
    }

    /**
     * Iterates over the provided pins in-order and attaches .isFirst = true
     * when a pin is the first of its type in the list. Additionally populates
     * self.pinCountsByType.
     *
     * @param {Object[]} pins - An array of pins sorted by pin type name
     */
    function populatePinTypeMetadata(pins) {
      pins[0].isFirst = true;
      self.pinCountsByType[pins[0].pinType.name] = 1;

      for (var i = 1; i < pins.length; i++) {
        var currentPin = pins[i];
        var previousPin = pins[i - 1];
        if (currentPin.pinType.name !== previousPin.pinType.name) {
          currentPin.isFirst = true;
          // initialize the pinCount for the new pinType
          self.pinCountsByType[currentPin.pinType.name] = 1;
        } else {
          // increment the pinCount for the pinType
          self.pinCountsByType[currentPin.pinType.name] += 1;
        }
      }
    }

    /**
     * Compound sort pins by pinType.name and then pin.name. Room pins will be
     * first. The incoming array will be modified.
     *
     * @param {Object[]} pins - Pins to sort (this will be done in-place)
     */
    function sortPins(pins) {
      /**
       * Comparator for sorting pins based on their name in ascending order.
       *
       * @param {Object} a - A pin
       * @param {Object} b - A pin
       * @return {Number} The result of comparing a.name and b.name
       */
      var pinNameComparator = function (a, b) {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        }
        return 0;
      };

      /**
       * Comparator for sorting pins based on their pinType. The sort is done
       * alphabetically on the pinType name in ascending order with the caveat
       * that Room pins will always come first.
       *
       * @param {Object} a - A pin (with attached pinType).
       * @param {Object} b - A pin (with attached pinType).
       *
       * @return {Number} Comparison result of a.pinType.name and
       *    b.pinType.name
       */
      var pinTypeNameComparator = function (a, b) {
        if (a.pinType.name === "Room" && b.pinType.name !== "Room") {
          return -1;
        }
        if (a.pinType.name !== "Room" && b.pinType.name === "Room") {
          return 1;
        }
        if (a.pinType.name < b.pinType.name) {
          return -1;
        }
        if (a.pinType.name > b.pinType.name) {
          return 1;
        }
        return 0;
      };

      // Compound sort by pinType and then pin name using the above comparators
      pins.sort(function (a, b) {
        var result = pinTypeNameComparator(a, b);
        if (result === 0) {
          result = pinNameComparator(a, b);
        }
        return result;
      });
    }

    /**
     * Used to set the active display state of the search input
     * when mode is activelySearching or a search is loading
     */
    function searchIsActive() {
      return self.mode === PIN_SEARCH_MODES.ACTIVELYSEARCHING || self.loading;
    }

    /**
     * Used to reset the search mode
     */
    function resetSearchMode() {
      self.pinSearchQuery = null;
      self.queriedPins = null;
      self.icon = "spyglass";
      self.mode = PIN_SEARCH_MODES.DEFAULT;
    }
  }
})();
