(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.inputField
   */
  angular
    .module("akitabox.desktop.directives.dateField", [
      "akitabox.core.lib.moment",
    ])
    .directive("abxDateField", AbxDateFieldDirective);

  /**
   * ngdoc directive
   * @module akitabox.direcitives.inputField
   * @name AbxInputFieldDirective
   * @description
   * `<abx-input-field>` is an date input directive
   */
  /* @ngInject */
  function AbxDateFieldDirective($timeout) {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/date-field/date-field.html",
      controller: AbxDateFieldController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        ngModel: "=",
        ngDisabled: "&",
        ngChange: "&",
        required: "=?abxRequired",
        dateFormat: "@?",
        minDate: "=?",
        maxDate: "=?",
        enabledDays: "=?",
      },
    };

    function postLink($scope, $element, attrs, vm) {
      if (angular.isDefined(attrs.placeholder))
        vm.placeholder = attrs.placeholder;
      // If abx variant is not used, default to the html attribute
      if (angular.isUndefined(vm.required))
        vm.required = angular.isDefined(attrs.required);

      vm.disabled = angular.isDefined(attrs.disabled);

      $scope.$watch("vm.minDate", function (date) {
        vm.onMinDateChange(date);
      });

      $scope.$watch("vm.maxDate", function (date) {
        vm.onMaxDateChange(date);
      });

      $scope.$watch("vm.open", function (newValue, oldValue) {
        if (newValue === false && oldValue === true) $scope.$emit("field:blur");
      });

      $scope.$watch("vm.ngDisabled()", function (disabled) {
        if (angular.isDefined(disabled)) {
          vm.disabled = disabled;
        }
      });

      $scope.$on("field:focus", function () {
        $timeout(function () {
          vm.open = true;
        });
      });

      $scope.$emit("$viewContentLoaded");
    }
  }

  /**
   * @ngdoc controller
   * @module akitabox.desktop.directives.inputField
   * @name AbxInputFieldController
   * @description
   * This controller responds to input field events
   */
  /* @ngInject */
  function AbxDateFieldController(moment) {
    var self = this;

    // Attributes
    self.open = false;
    self.dateFormat = self.dateFormat || "fullDate";
    self.dateOptions = { showWeeks: false };

    // Functions
    self.save = save;
    self.onMinDateChange = onMinDateChange;
    self.onMaxDateChange = onMaxDateChange;
    self.isDateDisabled = isDateDisabled;

    init();

    /**
     * Initialize date field
     */
    function init() {
      self.onMinDateChange(self.minDate);
      self.onMaxDateChange(self.maxDate);
      if (self.enabledDays) self.dateOptions.dateDisabled = isDateDisabled;
    }

    /**
     * Parse a date object or string
     * @param  {Date|String} date   Date
     */
    function parseDate(date) {
      if (angular.isString(date)) {
        if (date === "now" || date === "today") {
          return new moment().toDate();
        } else if (date === "yesterday") {
          return new moment().subtract(1, "days").toDate();
        } else if (date === "tomorrow") {
          return new moment().add(1, "days").toDate();
        }
        return null;
      }
      return new moment(date).toDate();
    }

    function isDateDisabled(options) {
      return !self.enabledDays[options.date.getDay()];
    }

    /**
     * Save input field value and invoke ngChange
     */
    function save() {
      if (angular.isEmpty(self.ngModel)) self.ngModel = null;
      self.ngChange({ value: self.ngModel });
    }

    function onMinDateChange(date) {
      self.dateOptions.minDate = date ? parseDate(date) : null;
    }

    function onMaxDateChange(date) {
      self.dateOptions.maxDate = date ? parseDate(date) : null;
    }
  }
})();
