(function () {
  angular.module("akitabox.core.utils").factory("DomService", DomService);

  /**
   * @ngdoc factory
   * @module akitabox.core.utils
   * @name DomService
   * @description
   * Service containing useful functions for interacting with the DOM. Note that
   * this service operates on DOM nodes, not jqlite objects.
   */
  /* @ngInject */
  function DomService($document) {
    var service = {
      getScrollParent: getScrollParent,
      getVerticalOffset: getVerticalOffset,
      isVerticallyScrolledIntoView: isVerticallyScrolledIntoView,
      getParent: getParent,
      getThumbnailStyles: getThumbnailStyles,
    };

    return service;

    // ==================
    // Public Functions
    // ==================

    /**
     * Walk up the DOM from the specified element, and return the first
     * scrolling container found. Falls back on returning document.body.
     * @param {DOMNode} element - The element for which to fetch the scroll
     *    parent.
     * @see https://stackoverflow.com/a/42543908/5298696
     */
    function getScrollParent(element) {
      var style = getComputedStyle(element);
      var excludeStaticParent = style.position === "absolute";
      var overflowRegex = /(auto|scroll)/;

      if (style.position === "fixed") return $document[0].body;
      for (var parent = element; (parent = parent.parentElement); ) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
          continue;
        }
        if (
          overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
        )
          return parent;
      }

      return $document[0].body;
    }

    /**
     * Calculates the vertical offset between two elements.
     * @param {DOMNode} element - The element to calculate the offset for.
     * @param {DOMNode} relativeTo - The ancestor element to calculate the
     * offset relative to. relativeTo MUST be an ancestor of element.
     */
    function getVerticalOffset(element, relativeTo) {
      return _getVerticalOffsetRecursive(element, relativeTo, 0, undefined);
    }

    /**
     * Check whether the specified element is currently scrolled into view
     * (vertically) of the given scrollParent. Takes several optional arguments
     * that can be used to save on performance since this function is likely to
     * be used in something like a scroll listener.
     * @param {DOMNode} element - The element to check.
     * @param {"top" | "bottom" | "both" | "either"} [strategy="both"] - Rules
     *    to use when evaluating whether an element is considered "in view" must
     *    be one of:
     *      "top": True if the top of the element is visible.
     *      "bottom": True if the bottom of the element is visible.
     *      "both": True if the entire element is visible.
     *      "either": True if any part of the element is visible.
     * @param {DOMNode} [scrollParent] - The scroll parent of the element if known.
     *    Will be calculated using getScrollParent if omitted.
     * @param {number} [offset] - The vertical offset of the element from
     *    its scrollParent if known. Will be calculated using getVerticalOffset
     *    if omitted.
     */
    function isVerticallyScrolledIntoView(
      element,
      strategy,
      scrollParent,
      offset
    ) {
      if (!scrollParent) {
        scrollParent = service.getScrollParent(element);
      }
      if (offset === undefined) {
        offset = service.getVerticalOffset(element, scrollParent);
      }
      var elementHeight = element.clientHeight;
      var elementTop = offset;
      var elementBottom = offset + elementHeight;

      // Threshold values are derived from the above positioning data based
      // on the provided strategy.
      var topThreshold = 0;
      var bottomThreshold = 0;

      switch (strategy) {
        case "top":
          topThreshold = elementTop;
          bottomThreshold = elementTop;
          break;
        case "bottom":
          topThreshold = elementBottom;
          bottomThreshold = elementBottom;
          break;
        case "either":
          topThreshold = elementBottom;
          bottomThreshold = elementTop;
          break;
        case "both":
        default:
          topThreshold = elementTop;
          bottomThreshold = elementBottom;
          break;
      }

      var containerHeight = scrollParent.clientHeight;
      var scrollTop = scrollParent.scrollTop;

      if (scrollTop > topThreshold) {
        // The container is scrolled down too far
        return false;
      }
      if (scrollTop + containerHeight < bottomThreshold) {
        // The container is scrolled up too far
        return false;
      }
      return true;
    }

    /**
     * Gets the closest parent element that matches the given selector string
     *
     * This fn uses Element.match(selectorString) under the hood to match
     * elements, so make sure you've polyfill that fn out beforehand
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
     *
     * @param {Element} el - the element to start the search at
     * @param {string} selector - the selector string to match on ie: ".class-name", "#id"
     * @param {string} stopSelector - the limit of how far up to look
     * @return {Element|null}
     */
    function getParent(el, selector, stopSelector) {
      if (!el || !el.parentElement) {
        // base case.  make sure el exist and has a parent before we even try
        return null;
      } else if (stopSelector && el.parentElement.matches(stopSelector)) {
        // kind of a 2nd base case.
        // if we've hit the stopSelector, dont search anymore
        return null;
      } else if (el.parentElement.matches(selector)) {
        // return the matching parent element
        return el.parentElement;
      } else {
        // keep looking up the DOM tree
        return getParent(el.parentElement, selector, stopSelector);
      }
    }

    // ==================
    // Private Functions
    // ==================

    /**
     * Tail-recursive function to iterate over parents, calculating the offset
     * added at each step.
     * @param {DOMNode} element - The element to calculate offset for.
     * @param {DOMNode} relativeTo - The ancestor containing element to calculate off
     *    of.
     * @param {number} sum - The current running total of offsets.
     * @param {DOMNode} lastOffsetParent - The last offsetParent that was evaluated.
     */
    function _getVerticalOffsetRecursive(
      element,
      relativeTo,
      sum,
      lastOffsetParent
    ) {
      var recurse = _getVerticalOffsetRecursive;

      var increment = element.offsetTop;
      if (element === relativeTo) {
        // finished traversing
        if (element.offsetParent === lastOffsetParent) {
          // handle the case where relativeTo is not an offsetParent by
          // subtracting its offset. Ensures the offset returned is relative to
          // the correct element.
          sum -= element.offsetTop;
        }
        return sum;
      }
      if (element.offsetParent === lastOffsetParent) {
        // sum already accounts for any offset added by this element
        increment = 0;
      }
      return recurse(
        // step up one node
        element.parentNode,
        relativeTo,
        // add the increment for this node
        increment + sum,
        // update lastOffsetParent if necessary
        element.offsetParent
      );
    }

    function getThumbnailStyles(image, size, fit) {
      var styles = {
        width: "",
        height: "",
        "min-width": "",
        "min-height": "",
        "margin-top": 0,
        "margin-left": 0,
      };
      var width = "";
      var height = "";
      var marginTop = 0;
      var marginLeft = 0;

      switch (fit) {
        case "all":
          var side = size / Math.sqrt(2); // side = hypotenuse (diameter) / sqrt(2)
          if (image.height > image.width) {
            height = side;
            width = scaleWidth(image.width, image.height, side);
          } else if (image.width > image.height) {
            width = side;
            height = scaleHeight(image.width, image.height, side);
          } else {
            height = side;
            width = side;
          }
          var padding = (size - side) / 2;
          marginTop = getOffsetTop(height, side, padding);
          marginLeft = getOffsetLeft(width, side, padding);
          break;
        case "width":
          width = "100%";
          height = scaleHeight(image.width, image.height, size);
          marginTop = getOffsetTop(height, size);
          break;
        case "height":
          height = "100%";
          width = scaleWidth(image.width, image.height, size);
          marginLeft = getOffsetLeft(width, size);
          break;
        case "smallest":
        default:
          if (image.height > image.width) {
            width = "100%";
            height = scaleHeight(image.width, image.height, size);
            marginTop = getOffsetTop(height, size);
          } else if (image.width > image.height) {
            height = "100%";
            width = scaleWidth(image.width, image.height, size);
            marginLeft = getOffsetLeft(width, size);
          } else {
            width = "100%";
            height = "100%";
          }
      }

      if (angular.isNumber(width)) {
        width += "px";
      }
      if (angular.isNumber(height)) {
        height += "px";
      }

      styles["width"] = styles["min-width"] = width;
      styles["height"] = styles["min-height"] = height;
      styles["margin-top"] = marginTop + "px";
      styles["margin-left"] = marginLeft + "px";

      return styles;

      function scaleHeight(width, height, size) {
        return height * (size / width);
      }

      function scaleWidth(width, height, size) {
        return width * (size / height);
      }

      function getOffsetTop(height, size, padding) {
        if (!padding) padding = 0;
        if (height > size) {
          return 0 - (height - size) / 2 - padding;
        }
        return (size - height) / 2 + padding;
      }

      function getOffsetLeft(width, size, padding) {
        if (!padding) padding = 0;
        if (width > size) {
          return 0 - (width - size) / 2 - padding;
        }
        return (size - width) / 2 + padding;
      }
    }
  }
})();
