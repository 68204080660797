import { AbxMentionFeedItem } from "../../report-template/ReportTemplateEditor";

export const buildingFeedItems: AbxMentionFeedItem[] = [
  {
    id: "#Building.Name",
    field: "building.name",
  },
  {
    id: "#Building.Address",
    field: "building.address",
  },
  {
    id: "#Building.Year Built",
    field: "building.date_built",
  },
  {
    id: "#Building.Description",
    field: "building.description",
  },
  {
    id: "#Building.Website",
    field: "building.website",
  },
  {
    id: "#Building.Type",
    field: "building.type",
  },
  {
    id: "#Building.Year Purchased",
    field: "building.date_purchased",
  },
  {
    id: "#Building.ID",
    field: "building.building_id",
  },
  {
    id: "#Building.Contact Name",
    field: "building.emergency_contact_name",
  },
  {
    id: "#Building.Phone Number",
    field: "building.emergency_contact_phone",
  },
];

export const buildingRPIFCIFeedItems: AbxMentionFeedItem[] = [
  {
    id: "#Building.Estimated Current Replacement Value",
    field: "building.estimated_replacement_value",
  },
  {
    id: "#Building.Mission Criticality (1-100)",
    field: "building.mission_criticality",
  },

  {
    id: "#Building.Assessment Date",
    field: "building.assessment_date",
  },

  {
    id: "#Building.FCI Score",
    field: "building.fci_score", // this is not a real field on the schema, will be calculated on the server
  },

  {
    id: "#Building.Estimated Renewal Cost",
    field: "building.estimated_renewal_cost", // this is not a real field on the schema, will be calculated on the server
  },
];
