(function () {
  angular.module("akitabox.planView.dialogs", [
    "akitabox.planView.dialogs.legacy-fac-filter",
    "akitabox.planView.dialogs.markup.create",
    "akitabox.planView.dialogs.markup.delete",
    "akitabox.planView.dialogs.markup.edit",
    "akitabox.planView.dialogs.printFloorPlan",
    "akitabox.planView.dialogs.skipStop",
  ]);
})();
