(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller("FloorDetailController", FloorDetailController);

  /* @ngInject */
  function FloorDetailController(
    // Third-party
    $stateParams,
    // Resolve
    building,
    floor,
    organization
  ) {
    var self = this;

    // Attributes
    self.floor = floor;
    self.building = building;
    self.organization = organization;
    self.tabs = getTabs();
    self.subtitle = getSubtitle();

    function getSubtitle() {
      return {
        name: "Floors",
        state: {
          name: "app.floors",
          params: $stateParams,
        },
      };
    }

    function getTabs() {
      var overview = {
        title: "Overview",
        state: "app.floor.overview",
      };
      var details = { title: "Details", state: "app.floor.details" };
      var rooms = { title: "Rooms", state: "app.floor.rooms" };
      var assets = { title: "Assets", state: "app.floor.assets" };
      var openWorkOrders = {
        title: "Open Work Orders",
        state: "app.floor.openWorkOrders",
      };
      var closedWorkOrders = {
        title: "Closed Work Orders",
        state: "app.floor.closedWorkOrders",
      };
      var schedules = {
        title: "Maintenance Schedules",
        state: "app.floor.schedules",
      };

      var tabsToReturn = [overview, details, rooms, assets];
      if (organization.show_tasks) {
        tabsToReturn.push(openWorkOrders, closedWorkOrders, schedules);
      }

      return tabsToReturn;
    }
  }
})();
