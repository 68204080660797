(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.timeCodeChart
   *
   * @param {Object} building - building to build the chart with
   */
  angular
    .module("akitabox.ui.components.reportingCharts.timeCodeChart", [
      "akitabox.constants",
      "akitabox.core.services.chart",
      "akitabox.core.services.flag",
      "akitabox.ui.directives.stackChart",
    ])
    .component("abxTimeCodeChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
      },
      controller: AbxTimeCodeChartController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/time-code-chart/time-code-chart.component.html",
    });

  /** @ngInject */
  function AbxTimeCodeChartController(
    // Angular
    $q,
    $log,
    $timeout,
    // Constants
    models,
    MINIMUM_RENDER_DELAY,
    // Services
    ChartService,
    OrganizationService,
    ShadowService,
    TimeCodeService
  ) {
    var self = this;
    var config = {};
    var GA_CATEGORY = "reporting";

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.idTag = "time-code-chart";
    self.data = [];
    self.groupKey = "_id";
    self.timeCodeNames = [];
    self.timeCodesMap = {};
    self.loading = true;
    self.showChart = false;

    self.yAxisLabelText = "Hours Logged";
    self.stackOptions = [];
    self.selectedStack = "---";
    self.excludeStackOptions = ["Age", "Assignee", "Work Performed By"];
    self.defaultStackOption = {
      model: "---",
      value: "---",
    };

    self.onStackChange = onStackChange;
    self.onStackHover = onStackHover;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxPriorityChart: abx-buildings is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        config = ChartService.getChartConfig(
          self.buildings,
          models.WORK_ORDER_LOG.MODEL
        );
        self.stackOptions = ChartService.getStackOptions(
          models.WORK_ORDER.MODEL,
          self.buildings.length > 1,
          [],
          self.excludeStackOptions
        );
      }
      if (changes.startDate || changes.endDate) {
        self.yAxisFilterOptions = ChartService.getYAxisConfig(
          [self.yAxisLabelText],
          self.startDate,
          self.endDate
        );

        self.yAxisConfig = self.yAxisFilterOptions[self.yAxisLabelText];
      }
      if (changes.filters) {
        updateFilters(self.yAxisConfig.lookupModel);
      }
      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings
      ) {
        calculateStats();
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    function onStackHover($event) {
      self.highlightedStack = $event.value;
    }

    function onStackChange($event) {
      ShadowService.setSelectedOption($event.value);
      ShadowService.sendEvent(GA_CATEGORY, "switch-stack-by", self.idTag);
      self.selectedStack = $event.model;
      updateFilters(self.yAxisConfig.lookupModel);
      calculateStats();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function setShowChart() {
      var options = {
        startDate: self.startDate,
        endDate: self.endDate,
      };
      self.showChart = ChartService.shouldShowChart(
        models.WORK_ORDER.MODEL,
        ChartService.CHART_TYPE_BAR,
        self.data,
        options
      );
    }

    function getTimeCodesByOrganization() {
      self.timeCodeNames = [];
      self.timeCodesMap = {};

      return TimeCodeService.getByOrganization(self.organization._id, {}).then(
        function (timeCodes) {
          let names = [];
          names.push("None");

          for (let i = 0; i < timeCodes.length; i++) {
            var timeCode = timeCodes[i];
            self.timeCodesMap[timeCode._id] = timeCode;
            names.push(timeCode.name);
          }
          self.timeCodeNames = names;
        }
      );
    }

    function getStats(params) {
      return config.statsFunction(config.parentId, params);
    }

    /**
     * Calculate the workOrder stats for the given date
     */
    function calculateStats() {
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      )
        return;
      self.loading = true;
      self.showChart = false;

      var params = {
        group_field: "time_code",
      };

      params = angular.extend(params, self.filters, self.yAxisConfig.params);

      if (config.buildingInString) params.buildings = config.buildingInString;

      $q.all([getTimeCodesByOrganization(), getStats(params)])
        .then((data) => {
          var statsData = data[1];

          for (let i = 0; i < statsData.length; i++) {
            var currentTimeCode = self.timeCodesMap[statsData[i]._id];
            if (!currentTimeCode) {
              statsData[i]._id = "None";
            } else {
              statsData[i]._id = currentTimeCode.name;
            }
          }
          self.legendValues = [];

          ChartService.addEmptyBars(statsData, self.timeCodeNames.join(","));

          if (!params.stack_field) {
            self.data = self.yAxisConfig.transform(statsData);
            return;
          }

          if (params.stack_field === "intent") {
            self.stackSort = ChartService.sortIntent;
          } else {
            self.stackSort = undefined; // sort function must either be undefined or function
          }

          return ChartService.parseStackData(
            statsData,
            params.stack_field,
            params.stack_field === "building" ? config.buildingMap : null,
            self.yAxisConfig.transform,
            self.buildings
          );
        })
        .then(function (stackData) {
          if (!stackData) {
            // no stack_field provided, self.data is already set above
            return;
          }
          self.legendValues = stackData.keys.sort(self.stackSort);
          self.data = stackData.data;
        })
        .then(function () {
          return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
        })
        .finally(function () {
          self.loading = false;
          setShowChart();
        });
    }

    /**
     *
     * @param {String} lookupModel model to prepend to the name of the stack filter
     */

    function updateFilters(lookupModel) {
      self.filters = ChartService.updateStackFilter(
        self.selectedStack,
        self.filters,
        lookupModel
      );
    }
  }
})();
