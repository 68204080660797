import { Plugin } from "@ckeditor/ckeditor5-core";
import { Widget, toWidget } from "@ckeditor/ckeditor5-widget";
import { InsertReportBlockPromptCommand } from "../commands/InsertReportBlockPrompt.command";

export class ReportBlockPromptConversion extends Plugin {
  static get requires() {
    /**
     * Additionally, you need to ensure that the Widget plugin is loaded.
     * If you omit it, the elements in the view will have all the classes
     * (like ck-widget) but there will be no “behaviors” loaded (for example, clicking a widget will not select it).
     */
    return [Widget];
  }

  init() {
    if (this.editor) {
      this.defineSchema();
      this.defineConverters();
      this.editor.commands.add(
        "insertReportBlockPrompt",
        new InsertReportBlockPromptCommand(this.editor)
      );
    }
  }

  private defineSchema() {
    const schema = this.editor?.model.schema;

    schema.register("abxReportBlockPrompt", {
      // Behaves like a self-contained block object (e.g. a block image)
      // allowed in places where other blocks are allowed (e.g. directly in the root).
      // https://ckeditor.com/docs/ckeditor5/latest/framework/architecture/editing-engine.html#schema
      isObject: true,
      allowIn: "$root",
      allowAttributes: [
        "abxDescription",
        "abxReportBlockName",
        "abxQueryString",
      ],
    });
  }

  private defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for("downcast").elementToStructure({
      model: "abxReportBlockPrompt",
      view: (modelElement, { writer }) => {
        let description = modelElement.getAttribute("abxDescription") as string;
        if (!description) {
          description = "Description not found";
        }

        let reportBlockName = modelElement.getAttribute(
          "abxReportBlockName"
        ) as string;
        if (!reportBlockName) {
          reportBlockName = "Report Block Name not found";
        }

        let queryString = modelElement.getAttribute("abxQueryString") as string;
        if (!queryString) {
          queryString = "";
        }

        const filterLabel = writer.createContainerElement(
          "td",
          { class: "abx-report-block-prompt__filter-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Filters"),
            ]),
          ]
        );
        const filterValue = writer.createContainerElement(
          "td",
          { class: "abx-report-block-prompt__filter-value col-70" },
          [writer.createText(queryString)]
        );
        const filterRow = writer.createContainerElement(
          "tr",
          { class: "abx-report-block-prompt__filter" },
          [filterLabel, filterValue]
        );

        const blockLabel = writer.createContainerElement(
          "td",
          { class: "abx-report-block-prompt__block-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Block"),
            ]),
          ]
        );
        const blockValue = writer.createContainerElement(
          "td",
          { class: "abx-report-block-prompt__block-value col-70" },
          [writer.createText(reportBlockName)]
        );
        const blockRow = writer.createContainerElement(
          "tr",
          { class: "abx-report-block-prompt__block" },
          [blockLabel, blockValue]
        );

        const descriptionLabel = writer.createContainerElement(
          "td",
          { class: "abx-report-block-prompt__description-label col-30" },
          [
            writer.createContainerElement("strong", {}, [
              writer.createText("Description"),
            ]),
          ]
        );
        const descriptionValue = writer.createContainerElement(
          "td",
          { class: "abx-report-block-prompt__description-value col-70" },
          [writer.createText(description)]
        );
        const descriptionRow = writer.createContainerElement(
          "tr",
          { class: "abx-report-block-prompt__description" },
          [descriptionLabel, descriptionValue]
        );

        const tableHead = writer.createContainerElement(
          "thead",
          { class: "abx-report-block-prompt__header" },
          [
            writer.createContainerElement("tr", {}, [
              writer.createContainerElement("th", { colspan: 2 }, [
                writer.createText("Insert Block content"),
                writer.createContainerElement(
                  "span",
                  { class: "abx-report-block-prompt__header-description" },
                  [
                    writer.createText(
                      " - This Block content will be generated and inserted when creating a Report from this Template"
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        );

        const tableBody = writer.createContainerElement(
          "tbody",
          { class: "abx-report-block-prompt__body" },
          [descriptionRow, blockRow, filterRow]
        );
        const table = writer.createContainerElement(
          "table",
          { class: "abx-report-block-prompt" },
          [tableHead, tableBody]
        );

        return toWidget(table, writer, { label: "report block prompt" });
      },
    });

    // since we built elementToStructure for downcast, we need to build structureToElement for upcast
    conversion.for("upcast").add((dispatcher) => {
      // look at every table element in the view, since our custom element is a table
      dispatcher.on("element:table", (_evt, data, conversionApi) => {
        const {
          consumable,
          writer,
          safeInsert,
          convertChildren,
          updateConversionResult,
        } = conversionApi;
        const { viewItem: table } = data;

        const tableFilter = {
          name: true,
          classes: "abx-report-block-prompt",
        };

        if (!consumable.test(table, tableFilter)) {
          // make sure the table we found is actually our custom element
          return;
        }

        // Check if there is only one child.
        if (table.childCount !== 2) {
          return;
        }

        // Get children elements
        const tHead = table.getChild(0);
        const tBody = table.getChild(1);

        // get description
        const description = tBody.getChild(0).getChild(1).getChild(0)?.data;
        const blockName = tBody.getChild(1).getChild(1).getChild(0)?.data;
        const queryString = tBody.getChild(2).getChild(1).getChild(0)?.data;

        // Create model element.
        const modelElement = writer.createElement("abxReportBlockPrompt", {
          abxDescription: description,
          abxReportBlockName: blockName,
          abxQueryString: queryString || "",
        });

        // Insert element on a current cursor location.
        if (!safeInsert(modelElement, data.modelCursor)) {
          return;
        }

        // Consume the main outer wrapper element.
        consumable.consume(table, tableFilter);
        // Consume the thead element.
        consumable.consume(tHead, {
          name: true,
          classes: "abx-report-block-prompt__header",
        });
        // Consume the tbody element.
        consumable.consume(tBody, {
          name: true,
          classes: "abx-report-block-prompt__body",
        });

        // Handle children conversion inside thead element.
        convertChildren(tHead, modelElement);
        // Handle children conversion inside tbody element.
        convertChildren(tBody, modelElement);

        // Necessary function call to help setting model range and cursor
        // for some specific cases when elements being split.
        updateConversionResult(modelElement, data);
      });
    });
  }
}
