(function () {
  angular
    .module("akitabox.ui.dialogs.recommission")
    .controller("RecommissionDialogController", RecommissionDialogController);

  /* @ngInject */
  function RecommissionDialogController(
    // Angular
    $mdDialog,
    $q,
    // Utils
    ServiceHelpers,
    // Services
    AssetService,
    RoomService,
    OrganizationService
  ) {
    var self = this;

    // fields
    /**
     * @prop {Assets[]|null} assets - an array of asset(s) that are being recommissioned
     */
    /**
     * @prop {boolean} recommissioning - flag to indicate we're recommissioning
     */
    self.recommissioning = false;
    /**
     * @prop { string | null } errorText - The current error to display to the user
     */
    self.errorText = null;
    /**
     * @prop {Organization} organization - the organization the user belongs to
     */
    self.organization = OrganizationService.getCurrent();
    /**
     * @prop {Room[]|null} rooms - an array of rooms that are being recommissioned
     */
    /**
     * @prop {string} roomDisplayName - the full room name, [room_number] - [room_name]
     */
    self.roomDisplayName = "...";
    self.headerTitle = "Recommission";

    // methods
    self.handleRecommissionClick = handleRecommissionClick;
    self.onCloseClickHandler = $mdDialog.cancel;

    init();

    function init() {
      // if we're recommissioning a single room, we need to get the pretty room name
      if (self.rooms) {
        if (self.rooms.length === 1) {
          var baseTitle = "Recommission Room: ";
          ServiceHelpers.getRoomDisplayName(self.rooms[0])
            .then(function (roomDisplayName) {
              self.headerTitle = baseTitle + roomDisplayName;
            })
            .catch(function (err) {
              // default to the room number if we can't get the full name
              self.headerTitle = baseTitle + self.rooms[0].name;
            });
        } else {
          self.headerTitle = "Recommission " + self.rooms.length + " Rooms";
        }
      } else if (self.assets) {
        if (self.assets.length === 1) {
          self.headerTitle = "Recommission Asset: " + self.assets[0].name;
        } else {
          self.headerTitle = "Recommission " + self.assets.length + " Assets";
        }
      }
    }

    /**
     * Recommission the pin. On success, close the dialog and return the updated
     * pin. On failure, updates error state.
     */
    function handleRecommissionClick() {
      self.recommissioning = true;
      self.errorText = null;
      var service = self.assets ? AssetService : RoomService;
      var pins = self.assets || self.rooms;
      self.determinateValue = 0;
      var determinateIncrement = (1 / pins.length) * 100;

      var recommissionPromises = pins.map(function (pin) {
        var buildingId = pin.building._id || pin.building;
        return service
          .recommission(buildingId, pin._id)
          .then(function (recomissionedPin) {
            self.determinateValue += determinateIncrement;
            return recomissionedPin;
          });
      });

      return $q
        .all(recommissionPromises)
        .then(function (recommissionedPins) {
          return $mdDialog.hide(recommissionedPins);
        })
        .catch(function (err) {
          if (err.data) {
            self.errorText = err.data.error.message;
          } else {
            self.errorText = err.message || err.toString();
          }
        })
        .then(function () {
          self.recommissioning = false;
        });
    }
  }
})();
