(function () {
  angular
    .module("akitabox.ui.directives.field", ["akitabox.core.utils"])
    .directive("abmFieldContainer", AbmFieldContainerDirective);

  /* @ngInject */
  function AbmFieldContainerDirective($timeout, $window, Utils) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/field/field.html",
      transclude: {
        label: "?label",
        value: "?abmFieldValue",
        placeholder: "?abmFieldPlaceholder",
      },
      controller: AbmFieldContainerController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        ngModel: "=?",
        ngDisabled: "&?",
        ngRequired: "&?",
        editing: "=?",
      },
    };

    function postLink($scope, $element, attrs, vm) {
      // Constants
      var MAX_LINES = 7;
      // Text styles used to calculate height of textarea value
      var TEXT_STYLES = {
        overflow: "hidden",
        "text-overflow": "ellipsis",
        "word-wrap": "break-word",
      };

      // Attributes
      vm.disabled = angular.isDefined(attrs.disabled);
      vm.readonly = angular.isDefined(attrs.readonly);
      vm.required = angular.isDefined(attrs.required);
      vm.editable = !vm.readonly && !vm.disabled;
      vm.isAutocomplete = angular.isDefined(attrs.isAutocomplete);
      vm.isDate = angular.isDefined(attrs.isDate);
      vm.useMdContainer = !vm.isAutocomplete;

      // Functions
      vm.toggleFullText = toggleFullText;

      // UI Elements
      var label;
      var $value;
      var $textarea;

      $timeout(function () {
        onLoad();
      });

      function onLoad() {
        findElements();
        evaluateRequired();

        if (angular.isEmpty(attrs.editing)) vm.editing = true;

        if (vm.isDate) {
          var abxDatePickerElement = $element.find("abx-datepicker");
          var abxDatePicker = angular.element(abxDatePickerElement[0]);
          abxDatePicker.addClass("flex layout-row");
        }

        $scope.$watch("vm.ngModel", function (newValue) {
          vm.ngModel = angular.isString(newValue) ? newValue.trim() : newValue;
          evaluateTextarea(newValue);
        });

        $scope.$watch("vm.ngDisabled()", function (value) {
          if (angular.isDefined(value)) {
            vm.disabled = value;
            vm.editable = !vm.readonly && !vm.disabled;
            if (vm.editing) vm._editing = vm.editable;
          }
        });

        $scope.$watch("vm.ngRequired()", function (value) {
          if (angular.isDefined(value)) {
            vm.required = value;
            evaluateRequired();
          }
        });

        $scope.$watch("vm.editing", function (newValue) {
          vm._editing = newValue ? vm.editable : false;
          evaluateTextarea(vm.ngModel);
        });
      }

      function findElements() {
        var $labels = $element.find("label");
        var $textareas = $element.find("textarea");
        var $values = $element.find("abm-field-value");
        // Get first label
        label = angular.element($labels[0]);
        // Get field value
        if ($values.length > 0) {
          $value = angular.element($values[0]);
          $value.css("width", vm.width + "px");
        }
        // Get textarea
        if ($textareas.length > 0) {
          vm.isTextarea = true;
          $textarea = angular.element($textareas[0]);
        }
      }

      function evaluateRequired() {
        if (vm.required) {
          label.addClass("required");
        } else {
          label.removeClass("required");
        }
      }

      function evaluateTextarea(newValue) {
        if (!vm.isTextarea || !newValue || !$value) {
          return;
        }

        var lines = newValue.split("\n");
        var fontSize = $window
          .getComputedStyle($value[0])
          .getPropertyValue("font-size");
        var width = vm.width;

        // Calculate the possible max height allowed
        var fontHeight = Utils.getContextualFontHeight(
          fontSize,
          width,
          TEXT_STYLES
        );
        var minMaxHeight = fontHeight * MAX_LINES;
        // Calculate the newValue's max height
        var textHeight = Utils.getContextualFontHeight(
          fontSize,
          width,
          TEXT_STYLES,
          newValue
        );
        // compare and see if we should toggle
        vm.showFullTextToggle = !vm._editing && textHeight >= minMaxHeight;
        setValueHeight(textHeight, minMaxHeight);

        if (vm._editing) {
          // should only be setting the textarea height when in edit mode
          setTextareaHeight(newValue);
        }

        $value.empty().html(lines.join("<br />"));
      }

      function toggleFullText() {
        vm.showLess = !vm.showLess;
        evaluateTextarea(vm.ngModel);
      }

      function setValueHeight(actualHeight, minHeight) {
        vm.valueHeight = vm.showLess ? minHeight + "px" : actualHeight + "px";
      }

      function setTextareaHeight(text) {
        var width = vm.width;
        var fontSize = $window
          .getComputedStyle($textarea[0])
          .getPropertyValue("font-size");
        var lineHeight = $window
          .getComputedStyle($textarea[0])
          .getPropertyValue("line-height");
        var fontHeight = Utils.getFontHeight(
          parseInt(fontSize.substring(0, fontSize.length - 2), 10)
        );
        var textArray = text.split("\n");
        var rows = textArray.length; // we know the min. amount of rows needed by looking at the # of \n

        for (var i = 0; i < textArray.length; i++) {
          // This loop will look through each line of text, and see if they will add additional rows to the
          // textarea by text-wrapping
          // We know a line will text-wrap if it is longer than the width of the textarea, we just have to
          // determine how many times that will happen
          var line = textArray[i];
          var $span = angular.element("<span>" + line + "</span>");

          $span.css("font-size", fontSize);
          $span.css("line-height", lineHeight);
          $span.css("white-space", "nowrap");
          document.body.appendChild($span[0]);
          var lineWidth = $span[0].offsetWidth;

          rows += parseInt(lineWidth / width, 10);
          $span.remove();
        }

        $textarea[0].rows = rows;
        $textarea.css("height", fontHeight * rows + "px");
      }
    }
  }

  function AbmFieldContainerController() {
    var self = this;
    var margins = 48;

    // Attributes
    // public editing state manipulated by user
    self.editing = angular.isDefined(self.editing) ? self.editing : false;
    self._editing = false; // private, internal editing state
    self.isTextarea = false;
    self.showFullTextToggle = false;
    self.showLess = true;
    self.useMdContainer = true;
    self.width = window.innerWidth - margins;

    // Functions
    self.isEmpty = isEmpty;

    function isEmpty() {
      return angular.isEmpty(self.ngModel);
    }
  }
})();
