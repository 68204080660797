(function () {
  angular.module("akitabox.core.root").run(states);

  /* @ngInject */
  function states(Router) {
    Router.configureStates([
      {
        state: "root",
        config: {
          abstract: true,
          templateUrl: "app/core/modules/root/root.html",
        },
      },
      {
        state: "root.upgradeBrowser",
        config: {
          controller: "UpgradeBrowserController",
          controllerAs: "vm",
          url: "/upgrade-browser",
          templateUrl: "app/core/modules/root/templates/upgrade-browser.html",
        },
      },
      {
        state: "root.503",
        config: {
          templateUrl: "app/core/modules/root/templates/503.html",
        },
      },
      {
        state: "root.404",
        config: {
          templateUrl: "app/core/modules/root/templates/404.html",
        },
      },
    ]);
  }
})();
