(function () {
  angular.module(
    "akitabox.core.ui.dialogs.inspectionProgram.create.checklistTemplateOverrideSection",
    [
      "akitabox.core.ui.dialogs.inspectionProgram.create.checklistTemplateOverrideSection.list",
      "akitabox.core.services.pinType",
      "akitabox.core.services.organization",
      "akitabox.core.services.roundTemplate",
      "akitabox.core.toast",
      "akitabox.core.services.checklistTemplate",
      "akitabox.core.ui.dialogs.inspectionProgram.create.overrideList",
    ]
  );
})();
