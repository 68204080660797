(function () {
  angular.module("akitabox.ui.dialogs.decommission", [
    "ngMaterial",
    "akitabox.core.lib.moment",
    "akitabox.core.services.asset",
    "akitabox.core.services.env",
    "akitabox.core.services.flag",
    "akitabox.core.services.helpers",
    "akitabox.core.services.organization",
    "akitabox.core.services.request",
    "akitabox.core.services.room",
    "akitabox.core.services.roundTemplate",
    "akitabox.core.services.schedule",
    "akitabox.core.services.workOrder",
    "akitabox.ui.components.input",
  ]);
})();
