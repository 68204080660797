abxImportAll(require.context("./app/global-setup", true, /\.js$/));
abxImportAll(require.context("./app/desktop", true, /\.js$/));
abxImportAll(require.context("./app/core", true, /\.js$/));

function importAll(context, filterFn) {
  let keys = context.keys();
  if (filterFn) {
    keys = keys.filter(filterFn);
  }
  keys.forEach(context);
}

function abxImportAll(context, moduleFilter, sourceFilter) {
  const finalModuleFilter = (moduleName) => {
    if (moduleName.endsWith(".spec.js") || moduleName.endsWith(".mock.js")) {
      return false;
    }
    if (moduleName.endsWith(".module.js")) {
      return moduleFilter ? moduleFilter(moduleName) : true;
    } else {
      return false;
    }
  };

  // decorate the source filter to exclude .module.js files
  const finalSourceFilter = (moduleName) => {
    if (moduleName.endsWith(".spec.js") || moduleName.endsWith(".mock.js")) {
      return false;
    }
    if (moduleName.endsWith(".module.js")) {
      return false;
    } else {
      return sourceFilter ? sourceFilter(moduleName) : true;
    }
  };

  importAll(context, finalModuleFilter);
  importAll(context, finalSourceFilter);
}
