(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller("ScheduleAssetTabController", ScheduleAssetTabController);

  /* @ngInject */
  function ScheduleAssetTabController(
    // Angular
    $timeout,
    // Resolves
    building,
    schedule,
    // Services
    AssetService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.rendering = true;
    self.building = building;
    self.schedule = schedule;
    self.asset = null;

    init();

    function init() {
      var assetId = schedule.asset._id;
      var params = { include_values: true };
      var options = {
        includePinType: true,
        includeFloor: true,
        includeRoom: true,
      };
      AssetService.getById(building._id, assetId, params, options)
        .then(function (asset) {
          self.asset = asset;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
          $timeout(function () {
            self.rendering = false;
          }, 500);
        });
    }
  }
})();
