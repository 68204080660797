(function () {
  angular
    .module("akitabox.planView.dialogs.markup.create")
    .service("CreateMarkupDialog", CreateMarkupDialog);

  /* @ngInject */
  function CreateMarkupDialog($mdDialog) {
    var modal = {
      templateUrl:
        "app/desktop/modules/plan-view/dialogs/markup/create/create-markup.dialog.html",
      controller: "CreateMarkupDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    /**
     * CreateFloorDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.building` is required
     * Show dialog, `options.locals.floor` is required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error>}               Promise that resolves with the newly created floor
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
