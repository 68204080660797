(function () {
  /**
   * @component
   * Component for displaying a form to collect data for an Inspection Component.
   */
  angular
    .module(
      "akitabox.core.ui.dialogs.inspectionProgram.create.inspectionDetailSection"
    )
    .component("abxInspectionDetailSection", {
      bindings: {
        subject: "=abxSubject",
        priority: "=abxPriority",
        checklist: "<abxChecklistTemplate",
        onChecklistTemplateChange: "&abxOnChecklistTemplateChange",
        description: "=abxDescription",
      },
      controller: AbxInspectionDetailController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/dialogs/inspection-program/create/inspection-detail-section/inspection-detail-section.html",
    });

  /** @ngInject */
  function AbxInspectionDetailController(
    // Constants
    PRIORITIES,
    // Services
    ChecklistTemplateService,
    OrganizationService,
    ToastService
  ) {
    var self = this;

    self.PRIORITIES = PRIORITIES;
    self.priorityOptions = getPriorityOptions();
    self.organization = OrganizationService.getCurrent();
    self.priorityDisplay = null;

    self.handleChecklistChange = handleChecklistChange;
    self.handleDescriptionChange = handleDescriptionChange;
    self.handlePriorityChange = handlePriorityChange;
    self.handleSubjectChange = handleSubjectChange;

    self.$onInit = function () {
      fetchChecklists();
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function handleDescriptionChange(event) {
      self.description = event.model;
    }

    function handlePriorityChange(event) {
      self.priority = event.model;
    }

    function handleSubjectChange(event) {
      self.subject = event.model;
    }

    function handleChecklistChange(event) {
      self.onChecklistTemplateChange({ $event: event });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function fetchChecklists() {
      return ChecklistTemplateService.getAll(self.organization._id, {
        status: "active",
      })
        .then(function (checklists) {
          self.availableChecklists = checklists.map(function (checklist) {
            return {
              model: checklist,
              value: checklist.name,
            };
          });
        })
        .catch(ToastService.showError);
    }

    function getPriorityOptions() {
      var options = [];
      var keys = Object.keys(PRIORITIES);
      for (var i = 0; i < keys.length; ++i) {
        var key = keys[i];
        options.push({
          model: key,
          value: PRIORITIES[key],
        });
      }

      return options;
    }
  }
})();
