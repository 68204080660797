(function () {
  angular
    .module("akitabox.planView.dialogs.skipStop", [
      "akitabox.constants",
      "ngMaterial",
      "akitabox.core.services.checklist",
      "akitabox.core.services.user",
    ])
    .controller("SkipStopDialogController", SkipStopDialogController);

  /* @ngInject */
  function SkipStopDialogController(
    // Angular
    $q,
    // constants
    models,
    // angular
    $mdDialog,
    // services
    BuildingService,
    ChecklistService,
    FeatureFlagService,
    UserService
  ) {
    var self = this;

    // Attributes
    // Holds any errors returned from the API or form errors
    self.error = undefined;
    self.formData = {
      reason: "",
    };
    self.saving = false;
    self.title = "Skip Reason";
    self.building = BuildingService.getCurrent();
    self.user = UserService.getCurrent();

    // Functions
    self.cancel = $mdDialog.cancel;
    self.canDelete = canDelete;
    self.canSave = canSave;
    self.skip = skip;
    self.remove = remove;

    init();

    function init() {
      if (self.reasonId) {
        // Change dialog name, we're editing now
        self.title = "Edit Skip Reason";
      }

      if (self.reasonText && self.reasonText.length) {
        // Use the passed in reason text as default value
        self.formData.reason = self.reasonText;
      }
    }

    /**
     * Adds or updates a skip reason via an API call
     */
    function skip() {
      if (self.error) self.error = undefined;
      self.saving = true;
      var apiCalls = [];
      var succeededSkipped = [];
      for (var i = 0; i < self.checklists.length; ++i) {
        var checklist = self.checklists[i];
        if (self.reasonId) {
          // Update the reason's text
          if (FeatureFlagService.isEnabled("jit_checklists")) {
            const stop = checklist;
            apiCalls.push(
              ChecklistService.updateSkipReasonByBuildingWorkOrder(
                self.building._id,
                stop.task,
                stop.asset ? stop.asset._id : null,
                stop.room ? stop.room._id : null,
                stop.level ? stop.level._id : null,
                self.reasonId,
                self.formData.reason
              ).then(function (checklist) {
                succeededSkipped.push(checklist);
              })
            );
          } else {
            apiCalls.push(
              ChecklistService.updateSkipReason(
                checklist.organization,
                checklist._id,
                self.reasonId,
                self.formData.reason
              ).then(function (checklist) {
                succeededSkipped.push(checklist);
              })
            );
          }
        } else {
          // Create a new skip reason
          if (FeatureFlagService.isEnabled("jit_checklists")) {
            const stop = checklist;
            apiCalls.push(
              ChecklistService.skipByBuildingWorkOrder(
                self.building._id,
                stop.task,
                stop.asset ? stop.asset._id : null,
                stop.room ? stop.room._id : null,
                stop.level ? stop.level._id : null,
                self.formData.reason
              ).then(function (checklist) {
                succeededSkipped.push(checklist);
              })
            );
          } else {
            apiCalls.push(
              ChecklistService.skip(
                checklist.organization,
                checklist._id,
                self.formData.reason
              ).then(function (checklist) {
                succeededSkipped.push(checklist);
              })
            );
          }
        }
      }

      $q.all(apiCalls)
        .then(function () {
          $mdDialog.hide(succeededSkipped);
        })
        .catch(function (err) {
          self.error = err;
        })
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Determines if user can press the skip button or not
     */
    function canSave() {
      if (self.saving) return false;
      else if (!self.formData.reason.length) return false;
      return true;
    }

    /**
     * Determines if the delete button should show up or not
     */
    function canDelete() {
      var checklist = self.checklists[0];
      // Dpn't let them delete while we're saving
      if (self.saving) return false;
      // Don't let them delete if the reason hasn't even been saved in our db yet
      else if (!self.reasonId || !self.reasonId.length) return false;
      // Don't let them delete if the checklist is currently skipped and has only 1 reason
      else if (
        checklist.status === models.CHECKLIST.STATUSES.SKIP &&
        checklist.skip_reasons.length === 1
      )
        return false;
      // Don't let them delete other peoples reasons
      for (var i = 0; i < checklist.skip_reasons.length; i++) {
        var skipReason = checklist.skip_reasons[i];
        if (skipReason._id !== self.reasonId) continue;
        if (skipReason.cre_account !== self.user.identity._id) return false;
        break;
      }
      return true;
    }

    /**
     * Removes the skip reason with an API call
     */
    function remove() {
      if (self.error) self.error = undefined;
      self.saving = true;
      var checklist = self.checklists[0];
      var updatedSkipReasons = [];

      for (var i = 0; i < checklist.skip_reasons.length; i++) {
        if (checklist.skip_reasons[i]._id !== self.reasonId) {
          updatedSkipReasons.push(checklist.skip_reasons[i]);
        }
      }

      ChecklistService.update(checklist.organization, checklist._id, {
        skip_reasons: updatedSkipReasons,
      })
        .then(function (updatedChecklist) {
          $mdDialog.hide(updatedChecklist);
        })
        .catch(function (err) {
          self.error = err;
        })
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
