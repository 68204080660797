(function () {
  angular
    .module("akitabox.ui.directives.simpleAutocomplete")
    .directive("abxSimpleAutocomplete", AbxSimpleAutocompleteDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.simpleAutocomplete
   * @name AbxSimpleAutocompleteDirective
   * @restrict E
   *
   * @description
   * `abx-simple-autocomplete` creates a generic autcomplete input.
   * Users of this directive should expect to fetch all items to be
   * populated in the autocomplete just once, and queried locally
   * thereafter. This directive should NOT be used for lists that
   * are unreasonable to fetch all items in one swoop.
   *
   * @param {Function} abx-fetch         (optional) A function that returns a promise
   *                                                  that resolves with a list
   *                                                  of items
   * @param {Array}    abx-items         (optional) A static list of items
   * @param {String}   abx-category-key  (optional) The key to categorize each list
   *                                                   item by
   * @param {String}   abx-item-text-key (required) The key to look at for the
   *                                                  fetched items to query on and
   *                                                  display
   * @param {String}   abx-label         (required) The label for the autocomplete
   *
   * @usage
   * <hljs lang="html">
   *  <abx-simple-autocomplete
   *         id="type"
   *         name="type"
   *         ng-model="vm.type"
   *         required="vm.requireType"
   *         placeholder="Select a type"
   *         abx-fetch="vm.fetchAllTypes()"
   *         abx-category-key="intent"
   *         abx-item-text-key="alias"
   *         abx-label="Type"
   *         >
   *   </abx-simple-autocomplete>
   * </hljs>
   *
   * @ngInject
   */
  function AbxSimpleAutocompleteDirective(
    // Angular
    $log,
    $timeout
  ) {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/simple-autocomplete/simple-autocomplete.html",
      require: ["abxSimpleAutocomplete", "ngModel"],
      controller: "AbxSimpleAutocompleteController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        fetch: "&abxFetch",
        items: "=?abxItems",
        categoryKey: "@?abxCategoryKey",
        itemTextKey: "@abxItemTextKey",
        label: "@abxLabel",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // UI elements
      var $input;

      // Functions
      vm.setModelValue = setModelValue;

      init();

      function init() {
        if (!validateAttributes()) return;
        attachPropertyWatchers();

        attrs.$set("abx-custom-input", "");

        // Watch for model changes
        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and handle events
          $input = angular.element($element.find("input")[0]);
          setPlaceholder($element.attr("placeholder"));
        });
      }

      /**
       * Validates the passed in attributes.
       *
       * @return {boolean}     True if all attributes valid, false otherwise.
       */
      function validateAttributes() {
        var valid = true;
        var hasFetch = !angular.isEmpty(attrs.abxFetch);
        var hasItems = !angular.isEmpty(attrs.abxItems);
        if (hasFetch ? hasItems : !hasItems) {
          // fetch XOR items
          $log.error(
            "<abx-simple-autocomplete>: exactly one of abx-fetch and abx-items must be provided"
          );
          valid = false;
        }
        if (angular.isEmpty(attrs.abxItemTextKey)) {
          $log.error(
            "<abx-simple-autocomplete>: abx-item-text-key must be provided as a non-empty string"
          );
          valid = false;
        }
        if (
          angular.isDefined(attrs.abxCategoryKey) &&
          angular.isEmpty(attrs.abxCategoryKey)
        ) {
          $log.error(
            "<abx-simple-autocomplete>: abx-category-key must not be an empty string if provided"
          );
          valid = false;
        }
        if (angular.isEmpty(attrs.abxLabel)) {
          $log.error(
            "<abx-simple-autocomplete>: abx-label must be provided as a non-empty string"
          );
          valid = false;
        }

        return valid;
      }

      /**
       * Handle external value changes
       *
       * @param  {*} value    New value
       * @return {*}          Parsed value
       */
      function onExternalChange(value) {
        vm.selectedItem = value;
        return value;
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Readonly
        $scope.$watch(function () {
          return $element.attr("readonly");
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          return $element.attr("disabled");
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          return $element.attr("placeholder");
        }, setPlaceholder);
      }

      /**
       * Set readonly
       *
       * @param {String} value    Attribute value
       */
      function setReadonly(value) {
        vm.readonly = Boolean(value);
      }

      /**
       * Set disabled
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        vm.disabled = Boolean(value);
      }

      /**
       * Set input and directive placeholder
       *
       * @param {String} value    Attribute value
       */
      function setPlaceholder(value) {
        $element.attr("placeholder", value);
        if ($input) $input.attr("placeholder", value);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
      }
    }
  }
})();
