(function () {
  angular
    .module("akitabox.ui.directives.job", [
      "ngMaterial",
      "angular-inview",
      "angular.filter",
      "akitabox.core.router",
      "akitabox.core.services.job",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.job.cancel",
      "akitabox.ui.dialogs.document.download",
    ])
    .directive("abxJob", AbxJobDirective);

  /* @ngInject */
  function AbxJobDirective(
    // Angular
    $filter,
    $interval,
    $q,
    // Material
    $mdMedia,
    // Dialogs
    CancelJobDialog,
    DownloadDocumentDialog,
    // Services
    AttachmentService,
    AdminJobService,
    Router,
    ToastService
  ) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/job/job.html",
      require: ["ngModel"],
      link: postLink,
      scope: {
        building: "=?abxBuilding",
        getOwner: "&abxOwner",
        permissions: "=?abxPermissions",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      var ngModelCtrl = controllers[0];

      // Constants
      var UPDATE_INTERVAL = 3000; // 3 seconds

      // Interval promises
      var durationInterval;
      var updateInterval;

      // UI elements
      var $duration = $element.find("time");

      // Attributes
      $scope.visible = false;
      $scope.active = false;
      $scope.status = null;
      $scope.owner = null;
      $scope.errCount = 0;
      $scope.permissions = $scope.permissions ? $scope.permissions : {};

      $scope.jobState = $scope.building
        ? "app.building.admin.job"
        : "admin.job";

      // Functions
      $scope.download = download;
      $scope.cancel = cancel;
      $scope.inView = inView;
      $scope.goToJob = goToJob;

      // Listen for external model changes
      ngModelCtrl.$formatters.push(onExternalChange);

      // Add $destroy event handler
      $scope.$on("$destroy", onDestroy);

      // ------------------------
      //   Public Functions
      // ------------------------

      /**
       * Go to Job detail
       */
      function goToJob() {
        return Router.go($scope.jobState, { jobId: $scope.job._id });
      }

      /**
       * Cancel the job
       */
      function cancel() {
        if (!$scope.active) return;
        var locals = {
          jobId: $scope.job._id,
        };
        CancelJobDialog.show({ locals: locals })
          .then(function (job) {
            $scope.job = job;
            updateStatus();
            refreshIntervals();
          })
          .catch(ToastService.showError);
      }

      /**
       * Handle element leaving or coming into view
       *
       * @param  {Boolean} visible  If the element is visible
       */
      function inView(visible) {
        $scope.visible = visible;
        refreshIntervals();
      }

      // ------------------------
      //   Private Functions
      // ------------------------

      /**
       * Handle external model changes
       *
       * @param  {*} value    New model value
       * @return {*}          Formatted model value
       */
      function onExternalChange(value) {
        $scope.job = value;
        // Update attributes
        updateErrorCount();
        updateOwner();
        updateStatus();
        updateDuration();
        fetchAttachments();
        // Refresh intervals
        refreshIntervals();
        return value;
      }

      /**
       * Handle scope $destroy event
       */
      function onDestroy() {
        if (updateInterval) $interval.cancel(updateInterval);
        if (durationInterval) $interval.cancel(durationInterval);
      }

      /**
       * Download provided documents
       */
      function download(documents) {
        var locals = {
          docs: documents,
        };
        DownloadDocumentDialog.show({ locals: locals }).catch(
          ToastService.showError
        );
      }

      /**
       * Update job
       */
      function updateJob() {
        AdminJobService.getById($scope.job._id)
          .then(function (job) {
            $scope.job = job;
            ngModelCtrl.$setViewValue(
              angular.extend(ngModelCtrl.$modelValue, $scope.job)
            );
            updateErrorCount();
            updateStatus();
            updateDuration();
            refreshIntervals();
            fetchAttachments();
          })
          .catch(ToastService.showError);
      }

      /**
       * Update job owner
       */
      function updateOwner() {
        $q.resolve($scope.getOwner({ accountId: $scope.job.cre_account }))
          .then(function (owner) {
            $scope.owner = owner;
          })
          .catch(function () {
            $scope.owner = { email: "Unavailable" };
          });
      }

      /**
       * Update status and active based on new job
       */
      function updateStatus() {
        $scope.active =
          $scope.job.status === "pending" || $scope.job.status === "processing";
        $scope.status =
          $scope.job.status === "errored" ? "failed" : $scope.job.status;
      }

      /**
       * Fetch documents linked to the job iff the job has completed
       */
      function fetchAttachments() {
        var isExport = $scope.job.type === "export";
        var isCompleted = $scope.job.status === "completed";
        if ((isExport && !isCompleted) || !$scope.building) return;

        var params = {
          entity_id: $scope.job._id,
          entity_type: "job",
        };

        AttachmentService.getAll($scope.building._id, params)
          .then(function (attachments) {
            if (!angular.isEmpty(attachments)) {
              var documents = [];
              for (var i = 0; i < attachments.length; i += 1) {
                documents.push(attachments[i].document);
              }
              $scope.documents = documents;
            }
          })
          .catch(ToastService.showError);
      }

      /**
       * Update job's error count
       * If the job has errored, set to length of error log
       * If the job has errored steps, set to number of errored steps
       */
      function updateErrorCount() {
        if ($scope.job.status === "errored") {
          $scope.errCount = $scope.job.error_log.length;
        } else if ($scope.job.has_errored_steps) {
          $scope.errCount = $scope.job.num_errored_steps;
        }
      }

      /**
       * Refresh intervals and determine, based on visibilty and status,
       * which ones should start or stop
       */
      function refreshIntervals() {
        if (!$scope.job) return;
        // Update duration
        if ($scope.job.status === "processing") {
          startDurationInterval();
        } else {
          stopDurationInterval();
        }
        // Update job
        if ($scope.visible && $scope.active) {
          startUpdateInterval();
        } else {
          stopUpdateInterval();
        }
      }

      /**
       * Increment job duration by one second
       */
      function incrementDuration() {
        $scope.job.duration += 1000;
        updateDuration();
      }

      /**
       * Update job duration element
       */
      function updateDuration() {
        if (!$scope.job.duration) return;
        var filtered = $filter("duration")($scope.job.duration, "h:mm:ss");
        $duration.attr("datetime", filtered);
        $duration.html(filtered);
      }

      /**
       * Start duration update interval
       */
      function startDurationInterval() {
        if (!durationInterval) {
          durationInterval = $interval(incrementDuration, 1000);
        }
      }

      /**
       * Stop duration update interval
       */
      function stopDurationInterval() {
        if (durationInterval) {
          $interval.cancel(durationInterval);
          durationInterval = null;
        }
      }

      /**
       * Start the job update interval
       */
      function startUpdateInterval() {
        if (!updateInterval) {
          updateInterval = $interval(updateJob, UPDATE_INTERVAL);
        }
      }

      /**
       * Stop the job update interval
       */
      function stopUpdateInterval() {
        if (updateInterval) {
          $interval.cancel(updateInterval);
          updateInterval = null;
        }
      }
    }
  }
})();
