(function () {
  angular
    .module("akitabox.desktop.directives.list.schedule", [
      "akitabox.core.constants",
      "akitabox.core.services.flag",
      "akitabox.core.services.schedule",
      "akitabox.core.services.timeZone",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.bulkAssign",
      "akitabox.ui.dialogs.schedule",
      "angular.filter",
    ])
    .directive("abxScheduleList", AbxScheduleListDirective);

  /* @ngInject */
  function AbxScheduleListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/schedule/schedule-list.html",
      controller: ScheduleListController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        status: "=abxStatus",
        parentFetch: "&abxFetch",
        parentFetchAll: "&abxFetchAll",
        allowAdd: "&?abxAllowAdd",
        createOptions: "=?abxCreateOptions",
      },
    };
  }

  /* @ngInject */
  function ScheduleListController(
    // Angular
    $scope,
    $timeout,
    // Constants
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
    MAINTENANCE_SCHEDULE_STATUS_CANCELED,
    // Dialogs
    BulkAssignDialog,
    CancelScheduleDialog,
    DeleteScheduleDialog,
    EditScheduleFrequencyDialog,
    ReopenScheduleDialog,
    // Services
    BuildingService,
    FeatureFlagService,
    OrganizationService,
    ScheduleService,
    ToastService,
    UserService
  ) {
    var self = this;

    var userPermissions = UserService.getPermissions();

    // Attributes
    self.permissions = getDefaultPermissions();
    self.organization = OrganizationService.getCurrent();
    self.buildings = {};

    // Functions
    self.getListActions = getListActions;
    self.getSchedules = getSchedules;
    self.getAllSchedules = getAllSchedules;
    self.getScheduleFrequencySummary = ScheduleService.getFrequencySummary;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watchCollection("vm.models", function (models) {
        if (models && !self.building) {
          BuildingService.populateBuildings(self.buildings, models);
        }
      });
      self.permissions.canCancel = userPermissions.future_task.cancel;
      self.permissions.canReactivate = userPermissions.future_task.reopen;
      self.permissions.canAssign = userPermissions.future_task.update;
      self.permissions.canEdit = userPermissions.future_task.update;
      self.permissions.canDelete = userPermissions.future_task.remove;
    }

    function getDefaultPermissions() {
      return {
        canCancel: false,
        canReactivate: false,
        canAssign: false,
        canEdit: false,
        canDelete: false,
      };
    }

    function cancelSchedules(items) {
      var locals = {
        schedules: items.map(function (item) {
          var schedule = self.models[item];
          return {
            _id: schedule._id,
            building: { _id: schedule.building },
          };
        }),
      };
      return CancelScheduleDialog.show({ locals: locals })
        .then(function (canceledScheduleIDs) {
          // update self.models to remove the relevant maintenance schedules
          if (self.models.length === canceledScheduleIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (schedule) {
              return canceledScheduleIDs.indexOf(schedule._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function reactivateSchedules(items) {
      var locals = {
        schedules: items.map(function (item) {
          var schedule = self.models[item];
          return {
            _id: schedule._id,
            building: { _id: schedule.building },
          };
        }),
      };
      return ReopenScheduleDialog.show({ locals: locals })
        .then(function (removedScheduleIDs) {
          // update self.models to remove the relevant maintenance schedules
          if (self.models.length === removedScheduleIDs.length) {
            self.models.length = 0;
          } else {
            self.models = self.models.filter(function (schedule) {
              return removedScheduleIDs.indexOf(schedule._id) < 0;
            });
          }
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function deleteSchedule(items) {
      var index = items[0];
      var schedule = self.models[index];
      var scheduleId = schedule._id;
      var locals = {
        schedule: {
          _id: schedule._id,
          building: schedule.building,
          number: schedule.number,
        },
      };
      return DeleteScheduleDialog.show({ locals: locals })
        .then(function () {
          // update self.models to remove the relevant maintenance schedule
          self.models = self.models.filter(function (schedule) {
            return schedule._id !== scheduleId;
          });
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    function editScheduleFrequency(items) {
      var index = items[0];
      var schedule = self.models[index];

      var locals = {
        schedule: schedule,
      };
      return EditScheduleFrequencyDialog.show({ locals: locals }).then(
        function (updatedSchedule) {
          self.models[index] = angular.copy(updatedSchedule, schedule);
        }
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getListActions(items) {
      var actions = [];
      var cancelAction = {
        text: "Cancel",
        icon: "close",
        onClick: cancelSchedules,
      };
      var reactivateAction = {
        text: "Reactivate",
        icon: "refresh",
        onClick: reactivateSchedules,
      };
      var assignAction = {
        text: "Assign",
        icon: "person",
        onClick: bulkAssignSchedules,
      };
      var editFrequencyAction = {
        text: "Edit Frequency",
        icon: "today",
        onClick: editScheduleFrequency,
      };
      var deleteAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteSchedule,
      };

      var isActive = self.status === MAINTENANCE_SCHEDULE_STATUS_ACTIVE;
      var isCanceled = self.status === MAINTENANCE_SCHEDULE_STATUS_CANCELED;

      if (isActive) {
        if (self.permissions.canCancel) {
          actions.push(cancelAction);
        }
        if (self.permissions.canAssign) {
          actions.push(assignAction);
        }
        if (self.permissions.canEdit && items.length === 1) {
          actions.push(editFrequencyAction);
        }
      } else if (isCanceled) {
        if (self.permissions.canReactivate) {
          actions.push(reactivateAction);
        }
        if (self.permissions.canDelete && items.length === 1) {
          actions.push(deleteAction);
        }
      }

      return actions;
    }

    function getSchedules(skip, limit) {
      return self.parentFetch({
        skip: skip,
        limit: limit,
      });
    }

    function getAllSchedules(limit) {
      return self.parentFetchAll({
        limit: limit,
      });
    }

    /**
     * Handles spawning the bulk assign dialog to help users assign in batches
     *
     * @param {Array<number>} items - array of the indexes for the selected items in the list
     *
     * @return {Promise<void>}
     */
    function bulkAssignSchedules(items) {
      var maintenanceSchedulesToAssign = items.map(function (item) {
        return self.models[item];
      });
      var buildings = maintenanceSchedulesToAssign.map(function (schedule) {
        return schedule.building;
      });
      var options = {
        locals: {
          // this needs to be passed directly, do not pass in a copy or the
          // dialog will not be able to update the assignees correctly within
          // this list
          buildings: buildings,
          maintenanceSchedules: self.models,
          selectedIndexes: items,
        },
      };

      return BulkAssignDialog.show(options)
        .catch(ToastService.showError)
        .finally(function () {
          $timeout(function () {
            $scope.$broadcast("list:setSelectedIndices", items);
            $scope.$broadcast("list:refreshClickEvents");
          });
        });
    }
  }
})();
