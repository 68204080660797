import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import { Plugin } from "@ckeditor/ckeditor5-core";
import { api } from "../../api";

export class ExportPdfButton extends Plugin {
  init() {
    const editor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add("abxExportPdf", () => {
      // The button will be an instance of ButtonView.
      const button = new ButtonView();

      button.set({
        icon: `<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"><path d="M19 4.5 14 0H3v6.5h1.5v-5h8v5h5V11H19V4.5ZM14 2l3.3 3H14V2Z"></path><path d="m12.452 18.5 1.25 1.5H3v-3h1.5v1.5h7.952Z"></path><path d="M19.826 16.843a.75.75 0 1 0-1.152-.96L17.5 17.29V13H16v4.29l-1.174-1.408a.75.75 0 1 0-1.152.96l2.346 2.816a.95.95 0 0 0 1.46 0l2.346-2.815Z"></path><path d="M1.63 14.24V12.3h.88c.66 0 1.14-.15 1.46-.45.32-.3.48-.71.48-1.24 0-.52-.15-.91-.44-1.2C3.7 9.15 3.28 9 2.69 9H0v5.24h1.63Zm.4-3h-.4v-1.17h.46c.3 0 .5.05.62.17.1.11.16.25.16.42 0 .16-.06.3-.19.41-.13.11-.34.16-.66.16l.01.01Zm5.7 3c.28 0 .6-.05.96-.14.26-.07.5-.21.73-.42.22-.2.4-.47.53-.77.12-.31.19-.75.19-1.3 0-.37-.04-.7-.13-1.02a2.3 2.3 0 0 0-.42-.84c-.19-.24-.43-.43-.72-.56C8.57 9.06 8.2 9 7.72 9h-2.4v5.24H7.73Zm-.4-1.19h-.4v-2.86h.4c.43 0 .73.1.91.3.18.2.27.59.27 1.14 0 .42-.04.73-.12.91a.76.76 0 0 1-.34.4c-.14.07-.38.11-.72.11Zm5.3 1.2V12.1h2.02v-1.06h-2.03v-.91H15V9h-4v5.24h1.62l.01.01Z"></path></svg>`,
        label: "Export PDF",
        tooltip: true,
      });

      button.on("execute", () => {
        button.isEnabled = false;
        // need the org and reort id from our config
        const exportPdfConfig = editor.config.get("abxExportPdf");
        if (!exportPdfConfig) {
          button.isEnabled = true;
          return;
        }
        // get our content
        api.reportContent
          .getById({
            organizationId: exportPdfConfig.organizationId,
            reportId: exportPdfConfig.reportId,
          })
          .then((response) => {
            const {
              data: { content },
            } = response;
            if (!content) {
              // eslint-disable-next-line no-console
              console.log("no content to print");
              button.isEnabled = true;
              return;
            }

            const config = editor.config.get("exportPdf");
            return editor.execute("exportPdf", {
              ...config,
              dataCallback: () => atob(content),
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            button.isEnabled = true;
          });
      });

      return button;
    });
  }

  _readBlob(b: Blob): Promise<string> {
    return new Promise(function (resolve) {
      const reader = new FileReader();

      reader.onloadend = function () {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(b);
    });
  }
}
