(function () {
  angular
    .module("akitabox.ui.directives.placesAutocomplete", [
      "akitabox.core",
      "akitabox.core.services.map",
    ])
    .directive("abxPlacesAutocomplete", AbxPlacesAutocompleteDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.abxPlacesAutocomplete
   * @name AbxPlacesAutocompleteDirective
   *
   * @restrict E
   *
   * @description
   * `<abx-places-autocomplete>` is an autocomplete that suggests locations for the building. The ng-model is the
   * variable that will be reverted to on cancel.
   *
   *
   * @usage
   * <hljs lang="html">
   *
   *   <abx-input-section
   *          abx-form-name="buildingAddressForm"
   *          abx-editable="vm.canChangeName"
   *          abx-submit="vm.updateBuildingAddress()"
   *          abx-on-cancel="vm.onAddressCancel">
   *      <abx-places-autocomplete id="address" name="address"
   *              abx-building="vm.building"
   *              ng-model="vm.place"
   *              abx-label="{{$root.MODEL.BUILDING.FIELDS.ADDRESS}}"
   *              required
   *              disabled="vm.saving">
   *      </abx-places-autocomplete>
   *  </abx-input-section>
   *
   * </hljs>
   */
  function AbxPlacesAutocompleteDirective($timeout) {
    return {
      restrict: "E",
      templateUrl:
        "app/core/ui/directives/places-autocomplete/places-autocomplete.html",
      require: ["abxPlacesAutocomplete", "ngModel"],
      controller: AbxPlacesAutocompleteController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        label: "@?abxLabel",
        building: "<?abxBuilding",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      var placeholder = attrs.placeholder;

      var $input;

      // Functions
      vm.setModelValue = setModelValue;

      init();

      /**
       * Initialize the element, watch properties, and add event listeners
       */
      function init() {
        attrs.$set("abx-custom-input", "");

        // Watch for model changes
        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        attachPropertyWatchers();

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and handle events
          $input = angular.element($element.find("input")[0]);
          if ($input) $input.attr("placeholder", placeholder);
        });
      }

      /**
       * Handle external changes to the model
       *
       * @param   {Object} value  The new model value
       * @returns {Object}        The formatted model value
       */
      function onExternalChange(value) {
        vm.place = angular.copy(value);
        return value;
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Readonly
        $scope.$watch(function () {
          return $element[0].attributes.readonly;
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
        vm.ngModelCtrl.$render();
      }

      /**
       * Set readonly
       *
       * @param {String} value    Attribute value
       */
      function setReadonly(value) {
        vm.readonly = Boolean(value);
      }

      /**
       * Set disabled, if building doesn't exist value is ignored
       * and disabled is set to true
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        vm.disabled = value;
      }
    }
  }

  /**
   * @ngdoc controller
   * @module akitabox.ui.directives.AbxPlacesAutocomplete
   * @name AbxPlacesAutocompleteController
   *
   * @description
   * Controller for the note input directive
   *
   * @ngInject
   */
  function AbxPlacesAutocompleteController(
    MapService,
    $stateParams,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.label = self.label || "Address";
    self.disabled = false;
    self.readonly = true;
    self.required = false;
    self.dialog = false;
    self.accountId = $stateParams.accountId;
    // self.types is passed to the places service to limit the kinds of results we get back to specific types.
    self.types = ["street_address", "route", "locality", "premise"];
    self.searchText = null;
    self.places = [];

    // Functions
    self.queryPlaces = queryPlaces;
    self.selectedItemChange = selectedItemChange;

    function selectedItemChange(place) {
      if (place) {
        if (place.place_id) {
          MapService.getPlaceDetails(place.place_id, place.description)
            .then(function (placeDetail) {
              self.place = placeDetail;
              self.setModelValue(self.place);
            })
            .catch(function (error) {
              self.place = null;
              self.selected = null;
              ToastService.showError(error);
            });
        }
      } else {
        self.setModelValue(null);
      }
    }

    function queryPlaces(searchText) {
      if (!searchText) {
        return [];
      }

      // handle the case where we have to put in an empty first character of the searchText to avoid [Object object]
      // (https://github.com/angular/material/issues/3760)
      if (searchText.charAt(0) === "\b") {
        searchText = searchText.substring(1);
      }

      self.loading = true;

      return MapService.getPlacePredictions(searchText, self.types)
        .then(function (results) {
          self.places = results;
          return self.places || [];
        })
        .catch(function (error) {
          self.places = [];
          return [];
        })
        .finally(function () {
          self.loading = false;
          return self.places || [];
        });
    }
  }
})();
