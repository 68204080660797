(function () {
  angular
    .module("akitabox.core.services.session", [
      "akitabox.core.services.identity",
    ])
    .factory("SessionService", SessionService);

  /* @ngInject */
  function SessionService(
    // Third Party
    store,
    // Services
    IdentityService,
    // Constants
    SESSION_KEYS
  ) {
    var service = {
      getBuildingId: getBuildingId,
      setBuildingId: setBuildingId,
      setDashboardCardStates: setDashboardCardStates,
      getDashboardCardStates: getDashboardCardStates,
      getOrganizationId: getOrganizationId,
      setOrganizationId: setOrganizationId,
      setPlanViewFilters: setPlanViewFilters,
      getPlanViewFilters: getPlanViewFilters,
      getReportingFilters: getReportingFilters,
      setReportingFilters: setReportingFilters,
      getInspectionProgramDrawerState: getInspectionProgramDrawerState,
      setInspectionProgramDrawerState: setInspectionProgramDrawerState,
      getWOCalendarState: getWOCalendarState,
      setWOCalendarState: setWOCalendarState,
      getStartDateColumnState: getStartDateColumnState,
      setStartDateColumnState: setStartDateColumnState,
      getWorkOrderSortColumn: getWorkOrderSortColumn,
      setWorkOrderSortColumn: setWorkOrderSortColumn,
      getWorkOrderSortOrder: getWorkOrderSortOrder,
      setWorkOrderSortOrder: setWorkOrderSortOrder,
      getCostDialogOrganizationId: getCostDialogOrganizationId,
      setCostDialogOrganizationId: setCostDialogOrganizationId,
      clearSession: clearSession,
      getRoomListSidebarToggleState: getRoomListSidebarToggleState,
      setRoomListSidebarToggleState: setRoomListSidebarToggleState,
      getAssetListSidebarToggleState: getAssetListSidebarToggleState,
      setAssetListSidebarToggleState: setAssetListSidebarToggleState,
    };

    return service;

    // =================
    // Public Functions
    // =================

    // -----------------
    // Building
    // -----------------

    /**
     * Retrieves the building id the user has last selected
     */
    function getBuildingId() {
      var key = SESSION_KEYS.SELECTED_BUILDING_KEY;
      return getSession(key, null);
    }

    /**
     * sets the building id when a user makes a selection.
     * @param {string} buildingId: the id that is stored in the session store
     */
    function setBuildingId(buildingId) {
      var key = SESSION_KEYS.SELECTED_BUILDING_KEY;
      saveSession(key, buildingId);
    }

    // -----------------
    // Dashboard Cards
    // -----------------

    /**
     * Retrieves the dashboard card states for organization or building
     *
     * @return {Object} Saved dashboard card states
     */
    function getDashboardCardStates() {
      var key = SESSION_KEYS.DASHBOARD_CARD_STATES;
      var cardStates = getSession(key, {});
      var orgId = service.getOrganizationId();
      var orgStates = orgId ? cardStates[orgId] : null;

      if (angular.isEmpty(orgStates)) {
        orgStates = {};
      }

      return orgStates;
    }

    /**
     * Store a set of dashboard card states for the organization or building
     *
     * @param {Object} states New dashboard card states
     */
    function setDashboardCardStates(states) {
      var key = SESSION_KEYS.DASHBOARD_CARD_STATES;
      var cardStates = getSession(key, {});
      var orgId = service.getOrganizationId();
      if (orgId) {
        cardStates[orgId] = states;
        saveSession(key, cardStates);
      }
    }

    // -----------------
    // Inspection Program Instruction Drawer
    // -----------------

    /**
     * Retrieves the dashboard card states for organization or building
     *
     * @return {Object} Saved dashboard card states
     */
    function getInspectionProgramDrawerState() {
      var key = SESSION_KEYS.INSPECTION_PROGRAM_INSTRUCTION_DRAWER;
      var drawerState = getSession(key, {});

      return drawerState;
    }

    /**
     * Store a set of dashboard card states for the organization or building
     *
     * @param {Object} states New dashboard card states
     */
    function setInspectionProgramDrawerState(state) {
      var key = SESSION_KEYS.INSPECTION_PROGRAM_INSTRUCTION_DRAWER;
      saveSession(key, state);
    }

    // -----------------
    // Organization
    // -----------------

    /**
     * Retrieves the organization id the user has last selected
     */
    function getOrganizationId() {
      var key = SESSION_KEYS.SELECTED_ORGANIZATION_KEY;
      return getSession(key, null);
    }

    /**
     * sets the organization id when a user makes a selection.
     * @param {string} organizationId: the id that is stored in the session store
     */
    function setOrganizationId(organizationId) {
      var key = SESSION_KEYS.SELECTED_ORGANIZATION_KEY;
      saveSession(key, organizationId);
    }

    // -----------------
    // Plan View Filters
    // -----------------

    /**
     * Retrieves the filters stored for a given building
     *
     * @param {string} buildingId   ID of the building to get filters for
     */
    function getPlanViewFilters(buildingId) {
      var key = SESSION_KEYS.SELECTED_PLANVIEW_FILTERS;
      var planViewFilters = getSession(key, {});

      if (planViewFilters[buildingId]) {
        return planViewFilters[buildingId];
      }

      return null;
    }

    /**
     * Store a set of filters for a particular building
     *
     * @param {string} buildingId   ID of the building where filters are saved
     * @param {Object} filters      Filter object to be saved
     */
    function setPlanViewFilters(buildingId, filters) {
      var key = SESSION_KEYS.SELECTED_PLANVIEW_FILTERS;
      var planViewFilters = getSession(key, {});
      planViewFilters[buildingId] = filters;
      saveSession(key, planViewFilters);
    }

    // -----------------
    // Reporting Filters
    // -----------------

    /**
     * Retrieves the dashboard card states for organization
     *
     * @return {Object} Saved dashboard card states
     */
    function getReportingFilters() {
      var key = SESSION_KEYS.REPORTING_FILTERS;
      var filters = getSession(key, {});
      var orgId = service.getOrganizationId();
      var orgStates = orgId ? filters[orgId] : null;

      if (angular.isEmpty(orgStates)) {
        orgStates = {};
      }

      return orgStates;
    }

    /**
     * Store a set of dashboard card states for the organization
     *
     * @param {Object} states New dashboard card states
     */
    function setReportingFilters(filters) {
      var key = SESSION_KEYS.REPORTING_FILTERS;
      var reportingFilters = getSession(key, {});
      var orgId = service.getOrganizationId();
      if (orgId) {
        reportingFilters[orgId] = filters;
        saveSession(key, reportingFilters);
      }
    }

    // ---------------------
    // Work Order Calendar
    // ---------------------

    /**
     * Retrieves the open state of the Work Order Calendar
     */
    function getWOCalendarState() {
      var key = SESSION_KEYS.WO_CALENDAR_KEY;
      return !!getSession(key, null);
    }

    /**
     * Sets the open state of the Work Order Calendar.
     * @param {boolean} isCalendarOpen: is the calendar displayed? true = open.
     */
    function setWOCalendarState(isCalendarOpen) {
      var key = SESSION_KEYS.WO_CALENDAR_KEY;
      saveSession(key, !!isCalendarOpen);
    }

    // ---------------------
    // Start Date Column
    // ---------------------

    /**
     * Retrieves the display state of the Start Date Column
     */
    function getStartDateColumnState() {
      var key = SESSION_KEYS.START_DATE_COLUMN_KEY;
      return !!getSession(key, null);
    }

    /**
     * Sets the display state of the Start Date Column.
     * @param {boolean} isDisplayingStartColumn: is the column displayed? true = yes.
     */
    function setStartDateColumnState(isDisplayingStartColumn) {
      var key = SESSION_KEYS.START_DATE_COLUMN_KEY;
      saveSession(key, !!isDisplayingStartColumn);
    }

    // ---------------------
    // Work Order Sort
    // ---------------------

    /**
     * Retrieves the saved work order sort column
     */
    function getWorkOrderSortColumn(showStartDate, list) {
      var defaults = {
        open: showStartDate ? "start_date" : "due_date",
        scheduled: "due_date",
        canceled: "canceled_date",
        completed: "completed_date",
      };
      var key = SESSION_KEYS.SAVED_WORK_ORDER_SORT_COLUMN;
      var savedSettings = getSession(key, defaults);
      return savedSettings[list];
    }

    /**
     * Saves the work order sort column
     */
    function setWorkOrderSortColumn(showStartDate, list, column) {
      var defaults = {
        open: showStartDate ? "start_date" : "due_date",
        scheduled: "due_date",
        canceled: "canceled_date",
        completed: "completed_date",
      };
      var key = SESSION_KEYS.SAVED_WORK_ORDER_SORT_COLUMN;
      var savedSettings = getSession(key, defaults);

      savedSettings[list] = column;
      return saveSession(key, savedSettings);
    }

    /**
     * Gets the work order sort order
     */
    function getWorkOrderSortOrder(list) {
      var defaults = {
        open: "asc",
        scheduled: "asc",
        canceled: "asc",
        completed: "asc",
      };
      var key = SESSION_KEYS.SAVED_WORK_ORDER_SORT_ORDER;
      var savedSettings = getSession(key, defaults);
      return savedSettings[list];
    }

    /**
     * Saves the work order sort order
     */
    function setWorkOrderSortOrder(list, order) {
      var defaults = {
        open: "asc",
        scheduled: "asc",
        canceled: "asc",
        completed: "asc",
      };
      var key = SESSION_KEYS.SAVED_WORK_ORDER_SORT_ORDER;
      var savedSettings = getSession(key, defaults);

      savedSettings[list] = order;
      return saveSession(key, savedSettings);
    }

    /**
     * Retrieves the organization id the user has last selected in the Asset Cost Dialog
     */
    function getCostDialogOrganizationId() {
      const key = SESSION_KEYS.SELECTED_COST_ORGANIZATION_KEY;
      return getSession(key, null);
    }

    /**
     * sets the organization id when a user makes a selection  in the Asset Cost Dialog.
     * @param {string} organizationId: the id that is stored in the session store
     */
    function setCostDialogOrganizationId(organizationId) {
      const key = SESSION_KEYS.SELECTED_COST_ORGANIZATION_KEY;
      saveSession(key, organizationId);
    }

    // -------------------------
    // Asset List Sidebar Toggle
    // -------------------------

    /**
     * Retrieves the state of the Asset list sidebar toggle
     */
    function getAssetListSidebarToggleState() {
      var key = SESSION_KEYS.ASSET_LIST_SIDEBAR_TOGGLE_STATE;
      return getSession(key, null);
    }

    /**
     * Sets the state of the Asset list sidebar toggle
     *
     * @param {boolean} state whether is it active or not
     */
    function setAssetListSidebarToggleState(state) {
      var key = SESSION_KEYS.ASSET_LIST_SIDEBAR_TOGGLE_STATE;
      saveSession(key, state);
    }

    // ------------------------
    // Room List Sidebar Toggle
    // ------------------------

    /**
     * Retrieves the state of the Room list sidebar toggle
     */
    function getRoomListSidebarToggleState() {
      var key = SESSION_KEYS.ROOM_LIST_SIDEBAR_TOGGLE_STATE;
      return getSession(key, null);
    }

    /**
     * Sets the state of the Room list sidebar toggle
     *
     * @param {boolean} state whether is it active or not
     */
    function setRoomListSidebarToggleState(state) {
      var key = SESSION_KEYS.ROOM_LIST_SIDEBAR_TOGGLE_STATE;
      saveSession(key, state);
    }

    /**
     * Clear the session from localStorage for the current user
     *
     * @param {string}  key           Session object key
     * @param {*}       defaultValue  Default for session key if not set
     */
    function clearSession() {
      var userId = IdentityService.getUserId();
      store.remove(SESSION_KEYS.SESSION_PREFIX + userId);
    }

    // =================
    // Private Functions
    // =================

    /**
     * Update a particular portion (key) of the user's session
     *
     * @param {string} key Session object key
     * @param {*} data Session data to save
     */
    function saveSession(key, data) {
      var session = _getSession() || {};
      session[key] = data;
      _saveSession(session);
    }

    /**
     * Get a particular portion (key) of the user's session
     *
     * @param {string}  key           Session object key
     * @param {*}       defaultValue  Default for session key if not set
     */
    function getSession(key, defaultValue) {
      var session = _getSession() || {};
      if (!session[key]) {
        session[key] = defaultValue;
      }
      return session[key];
    }

    /**
     * Get the session object from localStorage for the current user
     */
    function _getSession() {
      // Get the current user id
      var userId = IdentityService.getUserId();
      return store.get(SESSION_KEYS.SESSION_PREFIX + userId);
    }

    /**
     *  Save the session object to localstorage for this user
     *
     * @param {Object} session  Object that represents user's current session
     */
    function _saveSession(session) {
      var userId = IdentityService.getUserId();
      store.set(SESSION_KEYS.SESSION_PREFIX + userId, session);
    }
  }
})();
