(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.roomAssetRelationship
   *
   * @param {Object} asset  Asset if asset
   * @param {Object} room  Room if room
   * @param {Object} associations  Array of associations/relationships tied to this asset or room
   */
  angular
    .module("akitabox.ui.components.roomAssetRelationship", [
      "akitabox.core.services.association",
      "akitabox.core.services.organization",
      "akitabox.core.services.pinType",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.association",
    ])
    .component("abxRoomAssetRelationship", {
      bindings: {
        asset: "<?abxAsset",
        room: "<?abxRoom",
      },
      controller: AbxRoomAssetRelationshipController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/room-asset-relationship/room-asset-relationship.component.html",
    });

  /* @ngInject */
  function AbxRoomAssetRelationshipController(
    // Angular
    $log,
    $q,
    $window,
    // Services
    EnvService,
    AssociationDialog,
    // Services
    AssociationService,
    BuildingService,
    OrganizationService,
    PinTypeService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.showRelationships = false;
    self.showSetUp = false;
    self.organization = OrganizationService.getCurrent();
    self.chartHeader = "Room & Asset Relationships";
    self.setUpMessage =
      "Add room and asset connections to quickly see which assets relate to one another";
    self.pinTypes = {};
    self.entityId = null;
    self.buildingsWithoutPermissionCount = 0;
    self.relationshipsWithoutPermissionCount = 0;
    self.messageAboutMissingRelationships = "";

    // Functions
    self.getPinTypeIcon = getPinTypeIcon;
    self.linkToRoomOrAsset = linkToRoomOrAsset;

    self.showAssociationDialog = function () {
      AssociationDialog.show({
        locals: {
          model: self.asset || self.room,
          modelType: self.asset ? "Asset" : "Room",
          associations: self.associations,
          messageAboutMissingRelationships:
            self.messageAboutMissingRelationships,
        },
      })
        .then(getAssociations)
        .catch(ToastService.showError);
    };

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.asset && !self.room) {
        $log.error(
          "abxRoomAssetRelationship: abx-asset or abx-room is required"
        );
      }
      if (self.asset) {
        self.entityId = self.asset._id;
      } else if (self.room) {
        self.entityId = self.room._id;
      }

      getAssociations();
    };

    self.$onChanges = function (changes) {
      if (changes.asset || changes.room) {
        self.entityId = changes.asset
          ? changes.asset.currentValue._id
          : changes.room.currentValue._id;

        getAssociations();
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function getPinTypeIcon(pinTypeId) {
      var pinType = self.pinTypes[pinTypeId];
      if (pinType) return pinType.icon;
    }

    function linkToRoomOrAsset(association) {
      var entity =
        association.upstream_entity._id !== self.entityId
          ? association.upstream_entity
          : association.downstream_entity;
      var entityModel =
        association.upstream_entity._id !== self.entityId
          ? association.upstream_entity_model
          : association.downstream_entity_model;
      var buildingId = entity.building._id || entity.building;

      $window.open(
        EnvService.getCurrentBaseUrl() +
          "/buildings/" +
          buildingId +
          "/" +
          entityModel.toLowerCase() +
          "s/" +
          entity._id +
          "/overview",
        "_blank"
      );
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getMessageAboutMissingRelationships() {
      return self.relationshipsWithoutPermissionCount > 0
        ? AssociationService.getMessageAboutMissingRelationships(
            self.relationshipsWithoutPermissionCount,
            self.buildingsWithoutPermissionCount
          )
        : "";
    }

    function getAssociations() {
      self.loading = true;
      self.showRelationships = false;
      self.showSetUp = false;

      /** @type {Array} */
      let retrievedAssociations;

      let hasCrossBuildingAssociations = false;

      return AssociationService.getAll(self.organization._id, {
        $or:
          "upstream_entity=" +
          self.entityId +
          ";downstream_entity=" +
          self.entityId,
      })
        .then((_associations) => {
          retrievedAssociations = _associations;
          if (retrievedAssociations.length === 0) {
            return;
          }
          hasCrossBuildingAssociations = retrievedAssociations.some(
            (item) =>
              item.downstream_entity.building !== item.upstream_entity.building
          );
          if (!hasCrossBuildingAssociations) {
            return;
          }
          return BuildingService.getAll();
        })
        .then((userBuildings) => {
          if (!hasCrossBuildingAssociations) {
            return retrievedAssociations;
          }

          // Filter out associations the user should have no access to.
          const buildingsWithoutPermission = {};
          const userBuildingIdList = userBuildings.map((item) => item._id);
          const filteredAssociations = retrievedAssociations.filter((item) => {
            const isUpstreamIncluded = userBuildingIdList.includes(
              item.upstream_entity.building
            );
            if (!isUpstreamIncluded) {
              // Takes the opportunity to add the building to the list of buildingsWithoutPermission.
              buildingsWithoutPermission[item.upstream_entity.building] = true;
            }
            const isDownstreamIncluded = userBuildingIdList.includes(
              item.downstream_entity.building
            );
            if (!isDownstreamIncluded) {
              // Takes the opportunity to add the building to the list of buildingsWithoutPermission.
              buildingsWithoutPermission[
                item.downstream_entity.building
              ] = true;
            }
            self.buildingsWithoutPermissionCount = Object.keys(
              buildingsWithoutPermission
            ).length;
            return isUpstreamIncluded && isDownstreamIncluded;
          });
          self.relationshipsWithoutPermissionCount =
            retrievedAssociations.length - filteredAssociations.length;
          self.messageAboutMissingRelationships =
            getMessageAboutMissingRelationships();
          return filteredAssociations;
        })
        .then(function (associations) {
          var promises = [];
          associations.map(function (association) {
            var buildingId;
            var pinTypeId;

            if (association.downstream_entity._id === self.entityId) {
              buildingId = association.upstream_entity.building;
              pinTypeId = association.upstream_entity.pinType;
            } else {
              buildingId = association.downstream_entity.building;
              pinTypeId = association.downstream_entity.pinType;
            }

            if (!self.pinTypes[pinTypeId]) {
              promises.push(
                PinTypeService.getById(buildingId, pinTypeId).then(function (
                  pinType
                ) {
                  self.pinTypes[pinType._id] = pinType;
                })
              );
            }
          });

          return $q.all(promises).then(function () {
            return associations;
          });
        })
        .then(function (associations) {
          self.associations = sortAssociations(associations);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;

          if (self.associations.length) {
            self.showRelationships = true;
          } else {
            self.showSetUp = true;
          }
        });
    }

    function sortAssociations(associations) {
      // First group entities by association type, show downstream text first
      var groupedEntitiesByAssociationType =
        groupEntitiesByAssociationType(associations);
      var groupedAssociations = {};
      for (var property in groupedEntitiesByAssociationType) {
        var groupedEntitiesDownstreamAndUpstream =
          groupEntitiesDownstreamAndUpstream(
            groupedEntitiesByAssociationType[property]
          );

        var downstreamEntities =
          groupedEntitiesDownstreamAndUpstream.downstream;
        var upstreamEntities = groupedEntitiesDownstreamAndUpstream.upstream;
        var sortingText = downstreamEntities[0]
          ? downstreamEntities[0].association_type.upstream_text
          : upstreamEntities[0].association_type.upstream_text;

        var sortedDownstreamEntities = downstreamEntities.sort(function (a, b) {
          // Association Text
          var associationTextA =
            a.association_type.downstream_text.toLowerCase();
          var associationTextB =
            b.association_type.downstream_text.toLowerCase();
          // Association Downstream Entity Name
          var downstreamEntityNameA = a.downstream_entity.name.toLowerCase();
          var downstreamEntityNameB = b.downstream_entity.name.toLowerCase();

          // Sort by association text
          if (associationTextA > associationTextB) {
            return 1;
          } else if (associationTextA < associationTextB) {
            return -1;
          } else {
            // Then sort by entity name
            if (downstreamEntityNameA > downstreamEntityNameB) {
              return 1;
            } else if (downstreamEntityNameA < downstreamEntityNameB) {
              return -1;
            }
          }
        });

        var sortedUpstreamEntities = upstreamEntities.sort(function (a, b) {
          // Association Text
          var associationTextA = a.association_type.upstream_text.toLowerCase();
          var associationTextB = b.association_type.upstream_text.toLowerCase();
          // Association Upstream Entity Name
          var upstreamEntityNameA = a.upstream_entity.name.toLowerCase();
          var upstreamEntityNameB = b.upstream_entity.name.toLowerCase();

          // Sort by association text
          if (associationTextA > associationTextB) {
            return 1;
          } else if (associationTextA < associationTextB) {
            return -1;
          } else {
            // Then sort by entity name
            if (upstreamEntityNameA > upstreamEntityNameB) {
              return 1;
            } else if (upstreamEntityNameA < upstreamEntityNameB) {
              return -1;
            }
          }
        });

        groupedAssociations[sortingText] = [
          sortedDownstreamEntities,
          sortedUpstreamEntities,
        ];
      }

      // Sort all groups of associations alphabetically by their downstream text
      var sortedAssociations = Object.keys(groupedAssociations)
        .sort(function (a, b) {
          /**
           * Make sure we ignore case sensitive sorting
           * ie: we want "bar, Foo" instead of "Foo, bar"
           * */
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        .reduce(function (obj, key) {
          obj[key] = groupedAssociations[key];
          return obj;
        }, {});

      // Combine all of the arrays that were seperate for sorting
      var arrayOfSortedAssociations = [];
      for (var key in sortedAssociations) {
        sortedAssociations[key].forEach(function (assoc) {
          arrayOfSortedAssociations = arrayOfSortedAssociations.concat(assoc);
        });
      }

      // Hooray, this is finally the list of associations
      return arrayOfSortedAssociations;
    }

    function groupEntitiesByAssociationType(associations) {
      return associations.reduce(function (result, currentValue) {
        (result[currentValue.association_type._id] =
          result[currentValue.association_type._id] || []).push(currentValue);
        return result;
      }, {});
    }

    function groupEntitiesDownstreamAndUpstream(associations) {
      var downstream = [];
      var upstream = [];

      for (var i = 0; i < associations.length; i++) {
        if (self.entityId === associations[i].upstream_entity._id) {
          downstream.push(associations[i]);
        } else {
          upstream.push(associations[i]);
        }
      }
      return { downstream: downstream, upstream: upstream };
    }
  }
})();
