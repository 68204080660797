(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.yAxisSwitcher
   *
   * @param {Object} options - options to display in the y-axis switcher
   */
  angular
    .module("akitabox.ui.components.reportingCharts.yAxisSwitcher", [])
    .component("abxYAxisSwitcher", {
      bindings: {
        options: "<abxOptions",
        activeOption: "<abxActiveOption",
        onChange: "&abxOnChange",
      },
      controller: AbxYAxisSwitcherController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/y-axis-switcher/y-axis-switcher.component.html",
    });
  function AbxYAxisSwitcherController() {}
})();
