(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.datepicker
   */
  angular
    .module("akitabox.ui.directives.datepicker", [
      "ngMaterial",
      "akitabox.core.utils",
    ])
    .directive("abxDatepicker", AbxDatepickerDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.datePicker
   * @name AbxDatepickerDirective
   *
   * @description
   * `<abx-datepicker>` is component used to select a date.
   *
   * @param {String}                      placeholder     Input placeholder
   * @param {Date}                        abx-min-date    Minimim date
   * @param {Date}                        abx-max-date    Maximim date
   * @param {(function(Date): boolean)}   abx-date-filter Function expecting a
   *                                                      date and returning a
   *                                                      boolean whether it can
   *                                                      be selected or not
   * @param {String}                      abx-date-format Date format to use
   *
   * The abx-date-format will also be used to verify if the date is valid
   * and cannot be incomplete or backwards. That is, year cannot preceed month
   * and month cannot preceed day of month. All formats must contain year and
   * if day of month is specified the format must also contain month.
   *
   * This component supports [ngMessages](https://docs.angularjs.org/api/ngMessages/directive/ngMessages).
   * Supported attributes are:
   * * `date`: whether the input is a valid date
   * * `required`: whether a required date is not set.
   * * `mindate`: whether the selected date is before the minimum allowed date.
   * * `maxdate`: whether the selected date is after the maximum allowed date.
   *
   * @usage
   * <hljs lang="html">
   *   <md-input-container>
   *     <label for="birthday">Birthday</label>
   *     <abx-datepicker ng-model="birthday"></md-datepicker>
   *   </md-input-container>
   * </hljs>
   *
   * @ngInject
   */
  function AbxDatepickerDirective($filter, Utils) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/date-picker/date-picker.html",
      require: ["abxDatepicker", "ngModel", "?^mdInputContainer", "?^^form"],
      controller: AbxDatepickerController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        placeholder: "@",
        minDate: "<?abxMinDate",
        maxDate: "<?abxMaxDate",
        dateFilter: "=?abxDateFilter",
        dateFormat: "@?abxDateFormat",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Constants
      var VALIDITY_FIELDS = ["mindate", "maxdate", "filtered", "date"];
      var DELIMETER = /[ .,]+|[/-]/;

      // Date filter
      var ngDateFilter = $filter("date");

      // UI elements
      var $input;

      // Controllers
      var vm = controllers[0];
      var ngModelCtrl = controllers[1];
      var mdInputContainer = controllers[2];
      var formCtrl = controllers[3];

      var internalChange = false;

      // Timezone
      vm.timezone = Utils.getModelOption(ngModelCtrl, "timezone");

      // Debounced functions
      var debouncedUpdateErrorState = angular.debounce(updateErrorState, 250);
      var debouncedParseInputValue = angular.debounce(parseInputValue, 500);

      // Expose updating model value to controller
      vm.setModelValue = setModelValue;
      vm.onExternalChange = onExternalChange;

      init();

      /**
       * Initialize the datepicker element, parsed min/max dates,
       * and configure the input element
       */
      function init() {
        attrs.$set("type", "date");
        attrs.$set("abx-custom-input", "");

        // Parse min date
        if (angular.isDefined(attrs.abxMinDate)) {
          $scope.$watch("vm.minDate", function (value) {
            if (angular.isEmpty(value)) {
              vm.parsedMinDate = null;
            } else {
              vm.parsedMinDate = parseDate(value);
            }
          });
        } else {
          vm.parsedMinDate = null;
        }

        // Parse max date
        if (angular.isDefined(attrs.abxMaxDate)) {
          $scope.$watch("vm.maxDate", function (value) {
            if (angular.isEmpty(value)) {
              vm.parsedMaxDate = null;
            } else {
              vm.parsedMaxDate = parseDate(value);
            }
          });
        } else {
          vm.parsedMaxDate = null;
        }

        configureInput();
      }

      /**
       * Configure the nested input element for internal and external changes
       */
      function configureInput() {
        // Add external change listener
        ngModelCtrl.$formatters.push(onExternalChange);
        ngModelCtrl.$viewChangeListeners.push(function () {
          if (!internalChange) onExternalChange(ngModelCtrl.$modelValue);
          internalChange = false;
        });

        // Add internal change listener
        $input = angular.element($element.find("input")[0]);
        $input.on("input", onInputChange);
        $input.on("blur", onBlur);

        attachPropertyWatchers();

        if (mdInputContainer) {
          // Move error spacer
          var $spacer = $element[0].querySelector(".md-errors-spacer");
          if ($spacer) $element.after(angular.element("<div>").append($spacer));
          // Set container input element
          mdInputContainer.input = $element;
          // Observe required attribute
          attrs.$observe("required", function (value) {
            if (mdInputContainer && mdInputContainer.label) {
              mdInputContainer.label.toggleClass("md-required", value);
            }
          });
          // Add md-input-container error pass-through for validation
          $scope.$watch(
            mdInputContainer.isErrorGetter ||
              function () {
                return (
                  ngModelCtrl.$invalid &&
                  (ngModelCtrl.$touched || (formCtrl && formCtrl.$submitted))
                );
              },
            mdInputContainer.setInvalid
          );
        }
      }

      /**
       * Handle external value changes
       *
       * @param  {*} value    New value
       * @return {*}          Parsed value
       */
      function onExternalChange(value) {
        var isEmpty = angular.isEmpty(value);
        mdInputContainer.setHasValue(!isEmpty);

        var parsed = isEmpty ? null : parseDate(value);
        if (parsed) {
          $input.val(ngDateFilter(parsed, vm.dateFormat, vm.timezone));
        } else {
          $input.val(null);
        }

        vm.date = parsed;

        updateErrorState(parsed);

        return parsed;
      }

      /**
       * Handle internal input value change
       */
      function onInputChange() {
        invalidate();
        var value = $input.val();
        debouncedParseInputValue(value);
        debouncedUpdateErrorState(value);
      }

      function parseInputValue(value) {
        internalChange = true;
        if (angular.isEmpty(value)) {
          vm.date = null;
          setModelValue(null);
        } else if (isDateComplete(value)) {
          var parsed = parseDate(
            ngDateFilter(value, vm.dateFormat, vm.timezone)
          );
          if (parsed) {
            vm.date = parsed;
            setModelValue(vm.date);
          } else {
            vm.date = null;
            setModelValue(value);
          }
        } else {
          vm.date = null;
          setModelValue(value);
        }
      }

      /**
       * Set the elemet model value
       *
       * @param {*} value     New value
       */
      function setModelValue(value) {
        ngModelCtrl.$setViewValue(value);
      }

      /**
       * Invalidate the model, parent form, and input container
       */
      function invalidate() {
        formCtrl.$invalid = true;
        ngModelCtrl.$invalid = true;
        if (mdInputContainer) mdInputContainer.setInvalid(true);
      }

      /**
       * Detemine if a date is valid
       *
       * @param  {*}          date    Date bject
       *
       * @return {Boolean}            True if valid, false if not
       */
      function isValidDate(date) {
        return date && date.getTime && !isNaN(date.getTime());
      }

      /**
       * Detemine if a date is complete
       *
       * @param  {String}  dateString     Date string
       *
       * @return {Boolean}                True if complete, false if not
       */
      function isDateComplete(dateString) {
        dateString = dateString.trim();

        var chunks = vm.dateFormat.split(DELIMETER);

        var three =
          /^(([a-zA-Z]{3,}|[0-9]{1,4})([ .,]+|[/-])){2}([a-zA-Z]{3,}|[0-9]{1,4})$/;
        var two =
          /^([a-zA-Z]{3,}|[0-9]{1,4})([ .,]+|[/-])([a-zA-Z]{3,}|[0-9]{1,4})$/;
        var one = /^[0-9]{2,4}$/;

        switch (chunks.length) {
          case 1:
            return one.test(dateString);
          case 2:
            return two.test(dateString);
          case 3:
            return three.test(dateString);
          default:
            return false;
        }
      }

      /**
       * Set a date to the start of it's day
       *
       * @param  {Date} date      Date
       *
       * @return {Date}           Modified date
       */
      function getStartOfDay(date) {
        return new Date(ngDateFilter(date, vm.dateFilter, vm.timezone));
      }

      /**
       * Parse a date
       *
       * @param  {*}      date   Date object or date string
       *
       * @return {Date}          Parsed date
       */
      function parseDate(value) {
        var parsed = value;
        if (angular.isString(value)) {
          var chunks = value.trim().split(DELIMETER);
          if (chunks.length === 1) {
            // Add month and day (January 1st)
            chunks.push("01", "01");
          } else if (chunks.length === 2) {
            // Add day (1st of month)
            chunks.splice(1, 0, "01");
          } else {
            // Remove time
            var year = chunks[2];
            if (year.indexOf("T") > -1) {
              chunks[2] = year.split("T")[0];
            }
            if (chunks.length === 4) chunks.pop();
          }
          parsed = new Date(chunks.join("/"));
        } else if (angular.isNumber(value)) {
          parsed = new Date(value);
        }
        return isValidDate(parsed) ? getStartOfDay(parsed) : null;
      }

      /**
       * Update the error state of the element adn set model validity
       *
       * @param  {*} value    New value
       */
      function updateErrorState(value) {
        var date = value ? parseDate(value) : vm.date;
        var valid = isValidDate(date);
        if (angular.isString(value)) valid = valid && isDateComplete(value);

        clearErrorState();
        ngModelCtrl.$setValidity("date", valid || angular.isEmpty(value));

        if (value && valid) {
          date = getStartOfDay(date);
          // Min date
          if (vm.parsedMinDate) {
            ngModelCtrl.$setValidity("mindate", date >= vm.parsedMinDate);
          }
          // Max date
          if (valid && vm.parsedMaxDate) {
            ngModelCtrl.$setValidity("maxdate", date <= vm.parsedMaxDate);
          }
          // Filtered
          if (valid && angular.isFunction(vm.dateFilter)) {
            ngModelCtrl.$setValidity("filtered", vm.dateFilter(date));
          }
        }

        // Update input container
        if (mdInputContainer)
          mdInputContainer.setInvalid(
            ngModelCtrl.$invalid && ngModelCtrl.$touched
          );
      }

      /**
       * Clear the elements error state, reset validators
       */
      function clearErrorState() {
        for (var i = 0; i < VALIDITY_FIELDS.length; ++i) {
          ngModelCtrl.$setValidity(VALIDITY_FIELDS[i], true);
        }
      }

      /**
       * Handle input focused
       */
      function onBlur() {
        ngModelCtrl.$setTouched();
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Readonly
        $scope.$watch(function () {
          return $element[0].attributes.readonly;
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          var placeholder = $element[0].attributes.placeholder;
          return placeholder ? placeholder.value : "";
        }, setPlaceholder);
      }

      /**
       * Set nested inputs "readonly" property
       *
       * @param {String} value    New value
       */
      function setReadonly(value) {
        if (value) {
          $input.attr("readonly", "readonly");
        } else {
          $input.removeAttr("readonly");
        }
      }

      /**
       * Set nested inputs "disabled" property
       *
       * @param {String} value    New value
       */
      function setDisabled(value) {
        if (value !== null && value !== "false") {
          $input.attr("disabled", "disabled");
        } else {
          $input.removeAttr("disabled");
        }
      }

      /**
       * Set nested inputs "placeholder" property
       *
       * @param {String} value    New value
       */
      function setPlaceholder(value) {
        $input.attr("placeholder", value);
      }
    }
  }

  /**
   * Controller for abx-datepicker
   *
   * @ngInject
   */
  function AbxDatepickerController($mdPanel, moment) {
    var self = this;

    /**
     * Initial panel configuration
     * @type {Object}
     */
    var panelConfig = getPanelConfig();

    // Attributes
    self.date = new Date();
    self.dateFormat = angular.isString(self.dateFormat)
      ? self.dateFormat
      : "MM/dd/yyyy";

    // Attributes
    self.showCalendarPanel = showCalendarPanel;

    /**
     * Show the calendar panel
     *
     * @param  {Object} $event  Event to open the panel from
     */
    function showCalendarPanel($event) {
      // Prevents enter from triggering md-button clicks by blurring the button
      $event.currentTarget.blur();

      panelConfig.openFrom = $event;

      // Set position relative to event target
      panelConfig.position = $mdPanel
        .newPanelPosition()
        .relativeTo($event.target)
        .addPanelPosition(
          $mdPanel.xPosition.ALIGN_END,
          $mdPanel.yPosition.BELOW
        );

      panelConfig.locals = {
        date: self.date,
        minDate: self.parsedMinDate,
        maxDate: self.parsedMaxDate,
        dateFilter: self.dateFilter,
      };

      $mdPanel.open(panelConfig);
    }

    /**
     * Get the initial panel configuration
     *
     * @return {Object}     Panel configuration
     */
    function getPanelConfig() {
      return {
        attachTo: angular.element(document.body),
        controller: CalendarPanelCtrl,
        controllerAs: "panel",
        templateUrl: "app/core/ui/directives/date-picker/calendar.tmpl.html",
        panelClass: "abx-calendar-panel",
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: false,
        zIndex: 2000,
      };
    }

    /* @ngInject */
    function CalendarPanelCtrl(mdPanelRef, $scope) {
      $scope.$watch("panel.date", function (newValue, oldValue) {
        if (newValue === oldValue) return;

        // set new value to correct time zone offset
        var newValueInTimeZone = moment(newValue)
          .utcOffset(self.timezone, true)
          .toDate();

        // Set model value, invoking parent ngChange
        self.setModelValue(newValueInTimeZone);
        // Update input value
        self.onExternalChange(newValueInTimeZone);
        // Close the panel
        mdPanelRef.close();
      });
    }
  }
})();
