angular.module("templates", []).run([
  "$templateCache",
  function ($templateCache) {
    const templates = require.context("./", true, /(?<!index)\.html$/);
    templates.keys().map((fileName) => {
      if (fileName.endsWith("index.html")) return;
      const cachedName = fileName.substr(2); // remove the leading "./"
      $templateCache.put(cachedName, templates(fileName));
    });
  },
]);
