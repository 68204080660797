(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.woIntentChart
   *
   * @param {Object} building - building to build the chart with
   */
  angular
    .module("akitabox.ui.components.reportingCharts.woIntentChart", [
      "akitabox.constants",
      "akitabox.core.services.chart",
      "akitabox.ui.directives.donutChart",
    ])
    .component("abxWoIntentChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
      },
      controller: AbxWoIntentChartController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/wo-intent-chart/wo-intent-chart.component.html",
    });
  function AbxWoIntentChartController(
    $log,
    // Constants
    SOURCES,
    models,
    // Services
    ChartService
  ) {
    var self = this;
    var config = {};

    // Attributes
    self.idTag = "wo-intent-chart";
    self.data = [];
    self.innerData = [];
    self.loading = true;
    self.showChart = false;
    self.outerColors = ["#1C5076", "#2F9462"];

    var intentData = [];
    var sourceData = [];

    var totalReactive = 0;
    var totalPreventive = 0;

    var sourceTemplate = [
      {
        name: "Service Requests",
        value: 0,
        parent: "Reactive",
        parentValue: 0,
      },
      {
        name: "Reactive Self-Identified",
        value: 0,
        parent: "Reactive",
        parentValue: 0,
      },
      {
        name: "Maintenance Schedules",
        value: 0,
        parent: "Preventive",
        parentValue: 0,
      },
      {
        name: "Preventive Self-Identified",
        value: 0,
        parent: "Preventive",
        parentValue: 0,
      },
    ];
    var sourceToIntent = {
      "Service Requests": "Reactive",
      "Reactive Self-Identified": "Reactive",
      "Maintenance Schedules": "Preventive",
      "Preventive Self-Identified": "Preventive",
    };
    var sourceOrder = {
      request: 0,
      reactive_self_identified: 1,
      future_task: 2,
      preventive_self_identified: 3,
    };
    var intentOrder = {
      reactive: 0,
      preventive: 1,
    };

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxWoIntentChart: abx-buildings is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        config = ChartService.getChartConfig(
          self.buildings,
          models.WORK_ORDER.MODEL
        );
      }
      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings
      ) {
        calculateStats();
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    // ------------------------
    //   Private Functions
    // ------------------------

    function setShowChart() {
      self.showChart = self.data && self.data.length;
    }

    function calculateStats() {
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      )
        return;
      self.loading = true;
      var dateRange = ChartService.buildDateString(
        self.startDate,
        self.endDate
      );
      var intentParams = {
        group_field: "intent",
        completed_date: dateRange,
      };
      var sourceParams = {
        group_field: "source",
        completed_date: dateRange,
      };

      if (config.buildingInString) {
        intentParams.buildings = config.buildingInString;
        sourceParams.buildings = config.buildingInString;
      }

      intentParams = angular.extend({}, intentParams, self.filters);
      sourceParams = angular.extend({}, sourceParams, self.filters);

      config
        .statsFunction(config.parentId, intentParams)
        .then(function (results) {
          intentData = [];
          if (!results || !results.length || results[0].result === 0) {
            return;
          }
          sortData(results, intentOrder, "_id");

          if (results.length > 1) {
            for (const result of results) {
              if (result._id === "reactive") {
                totalReactive = result.result;
              } else if (result._id === "preventive") {
                totalPreventive = result.result;
              }
            }
            var total = totalReactive + totalPreventive;
            intentData = [
              {
                name: "Reactive",
                value: roundPercent(totalReactive, total),
                total: totalReactive,
              },
              {
                name: "Preventive",
                value: roundPercent(totalPreventive, total),
                total: totalPreventive,
              },
            ];
          } else if (results[0]._id === "reactive") {
            totalReactive = results[0].result;
            total = totalReactive;
            intentData = [
              {
                name: "Reactive",
                value: 100,
                total: totalReactive,
              },
              {
                name: "Preventive",
                value: 0,
                total: 0,
              },
            ];
          } else if (results[0]._id === "preventive") {
            totalPreventive = results[0].result;
            intentData = [
              {
                name: "Reactive",
                value: 0,
                total: 0,
              },
              {
                name: "Preventive",
                value: 100,
                total: totalPreventive,
              },
            ];
          }
        })
        .then(function () {
          return config.statsFunction(config.parentId, sourceParams);
        })
        .then(function (results) {
          sourceData = [];
          if (!results || !results.length || results[0].result === 0) {
            return;
          }
          sortData(results, sourceOrder, "_id");
          var j = 0;
          for (var i = 0; i < sourceTemplate.length; ++i) {
            var source = angular.extend({}, sourceTemplate[i]);
            if (results[j] && source.name === SOURCES[results[j]._id]) {
              var result = results[j];
              source.parent = sourceToIntent[source.name];
              source.parentValue =
                intentData[intentOrder[source.parent.toLowerCase()]].total;
              source.value = roundPercent(result.result, source.parentValue);
              j++;
            }
            sourceData.push(source);
          }
        })
        .finally(function () {
          self.data = intentData;
          self.innerData = sourceData;
          self.loading = false;
          setShowChart();
        });
    }

    function sortData(data, order, field) {
      data.sort(function (a, b) {
        return order[a[field]] - order[b[field]];
      });
    }

    function roundPercent(dividend, divisor) {
      if (!divisor) {
        return 0;
      }
      var quotient = dividend / divisor;
      return Math.round(quotient * 100);
    }
  }
})();
