import { FunctionComponent, useState } from "react";
import { SWRConfig } from "swr";
export const WebClientSWRConfig: FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [config, _setConfig] = useState(() => ({
    provider: () => new Map(),
  }));
  return <SWRConfig value={config}>{children}</SWRConfig>;
};
