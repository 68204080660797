(function () {
  /**
   * @ngdoc component
   * @name abxRoundSidebar
   *
   * @param {Object} task     round work order
   * @param {Object} floor    Current floor
   * @param {Object} pins     Map of pins in inspection by _id
   * @param {Object} stops    Inspection checklists
   * @param {($task) => Promise<void> } onTaskChange Function to be invoked after the
   *  round task has been changed. Invoked with the updated task.
   * @param {(status: string | string[]) => void} onVisibilityToggle - Function to be
   *  invoked when a checklist status pin visibility is toggled. Invoked with
   *  the status(es).
   * @param {(status: string | string[]) => boolean} getIsStatusVisible - Getter fn
   *  to determine if a checklist status' pins are currently visible or hidden.
   *
   * @description
   * Sidebar for round task information and steps
   */
  angular.module("akitabox.planView").component("abxRoundSidebar", {
    bindings: {
      task: "<abxTask",
      floor: "<abxFloor",
      allStops: "<abxAllStops",
      pinTypes: "<abxPinTypes",
      isLockedOpen: "<abxIsLockedOpen",
      onTaskChange: "<abxOnTaskChange",
      inspectionProgram: "<abxInspectionProgram",
      onVisibilityToggle: "&abxOnVisibilityToggle",
      getIsStatusVisible: "&abxGetIsStatusVisible",
      onAttachmentUploadStart: "<?abxOnAttachmentUploadStart",
      onAttachmentUploadsFinished: "<?abxOnAttachmentUploadsFinished",
    },
    controller: AbxRoundSidebarController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/round-sidebar/round-sidebar.component.html",
  });

  /** @ngInject */
  function AbxRoundSidebarController(
    // Angular
    $element,
    $log,
    $q,
    $scope,
    $stateParams,
    // AkitaBox
    models,
    EVENT_ROUND_TASK_MOVE_STOP,
    EVENT_ROUND_TASK_STOP_SELECTED,
    EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES,
    EVENT_ROUND_TASK_PIN_COLOR_UPDATE,
    // Services
    AttachmentService,
    BuildingService,
    ChecklistService,
    ChecklistTemplateService,
    FeatureFlagService,
    FloorService,
    NoteService,
    OrganizationService,
    ToastService,
    UserService,
    WorkOrderService,
    // Dialogs
    CompleteWorkOrderDialog,
    WorkOrderLogDialog
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.loadingStops = false;
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.floors = null;
    self.todoStops = [];
    self.skippedStops = [];
    self.completeStops = [];
    self.todoStopsCount = 0;
    self.skippedStopsCount = 0;
    self.completedStopsCount = 0;
    self.selectedStop = null;
    self.bulkSelectedStops = null;
    self.expandTodoList = false;
    self.expandSkipList = false;
    self.expandCompleteList = false;
    self.attachmentData = null;
    self.noteData = null;
    self.checklistTemplates = null;
    self.hasAlreadyLoaded = false;

    /**
     * This is just an array of all statuses that are lumped together as "done".
     * Just used as a convenience to avoid some really ugly stuff in the template.
     */
    self.completedStatuses = [
      models.CHECKLIST.STATUSES.PASS,
      models.CHECKLIST.STATUSES.FAIL,
      models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO,
      models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO,
      models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO,
    ];

    // Functions

    // Dialogs
    self.showWorkOrderCompleteDialog = showWorkOrderCompleteDialog;
    self.showLogWorkDialog = showLogWorkDialog;

    // Queries
    self.getPinType = getPinType;
    self.getNumAttachments = getNumAttachments;
    self.getNumNotes = getNumNotes;
    self.getNumWorkOrders = getNumWorkOrders;
    self.getNumExternalCMMSWorkOrders = getNumExternalCMMSWorkOrders;
    self.fetchStops = fetchStops;

    // Updates
    self.updateNumAssociatedItems = updateNumAssociatedItems;

    // Bulk editing
    self.bulkEdit = bulkEdit;
    self.onBulkEditClosed = onBulkEditClosed;

    self.setTodoExpanded = setTodoExpanded;
    self.setSkipExpanded = setSkipExpanded;
    self.setCompletedExpanded = setCompletedExpanded;

    self.canReopen = function () {
      return self.task.is_open || self.task.is_overdue;
    };

    // =================
    // Life Cycle
    // =================
    self.$onInit = function () {
      $scope.$on(EVENT_ROUND_TASK_MOVE_STOP, onMoveStop);
      $scope.$on(EVENT_ROUND_TASK_STOP_SELECTED, setSelectedStop);
      // Fetch floors and attachment/note counts
      var floorsRequest = FloorService.getAll(self.building._id)
        .then(function (floors) {
          // Create a lookup map of floors by ID
          self.floors = floors.reduce(function (map, floor) {
            map[floor._id] = floor;
            return map;
          }, {});
        })
        .catch(ToastService.showError);
      var attachmentStatsRequest = FeatureFlagService.isEnabled(
        "jit_checklists"
      )
        ? $q.resolve()
        : fetchAttachmentsData();
      if (FeatureFlagService.isEnabled("jit_checklists") && self.allStops) {
        initializeAttachmentDataFromStopsList();
      }
      var noteStatsRequest = fetchNotesData();

      /**
       * Check to see if user is trying to access a single stop or the list
       * Handle grabbing the possible single stop here and showing it
       */
      var selectedChecklistId = $stateParams.stop;
      var checklistRequest = $q.resolve(); // if there is none in url, just resolve
      const locationId =
        $stateParams.asset || $stateParams.room || $stateParams.level;
      if (
        selectedChecklistId ||
        (FeatureFlagService.isEnabled("jit_checklists") && locationId)
      ) {
        checklistRequest = FeatureFlagService.isEnabled("jit_checklists")
          ? ChecklistService.getByBuildingWorkOrder(
              self.building._id,
              self.task._id,
              $stateParams.asset || null,
              $stateParams.room || null,
              $stateParams.level || null
            )
          : ChecklistService.getById(
              self.organization._id,
              selectedChecklistId
            );
        checklistRequest.then(function (checklist) {
          if (checklist) {
            self.hasAlreadyLoaded = true;
            self.selectedStop = checklist;
            $scope.$emit(EVENT_ROUND_TASK_STOP_SELECTED, {
              stop: checklist,
              forceStatChangeOverride: true,
            });
          }
        });
      }
      $q.all([
        checklistRequest,
        floorsRequest,
        attachmentStatsRequest,
        noteStatsRequest,
      ]).finally(function () {
        if (FeatureFlagService.isEnabled("jit_checklists")) {
          initializeListCounts().then(resetListsState);
        }
        self.loading = isLoading();
      });
    };

    function filterStopsByStatus(stops, type) {
      const statuses = ChecklistService.getStatusListByType(type);
      return stops.filter((stop) => statuses.includes(stop.status));
    }

    self.$onChanges = function (changes) {
      if (changes.task && self.task) {
        self.isTaskOpen = self.task.is_open || self.task.is_overdue;
        self.isTaskScheduled = self.task.is_scheduled;

        self.canLogWork = WorkOrderService.canLogWork(
          self.task,
          UserService.getCurrent()
        );

        // Get checklist templates for bulk editing
        fetchTemplateData().finally(function () {
          self.loading = isLoading();
        });

        if (!FeatureFlagService.isEnabled("jit_checklists")) {
          initializeListCounts().then(
            // Should be fine resetting these values here because the task only changes
            // when the user navigates to another task causing a full page reload
            resetListsState
          );
        }
      }

      if (changes.allStops && self.allStops) {
        initializeListCounts().then(resetListsState);
        if (FeatureFlagService.isEnabled("jit_checklists")) {
          initializeAttachmentDataFromStopsList();
        }
      }

      if (changes.isLockedOpen) {
        if (self.isLockedOpen) {
          lockSidebar();
        } else {
          unlockSidebar();
        }
      }

      self.loading = isLoading();
    };

    // =================
    // Public Functions
    // =================

    function initializeListCounts() {
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        self.todoStopsCount = filterStopsByStatus(self.allStops, "todo").length;
        self.skippedStopsCount = filterStopsByStatus(
          self.allStops,
          "skip"
        ).length;
        self.completedStopsCount = filterStopsByStatus(
          self.allStops,
          "complete"
        ).length;
        return $q.resolve();
      } else {
        // These counts are also dependent on self.task
        var todoCountRequest = fetchStopCount(
          models.CHECKLIST.STATUSES.IN_PROGRESS
        ).then(function (count) {
          self.todoStopsCount = count;
        });
        var skippedCountRequest = fetchStopCount(
          models.CHECKLIST.STATUSES.SKIP
        ).then(function (count) {
          self.skippedStopsCount = count;
        });
        var completedCountRequest = fetchStopCount(
          "$in," + self.completedStatuses.join(",")
        ).then(function (count) {
          self.completedStopsCount = count;
        });

        return $q.all([
          todoCountRequest,
          skippedCountRequest,
          completedCountRequest,
        ]);
      }
    }

    function initializeAttachmentDataFromStopsList() {
      self.attachmentData = self.allStops.reduce(function (map, stop) {
        // Make sure the _id of the stop (the checklist id) is available.
        if (stop._id) {
          map[stop._id] = stop.attachments_size;
        }
        return map;
      }, {});
    }

    function resetListsState() {
      self.expandTodoList = false;
      self.expandSkipList = false;
      self.expandCompleteList = false;
      if (self.todoStopsCount > 0 && self.task.status === "open") {
        self.expandTodoList = true;
      } else if (self.completedStopsCount > 0) {
        self.expandCompleteList = true;
      }
    }

    function showLogWorkDialog() {
      WorkOrderLogDialog.show({
        locals: {
          workOrder: self.task,
        },
      }).catch(ToastService.showError);
    }

    function showWorkOrderCompleteDialog() {
      var workOrder = self.task;
      if (!workOrder) {
        $log.error("abx-round-sidebar: no task to complete");
      }
      var locals = {
        workOrders: [workOrder],
      };

      CompleteWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          self.onTaskChange(workOrders[0]);
        })
        .catch(ToastService.showError);
    }

    /**
     * Get data for selected Stop
     * @param {Object} stop
     */
    function getPinType(stop) {
      var pin = stop.room || stop.asset;

      if (pin && pin.pinType._id) {
        return pin.pinType;
      } else if (pin && !pin.pinType._id) {
        for (var i = 0; i < self.pinTypes.length; i++) {
          var pinType = self.pinTypes[i];
          if (pin.pinType === pinType._id) {
            return pinType;
          }
        }
      }
      return null;
    }

    function getNumAttachments(stop) {
      // Make sure the _id of the stop (the checklist id) is available.
      if (self.attachmentData && stop._id) {
        return self.attachmentData[stop._id] || 0;
      }
      return 0;
    }

    function getNumNotes(stop) {
      if (self.noteData) {
        return self.noteData[stop._id] || 0;
      }
      return 0;
    }

    function getNumWorkOrders(stop) {
      return stop.reactive_inspection_tasks.length || 0;
    }

    function getNumExternalCMMSWorkOrders(stop) {
      if (stop.reactive_inspection_tasks_external_cmms) {
        return stop.reactive_inspection_tasks_external_cmms.length || 0;
      }
      return 0;
    }

    /**
     * Allows child component to update the count
     * @param {String} itemType
     */
    function updateNumAssociatedItems(itemType) {
      switch (itemType) {
        case "attachments":
          fetchAttachmentsData().catch(ToastService.showError);
          break;
        case "messages":
          fetchNotesData().catch(ToastService.showError);
          break;
        case "workOrders":
          getNumWorkOrders(self.selectedStop);
          break;
        case "externalCMMSWorkOrders":
          getNumExternalCMMSWorkOrders(self.selectedStop);
      }
    }

    function bulkEdit(stops) {
      self.bulkEditing = true;
      self.bulkSelectedStops = stops;
    }

    function onBulkEditClosed(stops) {
      if (stops && stops.length) {
        // Move changed stops
        self.bulkSelectedStops = [];
        for (var i = 0; i < stops.length; ++i) {
          var currentStop = stops[i];
          var pin = currentStop.asset || currentStop.room || currentStop.level;
          $scope.$emit(EVENT_ROUND_TASK_PIN_COLOR_UPDATE, {
            pinId: pin._id,
            color: pin.color,
            inspection_status: ChecklistService.getStatusClass(currentStop),
          });

          // The app component needs to know this as well so it can update the global pins with
          // these updated statuses
          // This will also call the event on itself, which it is listening to
          $scope.$emit(EVENT_ROUND_TASK_MOVE_STOP, {
            stop: currentStop,
            to: currentStop.status.toUpperCase(),
            from: models.CHECKLIST.STATUSES.IN_PROGRESS,
          });
        }
      }
      $scope.$emit(EVENT_ROUND_TASK_REAPPLY_VISIBILITY_TOGGLES);
      self.bulkEditing = false;
    }

    // =================
    // Private Functions
    // =================

    /**
     * Sets the selected Stop and hides all details
     * except for those relevant to that Stop
     *
     * @param {Object} data Event data
     */
    function setSelectedStop($event, data) {
      // Remove if condition and else block when JIT Checklists FF is removed
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        if (data && data.stop) {
          const stop = data.stop;
          const checklistRequest = self.hasAlreadyLoaded
            ? $q.resolve(stop)
            : ChecklistService.getByBuildingWorkOrder(
                self.building._id,
                self.task._id,
                stop.asset ? stop.asset._id : null,
                stop.room ? stop.room._id : null,
                stop.level ? stop.level._id : null
              );
          checklistRequest.then(function (stop) {
            self.selectedStop = stop;
            self.bulkEditing = false;
          });
        } else {
          self.hasAlreadyLoaded = false;
          self.selectedStop = null;
          self.bulkEditing = false;
        }
      } else {
        self.selectedStop = data.stop;
        self.bulkEditing = false;
      }
    }

    function fetchAttachmentsData() {
      return AttachmentService.getStatsByBuilding(self.building._id, {
        entity_type: "checklist",
        unwind_field: "links",
        group_field: "links.entity_id",
        delete_date: "null",
      }).then(function (stats) {
        // Create a lookup map of attachment counts by checklist ID
        self.attachmentData = stats.reduce(function (map, stat) {
          map[stat._id] = stat.result;
          return map;
        }, {});
      });
    }

    function fetchNotesData() {
      return NoteService.getStatsByBuilding(self.building._id, {
        checklist: "$ne,null",
        group_field: "checklist",
      }).then(function (stats) {
        // Create a lookup map of note counts by checklist ID
        self.noteData = stats.reduce(function (map, stat) {
          map[stat._id] = stat.result;
          return map;
        }, {});
      });
    }

    function fetchTemplateData() {
      return ChecklistService.getStats(self.organization._id, {
        task: self.task._id,
        status: models.CHECKLIST.STATUSES.IN_PROGRESS,
        group_field: "checklist_template",
      })
        .then(function (stats) {
          if (FeatureFlagService.isEnabled("jit_checklists")) {
            // TODO: to be fixed properly by WEBAPP-14825
            return {};
          } else {
            var templateIds = stats.map(function (stat) {
              return stat._id;
            });
            return ChecklistTemplateService.getAll(self.organization._id, {
              _id: "$in," + templateIds.join(","),
            });
          }
        })
        .then(function (templates) {
          self.checklistTemplates = templates;
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.checklistTemplates = [];
        });
    }

    function fetchStopCount(status) {
      return ChecklistService.count(self.organization._id, {
        task: self.task._id,
        status: status,
      });
    }
    function fetchStops(filters) {
      self.loadingStops = true;
      let getStopsPromise;
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        const statuses = filters.status.split(",");
        const stops = self.allStops.filter((stop) =>
          statuses.includes(stop.status)
        );
        getStopsPromise = $q.resolve(stops);
      } else {
        var fetchFilters = Object.assign({ task: self.task._id }, filters);
        getStopsPromise = ChecklistService.get(
          self.organization._id,
          fetchFilters
        );
      }
      return getStopsPromise
        .then(function (stops) {
          // Create map of pin types by ID
          var pinTypeLookup = self.pinTypes.reduce(function (map, pinType) {
            map[pinType._id] = pinType;
            return map;
          }, {});
          // Create map of checklists by location (floor, room, asset) ID
          var stopLookup = stops.reduce(function (map, stop) {
            var location = stop.room || stop.asset || stop.level;
            map[location._id] = {
              ...stop,
              locationId: location._id,
            };
            return map;
          }, {});
          // Separate stops (checklists) in the order they appear in the round
          var items = self.task.round;
          for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            var location = item.room || item.asset || item.level;
            var stop = stopLookup[location._id];

            if (!stop) continue;

            // Set pin type, color, and add to floor plan if on selected floor
            var pin = item.asset || item.room;
            if (pin) {
              pin.pinType = pin.pinType._id
                ? pin.pinType
                : pinTypeLookup[pin.pinType];
              // set the color and add the class to set the pass/fail icon
              pin.inspection_status = getChecklistStatusClass(stop);
              pin.checklist_status = stop.status.toUpperCase();
              // the checklist has the color of the room/asset populated, so we use that since the pins
              // don't normally come with it populated
              pin.color = stop.asset ? stop.asset.color : stop.room.color;
            }

            var isDupe;
            var k;

            switch (stop.status) {
              case models.CHECKLIST.STATUSES.IN_PROGRESS:
                /**
                 * We need to check here because after a user moves a stop (ex: TODO -> SKIP) and then
                 * opens the skip list for the first time, we need to do the initial fetch, but the lust can
                 * already contain items from the previous move. So we need to check for dupes here
                 */
                isDupe = false;
                for (k = 0; k < self.todoStops.length; k++) {
                  if (self.todoStops[k].locationId === stop.locationId) {
                    isDupe = true;
                    break;
                  }
                }
                if (!isDupe) {
                  self.todoStops.push(stop);
                }
                break;
              case models.CHECKLIST.STATUSES.SKIP:
                isDupe = false;
                for (k = 0; k < self.skippedStops.length; k++) {
                  if (self.skippedStops[k].locationId === stop.locationId) {
                    isDupe = true;
                    break;
                  }
                }
                if (!isDupe) {
                  self.skippedStops.push(stop);
                }
                break;
              default:
                isDupe = false;
                for (k = 0; k < self.completeStops.length; k++) {
                  if (self.completeStops[k].locationId === stop.locationId) {
                    isDupe = true;
                    break;
                  }
                }
                if (!isDupe) {
                  self.completeStops.push(stop);
                }
                break;
            }
          }

          return stops;
        })
        .finally(function () {
          self.loadingStops = false;
        });

      function getChecklistStatusClass(checklist) {
        switch (checklist.status) {
          case models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO:
          case models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO:
          case models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO:
          case models.CHECKLIST.STATUSES.FAIL:
            return "fail";
          case models.CHECKLIST.STATUSES.PASS:
            return "pass";
          case models.CHECKLIST.STATUSES.SKIP:
            return "skip";
          default:
            return undefined;
        }
      }
    }

    function isLoading() {
      return (
        !self.floors ||
        !self.attachmentData ||
        !self.noteData ||
        !self.checklistTemplates
      );
    }

    function lockSidebar() {
      self.isLockedOpen = true;
      $element.addClass("abx-sidebar");
    }

    function unlockSidebar() {
      self.isLockedOpen = false;
      $element.removeClass("abx-sidebar");
    }

    function onMoveStop($event, data) {
      moveStop(data.stop, data.to, data.from);
      updateListCounts(data.to, data.from);
      fetchTemplateData();
    }

    function updateListCounts(toStatus, fromStatus) {
      // Update the counts on each type of stops without grabbing from API, because
      // we can
      if (toStatus === models.CHECKLIST.STATUSES.IN_PROGRESS) {
        self.todoStopsCount++;
      } else if (toStatus === models.CHECKLIST.STATUSES.SKIP) {
        self.skippedStopsCount++;
      } else if (self.completedStatuses.indexOf(toStatus) !== -1) {
        self.completedStopsCount++;
      }

      if (fromStatus === models.CHECKLIST.STATUSES.IN_PROGRESS) {
        self.todoStopsCount--;
      } else if (fromStatus === models.CHECKLIST.STATUSES.SKIP) {
        self.skippedStopsCount--;
      } else if (self.completedStatuses.indexOf(fromStatus) !== -1) {
        self.completedStopsCount--;
      }

      if (FeatureFlagService.isEnabled("jit_checklists")) {
        if (self.todoStopsCount === 0) {
          self.expandTodoList = false;
        }
        if (self.skippedStopsCount === 0) {
          self.expandSkipList = false;
        }
        if (self.completedStopsCount === 0) {
          self.expandCompleteList = false;
        }
      }
    }

    function moveStop(stop, toStatus, fromStatus) {
      var sourceStops;
      var destStops;

      switch (toStatus) {
        case models.CHECKLIST.STATUSES.PASS:
        case models.CHECKLIST.STATUSES.FAIL:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO:
          sourceStops = self.todoStops;
          destStops = self.completeStops;
          break;
        case models.CHECKLIST.STATUSES.SKIP:
          sourceStops = self.todoStops;
          destStops = self.skippedStops;
          break;
        case models.CHECKLIST.STATUSES.IN_PROGRESS:
          sourceStops =
            fromStatus === models.CHECKLIST.STATUSES.SKIP
              ? self.skippedStops
              : self.completeStops;
          destStops = self.todoStops;
          break;
      }

      const location = stop.room || stop.asset || stop.level;
      var index = -1;
      for (var i = 0; i < sourceStops.length; i++) {
        const curStop = sourceStops[i];
        const curLocation = curStop.asset || curStop.room || curStop.level;
        const isSameStop = FeatureFlagService.isEnabled("jit_checklists")
          ? curLocation._id === location._id
          : curStop._id === stop._id;
        if (isSameStop) {
          index = i;
          break;
        }
      }

      if (index !== -1) {
        const [stopToBeMoved] = sourceStops.splice(index, 1);
        destStops.push(stopToBeMoved);
        switch (toStatus) {
          case models.CHECKLIST.STATUSES.PASS:
          case models.CHECKLIST.STATUSES.FAIL:
            self.todoStops = sourceStops.slice();
            self.completeStops = destStops.slice();
            break;
          case models.CHECKLIST.STATUSES.SKIP:
            self.todoStops = sourceStops.slice();
            self.skippedStops = destStops.slice();
            break;
          case models.CHECKLIST.STATUSES.IN_PROGRESS:
            if (fromStatus === models.CHECKLIST.STATUSES.SKIP) {
              self.skippedStops = sourceStops.slice();
            } else {
              self.completeStops = sourceStops.slice();
            }
            self.todoStops = destStops.slice();
            break;
        }
      }
    }

    function setTodoExpanded(isExpanded) {
      self.expandTodoList = isExpanded;
    }

    function setSkipExpanded(isExpanded) {
      self.expandSkipList = isExpanded;
    }

    function setCompletedExpanded(isExpanded) {
      self.expandCompleteList = isExpanded;
    }
  }
})();
