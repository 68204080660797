(function () {
  angular.module("akitabox.ui.dialogs.association", [
    "ngMaterial",
    "akitabox.core.services.association",
    "akitabox.core.services.associationType",
    "akitabox.core.services.building",
    "akitabox.core.services.floor",
    "akitabox.core.services.pinType",
    "akitabox.core.services.organization",
    "akitabox.core.services.room",
    "akitabox.core.toast",
    "akitabox.core.utils",
  ]);
})();
