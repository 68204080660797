(function () {
  angular
    .module("akitabox.ui.dialogs.externalWorkOrderLink")
    .controller(
      "WorkOrderExternalCMMSDialogController",
      WorkOrderExternalCMMSDialogController
    );

  /* @ngInject */
  function WorkOrderExternalCMMSDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // AkitaBox
    models,
    // Services
    ToastService,
    ChecklistService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.successful = false;
    self.formData = {
      text: "",
    };
    self.placeholder = "http://www.example.com";

    // Functions
    self.cancel = $mdDialog.cancel;
    self.addExternalWorkOrder = addExternalWorkOrder;
    self.editExternalWoLink = editExternalWoLink;
    self.removeExternalWoLink = removeExternalWoLink;
    self.isValid = isValid;

    // dialog is editing an existing link if an externalWoLink is passed in
    self.isEditing = self.externalWoLink ? true : false;
    self.headerText = self.isEditing ? "Edit " : "Add ";
    if (self.isEditing) {
      self.headerText = "Edit ";
      self.formData.text = self.externalWoLink.url;
    } else {
      self.headerText = "Add ";
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Checks if user can add a link to an external work order
     * the only check for now is the text length
     * @return {boolean}
     */
    function isValid() {
      return self.formData.text.length > 0;
    }

    /**
     * Sends API request to add a link to the external WO
     * @return {checklist}  updated checklist
     */
    function addExternalWorkOrder() {
      if (self.saving || self.successful) {
        return $q.resolve();
      }
      self.saving = true;
      var data = angular.copy(self.formData);

      var update = {
        external_task_link: { url: data.text },
        action: "addExternalWoLink",
      };

      return ChecklistService.update(
        self.checklist.organization,
        self.checklist._id,
        update
      )
        .then(function (checklist) {
          self.successful = true;
          $mdDialog.hide(checklist);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    function editExternalWoLink() {
      if (self.saving || self.successful) {
        return $q.resolve();
      }
      self.saving = true;

      var update = {
        external_task_to_update: self.externalWoLink._id,
        external_task_link: { url: self.formData.text },
        action: "editExternalWoLink",
      };

      return ChecklistService.update(
        self.checklist.organization,
        self.checklist._id,
        update
      )
        .then(function (checklist) {
          self.successful = true;
          $mdDialog.hide(checklist);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    function removeExternalWoLink() {
      if (self.saving || self.successful) {
        return $q.resolve();
      }
      self.saving = true;

      var update = {
        external_task_to_remove: self.externalWoLink._id,
        action: "removeExternalWoLink",
      };

      return ChecklistService.update(
        self.checklist.organization,
        self.checklist._id,
        update
      )
        .then(function (checklist) {
          self.successful = true;
          $mdDialog.hide(checklist);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
