import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

const RedirectBuildings = ({ path }: { path: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathMatch = location.pathname.match(
      new RegExp(`/buildings/([^/]+)/${path}`)
    );
    if (pathMatch) {
      const buildingId = pathMatch[1];
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("building", buildingId);
      navigate(`/${path}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [location, navigate, path]);

  return null;
};

RedirectBuildings.propTypes = {
  path: PropTypes.string.isRequired,
};

export default RedirectBuildings;
