(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.priorityIcon
   */
  angular
    .module("akitabox.ui.directives.priorityIcon", ["ngMaterial"])
    .directive("abxPriorityIcon", AbxPriorityIcon);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.PriorityIcon
   * @name AbxPriorityIcon
   * @restrict E
   *
   * @description
   * `abx-priority-icon` creates an icon for the priority
   *
   * @param {String} abx-priority   Priority
   * @param {String} abx-size       Icon size (default: 16)
   *
   * @usage
   * <hljs lang="html">
   *  <abx-priority-icon
   *    abx-priority="high"
   *    abx-size="18"
   *  >
   *  </abx-priority-icon>
   * </hljs>
   *
   * @ngInject
   */
  function AbxPriorityIcon(
    // Angular
    $http,
    $compile,
    $templateCache,
    $log
  ) {
    return {
      restrict: "E",
      link: postLink,
      scope: {
        priority: "<abxPriority",
        size: "@?abxSize",
      },
    };

    function postLink($scope, $element) {
      if (!$scope.size) {
        $scope.size = "16";
      }

      $scope.$watch("priority", compileTemplate);

      function compileTemplate(priority) {
        if (!priority) {
          priority = "unknown";
        }
        var base = "app/core/ui/directives/priority-icon/templates";
        var templateUrl =
          base + "/priority-" + priority.toLowerCase() + ".html";
        $http
          .get(templateUrl, {
            cache: $templateCache,
          })
          .then(function (response) {
            if (response.status === 200) {
              $element.html(response.data);
              $compile($element.contents())($scope);
            } else {
              $log.error(
                "<abx-priority-icon>: unable to retrieve '" + templateUrl + "'"
              );
            }
          })
          .catch(function () {
            $log.error(
              "<abx-priority-icon>: unable to retrieve '" + templateUrl + "'"
            );
          });
      }
    }
  }
})();
