(function () {
  angular
    .module("akitabox.core.services.job.job")
    .factory("JobService", JobService);

  /** @ngInject */
  function JobService(
    $q,
    $log,
    models,
    FileService,
    HttpService,
    OrganizationService
  ) {
    var service = {
      // Routes
      buildListRoute: buildListRoute,
      buildDetailRoute: buildDetailRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getById: getById,
      getAll: getAll,
    };

    var PIN_MODELS = [models.ASSET.MODEL, models.ROOM.MODEL];

    var exportableModels = [];
    var importableModels = [];
    var modelToRoutePlurals = {};

    init();

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      // build array of models that can be exported and imported
      // and a map between the model names and readable names
      var keys = Object.keys(models);
      for (var i = 0; i < keys.length; ++i) {
        var model = models[keys[i]];
        modelToRoutePlurals[model.MODEL] = model.ROUTE_PLURAL;
        var isPin = PIN_MODELS.indexOf(model.MODEL) !== -1;

        if (model.CAN_EXPORT) {
          exportableModels.push({
            name: model.PLURAL,
            model: model.MODEL,
            inBuilding: model.BUILDING,
          });
        }
        if (model.CAN_IMPORT && !isPin) {
          importableModels.push({
            name: model.PLURAL,
            model: model.MODEL,
          });
        }
      }

      exportableModels.push({
        name: "Whole Building",
        staircase_id: "whole-building-export",
      });
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        "/my_exports"
      );
    }

    function buildDetailRoute(jobId, organizationId) {
      var base = buildBaseRoute(organizationId);
      return base + "/" + jobId;
    }

    function buildListRoute(organizationId) {
      return buildBaseRoute(organizationId);
    }

    /******************* *
     *   Create
     * ****************** */

    function create(accountId, buildingId, organizationId, data) {
      if (accountId) data.target_account = accountId;
      if (buildingId) data.target_building = buildingId;
      var route = buildListRoute(organizationId);
      var request;

      request = HttpService.post(route, data);

      return request.then(function (job) {
        if (!job) return $q.reject("Failed to create job");
        // Fetch job to get updated name
        return service.getById(job._id, organizationId);
      });
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(organizationId, params) {
      var route = buildListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getById(jobId, organizationId, params) {
      var route = buildDetailRoute(jobId, organizationId);
      return HttpService.getById(route, jobId, params);
    }

    function getAll(organizationId, params) {
      var route = buildListRoute(organizationId);
      return HttpService.getAll(route, params);
    }
  }
})();
