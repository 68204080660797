(() => {
  /**
   * @component
   *
   * @property { object } abxFormData Cost line customization form data
   * @property { (formData: object) => void } abxOnFormDataChange Callback invoked
   *  when `formData` should be updated
   * @property { CostLine[] } abxCostLines Cost lines to display
   *
   * @property { CostLine } abxSelectedCostLine The currently selected cost line
   *  or null
   * @property { (costLine: CostLine) => void } abxOnCostLineSelect Callback invoked
   *  when the selected cost line should be updated
   *
   * @property { string } abxCatalog Currently selected catalog
   *
   * @property { boolean } abxDisableForms True if forms in the section should be
   *  disabled
   *
   * @example
   * ```html
   * <abx-cost-line-selection-context ...>
   *   <child contents>
   * </abx-cost-line-selection-context>
   * ```
   *
   * @example To consume:
   * ```js
   * angular.module("my.module").component("fooComponent", {
   *   ...,
   *   // use the require configuration attribute for your component
   *   // to reference this context. It will be bound to `this.selectionContext`
   *   // in this example
   *   require: {
   *     selectionContext: "^abxCostLineSelectionContext"
   *   }
   * })
   * ```
   */
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineSelectionContext", {
      bindings: {
        formData: "<abxFormData",
        onFormDataChange: "<abxOnFormDataChange",
        costLines: "<abxCostLines",
        selectedCostLine: "<abxSelectedCostLine",
        onSelectedCostLineChange: "<abxOnSelectedCostLineChange",
        catalog: "<abxCatalog",
        listIsLoading: "<abxListIsLoading",
        disableForms: "<abxDisableForms",
      },
      controller: AbxCostLineSelectionContextController,
      template: `<ng-transclude></ng-transclude>`,
      transclude: true,
    });

  /* @ngInject */
  function AbxCostLineSelectionContextController() {}
})();
