// import React from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

import { Button, Tooltip, Box } from "@mui/material";

import { FileUploadOutlined, Search } from "@mui/icons-material";

const GridToolbar = ({
  onToggleHeaderFilters,
  showHeaderFilters,
  onExportClick,
}: {
  onToggleHeaderFilters: () => void;
  showHeaderFilters: boolean;
  onExportClick: () => void;
}) => {
  const handleExportClick = () => {
    onExportClick();
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Tooltip title={showHeaderFilters ? "Hide Filters" : "Show Filters"}>
        <Button
          onClick={onToggleHeaderFilters}
          color="primary"
          startIcon={<Search />}
        >
          {showHeaderFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Tooltip>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        aria-controls="export-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleExportClick}
        startIcon={<FileUploadOutlined />}
      >
        Export
      </Button>
    </GridToolbarContainer>
  );
};

export default GridToolbar;
