(function () {
  angular.module("akitabox.desktop.asset.organizationList", [
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.flag",
    "akitabox.core.services.filter",
    "akitabox.core.services.pin",
    "akitabox.ui.dialogs.asset",
    "akitabox.ui.dialogs.export",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
