(function () {
  /**
   * @ngdoc component
   * @name abxFloorCardContainer
   *
   * @param {Function} onSelect - The function to run on floor select. Will
   *     only be invoked if/when a floor has a floor plan
   * @param {Object[]} building - Current building. Will be included
   *     in floor query
   * @param {Object} [activeFloor] - The currently selected floor
   *
   * @description
   * A component to be used in conjunction with floor cards to display a collection of floors
   *
   * @controls {Object[]} floors - All floors for the current building
   */
  angular
    .module("akitabox.ui.components.floorCardContainer")
    .component("abxFloorCardContainer", {
      bindings: {
        onSelect: "&abxOnSelect",
        building: "<abxBuilding",
        activeFloor: "<?abxActiveFloor",
      },
      controller: AbxFloorCardContainerController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/floor-card-container/floor-card-container.component.html",
    });

  function AbxFloorCardContainerController(
    // Angular
    $rootScope,
    // Constants
    EVENT_FLOOR_ADD,
    // Services
    FloorService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.floors = null;

    $rootScope.$on(EVENT_FLOOR_ADD, function () {
      getFloors(self.building);
    });

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.building) {
        getFloors(self.building);
      }
    };

    // =================
    // Private Functions
    // =================

    /**
     * Get the floors for the given building
     *
     * @param {Object} building
     */
    function getFloors(building) {
      // Immediately flush the existing floors collection. This is done to
      // ensure that users will not see an incorrect listing of floors while
      // awaiting the result of an API fetch in FloorService.
      self.floors = null;

      if (!building) {
        return;
      }

      FloorService.getAll(building._id)
        .then(function (floors) {
          self.floors = floors;
        })
        .catch(function (err) {
          ToastService.showError("Unable to get floors");
          self.floors = [];
          return self.floors;
        });
    }
  }
})();
