(function () {
  angular.module("akitabox.desktop.workOrder", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.desktop.workOrder.detail",
    "akitabox.desktop.workOrder.list",
    "akitabox.desktop.workOrder.organizationList",
  ]);
})();
