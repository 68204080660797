(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedRoomFilter",
      function (
        $q,
        ManagedFilterHelpers,
        ManagedModelFieldFilter,
        RoomService,
        PinTypeService,
        PinFieldService,
        ServiceHelpers,
        RoomsBFFService
      ) {
        /**
         * @param {object} options
         * @param {() => string} options.getBuildingId - A function that should return the
         *  building ID to use for fetching enum options/chip text.
         * @param {() => object} options.getOrganization - A function that should return
         *  the organization the building is in.
         * @param {boolean} [options.allowTextValue=false] - If true, allows the user to enter
         *  a partial `room` name and have that be considered valid.
         * @param {string} [options.textValueQueryField] - The query field to use for text values.
         * Should be different from "room". Required if allowTextValue is true
         */
        function ManagedRoomFilter(manager, options) {
          if (!options) {
            throw new Error("ManagedRoomFilter: Options must be provided");
          }
          if (typeof options.getBuildingId !== "function") {
            throw new Error(
              "ManagedRoomFilter: Invalid options, getBuildingId must be a function."
            );
          }
          if (typeof options.getOrganization !== "function") {
            throw new Error(
              "ManagedRoomFilter: Invalid options, getOrganization must be a function."
            );
          }
          if ([undefined, false, true].indexOf(options.allowTextValue) === -1) {
            throw new Error(
              "ManagedRoomFilter: Invalid options, allowTextValue must be a boolean if provided"
            );
          }
          if (options.allowTextValue && !options.textValueQueryField) {
            throw new Error(
              "ManagedRoomFilter: Invalid options, textValueQueryField must be set if allowTextValue is true"
            );
          }
          if (
            options.textValueQueryField !== undefined &&
            (options.textValueQueryField === "" ||
              typeof options.textValueQueryField !== "string")
          ) {
            throw new Error(
              "ManagedRoomFilter: Invalid options, textValueQueryField must be a non-empty string"
            );
          }
          /**
           * @member
           * @type {() => string}
           */
          this.getBuildingId = options.getBuildingId;

          this.getPinFields = getPinFields.bind(this);
          this.parseRoomOptions = parseRoomOptions.bind(this);

          /**
           * @member
           * @type {() => object}
           */
          this.getOrganization = options.getOrganization;

          /**
           * @member
           * @type {ManagedFilterConfiguration | undefined}
           * The level configuration (if any) that this filter depends on
           */
          this.levelConfig = undefined;

          /**
           * @member
           * @type {ManagedFilterConfiguration | undefined}
           * The asset configuration (if any) that this filter depends on
           */
          this.assetConfig = undefined;

          /**
           * @member
           * @type {string | undefined}
           * The query field to use for text queries
           */
          this.textValueQueryField = options.textValueQueryField;

          var filterConfigurationOptions = {
            displayName: "Room",
            getQueryField: function (model) {
              if (model && model._id) {
                return "room";
              } else if (this.textValueQueryField) {
                return this.manager.getModelFieldFilter("room") !== undefined
                  ? "room"
                  : this.textValueQueryField;
              } else {
                return "room";
              }
            },
            inputType: "typeahead-dynamic",
            modelValueToFilterValue: function (model) {
              if (model && model._id) {
                return ManagedFilterHelpers.modelToId(model);
              } else if (
                typeof model === "string" &&
                model.length >= 3 &&
                this.textValueQueryField
              ) {
                return model;
              } else {
                throw new Error(
                  "Enter at least 3 characters to search for a room by name or number."
                );
              }
            },
            modelValueToChipText: function (model) {
              if (model && model._id) {
                return ManagedFilterHelpers.modelToName(model);
              } else if (
                this.textValueQueryField &&
                typeof model === "string"
              ) {
                return '"' + model + '"';
              }
            },
            allowTextValue: options.allowTextValue || false,
          };

          ManagedModelFieldFilter.call(
            this,
            manager,
            filterConfigurationOptions
          );
        }

        /**
         * Parse a list of rooms and convert names into display_names
         *
         * @param {Array} rooms
         *
         * @return {Promise<Object[]>} - Resolves with parsed options
         */
        function parseRoomOptions(rooms) {
          var self = this;

          if (!self.getPinFields) {
            return $q.resolve([]);
          }
          function parseRooms() {
            var requests = [];
            rooms.forEach(function (room) {
              var request = ServiceHelpers.getRoomDisplayName(
                room,
                self.pinFields
              ).then(function (displayName) {
                return angular.extend({}, room, { name: displayName });
              });
              requests.push(request);
            });
            return $q.all(requests);
          }
          if (
            self.pinFields &&
            self.getBuildingId() === self.pinFields[0].building
          ) {
            return parseRooms();
          } else {
            return self.getPinFields().then(parseRooms);
          }
        }

        /**
         * Get and save the pin fields for the building's room pin type for
         * use in parsing the room display name
         *
         * @return {Promise} - Resolves after setting this.pinFields to the roomPinFields
         */
        function getPinFields() {
          var self = this;
          var buildingId = self.getBuildingId();

          var params = { protected_type: "Room" };
          return PinTypeService.getAll(buildingId, params)
            .then(function (pinTypes) {
              var roomPinType = pinTypes[0];
              return PinFieldService.getAll(buildingId, roomPinType._id);
            })
            .then(function (roomPinFields) {
              self.pinFields = roomPinFields;
              return;
            });
        }

        // ManagedRoomFilter extends ManagedModelFieldFilter
        ManagedRoomFilter.prototype = Object.create(
          ManagedModelFieldFilter.prototype
        );
        ManagedRoomFilter.prototype.constructor = ManagedRoomFilter;

        /**
         * Invalidate any dependent filter's enum options on clear.
         */
        ManagedRoomFilter.prototype.afterRemove = function () {
          this.assetConfig && this.assetConfig.invalidateEnumOptions();
        };

        /**
         * Fetch a room by its id, fetches pinValues and returns
         * parsed room with displayName replacing name
         *
         * @return { Promise<Room> }
         */
        ManagedRoomFilter.prototype.filterValueToModelValue = function (
          idOrQuery
        ) {
          var self = this;
          if (
            this.textValueQueryField &&
            this.manager.getModelFieldFilter(this.textValueQueryField) !==
              undefined
          ) {
            // it's a query
            return $q.resolve([idOrQuery]);
          }

          function parseRoom(room) {
            return ServiceHelpers.getRoomDisplayName(room, self.pinFields).then(
              function (displayName) {
                room.name = displayName;
                return room;
              }
            );
          }

          var request = RoomService.get(self.getBuildingId(), {
            _id: idOrQuery,
            include_values: true,
          });
          return self
            .getPinFields()
            .then(function () {
              return request;
            })
            .then(parseRoom)
            .then(function (room) {
              return [room];
            });
        };

        /**
         * @param {object={}} params - What to pass into the mongo query as the filters
         *
         * Fetch enum options for this filter.
         * @return {Promise<[]>} - An array of enum options, one for each room.
         *
         * @param {object} params
         * @param {boolean} [params.noLimit]
         */
        ManagedRoomFilter.prototype.getEnumOptions = function (params) {
          const { _id: organizationId } = this.getOrganization();
          var levelId = this.getLevelId();

          // Sets params to a default if none provided
          params = angular.extend({}, params || {}, { include_values: true });
          if (levelId !== undefined) {
            params.level = levelId;
          }
          if (params.name) {
            params.nameOrNumber = params.name;
            delete params.name;
          }

          if (params.nameOrNumber) {
            params.$or = `name=${params.nameOrNumber};number=${params.nameOrNumber}`;
            delete params.nameOrNumber;
          }
          params.building = this.getBuildingId();

          let request;
          if (params.noLimit) {
            request = RoomsBFFService.getAllTypeAheads(organizationId, params);
          } else {
            request = RoomsBFFService.getTypeAheads(organizationId, params);
          }

          return request
            .then(this.parseRoomOptions)
            .then(ManagedFilterHelpers.mapModelsToEnumOption);
        };

        ManagedRoomFilter.prototype.getFilterValue = function (manager) {
          var filterValue =
            ManagedModelFieldFilter.prototype.getFilterValue.call(
              this,
              manager
            );

          if (typeof filterValue === "string" && filterValue.length < 3) {
            throw new Error(
              "Enter at least 3 characters to search for a room name or number."
            );
          }
          return filterValue;
        };

        /**
         * Update this filter configuration to use the provided level configuration.
         * @param [levelConfig] - The level config that this filter config
         *  should depend on when fetching rooms.
         * @return { void }
         */
        ManagedRoomFilter.prototype.setLevelConfig = function (levelConfig) {
          this.levelConfig = levelConfig;
        };

        /**
         * Get the level ID for use when filtering.
         * @return The level ID, or undefined if none set/no level filter associated.
         */
        ManagedRoomFilter.prototype.getLevelId = function () {
          if (this.levelConfig) {
            return this.levelConfig.getFilterValue(this.manager);
          }
        };

        /**
         * Couple this filter configuration to an asset configuration. Makes this
         * filter configuration fire any necessary events to maintain the
         * asset/room hierarchy when filtering.
         * @param [assetConfig] - The asset configuration to pair with this
         *  filter configuration.
         * @return { void }
         */
        ManagedRoomFilter.prototype.setAssetConfig = function (assetConfig) {
          this.assetConfig = assetConfig;
        };

        return ManagedRoomFilter;
      }
    );
})();
