(function () {
  angular
    .module("akitabox.ui.components.workOrderLogForm", [
      "akitabox.core.constants",
      "akitabox.core.services.timeZone",
      "akitabox.core.services.timeCode",
      "akitabox.core.services.organization",
      "akitabox.ui.components.input",
    ])
    .component("abxWorkOrderLogForm", {
      bindings: {
        workOrderLog: "=abxWorkOrderLog",
        formData: "=abxWorkOrderLog",
        /**
         * Flag to control the editing state of the form
         * true if you're editing, false otherwise
         */
        isEditing: "<abxIsEditing",
      },
      controller: AbxWorkOrderLogFormController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/work-order-log-form/work-order-log-form.component.html",
    });

  function AbxWorkOrderLogFormController(
    TimeZoneService,
    UserService,
    TimeCodeService,
    OrganizationService
  ) {
    var self = this;
    var _permissions = UserService.getPermissions();
    self.organization = OrganizationService.getCurrent();

    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.canAdmin = false;
    self.onChange = onChange;
    self.timeCodes = [];
    self.showTimeCodes = self.organization.show_time_codes;
    self.requireTimeCode = self.organization.require_time_codes;
    self.timeCodesOptions = getTimeCodes(self.organization);
    self.timeCodeSelected = null;

    //self.activeTimeCode = self.timeCodesOptions[2];

    // if creating a new WO Log, check if the current user has permissions to log work
    // for another user
    if (!self.isEditing) {
      self.canAdmin = _permissions.work_order_log.create_any;
    }

    /**
     * Handle field change
     *
     * @param {Object} $event Change event
     * @param {String} type Type of update (e.g name, address)
     */
    function onChange($event, field) {
      var model = $event ? $event.model : null;
      switch (field) {
        case "work_performed_by":
          if (model && model.account) {
            // (WEBAPP-9092) remove
            self.workOrderLog.work_performed_by = model.account._id;
          } else if (model && model.identity) {
            self.workOrderLog.work_performed_by = model.identity._id;
          } else {
            self.workOrderLog.work_performed_by = null;
          }
          break;
        default:
          self.workOrderLog[field] = model;
      }
    }

    function getTimeCodes(organization) {
      TimeCodeService.getActive(organization._id).then(function (response) {
        self.timeCodes = response.map(function (type) {
          return {
            model: type,
            value: `${type.code} - ${type.name}`,
          };
        });

        // select a default time code
        if (self.isEditing && self.timeCodes && self.timeCodes.length > 0) {
          const timeCodeId =
            self.workOrderLog.time_code && self.workOrderLog.time_code._id
              ? self.workOrderLog.time_code._id
              : self.workOrderLog.time_code;

          if (timeCodeId) {
            const index = self.timeCodes.findIndex((item) => {
              return item.model._id.toString() === timeCodeId.toString();
            });
            self.timeCodeSelected = self.timeCodes[index].model;
            self.workOrderLog["timeCodeSelected"] = self.timeCodeSelected;
          }
        }
      });
    }
  }
})();
