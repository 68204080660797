(function () {
  /**
   * @ngdoc component
   * @name abxAssigneeInputComponent
   *
   * @param {Boolean} disabled - if input is disabled
   * @param {Function} onSelect - To be invoked when an assignee is selected or
   *     cleared
   * @param {Function} onChange - To be invoked when the value in the input has
   *     changed (i.e. a `keyup` event), but the input is not blurred. Should be
   *     invoked with: event.model, event.value, [event.invalid].
   * @param {Function} onFocus - to be invoked when the input is focused
   * @param {Function} onBlur - To be invoked when the input is blurred
   * @param {String} value - Display value for currently selected room
   * @param {String} buildingId - Building to fetch assignees for
   * @param {Boolean} [blurOnEnter=false] - Blur the input on Enter keypress.
   *
   * @description
   * Room input component
   */
  angular
    .module("akitabox.ui.components.input.assignee", [
      "akitabox.core.services.flag",
      "akitabox.core.services.organization",
      "akitabox.core.services.user",
      "akitabox.core.services.permissionGroups",
      "akitabox.core.toast",
      "akitabox.ui.components.typeAheadInput",
    ])
    .component("abxAssignee", {
      bindings: {
        disabled: "<abxDisabled",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        onBlur: "&abxOnBlur",
        value: "<abxValue",
        buildingId: "<abxBuildingId",
        blurOnEnter: "<?abxBlurOnEnter",
      },
      controller: AbxAssigneeController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/input/components/assignee/assignee.component.html",
    });

  function AbxAssigneeController(
    // Services
    ToastService,
    OrganizationService,
    PermissionGroupsService,
    UserService
  ) {
    var self = this;

    // Private
    var assigneesRequest;
    var organization = OrganizationService.getCurrent();
    let srpPermissionGroupId;

    // Attributes
    self.loading = false;
    self.assigneeOptions = []; // Options objects consumable by the typeahead
    self.blurOnEnter = self.blurOnEnter || false;

    // Functions
    self.handleFocus = handleFocus;

    init();

    // =================
    // Life Cycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.buildingId) {
        assigneesRequest = null;
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Handle input focus
     */
    function handleFocus() {
      self.onFocus();

      if (!assigneesRequest) {
        assigneesRequest = getAssignees();
      }
    }

    /**
     * Fetch the assignees for the current building.
     *
     * @return {Promise} - Resolves when assignees have been fetched and parsed
     */
    function getAssignees() {
      self.loading = true;
      assigneesRequest = UserService.getAll(organization._id, {
        identity: "$ne,null",
        buildings: self.buildingId,
        status: "active",
        sort: "firstOrEmail,asc",
        permission_group: `$ne,${srpPermissionGroupId}`,
      })
        .then(function (users) {
          self.assigneeOptions = users.map(function (user) {
            return {
              model: user,
              value: user.identity.display_name,
            };
          });
        })
        .catch(function (err) {
          self.assigneeOptions = [];
          ToastService.showError(err);
        })
        .finally(function () {
          self.loading = false;
        });

      return assigneesRequest;
    }

    // =================
    // Private Functions
    // =================

    function init() {
      setSRPPermissionGroupId();
    }

    function setSRPPermissionGroupId() {
      self.loading = true;
      PermissionGroupsService.getSRPPermGroupIdByOrg(organization._id)
        .then((id) => {
          srpPermissionGroupId = id;
        })
        .catch(function (err) {
          ToastService.showError(err);
        })
        .finally(function () {
          self.loading = false;
        });
    }
  }
})();
