(function () {
  angular
    .module("akitabox.desktop")
    .config(configBlock)
    .factory("httpInterceptor", httpInterceptor);

  /* @ngInject */
  function configBlock($httpProvider, $sceDelegateProvider, storeProvider) {
    $httpProvider.interceptors.push("httpInterceptor");
    // Whitelist resource urls
    $sceDelegateProvider.resourceUrlWhitelist(["self"]);
    // Configure storage
    storeProvider.setStore("localStorage");
  }

  /* @ngInject */
  function httpInterceptor($injector, $location) {
    var service = {
      responseError: responseError,
    };
    return service;

    function responseError(response) {
      var $q = $injector.get("$q");
      var $state = $injector.get("$state");
      var buildingId = response.config.url.split("/")[4];

      switch (response.status) {
        case 401:
          if (
            $state.current.name !== "auth.login" &&
            $state.current.name !== "auth.logout"
          ) {
            $state.go("auth.logout", { redirect_url: $location.url() });
          }
          break;
        case 402:
          $state.go("app.building.detail", {
            buildingId: buildingId,
          });
          break;
        case 503:
          $state.go("root.503");
          break;
      }
      return $q.reject(response);
    }
  }
})();
