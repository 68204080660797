(function () {
  angular
    .module("akitabox.desktop.document.detail")
    .controller("DocumentOverviewController", DocumentOverviewController);

  /* @ngInject */
  function DocumentOverviewController(
    // Angular
    $scope,
    // Constants
    models,
    // Resolves
    building,
    document,
    // Dialogs
    ArchiveDocumentDialog,
    DeleteDocumentDialog,
    DownloadDocumentDialog,
    RestoreDocumentDialog,
    TagDocumentDialog,
    // Services
    Router,
    AssetService,
    AttachmentService,
    DocumentService,
    FloorService,
    NoteService,
    RequestService,
    RoomService,
    ToastService,
    UserService,
    WorkOrderService
  ) {
    var self = this;
    var permissions = UserService.getPermissions();

    // Attributes
    self.document = document;
    self.linkedAssets = [];
    self.linkedRooms = [];
    self.linkedWorkOrders = [];
    self.linkedRequests = [];
    self.linkedFloors = [];
    self.building = building;
    self.limit = 5;
    self.loading = true;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity_action_html:
        "This <em>" + models.DOCUMENT.SINGULAR + "</em> was created",
      buildingUri: building.uri,
      account: document._cre_account,
      entity: models.DOCUMENT.SINGULAR,
      time: document.cre_date,
    };
    self.newNote = null;
    self.noop = angular.noop;
    self.activityParams = {
      building: "null",
      document: self.document._id,
    };
    self.saving = false;
    self.permissions = getPermissions();

    // Functions
    self.archiveFile = archiveFile;
    self.deleteFile = deleteFile;
    self.downloadFile = downloadFile;
    self.restoreFile = restoreFile;
    self.tagFile = tagFile;
    self.createNote = createNote;
    self.updateName = updateName;

    // =================
    // Lifecycle
    // =================

    this.$onInit = () => {
      this.loading = true;
      AttachmentService.get(this.document.building, {
        document: this.document._id,
      })
        .then((attachments) => {
          const fetches = [];
          for (const attachment of attachments) {
            for (const link of attachment.links) {
              if (link.entity_type === "asset") {
                fetches.push(
                  AssetService.getById(
                    this.document.building,
                    link.entity_id
                  ).then((asset) => {
                    self.linkedAssets.push(asset);
                  })
                );
              } else if (link.entity_type === "room") {
                fetches.push(
                  RoomService.getById(
                    this.document.building,
                    link.entity_id
                  ).then((room) => {
                    self.linkedRooms.push(room);
                  })
                );
              } else if (link.entity_type === "task") {
                fetches.push(
                  WorkOrderService.getById(
                    this.document.building,
                    link.entity_id
                  ).then((task) => {
                    self.linkedWorkOrders.push(task);
                  })
                );
              } else if (link.entity_type === "request") {
                fetches.push(
                  RequestService.getById(
                    this.document.building,
                    link.entity_id
                  ).then((request) => {
                    self.linkedRequests.push(request);
                  })
                );
              } else if (link.entity_type === "level") {
                fetches.push(
                  FloorService.getById(
                    this.document.building,
                    link.entity_id
                  ).then((level) => {
                    self.linkedFloors.push(level);
                  })
                );
              }
            }
          }
          return Promise.all(fetches).then(() => {
            self.loading = false;
          });
        })
        .catch((err) => {
          ToastService.showError(err);
          this.loading = false;
        });
    };

    // --------------------
    //   Public Methods
    // --------------------

    /**
     * Creates a new note
     *
     * @param    {String}    noteText    string containing the new note's content
     */
    function createNote(noteText) {
      var data = {
        document: document._id,
        building: building._id,
        text: noteText,
      };

      NoteService.create(building._id, data)
        .then(function (note) {
          refreshActivity();
        })
        .catch(ToastService.showError);
    }

    /**
     * Updates the document's name
     *
     * @param    {String}    name    The new name of the document
     */
    function updateName() {
      DocumentService.rename(building._id, document._id, self.document.name)
        .then(function (updatedDocument) {
          self.document = updatedDocument;
          document = angular.extend(document, self.document);
        })
        .catch(ToastService.showError);
    }

    /**
     * Makes a call to the server to archive this file
     */
    function archiveFile() {
      var doc = document;
      doc.building = building._id;
      var locals = {
        documents: [doc],
      };

      ArchiveDocumentDialog.show({ locals: locals })
        .then(function () {
          return DocumentService.getById(building._id, document._id);
        })
        .then(function (updatedDocument) {
          self.document = updatedDocument;
          angular.extend(document, self.document);
          // User's permissions haven't changed, but document has
          return updatePermissions();
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens up the file deletion dialog
     */
    function deleteFile() {
      var doc = document;
      doc.building = building._id;
      var locals = {
        documents: [doc],
      };

      DeleteDocumentDialog.show({ locals: locals })
        .then(function () {
          Router.go("app.documents");
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens up the download file dialog
     */
    function downloadFile() {
      var locals = {
        docs: [document],
      };

      DownloadDocumentDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    /**
     * Opens up the tag editing dialog
     */
    function tagFile() {
      var doc = document;
      doc.building = building._id;
      var locals = {
        documents: [doc],
      };

      TagDocumentDialog.show({ locals: locals })
        .then(function (docs) {
          if (angular.isArray(docs) && docs[0]) {
            self.document = docs[0];
            angular.extend(document, self.document);
          }
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens up the file restore dialog
     */
    function restoreFile() {
      var doc = document;
      doc.building = building._id;
      var locals = {
        documents: [doc],
      };

      RestoreDocumentDialog.show({ locals: locals })
        .then(function () {
          return DocumentService.getById(building._id, document._id);
        })
        .then(function (updatedDocument) {
          self.document = updatedDocument;
          angular.extend(document, self.document);
          // User's permissions haven't changed, but document has
          return updatePermissions();
        })
        .catch(ToastService.showError);
    }

    // --------------------
    //   Private Methods
    // --------------------

    /**
     * Refresh the RecentActivity service's cache and re-pull the items
     */
    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function getPermissions() {
      var archived = document.archived;
      return {
        canTag: permissions.tag.create,
        canArchive: permissions.document.archive && !archived,
        canRestore: permissions.document.unarchive && archived,
        canDelete: permissions.document.remove && archived,
        canRename: permissions.document.rename,
      };
    }

    function updatePermissions() {
      self.permissions = getPermissions();
    }
  }
})();
