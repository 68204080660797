(function () {
  angular
    .module("akitabox.desktop.directives.details.qrcode", [
      "akitabox.constants",
      "akitabox.core.services.shadow",
      "akitabox.core.services.user",
      "akitabox.core.utils",
      "akitabox.ui.dialogs.qrScanner",
      "ui.mask",
    ])
    .directive("abxDetailsQrCode", function () {
      return {
        restrict: "E",
        templateUrl:
          "app/desktop/directives/details/qr-code/details-qr-code.html",
        controller: AbxDetailsQrCodeController,
        bindToController: true,
        controllerAs: "vm",
        scope: {
          model: "<abxModel",
          type: "@abxModelType",
          onChange: "&abxOnChange",
        },
      };
    });

  /**
   * Controller for AbxDetailsQrCode directive
   *
   * @ngInject
   */
  function AbxDetailsQrCodeController(
    QRCodeService,
    QrScannerDialog,
    ShadowService,
    ToastService,
    UserService,
    Utils,
    $scope
  ) {
    var vm = this;
    var oldValue = null;
    var userPermissions = UserService.getPermissions();

    vm.readOnly = true;

    // Custom Errors
    var associatedCodeError = "QR Code is already associated";

    this.$onInit = function () {
      if (!vm.type) {
        throw new Error("Model Type is required");
      }
      vm.readOnly = !(
        userPermissions.qrcode.associate && userPermissions.qrcode.disassociate
      );
    };

    $scope.$watch("vm.model", function (newValue) {
      vm.qrCode = newValue && newValue.qrCode && newValue.qrCode._code;
    });

    this.edit = function () {
      oldValue = vm.qrCode;
      vm.editing = true;
    };

    this.cancel = function () {
      vm.qrCode = oldValue;
      vm.editing = false;
    };

    this.save = function () {
      var newValue = vm.qrCode;
      if (Utils.isSame(oldValue, newValue)) {
        vm.editing = false;
        return;
      }

      vm.saving = true;
      ShadowService.sendEvent("qr-code", "save-qr-id", newValue);
      var association = {};
      association[vm.type] = vm.model._id;
      QRCodeService.replace(oldValue, newValue, association)
        .then(function (qrCode) {
          vm.model.qrCode = newValue ? qrCode : null;
          vm.editing = false;
          // since this isn't a real field on the room, we need to fake the field object..so it never matches with
          // any real fields, which is why we chose null
          // This just needs to bubble up to the parent caller, so it can update this virtual on the room/asset
          vm.onChange({ field: { _id: null }, newValue: vm.model.qrCode });
        })
        .catch(function (err) {
          if (err.status === 403) {
            err.message = associatedCodeError;
          }
          ToastService.showError(err);
        })
        .finally(function () {
          vm.saving = false;
        });
    };

    this.scanQRCode = function () {
      vm.saving = true;
      QrScannerDialog.show({ redirect: false })
        .then(function (qrCodeId) {
          var association = {};
          association[vm.type] = vm.model._id;

          return QRCodeService.replace(oldValue, qrCodeId, association);
        })
        .then(function (qrCode) {
          vm.model.qrCode = qrCode;
          vm.qrCode = qrCode._code;
          vm.editing = false;
          ShadowService.sendEvent("qr-code", "save-qr-id", qrCode._code);

          // since this isn't a real field on the room, we need to fake the field object..so it never matches with
          // any real fields, which is why we chose null
          // This just needs to bubble up to the parent caller, so it can update this virtual on the room/asset
          vm.onChange({ field: { _id: null }, newValue: vm.model.qrCode });
        })
        .catch(ToastService.showError)
        .finally(function () {
          vm.saving = false;
        });
    };
  }
})();
