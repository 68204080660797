(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.utils
   */
  angular.module("akitabox.core.utils", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.lib.moment",
    "akitabox.core.services.env",
  ]);
})();
