import {
  ListItem,
  Stack,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Skeleton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import { FC, useState } from "react";
import { stylesheet } from "../../stylesheet";
import { Color } from "../../colors";
import { AttachmentDialogTestids } from "../AttachmentDialog";
import { DocumentResponse } from "@akitabox/api-client";
import { ImageSkeleton } from "../../image-skeleton/ImageSkeleton";

export interface FileListItemProps {
  document: DocumentResponse;
  onRemove: () => void;
}

/**
 * Component for displaying details about a file in a list.
 *
 * @note This component should probably be refactored into a `DocumentListItem`
 * when we start uploading documents.
 */
export const FileListItem: FC<FileListItemProps> = ({
  document: file,
  onRemove,
}) => {
  const [thumbnailErrored, setThumbnailErrored] = useState(false);
  return (
    <ListItem
      css={ss.root}
      data-testid={AttachmentDialogTestids.FileListItem(file.name)}
    >
      <Stack direction="row" css={ss.wrapper}>
        <Box css={ss.thumbnailContainer}>
          {thumbnailErrored ? (
            <DescriptionIcon css={ss.thumbnail} />
          ) : (
            <ImageSkeleton
              src={file.public_thumbnail_url_small}
              css={ss.thumbnail}
              onError={() => {
                setThumbnailErrored(true);
              }}
              SkeletonProps={{ css: { width: "100px", height: "2.5rem" } }}
            />
          )}
        </Box>
        <Box css={ss.fileName}>
          <Typography variant="body1">{file.name}</Typography>
        </Box>
        <Box css={ss.fileSize}>
          <Typography variant="body2">{file._size_display}</Typography>
        </Box>
        <Box>
          <Tooltip title="Remove file">
            <IconButton color="error" onClick={() => onRemove()}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </ListItem>
  );
};

const ss = stylesheet({
  root: {
    alignItems: "center",
  },
  /**
   * Applied to the wrapper element containing the file details
   */
  wrapper: (theme) => ({
    borderBottom: `1px solid ${Color.BorderGray}`,
    width: "100%",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: "3rem",
  }),
  /** Applied to the file name container */
  fileName: (theme) => ({
    flexGrow: 0,
    flexBasis: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "& .MuiTypography-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 600,
    },
    marginLeft: theme.spacing(1),
  }),
  /** Applied to the file size container */
  fileSize: (theme) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    "& .MuiTypography-root": {
      fontStyle: "italic",
      color: Color.DarkGray,
      fontWeight: "normal",
    },
  }),
  thumbnailContainer: {
    width: "100px",
    display: "flex",
    justifyContent: "center",
  },
  thumbnail: {
    maxWidth: "100px",
    maxHeight: "3rem",
  },
});

/**
 * File list item skeleton/preloader component.
 */
export const PendingFileListItem: FC = () => (
  <ListItem css={ss.root}>
    <Stack direction="row" css={ss.wrapper}>
      <Box css={ss.thumbnailContainer}>
        <ImageSkeleton
          css={ss.thumbnail}
          SkeletonProps={{ css: { width: "100px", height: "2.5rem" } }}
          onError={() => {
            // noop
          }}
        />
      </Box>

      <Box css={ss.fileName}>
        <Skeleton
          variant="rounded"
          css={(theme) => ({
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          })}
        />
      </Box>
      <Box css={ss.fileSize}>
        <Skeleton
          variant="rounded"
          width={40}
          css={(theme) => ({
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          })}
        />
      </Box>
      <Box css={[(theme) => ({ padding: theme.spacing(1) })]}>
        <Skeleton variant="circular" width={24} height={24} />
      </Box>
    </Stack>
  </ListItem>
);
