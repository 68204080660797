import React, { useCallback, useMemo, useState } from "react";
import { useRouteLoaderData } from "react-router";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  Announcement,
  Event,
  Delete,
  RemoveCircle,
  Build,
  Place,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import {
  OrganizationResponse,
  BuildingResponse,
  WebAssetResponse,
} from "@akitabox/api-client";
import { useAngularApp } from "../legacy/angularApp";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { api } from "../api";
import {
  handleLegacyAddServiceRequest,
  handleLegacyAddWorkOrder,
  handleLegacyAddMaintenanceSchedule,
  handleLegacyDecommission,
  handleLegacyDelete,
  handleLegacyBulkDelete,
} from "./assetActionsUtils";
interface AssetActionsProps {
  assets: WebAssetResponse[]; // Adjust the type according to the actual structure of assets
  assetsInSameBuilding: boolean;
  permissions: any;
  onClose: () => void;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const AssetActions: React.FunctionComponent<AssetActionsProps> = ({
  assets,
  assetsInSameBuilding,
  permissions,
  onClose,
}) => {
  const selectedRow = useMemo(() => {
    return assets && assets.length ? assets[0] : null;
  }, [assets]);

  const { organization } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
  };
  const asset = assets ? assets[0] : null;
  const { data: buildingResponse } = makeUseServiceCall(api.buildings.getById)({
    organizationId: organization._id,
    buildingId: asset?.building?._id || "",
  });
  // Define a default value for BuildingResponse
  const building: BuildingResponse | null = useMemo(() => {
    return buildingResponse ? buildingResponse.data : null;
  }, [buildingResponse]);

  const injector = useAngularApp();

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand: _expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const addServiceRequest = useCallback(
    (
      organization: OrganizationResponse,
      building: BuildingResponse,
      assets: WebAssetResponse[]
    ) =>
      handleLegacyAddServiceRequest(injector, organization, building, assets),
    [injector]
  );

  const addWorkOrder = useCallback(
    (
      organization: OrganizationResponse,
      building: BuildingResponse,
      assets: WebAssetResponse[]
    ) => handleLegacyAddWorkOrder(injector, organization, building, assets),
    [injector]
  );

  const addMaintenanceSchedule = useCallback(
    (
      organization: OrganizationResponse,
      building: BuildingResponse,
      assets: WebAssetResponse[]
    ) =>
      handleLegacyAddMaintenanceSchedule(
        injector,
        organization,
        building,
        assets
      ),
    [injector]
  );
  const decommissionAsset = useCallback(
    (assets: WebAssetResponse[]) => handleLegacyDecommission(injector, assets),
    [injector]
  );

  const deleteAsset = useCallback(
    (assets: WebAssetResponse[]) => {
      if (assets.length > 1) {
        handleLegacyBulkDelete(injector, assets);
      } else {
        handleLegacyDelete(injector, assets);
      }
    },
    [injector]
  );

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 1,
        transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
        "&:hover": {
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
          transform: "scale(1.02)",
        },
      }}
    >
      {assets.length > 1 ? (
        <CardHeader
          title={"Multiple Rows Selected"}
          subheader={`${assets.length} assets selected`}
          avatar={
            <Avatar sx={{ bgcolor: "darkgrey" }} alt={"Multiple Assets"}>
              <Place />
            </Avatar>
          }
          action={<Button onClick={onClose}>Close</Button>}
        />
      ) : (
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: `${selectedRow?.color}` }}
              alt={selectedRow?.display_name}
              src={`/img/pin_icons/${selectedRow?.icon}.svg`}
            />
          }
          title={`${selectedRow?.display_name}`}
          subheader={`${selectedRow?.pinType.name}`}
          action={<Button onClick={onClose}>Close</Button>}
        />
      )}
      <CardActions disableSpacing>
        <CardActions>
          {!!assetsInSameBuilding && permissions.canAddRequest && (
            <IconButton
              onClick={() => {
                if (building) {
                  addServiceRequest(organization, building, assets);
                }
              }}
            >
              <Announcement />
            </IconButton>
          )}
          {!!assetsInSameBuilding && permissions.canAddWorkOrder && (
            <IconButton
              onClick={() => {
                if (building) {
                  addWorkOrder(organization, building, assets);
                }
              }}
            >
              <Build />
            </IconButton>
          )}
          {!!assetsInSameBuilding && permissions.canAddSchedule && (
            <IconButton
              onClick={() => {
                if (building) {
                  addMaintenanceSchedule(organization, building, assets);
                }
              }}
            >
              <Event />
            </IconButton>
          )}
        </CardActions>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List>
            {assets.length === 1 &&
              !!assetsInSameBuilding &&
              permissions.canAddRequest && (
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      if (building) {
                        addServiceRequest(organization, building, assets);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Announcement />
                    </ListItemIcon>
                    <ListItemText primary="Add Service Request" />
                  </ListItemButton>
                </ListItem>
              )}
            {!!assetsInSameBuilding && permissions.canAddWorkOrder && (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    if (building) {
                      addWorkOrder(organization, building, assets);
                    }
                  }}
                >
                  <ListItemIcon>
                    <Build />
                  </ListItemIcon>
                  <ListItemText primary="Add Work Order" />
                </ListItemButton>
              </ListItem>
            )}
            {!!assetsInSameBuilding && permissions.canAddSchedule && (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    if (building) {
                      addMaintenanceSchedule(organization, building, assets);
                    }
                  }}
                >
                  <ListItemIcon>
                    <Event />
                  </ListItemIcon>
                  <ListItemText primary="Add Maintenance Schedule" />
                </ListItemButton>
              </ListItem>
            )}
            {assets.length === 1 &&
              !!assetsInSameBuilding &&
              permissions.canDecommissionAsset && (
                <ListItem>
                  <ListItemButton onClick={() => decommissionAsset(assets)}>
                    <ListItemIcon sx={{ color: "red" }}>
                      <RemoveCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary="Decommission"
                      sx={{ color: "red" }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            {permissions.canDeleteAsset && (
              <ListItem>
                <ListItemButton onClick={() => deleteAsset(assets)}>
                  <ListItemIcon sx={{ color: "red" }}>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText primary="Delete Asset" sx={{ color: "red" }} />
                </ListItemButton>
              </ListItem>
            )}
            {!assetsInSameBuilding && (
              <ListItem>
                <ListItemText>
                  Not all functions available for assets in different buildings
                </ListItemText>
              </ListItem>
            )}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export { AssetActions };
