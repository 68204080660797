(function () {
  angular
    .module("akitabox.desktop.building.detail")
    .controller("BuildingDetailController", BuildingDetailController);

  /* @ngInject */
  function BuildingDetailController(building) {
    var self = this;

    // Attributes
    self.buildings = [building];
  }
})();
