(function () {
  angular
    .module("akitabox.ui.dialogs.bulkDelete.asset")
    .factory("BulkDeleteAssetDialog", BulkDeleteAssetDialog);

  /* @ngInject */
  function BulkDeleteAssetDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/bulk-delete/asset/bulk-delete-asset.dialog.html",
      controller: "BulkDeleteAssetDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.assets` and `options.locals.building` are required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error>}              Promise that resolves with the deleted assets
     */
    function show(options) {
      // Verify we have assets and building locals
      if (
        !options ||
        !options.locals ||
        !options.locals.assets ||
        !options.locals.assets.length > 0 ||
        !options.locals.assets.every(
          (item) =>
            Object.prototype.hasOwnProperty.call(item, "_id") &&
            Object.prototype.hasOwnProperty.call(item, "name") &&
            Object.prototype.hasOwnProperty.call(item, "buildingId")
        )
      ) {
        $log.error(
          "BulkDeleteAssetDialog: you must pass assets via locals and each asset must include _id, name, and buildingId"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
