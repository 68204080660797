(function () {
  angular
    .module("akitabox.desktop.document.detail")
    .controller("DocumentDetailController", DocumentDetailController);

  /**
   * @ngInject
   */
  function DocumentDetailController(
    // Angular
    $scope,
    $window,
    // Resolves
    building,
    document,
    models
    // Services
  ) {
    var self = this;

    // Attributes
    self.document = document;
    self.heading = document.archived
      ? document.path + " - Archived"
      : document.path;
    self.building = building;
    self.subtitle = {
      name: document.archived
        ? "Archived " + models.DOCUMENT.PLURAL
        : "Active " + models.DOCUMENT.PLURAL,
      state: {
        name: "app.documents",
        params: { archived: document.archived },
      },
    };
    self.tabs = [
      { title: "Overview", state: "app.document.overview" },
      {
        title: "Version History",
        state: "app.document.versionHistory",
      },
    ];
  }
})();
