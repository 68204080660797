(function () {
  angular
    .module("akitabox.desktop.directives.list.export", [
      "akitabox.core.toast",
      "akitabox.core.services.job.job",
    ])
    .directive("abxExportList", AbxExportListDirective);

  /* @ngInject */
  function AbxExportListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/export/export-list.html",
      controller: ExportListDirective,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        parentFetch: "&abxFetch",
        singleFetch: "&abxFetchSingle",
        downloadHandler: "&abxDownloadHandler",
      },
    };
  }

  /* @ngInject */
  function ExportListDirective(
    // Angular
    $scope,
    $interval
    // Dialogs
    // Services
  ) {
    var self = this;

    // Attributes

    // Functions
    self.getExports = fetchExports;
    self.downloadExport = downloadExport;
    self.setNameExport = setNameExport;
    self.incompleteExportsArr = [];
    self.exportsFetching = [];

    // ------------------------
    //   Private Functions
    // ------------------------

    const incompleteExports = $interval(() => {
      fetchIncompleteExports();
    }, 10000);

    //The function will check for incomplete exports and call
    //the fetchExport service every 10 seconds until every export is completed
    function checkIncompleteExports() {
      if (!self.models) return;
      const haveIncompleteExports = self.models.filter(
        (model) => model.is_pending || model.is_processing
      );
      if (!haveIncompleteExports) $interval.cancel(incompleteExports);
      else self.incompleteExportsArr = haveIncompleteExports;
    }

    function fetchIncompleteExports() {
      if (self.incompleteExportsArr.length < 1) return;

      self.incompleteExportsArr.forEach(async (incompleteExport) => {
        const id = incompleteExport._id;
        if (!self.exportsFetching[id]) {
          self.exportsFetching[id] = true;
          await self.singleFetch({ id: id });
          checkIncompleteExports();
          self.exportsFetching[id] = false;
        }
      });
    }

    $scope.$watch("vm.models", function (value) {
      if (angular.isDefined(value)) {
        checkIncompleteExports();
      }
    });
    // ------------------------
    //   Public Functions
    // ------------------------
    function fetchExports(skip, limit) {
      return self.parentFetch({
        skip: skip,
        limit: limit,
      });
    }

    function downloadExport(exportSelected) {
      return self.downloadHandler({ exportSelected: exportSelected });
    }

    function setNameExport(exportItem) {
      return exportItem.export_file_name
        ? exportItem.export_file_name.replace("-UTC", "")
        : exportItem.name;
    }
  }
})();
