(function () {
  angular.module("akitabox.desktop.request.list", [
    "akitabox.core.constants",
    "akitabox.core.services.filter",
    "akitabox.core.services.request",
    "akitabox.core.services.flag",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.request",
    "akitabox.desktop.components.filterBar",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
