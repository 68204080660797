(function () {
  /**
   * @ngdoc component
   * @name abxDrawerInstruction
   *
   * @param {Boolean} [isOpenOnInitInstructionDrawer] - override the isOpen property on initialization
   * @param {Boolean} [disabledInstructionDrawer] - close and disable component
   *
   * @description
   * Tab for inspection program instructions
   */
  angular
    .module("akitabox.desktop.inspectionProgram")
    .component("abxInspectionProgramDrawer", {
      bindings: {},
      controller: AbxInspectionProgramDrawerController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/modules/inspection-program/inspection-program-drawer/inspection-program-drawer.component.html",
    });

  function AbxInspectionProgramDrawerController(SessionService, UserService) {
    var self = this;

    // Org admin are the only group who have permission to create round templates
    // They will also have inspection_program.create and checklist_template.create permissions by default
    // Only show this drawer with instructions to them
    var permissions = UserService.getPermissions();
    self.isAdministrator = permissions.is_administrator;

    self.open = true;
    self.onCardToggled = onCardToggled;

    self.$onInit = function () {
      var drawerState = SessionService.getInspectionProgramDrawerState();
      if (angular.isDefined(drawerState.isOpen)) {
        self.isOpenOnInitInstructionDrawer = drawerState.isOpen;
        self.open = drawerState.isOpen;
      } else {
        self.isOpenOnInitInstructionDrawer = true;
      }
    };

    function onCardToggled() {
      self.open = !self.open;
      var drawerState = SessionService.getInspectionProgramDrawerState();
      var currentState = drawerState || {};
      angular.extend(currentState, { isOpen: self.open });
      drawerState = currentState;
      SessionService.setInspectionProgramDrawerState(drawerState);
    }
  }
})();
