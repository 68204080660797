(function () {
  angular
    .module("akitabox.ui.dialogs.job.create")
    .controller("DevJobDialogController", CreateScriptDialogController);

  /* @ngInject */
  function CreateScriptDialogController(
    // Material
    $mdDialog,
    // Services
    AdminJobService,
    ToastService,
    // Akitabox
    models,
    CRITICAL_SCRIPTS
  ) {
    var self = this;
    // Constants
    var LABEL = {
      CANCEL: "Cancel",
      BACK: "Back",
      SUBMIT: "Submit",
      CONTINUE: "Continue",
    };

    // Attributes
    self.loading = true;
    self.creating = false;
    self.scripts = null;
    self.script = null;
    self.body = "{\n  \n}";
    self.cancelButtonLabel = LABEL.CANCEL;
    self.submitButtonLabel = LABEL.SUBMIT;
    self.showRequireConfirmationContent = false;

    // Functions
    self.close = $mdDialog.cancel;
    self.cancel = cancel;
    self.submit = submit;

    init();

    function init() {
      AdminJobService.getScripts({ type: models.STAIRCASE.TYPES.ENVIRONMENT })
        .then(function (scripts) {
          self.scripts = scripts;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function submit() {
      var scriptData = {
        staircase_id: self.script.id,
      };

      try {
        scriptData.data = JSON.parse(self.body);

        // Check to see if the script requires a confirm dialog
        var isCriticalScript = CRITICAL_SCRIPTS.indexOf(self.script.id) > -1;

        if (self.showRequireConfirmationContent || !isCriticalScript) {
          executeJob(scriptData);
        } else {
          self.showRequireConfirmationContent = true;
          setDialogButtonText();
        }
      } catch (err) {
        ToastService.showError(err);
        self.creating = false;
      }
    }

    function cancel() {
      // Revert back to previous dialog
      if (self.showRequireConfirmationContent) {
        self.showRequireConfirmationContent = false;
        setDialogButtonText();
      } else {
        $mdDialog.cancel();
      }
    }

    function executeJob(scriptData) {
      if (self.creating) return;
      self.creating = true;

      return AdminJobService.create(null, null, scriptData)
        .then($mdDialog.hide)
        .catch(ToastService.showError)
        .finally(function () {
          self.creating = false;
        });
    }

    function setDialogButtonText() {
      if (self.showRequireConfirmationContent) {
        self.cancelButtonLabel = LABEL.BACK;
        self.submitButtonLabel = LABEL.CONTINUE;
      } else {
        self.cancelButtonLabel = LABEL.CANCEL;
        self.submitButtonLabel = LABEL.SUBMIT;
      }
    }
  }
})();
