(function () {
  /**
   * @ngdoc component
   * @name abxFieldPreloader
   *
   * @param {String} [labelSize=medium] - Size/width of the label for the field.
   *     Available values include: "small", "medium", and "large".
   * @param {String} [type] - Type of field to show. Available types:
   *     - standard: A standard field with a label and a one line input
   *     - circle: A field with two inputs on one line, both preceded by a circle
   *     - pin: A circle and single field
   *
   * @description
   * This component provides a preloader intended to mock an input field.
   */
  angular
    .module("akitabox.ui.components.preloaders")
    .component("abxFieldPreloader", {
      bindings: {
        labelSize: "@?abxLabelSize",
        type: "@?abxType",
        listLength: "@?abxListLength",
      },
      controller: FieldPreloaderController,
      controllerAs: "vm",
      template: "<ng-include src='vm.templateUrl'></ng-include>",
    });

  function FieldPreloaderController($log) {
    var self = this;

    // Attributes
    self.labelSize = self.labelSize || "medium";
    self.type = self.type || "standard";

    if (self.type === "dialog-list") {
      try {
        /**
         * Note that ng-repeat requires an enumerable to work properly
         * @prop {number} listLength - length of the list for the dialog-list-field preloader
         */
        self.listArray = self.listLength
          ? new Array(parseInt(self.listLength))
          : [1];
      } catch (err) {
        $log.error("Invalid field preloader list length");
      }
    }

    // =================
    // Lifecylce
    // =================

    self.$onInit = function () {
      self.templateUrl = buildTemplateUrl();
    };

    // =================
    // Private Functions
    // =================

    function buildTemplateUrl() {
      return (
        "app/core/ui/components/preloaders/field-preloader/templates/" +
        self.type +
        "-field-preloader.html"
      );
    }
  }
})();
