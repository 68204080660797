import { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";

import { api } from "../../api";
import { importProgress } from "../generators/importProgress";
import { BulkAddImportStats, BulkAddStepProps } from "../BulkAddDialogTypes";
import { useGlobalSnackbar } from "../../consecutive-snackbar/GlobalSnackbar";
import { getMultiLevelPropertyValue } from "../../../utils/getMultiLevelPropertyValue";

export const TrackProcess: FunctionComponent<BulkAddStepProps> = ({
  model,
  onNext,
  importId,
}) => {
  const { simple } = useGlobalSnackbar();
  const [isCanceled, setIsCanceled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [importStats, setImportStats] = useState<BulkAddImportStats>();

  useEffect(() => {
    let unmounted = false;

    (async () => {
      if (!importId) return;

      try {
        setIsProcessing(true);
        for await (const stats of importProgress(importId)) {
          if (unmounted) break;
          setImportStats(stats);
        }
      } catch (error) {
        let message = "Unknown network error";
        if (error instanceof Error) {
          const errorMessage = getMultiLevelPropertyValue(
            error,
            "response.data.error.message"
          );
          message = errorMessage ? errorMessage : error.message;
        }
        simple(message, { severity: "error" });
      } finally {
        setIsProcessing(false);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [importId, simple]);

  const handleCancel = async () => {
    if (!importId) return;
    try {
      setIsCanceled(true);
      await api.imports.cancelImport({ importId });
    } catch (error) {
      let message = "Unknown network error";
      if (error instanceof Error) {
        const errorMessage = getMultiLevelPropertyValue(
          error,
          "response.data.error.message"
        );
        message = errorMessage ? errorMessage : error.message;
      }
      simple(message, { severity: "error" });
      setIsCanceled(false);
    }
  };

  return (
    <Box css={(theme) => ({ padding: theme.spacing(2) })}>
      <Typography
        css={(theme) => ({
          padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
        })}
      >
        Give us a moment. We are creating your {model}…
      </Typography>
      <Typography
        css={(theme) => ({
          padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
        })}
      >
        {importStats?.processed ?? 0} of {importStats?.total ?? "…"} rows have
        been processed
      </Typography>
      <Box
        css={(theme) => ({
          padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
        })}
      >
        <LinearProgress
          variant="determinate"
          value={importStats?.progress ?? 0}
        />
      </Box>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        css={(theme) => ({ padding: `0 ${theme.spacing(2)}` })}
      >
        <Button disableElevation onClick={handleCancel} disabled={isCanceled}>
          CANCEL
        </Button>
        <Button
          color="primary"
          onClick={onNext}
          disableElevation
          variant="contained"
          disabled={isProcessing}
        >
          NEXT
        </Button>
      </Stack>
    </Box>
  );
};
