(function () {
  angular
    .module("akitabox.desktop.floor.organizationList")
    .controller(
      "OrganizationFloorListController",
      OrganizationFloorListController
    );

  /* @ngInject */
  function OrganizationFloorListController(
    // Angular
    $scope,
    $timeout,
    $location,
    $q,
    // Constants
    EVENT_FLOOR_CREATE,
    // Services
    ExportDialog,
    FloorService,
    ToastService,
    ManagedModelFieldFilter,
    ManagedBuildingFilter,
    FilterBarManager,
    // Resolve
    organization
  ) {
    var self = this;

    self.floors = null;
    self.fabActions = getFabActions();
    self.organization = organization;

    // Public Functions
    self.fetchFloors = fetchFloors;
    self.fetchAllFloors = fetchAllFloors;
    self.changeFilters = changeFilters;

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // update the list
        changeFilters(query);
      },
    });

    var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Name",
      queryField: "name",
    });

    var hasFloorPlanConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Has Floor Plan",
        queryField: "document",
        inputType: "boolean",
        modelValueToFilterValue: function (model) {
          if (model) return "$ne,null";
          return "null";
        },
        modelValueToChipText: function (model) {
          if (model) return "Yes";
          return "No";
        },
        filterValueToModelValue: function (queryStringValue) {
          if (queryStringValue === "$ne,null") return [true];
          return [false];
        },
      }
    );

    var buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
      getOrganizationId: function () {
        return self.organization._id;
      },
    });

    self.filterBarManager.addFilterConfiguration(nameConfig);
    self.filterBarManager.addFilterConfiguration(hasFloorPlanConfig);
    self.filterBarManager.addFilterConfiguration(buildingConfig);

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_FLOOR_CREATE, onFloorCreate);

    function fetchFloors(skip, limit) {
      var doFetch = function () {
        var params = {
          limit: limit,
          skip: skip,
          sort: "building,asc",
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return FloorService.getByOrganization(organization._id, filters).then(
          function (floors) {
            if (angular.isArray(self.floors)) {
              self.floors = self.floors.concat(floors);
            } else {
              self.floors = floors;
            }
            return floors.length;
          }
        );
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllFloors(limit) {
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          sort: "building,asc",
        };
        return FloorService.getAllByOrganization(organization._id, params);
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = angular.isEmpty(activeFilters, true)
        ? null
        : activeFilters;
      self.floors = null;
      $scope.$broadcast("list:refresh");
    }

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportFloors,
        },
      ];
    }

    function exportFloors() {
      var locals = {
        route: FloorService.buildOrganizationListRoute(self.organization._id),
        filters: self.activeFilters,
        excelOnly: true,
      };

      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Handle floors creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Floor[]} floors    List of new floors
     */
    function onFloorCreate($event, floors) {
      // We can only create one asset at a time
      var floor = floors[0];
      if (!self.floors) {
        self.floors = [];
      }
      self.floors.push(floor);
      $timeout(function () {
        $scope.$broadcast("list:refreshClickEvents");
      });
    }
  }
})();
