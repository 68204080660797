(function () {
  angular
    .module("akitabox.ui.dialogs.inspectionProgram.create")
    .factory(
      "InspectionStopAssociationMap",
      InspectionStopAssociationMapClassFactory
    );

  /**
   * @typedef { object } Association
   * @property { string } building
   * @property { string } checklist_template
   * @property { string | null | undefined } asset
   * @property { string | null | undefined } room
   * @property { string | null | undefined } level
   */

  /** @ngInject */
  function InspectionStopAssociationMapClassFactory(RoundTemplateService) {
    /**
     * @class InspectionStopAssociationMap
     * @constructor
     * Create a new map, optionally from an array of associations
     * @param { Association[] } [associationArray]
     */
    function InspectionStopAssociationMap(associationArray) {
      if (!associationArray) {
        associationArray = [];
      }
      this.map = {};
      for (var i = 0; i < associationArray.length; i++) {
        this.addAssociation(associationArray[i]);
      }
    }

    /**
     * Add a new association to the map.
     * @param { Association } association
     */
    InspectionStopAssociationMap.prototype.addAssociation =
      function addAssociation(association) {
        if (!association.building || typeof association.building !== "string") {
          throw new Error("Error adding association. Missing building");
        }
        if (
          !association.checklist_template ||
          typeof association.checklist_template !== "string"
        ) {
          throw new Error(
            "Error adding association. Missing checklist template"
          );
        }
        var checklistTemplateMap = this.map[association.building] || {};
        var arr = checklistTemplateMap[association.checklist_template] || [];
        arr.push(association);
        checklistTemplateMap[association.checklist_template] = arr;
        this.map[association.building] = checklistTemplateMap;
      };

    /**
     *
     * @param { Association } association
     * @returns { boolean } True if an item was removed, false otherwise.
     */
    InspectionStopAssociationMap.prototype.removeAssociation =
      function removeAssociation(association) {
        var index = this.findIndex(association);
        if (index === -1) {
          return false;
        }
        var bucket =
          this.map[association.building][association.checklist_template];
        if (bucket.length === 1) {
          delete this.map[association.building][association.checklist_template];
          if (Object.keys(this.map[association.building]).length === 0) {
            delete this.map[association.building];
          }
        } else {
          bucket.splice(index, 1);
        }
        return true;
      };

    /**
     * Find the difference (ignoring order) between this map and another.
     * @param { InspectionStopAssociationMap } associationMap
     * @returns {
     *  {
     *    added: InspectionStopAssociationMap,
     *    removed: InspectionStopAssociationMap
     *  }
     * }
     */
    InspectionStopAssociationMap.prototype.diff = function diff(
      associationMap
    ) {
      var result = {
        // initially, assume the lists share no similarities
        // then we'll trim off things that weren't actually added or removed
        added: associationMap.copy(),
        removed: this.copy(),
      };

      // anything that's already in this list isn't being added
      this.forEach(function (association) {
        result.added.removeAssociation(association);
      });

      // anything that's in the second list isn't being removed
      associationMap.forEach(function (association) {
        result.removed.removeAssociation(association);
      });

      return result;
    };

    /**
     * Duplicate an association map. Maintains references to associations. The structure
     * of the internal map is deep-copied, but the actual data isn't.
     * @returns { InspectionStopAssociationMap } A copy of this map
     */
    InspectionStopAssociationMap.prototype.copy = function copy() {
      var result = new InspectionStopAssociationMap();
      this.forEach(function (association) {
        result.addAssociation(association);
      });
      return result;
    };

    /**
     * Similar to array.forEach. Run a callback on each association in the map.
     * @param { (assocation: Association) => void } callback
     */
    InspectionStopAssociationMap.prototype.forEach = function forEach(
      callback
    ) {
      var buildingIds = Object.keys(this.map);
      for (var i = 0; i < buildingIds.length; i++) {
        var buildingId = buildingIds[i];
        var checklistTemplateIds = Object.keys(this.map[buildingId]);
        for (var j = 0; j < checklistTemplateIds.length; j++) {
          var checklistTemplateId = checklistTemplateIds[j];
          this.map[buildingId][checklistTemplateId].forEach(callback);
        }
      }
    };

    /**
     * Iteraate over all of the associations in a specified building
     * @param { string } buildingId
     * @param { (assocation: Association) => void } callback Invoked for each association in the
     *  building
     */
    InspectionStopAssociationMap.prototype.forEachInBuilding = function forEach(
      buildingId,
      callback
    ) {
      var map = this.map[buildingId] || {};
      var checklistTemplateIds = Object.keys(map);
      for (var i = 0; i < checklistTemplateIds.length; i++) {
        var checklistTemplateId = checklistTemplateIds[i];
        this.map[buildingId][checklistTemplateId].forEach(callback);
      }
    };

    /**
     * Get the index of the target association in the association's target submap
     * of the internal map.
     * @return { number } -1 if there is no matching association
     */
    InspectionStopAssociationMap.prototype.findIndex = function findIndex(
      association
    ) {
      var list = this.map[association.building];
      if (!list) {
        return -1;
      }
      list = list[association.checklist_template];
      if (!list) {
        return -1;
      }

      for (var i = 0; i < list.length; i++) {
        if (RoundTemplateService.stopLocationsAreEqual(association, list[i])) {
          return i;
        }
      }
      return -1;
    };

    /**
     * Flatten this map to an array of associations.
     * @returns { Association[] } An array of all associations in this map.
     */
    InspectionStopAssociationMap.prototype.toArray = function toArray() {
      var result = [];
      this.forEach(function (assocation) {
        result.push(assocation);
      });
      return result;
    };

    return InspectionStopAssociationMap;
  }
})();
