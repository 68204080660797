(function () {
  angular
    .module("akitabox.core.services.checklist", [
      "akitabox.constants",
      "akitabox.core.services.file",
      "akitabox.core.services.http",
    ])
    .factory("ChecklistService", ChecklistService);

  /** @ngInject */
  function ChecklistService(
    // Angular
    $q,
    // Constants
    models,
    // Services
    FileService,
    HttpService
  ) {
    var service = {
      get: get,
      getAll: getAll,
      getById: getById,
      getByWorkOrderId: getByWorkOrderId,
      getAllByWorkOrderId: getAllByWorkOrderId,
      getStatusClass: getStatusClass,
      complete: complete,
      count: count,
      reopen: reopen,
      update: update,
      updateAnswer: updateAnswer,
      updateMultipleAnswers: updateMultipleAnswers,
      getStats: getStats,
      skip: skip,
      updateSkipReason: updateSkipReason,
      exportChecklists: exportChecklists,
      getByInspectionStop,
      getByBuildingWorkOrder,
      updateByBuildingWorkOrder,
      updateAnswerByBuildingWorkOrder,
      updateMultipleAnswersByBuildingWorkOrder,
      completeByBuildingWorkOrder,
      reopenByBuildingWorkOrder,
      skipByBuildingWorkOrder,
      updateSkipReasonByBuildingWorkOrder,
      getStatusListByType,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Fetches checklists by their organization (limited)
     * @param {String} organizationId
     * @param {Object} query
     * @return {$q.Promise<Checklist[]>}
     */
    function get(organizationId, query) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );

      return HttpService.get(buildBaseRoute(organizationId), query);
    }

    /**
     * Fetches all checklists by their organization
     * @param {String} organizationId
     * @param {Object} query
     * @return {$q.Promise<Checklist[]>}
     */
    function getAll(organizationId, query) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );

      var useCache = false;

      // We dont use the cache becaus it's currently broken
      return HttpService.getAll(
        buildBaseRoute(organizationId),
        query,
        useCache
      );
    }

    function getById(organizationId, checklistId) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!checklistId)
        return $q.reject(new Error("ChecklistService: checklistId required"));

      return HttpService.getById(
        buildDetailRoute(organizationId, checklistId),
        checklistId,
        {},
        false
      );
    }

    function getByInspectionStop(
      organizationId,
      inspectionId,
      stopType,
      stopId
    ) {
      if (!organizationId) {
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      }
      if (!inspectionId) {
        return $q.reject(new Error("ChecklistService: inspectionId required"));
      }

      if (!["asset", "room", "level"].includes(stopType)) {
        return $q.reject(
          new Error("ChecklistService: stop type must be asset, room, or level")
        );
      }

      return HttpService.get(
        buildInspectionStopRoute(organizationId, inspectionId),
        {
          [stopType]: stopId,
        },
        false
      );
    }

    /**
     * Fetches checklists by their work order (limited)
     * @param {String} organizationId
     * @param {String} workOrderId
     * @return {$q.Promise<Checklist[]>}
     */
    function getByWorkOrderId(organizationId, workOrderId) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      var query = {
        task: workOrderId,
      };

      return get(organizationId, query);
    }

    /**
     * Fetches all checklists by their work order
     * @param {String} organizationId
     * @param {String} workOrderId
     * @return {$q.Promise<Checklist[]>}
     */
    function getAllByWorkOrderId(organizationId, workOrderId) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      var query = {
        task: workOrderId,
      };

      return getAll(organizationId, query);
    }

    /**
     * Completes a checklist
     * @param {String} organizationId
     * @param {String} checklistId
     * @return {$q.Promise<Checklist>} the completed checklist
     */
    function complete(organizationId, checklistId) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!checklistId)
        return $q.reject(new Error("ChecklistService: checklistId required"));

      var route = buildDetailRoute(organizationId, checklistId);
      var payload = {
        action: "complete",
      };

      return HttpService.put(route, payload);
    }

    function count(organizationId, queryString) {
      if (!organizationId) {
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      }

      if (!queryString) {
        queryString = {};
      }

      queryString.count = true;

      return HttpService.get(buildBaseRoute(organizationId), queryString).then(
        function (response) {
          return response.count;
        }
      );
    }

    /**
     * Reopens a checklist
     * @param {String} organizationId
     * @param {String} checklistId
     * @return {$q.Promise<Checklist>} the completed checklist
     */
    function reopen(organizationId, checklistId) {
      if (!organizationId) {
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      } else if (!checklistId) {
        return $q.reject(new Error("ChecklistService: checklistId required"));
      }

      var route = buildDetailRoute(organizationId, checklistId);
      var payload = {
        action: "reopen",
      };

      return HttpService.put(route, payload);
    }

    /**
     * Updates the checklist
     * @param {String} organizationId
     * @param {String} checklistId
     * @return {$q.Promise<Checklist[]>}
     */
    function update(organizationId, checklistId, data) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!checklistId)
        return $q.reject(new Error("ChecklistService: checklistId required"));
      var route = buildDetailRoute(organizationId, checklistId);
      return HttpService.put(route, data);
    }

    /**
     * Updates the answer to a checklist question
     * @param {String} organizationId
     * @param {String} checklistId
     * @return {$q.Promise<Checklist>} the checklist with the updated answer
     */
    function updateAnswer(organizationId, checklistId, questionId, value) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!checklistId)
        return $q.reject(new Error("ChecklistService: checklistId required"));
      else if (!questionId)
        return $q.reject(new Error("ChecklistService: questionId required"));
      else if (value === undefined)
        return $q.reject(new Error("ChecklistService: value required"));

      var route = buildDetailRoute(organizationId, checklistId);
      var payload = {
        action: "answer",
        question: questionId,
        value: value,
      };

      return HttpService.put(route, payload);
    }

    /**
     * Updates the answer to a checklist question
     * @param {String} organizationId
     * @param {String} checklistId
     * @param {Array[Object]} answers
     * @return {$q.Promise<Checklist>} the checklist with the updated answer
     */
    function updateMultipleAnswers(organizationId, checklistId, answers) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistService: organizationId required")
        );
      else if (!checklistId)
        return $q.reject(new Error("ChecklistService: checklistId required"));
      else if (!answers || !answers.length)
        return $q.reject(new Error("ChecklistService: answers required"));

      var route = buildDetailRoute(organizationId, checklistId);
      var payload = {
        action: "answerMultiple",
        answers: answers,
      };

      return HttpService.put(route, payload);
    }

    /**
     * Get checklist (stop) stats within an organization
     * @param {String} organizationId
     * @param {Object} params
     */
    function getStats(organizationId, params) {
      var route = "/stats/" + buildBaseRoute(organizationId);
      return HttpService.get(route, params);
    }

    /**
     * Skip a checklist
     * @param {String} organizationId - org the checklist belongs to
     * @param {String} checklistId - checklist to be skipped
     * @param {String} reason - reason we are skipping this checklist
     * @return {Promise<Checklist|Error>}
     */
    function skip(organizationId, checklistId, reason) {
      var route = buildDetailRoute(organizationId, checklistId);
      return HttpService.patch(route, { action: "skip", reason: reason });
    }

    /**
     * Update the reason behind skipping a checklist
     * @param {String} organizationId - org the checklist belongs to
     * @param {String} checklistId - checklist of the reason being updated
     * @param {String} reasonId - reason to be updated
     * @param {String} reason - reason we are skipping this checklist
     * @return {Promise<Checklist|Error>}
     */
    function updateSkipReason(organizationId, checklistId, reasonId, reason) {
      var route = buildDetailRoute(organizationId, checklistId);
      return HttpService.patch(route, {
        action: "skip",
        id: reasonId,
        reason: reason,
      });
    }

    /**
     * Export checklists to csv
     * @param {String} organizationId
     * @param {Object} params
     */
    function exportChecklists(organizationId, params) {
      params.export = "csv";
      params.exportCSV = true;
      var route = buildBaseRoute(organizationId);
      route += HttpService.buildQueryString(params);

      return FileService.download(route);
    }

    /** JIT Checklists Methods */
    function getByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));

      if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      const useCache = false;

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      // We dont use the cache becaus it's currently broken
      return HttpService.getAll(
        buildBuildingWorkOrderRoute(buildingId, workOrderId),
        query,
        useCache
      );
    }

    /**
     * Updates the checklist
     * @param {String} buildingId
     * @param {String} workOrderId
     * @return {$q.Promise<Checklist>}
     */
    function updateByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId,
      data
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));
      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);
      return HttpService.patch(route, data, query);
    }

    /**
     * Updates the answer to a checklist question
     * @param {String} buildingId
     * @param {String} workOrderId
     * @return {$q.Promise<Checklist>} the checklist with the updated answer
     */
    function updateAnswerByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId,
      questionId,
      value
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));
      else if (!questionId)
        return $q.reject(new Error("ChecklistService: questionId required"));
      else if (value === undefined)
        return $q.reject(new Error("ChecklistService: value required"));

      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);
      var payload = {
        action: "answer",
        question: questionId,
        value: value,
      };

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      return HttpService.patch(route, payload, query);
    }

    /**
     * Updates the answer to a checklist question
     * @param {String} buildingId
     * @param {String} workOrderId
     * @param {Array[Object]} answers
     * @return {$q.Promise<Checklist>} the checklist with the updated answer
     */
    function updateMultipleAnswersByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId,
      answers
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));
      else if (!answers || !answers.length)
        return $q.reject(new Error("ChecklistService: answers required"));

      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);
      var payload = {
        action: "answerMultiple",
        answers: answers,
      };

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      return HttpService.patch(route, payload, query);
    }

    /**
     * Completes a checklist
     * @param {String} buildingId
     * @param {String} workOrderId
     * @return {$q.Promise<Checklist>} the completed checklist
     */
    function completeByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);
      var payload = {
        action: "complete",
      };

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      return HttpService.patch(route, payload, query);
    }

    /**
     * Reopens a checklist
     * @param {String} buildingId
     * @param {String} workOrderId
     * @return {$q.Promise<Checklist>} the completed checklist
     */
    function reopenByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);
      var payload = {
        action: "reopen",
      };

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      return HttpService.patch(route, payload, query);
    }

    /**
     * Skips a checklist
     * @param {String} buildingId
     * @param {String} workOrderId
     * @param {String} reason - reason we are skipping this checklist
     * @return {$q.Promise<Checklist>} the completed checklist
     */
    function skipByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId,
      reason
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);
      var payload = {
        action: "skip",
        reason: reason,
      };

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      return HttpService.patch(route, payload, query);
    }

    /**
     * Update skip reason for a checklist
     * @param {String} buildingId
     * @param {String} workOrderId
     * @param {String} reason - reason we are skipping this checklist
     * @return {$q.Promise<Checklist>} the completed checklist
     */
    function updateSkipReasonByBuildingWorkOrder(
      buildingId,
      workOrderId,
      assetId,
      roomId,
      levelId,
      reasonId,
      reason
    ) {
      if (!buildingId)
        return $q.reject(new Error("ChecklistService: buildingId required"));
      else if (!workOrderId)
        return $q.reject(new Error("ChecklistService: workOrderId required"));

      var route = buildBuildingWorkOrderRoute(buildingId, workOrderId);
      var payload = {
        action: "skip",
        id: reasonId,
        reason: reason,
      };

      const query = getJITChecklistRequestQuery(assetId, roomId, levelId);

      return HttpService.patch(route, payload, query);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildInspectionStopRoute(organizationId, inspectionId) {
      return `${models.ORGANIZATION.ROUTE_PLURAL}/${organizationId}/${models.INSPECTION.ROUTE_PLURAL}/${inspectionId}/checklist`;
    }

    /**
     * Builds the url of: /organizations/:organiationId/checklists
     * @param {String} organizationId
     * @return {String}
     */
    function buildBaseRoute(organizationId) {
      if (!organizationId) throw new Error("organizationId required");

      return (
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        "/" +
        models.CHECKLIST.ROUTE_PLURAL
      );
    }

    /**
     * Builds the url of: /organizations/:organiationId/checklists/:checklistId
     * @param {String} organizationId
     * @return {String}
     */
    function buildDetailRoute(organizationId, checklistId) {
      if (!organizationId) throw new Error("organizationId required");
      else if (!checklistId) throw new Error("checklistId required");

      return buildBaseRoute(organizationId) + "/" + checklistId;
    }

    /**
     * Get the css class name that correlats to the checklist's status
     * Purely for front-end use only
     * @param {Checklist} checklist
     * @return {string} class name
     */
    function getStatusClass(checklist) {
      switch (checklist.status) {
        case models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO:
        case models.CHECKLIST.STATUSES.FAIL:
          return "fail";
        case models.CHECKLIST.STATUSES.PASS:
          return "pass";
        case models.CHECKLIST.STATUSES.SKIP:
          return "skip";
        default:
          return "";
      }
    }

    /**
     * Builds the url of: /buildings/:buildingId/tasks/:taskId/checklist
     * @param {String} buildingId
     * @param {String} workOrderId
     * @return {String}
     */
    function buildBuildingWorkOrderRoute(buildingId, workOrderId) {
      if (!buildingId) throw new Error("buildingId required");
      if (!workOrderId) throw new Error("workOrderId required");

      return (
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.WORK_ORDER.ROUTE_PLURAL +
        "/" +
        workOrderId +
        "/" +
        models.CHECKLIST.ROUTE_SINGULAR
      );
    }

    /**
     * Returns the filtered JIT Checklists request query with the most specific parameter
     * @param {String} assetId
     * @param {String} roomId
     * @param {String} levelId
     * @return {Object} query object
     */
    function getJITChecklistRequestQuery(assetId, roomId, levelId) {
      if (assetId) {
        return {
          asset: assetId,
        };
      }
      if (roomId) {
        return {
          room: roomId,
        };
      }
      if (levelId) {
        return {
          level: levelId,
        };
      }
      throw new Error(
        "One of the parameters must be passed: assetId, roomId or levelId"
      );
    }

    /**
     * Returns an array of checklist status corresponding to the specified type.
     * @param {"skip" | "complete" | "todo"} type
     * @returns {string[]} an array of checklist status
     */
    function getStatusListByType(type) {
      let statuses = [];

      switch (type) {
        case "skip":
          statuses = [models.CHECKLIST.STATUSES.SKIP];
          break;
        case "complete":
          statuses = [
            models.CHECKLIST.STATUSES.PASS,
            models.CHECKLIST.STATUSES.FAIL,
            models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO,
            models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO,
            models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO,
          ];
          break;
        default:
          statuses = [models.CHECKLIST.STATUSES.IN_PROGRESS];
          break;
      }

      return statuses;
    }
  }
})();
