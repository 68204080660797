(function () {
  angular
    .module("akitabox.ui.dialogs.qrCode.associate")
    .controller(
      "AssociateQrCodeDialogController",
      AssociateQrCodeDialogController
    );

  /* @ngInject */
  function AssociateQrCodeDialogController(
    // Dialogs
    CreateAssetDialog,
    CreateRoomDialog,
    QrPinSearchDialog,
    // Material
    $mdDialog,
    // Services
    QRCodeService,
    ShadowService,
    ToastService,
    UserService
  ) {
    var self = this;

    var permissions = UserService.getPermissions();

    // Attributes
    self.showPlanViewActions = angular.isDefined(self.showPlanViewActions)
      ? self.showPlanViewActions
      : false;
    self.canAssociate = permissions.qrcode.associate;

    // Functions
    self.cancel = cancel;
    self.createAsset = createAsset;
    self.createRoom = createRoom;
    self.searchForPin = searchForPin;
    self.selectFromFloorPlan = selectFromFloorPlan;

    // =================
    // Public Functions
    // =================

    /**
     * Closes the dialog
     */
    function cancel() {
      ShadowService.sendEvent("qr-code", "cancel-unassigned-dialog");
      $mdDialog.cancel();
    }

    /**
     * Create a new asset to associate with the dialog's given QR code.
     */
    function createAsset() {
      createPin("asset");
    }

    /**
     * Create a new room to associate with the dialog's given QR code.
     */
    function createRoom() {
      createPin("room");
    }

    /**
     * Show the dialog for the user to search for a pin to associate with the
     * dialogs' given QR code.
     *
     * If the user submits that dialog with a pin, this dialog will hide the
     * $mdDialog with an $event of form { actionTaken, [asset], [room] }. The
     * pin on the $event will always have the qrCode attached, since the search
     * dialog handles the association request, and will only hide if it is
     * successful.
     */
    function searchForPin() {
      var options = { locals: { code: self.qrCode._code } };
      var $event = { actionTaken: "search" };
      ShadowService.sendEvent("qr-code", "choose-assign-type", "find");
      var promise = QrPinSearchDialog.show(options).then(function (pin) {
        var pinModel = pin.pinType.protected_type.toLowerCase();
        $event[pinModel] = pin;
        return $event;
      });
      $mdDialog.hide(promise);
    }

    /**
     * Handle "Select Pin from Floor Plan" option; close dialog
     * @return {Promise|Object} promise that resolves with the actionTaken as "assign"
     */
    function selectFromFloorPlan() {
      ShadowService.sendEvent(
        "qr-code",
        "choose-assign-type",
        "select-from-plan"
      );
      return $mdDialog.hide({ actionTaken: "assign" });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Shows the create dialog for the given pin model. Associate the newly
     * created pin with the dialog's given QR code. When creation is completed,
     * hides the $mdDialog with an $event of form { actionTaken, [asset],
     * [room] }. The pin on the $event will have the qrCode attached iff the
     * QR code association was successful (but the dialog will be hidden
     * regardless).
     *
     * @param {String} pinModel - Type of pin to create (e.g. "asset", "room")
     */
    function createPin(pinModel) {
      var pin;
      var $event = { actionTaken: "create" };
      ShadowService.sendEvent(
        "qr-code",
        "choose-assign-type",
        "new-" + pinModel
      );
      var Dialog = pinModel === "asset" ? CreateAssetDialog : CreateRoomDialog;
      var options = {
        locals: {
          building: self.building,
          floor: self.floor,
          hideToast: self.hidePinCreateToast,
        },
      };
      var promise = Dialog.show(options).then(function (rooms) {
        pin = rooms[0];
        $event[pinModel] = pin;

        var association = {};
        association[pinModel] = pin._id;
        return QRCodeService.associate(self.qrCode._id, association)
          .then(function (qrCode) {
            $event[pinModel].qrCode = qrCode;
            return $event;
          })
          .catch(function (err) {
            ToastService.showError(err);
            return $event;
          });
      });
      $mdDialog.hide(promise);
    }
  }
})();
