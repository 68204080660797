(function () {
  angular
    .module("akitabox.desktop.asset.detail")
    .controller("AssetOverviewTabController", AssetOverviewTabController);

  /* @ngInject */
  function AssetOverviewTabController(
    // Angular
    $state,
    $q,
    $window,
    // Constants
    WORK_ORDER_STATUS_OPEN,
    // Dialogs
    CreateRequestDialog,
    CreateWorkOrderDialog,
    CreateScheduleDialog,
    DecommissionDialog,
    DeleteDialog,
    DownloadDocumentDialog,
    FilePreviewDialog,
    // Resolves
    asset,
    building,
    organization,
    // Services
    Router,
    AssetService,
    FeatureFlagService,
    NoteService,
    ToastService,
    UserService,
    WorkOrderService,
    WorkOrdersBFFService,
    // Third-party
    $scope,
    // Utils
    ServiceHelpers,
    models
  ) {
    var self = this;

    var permissions = UserService.getPermissions();

    var OVERDUE_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      due_date: "$lte," + Date.now(),
      asset: asset._id,
    };
    var OPEN_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      start_date: "$lte," + Date.now(),
      due_date: "$gte," + Date.now(),
      asset: asset._id,
    };

    // Attributes
    self.building = building;
    self.asset = asset;
    self.assetForOneWayBinds = angular.copy(asset);
    self.allOverdueWorkOrdersState = {
      state: "app.workOrders",
      params: OVERDUE_QUERY,
    };
    self.allOpenWorkOrdersState = {
      state: "app.workOrders",
      params: OPEN_QUERY,
    };
    self.overdueWorkOrders = [];
    self.openWorkOrders = [];
    self.overdueWorkOrdersCount = 0;
    self.openWorkOrdersCount = 0;
    self.activityParams = {
      asset: self.asset._id,
    };
    self.limit = 5;
    self.lastActivityItem = {
      activityType: "created-entity",
      entity: "Asset",
      account: self.asset.createdBy,
      building: self.asset.building,
      time: self.asset.createdAt,
    };
    self.floor = angular.copy(self.asset.level);
    self.room = angular.copy(self.asset.room);

    // Save off populated pin type (populated in asset detail route resolve)
    // so we don't have to re-fetch it every time we make an update to the asset
    // and the back end gives us a pinType ID instead of a pinType object
    var pinType = angular.copy(self.asset.pinType);

    self.permissions = getDefaultPermissions();
    self.organization = organization;

    // Functions
    self.init = init;
    self.createNote = createNote;
    self.createRequest = createRequest;
    self.createWorkOrder = createWorkOrder;
    self.createSchedule = createSchedule;
    self.decommissionAsset = decommissionAsset;
    self.deleteAsset = deleteAsset;
    self.openInPlanView = openInPlanView;
    self.showRelatedWorkOrders = showRelatedWorkOrders;
    self.showLifeCycleCard = organization.show_rpi_fci;
    self.showCostEstimation = organization.show_fca;
    self.onLocationChange = onLocationChange;
    self.updateLocation = updateLocation;
    self.getOverdueWorkOrdersLimit = getOverdueWorkOrdersLimit;
    self.getOpenWorkOrdersLimit = getOpenWorkOrdersLimit;
    self.openAttachment = openAttachment;

    // listen to external asset changes
    $scope.$on("asset-updated", function (event, args) {
      if (args.asset) {
        self.asset = angular.extend({}, self.asset, args.asset);
      }
    });

    init();

    function init() {
      parseRoomDisplayValue(self.room);
      if (organization.show_tasks) {
        // Populate lists
        getOverdueWorkOrdersLimit();
        getOpenWorkOrdersLimit();

        // Get total work order list counts
        getOverdueWorkOrdersCount();
        getOpenWorkOrdersCount();
      }
    }
    /**
     * This function overwrites the function used
     * to open the attachment on <abx-attachment-thumbnail> component
     */
    function openAttachment($event) {
      if (!$event || !$event.document || !self.asset) {
        ToastService.showError("Document selected is not available");
      } else {
        const { document, documentOrder } = $event;
        FilePreviewDialog.show({
          pin: self.asset,
          documentId: document._id,
          supportMultipleFiles: true,
          documentOrder,
        });
      }
    }

    function parseRoomDisplayValue(room) {
      if (!room) {
        return;
      }
      ServiceHelpers.getRoomDisplayName(room)
        .then(function (displayName) {
          self.roomDisplayName = displayName || room.name;
        })
        .catch(function (err) {
          self.roomDisplayName = room.name;
          ToastService.showError(err);
        });
    }

    function getDefaultPermissions() {
      return {
        canCreateRequest: permissions.request.create && organization.show_tasks,
        canCreateWorkOrder: permissions.task.create && organization.show_tasks,
        canCreateSchedule:
          permissions.future_task.create && organization.show_tasks,
        canSetLocation: permissions.asset.set_location,
        canUpdateLocation:
          permissions.asset.update_room || permissions.asset.update_level,
        canDelete: permissions.asset.remove,
        canDecommission: permissions.asset.decommission,
        canAddAttachments: permissions.asset.add_attachment,
      };
    }

    function updateLocation(buildingId, assetId, data) {
      self.room = data.populatedRoom;
      self.floor = data.populatedFloor;
      var roomData = {
        value: data.room,
      };
      var roomParams = {
        include_values: true,
      };
      return AssetService.updateRoom(
        buildingId,
        assetId,
        roomData,
        roomParams
      ).then(function (_updatedAsset) {
        if (roomData.value) {
          return _updatedAsset;
        }
        var floorData = {
          value: data.level,
        };
        var floorParams = {
          include_values: true,
        };

        return AssetService.updateFloor(
          buildingId,
          assetId,
          floorData,
          floorParams
        ).then(function (updatedAsset) {
          return updatedAsset;
        });
      });
    }

    function showRelatedWorkOrders() {
      if (!self.organization.show_tasks) return false;
      else if (self.asset.is_decommissioned) return false;
      return true;
    }

    function onLocationChange($event) {
      if ($event && $event.model) {
        refreshActivity();
        // Re-attach populated pin type
        $event.model.pinType = pinType;
        $event.model.level = self.floor;
        $event.model.room = self.room;
        /**
         * This will make sure the top level room of all these room routes is
         * updated, so when navigating between all room routes, you get the latest room
         * without ever doing a full refresh. This needs to be done because we do not
         * refresh the resolved room on any of these asset routes
         */
        angular.copy($event.model, self.asset);
        /**
         * Since the room is also one-way bound some some child components, we need
         * to trigger angular to perform a re-render of them room, we do so by doing
         * a es4 version of self.room = { ...self.room } to refresh the memory address
         * so angular knows to update all the child component's room
         */
        self.assetForOneWayBinds = angular.extend({}, $event.model);
        parseRoomDisplayValue(self.room);
      }
    }

    function createNote(noteText) {
      var data = {
        asset: self.asset._id,
        text: noteText,
      };

      NoteService.create(self.building._id, data)
        .then(function () {
          refreshActivity();
        })
        .catch(ToastService.showError);
    }

    function createRequest() {
      var locals = {
        building: self.building,
        asset: self.asset,
      };
      CreateRequestDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function createWorkOrder() {
      var locals = {
        building: self.building,
        assets: [self.asset],
      };
      CreateWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          var newWorkOrderRound = workOrders[0].round;

          for (var i = 0; i < newWorkOrderRound.length; i++) {
            if (
              newWorkOrderRound[i].asset &&
              newWorkOrderRound[i].asset._id === self.asset._id
            ) {
              self.openWorkOrders.unshift(workOrders[0]);
            }
          }
        })
        .catch(ToastService.showError);
    }

    function createSchedule() {
      var locals = {
        building: self.building,
        assets: [self.asset],
      };
      CreateScheduleDialog.show({ locals: locals })
        .then(function () {
          return getOpenWorkOrdersLimit();
        })
        .catch(ToastService.showError);
    }

    function decommissionAsset() {
      var locals = { asset: self.asset };
      DecommissionDialog.show({ locals: locals })
        .then(function (decommissionedAsset) {
          $state.go($state.current, {}, { reload: true });
        })
        .catch(ToastService.showError);
    }

    function deleteAsset() {
      var locals = {};
      locals.asset = self.asset;
      DeleteDialog.show({ locals: locals })
        .then(function () {
          Router.go("app.assets");
        })
        .catch(ToastService.showError);
    }

    function openInPlanView() {
      var url = $state.href("planView.building.floor", {
        buildingId: building._id,
        floorId: self.floor._id,
        asset: asset._id,
      });
      $window.open(url + "&mode=PLACEMENT", "_blank");
    }

    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function getOverdueWorkOrdersLimit() {
      var query = angular.extend({}, OVERDUE_QUERY, { limit: 5 });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        query
      )
        .then(function (overdueWorkOrders) {
          self.overdueWorkOrders = overdueWorkOrders;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrdersLimit() {
      var query = angular.extend({}, OPEN_QUERY, { limit: 5 });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        query
      )
        .then(function (openWorkOrders) {
          self.openWorkOrders = openWorkOrders;
        })
        .catch(ToastService.showError);
    }

    function getOverdueWorkOrdersCount() {
      var query = angular.extend({}, OVERDUE_QUERY, { count: true });
      WorkOrderService.getByBuildingId(self.building._id, query)
        .then(function (response) {
          self.overdueWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrdersCount() {
      var query = angular.extend({}, OPEN_QUERY, { count: true });
      WorkOrderService.getByBuildingId(self.building._id, query)
        .then(function (response) {
          self.openWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }
  }
})();
