(function () {
  /**
   * @ngdoc component
   * @name abxMultipleAssigneeInput
   *
   * @description
   * <abx-multiple-assignee-input> allows users to aggregate a bunch of assignees together
   */
  angular
    .module("akitabox.ui.components.multipleAssigneeInput", [
      "akitabox.core.toast",
      "akitabox.ui.components.typeAheadInput",
      "akitabox.ui.directives.autocomplete",
      "akitabox.ui.directives.field",
      "akitabox.core.services.permissionGroups",
    ])
    .component("abxMultipleAssigneeInput", {
      bindings: {
        /**
         * @prop {Array<Identity>} abx-assignees - list of assignees (identities)
         */
        assignees: "<abxAssignees",
        /**
         * @prop {Building} abx-building - building that the assignees belong to
         */
        building: "<?abxBuilding",
        /**
         * @prop {Buildings} abx-buildings - building list that the assignees belong to
         */
        buildings: "<?abxBuildings",
        /**
         * @prop {boolean} abx-disabled - flag that disables the entire input
         */
        disabled: "<?abxDisabled",
        /**
         * @prop {boolean} abx-is-mobile - flag that allows for a mobile version of this component
         */
        isMobile: "<?abxIsMobile",
        /**
         * @prop {function} abx-on-change - executed whenever an assignee is added or removed
         * returns with params ({ type: "add"|"remove", value: Array<Identity> })
         */
        onChange: "&abxOnChange",
        /**
         * @prop {boolean} abx-include-expired - flag that determines whether dropdowns should include
         * expired identities or only display active identities
         */
        includeExpired: "<?abxIncludeExpired",
        /**
         * @prop {object} abx-classes - an object containing all the classes to apply the this component
         * ONLY SUPPORTED ON THE MOBILE VERSION OF THIS COMPONENT
         * @example { root: "class class class", item: "class class class" }
         * root applies to the overarching div
         * item applies to the individual md-list-items
         */
        classes: "<?abxClasses",
        /**
         * @prop {Array<Identity>} abx-initial-assignee-options - list of assignees (identities), optional property to initialize
         * the options and prevent the initial API call for performance reasons. We'll clear this property out once an actual
         * fetch of users is triggered
         */
        initialAssigneeOptions: "<?abxInitialAssigneeOptions",
      },
      controller: AbxMultipleAssigneeInputController,
      controllerAs: "vm",
      templateUrl: [
        "$attrs",
        function ($attrs) {
          // abxIsMobile is a string so the value needs to be explictly checked
          var isMobile = $attrs.abxIsMobile === "true";

          if (isMobile) {
            return "app/core/ui/components/multiple-assignee-input/mobile-multiple-assignee-input.component.html";
          }

          return "app/core/ui/components/multiple-assignee-input/multiple-assignee-input.component.html";
        },
      ],
    });

  /* @ngInject */
  function AbxMultipleAssigneeInputController(
    $log,
    $scope,
    $timeout,
    EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION,
    ToastService,
    UserService,
    OrganizationService,
    PermissionGroupsService
  ) {
    // ------------------------
    //   Attaching to This
    // ------------------------
    var self = this;
    self.organization = OrganizationService.getCurrent();
    self.loading = false;
    // keep overall state, when adding and removing across all assignees
    self.adding = false;
    self.removing = false;
    // props
    self.initialAssigneeOptions = self.initialAssigneeOptions || [];
    self.assignees = self.assignees || [];
    self.assigneesLength = self.assignees.length;
    self.assigneesInputValues = []; // holds the assignee models and display values
    self.addAssigneeOptions = getOptionsFromUsers(self.initialAssigneeOptions); // list of all the possible assignees to choose from
    self.addNewAssigneeValue = ""; // default HTML Input value attr for adding a new assignee
    self.mobileAssigneeQuery = ""; // Search text for mobile dropdown

    // This prop is only supported and used in the mobile version
    self.classes = self.classes || {};
    if (!self.classes.root) self.classes.root = "";
    if (!self.classes.item) self.classes.item = "";

    // ------------------------
    //   Life Cycle
    // ------------------------

    /**
     * Triggers on initialization of the component
     */
    self.$onInit = function () {
      if (self.includeExpired) {
        // if including expired options, group them in typeahead dropdowns
        self.optionsGroupKey = "secondaryOptionName";
      }
      // check if assignees passed in are full objects, error if not
      if (self.assignees.length) {
        self.assignees.map(function (assignee) {
          if (
            !assignee ||
            !assignee._id ||
            !assignee.display_name ||
            !assignee.email
          ) {
            throw new Error(
              "AbxMultipleAssigneeInput: abx-assignees is invalid"
            );
          }
        });
      }
    };

    /**
     * Life cycle fn that triggers every time a one-way bound prop changes
     * @param {object} changes - see angular docs on this
     * @param {Array<Identity>} changes.assignees - list of assignees
     */
    self.$onChanges = function (changes) {
      if (changes.assignees) {
        // Every time the assignees is changed externally, we need to re-sync
        syncAssigneesAndInputValues();
      }

      // Only fetch users IF
      // - building(s) changed and this isn't the initial load of the component
      // - OR no initial assignee options were provided
      if (
        (changes.building && !changes.building.isFirstChange()) ||
        (changes.buildings && !changes.buildings.isFirstChange()) ||
        (!self.initialAssigneeOptions.length && !self.addAssigneeOptions.length)
      ) {
        /**
         * Every time the building changes, we need to re-fetch the all the new
         * possible assignees for that building. Excluding the group of users
         * in the Service Request Portal permission group.
         */
        getSRPPermissionGroupIdByOrg(self.organization._id).then(
          (srpPermissionGroupId) => {
            const params = { permission_group: `$ne,${srpPermissionGroupId}` };
            self.onFetchUsers(params);
          }
        );
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    // ------------------------
    //   Desktop
    // ------------------------

    /**
     * Handles what happens when a user selects an assignee from the dropdown
     * options by adding a new user to the assignees list. Will eventually call
     * the onChange fn with that new assignees list
     *
     * @param {object} $event
     * @param {Identity} $event.model - the assignee
     * @param {string} $event.value - the assignee's display value (display_name)
     * @param {number} [$index] - the index of where the assignee was selected
     * from within the array of assignees
     *
     * @return {void}
     */
    self.onSelectAssigneeHandler = function ($event, $index) {
      var assignee = $event.model;

      var matchingAssignees = self.assignees.filter(function (_assignee) {
        if (!_assignee) return;
        return _assignee._id === assignee._id;
      });

      if (matchingAssignees.length) {
        ToastService.showError(
          "User already assigned: " + assignee.display_name
        );
        return;
      }

      if ($index && self.assignees.length < $index) {
        ToastService.showError(
          "Cannot assign user (" +
            assignee.display_name +
            ") to an unavailable spot: "
        );
        return;
      }

      self.adding = true;
      let onChangePromise;

      if (!isNaN($index)) {
        self.assignees[$index].__adding = true;
        // Replace assignee for a given index
        const newAssignees = angular.copy(self.assignees);
        newAssignees[$index] = assignee;
        onChangePromise = self
          .onChange({
            $event: {
              type: "replace",
              model: assignee,
              value: newAssignees,
            },
          })
          .catch(() => {
            self.assignees = angular.copy(self.assignees);
            self.assigneesInputValues[$index] = {
              model: self.assignees[$index],
              value: self.assignees[$index].email
                ? self.assignees[$index].display_name +
                  " | " +
                  self.assignees[$index].email
                : self.assignees[$index].display_name,
            };
          });
      } else {
        // they're adding an assignee to the end of the list or adding their very first
        // assignee, we need to create a place holder (UI) assignee while its being added via
        // the api
        const newAssignee = { ...assignee, __adding: true };
        self.assignees.push(newAssignee);
        $index = self.assignees.length - 1;
        self.assigneesInputValues[$index] = {
          model: newAssignee,
          value: newAssignee.email
            ? newAssignee.display_name + " | " + newAssignee.email
            : newAssignee.display_name,
        };

        // Add new assignee to the list
        // Send an event to parent component
        onChangePromise = self
          .onChange({
            $event: {
              type: "add",
              model: assignee,
              // quick way to send a duplicate of our array to parent fn
              value: self.assignees.concat([]),
            },
          })
          .catch(() => {
            // remove the assignee that failed to be added
            self.assignees = self.assignees.filter(
              (assignee) => !assignee.__adding
            );
          });
        // Reset the input to blank
        self.addNewAssigneeValue = "";
      }

      return onChangePromise.finally(() => {
        // clean up state properties
        if (self.assignees[$index]) {
          delete self.assignees[$index].__adding;
        }
        self.adding = false;
      });
    };

    /**
     * Removes and assignee from the list and calls the parent onChange fn
     *
     * @param {Identity} assignee - the assignee to remove
     *
     * @return {void}
     */
    self.onRemoveAssigneeHandler = function (assignee) {
      if (!assignee) {
        $log.error("Cannot remove non existing assignee");
        return;
      }

      // Remove from array
      var newAssigneeList = self.assignees.filter(function (_assignee) {
        return _assignee._id !== assignee._id;
      });
      self.assigneesLength = self.assignees.length;

      if (newAssigneeList.length === self.assignees.length) {
        // If no one was removed, don't report it as a change
        return;
      }

      // Send an event to parent component
      assignee.__removing = true;
      // set the overall action to removing
      self.removing = true;
      return self
        .onChange({
          $event: {
            type: "remove",
            model: assignee,
            value: newAssigneeList,
          },
        })
        .finally(() => {
          // clean up state properties
          delete assignee.__removing;
          self.removing = false;
        });
    };

    /**
     * Fill back in the value of the HTML input if a user clicks elsewhere with the
     * original value of teh assignee that was there
     *
     * @param {number} $index - index of the assignee input that lost focus
     *
     * @return {void}
     */
    // TODO: Ensure onBlurHandler resets the state of the input - JF 10/17/19
    self.onBlurHandler = function ($index) {
      const identity = self.assignees[$index];
      self.assigneesInputValues[$index].model = identity;
      self.assigneesInputValues[$index].value =
        identity.display_name !== identity.email
          ? identity.display_name + " | " + identity.email
          : identity.display_name;
    };

    /**
     * Whenever the value of a single input is changed, this fn will reflect
     * that change onto our variable that stores all those input values (assigneesInputValues)
     * - handles special case of user clicking the X to clear out the value
     * - re-creates the list of assignee options each time the input value is changed
     *
     * @param {object} $event
     * @param {Identity} $event.model - the assignee that had its value changed
     * @param {string} $event.value - the value of the HTML input
     * @param {Boolean} [$event.clearInput] - true if the value should be cleared
     * @param {number} [$index] - the index of the assignee that was changed
     *
     * @return {void}
     */
    self.onChangeAssigneeHandler = function ($event, $index) {
      // clear out the value

      if ($index === undefined) {
        // we are changing the input value of the lowest (soon to be assignee) input
        self.addNewAssigneeValue = $event.value;
      } else if ($event.clearInput) {
        self.assigneesInputValues[$index].model = undefined;
        self.assigneesInputValues[$index].value = "";
      } else {
        self.assigneesInputValues[$index].model = $event.model;
        self.assigneesInputValues[$index].value = $event.value;
      }
    };

    /**
     * Fetches users and add them to the dropdown list
     *
     * @return {Promise<Array<Identity>>}
     */
    self.onFetchUsers = function (params = {}) {
      // Since these are optional params, they both need to be checked before we process the fetch
      if (!self.building && !self.buildings) return;

      var buildings;
      if (self.building) {
        buildings = self.building._id;
      } else if (self.buildings) {
        const buildingsIds = self.buildings.map((t) => {
          return t._id || t;
        });
        buildings = "$in," + buildingsIds.join(",");
      }
      var reqParams = {
        identity: "$ne,null",
        buildings: buildings,
        sort: "firstOrEmail,asc",
        ...params,
      };
      if (!self.includeExpired) {
        reqParams.status = "active";
      }
      var request = UserService.getAll(self.organization._id, reqParams);
      return request.then(function (users) {
        if (angular.isEmpty(users)) {
          self.addAssigneeOptions = [];
        } else {
          // set the option's secondaryOption property if the identity is
          // expired. This will allow it to be styled differently in the
          // type-ahead dropdowns

          // `secondaryOption` is a boolean that enables styling
          // `secondaryOptionName` is the "group title" that will be shown in
          // the dropdown
          self.addAssigneeOptions = getOptionsFromUsers(users);
        }
      });
    };

    self.onAssigneeRender = function () {
      $scope.$broadcast(EVENT_UPDATE_ABX_TYPE_AHEAD_OPTION_LOCATION);
    };

    // ------------------------
    //   Mobile
    // ------------------------

    /**
     * Filters the list of available options.
     * Function is fed into the mobile autocomplete
     *
     * @param {string} assigneeQuery - Search text
     * from within the array of assignees
     *
     * @return {Array<Identity>}
     */
    self.filterMobileAssigneeOptions = function (assigneeQuery) {
      if (angular.isEmpty(assigneeQuery)) {
        return getAddAssignees(self.addAssigneeOptions);
      }

      var matchExp = new RegExp(assigneeQuery, "i");
      var filteredAssigneeOption = self.addAssigneeOptions.filter(function (
        option
      ) {
        var model = option.model;
        if (model.display_name.match(matchExp)) {
          return true;
        } else if (model.email.match(matchExp)) {
          return true;
        }
        return false;
      });

      return getAddAssignees(filteredAssigneeOption);
    };

    /**
     * Handles the bridging of events between a desktop and a mobile user template.
     * This function creates an interface between the autocomplete directive used in
     * mobile and the application logic used in our component.
     *
     * @param {object} $event
     * @param {Identity} $event.model - the assignee
     * @param {string} $event.value - the assignee's display value (display_name)
     * @param {number} [$index] - the index of where the assignee was selected
     * from within the array of assignees
     *
     * @return {void}
     */
    self.onMobileSelectedItemChange = function ($event, $index) {
      var assignee = $event.model;
      var matchingAssignee = self.assignees[$index];

      // When autocomplete component loads it invokes this function
      // and we need to make sure we are not trying to reassign
      // the same assignees
      if (
        !assignee ||
        (matchingAssignee && matchingAssignee._id === assignee._id)
      ) {
        return;
      }

      self.onSelectAssigneeHandler($event, $index);
    };

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Rebuild our array of assignee input values with what's currently in the
     * assignees list
     */
    function syncAssigneesAndInputValues() {
      self.assigneesInputValues = self.assignees.map(function (assignee) {
        return {
          model: assignee,
          value:
            assignee.display_name !== assignee.email
              ? assignee.display_name + " | " + assignee.email
              : assignee.display_name,
        };
      });
      self.assigneesLength = self.assignees.length;
    }

    /**
     * Extracts assignees from assignee options
     *
     * @param {object} assigneeOptions - A list of assignee options represented as {model, value}
     *
     * @returns {Array<Identity>} - List of assignees
     */
    function getAddAssignees(assigneeOptions) {
      return assigneeOptions.map(function (_option) {
        return _option.model;
      });
    }

    function getOptionsFromUsers(users) {
      return users.map(function (user) {
        var secondaryOptionName = user.is_deactivated ? "Inactive" : "Active";

        return {
          model: user.identity,
          value:
            user.identity.display_name !== user.identity.email
              ? user.identity.display_name + " | " + user.identity.email
              : user.identity.display_name,
          secondaryOption: user.is_deactivated,
          secondaryOptionName: secondaryOptionName,
        };
      });
    }

    function getSRPPermissionGroupIdByOrg(organizationId) {
      self.loading = true;
      return PermissionGroupsService.getSRPPermGroupIdByOrg(organizationId)
        .then((id) => {
          return id;
        })
        .catch(function (err) {
          ToastService.showError(err);
        })
        .finally(function () {
          self.loading = false;
        });
    }
  }
})();
