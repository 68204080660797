(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.activityList
   */
  angular
    .module("akitabox.ui.directives.activityList")
    .controller("AbxActivityListController", AbxActivityListController)

    /**
     * @ngdoc component
     * @module akitabox.ui.directives.activityList
     * @name AbxActivityList
     * @restrict E
     *
     * @description
     * `<abx-activity-list>` is a list container with title for 1..n `<abx-activity-list-item` elements
     *
     * @usage - DEPRECATED
     * <hljs lang="html">
     * <abx-activity-list title="Recent Activity">
     *     <abx-activity-list-item type="note" ng-model="vm.recentNote"></abx-activity-list-item>
     * </abx-activity-list>
     * </hljs>
     */
    .component("abxActivityList", {
      templateUrl: "app/core/ui/directives/activity-list/activity-list.html",
      controller: "AbxActivityListController",
      controllerAs: "vm",
      bindings: {
        title: "@?",
        fetchParams: "<",
        noteParams: "<?",
        limit: "<?",
        lastItem: "<?",
        building: "<?abxBuilding",
        organization: "<?abxOrganization",
        workOrder: "<?abxWorkOrder",
        createNote: "&?",
        hasRequester: "<?abxHasRequester",
        request: "<?abxRequest",
        filterLogs: "<?",
        filterWorkOrders: "<?",
        showAttachments: "<?abxShowAttachments",
      },
    });

  var ACTIVITY_LIST_FILTER_OPTIONS = [
    {
      value: null,
      label: "All",
    },
    {
      value: "workOrderLogs",
      label: "Logged Work",
    },
    {
      value: "notes",
      label: "Messages",
    },
  ];
  var ACTIVITY_TYPE_NOTE = "note";

  /** @ngInject */
  function AbxActivityListController(
    // Angular
    $scope,
    $window,
    $q,
    $timeout,
    $interval,
    // Events
    EVENT_LOG_WORK,
    // Services
    FloorService,
    IdentityService,
    ToastService,
    RecentActivityService,
    WorkOrderLogService,
    RequestService,
    RoomService,
    OrganizationService,
    ShadowService,
    UserService,
    models,
    // Dialogs
    WorkOrderLogDialog,
    // Libraries
    moment
  ) {
    var self = this;

    var REFRESH_FREQUENCY_SECONDS = 60; // how often we auto-refresh
    var secondsUntilNextRefresh = 60;
    var refreshInterval;
    var hasFocus = true;
    var blurredAt = moment();
    var organization = OrganizationService.getCurrent();
    var showInspections = organization.show_inspections;
    var assetRoutes = ["asset_detail", "asset_value_detail"];

    // Attributes
    self.filterOptions = ACTIVITY_LIST_FILTER_OPTIONS.slice().filter(function (
      option
    ) {
      return option.value !== "workOrderLogs" || self.filterLogs;
    });
    self.filter = null;
    self.items = [];
    self.fetching = false;
    self.limit = self.limit || 20;
    self.hasRequester = self.hasRequester || false;
    self.isAdmin = false;
    self.isTwoWayCommunicationEnabled =
      organization.show_two_way_portal && organization.enable_two_way_comm;

    self.nextRefreshAt = moment().add(REFRESH_FREQUENCY_SECONDS, "seconds");
    self.refreshCountdownDisplay = "60 seconds";
    self.users = [];

    // Initialize perms
    self.permissions = getPermissions();

    // Functions
    self.fetchOlderItems = fetchOlderItems;
    self.fetchNewItems = fetchNewItems;
    self.onCreateNote = onCreateNote;
    self.onFilterChange = onFilterChange;
    self.showMore = showMore;
    self.moreToLoad = moreToLoad;
    self.resetActivities = resetActivities;
    self.resetWorkOrderLogs = resetWorkOrderLogs;
    self.fetchInitialItems = fetchInitialItems;
    self.showLogWorkDialog = showLogWorkDialog;
    self.toggleLocked = toggleLocked;
    self.deleteItem = deleteItem;

    self.$onInit = init;

    function init() {
      resetActivities();

      $scope.$on("activity:refresh", function () {
        fetchUsers().then(() => fetchNewItems());
      });

      $window.addEventListener("blur", windowBlur);
      $window.addEventListener("focus", windowFocus);

      IdentityService.getCurrent().then(function (identity) {
        self.currentUser = identity;
      });

      if (self.workOrder) {
        $scope.$on(EVENT_LOG_WORK, function (event, eventBody) {
          if (
            eventBody &&
            (eventBody.task === self.workOrder._id || !eventBody.task)
          ) {
            resetWorkOrderLogs();
            fetchNewItems();
          }
        });
        resetWorkOrderLogs();
      }
      resetRefreshTimer();
    }

    function getPermissions() {
      var userPermissions = UserService.getPermissions();
      var canLogWork = false;
      if (self.workOrder) {
        var currentUser = UserService.getCurrent();
        var identityId = currentUser.identity._id;
        var assignees = self.workOrder.assignees.map(function (assignee) {
          if (assignee._id) return assignee._id;
          return assignee;
        });
        var isAssigned = assignees.indexOf(identityId) > -1;
        canLogWork =
          userPermissions.work_order_log.create_any ||
          userPermissions.work_order_log.create_own_log_on_any_task ||
          isAssigned;
      }
      return {
        // only set this here if new perms enabled, if not, set to false and determine it later
        canToggleRequestMessaging:
          userPermissions.request.update &&
          self.hasRequester &&
          self.isTwoWayCommunicationEnabled,
        canLogWork: canLogWork,
      };
    }

    /**
     * Update WO log stats
     */
    function resetWorkOrderLogs() {
      return $q
        .all([
          WorkOrderLogService.getStatsByTaskId(
            self.building._id,
            self.workOrder._id,
            {
              operator_field: "work_minutes",
              operator: "sum",
            }
          ),
          WorkOrderLogService.getStatsByTaskId(
            self.building._id,
            self.workOrder ? self.workOrder._id : null,
            {
              operator_field: "cost",
              operator: "sum",
            }
          ),
        ])
        .then(function (results) {
          var estimatedHours = self.workOrder.estimated_man_hr;
          var workMinutes = results[0][0].result;
          var cost = results[1][0].result;

          self.workOrderLogStats = {
            estimatedMinutes: ~~(estimatedHours * 60),
            totalLoggedMinutes: workMinutes,
            totalCost: cost,
          };
        });
    }

    /**
     * Clear and reinitialize the activity feed.
     */
    function resetActivities() {
      if (self.building) {
        self.activityFeed = RecentActivityService.getActivityFeed(
          self.building._id,
          getQueryParams({
            sort_by: "cre_date,desc",
            limit: self.limit,
          }),
          getActivityOptions()
        );
      } else {
        self.activityFeed = RecentActivityService.getOrgActivityFeed(
          OrganizationService.getCurrent()._id,
          getQueryParams({ sort_by: "cre_date,desc", limit: self.limit }),
          getActivityOptions()
        );
      }
      // clear items to avoid appending duplicates
      self.items = [];
      return fetchInitialItems()
        .then(fetchUsers)
        .then(() => resetRefreshTimer());
    }

    function onFilterChange(value) {
      self.filter = value;
      resetActivities();
    }

    function showMore() {
      return self.fetchOlderItems();
    }

    function moreToLoad() {
      return self.activityFeed.hasMore();
    }

    function windowBlur() {
      blurredAt = moment();
      hasFocus = false;
    }

    function windowFocus() {
      var blurDuration = moment().diff(blurredAt, "seconds");
      self.nextRefreshAt.add(blurDuration, "seconds");
      hasFocus = true;
    }

    function resetRefreshTimer() {
      self.nextRefreshAt = moment().add(REFRESH_FREQUENCY_SECONDS, "seconds");
      secondsUntilNextRefresh = REFRESH_FREQUENCY_SECONDS;
      refreshInterval = $interval(function () {
        if (!hasFocus) {
          return;
        }
        var remaining = self.nextRefreshAt.diff(moment(), "seconds");
        if (remaining <= 0) {
          $interval.cancel(refreshInterval);
          self.fetchNewItems();
        } else if (remaining !== secondsUntilNextRefresh) {
          self.refreshCountdownDisplay =
            remaining + " second" + (remaining === 1 ? "" : "s");
          secondsUntilNextRefresh = remaining;
        }
      }, 250);
    }

    /**
     * Get options for the recent activity service. Depends on current filters.
     */
    function getActivityOptions() {
      var activityOptions = {};
      switch (self.filter) {
        case "notes":
          activityOptions.showNotes = true;
          activityOptions.noteParams = self.noteParams;
          break;
        case "workOrderLogs":
          activityOptions.showWorkOrderLogs = true;
          break;
        default:
          activityOptions.showNotes = true;
          activityOptions.noteParams = self.noteParams;
          activityOptions.showWorkOrderLogs = Boolean(self.filterLogs);
          activityOptions.showWorkOrders = Boolean(self.filterWorkOrders);
          activityOptions.showAttachments = Boolean(self.showAttachments);
          activityOptions.showUserActions = true;
          activityOptions.showReactiveInspectionTasks =
            self.workOrder &&
            showInspections &&
            !organization.third_party_cmms_reactive_wos;
          activityOptions.showReactiveInspectionTaskLinks =
            self.workOrder &&
            showInspections &&
            organization.third_party_cmms_reactive_wos;
          break;
      }

      return activityOptions;
    }

    /**
     * Spread custom query params over the defaults
     * @param { Object } custom
     * @return { Object }
     */
    function getQueryParams(custom) {
      return Object.assign(custom, self.fetchParams);
    }

    function beforeFetch(resetTimer) {
      var reset =
        typeof resetTimer === "undefined" ? true : Boolean(resetTimer);
      self.fetching = true;
      if (reset) {
        $interval.cancel(refreshInterval);
        self.refreshCountdownDisplay = REFRESH_FREQUENCY_SECONDS + " seconds";
      }
    }

    function parseActivity(userActions) {
      const results = [];
      const promises = userActions.map((userAction) => {
        // Check if it's an asset user action
        if (
          assetRoutes.includes(userAction.route_name) &&
          userAction.activityType === "userAction"
        ) {
          return findDifferences(userAction)
            .then((userActionDiff) => {
              results.push({
                ...userAction,
                ...userActionDiff,
                isDetailed: true,
              });
            })
            .catch((err) => {
              ToastService.showError(err);
              self.fetching = false;
              return;
            });
        } else {
          results.push({ ...userAction, isDetailed: false });
        }
      });

      return $q
        .all(promises)
        .then(() => {
          const sortedResults = results.sort(function (a, b) {
            return new Date(b.time) - new Date(a.time);
          });
          return sortedResults;
        })
        .catch((err) => {
          ToastService.showError(err);
          self.fetching = false;
          return [];
        });
    }
    /**
     * Initialize the activity feed and load the first page of results.
     */
    function fetchInitialItems() {
      // set fetching to false to fetch items after WO log update
      if (!self.items.length) {
        self.fetching = false;
      }
      if (self.fetching) {
        return $q.resolve();
      }
      beforeFetch();

      self.fetching = true;
      return self.activityFeed
        .init()
        .then(function () {
          return self.activityFeed.popNewestPage();
        })
        .then(function (activity) {
          return parseActivity(activity);
        })
        .then((pages) => {
          return parseNotes(pages);
        })
        .then((pages) => {
          self.items = pages;
          self.fetching = false;
        })
        .catch((err) => {
          ToastService.showError(err);
          self.fetching = false;
        });
    }

    function parseNotes(pages) {
      return UserService.get(organization._id, {
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
      })
        .then(function (users) {
          let formattedPages = [];
          for (const page of pages) {
            if (page.activityType === ACTIVITY_TYPE_NOTE) {
              let comment = page.text.replaceAll("\n", "<br>");
              const userIds = getUsersTag(comment);
              for (const userId of userIds) {
                const user = users.find((u) => u._id === userId);
                comment = user.is_deactivated
                  ? comment.replace(
                      `<@${userId}>`,
                      `<div class="mention-disabled"><span>${user.identity.display_name}</span><span> (deactivate user)</span></div>`
                    )
                  : comment.replace(
                      `<@${userId}>`,
                      `<div class="mention">${user.identity.display_name}</div>`
                    );
              }
              formattedPages.push({ ...page, text: comment });
            } else {
              formattedPages.push(page);
            }
          }

          return formattedPages;
        })
        .catch(ToastService.showError);
    }

    /**
     * Check if the comment have users tagged with a regex
     * @param {string} text comment text
     * @returns Array of UserId
     */
    function getUsersTag(text) {
      const matches = text.matchAll(/<@(?<userId>[a-z0-9]+)>/g);
      const userIds = [];
      for (let match of matches) {
        userIds.push(match.groups["userId"]);
      }
      return userIds;
    }

    /**
     * Fetch activity items that may have been created since our initial fetch.
     */
    function fetchNewItems() {
      if (self.fetching) {
        return;
      }
      beforeFetch();

      self.fetching = true;
      self.activityFeed
        .loadNewItems()
        .then(function (newItems) {
          return parseActivity(newItems);
        })
        .then((newItems) => {
          return parseNotes(newItems);
        })
        .then((newItems) => {
          self.items = newItems.concat(self.items);
        })
        .catch(ToastService.showError)
        .then(() => {
          self.fetching = false;
          resetRefreshTimer();
        });
    }

    function fetchUsers() {
      if (self.fetching) {
        return $q.resolve();
      }

      self.fetching = true;
      return UserService.getAll(organization._id, {
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
      })
        .catch((err) => {
          ToastService.showError(err);
          return [];
        })
        .then((users) => {
          self.users = users;
          self.fetching = false;
        });
    }

    /**
     * Load an additional page of items from the activity feed.
     */
    function fetchOlderItems() {
      if (self.fetching) {
        return;
      }
      beforeFetch(false);

      self.fetching = true;
      return self.activityFeed
        .popNewestPage()
        .then(function (activity) {
          return parseActivity(activity);
        })
        .then((pages) => {
          return parseNotes(pages);
        })
        .then((page) => {
          self.items = self.items.concat(page);
          self.fetching = false;
          return page;
        })
        .catch((err) => {
          ToastService.showError(err);
          self.fetching = false;
        });
    }

    function onCreateNote(noteText, sendToRequester) {
      // Call parent function if it exists
      if (angular.isFunction(self.createNote)) {
        self.createNote({
          noteText: noteText,
          sendToRequester: sendToRequester,
        });
      }
    }

    function showLogWorkDialog() {
      WorkOrderLogDialog.show({
        locals: {
          workOrder: self.workOrder,
        },
      }).then(function () {
        ShadowService.sendEvent("workorder", "add-log", "activity-card");
        $timeout(function () {
          $scope.$broadcast("activity:refresh");
        });
      });
    }

    function toggleLocked() {
      if (!self.permissions.canToggleRequestMessaging) {
        // new perms, if user can't edit service requests, don't allow them to toggle this feature
        return;
      }

      RequestService.update(self.request.building, self.request._id, {
        locked: self.request.locked,
      }).catch(ToastService.showError);
    }

    function deleteItem(itemID) {
      self.items = self.items.filter(function (item) {
        return item._id !== itemID;
      });
    }

    /**
     * @param {String} data_type pin type field data type
     * @returns True if it's supported
     */
    function isPinFieldDataTypeSupported(data_type) {
      const unsupportedPinFieldDataTypes = [
        "document_array",
        "tag_filter",
        "tree",
      ];

      return !unsupportedPinFieldDataTypes.includes(data_type);
    }

    /**
     * @param {Object} userAction userAction object
     * @returns The value according to the action body, name action is returned by default
     */
    function getChanges(userAction) {
      switch (userAction.body.action) {
        case models.ASSET.ACTIONS.UPDATE_ROOM:
          return {
            oldValue: userAction.diff.asset.old.room,
            newValue: userAction.diff.asset.new.room,
            changeMessage: `updated "Room"`,
            action: userAction.body.action,
            canRevert: true,
          };
        case models.ASSET.ACTIONS.UPDATE_CONDITION:
          return {
            changeMessage: `updated "Condition"`,
            action: userAction.body.action,
            canRevert: false,
          };
        case models.ASSET.ACTIONS.UPDATE_FLOOR:
          return {
            oldValue: userAction.diff.asset.old.level,
            newValue: userAction.diff.asset.new.level,
            changeMessage: `updated "Floor"`,
            action: userAction.body.action,
            canRevert: true,
          };
        case models.ASSET.ACTIONS.DECOMMISSION:
          return {
            changeMessage: "decommissioned this asset",
            action: userAction.body.action,
            canRevert: false,
          };
        case models.ASSET.ACTIONS.RECOMMISSION:
          return {
            changeMessage: "recommissioned this asset",
            action: userAction.body.action,
            canRevert: false,
          };
        case models.ASSET.ACTIONS.SET_LOCATION:
          return {
            oldValue: {
              percentX: userAction.diff.asset.old.percentX,
              percentY: userAction.diff.asset.old.percentY,
              page: userAction.diff.asset.old.page,
            },
            newValue: {
              percentX: userAction.diff.asset.new.percentX,
              percentY: userAction.diff.asset.new.percentY,
              page: userAction.diff.asset.new.page,
            },
            changeMessage: `updated "Location"`,
            action: userAction.body.action,
            canRevert: true,
          };
        case models.ASSET.ACTIONS.CHANGE_PIN_TYPE:
          return {
            changeMessage: `changed "Category"`,
            action: userAction.body.action,
            canRevert: false,
          };
        case models.ASSET.ACTIONS.UPDATE_VALUES: {
          const oldAssetValues = userAction.diff.asset.old.values;
          const newAssetValues = userAction.diff.asset.new.values;
          const changedValues = userAction.body.values;
          const pinType = userAction.pin_type;

          let oldValues = "";
          let newValues = "";

          if (oldAssetValues && newAssetValues && changedValues) {
            oldValues = Object.keys(changedValues)
              .map((key) => {
                const assetValue = oldAssetValues.find(
                  (value) => value._id === key
                );
                const field = pinType.fields.find(
                  (value) => value._id === assetValue.pinField
                );
                const fieldName = field.name || `Field: `;
                return field ? `${fieldName}: ${assetValue.value}` : fieldName;
              })
              .join(", ");
            newValues = Object.keys(changedValues)
              .map((key) => {
                const assetValue = newAssetValues.find(
                  (value) => value._id === key
                );
                const field = pinType.fields.find(
                  (value) => value._id === assetValue.pinField
                );
                const fieldName = field.name || `Field: `;
                return field ? `${fieldName}: ${assetValue.value}` : fieldName;
              })
              .join(", ");
          }

          return {
            changeMessage: `updated "Values"`,
            action: userAction.body.action,
            canRevert: true,
            oldValue: oldValues,
            newValue: newValues,
          };
        }
        default: {
          const oldAsset = userAction.diff.asset.old;
          const newAsset = userAction.diff.asset.new;

          if (oldAsset.name !== newAsset.name) {
            return {
              oldValue: userAction.diff.asset.old.name,
              newValue: userAction.diff.asset.new.name,
              changeMessage: `updated "Name"`,
              action: "name",
              canRevert: true,
            };
          }

          return {
            changeMessage: "updated this asset",
            action: "update",
            canRevert: false,
          };
        }
      }
    }

    function getLocation(oldValue, newValue) {
      let oldLocation = "";
      let newLocation = "";
      const oldLocationValue = {
        x: oldValue && oldValue.percentX ? oldValue.percentX : null,
        y: oldValue && oldValue.percentY ? oldValue.percentY : null,
      };

      const newLocationValue = {
        x: newValue && newValue.percentX ? newValue.percentX : null,
        y: newValue && newValue.percentY ? newValue.percentY : null,
      };

      if (!oldLocationValue.x && !oldLocationValue.y) {
        oldLocation = "None";
        newLocation = "Placed";
      } else {
        let xMoved;
        let yMoved;
        if (newLocationValue.x > oldLocationValue.x) xMoved = "right";
        else xMoved = "left";
        if (newLocationValue.y > oldLocationValue.y) yMoved = "down";
        else yMoved = "up";
        oldLocation = "Placed";
        newLocation = `Moved ${xMoved} and ${yMoved}`;
      }

      return { oldLocation, newLocation };
    }
    /**
     * @param {Object} userActionModel userAction object
     * @returns An object the field changed, the old and new value
     */
    function findDifferences(userActionModel) {
      let changeMessage = "";
      let oldValue = "";
      let newValue = "";
      let canRevert = true;
      const deferred = $q.defer();

      return $q(function (resolve, reject) {
        if (userActionModel && userActionModel.diff) {
          const modelDiff = userActionModel.diff;
          const diffOldValues = modelDiff[Object.keys(modelDiff)[0]].old;
          const diffNewValues = modelDiff[Object.keys(modelDiff)[0]].new;

          if (Object.prototype.hasOwnProperty.call(modelDiff, "asset")) {
            const actionChanges = getChanges(userActionModel);
            newValue = actionChanges.newValue ? actionChanges.newValue : "";
            oldValue = actionChanges.oldValue ? actionChanges.oldValue : "";
            changeMessage = actionChanges.changeMessage;
            canRevert = actionChanges.canRevert;

            if (actionChanges.action === models.ASSET.ACTIONS.UPDATE_FLOOR) {
              let fetchOptions = { includePinType: false };
              let fetchParams = { include_values: false };
              let fetchPromises = [];

              if (newValue) {
                fetchPromises.push(
                  FloorService.getById(
                    diffNewValues.building,
                    newValue,
                    fetchParams,
                    fetchOptions
                  )
                    .then((level) => {
                      newValue = level.name;
                    })
                    .catch((err) => {
                      ToastService.showError(`Unable to get floor name`);
                    })
                );
              }

              if (oldValue) {
                fetchPromises.push(
                  FloorService.getById(
                    diffOldValues.building,
                    oldValue,
                    fetchParams,
                    fetchOptions
                  )
                    .then((level) => {
                      oldValue = level.name;
                    })
                    .catch((err) => {
                      ToastService.showError(`Unable to get floor name`);
                    })
                );
              }

              $q.all(fetchPromises).then(() => {
                oldValue = oldValue === "" ? "None" : `"${oldValue}"`;
                newValue = newValue === "" ? "None" : `"${newValue}"`;
                changeMessage = changeMessage === "" ? "None" : changeMessage;

                return resolve({
                  newValue,
                  oldValue,
                  changeMessage,
                  canRevert,
                });
              });
              return deferred.promise;
            } else if (
              actionChanges.action === models.ASSET.ACTIONS.UPDATE_ROOM
            ) {
              let fetchOptions = { includePinType: false };
              let fetchParams = { include_values: false };
              let fetchPromises = [];

              if (newValue) {
                fetchPromises.push(
                  RoomService.getById(
                    diffNewValues.building,
                    newValue,
                    fetchParams,
                    fetchOptions
                  )
                    .then((room) => {
                      newValue = room.display_name;
                    })
                    .catch((err) => {
                      ToastService.showError(`Unable to get room name`);
                    })
                );
              }

              if (oldValue) {
                fetchPromises.push(
                  RoomService.getById(
                    diffOldValues.building,
                    oldValue,
                    fetchParams,
                    fetchOptions
                  )
                    .then((room) => {
                      oldValue = room.display_name;
                    })
                    .catch((err) => {
                      ToastService.showError(`Unable to get room name`);
                    })
                );
              }

              $q.all(fetchPromises).then(() => {
                oldValue = oldValue === "" ? "None" : `"${oldValue}"`;
                newValue = newValue === "" ? "None" : `"${newValue}"`;
                changeMessage = changeMessage === "" ? "None" : changeMessage;

                return resolve({
                  newValue,
                  oldValue,
                  changeMessage,
                  canRevert,
                });
              });
              return deferred.promise;
            } else if (
              actionChanges.action === models.ASSET.ACTIONS.CHANGE_PIN_TYPE
            ) {
              oldValue = diffOldValues.pinType.name;
              newValue = diffNewValues.pinType.name;
              changeMessage = `changed "Category"`;
              canRevert = false;
              return resolve({
                newValue,
                oldValue,
                changeMessage,
                canRevert,
              });
            } else {
              if (actionChanges.action === models.ASSET.ACTIONS.SET_LOCATION) {
                const location = getLocation(oldValue, newValue);
                oldValue = location.oldLocation;
                newValue = location.newLocation;
                if (oldValue === "None") {
                  canRevert = false;
                }
              } else {
                oldValue = oldValue === "None" ? oldValue : `"${oldValue}"`;
                newValue = newValue === "None" ? newValue : `"${newValue}"`;
              }

              return resolve({
                newValue,
                oldValue,
                changeMessage,
                canRevert,
              });
            }
          } else if (
            Object.prototype.hasOwnProperty.call(modelDiff, "pin_value") &&
            Object.prototype.hasOwnProperty.call(userActionModel, "pin_type")
          ) {
            newValue = diffNewValues.value;
            oldValue = diffOldValues.value;
            const pinTypeFields = userActionModel.pin_type.fields;
            if (pinTypeFields) {
              const field = pinTypeFields.find(
                (_field) => _field._id === diffOldValues.pinField
              );
              if (field && field.name) {
                changeMessage = `updated "${field.name}"`;
                if (field.data_type === "date") {
                  newValue =
                    newValue && newValue.length > 0
                      ? newValue.substring(0, 10)
                      : "";
                  oldValue =
                    oldValue && oldValue.length > 0
                      ? oldValue.substring(0, 10)
                      : "";
                } else if (!isPinFieldDataTypeSupported(field.data_type)) {
                  canRevert = false;
                }
              }
            }

            oldValue = oldValue === "" ? "None" : `"${oldValue}"`;
            newValue = newValue === "" ? "None" : `"${newValue}"`;
            changeMessage = changeMessage === "" ? "None" : changeMessage;

            return resolve({
              newValue,
              oldValue,
              changeMessage,
              canRevert,
            });
          }
        }
      });
    }
  }
})();
