(function () {
  angular.module("akitabox.ui.dialogs.workOrder.reschedule", [
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.lib.moment",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.workOrder",
    "akitabox.core.toast",
  ]);
})();
