(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineSelectionList", {
      bindings: {},
      controllerAs: "vm",
      controller: AbxCostLineSelectionListController,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/selection-list/cost-line-selection-list.component.html",
      require: {
        context: "^abxCostLineSelectionContext",
      },
    });

  /* @ngInject */
  function AbxCostLineSelectionListController($scope) {
    this.selectedCostLine = null;

    const updateSelectionFromContext = () => {
      this.selectedCostLine = this.context.selectedCostLine;
    };

    this.$onInit = () => {
      updateSelectionFromContext();
    };

    $scope.$watch("vm.context.selectedCostLine", () => {
      updateSelectionFromContext();
    });
    $scope.$watch("vm.context.costLines", () => {
      updateSelectionFromContext();
    });
  }
})();
