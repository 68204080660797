import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import { Color } from "../colors";
import { stylesheet } from "../stylesheet";
import { NumericFormat } from "react-number-format";

export interface PageMargins {
  top: number;
  right: number;
  bottom: number;
  left: number;
  unit: "in" | "cm" | "mm";
}

export interface ReportMarginsDialogProps extends DialogProps {
  margins?: PageMargins;
  onSave: (margins: PageMargins) => Promise<void>;
}

export const ReportMarginsDialog: FC<ReportMarginsDialogProps> = ({
  margins,
  onSave,
  ...props
}) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [pageMargins, setPageMargins] = useState<{
    top: string;
    right: string;
    bottom: string;
    left: string;
    unit: PageMargins["unit"];
  }>({
    top: margins?.top.toString() || "",
    right: margins?.right.toString() || "",
    bottom: margins?.bottom.toString() || "",
    left: margins?.left.toString() || "",
    unit: margins?.unit || "in",
  });

  const reset = async () => {
    if (margins) {
      setPageMargins({
        top: margins.top.toString(),
        right: margins.right.toString(),
        bottom: margins.bottom.toString(),
        left: margins.left.toString(),
        unit: margins.unit,
      });
    }
    setError(null);
    setSaving(false);
  };

  const handleClose = async (margins?: PageMargins) => {
    setError(null);
    return await onSave(margins || castToMargins());
  };

  const castToMargins = () => {
    return {
      top: parseFloat(pageMargins.top),
      right: parseFloat(pageMargins.right),
      bottom: parseFloat(pageMargins.bottom),
      left: parseFloat(pageMargins.left),
      unit: pageMargins.unit,
    };
  };

  return (
    <Dialog maxWidth="lg" {...props}>
      <DialogTitle component={Box} css={ss.dialogTitle}>
        <Typography variant="h6" color="inherit">
          Page Margins
        </Typography>
        <IconButton
          color="inherit"
          onClick={() => {
            reset();
            // we pass in the original margins, so we don'tr trigger a save
            // this is beccause reset() uses setPageMargins() which isn't instantenous
            handleClose(margins);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          css={(theme) => ({ marginTop: theme.spacing(2) })}
        >
          {error && (
            <Alert severity="error">
              Could not update page margins. Error: {error.message}
            </Alert>
          )}
          <Stack
            direction="row"
            spacing={1}
            css={{ alignItems: "flex-start", justifyContent: "center" }}
          >
            <NumericFormat
              decimalScale={2}
              customInput={TextField}
              suffix={` ${pageMargins.unit}`}
              size="small"
              label="Top"
              value={pageMargins.top}
              helperText="Please enter the top margin"
              required
              onValueChange={(values) => {
                const { formattedValue } = values;
                setPageMargins({ ...pageMargins, top: formattedValue });
              }}
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                const top = pageMargins.top;
                setPageMargins({
                  ...pageMargins,
                  top,
                  right: top,
                  bottom: top,
                  left: top,
                });
              }}
            >
              Apply To All
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            css={{ alignItems: "flex-start", justifyContent: "center" }}
          >
            <NumericFormat
              decimalScale={2}
              customInput={TextField}
              suffix={` ${pageMargins.unit}`}
              size="small"
              label="Right"
              value={pageMargins.right}
              helperText="Please enter the right margin"
              required
              onValueChange={(values) => {
                const { formattedValue } = values;
                setPageMargins({ ...pageMargins, right: formattedValue });
              }}
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                const right = pageMargins.right;
                setPageMargins({
                  ...pageMargins,
                  top: right,
                  right,
                  bottom: right,
                  left: right,
                });
              }}
            >
              Apply To All
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            css={{ alignItems: "flex-start", justifyContent: "center" }}
          >
            <NumericFormat
              decimalScale={2}
              customInput={TextField}
              suffix={` ${pageMargins.unit}`}
              size="small"
              label="Bottom"
              value={pageMargins.bottom}
              helperText="Please enter the bottom margin"
              required
              onValueChange={(values) => {
                const { formattedValue } = values;
                setPageMargins({ ...pageMargins, bottom: formattedValue });
              }}
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                const bottom = pageMargins.bottom;
                setPageMargins({
                  ...pageMargins,
                  top: bottom,
                  right: bottom,
                  bottom,
                  left: bottom,
                });
              }}
            >
              Apply To All
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            css={{ alignItems: "flex-start", justifyContent: "center" }}
          >
            <NumericFormat
              decimalScale={2}
              customInput={TextField}
              suffix={` ${pageMargins.unit}`}
              size="small"
              label="Left"
              value={pageMargins.left}
              helperText="Please enter the left margin"
              required
              onValueChange={(values) => {
                const { formattedValue } = values;
                setPageMargins({ ...pageMargins, left: formattedValue });
              }}
            />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                const left = pageMargins.left;
                setPageMargins({
                  ...pageMargins,
                  top: left,
                  right: left,
                  bottom: left,
                  left,
                });
              }}
            >
              Apply To All
            </Button>
          </Stack>
          <TextField
            label="Unit"
            value={pageMargins.unit}
            defaultValue={"mm"}
            size="small"
            helperText="Please select the unit of measurement"
            select
            required
            onChange={(event) => {
              const value = event.target.value as "in" | "cm" | "mm";
              setPageMargins({
                ...pageMargins,
                unit: value,
              });
            }}
          >
            <MenuItem value={"in"}>Inches</MenuItem>
            <MenuItem value={"mm"}>Millimeters</MenuItem>
            <MenuItem value={"cm"}>Centimeters</MenuItem>
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => {
              reset();
              // we pass in the original margins, so we don'tr trigger a save
              // this is beccause reset() uses setPageMargins() which isn't instantenous
              handleClose(margins);
            }}
            disabled={saving}
          >
            CANCEL
          </Button>
          <LoadingButton
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={saving}
            onClick={async () => {
              try {
                setSaving(true);
                await handleClose();
              } catch (err) {
                if (axios.isAxiosError(err)) {
                  setError(
                    new Error(err.response?.data?.error?.message || err.message)
                  );
                } else if (err instanceof Error) {
                  setError(err);
                }
              }

              setSaving(false);
            }}
          >
            {saving ? "Saving..." : error ? "Retry" : "Save"}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ss = stylesheet({
  dialogTitle: {
    backgroundColor: Color.MidnightExpress,
    color: Color.White,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
