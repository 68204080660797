(function () {
  /**
   * @ngdoc component
   * @name abxHeaderSwitcher
   *
   * @param {Object}    [building]         Current building
   * @param {Object}    [floor]            Current floor
   * @param {Boolan}    [includeFloors]    Include floor selection
   *                                       Defaults to falsy
   * @param {Boolean}   isShown            Whether this component is currently shown
   *     Allows us to completely remove the map element (instead of just
   *     hiding it), as it has many watchers that slow down the app
   * @param {Function}  onBuildingChange   Building change callback
   * @param {Function}  selectAllBuildings Select all buildings callback
   * @param {Function}  onGroupSelected    Select group callback
   * @param {Function}  [onFloorChange]    Floor change callback
   *
   * @description
   * A component to be used in conjunction with the app header to switch the building and floors
   *
   * @controls {Object[]} buildings - All buildings the current user has access
   *     to
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .component("abxHeaderSwitcher", {
      bindings: {
        group: "<?abxGroup",
        organization: "<?abxOrganization",
        building: "<?abxBuilding",
        floor: "<?abxFloor",
        isShown: "<abxIsShown",
        onGroupSelected: "&abxOnGroupSelected",
        onOrganizationChange: "&abxOnOrganizationChange",
        onBuildingChange: "&abxOnBuildingChange",
        onFloorChange: "&?abxOnFloorChange",
        includeFloors: "&?abxIncludeFloors",
      },
      controller: AbxHeaderSwitcherController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/components/header-switcher/header-switcher.component.html",
    });

  /* @ngInject */
  function AbxHeaderSwitcherController(
    // Angular
    $q,
    // AkitaBox
    headerSearchFilter,
    // Services
    BuildingService,
    OrganizationService,
    ToastService
  ) {
    var self = this;

    var ALL_BUILDINGS_GROUP = {
      _id: null,
      name: "All Buildings",
      buildings: [],
      all: true,
    };

    // Attributes
    self.allBuildingsGroup = ALL_BUILDINGS_GROUP;
    self.showFloors = true;
    self.buildings = null;
    self.groups = [];
    self.buildingGroups = [];
    self.organizations = null;
    self.searchText = "";
    self.orgSearchText = "";
    self.buildingGroupsSearchText = "";
    self.loadingBuildings = false;

    // Functions
    self.onOrganizationSelect = onOrganizationSelect;
    self.onBuildingSelect = onBuildingSelect;
    self.onGroupSelect = onGroupSelect;
    self.onViewAllBuildings = onViewAllBuildings;
    self.closeFloors = closeFloors;
    self.isGroupsEmpty = isGroupsEmpty;
    self.isBuildingsEmpty = isBuildingsEmpty;

    // ------------------------
    //   Lifecycle
    // ------------------------
    self.$onInit = function () {
      getOrganizations();
    };

    self.$onChanges = function (changes) {
      if (changes.organization && self.organization) {
        self.buildings = [];
        getBuildings().then((userBuildings) => {
          getBuildingGroups(userBuildings);
        });
      }
      if (changes.isShown) {
        if (!self.isShown) {
          self.searchText = "";
        }
        if (changes.isShown.currentValue === false) {
          self.showFloors = true;
        }
      }
    };

    // =================
    // Public Functions
    // =================

    function onOrganizationSelect($event) {
      self.building = null;
      self.group = null;
      self.searchText = "";
      self.buildingGroupsSearchText = "";
      self.organization = $event.model;
      self.onOrganizationChange({ $event: $event });
    }

    /**
     * Handles actions when the user selects a building from the map
     *
     * @param {Object}  $event    Selection event
     */
    function onBuildingSelect($event) {
      self.showFloors = true;
      self.building = $event.model;
      self.group = null;
      $event.organization = self.organization;
      self.onBuildingChange({ $event: $event });
    }

    /**
     * Hanldes group selection
     *
     * @param {Array} group   group of buildings
     * @param {Object} params parameter object
     */
    function onGroupSelect($event) {
      self.building = null;
      self.buildings = $event.model.buildings;
      self.group = $event.model;
      $event.organization = self.organization;
    }

    function onViewAllBuildings($event) {
      $event.organization = self.organization;
      $event.model = self.group || $event.model;
      self.onGroupSelected({ $event: $event });
    }

    /**
     * Handles closing viewing floors
     */
    function closeFloors() {
      self.showFloors = false;
    }

    /**
     * Determine if groups is empty with current search text
     */
    function isGroupsEmpty() {
      if (angular.isEmpty(self.searchText)) {
        return !self.groups || self.groups.length === 0;
      }

      var filteredGroups = headerSearchFilter(self.groups, self.searchText);
      return !filteredGroups.length;
    }

    /**
     * Determine if buildings is empty with the current search text
     */
    function isBuildingsEmpty() {
      if (self.loadingBuildings) {
        return false;
      }
      if (angular.isEmpty(self.searchText)) {
        return !self.buildings || self.buildings.length === 0;
      }
      var filteredBuildings = headerSearchFilter(
        self.buildings,
        self.searchText
      );
      return !filteredBuildings.length;
    }

    // =================
    // Private Functions
    // =================

    /**
     * Get all the buildings
     */
    function getBuildings() {
      self.loadingBuildings = true;
      var params = {
        archive_date: "null",
      };
      if (self.organization) {
        params.organization = self.organization._id;
      }
      return BuildingService.getAll(params)
        .then(function (buildings) {
          self.buildings = buildings;
          ALL_BUILDINGS_GROUP.buildings = self.buildings;

          // Saving the building array so it can be used through the system
          BuildingService.orgBuildings = buildings;
          return self.buildings;
        })
        .catch(function (err) {
          ToastService.showError("Unable to get buildings");
          self.buildings = [];
          return self.buildings;
        });
    }

    /**
     * Get all the organizations
     */
    function getOrganizations() {
      var params = {
        deactivated_date: "null",
      };
      return OrganizationService.getAll(params)
        .then(function (organizations) {
          self.organizations = organizations;
          return self.organizations;
        })
        .catch(function (err) {
          ToastService.showError("Unable to get organizations");
          self.organizations = [];
          return self.organizations;
        });
    }

    /**
     * Get all the building groups
     */
    function getBuildingGroups() {
      var params = {
        sort: "name, asc",
        insensitive: true,
        skip: 0,
        limit: 1000,
        buildings: `$in,${self.buildings.map((b) => b._id).join(",")}`,
      };
      const orgId = self.organization._id || self.organization;
      return BuildingService.getAllBuildingGroups(orgId, params)
        .then(function (buildingGroups) {
          self.buildingGroups = buildingGroups;
          if (self.buildingGroups.length > 0) {
            const bgroups = self.buildingGroups.map((group) => {
              return {
                _id: group._id,
                name: group.name,
                all: false,
                buildings: self.buildings.filter((building) =>
                  group.buildings.includes(building._id)
                ),
              };
            });

            self.groups = [ALL_BUILDINGS_GROUP, ...bgroups];
          } else if (!self.includeFloors()) {
            self.groups =
              self.buildings.length > 1 ? [ALL_BUILDINGS_GROUP] : [];
          } else {
            self.groups = [];
          }
          return self.organizations;
        })
        .catch(function (err) {
          ToastService.showError("Unable to get buildingGroups");
          self.buildingGroups = [];
          return self.buildingGroups;
        })
        .finally(function () {
          self.loadingBuildings = false;
        });
    }
  }
})();
