(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.lifeCycleChart
   *
   * @param {Array} conditions  Condition data to populate the chart
   * @param {String} idTag      Unique #id for the chart
   */
  angular
    .module("akitabox.ui.directives.lifeCycleChart", [
      "akitabox.constants",
      "ngMaterial",
      "akitabox.core",
      "akitabox.core.lib.d3",
    ])
    .directive("abxLifeCycleChart", AbxLifeCycleChartDirective);

  /* @ngInject */
  function AbxLifeCycleChartDirective(
    // Angular
    $log,
    // AkitaBox
    models,
    // Libraries
    d3,
    moment,
    // Services
    AssemblyService
  ) {
    return {
      restrict: "E",
      scope: {
        idTag: "<abxIdTag",
        conditions: "<abxConditions",
        installationYear: "<?abxInstallationYear",
        severityOfFailure: "<?abxSeverityOfFailure",
        replacementYear: "<?abxReplacementYear",
        originalReplacementYear: "<?abxOriginalReplacementYear",
      },
      link: postlink,
    };
    function postlink($scope, $element) {
      if (!$scope.idTag) {
        return $log.error(
          "<abx-life-cycle-chart>: abx-id-tag must be provided"
        );
      }

      // Constants
      var HEIGHT = 200;
      var WIDTH = 300;
      var MARGINS = { top: 16, right: 0, bottom: 30, left: 50 };
      var CONDITION_GRADES_VERBIAGE =
        models.CONDITION.CONDITION_GRADES_VERBIAGE;
      // Dumb Object.values() shim, returns array of object values
      var CONDITION_GRADES_VERBIAGE_VALUES = Object.keys(
        CONDITION_GRADES_VERBIAGE
      ).map(function (grade) {
        return CONDITION_GRADES_VERBIAGE[grade];
      });
      var QUADRATIC_CTLR_MULTIPLIERS = { x: 7 / 8, y: 1 / 4 };

      // Attributes
      var idTag = $scope.idTag;

      // Watchers

      $scope.$watchCollection(
        "conditions",
        function (newConditions, oldConditions) {
          drawChart(newConditions);
        }
      );

      $scope.$watch("installationYear", function (newYear, oldYear) {
        if (oldYear !== undefined && oldYear !== newYear) {
          drawChart($scope.conditions);
        }
      });

      $scope.$watch("replacementYear", function (newYear, oldYear) {
        if (newYear || oldYear) {
          drawChart($scope.conditions);
        }
      });

      // Private

      function drawChart(conditions) {
        d3.select("#" + idTag).remove();

        var hasConditions = Boolean(conditions && conditions.length);
        if (!hasConditions && !$scope.replacementYear) {
          return;
        }

        if (!hasConditions) {
          conditions = [];
        }

        var conditionYears = conditions.map(function (d) {
          var date = new Date(d.date_recorded);
          return date.getFullYear();
        });

        var today = new Date();
        var todayYear = today.getFullYear();
        conditionYears.push(todayYear);

        if (!isNaN($scope.installationYear)) {
          conditionYears.push($scope.installationYear);
        }

        if ($scope.replacementYear) {
          conditionYears.push($scope.replacementYear);
          if ($scope.originalReplacementYear) {
            conditionYears.push($scope.originalReplacementYear);
          }
        }

        var minYear = Math.min.apply(Math, conditionYears);
        var maxYear = Math.max.apply(Math, conditionYears);

        var diff = maxYear - minYear;
        if (diff > 0) {
          maxYear = maxYear + diff * 0.1;
        } else {
          maxYear = maxYear + 5;
        }

        var conditionDateRange = [minYear, maxYear];

        var chart = d3
          .select($element[0])
          .append("svg")
          .attr("viewBox", [
            0,
            0,
            WIDTH + MARGINS.left,
            HEIGHT + MARGINS.bottom,
          ])
          .attr("id", idTag);

        chart
          .append("g")
          .attr(
            "transform",
            "translate(" + MARGINS.left + "," + MARGINS.top + ")"
          );

        // Set up the X and Y scales

        var xScale = d3
          .scaleLinear()
          .domain(conditionDateRange)
          .rangeRound([MARGINS.left, WIDTH]);
        var yScale = d3
          .scalePoint()
          .domain(CONDITION_GRADES_VERBIAGE_VALUES)
          .range([0, HEIGHT - MARGINS.top]);

        // Set up the X axis
        var xAxis = d3
          .axisBottom(xScale)
          .ticks(WIDTH)
          .tickSize(0)
          .tickPadding(12)
          .tickFormat(function (d) {
            if (conditionYears.includes(d)) {
              if (d === $scope.installationYear) {
                return d + " Installed";
              }
              return d;
            }
          });
        chart.append("g").call(function (g) {
          return g
            .attr("transform", "translate(0, " + HEIGHT + ")")
            .attr("class", "x-axis")
            .call(xAxis);
        });

        // Set up the Y axis
        var yAxis = d3
          .axisLeft()
          .scale(yScale)
          .ticks(CONDITION_GRADES_VERBIAGE.length)
          .tickSize(0)
          .tickPadding(12);

        chart
          .append("g")
          .call(function (g) {
            g.attr(
              "transform",
              "translate(" + MARGINS.left + "," + MARGINS.top + ")"
            ).attr("class", "y-axis");
          })
          .call(yAxis);

        chart.selectAll("g.tick text").each(insertLineBreaks);
        chart.selectAll("g.y-axis g.tick text tspan").attr("x", "-8");

        // Today line and label
        var todayPath = d3.path();
        var todayStart = {
          x: xScale(todayYear),
          y: MARGINS.top,
        };
        var todayEnd = {
          x: xScale(todayYear),
          y: HEIGHT,
        };
        todayPath.moveTo(todayStart.x, todayStart.y);
        todayPath.lineTo(todayEnd.x, todayEnd.y);

        chart.append("path").attr("class", "today-path").attr("d", todayPath);
        chart
          .append("g")
          .attr(
            "transform",
            "translate(" + todayStart.x + "," + todayStart.y + ")"
          )
          .append("text")
          .text("Today")
          .attr("class", "today-label")
          .attr("dx", -15)
          .attr("dy", -8);

        var independentAssessments = [];
        var workOrderAssessments = [];

        for (var i = 0; i < conditions.length; ++i) {
          var condition = conditions[i];
          if (condition.task) {
            workOrderAssessments.push(condition);
          } else {
            independentAssessments.push(condition);
          }
        }

        // Draw Dots
        chart
          .selectAll("dot")
          .data(independentAssessments)
          .enter()
          .append("circle")
          .attr("class", "dot-condition dot-condition--open")
          .attr("r", 5)
          .attr("cx", getDotCX)
          .attr("cy", getDotCY)
          .on("mouseover", onDotMouseover)
          .on("mouseout", onDotMouseout);

        chart
          .selectAll("dot")
          .data(workOrderAssessments)
          .enter()
          .append("circle")
          .attr("class", "dot-condition dot-condition--closed")
          .attr("r", 3)
          .attr("cx", getDotCX)
          .attr("cy", getDotCY)
          .on("mouseover", onDotMouseover)
          .on("mouseout", onDotMouseout);

        // Projected replacement line
        if ($scope.replacementYear && $scope.installationYear) {
          var projectedPath = d3.path();
          var projectedStart = {
            x: getX($scope.installationYear),
            y: getY(CONDITION_GRADES_VERBIAGE.VERY_GOOD),
          };
          var projectedEnd = {
            x: getX($scope.replacementYear),
            y: getY(CONDITION_GRADES_VERBIAGE.FAILED),
          };
          var projectedCtrlPoint = {
            x: projectedEnd.x * QUADRATIC_CTLR_MULTIPLIERS.x,
            y: projectedEnd.y * QUADRATIC_CTLR_MULTIPLIERS.y,
          };
          projectedPath.moveTo(projectedStart.x, projectedStart.y);
          projectedPath.quadraticCurveTo(
            projectedCtrlPoint.x,
            projectedCtrlPoint.y,
            projectedEnd.x,
            projectedEnd.y
          );
          chart
            .append("path")
            .attr("class", "projected-path")
            .attr("d", projectedPath);

          // Original replacement line
          if ($scope.originalReplacementYear) {
            var originalPath = d3.path();
            var originalStart = {
              x: getX($scope.installationYear),
              y: getY(CONDITION_GRADES_VERBIAGE.VERY_GOOD),
            };
            var originalEnd = {
              x: getX($scope.originalReplacementYear),
              y: getY(CONDITION_GRADES_VERBIAGE.FAILED),
            };
            var originalCtrlPoint = {
              x: originalEnd.x * QUADRATIC_CTLR_MULTIPLIERS.x,
              y: originalEnd.y * QUADRATIC_CTLR_MULTIPLIERS.y,
            };
            originalPath.moveTo(originalStart.x, originalStart.y);
            originalPath.quadraticCurveTo(
              originalCtrlPoint.x,
              originalCtrlPoint.y,
              originalEnd.x,
              originalEnd.y
            );
            chart
              .append("path")
              .attr("class", "original-path")
              .attr("d", originalPath);
          }
        }

        function getX(year) {
          return xScale(year);
        }

        function getY(condition) {
          return yScale(condition) + MARGINS.top;
        }

        function getDotCX(d) {
          // parsing year from date string
          return getX(d.date_recorded.substring(0, 4));
        }

        function getDotCY(d) {
          return getY(CONDITION_GRADES_VERBIAGE[d.condition_rating]);
        }

        function onDotMouseover(d) {
          var svg = $element.find("svg")[0];

          var year = new Date(d.date_recorded).getFullYear();
          var condition = CONDITION_GRADES_VERBIAGE[d.condition_rating];
          var task = d.task;
          var x = xScale(year);
          var y = yScale(condition);

          var chartWidth = WIDTH + MARGINS.left;
          var chartHeight = HEIGHT + MARGINS.bottom;

          var tooltipContainer = d3
            .select($element[0])
            .append("span")
            .attr("class", "abx-life-cycle-chart__tooltip-container")
            .style("position", "absolute")
            .style("left", x * (svg.clientWidth / chartWidth) + "px")
            .style("top", y * (svg.clientHeight / chartHeight) + "px");

          var tooltipLines = [];
          if (task) {
            tooltipLines.push(boldText(task.display_number));
          }
          var dateRecorded = moment(d.date_recorded).format("l");
          tooltipLines.push(
            [
              boldText("Condition:"),
              condition,
              italicizeText(dateRecorded),
            ].join(" ")
          );
          if ($scope.severityOfFailure) {
            var risk = AssemblyService.calculateRisk(
              $scope.severityOfFailure,
              d.condition_rating
            );
            if (risk) {
              tooltipLines.push(boldText("Risk :") + " " + capitalize(risk));
            }
          }

          tooltipContainer
            .append("span")
            .attr("class", "abx-life-cycle-chart__tooltip")
            .html(tooltipLines.join("<br/>"));
        }

        function onDotMouseout(d) {
          d3.selectAll(".abx-life-cycle-chart__tooltip-container").remove();
        }
      }

      function insertLineBreaks(d) {
        var el = d3.select(this);
        var words = el.text().split(" ");
        el.text("");

        for (var i = 0; i < words.length; i++) {
          var tspan = el.append("tspan").text(words[i]);
          tspan.attr("x", "0");
          if (i > 0) tspan.attr("dy", "10");
        }
      }

      function boldText(text) {
        return "<b>" + text + "</b>";
      }

      function italicizeText(text) {
        return "<em>" + text + "</em>";
      }

      function capitalize(word) {
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
      }
    }
  }
})();
