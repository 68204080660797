(function () {
  angular.module("akitabox.desktop.admin.job").run(stateBlock);

  /** @ngInject **/
  function stateBlock(models, Router) {
    Router.configureStates([
      {
        state: "admin.job",
        config: {
          url: "/" + models.JOB.ROUTE_SINGULAR + "/:jobId",
          templateUrl: "app/desktop/modules/admin/job/detail/job-detail.html",
          controller: "JobDetailController",
          controllerAs: "vm",
          resolve: {
            job: getJob,
            building: function () {
              return null;
            },
          },
          data: {
            pageTitle: "Account/Dev Job Details",
          },
        },
      },
      {
        state: "admin.jobs",
        config: {
          url: "/" + models.JOB.ROUTE_PLURAL,
          templateUrl:
            "app/desktop/modules/admin/job/account-list/account-job-list.html",
          controller: "AccountJobListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Account Jobs",
          },
        },
      },
      {
        state: "admin.devJobs",
        config: {
          url: "/dev-" + models.JOB.ROUTE_PLURAL,
          templateUrl:
            "app/desktop/modules/admin/job/dev-list/dev-job-list.html",
          controller: "DevJobListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Dev Jobs",
          },
        },
      },
    ]);
  }

  /** @ngInject **/
  function getJob($stateParams, account, AdminJobService) {
    return AdminJobService.getById($stateParams.jobId);
  }
})();
