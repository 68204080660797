(function () {
  angular
    .module("akitabox.ui.dialogs.print")
    .factory("PrintDialog", PrintDialog);

  /* @ngInject */
  function PrintDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/print/print.dialog.html",
      controller: "PrintDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var PRINT_LIMIT = 100;

    /**
     * PrintDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}          options                 Dialog options
     * @param {Object}          options.locals          Locals scope variables
     * @param {String}          options.locals.route    Base request route for document
     * @param {Object}          options.locals.filters  [Optional] Query filter parameters
     * @param {Array}           options.locals.selected [Optional] List of selected entity IDs to print
     *
     * @return {Promise<Object|Error}       Promise that resolves with null
     */
    function show(options) {
      var genericErrorMsg = "Sorry, we weren't able to complete your request.";
      // Verify we have a base route
      if (!options || !options.locals) {
        $log.error("PrintDialog: no locals provided");
        return $q.reject(genericErrorMsg);
      } else if (!options.locals.route) {
        $log.error("PrintDialog: no route local provided");
        return $q.reject(genericErrorMsg);
      } else if (options.locals.selected) {
        if (options.locals.selected.length > PRINT_LIMIT) {
          $log.error("PrintDialog: limit exceeded");
          return $q.reject(
            "Cannot print more than " + PRINT_LIMIT + " items at once"
          );
        } else if (!options.locals.selected.length) {
          $log.error("PrintDialog: empty selected local was provided");
          return $q.reject(genericErrorMsg);
        }
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
