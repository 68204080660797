(function () {
  angular
    .module("akitabox.ui.components.buildingInfo")
    .component("abxBuildingInfo", {
      bindings: {
        model: "<abxModel",
        onChange: "&?abxOnChange",
        buildings: "<abxBuildings",
      },
      controller: AbxBuildingInfoController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/building-info/building-info.component.html",
    });
  function AbxBuildingInfoController(
    // Angular
    $state,
    $log,
    // Services
    Router,
    RoomService,
    OrganizationService,
    RedirectService,
    UserService
  ) {
    var self = this;

    // Attributes
    self.permissions = {};
    self.visibility = {
      address: false,
      multipleBuildings: false,
    };
    self.descriptionHtml = null;
    self.organization = OrganizationService.getCurrent();
    self.buildingSelectOpen = false;

    // Function
    self.onFloorSelect = onFloorSelect;
    self.onDetailsClick = onDetailsClick;
    self.onViewAllAssets = onViewAllAssets;
    self.onViewAllRooms = onViewAllRooms;
    self.onMapClick = onMapClick;
    self.roomCount = 0;
    self.fitMap = true;
    var permissions = UserService.getPermissions();

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxBuildingInfo: abx-buildings is required");
        return;
      }
      self.visibility.multipleBuildings = self.buildings.length > 1;
    };

    self.$onChanges = function (changes) {
      if (changes.model) {
        setCurrentBuilding(self.model);
      }
    };

    // =================
    // Private Functions
    // =================

    function setCurrentBuilding(building) {
      self.model = building;
      self.roomCount = 0;
      // Reset permissions
      self.permissions = {
        showDetails: false,
      };
      // Update visibility
      self.visibility.address = getAddressVisibility();
      self.descriptionHtml = getDescriptionHtml();
      self.permissions = {
        showDetails: permissions.is_administrator,
      };
      getRooms(building);
    }

    function getAddressVisibility() {
      if (!self.model) {
        return false;
      }
      return Boolean(
        self.model.address ||
          self.model.city ||
          self.model.state ||
          self.model.postal_code ||
          self.model.country
      );
    }

    function getDescriptionHtml() {
      if (!self.model || !self.model.description) {
        return null;
      }
      return self.model.description.split("\n").join("<br>");
    }

    function getRooms(building) {
      RoomService.getAll(building._id, { count: true }).then(function (
        results
      ) {
        self.roomCount = results.count;
      });
    }

    // =================
    // Public Functions
    // =================

    function onDetailsClick() {
      if (!self.model) return;
      RedirectService.redirectToOrganization(
        self.organization,
        `/organization/settings/buildings/${self.model._id}`,
        true
      );
    }

    /**
     * Handle map marker clicks. Disable map fitting on the first click to fix
     * the bug described in https://github.com/angular-ui/angular-google-maps/issues/1964
     * @param {object} $event - The click event.
     */
    function onMapClick($event) {
      self.fitMap = false;
      self.onChange($event);
    }

    function onViewAllAssets() {
      if (self.buildings.length > 1) {
        Router.go("app.assets", { building: self.model._id });
      } else {
        Router.go("app.building.assets", { buildingId: self.model._id });
      }
    }

    function onViewAllRooms() {
      if (self.buildings.length > 1) {
        Router.go("app.rooms", { building: self.model._id });
      } else {
        Router.go("app.building.rooms", { buildingId: self.model._id });
      }
    }

    function onFloorSelect(event) {
      var floor = event.floor;

      $state.go("planView.building.floor", {
        buildingId: self.model._id,
        floorId: floor._id,
      });
    }
  }
})();
