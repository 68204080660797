(function () {
  angular
    .module("akitabox.desktop.asset.detail")
    .controller("AssetDetailController", AssetDetailController);

  /* @ngInject */
  function AssetDetailController(
    //Angular
    $scope,
    // Third-party
    $stateParams,
    // constants
    EVENT_PIN_REFRESH,
    // Resolve
    building,
    organization,
    asset,
    // Services
    AssetService,
    UserService
  ) {
    var self = this;

    // Attributes
    self.asset = asset;
    self.building = building;
    self.organization = organization;
    self.subtitle = {
      name: "Assets",
      state: { name: "app.assets", params: $stateParams },
    };
    self.permissions = getDefaultPermissions();
    self.tabs = getTabs();

    // Functions
    self.onAssetChange = onAssetChange;

    $scope.$on(EVENT_PIN_REFRESH, function () {
      var params = {
        include_values: true,
      };
      var options = {
        includePinType: true,
        includeFloor: true,
        includeRoom: true,
      };
      AssetService.getById(
        self.asset.building,
        self.asset._id,
        params,
        options
      ).then((updatedAsset) => {
        onAssetChange(updatedAsset);
      });
    });

    init();

    function init() {
      var permissions = UserService.getPermissions();
      self.permissions.canDecommission = permissions.asset.decommission;
      self.permissions.canRecommission = permissions.asset.recommission;
    }

    function getDefaultPermissions() {
      return {
        canRecommission: false,
        canDecommission: false,
      };
    }

    function getTabs() {
      var overview = {
        title: "Overview",
        state: "app.asset.overview",
      };
      var details = { title: "Details", state: "app.asset.details" };
      var openWorkOrders = {
        title: "Open Work Orders",
        state: "app.asset.openWorkOrders",
      };
      var closedWorkOrders = {
        title: "Closed Work Orders",
        state: "app.asset.closedWorkOrders",
      };
      var schedules = {
        title: "Maintenance Schedules",
        state: "app.asset.schedules",
      };

      var tabsToReturn = [overview, details];
      if (organization.show_tasks) {
        tabsToReturn.push(openWorkOrders, closedWorkOrders);
        if (!self.asset.is_decommissioned) {
          tabsToReturn.push(schedules);
        }
      }

      return tabsToReturn;
    }

    function onAssetChange(updatedAsset) {
      self.asset = updatedAsset;
      self.tabs = getTabs();
      $scope.$broadcast("asset-updated", { asset: updatedAsset });
    }
  }
})();
