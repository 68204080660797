(function () {
  angular
    .module("akitabox.core.services.markup", [
      "ngCookies",
      "akitabox.core.constants",
      "akitabox.core.services.http",
      "akitabox.planView",
    ])
    .factory("MarkupService", MarkupService);

  /** @ngInject */
  function MarkupService(
    $rootScope,
    $q,
    // Constants
    models,
    MARKUP_TOOL,
    // Services
    HttpService,
    IdentityService,
    // Third-party
    CacheFactory,
    paper,
    moment
  ) {
    var cache;

    self.$onInit = init;

    function init() {
      cache = CacheFactory.get(models.MARKUP.ROUTE_PLURAL);
      if (!cache) {
        cache = CacheFactory(models.MARKUP.ROUTE_PLURAL);
      }
    }

    /**
     * Markup service
     *
     * @type {Object}
     */
    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getById: getById,
      // Update
      update: update,
      updateAdminOnly: updateAdminOnly,
      // Delete
      remove: remove,
      // Active Markups
      queueSave: angular.debounce(autoSaveMarkup, 1000),
      forceSave: autoSaveMarkup,
      loadActiveMarkups: loadActiveMarkups,
      reloadActiveMarkups: reloadActiveMarkups,
      getActiveMarkups: getActiveMarkups,
      getActiveMarkup: getActiveMarkup,
      createActiveMarkup: createActiveMarkup,
      removeMarkup: removeMarkup,
      selectMarkup: selectMarkup,
      selectLatestUserMarkup: selectLatestUserMarkup,
      hasActiveMarkup: hasActiveMarkup,
      isMarkupSaving: isMarkupSaving,
      isMarkupSynced: isMarkupSynced,
      isMarkupSyncFailing: isMarkupSyncFailing,
      isInternetUnavailable: isInternetUnavailable,
      isGloballyVisible: isGloballyVisible,
      toggleGlobalVisibility: toggleGlobalVisibility,
      toggleAdmin: toggleAdmin,
      toggleMarkup: toggleMarkup,
      // Utils
      filterByCreAccount: filterByCreAccount,
    };

    function buildBaseRoute(buildingId, floorId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.FLOOR.ROUTE_PLURAL +
        "/" +
        floorId +
        "/" +
        models.MARKUP.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, floorId, markupId) {
      var base = buildBaseRoute(buildingId, floorId);
      return base + "/" + markupId;
    }

    function buildListRoute(buildingId, floorId) {
      return buildBaseRoute(buildingId, floorId);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Create a new Markup
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 floorId     Floor ID
     * @param  {Object}                 data        Params used to create a markup
     * @return {Promise<Object|Error>}              Promise that resolves with a new markup
     */
    function create(buildingId, floorId, data) {
      var route = buildListRoute(buildingId, floorId);
      return HttpService.post(route, omit$(data), undefined, undefined, cache);
    }

    /**
     * Get markups
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 floorId     Floor ID
     * @param  {Object}                 params      Query params
     * @return {Promise<Object|Error>}              Promise that resolves with markups
     */
    function get(buildingId, floorId, params) {
      var route = buildListRoute(buildingId, floorId);
      return HttpService.get(route, params, cache);
    }

    /**
     * Get a markup by ID
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 floorId     Floor ID
     * @param  {String}                 markupId    Markup ID
     * @param  {Object}                 params      Query params
     * @return {Promise<Object|Error>}              Promise that resolves with markup
     */
    function getById(buildingId, floorId, markupId, params) {
      var route = buildDetailRoute(buildingId, floorId, markupId);
      return HttpService.getById(route, markupId, params, cache);
    }

    /**
     * Update a markup by ID
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 floorId     Floor ID
     * @param  {String}                 markupId    Markup ID
     * @param  {Object}                 data        Markup values
     * @return {Promise<Object|Error>}              Promise that resolves with markup
     */
    function update(buildingId, floorId, markupId, data) {
      var route = buildDetailRoute(buildingId, floorId, markupId);
      return HttpService.put(route, omit$(data), undefined, cache);
    }

    function updateAdminOnly(buildingId, floorId, markupId, admin) {
      var route = buildDetailRoute(buildingId, floorId, markupId);
      return HttpService.patch(route, {
        action: "updateAdminOnly",
        admin_only: admin,
      });
    }

    /**
     * Remove a markup by ID
     *
     * @param  {String}                 buildingId  Building ID
     * @param  {String}                 floorId     Floor ID
     * @param  {String}                 markupId    Markup ID
     * @return {Promise<Object|Error>}              Promise that resolves (without a markup)
     */
    function remove(buildingId, floorId, markupId) {
      var route = buildDetailRoute(buildingId, floorId, markupId);
      return HttpService.remove(route, markupId, cache);
    }

    /**
     * Active Markups
     */
    var markups = [];
    var globalVisibility = true;
    var activeBuildingId;
    var activeFloorId;
    var activeMarkupId;
    var autoSaveState = MARKUP_TOOL.AUTOSAVE_STATE.SYNCED;

    // Param is goofy way to circumvent Angular circular dependency complaints
    function autoSaveMarkup(abxMarkupTools) {
      if (!abxMarkupTools.isDrawing() && !service.isMarkupSaving()) {
        autoSaveState = MARKUP_TOOL.AUTOSAVE_STATE.SAVING;
        var activeMarkup = service.getActiveMarkup();
        var exportedJson = activeMarkup.$layer.exportJSON();
        var hash = stringHash(exportedJson);

        if (activeMarkup.$hash !== hash) {
          activeMarkup.markup_json = exportedJson;

          return service
            .update(
              activeBuildingId,
              activeFloorId,
              activeMarkupId,
              activeMarkup
            )
            .then(function (markup) {
              Object.assign(activeMarkup, markup);
              activeMarkup.$hash = hash;
              autoSaveState = MARKUP_TOOL.AUTOSAVE_STATE.SYNCED;
            })
            .catch(function (e) {
              if (e.status === -1) {
                autoSaveState = MARKUP_TOOL.AUTOSAVE_STATE.NO_INTERNET;
              } else {
                autoSaveState = MARKUP_TOOL.AUTOSAVE_STATE.FAILED;
              }
            });
        } else {
          autoSaveState = MARKUP_TOOL.AUTOSAVE_STATE.SYNCED;

          return $q.resolve();
        }
      }

      return $q.resolve();
    }

    function selectLatestUserMarkup() {
      IdentityService.getCurrent().then(function (user) {
        var latestUserMarkup = markups.find(service.filterByCreAccount(user));

        service.selectMarkup(latestUserMarkup);
      });
    }

    function selectMarkup(markup) {
      if (markup) {
        activeMarkupId = markup._id;

        return service
          .getById(activeBuildingId, activeFloorId, activeMarkupId, {})
          .then(function (markupWithJson) {
            markup.markup_json = markupWithJson.markup_json;

            if (markup.$layer) {
              markup.$layer.activate();
            } else {
              markup.$layer = new paper.Layer();

              if (markup.markup_json) {
                markup.$layer.importJSON(markup.markup_json);
              }
            }
            markup.$layer.opacity = 1;
            service.toggleMarkup(markup, true);
            service.getActiveMarkups().forEach(function (m) {
              if (m._id !== activeMarkupId && m.$layer) {
                m.$layer.opacity = 0.5;
              }
            });

            $rootScope.$broadcast(MARKUP_TOOL.ACTIONS.SELECT_MARKUP, markup);

            return markup;
          });
      } else {
        activeMarkupId = null;
        $rootScope.$broadcast(MARKUP_TOOL.ACTIONS.SELECT_MARKUP, null);
        $q.resolve();
      }
    }

    function reloadActiveMarkups() {
      var oldMarkups = markups;
      return service
        .loadActiveMarkups(activeBuildingId, activeFloorId, activeMarkupId)
        .then(function (newMarkups) {
          newMarkups.forEach(function (markup) {
            var oldMarkup = oldMarkups.find(function (m) {
              return m._id === markup._id;
            });

            if (oldMarkup && oldMarkup.$layer) {
              var opacity = oldMarkup.$layer.opacity;
              Object.assign(markup, {
                $layer: oldMarkup.$layer,
                $isVisible: oldMarkup.$isVisible,
              });
              markup.$layer.importJSON(markup.markup_json);
              markup.$layer.opacity = opacity;
            }
          });

          return newMarkups;
        });
    }

    function loadActiveMarkups(buildingId, floorId, activeMarkup) {
      activeBuildingId = buildingId;
      activeFloorId = floorId;
      activeMarkupId = activeMarkup;
      return service
        .get(buildingId, floorId, {
          excludeMarkupLayer: !activeMarkup,
        })
        .then(function (markupsResponse) {
          markupsResponse.sort(function (a, b) {
            var aDate = moment(a.last_mod_date || a.cre_date);
            var bDate = moment(b.last_mod_date || b.cre_date);

            if (aDate < bDate) {
              return 1;
            } else if (aDate > bDate) {
              return -1;
            } else {
              return 0;
            }
          });
          return (markups = markupsResponse);
        });
    }

    function getActiveMarkups() {
      return markups;
    }

    function getActiveMarkup() {
      return markups.find(function (markup) {
        return markup._id === activeMarkupId;
      });
    }

    function hasActiveMarkup() {
      return Boolean(activeMarkupId);
    }

    function isMarkupSaving() {
      return autoSaveState === MARKUP_TOOL.AUTOSAVE_STATE.SAVING;
    }

    function isMarkupSynced() {
      return autoSaveState === MARKUP_TOOL.AUTOSAVE_STATE.SYNCED;
    }

    function isMarkupSyncFailing() {
      return autoSaveState === MARKUP_TOOL.AUTOSAVE_STATE.FAILED;
    }

    function isInternetUnavailable() {
      return autoSaveState === MARKUP_TOOL.AUTOSAVE_STATE.NO_INTERNET;
    }

    function createActiveMarkup(markup) {
      return service
        .create(activeBuildingId, activeFloorId, markup)
        .then(function (markup) {
          markups.unshift(markup);

          return service.selectMarkup(markup);
        });
    }

    function removeMarkup(markup) {
      return service
        .remove(markup.building, markup.level, markup._id)
        .then(function () {
          markups = markups.filter(function (m) {
            return m !== markup;
          });

          if (markup.$layer) {
            markup.$layer.remove();
          }

          if (activeMarkupId === markup._id) {
            activeMarkupId = null;
          }

          return markup;
        });
    }

    function toggleAdmin(markup, admin) {
      return service.updateAdminOnly(
        activeBuildingId,
        activeFloorId,
        markup._id,
        admin
      );
    }

    /**
     * Set the visibility of this markup, and its layer visibility
     * if applicable.
     */
    function toggleMarkup(markup, visibility) {
      markup.$isVisible = visibility;

      if (markup.$layer) {
        markup.$layer.visible = globalVisibility && visibility;
      } else if (visibility) {
        service
          .getById(activeBuildingId, activeFloorId, markup._id, {})
          .then(function (markupWithJson) {
            markup.markup_json = markupWithJson.markup_json;
            markup.$layer = new paper.Layer();

            if (markup.markup_json) {
              markup.$layer.importJSON(markup.markup_json);
              markup.$layer.opacity = 0.5;
            }
          });
      }

      if (!visibility && markup._id === activeMarkupId) {
        activeMarkupId = null;
      }

      if (visibility && activeMarkupId === null) {
        IdentityService.getCurrent().then(function (user) {
          var userId = markup.cre_account && markup.cre_account._id;
          if (user._id === userId) {
            service.selectMarkup(markup);
          }
        });
      }
    }

    /**
     * Utility function allows to filter markups by an account.
     * Creation of this functions is due to the need for consistent filtering
     *
     * @param {Object} identity               Active user's identity
     * @param {boolean} isAccountCompliment   Set to true if you require markups that are not created by active user
     * @returns {Function} filter
     */
    function filterByCreAccount(identity, isAccountCompliment) {
      return function (markup) {
        var userId = markup.cre_account._id || markup.cre_account;

        return isAccountCompliment
          ? userId !== identity._id
          : userId === identity._id;
      };
    }

    /**
     * Set global markup visibility and update actual layer visibilities
     */
    function toggleGlobalVisibility(visibility) {
      globalVisibility = visibility;

      service.getActiveMarkups().forEach(function (markup) {
        if (markup.$layer) {
          markup.$layer.visible = globalVisibility && markup.$isVisible;
        }
      });
    }

    function isGloballyVisible() {
      return globalVisibility;
    }

    function stringHash(str) {
      var hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }

    /**
     * Omit $ prepended fields
     */
    function omit$(o) {
      return Object.keys(o)
        .filter(function (key) {
          return !key.includes("$");
        })
        .reduce(function (withoutOmitted, key) {
          withoutOmitted[key] = o[key];
          return withoutOmitted;
        }, {});
    }

    return service;
  }
})();
