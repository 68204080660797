(function () {
  angular
    .module("akitabox.core.services.pinType", [
      "akitabox.constants",
      "akitabox.core.services.cacheHelpers",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("PinTypeService", PinTypeService);

  /** @ngInject */
  function PinTypeService(
    // Constants
    models,
    // Services
    HttpService,
    CacheHelpers,
    // Third-party
    CacheFactory
  ) {
    var cache;
    var buildingIdUsedForCache;

    init();

    function init() {
      cache = CacheFactory.get("pinTypes");
      if (!cache) {
        cache = CacheFactory("pinTypes");
      }
    }

    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getAllByOrganization: getAllByOrganization,
      getById: getById,
      getFieldIdByName: getFieldIdByName,
      getFieldByName: getFieldByName,
      // Update
      update: update,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildListRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.PIN_TYPE.ROUTE_PLURAL
      );
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" + organizationId + "/" + models.PIN_TYPE.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, pinTypeId) {
      var base = buildListRoute(buildingId);
      return base + "/" + pinTypeId;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildListRoute(buildingId);
      return HttpService.post(route, data, undefined, undefined, cache);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      if (options && !options.cache) {
        return HttpService.get(route, params);
      }
      return HttpService.get(route, params, cache);
    }

    function getAll(buildingId, params, options) {
      params = params || {};
      options = options || { cache: true };

      if (options.cache) {
        var buildingHasChanged = buildingId !== buildingIdUsedForCache;
        var hasCustomFilters = CacheHelpers.hasCustomFilters(params, [
          "protected_type",
        ]);
        var usingCache = !buildingHasChanged && !hasCustomFilters;
        if (!usingCache) {
          cache.removeAll();
        }
      }

      var protectedType = params.protected_type;
      delete params.protected_type;

      var route = buildListRoute(buildingId);
      var request = !options.cache
        ? HttpService.getAll(route, params)
        : HttpService.getAll(route, params, cache);

      return request.then(function (pinTypes) {
        if (options.cache) buildingIdUsedForCache = buildingId;

        return filterByProtectedType(pinTypes);
      });

      function filterByProtectedType(pinTypes) {
        if (protectedType) {
          var lowerCaseType = protectedType.toLowerCase();
          return pinTypes.filter(function (pinType) {
            return pinType.protected_type.toLowerCase() === lowerCaseType;
          });
        }
        return pinTypes;
      }
    }

    function getAllByOrganization(organizationId, params) {
      params = params || {};

      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, id, params) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params, cache);
    }

    /**
     * Find a pin field in a pin type, and return its ID.
     *
     * @param {String}      buildingId      Building ID
     * @param {String}      pinTypeId       PinType ID
     * @param {String}      pinFieldName    PinField Name
     * @return {Object}     pinFieldID      PinField ID
     */
    function getFieldIdByName(buildingId, pinTypeId, pinFieldName) {
      return service
        .getFieldByName(buildingId, pinTypeId, pinFieldName)
        .then(function (pinField) {
          if (!pinField) {
            return pinField;
          }
          return pinField._id;
        });
    }

    /**
     * Find a pin field in a pin type, and return it
     *
     * @param {String}      buildingId      Building ID
     * @param {String}      pinTypeId       PinType ID
     * @param {String}      pinFieldName    PinField Name
     * @return {Object}     pinFieldID      PinField ID
     */
    function getFieldByName(buildingId, pinTypeId, pinFieldName) {
      var lowerCaseFieldName = pinFieldName.toLowerCase();
      return getById(buildingId, pinTypeId).then(function (pinType) {
        var fields = pinType.fields;
        for (var i = 0; i < fields.length; ++i) {
          var field = fields[i];
          if (field.name.toLowerCase() === lowerCaseFieldName) {
            return field;
          }
        }
        return null;
      });
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, id, data) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.put(route, data, undefined, cache);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, id) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.remove(route, id).then(function (data) {
        CacheHelpers.removeModel(cache, id);
        return data;
      });
    }
  }
})();
