import { reactFromAngular } from "../reactFromAngular";

// TODO
type Org = any;
type Building = any;
// TODO: Finish typing this interface when we start using
// the <AngularShell> component
export interface AbxAppHeaderProps {
  abxSuperText: string | null;
  abxPrimaryText: string | null;
  abxOrganization: Org;
  abxGroup: unknown;
  abxBuilding: Building | null;
  abxIsMobile: boolean;
  abxOnGroupChange: unknown;
  abxOnBuildingChange: unknown;
  abxOnAdd: unknown;
  abxIsOpen: () => boolean;
  abxAppTitle: string;
}

export const AbxAppHeader = reactFromAngular<AbxAppHeaderProps>(
  "abxAppHeader",
  {
    abxSuperText: "@",
    abxAppTitle: "@",
    abxPrimaryText: "@",
    abxIsOpen: "&",
    abxOnAdd: "&($event)",
    abxOnGroupChange: "&($event)",
    abxOnBuildingChange: "&($event)",
  }
);
