(function () {
  angular.module("akitabox.desktop.schedule").run(stateBlock);

  /* @ngInject */
  function stateBlock(Router, models, FeatureFlagService) {
    var LIST_QUERY_PARAMS = models.MAINTENANCE_SCHEDULE.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.schedules",
        config: {
          url:
            "/" +
            models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL +
            "?" +
            LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/schedule/building-list/schedule-list.html",
          controller: "ScheduleListController",
          controllerAs: "vm",
          reloadOnSearch: true,
          resolve: {
            status: getStatus,
          },
          data: {
            pageTitle: "Maintenance Schedules",
          },
        },
      },
      {
        state: "app.building.schedule",
        config: {
          url: "/" + models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL + "/:scheduleId",
          templateUrl:
            "app/desktop/modules/schedule/detail/schedule-detail.html",
          controller: "ScheduleDetailController",
          controllerAs: "vm",
          resolve: {
            schedule: getSchedule,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.schedules",
        config: {
          url:
            "/" +
            models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL +
            "?" +
            LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/schedule/organization-list/org-schedule-list.html",
          controller: "OrganizationScheduleListController",
          controllerAs: "vm",
          reloadOnSearch: true,
          resolve: {
            status: getStatus,
          },
          data: {
            pageTitle: "Maintenance Schedules",
          },
        },
      },
      {
        state: "app.schedule",
        config: {
          url: "/" + models.MAINTENANCE_SCHEDULE.ROUTE_PLURAL + "/:scheduleId",
          templateUrl:
            "app/desktop/modules/schedule/detail/schedule-detail.html",
          controller: "ScheduleDetailController",
          controllerAs: "vm",
          resolve: {
            schedule: getOrganizationSchedule,
            building: getBuilding,
            redirect: redirect,
          },
        },
      },
    ]);
  }

  /** @ngInject */
  function getOrganizationSchedule(
    $stateParams,
    account,
    organization,
    ScheduleService
  ) {
    return ScheduleService.getByOrganization(organization._id, {
      _id: $stateParams.scheduleId,
    }).then(function (schedule) {
      return schedule;
    });
  }

  /** @ngInject */
  function getBuilding($stateParams, account, schedule, BuildingService) {
    return BuildingService.getById(schedule.building);
  }

  /** @ngInject **/
  function getSchedule($stateParams, account, building, ScheduleService) {
    return ScheduleService.getById(building._id, $stateParams.scheduleId);
  }

  /** @ngInject **/
  function redirect($stateParams, $state, account, schedule, RedirectService) {
    var destination = RedirectService.getDestination();
    if (destination.match(/^.*\/floors$/)) {
      if (angular.isEmpty(schedule.level)) {
        return $state.go("root.404");
      }
    } else if (destination.match(/^.*\/rooms$/)) {
      if (angular.isEmpty(schedule.room)) {
        return $state.go("root.404");
      }
    } else if (destination.match(/^.*\/assets/)) {
      if (angular.isEmpty(schedule.asset)) {
        return $state.go("root.404");
      }
    } else if (
      destination.match(/^\/([a-zA-Z0-9_]*\/){2}future_tasks\/[a-zA-Z0-9]+$/)
    ) {
      return $state.go("app.building.schedule.overview", $stateParams);
    } else if (destination.match(/\/future_tasks\/[a-zA-Z0-9]+$/)) {
      return $state.go("app.schedule.overview", $stateParams);
    }
  }

  /** @ngInject */
  function getStatus(
    account,
    $stateParams,
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE
  ) {
    return $stateParams.status || MAINTENANCE_SCHEDULE_STATUS_ACTIVE;
  }
})();
