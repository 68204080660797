(function () {
  angular.module("akitabox.core.pinValue", [
    "akitabox.core.services.asset",
    "akitabox.core.services.document",
    "akitabox.core.services.floor",
    "akitabox.core.services.pinType",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.room",
    "akitabox.core.services.tree",
    "akitabox.core.services.flag",
    "akitabox.core.services.user",
  ]);
})();
