(function () {
  angular
    .module("akitabox.ui.dialogs.alert")
    .service("AlertDialog", AlertDialog);

  /* @ngInject */
  function AlertDialog($mdDialog, $mdMedia, $log, $q) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/alert/alert.dialog.html",
      controller: "AlertDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * AlertDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param  {Object}   options                        Dialog options
     * @param  {Object}   options.locals                 Dialog options locals
     * @param  {String}   options.locals.title           The Title text for the Alert dialog
     * @param  {String}   options.locals.textContent     The main message text to display in the alert body
     * @param  {String}   [options.locals.ok]            The text for the Alert dialog's close/ok button. Defaults to "got it!"
     * @param  {String}   [options.locals.cancel]        The text for the Alert dialog's cancel button
     * @param { boolean } [options.locals.isShort=false] If short message dialog size
     * @return {Promise<Object|Error} Alert dialog promise
     */
    function show(options) {
      // Verify we have required data
      if (
        !options ||
        !options.locals ||
        !options.locals.title ||
        (!options.locals.textContent && !options.locals.listContents)
      ) {
        $log.error("AlertDialog: missing alert content");
        return $q.reject(
          "Sorry, we weren't able to display the correct dialog."
        );
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show($mdDialog.alert(dialogOptions));
    }
  }
})();
