(function () {
  angular
    .module("akitabox.core.directives.pinType", [])
    .directive("abxPinType", AbxPinTypeDirective);

  function AbxPinTypeDirective() {
    return {
      restrict: "E",
      templateUrl: "app/core/directives/pin-type/pin-type.html",
      transclude: true,
      scope: {
        icon: "@",
        color: "@",
        classes: "<?",
      },
    };
  }
})();
