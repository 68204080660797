(function () {
  angular.module("akitabox.ui.dialogs.asset.lifeCycle", [
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.services.cancellable",
    "akitabox.core.toast",
    "akitabox.ui.components.input",
    "akitabox.ui.dialogs",
    "akitabox.core.services.catalog",
    "akitabox.core.services.catalogItem",
    "akitabox.core.services.asset",
  ]);
})();
