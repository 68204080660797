(function () {
  angular
    .module("akitabox.ui.dialogs.note.delete")
    .controller("DeleteNoteDialogController", DeleteNoteDialogController);

  /* @ngInject */
  function DeleteNoteDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    NoteService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since nodeIDs is passed in through locals
    self.multiple = self.noteIDs ? self.noteIDs.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteNote = deleteNote;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete assets
     */
    function deleteNote() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.noteIDs.length) * 100;
      var promiseArray = [];
      var buildingId = self.building._id || self.building;

      // Delete the notes
      for (var i = 0; i < self.noteIDs.length; i++) {
        promiseArray[i] = NoteService.remove(buildingId, self.noteIDs[i])
          // when we delete a asset, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.noteIDs[storedI];
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedNoteIDs) {
          $mdDialog.hide(deletedNoteIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
