(function () {
  angular
    .module("akitabox.ui.dialogs.qrCode.pinSearch")
    .factory("QrPinSearchDialog", QrPinSearchDialogService);

  /* @ngInject */
  function QrPinSearchDialogService($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/qr-code/pin-search/qr-pin-search.dialog.html",
      controller: "QrPinSearchDialogController",
      controllerAs: "vm",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
      multiple: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog.
     *
     * @param {Object} options - Dialog options
     * @param {Object} options.locals - Local data to provide to the dialog's
     *     controller
     * @param {String} options.locals.code - String version of the QR code's
     *     actual "code". This is the `_code` field on the QR code schema.
     */
    function show(options) {
      validateLocals();
      angular.extend(modal, options);
      return $mdDialog.show(modal);

      function validateLocals() {
        var locals = options.locals;
        if (!angular.isString(locals.code)) {
          throw Error("QrPinSearchDialog: locals.code is required as a string");
        }
      }
    }
  }
})();
