import { bulkAddDialogOpened } from "../../../../../react/bulk-add-dialog/BulkAddDialogService";
(function () {
  angular
    .module("akitabox.ui.dialogs.asset.create")
    .controller("CreateAssetDialogController", CreateAssetDialogController);

  /* @ngInject */
  function CreateAssetDialogController(
    // Angular
    $q,
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Constants,
    models,
    // Services
    Router,
    AssetService,
    RoomService,
    PinTypeService,
    PinFieldService,
    ToastService,
    FeatureFlagService,
    OrganizationService
  ) {
    var self = this;

    var UNSUPPORTED_DATA_TYPES = ["document_array", "tag_filter"];

    // data is the Asset object that is ultimately sent to the database on create
    var data = {};
    data.values = {};

    // Attributes
    self.saving = false;
    self.pinTypes = null;
    self.pinTypeOptions = [];
    self.pinTypeLoaded = false;
    self.model = models.ASSET.PLURAL;
    self.organization = OrganizationService.getCurrent();

    // Asset object used by pinValueList
    self.asset = {
      building: self.building ? self.building._id : null,
      name: self.name || null,
      pinType: null,
      values: {},
    };
    self.loading = true;
    self.newAsset = null;
    self.installationYear = null;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.isDisabled = isDisabled;
    self.onBuildingChange = onBuildingChange;
    self.isFieldSupported = isFieldSupported;
    self.selectPinType = selectPinType;
    self.onNameChange = onNameChange;
    self.onValueChange = onValueChange;
    self.onInstallationYearChange = onInstallationYearChange;
    self.openBulkAddAssets = openBulkAddAssets;

    init();

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handle asset building change
     *
     * @param {Event} $event Change event
     */
    function onBuildingChange($event) {
      self.building = $event.model;
      self.asset.building = self.building ? self.building._id : null;
      selectPinType({ value: null });
      self.room = null;
      self.floor = null;
      if (self.building) fetchStuff();
    }

    /**
     * Called on pin type change. Responsible for setting initial values for the new pin type.
     */
    function selectPinType($event) {
      self.pinTypeLoaded = false;
      var pinType = findPinTypeByName($event.model);
      self.asset.pinType = pinType;
      self.asset.values = {};

      var pinFields = pinType ? pinType.fields : [];

      for (var i = pinFields.length - 1; i >= 0; --i) {
        var pinField = pinFields[i];
        var tempValue = getEmptyPinValueObject(pinField);

        // Set values (that we already have) for the new pin type
        if (tempValue.is_level_pin_value && !angular.isEmpty(self.floor)) {
          tempValue.is_empty = false;
          tempValue.name = self.floor.name;
          tempValue.value = self.floor._id;
          data.values[pinField._id] = self.floor._id;
        } else if (tempValue.is_room_pin_value && !angular.isEmpty(self.room)) {
          tempValue.is_empty = false;
          tempValue.name = self.room.name;
          tempValue.number = self.room.number;
          tempValue.value = self.room._id;
          data.values[pinField._id] = self.room._id;
        }

        if (!pinField.is_default_empty) {
          var defaultValue = pinField.default_value;
          data.values[pinField._id] = defaultValue;
          tempValue.value = defaultValue;
        }

        self.asset.values[pinField._id] = tempValue;
      }

      self.pinTypeLoaded = true;

      function getEmptyPinValueObject(pinField) {
        return {
          building: pinField.building,
          pinField: pinField._id,
          is_empty: true,
          is_room_pin_value: pinField.is_room_field,
          is_level_pin_value: pinField.is_level_field,
        };
      }
    }

    /**
     * Handle asset name change
     *
     * @param {Event} $event Change event
     */
    function onNameChange($event) {
      self.asset.name = $event.model;
    }

    /**
     * Updates floor field to be the same as the provided room's floor
     *
     * @param {Object} room Indicates which floor should be set via `room.level`
     */
    function setFloorFromRoom(room) {
      const floorPinField = PinFieldService.getFloorPinField(
        self.asset.pinType.fields
      );

      RoomService.getFloorBFF(self.building._id, room).then((floor) => {
        self.onValueChange({ model: floor }, floorPinField);
      });
    }

    function onInstallationYearChange($event) {
      self.installationYear = $event.model;
    }

    /**
     * Update asset values when the user changes the form field values
     */
    function onValueChange($event, field) {
      var value = $event.model;
      if (!Object.prototype.hasOwnProperty.call(self.asset.values, field._id)) {
        // the field doesn't exist in this asset, don't update anything
        return;
      }

      // clear out the data we send to the database
      if (Object.prototype.hasOwnProperty.call(data.values, field._id)) {
        delete data.values[field._id];
      }
      if (field.is_level_field) {
        self.floor = value;
        if (!self.floor) {
          // clear out current room when floor gets cleared
          const roomPinField = PinFieldService.getRoomPinField(
            self.asset.pinType.fields
          );
          self.onValueChange({ model: null }, roomPinField);
        }
      } else if (field.is_room_field) {
        self.room = value;
        if (self.room && self.room.level) {
          setFloorFromRoom(self.room);
        }
      }

      // extract the actual id from the room and floor fields since that's what the DB expects
      if ((field.is_level_field || field.is_room_field) && value) {
        // value in this context is an entire floor or room object with ._id field
        // and we only want to save the ._id field
        value = value._id;
      }

      if (angular.isEmpty(value)) {
        self.asset.values[field._id] = {
          value: null,
        };
      } else {
        var pinValue = self.asset.values[field._id];
        self.asset.values[field._id] = angular.extend({}, pinValue, {
          value: value,
        });
        data.values[field._id] = value;
      }
    }

    /**
     * Save the new Asset
     */
    function create() {
      if (self.saving) return;
      // start the loading spinner
      self.saving = true;

      // grab data from the form to send to the database; data.values is already correct
      data.name = angular.copy(self.asset.name);
      data.pinType = angular.copy(self.asset.pinType._id);
      if (self.installationYear || self.installationYear === 0) {
        if (self.installationYear < 1000 || self.installationYear > 9999) {
          return $q.reject("Invalid year");
        }
        var installationDate = new Date(self.installationYear, 0, 2); // 01/02/YYYY
        data.installation_date = installationDate.getTime();
      } else {
        data.installation_date = null;
      }
      var params = { include_values: true };

      AssetService.create(self.building._id, data, params)
        .then(function (asset) {
          asset.pinType = angular.copy(self.asset.pinType);

          self.newAsset = asset;
          $mdDialog.hide([asset]);
          if (!self.hideToast) {
            ToastService.complex()
              .text("Successfully created asset")
              .action("View", goToNewAsset)
              .show();
          }
        })
        .catch(ToastService.showError)
        .finally(function () {
          // stop the spinner
          self.saving = false;
        });
    }

    /**
     * Determine if the submit button should be disabled.
     * Asset name, building, pin type and required pin fields are required
     *
     * Determine if the user has permissions to create an asset
     *
     * @param  {Object} Event object
     * @return {Boolean} True if disabled, false if not
     */
    function isDisabled() {
      const hasValidInstallationYear =
        (self.installationYear && self.installationYear < 1000) ||
        self.installationYear > 9999;
      if (
        !self.asset.name ||
        !self.asset.building ||
        !self.asset.pinType ||
        hasValidInstallationYear ||
        self.saving
      ) {
        return true;
      }

      // Check pin values
      var fields = self.asset.pinType.fields;
      for (var i = 0; i < fields.length; ++i) {
        var pinField = fields[i];
        var value = data.values[pinField._id];
        if (pinField.is_required && angular.isEmpty(value)) {
          return true;
        }
      }

      return false;
    }

    /**
     * Determine if a pin field is supported by this create dialog
     *
     * @return {Boolean}  True if supported, false if not
     */
    function isFieldSupported(field) {
      return UNSUPPORTED_DATA_TYPES.indexOf(field.data_type) < 0;
    }

    function openBulkAddAssets() {
      self.cancel();

      bulkAddDialogOpened.next({
        model: self.model,
        organizationId: self.organization._id,
      });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, fetch PinTypes of type Asset
     */
    function init() {
      if (angular.isEmpty(self.building)) {
        self.loading = false;
        self.pinTypes = [];
        return;
      }

      fetchStuff();
    }

    function fetchStuff() {
      $q.all([setPinTypes(), setRoom().then(setFloor)])
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });

      function setPinTypes() {
        var params = { protected_type: models.ASSET.SINGULAR };
        var options = { cache: false };
        return PinTypeService.getAll(self.building._id, params, options)
          .then(function (pinTypes) {
            self.pinTypes = pinTypes || [];
            self.pinTypeOptions = self.pinTypes.map(function (pinType) {
              return pinType.name;
            });
            var pinTypeId = self.pinType ? self.pinType._id : null;
            // If we have been provided a pin type,
            // find it in the list and select it
            if (pinTypeId) {
              for (var i = 0; i < pinTypes.length; ++i) {
                var pinType = pinTypes[i];
                if (pinType._id === pinTypeId) {
                  self.asset.pinType = pinType;
                  selectPinType({ model: pinType.name });
                }
              }
              if (self.values) {
                populateAssetValues(self.pinType, self.values);
              }
            }
          })
          .catch(function (err) {
            self.pinTypes = [];
            return $q.reject(err);
          });
      }

      function setFloor() {
        if (!self.floor || !self.floor.name) {
          if (self.room && self.room.values) {
            return RoomService.getFloor(
              self.building._id,
              self.room.values
            ).then(selectFloor);
          } else if (self.values) {
            return AssetService.getFloor(self.building._id, self.values).then(
              selectFloor
            );
          }
        }
        return $q.resolve();

        function selectFloor(floor) {
          self.floor = floor || null;
          if (self.pinType) {
            var pinField = PinFieldService.getFloorPinField(
              self.pinType.fields
            );
            self.onValueChange({ model: floor }, pinField);
          }
          return floor;
        }
      }

      function setRoom() {
        if (!self.room && self.values) {
          return AssetService.getRoom(self.building._id, self.values).then(
            function (room) {
              self.room = room || null;
              if (self.pinType) {
                var pinField = PinFieldService.getRoomPinField(
                  self.pinType.fields
                );
                self.onValueChange({ model: room }, pinField);
              }
              return room;
            }
          );
        }
        return $q.resolve();
      }
    }

    /**
     * Go to the newly created asset's detail page
     */
    function goToNewAsset() {
      var stateParams = {
        buildingId:
          self.newAsset.buildingId ||
          $stateParams.buildingId ||
          self.building._id ||
          null,
        assetId: self.newAsset._id,
      };
      Router.go("app.asset", stateParams);
    }

    function findPinTypeByName(name) {
      if (self.pinTypes && self.pinTypes.length) {
        return self.pinTypes.filter(function (pinType) {
          return pinType.name === name;
        })[0];
      }
      return null;
    }

    /**
     * Populate asset (form) values, ignores floor and room values
     *
     * @param {Object}    pinType     Selected pin type
     * @param {Object[]}  newValues   Pin values to populate
     */
    function populateAssetValues(pinType, newValues) {
      var pinFields = pinType.fields.reduce(function (map, field) {
        map[field._id] = field;
        return map;
      }, {});
      var keys = Object.keys(newValues);
      for (var i = 0; i < keys.length; ++i) {
        var key = keys[i];
        var value = newValues[key];
        var field = pinFields[value.pinField];
        if (!field || field.is_level_field || field.is_room_field) {
          // Skip floor and room fields
          continue;
        } else {
          self.onValueChange({ model: value.value }, field);
        }
      }
    }
  }
})();
