(function () {
  /**
   * @ngdoc component
   * @name abxFieldListPreloaders
   *
   * @description
   * This component provides a static list of preloaders that are intended to
   * mock a list of fields. An example use case is for the details of an asset.
   */
  angular
    .module("akitabox.ui.components.preloaders")
    .component("abxFieldListPreloaders", {
      templateUrl:
        "app/core/ui/components/preloaders/field-list-preloaders/field-list-preloaders.component.html",
    });
})();
