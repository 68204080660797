import { reactRouterFromAngular } from "../../../../react/WebClientApp";
(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.sideMenu
   */
  angular
    .module("akitabox.ui.directives.sideMenu", [
      "ui.router",
      "akitabox.core.lib.pluralize",
      "akitabox.core.router",
      "akitabox.core.services.shadow",
      "akitabox.core.utils",
    ])
    .directive("abxSideMenu", AbxSideMenuDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.sideMenu
   * @name AbxSideMenuDirective
   * @restrict E
   *
   * @description
   * `<abx-side-menu>` is a side menu directive that displays a
   * collapsible menu with configurable sections and children
   *
   * @usage
   * <hljs lang="html">
   * <abx-side-menu section="vm.sections"></abx-side-menu>
   * </hljs>
   */
  function AbxSideMenuDirective() {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/side-menu/side-menu.html",
      controller: AbxSideMenuController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        sections: "=",
      },
    };
  }

  /* @ngInject */
  function AbxSideMenuController(
    // Angular
    $window,
    $location,
    // Material
    $mdMedia,
    // Third party
    pluralize,
    $state,
    // Services
    Router,
    ShadowService,
    ToastService,
    Utils
  ) {
    var self = this;

    // Functions
    self.$mdMedia = $mdMedia;
    self.isSectionActive = isSectionActive;
    self.isSectionChildActive = isSectionChildActive;
    self.isChildActive = isChildActive;
    self.showChildren = showChildren;
    self.followLink = followLink;
    self.go = go;
    self.goTo = goTo;

    function go(state, gaParams) {
      if (gaParams) sendGAEvent(gaParams);

      var href = Router.href(state.name, state.params);
      if (decodeURIComponent($location.url()) === decodeURIComponent(href)) {
        // Already there, do nothing
        return;
      }
      return Router.go(state.name, state.params).catch(function (err) {
        if (err) {
          ToastService.showError(err);
        }
      });
    }

    function goTo(pathname, gaParams) {
      if (gaParams) sendGAEvent(gaParams);
      reactRouterFromAngular.next({ to: { pathname } });
    }

    function isSectionActive(section) {
      if (section.isReact) {
        return $location.url() === section.pathname;
      }
      // Check if the section is a parent of the current state
      var active = isSectionStateActive(section);
      if (!active) return false;
      // Section is not active if one of it's menu children is
      var childActive = isSectionChildActive(section);
      return !childActive;
    }

    function isChildActive(child) {
      if (child.isReact) {
        return $location.url() === child.pathname;
      }
      return Utils.isStateActive(child.state);
    }

    function showChildren(section) {
      return isSectionStateActive(section);
    }

    function followLink(link, gaParams) {
      if (gaParams) sendGAEvent(gaParams);

      if (link.newTab) {
        $window.open(link.href, "_blank");
      } else {
        $window.location.href = link.href;
      }
    }

    function sendGAEvent(gaParams) {
      return ShadowService.sendEvent(
        gaParams.category,
        gaParams.action,
        gaParams.label,
        gaParams.value
      );
    }

    function isSectionChildActive(section) {
      var children = section.children;
      var numChildren = children ? children.length : 0;
      if (numChildren) {
        for (var i = 0; i < numChildren; ++i) {
          if (isChildActive(children[i])) return true;
        }
      }
      return false;
    }

    function isSectionStateActive(section) {
      if (section.isReact) {
        return $location.url().includes(section.pathname);
      }
      const currentStateName = $state.current.name;
      const singularSectionName = pluralize.singular(section.state.name);
      return currentStateName.indexOf(singularSectionName) !== -1;
    }
  }
})();
