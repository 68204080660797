(function () {
  /**
   * @ngdoc component
   * @name abxPinTypeFilterItem
   *
   * @param {Boolean} active - True iff this filter is active
   * @param {Function} onChange - To be invoked when activated, deactivated, or
   *     modified. Should be invoked with: event.filter, event.pinType.
   * @param {Function} onSubfilterClick - To be invoked when the subfilter icon
   *     for a filter item is clicked. Should be invoked with: event.pinTypeId.
   * @param {Object} pinType - Pin type to be responsible for and filter with
   * @param {Number} [pinType.pins_count] - Total number of pins for the given
   *     pin type on the floor plan.
   * @param {Number} [pinType.shown_pins_count] - Number of pins shown for the
   *     pin type on the floor plan (i.e. sub-filtering has hidden some of
   *     the pins that would be included in the normal pins count)
   * @param {Object} pinTypeFilter - Current filter object used for filtering
   *     by the pin type. Necessary to show chips for its subfilters.
   *
   * @description
   * A list item for a pin type used for filtering. The user can filter by this
   * pin type, and additionally filter by specific pin fields in this pin type.
   */
  angular.module("akitabox.planView").component("abxPinTypeFilterItem", {
    bindings: {
      active: "<abxActive",
      onChange: "&abxOnChange",
      onSubfilterClick: "&abxOnSubfilterClick",
      pinType: "<abxPinType",
      pinTypeFilter: "<abxPinTypeFilter",
    },
    controller: AbxPinTypeFilterItemController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/pin-type-filters/pin-type-filter-item.component.html",
  });

  function AbxPinTypeFilterItemController(
    // Angular
    $element,
    // Constants
    PV_GA,
    // Services
    ShadowService,
    PlanViewUtils
  ) {
    var self = this;

    // Private
    var filter; // Represents filter to be used in query for pins
    var $chipBag;

    // Attributes
    self.count = null;
    self.subfilters = [];

    // Functions
    self.showSubfiltering = showSubfiltering;
    self.toggle = toggle;
    self.getCount = getCount;
    self.onTuneIconMouseEnter = onTuneIconMouseEnter;
    self.onTuneIconMouseLeave = onTuneIconMouseLeave;

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.pinType) {
        filter = { _id: self.pinType._id };
      }

      if (changes.pinTypeFilter) {
        setSubfilters();

        if (self.pinTypeFilter) {
          filter = self.pinTypeFilter;
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Tell the parent to show the view for a user to sub-filter on this pin
     * type's pin fields.
     */
    function showSubfiltering($event) {
      // prevent self.toggle from firing
      $event.stopPropagation();

      self.onSubfilterClick({
        $event: { pinTypeId: self.pinType._id },
      });
    }

    /**
     * Toggle this filter on and off. Any specific filter data will be remembered
     * between being on and off.
     */
    function toggle() {
      var filterToSend = self.active ? null : filter;
      var wasNotActive = !self.active;
      self
        .onChange({
          $event: {
            pinType: self.pinType,
            filter: filterToSend,
          },
        })
        .then(function () {
          if (wasNotActive) {
            ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.FILTER);
          }
        });
    }

    /**
     * Get the count to display. The "shown" pins count will be prioritized
     * over a total pins count. If neither are present, no count will be shown.
     *
     * @return {Number | String} - Count to show
     */
    function getCount() {
      if (angular.isNumber(self.pinType.shown_pins_count)) {
        return self.pinType.shown_pins_count;
      } else if (angular.isNumber(self.pinType.pins_count)) {
        return self.pinType.pins_count;
      } else {
        return "…";
      }
    }

    /**
     * Handle activity related to tune icon mouse enter event
     */
    function onTuneIconMouseEnter() {
      // $element.find only works on tag names, not class selectors
      var $allDivs = angular.element($element.find("div"));

      $allDivs.each(function (index, $elem) {
        var $wrappedElem = angular.element($elem);
        if ($wrappedElem.hasClass("abx-pin-type-filter-item__chip-bag")) {
          $chipBag = $wrappedElem;
        }
      });

      if ($chipBag) {
        $chipBag.addClass("abx-tune-icon-hover");
      }
    }

    /**
     * Handle activity related to tune icon mouse leave event
     */
    function onTuneIconMouseLeave() {
      if ($chipBag) {
        $chipBag.removeClass("abx-tune-icon-hover");
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * Parse the subfilters from the current pin type filter and attach them
     */
    function setSubfilters() {
      if (!self.pinTypeFilter) {
        self.subfilters = [];
        return;
      }

      PlanViewUtils.getPinTypeSubfilters(self.pinTypeFilter, self.pinType).then(
        function (subfilters) {
          self.subfilters = subfilters;
        }
      );
    }
  }
})();
