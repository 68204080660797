(function () {
  angular
    .module("akitabox.desktop.inspectionProgram.detail")
    .controller(
      "InspectionProgramDetailsSectionController",
      InspectionProgramDetailsSectionController
    );

  /** @ngInject */
  function InspectionProgramDetailsSectionController(
    // Angular
    $q,
    $log,
    $scope,
    $window,
    //Akitabox
    models,
    // Third party
    moment,
    // Events
    EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE,
    // Resolves
    inspectionProgram,
    organization,
    // Services
    AttachmentService,
    BuildingService,
    EnvService,
    FloorService,
    ChecklistService,
    InspectionService,
    NoteService,
    Router,
    ToastService
  ) {
    var self = this;
    var debounceFetchStops = angular.debounce(getFilteredStops, 500);
    var CHECKLIST_STATUS_VERBIAGE = models.CHECKLIST.STATUS_VERBIAGE;

    // Public Attributes
    self.inspectionProgram = inspectionProgram;
    self.checklists = null;
    self.building = BuildingService.getCurrent();
    self.organization = organization;
    self.usesThirdPartyCMMS = organization.third_party_cmms_reactive_wos;
    self.headerTitle = self.inspectionProgram.display_name;
    self.fabActions = getFabActions();

    self.selectedFilters = {
      iterations: [],
      buildings: [],
      statuses: [],
    };
    self.filters = null;

    self.displayLimit = 100;
    self.skip = 0;
    self.displayShowMore = false;

    self.floorNameMap = {};
    self.noteData = {};
    self.attachmentData = {};

    // Functions
    self.filtersUpdated = filtersUpdated;
    self.buildHyperLink = buildHyperLink;
    self.showMore = showMore;
    self.getChecklistDetailRoute = getChecklistDetailRoute;
    self.onClickChecklistRow = onClickChecklistRow;
    self.stopPropagation = stopPropagation;

    self.$onInit = function () {
      if (!self.inspectionProgram) {
        $log.error("inspectionProgram is required");
        return;
      }
      // Get the breadcrumbs set
      $scope.$emit(EVENT_INSPECTION_PROGRAM_DETAIL_NAVIGATE, {
        page: "ip-details",
      });

      fetchNotesData();
      fetchAttachmentsData();
    };

    /**
     * Get the checklists according to the selected filters
     * TODO: modify this to take into account clicking statuses on the chart to
     * filter even further
     * @param {boolean} showMore true if calling this from show more btn
     */
    function getFilteredStops(showMore) {
      // avoid the flicker when changing filters
      if (!showMore) {
        self.checklists = null;
      }
      var inspectionIds = [];
      var buildingIds = [];
      populateFilterIdArrays(inspectionIds, buildingIds);

      // If all of either filter is unselected, display nothing
      if (inspectionIds.length < 1 || buildingIds.length < 1) {
        self.checklists = [];
        self.displayShowMore = false;
        return;
      }

      // only increment this if loading more, otherwise clear it
      if (showMore) {
        self.skip += self.displayLimit;
      } else {
        self.skip = 0;
      }

      var params = {
        inspection: "$in," + inspectionIds.join(","),
        building: "$in," + buildingIds.join(","),
        limit: self.displayLimit,
        skip: self.skip,
      };

      var statuses = self.selectedFilters.statuses;
      if (statuses && statuses.length) {
        params.status = "$in," + statuses.join(",");
      }

      ChecklistService.get(self.organization._id, params).then(function (
        checklists
      ) {
        var floorNameRequests = [];
        // populate various fields used to display info
        for (var k = 0; k < checklists.length; k++) {
          var checklist = checklists[k];
          checklist.status = CHECKLIST_STATUS_VERBIAGE[checklist.status];
          checklist.reactiveWorkOrderText = getLinkedWorkOrderText(checklist);
          if (!self.usesThirdPartyCMMS) {
            checklist.reactiveWorkOrderStatus =
              getLinkedWorkOrderStatus(checklist);
          }
          checklist.modifiedDateText = getDateText(checklist);
          checklist.stopStatusIcon = getStatus(checklist);
          checklist.numAttachments = getNumAttachments(checklist);
          checklist.numNotes = getNumNotes(checklist);
          checklist.inspection.name = InspectionService.getInspectionName(
            checklist.inspection,
            self.inspectionProgram.interval
          );

          populateChecklistLocation(checklist, floorNameRequests);
        }

        // Hold off rendering the rows until the floor fetches resolve
        $q.all(floorNameRequests).then(function () {
          if (checklists.length === self.displayLimit) {
            self.displayShowMore = true;
          } else {
            // set it back in case we loaded all, then changed filters
            self.displayShowMore = false;
          }
          if (showMore) {
            self.checklists.push.apply(self.checklists, checklists);
          } else {
            self.checklists = checklists;
          }
        });
      });
    }

    function populateChecklistLocation(checklist, floorNameRequests) {
      // If checklist has a pin, add that pins floor to the map
      var pin = checklist.room || checklist.asset;
      if (pin) {
        checklist.locationText = checklist.room
          ? "Room: " + pin.name
          : "Asset: " + pin.name;
        if (pin.level) {
          checklist.pinFloor = pin.level;
          if (!(pin.level in self.floorNameMap)) {
            self.floorNameMap[pin.level] = null;
            floorNameRequests.push(addFloorNameToMap(pin.building, pin.level));
          }
        }
      } else if (checklist.level) {
        // Otherwise just populate the floor name
        checklist.locationText = "Floor: " + checklist.level.name;
      }
    }

    function addFloorNameToMap(building, levelId) {
      return FloorService.getById(building, levelId).then(function (floor) {
        self.floorNameMap[levelId] = floor.name;
      });
    }

    function getLinkedWorkOrderText(checklist) {
      var numTasks;
      if (self.usesThirdPartyCMMS) {
        numTasks = checklist.reactive_inspection_tasks_external_cmms
          ? checklist.reactive_inspection_tasks_external_cmms.length
          : 0;
        if (numTasks === 1) {
          return "View Linked Work Order";
        } else if (numTasks > 1) {
          return numTasks + " Linked Work Orders";
        } else {
          return "None";
        }
      } else {
        numTasks = checklist.reactive_inspection_tasks
          ? checklist.reactive_inspection_tasks.length
          : 0;

        if (numTasks === 1) {
          return checklist.reactive_inspection_tasks[0].display_number;
        } else if (numTasks > 1) {
          return numTasks + " Work Orders";
        } else {
          return "None";
        }
      }
    }

    function getLinkedWorkOrderStatus(checklist) {
      var numTasks = checklist.reactive_inspection_tasks
        ? checklist.reactive_inspection_tasks.length
        : 0;
      if (numTasks === 1) {
        return checklist.reactive_inspection_tasks[0].status_display;
      } else if (numTasks > 1) {
        return "See details";
      }
      return "";
    }

    function getDateText(checklist) {
      var lastModified = checklist.last_mod_date || checklist.completed_date;
      if (lastModified) {
        return moment(lastModified).format("MMM. D, YYYY [at]  h:mmA");
      }
      return "";
    }

    function getStatus(checklist) {
      switch (checklist.status) {
        case CHECKLIST_STATUS_VERBIAGE.PASS:
          return {
            icon: "check",
            class: "status-passed",
          };
        case CHECKLIST_STATUS_VERBIAGE.IN_PROGRESS:
          return {
            icon: "donut_large",
            class: "status-incomplete",
          };
        default:
          return {
            icon: "error_outline",
            class: "status-failed",
          };
      }
    }

    function getNumAttachments(checklist) {
      if (self.attachmentData) {
        return self.attachmentData[checklist._id] || 0;
      }
      return 0;
    }

    function getNumNotes(checklist) {
      if (self.noteData) {
        return self.noteData[checklist._id] || 0;
      }
      return 0;
    }

    function fetchAttachmentsData() {
      return AttachmentService.getStatsByOrg(self.organization._id, {
        entity_type: "checklist",
        unwind_field: "links",
        group_field: "links.entity_id",
        delete_date: "null",
      }).then(function (stats) {
        // Create a lookup map of attachment counts by checklist ID
        self.attachmentData = stats.reduce(function (map, stat) {
          map[stat._id] = stat.result;
          return map;
        }, {});
      });
    }

    function fetchNotesData() {
      return NoteService.getStatsByOrg(self.organization._id, {
        checklist: "$ne,null",
        group_field: "checklist",
      }).then(function (stats) {
        // Create a lookup map of note counts by checklist ID
        self.noteData = stats.reduce(function (map, stat) {
          map[stat._id] = stat.result;
          return map;
        }, {});
      });
    }

    function populateFilterIdArrays(inspectionIds, buildingIds) {
      for (var i = 0; i < self.selectedFilters.iterations.length; i++) {
        var inspectionId = self.selectedFilters.iterations[i]._id;
        if (inspectionId !== "all") {
          inspectionIds.push(inspectionId);
        }
      }
      for (var j = 0; j < self.selectedFilters.buildings.length; j++) {
        var buildingId = self.selectedFilters.buildings[j]._id;
        if (buildingId !== "all") {
          buildingIds.push(buildingId);
        }
      }
    }

    // ------------------------
    //   Utilities
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    function showMore() {
      getFilteredStops(true);
    }

    /**
     * so that the filter component can notify when it picks new filters
     */
    function filtersUpdated(filter, filters) {
      self.selectedFilters[filter] = filters;
      var params = {
        iterations: self.selectedFilters.iterations.map(function (iteration) {
          return iteration._id;
        }),
        buildings: self.selectedFilters.buildings.map(function (building) {
          return building._id;
        }),
      };
      self.filters = encodeURIComponent(JSON.stringify(params));
      debounceFetchStops();
    }

    /**
     * Build a clickable url from the stored link in reactive_inspection_tasks_external_cmms
     * @param {string} url
     */
    function buildHyperLink(url) {
      return url.startsWith("http") ? url : "http://" + url;
    }

    // ------------------------
    //   Private Functions
    // ------------------------
    function getFabActions() {
      return [
        {
          icon: "grid_on",
          label: "Export",
          action: exportInspectionProgram,
        },
      ];
    }

    function exportInspectionProgram() {
      var inspectionIds = [];
      var buildingIds = [];
      populateFilterIdArrays(inspectionIds, buildingIds);
      if (inspectionIds.length < 1 || buildingIds.length < 1) {
        ToastService.showError("No data to export based on current filters");
        return;
      }

      var params = {
        inspection: "$in," + inspectionIds.join(","),
        building: "$in," + buildingIds.join(","),
      };

      var statuses = self.selectedFilters.statuses;
      if (statuses && statuses.length) {
        params.status = "$in," + statuses.join(",");
      }

      // Checklist template Ids...
      var checklistTemplateIds = [];

      ChecklistService.getStats(self.inspectionProgram.organization, {
        inspection: "$in," + inspectionIds,
        group_field: "checklist_template",
      })
        .then(function (results) {
          for (var i = 0; i < results.length; i++) {
            checklistTemplateIds.push(results[i]._id);
          }
          /**
           * We'll always have the default template, so we can expect this to download every
           * time
           */
          ToastService.showSimple(
            "Starting download. Please be patient and do not refresh the page."
          );

          if (checklistTemplateIds.length === 1) {
            // Only one template, so only need one quick call
            params.checklist_template = checklistTemplateIds[0];
            return ChecklistService.exportChecklists(
              self.organization._id,
              angular.extend({}, params)
            );
          } else if (checklistTemplateIds.length > 1) {
            // lots of templates, we gotta chain these promises up
            var promises = [];

            for (var j = 0; j < checklistTemplateIds.length; j++) {
              params.checklist_template = checklistTemplateIds[j];
              promises.push(
                ChecklistService.exportChecklists(
                  self.organization._id,
                  angular.extend({}, params)
                )
              );
            }

            return $q.all(promises);
          }
        })
        .then(function () {
          ToastService.showSimple("Downloading complete!");
        })
        .catch(ToastService.showError);
    }

    function getChecklistDetailRoute(checklist) {
      return Router.href("app.inspectionProgram.checklistDetails", {
        inspectionProgramId: self.inspectionProgram._id,
        checklistId: checklist._id,
      });
    }

    function onClickChecklistRow($event, checklist) {
      var nativeEvent = $event && $event.originalEvent;
      var isControlClick =
        !!nativeEvent && (nativeEvent.ctrlKey || nativeEvent.metaKey);
      var url =
        EnvService.getCurrentBaseUrl() +
        self.getChecklistDetailRoute(checklist);
      // do the actual navigate here
      var target = isControlClick ? "_blank" : "_self";
      $window.open(url, target);
    }

    /**
     * This function is used to isolate anchor tags from a parent element that has ng-click defined.
     * When an anchor tag is the chiled of an element with ng-click defined, clicking the ancor tag triggers
     * the parent's ng-click and does not yeild the expected behavior from the anchor tag.
     * Placing an element with `ng-click=vm.stopPropagation($event)` between the parent and the anchor tag
     * results in the ancor tag working as expected.
     *
     * @param {*} $event
     */
    function stopPropagation($event) {
      $event.stopPropagation();
    }
  }
})();
