import * as Sniffr from "sniffr";
(function () {
  angular
    .module("akitabox.core.lib.sniffr", [])
    .factory("Sniffr", SniffrService);

  /* @ngInject */
  function SniffrService() {
    if (!Sniffr) throw new Error("Sniffr is not available");
    return new Sniffr().sniff();
  }
})();
