(function () {
  angular.module("akitabox.ui", [
    "ngMaterial",
    "akitabox.core",
    "akitabox.ui.components",
    "akitabox.ui.dialogs",
    "akitabox.ui.directives",
    "akitabox.ui.themes",
  ]);
})();
