(function () {
  angular.module("akitabox.core.filters", [
    "akitabox.core.filters.bytes",
    "akitabox.core.filters.minutesToHours",
    "akitabox.core.filters.stringSeparator",
    "akitabox.core.filters.tel",
    "akitabox.core.filters.trustAs",
    "akitabox.core.filters.urlSchemePrefix",
  ]);
})();
