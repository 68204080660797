(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.reschedule")
    .controller(
      "RescheduleWorkOrderDialogController",
      RescheduleWorkOrderDialogController
    );

  /* @ngInject */
  function RescheduleWorkOrderDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Libraries
    moment,
    // AkitaBox
    models,
    // Services
    TimeZoneService,
    ToastService,
    WorkOrderService
  ) {
    var self = this;

    var data = {};
    var invalid = true;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.saving = false;
    self.labels = [
      models.WORK_ORDER.FIELDS.START_DATE,
      models.WORK_ORDER.FIELDS.DUE_DATE,
    ];

    // Functions
    self.dateRange = getDateRange();
    self.cancel = $mdDialog.cancel;
    self.onDateChange = onDateChange;
    self.save = save;
    self.isDisabled = isDisabled;
    self.showErrors = showErrors;
    self.errorMessages = {};

    function onDateChange($event) {
      invalid = $event.invalid || !$event.newValue;
      var offset = moment().utcOffset();
      // get start dates for all selected WOs
      var startDates = this.workOrders.map(function (obj) {
        return moment(obj.start_date).utc().utcOffset(offset, true).toDate();
      });
      // get due dates for all selected WOs
      var dueDates = this.workOrders.map(function (obj) {
        return moment(obj.due_date).toDate();
      });
      if (this.workOrders.length === 1) {
        // require both start and due dates if only one WO is selected
        data.start_date = $event.newValue.start;
        data.due_date = $event.newValue.end;
        invalid = !data.start_date || !data.due_date;
      } else {
        // batch rescheduling work orders
        if (!invalid) {
          // clear error messages
          self.errorMessages.start = null;
          self.errorMessages.end = null;

          if ($event.newValue.start) {
            data.start_date = $event.newValue.start;
          } else {
            // delete so a null field isn't sent in the update body
            delete data.start_date;
          }
          if ($event.newValue.end) {
            data.due_date = $event.newValue.end;
          } else {
            // delete so a null field isn't sent in the update body
            delete data.due_date;
          }

          // max start date is due_date in the dialog, or earliest due_date from the selected work orders
          var maxStartDate =
            data.due_date || new Date(Math.min.apply(Math, dueDates));
          // min due date is the start_date in the dialog, or the latest start_date from the selected work orders
          var minDueDate =
            data.start_date || new Date(Math.max.apply(Math, startDates));

          if (data.start_date && data.start_date > maxStartDate) {
            self.errorMessages.start = {
              type: "start",
              message:
                "Start date cannot be after the earliest selected due date.",
            };
          }

          if (data.due_date && data.due_date < minDueDate) {
            self.errorMessages.end = {
              type: "max",
              message:
                "Due date cannot be earlier than the latest selected start date.",
            };
          }

          // invalid if neither field is filled out, or there is an error
          invalid =
            !(data.start_date || data.due_date) ||
            Boolean(self.errorMessages.start) ||
            Boolean(self.errorMessages.end);

          // show any new error messages
          showErrors();
        } else {
          self.errorMessages.start = null;
          self.errorMessages.end = null;
        }
      }
    }

    function save() {
      self.saving = true;
      //self.workOrders

      self.determinateValue = 0;
      var increment = (1 / self.workOrders.length) * 100;
      var promiseArray = [];

      for (var i = 0; i < self.workOrders.length; i++) {
        var workOrder = self.workOrders[i];
        var buildingId = getBuildingId(workOrder);

        promiseArray[i] = WorkOrderService.update(
          buildingId,
          workOrder._id,
          data
        ).then(function (rescheduledWorkOrder) {
          self.determinateValue += increment;
          return rescheduledWorkOrder;
        });
      }

      $q.all(promiseArray)
        .then(function (rescheduledWorkOrders) {
          if (rescheduledWorkOrders.length) {
            if (rescheduledWorkOrders.length === 1) {
              ToastService.showSimple("Successfully rescheduled work order");
            } else {
              ToastService.showSimple(
                "Successfully rescheduled " +
                  rescheduledWorkOrders.length +
                  " work orders"
              );
            }
          }
          // show toast if batch rescheduling
          $mdDialog.hide(rescheduledWorkOrders);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    function isDisabled() {
      return invalid;
    }

    function getBuildingId(workOrder) {
      return Object.prototype.hasOwnProperty.call(workOrder.building, "_id")
        ? workOrder.building._id
        : workOrder.building;
    }

    // set default start and end dates to the current dates if a single WO is selected
    function getDateRange() {
      if (self.workOrders.length === 1) {
        var start = moment(self.workOrders[0].start_date).toDate();
        var end = moment(self.workOrders[0].due_date).toDate();
        return (self.dateRange = {
          start: start,
          end: end,
        });
      } else {
        return (self.dateRange = {
          start: null,
          end: null,
        });
      }
    }

    function showErrors() {
      return self.errorMessages;
    }
  }
})();
