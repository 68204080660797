import { UploadCSV } from "./UploadCSV";
import { ShowResults } from "./ShowResults";
import { TrackProcess } from "./TrackProcess";
import { BulkAddStep } from "../BulkAddDialogTypes";

/**
 * Defines the steps (in order) the bulk add operation has
 */
export const BULK_ADD_STEPS: BulkAddStep[] = [
  {
    label: "Select your CSV",
    StepComponent: UploadCSV,
  },
  {
    label: "Processing",
    StepComponent: TrackProcess,
  },
  {
    label: "Results",
    dialogWidth: "xl",
    StepComponent: ShowResults,
  },
];
