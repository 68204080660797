import { ButtonView } from "@ckeditor/ckeditor5-ui";
import { Plugin } from "@ckeditor/ckeditor5-core";

export class FooterButton extends Plugin {
  init() {
    const editor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add("abxFooterButton", () => {
      // The button will be an instance of ButtonView.
      const button = new ButtonView();

      button.set({
        label: "Footer",
        withText: true,
      });

      button.on("execute", () => {
        const config = editor.config.get("abxHeaderAndFooter");
        if (config) {
          config.setShowFooterDialog(true);
        }
      });

      return button;
    });
  }
}
