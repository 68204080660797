(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.assetInput
   */
  angular
    .module("akitabox.ui.directives.assetInput", [
      "akitabox.constants",
      "akitabox.core.services.asset",
      "akitabox.core.services.pinValue",
      "akitabox.core.toast",
    ])
    .directive("abxAssetInput", AbxAssetInputDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.assetInput
   * @name AbxAssetInputDirective
   * @restrict E
   *
   * @description
   * `abx-asset-input` creates a dropdown menu to allow for selection of the assets
   *
   * @usage
   * <hljs lang="html">
   *   <abx-asset-input
   *      id="asset"
   *      name="asset"
   *      ng-model="vm.asset"
   *      abx-label="Asset"
   *      abx-building="vm.building"
   *      abx-floor="vm.floor"
   *      abx-room="vm.room">
   *   </abx-asset-input>
   * </hljs>
   *
   * @ngInject
   */
  function AbxAssetInputDirective($timeout, models) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/asset-input/asset-input.html",
      require: ["abxAssetInput", "ngModel"],
      controller: AbxAssetInputController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        label: "@?abxLabel",
        building: "=abxBuilding",
        floor: "=abxFloor",
        room: "=?abxRoom",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Attributes
      var noPlaceholder = angular.isDefined(attrs.abxNoPlaceholder);
      var placeholder = noPlaceholder
        ? vm.label
        : attrs.placeholder || "Enter a " + models.ASSET.SINGULAR;
      var originalPlaceholder = placeholder;

      // UI elements
      var $input;

      // Functions
      vm.setModelValue = setModelValue;

      init();

      /**
       * Initialize the element, verify building is provided,
       * watch properties, and add event listeners
       */
      function init() {
        attrs.$set("abx-custom-input", "");

        // Watch for model changes
        $scope.$watch(function () {
          return vm.ngModelCtrl.$modelValue;
        }, onExternalChange);

        // Watch dependency changes
        $scope.$watch("vm.building", onDependencyChange);
        $scope.$watch("vm.floor", function (newFloor, oldFloor) {
          onDependencyChange(newFloor, oldFloor, "level");
        });
        $scope.$watch("vm.room", function (newRoom, oldRoom) {
          onDependencyChange(newRoom, oldRoom, "room");
        });

        attachPropertyWatchers();

        // Wait for input be attached
        $timeout(function () {
          // Find the nested input element and handle events
          $input = angular.element($element.find("input")[0]);
          setPlaceholder(originalPlaceholder);
          $input.on("blur", onBlur);
        });
      }

      /**
       * Determines if model should be set to null. Clears the autocompletes matches
       * on floor change.
       *
       * @param {Object} newValue     New dependency value
       * @param {Object} oldValue     Old dependency value
       * @param {String} [type]       Type of dependency value that changed, e.g. 'level' or 'room'
       */
      function onDependencyChange(newValue, oldValue, type) {
        // If editable, set disabled and reset placeholder
        if ($input && !vm.readonly) {
          setDisabled();
          setPlaceholder(originalPlaceholder);
        }

        if (newValue === oldValue) return;

        var hasChanged = true;
        if (newValue && oldValue) {
          if (
            Object.prototype.hasOwnProperty.call(newValue, "_id") &&
            Object.prototype.hasOwnProperty.call(oldValue, "_id")
          ) {
            hasChanged = newValue._id !== oldValue._id;
          }
        }

        if (hasChanged) {
          if (vm.asset && type && newValue && newValue._id) {
            var isChild = false;
            // Find parent pin value
            for (var pinValue in vm.asset.values) {
              if (newValue._id === vm.asset.values[pinValue].value) {
                isChild = true;
                break;
              }
            }
            if (!isChild) {
              if (type === "level") clearMatches();
              clearModel();
              setReadonly(vm.readonly);
            }
          } else {
            if (type === "level") clearMatches();
            clearModel();
            setReadonly(vm.readonly);
          }
        }
      }

      /**
       * Handle external value changes
       *
       * @param  {*} value    New value
       * @return {*}          Parsed value
       */
      function onExternalChange(value) {
        vm.asset = value;
        return value;
      }

      /**
       * Clear the ngModel (asset)
       */
      function clearModel() {
        vm.asset = null;
        setModelValue(null);
      }

      /**
       * Attach watchers to element properties
       */
      function attachPropertyWatchers() {
        // Observe required attribute
        attrs.$observe("required", function (value) {
          vm.required = value;
        });
        // Readonly
        $scope.$watch(function () {
          return $element[0].attributes.readonly;
        }, setReadonly);
        // Disabled
        $scope.$watch(function () {
          var disabled = $element[0].attributes.disabled;
          return disabled ? disabled.value : null;
        }, setDisabled);
        // Placeholder
        $scope.$watch(function () {
          return $element.attr("placeholder");
        }, setPlaceholder);
      }

      /**
       * Set new model value
       *
       * @param {*} value     New model value
       */
      function setModelValue(value) {
        vm.ngModelCtrl.$setViewValue(value);
      }

      /**
       * Handle input blur events
       */
      function onBlur() {
        vm.ngModelCtrl.$setTouched();
      }

      /**
       * Set readonly
       *
       * @param {String} value    Attribute value
       */
      function setReadonly(value) {
        vm.readonly = Boolean(value);
        // Show / hide input
        if (vm.readonly && vm.asset) {
          hideInput();
        } else {
          showInput();
        }
      }

      /**
       * Set disabled, if building or floor doesn't exist value is ignored
       * and disabled is set to true
       *
       * @param {String} value    Attribute value
       */
      function setDisabled(value) {
        if (vm.building && vm.floor) {
          vm.disabled = angular.isEmpty(value) ? false : value !== "false";
        } else {
          vm.disabled = true;
          setPlaceholder();
        }
      }

      /**
       * Set input placeholder, if building or floor doesn't exist value is
       * ignored and the placeholder is set to indicate they are required
       *
       * @param {String} value    Attribute value
       */
      function setPlaceholder(value) {
        if ((vm.building && vm.floor) || vm.readonly) {
          placeholder = value;
        } else if (!vm.floor) {
          placeholder = "Floor required";
        } else if (!vm.building) {
          placeholder = "Building required";
        }
        if (noPlaceholder) {
          if (placeholder) vm.label = placeholder;
        } else {
          $element.attr("placeholder", placeholder);
          if ($input) $input.attr("placeholder", placeholder);
        }
      }

      /**
       * Show input element
       */
      function hideInput() {
        if ($input) $input.css("visibility", "hidden");
      }

      /**
       * Hide input element
       */
      function showInput() {
        if ($input) $input.css("visibility", "visible");
      }

      /**
       * Clear the autcompletes list of matches
       */
      function clearMatches() {
        vm.mdAutocompleteCtrl.matches.length = 0;
      }
    }
  }

  /* @ngInject */
  function AbxAssetInputController(
    // Angular
    $q,
    // Services
    AssetService,
    PinValueService,
    ToastService
  ) {
    var self = this;

    var allAssets = null; // All assets available for current floor/room
    var assetRequest = null;
    var lastFloorId = self.floor ? self.floor._id : null;
    var lastRoomId = self.room ? self.room._id : null;

    // Attributes
    self.label = self.label || "Asset";
    self.loading = false;
    self.query = null;
    self.disabled = false;
    self.readonly = true;
    self.required = false;

    // Functions
    self.querySearch = querySearch;
    self.selectedItemChange = selectedItemChange;

    /**
     * Search for buildling assets based on query, if query is blank
     * all assets will be returned. Will only query when necessary, i.e.
     * not when the floor or room hasn't changed since we last queried
     *
     * @param  {String} query   Search text
     *
     * @return {Array}          List of assets
     */
    function querySearch(query) {
      if (!self.building || !self.floor) return $q.resolve([]);

      self.query = query;

      if (!hasFloorChanged() && !hasRoomChanged()) {
        // Filter and return on all assets if we've already fetched them
        if (allAssets) {
          return setMatches(query);
        }

        // Use the outstanding request, if we have one
        if (self.loading) {
          return assetRequest;
        }
      }

      var params = {
        include_values: true,
      };

      if (self.room) {
        params.room = self.room._id;
        lastRoomId = self.room._id;
      } else {
        lastRoomId = null;
      }

      var floorId = self.floor ? self.floor._id : null;

      // Add floor to query params
      if (floorId) params.level = floorId;

      // Update previous floor
      lastFloorId = floorId;

      self.loading = true;
      assetRequest = AssetService.getAll(self.building._id, params)
        .then(function (assets) {
          if (hasFloorChanged() || hasRoomChanged()) {
            return querySearch(self.query);
          }
          allAssets = assets;
          return setMatches(self.query);
        })
        .catch(function (err) {
          ToastService.showError(err);
          return [];
        })
        .finally(function () {
          self.loading = false;
        });

      return assetRequest;
    }

    /**
     * Handle asset select
     *
     * @param  {Object} item    Selected item (asset)
     */
    function selectedItemChange(item) {
      if (!self.room && item && item.values) {
        // Set the directive's `room` dependency if the new asset has a room
        // associated with it
        AssetService.getRoom(self.building._id, item.values).then(function (
          room
        ) {
          self.room = room;
        });
      }
      self.setModelValue(item);
    }

    /**
     * Filter matches based on a query string
     *
     * @param  {String} query       Query string
     * @param  {Array}  matches     List of possible matches
     *
     * @return {Array}              Filtered list of matches
     */
    function filterQuery(query, matches) {
      if (angular.isEmpty(query)) query = ""; // Convert null query to blank string (will match everything)

      return matches.filter(function (asset) {
        var roomId = PinValueService.getRoomId(asset.values);
        var matchesRoom =
          roomId && self.room ? roomId === self.room._id : !self.room;
        var matchesQuery = asset.name.match(new RegExp(query, "i"));
        return matchesRoom && matchesQuery;
      });
    }

    /**
     * Sets and returns the autcomplete matches
     *
     * @param {String} query    Query search text
     *
     * @return {Array}          List of asset matches
     */
    function setMatches() {
      if (angular.isEmpty(self.query) && !self.room) {
        // Return all assets for current floor
        self.mdAutocompleteCtrl.matches = angular.copy(allAssets);
      } else {
        // Filter on current room and query
        self.mdAutocompleteCtrl.matches = filterQuery(self.query, allAssets);
      }
      return self.mdAutocompleteCtrl.matches;
    }

    /**
     * Determine if the floor has changed
     *
     * @return {Boolean}    True if changed, false if not
     */
    function hasFloorChanged() {
      return self.floor ? lastFloorId !== self.floor._id : lastFloorId;
    }

    function hasRoomChanged() {
      return self.room ? lastRoomId !== self.room._id : lastRoomId;
    }
  }
})();
