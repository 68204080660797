(function () {
  angular
    .module("akitabox.ui.dialogs.job.cancel")
    .factory("CancelJobDialog", CancelJobDialog);

  /* @ngInject */
  function CancelJobDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/job/cancel/cancel-job.dialog.html",
      controller: "CancelJobDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.jobId` is required
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error}               Promise that resolves with the canceled job
     */
    function show(options) {
      // Verify we have workOrderIDs and building locals
      if (!options || !options.locals || !options.locals.jobId) {
        $log.error("CancelJobDialog: you must pass in jobId via locals");
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
