import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FunctionComponent, useState } from "react";
import { ReportTemplateResponse } from "@akitabox/api-client";
import { api } from "../api";
import { useApiMutation } from "../hooks/useApiMutation";
import axios from "axios";

export type DeleteReportTemplateDialog = Omit<DialogProps, "onClose"> & {
  reportTemplate: ReportTemplateResponse;
  onClose?: (deleted: boolean) => void;
};

export const DeleteReportTemplateDialog: FunctionComponent<
  DeleteReportTemplateDialog
> = ({ reportTemplate, onClose, ...rest }) => {
  const [error, setError] = useState<Error | null>(null);

  const { trigger: deleteReportTemplate, isMutating: isDeleting } =
    useApiMutation(api.reportTemplates.delete);

  const onDeleteHandler = async () => {
    try {
      await deleteReportTemplate({
        args: {
          organizationId: reportTemplate.organization,
          reportTemplateId: reportTemplate._id,
        },
      });
      if (onClose) onClose(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(err);
      } else {
        setError(new Error("Unknown error while deleting report template"));
      }
    }
  };

  return (
    <Dialog fullWidth disableEscapeKeyDown {...rest}>
      <DialogTitle
        component={Box}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" color="inherit">
          Delete Report Template
        </Typography>
        <IconButton
          ariia-label="close"
          color="inherit"
          onClick={() => {
            if (onClose) onClose(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          css={(theme) => ({
            padding: theme.spacing(4),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <Typography>
            Are you sure you want to delete{" "}
            <span css={{ fontWeight: "bold" }}>{reportTemplate.name}</span>?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isDeleting}
          onClick={() => {
            if (onClose) onClose(false);
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          disabled={isDeleting}
          onClick={() => onDeleteHandler()}
        >
          {error ? "Try Again" : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
