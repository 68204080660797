(function () {
  /**
   * @ngdoc component
   * @name abxPinValueInputComponent
   *
   * @param {Boolean} [disabled=false] - Set to true to disable this input
   * @param {String} [floorId] - Floor ID to use when fetching rooms
   *     (required for room inputs only).
   * @param {Function} [onBlur] - To be invoked when this component's input
   *     field loses focus with new model value.
   * @param {Function} [onChange] - To be invoked when the component's input
   *     has its model changed
   * @param {Object} pin - The pin
   * @param {Object} pinValue - Pin value to be responsible for.
   * @param {Object} pinField - Pin field associated with the pin value.
   * @param {Boolean} [urlInspectionMode] - When true, enables button-style rendering of
   *     'url' type inputs.
   * @param {Boolean} [saveOnBlur] - Save on input blur. Defaults to falsey
   *     value.
   * @param {Boolean} [readOnly] - Property associated with displaying input values
   *    as readonly text
   *
   * @callback onBlur
   * @param {Object} $event
   * @param {*} $event.newValue - Current model value for the input.
   * @param {Boolean} $event.invalid - Signals that the value being sent up
   *     is considered invalid.
   *
   * @callback onChange
   * @param {Object} $event
   * @param {*} $event.model - Current model value for the input (after the
   *      change).
   * @param {*} [$event.value] - Current (display) value for the input (after
   *      the change).
   * @param {Boolean} $event.invalid - Signals that the new model value is
   *      considered invalid.
   *
   * @description
   * Input for a pin value.
   */
  angular
    .module("akitabox.ui.components.pinValueInput")
    .component("abxPinValueInputComponent", {
      bindings: {
        disabled: "<?abxDisabled",
        required: "<?abxRequired",
        buildingId: "<?abxBuildingId",
        floorId: "<?abxFloorId",
        onBlur: "&abxOnBlur",
        onChange: "&abxOnChange",
        pin: "<abxPin",
        pinValue: "<abxPinValue",
        pinField: "<abxPinField",
        urlInspectionMode: "<?abxUrlInspectionMode",
        saveOnBlur: "<?abxSaveOnBlur",
        readOnly: "<?abxReadOnly",
        editable: "<?abxEditable",
        allowUpload: "<?abxAllowUpload",
      },
      controller: AbxPinValueInputController,
      controllerAs: "vm",
      template: '<ng-include src="vm.templateUrl"></ng-include>',
    });

  function AbxPinValueInputController(
    $attrs,
    // Services
    PinValueService,
    Utils
  ) {
    var self = this;

    // Attributes
    self.loading = false;
    self.parsedValue = null;
    self.required = angular.isDefined($attrs.abxRequired)
      ? self.required
      : false;

    // Functions
    self.getParsedValue = getParsedValue;
    self.handleAtomicChange = handleAtomicChange;
    self.updateValue = updateValue;

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.pinField && self.pinField) {
        self.dataType = parseDataType(self.pinField.data_type);
        self.templateUrl = buildTemplateUrl();
      }

      if (
        changes.pin &&
        !Utils.isSameModel(changes.pin.previousValue, self.pin)
      ) {
        self.parsedValue = null;
      }

      if (haveNewValue()) {
        self.getParsedValue();
      }

      /**
       * Determine if the new pin value actually has a new value
       */
      function haveNewValue() {
        if (!changes.pinValue || !self.pinValue) return false;
        if (!changes.pinValue.previousValue) return true;

        return !angular.equals(
          self.pinValue.value,
          changes.pinValue.previousValue.value
        );
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Parse the pin value into human-usable content.
     */
    function getParsedValue() {
      self.loading = true;

      var buildingId = self.pin ? self.pin.building : self.pinValue.building;
      PinValueService.getByDataType(
        buildingId,
        self.pinField.data_type,
        self.pinValue
      )
        .then(function (parsedValue) {
          self.parsedValue = parsedValue;
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function updateValue(event) {
      // Undefined pin values need to be empty strings
      if (angular.isUndefined(event.newValue)) {
        event.newValue = "";
      }

      return self.onBlur({
        $event: { newValue: event.newValue },
      });
    }

    /**
     * Handle atomic changes from child components. These are changes where
     * the child component "change" and "blur" in the same event, so we have
     * to send up both at the same time (e.g. document lists).
     *
     * @param {Object} event
     * @param {*} event.newValue - New model value for the input
     */
    function handleAtomicChange(event) {
      self.onChange({ $event: { model: event.newValue, invalid: false } });
      return updateValue(event);
    }

    // =================
    // Private Functions
    // =================

    /**
     * Gets the template URL for the given pin field and its data type
     *
     * @param {String} pinField - Pin field associated with template to use
     * @return {String} - Template URL to use
     */
    function buildTemplateUrl() {
      // Data types that don't conform to our standard input component template
      // need their own special templates
      var specialDataTypes = ["document-array", "tag-filter", "tree"];
      var isSpecialDataType = specialDataTypes.indexOf(self.dataType) > -1;
      if (isSpecialDataType) {
        return (
          "app/core/ui/components/pin-value-input/templates/" +
          self.dataType +
          "-pin-value-input.html"
        );
      } else {
        return "app/core/ui/components/pin-value-input/pin-value-input.component.html";
      }
    }

    /**
     * Convert a given pin field data type into front end friendly aliases
     *
     * @param {String} dataType - Data type to parse
     * @return {String} - Parsed data type
     */
    function parseDataType(dataType) {
      dataType = dataType.replace(/_/g, "-");

      switch (dataType) {
        case "level":
          return "floor";
        default:
          return dataType;
      }
    }
  }
})();
