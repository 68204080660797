(function () {
  angular
    .module("akitabox.desktop.admin")
    .controller("AdminController", AdminController);

  /* @ngInject */
  function AdminController(
    // Services
    Router,
    OrganizationService,
    ShadowService
  ) {
    var self = this;

    self.onGroupSelect = onGroupSelect;
    self.onBuildingSelect = onBuildingSelect;

    /**
     * Handle group selection
     *
     * @param {Object} $event event object
     */
    function onGroupSelect($event) {
      OrganizationService.setCurrent($event.organization);
      // TODO: Implement Groups
      var group = $event.model;
      if (group && group.all) {
        Router.go("app.dashboard");
      }
    }

    /**
     * Handle building selection
     *
     * @param {Object} $event event object
     */
    function onBuildingSelect($event) {
      OrganizationService.setCurrent($event.organization);
      var building = $event.building || $event.model;
      if ($event.marker) {
        ShadowService.sendEvent("homepage", "map-nav-to-bldg");
      }
      Router.go("app.building.detail", {
        buildingId: building._id,
      });
    }
  }
})();
