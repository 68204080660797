(function () {
  /**
   * @ngdoc component
   * @name abxRoundStopListItem
   *
   * @param {Object} task   Round work order
   * @param {Object} floor  Current floor
   * @param {Object} pin    Selected pin
   *
   * @description
   * Stop List Item for Round task stop information
   */
  angular.module("akitabox.planView").component("abxRoundStopListItem", {
    require: {
      parentCtrl: "^^abxRoundStopList", // give us access to this parent controller on self
    },
    bindings: {
      stop: "<abxStop",
      pinType: "<abxPinType",
      pinFloor: "<abxPinFloor",
      listType: "<abxListType",
      expandedList: "<abxExpandedList",
      toggleContent: "<abxToggleContent",
      numAttachments: "<?abxNumAttachments",
      numNotes: "<?abxNumNotes",
      numWorkOrders: "<?abxNumWorkOrders",
      numExternalCMMSWorkOrders: "<?abxNumExternalCmmsWorkOrders",
      bulkEditModeEnabled: "<?abxBulkEditModeEnabled",
    },
    controller: AbxRoundStopListItemController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/round-stop-list-item/round-stop-list-item.component.html",
  });

  /** @ngInject */
  function AbxRoundStopListItemController(
    // Angular
    $element,
    $scope,
    $rootScope,
    $stateParams,
    $timeout,
    // AkitaBox
    models,
    // Constants
    EVENT_ROUND_TASK_STOP_SELECTED,
    EVENT_BULK_EDIT_STOP_SELECTED,
    EVENT_BULK_EDIT_TOGGLE_ALL,
    // Services
    FeatureFlagService,
    OrganizationService
  ) {
    var self = this;

    var COLOR_IN_PROGRESS = "#666666";

    // Attributes
    self.iconColor = COLOR_IN_PROGRESS;
    self.floorColor = COLOR_IN_PROGRESS;
    self.floor = null;
    self.pin = null;
    self.title = null;
    self.selected = false;
    self.classes = [];
    self.organization = OrganizationService.getCurrent();
    self.usesThirdPartyCMMS =
      self.organization.third_party_cmms_reactive_wos || false;
    self.bulkEditModeSelected = false;

    self.commentTooltipText = "";
    self.attachmentTooltipText = "";
    self.workOrderTooltipText = "";

    // Functions
    self.onClickHeader = onClickHeader;
    self.$onChanges = onChange;
    self.toggleBulkEditModeSelected = toggleBulkEditModeSelected;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      populateTooltips();
      $scope.$on(EVENT_ROUND_TASK_STOP_SELECTED, onStopSelected);
      $scope.$on(EVENT_BULK_EDIT_TOGGLE_ALL, onToggleAll);
      self.classes = setStatusClass([]);

      self.$header = $element[0].querySelector(".abx-stop-header");
      self.$content = $element[0].querySelector(".abx-stop-content");

      var selectedStopId = $stateParams.stop;
      var selectedStopLocationId =
        $stateParams.asset || $stateParams.room || $stateParams.level;

      let isStopSelected = false;
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        const location = self.stop.room || self.stop.asset || self.stop.level;
        isStopSelected =
          selectedStopLocationId && selectedStopLocationId === location._id;
      } else {
        isStopSelected = selectedStopId && selectedStopId === self.stop._id;
      }

      if (isStopSelected) {
        self.selected = true;
        // Make sure list is expanded
        if (!self.parentCtrl.expanded) {
          self.parentCtrl.toggleContent();
        }
        // Scroll item into view
        $timeout(function () {
          $element[0].scrollIntoView({
            behavior: "smooth",
          });
        }, 1000);
      }
    };

    // =================
    // Public Functions
    // =================

    function onChange(changes) {
      if (changes.stop && self.stop) {
        self.floor = self.stop.level;
        self.pin = self.stop.asset || self.stop.room;
        if (self.pin) {
          self.title = self.pin.name;
        } else if (self.floor) {
          self.title = self.floor.name;
        } else {
          self.title = "Unknown";
        }
      }
      if (changes.bulkEditModeEnabled) {
        self.bulkEditModeSelected = false;
      }
      if (
        changes.numExternalCMMSWorkOrders ||
        changes.numWorkOrders ||
        changes.numAttachments ||
        changes.numNotes
      ) {
        populateTooltips();
      }
      if (changes.toggleAll && !changes.toggleAll.isFirstChange()) {
        if (self.toggleAll) {
          self.bulkEditModeSelected = true;
        } else {
          self.bulkEditModeSelected = false;
        }
      }
    }

    /**
     * Click handler for the header element
     */
    function onClickHeader() {
      if (!self.selected) {
        selectStop();
      } else {
        deselectStop();
      }
    }

    function toggleBulkEditModeSelected($event) {
      $event.stopPropagation();
      self.bulkEditModeSelected = !self.bulkEditModeSelected;
      $rootScope.$broadcast(EVENT_BULK_EDIT_STOP_SELECTED, {
        checked: self.bulkEditModeSelected,
        stop: self.stop,
      });
      return self.bulkEditModeSelected;
    }

    // ===================
    // Private Functions
    // ===================

    function populateTooltips() {
      if (self.usesThirdPartyCMMS) {
        self.workOrderTooltipText =
          self.numExternalCMMSWorkOrders > 1
            ? self.numExternalCMMSWorkOrders + " external work orders"
            : self.numExternalCMMSWorkOrders + " external work order";
      } else {
        self.workOrderTooltipText =
          self.numWorkOrders > 1
            ? self.numWorkOrders + " work orders"
            : self.numWorkOrders + " work order";
      }

      self.commentTooltipText =
        self.numNotes > 1 ? self.numNotes + " notes" : self.numNotes + " note";

      self.attachmentTooltipText =
        self.numAttachments > 1
          ? self.numAttachments + " attachments"
          : self.numAttachments + " attachment";
    }

    /**
     * Used to fire on event when a stop is opened so that other stops
     * all close appropriately
     */
    function onStopSelected($event, data) {
      self.selected = data.stop && self.stop._id === data.stop._id;
    }

    function onToggleAll($event, data) {
      self.bulkEditModeSelected = data.checked;
    }

    function selectStop() {
      // emit event to navigate to pin on floor plan
      $rootScope.$broadcast(EVENT_ROUND_TASK_STOP_SELECTED, {
        stop: self.stop,
      });
      var currentFloorId = $stateParams.floorId;
      var pin = self.stop.asset || self.stop.room;
      var stopFloor = self.stop.level;
      // if floors are different, we'll do a page refresh so we want to prevent expansion until then
      if (pin) {
        const pinLevelId = FeatureFlagService.isEnabled("jit_checklists")
          ? pin.level
          : pin.level._id;
        self.selected =
          currentFloorId === pinLevelId || !pin.level || !pin.level.document;
      } else if (stopFloor) {
        self.selected = currentFloorId === stopFloor._id;
      }
    }

    function deselectStop() {
      self.selected = false;
      // emit event to navigate to pin on floor plan
      $rootScope.$broadcast(EVENT_ROUND_TASK_STOP_SELECTED, {
        stop: null,
      });
    }

    function setStatusClass(classes) {
      // Since classes can be an array of classes for x amount, we wnat to purge it of our
      // pass and fail before we add in the correct one
      var FAIL_CLASS = "fail";
      var PASS_CLASS = "pass";
      var SKIP_CLASS = "skip";
      var indexesToRemove = [];

      for (var i = 0; i < classes.length; i++) {
        var className = classes[i];
        if (
          className === PASS_CLASS ||
          className === FAIL_CLASS ||
          className === SKIP_CLASS
        )
          indexesToRemove.push(i);
      }

      while (indexesToRemove.length) {
        classes.splice(indexesToRemove.shift(), 1);
      }

      // Add the correct pass/fail class
      switch (self.stop.status) {
        case models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO:
        case models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO:
        case models.CHECKLIST.STATUSES.FAIL:
          classes.push(FAIL_CLASS);
          break;
        case models.CHECKLIST.STATUSES.PASS:
          classes.push(PASS_CLASS);
          break;
        case models.CHECKLIST.STATUSES.SKIP:
          classes.push(SKIP_CLASS);
          break;
        default:
          break;
      }

      return classes;
    }
  }
})();
