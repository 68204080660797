(function () {
  angular
    .module("akitabox.ui.dialogs.document.selectBuilding")
    .service("SelectBuildingForUploadDialog", SelectBuildingForUploadDialog);

  /* @ngInject */
  function SelectBuildingForUploadDialog($mdDialog, $log, $q, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/document/select-building/select-building.dialog.html",
      controller: "SelectBuildingForUploadDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param  {Object}                 options     Dialog options
     * @return {Promise<Object|Error}               Promise that resolves with the newly created building
     */
    function show(options) {
      if (!options || !options.locals || !options.locals.organization) {
        $log.error(
          "SelectBuildingForUploadDialog: you must pass in organization via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
