(function () {
  angular
    .module("akitabox.ui.components.lifeCycle", ["akitabox.core.lib.moment"])
    .component("abxLifeCycle", {
      bindings: {
        conditions: "<?abxConditions",
        installDate: "<?abxInstallDate",
        replacementCostLine: "<?abxReplacementCostLine",
        replacementYear: "<?abxReplacementYear",
        severityOfFailure: "<?abxSeverityOfFailure",
      },
      controller: AbxLifeCycleController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/life-cycle/life-cycle.component.html",
    });

  /* @ngInject */
  function AbxLifeCycleController(moment) {
    var self = this;

    // Attributes
    self.installationYear = null;
    self.replacementYear = null;
    self.originalReplacementYear = null;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onChanges = function (changes) {
      if (changes.installDate) {
        self.installationYear = self.installDate
          ? new Date(self.installDate).getFullYear()
          : null;
      }
      if (changes.replacementCostLine) {
        if (self.replacementCostLine) {
          if (self.replacementCostLine.date_last_performed) {
            var interval =
              self.replacementCostLine.user_interval ||
              self.replacementCostLine.suggested_interval;
            var lastPerformed = new Date(
              self.replacementCostLine.date_last_performed
            );
            self.originalReplacementYear = moment(lastPerformed)
              .add(interval, "years")
              .year();
          } else {
            self.originalReplacementYear = null;
          }
        }
      }
    };
  }
})();
