(function () {
  angular.module("akitabox.ui.dialogs.workOrderLog.createUpdate", [
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.toast",
    "akitabox.core.services.floor",
    "akitabox.core.services.shadow",
    "akitabox.core.services.organization",
    "akitabox.core.services.timeCode",
    "akitabox.core.services.trade",
    "akitabox.core.services.type",
    "akitabox.core.services.user",
    "akitabox.core.services.workOrder",
    "akitabox.ui.components.input",
    "angular.filter",
  ]);
})();
