import { Observer } from "./Observer";

/**
 * Simplified version of RxJS subjects/event emitters.
 */
export class Subject<T = void> {
  private observers: Observer<T>[] = [];

  /**
   * Subscribe to this subject to be notified when messages
   * arrive.
   */
  public subscribe(observerOrNext: Observer<T> | Observer<T>["next"]) {
    const observer =
      typeof observerOrNext === "function"
        ? { next: observerOrNext }
        : observerOrNext;
    this.handleSubscribe(observer);
    return () => this.unsubscribe(observer);
  }

  /**
   * Publish a message to this subject
   * @param message The message to publish
   */
  public next(message: T): void {
    for (const observer of this.observers) {
      observer.next(message);
    }
  }

  /**
   * Add an observer to the registry
   * @param observer The observer to add
   */
  protected handleSubscribe(observer: Observer<T>): void {
    if (this.observers.some((el) => el === observer)) {
      throw new Error("Duplicate observer");
    }
    this.observers.push(observer);
  }

  /**
   * Remove an observer from the registry
   * @param observer The observer to remove
   */
  protected unsubscribe(observer: Observer<T>): void {
    this.observers = this.observers.filter((el) => el !== observer);
  }
}
