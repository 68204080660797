(function () {
  angular
    .module("akitabox.ui.components.serviceRequestList", [
      "akitabox.ui.components.input",
      "akitabox.ui.components.serviceRequestList.serviceRequestQuickActions",
    ])
    .component("abxSrList", {
      bindings: {
        buildings: "<abxBuildings",
        serviceRequests: "<abxServiceRequests",
        showWOName: "<abxShowWoName",
        onServiceRequestUpdate: "&",
        onWorkOrderUpdate: "&",
      },
      controller: AbxServiceRequestListController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/service-request-list/service-request-list.component.html",
    });

  function AbxServiceRequestListController($mdMedia) {
    var self = this;

    self.quickActionSR = null;
    self.handleServiceRequestUpdate = handleServiceRequestUpdate;
    self.handleWorkOrderUpdate = handleWorkOrderUpdate;
    self.showQuickActions = showQuickActions;
    self.checkPermissionsForRequest = checkPermissionsForRequest;

    self.visibility = {
      multipleBuildings: false,
    };

    // Lifecycle
    self.$onInit = function () {
      // Update visibility
      self.visibility.multipleBuildings = self.buildings.length > 1;
    };

    /**
     * Checks the permissions for the user
     * If the user has no additional permissions besides default, return false, otherwise return true
     *
     * (I.E. If the current user can't create a task or deny the request we don't want to
     * show the quick-actions dropdown button)
     *
     * @param {*} Request - the service request to check permissions of
     * @returns {Boolean}
     */
    function checkPermissionsForRequest(request) {
      return (
        request.hasPermission.canCreate ||
        request.hasPermission.canDeny ||
        $mdMedia("xs") ||
        $mdMedia("sm")
      );
    }

    function handleServiceRequestUpdate(serviceRequest) {
      self.onServiceRequestUpdate({ serviceRequest: serviceRequest });
    }

    function handleWorkOrderUpdate(workOrder) {
      self.onWorkOrderUpdate({ workOrder: workOrder });
    }

    function showQuickActions(serviceRequest) {
      self.quickActionSR =
        serviceRequest === self.quickActionSR ? null : serviceRequest;
    }
  }
})();
