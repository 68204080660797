(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.srCompletedChart
   *
   * @param {Object} building - building to build the chart with
   */
  angular
    .module("akitabox.ui.components.reportingCharts.srCompletedChart", [
      "akitabox.constants",
      "akitabox.core.services.chart",
      "akitabox.core.services.request",
      "akitabox.ui.directives.stackChart",
    ])
    .component("abxSrCompletedChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
      },
      controller: AbxSrCompletedChartController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/sr-completed-chart/sr-completed-chart.component.html",
    });
  function AbxSrCompletedChartController(
    // Angular
    $log,
    $timeout,
    // Constants
    models,
    MINIMUM_RENDER_DELAY,
    // Services
    ChartService,
    ShadowService
  ) {
    var self = this;
    var config = {};
    var GA_CATEGORY = "reporting";

    // Attributes
    self.idTag = "sr-completed-chart";
    self.hoverLabel = "Service Requests";
    self.data = [];
    self.loading = true;
    self.legendValues = [];
    self.showChart = false;
    self.stackOptions = [];
    self.selectedStack = "---";
    self.highlightedStack;

    // Functions
    self.onStackChange = onStackChange;
    self.onStackHover = onStackHover;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxSrCompletedChart: abx-buildings is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        config = ChartService.getChartConfig(
          self.buildings,
          models.SERVICE_REQUEST.MODEL
        );
        self.stackOptions = ChartService.getStackOptions(
          models.SERVICE_REQUEST.MODEL,
          self.buildings.length > 1,
          ["Time Code"]
        );
      }
      if (changes.filters) {
        updateFilters();
      }
      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings
      ) {
        calculateStats();
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    function onStackHover($event) {
      self.highlightedStack = $event.value;
    }

    function onStackChange($event) {
      ShadowService.sendEvent(
        GA_CATEGORY,
        "switch-stack-by",
        self.idTag,
        $event.value
      );
      self.selectedStack = $event.model;
      updateFilters();
      calculateStats();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function setShowChart() {
      var options = {
        startDate: self.startDate,
        endDate: self.endDate,
      };
      self.showChart = ChartService.shouldShowChart(
        models.SERVICE_REQUEST.MODEL,
        ChartService.CHART_TYPE_BAR,
        self.data,
        options
      );
    }

    // Calculate the service request stats for the given date
    function calculateStats() {
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      )
        return;
      self.loading = true;
      self.showChart = false;

      var params = {
        group_field: "completed_date",
        group_time: ChartService.getGroupTime(self.startDate, self.endDate),
      };
      params = angular.extend(params, self.filters);

      if (config.buildingInString) params.buildings = config.buildingInString;

      var dateKeys = ["date", params.stack_field ? "results" : "result"];
      var dates = ChartService.buildDateArray(
        self.startDate,
        self.endDate,
        dateKeys
      );

      config
        .statsFunction(config.parentId, params)
        .then(function (results) {
          return ChartService.parseDateResults(results, dates);
        })
        .then(function (data) {
          self.legendValues = [];

          if (!params.stack_field) {
            self.data = data;
            return;
          }

          return ChartService.parseStackData(
            data,
            params.stack_field,
            params.stack_field === "building" ? config.buildingMap : null,
            null,
            self.buildings
          );
        })
        .then(function (stackData) {
          if (!stackData) {
            // no stack_field provided, self.data is already set above
            return;
          }
          self.legendValues = stackData.keys.sort(self.stackSort);
          self.data = stackData.data;
        })
        .then(function () {
          return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
        })
        .finally(function () {
          self.loading = false;
          setShowChart();
        });
    }

    function updateFilters() {
      self.filters = ChartService.updateStackFilter(
        self.selectedStack,
        self.filters
      );
    }
  }
})();
