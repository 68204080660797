(function () {
  /**
   * @component Displays a set of controls allowing users to associate round stops
   * with checklist templates.
   *
   * @param {string} roundTemplateId - The ID of the round template to use
   * @param {string} organizationId - The ID of the
   */
  angular
    .module(
      "akitabox.core.ui.dialogs.inspectionProgram.create.checklistTemplateOverrideSection"
    )
    .component("abxChecklistTemplateOverrideSection", {
      bindings: {
        buildingId: "<abxBuildingId",
        roundTemplateId: "<abxRoundTemplateId",
        defaultChecklistTemplate: "<abxDefaultChecklistTemplate",
        onOverridesChange: "&abxOnOverridesChange",
        overrides: "<abxOverrides",
        onLoadingComplete: "<?abxOnLoadingComplete",
      },
      controller: AbxChecklistTemplateOverrideSectionController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/dialogs/inspection-program/create/checklist-template-override-section/checklist-template-override-section.template.html",
    });

  /** @ngInject */
  function AbxChecklistTemplateOverrideSectionController(
    ChecklistTemplateService,
    OrganizationService,
    PinTypeService,
    RoundTemplateService,
    ToastService,
    OverrideList,
    $q
  ) {
    var self = this;

    // Fields
    /**
     * @type { [pinTypeId: string]: PinType }
     */
    self.pinTypes = {};

    /**
     * @type { OverrideList } The list for non-overridden stops
     */
    self.defaultList;

    /**
     * @type { OverrideList[] }
     * Each entry in this array denotes one bucket of stops and the associated checklist.
     */
    self.overrideLists = [];

    /**
     * List of checklist template enum options for checklist typeaheads
     */
    self.allChecklistTemplateEnumOptions = [];

    /**
     * @type { boolean }
     */
    self.enableAddButtons = false;

    var roundStopsPromise = $q.resolve([]);
    var checklistTemplatesPromise = $q.resolve([]);

    self.loading = false;

    // init
    fetchChecklistTemplates();
    if (self.buildingId) {
      fetchPinTypes();
    } else {
      self.pinTypes = {};
    }

    self.$onChanges = function $onChanges(changes) {
      /**
       * When the round template is changed, we refetch stops
       */
      if (changes.roundTemplateId || changes.buildingId) {
        self.loading = true;
        if (self.onLoadingComplete) {
          self.onLoadingComplete(false);
        }
        resetStops();

        if (self.roundTemplateId && self.buildingId) {
          fetchPinTypes();
          roundStopsPromise = RoundTemplateService.getStops(
            self.buildingId,
            self.roundTemplateId,
            {
              include_values: true,
            }
          )
            .then(function (stops) {
              resetStops(angular.copy(stops));
              self.loading = false;
              return stops;
            })
            .catch(ToastService.showError)
            .finally(function () {
              if (self.onLoadingComplete) {
                self.onLoadingComplete(true);
              }
            });
        } else {
          resetStops([]);
          self.loading = false;
          if (self.onLoadingComplete) {
            self.onLoadingComplete(true);
          }
        }
      }
      if (changes.overrides && self.overrides) {
        // we don't respect changes to overrides from above because there is
        // no use case currently and doing so breaks some behavior with selection
        if (changes.overrides.isFirstChange()) {
          var newOverrides = self.overrides;
          $q.all([roundStopsPromise, checklistTemplatesPromise]).then(function (
            results
          ) {
            var stops = results[0];
            self.overrideLists = createOverrideLists(newOverrides, stops);
            syncChecklistTemplateEnumOptions();
            notifyParentOverridesChanged();
          });
        }
      }
      /**
       * When the Checklist Template changes, resync enum options
       */
      if (changes.defaultChecklistTemplate) {
        syncChecklistTemplateEnumOptions();
      }
    };

    // Public Methods
    self.addOverrideList = function addOverrideList() {
      self.overrideLists.push(new OverrideList());
      syncChecklistTemplateEnumOptions();
      notifyParentOverridesChanged();
    };

    self.removeOverrideList = function removeOverrideList(list) {
      self.defaultList.addStops(list.stops);
      var index = self.overrideLists.indexOf(list);
      self.overrideLists.splice(index, 1);
      notifyParentOverridesChanged();
    };

    self.handleAddStopsClick = function handleAddStopsClick(overrideList) {
      self.enableAddButtons = false;
      var stops = self.defaultList.removeSelectedStops();
      overrideList.addStops(stops);
      notifyParentOverridesChanged();
    };

    self.handleRemoveStopClick = function handleRemoveStopClick(
      overrideList,
      stop
    ) {
      overrideList.removeStop(stop);
      // TODO: Depending upon how ordering is implemented (w.r.t. hierarchical list view)
      // might want to do something smarter than just putting removed stops at the bottom
      self.defaultList.addStops([stop]);
      notifyParentOverridesChanged();
    };

    self.handleChecklistTemplateChange = function handleChecklistTemplateChange(
      overrideList,
      $event
    ) {
      overrideList.checklistTemplate = $event.model;
      syncChecklistTemplateEnumOptions();
      notifyParentOverridesChanged();
    };

    function syncChecklistTemplateEnumOptions() {
      var enumOptions = angular.copy(self.allChecklistTemplateEnumOptions);

      var usedChecklistTemplates = {};
      self.overrideLists.forEach(function (overrideList) {
        if (overrideList.checklistTemplate) {
          usedChecklistTemplates[overrideList.checklistTemplate._id] = true;
        }
      });
      if (self.defaultChecklistTemplate) {
        usedChecklistTemplates[self.defaultChecklistTemplate._id] = true;
      }
      enumOptions = enumOptions.filter(function (enumOption) {
        var checklistTemplateId = enumOption.model._id;
        if (usedChecklistTemplates[checklistTemplateId]) {
          return false;
        } else {
          return true;
        }
      });

      self.overrideLists.forEach(function (list) {
        list.checklistTemplateEnumOptions = angular.copy(enumOptions);
      });
    }

    self.handleSelectedStopsChange = function handleSelectedStopsChange(event) {
      var selectedStops = event.model;
      self.defaultList.selectedStops = selectedStops;
      var hasStopsSelected = false;
      var stopIds = Object.keys(selectedStops);
      for (var i = 0; i < stopIds.length; i++) {
        var stopId = stopIds[i];
        if (selectedStops[stopId]) {
          hasStopsSelected = true;
          break;
        }
      }
      self.enableAddButtons = hasStopsSelected;
    };

    // Private Functions
    function notifyParentOverridesChanged() {
      self.onOverridesChange({
        $event: { model: angular.copy(self.overrideLists) },
      });
    }

    function createOverrideLists(stopChecklistTemplateOverrides, stops) {
      var lists = {};
      var stopsMap = stops.reduce(function (map, stop) {
        var location = RoundTemplateService.getStopLocation(stop);
        map[location._id] = stop;
        return map;
      }, {});

      var checklistTemplateIds = Object.keys(stopChecklistTemplateOverrides);
      checklistTemplateIds.forEach(function (checklistTemplateId) {
        var overrideList = new OverrideList();
        var overrides = stopChecklistTemplateOverrides[checklistTemplateId];

        var relevantStops = overrides.map(function (override) {
          var locationId = RoundTemplateService.getStopLocation(override);
          return stopsMap[locationId];
        });
        overrideList.setInitialStops(relevantStops);
        relevantStops.forEach(function (stop) {
          self.defaultList.removeStop(stop);
        });
        lists[checklistTemplateId] = overrideList;
      });

      var result = [];

      for (var checklistTemplateId in lists) {
        var overrideList = lists[checklistTemplateId];
        var checklistTemplate = self.allChecklistTemplateEnumOptions.find(
          function (enumOption) {
            return enumOption.model._id === checklistTemplateId;
          }
        );
        overrideList.checklistTemplate = checklistTemplate
          ? checklistTemplate.model
          : null;
        result.push(overrideList);
      }
      return result;
    }

    function fetchPinTypes() {
      PinTypeService.getAll(self.buildingId, {}, { cache: false })
        .then(function (pinTypes) {
          self.pinTypes = {};
          pinTypes.forEach(function (pinType) {
            self.pinTypes[pinType._id] = pinType;
          });
        })
        .catch(ToastService.showError);
    }

    function fetchChecklistTemplates() {
      var orgId = OrganizationService.getCurrent()._id;

      return (checklistTemplatesPromise = ChecklistTemplateService.getAll(
        orgId,
        {
          status: "active",
        }
      )
        .then(function (checklists) {
          var checklistEnumOptions = checklists.map(function (checklist) {
            return { model: checklist, value: checklist.name };
          });
          self.allChecklistTemplateEnumOptions = checklistEnumOptions;

          syncChecklistTemplateEnumOptions();
        })
        .catch(ToastService.showError));
    }

    /**
     * Reset the state of the component and set the default stops to a new list.
     * Clears all override listss
     * @param {RoundTemplateStop[]} stops The stops to reset to initial state with
     */
    function resetStops(stops) {
      self.overrideLists = [];
      self.defaultList = new OverrideList(stops);
      self.selectedStops = {};
      syncChecklistTemplateEnumOptions();
    }
  }
})();
