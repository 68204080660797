(function () {
  /**
   * @ngdoc component
   * @name abxFloorCard
   *
   * @param {Object} floor - Floor to display in the floor card
   * @param {Function} onSelect - To be invoked when a user has selected floor.
   *     If a floor does not have a floor plan, this will not be invoked until
   *     the user assigns one (through a dialog shown by this component).
   * @param {Object} building - Building for the floor responsible for.
   *     Necessary for the dialog that will be shown to the user to assign a
   *     floor plan to a floor.
   * @param {Object} [activeFloor] - The floor of the card that should be highlighted
   *
   * @description
   * A component to display a single floor in a card style
   */
  angular.module("akitabox.ui.components.floorCard").component("abxFloorCard", {
    bindings: {
      building: "<abxBuilding",
      floor: "<abxFloor",
      activeFloor: "<?abxActiveFloor",
      onSelect: "&abxOnSelect",
    },
    controller: AbxFloorCardController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/floor-card/floor-card.component.html",
  });

  function AbxFloorCardController(
    // Angular
    $q,
    $rootScope,
    // Constants
    EVENT_FLOOR_ROTATE,
    // Dialogs
    SelectDocumentDialog,
    // Services
    FeatureFlagService,
    FloorService,
    ToastService,
    TagCategoryService
  ) {
    var self = this;

    // Attributes
    self.loading = false;

    // Functions
    self.handleClick = handleClick;
    self.openInNewTab = openInNewTab;

    // ------------------------
    //   Lifecycle
    // ------------------------

    self.$onInit = function () {
      // Attributes
      self.MAX_IMAGE_HEIGHT = 128;
      self.MAX_IMAGE_WIDTH = 200;

      self.imageAngle = 0;
      self.imageStyle = {};
      self.placeholderDocument = {
        is_supported: true,
      };
    };

    self.$onChanges = function (changes) {
      if (changes.floor) {
        if (self.floor && self.floor.document) {
          loadAndRenderImage();
        } else {
          self.image = null;
        }
      }
    };

    // =================
    // Public Functions
    // =================

    function openInNewTab() {
      self.handleClick({ metaKey: true });
    }

    /**
     * Handle clicks to the entire component. If the floor doesn't have a floor
     * plan, show a dialog to allow the user to assign one (then trigger the
     * event for selecting this floor).
     */
    function handleClick($event) {
      var hasFloorPlan = Boolean(self.floor.document);
      if (hasFloorPlan) {
        self.onSelect({
          $event: { floor: self.floor, newTab: !!$event.metaKey },
        });
      } else {
        var dialogOptions = {
          locals: {
            building: self.building,
            title: "Edit Floor Plan",
            followLink: false,
          },
        };
        TagCategoryService.getAll(self.building._id)
          .then(function (tagCategories) {
            dialogOptions.locals.tagCategories = tagCategories;
            return SelectDocumentDialog.show(dialogOptions);
          })
          .then(function (document) {
            return FloorService.updateDocument(
              self.building._id,
              self.floor._id,
              document._id
            );
          })
          .then(function (updatedFloor) {
            self.floor = updatedFloor;
            loadAndRenderImage();
            self.onSelect({
              $event: { floor: self.floor, newTab: !!$event.metaKey },
            });
          })
          .catch(ToastService.showError);
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * Load the image for the floor plan. Not responsible for rendering it.
     * Should only be called when the given floor has a floor plan to be loaded.
     * @return {Promise<Object>} - Resolves with HTMLImageElement for loaded image
     */
    function loadImage() {
      return $q(function (resolve, reject) {
        self.image = new Image();

        self.image.onload = function () {
          return resolve(this);
        };
        self.image.onerror = function (err) {
          return reject(err);
        };

        self.image.src = self.floor.document.public_thumbnail_url_medium;
      });
    }

    /**
     * Load the image, make any necessary transformations, and render it.
     *
     * @return {Promise} - Resolves when image has finished being rendered
     */
    function loadAndRenderImage() {
      self.loading = true;
      return loadImage()
        .then(function (image) {
          resizeImage(image);
        })
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Resize the image to fit within the max bounds
     * @param {Object}  image   The image to resize
     */
    function resizeImage(image) {
      if (
        image.height > self.MAX_IMAGE_HEIGHT ||
        image.width > self.MAX_IMAGE_WIDTH
      ) {
        image.height = image.height * 0.95;
        image.width = image.width * 0.95;
        return resizeImage(image);
      }
      self.imageStyle.height = image.height + "px";
      self.imageStyle.width = image.width + "px";
    }
  }
})();
