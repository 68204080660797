(function () {
  angular
    .module("akitabox.ui.directives.animateChange", [])
    .directive("abxAnimateChange", AbxAnimateChangeDirective);

  /* @ngInject */
  function AbxAnimateChangeDirective($timeout) {
    return {
      restrict: "A",
      link: postLink,
    };

    function postLink($scope, $element, attrs) {
      var DURATION = 200;
      var SUPPORTED_ANIMATIONS = ["fade"];

      // Parse animation class name
      var isSupported = SUPPORTED_ANIMATIONS.indexOf(attrs.abxAnimation) > -1;
      var className =
        "abx-animate-" +
        (isSupported ? attrs.abxAnimation : SUPPORTED_ANIMATIONS[0]);
      var activeClassName = className + "-active";

      $element.addClass(className);

      // Watch attribute for changes
      $scope.$watch(attrs.abxAnimateChange, function (newValue, oldValue) {
        if (newValue === oldValue) return;
        $element.addClass(activeClassName);
        $timeout(function () {
          $element.removeClass(activeClassName);
        }, DURATION);
      });
    }
  }
})();
