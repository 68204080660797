(function () {
  angular.module("akitabox.desktop.directives.details", [
    "akitabox.desktop.directives.details.asset",
    "akitabox.desktop.directives.details.qrcode",
    "akitabox.desktop.directives.details.floor",
    "akitabox.desktop.directives.details.schedule",
    "akitabox.desktop.directives.details.room",
    "akitabox.desktop.directives.details.serviceRequest",
    "akitabox.desktop.directives.details.workOrder",
  ]);
})();
