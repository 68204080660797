import {
  attachmentDialogOpened,
  attachmentDialogClosed,
} from "../../../../react/attachment-dialog/AttachmentDialogService";
(function () {
  /**
   * @ngdoc module
   * @name akitbox.ui.directives.attachmentContainer
   */
  angular
    .module("akitabox.ui.directives.attachmentContainer", [
      "akitabox.core.constants",
      "akitabox.core.services.identity",
      "akitabox.core.services.attachment",
      "akitabox.core.services.organization",
      "akitabox.core.services.user",
      "akitabox.core.toast",
      "akitabox.ui.directives.attachmentThumbnail",
      "akitabox.ui.directives.attachmentUpload",
    ])
    .component("abxAttachmentContainer", {
      bindings: {
        entityType: "<abxEntityType",
        entityId: "<?abxEntityId",
        entity: "<?abxEntity",
        hideExistingAttachments: "<abxHideExistingAttachments",
        buildingId: "<abxBuildingId",
        emptyText: "@abxNoAttachment",
        label: "@abxLabel",
        /**
         * @type { boolean }
         * @default false
         * If true, the label will not be transformed to all uppercase.
         */
        preserveLabelCase: "<?abxPreserveLabelCase",
        analyticsCategory: "@?abxAnalyticsCategory",
        uploadable: "<abxUploadable",
        removable: "<abxRemovable",
        overwriteClick: "<abxOverwriteClick",
        removeLink: "<abxRemoveLink",
        onFilesChange: "<?abxOnFilesChange",
        onUploadComplete: "&?abxOnUploadComplete",
        onAllUploadsAttempted: "<?abxOnAllUploadsAttempted",
        onUploadStart: "<?abxOnUploadStart",
        onRemoveAttachment: "&?abxOnRemoveAttachment",
        onAttachmentClick: "&?abxOnAttachmentClick",
        onRemoveUpload: "&?abxOnRemoveUpload",
        allowedFileTypes: "<?abxAllowedFileTypes",
      },
      controller: AbxAttachmentContainerController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/directives/attachment-container/attachment-container.html",
    });

  var instanceNumber = 0;

  /* @ngInject */
  function AbxAttachmentContainerController(
    // Angular
    $scope,
    $q,
    // Constants
    models,
    // Events
    EVENT_LOG_WORK,
    // Services
    $mdDialog,
    AttachmentService,
    EnvService,
    FeatureFlagService,
    OrganizationService,
    ShadowService,
    ToastService,
    UserService,
    Utils
  ) {
    var self = this;
    var DOCUMENT_UPLOAD_LIMIT = 100;
    var GA_CATEGORY = self.analyticsCategory;
    var GA_ATTACHMENT_ACTION = "attachments-add";
    var _permissions = UserService.getPermissions();

    var canAddAttachments = Boolean(
      _permissions[self.entityType].add_attachment
    );

    self.organization = OrganizationService.getCurrent();
    self.addButtonLabel = "ADD";
    self.filePreviewDocumentOrder = [];

    if (self.entityType === "checklist" && canAddAttachments) {
      var pinPermission = self.entity.asset
        ? _permissions.asset.add_attachment
        : _permissions.room.add_attachment;
      canAddAttachments = Boolean(
        _permissions.task.add_attachment && pinPermission
      );
    }

    // Uniquely identify instances so that labels do not select each other
    self.instanceNumber = instanceNumber++;

    // Attachments are the files from API
    self.attachments = [];
    self.newAttachments = [];

    // Files is a list of files that have yet to be attached
    self.files = [];
    self.limit = DOCUMENT_UPLOAD_LIMIT;
    self.uploadable =
      canAddAttachments &&
      (typeof self.uploadable === "boolean" ? self.uploadable : false);
    self.removable =
      typeof self.removable === "boolean" ? self.removable : false;
    self.overwriteClick =
      typeof self.overwriteClick === "boolean" ? self.overwriteClick : false;

    var labelText = "Photos & Files";
    self.formattedLabelText = self.preserveLabelCase
      ? labelText
      : labelText.toUpperCase();

    let remainingRequests = 0;

    // Functions
    self.getLimit = getLimit;
    self.onAttachmentRemove = onAttachmentRemove;
    self.onDocumentUploadComplete = onDocumentUploadComplete;
    self.onUpload = onUpload;
    self.onUploadRemove = onUploadRemove;
    self.loadAttachmentsComplete = false;

    self.$onInit = function () {
      if (angular.isArray(self.allowedFileTypes)) {
        self.allowedFileTypes = self.allowedFileTypes.join(",");
      } else if (typeof self.allowedFileTypes !== "string") {
        self.allowedFileTypes = "*";
      }
      if (self.onFilesChange && typeof self.onFilesChange === "function") {
        registerFileWatcher();
      }
      if (!self.entityId) {
        self.loadAttachmentsComplete = true;
      } else {
        $scope.$on("attachments:update", function (event, data) {
          if (data.entityId === self.entityId) {
            fetchAttachments();
          }
        });

        if (self.entityType === "task") {
          $scope.$on(EVENT_LOG_WORK, function (event, eventBody) {
            if (
              eventBody &&
              (eventBody.task === self.entityId || !eventBody.task)
            ) {
              fetchAttachments();
            }
          });
        }
      }
      registerFileOrderWatcher();
    };

    self.$onChanges = function (changes) {
      var bldgChanged = Utils.hasChanged(changes.buildingId);
      var entChanged = Utils.hasChanged(changes.entityId);

      if (bldgChanged) {
        self.files = [];
      }
      if (entChanged && self.entityId) {
        fetchAttachments();
      }
    };

    self.handleAddClick = function handleAddClick() {
      const {
        organization: { _id: organization },
        buildingId: building,
        entityId,
        entityType,
      } = self;
      attachmentDialogOpened.next({
        organization,
        building,
        entityIds: entityId ? [entityId] : undefined,
        entityType,
      });

      const unsubscribe = attachmentDialogClosed.subscribe(({ uploads }) => {
        try {
          if (!self.entityId) {
            $scope.$apply(() => {
              self.files = [
                ...self.files,
                // massage the uploaded documents from the attachment dialog
                // into the format expected by this and child components
                ...uploads.map(({ file, document }) => ({
                  file,
                  document: $q.resolve(document),
                  // this field is usually added by the upload section, and is used in child components
                  _document: document,
                  complete: true,
                  error: null,
                })),
              ];
            });
          } else {
            $scope.$apply(() => fetchAttachments());
          }
        } finally {
          unsubscribe();
        }
      });
    };

    function fetchAttachments() {
      // fetch for the attachments, populate the self.attachments
      var params;
      if (!self.hideExistingAttachments) {
        params = {
          entity_id: self.entityId,
          entity_type: self.entityType,
        };
      } else {
        params = {
          _id:
            "$in," +
            self.newAttachments
              .map(function (a) {
                return a._id.toString();
              })
              .join(","),
        };
      }

      AttachmentService.getAll(self.buildingId, params)
        .then(function (attachments) {
          // Filter out annotated attachments
          self.attachments = attachments.filter((attachment) => {
            const document = attachment.document;
            return document && !document.document_annotated;
          });
          setLabelText();
          self.files = [];
          self.loadAttachmentsComplete = true;
        })
        .catch(function (error) {
          ToastService.showError(error);
        });
    }

    function onUpload(newFilesList) {
      self.files = self.files.concat(newFilesList);
      if (self.onUploadStart) {
        self.onUploadStart();
      }
      remainingRequests = newFilesList.length;

      // trigger Google Analytics event if uploading attachments from a work order
      if (self.analyticsCategory) {
        ShadowService.sendEvent(
          GA_CATEGORY,
          GA_ATTACHMENT_ACTION,
          "",
          newFilesList.length
        );
      }
    }

    function onUploadRemove(index) {
      if (!self.removable) {
        return;
      }

      var file = self.files[index];

      if (!file) {
        return ToastService.showError(
          new Error("Cannot remove non-existent file")
        );
      }

      self.files.splice(index, 1);

      setLabelText();

      if (self.entityId) {
        file.document.then(function (document) {
          removeAttachment(document.attachment)
            .catch(ToastService.showError)
            .finally(function () {
              if (self.onRemoveUpload) {
                self.onRemoveUpload({ file: file });
              }
            });
        });
      } else if (self.onRemoveUpload) {
        self.onRemoveUpload(file);
      }
    }

    function onAttachmentRemove(index) {
      if (!self.removable) {
        return;
      }

      if (EnvService.getBrowser().mobile) {
        var confirm = $mdDialog
          .confirm()
          .title("Are you sure?")
          .textContent("Are you sure you want to remove this attachment?")
          .ok("Remove");

        $mdDialog.show(confirm).then(remove);
      } else {
        remove();
      }

      function remove() {
        // Check for existance
        var attachment = self.attachments[index];

        if (!attachment) {
          // invalid index
          return ToastService.showError(
            new Error("Cannot remove non-existent attachment")
          );
        }

        // Remove Localy
        self.attachments.splice(index, 1);
        self.newAttachments = self.newAttachments.filter(function (a) {
          return a._id !== attachment._id;
        });

        // Remove Remotely
        removeAttachment(attachment)
          .catch(ToastService.showError)
          .finally(function () {
            /**
             * TODO: MY - 8/1/18 - Requires thourough testing when
             *  actually going to be implemented
             *
             * Possible Issue to test:
             *  Once it's a valid remove, we probably want to disable
             *  any other removes that could happen concurrently?
             *  if they remove files super fast, we do end up fetching
             *  Attachments a bunch of times
             */
            fetchAttachments();
            if (self.onRemoveAttachment) {
              self.onRemoveAttachment({ attachment: attachment });
            }
          });
      }
    }

    /**
     * After a document is done uploading, we create the attachment
     * from the between it and the entity_id
     *
     * @param {Document} document
     */
    function onDocumentUploadComplete(document) {
      if (self.entityId) {
        // do the attaching here
        var data = {
          document: document._id,
        };
        if (self.entityType === "checklist") {
          data.links = [
            {
              entity_id: self.entityId,
              entity_type: self.entityType,
            },
            {
              entity_id: self.entity.task,
              entity_type: "task",
            },
          ];

          if (self.entity.level) {
            data.links.push({
              entity_id: self.entity.level._id,
              entity_type: "level",
            });
          } else if (self.entity.room) {
            data.links.push({
              entity_id: self.entity.room._id,
              entity_type: "room",
            });
          } else if (self.entity.asset) {
            data.links.push({
              entity_id: self.entity.asset._id,
              entity_type: "asset",
            });
          }
        } else {
          data.links = [
            {
              entity_id: self.entityId,
              entity_type: self.entityType,
            },
          ];
        }
        AttachmentService.create(self.buildingId, data)
          .then(function (attachment) {
            document.attachment = attachment;
            self.newAttachments.push(attachment);
            var numAttachments = self.attachments.length + self.files.length;
            var labelText = self.pinAttachmentFlag
              ? "Photos & Files (" + numAttachments + ")"
              : self.label;
            self.formattedLabelText = self.preserveLabelCase
              ? labelText
              : labelText.toUpperCase();
            if (self.onUploadComplete) {
              self.onUploadComplete(document);
            }
            decrementValidateRemainingRequests();
          })
          .catch(function (error) {
            decrementValidateRemainingRequests();
            ToastService.showError(error);
          });
      }
    }

    function decrementValidateRemainingRequests() {
      remainingRequests--;
      if (remainingRequests < 1 && self.onAllUploadsAttempted) {
        self.onAllUploadsAttempted();
      }
    }

    function getLimit() {
      return (
        DOCUMENT_UPLOAD_LIMIT - (self.files.length + self.attachments.length)
      );
    }

    function registerFileWatcher() {
      $scope.$watchCollection("vm.files", function (newValue, oldValue) {
        if (newValue !== oldValue) {
          self.onFilesChange(self.files);
        }
      });
    }

    function registerFileOrderWatcher() {
      $scope.$watchCollection("vm.files", function (newValue, oldValue) {
        if (newValue !== oldValue) {
          const documentPromisees = self.files.map((file) => file.document);
          $q.all(documentPromisees).then((documents) => {
            const fileDocumentIds = documents.map((document) => document._id);
            const attachmentDocumentIds = self.attachments.map(
              (attachment) => attachment.document._id
            );

            self.filePreviewDocumentOrder = [];
            fileDocumentIds.forEach((documentId) => {
              self.filePreviewDocumentOrder.push(documentId);
            });
            attachmentDocumentIds.forEach((documentId) => {
              self.filePreviewDocumentOrder.push(documentId);
            });
          });
        }
      });
    }

    /**
     * Depending on this directives configuration, remove the attachment
     * link to the entity or remove the entire attachment
     *
     * @param {Attachment} attachment Attachment to remove or unlink
     */
    function removeAttachment(attachment) {
      if (!self.removable || !attachment) return;
      if (self.removeLink) {
        var update = {
          action: "removeLink",
          entity_id: self.entityId,
        };
        return AttachmentService.update(
          self.buildingId,
          attachment._id,
          update
        );
      }

      setLabelText();

      return AttachmentService.remove(self.buildingId, attachment._id);
    }

    function setLabelText() {
      var numAttachments = self.attachments.length + self.files.length;
      var labelText = self.pinAttachmentFlag
        ? "Photos & Files (" + numAttachments + ")"
        : self.label;
      self.formattedLabelText = self.preserveLabelCase
        ? labelText
        : labelText.toUpperCase() + " (" + numAttachments + ")";
    }
  }
})();
