import { Skeleton, SkeletonProps } from "@mui/material";
import { FC, ImgHTMLAttributes, useEffect, useRef, useState } from "react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { preloadImage } from "../../utils/preloadImage";

export interface ImageSkeletonProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "onError"> {
  onError: (err: Error, src: string) => void;
  SkeletonProps?: EmotionJSX.LibraryManagedAttributes<
    typeof Skeleton,
    SkeletonProps
  >;
  "data-testid"?: string;
}

/**
 * Image component that displays a MUI {@link Skeleton} while the image is
 * loading.
 */
export const ImageSkeleton: FC<ImageSkeletonProps> = ({
  src,
  SkeletonProps = {},
  className,
  onError,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onErrorRef.current = onError;
  }, [onError]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setIsLoading(true);
      if (!src) {
        return;
      }

      try {
        await preloadImage(src);
      } catch (e) {
        onErrorRef.current(e instanceof Error ? e : new Error(String(e)), src);
        return;
      }

      if (isCancelled) {
        return;
      }
      setIsLoading(false);
    })();

    return () => {
      isCancelled = true;
    };
  }, [src]);

  return isLoading ? (
    <Skeleton
      data-testid={ImageSkeletonTestIds.skeleton}
      variant="rounded"
      className={className}
      {...SkeletonProps}
    />
  ) : (
    <img
      data-testid={ImageSkeletonTestIds.image}
      src={src}
      className={className}
      {...props}
    />
  );
};

export const ImageSkeletonTestIds = {
  image: "ImageSkeleton.img",
  skeleton: "ImageSkeleton.skeleton",
};
