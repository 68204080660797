(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.file
   */
  angular
    .module("akitabox.core.services.file", [
      "angularFileUpload",
      "ngFileSaver",
      "akitabox.core.services.env",
      "akitabox.core.services.token",
    ])
    .factory("FileService", FileService);

  /**
   * @ngdoc factory
   * @name akitabox.core.services.file:FileService
   *
   * @description
   * This service that handles file download and creation of a file uploader
   *
   * @ngInject
   */
  function FileService(
    $http,
    $q,
    $window,
    FileUploader,
    FileSaver,
    Blob,
    EnvService,
    TokenService
  ) {
    var service = {
      createUploader: createUploader,
      download: download,
      open: open,
      upload: upload,
      downloadFromPurl: downloadFromPurl,
    };

    service.MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

    return service;

    /**
     * Download a file
     *
     * @param  {String}     route   File route
     * @return {Promise}            Request promise
     */
    function download(route) {
      var req = {
        method: "GET",
        url: EnvService.getApiUrl(route),
        headers: {
          "X-Response-Type": undefined,
          Authorization: TokenService.getAuthHeader(),
        },
        responseType: "arraybuffer",
      };
      return $http(req).then(function (response) {
        if (response.data) {
          var filename = "Untitled";
          var responseTruncated =
            response.headers("Abx-Truncated-Export") === "true";
          var contentType = response.headers("Content-Type") || "text/plain";
          var fileBlob = new Blob([response.data], { type: contentType });
          var contentDisposition = response.headers("Content-Disposition");
          if (
            contentDisposition &&
            contentDisposition.length &&
            contentDisposition.indexOf("filename=") !== -1
          ) {
            filename = contentDisposition
              .split("filename=")[1]
              .replace(/"/g, "");
          }
          FileSaver.saveAs(fileBlob, filename, true);
          return $q.resolve({
            message: "File download started",
            truncated: responseTruncated,
          });
        }
        return $q.reject({ message: "Invalid response data" });
      });
    }

    function downloadFromPurl(route, filename) {
      return getBlobFromUrl(route).then((blob) => {
        if (blob) {
          FileSaver.saveAs(blob, filename, true);
          return $q.resolve({
            message: "File download started",
            truncated: false,
          });
        }
        return $q.reject({ message: "Invalid response data" });
      });
    }

    function getBlobFromUrl(route) {
      return $q(function (res) {
        fetch(route, {}).then((response) => res(response.blob()));
      });
    }

    function open(route, newTab) {
      var url = EnvService.getApiUrl(route);
      var target = angular.isDefined(newTab) && !newTab ? "_self" : "_blank";
      $window.open(url, target);
    }

    /**
     * Create a file uploader (angular-file-upload)
     *
     * @param  {String}     url         Where the file should be uploaded
     * @param  {Object}     options     Uploader options
     * @return {Object}                 New FileUploader
     */
    function createUploader(url, options) {
      if (!angular.isDefined(options)) options = {};
      options.url = EnvService.getApiUrl(url);
      options.headers = { "X-Response-Type": "json" };
      var fileUploader = new FileUploader(options);
      fileUploader.filters.push({
        name: "maxFileSize",
        fn: validateFileSize,
      });
      return fileUploader;
    }

    /**
     * Upload a file in a multipart data post request
     *
     * @param  {File}   file    File to upload
     * @param  {String} route   Route to upload to
     * @param  {Object} extra   Additional data to add to the request
     *
     * @return {Promise<Error|Response>}     Http response
     */
    function upload(file, route, extra, onProgress) {
      if (!validateFileSize(file)) {
        return $q.reject("File is over the 50 MB limit");
      }
      var url = EnvService.getApiUrl(route);
      var data = new FormData();
      data.append("file", file);
      // Append extra data
      if (extra) {
        var keys = Object.keys(extra);
        for (var i = 0; i < keys.length; ++i) {
          var key = keys[i];
          data.append(key, extra[key]);
        }
      }
      return $http.post(url, data, {
        // prevent request serialization
        transformRequest: angular.identity,
        headers: {
          "Content-Type": undefined, // allow the browser to set this
          Authorization: TokenService.getAuthHeader(),
        },
        uploadEventHandlers: {
          progress: onProgress,
        },
      });
    }

    /**
     * Validate that a file item's size is less than the max file size
     *
     * @param  {File}       file    File
     * @return {Boolean}            True if the file is not too big, false if it is
     */
    function validateFileSize(file) {
      return file.size <= service.MAX_FILE_SIZE;
    }
  }
})();
