import type { IScope } from "angular";
import { createElement, useEffect, useRef, useState } from "react";
import type { FunctionComponent } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { useAngularApp } from "./angularApp";

/**
 * Create a react component from an angularjs component. Please consider
 * implementing whatever component you need in react instead of using this.
 *
 * @typeParam P The type of the react props for this component. Prop keys must be
 *  in camelCase and should be representative of the value for the binding at the
 *  kebab-case version of the same key.
 * @param componentName The camelCase name of the angular component to
 *  render. eg `abxInput`
 * @param overrides A map of binding settings for the component. Each key in the
 *  map should be a react prop name, and the value can be any of the following
 *  kinds of values. Each value is listed below along with its equivalent angularjs
 *  binding syntax. `props` refers to this component's react props in the angularjs
 *  scope that evaluates these expressions.
 *  - `@`                 | `{{ props[key] }}`
 *  - `&`                 | `props[key]()`
 *    - `&($event)`       | `props[key]($event)`
 *    - `&($event, $foo)` | `props[key]($event, $foo)`
 *  - `props.someKey`     | `props[someKey]`
 *  - Omit a key          | `props[someKey]`
 * @returns A react component that passes its props to the underlying
 *  angularjs component's bindings. React props must all be in camelCase,
 *  and will be converted to kebab-case to match with an angularjs binding.
 *  The component will also take in an `injector` prop that must be provided.
 *  @see {@link useAngularApp}
 *
 * @note There are some somewhat expensive operations that occur when props
 *  to components created by this function are changed. It is a good idea to
 *  use `useCallback` or `useMemo` as appropriate to minimize prop changes.
 *
 * @example Basic Usage
 * // some-component.js
 * angular.module("...").component("abxSomeComponent", {
 *    bindings: {
 *       someValue: "<abxSomeValue",
 *       onSomeEventNoArgs: "&abxOnSomeEventNoArgs",
 *       onSomeEventWithArgs: "&abxOnSomeEventWithArgs"
 *       someLiteral: "@abxSomeLiteral"
 *    },
 *    ...
 * })
 *
 * // SomeComponent.tsx
 * const AbxSomeComponent = reactFromAngular("abxSomeComponent", {
 *   abxSomeValue: "props.someValue", // this line is optional
 *   abxOnSomeEventNoArgs: "&",
 *   abxOnSomeEventWithArgs: "&($event)"
 *   abxSomeLiteral: "@"
 * });
 *
 * // SomeOtherComponent.tsx
 * const SomeOtherComponent = () => {
 *    const [someValue, setSomeValue] = useState(false);
 *    return <AbxSomeComponent
 *      injector={useInjector()}
 *      abxOnSomeEventNoArgs={() => console.log("Some event happened")}
 *      abxOnSomeEventWithArgs={($event: any) => console.log("Event was", $event)}
 *      abxSomeLiteral="Some Literal Value",
 *      abxSomeValue={someValue}
 *    />;
 * }
 */

export function reactFromAngular<P>(
  componentName: string,
  overrides: Overrides<P> = {}
): FunctionComponent<P & { injector: angular.auto.IInjectorService }> {
  const ResultComponent: FunctionComponent<
    P & { injector: angular.auto.IInjectorService }
  > = ({ injector, ...bindings }) => {
    const componentRef = useRef();
    /**
     * The angularjs parent scope for the component.
     */
    const [angularScope, setAngularScope] = useState<
      (IScope & { props: any }) | null
    >(null);

    /**
     * Angular bindings to be set on the component's html element.
     */
    const [angularProps, setAngularProps] = useState<Record<string, string>>();

    /**
     * This ref is set to true once the underlying angularjs component has been
     * `$compile`d.
     */
    const didCompileRef = useRef<boolean>(false);

    useEffect(() => {
      // set up the mapping for react props to angularjs bindings
      const newAngularProps: Record<string, string> = {};
      for (const reactKey of Object.keys(bindings)) {
        const defaultValue = `props.${reactKey}`;
        const angularBindingKey = camelCaseToKebabCase(reactKey);

        const override =
          (overrides as Record<string, OverrideValue<P>>)[reactKey] ||
          defaultValue;

        let bindingValue: string;
        if (override === "@") {
          bindingValue = `{{ props.${reactKey} }}`;
        } else if (override === "&") {
          bindingValue = `${defaultValue}()`;
        } else if (override.startsWith("&")) {
          bindingValue = override.replace("&", defaultValue);
        } else {
          bindingValue = override;
        }
        newAngularProps[angularBindingKey] = bindingValue;
      }

      // create an angularjs scope to hold the component's bindings
      const scope: ReactAngularScope<P> = injector
        .get("$rootScope")
        .$new(true) as any;

      setAngularScope(scope);
      setAngularProps(newAngularProps);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // once the scope and component ref are ready, we can
    // initialize the angularjs component
    useEffect(() => {
      if (didCompileRef.current || !componentRef.current || !angularScope) {
        return;
      }
      injector.get("$compile")(componentRef.current)(angularScope);
      didCompileRef.current = true;
    }, [injector, angularScope]);

    // any time a prop changes, update the angularjs scope and digest
    useEffect(() => {
      if (!angularScope) {
        return;
      }
      angularScope.props = { ...bindings };
      angularScope.$digest();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [angularScope, ...propsToDependencies(bindings)]);

    return !angularProps ? (
      <></>
    ) : (
      createElement(camelCaseToKebabCase(componentName), {
        ...angularProps,
        ref: componentRef,
      })
    );
  };
  ResultComponent.displayName = camelCaseToPascalCase(componentName);
  return ResultComponent;
}

/**
 * Acceptable override values
 */
export type OverrideValue<P> =
  | `@`
  | `props.${Exclude<keyof P, symbol>}`
  | `&(${string})`
  | `&`;

/**
 * Override options for react angular component creation.
 */
export type Overrides<P> = {
  [x in keyof P]?: OverrideValue<P>;
};

/**
 * Convert a camelCase string to a PascalCase string.
 * @param camelCase The string to convert
 * @returns The same string in PascalCase
 */
function camelCaseToPascalCase(camelCase: string) {
  return camelCase[0].toUpperCase() + camelCase.substring(1);
}

/**
 * Convert a camcelCase string to a kebab-case string
 * @param camelCase The string to convert
 * @returns The same string in kebab-case
 */
function camelCaseToKebabCase(camelCase: string) {
  return camelCase
    .split("")
    .map((c) => {
      if (/[A-Z]/.test(c)) {
        return `-${c.toLowerCase()}`;
      } else {
        return c;
      }
    })
    .join("");
}

/**
 * Type of the angularjs scope that holds data for component
 * bindings.
 */
interface ReactAngularScope<P> extends IScope {
  props: P;
}

/**
 * Convert an object to a reliably-ordered flat array that
 *  can be used as a `dependencies`
 * @param props An object
 * @returns The keys and values of the object in an array like
 *  [key, value, key, value, key, value]. This order will be consistent
 *  for objects that have the same keys.
 */
function propsToDependencies(props: Record<string, any>) {
  return Object.entries(props)
    .sort(([a], [b]) => a.localeCompare(b))
    .flat();
}
