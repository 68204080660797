(function () {
  angular
    .module("akitabox.ui.dialogs.request.reopen")
    .controller("ReopenRequestDialogController", ReopenRequestDialogController);

  /* @ngInject */
  function ReopenRequestDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    RequestService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since requests is passed in through locals
    self.multiple = self.requests ? self.requests.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.reopen = reopen;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Reopen service requests
     */
    function reopen() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.requests.length) * 100;
      var promiseArray = [];

      // Reopen the service requests
      for (var i = 0; i < self.requests.length; i++) {
        promiseArray[i] = RequestService.reopen(
          self.requests[i].building,
          self.requests[i]._id
        ).then(function (reopenedRequest) {
          self.determinateValue += increment;
          return reopenedRequest._id;
        });
      }

      $q.all(promiseArray)
        .then(function (reopenedRequestIDs) {
          $mdDialog.hide(reopenedRequestIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
