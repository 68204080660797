(function () {
  angular
    .module("akitabox.core.services.virtualRepeat", ["akitabox.core"])
    .factory("VirtualRepeatService", VirtualRepeatService);

  /* @ngInject */
  function VirtualRepeatService($log, $timeout) {
    var service = {
      create: create,
    };

    var VirtualRepeat = function (fetch, limit) {
      if (!angular.isFunction(fetch)) {
        return $log.error("fetch has to be a function");
      }
      this.fetch = fetch;
      /* {Number} - the number of items to grab on each fetch */
      this.limit = limit || 30;
      /* {Object} - an object holding all the items in the format of
           { fetch-index: [item, item, item], fetch-index: [item. item] } */
      this.items = {};
      /* {Number} - the total number of items currently present */
      this.numItems = null;
      /* {Boolean} - an indicator of when we've reached the end of the collection */
      this.moreToLoad = true;
      /* {Boolean} - an indicator of when we're making requests */
      this.fetching = false;
    };

    /**
     * DO NOT CHANGE THE NAME OF THIS METHOD!  THIS IS A REQUIRED METHOD/FUNCTION FOR MD-VIRTUAL-REPEAT
     * DO NOT CHANGE THE NAME OF THIS METHOD!  THIS IS A REQUIRED METHOD/FUNCTION FOR MD-VIRTUAL-REPEAT
     * DO NOT CHANGE THE NAME OF THIS METHOD!  THIS IS A REQUIRED METHOD/FUNCTION FOR MD-VIRTUAL-REPEAT
     *
     * Grabs the entire collection of this.items at the given index.  Will perform a fetch if index is non-existent
     *
     * @param index
     * @returns {[]|null}
     */
    VirtualRepeat.prototype.getItemAtIndex = function (index) {
      var fetchPage = Math.floor(index / this.limit);
      var page = this.items[fetchPage];
      var item = page ? page[index % this.limit] : undefined;

      if (item) {
        return page[index % this.limit];
      } else if (page !== null && this.moreToLoad) {
        this.fetchItems(fetchPage);
      }

      return null;
    };

    /**
     * DO NOT CHANGE THE NAME OF THIS METHOD!  THIS IS A REQUIRED METHOD/FUNCTION FOR MD-VIRTUAL-REPEAT
     * DO NOT CHANGE THE NAME OF THIS METHOD!  THIS IS A REQUIRED METHOD/FUNCTION FOR MD-VIRTUAL-REPEAT
     * DO NOT CHANGE THE NAME OF THIS METHOD!  THIS IS A REQUIRED METHOD/FUNCTION FOR MD-VIRTUAL-REPEAT
     *
     * Returns the current size of the items collection
     *
     * @returns {Number}
     */
    VirtualRepeat.prototype.getLength = function () {
      if (this.numItems === null) return 1;

      // If we have more items to still load, we want to add a bit extra to trigger infinite loading
      // One were done loading ALL items, we just return the length of the items
      return this.moreToLoad ? this.numItems + 3 : this.numItems;
    };

    /**
     * Performs a fetch to the provided Service's collection given the page number
     *
     * @param fetchPage
     */
    VirtualRepeat.prototype.fetchItems = function (fetchPage) {
      // Set the page to null so we know it is already being fetched.
      this.items[fetchPage] = null;
      this.fetching = true;

      this.fetch(fetchPage * this.limit, this.limit)
        .then(
          angular.bind(this, function (items) {
            if (items.length !== this.limit) {
              // If we ever fetch less then what we expect, it should be the last of this collection
              this.moreToLoad = false;
            }

            this.items[fetchPage] = items;

            if (this.numItems === null) {
              this.numItems = 0;
            }

            this.numItems += items.length;

            return $timeout(angular.noop, 500);
          })
        )
        .finally(
          angular.bind(this, function () {
            this.fetching = false;
          })
        );
    };

    return service;

    function create(fetch, limit) {
      return new VirtualRepeat(fetch, limit);
    }
  }
})();
