(function () {
  /**
   * Filter Bar and Result Count component. The result count portion requires
   * that you be in the ListView, since the url schema must match the api
   * schema. Should you need to use this component elsewhere, it may easiest
   * to conditionally hide that portion of the UI. Otherwise, it may be worthwhile
   * for the manager to generate the correct API path for the queries it is
   * generating.
   *
   * @typedef InputConfiguration
   * @member {boolean} [isLoading=false] - When true, forces the entire input
   *  section to be replaced with only a spinner.
   * @member {
   *  "string" | "typeahead" | "typeahead-dynamic" | "boolean" | "date-range" | "number-range"
   * } type - The type of input to display
   * @member {*} model - The current input model for the filter value input.
   * @member {string} [value] - The current input value for the filter value
   *  input. Required for typeahead inputs only, controls the displayed text
   *  of the typeahead's input.
   * @member {string} [placeholder] - The placeholder for the filter input
   * @member {string} [prefix] - Prefix text for the input. Only applies to
   *  "string" type inputs.
   * @member {{model: any, value: string}[]} [enumOptions] - Array of typeahead options.
   *  Required for "typeahead" type input configuration. See `abxInput`'s `options`
   *  binding for details.
   */
  /**
   * @typedef FilterOption
   * An object representing a single option from the filter dropdown.
   * @member {string} displayName - The user-friendly name for this filter option.
   */
  /**
   * @typedef FilterBarChip
   * An object representing a single chip on the filter bar.
   * @member {string} left - Chip left text
   * @member {string} right - Chip right text
   * @member {() => any} onClick - Callback invoked when the chip's X is clicked.
   */
  /**
   * @ngdoc component
   * @name abxFilterBarComponent
   * @param {Array<FilterOption>} filterOptions - Filter options for
   *  this component. Each member represents a single dropdown option on the
   *  left-hand-side. Each filter option must have a unique `displayName`.
   * @param {FilterOption} selectedFilterOption - Value controlling the filter
   *  selection typeahead's value.
   * @param {InputConfiguration} inputConfiguration - An object that contains
   *  configuration for display of the filter input section. See `InputConfiguration`
   *  typedef for details.
   * @param {({$event}) => any} onFilterInputChange - Change event handler
   *  for updates to the filter input. Type of $event depends on the type of
   *  input in use.
   * @param {({$event: FilterOption}) => any} onFilterSelect - Callback
   *  invoked when a new filter option is selected. The `$event` argument will
   *  be populated with the newly selected filter option.
   * @param {Array<FilterBarChip>} chips - The chips to display. Each chip's
   *  `onClick` function will be invoked when its `x` button is clicked.
   * @param {({$value: any; $filterOption: FilterOption}) => any} onFilterApply -
   *  Callback invoked when a filter is applied using the Apply button.
   *  `$value` is the input value to filter on `$filterOption` is the currently
   *  selected filter option.
   * @param {() => void} onClearAllFilters - Callback invoked when the "Clear All"
   *  button is clicked.
   */
  angular
    .module("akitabox.desktop.components.filterBar")
    .component("abxFilterBarComponent", {
      bindings: {
        filterOptions: "<abxFilterOptions",
        selectedFilterOption: "<abxSelectedFilterOption",
        inputConfiguration: "<abxInputConfiguration",
        onFilterInputChange: "&abxOnFilterInputChange",
        onFilterSelect: "&abxOnFilterSelect",
        chips: "<abxChips",
        onFilterApply: "&abxOnFilterApply",
        onClearAllFilters: "&abxOnClearAllFilters",
        modifyQuery: "&abxModifyQuery",
        countRoute: "<?abxCountRoute",
        disabledFilterOptions: "<?abxDisabledFilterOptions",
        loading: "<?abxLoading",
      },
      controller: FilterBarController,
      controllerAs: "vm",
      templateUrl:
        "app/desktop/components/filter-bar-component/filter-bar-component.component.html",
    });

  function FilterBarController(
    $timeout,
    $q,
    $location,
    HttpService,
    SessionService,
    ToastService
  ) {
    var self = this;

    /**
     * Count of total models that match the currently applied filters
     */
    self.filteredResultsCount = 0;

    /**
     * A flag to hide results count while loading them
     */
    self.filteredResultsCountLoading = true;

    /**
     * This value is derived from the `filterOptions` binding and is used to
     * populate the filter option dropdown. Each element will be a filter
     * option's `displayName`.
     * @type { string[] }
     */
    self.filterEnumOptions = [];
    /**
     * Value used as a model for the filter option dropdown.
     * @type { string }
     */
    self.selectedFilterDisplayName = null;

    /**
     * True while the "Add Filter" button callback is being awaited.
     * @type { boolean }
     */
    self.isApplyingFilter = false;

    // ==================
    // Public Methods
    // ==================

    self.handleFilterOptionSelect = handleFilterOptionSelect;
    self.handleFilterApply = handleFilterApply;

    self.getCurrentFilterOption = getCurrentFilterOption;
    self.isAddFilterDisabled = isAddFilterDisabled;

    self.$onInit = function () {
      updateResultCount();
    };

    /**
     * @return true if the add filter button should be disabled, false if it should be enabled
     */
    function isAddFilterDisabled() {
      if (!self.inputConfiguration) {
        return true;
      }
      if (self.isApplyingFilter) {
        return true;
      }
      var emptyValues = [null, undefined, ""];
      var isEmpty = function (val) {
        return emptyValues.indexOf(val) !== -1;
      };
      // anything besides one of thse empty values is fine
      if (!isEmpty(self.inputConfiguration.model)) {
        return false;
      } else {
        // for allowTextValue=true, we're allowed to send up the value if model is empty
        if (self.inputConfiguration.allowTextValue) {
          // still don't allow empty values
          return isEmpty(self.inputConfiguration.value);
        } else {
          return true;
        }
      }
    }

    /**
     * Event handler for the filter apply button. Invokes the onFilterApply
     * binding.
     * @return { void }
     */
    function handleFilterApply() {
      var model = self.inputConfiguration.model;
      if (model === null || model === undefined) {
        model = self.inputConfiguration.value;
      }
      if (model || model === 0 || model === false) {
        // set loading state
        self.isApplyingFilter = true;
        var applyFilter = function () {
          try {
            return self.onFilterApply({
              $value: model,
              $filterOption: self.getCurrentFilterOption(),
            });
          } catch (e) {
            return $q.reject(e);
          }
        };
        $q.resolve(applyFilter())
          .catch(function (err) {
            // applying the filter failed
            ToastService.showError(err);
            self.isApplyingFilter = false;
          })
          .then(function () {
            // clear loading state
            self.isApplyingFilter = false;
          });
      }
    }

    /**
     * Get the currently selected filter option.
     * @param { string } [displayName=self.selectedFilterDisplayName]
     * @return { FilterOption } The currently selected option from
     *  `filterOptions`
     */
    function getCurrentFilterOption(displayName) {
      if (!displayName) {
        displayName = self.selectedFilterDisplayName;
      }
      for (var i = 0; i < self.filterOptions.length; i++) {
        if (self.filterOptions[i].displayName === displayName) {
          return self.filterOptions[i];
        }
      }
      throw new Error("Unknown filter option: " + displayName);
    }

    /**
     * Event handler for selecting a filter option.
     * @param {object} event - The abx-input event.
     * @return { void }
     */
    function handleFilterOptionSelect(event) {
      var selectedOption = self.getCurrentFilterOption(event.model);
      notifyFilterOptionSelected(selectedOption);
    }

    /**
     * Notify the parent component of a newly selected filter option via the
     * `onFilterSelect` binding.
     * @param { FilterOption } filterOption
     */
    function notifyFilterOptionSelected(filterOption) {
      return self.onFilterSelect({ $event: filterOption });
    }

    self.$onChanges = function (changes) {
      if (changes.filterOptions) {
        // whenever filterOptions changes, we need to update our enum options
        // as well
        self.filterEnumOptions = self.filterOptions.map(function (
          filterOption
        ) {
          return filterOption.displayName;
        });

        if (
          changes.filterOptions.isFirstChange() &&
          self.filterOptions.length
        ) {
          // Select the first filter option when mounted
          notifyFilterOptionSelected(self.filterOptions[0]);
        }
      } else if (changes.chips) {
        updateResultCount();
      }
      if (changes.selectedFilterOption) {
        self.selectedFilterDisplayName = self.selectedFilterOption
          ? self.selectedFilterOption.displayName
          : null;
      }
    };

    /**
     * Query for the total count of models that match the given list
     * parameters
     */
    function updateResultCount() {
      // Push update till after digest to get final path & params
      self.filteredResultsCountLoading = true;
      $timeout(function () {
        var currentListPath = $location.path();
        var query = $location.search();
        query.count = true;

        // Include special cases from controllers
        if (self.modifyQuery) {
          self.modifyQuery()(query);
        }

        if (self.countRoute) {
          currentListPath = self.countRoute;
        } else if (!currentListPath.includes("buildings/")) {
          currentListPath =
            "organizations/" +
            SessionService.getOrganizationId() +
            currentListPath;
        }

        HttpService.get(currentListPath, query)
          .then(function (result) {
            if (result && result.count !== null) {
              self.filteredResultsCount = result.count;
            }
          })
          .finally(function () {
            self.filteredResultsCountLoading = false;
          });
      }, 0);
    }
  }
})();
