(function () {
  /**
   * @ngdoc component
   * @name abxHeaderList
   *
   * @param {Object} [activeItem] - The item that should be highlighted
   * @param {String}  default - Default item
   * @param {Object[]} items - All items
   * @param {String} type - Type of items ex. building
   * @param {Function} onClick - The function to run when an item is selected from the list
   * @param {Function} onDefaultClick - The function to run when the default item is selected
   *
   * @description
   * A component to be used in conjunction with the header header to create a interactive
   * list.
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .component("abxHeaderList", {
      bindings: {
        selected: "<?abxSelected",
        models: "<?abxModels",
        onClick: "&?abxOnClick",
        searchText: "<?abxSearchText",
        orgSearchText: "<?abxOrgSearchText",
        buildingGroupSearchText: "<?abxBuildingGroupsSearchText",
      },
      controller: AbxHeaderListController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/components/header-list/header-list.component.html",
    });

  function AbxHeaderListController() {
    var self = this;

    self.onModelClick = onModelClick;
    self.isSelected = isSelected;

    function onModelClick(model) {
      self.onClick({ $event: { model: model } });
    }

    function isSelected(model) {
      return Boolean(model && self.selected && model._id === self.selected._id);
    }
  }
})();
