/**
 * Retrieves the `value` of a specific `cookie` by a given `name`
 *
 * @param {string} name - The name/key of the cookie you want to retrieve
 * @param cookies - Represents the cookies in a string. Defaults to `document.cookie`
 * @returns The value of the cookie
 */
export function getFromCookies(name: string, cookies = document.cookie) {
  return cookies
    .split(/;\s/g)
    .find((cookie) => cookie.startsWith(`${name}=`))
    ?.split(/=/g)[1];
}
