import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import {
  Avatar,
  Button,
  Popover,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  ListItem,
} from "@mui/material";
import { Business, Dashboard, Layers } from "@mui/icons-material";
import { api } from "../api";
import { makeUseServiceCall } from "../hooks/useServiceCall";

type LinkPreviewProps = {
  asset: any;
  assets: any[];
  anchorEl: any;
  onClose: () => void;
};

type LocationCardProps = {
  asset: any;
  assets: any[];
  showHeader: boolean;
  applyStyles?: boolean;
};

const LocationCard = ({
  asset,
  assets,
  showHeader = true,
  applyStyles = true,
}: LocationCardProps) => {
  const onLocationClick = (location: string) => {
    const buildingId = selectedBuilding;
    const levelId = selectedLevel;
    const assetId = asset._id;
    const roomId = asset.room?._id;
    switch (location) {
      case "building":
        window.open(`/buildings/${buildingId}`, "_blank");
        break;
      case "level":
        window.open(`/levels/${levelId}`, "_blank");
        break;
      case "room":
        window.open(`/rooms/${roomId}`, "_blank");
        break;
      case "floorPlan":
        window.open(
          `/plan/buildings/${buildingId}/levels/${levelId}?asset=${assetId}`,
          "_blank"
        );
        break;
      default:
        break;
    }
    window.open(
      `/plan/buildings/${buildingId}/levels/${levelId}?asset=${assetId}`,
      "_blank"
    );
  };

  const imageRef = useRef<HTMLImageElement | null>(null);
  const [pinStyles, setPinStyles] = useState<
    { top?: string; left?: string; asset: any }[]
  >([]);
  const [imageStyle] = useState<{
    height?: string;
    width?: string;
  }>({
    height: "auto",
    width: "auto",
  });

  const rotateAroundPoint = (
    centerX: number,
    centerY: number,
    x: number,
    y: number,
    angle: number
  ): any => {
    const radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians);
    const rotatedX = cos * (x - centerX) + sin * (y - centerY) + centerX;
    const rotatedY = cos * (y - centerY) - sin * (x - centerX) + centerY;

    return { x: rotatedX, y: rotatedY };
  };

  const [selectedBuilding, setSelectedBuilding] = useState<any[]>([]);
  const buildings = useMemo(() => {
    // return unique buildings from assets
    const uniqueBuildings = assets
      .map((asset) => asset.building)
      .filter(
        (building, index, self) =>
          index ===
          self.findIndex(
            (t) => t._id === building._id && t?.name === building?.name
          )
      )
      .sort((a, b) => a?.name.localeCompare(b?.name));
    setSelectedBuilding(uniqueBuildings[0]?._id);
    return uniqueBuildings;
  }, [assets]);

  const [selectedLevel, setSelectedLevel] = useState<string>("");
  const levels = useMemo(() => {
    // return unique levels from assets
    const uniqueLevels = assets
      // Filter assets by selectedBuilding
      .filter((asset) => asset.building._id === selectedBuilding)
      .map((asset) => asset.level)
      .filter(
        (level, index, self) =>
          index ===
          self.findIndex(
            (t) => t?._id === level?._id && t?.name === level?.name
          )
      )
      .sort((a, b) => a?.name?.localeCompare(b?.name));

    setSelectedLevel(uniqueLevels[0]?._id);
    return uniqueLevels;
  }, [assets, selectedBuilding]);

  useEffect(() => {
    if (selectedLevel) {
      const filteredAssets = assets.filter(
        (a: any) => a.level?._id === selectedLevel
      );
      setSelectedAssets(filteredAssets);
    }
  }, [selectedLevel, assets]);
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);

  const { data: levelResponse, isLoading: isLoading } = makeUseServiceCall(
    api.levels.getById
  )({
    organizationId: selectedAssets[0]?.organization,
    levelId: selectedLevel,
  });

  const level: any = useMemo(() => {
    return levelResponse ? levelResponse.data : {};
  }, [levelResponse]);

  const rooms = useMemo(() => {
    // return unique rooms from assets
    return assets
      .map((asset) => asset.room)
      .filter(
        (room, index, self) =>
          index ===
          self.findIndex(
            (t: any) => t?._id === room?._id && t?.name === room?.name
          )
      );
  }, [assets]);

  const placePins = useCallback(() => {
    if (!imageRef.current) return;
    const containerBounds = imageRef?.current?.getBoundingClientRect();
    const imageHeight =
      imageStyle.height !== "auto"
        ? parseFloat(imageStyle.height || "")
        : imageRef?.current?.height || 0;
    const imageWidth =
      imageStyle.width !== "auto"
        ? parseFloat(imageStyle.width || "")
        : imageRef?.current?.width;

    const newPinStyles = selectedAssets
      ?.map((asset) => {
        if (!asset.percentX || !asset.percentY) return null;

        const pinSize = 10; // Assuming a fixed pin size
        const yOrigin =
          imageHeight < containerBounds.height
            ? (containerBounds.height - imageHeight) / 2 - pinSize
            : -pinSize;
        const xOrigin =
          imageWidth < containerBounds.width
            ? (containerBounds.width - imageWidth) / 2 - pinSize
            : -pinSize;

        const rotatedPercentages = rotateAroundPoint(
          0.5,
          0.5,
          asset.percentX,
          asset.percentY,
          360 - level?.document?.rotation
        );
        const yDelta = imageHeight * rotatedPercentages.y;
        const xDelta = imageWidth * rotatedPercentages.x;

        return {
          top: `${yOrigin + yDelta}px`,
          left: `${xOrigin + xDelta}px`,
          asset,
        };
      })
      .filter((pinStyle) => pinStyle !== null) as {
      top: string;
      left: string;
      asset: any;
    }[];
    setPinStyles(newPinStyles);
  }, [
    selectedAssets,
    imageRef,
    imageStyle.height,
    imageStyle.width,
    level?.document?.rotation,
  ]);

  useEffect(() => {
    placePins();
  }, [selectedAssets, placePins]);

  const handleBuildingChange = (event: any) => {
    const buildingId = event.target.value;
    setSelectedBuilding(buildingId);
  };

  const getSelectedBuildingName = () => {
    const selected = buildings.find(
      (building: any) => building._id === selectedBuilding
    );
    return selected ? selected?.name : "Select Building";
  };

  const handleLevelChange = (event: any) => {
    const levelId = event.target.value;
    setSelectedLevel(levelId);
  };

  const getSelectedLevelName = () => {
    const selected = levels.find((level: any) => level?._id === selectedLevel);
    return selected ? selected?.name : "Select Level";
  };

  return (
    <Card
      variant="outlined"
      sx={
        applyStyles
          ? {
              width: "100%",
              minWidth: 350,
              boxShadow: "none",
              borderRadius: 1,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:hover": {
                boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
                transform: "scale(1.02)",
              },
            }
          : {}
      }
    >
      {showHeader ? (
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: `${asset?.color}` }}
              alt={asset?.display_name}
              src={`/img/pin_icons/${asset?.icon}.svg`}
            />
          }
          title={`${asset?.display_name} location`}
        />
      ) : (
        <CardHeader title={`Location`} />
      )}
      <CardContent style={{ overflowY: "auto" }}>
        <>
          <List component="nav">
            {buildings.length > 1 ? (
              <ListItem style={{ display: "flex" }}>
                <ListItemIcon
                  onClick={() => onLocationClick("building")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#1976d2",
                    },
                  }}
                >
                  <Business />
                </ListItemIcon>
                <Select
                  value={selectedBuilding}
                  onChange={handleBuildingChange}
                  displayEmpty
                  renderValue={() => getSelectedBuildingName()}
                  style={{ flexGrow: 1 }}
                >
                  {buildings.map((building) => (
                    <MenuItem key={building._id} value={building._id}>
                      {building?.name}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            ) : (
              <ListItemButton onClick={() => onLocationClick("building")}>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary={asset?.building?.name ?? ""} />
              </ListItemButton>
            )}
            {levels.length > 1 ? (
              <ListItem style={{ display: "flex" }}>
                <ListItemIcon
                  onClick={() => onLocationClick("level")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#1976d2",
                    },
                  }}
                >
                  <Layers />
                </ListItemIcon>
                <Select
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  displayEmpty
                  renderValue={() => getSelectedLevelName()}
                  style={{ flexGrow: 1 }}
                >
                  {levels.map((level) => (
                    <MenuItem key={level?._id} value={level?._id}>
                      {level?.name}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            ) : (
              <ListItemButton onClick={() => onLocationClick("level")}>
                <ListItemIcon>
                  <Layers />
                </ListItemIcon>
                <ListItemText primary={asset?.level?.name} />
              </ListItemButton>
            )}
            {rooms.length > 1 ? (
              <ListItem style={{ display: "flex" }}>
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText
                  primary={`Multiple Rooms`}
                  style={{ flexGrow: 1 }}
                />
              </ListItem>
            ) : rooms.length === 1 && rooms[0]?.number ? (
              <ListItemButton onClick={() => onLocationClick("room")}>
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText
                  primary={`${asset?.room?.number} - ${asset?.room?.name}`}
                />
              </ListItemButton>
            ) : (
              <ListItem>
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText primary="No Room Location" />
              </ListItem>
            )}
          </List>
          {isLoading ? (
            <Skeleton variant="rectangular" height={250} width={300} />
          ) : (
            <>
              {asset?.level && level?.document?.public_thumbnail_url_medium && (
                <div style={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="250"
                    width="300"
                    ref={imageRef}
                    style={imageStyle}
                    image={level?.document?.public_thumbnail_url_medium}
                    alt={asset?.level?.name}
                    className="abx-thumbnail-image"
                    onLoad={() => placePins()}
                    onClick={() => onLocationClick("floorPlan")}
                  />
                  {pinStyles?.map((pinStyle, index) => (
                    <Tooltip title={pinStyle?.asset.display_name} key={index}>
                      <Avatar
                        key={index}
                        src={`/img/pin_icons/${pinStyle?.asset.icon}.svg`}
                        alt={pinStyle?.asset.display_name}
                        sx={{
                          bgcolor: `${pinStyle?.asset.color}`,
                          width: 20,
                          height: 20,
                        }}
                        style={{
                          position: "absolute",
                          ...pinStyle,
                        }}
                        className="abx-thumbnail-pin"
                      />
                    </Tooltip>
                  ))}
                </div>
              )}
              {!(
                asset?.level && level?.document?.public_thumbnail_url_medium
              ) && (
                <div style={{ position: "relative" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      No Floor Plan Available
                    </Typography>
                  </CardContent>
                </div>
              )}
            </>
          )}
        </>
      </CardContent>
      <CardActions>
        {asset?.level && level?.document?.public_thumbnail_url_medium && (
          <Button onClick={() => onLocationClick("floorPlan")} size="small">
            View on Floor Plan
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const LocationPreview = ({
  asset,
  assets,
  anchorEl,
  onClose,
}: LinkPreviewProps) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <LocationCard
        asset={asset}
        assets={assets}
        showHeader={true}
        applyStyles={false}
      />
    </Popover>
  );
};

export { LocationCard, LocationPreview };
