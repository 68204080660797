(function () {
  angular.module("akitabox.core.utils").factory("raf", rafFactory);

  /**
   * @ngdoc factory
   * @module akitabox.core.utils
   * @name rafFactory
   * @description
   * Wrapper service around window.requestAnimationFrame
   */
  /* @ngInject */
  function rafFactory($timeout) {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (func) {
        $timeout(func, 1000 / 60);
      }
    );
  }
})();
