(function () {
  angular.module("akitabox.desktop.inspectionProgram.detail", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.lib.moment",
    "akitabox.core.router",
    "akitabox.core.services.attachment",
    "akitabox.core.services.building",
    "akitabox.core.services.checklist",
    "akitabox.core.services.env",
    "akitabox.core.services.floor",
    "akitabox.core.services.http",
    "akitabox.core.services.inspection",
    "akitabox.core.services.inspectionProgram",
    "akitabox.core.services.note",
    "akitabox.core.services.pinType",
    "akitabox.core.services.workOrder",
    "akitabox.ui.directives.donutChart",
  ]);
})();
