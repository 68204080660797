(function () {
  /**
   * @ngdoc component
   * @name abxLocation
   *
   * @param viewAllUiSref - Optional, used to control the ui-sref attribute for
   *    the VIEW ALL link displayed under round details. Link is only displayed
   *    if this param is set.
   *
   * @description
   * <abx-location> displays location information for a model
   */
  angular
    .module("akitabox.ui.components.location", [
      "akitabox.constants",
      "akitabox.ui.components.locationButtons",
      "akitabox.ui.components.locationMap",
      "akitabox.core.services.building",
      "akitabox.core.services.checklist",
      "akitabox.core.services.flag",
      "akitabox.core.services.floor",
      "akitabox.core.services.helpers",
      "akitabox.core.services.room",
      "akitabox.core.services.asset",
      "akitabox.core.services.organization",
      "akitabox.core.utils",
      "akitabox.ui.dialogs.editLocation",
    ])
    .component("abxLocation", {
      bindings: {
        model: "<abxModel",
        isMobile: "<abxIsMobile",
        canUpdateLocation: "<?abxCanUpdateLocation",
        onChange: "&abxOnChange",
        type: "@abxModelType",
        updateFunction: "<?abxUpdateFunction",
        viewAllUiSref: "@?abxViewAllUiSref",
      },
      controller: AbxLocation,
      controllerAs: "vm",
      templateUrl: "app/core/ui/components/location/location.component.html",
    });

  /* @ngInject */
  function AbxLocation(
    // Angular
    $q,
    // AkitaBox
    models,
    // Services
    BuildingService,
    FloorService,
    RoomService,
    AssetService,
    ChecklistService,
    InspectionService,
    OrganizationService,
    WorkOrderService,
    Utils,
    // Dialogs
    EditLocationDialog
  ) {
    var self = this;

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.canUpdateLocation = angular.isDefined(self.canUpdateLocation)
      ? self.canUpdateLocation
      : false;
    self.loading = true;
    self.building = null;
    self.floor = null;
    self.room = null;
    self.asset = null;
    self.roomDisplayName = null;
    self.isInspectionWo = false;

    self.multiLocationEnabled = self.organization.show_inspections;
    self.hasMultipleLocations = false;

    // Functions
    self.showUpdateDialog = showUpdateDialog;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onChanges = function (changes) {
      if (changes.model && self.model) {
        self.hasMultipleLocations =
          self.multiLocationEnabled &&
          self.model.round &&
          self.model.round.length > 1;

        var hasChanges = false;
        checkEntity("building", self.model.building);

        if (!self.hasMultipleLocations) {
          /**
           * These fields don't exist on WOs with multiple locations
           * so we make sure we only do this when we have single locations
           */
          checkEntity("floor", getFloor(self.model));
          checkEntity("room", getRoom(self.model));
          checkEntity("asset", getAsset(self.model));
        }

        if (hasChanges) {
          self.isInspectionWo = self.model.source === "inspection";
          parseLocations(self.model);
        }
      }

      function checkEntity(name, newEntity) {
        var currentEntity = self[name];
        var newEntityId = Utils.getEntityId(newEntity);
        if (currentEntity) {
          if (currentEntity._id !== newEntityId) {
            hasChanges = true;
          }
        } else if (newEntityId) {
          hasChanges = true;
        }
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function showUpdateDialog() {
      EditLocationDialog.show({
        locals: {
          building: self.building,
          model: self.model,
          modelType: self.type,
          update: self.updateFunction,
        },
      }).then(function (updatedModel) {
        // Don't update self.model here since it's one-way bound, we need to let the parent do the update
        self.onChange({ $event: { model: updatedModel } });
      });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getFloor(model) {
      if (!model) {
        return null;
      }
      if (self.type === models.WORK_ORDER.MODEL) {
        return WorkOrderService.getLevel(model);
      }
      return model.level;
    }
    function getRoom(model) {
      if (!model) {
        return null;
      }
      if (self.type === models.WORK_ORDER.MODEL) {
        return WorkOrderService.getRoom(model);
      }
      return model.room;
    }
    function getAsset(model) {
      if (!model) {
        return null;
      }
      if (self.type === models.WORK_ORDER.MODEL) {
        return WorkOrderService.getAsset(model);
      }
      return model.asset;
    }

    /**
     * Parse and fetch location information for model
     *
     * @param {*} model Model to parse location information from
     */
    function parseLocations(model) {
      self.loading = true;

      var building = model.building;
      // buildingID used for the fetch functions
      var buildingID;
      var requests = [];

      if (building) {
        if (Object.prototype.hasOwnProperty.call(building, "_id")) {
          self.building = building;
          buildingID = self.building._id;
        } else {
          buildingID = building;
          requests.push(fetchBuilding(building));
        }
      } else {
        self.building = null;
      }

      var floor = getFloor(model);
      var room = getRoom(model);
      var asset = getAsset(model);

      if (model.round && self.isInspectionWo) {
        requests.push(setInspectionFloor(model));
      }

      if (floor) {
        if (Object.prototype.hasOwnProperty.call(floor, "_id")) {
          self.floor = floor;
        } else {
          requests.push(fetchFloor(buildingID, floor));
        }
      } else {
        self.floor = null;
      }

      if (room) {
        if (Object.prototype.hasOwnProperty.call(room, "_id")) {
          self.room = room;
        } else {
          requests.push(fetchRoom(buildingID, room));
        }
      } else {
        self.room = null;
      }

      if (asset) {
        if (Object.prototype.hasOwnProperty.call(asset, "_id")) {
          self.asset = asset;
        } else {
          requests.push(fetchAsset(buildingID, asset));
        }
      } else {
        self.asset = null;
      }

      if (requests.length) {
        $q.all(requests).finally(function () {
          self.loading = false;
        });
      } else {
        self.loading = false;
      }
    }

    /**
     * Get building and attach to self.building
     *
     * @param {String} id Building ID
     *
     * @return {Promise}  Promise with building
     */
    function fetchBuilding(id) {
      return BuildingService.getById(id).then(function (building) {
        self.building = building;
        return building;
      });
    }

    /**
     * Get floor and attach to self.floor
     *
     * @param {String} buildingID the building ID
     *
     * @param {String} id floor ID
     *
     * @return {Promise}  Promise with floor
     */
    function fetchFloor(buildingID, id) {
      return FloorService.getById(buildingID, id).then(function (floor) {
        self.floor = floor;
        return floor;
      });
    }

    /**
     * Get room and attach to self.room, then get room's display name
     *
     * @param {String} buildingID the building ID
     *
     * @param {String} id room ID
     *
     * @return {Promise}  Promise with room
     */
    function fetchRoom(buildingID, id) {
      return RoomService.getById(buildingID, id).then(function (room) {
        self.room = room;
        return room;
      });
    }

    /**
     * Get asset and attach to self.asset
     *
     * @param {String} buildingID the building ID
     *
     * @param {String} id asset ID
     *
     * @return {Promise}  Promise with asset
     */
    function fetchAsset(buildingID, id) {
      return AssetService.getById(buildingID, id).then(function (asset) {
        self.asset = asset;
        return asset;
      });
    }

    function setInspectionFloor(workOrder) {
      return ChecklistService.getByWorkOrderId(
        self.organization._id,
        workOrder._id
      ).then(function (checklists) {
        self.floor = InspectionService.getInspectionFloor(
          checklists,
          workOrder
        );
      });
    }
  }
})();
