(function () {
  angular
    .module("akitabox.ui.components.dashboardStats", [
      "akitabox.constants",
      "akitabox.core.services.chart",
      "akitabox.core.services.identity",
      "akitabox.core.services.request",
      "akitabox.core.services.workOrder",
      "akitabox.core.services.user",
      "akitabox.core.utils",
    ])
    .component("abxDashboardStats", {
      bindings: {
        buildings: "<abxBuildings",
      },
      controller: AbxDashboardStatsController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/dashboard-stats/dashboard-stats.component.html",
    });
  function AbxDashboardStatsController(
    // Angular
    $q,
    $log,
    $scope,
    $rootScope,
    // Libraries
    moment,
    // Constants,
    models,
    DASHBOARD_CARDS,
    // EVENTS
    EVENT_REFRESH_DASHBOARD,
    EVENT_REFRESH_DASHBOARD_STATS_COMPLETE,
    EVENT_REFRESH_DASHBOARD_ERROR,
    // Services
    ChartService,
    IdentityService,
    SessionService,
    UserService
  ) {
    var self = this;

    var config = {};
    var getWorkOrderStats;
    var getWorkOrderLogStats;
    var getServiceRequestStats;
    var workSummaryRequests = null;

    var dateFilterConfig = ChartService.getDateFilterConfig(false);
    var dateFilterOptionsMap = dateFilterConfig.map;

    self.userId = IdentityService.getUserId();

    var SAVE_DATES_KEY = DASHBOARD_CARDS.WORK_SUMMARY;
    var userPerms = UserService.getPermissions();

    self.permissions = {
      canOnlyViewMyWO: userPerms.task.read_own && !userPerms.task.read_all,
    };

    // Dates
    self.isCustomDate = false;
    self.dateFilterOptions = dateFilterConfig.options;
    self.activeDateFilterLabel = self.dateFilterOptions[1]; // Last Month
    var activeDateFilter = dateFilterOptionsMap[self.activeDateFilterLabel];

    var today = new moment.utc().endOf("day").toDate();
    self.today = today;

    self.startDate = activeDateFilter.startDate;
    self.endDate = activeDateFilter.endDate;
    self.dateRange = {
      start: self.startDate,
      end: self.endDate,
    };

    // Attributes
    self.newRequestsCount = null;
    self.numOfCompletedWOs = null;
    self.numOfMyCompletedWOs = null;
    self.totalHoursWorked = null;
    self.myHoursWorked = null;
    self.avgHoursWorkedPerWO = null;
    self.avgWODuration = null;
    self.avgWOCompletedPerDay = null;
    self.reactiveToPreventiveRatio = null;
    self.totalSRCount = null;
    self.avgSRCompletionTime = null;

    // Function
    self.isEmpty = angular.isEmpty;
    self.onDateChange = onDateChange;
    self.onDateFilterClosed = onDateFilterClosed;
    self.onDateFilterChange = onDateFilterChange;

    $scope.$on(EVENT_REFRESH_DASHBOARD, function (ev, eventArgs) {
      calculateStats(eventArgs);
    });

    // Lifecycle
    self.$onInit = function () {
      // Get saved state
      var states = SessionService.getDashboardCardStates();
      var statsStates = states[SAVE_DATES_KEY] || {};

      if (statsStates.dateRange) {
        if (statsStates.filterLabel) {
          self.activeDateFilterLabel = statsStates.filterLabel; // Last Month
        }

        if (self.activeDateFilterLabel === "Custom") {
          self.dateRange = {
            start: new moment.utc(statsStates.dateRange.start).toDate(),
            end: new moment.utc(statsStates.dateRange.end).toDate(),
          };
          addCustomDate();
        } else {
          var activeDateFilter =
            dateFilterOptionsMap[self.activeDateFilterLabel];
          self.startDate = activeDateFilter.startDate;
          self.endDate = activeDateFilter.endDate;
          self.dateRange = {
            start: self.startDate,
            end: self.endDate,
          };
        }
      }
      self.oldDateRange = self.dateRange;

      // Set the current active building
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxDashboardStats: abx-buildings is required");
        return;
      }

      config = ChartService.getChartConfig(
        self.buildings,
        models.WORK_ORDER.MODEL
      );
      getWorkOrderStats = config.statsFunction;
      getWorkOrderLogStats = ChartService.getChartConfig(
        self.buildings,
        models.WORK_ORDER_LOG.MODEL
      ).statsFunction;
      getServiceRequestStats = ChartService.getChartConfig(
        self.buildings,
        models.SERVICE_REQUEST.MODEL
      ).statsFunction;
      // Calculate the statistics
      calculateStats();
    };

    function buildParams(newParams) {
      var baseParams = {
        status: "completed",
        completed_date:
          "$lte," +
          self.dateRange.end.valueOf() +
          ",$gte," +
          self.dateRange.start.valueOf(),
      };
      var params = angular.extend({}, newParams, baseParams);
      if (config.buildingInString) params.buildings = config.buildingInString;
      return params;
    }

    function buildWorkOrderLogParams(newParams) {
      var baseParams = {
        work_performed_date:
          "$lte," +
          self.dateRange.end.valueOf() +
          ",$gte," +
          self.dateRange.start.valueOf(),
      };
      var params = angular.extend({}, newParams, baseParams);
      if (config.buildingInString) params.buildings = config.buildingInString;
      return params;
    }

    function saveState() {
      var cardStates = SessionService.getDashboardCardStates();
      var currentState = cardStates[SAVE_DATES_KEY] || {};
      angular.extend(currentState, {
        filterLabel: self.activeDateFilterLabel,
        dateRange: self.dateRange,
      });
      cardStates[SAVE_DATES_KEY] = currentState;
      SessionService.setDashboardCardStates(cardStates);
    }

    // Public Functions

    function onDateChange($event) {
      var value = $event.newValue;
      if (value.start === null) {
        value.start = self.oldDateRange.start;
      }
      if (value.end === null) {
        value.end = self.oldDateRange.end;
      }
      var invalid = $event.invalid || !value;
      if (!invalid && value.start && value.end) {
        self.dateRange = value;
        if (self.isCustomDate) {
          addCustomDate();
        }
        saveState();
        calculateStats();
      }
    }

    function onDateFilterChange($event) {
      if (self.isCustomDate) self.dateFilterOptions.pop(); // Remove Custom Date Filter

      self.isCustomDate = false;
      self.activeDateFilterLabel = $event.model;
      activeDateFilter = dateFilterOptionsMap[self.activeDateFilterLabel];
      self.startDate = activeDateFilter.startDate;
      self.endDate = activeDateFilter.endDate;
      self.dateRange = {
        start: self.startDate,
        end: self.endDate,
      };
      onDateChange({ newValue: self.dateRange });
    }

    function onDateFilterClosed() {
      if (self.isCustomDate) {
        addCustomDate();
      }
    }

    function addCustomDate() {
      var options = self.dateFilterOptions;
      var length = options.length;
      if (options[length - 1] !== "Custom") options.push("Custom");
      self.activeDateFilterLabel = "Custom";
    }

    // Private Functions
    function calculateStats(eventArgs) {
      if (workSummaryRequests) {
        return;
      }
      workSummaryRequests = $q
        .all([
          getCompletedWorkOrdersStats(),
          getMyCompletedWorkOrdersStats(),
          getTotalHoursWorked(),
          getMyHoursWorked(),
          getTotalServiceRequestCount(),
        ])
        .then(function () {
          $rootScope.$broadcast(
            EVENT_REFRESH_DASHBOARD_STATS_COMPLETE,
            eventArgs
          );
        })
        .catch(function (error) {
          $rootScope.$broadcast(
            EVENT_REFRESH_DASHBOARD_ERROR,
            angular.extend({}, eventArgs, { error: error })
          );
        })
        .finally(function () {
          workSummaryRequests = null;
        });
    }

    // Gets the number of completed work orders for the current date range and then computes the average amount
    // completed per day
    function getCompletedWorkOrdersStats() {
      self.completedWorkOrderParams = buildParams();
      return getWorkOrderStats(
        config.parentId,
        self.completedWorkOrderParams
      ).then(function (stats) {
        self.numOfCompletedWOs = stats[0].result;
        var timeDiff = moment(self.dateRange.end).diff(
          self.dateRange.start,
          "days"
        );
        timeDiff = timeDiff > 0 ? timeDiff : 1;
        self.avgWOCompletedPerDay = (self.numOfCompletedWOs / timeDiff).toFixed(
          2
        );
      });
    }

    // Gets the number of completed work orders assigned to the current user
    function getMyCompletedWorkOrdersStats() {
      self.myCompletedWorkOrderParams = buildParams();
      self.myCompletedWorkOrderParams.assignees = "$in," + self.userId;
      return getWorkOrderStats(
        config.parentId,
        self.myCompletedWorkOrderParams
      ).then(function (stats) {
        self.numOfMyCompletedWOs = stats[0].result;
      });
    }

    // Gets the total amount of hours worked across all work orders, regardless of status
    function getTotalHoursWorked() {
      var newParams = {
        operator: "sum",
        operator_field: "work_minutes",
      };
      var params = buildWorkOrderLogParams(newParams);
      return getWorkOrderLogStats(config.parentId, params).then(function (
        stats
      ) {
        self.totalHoursWorked = stats[0].result / 60;
      });
    }

    // Gets the total amount of hours worked across all of the current user's work orders, regardless of status
    function getMyHoursWorked() {
      var newParams = {
        operator: "sum",
        operator_field: "work_minutes",
        work_performed_by: self.userId,
      };
      var params = buildWorkOrderLogParams(newParams);
      return getWorkOrderLogStats(config.parentId, params).then(function (
        stats
      ) {
        self.myHoursWorked = stats[0].result / 60;
      });
    }

    // Gets the total amount of service requests made
    function getTotalServiceRequestCount() {
      var params = {
        cre_date:
          "$lte," +
          self.dateRange.end.valueOf() +
          ",$gte," +
          self.dateRange.start.valueOf(),
      };
      return getServiceRequestStats(config.parentId, params).then(function (
        stats
      ) {
        self.totalSRCount = stats[0].result;
      });
    }
  }
})();
