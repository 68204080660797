(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.stackChart
   *
   * @param {Array} data - data to populate the chart
   * @param {String} idTag - unique #id for the chart
   * @param {Array} colors - an array of colors for the chart (has default)
   * @param {Object} startDate - the start date of the date range
   * @param {Object} endDate - the end date of the date range
   * @param {Boolean} groups - if the chart should be by groups
   * @param {String} groupKey - the name of the key to access the group
   */
  angular
    .module("akitabox.ui.directives.stackChart", [
      "720kb.tooltips",
      "ngMaterial",
      "akitabox.core",
      "akitabox.core.lib.d3",
      "akitabox.core.services.chart",
    ])
    .directive("abxStackChart", AbxStackChartDirective);

  /* @ngInject */
  function AbxStackChartDirective(
    $log,
    $window,
    $mdMedia,
    d3,
    ChartService,
    DEFAULT_CHART_COLOR_RANGE
  ) {
    return {
      restrict: "E",
      scope: {
        data: "<abxData",
        idTag: "<abxIdTag",
        colors: "<?abxColors",
        startDate: "<?abxStartDate",
        endDate: "<?abxEndDate",
        groupKey: "<?abxGroupKey",
        nullValue: "<?abxNullValue",
        hoverLabel: "<?abxHoverLabel",
        highlightedStack: "<?abxHighlightedStack",
        isUnsorted: "<?abxIsUnsorted",
        stackSort: "<?abxStackSort",
        onRedirect: "&?abxOnRedirect",
      },
      link: postlink,
    };
    function postlink($scope, $element) {
      if (!$scope.data) {
        return $log.error("<abx-stack-chart>: abx-data must be provided");
      }

      if (!$scope.idTag) {
        return $log.error("<abx-stack-chart>: abx-id-tag must be provided");
      }

      // Attributes
      var idTag = $scope.idTag;
      var data;

      // Constants
      var CHART_SUFFIX = "-stack-chart";
      var HEIGHT = 300;
      var MAX_BAR_WIDTH = 40;
      var LEFT_PADDING = 40;
      var RIGHT_PADDING = 20;
      var VERTICAL_MARGIN = 40;
      var HORIZONTAL_MARGIN = 20;
      var NULL_VALUE = $scope.nullValue || "None";
      var ROTATE_LABEL_CLASS = "label-container-rotate";
      var MAX_BARS_ALLOWED = $mdMedia("gt-md") ? 60 : 40;
      var TRUNCATE_TEXT_LIMIT = 80;
      var ROTATED_LEGEND_MARGIN = 50;

      // Defaults
      var hoverLabel = " " + $scope.hoverLabel || "";
      var groupKey = $scope.groupKey;
      var usingGroups = !!groupKey;
      var timeFormat = "%b %d, %Y";
      var colorRange = $scope.colors || DEFAULT_CHART_COLOR_RANGE;
      var color = d3.scaleOrdinal().range(colorRange);
      var isUnsorted = $scope.isUnsorted || false;
      var stackSort = $scope.stackSort || undefined;
      var onRedirect = $scope.onRedirect;

      // Functions
      var debouncedOnResize = angular.debounce(onResize, 50);

      // Watchers

      $scope.$watch("data", function (newData) {
        if (JSON.stringify(data) === JSON.stringify(newData)) return;
        data = newData.slice(0, MAX_BARS_ALLOWED - 1);
        drawChart(data);
      });

      $scope.$watch("hoverLabel", function (label) {
        hoverLabel = " " + label;
        drawChart(data);
      });

      $scope.$watch("startDate", function (newData) {
        if (
          $scope.startDate &&
          $scope.startDate.getDate() === newData.getDate()
        )
          return;
        drawChart(data);
      });

      $scope.$watch("endDate", function (newData) {
        if ($scope.endDate && $scope.endDate.getDate() === newData.getDate())
          return;
        drawChart(data);
      });

      $scope.$watch("highlightedStack", function (newData) {
        if (!newData) {
          d3.selectAll("rect").dispatch("mouseout");
          return;
        }

        var id = idTag + CHART_SUFFIX + "-" + ChartService.normalize(newData);
        d3.selectAll("#" + id).dispatch("mouseover");
      });

      angular.element($window).on("resize", debouncedOnResize);
      $scope.$watch(
        function () {
          var $parent = $element[0].parentElement;
          return {
            width: $parent ? $parent.clientWidth : 0,
            height: $parent ? $parent.clientHeight : 0,
          };
        },
        debouncedOnResize,
        true
      );

      function drawChart(newData) {
        var timeDifference;
        var x, y;
        var xAxis, yAxis;
        var width, height;
        var bandwidth = 0;
        var xDomainMax;
        var barwidth;
        var length = newData.length || 0;

        d3.select("#" + idTag + CHART_SUFFIX).remove();
        if (!newData || length <= 0) return;

        var svg = d3
          .select($element[0])
          .append("svg")
          .attr("id", idTag + CHART_SUFFIX)
          .attr("width", "100%")
          .attr("height", HEIGHT)
          .style("overflow", "visible");
        var g = svg.append("g");

        var margin = {
          top: VERTICAL_MARGIN,
          right: HORIZONTAL_MARGIN,
          bottom: VERTICAL_MARGIN,
          left: HORIZONTAL_MARGIN,
        };

        width = Math.max(0, $element[0].offsetWidth - HORIZONTAL_MARGIN);
        height = HEIGHT - margin.top - margin.bottom;

        g = svg
          .append("g")
          .attr(
            "transform",
            "translate(" + margin.left + "," + margin.top + ")"
          );

        // Remove the x-axis keys
        var keys = Object.keys(newData[0]);
        if (stackSort) {
          keys.sort(stackSort);
        } else {
          keys.sort();
        }
        keys = keys.filter(function (key) {
          if (key !== "date" && key !== groupKey) return key;
        });

        if (!usingGroups) {
          createTimeAxis();
        } else {
          createGroupAxis();
        }

        color.domain(keys);

        g.append("g")
          .attr("class", "axis")
          .attr("transform", "translate(0," + height + ")")
          .call(customXAxis);

        g.append("g").attr("class", "axis").call(customYAxis);

        // Append the Bars to the chart
        var groupContainer = g
          .append("g")
          .selectAll("g")
          .data(d3.stack().keys(keys)(newData))
          .enter()
          .append("g")
          .attr("fill", function (d) {
            return color(d.key);
          })
          .attr("data-key", function (d) {
            return ChartService.normalize(d.key);
          })
          .selectAll("rect")
          .data(function (d) {
            return d;
          })
          .enter();
        groupContainer
          .append("rect")
          .attr("id", function (d) {
            var key = d3.select(this.parentNode).attr("data-key");
            return idTag + CHART_SUFFIX + "-" + key;
          })
          .attr("x", function (d) {
            var value = d.data[groupKey] || NULL_VALUE;
            var xValue = usingGroups ? value : d.data.date;
            return x(xValue) + bandwidth * 0.5 - barwidth / 2;
          })
          .attr("y", function (d) {
            return y(d[1]);
          })
          .attr("height", function (d) {
            return y(d[0]) - y(d[1]);
          })
          .attr("width", barwidth)
          .on("mouseover", function (d, index) {
            var key = d3.select(this.parentNode).attr("data-key");
            onMouseOver(d, index + 1, key);
          })
          .on("mouseout", function (d) {
            var segment = d3.select(this.parentNode);
            var key = segment.attr("data-key");
            if (onRedirect) {
              segment.style("cursor", "pointer");
            }
            onMouseOut(key);
          })
          .on("click", function (d) {
            if (onRedirect) {
              onRedirect({ name: d.data._id });
            }
          });

        // Add the count to the top of the bars
        if (MAX_BARS_ALLOWED < 60) return;
        var barLabelContainer = g.append("g");
        for (var i = 0; i < length; i++) {
          var d = newData[i];
          var value = d[groupKey] || NULL_VALUE;
          var xValue = usingGroups ? value : d.date;
          var total = keys.reduce(function (total, k) {
            return total + d[k];
          }, 0);

          barLabelContainer
            .append("text")
            .attr("text-anchor", "middle")
            .attr("font-size", 12)
            .attr("fill", "#333")
            .attr("x", x(xValue) + bandwidth * 0.5)
            .attr("y", y(total) - 6)
            .text(roundNumber(total, 2));
        }

        // Create the X/Y Axis and Domain for a time scale
        function createTimeAxis() {
          timeDifference = ChartService.buildTimeDifference(
            $scope.startDate,
            $scope.endDate
          );

          barwidth = (width / timeDifference.days) * 0.75;

          if (timeDifference.days >= 60) {
            timeFormat = "%b %Y";
            barwidth = (width / timeDifference.months) * 0.75;
          }
          if (timeDifference.years >= 3) {
            timeFormat = "%Y";
            barwidth = (width / timeDifference.years) * 0.75;
          }

          barwidth = barwidth > MAX_BAR_WIDTH ? MAX_BAR_WIDTH : barwidth;

          x = d3
            .scaleUtc()
            .domain([$scope.startDate, $scope.endDate])
            .range([LEFT_PADDING, width - HORIZONTAL_MARGIN - RIGHT_PADDING]);

          xDomainMax = x($scope.endDate);

          y = d3.scaleLinear().rangeRound([height, 0]);

          y.domain([
            0,
            d3.max(newData, function (d) {
              return keys.reduce(function (total, k) {
                return total + d[k];
              }, 0);
            }),
          ]).nice();

          // Set up the X and Y axis
          xAxis = d3
            .axisBottom(x)
            .ticks(timeDifference.days)
            .tickFormat(d3.utcFormat(timeFormat));

          if (timeDifference.days >= 60) {
            xAxis.ticks(timeDifference.months);
          }

          yAxis = d3.axisRight(y).tickSize(width).ticks(3);
        }

        // Create the X/Y Axis and Domain for a group scale
        function createGroupAxis() {
          // Sort in descending order
          if (!isUnsorted) {
            newData = newData.sort(function (a, b) {
              var totalA = keys.reduce(function (total, k) {
                return total + a[k];
              }, 0);
              var totalB = keys.reduce(function (total, k) {
                return total + b[k];
              }, 0);
              return totalA - totalB;
            });
          }

          x = d3.scaleBand().rangeRound([0, width]).paddingInner(0.1);

          var groups = [];
          for (var i = 0; i < length; i++) {
            var group = newData[i][groupKey] || NULL_VALUE;
            if (group === NULL_VALUE) groups.push(group);
            else groups.unshift(group);
          }

          x.domain(groups);
          bandwidth = x.bandwidth();
          barwidth = bandwidth > MAX_BAR_WIDTH ? MAX_BAR_WIDTH : bandwidth;
          xDomainMax = x(groups[groups.length - 1]);

          y = d3.scaleLinear().rangeRound([height, 0]);

          y.domain([
            0,
            d3.max(newData, function (d) {
              return keys.reduce(function (total, k) {
                return total + d[k];
              }, 0);
            }),
          ]).nice();

          // Set up the X and Y axis
          xAxis = d3.axisBottom(x);

          yAxis = d3.axisRight(y).tickSize(width).ticks(3);
        }

        // X axis style
        function customXAxis(g) {
          g.call(xAxis);
          g.select(".domain").remove();
          var ticks;
          if (!groupKey) {
            g.selectAll(".tick").style("display", "none").attr("id", "x-axis");
            g.select(".tick:first-of-type").style("display", "block");
            g.select(".tick:last-of-type").style("display", "block");
            g.selectAll(".tick text").attr("dy", 15);
          } else {
            ticks = g
              .selectAll(".tick text")
              .attr("class", "x-axis-text")
              .attr("font-size", 14)
              .attr("y", 20)
              .attr("x", 0);
            var fontSize = 11;
            if ($mdMedia("gt-md")) {
              fontSize = 14;
            }
            // rotate x-axis text 90 degrees if more than 5 bars
            // or if the window is sufficiently small and there are more than 4 bars
            if (
              (length > 5 && ticks) ||
              (length > 4 && !$mdMedia("gt-md") && ticks)
            ) {
              ticks
                .attr("clip-path", "")
                .attr("font-size", fontSize)
                .attr("class", "x-axis-text--rotated")
                .attr("y", -6)
                .attr("x", -10)
                .each(truncate)
                .on("mouseover", function (d) {
                  onXAxisLabelMouseOver(d);
                })
                .on("mouseout", onXAxisLabelMouseOut);

              d3.select($element[0]).style(
                "margin-bottom",
                ROTATED_LEGEND_MARGIN + "px"
              );
            } else {
              ticks.call(wrap, bandwidth);
            }
          }
        }

        // Y axis style
        function customYAxis(g) {
          g.call(yAxis);
          g.select(".domain").remove();
          g.selectAll(".tick:not(:first-of-type) line").attr("stroke", "#eee");
          g.selectAll(".tick:first-of-type line")
            .attr("stroke", "#999")
            .attr("stroke-width", 1);
          g.select(".tick:first-of-type text").style("display", "none");
          g.selectAll(".tick text").attr("x", -20).attr("dy", 0);
        }

        function onXAxisLabelMouseOver(value) {
          var xValue = x(value);
          var yCoord = -30; // gets negative of the length of the value
          var labelClass = "";
          var xLabelContainer = d3
            .select($element[0])
            .append("div")
            .attr("id", idTag + CHART_SUFFIX + "x-axis-label-container")
            .attr("class", "chart-hover");
          var xCoord =
            xValue + bandwidth * 0.5 + barwidth / 2 + HORIZONTAL_MARGIN + 15;

          // Flip if the label is too far right
          if (xValue > xDomainMax * 0.75) {
            xCoord =
              xValue - 15 + bandwidth * 0.5 - barwidth / 2 + HORIZONTAL_MARGIN;

            labelClass = ROTATE_LABEL_CLASS;
          } else {
            xCoord =
              xValue + bandwidth * 0.5 + barwidth / 2 + HORIZONTAL_MARGIN + 15;
          }
          // Label Container
          var labelContainer = xLabelContainer
            .append("div")
            .attr("class", "label-container " + labelClass)
            .style("position", "absolute")
            .style("top", yCoord + "px")
            .style("left", xCoord + "px")
            .style("border-left", "none");

          // Label Text
          labelContainer.append("span").attr("class", "label-name").html(value);
        }

        function onXAxisLabelMouseOut() {
          d3.selectAll(
            "#" + idTag + CHART_SUFFIX + "x-axis-label-container"
          ).remove();
        }

        // Mouse over behavior
        function onMouseOver(d, index, key) {
          var yValue = roundNumber(d[1] - d[0], 2);

          if (yValue <= 0) return;

          var value = d.data[groupKey] || NULL_VALUE;
          var xValue = usingGroups ? x(value) : x(d.data.date);
          var yCoord =
            y(d[1]) - (y(d[1]) - y(d[0])) / 2 - HEIGHT + VERTICAL_MARGIN / 2;
          var xCoord;
          var labelClass = "";
          var barLabelContainer = d3
            .select($element[0])
            .append("div")
            .attr("id", idTag + CHART_SUFFIX + "container")
            .attr("class", "chart-hover");

          if (!usingGroups) {
            // Show tick
            g.select("#x-axis.tick:nth-of-type(" + index + ")").style(
              "display",
              "block"
            );

            // Hide first/ticks if too near
            if (xValue > xDomainMax * 0.8) {
              g.select("#x-axis.tick:last-of-type").style("display", "none");
            }

            if (xValue < xDomainMax * 0.1 && xValue !== x($scope.startDate)) {
              g.select("#x-axis.tick:first-of-type").style("display", "none");
            }
          }

          // Handle Hover Legend Item
          var legendItemText = d3.select(
            "#" + key + "-legend-item-text-" + idTag
          );
          var legendItemCircle = d3.select(
            "#" + key + "-legend-item-circle-" + idTag
          );
          legendItemCircle.style("transform", "scale(1.1)");
          legendItemText.style("font-weight", "bold");

          // Flip if the label is too far right
          if (xValue > xDomainMax * 0.75) {
            xCoord =
              xValue - 15 + bandwidth * 0.5 - barwidth / 2 + HORIZONTAL_MARGIN;

            labelClass = ROTATE_LABEL_CLASS;
          } else {
            xCoord =
              xValue + bandwidth * 0.5 + barwidth / 2 + HORIZONTAL_MARGIN + 15;
          }

          // Label Container
          var labelContainer = barLabelContainer
            .append("div")
            .attr("class", "label-container " + labelClass)
            .style("position", "absolute")
            .style("top", yCoord + "px")
            .style("left", xCoord + "px")
            .style("border-left", "none");

          // Label Text
          labelContainer
            .append("span")
            .attr("class", "label-number")
            .html(yValue)
            .append("span")
            .attr("class", "label-name")
            .html(hoverLabel);
        }

        // Mouse out behavior
        function onMouseOut(key) {
          d3.selectAll("#" + idTag + CHART_SUFFIX + "container").remove();

          // Reset tick marks
          g.selectAll("#x-axis.tick").style("display", "none");
          g.select(".tick:first-of-type").style("display", "block");
          g.select(".tick:last-of-type").style("display", "block");

          // Handle Hover Legend Item
          var legendItemText = d3.select(
            "#" + key + "-legend-item-text-" + idTag
          );
          var legendItemCircle = d3.select(
            "#" + key + "-legend-item-circle-" + idTag
          );
          legendItemCircle.style("transform", "scale(1.0)");
          legendItemText.style("font-weight", "unset");
        }

        function wrap(text, _width) {
          text.each(function () {
            var text = d3.select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 15,
              y = text.attr("y"),
              dy = parseFloat(text.attr("dy")),
              tspan = text
                .text(null)
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", dy);
            while ((word = words.pop())) {
              line.push(word);
              tspan.text(line.join(" "));
              if (tspan.node().getComputedTextLength() > _width) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text
                  .append("tspan")
                  .attr("x", 0)
                  .attr("y", y)
                  .attr("dy", ++lineNumber * lineHeight + dy)
                  .text(word);
              }
            }
          });
        }

        function truncate() {
          var self = d3.select(this),
            textLength = self.node().getComputedTextLength(),
            text = self.text();
          while (textLength > TRUNCATE_TEXT_LIMIT && text.length > 0) {
            text = text.slice(0, -1);
            self.text(text + "...");
            textLength = self.node().getComputedTextLength();
          }
        }
      }

      /**
       * Check if we can display full text action buttons
       */
      function onResize() {
        MAX_BARS_ALLOWED = $mdMedia("gt-md") ? 60 : 40;
        drawChart(data);
      }

      function roundNumber(number, numDecimalPlaces) {
        try {
          return parseFloat(number.toFixed(numDecimalPlaces));
        } catch (err) {
          return 0;
        }
      }
    }
  }
})();
