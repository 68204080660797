(function () {
  angular
    .module("akitabox.ui.dialogs.association")
    .controller("AssociationDialogController", AssociationDialogController);

  /**
   * @ngInject
   */
  function AssociationDialogController(
    $mdDialog,
    $q,
    AssociationService,
    AssociationTypeService,
    BuildingService,
    FloorService,
    OrganizationService,
    PinTypeService,
    RoomService,
    Utils
  ) {
    var self = this;

    /**
     * Holds the error during initilization of this model/dialog
     * Will prevent user from doing anything if this is present
     */
    self.disableEverything = false;
    self.errorMessage = "";

    /**
     * Determines if the inputs have been touched or not, false = untouched
     * This will disable saving on initial load up
     */
    self.dirty = false;
    /**
     * @type {Object<Association._id, Association>}
     */
    self.inputs = {};
    /**
     * Holds the actual associations the user will be manipulating throughout this UI
     * @type {Object<string, Association._id>} - key is association hash, the value is the association id
     */
    self.inputHashes = {};
    /**
     * Tracks the associations that are to be deleted from the DB
     * @type {Array<string<Association._id>>} - array that holds associations ids
     */
    self.associationsMarkedForDeletion = [];

    /** Flag to determine if any associations are duplicated */
    self.hasDuplicatedAssociations = false;
    /** Flag to determine if any associations are incomplete */
    self.hasIncompleteAssociations = false;
    /**
     * Map of pin types so we can show a more specific text of the asset's type
     * ie: inset of just saying "asset", we can show "Toilet"
     */
    self.pinTypes = {};
    self.organization = undefined;
    /**
     * Used for mainly just setting the default input value for the building dropdown
     * Derived from the room/asset that we are trying to create relationships for
     */
    self.building = undefined;
    /**
     * Used for mainly just setting the default input value for the floor dropdown
     * Derived from the room/asset that we are trying to create relationships for
     */
    self.floor = undefined;
    /**
     * Options for the relation types dropdown input
     */
    self.relationTypes = [];

    self.loading = false;
    self.progress = 0;
    self.saving = false;

    self.cancel = $mdDialog.cancel;
    self.inputsSize = function inputSize() {
      return Object.keys(self.inputs).length;
    };
    self.save = save;

    self.hideDeleteButton = hideDeleteButton;

    // DOM input change handlers
    self.handleBuildingChange = handleBuildingChange;
    self.handleFloorChange = handleFloorChange;
    self.handleRoomChange = handleRoomChange;
    self.handleAssetChange = handleAssetChange;
    self.handleRelationTypeChange = handleRelationTypeChange;
    self.handleAddingEmptyAssociation = handleAddingEmptyAssociation;
    self.handleDeletingAssociation = handleDeletingAssociation;
    self.isDuplicated = isDuplicated;
    self.isComplete = isComplete;

    init();

    /**
     * This fn is only there for when the asset/room is completely unassociated with anything
     * This will make sure we don't render a delete button on the first empty association until
     * they add another association or save teh first empty one
     * @returns {boolean} true if should hide, false otherwise
     */
    function hideDeleteButton() {
      var keys = Object.keys(self.inputs);
      var firstInput = self.inputs[keys[0]];

      if (keys.length === 1 && associationIsUUIDv4(firstInput._id)) {
        return true;
      } else {
        return false;
      }
    }

    function checkFloorField(id) {
      const floor = self.inputs[id].floor.model;
      const building = self.inputs[id].building.model;
      const floorBuildingId = floor && floor.building;
      if (floor && (!building || floorBuildingId !== building._id)) {
        self.inputs[id].floor.model = undefined;
      }
    }

    function checkRoomField(id) {
      const room = self.inputs[id].room.model;
      const floor = self.inputs[id].floor.model;
      const roomFloorId = room && room.floor;
      if (room && (!floor || roomFloorId !== floor._id)) {
        self.inputs[id].room.model = undefined;
      }
    }

    function checkAssetField(id) {
      const asset = self.inputs[id].asset.model;
      const room = self.inputs[id].room.model;
      const assetRoomId = asset && asset.room;
      if (asset && (!room || assetRoomId !== room._id)) {
        self.inputs[id].asset.model = undefined;
      }
    }

    function handleBuildingChange(event, id) {
      var association = self.inputs[id];
      var building = event ? event.model : undefined;

      if (association) {
        if (
          (!association.building.model && !building) ||
          (association.building.model &&
            building &&
            association.building.model._id === building._id)
        ) {
          /**
           * Hasn't changed at all, just ignore this input
           */
          return;
        }

        var oldHash = generateAssociationHash(association);
        /** Reset these fields everytime someone changes an input value */
        self.hasIncompleteAssociations = false;
        self.hasDuplicatedAssociations = false;

        association.building.model = building;
        self.dirty = true;

        checkFloorField(id);
        checkRoomField(id);
        checkAssetField(id);

        removeFromInputHashes(oldHash, association._id);
        addToInputHashes(generateAssociationHash(association), association._id);
      }
    }

    function handleFloorChange(event, id) {
      var association = self.inputs[id];
      var floor = event ? event.model : undefined;

      if (association) {
        if (
          (!association.floor.model && !floor) ||
          (association.floor.model &&
            floor &&
            association.floor.model._id === floor._id)
        ) {
          /**
           * Hasn't changed at all, just ignore this input
           */
          return;
        }
        var oldHash = generateAssociationHash(association);

        /** Reset these fields everytime someone changes an input value */
        self.hasIncompleteAssociations = false;
        self.hasDuplicatedAssociations = false;
        association.floor.model = floor;
        self.dirty = true;

        checkRoomField(id);
        checkAssetField(id);

        removeFromInputHashes(oldHash, association._id);
        addToInputHashes(generateAssociationHash(association), association._id);
      }
    }

    function handleRoomChange(event, id) {
      var association = self.inputs[id];
      var room = event ? event.model : undefined;
      var promises = [];

      if (association) {
        if (
          (!association.room.model && !room) ||
          (association.room.model &&
            room &&
            association.room.model._id === room._id)
        ) {
          /**
           * Hasn't changed at all, just ignore this input
           */
          return;
        }

        var oldHash = generateAssociationHash(association);
        /** Reset these fields everytime someone changes an input value */
        self.hasIncompleteAssociations = false;
        self.hasDuplicatedAssociations = false;

        association.room.model = room;

        checkAssetField(id);

        if (
          room &&
          room.level &&
          (!association.floor.model ||
            association.floor.model._id !== room.level)
        ) {
          /**
           * We need to freeze both the floor and room inputs when we're populating
           * the room's floor, so they don't trigger any other data population
           */
          const buildingId =
            room.building && typeof room.building === "object"
              ? room.building._id
              : room.building;
          const levelId =
            room.level && typeof room.level === "object"
              ? room.level._id
              : room.level;
          association.floor.loading = true;
          promises.push(
            FloorService.getById(buildingId, levelId)
              .then(function (floor) {
                association.floor.model = floor;
              })
              .catch(function () {
                throw new Error("Error trying to set the room's floor");
              })
              .finally(function () {
                association.floor.loading = true;
              })
          );
        }

        association.room.loading = true;
        $q.all(promises)
          .then(function () {
            if (
              association.room.model &&
              association.room.model._id === self.model._id
            ) {
              association.room.error = new Error(
                "Cannot associate room to itself"
              );
            } else {
              association.room.error = undefined;
              association.summaryHTML = generateAssociationSummary(association);
              self.dirty = true;

              removeFromInputHashes(oldHash, association._id);
              addToInputHashes(
                generateAssociationHash(association),
                association._id
              );
            }
          })
          .catch(function (response) {
            var error = convertResponseToError(response);
            association.room.error = error;
          })
          .finally(function () {
            association.room.loading = false;
          });
      }
    }

    function handleAssetChange(event, id) {
      var association = self.inputs[id];
      var asset = event && event.model;
      var promises = [];

      if (association) {
        if (
          (!association.asset.model && !asset) ||
          (association.asset.model &&
            asset &&
            association.asset.model._id === asset._id)
        ) {
          /**
           * Hasn't changed at all, just ignore this input
           */
          return;
        }

        var oldHash = generateAssociationHash(association);

        /** Reset these fields everytime someone changes an input value */
        self.hasIncompleteAssociations = false;
        self.hasDuplicatedAssociations = false;

        association.asset.model = asset;

        let buildingId;

        if (asset) {
          buildingId =
            asset.building && typeof asset.building === "object"
              ? asset.building._id
              : asset.building;
        }

        if (
          asset &&
          asset.level &&
          (!association.floor.model ||
            association.floor.model._id !== asset.level)
        ) {
          /**
           * Here we are attempting to auto fill the floor input if the asset has a floor
           */
          const levelId =
            asset.level && typeof asset.level === "object"
              ? asset.level._id
              : asset.level;
          association.floor.loading = true;
          promises.push(
            FloorService.getById(buildingId, levelId)
              .then(function (floor) {
                association.floor.model = floor;
                association.floor.error = undefined;
              })
              .catch(function () {
                throw new Error("Error trying to set the asset's floor");
              })
              .finally(function () {
                association.floor.loading = false;
              })
          );
        }
        const isntEmptyObj = (value) =>
          angular.isObject(value) && Object.keys(value).length > 0;

        if (
          asset &&
          (angular.isString(asset.room) || isntEmptyObj(asset.room)) &&
          (!association.room.model || association.room.model._id !== asset.room)
        ) {
          /**
           * Here we are attempting to auto fill the floor input if the asset has a floor
           */
          const roomId =
            asset.room && typeof asset.room === "object"
              ? asset.room._id
              : asset.room;
          association.room.loading = true;
          promises.push(
            RoomService.getById(buildingId, roomId)
              .then(function (room) {
                association.room.model = room;
              })
              .catch(function () {
                throw new Error("Error trying to set the asset's room");
              })
              .finally(function () {
                association.room.loading = false;
              })
          );
        }

        association.asset.loading = true;
        $q.all(promises)
          .then(function () {
            if (
              association.asset.model &&
              association.asset.model._id === self.model._id
            ) {
              association.asset.error = new Error(
                "Cannot associate asset to itself"
              );
            } else {
              association.asset.error = undefined;
              association.summaryHTML = generateAssociationSummary(association);
              self.dirty = true;

              // Update self.inputHashes
              removeFromInputHashes(oldHash, association._id);
              addToInputHashes(
                generateAssociationHash(association),
                association._id
              );
            }
          })
          .catch(function (response) {
            association.asset.error = convertResponseToError(response);
          })
          .finally(function () {
            association.asset.loading = false;
          });
      }
    }

    function handleRelationTypeChange(event, id) {
      var association = self.inputs[id];
      var relationType = (event && event.model) || undefined;

      if (association) {
        if (
          (!association.relationType.model && !relationType) ||
          (association.relationType.model &&
            relationType &&
            association.relationType.model._id === relationType._id)
        ) {
          /**
           * Hasn't changed at all, just ignore this input
           */
          return;
        }
        var oldHash = generateAssociationHash(association);

        association.relationType.model = relationType;
        association.summaryHTML = generateAssociationSummary(association);
        self.dirty = true;
        self.hasIncompleteAssociations = false;
        self.hasDuplicatedAssociations = false;

        removeFromInputHashes(oldHash, association._id);
        addToInputHashes(generateAssociationHash(association), association._id);
      }
    }

    function handleAddingEmptyAssociation() {
      var newAssociation = createAssociation({
        building: {
          model: self.building,
        },
        floor: {
          model: self.floor,
        },
      });
      self.inputs[newAssociation._id] = newAssociation;

      return newAssociation;
    }

    function handleDeletingAssociation(id) {
      var association = self.inputs[id];

      if (!association) {
        return;
      }

      if (!associationIsUUIDv4(association._id)) {
        /** Queue it up for deletion, needs api call, thus we use a separate container for theses */
        self.associationsMarkedForDeletion.push(association._id);
      }

      /** Need to delete it from the hash list of existing associations as well */
      var hash = generateAssociationHash(association);
      removeFromInputHashes(hash, association._id);

      /** Remove it from the actual associations ui map */
      delete self.inputs[association._id];
      /** Mark the inputs as dirty (edited) */
      self.dirty = true;

      /** Reset these fields everytime someone changes an input value */
      self.hasIncompleteAssociations = false;
      self.hasDuplicatedAssociations = false;
    }

    function init() {
      self.organization = OrganizationService.getCurrent();
      if (!self.organization) {
        self.errorMessage = "Unable to retrieve current organization";
        self.disableEverything = true;
        return;
      }
      // This is the only portion we need to wait for, grabbing the relation types
      self.loading = true;
      $q.all([
        setBuilding(self.model),
        setFloor(self.model),
        setAssociationTypes(self.organization._id),
        setPinTypes(self.organization._id),
      ])
        .then(function () {
          // Need to wait for the building and floor to be populated before we can do this
          return setAssociations(self.associations);
        })
        .catch(function (err) {
          self.disableEverything = true;
          self.errorMessage =
            "There was an issue opening this window. Try reloading it to fix the issue.";
        })
        .finally(function () {
          self.loading = false;
        });
    }

    function generateAssociationSummary(association) {
      if (
        association.relationType.model &&
        association.relationType.model.text.length &&
        (association.asset.model || association.room.model)
      ) {
        var modelPinType = self.model.pinType; // self.model has a populated pinType coming into this dialog/modal
        var pinType =
          self.pinTypes[
            association.asset.model
              ? association.asset.model.pinType
              : association.room.model.pinType
          ];

        return (
          "<i>" +
          (self.modelType === "Room"
            ? "Room "
            : (modelPinType ? Utils.capitalize(modelPinType.name) : "Asset") +
              " ") +
          "<b>" +
          (self.modelType === "Room"
            ? self.model.display_name
            : self.model.name) +
          "</b> " +
          association.relationType.model.text.toLowerCase() +
          " " +
          (association.asset.model
            ? (pinType ? pinType.name : "asset") + " "
            : "room ") +
          (association.asset.model
            ? "<b>" + association.asset.model.name + "</b>"
            : "<b>" + association.room.model.display_name + "</b>") +
          "</i>"
        );
      } else {
        return "";
      }
    }

    function createAssociation(params) {
      if (!params) {
        params = {};
      }

      /**
       * This is what the front-end association looks like, built more
       * specifically for this ui
       */
      var id = params._id || Utils.uuidv4();
      return {
        _id: id,
        building: {
          error: (params.building && params.building.error) || undefined,
          model: (params.building && params.building.model) || undefined,
          loading: (params.building && params.building.loading) || false,
        },
        floor: {
          error: (params.floor && params.floor.error) || undefined,
          model: (params.floor && params.floor.model) || undefined,
          loading: (params.floor && params.floor.loading) || false,
        },
        room: {
          error: (params.room && params.room.error) || undefined,
          model: (params.room && params.room.model) || undefined,
          loading: (params.room && params.room.loading) || false,
        },
        asset: {
          error: (params.asset && params.asset.error) || undefined,
          model: (params.asset && params.asset.model) || undefined,
          loading: (params.asset && params.asset.loading) || false,
        },
        relationType: {
          error:
            (params.relationType && params.relationType.error) || undefined,
          model:
            (params.relationType && params.relationType.model) || undefined,
          loading:
            (params.relationType && params.relationType.loading) || false,
          selectedOption:
            (params.relationType && params.relationType.selectedOption) || 0,
        },
        summaryHTML: params.summaryHTML || "",
      };
    }

    /**
     * Sets the default building that should populate each association input section
     * @param {Room|Asset} model -  the room or asset we are setting up relationships for
     * @return {Promise<undefined>}
     */
    function setBuilding(model) {
      if (model.building && !model.building._id) {
        return BuildingService.getById(model.building).then(function (
          building
        ) {
          self.building = building;
        });
      } else if (model.building) {
        self.building = model.building;
        return $q.resolve();
      } else {
        self.building = undefined;
        return $q.resolve();
      }
    }

    /**
     * Sets the default floor that should populate each association input section
     * @param {Room|Asset} model -  the room or asset we are setting up relationships for
     * @return {Promise<undefined>}
     */
    function setFloor(model) {
      if (model.level && !model.level._id) {
        return FloorService.getById(model.building, model.level).then(function (
          floor
        ) {
          self.floor = floor;
        });
      } else if (model.level) {
        // already populated
        self.floor = model.level;
        return $q.resolve();
      } else {
        self.floor = undefined;
        return $q.resolve();
      }
    }

    /**
     * Creates the initial loaded in associations to be properly displayed in the UI
     * @param {*} associations
     * @returns
     */
    function setAssociations(associations) {
      if (!associations.length) {
        self.inputs = {};
        handleAddingEmptyAssociation();
        return $q.resolve();
      } else {
        var existingAssociations = {};

        /**
         * Caching these inital buildings, floors, rooms so we hopefully don't
         * grab redundant ones for each association
         */
        var buildings = {};
        var floors = {};
        var rooms = {};
        var promises = [];

        associations.forEach(function (association) {
          /**
           * We create the empty entry to keep the order of the associations to how
           * they were originally passed in. This has to be done because we do some async work
           * below that only pushes the populated association back to he array when those fetches
           * are done, possibly changing the order of the associations
           *
           * If we create the etnry first, we can just repopulate the entry when the populated
           * association comes back, keeping its original order
           */
          existingAssociations[association._id] = undefined;
          promises.push(
            new $q(function (resolve, reject) {
              var innerPromises = [];
              var isDownstream =
                association.downstream_entity._id === self.model._id;
              var relationTypeText = "";
              var building;
              var floor;
              var room;
              var asset;

              if (isDownstream) {
                /**
                 * Meaning this association is upstream from the self.model
                 * ie: self.model IS POWERED BY this association
                 */
                relationTypeText = association.association_type.downstream_text;
                building = association.upstream_entity.building;
                floor = association.upstream_entity.level;
                if (association.upstream_entity_model === "Asset") {
                  asset = association.upstream_entity;
                } else if (association.upstream_entity_model === "Room") {
                  room = association.upstream_entity;
                } else {
                  return; // this association is messed up, don't add it to the inputs
                }
              } else {
                relationTypeText = association.association_type.upstream_text;
                building = association.downstream_entity.building;
                floor = association.downstream_entity.level;

                if (association.downstream_entity_model === "Asset") {
                  asset = association.downstream_entity;
                } else if (association.downstream_entity_model === "Room") {
                  room = association.downstream_entity;
                } else {
                  return; // this association is messed up, don't add it to the inputs
                }
              }

              var relationType;
              var selectedOption = 0;
              for (var i = 0; i < self.relationTypes.length; i++) {
                var currentRelationType = self.relationTypes[i].model;
                if (
                  currentRelationType.associationType._id ===
                    association.association_type._id &&
                  currentRelationType.text === relationTypeText
                ) {
                  // Deep copy this sucker
                  relationType = currentRelationType; //JSON.parse(JSON.stringify(currentRelationType));
                  selectedOption = i + 1;
                  break;
                }
              }

              if (asset) {
                room = asset.room;
              }

              if (room && !room._id && !rooms[room]) {
                innerPromises.push(
                  RoomService.getById(building, room).then(function (
                    fetchedRoom
                  ) {
                    rooms[fetchedRoom._id] = fetchedRoom;
                    room = fetchedRoom;
                  })
                );
              } else if (room && !room._id) {
                room = rooms[room];
              }

              /**
               * We populate the floor first because the building populate logic might change
               * the value of the building variable to an actual building when we need it to
               * remain an id first
               */
              if (floor && !floor._id && !floors[floor]) {
                innerPromises.push(
                  FloorService.getById(building, floor).then(function (
                    fetchedFloor
                  ) {
                    floors[fetchedFloor._id] = fetchedFloor;
                    floor = fetchedFloor;
                  })
                );
              } else if (floor && !floor._id) {
                floor = floors[floor];
              }

              if (building && !building._id && !buildings[building]) {
                // fetch teh building
                innerPromises.push(
                  BuildingService.getById(building).then(function (
                    fetchedBuilding
                  ) {
                    buildings[fetchedBuilding._id] = fetchedBuilding;
                    building = fetchedBuilding;
                  })
                );
              } else if (building && !building._id) {
                building = buildings[building];
              }

              return $q
                .all(innerPromises)
                .then(function () {
                  var newAssociation = createAssociation({
                    _id: association._id,
                    building: {
                      model: building,
                    },
                    floor: {
                      model: floor,
                    },
                    room: {
                      model: room,
                    },
                    asset: {
                      model: asset,
                    },
                    relationType: {
                      model: relationType,
                      selectedOption: selectedOption,
                    },
                    summaryHTML: generateAssociationSummary({
                      relationType: {
                        model: {
                          text: relationTypeText,
                        },
                      },
                      room: {
                        model: room,
                      },
                      asset: {
                        model: asset,
                      },
                    }),
                  });

                  existingAssociations[association._id] = newAssociation;
                  self.inputHashes[generateAssociationHash(newAssociation)] = [
                    association._id,
                  ];
                  resolve(newAssociation);
                })
                .catch(function (err) {
                  reject(err);
                });
            })
          );
        });

        return $q.all(promises).then(function (newAssociations) {
          self.inputs = existingAssociations;
        });
      }
    }

    function setAssociationTypes(organizationId) {
      return AssociationTypeService.getAll(organizationId, {}).then(function (
        associationTypes
      ) {
        associationTypes.sort(function (a, b) {
          var textA = a.upstream_text ? a.upstream_text.toUpperCase() : "";
          var textB = b.upstream_text ? b.upstream_text.toUpperCase() : "";
          return textA.localeCompare(textB);
        });
        self.relationTypes = associationTypes.reduce(function (
          accumulator,
          value
        ) {
          accumulator.push({
            model: {
              _id: Utils.uuidv4(),
              associationType: value,
              text: value.upstream_text,
            },
            value: value.upstream_text,
          });
          accumulator.push({
            model: {
              _id: Utils.uuidv4(),
              associationType: value,
              text: value.downstream_text,
            },
            value: value.downstream_text,
          });
          return accumulator;
        },
        []);
      });
    }

    function setPinTypes(organizationId) {
      return PinTypeService.getAllByOrganization(organizationId).then(function (
        pinTypes
      ) {
        pinTypes.map(function (pinType) {
          self.pinTypes[pinType._id] = pinType;
        });
      });
    }

    function save() {
      /** So we can process all these requests together, using $q.all */

      self.saving = true;
      return new $q(function (resolve, reject) {
        var promises = [];
        for (var i = 0; i < self.associationsMarkedForDeletion.length; i++) {
          var associationId = self.associationsMarkedForDeletion[i];
          promises.push(
            AssociationService.remove(self.organization._id, associationId)
          );
        }

        $q.all(promises)
          .then(function () {
            resolve();
          })
          .catch(function (response) {
            reject(response);
          });
      })
        .then(function () {
          var promises = [];

          /** Go through each of those associations and create the api requests */
          for (var associationId in self.inputs) {
            var association = self.inputs[associationId];
            var relationType = association.relationType.model;
            var associationType = relationType.associationType;
            var downstream_entity;
            var downstream_entity_model;
            var upstream_entity;
            var upstream_entity_model;

            if (relationType.text === associationType.downstream_text) {
              /**
               * This is a downstream relationship
               * ie: self.model "is powered by" room/asset
               */
              downstream_entity = self.model._id;
              downstream_entity_model = Utils.capitalize(
                self.modelType.toLowerCase()
              ); // converts ROOM -> Room
              upstream_entity = association.asset.model
                ? association.asset.model._id
                : association.room.model._id;
              upstream_entity_model = association.asset.model
                ? "Asset"
                : "Room";
            } else {
              /**
               * This is an upstream relationship
               * ie: self.model "powers" room/asset
               */
              downstream_entity = association.asset.model
                ? association.asset.model._id
                : association.room.model._id;
              downstream_entity_model = association.asset.model
                ? "Asset"
                : "Room";
              upstream_entity = self.model._id;
              upstream_entity_model = Utils.capitalize(
                self.modelType.toLowerCase()
              );
            }

            if (associationIsUUIDv4(associationId)) {
              // uses a uuid, so it needs to be created, because existing associations use object ids

              /** push the create call onto the promise stack */
              promises.push(
                AssociationService.create(self.organization._id, {
                  association_type: associationType._id,
                  downstream_entity: downstream_entity,
                  downstream_entity_model: downstream_entity_model,
                  upstream_entity: upstream_entity,
                  upstream_entity_model: upstream_entity_model,
                })
              );
            } else {
              /** These bad boys just need to be updated, not created */
              var originalAssociation = self.associations.find(function (obj) {
                return obj._id === association._id;
              });

              if (
                originalAssociation &&
                associationType._id ===
                  originalAssociation.association_type._id &&
                upstream_entity === originalAssociation.upstream_entity._id &&
                downstream_entity === originalAssociation.downstream_entity._id
              ) {
                // association wasn't changed, we can just skip this update
                continue;
              }

              promises.push(
                AssociationService.update(
                  self.organization._id,
                  association._id,
                  {
                    association_type: associationType._id,
                    downstream_entity: downstream_entity,
                    downstream_entity_model: downstream_entity_model,
                    upstream_entity: upstream_entity,
                    upstream_entity_model: upstream_entity_model,
                  }
                )
              );
            }
          }

          return $q.all(promises).then(function (updatedAssociations) {
            $mdDialog.hide(updatedAssociations);
            self.saving = false;
          });
        })
        .catch(function (response) {
          self.errorMessage = convertResponseToError(response).message;
          self.saving = false;
        });

      // don't use a finally block if we are going to close the dialog
    }

    function generateAssociationHash(association) {
      var building = association.building.model;
      var floor = association.floor.model;
      var room = association.room.model;
      var asset = association.asset.model;
      var relationType = association.relationType.model;
      var associationType = relationType
        ? relationType.associationType
        : undefined;

      return (
        "building_" +
        (building ? building._id : "") +
        "_" +
        "floor_" +
        (floor ? floor._id : "") +
        "_" +
        "room_" +
        (room ? room._id : "") +
        "_" +
        "asset_" +
        (asset ? asset._id : "") +
        "_" +
        "association_type_" +
        (associationType ? associationType._id : "") +
        "_" +
        "stream_" +
        (relationType && associationType
          ? relationType.text === associationType.downstream_text
            ? "down"
            : "up"
          : "_")
      );
    }

    function isDuplicated(association) {
      var hash = generateAssociationHash(association);
      var existingAssociationIds = self.inputHashes[hash];
      if (existingAssociationIds && existingAssociationIds.length > 1) {
        self.hasDuplicatedAssociations = true;
        return true;
      } else {
        return false;
      }
    }

    function isComplete(association) {
      if (
        !association.relationType.error &&
        !association.building.error &&
        !association.floor.error &&
        !association.room.error &&
        !association.asset.error &&
        association.relationType.model &&
        (association.room.model || association.asset.model)
      ) {
        return true;
      } else {
        self.hasIncompleteAssociations = true;
        return false;
      }
    }

    function removeFromInputHashes(hash, associationId) {
      var existingHashes = self.inputHashes[hash];

      if (existingHashes && existingHashes.length <= 1) {
        delete self.inputHashes[hash];
      } else if (existingHashes) {
        var index = existingHashes.indexOf(associationId);
        if (index >= 0) {
          existingHashes.splice(index, 1);
        }
      }
    }

    function addToInputHashes(hash, associationId) {
      var existingHashes = self.inputHashes[hash];

      if (existingHashes && existingHashes.indexOf(associationId) === -1) {
        existingHashes.push(associationId);
      } else if (!existingHashes) {
        self.inputHashes[hash] = [associationId];
      }

      /** Only other possible case is if the association already existed in this array, then we don't need to do anything */
    }

    function convertResponseToError(response) {
      if (angular.isString(response)) {
        return new Error(response);
      } else if (response && response.message && response.stack) {
        // probably already an error
        return response;
      } else if (
        response.data &&
        response.data.error &&
        response.data.error.message
      ) {
        // api error
        return new Error(response.data.error.message);
      } else {
        return new Error("Error ocurred. Please reload this window to fix.");
      }
    }

    function associationIsUUIDv4(associationId) {
      return (
        typeof associationId === "string" &&
        !!associationId.match(
          /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
        )
      );
    }
  }
})();
