(function () {
  angular
    .module("akitabox.core.services.costAdjustment", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("CostAdjustmentService", CostAdjustmentService);

  /* @ngInject */
  function CostAdjustmentService(
    // Angular
    $q,
    // AkitaBox
    HttpService,
    models,
    // Libraries
    CacheFactory
  ) {
    var service = {
      // Retrieve
      create: create,
      get: get,
      getAll: getAll,
      getById: getById,
      getCommon: getCommon,
      remove: remove,
      update: update,
      updateById: updateById,
      getCommonAdjustmentInstance,
    };

    const cache = _createCache();

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------
    function _buildListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.COST_ADJUSTMENT.ROUTE_PLURAL
      );
    }

    /* eslint-disable no-unused-vars */
    function _buildBulkRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.COST_ADJUSTMENT.ROUTE_PLURAL +
        "/common"
      );
    }
    /* eslint-enable no-unused-vars */

    function _buildDetailRoute(organizationId, costAdjustmentId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.COST_ADJUSTMENT.ROUTE_PLURAL +
        "/" +
        costAdjustmentId
      );
    }

    function _createCache() {
      let cache = CacheFactory.get("cost_adjustments");
      if (!cache) {
        cache = CacheFactory("cost_adjustments");
      }
      return cache;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Creates a Cost Adjustment
     *
     * @param {string} organizationId - the organization in which the cost adjustments belongs to
     * @param {object} body
     * @param {string} body.asset - the asset the cost adjustment is against
     * @param {string} body.description - the description of the new cost adjustment
     */
    function create(organizationId, body) {
      if (!organizationId) {
        return $q.reject(
          new Error("organizationId is required to create a cost adjustment")
        );
      } else if (!body.asset) {
        return $q.reject(
          new Error("body.asset is required to create a cost adjustment")
        );
      } else if (!body.description) {
        return $q.reject(
          new Error("body.description is required to create a cost adjustment")
        );
      }

      // Don't neeed config or params
      // cache is used to storage this new cost adjustment into
      // our local ng cache
      return HttpService.post(
        _buildListRoute(organizationId),
        body,
        null,
        null,
        cache
      );
    }

    /**
     * Fetches up to 1000 cost adjustments within organization
     *
     * @param {string} organizationId - organization id in which to fetch cost adjustments
     * @param {Object} [params={}] - additional query strings to attach
     * @return {Promise<CostAdjustment[]>}
     */
    function get(organizationId, params = {}) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      // opting to not use the cache here because the logic
      // does not account for breaking, sorting, etc
      return HttpService.get(_buildListRoute(organizationId), params);
    }

    function getCommonAdjustmentInstance(
      organizationId,
      assetId,
      commonAdjustment
    ) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }
      if (!assetId) {
        return $q.reject(new Error("assetId is required"));
      }
      if (!commonAdjustment.description) {
        return $q.reject(new Error("invalid common adjustment"));
      }

      const adjustmentData = {};
      if (commonAdjustment.adjustment_percent) {
        adjustmentData.adjustment_percent = commonAdjustment.adjustment_percent;
      } else if (commonAdjustment.adjustment_fixed) {
        adjustmentData.adjustment_fixed = commonAdjustment.adjustment_fixed;
      }

      return service.get(organizationId, {
        asset: assetId,
        description: commonAdjustment.description,
        ...adjustmentData,
        limit: 1,
      });
    }

    /**
     * Fetches all cost adjustments within organization
     *
     * @param {string} organizationId - organization id in which to fetch cost adjustments
     * @param {Object} [params={}] - additional query strings to attach
     * @return {Promise<CostAdjustment[]>}
     */
    function getAll(organizationId, params = {}) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      return HttpService.getAll(_buildListRoute(organizationId), params);
    }

    /**
     * Fetches cost adjustments that are common amongst a set of assets
     * TODO: implement this after server side has
     *
     * @param {string} organizationId - organization id in which to fetch cost adjustments
     * @param {Object} [params={}] - additional query strings to attach
     * @return {Promise<CostAdjustment[]>}
     */
    function getCommon(organizationId, params = {}) {
      if (!organizationId) {
        return $q.reject(new Error("Organization id required"));
      }

      if (Array.isArray(params.asset)) {
        params.asset = `$in,${params.asset.join(",")}`;
      }

      return HttpService.get(_buildBulkRoute(organizationId), params);
    }

    /**
     * Fetches a cost adjustment by its id
     *
     * @param {string} organizationId - cost adjustments's oranization id
     * @param {string} costAdjustmentId - cost adjustment's id
     * @return {Promise<CostAdjustment|null>}
     */
    function getById(organizationId, costAdjustmentId) {
      if (!organizationId) {
        return $q.reject(
          new Error("organizationId is required to get a cost adjustment")
        );
      }

      if (!costAdjustmentId) {
        return $q.reject(
          new Error("costAdjustmentId is required to get a cost adjustment")
        );
      }

      /**
       * We don't provide the 2nd, 3rd and 4th params because
       * we don't want to use the cache, currently it doesn't
       * know when to properly update itself
       */
      return HttpService.getById(
        _buildDetailRoute(organizationId, costAdjustmentId),
        null,
        {},
        null
      );
    }

    /**
     * Deletes a cost adjustment
     *
     * @param {string} organizationId - cost adjustments's oranization id
     * @param {string} costAdjustmentId - cost adjustment's id
     * @return {Promise<void>}
     */
    function remove(organizationId, costAdjustmentId) {
      if (!organizationId) {
        return $q.reject(
          new Error("organizationId is required to remove a cost adjustment")
        );
      }

      if (!costAdjustmentId) {
        return $q.reject(
          new Error("costAdjustmentId is required to remove a cost adjustment")
        );
      }

      /**
       * The 2nd and 3rd params are only there to remove this
       * cost adjustment from our local cache
       */
      return HttpService.remove(
        _buildDetailRoute(organizationId, costAdjustmentId),
        costAdjustmentId,
        cache
      );
    }

    /**
     * Updates a cost adjustment (acts as a PATCH, not a real PUT)
     *
     * @param {string} organizationId - cost adjustments's oranization id
     * @param {string} costAdjustmentId - cost adjustment's id
     * @param {object} body - what to update
     * @param {string} [body.desription] - the updated description value
     * @return {Promise<CostAdjustment>} - the cost adjustment with the updated values
     */
    function updateById(organizationId, costAdjustmentId, body) {
      if (!organizationId) {
        return $q.reject(
          new Error("organizationId is required to update a cost adjustment")
        );
      }

      if (!costAdjustmentId) {
        return $q.reject(
          new Error("costAdjustmentId is required to update a cost adjustment")
        );
      }

      /**
       * cache is passed in just to update our cached version
       * of this cost adjustment with the one returned from
       * the api
       */
      return HttpService.patch(
        _buildDetailRoute(organizationId, costAdjustmentId),
        body,
        {},
        cache
      );
    }

    function update(organizationId, costAdjustmentId, body) {
      if (!organizationId) {
        return $q.reject(
          new Error("organizationId is required to update a cost adjustment")
        );
      }

      if (!costAdjustmentId) {
        return $q.reject(
          new Error("costAdjustmentId is required to update a cost adjustment")
        );
      }

      return HttpService.put(
        _buildDetailRoute(organizationId, costAdjustmentId),
        body,
        {},
        cache
      );
    }
  }
})();
