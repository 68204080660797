(function () {
  angular
    .module("akitabox.ui.dialogs.editLocation")
    .factory("EditLocationDialog", EditLocationDialog);

  /* @ngInject */
  function EditLocationDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/edit-location/edit-location.dialog.html",
      controller: "EditLocationDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * EditLocationDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object}          options                  Dialog options
     * @param {Object}          options.locals           Locals scope variables
     * @param {Object}          options.locals.building  Building the model is in
     * @param {Object}          options.locals.model     The model to edit
     * @param {Object}          options.locals.modelType The type of model to edit
     * @param {string}          options.locals.type      Supported for some model types to fine-tune dialog behavior
     *    model type: "task"
     *    type: "append-inspection-stop" - Displays a form for appending a stop to an inspection WO
     * @param {Function}        options.locals.update    The (optional) update function to call
     *
     * @return {Promise<Object|Error>}       Promise that resolves with the newly created floor
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
