/**
 * Takes in a string and returns the same string
 * with the first letter of each word capitalized.
 *
 * @param {string} text - The input string that needs to be capitalized
 * @returns {string} The capitalized version of the provided `text`
 *
 * @example
 * capitalizeText("asset"); // "Asset"
 * capitalizeText("work order"); // "Work Order"
 * capitalizeText("service_requests"); // "Service Requests"
 * capitalizeText("UPPER CASE THING"); // "Upper Case Thing"
 * capitalizeText("maintenance-schedule"); // "Maintenance Schedule"
 */
export function capitalizeText(text: string): string {
  return text
    .split(/[\s_-]/g)
    .map((word) => {
      const wordLength = word.length;
      const first = word[0].toUpperCase();
      const rest = word.slice(-(wordLength - 1)).toLowerCase();
      return `${first}${rest}`;
    })
    .join(" ");
}
