(function () {
  angular
    .module("akitabox.ui.components.collapsibleCard", ["akitabox.core.utils"])
    .component("abxCollapsibleCard", {
      bindings: {
        open: "<?abxOpen",
        onOpen: "&?abxOnOpen",
        onClose: "&?abxOnClose",
        onToggle: "&?abxOnToggle",
      },
      controller: AbxCollapsibleCardController,
      controllerAs: "vm",
      transclude: {
        header: "abxCollapsibleCardHeader",
        content: "abxCollapsibleCardContent",
      },
      templateUrl:
        "app/core/ui/components/collapsible-card/collapsible-card.component.html",
    });

  /* @ngInject */
  function AbxCollapsibleCardController($element, $q, $timeout, Utils) {
    var self = this;

    var ANIMATION_DURATION = 0.35 * 1000;
    var $section = $element.find("section")[0];

    // Attributes
    self.visible = true;
    self.toggleDirection = "up";

    // Functions
    self.handleToggle = handleToggle;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (angular.isDefined(self.open)) {
        if (!self.open) {
          self.toggleDirection = "down";
          hideContent();
        }
      } else {
        self.open = true;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.open && self.visible !== self.open) {
        toggle();
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function handleToggle($event) {
      if (self.onToggle) {
        $q.resolve(self.onToggle({ $event: $event })).then(function (
          preventToggle
        ) {
          if (!preventToggle) {
            toggle();
          }
        });
      } else {
        toggle();
      }
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function toggle() {
      if (self.visible) {
        self.toggleDirection = "down";
        hideContent();
        if (self.onClose) {
          self.onClose();
        }
      } else {
        self.toggleDirection = "up";
        showContent();
        if (self.onOpen) {
          self.onOpen();
        }
      }
    }

    function showContent() {
      self.visible = true;
      $timeout(function () {
        Utils.expandElement($section);
      }, 50);
    }

    function hideContent() {
      Utils.collapseElement($section).then(function () {
        $timeout(function () {
          self.visible = false;
        }, ANIMATION_DURATION);
      });
    }
  }
})();
