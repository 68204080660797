(function () {
  /**
   * @ngdoc component
   * @name abxFloorInputComponent
   *
   * @param {String} buildingId - currently selected building's id.
   * @param {Boolean} disabled - if input is disabled.
   * @param {Function} onSelect - To be invoked when a floor is selected
   * @param {Function} onChange - To be invoked when the value in the input has
   *     changed (i.e. a `keyup` event), but the input is not blurred. Should
   *     be invoked with: event.model, event.value, [event.invalid].
   * @param {Function} onFocus - To be invoked when the input is focused
   * @param {Function} onBlur - To be invoked when the input blurs
   * @param {Object} value - display value for currently selected floor
   * @param {Boolean} [blurOnEnter=false] - Blur the input on Enter keypress.
   *
   * @description
   * Floor input component
   */
  angular
    .module("akitabox.ui.components.floorInput")
    .component("abxFloorInputComponent", {
      bindings: {
        buildingId: "<abxBuildingId",
        model: "<abxModel",
        disabled: "<abxDisabled",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        onBlur: "&abxOnBlur",
        value: "<abxValue",
        blurOnEnter: "<abxBlurOnEnter",
      },
      controller: AbxFloorInputComponentController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/floor-input/floor-input.component.html",
    });

  function AbxFloorInputComponentController(
    // Services
    FloorService,
    ToastService,
    Utils
  ) {
    var self = this;

    // Private
    var floorsRequest;

    // Attributes
    self.floorOptions = []; // Options objects consumable by the typeahead
    self.loading = false;
    self.blurOnEnter = self.blurOnEnter || false;

    // Functions
    self.handleFocus = handleFocus;

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      var buildingChanged = Utils.hasChanged(changes.buildingId);
      if (buildingChanged) {
        // The next focus will do a fresh floors request
        floorsRequest = undefined;
        self.floorOptions = [];
        if (self.model) {
          // If model's building is different, clear out input and trigger change
          const modelBuildingId = self.model.building
            ? self.model.building._id || self.model.building
            : null;
          if (!Utils.isSame(modelBuildingId, self.buildingId)) {
            var $event = {
              model: null,
              value: null,
            };
            self.onChange({ $event: $event });
          }
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Handle input focus
     */
    function handleFocus() {
      self.onFocus();

      if (!floorsRequest) {
        floorsRequest = getFloors(self.buildingId);
      }
    }

    // =================
    // Public Functions
    // =================
    /**
     * Fetch floors for the given building and populate self.floorOptions
     * with them. Toasts on error.
     *
     * @param {String} buildingId - The building to fetch floors for
     * @return {Promise} - A promise that resolves with the floors once
     *    self.floorOptions has been populated.
     */
    function getFloors(buildingId) {
      self.loading = true;
      return FloorService.getAll(buildingId)
        .then(function (floors) {
          createEnumObjects(floors);
          return floors;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Update self.floorOptions with the given floors, thus providing
     * all of the floors as options to the typeahead.
     *
     * @param {Object[]} floors - The floors to provide to the typeahead
     */
    function createEnumObjects(floors) {
      self.floorOptions = [];
      for (var floor = 0; floor < floors.length; floor++) {
        self.floorOptions.push(createValueObject(floors[floor]));
      }
    }

    /**
     * Helper function to create a valueObject for the typeahead
     *
     * @param {Object} floor - The floor to create a valueObject for
     * @return {Object} - A valueObject populated with the floor as the value,
     *    the floor's name as the label, and the floor's mongo id as id.
     */
    function createValueObject(floor) {
      if (!floor) {
        return {
          model: null,
          value: null,
        };
      }
      return {
        model: floor,
        value: floor.name,
      };
    }
  }
})();
