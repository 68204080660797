(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.delete")
    .controller(
      "DeleteWorkOrderDialogController",
      DeleteWorkOrderDialogController
    );

  /* @ngInject */
  function DeleteWorkOrderDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    FeatureFlagService,
    ToastService,
    WorkOrderService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since workOrderIDs is passed in through locals
    self.multiple = self.workOrders ? self.workOrders.length > 1 : false;

    self.buildingId = self.building ? self.building._id : self.buildingId;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteWorkOrder = deleteWorkOrder;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete work orders
     */
    function deleteWorkOrder() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.workOrders) * 100;
      var promiseArray = [];

      // Delete the work orders
      for (var i = 0; i < self.workOrders.length; i++) {
        const buildingId =
          self.workOrders[i].building && self.workOrders[i].building._id
            ? self.workOrders[i].building._id
            : self.workOrders[i].building;
        promiseArray[i] = WorkOrderService.remove(
          buildingId,
          self.workOrders[i]._id
        )
          // when we delete a work order, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.workOrders[storedI]._id;
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedWorkOrderIDs) {
          $mdDialog.hide(deletedWorkOrderIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
