(function () {
  angular
    .module("akitabox.core.services.job", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.http",
      "akitabox.core.services.pinType",
      "akitabox.core.services.organization",
    ])
    .factory("AdminJobService", AdminJobService);

  /** @ngInject */
  function AdminJobService(
    $q,
    $log,
    models,
    FeatureFlagService,
    FileService,
    HttpService,
    PinTypeService,
    OrganizationService
  ) {
    var service = {
      // Routes
      buildListRoute: buildListRoute,
      buildDetailRoute: buildDetailRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getById: getById,
      getAll: getAll,
      getBuildingJobs: getBuildingJobs,
      getAccountJobs: getAccountJobs,
      getEnvJobs: getEnvJobs,
      getScripts: getScripts,
      getModels: getModels,
      getSteps: getSteps,
      getStep: getStep,
      downloadOrganizationTemplate: downloadOrganizationTemplate,
      downloadBuildingTemplate: downloadBuildingTemplate,
      downloadPinTypeTemplate: downloadPinTypeTemplate,
      downloadCompletedWoTemplate: downloadCompletedWoTemplate,
      // Cancel
      cancel: cancel,
    };

    var PIN_MODELS = [models.ASSET.MODEL, models.ROOM.MODEL];

    var exportableModels = [];
    var importableModels = [];
    var modelToRoutePlurals = {};

    init();

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      // build array of models that can be exported and imported
      // and a map between the model names and readable names
      var keys = Object.keys(models);
      for (var i = 0; i < keys.length; ++i) {
        var model = models[keys[i]];
        modelToRoutePlurals[model.MODEL] = model.ROUTE_PLURAL;
        var isPin = PIN_MODELS.indexOf(model.MODEL) !== -1;

        if (model.CAN_EXPORT) {
          exportableModels.push({
            name: model.PLURAL,
            model: model.MODEL,
            inBuilding: model.BUILDING,
          });
        }
        if (model.CAN_IMPORT && !isPin) {
          importableModels.push({
            name: model.PLURAL,
            model: model.MODEL,
          });
        }
      }

      exportableModels.push({
        name: "Whole Building",
        staircase_id: "whole-building-export",
      });
    }

    function _downloadImportTemplate(route, model, query) {
      if (!query) query = {};
      query.export = "csv";
      query.blank_import = true;

      var routeModel = modelToRoutePlurals[model];
      route += "/" + routeModel + HttpService.buildQueryString(query);

      return FileService.download(route);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute() {
      return "/admin/" + models.JOB.ROUTE_PLURAL;
    }

    function buildDetailRoute(jobId) {
      var base = buildBaseRoute();
      return base + "/" + jobId;
    }

    function buildListRoute() {
      return buildBaseRoute();
    }

    /******************* *
     *   Create
     * ****************** */

    function create(accountId, buildingId, data) {
      if (accountId) data.target_account = accountId;
      if (buildingId) data.target_building = buildingId;
      var route = buildListRoute();
      var request;
      // If the data contains a file we need to upload it
      // along with the rest of the data
      if (Object.prototype.hasOwnProperty.call(data, "file")) {
        var file = data.file;
        var extras = angular.copy(data);
        delete extras.file;
        if (Object.prototype.hasOwnProperty.call(extras, "data")) {
          extras.data = JSON.stringify(extras.data);
        }
        var onProgress = function () {}; // no-op fn, we dont care about the progress
        request = FileService.upload(file, route, extras, onProgress).then(
          function (res) {
            return res.data;
          }
        );
      } else {
        request = HttpService.post(route, data);
      }

      return request.then(function (job) {
        if (!job) return $q.reject("Failed to create job");
        // Fetch job to get updated name
        return service.getById(job._id);
      });
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(params) {
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getById(jobId, params) {
      var route = buildDetailRoute(jobId);
      return HttpService.getById(route, jobId, params);
    }

    function getAll(params) {
      var route = buildListRoute();
      return HttpService.getAll(route, params);
    }

    function getBuildingJobs(buildingId, params) {
      if (!params) params = {};
      params.target_building = buildingId;
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getAccountJobs(accountId, params) {
      if (!params) params = {};
      params.target_account = accountId;
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getEnvJobs(params) {
      if (!params) params = {};
      params.target_account = "$eq,null";
      params.target_building = "$eq,null";
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getScripts(params) {
      return HttpService.getAll("/admin/staircases", params);
    }

    function getModels(organizationId, buildingId, exportable) {
      var completedWOModel = {
        name: "Work Orders - Completed",
        model: "task",
        inBuilding: true,
        isCompletedWO: true,
      };
      if (exportable) {
        var _models = exportableModels.slice();
        _models.push(completedWOModel);
        // remove Assembly & CostLinbe by default
        _models = _models.filter(function (model) {
          return (
            model.model !== models.ASSEMBLY.MODEL &&
            model.model !== models.COST_LINE.MODEL
          );
        });

        // check org for `show_rpi_fci` feature option
        return OrganizationService.getById(organizationId)
          .then(function (organization) {
            // add Assembly & CostLine if `show_rpi_fci` is enabled
            if (organization && organization.show_rpi_fci) {
              _models = exportableModels.slice();
              _models.push(completedWOModel);
            }
            return $q.resolve(_models);
          })
          .catch(function (err) {
            $log.error("AdminJobService:", err);
            return $q.resolve(_models);
          });
      }

      var allModels = angular.copy(importableModels);

      allModels.push(completedWOModel);

      // If importing, we need to add pin types
      return PinTypeService.getAll(buildingId)
        .then(function (pinTypes) {
          for (var i = 0; i < pinTypes.length; ++i) {
            var pinType = pinTypes[i];
            var type = pinType.protected_type.toLowerCase();
            var name;
            if (type === "room") {
              name = models.ROOM.PLURAL;
            } else if (type === "asset") {
              name = models.ASSET.PLURAL + " - " + pinType.name;
            }
            allModels.push({
              name: name,
              model: type,
              pinType: pinType._id,
            });
          }
          allModels.push({
            name: "Assets - All Types",
            model: "asset",
            pinType: "all",
          });
          return allModels;
        })
        .catch(function (err) {
          $log.error("AdminJobService:", err);
          return importableModels;
        });
    }

    function getSteps(jobId, params) {
      var route = buildDetailRoute(jobId) + "/" + models.STEP.ROUTE_PLURAL;
      return HttpService.get(route, params);
    }

    function getStep(jobId, stepId, params) {
      var route =
        buildDetailRoute(jobId) + "/" + models.STEP.ROUTE_PLURAL + "/" + stepId;
      return HttpService.get(route, params);
    }

    function downloadOrganizationTemplate(organizationId, model) {
      var route = "/organizations/" + organizationId;
      return _downloadImportTemplate(route, model);
    }

    function downloadBuildingTemplate(buildingId, model) {
      var route = "/buildings/" + buildingId;
      var query = { import: "building_template_download" };
      return _downloadImportTemplate(route, model, query);
    }

    function downloadPinTypeTemplate(buildingId, model, pinType) {
      var route = "/buildings/" + buildingId;
      var query = {
        import: "building_template_download",
        pinType: pinType,
      };
      return _downloadImportTemplate(route, model, query);
    }

    function downloadCompletedWoTemplate(buildingId, model) {
      var route = "/buildings/" + buildingId;
      var query = {
        import: "building_template_download",
        isCompletedWO: true,
      };
      return _downloadImportTemplate(route, model, query);
    }

    /******************* *
     *   Cancel
     * ****************** */

    function cancel(jobId) {
      var data = { action: models.JOB.ACTIONS.CANCEL };
      var route = buildDetailRoute(jobId);
      return HttpService.patch(route, data);
    }
  }
})();
