(function () {
  /**
   * @ngdoc component
   * @name abxMarkupGenericLayerList
   *
   * @param {Function} addNewMarkup - Opens dialog to create new user markup
   * @param {Boolean} isOpen - Show/Hide dropdown content
   *
   * @description
   * Container for a list of user markups
   */
  angular.module("akitabox.planView").component("abxMarkupGenericLayerList", {
    bindings: {
      headerTitleText: "@abxHeaderTitleText",
      headerButtonIcon: "@abxHeaderButtonIcon",
      headerButtonText: "@abxHeaderButtonText",
      headerButtonAction: "<?abxHeaderButtonAction",
      loading: "<?abxLoading",
      numberOfMarkups: "<?abxNumberOfMarkups",
    },
    controller: abxMarkupGenericLayerListController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/markup-tool-drawer/markup-tool-dropdowns/markup-generic-layer-list/markup-generic-layer-list.html",
    transclude: true,
  });

  /* @ngInject */
  function abxMarkupGenericLayerListController(
    // Angular
    $document,
    $element,
    $scope
  ) {
    var self = this;

    self.$onInit = init;
    self.$onDestroy = destroyHandler;
    self.transcludePresent = transcludePresent;

    function transcludePresent() {
      return $element.find("ng-transclude")[0].children.length > 0;
    }

    function init() {}

    function destroyHandler() {}
  }
})();
