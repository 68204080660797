(function () {
  angular.module("akitabox.ui.components.input", [
    "ngMessages",
    "akitabox.constants",
    "akitabox.core.directives.focusOut",
    "akitabox.core.filters.urlSchemePrefix",
    "akitabox.core.services.helpers",
    "akitabox.core.utils",
    "akitabox.ui.components.input.address",
    "akitabox.ui.components.input.asset",
    "akitabox.ui.components.input.assignee",
    "akitabox.ui.components.input.asyncSelect",
    "akitabox.ui.components.input.building",
    "akitabox.ui.components.floorInput",
    "akitabox.ui.components.roomInput",
    "akitabox.ui.components.input.roundTemplate",
    "akitabox.ui.components.typeAheadInput",
    "akitabox.ui.components.typeAheadDynamicInput",
    "ui.mask",
  ]);
})();
