(function () {
  angular.module("akitabox.planView").component("abxFloorDropdownSelect", {
    bindings: {
      buildingId: "<abxBuildingId",
      selectedFloorId: "<?abxSelectedFloorId", // the initially selected floor, string value (floor._id)
      onFloorSelect: "&?abxOnFloorSelect", // called in template like so => vm.onFloorSelect(floor)
    },
    controller: FloorDropdownSelectController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/floor-dropdown-select/floor-dropdown-select.component.html",
  });

  /** @ngInject */
  function FloorDropdownSelectController(
    ChecklistService,
    FloorService,
    OrganizationService,
    ToastService,
    // Third party
    $stateParams
  ) {
    var self = this;
    var floorFilters = [
      { publicAlias: "All Floors", filter: "all" },
      { publicAlias: "Floors With Stops", filter: "stops-only" },
    ];

    self.floors = [];
    self.selectedFloorFilter = floorFilters[1];
    self.floorFilterOptions = floorFilters.map(function (filter) {
      return filter.publicAlias;
    });
    self.loading = false;
    // Our internally tracked selected floor
    self._selectedFloor = {};

    /**
     * Handles what should happen when the user manually ineracts with the select dropdown list
     * @param {} $event
     * @param {Floor} $event.model - floor's entire model
     * @param {string} $event.value - floor's name
     */
    self.handleFloorSelect = function ($event) {
      if (typeof self.onFloorSelect === "function") {
        self.loading = true;
        self._selectedFloor = $event.model;
        self.onFloorSelect({ floor: $event.model }).finally(function () {
          self.loading = false;
        });
      }
    };

    self.changeFloorFilter = function ($event) {
      var model = $event.model;
      self.selectedFloorFilter = floorFilters.find(function (filter) {
        return filter.publicAlias === model;
      });

      fetchAndSetDropdownOptions(self.buildingId);
    };

    // Life Cycle fns
    self.$onInit = function () {
      self.loading = true;
      fetchAndSetDropdownOptions(self.buildingId)
        .then(function () {
          if (self.selectedFloorId) {
            // They wanna set the selected floor
            setSelectedFloor(self.selectedFloorId);
          }
        })
        .finally(function () {
          self.loading = false;
        });
    };

    self.$onChanges = function (changes) {
      // We let $onInit handle the first changes, so we ignore them here
      if (changes.buildingId && !changes.buildingId.isFirstChange()) {
        if (changes.buildingId.currentValue) {
          fetchAndSetDropdownOptions(changes.buildingId.currentValue);
        } else {
          self.floors = [];
        }
      }

      if (
        changes.selectedFloorId &&
        !changes.selectedFloorId.isFirstChange() &&
        self.floors.length
      ) {
        setSelectedFloor(changes.selectedFloorId.currentValue);
      }
    };

    // Private helper fns

    /**
     * Fetches and sets the components dropdown options of floors
     * @param {string} buildingId - building to pull the floors from
     */
    function fetchAndSetDropdownOptions(buildingId) {
      self.floors = [];
      if (self.selectedFloorFilter.filter === "stops-only") {
        // get floor ids associated with checklists on the selected task
        var org = OrganizationService.getCurrent();
        var statParams = {
          task: $stateParams.round_task,
          group_field: "level",
        };

        return ChecklistService.getStats(org._id, statParams)
          .then(function (stats) {
            var floorIds = [];
            for (var i = 0; i < stats.length; i++) {
              var floorGroup = stats[i];
              if (floorGroup._id) {
                floorIds.push(floorGroup._id);
              }
            }
            if (floorIds.length) {
              var idQuery = "$in," + floorIds.join(",");
              // Make sure we only grab floors that have floor plans
              return FloorService.getAll(
                buildingId,
                { document: "$ne,null", _id: idQuery },
                false
              );
            } else {
              return [];
            }
          })
          .then(function (floors) {
            self.floors = floors.map(function (floor) {
              return {
                model: floor,
                value: floor.name,
              };
            });
          })
          .catch(ToastService.showError);
      } else {
        return FloorService.getAll(buildingId, { document: "$ne,null" }, false)
          .then(function (floors) {
            self.floors = floors.map(function (floor) {
              return {
                model: floor,
                value: floor.name,
              };
            });
          })
          .catch(ToastService.showError);
      }
    }

    /**
     * Sets the components <select> value manually
     * Does not trigger onFloorSelect()
     * @param {string} floorId - the id of the floor to be selected
     */
    function setSelectedFloor(floorId) {
      if (!floorId) {
        self._selectedFloor = undefined;
      } else if (!self.floors.length) {
        ToastService.showError(new Error("There are no floors to select from"));
      } else {
        var found = false;
        for (var i = 0; i < self.floors.length; i++) {
          if (self.floors[i].model._id === floorId) {
            self._selectedFloor = self.floors[i].model;
            found = true;
            break;
          }
        }

        if (!found) {
          ToastService.showError(
            new Error("Cannot find indicated floor to select from")
          );
        }
      }
    }
  }
})();
