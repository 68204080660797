(function () {
  angular
    .module("akitabox.core.services.request", [
      "akitabox.constants",
      "akitabox.core.services.attachment",
      "akitabox.core.services.filter",
      "akitabox.core.services.http",
      "akitabox.core.services.virtualRepeat",
    ])
    .factory("RequestService", RequestService);

  /* @ngInject */
  function RequestService(
    $q,
    // AkitaBox
    models,
    // Services
    AttachmentService,
    HttpService,
    WorkOrderService,
    BuildingService,
    FilterService,
    VirtualRepeatService
  ) {
    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getWithPopulatedTask: getWithPopulatedTask,
      getByOrganization: getByOrganization,
      getByOrganizationWithPopulatedTask: getByOrganizationWithPopulatedTask,
      getByOrganizationWithPopulatedBuilding:
        getByOrganizationWithPopulatedBuilding,
      getById: getById,
      getAll: getAll,
      getAllByOrganization: getAllByOrganization,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrganization: getStatsByOrganization,
      // Update
      update: update,
      deny: deny,
      reopen: reopen,
      // Delete
      remove: remove,
      // Virtual Repeat
      getVirtualRepeat: getVirtualRepeat,
      getOrgVirtualRepeat: getOrgVirtualRepeat,
      // Filters
      getBuildingListFilter: getBuildingListFilter,
      setBuildingListFilter: setBuildingListFilter,
      // Attachments
      getAttachments: getAttachments,
    };

    return service;

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute(buildingId) {
      var buildingUri = "/" + models.BUILDING.ROUTE_PLURAL + "/" + buildingId;
      return buildingUri + "/" + models.SERVICE_REQUEST.ROUTE_PLURAL;
    }

    function buildDetailRoute(buildingId, requestId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + requestId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.SERVICE_REQUEST.ROUTE_PLURAL
      );
    }

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildListRoute(buildingId);
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getByOrganizationWithPopulatedBuilding(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params).then(populateBuildingOnRequest);
    }

    function getWithPopulatedTask(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params).then(populateTaskOnRequests);
    }

    function getByOrganizationWithPopulatedTask(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params).then(populateTaskOnRequests);
    }

    /**
     * Deep populates `task` when provided
     * Used primarily to deep populate `assignees`
     */
    function populateTaskOnRequests(serviceRequests) {
      if (serviceRequests && serviceRequests.length) {
        var requests = serviceRequests.map(function (sr) {
          if (sr && sr.task) {
            return WorkOrderService.getById(sr.building, sr.task._id).then(
              function (wo) {
                sr.task = wo;
                return sr;
              }
            );
          } else {
            return sr;
          }
        });
        return $q.all(requests);
      } else {
        return serviceRequests;
      }
    }

    /**
     * Deep populate `building` when provided
     * Used primarily to deep populate `name`
     */
    function populateBuildingOnRequest(serviceRequests) {
      if (serviceRequests && serviceRequests.length) {
        var requests = serviceRequests.map(function (sr) {
          if (sr && sr.building) {
            return BuildingService.getById(sr.building).then(function (bldg) {
              sr.building = bldg;
              return sr;
            });
          } else {
            return sr;
          }
        });
        return $q.all(requests);
      } else {
        return serviceRequests;
      }
    }

    function getAll(buildingId, params, useCache) {
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, params, useCache);
    }

    function getAllByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, id, params) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params);
    }

    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getStatsByOrganization(organizationId, params) {
      var route = "/stats" + buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, id, data) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.put(route, data);
    }

    function deny(buildingId, id, data) {
      data.action = "deny";
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data);
    }

    function reopen(buildingId, id) {
      var data = { action: "reopen" };
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, id) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.remove(route);
    }

    /******************* *
     *   Virtual Repeat
     * ****************** */

    function getVirtualRepeat(buildingId, fetchLimit, query) {
      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service.get(buildingId, params);
      }
    }

    function getOrgVirtualRepeat(fetchLimit, query, orgId) {
      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service.getByOrganizationWithPopulatedBuilding(orgId, params);
      }
    }

    /******************* *
     *   Filters
     * ****************** */

    /**
     * Retrieve cached building service request list filter
     *
     * @param  {String} buildingId
     * @return {Object}
     */
    function getBuildingListFilter(buildingId) {
      return FilterService.getBuildingFilter(buildingId, "request");
    }

    /**
     * Cache the provided building service request list filter
     *
     * @param {String} buildingId
     * @param {Object} filter
     */
    function setBuildingListFilter(buildingId, filter) {
      FilterService.setBuildingFilter(buildingId, "request", filter);
    }

    /******************* *
     *   Attachments
     * ****************** */

    function getAttachments(buildingId, requestId, params) {
      var requiredParams = {
        entity_id: requestId,
        entity_type: "request",
      };

      params = Object.assign({}, params, requiredParams);

      return AttachmentService.getAll(buildingId, params);
    }
  }
})();
