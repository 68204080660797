(function () {
  /**
   * @ngdoc component
   * @name abxTreeInput
   *
   * @description
   * Input for a tree field.
   *
   * @param {Boolean} [disabled] - Disable all inputs. Defaults to false.
   * @param {Function} onBlur - To be called when a field for any level of the
   *     tree loses focus with a different value than it originally had.
   * @param {Object} pinField - Pin field model for this tree
   * @param {Boolean} [required] - All levels of the tree are required. Defaults
   *     to false.
   * @param {String[]} selectedValues - Currently selected values for each
   *     level of the tree. The first value should be for the first level, the
   *     second for the second level, and so on.
   * @param {Boolean} [saveOnBlur] - Save on any tree level input blur. Defaults
   *     to falsey value.
   * @param {Boolean} [readOnly] - Property associated with displaying input values
   *    as readonly text
   *
   * @callback onBlur
   * @param {Object} $event
   * @param {String[]} $event.newValue - New selected values for this input that
   *     reflects the values after a field blurs
   *
   * A "tree" is a data type for our pin fields and pin values. It represents
   * a hierarchy of values that depend on their parent. In other words, for
   * every "level" of the tree, this input will have an input field for it
   * (which will all be text inputs). The selectable values for any level of
   * the tree are based on the selected values above it. In other words,
   * for a value to be selectable, one of its ancestors needs to be selected.
   *
   * Note: We have to call this an `abxTreeInputComponent`, since there already
   * exists a tree input directive with the namespace `abxTreeInput` in the
   * `akitabox.ui.components` module.
   */
  angular
    .module("akitabox.ui.components.treeInput")
    .component("abxTreeInputComponent", {
      bindings: {
        disabled: "<?abxDisabled",
        onBlur: "&abxOnBlur",
        pinField: "<abxPinField",
        required: "<?abxRequired",
        selectedValues: "<abxSelectedValues",
        saveOnBlur: "<abxSaveOnBlur",
        readOnly: "<?abxReadOnly",
      },
      controller: AbxTreeInputController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/tree-input/tree-input.component.html",
    });

  function AbxTreeInputController(
    // Factories
    TreeLevelFactory,
    // Services
    TreeService
  ) {
    var self = this;

    // Functions
    self.onValueChange = onValueChange;

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.pinField) {
        if (self.pinField) {
          initTree();
        } else {
          self.levels = null;
        }
      }

      if (changes.selectedValues && self.levels) {
        setSelectedNodes();
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Handle changes to a level of the tree.
     *
     * @param {Object} event - Propagated event
     * @param {String} event.newValue - New value for the level. Equivalent to
     *     the name of the newly selected node for that level.
     * @param {Object} level - Level whose selected value is changing
     */
    function onValueChange(event, level) {
      var nodeName = event.newValue || event.model;
      level.selectNodeByName(nodeName);

      var selectedValues = getSelectedValues();
      return self.onBlur({
        $event: { newValue: selectedValues },
      });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Initialize necessary data for the tree. This is based on
     * the given pin field's data structure for the tree.
     */
    function initTree() {
      TreeService.getByPinField(self.pinField).then(function (tree) {
        initLevels(tree);
        setSelectedNodes();
      });
    }

    /**
     * Initialize the levels of the tree. This includes setting the label, and
     * linking neighbor levels to each other. It does not include selecting any
     * nodes for the levels.
     *
     * @param {Object[]} tree - Tree to initialize levels for
     */
    function initLevels(tree) {
      self.levels = [];

      // Since trees don't technically have a root node, we have to make the
      // first level in a one-off
      var firstLevel = TreeLevelFactory.create({
        label: self.pinField.name,
        selectableNodes: tree,
      });
      self.levels.push(firstLevel);

      var parentLevel = firstLevel;
      while (parentLevel) {
        var level = TreeLevelFactory.create({
          parentLevel: parentLevel,
        });

        self.levels.push(level);
        parentLevel.childLevel = level;

        // Stop making levels if this is the last in the tree
        parentLevel = level.isLastLevel() ? null : level;
      }
    }

    /**
     * Set the selected nodes for each level of the tree based on the currently
     * selected values.
     */
    function setSelectedNodes() {
      if (!self.selectedValues) return;
      self.levels.forEach(function (level, index) {
        var selectedValue = self.selectedValues[index];
        level.selectNodeByName(selectedValue);
      });
    }

    /**
     * Get the selected values for each level of the tree. This means returning
     * the string value for each level, _not_ the node itself.
     *
     * @return {String[]} - Values for each level of the tree
     */
    function getSelectedValues() {
      return self.levels.map(getSelectedValue).filter(isValidValue);

      function getSelectedValue(level) {
        return level.getSelectedValue();
      }

      function isValidValue(value) {
        // The back end expects to get no empty elements
        return !angular.isEmpty(value);
      }
    }
  }
})();
