(function () {
  angular
    .module("akitabox.desktop.building")
    .controller("BuildingController", BuildingController);

  /* @ngInject */
  function BuildingController(
    // Services
    BuildingService,
    TagCategoryService,
    ToastService,
    // Resolve
    building,
    // Dialogs
    SelectDocumentDialog
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.BuildingService = BuildingService;

    // Functions
    self.changeThumbnail = changeThumbnail;

    /**
     * Method that allows for the change of a building's thumbnail
     */
    function changeThumbnail() {
      var locals = {
        building: building,
        title: "Change Thumbnail",
        uploadText: "Upload Thumbnail",
        allowMultiple: true,
      };

      return TagCategoryService.getAll(building._id)
        .then(function (categories) {
          locals.tagCategories = categories;
          return SelectDocumentDialog.show({ locals: locals });
        })
        .then(function (document) {
          var data = { thumbnail: document._id };
          return BuildingService.update(self.building._id, data);
        })
        .then(function (updatedBuilding) {
          self.building = updatedBuilding;
          angular.extend(building, updatedBuilding);
        })
        .catch(ToastService.showError);
    }
  }
})();
