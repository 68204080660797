(function () {
  /**
   * @ngdoc component
   * @name abxBlockRadio
   *
   * @description
   * Radio input that displays each option as a block element
   *
   * @param {String[]} options - Options to select from
   * @param {String} [selected] - Currently selected option
   * @param {Function} onChange - Change listener to be invoked on option
   *     select. Will not get invoked if the already-selected option is selected
   *     again.
   *
   * @callback onChange
   * @param {Object} $event
   * @param {String} $event.selected - Newly selected option
   *
   * @example
   * <abx-block-radio
   *   abx-options="{{ ["one", "two", "three"] }}"
   *   abx-selected="{{ 'two' }}"
   *   abx-on-change="vm.handleChange($event)">
   * </abx-block-radio>
   */
  angular
    .module("akitabox.ui.components.blockRadio", [])
    .component("abxBlockRadio", {
      bindings: {
        options: "<abxOptions",
        selected: "<?abxSelected",
        onChange: "&abxOnChange",
      },
      controller: BlockRadioController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/block-radio/block-radio.component.html",
    });

  function BlockRadioController() {
    var self = this;

    // Functions
    self.selectOption = selectOption;

    // =================
    // Public Functions
    // =================

    /**
     * Select the given option
     *
     * @param {String} option - Option to select
     */
    function selectOption(option) {
      if (option === self.selected) return;

      self.selected = option;
      self.onChange({ $event: { selected: option } });
    }
  }
})();
