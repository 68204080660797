/**
 * @file This angularjs module allows for runtime control over
 * registration of individual app states. In practice, this is used
 * to disable angularjs routes under specific feature flags.
 */
import { getRouteFromAngularState } from "../../react/legacy/angularUiRouterUtils";

/**
 * If a state is specified as a key of this object, it will
 * not be registered when the specified flag is enabled.
 */
const statesToFlags = {
  // example entry:
  // "app.building.assets": "react_asset_listview",
};

angular
  .module("akitabox.core.router.react", [
    "ui.router",
    "ui.router.util",
    "akitabox.core.services.flag",
  ])
  .provider("reactControlledStates", doNotRegisterReactRoutes)
  .run(patchStateGo);

/**
 * This provider patches `$stateProvider.state()` to allow us to
 * dynamically skip registering certain routes at runtime. This is
 * used to facilitate strangling routes over to the react application
 * based on feature flags.
 *
 * This provider's `$get` value is a map of state names to state configs.
 * It will contain all states that were prevented from being registered.
 * @ngInject
 **/
function doNotRegisterReactRoutes($stateProvider) {
  this.$get = reactControlledStates;

  /** @ngInject */
  function reactControlledStates($q, FeatureFlagService) {
    const reactControlledStates = {};
    decorate(
      $stateProvider,
      "state",
      (stateProviderDotState, stateName, stateConfig) => {
        if (statesToFlags[stateName]) {
          if (FeatureFlagService.isEnabled(statesToFlags[stateName])) {
            reactControlledStates[stateName] = stateConfig;
            return $q.resolve();
          }
        }
        return stateProviderDotState(stateName, stateConfig);
      }
    );
    return reactControlledStates;
  }
}

/**
 * Part 2 of the required setup for react-owned states. Patches
 * `$state.go` to a react state to perform the appropriate
 * `$location` call instead of loading the angularjs view. This
 *  results in the react app having the ability to take over
 *  these routes, while we keep thnings like `abx-sref` and `Router.go`
 *  working.
 *  @ngInject
 **/
function patchStateGo(
  $q,
  $state,
  $location,
  $urlMatcherFactory,
  $urlRouter,
  reactControlledStates
) {
  decorate($state, "go", (stateDotGo, to, params, options) => {
    // check if this is a react controlled state
    if (typeof to === "string" && reactControlledStates[to]) {
      let baseUrl = "/";
      if (to.includes(".")) {
        // get the full parent path
        const parentStateName = to.substring(0, to.lastIndexOf("."));
        baseUrl = getRouteFromAngularState($state.get(parentStateName), $state);
      }
      const forgottenState = reactControlledStates[to];

      // construct the route's full path
      // something like `/buildings/:buildingId/assets?foo&bar&baz
      const url = baseUrl + forgottenState.url;

      // use state params to convert the path to a real url
      // something like `/buildings/622a8fdac7d57f90d9f1d9fd/assets?foo=5&bar=10
      const matcher = $urlMatcherFactory.compile(url);
      const builtUrl = $urlRouter.href(matcher, params, options);
      $location.url(builtUrl);
      return $q.resolve();
    }
    return stateDotGo(to, params, options);
  });
}

/**
 * Decorate a function on an object.
 * @param {object} obj
 * @param {string|number|symbol} key
 * @return { void }
 */
function decorate(obj, key, fn) {
  let originalFn = obj[key];
  if (!originalFn || typeof originalFn !== "function") {
    throw new Error("Cannot decorate, no original function found");
  }

  originalFn = originalFn.bind(obj);
  obj[key] = (...args) => fn(originalFn, ...args);
}
