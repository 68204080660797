/**
 * @file This file ensures that browsers exclusively supporting the webRTC
 * window.navigator.mediaDevices.getUserMedia API also present the legacy
 * window.navigator.getUserMedia API since our <webcam> and <bc-qr-reader>
 * directives rely on that function being present.
 */
(function () {
  function isFunction(fn) {
    return fn && typeof fn === "function";
  }
  if (isFunction(window.navigator.getUserMedia)) {
    // no need to apply, browser already has what we need
    return;
  }
  if (
    !window.navigator.mediaDevices ||
    !isFunction(window.navigator.mediaDevices.getUserMedia)
  ) {
    // browser doesn't support the new webrtc api
    return;
  }

  function getMediaShim(constraints, onSuccess, onError) {
    window.navigator.mediaDevices
      .getUserMedia(constraints)
      .then(onSuccess)
      .catch(onError);
  }

  // We are polyfilling this fn in, so ignore this compatibility error
  // eslint-disable-next-line compat/compat
  window.navigator.getUserMedia = getMediaShim;
})();
