(function () {
  angular
    .module("akitabox.core.pinValue")
    .controller("PinValueListController", PinValueListController);

  /* @ngInject */
  function PinValueListController(
    // Angular
    $log,
    // Services
    FloorService,
    PinTypeService,
    PinValueService,
    UserService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.buildingId = Object.prototype.hasOwnProperty.call(self.building, "_id")
      ? self.building._id
      : self.building;
    self.pinId = self.pin._id || null;
    self.floor = null;
    self.floorId = null;
    self.save = angular.isDefined(self.save) ? self.save : true;

    // Functions
    self.getOptions = PinValueService.getFieldOptions;
    self.onValueChange = onValueChange;
    self.isHidden = isHidden;

    init();

    function init() {
      if ((self.isAsset && self.isRoom) || (!self.isAsset && !self.isRoom)) {
        $log.error(
          "PinValueListController: Only one of is-asset and is-room " +
            "can be true"
        );
      }

      // Set pin fields
      var pinType = self.pin.pinType;
      if (angular.isString(pinType)) {
        /* Pin type not populated, need to go get it for its pin fields */
        PinTypeService.getById(self.buildingId, pinType).then(function (
          populatedPinType
        ) {
          self.pinFields = populatedPinType.fields;
          updateAllLocations();
        });
      } else {
        /* Pin type populated, just use its fields */
        self.pinFields = pinType.fields;
        getPermissions();
        updateAllLocations();
      }

      self.loading = false;

      /**
       * Check every pin value, and set controller location info based on
       * them.
       */
      function updateAllLocations() {
        for (var i = 0; i < self.pinFields.length; ++i) {
          var pinField = self.pinFields[i];
          var pinValue = self.values[pinField._id];
          updateLocation(pinField, pinValue);
        }
      }

      function getPermissions() {
        var permissions = UserService.getPermissions();

        for (var i = 0; i < self.pinFields.length; i++) {
          var pinField = self.pinFields[i];
          var fieldType = pinField.data_type;
          switch (fieldType) {
            case "document_array":
            case "tag_filter":
              pinField.is_editable = self.isRoom
                ? permissions.room.update
                : permissions.asset.update;
              pinField.is_uploadable =
                permissions.document.create && pinField.is_editable;
              break;
            case "room":
              pinField.is_editable = permissions.asset.update_room;
              break;
            case "level":
              pinField.is_editable = self.isRoom
                ? permissions.room.update_level
                : permissions.asset.update_level;
              break;
            default:
              pinField.is_editable = self.isRoom
                ? permissions.room.update
                : permissions.asset.update;
              break;
          }
        }
      }
    }

    function onValueChange(field, value) {
      updateLocation(field, value, self.save);
      self.onChange({ field: field, value: value });
    }

    function updateLocation(field, value, onSave) {
      if (field && field.is_level_field) {
        var newLevel = null;
        if (value)
          newLevel = value.is_level_pin_value ? value.value : value._id;

        if (self.floorId !== newLevel) {
          // Only update floor if it changed
          self.floorId = newLevel;
          if (value && value.is_level_pin_value && value.value) {
            FloorService.getById(self.buildingId, self.floorId).then(function (
              floor
            ) {
              self.floor = floor;
            });
          } else {
            self.floor = value;
          }

          if (onSave) {
            // only perform this on an actual save, not when we are setting the initial values
            // need to clear the room when the floor changes
            for (var key in self.values) {
              var pinValue = self.values[key];
              if (pinValue.is_room_pin_value) {
                var newValue = angular.copy(pinValue);

                newValue.value = null;
                self.values[key] = newValue;
                self.pin.values[key] = newValue;
                break;
              }
            }
          }
        }
      }
    }

    function isHidden(field) {
      if (field.is_hidden) {
        // The field has been hidden by the user
        return true;
      } else if (
        !self.save &&
        (field.data_type === "tag_filter" ||
          field.data_type === "document_array")
      ) {
        // the asset create dialog modal shouldn't display tag filters or document arrays
        return true;
      }

      return false;
    }
  }
})();
