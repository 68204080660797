(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedBuildingFilter",
      function (
        ManagedFilterHelpers,
        ManagedModelFieldFilter,
        BuildingService
      ) {
        /**
         * @class
         * A building filter can be used with any query that has a `building`
         * field expecting a building id. Presents users with a typeahead of
         * buildings, filtered to a specific organization.
         * @param {FilterBarManager} manager
         * @param {object} options
         * @param {() => string} options.getOrganizationId - A function that should
         *  return the org id to filter on.
         */
        function ManagedBuildingFilter(manager, options) {
          if (!options || typeof options.getOrganizationId !== "function") {
            throw new Error(
              "Invalid options, getOrganizationId must be a function."
            );
          }

          /**
           * @member
           * @type {() => string}
           */
          this.getOrganizationId = options.getOrganizationId;

          var filterConfigurationOptions = {
            displayName: "Building",
            queryField: "building",
            inputType: "typeahead",
            modelValueToFilterValue: ManagedFilterHelpers.modelToId,
            modelValueToChipText: ManagedFilterHelpers.modelToName,
          };

          ManagedModelFieldFilter.call(
            this,
            manager,
            filterConfigurationOptions
          );
        }
        // ManagedBuildingFilter extends ManagedModelFieldFilter
        ManagedBuildingFilter.prototype = Object.create(
          ManagedModelFieldFilter.prototype
        );
        ManagedBuildingFilter.prototype.constructor = ManagedBuildingFilter;

        /**
         * Fetch enum options for this filter. Fetches all buildings for the
         * organization.
         * @return {Promise<[]>} - An array of enum options for this filter.
         */
        ManagedBuildingFilter.prototype.getEnumOptions = function () {
          return BuildingService.getAllByOrganization(
            this.getOrganizationId()
          ).then(ManagedFilterHelpers.mapModelsToEnumOption);
        };

        /**
         * Fetch a building by its id.
         * @return {Promise<Building>}
         */
        ManagedBuildingFilter.prototype.filterValueToModelValue = function (
          id
        ) {
          return BuildingService.getById(id).then(function (building) {
            return [building];
          });
        };

        return ManagedBuildingFilter;
      }
    );
})();
