(function () {
  angular.module("akitabox.ui.components.reportingCharts", [
    "akitabox.ui.components.reportingCharts.assigneeChart",
    "akitabox.ui.components.reportingCharts.workPerformedByChart",
    "akitabox.ui.components.reportingCharts.buildingChart",
    "akitabox.ui.components.reportingCharts.chartLegend",
    "akitabox.ui.components.reportingCharts.priorityChart",
    "akitabox.ui.components.reportingCharts.srActivityChart",
    "akitabox.ui.components.reportingCharts.srCompletedChart",
    "akitabox.ui.components.reportingCharts.statusChart",
    "akitabox.ui.components.reportingCharts.timeCodeChart",
    "akitabox.ui.components.reportingCharts.tradeChart",
    "akitabox.ui.components.reportingCharts.typeChart",
    "akitabox.ui.components.reportingCharts.woActivityChart",
    "akitabox.ui.components.reportingCharts.woAgeChart",
    "akitabox.ui.components.reportingCharts.woCompletedChart",
    "akitabox.ui.components.reportingCharts.woIntentChart",
    "akitabox.ui.components.reportingCharts.yAxisLabel",
    "akitabox.ui.components.reportingCharts.yAxisSwitcher",
    "akitabox.core.utils",
  ]);
})();
