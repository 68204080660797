(function () {
  angular
    .module("akitabox.core.services.inspection", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.http",
    ])
    .factory("InspectionService", InspectionService);

  function InspectionService(
    moment,
    // Angular
    $q,
    // AkitaBox
    models,
    // Services
    EnvService,
    HttpService
  ) {
    var service = {
      // Routes
      buildDetailRoute: buildDetailRoute,
      buildBaseRoute: buildBaseRoute,
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      getByOrganization: getByOrganization,
      getAllByOrganization: getAllByOrganization,
      getAllWithBuildingsBFF: getAllWithBuildingsBFF,
      // Utilities
      getInspectionFloor: getInspectionFloor,
      getInspectionUrl: getInspectionUrl,
      getInspectionName: getInspectionName,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildDetailRoute(organizationId, inspectionId) {
      var base = buildBaseRoute(organizationId);
      return base + "/" + inspectionId;
    }

    function buildBaseRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.INSPECTION.ROUTE_PLURAL
      );
    }

    /******************* *
     *   Create
     * ****************** */

    function create(organizationId, data) {
      var route = buildBaseRoute(organizationId);
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     ******************* */

    function getById(organizationId, id, params) {
      var route = buildDetailRoute(organizationId, id);
      return HttpService.getById(route, id, params);
    }

    /**
     * Fetches inspections by their organization (limited)
     * @param {String} organizationId
     * @param {Object} params
     * @return {$q.Promise<Checklist[]>}
     */
    function get(organizationId, params) {
      if (!organizationId)
        return $q.reject(
          new Error("InspectionService: organizationId required")
        );

      return HttpService.get(buildBaseRoute(organizationId), params);
    }

    /**
     * Fetches all inspections by their organization
     * @param {String} organizationId
     * @param {Object} params
     * @return {$q.Promise<Checklist[]>}
     */
    function getAll(organizationId, params) {
      if (!organizationId)
        return $q.reject(
          new Error("InspectionService: organizationId required")
        );

      var useCache = false;

      // We dont use the cache becaus it's currently broken
      return HttpService.getAll(
        buildBaseRoute(organizationId),
        params,
        useCache
      );
    }

    function getAllByOrganization(organizationId, params) {
      var route = buildBaseRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getAllWithBuildingsBFF(organizationId, params = {}) {
      var route = `/organizations/${organizationId}/web/inspections_with_buildings`;
      return HttpService.getAll(route, params, false);
    }

    /******************* *
     *   Utilities
     ******************* */

    function getInspectionUrl(buildingId, floorId, workOrderId) {
      var route =
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.FLOOR.ROUTE_PLURAL +
        "/" +
        floorId;
      var cleanParams = {
        round_task: workOrderId,
      };

      var query = HttpService.buildQueryString(cleanParams);
      return EnvService.getPlanViewUrl(route, query);
    }

    /**
     * Returns the floor model corresponding to the next floor to be stopped
     * at in an inspection
     * @param {Array<Checklist>} checklists
     */
    function getInspectionFloor(checklists, workOrder) {
      var locationsInProgress = [];
      var stop;
      var stopLocation;
      for (var i = 0; i < checklists.length; ++i) {
        var checklist = checklists[i];
        if (checklist.status === models.CHECKLIST.STATUSES.IN_PROGRESS) {
          var checklistLocation =
            checklist.level || checklist.room || checklist.asset;
          locationsInProgress.push(checklistLocation._id || checklistLocation);
        }
      }

      var location = null;
      // check the in-progress locations to try to find the next floor to use
      if (locationsInProgress.length) {
        for (var j = 0; j < workOrder.round.length; ++j) {
          stop = workOrder.round[j];
          stopLocation = stop.level || stop.room || stop.asset;
          if (locationsInProgress.indexOf(stopLocation._id) > -1) {
            // Only set location if there is a floor plan
            if (stopLocation.document) {
              location = stopLocation;
              break;
            } else if (stopLocation.level && stopLocation.level.document) {
              location = stopLocation;
              break;
            }
          }
        }
      }

      var floor = null;

      if (location) {
        floor = location.level || location;
      } else {
        // if we haven't found a floor from the in-progress locations, iterate through the WO's round until we find a floor
        for (var k = 0; k < workOrder.round.length; k++) {
          stop = workOrder.round[k];
          stopLocation = stop.level || stop.room || stop.asset;
          if (stopLocation.level) {
            floor = stopLocation.level;
            break;
          }
        }
      }

      return floor;
    }

    function getByOrganization(organizationId, params) {
      var route = buildBaseRoute(organizationId);
      return HttpService.get(route, params);
    }

    /**
     * Returns a user-facing name for an inspection based off it's interval
     * @param {Inspection} inspection an inspection object
     * @param {String} interval the interval from the inspection program
     */
    function getInspectionName(inspection, interval) {
      var dueDate = moment(inspection.due_date);
      switch (interval) {
        case "days":
          return dueDate.format("M/D/YYYY");
        case "weeks":
          return dueDate.format("YYYY [week] w [(due] M/D[)]");
        case "months":
          return dueDate.format("MMMM YYYY [(due] M/D[)]");
        case "years":
          return dueDate.format("YYYY [(due] M/D[)]");
      }
    }
  }
})();
