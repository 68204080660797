(function () {
  angular.module("akitabox.core.utils").factory("Utils", UtilsFactory);

  /**
   * @ngdoc factory
   * @module akitabox.core
   * @name UtilsFactory
   * @description
   * Collection of useful functions and services
   */
  /* @ngInject */
  function UtilsFactory(
    // Angular
    $location,
    $log,
    $q,
    $window,
    $timeout,
    // Third-party
    $state,
    $stateParams,
    // Constants
    models,
    // Libraries
    moment,
    // Services
    EnvService,
    raf,
    vendor
  ) {
    var service = {
      formatRelativeDateTime: formatRelativeDateTime,
      getNamespacedValue: getNamespacedValue,
      setNamespacedValue: setNamespacedValue,
      getFontHeight: getFontHeight,
      getContextualFontHeight: getContextualFontHeight,
      getModelOption: getModelOption,
      getParsedQueryParam: getParsedQueryParam,
      setQueryParam: setQueryParam,
      hasChanged: hasChanged,
      isStateActive: isStateActive,
      onScroll: onScroll,
      throttle: throttle,
      rafThrottle: rafThrottle,
      vendor: vendor,
      reduceGCD: reduceGCD,
      removeElement: removeElement,
      isDateValid: isDateValid,
      parseAttribute: parseAttribute,
      parseExpression: parseExpression,
      validateUrlLength: validateUrlLength,
      isSame: isSame,
      isSameModel: isSameModel,
      getPinConstants: getPinConstants,
      getBooleanAlias: getBooleanAlias,
      getEntityId: getEntityId,
      find: find,
      findModelById: findModelById,
      formatPhoneNumber: formatPhoneNumber,
      expandElement: expandElement,
      collapseElement: collapseElement,
      isDateStringValid: isDateStringValid,
      hashCode: hashCode,
      print: print,
      uuidv4: uuidv4,
      capitalize: capitalize,
      roundNumber: roundNumber,
    };

    return service;

    /**
     * Remove element from DOM. Can't use .remove() because that isn't supported by IE.
     *
     * @param element      Element to remove
     */
    function removeElement(element) {
      element.remove();
    }

    /**
     * Get the namespaced value of an object
     *
     * @param  {Object} obj         The object
     * @param  {String} namespace   Dot-separated namespaced value (eg. nested.property.name)
     * @return {*}                  The nested value
     */
    function getNamespacedValue(obj, namespace) {
      return namespace.split(".").reduce(function (o, prop) {
        return o ? o[prop] : null;
      }, obj);
    }

    /**
     * Set the property of an object at the specified namespace
     *
     * @param {Object} obj         The object holding all the values
     * @param {String} namespace   Dot-separated string indicating the property to access
     * @param {*}      newValue    The value to set the object's property to
     */
    function setNamespacedValue(obj, namespace, newValue) {
      namespace = namespace.split(".");
      namespace.reduce(function (value, key, index) {
        if (
          index === namespace.length - 1 &&
          value &&
          Object.prototype.hasOwnProperty.call(value, key)
        ) {
          value[key] = newValue;
        }

        return value ? value[key] : null;
      }, obj);
    }

    /**
     * Gives you the pixel height of a font size
     *
     * @param size {number}
     * @param unit {string}
     * @param fontFamily {string}
     * @param fontWeight {string}
     * @returns {number}
     */
    function getFontHeight(size, unit, fontFamily, fontWeight) {
      size = size || 12;
      unit = unit || "px";
      var $span = angular.element("<span></span>");

      $span.css("position", "absolute");
      $span.css("margin-top", "-9999px");
      $span.css("font-size", size + unit);

      if (fontFamily) {
        $span.css("font-family", fontFamily);
      }

      if (fontWeight) {
        $span.css("font-weight", fontWeight);
      }

      $span.html("M");
      document.body.appendChild($span[0]);

      var height = $span[0].offsetHeight;
      // Then remove this clone
      removeElement($span[0]);

      return height;
    }

    /**
     *  Gives you the calculated height of a body of text encased in a div with a given width
     *
     * @param fontSize  {String}    Font size (in pixels)
     * @param width     {Number}    Available width (in pixels)
     * @param styles    {Object}    Styles to apply to the text
     * @param text      {String}    [Optional] Text to calculate height for
     * @returns         {Number}    Calculated hieght
     */
    function getContextualFontHeight(fontSize, width, styles, text) {
      styles = styles || {};
      text = text || "M";
      width += "px";
      var defaultStyles = {
        position: "absolute", // Make sure we don't shift any elements by placing this as absolute
        top: "-9999px",
        "font-size": fontSize,
        width: width,
        overflow: "visible",
      };
      // Create element
      var $elem = angular.element("<div></div>");
      // Apply styles
      $elem.css(angular.extend({}, defaultStyles, styles));
      // Add text
      $elem.empty().append(text.split("\n").join("<br />"));
      // Append it to the body so we can get the height
      document.body.appendChild($elem[0]);
      // Get the height
      var height = $elem[0].offsetHeight;
      // Then remove this clone
      removeElement($elem);

      return height;
    }

    /**
     * Cross-version compatibility method to retrieve an
     * option of a ngModel controller
     *
     * @param {!angular.ngModelCtrl} ngModelCtrl    NgModelController
     * @param {!string}              name           Name of option to get
     *
     * @returns {Object|undefined}                  Option value
     */
    function getModelOption(ngModelCtrl, name) {
      if (!ngModelCtrl.$options) return;

      var $options = ngModelCtrl.$options;

      // The newer versions of AngularJS introduced a `getOption function
      // and made the option values no longer visible on the $options object
      return $options.getOption ? $options.getOption(name) : $options[name];
    }

    /**
     * Parse the given key from the query params iff it is in JSON format.
     *
     * @param {String} key
     */
    function getParsedQueryParam(key) {
      try {
        return JSON.parse($stateParams[key]);
      } catch (err) {
        return undefined;
      }
    }

    /**
     * Determine if a change has indeed changed
     *
     * @param {SimpleChange} simpleChange Angular change object
     */
    function hasChanged(simpleChange) {
      return Boolean(
        simpleChange && simpleChange.previousValue !== simpleChange.currentValue
      );
    }

    /**
     * Put the given value at the given key in the query string. Stringify
     * the value if necessary.
     *
     * @param {String} key
     * @param {*} value
     */
    function setQueryParam(key, value) {
      if (angular.isObject(value)) {
        $stateParams[key] = JSON.stringify(value);
      } else {
        $stateParams[key] = value;
      }
    }

    /**
     * Scroll utility to ignore elastic scrolling events
     *
     * @param  {Function} handler       Scroll event handler
     * @param  {Boolean}  allowElastic  If true, handle elastic scrolling events
     * @return {Function}               Scroll event listener
     */
    function onScroll(handler, allowElastic) {
      return function ($event) {
        var currentPosition = $event.target.scrollTop;

        // Determine if user is scrolling above or below target element bounds (eg. iOS elastic scrolling)
        var aboveBounds = currentPosition < 0;
        var belowBounds =
          $event.target.scrollHeight -
            currentPosition -
            $event.target.clientHeight <
          0;
        if (aboveBounds || (belowBounds && !allowElastic)) return;

        // Call handler
        return handler($event, currentPosition);
      };
    }

    /**
     * Throttle a function
     *
     * @param  {Function} func      Function to throttle
     * @param  {Number}   delay     Milliseconds to delay invoking function
     * @return {Function}           Throttled function
     */
    function throttle(func, delay) {
      var queuedArgs;
      var alreadyQueued;
      var queuedFunc;
      var context;
      var last;
      return function throttled() {
        queuedArgs = arguments;
        context = this;
        queuedFunc = func;
        var now = new Date().getTime();
        var delayed = delay && last ? now < last + delay : false;
        if (!alreadyQueued && !delayed) {
          alreadyQueued = true;
          last = now;
          queuedFunc.apply(context, Array.prototype.slice.call(queuedArgs));
          alreadyQueued = false;
        }
      };
    }

    /**
     * Throttle a function with window.requestAnimationFrame
     *
     * @param  {Function} func  Function to throttle
     * @return {Function}       Throttled function
     */
    function rafThrottle(func) {
      var context;
      var args;
      return function throttled() {
        context = this;
        args = arguments;
        raf(function () {
          func.apply(context, Array.prototype.slice.call(args));
        });
      };
    }

    /**
     * Check to see if a given state is the active one by matching against the current URL
     *
     * @param  {{ name<String>, params<{}>}}  state The state to test against
     * @return {Boolean}       If the given state matches the URL's state
     */
    function isStateActive(state) {
      if (state) {
        if (state.params) {
          var href = $state.href(state.name, state.params);
          return (
            decodeURIComponent($location.url()) === decodeURIComponent(href)
          );
        }
        return state.name === $state.current.name;
      }
      return false;
    }

    /**
     * Function which reduces a numerator and denominator down to its lowest form
     *
     * @param {number} numerator
     * @param {number} denominator
     * @returns {[{string}, {string}]}
     */
    function reduceGCD(numerator, denominator) {
      var gcd = function gcd(a, b) {
        return b ? gcd(b, a % b) : a;
      };
      var calcGCD = gcd(numerator, denominator);

      var npart = numerator / calcGCD;
      var dpart = denominator / calcGCD;

      if (Number(numerator) !== 0 && Number(denominator) !== 0) {
        if (npart > dpart) {
          npart = npart / dpart;
          dpart = 1;
        } else {
          dpart = dpart / npart;
          npart = 1;
        }
      }
      return [cleanRatioDecimal(npart), cleanRatioDecimal(dpart)];
    }

    /**
     * Function which returns a decimal number appropriately truncated
     * for use in a displayed ratio.
     *
     * @param {string|number} num
     * @returns {string}
     */
    function cleanRatioDecimal(num) {
      return parseFloat(num)
        .toFixed(2)
        .replace(/0+$/, "") // trim trailing zeroes
        .replace(/\.$/, ""); // trim trailing dot if it's effectively an integer
    }

    /**
     * Function which turns a date object into a more readable string
     *
     * @param {Date} date
     * @param {string} format
     * @returns {string}
     */
    function formatRelativeDateTime(date, format) {
      var now = new moment();
      var diff = Math.round(now.diff(new moment(date), "seconds"));
      var formattedDate;

      switch (diff) {
        case diff < 1:
          formattedDate = "a moment ago";
          break;
        case diff === 1:
          // second
          formattedDate = "a second ago";
          break;
        case diff < 60:
          // seconds
          formattedDate = diff + " seconds ago";
          break;
        case diff === 60:
          // minute
          formattedDate = "a minute ago";
          break;
        case diff < 3600:
          // minutes
          formattedDate = diff + " minutes ago";
          break;
        case diff === 3600:
          // hour
          formattedDate = "an hour ago";
          break;
        case diff < 86400:
          // hours
          formattedDate = diff + " hours ago";
          break;
        default:
          // just show the date
          if (format) {
            formattedDate = new moment(date).format(format);
          } else {
            formattedDate = new moment(date).format("M/D/YY, h:mm A");
          }
          break;
      }

      return formattedDate;
    }

    /**
     * Return the constants for the given pin model
     *
     * @param {String} pinModel - The type of pin model (e.g. "Asset")
     * @return {Object} - Constants for the pin model (from our constants
     *     package)
     */
    function getPinConstants(pinModel) {
      switch (pinModel.toLowerCase()) {
        case "asset":
          return models.ASSET;
        case "room":
          return models.ROOM;
        default:
          $log.error(
            "utils.service.getPinConstants",
            "Unknown model: " + pinModel
          );
      }
    }

    /**
     * Get a more human-friendly string for a given boolean.
     *
     * @param {Boolean} bool - Boolean to convert
     * @return {String} - Alias for the boolean
     */
    function getBooleanAlias(bool) {
      switch (bool) {
        case true:
          return "yes";
        case false:
          return "no";
        default:
          $log.error(
            "utils.service.getBooleanAlias",
            "Invalid boolean value, not one of {true, false}"
          );
      }
    }

    function parseAttribute(attrs, name, defaultValue, ctrl) {
      var value = attrs[name];
      defaultValue = angular.isDefined(defaultValue) ? defaultValue : null;
      if (angular.isDefined(ctrl)) {
        return ctrl[name];
      }
      return angular.isDefined(value) ? value : defaultValue;
    }

    function parseExpression(ctrl, name, defaultValue) {
      var func = ctrl[name];
      defaultValue = angular.isDefined(defaultValue) ? defaultValue : null;
      return angular.isFunction(func) ? func() : defaultValue;
    }

    /**
     * Check to see if the current URL's length is valid for the current
     * browser. Currently, that means just checking for IE, since other
     * browsers support URLs with length that are far more than our app needs.
     *
     * - IE supports a max URL length of 2083 and path length of 2048
     * - - https://support.microsoft.com/en-us/help/208427/maximum-url-length-is-2-083-characters-in-internet-explorer
     * - - IE 11 seems to truncate some extra long URLs, so this uses 19,50 as
     *     the threshold to give some extra space to make sure all invalid IE
     *     URLs are seen as such.
     *
     * @return {boolean} - URL is valid in current browser
     */
    function validateUrlLength() {
      var url = $location.absUrl();
      var path = $location.path();
      var isIe = EnvService.getBrowser().name === "ie";

      if (isIe && (url.length > 1950 || path.length > 2048)) {
        return false;
      }

      return true;
    }

    function collapseElement(element) {
      return $q(function (resolve) {
        var sectionHeight = element.scrollHeight;
        // Temporarily disable all css transitions
        var elementTransition = element.style.transition;
        element.style.transition = "";
        // Update height
        raf(function () {
          element.style.height = sectionHeight + "px";
          element.style.transition = elementTransition;
          raf(function () {
            element.style.height = 0;
            resolve();
          });
        });
      });
    }

    function expandElement(element) {
      return $q(function (resolve) {
        var sectionHeight = element.scrollHeight;
        element.style.height = sectionHeight + "px";
        element.addEventListener("transitionend", function onTransitionEnd() {
          // Remove this event listener so it only gets triggered once
          element.removeEventListener("transitionend", onTransitionEnd);
          // remove "height" from the element's inline styles,
          // so it can return to its initial value
          element.style.height = null;
          resolve();
        });
      });
    }

    /**
     * Determine if the given date string is compliant with our date format,
     * ISO, and RFC.
     *
     * @param {String} dateString - Date string to evaluate
     * @param {Boolean} - True iff the date string is considered to be valid
     */
    function isDateStringValid(dateString) {
      return moment(dateString, "M/D/YYYY", true).isValid();
    }

    /**
     * Returns a hash code for a string.
     * (Comparable to Java's String.hashCode())
     *
     * The hash code for a string object is computed as
     *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
     * using number arithmetic, where s[i] is the i th character
     * of the given string, n is the length of the string,
     * and ^ indicates exponentiation.
     * (The hash value of the empty string is zero.)
     *
     * https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
     * @param {String} value  String value to hash
     *
     * @return {Number} Hash code value for the given string
     */
    function hashCode(value) {
      var hash = 0;
      var len = value.length;
      var i = 0;
      if (len > 0) {
        while (i < len) {
          hash = ((hash << 5) - hash + value.charCodeAt(i++)) | 0;
        }
      }
      return hash;
    }

    function print(timeout) {
      return $q(function (resolve) {
        var done = false;
        $window.print();

        // Some browsers (eg. Safari) will show a confirmation dialog
        // on consecutive, programmatic prints
        // Add focus event listener to better indicate print completion
        // (once the window regains focus)
        $window.addEventListener("focus", onFocus);

        // Attach print listener if browser supports it
        if ($window.onbeforeprint) {
          $window.addEventListener("beforeprint", beforePrint);
        } else if ($window.matchMedia) {
          // Add print media query listener
          $window.matchMedia("print").addListener(onPrintMediaQuery);
        }

        if (timeout) {
          $timeout(function () {
            if (!done) {
              done = true;
              resolve();
            }
          }, timeout);
        }

        function onPrintMediaQuery(query) {
          if (query.matches) {
            $window.matchMedia("print").removeListener(onPrintMediaQuery);
            if (!done) {
              done = true;
              resolve();
            }
          }
        }

        function onFocus() {
          $window.removeEventListener("focus", onFocus);
          if (!done) {
            done = true;
            resolve();
          }
        }

        function beforePrint() {
          $window.removeEventListener("beforeprint", beforePrint);
          if (!done) {
            done = true;
            resolve();
          }
        }
      });
    }
  }

  /**
   * Determines if a given date object is a valid JavaScript date.
   *
   * An example of an invalid date is what would be returned by:
   *   `new Date("some invalid date string")`
   * This would give you a `Date` instance, but an invalid one that returns
   * "Invalid Date" from a `toString()` call, and returns invalid values on its
   * methods.
   *
   * This function is basically a pass through to see if JavaScript sees a date
   * as invalid. If you have a date string that you want to see is valid (i.e.
   * that the month and day are in bounds, etc., use a library like Moment.js).
   *
   * @param {Date} date - Date to parse
   * @return {Boolean} - True iff the given date is valid.
   */
  function isDateValid(date) {
    // `isNaN` will convert any given date to a number. If its invalid, that
    // number will be `NaN`
    return date instanceof Date && !isNaN(date);
  }

  /**
   * Compares two values, undefined === null
   *
   * @param {String} a   First value to compare
   * @param {String} b   Second value to compare
   *
   * @return {Boolean} True if values are the same, false if not
   */
  function isSame(a, b) {
    if (!a && !b) return true;
    return a === b;
  }

  /**
   * Compares if two models are the same, by comparing their IDs. This kind
   * of equality is useful if the same model exists at two different
   * locations in memory, where a simple `angular.equals` would return
   * false.
   *
   * It is important to note that this will _not_ check for deep equality
   * of the actual model values. This is strictly an ID check.
   *
   * @param {Object} model1 - First model to compare
   * @param {Object} model2 - Second model to compare
   * @return {Boolean} - If the models share the same ID
   */
  function isSameModel(model1, model2) {
    var bothEmpty = !model1 && !model2;
    if (bothEmpty) return true;

    return model1 && model2 && model1._id === model2._id;
  }

  /**
   * Find the (first) element in the given array that satisfies the given
   * callback.
   *
   * @param {*[]} array - Array to traverse
   * @param {Function} callback - Function to execute on each element. Will
   *     be invoked with (element), where `element` is the current element
   *     being processed in the array.
   */
  function find(array, callback) {
    for (var i = 0; i < array.length; ++i) {
      var element = array[i];
      if (callback(element)) return element;
    }
  }

  /**
   * Given a set of models, find among them the one with the given ID.
   *
   * @param {Object[]} models - Models to search through
   * @param {String} _id - MongoDB `_id` field of model to find
   * @return {Object|undefined} - Found model, or `undefined`
   */
  function findModelById(models, _id) {
    return find(models, function (model) {
      return model._id === _id;
    });
  }

  function formatPhoneNumber(phone) {
    var country;
    var areaCode;
    var number;

    if (!phone) return;

    //strip special characters
    phone = phone.replace(/\D/g, "");

    if (phone.length > 11) {
      number = phone;
    } else if (phone.length === 11) {
      country = phone.slice(0, 1);
      areaCode = phone.slice(1, 4);
      number = phone.slice(4, 7) + "-" + phone.slice(7);
    } else if (phone.length > 7) {
      areaCode = phone.slice(0, 3);
      number = phone.slice(3, 6) + "-" + phone.slice(6);
    } else if (phone.length > 3) {
      number = phone.slice(0, 3) + "-" + phone.slice(3, 7);
    } else {
      number = phone;
    }

    if (country) {
      return "+" + country + " (" + areaCode + ") " + number;
    } else if (areaCode) {
      return "(" + areaCode + ") " + number;
    } else {
      return number;
    }
  }

  function getEntityId(entity) {
    if (entity) {
      if (Object.prototype.hasOwnProperty.call(entity, "_id")) {
        return entity._id;
      }
      return entity;
    }

    return null;
  }

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Rounds a number.
   * @param {number} Value to round
   * @param {number} Places to round in the decimal part (if you want to round to .01 then use 2)
   * @public
   * @returns { number }
   */
  function roundNumber(value, places) {
    try {
      return Number(`${Math.round(`${value}e${places}`)}e-${places}`).valueOf();
    } catch (error) {
      return null;
    }
  }
})();
