(function () {
  angular
    .module("akitabox.core.services.userAction", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("UserActionService", UserActionService);

  /** @ngInject */
  function UserActionService(models, HttpService) {
    var service = {
      getAdminUserActions: getAdminUserActions,
      getOrgUserActions: getOrgUserActions,
      getBuildingUserActions: getBuildingUserActions,
      getAllBuildingUserActions: getAllBuildingUserActions,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute(buildingId) {
      var buildingUri = "/buildings/" + buildingId;
      return buildingUri + "/" + models.USER_ACTION.ROUTE_PLURAL;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.USER_ACTION.ROUTE_PLURAL
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getAdminUserActions() {
      var route = "/admin/" + models.USER_ACTION.ROUTE_PLURAL;
      return HttpService.getAll(route);
    }

    function getOrgUserActions(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getBuildingUserActions(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getAllBuildingUserActions(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, params);
    }
  }
})();
