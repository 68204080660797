(function () {
  angular
    .module("akitabox.core.services.type", [
      "akitabox.core.services.issueType",
      "akitabox.core.services.maintenanceType",
    ])
    .factory("TypeService", TypeService);

  /* @ngInject */
  function TypeService(
    // Angular
    $q,
    // Services
    IssueTypeService,
    MaintenanceTypeService
  ) {
    var service = {
      getTypes: getTypes,
    };

    return service;

    function getTypes(building, params, options) {
      var fetchRequests = [];

      if (building.show_issue_types) {
        var issueTypesRequest = IssueTypeService.getAll(
          building._id,
          params,
          options
        );
        fetchRequests.push(issueTypesRequest);
      }
      if (building.show_maintenance_types) {
        var maintenanceTypesRequest = MaintenanceTypeService.getAll(
          building._id,
          params,
          options
        );
        fetchRequests.push(maintenanceTypesRequest);
      }

      // Building does not show any types
      if (!fetchRequests.length) {
        return $q.reject(
          "TypeService.getTypes: The current building doesn't show any types"
        );
      }

      return $q.all(fetchRequests).then(function (typeSets) {
        var retTypes = [];
        for (var i = 0; i < typeSets.length; ++i) {
          var typeSet = typeSets[i];
          Array.prototype.push.apply(retTypes, typeSet);
        }

        return retTypes;
      });
    }
  }
})();
