(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.components.reportingCharts.srActivityChart
   *
   * @param {Object} building - building to build the chart with
   */
  angular
    .module("akitabox.ui.components.reportingCharts.srActivityChart", [
      "akitabox.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.chart",
      "akitabox.core.services.request",
      "akitabox.ui.components.reportingCharts.chartLegend",
      "akitabox.ui.directives.lineChart",
    ])
    .component("abxSrActivityChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
      },
      controller: AbxSrActivityChartController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/sr-activity-chart/sr-activity-chart.component.html",
    });
  function AbxSrActivityChartController(
    // Angular
    $q,
    $log,
    moment,
    // Constants
    models,
    // Services
    ChartService,
    ShadowService
  ) {
    var self = this;
    var config = null;

    var GA_CATEGORY = "reporting";

    // Attributes
    self.idTag = "sr-activity-chart";
    self.data = [];
    self.visibleData = [false, false, false];
    self.loading = true;
    self.showChart = false;
    self.chartColors = ["#2D8DD9", "#FFA836", "#A1C750"];
    self.lines = buildActivityLines();
    self.hoverLabels = ["Submitted", "Denied", "Completed"];

    self.toggleLineVisibility = toggleLineVisibility;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        $log.error("abxSrActivityChart: abx-buildings is required");
        return;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        config = ChartService.getChartConfig(
          self.buildings,
          models.SERVICE_REQUEST.MODEL
        );
      }
      if (changes.startDate || changes.endDate) {
        self.lines = buildActivityLines();
      }
      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings
      ) {
        calculateStats();
      }
    };

    // ------------------------
    //   Events
    // ------------------------

    // ------------------------
    //   Public Functions
    // ------------------------

    function toggleLineVisibility(index) {
      var toggled = ChartService.toggleLineVisibility(
        index,
        self.lines,
        self.visibleData
      );
      var action =
        "toggle-line-" + (toggled.lines[index].visible ? "on" : "off");
      var name = toggled.lines[index].name;
      ShadowService.setSelectedOption(name);
      ShadowService.sendEvent(GA_CATEGORY, action, self.idTag);
      self.visibleData = toggled.visibleData;
      self.lines = toggled.lines;
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function setShowChart() {
      var options = {
        startDate: self.startDate,
        endDate: self.endDate,
      };
      self.showChart = ChartService.shouldShowChart(
        models.SERVICE_REQUEST.MODEL,
        ChartService.CHART_TYPE_LINE,
        self.data,
        options
      );
    }

    /**
     * Calculate the service request stats for the given date
     */
    function calculateStats() {
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      )
        return;
      self.loading = true;
      self.showChart = false;

      var requests = [];
      for (var i = 0; i < self.lines.length; ++i) {
        var currentLine = self.lines[i];
        var params = angular.extend(
          {},
          {
            group_time: ChartService.getGroupTime(self.startDate, self.endDate),
            timezone: moment.tz.guess(),
          },
          currentLine.params
        );
        var currentRequest = getSrStats(params, i);
        requests.push(currentRequest);
      }

      $q.all(requests).finally(function () {
        self.loading = false;
        setShowChart();
      });
    }

    function getSrStats(params, statusIndex) {
      var dates = ChartService.buildDateArray(self.startDate, self.endDate);

      params = angular.extend(params, self.filters);

      if (config.buildingInString) params.buildings = config.buildingInString;

      return config
        .statsFunction(config.parentId, params)
        .then(function (results) {
          var stats = ChartService.parseDateResults(results, dates);
          self.data[statusIndex] = stats;
          if (self.lines[statusIndex].visible) {
            self.visibleData[statusIndex] = true;
          }
        });
    }

    function buildActivityLines() {
      var dateString = ChartService.buildDateString(
        self.startDate,
        self.endDate
      );
      return [
        {
          name: "Submitted",
          params: {
            group_field: "cre_date",
            cre_date: dateString,
          },
          visible: true,
        },
        {
          name: "Denied",
          params: {
            group_field: "denied_date",
            denied_date: dateString,
          },
          visible: true,
        },
        {
          name: "Completed",
          params: {
            group_field: "completed_date",
            completed_date: dateString,
          },
          visible: true,
        },
      ];
    }
  }
})();
