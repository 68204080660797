(function () {
  angular
    .module("akitabox.core.services.assembly", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("AssemblyService", Assembly);

  /** @ngInject */
  function Assembly(models, HttpService) {
    /**
     * Account service
     *
     * @type {Object}
     */
    var service = {
      // Routes
      buildBuildingDetailRoute: buildBuildingDetailRoute,
      buildBuildingListRoute: buildBuildingListRoute,
      buildOrganizationDetailRoute: buildOrganizationDetailRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      // Retrieve
      getByOrganization: getByOrganization,
      getByBuilding: getByBuilding,
      create: create,
      update: update,
      updateCostLines: updateCostLines,
      remove: remove,
      // Misc
      calculateRisk: calculateRisk,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildOrganizationDetailRoute(orgId, assemblyId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        orgId +
        "/" +
        models.ASSEMBLIES.ROUTE_PLURAL +
        "/" +
        assemblyId
      );
    }

    function buildOrganizationListRoute(orgId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        orgId +
        "/" +
        models.ASSEMBLY.ROUTE_PLURAL
      );
    }

    function buildBuildingDetailRoute(buildingId, assemblyId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.ASSEMBLY.ROUTE_PLURAL +
        "/" +
        assemblyId
      );
    }

    function buildBuildingListRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.ASSEMBLY.ROUTE_PLURAL
      );
    }

    /**
     * Get an array of assemblies within an org
     *
     * @param {String} orgId     the org id in which to fetch assemblies
     * @param {Object} params              an object of query string parameters
     */
    function getByOrganization(orgId, params) {
      var route = buildOrganizationListRoute(orgId);
      return HttpService.get(route, params);
    }

    /**
     * Get an array of assemblies within a building
     *
     * @param {String} buildingId     the building id in which to fetch assemblies
     * @param {Object} params              an object of query string parameters
     */
    function getByBuilding(buildingId, params) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.get(route, params);
    }

    /**
     * Updates an existing assembly
     *
     * @param {String} buildingId     building id for the assembly
     * @param {String} assemblyId     assembly to update
     * @param {Object} data           Object containing fields to update
     */
    function update(buildingId, assemblyId, data) {
      var route = buildBuildingDetailRoute(buildingId, assemblyId);
      return HttpService.put(route, data);
    }

    function updateCostLines(buildingId, assemblyId, costLines) {
      var route = buildBuildingDetailRoute(buildingId, assemblyId);
      var data = {
        action: "updateCostLines",
        cost_lines: costLines,
      };
      return HttpService.put(route, data);
    }

    /**
     * Creates a new assembly
     * @param {String} buildingId   building that the new assembly will be in
     * @param {Object} data         Object containing fields to set on the new assembly
     */
    function create(buildingId, data) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.post(route, data);
    }

    /**
     * Hard-delete a new assembly
     * @param {String} buildingId   building id for the assembly
     * @param {String} assemblyId   assembly to remove
     */
    function remove(buildingId, assemblyId) {
      var route = buildBuildingDetailRoute(buildingId, assemblyId);
      return HttpService.remove(route);
    }

    function calculateRisk(severityOfFailure, conditionRating) {
      if (!severityOfFailure || !conditionRating) {
        return null;
      }

      var severityNumber =
        models.ASSEMBLY.SEVERITY_OF_FAILURE_NUMBERS[severityOfFailure];
      var conditionNumber =
        models.CONDITION.CONDITION_GRADES_ID[conditionRating];
      if (!severityNumber || !conditionNumber) {
        return null;
      }

      var riskNumber = severityNumber * conditionNumber;
      var RISK_RANGES = models.ASSEMBLY.RISK_RANGES;
      var risk = null;
      var rangeKeys = Object.keys(RISK_RANGES);
      for (var i = 0; i < rangeKeys.length; ++i) {
        var key = rangeKeys[i];
        var riskRange = RISK_RANGES[key];
        var lowerBound = riskRange[0];
        var upperBound = riskRange[1];
        if (lowerBound <= riskNumber && riskNumber < upperBound) {
          risk = key;
          break;
        }
      }

      return risk || null;
    }
  }
})();
