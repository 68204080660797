(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.details.schedule
   */
  angular
    .module("akitabox.desktop.directives.details.schedule", [
      "angular.filter",
      "ui.router",
      "akitabox.constants",
      "akitabox.ui",
      "akitabox.ui.dialogs.schedule.editFrequency",
      "akitabox.core.services",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.assign.assign",
    ])
    .controller("AbxScheduleDetailsController", AbxScheduleDetailsController)
    .directive("abxScheduleDetails", AbxScheduleDetailsDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.details.schedule
   * @name AbxScheduleDetailsDirective
   *
   * @description
   * `<abx-schedule-details>` displays the details of a maintenance schedule and allows
   * for editing of fields based on the current user's permissions
   *
   * @param {Object}      ng-model            The component's model (schedule)
   * @param {Object}      ng-model-options    Allows tuning of the way in which the `ng-model`
   *                                          is updated
   *  @param {expression}  ng-change           Expression evaluated when the model value changes
   * @param {Object}      abx-building        Building that the maintenance schedule belongs to
   *
   * @usage
   * <hljs lang="html">
   *     <abx-schedule-details
   *         ng-model="vm.schedule"
   *         ng-change="vm.onScheduleChanged(schedule)"
   *         abx-building="vm.building">
   *     </abx-schedule-details>
   * </hljs>
   *
   * @ngInject
   */
  function AbxScheduleDetailsDirective() {
    return {
      restrict: "E",
      templateUrl:
        "app/desktop/directives/details/schedule/schedule-details.html",
      require: ["abxScheduleDetails", "ngModel"],
      controller: "AbxScheduleDetailsController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=abxBuilding",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      vm.readonly = angular.isDefined(attrs.readonly);

      // Add external change listener
      vm.ngModelCtrl.$formatters.push(onExternalChange);

      /**
       * Handle external changes to the model value
       *
       * @param {Object} value    New model value
       * @return {Object}         Formatted model value
       */
      function onExternalChange(value) {
        vm.schedule = angular.copy(value);
        if (value) vm.init();
        return value;
      }
    }
  }

  /**
   * Controller for abx-schedule-details
   *
   * @ngInject
   */
  function AbxScheduleDetailsController(
    // Angular
    $q,
    $timeout,
    $state,
    // Constants
    models,
    PRIORITIES,
    // Dialogs
    EditScheduleFrequencyDialog,
    AssignDialog,
    EditLocationDialog,
    // Services
    MaintenanceTypeService,
    ScheduleService,
    TimeZoneService,
    ToastService,
    TradeService,
    UserService,
    ServiceHelpers
  ) {
    var self = this;

    var tradesRequest;

    // ------------------------
    //   Attributes
    // ------------------------

    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.building = angular.isDefined(self.building) ? self.building : null;
    self.schedule = null;
    self.freqSummary = null;
    self.canUpdate = false;
    self.PRIORITIES = PRIORITIES;
    self.rendering = true;
    self.buildingUrl = null;

    // Functions
    self.init = init;
    self.showDialog = showDialog;
    self.updateDetails = updateDetails;
    self.updateTypesAndTrades = updateTypesAndTrades;
    self.updateLocation = updateLocation;
    self.updateCategory = updateCategory;
    self.fetchAllMaintenanceTypes = fetchAllMaintenanceTypes;
    self.fetchAllTrades = fetchAllTrades;
    self.onTypeChange = onTypeChange;
    self.onEditAssigneesHandler = onEditAssigneesHandler;
    self.getFrequencySummary = getFrequencySummary;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Initialize the directive
     */
    function init() {
      fetchAllTrades();
      parseRoomDisplayValue(self.schedule.room);
      self.buildingUrl = getBuildingUrl();
      self.freqSummary = ScheduleService.getFrequencySummary(self.schedule);
      if (!self.readonly) {
        setPermissions();
      }
      $timeout(function () {
        self.rendering = false;
      }, 500);
    }

    /**
     * Attempts to grab the 'displayName' from the room, otherwise defaults to just the 'name'
     * @param {Room} room
     */
    function parseRoomDisplayValue(room) {
      if (!room) {
        self.roomDisplayName = null;
        return;
      }
      ServiceHelpers.getRoomDisplayName(room)
        .then(function (displayName) {
          self.roomDisplayName = displayName || room.name;
        })
        .catch(function (err) {
          self.roomDisplayName = room.name;
          ToastService.showError(err);
        });
    }

    /**
     * Shows dialog that allows user to edit frequency
     */
    function showDialog() {
      var locals = {
        building: self.building,
        scheduleId: self.schedule._id,
        formData: {
          period: self.schedule.period,
          interval: self.schedule.interval,
          is_weekly_sunday: self.schedule.is_weekly_sunday,
          is_weekly_monday: self.schedule.is_weekly_monday,
          is_weekly_tuesday: self.schedule.is_weekly_tuesday,
          is_weekly_wednesday: self.schedule.is_weekly_wednesday,
          is_weekly_thursday: self.schedule.is_weekly_thursday,
          is_weekly_friday: self.schedule.is_weekly_friday,
          is_weekly_saturday: self.schedule.is_weekly_saturday,
          start_date: self.schedule.start_date,
          days_until_due: self.schedule.days_until_due,
        },
      };
      return EditScheduleFrequencyDialog.show({ locals: locals }).then(
        function (schedule) {
          self.schedule = schedule;
          var model = angular.extend(
            self.ngModelCtrl.$modelValue,
            self.schedule
          );
          self.ngModelCtrl.$setViewValue(model);
        }
      );
    }

    /**
     * Update the maintenance schedule details
     * (subject, description)
     */
    function updateDetails() {
      var data = {
        subject: self.schedule.subject,
        description_text: self.schedule.description_text,
      };
      return updateSchedule(data);
    }

    /**
     * Update the maintenance schedule types and trades
     * (maintenance_type, trade)
     */
    function updateTypesAndTrades() {
      var data = {
        maintenance_type: self.schedule.maintenance_type,
        trade: self.schedule.trade,
      };
      return updateSchedule(data);
    }

    /**
     * Update the maintenance schedule location via dialog then save
     * (level, room, asset)
     */
    function updateLocation() {
      EditLocationDialog.show({
        locals: {
          building: self.building,
          model: self.schedule,
          modelType: models.MAINTENANCE_SCHEDULE.MODEL,
        },
      })
        .then(function (updatedWorkOrder) {
          var data = {
            level: updatedWorkOrder.level,
            room: updatedWorkOrder.room,
            asset: updatedWorkOrder.asset,
          };
          updateSchedule(data);
          parseRoomDisplayValue(updatedWorkOrder.room);
        })
        .catch(ToastService.showError);
    }

    /**
     * Update the maintenance schedule category
     * (priority, estimated man hours)
     */
    function updateCategory() {
      var data = {
        priority: self.schedule.priority,
        estimated_man_hr: self.schedule.estimated_man_hr,
      };
      return updateSchedule(data);
    }

    /**
     * Get all available maintenance types to choose from for the building.
     *
     * @return {Promise<Array|Error>}   Resolves with all maintenance types for the building
     */
    function fetchAllMaintenanceTypes() {
      return MaintenanceTypeService.getAll(self.building._id).catch(
        ToastService.showError
      );
    }

    /**
     * Get all available trades to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all trades for the building
     */
    function fetchAllTrades() {
      if (tradesRequest) return tradesRequest;

      // Return outstanding request
      tradesRequest = TradeService.getAll(self.building._id).catch(
        ToastService.showError
      );

      return tradesRequest;
    }

    /**
     * Populates the trade input with the newly selected type's default trade, iff there isn't a trade selected
     * currently.
     */
    function onTypeChange() {
      // don't update the trade if maintenance_type is cleared out, if the building isn't showing trades,
      // if a trade is already selected (don't overwrite it), or if there is no default trade for the maintenance type
      if (
        !self.schedule.maintenance_type ||
        !self.building.show_trades ||
        self.schedule.trade
      ) {
        return;
      }

      tradesRequest.then(function (trades) {
        // Don't set trade if one was selected while tradesRequest was running
        if (self.schedule.trade) return;

        // Find trade object based on the trade ID given by the new type
        var newTradeId = self.schedule.maintenance_type.trade;
        for (var i = 0; i < trades.length; ++i) {
          var trade = trades[i];
          if (trade._id === newTradeId) {
            self.schedule.trade = trade;
            break;
          }
        }
      });
    }

    function onEditAssigneesHandler() {
      var options = {
        locals: {
          buildingId: self.building._id,
          scheduleId: self.schedule._id,
        },
      };
      AssignDialog.show(options)
        .then(function (updatedSchedule) {
          self.schedule = updatedSchedule;
          var model = angular.extend(
            self.ngModelCtrl.$modelValue,
            self.schedule
          );
          self.ngModelCtrl.$setViewValue(model);
        })
        .catch(function (err) {
          ToastService.showError(err);
        });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Fetch the current user's permissions for the maintenance schedule
     */
    function setPermissions() {
      var permissions = UserService.getPermissions();
      self.canUpdate = permissions.future_task.update;
    }

    /**
     * Update the maintenance schedule
     * @param {Object}  data    Maintenance Schedule fields and values to update
     */
    function updateSchedule(data) {
      return ScheduleService.update(self.building._id, self.schedule._id, data)
        .then(function (schedule) {
          self.schedule = schedule;
          var model = angular.extend(
            self.ngModelCtrl.$modelValue,
            self.schedule
          );
          self.ngModelCtrl.$setViewValue(model);
        })
        .catch(function (err) {
          ToastService.showError(err);
          return $q.reject(err);
        });
    }

    /**
     * Generates url to the building dashboad
     */
    function getBuildingUrl() {
      return $state.href("app.building.detail", {
        buildingId: self.building._id,
      });
    }

    /**
     * Generates the textual frequency summary with UTC offset on demand
     */
    function getFrequencySummary() {
      return ScheduleService.getFrequencySummary(self.schedule, self.utcOffset);
    }
  }
})();
