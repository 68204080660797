(function () {
  angular
    .module("akitabox.ui.dialogs.alert")
    .controller("AlertDialogController", AlertDialogController);

  /* @ngInject */
  function AlertDialogController(
    // Material
    $mdDialog
  ) {
    var self = this;
    self.hideDialog = hideDialog;

    function hideDialog(action) {
      $mdDialog.hide(action);
    }
  }
})();
