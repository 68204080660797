(function () {
  /**
   * @ngdoc component
   * @name abxChecklistSummary
   *
   */
  angular
    .module("akitabox.ui.components.checklistSummary", [
      "akitabox.constants",
      "akitabox.core.services.building",
      "akitabox.core.services.checklist",
      "akitabox.core.services.inspection",
      "akitabox.core.services.organization",
      "akitabox.core.toast",
    ])
    .component("abxChecklistSummary", {
      bindings: {
        inspectionProgram: "<abxInspectionProgram",
        selectedStatusFilters: "<abxSelectedStatusFilters",
        maxIterationsToDisplay: "<abxMaxIterationsToDisplay",
        maxBuildingsToDisplay: "<abxMaxBuildingsToDisplay",
        onFilterChange: "&abxOnFilterChange",
      },
      controller: AbxChecklistSummary,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/checklist-summary/checklist-summary.component.html",
    });

  /* @ngInject */
  function AbxChecklistSummary(
    // Angular
    $log,
    $q,
    // AkitaBox
    models,
    // Services
    BuildingService,
    ChecklistService,
    InspectionService,
    OrganizationService,
    ToastService,
    WorkOrderService
  ) {
    var self = this;

    // Constants
    var CHECKLIST_OUTER_STATUS_CATEGORIES = models.CHECKLIST.PARENT_STATUS;
    var BUILDINGS_FILTER = "buildings";
    var ITERATIONS_FILTER = "iterations";
    var STATUSES_FILTER = "statuses";

    // Functions
    var debounceGetData = angular.debounce(getData, 500);

    // Public Attributes
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.inspections = null;
    self.selectedBuildingFilters = [];
    self.selectedIterationFilters = [];
    self.allBuildingsSelected = false;
    self.allIterationsSelected = false;

    self.defaultStopChartText = {
      name: "Total Stops",
      value: 0,
    };
    self.chartData = {
      innerData: [],
      outerData: [],
    };
    self.chartHasData = false;
    self.loading = true;

    self.chartOuterOrdinalScale = {
      labels: ["Passed", "Failed", "Incomplete"],
      colors: ["#A1C750", "#D0021B", "#999999"],
    };

    var innerLabels = [
      "Passed",
      "Incomplete",
      "Failed - Linked External WO",
      "Failed - Linked Open WO",
      "Failed - Linked Closed WO",
      "Failed - No Linked WO",
    ];

    self.chartInnerOrdinalScale = {
      labels: innerLabels,
      colors: [
        "#A1C750",
        "#999999",
        "#FDD835",
        "#FDD835",
        "#99CFEA",
        "#FFA836",
      ],
    };

    // Public Functions
    self.onClickDonutComponent = onClickDonutComponent;
    self.handleFilterChange = handleFilterChange;

    // ------------------------
    //   Life cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.inspectionProgram) {
        $log.error("inspectionProgram is required");
        return;
      }

      if (!self.organization) {
        $log.error("organization is required");
        return;
      }

      // Get inspections
      // all options should originate from current and past Inspections
      var today = new Date();
      var params = {
        inspection_program: self.inspectionProgram._id,
        sort: "start_date,desc",
        start_date: "$lt," + today,
      };

      InspectionService.getAllWithBuildingsBFF(self.organization._id, params)
        .then((inspections) => {
          self.inspections = inspections;
          debounceGetData();
        })
        .catch((err) => {
          self.inspections = [];
          ToastService.showError(err);
        });
    };

    self.$onChanges = function (changes) {
      if (changes.selectedStatusFilters && self.selectedStatusFilters) {
        setChartDataEmphasis();
      }
    };

    self.getInspectionName = function (inspection) {
      return InspectionService.getInspectionName(
        inspection,
        inspection.inspection_program.interval
      );
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    function onClickDonutComponent($event) {
      // get the checklist status(es) that correspond to the clicked section of donut
      var statuses = $event.data.status;

      // add/remove statuses from this component's filters
      var allStatusesAlreadySelected = statuses.every(function (status) {
        return self.selectedStatusFilters.indexOf(status) > -1;
      });
      if (allStatusesAlreadySelected) {
        // remove all statuses
        for (var i = 0; i < statuses.length; i += 1) {
          var status = statuses[i];
          var indexOfStatus = self.selectedStatusFilters.indexOf(status);
          if (indexOfStatus > -1) {
            self.selectedStatusFilters.splice(indexOfStatus, 1);
          }
        }
      } else {
        // add statuses that aren't already selected
        for (var j = 0; j < statuses.length; j += 1) {
          var status2 = statuses[j];
          var indexOfStatus2 = self.selectedStatusFilters.indexOf(status2);
          if (indexOfStatus2 === -1) {
            self.selectedStatusFilters.push(status2);
          }
        }
      }

      // Correct an edge case that leads to unintuitive ux:
      // Having all statuses selected is visualy and functionally equivelant to having no statuses selected
      var allStatusesSelected = Object.keys(models.CHECKLIST.STATUSES).every(
        function (status) {
          return self.selectedStatusFilters.indexOf(status) > -1;
        }
      );
      if (allStatusesSelected) {
        self.selectedStatusFilters = [];
      }

      // Trigger downstream changes
      if (angular.isFunction(self.onFilterChange)) {
        self.onFilterChange({
          filter: STATUSES_FILTER,
          filters: self.selectedStatusFilters,
        });
      }
      setChartDataEmphasis();
    }

    function handleFilterChange(filter, filters) {
      if (filter === BUILDINGS_FILTER) {
        self.selectedBuildingFilters = filters;
        debounceGetData();
        if (angular.isFunction(self.onFilterChange)) {
          self.onFilterChange({
            filter: filter,
            filters: filters,
          });
        }
      } else if (filter === ITERATIONS_FILTER) {
        self.selectedIterationFilters = filters;
        debounceGetData();
        if (angular.isFunction(self.onFilterChange)) {
          self.onFilterChange({
            filter: filter,
            filters: filters,
          });
        }
      }
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Populate data for the summary chart
     */
    function getData() {
      // statuses that make up the "failed" outer slice
      var failStatuses = [
        models.CHECKLIST.STATUSES.FAIL,
        models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO,
        models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO,
        models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO,
      ];

      // outer data slices
      var outerData = [
        {
          _id: "incomplete-outer",
          name: "Incomplete",
          value: 0,
          percentValue: 0,
          domID: "ip-summary-chart-incomplete",
          status: [models.CHECKLIST.STATUSES.IN_PROGRESS],
        },
        {
          _id: "passed-outer",
          name: "Passed",
          value: 0,
          percentValue: 0,
          domID: "ip-summary-chart-passed",
          status: [models.CHECKLIST.STATUSES.PASS],
        },
        {
          _id: "failed-outer",
          name: "Failed",
          value: 0,
          percentValue: 0,
          domID: "ip-summary-chart-failed",
          status: failStatuses,
        },
      ];

      var innerData = [
        // inner data slices that are always used
        {
          _id: "incomplete-inner",
          name: "Incomplete",
          value: 0,
          percentValue: 0,
          parent: "Incomplete",
          parentDomID: "ip-summary-chart-incomplete",
          skipInnerLegend: true,
          status: [models.CHECKLIST.STATUSES.IN_PROGRESS],
        },
        {
          _id: "passed-inner",
          name: "Passed",
          value: 0,
          percentValue: 0,
          parent: "Passed",
          parentDomID: "ip-summary-chart-passed",
          skipInnerLegend: true,
          status: [models.CHECKLIST.STATUSES.PASS],
        },
        {
          _id: "failed-inner",
          name: "Failed - No Linked WO",
          value: 0,
          percentValue: 0,
          parent: "Failed",
          skipInnerLegend: false,
          status: [models.CHECKLIST.STATUSES.FAIL],
        },
      ];
      // show linked external WO slice
      innerData.push({
        _id: "failed-external-wo-inner",
        name: "Failed - Linked External WO",
        value: 0,
        percentValue: 0,
        parent: "Failed",
        skipInnerLegend: false,
        status: [models.CHECKLIST.STATUSES.FAIL_LINKED_EXTERNAL_WO],
      });
      // show open & closed WO slices
      innerData.push({
        _id: "failed-open-wo-inner",
        name: "Failed - Linked Open WO",
        value: 0,
        percentValue: 0,
        parent: "Failed",
        skipInnerLegend: false,
        status: [models.CHECKLIST.STATUSES.FAIL_LINKED_OPEN_WO],
      });
      innerData.push({
        _id: "failed-closed-wo-inner",
        name: "Failed - Linked Closed WO",
        value: 0,
        percentValue: 0,
        parent: "Failed",
        skipInnerLegend: false,
        status: [models.CHECKLIST.STATUSES.FAIL_LINKED_CLOSED_WO],
      });

      // initialize chart data
      self.chartData = {
        outerData: outerData,
        innerData: innerData,
      };
      self.chartHasData = false;
      self.loading = true;

      // need to get the IDs and discard 'all'
      var iterations = [];
      var buildings = [];
      for (var i = 0; i < self.selectedIterationFilters.length; i++) {
        var iteration = self.selectedIterationFilters[i];
        if (iteration._id !== "all") {
          iterations.push(iteration._id);
        }
      }
      for (var j = 0; j < self.selectedBuildingFilters.length; j++) {
        var building = self.selectedBuildingFilters[j];
        if (building._id !== "all") {
          buildings.push(building._id);
        }
      }

      if (iterations.length < 1 || buildings.length < 1) {
        // must have at least one inspection and one building to filter on
        self.loading = false;
        return;
      }

      getExpectedTotalStops(iterations, buildings)
        .then(function (numChecklists) {
          if (numChecklists < 1) {
            return [];
          }
          // One unrgouped call to get the total so we can calculate percentages
          self.defaultStopChartText.value = numChecklists ? numChecklists : 0;

          return getChecklistStats(iterations, buildings, "status").then(
            (stats) => {
              return { stats, numChecklists };
            }
          );
        })
        .then(function ({ stats, numChecklists }) {
          var failedStatus = CHECKLIST_OUTER_STATUS_CATEGORIES.FAIL;

          const sumStops = stats.reduce(
            (accumulator, currentValue) => accumulator + currentValue.result,
            0
          );

          const numEphemeralStops = numChecklists - sumStops;

          // Groups must be added in a specific order to line up right on the chart
          var chartOuterIndices = {
            IN_PROGRESS: 0,
            PASS: 1,
            FAIL: 2,
          };
          var chartInnerIndices = {
            IN_PROGRESS: 0,
            PASS: 1,
            FAIL: 2,
            FAIL_LINKED_EXTERNAL_WO: 3,
            FAIL_LINKED_OPEN_WO: 4,
            FAIL_LINKED_CLOSED_WO: 5,
          };

          chartOuterIndices.SKIP = 0;
          chartInnerIndices.SKIP = 0;

          if (!stats.some((element) => element._id === "IN_PROGRESS")) {
            stats.push({ _id: "IN_PROGRESS", result: 0 });
          }

          for (var i = 0; i < stats.length; i++) {
            var stat = stats[i];

            if (stat._id === "IN_PROGRESS") {
              stat.result += numEphemeralStops;
            }

            // Don't include stats with no checklists
            if (stat.result > 0) {
              self.chartHasData = true;
              var outerCategory = CHECKLIST_OUTER_STATUS_CATEGORIES[stat._id];
              var outerIndex = chartOuterIndices[outerCategory];
              var innerIndex = chartInnerIndices[stat._id];

              if (innerIndex === undefined) {
                continue;
              }

              if (outerCategory === failedStatus) {
                // Otherwise track how many failed we have across all fail states
                var failedCount =
                  self.chartData.outerData[outerIndex].value + stat.result;
                var failedPercent = roundPercent(
                  failedCount,
                  self.defaultStopChartText.value
                );
                self.chartData.outerData[outerIndex].value = failedCount;
                self.chartData.outerData[outerIndex].percentValue =
                  failedPercent;

                // and add all failed states to inner data
                self.chartData.innerData[innerIndex].value = stat.result;
                self.chartData.innerData[innerIndex].percentValue =
                  roundPercent(stat.result, self.defaultStopChartText.value);
              } else {
                self.chartData.outerData[outerIndex].value = stat.result;
                self.chartData.outerData[outerIndex].percentValue =
                  roundPercent(stat.result, self.defaultStopChartText.value);
                self.chartData.innerData[innerIndex].value = stat.result;
                self.chartData.innerData[innerIndex].percentValue =
                  roundPercent(stat.result, self.defaultStopChartText.value);
              }
            }
          }

          // Filter out segments with no data
          self.chartData.outerData = self.chartData.outerData.filter(function (
            status
          ) {
            return status.value;
          });
          self.chartData.innerData = self.chartData.innerData.filter(function (
            status
          ) {
            return status.value;
          });
        })
        .finally(function () {
          setChartDataEmphasis();
          self.loading = false;
        });
    }

    function setChartDataEmphasis() {
      // set `shouldEmphasize` for each data point
      var dataSets = [self.chartData.outerData, self.chartData.innerData];
      for (
        var dataSetIndex = 0;
        dataSetIndex < dataSets.length;
        dataSetIndex += 1
      ) {
        var dataSet = dataSets[dataSetIndex];
        for (var i = 0; dataSet && i < dataSet.length; i += 1) {
          var datum = dataSet[i];
          var shouldEmphasize =
            !!self.selectedStatusFilters &&
            !!self.selectedStatusFilters.length &&
            datum.status.every(function (status) {
              return self.selectedStatusFilters.indexOf(status) > -1;
            });
          datum.shouldEmphasize = shouldEmphasize;
        }
      }
      // set data to new arrays to force chart redraw
      self.chartData.outerData = self.chartData.outerData.slice();
      self.chartData.innerData = self.chartData.innerData.slice();
    }

    /**
     * Gets stats for all checklists linked to any of the passed-in Inspections
     * @param {Array<Inspecion>} inspections an array of inspection ids to filter by
     * @param {Array<Building>} buildings an array of building ids to filter by or 'all'
     * @param {String} groupByField - field to group the checklists by
     */
    function getChecklistStats(inspections, buildings, groupByField) {
      var statParams = {};
      statParams.inspection = "$in," + inspections.join(",");

      if (buildings.length) {
        /**
         * This is pretty much only here for the initial filter fetch
         * as this is required elsewhere
         */
        statParams.building = "$in," + buildings.join(",");
      }

      if (groupByField) {
        statParams.group_field = groupByField;
      }

      return ChecklistService.getStats(self.organization._id, statParams);
    }

    function getExpectedTotalStops(inspections, buildings) {
      const statParams = {};
      statParams.inspection = "$in," + inspections.join(",");

      if (buildings.length) {
        statParams.building = "$in," + buildings.join(",");
      }
      return WorkOrderService.getStatsByOrganization(self.organization._id, {
        operator: "sum",
        operator_field: "round_size",
        ...statParams,
      }).then((result) => (result[0] ? result[0].result : 0));
    }

    // ------------------------
    //   Utilities
    // ------------------------

    function roundPercent(dividend, divisor) {
      if (!divisor) {
        return 0;
      }
      var quotient = dividend / divisor;
      return Math.round(quotient * 100);
    }
  }
})();
