(function () {
  angular
    .module("akitabox.ui.dialogs.job.create")
    .controller("ExportJobDialogController", ExportJobDialogController);

  /* @ngInject */
  function ExportJobDialogController(
    moment,
    // Angular
    $q,
    $timeout,
    // Material
    $mdDialog,
    // Services
    AssetService,
    FeatureFlagService,
    AdminJobService,
    PinTypeService,
    ToastService,
    // Akitabox
    models
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.creating = false;
    self.includeData = true;
    self.includeQrCodes = false;
    self.includePhotoUrls = false;
    self.includeWorkOrderLogs = false;
    self.options = null;
    self.selected = null;
    self.pinTypes = null;
    self.loadingAssetTypes = false;
    self.selectedTypes = [];
    self.workOrderExports = {
      all: false,
      open: false,
      completed: false,
      canceled: false,
      startDateRange: {},
      dueDateRange: {},
      closedDateRange: {},
      onStartDateChange: onStartDateChange,
      onDueDateChange: onDueDateChange,
      onClosedDateChange: onClosedDateChange,
    };
    self.scheduleExports = {
      all: false,
      active: false,
      canceled: false,
    };
    self.userActionExports = {
      sevenDay: false,
      thirtyDay: false,
    };

    // UI State
    self.shouldShowManageExport = shouldShowManageExport;
    self.shouldShowAssetsExportOptions = shouldShowAssetsExportOptions;
    self.shouldShowWOExportOptions = shouldShowWOExportOptions;
    self.shouldShowMSExportOptions = shouldShowMSExportOptions;
    self.shouldShowUAExportOptions = shouldShowUAExportOptions;
    self.shouldShowQRExportOptions = shouldShowQRExportOptions;
    self.shouldShowWOLogExportOptions = shouldShowWOLogExportOptions;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.submit = submit;
    self.fetchAssetTypes = fetchAssetTypes;
    self.onIncludeDataChange = onIncludeDataChange;
    self.toggle = toggle;
    self.selectAll = selectAll;
    self.supportsQrCodes = supportsQrCodes;
    self.selectAllWorkOrderExports = selectAllWorkOrderExports;
    self.selectAllScheduleExports = selectAllScheduleExports;
    self.isSubmitDisabled = isSubmitDisabled;
    self.createModelsForWorkOrders = createModelsForWorkOrders;

    init();

    function init() {
      // Get import model types
      AdminJobService.getModels(
        self.building.organization,
        self.building._id,
        true
      )
        .then(function (models) {
          self.options = models;
        })
        .finally(function () {
          self.loading = false;
        });
    }

    var QR_CODE_SUPPORTED_MODELS = [
      models.ROOM.PLURAL,
      models.WORK_ORDER.PLURAL,
      models.ASSET.PLURAL,
    ];

    function downloadTemplates() {
      var model = self.selected.model;
      var buildingId = self.building._id;
      var organizationId = self.building.organization;
      var downloadPromise;

      if (self.selected.isCompletedWO) {
        downloadPromise = AdminJobService.downloadCompletedWoTemplate(
          buildingId,
          model
        );
      } else if (!angular.isEmpty(self.selectedTypes)) {
        // Trying to download too many files in one go makes chrome skip saving some files
        // This reduce chains the template downloads, with a 100ms pause between requests
        downloadPromise = self.selectedTypes.reduce(function (
          acc,
          value,
          index
        ) {
          return acc.then(function () {
            var delay = index === 0 ? 0 : 100;
            return $timeout(
              AdminJobService.downloadPinTypeTemplate,
              delay,
              false, // skip model checking, not necessary
              // Download function arguments
              buildingId,
              model,
              value
            );
          });
        },
        $q.resolve());
      } else if (self.selected.inBuilding) {
        downloadPromise = AdminJobService.downloadBuildingTemplate(
          buildingId,
          model
        );
      } else {
        downloadPromise = AdminJobService.downloadOrganizationTemplate(
          organizationId,
          model
        );
      }

      downloadPromise
        .then(function () {
          $mdDialog.hide();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.creating = false;
        });
    }

    function submit() {
      if (self.creating) {
        return;
      }
      self.creating = true;
      if (!self.includeData) {
        return downloadTemplates();
      }
      if (self.selected.name === models.ROOM.PLURAL) {
        return PinTypeService.getAll(self.building._id, {
          protected_type: models.ROOM.SINGULAR,
        })
          .then(function (pinTypes) {
            return create(self.selected.model, [pinTypes[0]._id]);
          })
          .catch(ToastService.showError);
      }
      create(self.selected.model, self.selectedTypes);
    }

    function create(model, pinTypes) {
      var staircaseId = self.selected.staircase_id
        ? self.selected.staircase_id
        : "export-staircase";
      var body = {
        staircase_id: staircaseId,
        data: {
          models: [],
        },
      };

      if (model) {
        if (angular.isEmpty(pinTypes)) {
          if (model === models.WORK_ORDER.MODEL) {
            body.data.models = createModelsForWorkOrders(model, true);
          } else if (model === models.MAINTENANCE_SCHEDULE.MODEL) {
            if (self.scheduleExports.active) {
              body.data.models.push({
                name: model,
                filters: {
                  import: true,
                  fileName: self.building.name + "-Active",
                  status: "active",
                },
              });
            }

            if (self.scheduleExports.canceled) {
              body.data.models.push({
                name: model,
                filters: {
                  import: true,
                  fileName: self.building.name + "-Canceled",
                  status: "canceled",
                },
              });
            }
          } else if (model === models.USER_ACTION.MODEL) {
            var sevenDay = new Date();
            var thirtyDay = new Date();
            sevenDay = sevenDay.setDate(sevenDay.getDate() - 7);
            thirtyDay = thirtyDay.setDate(thirtyDay.getDate() - 30);
            var filters;
            var methodFilter = {
              method: "$in,POST,DELETE,PATCH,PUT",
            };

            if (self.userActionExports.sevenDay) {
              filters = Object.assign(
                {
                  import: true,
                  fileName: self.building.name + "-Seven-Day",
                  time: "$gte," + sevenDay.valueOf(),
                },
                methodFilter
              );

              body.data.models.push({
                name: model,
                filters: filters,
              });
            }
            if (self.userActionExports.thirtyDay) {
              filters = Object.assign(
                {
                  import: true,
                  fileName: self.building.name + "-Thirty-Day",
                  time: "$gte," + thirtyDay.valueOf(),
                },
                methodFilter
              );

              body.data.models.push({
                name: model,
                filters: filters,
              });
            }

            // export all actions is sevenDay or thirtyDay is not selcted
            if (
              !self.userActionExports.sevenDay &&
              !self.userActionExports.thirtyDay
            ) {
              filters = Object.assign(
                {
                  import: true,
                },
                methodFilter
              );
              body.data.models.push({
                name: model,
                filters: filters,
              });
            }
          } else {
            body.data.models[0] = {
              name: model,
              filters: {
                import: true,
              },
            };
          }
        } else {
          for (var i = 0; i < pinTypes.length; ++i) {
            body.data.models[i] = {
              name: model,
              filters: {
                import: true, // tells backend to use more verbose headers for in-house exports
                pinType: pinTypes[i],
              },
            };
          }
        }
      }

      if (
        supportsQrCodes(self.selected) &&
        self.includeQrCodes &&
        self.includeData
      ) {
        body.data.models.forEach(function (m) {
          m.filters.includeQrCodes = self.includeQrCodes;
        });
      }

      const jobs = [];

      const job = AdminJobService.create(null, self.building._id, body).catch(
        ToastService.showError
      );
      jobs.push(job);

      if (model === models.ASSET.MODEL && self.includePhotoUrls) {
        let filters = {};
        if (pinTypes.length) {
          filters = {
            pinType: {
              in: pinTypes,
            },
          };
        }
        const body = {
          staircase_id: "export-asset-photo-url-staircase",
          data: {
            filters,
          },
        };
        const job = AdminJobService.create(null, self.building._id, body).catch(
          ToastService.showError
        );
        jobs.push(job);
      }

      $q.all(jobs)
        .then($mdDialog.hide)
        .finally(function () {
          self.creating = false;
        });
    }

    function createModelsForWorkOrders(model, importValue) {
      var models = [];
      // Add filters for date fields
      var workOrderDateFilters = {};
      // start date
      var startDateRange = self.workOrderExports.startDateRange;
      if (startDateRange && (startDateRange.start || startDateRange.end)) {
        workOrderDateFilters.start_date = { $exists: true };
        if (startDateRange.start) {
          workOrderDateFilters.start_date.$gte = moment(
            startDateRange.start
          ).startOf("day");
        }
        if (startDateRange.end) {
          workOrderDateFilters.start_date.$lte = moment(
            startDateRange.end
          ).endOf("day");
        }
      }
      // due date
      var dueDateRange = self.workOrderExports.dueDateRange;
      if (dueDateRange && (dueDateRange.start || dueDateRange.end)) {
        workOrderDateFilters.due_date = { $exists: true };
        if (dueDateRange.start) {
          workOrderDateFilters.due_date.$gte = moment(
            dueDateRange.start
          ).startOf("day");
        }
        if (dueDateRange.end) {
          workOrderDateFilters.due_date.$lte = moment(dueDateRange.end).endOf(
            "day"
          );
        }
      }
      // closed date (completed OR canceled)
      var closedDateRange = self.workOrderExports.closedDateRange;
      var closedDateFilters;
      if (closedDateRange && (closedDateRange.start || closedDateRange.end)) {
        closedDateFilters = { $exists: true };
        if (closedDateRange.start) {
          closedDateFilters.$gte = moment(closedDateRange.start).startOf("day");
        }
        if (closedDateRange.end) {
          closedDateFilters.$lte = moment(closedDateRange.end).endOf("day");
        }
      }
      // Add filters for WO statuses
      if (self.workOrderExports.open) {
        var openFilters = angular.extend({}, workOrderDateFilters, {
          import: importValue,
          fileName: self.building.name + "-Open",
          status: "open",
        });
        models.push({
          name: model,
          filters: openFilters,
        });
      }

      if (self.workOrderExports.completed) {
        var completedFilters = angular.extend(
          {},
          workOrderDateFilters,
          { completed_date: closedDateFilters },
          {
            import: importValue,
            fileName: self.building.name + "-Completed",
            status: "completed",
          }
        );
        models.push({
          name: model,
          filters: completedFilters,
        });
      }

      if (self.workOrderExports.canceled) {
        var canceledFilters = angular.extend(
          {},
          workOrderDateFilters,
          { canceled_date: closedDateFilters },
          {
            import: importValue,
            fileName: self.building.name + "-Canceled",
            status: "canceled",
          }
        );
        models.push({
          name: model,
          filters: canceledFilters,
        });
      }

      if (self.includeWorkOrderLogs && self.includeData) {
        models.forEach(function (model) {
          model.filters.includeWorkOrderLogs = true;
        });
      }

      return models;
    }

    function onIncludeDataChange(selected) {
      // If `include data` should be disabled for an export, add the exception here
      if (selected.isCompletedWO) {
        self.includeData = false;
      } else {
        self.includeData = true;
      }
      fetchAssetTypes(selected);
    }

    function fetchAssetTypes(selected) {
      if (selected.name !== models.ASSET.PLURAL) return;
      self.loadingAssetTypes = true;
      PinTypeService.getAll(self.building._id, {
        protected_type: models.ASSET.SINGULAR,
      })
        .then(function (pinTypes) {
          self.assetTypes = pinTypes;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loadingAssetTypes = false;
        });

      // Count number of assets for each pinType
      var params = {
        group_field: "pinType",
      };
      // Pull stats of pinTypes
      AssetService.getStatsByBuilding(self.building._id, params)
        .then(function (pinTypes) {
          // Iterate through the recieved pinTypes to match the count with assetTypes
          for (var i = 0; i < pinTypes.length; i++) {
            for (var j = 0; j < self.assetTypes.length; j++) {
              // If the id matches, set the count
              if (pinTypes[i]._id === self.assetTypes[j]._id) {
                self.assetTypes[j].count = pinTypes[i].result;
                break;
              }
            }
          }
        })
        .catch(ToastService.showError);
    }

    function toggle(id) {
      var index = self.selectedTypes.indexOf(id);
      if (index === -1) {
        self.selectedTypes.push(id);
      } else {
        self.selectedTypes.splice(index, 1);
      }
    }

    function selectAll() {
      if (self.selectedTypes.length === self.assetTypes.length) {
        self.selectedTypes = [];
      } else {
        self.selectedTypes = self.assetTypes.map(function (type) {
          return type._id;
        });
      }
    }

    function supportsQrCodes(selectedModel) {
      return (
        QR_CODE_SUPPORTED_MODELS.indexOf(selectedModel && selectedModel.name) >
        -1
      );
    }

    function selectAllWorkOrderExports() {
      if (self.workOrderExports.all) {
        self.workOrderExports.open = true;
        self.workOrderExports.completed = true;
        self.workOrderExports.canceled = true;
      }
    }

    function selectAllScheduleExports() {
      if (self.scheduleExports.all) {
        self.scheduleExports.active = true;
        self.scheduleExports.canceled = true;
      }
    }

    function isSubmitDisabled() {
      /**
       * disable the submit button if you are:
       * - already submitting
       * - you chose assets and didn't pick an asset type
       * - you chose work orders and didn't specify which kind of work orders
       * - you chose maintenance schedules and didn't specify which kind of schedules
       */
      return (
        self.creating ||
        (self.selected.name === models.ASSET.PLURAL &&
          self.selectedTypes.length < 1) ||
        (self.includeData &&
          self.selected.model === "task" &&
          !self.workOrderExports.open &&
          !self.workOrderExports.completed &&
          !self.workOrderExports.canceled) ||
        (self.includeData &&
          self.selected.model === "future_task" &&
          !self.scheduleExports.active &&
          !self.scheduleExports.canceled)
      );
    }

    // UI State Functions
    function shouldShowManageExport() {
      return (
        self.shouldShowAssetsExportOptions() ||
        self.shouldShowWOExportOptions() ||
        self.shouldShowMSExportOptions() ||
        self.shouldShowMSExportOptions() ||
        self.shouldShowUAExportOptions() ||
        self.shouldShowQRExportOptions()
      );
    }

    function shouldShowAssetsExportOptions() {
      return self.selected && self.selected.name === "assets";
    }

    function shouldShowWOExportOptions() {
      return (
        self.selected && self.selected.model === "task" && self.includeData
      );
    }

    function shouldShowMSExportOptions() {
      return (
        self.selected &&
        self.selected.model === "future_task" &&
        self.includeData
      );
    }

    function shouldShowUAExportOptions() {
      return self.selected && self.selected.model === "user_action";
    }

    function shouldShowQRExportOptions() {
      return self.supportsQrCodes(self.selected) && self.includeData;
    }

    function shouldShowWOLogExportOptions() {
      return self.selected && self.selected.model === "task";
    }

    // Work Order Date Range Functions
    function onStartDateChange($event) {
      onDateChange($event, self.workOrderExports.startDateRange);
    }
    function onDueDateChange($event) {
      onDateChange($event, self.workOrderExports.dueDateRange);
    }
    function onClosedDateChange($event) {
      onDateChange($event, self.workOrderExports.closedDateRange);
    }
    function onDateChange($event, dateRangeToChange) {
      if (!$event.newValue) {
        delete dateRangeToChange.start;
        delete dateRangeToChange.end;
      } else {
        dateRangeToChange.start = $event.newValue.start;
        dateRangeToChange.end = $event.newValue.end;
      }
    }
  }
})();
