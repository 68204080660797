(function () {
  angular.module("akitabox.desktop.request.detail", [
    "angular.filter",
    "ui.router",
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.building",
    "akitabox.core.services.document",
    "akitabox.core.services.env",
    "akitabox.core.services.flag",
    "akitabox.core.services.note",
    "akitabox.core.services.room",
    "akitabox.core.services.request",
    "akitabox.core.services.trade",
    "akitabox.core.services.user",
    "akitabox.core.services.workOrder",
    "akitabox.core.toast",
    "akitabox.core.utils",
    "akitabox.desktop.directives.details",
    "akitabox.ui.dialogs.request",
    "akitabox.ui.dialogs.workOrder.create",
  ]);
})();
