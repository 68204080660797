(function () {
  /**
   * @ngdoc component
   * @name abxRoundStopList
   *
   * @param {Object[]}   stops          List of stops
   * @param {String}     title          List title
   * @param {Object}     pinTypes       Map of pin types by ID
   * @param {boolean}    pinsAreVisible Boolean that controls the display of the
   *  visibility toggle icon. Should be true when this list's pins are visible
   * @param {() => void} onVisibilityToggle Event handler for clicking the toggle
   *  visibility button.
   *
   * @description
   * Stop list for round task
   */
  angular.module("akitabox.planView").component("abxRoundStopList", {
    bindings: {
      stops: "<abxStops",
      floors: "<abxFloors",
      pinTypes: "<abxPinTypes",
      checklistTemplates: "<?abxChecklistTemplates",
      title: "@abxTitle",
      expanded: "<abxExpanded",
      listType: "@abxListType",
      attachmentData: "<abxAttachmentData",
      noteData: "<abxNoteData",
      inspectionProgram: "<abxInspectionProgram",
      onBulkEdit: "&abxOnBulkEdit",
      pinsAreVisible: "<abxPinsAreVisible",
      onVisibilityToggle: "&abxOnVisibilityToggle",
      fetchStops: "&abxFetchStops",
      count: "<abxCount",
      setExpanded: "<abxSetExpanded",
    },
    controller: AbxRoundStopListController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/round-stop-list/round-stop-list.component.html",
  });

  /** @ngInject */
  function AbxRoundStopListController(
    // Angular
    $scope,
    $stateParams,
    $element,
    // Events
    EVENT_ROUND_TASK_STOP_SELECTED,
    EVENT_ROUND_TASK_PIN_SELECTED,
    EVENT_BULK_EDIT_STOP_SELECTED,
    EVENT_BULK_EDIT_TOGGLE_ALL,
    // Services
    BuildingService,
    ChecklistService,
    FeatureFlagService,
    OrganizationService,
    ToastService,
    models
  ) {
    var self = this;

    var EXPANDED_CLASS = "abx-stop-list__expanded";

    var $content = angular.element($element.find("md-content")[0]);

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self._expanded = self.expanded;
    self.toggleDirection = self._expanded ? "up" : "down";
    self.bulkEditModeEnabled = false;
    self.checked = false;
    self.toggleAll = false;
    self.checkedStops = [];
    self.checklistTemplate = null;
    self.checklistTemplateOptions = null;
    self.filteredStops = self.stops;
    self.skip = 0;
    self.limit = 100;
    self.status = getStatusFilter(self.listType);
    self.canShowMore = false;
    self.haveNotFetchedYet = true;

    // Functions
    self.fetch = function () {
      return self
        .fetchStops({ filters: getFetchFilters() })
        .then(function (stops) {
          if (stops.length === self.limit) {
            self.canShowMore = true;
          } else {
            self.canShowMore = false;
          }

          self.filteredStops = self.stops; // need to re-assign because it losess track!
          self.haveNotFetchedYet = false;
        })
        .catch(ToastService.showError);
    };

    self.showMore = function () {
      return (
        self.canShowMore &&
        self.count > 0 &&
        self.stops.length > 0 &&
        self.stops.length < self.count
      );
    };

    self.getPinType = getPinType;
    self.getNumAttachments = getNumAttachments;
    self.getNumNotes = getNumNotes;
    self.getNumWorkOrders = getNumWorkOrders;
    self.getNumExternalCMMSWorkOrders = getNumExternalCMMSWorkOrders;
    self.toggleContent = toggleContent;
    self.isEmpty = isEmpty;
    self.toggleBulkEditMode = toggleBulkEditMode;
    self.doToggleAll = doToggleAll;
    self.handleChecklistChange = handleChecklistChange;
    self.handleVisibilityToggleClick = handleVisibilityToggleClick;

    // =================
    // Life Cycle
    // =================

    self.$onInit = function () {
      $scope.$on(EVENT_ROUND_TASK_STOP_SELECTED, onStopSelected);
      $scope.$on(EVENT_ROUND_TASK_PIN_SELECTED, onPinSelected);
      $scope.$on(EVENT_BULK_EDIT_STOP_SELECTED, toggleChecked);

      if (self._expanded) {
        self.fetch().then(function () {
          expand();
        });
      }
    };

    self.$onChanges = function (changes) {
      if (changes.expanded && !changes.expanded.isFirstChange()) {
        if (self.expanded && self.haveNotFetchedYet) {
          self.fetch().then(function () {
            expand();
          });
        } else if (self.expanded) {
          expand();
        } else {
          collapse();
        }
      }
      if (changes.stops) {
        self.bulkEditModeEnabled = false;
        self.checked = false;
        self.toggleAll = false;
        self.filteredStops = self.stops;
      }
      if (changes.checklistTemplates || changes.inspectionProgram) {
        if (self.checklistTemplates && self.inspectionProgram) {
          updateChecklistTemplatesOptions();
        }
      }
    };

    // =================
    // Public Functions
    // =================

    function getPinType(stop) {
      var pin = stop.room || stop.asset;

      if (pin && pin.pinType._id) {
        return pin.pinType;
      } else if (pin && !pin.pinType._id) {
        for (var i = 0; i < self.pinTypes.length; i++) {
          var pinType = self.pinTypes[i];
          if (pin.pinType === pinType._id) {
            return pinType;
          }
        }
      }
      return null;
    }

    function getNumAttachments(stop) {
      // Make sure the _id of the stop (the checklist id) is available.
      if (self.attachmentData && stop._id) {
        return self.attachmentData[stop._id] || 0;
      }
      return 0;
    }

    function getNumNotes(stop) {
      if (self.noteData) {
        return self.noteData[stop._id] || 0;
      }
      return 0;
    }

    function getNumWorkOrders(stop) {
      return FeatureFlagService.isEnabled("jit_checklists")
        ? stop.reactive_inspection_tasks_size
        : stop.reactive_inspection_tasks.length || 0;
    }

    function getNumExternalCMMSWorkOrders(stop) {
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        return stop.reactive_inspection_tasks_external_cmms_size;
      }
      if (stop.reactive_inspection_tasks_external_cmms) {
        return stop.reactive_inspection_tasks_external_cmms.length || 0;
      }
      return 0;
    }

    function toggleContent() {
      if (self._expanded) {
        collapse();
      } else if (self.count && self.haveNotFetchedYet) {
        self.fetch().then(function () {
          expand();
        });
      } else if (self.stops.length) {
        expand();
      }
    }

    function isEmpty() {
      return !self.count;
    }

    function toggleBulkEditMode(enabled) {
      if (enabled) {
        self.bulkEditModeEnabled = true;
        if (!self.checklistTemplate) {
          handleChecklistChange(self.checklistTemplateOptions[0].model);
        } else {
          handleChecklistChange(self.checklistTemplate);
        }
      } else {
        self.bulkEditModeEnabled = false;
        self.checked = false;
        self.toggleAll = false;
        self.checkedStops = [];
        self.filteredStops = self.stops;
      }
    }

    function toggleChecked($event, data) {
      var checkedStop;
      var index;
      for (var i = 0; i < self.checkedStops.length; ++i) {
        var stop = self.checkedStops[i];
        if (data.stop._id === stop._id) {
          checkedStop = stop;
          index = i;
          break;
        }
      }
      // If a checkbox in the list was checked
      if (data.checked) {
        // Add stop if it hasn't been added already
        if (!checkedStop) {
          self.checkedStops.push(data.stop);
        }
        // If all stops are checked, set toggleAll to true and check the main checkbox
        if (self.checkedStops.length === self.filteredStops.length) {
          self.toggleAll = true;
          self.checked = true;
        }
      }
      // If a checkbox in the list was unchecked
      else {
        // Splice this stop from the list of checked stops and uncheck the main checkbox
        self.checkedStops.splice(index, 1);
        self.checked = false;
        // If all stops are unchecked, set toggleAll to false
        if (self.checkedStops.length === 0) {
          self.toggleAll = false;
        }
      }
    }

    function doToggleAll() {
      // If the main checkbox is checked, uncheck it and toggle all to unchecked
      if (self.checked) {
        self.checked = false;
        self.checkedStops = [];
      }
      // If the main checkbox is not checked, check it and toggle all to checked
      else {
        self.checked = true;
        Array.prototype.push.apply(self.checkedStops, self.filteredStops);
      }

      $scope.$broadcast(EVENT_BULK_EDIT_TOGGLE_ALL, { checked: self.checked });

      return self.checked;
    }

    /**
     * Event handler for the "eye"/toggle pin visibility button
     */
    function handleVisibilityToggleClick($event) {
      $event.stopPropagation();
      self.onVisibilityToggle();
    }

    function handleChecklistChange(model) {
      self.filteredStops = self.stops;
      self.checklistTemplate = model;
      if (model) {
        // Update stops shown
        self.filteredStops = self.filteredStops.filter(function (filteredStop) {
          if (filteredStop.checklist_template === model._id) {
            return filteredStop;
          }
        });
        // Update stops checked
        self.checkedStops = self.checkedStops.filter(function (checkedStop) {
          if (checkedStop.checklist_template === model._id) {
            return checkedStop;
          }
        });
        // Update main checkbox
        if (self.checkedStops.length === 0) {
          self.checked = false;
        } else if (self.checkedStops.length === self.filteredStops.length) {
          self.checked = true;
        }
      }
    }

    // =================
    // Private Functions
    // =================

    function collapse() {
      if (!FeatureFlagService.isEnabled("jit_checklists") && !self.stops.length)
        return;
      self.toggleDirection = "down";
      hideContent();
    }

    function expand() {
      if (!self.stops.length) return;
      self.toggleDirection = "up";
      showContent();
    }

    function showContent() {
      self._expanded = true;
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        self.setExpanded(true);
      }
      $content.addClass(EXPANDED_CLASS);
      $element.addClass(EXPANDED_CLASS);
    }

    function hideContent() {
      self._expanded = false;
      if (FeatureFlagService.isEnabled("jit_checklists")) {
        self.setExpanded(false);
      }
      $content.removeClass(EXPANDED_CLASS);
      $element.removeClass(EXPANDED_CLASS);
    }

    function updateChecklistTemplatesOptions() {
      // Clear out currenctly selected template
      self.checklistTemplate = null;

      if (angular.isEmpty(self.checklistTemplates)) {
        self.checklistTemplateOptions = [];
      } else {
        self.checklistTemplateOptions = self.checklistTemplates.map(function (
          checklistTemplate
        ) {
          return {
            model: checklistTemplate,
            value: checklistTemplate.name,
          };
        });
        // Always list the default checklist first
        for (var i = 0; i < self.checklistTemplateOptions.length; ++i) {
          var option = self.checklistTemplateOptions[i];
          if (
            option.model &&
            option.model._id === self.inspectionProgram.checklist_template
          ) {
            if (i > 0) {
              var defaultChecklist = option;
              self.checklistTemplateOptions.splice(i, 1);
              self.checklistTemplateOptions.unshift(defaultChecklist);
            }
            break;
          }
        }
      }
    }

    function onStopSelected($event, data) {
      var stop = data.stop;
      if (stop && self._expanded && !containsStop(stop)) {
        collapse();
      }
    }

    /**
     * Set selected stop if a pin is selected from the floorplan
     */
    function onPinSelected($event, data) {
      var pin = data.pin;

      var selectedStop;
      var selectedStopId;

      if (data.stop) {
        selectedStop = data.stop;
      } else if (pin) {
        // data.pin is either a room or asset
        // find which item in self.stops[] has a room._id or asset._id equal to data.pin._id
        for (var i = 0; i < self.stops.length; i++) {
          var stop = self.stops[i];
          var stopPin = stop.room || stop.asset;
          if (!stopPin) continue;
          var stopPinId = stopPin._id;
          if (data.pin._id === stopPinId) {
            selectedStop = stop;
            break;
          }
        }
      }

      // if stop is found, expand this list and open the stop
      if (selectedStop) {
        $stateParams.stop = selectedStop._id;
        selectedStopId = "stop-" + selectedStop._id;
        if (!self._expanded) {
          expand();
        }
        // get the element from the DOM to scroll to it
        var stopItemContent = document.getElementById(selectedStopId);
        if (stopItemContent) {
          stopItemContent.parentElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      } else if (pin && self._expanded) {
        // selected pin is not in this list, collapse
        collapse();
      }
    }

    function containsStop(stop) {
      if (!stop) return false;
      const location = stop.room || stop.asset || stop.level;
      if (!location) return false;
      var includesStop = false;
      for (var i = 0; i < self.stops.length; ++i) {
        const curStop = self.stops[i];
        const curLocation = curStop.asset || curStop.room || curStop.level;
        const isSameStop = FeatureFlagService.isEnabled("jit_checklists")
          ? curLocation._id === location._id
          : curStop._id === stop._id;
        if (isSameStop) {
          includesStop = true;
          break;
        }
      }
      return includesStop;
    }

    function getStatusFilter() {
      let filter;
      const statuses = ChecklistService.getStatusListByType(self.listType);

      if (statuses) {
        if (statuses.length === 1) {
          filter = statuses[0];
        }
        if (statuses.length > 1) {
          filter = `$in,${statuses.join(",")}`;
        }
      }

      return filter;
    }

    function getFetchFilters() {
      return {
        limit: self.limit,
        skip: self.stops.length,
        status: getStatusFilter(),
      };
    }
  }
})();
