(function () {
  angular
    .module("akitabox.ui.dialogs.document.editDocumentArray")
    .controller(
      "EditDocumentArrayDialogController",
      EditDocumentArrayDialogController
    );

  /**
   * @ngInject
   */
  function EditDocumentArrayDialogController(
    // Angular
    $mdDialog,
    $scope,
    // Services
    AssetService,
    DocumentService,
    RoomService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.allDocuments = null;
    self.activeFilters = {};
    self.activeTab = 1;
    self.disabled = false;
    self.saving = false;

    // Functions
    self.fetch = fetch;
    self.changeFilters = changeFilters;
    self.onCheckChange = onCheckChange;
    self.addDocument = addDocument;
    self.removeDocument = removeDocument;
    self.cancel = $mdDialog.cancel;
    self.save = save;

    // Public Methods
    function fetch(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
      };

      params = angular.extend({}, params, self.activeFilters);

      return DocumentService.get(self.building._id, params).then(function (
        documents
      ) {
        // We need to add .checked prop to each document for the check box
        for (var i = 0; i < documents.length; i++) {
          var checked = false;

          for (var k = 0; k < self.selectedDocuments.length; k++) {
            if (documents[i]._id === self.selectedDocuments[k]._id) {
              checked = true;
              break;
            }
          }

          documents[i].checked = checked;
        }

        if (angular.isArray(self.allDocuments)) {
          self.allDocuments = self.allDocuments.concat(documents);
        } else {
          self.allDocuments = documents;
        }

        return documents.length;
      });
    }

    function changeFilters(activeFilters) {
      self.activeFilters = activeFilters;
      self.allDocuments = null;
      $scope.$broadcast("list:refresh");
    }

    function save() {
      var updatePromise;
      var building = self.building;
      var buildingID = building._id;
      var pinID = self.pin._id;
      var pinValueID = self.pinValue._id;
      var selectedDocumentIds = self.selectedDocuments.map(function (document) {
        return document._id;
      });

      var data = {
        value: selectedDocumentIds,
      };

      if (!self.skipPinValueUpdate) {
        if (self.isAsset) {
          updatePromise = AssetService.updateValue(
            buildingID,
            pinID,
            pinValueID,
            data
          );
        } else if (self.isRoom) {
          updatePromise = RoomService.updateValue(
            buildingID,
            pinID,
            pinValueID,
            data
          );
        }

        self.saving = true;
        return updatePromise
          .then(function () {
            // Send back the populated documents to the dialog user
            $mdDialog.hide(angular.copy(self.selectedDocuments));
          })
          .catch(ToastService.showError)
          .finally(function () {
            self.saving = false;
          });
      } else {
        return $mdDialog
          .hide(angular.copy(self.selectedDocuments))
          .catch(ToastService.showError);
      }
    }

    function onCheckChange(document) {
      if (document.checked) {
        addDocument(document);
      } else {
        removeDocument(document);
      }
    }

    function addDocument(document) {
      document.checked = true;
      self.selectedDocuments.push(document);
    }

    function removeDocument(document) {
      document.checked = false;

      // remove it from selectedDocuments
      for (var i = 0; i < self.selectedDocuments.length; i++) {
        if (self.selectedDocuments[i]._id === document._id) {
          self.selectedDocuments.splice(i, 1);
          break;
        }
      }

      // remove it from allDocuments
      for (var j = 0; j < self.allDocuments.length; j++) {
        if (self.allDocuments[j]._id === document._id) {
          self.allDocuments[j].checked = false;
          break;
        }
      }
    }
  }
})();
