const pluralize = require("pluralize");
(function () {
  angular
    .module("akitabox.core.lib.pluralize", [])
    .factory("pluralize", PluralizeService);

  /* @ngInject */
  function PluralizeService() {
    if (!angular.isDefined(pluralize))
      throw new Error("pluralize is not available");
    return pluralize;
  }
})();
