(function () {
  angular
    .module("akitabox.core.services.building", [
      "akitabox.constants",
      "akitabox.core",
      "akitabox.core.services.filter",
      "akitabox.core.services.http",
      "akitabox.core.services.issueType",
      "akitabox.core.services.maintenanceType",
    ])
    .factory("BuildingService", BuildingService);

  /** @ngInject */
  function BuildingService(
    // Angular
    $q,
    // AkitaBox
    models,
    // Services
    HttpService,
    CacheFactory,
    FilterService,
    SessionService
  ) {
    var service = {
      init: init,
      // Attributes
      thumbnail: null,
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      cache: {},
      // Accessors
      getCurrent: getCurrent,
      setCurrent: setCurrent,
      // Create
      create: create,
      // Retrieve
      get: get,
      getByOrganization: getByOrganization,
      getById: getById,
      getAll: getAll,
      getAllByOrganization: getAllByOrganization,
      getByPortalId: getByPortalId,
      populateBuildings: populateBuildings,
      // Update
      update: update,
      changeName: changeName,
      archive: archive,
      unarchive: unarchive,
      // Filters
      getMyListFilter: getMyListFilter,
      setMyListFilter: setMyListFilter,
      // Building groups
      getAllBuildingGroups: getAllBuildingGroups,
    };

    var current;
    var currentBuildingRequest;
    var getByIdRequests = {};
    self.orgBuildings = [];

    service.cache = CacheFactory.get("buildingsById");
    if (!service.cache) {
      service.cache = CacheFactory("buildingsById");
    }

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildDetailRoute(buildingId) {
      return "/" + models.BUILDING.ROUTE_PLURAL + "/" + buildingId;
    }

    function buildListRoute() {
      return "/" + models.BUILDING.ROUTE_PLURAL;
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        buildListRoute()
      );
    }

    function buildOrganizationBuildingGroupsListRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        "/building_groups"
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * This init function returns a promise that resolves after the "current" building is ready to use.
     * This is so that the desktop controller has the chance to defer accessing the current building until
     * it is set, but other code can retrieve the current building right away through getCurrent().
     */
    function init(path) {
      if (currentBuildingRequest) {
        return currentBuildingRequest;
      }

      var buildingId;
      if (path) {
        var matches = path.match(/buildings\/([a-zA-Z0-9]*)/);
        if (matches && matches.length > 1) {
          buildingId = matches[1];
          if (current && buildingId !== current._id) {
            current = null;
          }
        }
      }
      if (!buildingId) {
        current = null;
        buildingId = SessionService.getBuildingId();
      }
      if (buildingId) {
        currentBuildingRequest = getById(buildingId).then(function (building) {
          currentBuildingRequest = null;
          setCurrent(building);
          return current;
        });
        return currentBuildingRequest;
      }

      return $q.resolve(null);
    }

    /***
     * This function populates a map passed into it with the full building objects, based on the ids in the `models` objects
     * The models should each have a `building` attribute that contains the building id.
     * The buildings map will, after the returned promise resolves, contain every building needed for the models, keyd by the building's `_id`
     */
    function populateBuildings(buildings, models) {
      if (!buildings) {
        buildings = {};
      }

      var buildingPomises = [];

      for (var i = 0; i < models.length; i++) {
        var building = models[i].building;
        if (building && building._id) {
          buildings[building._id] = building;
          continue;
        }
        (function (buildingId, buildings, buildingPromises) {
          if (buildingId && angular.isEmpty(buildings[buildingId])) {
            buildings[buildingId] = false; // Just to keep from having to return a bunch of the same promise
            buildingPromises.push(
              service
                .getById(buildingId, { useCache: true })
                .then(function (building) {
                  buildings[buildingId] = building;
                  return building;
                })
            );
          }
        })(building, buildings, buildingPomises);
      }

      return $q.all(buildingPomises);
    }

    /******************* *
     *   Create
     * ****************** */

    function create(data) {
      var route = buildListRoute();
      return HttpService.post(route, data);
    }

    /******************* *
     *   Accessors
     * ****************** */

    function getCurrent() {
      return current;
    }

    function setCurrent(building) {
      var currentBldnId = building ? building._id : null;

      SessionService.setBuildingId(currentBldnId);
      current = building;
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(params) {
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getById(buildingId, params) {
      var route = buildDetailRoute(buildingId);
      var organization;
      if (params && params.organization) {
        organization = params.organization;
        delete params.organization;
      }

      var requestPromise = getByIdRequests[route];

      if (params && params.useCache) {
        var cache = service.cache.get(buildingId);
        if (cache) {
          requestPromise = $q.resolve(cache);
        }
        delete params.useCache;
      }

      if (!requestPromise) {
        requestPromise = HttpService.get(route, params).then(function (
          building
        ) {
          getByIdRequests[route] = null;
          if (organization && building.organization !== organization) {
            const error = new Error();
            error.status = 404;
            error.error = {
              name: "NotFoundError",
              message: "Not Found",
            };
            (error.name = "NotFoundError"), (error.message = "Not Found");
            return $q.reject(error);
          }
          service.cache.put(buildingId, building);

          return building;
        });
        getByIdRequests[route] = requestPromise;
      }

      return requestPromise;
    }

    /**
     * Get all buildings (both archived and active)
     *
     * @param {Object} params Query params
     */
    function getAll(params) {
      var route = buildListRoute();
      var buildings = [];
      return HttpService.getAll(route, params)
        .then(function () {
          return buildings;
        })
        .finally(angular.noop, function (data) {
          angular.copy(data, buildings);
        });
    }

    function getAllByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getAllBuildingGroups(organizationId, params) {
      var route = buildOrganizationBuildingGroupsListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getByPortalId(portalId) {
      return HttpService.get("/portal/" + portalId);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, data) {
      var route = buildDetailRoute(buildingId);
      return HttpService.put(route, data).then(function (building) {
        service.cache.put(building._id, building);
        return building;
      });
    }

    function changeName(buildingId, data) {
      data = angular.extend({}, data, { action: "changeName" });
      var route = buildDetailRoute(buildingId);
      return HttpService.patch(route, data).then(function (building) {
        service.cache.put(building._id, building);
        return building;
      });
    }

    function archive(buildingId) {
      var data = { action: "archive" };
      var route = buildDetailRoute(buildingId);
      return HttpService.patch(route, data).then(function (building) {
        service.cache.put(building._id, building);
        return building;
      });
    }

    function unarchive(buildingId) {
      var data = { action: "unarchive" };
      var route = buildDetailRoute(buildingId);
      return HttpService.patch(route, data).then(function (building) {
        service.cache.put(building._id, building);
        return building;
      });
    }

    /******************* *
     *   Filters
     * ****************** */

    /**
     * Retrieve cached user building list filter
     *
     * @param  {String} buildingId
     * @return {Object}
     */
    function getMyListFilter() {
      return FilterService.getUserFilter("building");
    }

    /**
     * Cache the provided user building list filter
     *
     * @param {String} buildingId
     * @param {Object} filter
     */
    function setMyListFilter(filter) {
      FilterService.setUserFilter("building", filter);
    }
  }
})();
