(function () {
  angular
    .module("akitabox.core.services.roomsBff", ["akitabox.core.services.http"])
    .factory("RoomsBFFService", RoomsBFFService);

  function RoomsBFFService(
    //Services
    HttpService
  ) {
    var services = {
      getTypeAheads: getTypeAheads,
      getAllTypeAheads: getAllTypeAheads,
    };

    return services;

    function buildBaseRoute(organizationId) {
      return `organizations/${organizationId}/web/rooms_typeahead`;
    }

    function getTypeAheads(organizationId, params) {
      const route = buildBaseRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getAllTypeAheads(organizationId, params) {
      const route = buildBaseRoute(organizationId);
      return HttpService.getAll(route, params);
    }
  }
})();
