(function () {
  angular
    .module("akitabox.ui.directives.url", [])
    .directive("abxUrl", AbxUrlDirective);

  /* @ngInject */
  function AbxUrlDirective() {
    return {
      restrict: "A",
      require: "ngModel",
      link: postLink,
    };

    function postLink($scope, $element, attrs, ngModelCtrl) {
      var url = attrs.abxUrl;
      var readonly = true;

      // Html attributes
      var target = attrs.abxUrlTarget || "_self";

      // UI elements
      var $anchor = angular.element('<a href="" target="' + target + '"></a>');

      if (angular.isEmpty(attrs.abxUrl)) {
        // Subscribe to model changes
        ngModelCtrl.$formatters.push(onValueChange);
        ngModelCtrl.$viewChangeListeners.push(
          angular.debounce(onInternalChange, 250)
        );
      } else {
        setAnchorHref(attrs.abxUrl);
        // Watch url attribute changes
        $scope.$watch(function () {
          return attrs.abxUrl;
        }, onValueChange);
      }

      // Add anchor element
      $anchor.css({
        display: "none",
        height: $element.css("height"),
        padding: $element.css("padding"),
        margin: $element.css("margin"),
        "font-size": $element.css("font-size"),
        "line-height": $element.css("line-height"),
      });
      $element.after($anchor);

      // Watch readonly attribute
      $scope.$watch(function () {
        return $element[0].attributes.readonly;
      }, onReadonlyChange);

      /**
       * Parse new model value and set anchor tag attributes
       *
       * @param {*}   value   New model value
       *
       * @return {*}          Parsed model value
       */
      function parseUrl(value) {
        if (value && value.indexOf("//") === 0) {
          return value.substring(2);
        }
        return value;
      }

      /**
       * Set the anchor tags href attribute
       *
       * @param {String} url      Hyperlink
       */
      function setAnchorHref(url) {
        if (url && !url.match(/^(.*:\/\/)|(^\/).*/)) url = "//" + url;
        $anchor.attr("href", url);
      }

      /**
       * Handle value changes, parse new url and update anchor tag
       *
       * @param {*}   value   New url
       *
       * @return {*}          Parsed url
       */
      function onValueChange(value) {
        url = parseUrl(value);
        $anchor.text(url);
        setAnchorHref(value);
        toggleAnchor();
        return url;
      }

      /**
       * Handle input changes
       */
      function onInternalChange() {
        onValueChange(ngModelCtrl.$modelValue);
      }

      /**
       * Handle readonly attribute changes
       *
       * @param {String} newValue    Readonly attribute value
       */
      function onReadonlyChange(newValue) {
        readonly = newValue;
        toggleAnchor();
      }

      /**
       * Toggle the anchor tag
       */
      function toggleAnchor() {
        if (readonly && !angular.isEmpty(url)) {
          $anchor.css("display", "inline-block");
          $element.css("display", "none");
        } else {
          $anchor.css("display", "none");
          $element.css("display", "block");
        }
      }
    }
  }
})();
