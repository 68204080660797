(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.donutChart
   *
   * @param {Array} data - data to populate the chart
   * @param {Array} innerData - optional data for an inner ring
   * @param {String} idTag - unique #id for the chart
   * @param {Number} diameter - diameter of the chart (default 300)
   * @param {Number} thickness - thickness of the chart sections (default 50)
   * @param {Array} colors - an array of colors for the chart (has default)
   * @param {Object} defaultText - object containing the default value and name
   */
  angular
    .module("akitabox.ui.directives.donutChart", [
      "ngMaterial",
      "akitabox.core",
      "akitabox.core.lib.d3",
    ])
    .directive("abxDonutChart", AbxDonutChartDirective);

  /* @ngInject */
  function AbxDonutChartDirective(
    $log,
    $mdMedia,
    d3,
    ChartService,
    Utils,
    DEFAULT_CHART_COLOR_RANGE
  ) {
    return {
      restrict: "E",
      scope: {
        data: "<abxData",
        innerData: "<?abxInnerData",
        outerOrdinalScale: "<?abxOuterOrdinalScale",
        innerOrdinalScale: "<?abxInnerOrdinalScale",
        idTag: "<abxIdTag",
        diameter: "<?abxDiameter",
        thickness: "<?abxThickness",
        colors: "<?abxColors",
        defaultText: "<?abxDefaultText",
        onClick: "&?abxOnClick",
        displayValueAsPercent: "<?abxDisplayValueAsPercent",
        usePercentInLegend: "<?abxUsePercentInLegend",
        truncateEmptyData: "<?abxTruncateEmptyData",
        truncateLegendText: "<?abxTruncateLegendText",
        hideSourceInLegend: "<?abxHideSourceInLegend",
      },
      link: postlink,
    };
    function postlink($scope, $element, $attrs) {
      if (!$scope.data) {
        return $log.error("<abx-donut-chart>: abx-data must be provided");
      }

      if (!$scope.idTag) {
        return $log.error("<abx-donut-chart>: abx-id-tag must be provided");
      }

      // Attributes
      $scope.hasClick = Utils.parseAttribute($attrs, "abxOnClick", false);

      // Constants
      var CHART_SUFFIX = "-donut-chart";
      var LEGEND_SUFFIX = "-donut-legend";
      var NAME_SUFFIX = "-name-text";
      var VALUE_SUFFIX = "-value-text";
      var MAX_LEGEND_LENGTH = {
        xs: 12,
        sm: 30, // max length of pin type name
        md: 16,
        lg: 18,
        "gt-lg": 30,
      };
      var MAX_CHART_LENGTH = 15;
      var TRUNC_CHART_LENGTH = MAX_CHART_LENGTH;
      var SMALL_FONT = 12;
      var LARGE_FONT = 14;
      var CHART_NAME_DY = "2.4em";
      var CHART_VALUE_DY = "0.2em";
      var LEGEND_DY = "1em";
      var CHART_PADDING = $scope.innerData ? 0 : 0.02;
      var HOVER_SIZE = 15;

      // Defaults
      var defaultDiameter = 300;
      var defaultThickness = 50;
      var defaultDoubleRingThickness = 25;
      var defaultInnerColors = ["#2D8DD9", "#99CFEA", "#94CB17", "#AAB96D"];

      var displayValueAsPercent = $scope.displayValueAsPercent || false;
      var usePercentInLegend = $scope.usePercentInLegend || false;
      var hideSourceInLegend = $scope.hideSourceInLegend || false;
      var diameter = $scope.diameter || defaultDiameter;
      var thickness = $scope.thickness || defaultThickness;
      var defaultText = $scope.defaultText || { name: "", value: "" };
      var truncateEmptyData = $scope.truncateEmptyData || false;
      var truncateLegendText = $scope.truncateLegendText || false;
      var colorRange = $scope.colors || DEFAULT_CHART_COLOR_RANGE;
      var outerColorScale = $scope.outerOrdinalScale
        ? d3
            .scaleOrdinal()
            .domain($scope.outerOrdinalScale.labels)
            .range($scope.outerOrdinalScale.colors)
        : null;

      var innerColorScale = $scope.innerOrdinalScale
        ? d3
            .scaleOrdinal()
            .domain($scope.innerOrdinalScale.labels)
            .range($scope.innerOrdinalScale.colors)
        : null;

      var outerRadius = diameter / 2 - HOVER_SIZE;
      var color = d3.scaleOrdinal().range(colorRange);
      var innerColor = d3.scaleOrdinal().range(defaultInnerColors);

      var idTag = $scope.idTag;

      var legendValues = [];

      var debouncedOnResize = angular.debounce(onResize, 50);

      // The onus is on the user of abx-donut-chart to ensure their idTag is unique,
      // but this should ensure that those elements we need to hook into for interactions
      // are truly unique on the page
      var chartName =
        new Date().getTime() + "_" + Math.floor(Math.random() * 1000000);
      var idSequence = 0;

      // Watchers

      $scope.$watch("data", function (newData) {
        // Remove any existing donut charts
        d3.select("#" + idTag + CHART_SUFFIX).remove();
        d3.select("#" + idTag + LEGEND_SUFFIX).remove();

        drawChart(newData, $scope.innerData);
      });

      $scope.$watch("innerData", function (newData) {
        // Remove any existing donut charts
        d3.select("#" + idTag + CHART_SUFFIX).remove();
        d3.select("#" + idTag + LEGEND_SUFFIX).remove();

        drawChart($scope.data, newData);
      });

      $scope.$watch("defaultText", function (newData) {
        if (
          newData.name === defaultText.name &&
          newData.value === defaultText.value
        ) {
          return;
        }
        // Remove any existing donut charts
        d3.select("#" + idTag + CHART_SUFFIX).remove();
        d3.select("#" + idTag + LEGEND_SUFFIX).remove();
        defaultText = newData;

        drawChart($scope.data, $scope.innerData);
      });

      $scope.$watch(
        function () {
          var $parent = $element[0].parentElement;
          return {
            width: $parent ? $parent.clientWidth : 0,
            height: $parent ? $parent.clientHeight : 0,
          };
        },
        debouncedOnResize,
        true
      );

      function generateID(append) {
        var domID = "id_" + idTag + "_" + chartName + "_" + ++idSequence;
        if (append) {
          domID += "_" + append;
        }
        return domID;
      }

      function drawChart(outerData, innerData) {
        if (!outerData || !outerData.length) {
          return;
        }

        // Filter out segments with no data if specified
        if (truncateEmptyData) {
          outerData = outerData.filter(function (item) {
            return item.value;
          });
          innerData = innerData.filter(function (item) {
            return item.value;
          });
        }

        // Determine whether any data segment is emphasized
        var shouldEmphasizeSections =
          (outerData &&
            outerData.some(function (d) {
              return d.shouldEmphasize;
            })) ||
          (innerData &&
            innerData.some(function (d) {
              return d.shouldEmphasize;
            }));

        var thicknessToUse = thickness;
        if (innerData && innerData.length && !$scope.thickness) {
          thicknessToUse = defaultDoubleRingThickness;
        }
        var innerRadius = outerRadius - thicknessToUse + 1;

        // Initialize Chart
        var donutChart = d3.select($element[0]);
        var chartDiv = donutChart
          .append("div")
          .attr("id", idTag)
          .attr("class", "abx-donut-chart")
          .style("min-width", diameter)
          .style("min-height", diameter);

        var svg = chartDiv
          .append("svg")
          .attr("id", idTag + CHART_SUFFIX)
          .attr("width", diameter)
          .attr("height", diameter);
        var outerChart = svg
          .append("g")
          .attr(
            "transform",
            "translate(" + diameter / 2 + "," + diameter / 2 + ")"
          );
        var innerChart = svg
          .append("g")
          .attr(
            "transform",
            "translate(" + diameter / 2 + "," + diameter / 2 + ")"
          );

        var outerArc = d3
          .arc()
          .innerRadius(outerRadius - thicknessToUse)
          .outerRadius(outerRadius);
        var innerArc = d3
          .arc()
          .innerRadius(innerRadius - thicknessToUse)
          .outerRadius(innerRadius);

        var outerHoverArc = d3
          .arc()
          .innerRadius(outerRadius - thicknessToUse)
          .outerRadius(outerRadius + HOVER_SIZE);

        var innerHoverArc = d3
          .arc()
          .innerRadius(innerRadius - thicknessToUse)
          .outerRadius(innerRadius + HOVER_SIZE);

        var pie = d3
          .pie()
          .value(function (d) {
            // If percentValue, value is a whole number and should be used as is
            if (d.parent && !d.percentValue) {
              return d.parentValue * (d.value / 100);
            }
            return d.value;
          })
          .padAngle(CHART_PADDING)
          .sort(null);

        outerChart
          .selectAll("path")
          .data(pie(outerData))
          .enter()
          .append("path")
          .attr("d", outerArc)
          .attr("fill", function (d, i) {
            var fillColor;
            if (outerColorScale) {
              fillColor = outerColorScale(d.data.name);
            } else {
              fillColor = color(i);
            }
            if (
              !shouldEmphasizeSections ||
              (shouldEmphasizeSections && d.data.shouldEmphasize)
            ) {
              return fillColor;
            } else {
              var hslColor = d3.hsl(fillColor);
              hslColor.l = hslColor.l + 0.2;
              return d3.rgb(hslColor).toString();
            }
          })
          .attr("id", function (d) {
            if (!d.data.domID) {
              d.data.domID = generateID();
            }
            return d.data.domID;
          })
          .on("mouseover", function (d) {
            mouseover(d.data, false);
          })
          .on("mouseout", function (d) {
            mouseout(d.data, false);
          })
          .each(function (d, i) {
            var data = d.data;
            if (data.shouldEmphasize) {
              var segment = data.parentDomID
                ? d3.select("#" + data.parentDomID)
                : d3.select("#" + data.domID);

              segment.attr("d", outerHoverArc);
            }

            this._current = i;
            angular.element(this).on("click", function () {
              onClick(d.data);
            });
          });

        // Create Text for inside the donut
        var chartName = outerChart
          .append("text")
          .attr("id", generateID(NAME_SUFFIX))
          .attr("class", "name-text")
          .text(defaultText.name)
          .attr("text-anchor", "middle")
          .attr("dy", CHART_NAME_DY);

        var chartValue = outerChart
          .append("text")
          .attr("id", generateID(VALUE_SUFFIX))
          .attr("class", "value-text")
          .text(defaultText.value)
          .attr("text-anchor", "middle")
          .attr("dy", CHART_VALUE_DY);

        // Create Legend Div
        var legend = donutChart
          .append("div")
          .attr("id", idTag + LEGEND_SUFFIX)
          .attr("class", "legend");

        var legendMap = {};

        // Create <text> and <svg> for each data type
        outerData.forEach(function (data, index) {
          // parent (top level) legend element
          var div = legend
            .append("div")
            .attr("class", "legend-value")
            .on("mouseover", function () {
              mouseover(data, false);
            })
            .on("mouseout", function () {
              mouseout(data, false);
            })
            .on("click", function () {
              onClick(data);
            });

          // Draw circle
          var childDiv = div
            .append("div")
            .attr("id", ChartService.normalize(data.name) + LEGEND_SUFFIX)
            .attr("class", "legend-value-container");

          if (
            !shouldEmphasizeSections ||
            (shouldEmphasizeSections && data.shouldEmphasize)
          ) {
            // Display filled crcles for the following 2 cases
            // 1. nothing is emphasized -> everything gets a filled curcle in the legend
            // 2. at least one section should be emphasized, and this section should be emphasized
            childDiv
              .append("svg")
              .attr("width", 20)
              .attr("height", 20)
              .append("circle")
              .attr("cx", 10)
              .attr("cy", 10)
              .attr("r", 10)
              .style("fill", function () {
                if (outerColorScale) {
                  return outerColorScale(data.name);
                }
                return color(index);
              });
          } else {
            childDiv
              .append("svg")
              .attr("width", 20)
              .attr("height", 20)
              .append("circle")
              .attr("cx", 10)
              .attr("cy", 10)
              .attr("r", 9)
              .style("stroke", function () {
                var strokeColor;
                if (outerColorScale) {
                  strokeColor = outerColorScale(data.name);
                } else {
                  strokeColor = color(index);
                }
                var hslColor = d3.hsl(strokeColor);
                hslColor.l = hslColor.l + 0.2;
                return d3.rgb(hslColor).toString();
              })
              .style("stroke-width", 2)
              .style("fill", "none");
          }

          // Adjust names that are too long
          var maxLength = getMaxLegendLength();
          var text = buildLegendText(data, maxLength, maxLength - 3);

          var textElement = childDiv.append("text");
          textElement
            .text(text)
            .attr("dy", LEGEND_DY)
            .attr("class", "value-text");
          if (shouldEmphasizeSections && !data.shouldEmphasize) {
            textElement.style("opacity", 0.7);
          }
          legendValues.push({
            data: data,
            element: textElement,
          });

          legendMap[data.name] = div
            .append("div")
            .attr("class", "legend-children");
        });

        if (!innerData || !innerData.length) {
          return;
        }

        innerChart
          .selectAll("path")
          .data(pie(innerData))
          .enter()
          .append("path")
          .attr("d", innerArc)
          .attr("fill", function (d, i) {
            var fillColor;
            if (innerColorScale) {
              fillColor = innerColorScale(d.data.name);
            } else {
              fillColor = innerColor(i);
            }
            if (
              !shouldEmphasizeSections ||
              (shouldEmphasizeSections && d.data.shouldEmphasize)
            ) {
              return fillColor;
            } else {
              var hslColor = d3.hsl(fillColor);
              hslColor.l = hslColor.l + 0.2;
              return d3.rgb(hslColor).toString();
            }
          })
          .attr("id", function (d) {
            if (!d.data.domID) {
              d.data.domID = generateID();
            }
            return d.data.domID;
          })
          .on("mouseover", function (d) {
            mouseover(d.data, true);
          })
          .on("mouseout", function (d) {
            mouseout(d.data, true);
          })
          .each(function (d, i) {
            var data = d.data;
            if (data.shouldEmphasize) {
              var segment = data.parentDomID
                ? d3.select("#" + data.parentDomID)
                : d3.select("#" + data.domID);

              segment.attr(
                "d",
                !data.parentDomID ? innerHoverArc : outerHoverArc
              );
            }

            this._current = i;
            angular.element(this).on("click", function () {
              onClick(d.data);
            });
          });

        // Create <text> and <svg> for each data type
        var curParents = [];
        innerData.forEach(function (data, index) {
          if (data.skipInnerLegend) {
            return;
          }
          var parentLegend = legendMap[data.parent];

          // Add SOURCE: To the first child legend
          if (curParents.indexOf(data.parent) === -1 && !hideSourceInLegend) {
            curParents.push(data.parent);
            parentLegend
              .append("div")
              .style("padding", " 4px 18px")
              .html("<strong>SOURCE:</strong>");
          }

          // child (second level) legend element
          var div = parentLegend
            .append("div")
            .attr("id", generateID(LEGEND_SUFFIX))
            .attr("class", "legend-value-container inner-legend-value")
            .on("mouseover", function () {
              d3.event.stopPropagation();
              mouseover(data, true);
            })
            .on("mouseout", function () {
              d3.event.stopPropagation();
              mouseout(data, true);
            })
            .on("click", function () {
              d3.event.stopPropagation();
              onClick(data);
            });

          // Draw circle
          if (
            !shouldEmphasizeSections ||
            (shouldEmphasizeSections && data.shouldEmphasize)
          ) {
            // Display filled crcles for the following 2 cases
            // 1. nothing is emphasized -> everything gets a filled curcle in the legend
            // 2. at least one section should be emphasized, and this section should be emphasized
            div
              .append("svg")
              .attr("width", 20)
              .attr("height", 20)
              .append("circle")
              .style("fill", function () {
                if (innerColorScale) {
                  return innerColorScale(data.name);
                }
                return innerColor(index);
              })
              .attr("cx", 10)
              .attr("cy", 10)
              .attr("r", 10);
          } else {
            div
              .append("svg")
              .attr("width", 20)
              .attr("height", 20)
              .append("circle")
              .style("stroke", function () {
                var strokeColor;
                if (innerColorScale) {
                  strokeColor = innerColorScale(data.name);
                } else {
                  strokeColor = innerColor(index);
                }
                var hslColor = d3.hsl(strokeColor);
                hslColor.l = hslColor.l + 0.2;
                return d3.rgb(hslColor).toString();
              })
              .style("stroke-width", 2)
              .style("fill", "none")
              .attr("cx", 10)
              .attr("cy", 10)
              .attr("r", 9);
          }

          // Adjust names that are too long
          var maxLength = getMaxLegendLength();
          var text = buildLegendText(data, maxLength, maxLength - 3);

          var textElement = div.append("text");
          textElement
            .text(text)
            .attr("dy", LEGEND_DY)
            .attr("class", "value-text");
          if (shouldEmphasizeSections && !data.shouldEmphasize) {
            textElement.style("opacity", 0.7);
          }
          legendValues.push({
            data: data,
            element: textElement,
          });
        });

        // Section Size Animation on Mouseover
        function mouseover(data, isInnerArc) {
          var segment = data.parentDomID
            ? d3.select("#" + data.parentDomID)
            : d3.select("#" + data.domID);
          if (!$element.hasClass("abx-no-click")) {
            segment.style("cursor", "pointer");
          }

          segment
            .transition()
            .attr(
              "d",
              isInnerArc && !data.parentDomID ? innerHoverArc : outerHoverArc
            );

          // for when we treat the inner and outer ring segments as one
          if (data.parentDomID) {
            var innerSegment = d3.select("#" + data.domID);
            innerSegment.style("cursor", "pointer");
          }

          // Adjust names that are too long
          chartName.text(
            truncate(data.name, MAX_CHART_LENGTH, TRUNC_CHART_LENGTH)
          );
          var valueText = displayValueAsPercent ? data.value + "%" : data.value;
          chartValue.text(valueText);

          var nameText = chartName._groups[0][0].innerHTML;
          adjustFontSize(chartName, nameText, MAX_CHART_LENGTH);
        }

        // Section Size Animation on Mouseout
        function mouseout(data, isInnerArc) {
          var segment = data.parentDomID
            ? d3.select("#" + data.parentDomID)
            : d3.select("#" + data.domID);
          if (!$element.hasClass("abx-no-click")) {
            segment.style("cursor", "pointer");
          }

          if (!data.shouldEmphasize) {
            segment
              .transition()
              .attr("d", isInnerArc && !data.parentDomID ? innerArc : outerArc);
          }

          chartName.text(defaultText.name);
          chartValue.text(defaultText.value);
        }

        function onClick(data) {
          if ($scope.hasClick) {
            var $event = {
              data: data,
            };
            $scope.onClick({ $event: $event });
          }
        }
      }

      /**
       * Check if we can display full text action buttons
       */
      function onResize() {
        $scope.$apply(function () {
          var maxLength = getMaxLegendLength();
          var truncatedLength = maxLength - 3; // ellipsis
          for (var v in legendValues) {
            var value = legendValues[v];
            var text = buildLegendText(value.data, maxLength, truncatedLength);
            value.element.text(text);
          }
        });
      }

      /* Helper Functions */

      function getMaxLegendLength() {
        var keys = Object.keys(MAX_LEGEND_LENGTH);
        for (var k in keys) {
          var key = keys[k];
          if ($mdMedia(key)) {
            return MAX_LEGEND_LENGTH[key];
          }
        }
        return MAX_LEGEND_LENGTH.xs;
      }

      function buildLegendText(data, maxLength, newLength) {
        var text = truncateLegendText
          ? truncate(data.name, maxLength, newLength)
          : data.name;
        if (usePercentInLegend) {
          text += " (" + data.percentValue + "%";
        } else {
          text += " (" + data.value;
          if (displayValueAsPercent) {
            text += "%";
          }
        }

        return text + ")";
      }

      function adjustFontSize(element, string, maxLength) {
        if (string && string.length > maxLength) {
          element.style("font-size", SMALL_FONT);
        } else {
          element.style("font-size", LARGE_FONT);
        }
      }

      function truncate(text, maxLength, newLength) {
        return text.length > maxLength
          ? text.substring(0, newLength) + "..."
          : text;
      }
    }
  }
})();
