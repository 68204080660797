(function () {
  angular.module("akitabox.desktop.insights", [
    "akitabox.core.router",
    "akitabox.constants",
    "akitabox.core.services.organization",
    "akitabox.core.filters.trustAs",
    "angular.filter",
    "ui.router",
    "akitabox.core.utils",
  ]);
})();
