(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.details.room
   */
  angular
    .module("akitabox.desktop.directives.details.room", [
      "ui.router",
      "akitabox.constants",
      "akitabox.ui",
      "akitabox.core.services.floor",
      "akitabox.core.services.pinValue",
      "akitabox.core.services.user",
      "akitabox.core.services.room",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.document.select",
    ])
    .directive("abxRoomDetails", AbxRoomDetailsDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.details.room
   * @name AbxRoomDetailsDirective
   *
   * @description
   * `<abx-room-details>` displays the details of a room and allows for editing
   * of fields based on the current user's permissions
   *
   * @param {Object}      ng-model            The component's model (room)
   * @param {Object}      ng-model-options    Allows tuning of the way in which
   *                                          the `ng-model` is updated
   * @param {expression}  ng-change           Expression evaluated when the model
   *                                          value changes
   * @param {Object}      abx-building        Building that the room belongs to
   *
   * @usage
   * <hljs lang="html">
   *   <abx-room-details
   *       ng-model="vm.room"
   *       abx-building="vm.building">
   *   </abx-room-details>
   * </hljs>
   *
   * @ngInject
   */
  function AbxRoomDetailsDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/details/room/room-details.html",
      require: ["abxRoomDetails", "ngModel"],
      controller: AbxRoomDetailsController,
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=abxBuilding",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Controllers
      var vm = controllers[0];
      vm.ngModelCtrl = controllers[1];

      // Add external change listener
      vm.ngModelCtrl.$formatters.push(onExternalChange);

      /**
       * Handle external changes to the model value
       *
       * @param  {Object} value   New model value
       * @return {Object}         Formatted model value
       */
      function onExternalChange(value) {
        vm.room = angular.copy(value);
        vm.init();
        return value;
      }
    }
  }

  /**
   * Controller for abx-room-details
   *
   * @ngInject
   */
  function AbxRoomDetailsController(
    // Angular
    $q,
    $timeout,
    $state,
    // Services
    FeatureFlagService,
    PinValueService,
    RoomService,
    ToastService,
    UserService
  ) {
    var self = this;
    var _permissions = UserService.getPermissions();

    // ------------------------
    //   Attributes
    // ------------------------

    self.building = angular.isDefined(self.building) ? self.building : null;
    self.room = null;
    self.canEdit = _permissions.room.update;
    self.floorLink = null;
    self.rendering = true;
    self.pinTypeUrl = null;
    self.buildingUrl = null;

    // Functions
    self.init = init;
    self.updateRoom = updateRoom;
    self.updateField = updateField;
    self.getValuesLength = getValuesLength;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Initialize the directive and get permissions
     */
    function init() {
      self.buildingUrl = getBuildingUrl();
      if (self.room) {
        self.pinTypeUrl = getPinTypeUrl(self.room.pinType.uri);

        // Need to save level pinfield IDs for later use (easier to access)
        // This way, we don't traverse the .values object each time
        var roomValues = self.room.values;
        self.levelValue = PinValueService.getFloorPinValue(roomValues);
        self.levelFieldId = self.levelValue ? self.levelValue.pinField : null;
      } else {
        self.canEdit = false;
      }
      $timeout(function () {
        self.rendering = false;
      }, 500);
    }

    /**
     * Method to update this room
     */
    function updateRoom() {
      var data = {
        name: self.room.name,
        number: self.room.number,
        square_feet: self.room.square_feet,
      };
      var params = { include_values: true };
      var floor = self.room.level || null;
      var pinType = self.room.pinType;

      return RoomService.updateById(
        self.building._id,
        self.room._id,
        data,
        params
      )
        .then(function (updatedRoom) {
          // room name is changing, assume pin type and floor stay the
          // same; have to re-attach to updated object
          updatedRoom.level = floor;
          updatedRoom.pinType = pinType;
          self.room = updatedRoom;
          setModel();
        })
        .catch(ToastService.showError);
    }

    /**
     * Method which updates self.room and propagates it up to its parent
     *
     * @param {Object}  field       The pin field object that belongs to the changed pin value
     * @param {Object}  newValue    The new value of the pin value
     */
    function updateField(field, newValue) {
      // Level field update needs some extra handling
      if (
        angular.isString(self.levelFieldId) &&
        self.levelFieldId === field._id
      ) {
        if (newValue && newValue._id) {
          // they are changing the level to something
          self.room.level = newValue;
          self.room.values[self.levelFieldId].name = newValue.name;
          self.room.values[self.levelFieldId].value = newValue._id;
        } else {
          // they are clearing the level
          self.room.level = null;
          self.room.values[self.levelFieldId].name = null;
          self.room.values[self.levelFieldId].value = "";
        }
      } else {
        // Simple fields we don't need to do special handling on the update
        for (var key in self.room.values) {
          var roomValue = self.room.values[key];
          if (roomValue.pinField === field._id) {
            if (roomValue.pinField.data_type === "document_array") {
              roomValue.documents = newValue.documents;
              roomValue.value = newValue.value;
            } else if (field.data_type === "tag_filter") {
              roomValue.value = newValue.value;
            } else {
              roomValue.value = newValue;
            }
            break;
          }
        }
      }

      setModel();
    }

    function getValuesLength(pinValues) {
      return Object.keys(pinValues).length;
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Set the directives model
     */
    function setModel() {
      var model = angular.extend(self.ngModelCtrl.$modelValue, self.room);
      self.ngModelCtrl.$setViewValue(model);
    }

    /**
     * Generates the correct front-end url to the pin type
     *
     * @param {String} url
     *
     * @returns {String}
     */
    function getPinTypeUrl(url) {
      return `/organization/settings/categories/${
        url.split("/pin_types/")[1]
      }/fields`;
    }

    /**
     * Generates url to the building dashboad
     */
    function getBuildingUrl() {
      return $state.href("app.building.detail", {
        buildingId: self.building._id,
      });
    }
  }
})();
