(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.note
   */
  angular
    .module("akitabox.core.services.note", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("NoteService", NoteService);

  /**
   * @ngdoc factory
   * @name akitabox.core.services.note:NoteService
   *
   * @description
   * Note service for handle note CRUD operations
   */
  /** @ngInject */
  function NoteService(models, HttpService) {
    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrg: getStatsByOrg,
      // Update
      update: update,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBuildingBaseRoute(buildingId) {
      return "/buildings/" + buildingId + "/" + models.NOTE.ROUTE_PLURAL;
    }

    function buildOrganizationBaseRoute(orgId) {
      return "/organizations/" + orgId + "/" + models.NOTE.ROUTE_PLURAL;
    }

    function buildBuildingDetailRoute(buildingId, noteId) {
      var base = buildBuildingBaseRoute(buildingId);
      return base + "/" + noteId;
    }

    function buildBuildingListRoute(buildingId) {
      return buildBuildingBaseRoute(buildingId);
    }

    function buildOrganizationListRoute(orgId) {
      return buildOrganizationBaseRoute(orgId);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    /**
     * Create note
     *
     * @param  {String}     buildingId      Building URI
     * @param  {Object}     data            Note data
     * @return {Promise}                    Request promise
     */
    function create(buildingId, data) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    /**
     * Get notes for a building that match a set of params
     *
     * @param  {String}     buildingId      Building id
     * @param  {Object}     params          Query parameters
     * @return {Promise}                    Request promise
     */
    function get(buildingId, params) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.get(route, params);
    }

    /**
     * Get all notes for a building
     *
     * @param  {String}     buildingId      Building id
     * @param  {Object}     params          Query parameters
     * @return {Promise}                    Request promise
     */
    function getAll(buildingId, params) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.getAll(route, params);
    }

    /**
     * Get a note by ID
     *
     * @param  {String}     buildingId      Building URI
     * @param  {String}     id              Note ID
     * @return {Promise}                    Request promise
     */
    function getById(buildingId, id, params) {
      var route = buildBuildingDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params);
    }

    /**
     * Get stats for an org
     *
     * @param {String} orgId Org ID
     * @param {Object} params Params
     */
    function getStatsByOrg(orgId, params) {
      var route = "/stats" + buildOrganizationListRoute(orgId);
      return HttpService.get(route, params);
    }

    /**
     * Get stats for a building
     *
     * @param {String} buldingId Building ID
     * @param {Object} params Params
     */
    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildBuildingListRoute(buildingId);
      return HttpService.get(route, params);
    }

    /******************* *
     *   Update
     * ****************** */

    /**
     * Update a note
     *
     * @param  {String}     buildingId      Building Id
     * @param  {String}     id              Note ID
     * @param  {Object}     data            Note data
     * @return {Promise}                    Request promise
     */
    function update(buildingId, id, data) {
      var route = buildBuildingDetailRoute(buildingId, id);
      return HttpService.put(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    /**
     * Delete a note
     *
     * @param  {String}     buildingId      Building URI
     * @param  {String}     id              Note ID
     * @return {Promise}                    Request promise
     */
    function remove(buildingId, id) {
      var route = buildBuildingDetailRoute(buildingId, id);
      return HttpService.remove(route);
    }
  }
})();
