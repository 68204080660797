import { getDomain } from "./getDomain";
import { getSubdomain } from "./getSubdomain";

/**
 * Returns a full URL link with protocol, org subdomain and env domain
 *
 * @param {string} [subdomainKey=getSubdomain()] - Optional subdomain key (overrides)
 * @returns the full URL link. The subdomain can either be passed as
 * an argument or it is obtained from the `getSubdomain()` function.
 * The domain is obtained from the `getDomain()` function and
 * the protocol is obtained from the `window.location` object
 */
export function getHref(subdomainKey = getSubdomain()): string {
  const domain = getDomain();
  const { protocol } = window.location;
  if (subdomainKey) {
    return `${protocol}//${subdomainKey}.${domain}`;
  }
  return `${protocol}//${domain}`;
}
