(function () {
  angular
    .module("akitabox.core.services.trade", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("TradeService", TradeService);

  /** @ngInject */
  function TradeService(
    // Constants
    models,
    // Services
    HttpService
    // Third-party
    // CacheFactory
  ) {
    var cache;

    init();

    function init() {
      // TODO: Re-add once trades are moved to organization-level
      // cache = CacheFactory.get("trades");
      // if (!cache) {
      //   cache = CacheFactory("trades");
      // }
    }

    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildDetailRoute: buildDetailRoute,
      buildListRoute: buildListRoute,
      // Retrieve
      get: get,
      getById: getById,
      getAll: getAll,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute(buildingId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.TRADE.ROUTE_PLURAL
      );
    }

    function buildDetailRoute(buildingId, tradeId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + tradeId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      // TODO: Re-add once trades are moved to organization-level
      // if (options && !options.cache) {
      //   return HttpService.get(route, params);
      // }
      return HttpService.get(route, params, cache);
    }

    function getAll(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      // TODO: Re-add once trades are moved to organization-level
      // if (options && !options.cache) {
      //   return HttpService.getAll(route, params);
      // }
      return HttpService.getAll(route, params, cache);
    }

    function getById(buildingId, tradeId, params, options) {
      var route = buildDetailRoute(buildingId, tradeId);
      // TODO: Re-add once trades are moved to organization-level
      // if (options && !options.cache) {
      //   return HttpService.getById(route, tradeId, params);
      // }
      return HttpService.getById(route, tradeId, params, cache);
    }
  }
})();
