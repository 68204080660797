(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.dialogs.schedule.delete
   */
  angular
    .module("akitabox.ui.dialogs.schedule.delete")
    .controller(
      "DeleteScheduleDialogController",
      DeleteScheduleDialogController
    );

  /* @ngInject */
  function DeleteScheduleDialogController(
    // Material
    $mdDialog,
    // Services
    WorkOrderService,
    ToastService,
    ScheduleService,
    // Constants
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_CANCELED,
    WORK_ORDER_STATUS_COMPLETED
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.totalWorkOrdersCount;
    self.canceledWorkOrderCount;
    self.openWorkOrderCount;
    self.scheduledWorkOrderCount;
    self.completedWorkOrderCount;
    // Functions
    self.deleteMaintenanceSchedule = deleteMaintenanceSchedule;
    self.cancel = $mdDialog.cancel;
    self.confirm = confirm;
    var TOTAL_WORK_ORDERS_QUERY = {
      status: "$in,open,new,completed,scheduled,canceled",
      future_task: self.schedule._id,
    };
    var CANCELLED_QUERY = {
      status: WORK_ORDER_STATUS_CANCELED,
      future_task: self.schedule._id,
    };
    var OPEN_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      future_task: self.schedule._id,
    };
    var SCHEDULED_QUERY = {
      status: WORK_ORDER_STATUS_SCHEDULED,
      future_task: self.schedule._id,
    };

    var COMPLETED_QUERY = {
      status: WORK_ORDER_STATUS_COMPLETED,
      future_task: self.schedule._id,
    };

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog,
     */
    function init() {
      if (angular.isEmpty(self.schedule.building)) {
        self.loading = false;
        return;
      }
      // check for existing work orders before allowing the schedule to be deleted
      getTotalWorkOrderCount();
      getOpenWorkOrderCount();
      getScheduledWorkOrderCount();
      getCompletedWorkOrderCount();
      getCancelledWorkOrderCount();
    }

    function getTotalWorkOrderCount() {
      var query = angular.extend({}, TOTAL_WORK_ORDERS_QUERY, { count: true });

      WorkOrderService.getByBuildingId(self.schedule.building, query)
        .then(function (response) {
          self.totalWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrderCount() {
      var query = angular.extend({}, OPEN_QUERY, { count: true });

      WorkOrderService.getByBuildingId(self.schedule.building, query)
        .then(function (response) {
          self.openWorkOrderCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getScheduledWorkOrderCount() {
      var query = angular.extend({}, SCHEDULED_QUERY, { count: true });

      WorkOrderService.getByBuildingId(self.schedule.building, query)
        .then(function (response) {
          self.scheduledWorkOrderCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getCompletedWorkOrderCount() {
      var query = angular.extend({}, COMPLETED_QUERY, { count: true });

      WorkOrderService.getByBuildingId(self.schedule.building, query)
        .then(function (response) {
          self.completedWorkOrderCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getCancelledWorkOrderCount() {
      var query = angular.extend({}, CANCELLED_QUERY, { count: true });

      WorkOrderService.getByBuildingId(self.schedule.building, query)
        .then(function (response) {
          self.canceledWorkOrderCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function deleteMaintenanceSchedule() {
      self.saving = true;

      return ScheduleService.remove(self.schedule.building, self.schedule._id)
        .then(function () {
          $mdDialog.hide();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
