(function () {
  /**
   * AbxOnClickAway behavioral directive.
   *
   * Evaluates a defined expression when you click away from the specified element.
   * This directive is useful for instances like programmatic dropdown menus that
   * should automatically close when you click away from them.
   */

  angular
    .module("akitabox.core.directives.onClickAway", [])
    .directive("abxOnClickAway", AbxOnClickAway);

  function AbxOnClickAway($document, $timeout) {
    return {
      restrict: "A",
      link: function ($scope, $element, attrs) {
        $document[0].addEventListener("click", checkForClickAway);

        $scope.$on("$destroy", function () {
          $document[0].removeEventListener("click", checkForClickAway);
        });

        function checkForClickAway(event) {
          if (!$element[0].contains(event.target)) {
            $timeout(function () {
              $scope.$eval(attrs.abxOnClickAway);
            });
          }
        }
      },
    };
  }
})();
