(function () {
  angular
    .module("akitabox.ui.dialogs.inspectionProgram.reactivate")
    .controller(
      "ReactivateInspectionProgramDialogController",
      ReactivateInspectionProgramDialogController
    );

  /* @ngInject */
  function ReactivateInspectionProgramDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    InspectionProgramService,
    OrganizationService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.cancel = $mdDialog.cancel;
    self.confirm = confirm;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Reactivate inspection program
     */
    function confirm() {
      self.saving = true;
      var program = self.inspectionPrograms[0];

      InspectionProgramService.activate(program._id, self.organization._id)
        .then(function (inspectionProgramID) {
          $mdDialog.hide(inspectionProgramID);
        })
        .catch(ToastService.showError)
        .finally(function (inspectionProgramID) {
          self.saving = false;
          return inspectionProgramID;
        });
    }
  }
})();
