(function () {
  /**
   * Common usage of this module:
   * inject(Mocks => {
   *  suite.mocks = new Mocks();
   * });
   */
  angular
    .module("mocks", ["akitabox.core.constants"])
    .service("Mocks", function (PIN_FIELD_DATA_TYPES) {
      return function (options) {
        const defaultOptions = {
          account: {},
          accounts: [],
          assemblies: [],
          assets: [],
          assetTypes: [],
          bannerMessages: [],
          buildings: [],
          checklists: [],
          checklistTemplates: [],
          conditions: [],
          costAdjustments: [],
          costLines: [],
          documents: [],
          floors: [],
          identities: [],
          inspectionPrograms: [],
          inspections: [],
          issueTypes: {},
          maintenanceTypes: {},
          markups: [],
          organizations: [],
          permission_groups: {},
          pinFields: {},
          pinTypes: [],
          pinValues: {},
          restrictedDomains: {},
          revisions: [],
          rooms: [],
          roomTypes: [],
          schedules: [],
          serviceRequests: [],
          subdomains: [],
          tagCategories: [],
          tagCategoryValues: [],
          tags: [],
          trades: {},
          treePinField: {},
          trees: {},
          users: [],
          workOrders: [],
          workOrderLogs: [],
        };

        const finalOptions = angular.extend(defaultOptions, options);

        const mocks = this;

        // =================
        // Accounts
        // =================

        mocks.accounts = [
          {
            _id: "mock",
            name: "Mr. Mock",
            email: "mrmock@mock.com",
            _data: {},
            can_admin: false,
            can_write: true,
            is_super_admin: false,
          },
          {
            _id: "super",
            name: "Super",
            email: "super@mock.com",
            _data: {},
            can_admin: true,
            can_write: true,
            is_super_admin: true,
          },
          {
            _id: "admin",
            name: "Admin",
            email: "admin@mock.com",
            _data: {},
            can_admin: true,
            can_write: true,
            is_super_admin: false,
          },
          {
            _id: "read",
            name: "Read",
            email: "read@mock.com",
            _data: {},
            can_admin: false,
            can_write: false,
            is_super_admin: false,
          },
          {
            _id: "write",
            name: "Write",
            email: "write@mock.com",
            _data: {},
            can_admin: false,
            can_write: true,
            is_super_admin: false,
          },
        ];

        // =================
        // Identities
        // =================
        mocks.identities = mocks.accounts.map((account) => {
          account.display_name = account.name;
          account.site_permissions = {};
          return account;
        });

        // =================
        // Inspection Programs
        // =================
        mocks.inspectionPrograms = [
          {
            checklist_template: "checklistTemplateId",
            name: "Daily - 1",
            description: "description",
            period: 1,
            interval: "days",
            start_date: "2020-04-24T00:00:00.001Z",
            organization: "5d448983c25570149b1316ad",
            cre_account: "testuser_akitabox",
            last_mod_account: null,
            last_mod_date: null,
            deleted_account: null,
            deleted_date: null,
            cre_date: "2020-05-18T19:35:35.888Z",
            deactivated_account: null,
            deactivated_date: null,
            compliance_code: null,
            priority: "medium",
            inspection_components: [
              {
                building: "buildingId",
                round_template: "round_template_id",
                _id: "5ec2e387da31da83c63e3c42",
                assignees: [],
                maintenance_type: null,
                trade: null,
              },
            ],
            days_until_due: 1,
            is_weekly_saturday: false,
            is_weekly_friday: false,
            is_weekly_thursday: false,
            is_weekly_wednesday: false,
            is_weekly_tuesday: false,
            is_weekly_monday: false,
            is_weekly_sunday: false,
            status: "active",
          },
          {
            checklist_template: "checklistTemplateId2",
            name: "Daily Clean - 1",
            description: "description",
            period: 1,
            interval: "days",
            start_date: "2020-04-24T00:00:00.001Z",
            organization: "5d448983c25570149b1316ad",
            cre_account: "testuser_akitabox",
            last_mod_account: null,
            last_mod_date: null,
            deleted_account: null,
            deleted_date: null,
            cre_date: "2020-05-18T19:35:35.888Z",
            deactivated_account: null,
            deactivated_date: null,
            compliance_code: null,
            priority: "medium",
            inspection_components: [
              {
                building: "buildingId",
                round_template: "round_template_id",
                _id: "5ec2e387da31da83c63e3c42",
                assignees: [],
                maintenance_type: null,
                trade: null,
              },
            ],
            days_until_due: 1,
            is_weekly_saturday: false,
            is_weekly_friday: false,
            is_weekly_thursday: false,
            is_weekly_wednesday: false,
            is_weekly_tuesday: false,
            is_weekly_monday: false,
            is_weekly_sunday: false,
            status: "active",
          },
        ];

        // =================
        // Subdomains
        // =================

        mocks.subdomains = [
          {
            _id: "0",
            key: "abx",
          },
          {
            _id: "1",
            key: "aramark",
          },
          {
            _id: "2",
            key: "goog",
          },
        ];

        // =================
        // Organizations
        // =================

        mocks.organizations = [
          {
            _id: "0",
            name: "AkitaBox",
            _data: {},
            subdomain_key: "abx",
          },
          {
            _id: "1",
            name: "Aramark",
            _data: {},
            subdomain_key: "aramark",
          },
          {
            _id: "2",
            name: "Google",
            _data: {},
            subdomain_key: "g",
          },
        ];

        // Assign subdomains to organizations and vice versa
        for (let s in mocks.subdomains) {
          mocks.subdomains[s].organization = mocks.organizations[s]._id;
          mocks.organizations[s].subdomain = mocks.subdomains[s];
        }

        // =================
        // Buildings
        // =================

        mocks.buildings = [
          {
            _id: "0",
            name: "Mock Building 1",
            _data: {},
            type: "Education",
            address: "212 East Washington Avenue",
            city: "Madison",
            state: "WI",
            postal_code: "53703",
            country: "United States",
            lat: 43.076799,
            lng: -89.3821346,
            coordinates: {
              latitude: 43.076799,
              longitude: -89.3821346,
            },
            date_built: new Date("1980-01-01"),
            date_purchased: new Date("2000-01-01"),
            show_levels: true,
            show_tasks: true,
            show_trades: true,
            show_maintenance_types: true,
            show_issue_types: true,
            require_trades: true,
            require_maintenance_types: true,
            require_issue_types: true,
            organization: mocks.organizations[0]._id,
          },
          {
            _id: "1",
            name: "Mock Building 2",
            _data: {},
            type: "Commericial",
            address: "1600 Amphitheatre Parkway",
            city: "Mountain View",
            postal_code: "94043",
            state: "CA",
            country: "United States",
            lat: 37.4226231,
            lng: -122.0845839,
            coordinates: {
              latitude: 37.4226231,
              longitude: -122.0845839,
            },
            date_built: new Date("1993-01-01"),
            date_purchased: new Date("2003-01-01"),
            show_levels: false,
            show_tasks: false,
            organization: mocks.organizations[1]._id,
          },
        ];

        // =================
        // QR Codes
        // =================

        mocks.qrCodes = [
          { _id: "0", code: 100000000 },
          { _id: "1", code: 100000001 },
          { _id: "2", code: 100000002 },
          { _id: "3", code: 100000003 },
        ];

        // =================
        // Tags
        // =================

        mocks.tagCategories = [
          { cat_name: "mock_tag_category_0" },
          { cat_name: "mock_tag_category_1" },
        ];

        mocks.tagCategoryValues = [
          { value_name: "cat_0_val_0" },
          { value_name: "cat_0_val_1" },
          { value_name: "cat_1_val_0" },
          { value_name: "cat_1_val_1" },
        ];

        mocks.tags = [
          {
            cat_name: mocks.tagCategories[0].cat_name,
            value_name: mocks.tagCategoryValues[0],
          },
          {
            cat_name: mocks.tagCategories[0].cat_name,
            value_name: mocks.tagCategoryValues[1],
          },
          {
            cat_name: mocks.tagCategories[1].cat_name,
            value_name: mocks.tagCategoryValues[2],
          },
          {
            cat_name: mocks.tagCategories[1].cat_name,
            value_name: mocks.tagCategoryValues[3],
          },
        ];

        // =================
        // Trades
        // =================

        const tradeNames = [
          "Custodial",
          "Electric",
          "Fire",
          "HVAC",
          "IT",
          "Plumbing",
        ];
        mocks.trades = {};
        for (let i in mocks.buildings) {
          let building = mocks.buildings[i];
          let buildingTrades = [];
          for (let j in tradeNames) {
            buildingTrades.push({
              _id: `${building._id}${j}`,
              name: tradeNames[j],
              building: building._id,
            });
          }
          mocks.trades[building._id] = buildingTrades;
        }

        // =================
        // Issue Types
        // =================

        const issueTypeNames = [
          "Cleaning / Custodial",
          "Electrical",
          "Heating or Cooling",
          "Keys / Locks",
          "Plumbing",
          "Room Setup",
        ];
        mocks.issueTypes = {};
        for (let i in mocks.buildings) {
          let building = mocks.buildings[i];
          let buildingIssueTypes = [];
          for (let j in issueTypeNames) {
            buildingIssueTypes.push({
              _id: `${building._id}${j}`,
              name: issueTypeNames[j],
              building: building._id,
              intent: "Reactive",
            });
          }
          mocks.issueTypes[building._id] = buildingIssueTypes;
        }

        // =================
        // Maintenance Types
        // =================

        const maintenanceTypeNames = [
          "Cleaning / Custodial",
          "Electrical",
          "Heating or Cooling",
          "Keys / Locks",
          "Plumbing",
          "Room Setup",
        ];
        mocks.maintenanceTypes = {};
        for (let i in mocks.buildings) {
          let building = mocks.buildings[i];
          let buildingMaintenanceTypes = [];
          for (let j in maintenanceTypeNames) {
            buildingMaintenanceTypes.push({
              _id: `${building._id}${j}`,
              name: maintenanceTypeNames[j],
              building: building._id,
              intent: "Preventive",
            });
          }
          mocks.maintenanceTypes[building._id] = buildingMaintenanceTypes;
        }

        // =================
        // Pin Types
        // =================

        mocks.assetTypes = [
          { _id: "0", name: "Mechanical", _data: {}, protected_type: "Asset" },
          { _id: "1", name: "IT", _data: {}, protected_type: "Asset" },
          { _id: "2", name: "Door", _data: {}, protected_type: "Asset" },
          { _id: "3", name: "Appliance", _data: {}, protected_type: "Asset" },
        ];

        mocks.assetTypes.forEach((pinType) => {
          pinType.is_asset = true;
          pinType.is_room = false;
        });

        // These IDs can't share IDs with asset types, since they are in the same
        // collection on the back end
        mocks.roomTypes = [
          {
            _id: "4",
            _data: {},
            protected_type: "Room",
            name: "Room",
            fields: [
              {
                _id: "pinFieldId1",
                name: "Name",
              },
              {
                _id: "pinFieldId2",
                name: "Floor",
              },
              {
                _id: "pinFieldId3",
                name: "Square Feet",
              },
            ],
          },
          {
            _id: "5",
            _data: {},
            protected_type: "Room",
            name: "Room",
            fields: [
              {
                _id: "pinFieldId1",
                name: "Name",
              },
              {
                _id: "pinFieldId2",
                name: "Floor",
              },
              {
                _id: "pinFieldId3",
                name: "Square Feet",
              },
            ],
          },
        ];

        mocks.roomTypes.forEach((pinType) => {
          pinType.is_asset = false;
          pinType.is_room = true;
        });
        mocks.pinTypes = [...mocks.assetTypes, ...mocks.roomTypes];

        // =================
        // Documents
        // =================

        mocks.documents = [
          {
            _id: "0",
            _data: {},
            building: mocks.buildings[0]._id,
            public_thumbnail_url_medium: "doc_0_med_thumbnail_url",
          },
          {
            _id: "1",
            _data: {},
            building: mocks.buildings[0]._id,
            public_thumbnail_url_medium: "doc_1_med_thumbnail_url",
          },
          {
            _id: "2",
            _data: {},
            building: mocks.buildings[1]._id,
            public_thumbnail_url_medium: "doc_2_med_thumbnail_url",
          },
          {
            _id: "3",
            _data: {},
            building: mocks.buildings[1]._id,
            public_thumbnail_url_medium: "doc_3_med_thumbnail_url",
          },
        ];

        // =================
        // Floors
        // =================

        mocks.floors = [
          {
            _id: "0",
            _data: {},
            name: "Mock Floor 1",
            building: mocks.buildings[0]._id,
            document: mocks.documents[0],
          },
          {
            _id: "1",
            _data: {},
            name: "Mock Floor 2",
            building: mocks.buildings[0]._id,
            document: mocks.documents[1],
          },
          {
            _id: "2",
            _data: {},
            name: "Mock Floor 3",
            building: mocks.buildings[1]._id,
            document: mocks.documents[2],
          },
          {
            _id: "3",
            _data: {},
            name: "Mock Floor 4",
            building: mocks.buildings[1]._id,
            document: mocks.documents[3],
          },
        ];

        // Link documents to their floors
        mocks.documents[0].level = mocks.floors[0]._id;
        mocks.documents[1].level = mocks.floors[1]._id;
        mocks.documents[2].level = mocks.floors[2]._id;
        mocks.documents[3].level = mocks.floors[3]._id;

        // =================
        // Revisions
        // =================

        mocks.revisions = [
          {
            _id: "0",
            binary_commit_uri: "mock_revision_uri_0",
            document: mocks.documents[0]._id,
            document_uri: `/buildings/${mocks.buildings[0]._id}/docs/${mocks.documents[0]._id}`,
            document_rev_num: 0,
            binary_commit: "mock_commit_0",
            public_url: "revision_0_public_url",
          },
          {
            _id: "1",
            binary_commit_uri: "mock_revision_uri_1",
            document: mocks.documents[0]._id,
            document_uri: `/buildings/${mocks.buildings[0]._id}/docs/${mocks.documents[0]._id}`,
            document_rev_num: 1,
            binary_commit: "mock_commit_1",
            public_url: "revision_1_public_url",
          },
        ];

        // =================
        // Rooms
        // =================

        mocks.rooms = [
          {
            _id: "0",
            _data: {
              pinType: mocks.roomTypes[0],
              values: {
                pinFieldId1: {
                  name: "Name",
                  value: "100",
                },
                pinFieldId2: {
                  value: "1",
                },
                pinFieldId3: {
                  value: 1000,
                },
              },
            },
            building: mocks.buildings[0]._id,
            pinType: mocks.roomTypes[0]._id,
            name: "Room 100",
            number: "100",
            display_name: "Room 100",
            percentX: "0.687618092866613",
            percentY: "﻿0.463667820069204",
            page: "1",
            color: "#FEB934",
            uri: "assets/0",
            square_feeet: null,
          },
          {
            _id: "1",
            _data: {
              pinType: mocks.roomTypes[0],
              values: {
                pinFieldId1: {
                  name: "Name",
                  value: "101",
                },
                pinFieldId2: {
                  value: "1",
                },
                pinFieldId3: {
                  value: 1000,
                },
              },
            },
            building: mocks.buildings[0]._id,
            pinType: mocks.roomTypes[0]._id,
            name: "Room 101",
            number: "101",
            display_name: "Room 101",
            percentX: "0.475818092866613",
            percentY: "﻿0.931267820069204",
            page: "1",
            color: "#4732F3",
            uri: "assets/1",
            square_feeet: 100,
          },
          {
            _id: "2",
            _data: {
              pinType: mocks.roomTypes[1],
              values: {
                pinFieldId1: {
                  value: "102",
                },
                pinFieldId2: {
                  value: "1",
                },
                pinFieldId3: {
                  value: 1000,
                },
              },
            },
            building: mocks.buildings[1]._id,
            pinType: mocks.roomTypes[1]._id,
            name: "Room 102",
            number: "102",
            display_name: "Room 102",
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#473284",
            uri: "assets/2",
            square_feeet: 250,
          },
          {
            _id: "3",
            _data: {
              pinType: mocks.roomTypes[1],
              values: {
                pinFieldId1: {
                  value: "103",
                },
                pinFieldId2: {
                  value: "1",
                },
                pinFieldId3: {
                  value: 1000,
                },
              },
            },
            building: mocks.buildings[1]._id,
            pinType: mocks.roomTypes[1]._id,
            name: "Room 103",
            number: "103",
            display_name: "Room 103",
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#94FB3E",
            uri: "assets/3",
            square_feeet: 1000,
          },
        ];

        if (finalOptions.rooms.includePinType) {
          mocks.rooms = mocks.rooms.map(function (room) {
            room.pinType = room._data.pinType;
            return room;
          });
        }

        if (finalOptions.rooms.includeValues) {
          mocks.rooms = mocks.rooms.map(function (room) {
            room.values = room._data.values;
            return room;
          });
        }

        // =================
        // Assets
        // =================

        mocks.assets = [
          {
            _id: "0",
            name: "Asset 100",
            _data: {
              pinType: mocks.assetTypes[0],
              values: {
                pinFieldId1: {
                  value: "100",
                  _id: "assetValueId0.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId0.2",
                },
              },
            },
            building: mocks.buildings[0]._id,
            pinType: mocks.assetTypes[0]._id,
            percentX: "0.333318092866613",
            percentY: "﻿0.777467820069204",
            page: "1",
            color: "#487EBA",
            uri: "assets/0",
          },
          {
            _id: "1",
            name: "Asset 101",
            _data: {
              pinType: mocks.assetTypes[0],
              values: {
                pinFieldId1: {
                  value: "101",
                  _id: "assetValueId1.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId1.2",
                },
              },
            },
            building: mocks.buildings[0]._id,
            pinType: mocks.assetTypes[0]._id,
            percentX: "0.222218092866613",
            percentY: "﻿0.555567820069204",
            page: "2",
            color: "#321BCC",
            uri: "assets/1",
          },
          {
            _id: "2",
            name: "Asset 102",
            _data: {
              pinType: mocks.assetTypes[1],
              values: {
                pinFieldId1: {
                  value: "102",
                  _id: "assetValueId2.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId2.2",
                },
              },
            },
            building: mocks.buildings[0]._id,
            pinType: mocks.assetTypes[1]._id,
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#FEFEFE",
            uri: "assets/2",
          },
          {
            _id: "3",
            name: "Asset 103",
            _data: {
              pinType: mocks.assetTypes[1],
              values: {
                pinFieldId1: {
                  value: "103",
                  _id: "assetValueId3.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId3.2",
                },
              },
            },
            building: mocks.buildings[0]._id,
            pinType: mocks.assetTypes[1]._id,
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#5BCBCF",
            uri: "assets/3",
          },
          {
            _id: "4",
            name: "Asset 104",
            _data: {
              pinType: mocks.assetTypes[2],
              values: {
                pinFieldId1: {
                  value: "104",
                  _id: "assetValueId4.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId4.2",
                },
              },
            },
            building: mocks.buildings[1]._id,
            pinType: mocks.assetTypes[2]._id,
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#000000",
            uri: "assets/4",
          },
          {
            _id: "5",
            name: "Asset 105",
            _data: {
              pinType: mocks.assetTypes[2],
              values: {
                pinFieldId1: {
                  value: "105",
                  _id: "assetValueId5.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId5.2",
                },
              },
            },
            building: mocks.buildings[1]._id,
            pinType: mocks.assetTypes[2]._id,
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#A5A5A5",
            uri: "assets/5",
          },
          {
            _id: "6",
            name: "Asset 106",
            _data: {
              pinType: mocks.assetTypes[3],
              values: {
                pinFieldId1: {
                  value: "106",
                  _id: "assetValueId6.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId6.2",
                },
              },
            },
            building: mocks.buildings[1]._id,
            pinType: mocks.assetTypes[3]._id,
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#561A55",
            uri: "assets/6",
          },
          {
            _id: "7",
            name: "Asset 107",
            _data: {
              pinType: mocks.assetTypes[3],
              values: {
                pinFieldId1: {
                  value: "107",
                  _id: "assetValueId7.1",
                },
                pinFieldId2: {
                  value: "1",
                  _id: "assetValueId7.2",
                },
              },
            },
            building: mocks.buildings[1]._id,
            pinType: mocks.assetTypes[3]._id,
            percentX: "null",
            percentY: "null",
            page: "null",
            color: "#43BCAB",
            uri: "assets/7",
          },
        ];

        if (finalOptions.assets.includePinType) {
          mocks.assets = mocks.assets.map(function (asset) {
            asset.pinType = asset._data.pinType;
            return asset;
          });
        }

        if (finalOptions.assets.includeValues) {
          mocks.assets = mocks.assets.map(function (asset) {
            asset.values = asset._data.values;
            return asset;
          });
        }

        // =================
        // Assemblies
        // =================

        mocks.assemblies = [
          {
            _id: "mock_assembly_1",
            building: mocks.buildings[0],
            associated_assets: [mocks.assets[0]._id],
            cnc_uniformat: "mock_uniformat_a",
            cnc_description: "mock_description_a",
            severity_of_failure: "NONE",
            installation_date: new Date(),
          },
          {
            _id: "mock_assembly_2",
            building: mocks.buildings[0],
            associated_assets: [mocks.assets[1]._id],
            cnc_uniformat: "mock_uniformat_b",
            cnc_description: "mock_description_b",
            severity_of_failure: "INJURY",
            installation_date: new Date(),
          },
          {
            _id: "mock_assembly_3",
            building: mocks.buildings[0],
            associated_assets: [mocks.assets[2]._id],
            cnc_uniformat: "mock_uniformat_c",
            cnc_description: "mock_description_c",
            severity_of_failure: "DEATH",
            installation_date: new Date(),
          },
        ];

        // =================
        // Associations
        // =================

        mocks.associations = [
          {
            _id: "fake_id",
            last_mod_date: null,
            last_mod_account: null,
            association_type: "assoc_type_id",
            downstream_entity: mocks.assets[0],
            downstream_entity_model: "Asset",
            upstream_entity: mocks.rooms[0],
            upstream_entity_model: "Room",
            cre_account: "testuser_akitabox",
            cre_date: "2021-04-02T20:38:52.300Z",
            organization: "fake_org_id",
          },
          {
            _id: "another_fake_id",
            last_mod_date: null,
            last_mod_account: null,
            association_type: "assoc_type_id",
            downstream_entity: mocks.rooms[0],
            downstream_entity_model: "Room",
            upstream_entity: mocks.assets[0],
            upstream_entity_model: "Asset",
            cre_account: "testuser_akitabox",
            cre_date: "2021-04-02T20:38:52.300Z",
            organization: "fake_org_id",
          },
        ];

        // =================
        // Conditions
        // =================

        mocks.conditions = [
          {
            _id: "mock_condition_1",
            assembly: mocks.assemblies[0],
            condition_rating: "VERY_GOOD",
            date_recorded: new Date(),
          },
          {
            _id: "mock_condition_2",
            assembly: mocks.assemblies[0],
            condition_rating: "FAIR",
            date_recorded: new Date(),
          },
          {
            _id: "mock_condition_3",
            assembly: mocks.assemblies[1],
            condition_rating: "POOR",
            date_recorded: new Date(),
          },
        ];

        // =================
        // Cost Lines
        // =================

        mocks.costLines = [
          {
            _id: "mock_cost_line_1",
            assembly: mocks.assemblies[0],
            category: "maintenance",
            suggested_interval: 1,
            user_interval: null,
          },
          {
            _id: "mock_cost_line_2",
            assembly: mocks.assemblies[0],
            category: "replacement",
            suggested_interval: 1,
            user_interval: 2,
          },
          {
            _id: "mock_cost_line_3",
            assembly: mocks.assemblies[1],
            category: "maintenance",
            suggested_interval: 2,
            user_interval: null,
          },
        ];

        mocks.rsMeansCostLines = [
          {
            id: "b10135101010",
            lineNumber: "B10135101010",
            release: {
              id: "2020-an",
              href: "https://dataapi.gordian.com/v1/costdata/Releases/2020-an",
            },
            catalog: {
              id: "fmar-uni-imp-fmr-2020-an-us-us-national",
              href: "https://dataapi.gordian.com/v1/costdata/assembly/Catalogs/fmar-uni-imp-fmr-2020-an-us-us-national",
            },
            costDataFormat: "uni",
            measurementSystem: "imp",
            laborType: "fmr",
            frequency: 30,
            division: {
              id: "b1013510",
              href: "https://dataapi.gordian.com/v1/costdata/assembly/catalogs/fmar-uni-imp-fmr-2020-an-us-us-national/Divisions/b1013510",
            },
            shortDescription: "Repair exterior concrete steps",
            description: "Repair concrete stairs",
            unitOfMeasure: "S.F.",
            crew: {
              crewId: 431,
              crewCode: "CEFI",
              crewDescription: "1 Cefi",
              crewLaborHours: 0.104,
            },
            baseCosts: {
              installCost: 5.2,
              installOpCost: 7.94,
              totalInHouseCost: 23,
              materialCost: 14.85,
              materialOpCost: 18.56,
              laborCost: 5.2,
              laborOpCost: 7.94,
              totalCost: 20.05,
              totalOpCost: 26.5,
            },
            location: {
              id: "us-us-national",
              href: "https://dataapi.gordian.com/v1/costdata/Locations/us-us-national",
            },
            costFactors: {
              costFactorKey: "030",
              materialCostFactor: 1,
              equipmentCostFactor: 1,
              installationCostFactor: 1,
              totalCostFactor: 1,
            },
            localizedCosts: {
              installCost: 5.2,
              installOpCost: 7.94,
              totalInHouseCost: 23,
              materialCost: 14.85,
              materialOpCost: 18.56,
              laborCost: 5.2,
              laborOpCost: 7.94,
              equipmentCost: 0,
              equipmentOpCost: 0,
              totalCost: 20.05,
              totalOpCost: 26.5,
            },
            assemblyUnitCostLines: {
              recordCount: 1,
              href: "https://dataapi.gordian.com/v1/costdata/assembly/catalogs/fmar-uni-imp-fmr-2020-an-us-us-national/CostLines/b10135101010/Components",
            },
            href: "https://dataapi.gordian.com/v1/costdata/assembly/catalogs/fmar-uni-imp-fmr-2020-an-us-us-national/CostLines/b10135101010",
          },
        ];

        // =================
        // Cost Adjustments
        // =================

        mocks.costAdjustments = [
          {
            _id: "mock_cost_adjustment_1",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            description: "mock adjustment 1",
            master_cost_adjustment: null,
            adjustment_percent: 0.5,
          },
          {
            _id: "mock_cost_adjustment_2",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            description: "mock adjustment 2",
            master_cost_adjustment: null,
            adjustment_percent: 0.2,
          },
          {
            _id: "mock_cost_adjustment_3",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            description: "mock adjustment 3",
            master_cost_adjustment: null,
            adjustment_percent: 0.1,
          },
        ];

        // =================
        // Cost Adjustment Rules
        // =================

        mocks.costAdjustmentRules = [
          {
            _id: "mock_cost_adjustment_rule_1",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            pin_type: mocks.assetTypes[1],
            description: "mock adjustment rule 1",
            adjustment_percent: 0.5,
            adjustment_percent_display: "50%",
            cre_date: new Date(),
            cre_account: mocks.accounts[0],
            last_mod_account: mocks.accounts[0],
            last_mod_date: new Date(),
            deleted_date: null,
            deleted_account: null,
            uri: "/organizations/fake_org_id/cost_adjustment_rules/mock_cost_adjustment_rule_1",
          },
          {
            _id: "mock_cost_adjustment_rule_2",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            pin_type: mocks.assetTypes[1],
            description: "mock adjustment rule 2",
            adjustment_percent: 0.2,
            adjustment_percent_display: "20%",
            cre_date: new Date(),
            cre_account: mocks.accounts[0],
            last_mod_account: mocks.accounts[0],
            last_mod_date: new Date(),
            deleted_date: null,
            deleted_account: null,
            uri: "/organizations/fake_org_id/cost_adjustment_rules/mock_cost_adjustment_rule_2",
          },
          {
            _id: "mock_cost_adjustment_rule_3",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            pin_type: mocks.assetTypes[1],
            description: "mock adjustment rule 3",
            adjustment_percent: 0.1,
            adjustment_percent_display: "10%",
            cre_date: new Date(),
            cre_account: mocks.accounts[0],
            last_mod_account: mocks.accounts[0],
            last_mod_date: new Date(),
            deleted_date: null,
            deleted_account: null,
            uri: "/organizations/fake_org_id/cost_adjustment_rules/mock_cost_adjustment_rule_3",
          },
          {
            _id: "mock_deleted_cost_adjustment_rule",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            pin_type: mocks.assetTypes[1],
            description: "deleted mock adjustment rule",
            adjustment_percent: 0.1,
            adjustment_percent_display: "10%",
            cre_date: new Date(),
            cre_account: mocks.accounts[0],
            last_mod_account: mocks.accounts[0],
            last_mod_date: new Date(),
            deleted_date: new Date(),
            deleted_account: mocks.accounts[0],
            uri: "/organizations/fake_org_id/cost_adjustment_rules/mock_cost_adjustment_rule_4",
          },
          {
            _id: "mock_deleted_cost_adjustment_rule",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            pin_type: null,
            description: "building adjustment rule 1",
            adjustment_percent: 0.1,
            adjustment_percent_display: "10%",
            cre_date: new Date(),
            cre_account: mocks.accounts[0],
            last_mod_account: mocks.accounts[0],
            last_mod_date: new Date(),
            deleted_date: new Date(),
            deleted_account: mocks.accounts[0],
            uri: "/organizations/fake_org_id/cost_adjustment_rules/mock_cost_adjustment_rule_4",
          },
          {
            _id: "mock_deleted_cost_adjustment_rule",
            organization: "fake_org_id",
            building: mocks.buildings[0],
            pin_type: null,
            description: "building adjustment rule 2",
            adjustment_percent: 0.1,
            adjustment_percent_display: "10%",
            cre_date: new Date(),
            cre_account: mocks.accounts[0],
            last_mod_account: mocks.accounts[0],
            last_mod_date: new Date(),
            deleted_date: new Date(),
            deleted_account: mocks.accounts[0],
            uri: "/organizations/fake_org_id/cost_adjustment_rules/mock_cost_adjustment_rule_4",
          },
        ];

        // =================
        // Schedules
        // =================

        mocks.schedules = [
          {
            _id: "mock_schedule_1",
            cre_account: mocks.accounts[0],
          },
          {
            _id: "mock_schedule_2",
            cre_account: mocks.accounts[1],
          },
          {
            _id: "mock_schedule_3",
            cre_account: mocks.accounts[2],
          },
        ];

        // =================
        // Work Orders
        // =================

        mocks.workOrders = [
          {
            _id: 0,
            subject: "Fake Work Order 1",
            asset: mocks.assets[0],
            level: mocks.floors[0],
            room: mocks.rooms[0],
            round: [{ _id: "mock_round_id", asset: mocks.assets[0]._id }],
            building: mocks.buildings[0],
            start_date: new Date(0).toISOString(),
            due_date: new Date(0).toISOString(),
            cre_date: new Date(0).toISOString(),
          },
          {
            _id: 1,
            subject: "Fake Work Order 2",
            asset: mocks.assets[1],
            level: mocks.floors[1],
            room: mocks.rooms[1],
            building: mocks.buildings[1],
            start_date: new Date(0).toISOString(),
            due_date: new Date(0).toISOString(),
            cre_date: new Date(0).toISOString(),
          },
        ];

        // =================
        // Work Orders Logs
        // =================

        mocks.workOrderLogs = [
          {
            _id: 0,
            task: mocks.workOrders[0],
            actions_taken: "Put up dry wall",
            work_minutes: 237,
            work_performed_by: {
              email: "testuser@akitabox.com",
              _id: "testuser_akitabox",
            },
            time_code: "time_code_id",
            work_performed_date: "2019-04-24T16:03:44.867Z",
            cre_account: {
              _id: "testuser_akitabox",
              email: "testuser@akitabox.com",
            },
            building: mocks.buildings[0],
            cre_date: "2019-04-24T16:03:44.868Z",
            material_used: null,
            cost: 2.51,
            delete_date: null,
            delete_account: null,
          },
        ];

        // =================
        // Helper data
        // =================

        mocks.buildings[0]._data.roomTypes = [mocks.roomTypes[0]];
        mocks.buildings[1]._data.roomTypes = [mocks.roomTypes[1]];

        mocks.buildings[0]._data.assetTypes = [
          mocks.assetTypes[0],
          mocks.assetTypes[1],
        ];
        mocks.buildings[1]._data.assetTypes = [
          mocks.assetTypes[2],
          mocks.assetTypes[3],
        ];

        mocks.buildings.forEach(function (building) {
          building._data.pinTypes = building._data.roomTypes.concat(
            building._data.assetTypes
          );
        });

        mocks.buildings[0]._data.floors = [mocks.floors[0], mocks.floors[1]];
        mocks.buildings[1]._data.floors = [mocks.floors[2], mocks.floors[3]];

        mocks.roomTypes[0]._data.pins = [mocks.rooms[0], mocks.rooms[1]];
        mocks.roomTypes[1]._data.pins = [mocks.rooms[2], mocks.rooms[3]];

        mocks.assetTypes[0]._data.pins = [mocks.assets[0], mocks.assets[1]];
        mocks.assetTypes[1]._data.pins = [mocks.assets[2], mocks.assets[3]];
        mocks.assetTypes[2]._data.pins = [mocks.assets[4], mocks.assets[5]];
        mocks.assetTypes[3]._data.pins = [mocks.assets[6], mocks.assets[7]];

        mocks.buildings.forEach((building) => {
          building._data.rooms = building._data.roomTypes.reduce(
            (result, roomType) => result.concat(roomType._data.pins),
            []
          );
          building._data.assets = building._data.assetTypes.reduce(
            (result, assetType) => result.concat(assetType._data.pins),
            []
          );
          building._data.pins = building._data.rooms.concat(
            building._data.assets
          );
        });

        mocks.floors[0]._data.rooms = [mocks.rooms[0]];
        mocks.floors[1]._data.rooms = [mocks.rooms[1]];
        mocks.floors[2]._data.rooms = [mocks.rooms[2]];
        mocks.floors[3]._data.rooms = [mocks.rooms[3]];
        mocks.floors[0]._data.assets = [mocks.assets[0], mocks.assets[1]];
        mocks.floors[1]._data.assets = [mocks.assets[2], mocks.assets[3]];
        mocks.floors[2]._data.assets = [mocks.assets[4], mocks.assets[5]];
        mocks.floors[3]._data.assets = [mocks.assets[6], mocks.assets[7]];

        mocks.tree = {
          depth: 3,
          acceptable_enum_values: [
            {
              original: "Building Service Area",
              name: "building_service_area",
              child_title: "Space Category",
              children: [
                {
                  original: "Custodial Supply Closet",
                  name: "custodial_supply_closet",
                  child_title: "FICM Number",
                  children: [
                    {
                      name: "x01",
                      original: "x01",
                      child_title: "",
                    },
                  ],
                },
                {
                  original: "Janitor Room",
                  name: "janitor_room",
                  child_title: "FICM Number",
                  children: [
                    {
                      name: "x02",
                      original: "x02",
                      child_title: "",
                    },
                  ],
                },
                {
                  original: "Public Restroom",
                  name: "public_restroom",
                  child_title: "FICM Number",
                  children: [
                    {
                      original: "X03",
                      name: "x03",
                      child_title: "",
                    },
                  ],
                },
              ],
            },
            {
              original: "Circulation Area",
              name: "circulation_area",
              child_title: "Space Category",
              children: [
                {
                  original: "Classroom",
                  name: "classroom",
                  child_title: "FICM Number",
                  children: [
                    {
                      name: "w01",
                      original: "w01",
                      child_title: "",
                    },
                  ],
                },
                {
                  original: "Classroom Service",
                  name: "classroom_service",
                  child_title: "FICM Number",
                  children: [
                    {
                      name: "w02",
                      original: "w02",
                      child_title: "",
                    },
                  ],
                },
              ],
            },
          ],
        };

        mocks.account = {
          _id: "mock_account",
        };

        // =================
        // Restricted Domains
        // =================

        mocks.restrictedDomains = {
          SAML: {
            redirect: "somesamlurl.com",
          },
          ABX: null, // an ABX login returns no body
        };

        // =================
        // Service Requests
        // =================
        mocks.serviceRequests = [
          {
            _id: 0,
            title: "Fake Service Request 1",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
          },
          {
            _id: 1,
            title: "Fake Service Request 2",
            cre_date: new Date(),
            level: mocks.floors[1],
            room: mocks.rooms[1],
            building: mocks.buildings[1],
          },
          {
            _id: 2,
            title: "Fake Service Request 3",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 3,
            title: "Fake Service Request 4",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 4,
            title: "Fake Service Request 5",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 5,
            title: "Fake Service Request 6",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 6,
            title: "Fake Service Request 7",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 7,
            title: "Fake Service Request 8",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 8,
            title: "Fake Service Request 9",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
          {
            _id: 10,
            title: "Fake Service Request 10",
            cre_date: new Date(),
            level: mocks.floors[0],
            room: Object.assign({}, mocks.rooms[0]),
            building: mocks.buildings[0],
            asset: mocks.assets[0],
            issue_type: mocks.issueTypes[mocks.buildings[0]._id].filter(
              (it) => it.building === mocks.buildings[0]._id
            )[0],
          },
        ];

        // =================
        // Markups
        // =================

        mocks.markups = [
          // Empty markup
          {
            _id: "0",
            markup_json: "{}",
            building: mocks.buildings[0]._id,
            level: mocks.floors[0]._id,
            cre_account: mocks.identities[0],
          },
          // Markups belonging to another user
          {
            _id: "1",
            markup_json: "{}",
            building: mocks.buildings[0]._id,
            level: mocks.floors[0]._id,
            cre_account: mocks.identities[1],
          },
        ];

        // =================
        // Permission Groups
        // =================

        mocks.permission_groups = {
          vendor: getVendorPermissionGroup(mocks.organizations[0]),
          technician: getTechnicianPermissionGroup(mocks.organizations[0]),
          manager: getManagerPermissionGroup(mocks.organizations[0]),
          administrator: getAdministratorPermissionGroup(
            mocks.organizations[0]
          ),
        };

        // =================
        // Users
        // =================

        mocks.users = [
          {
            _id: "0",
            organization: mocks.organizations[0],
            cre_account: mocks.accounts[0],
            email: mocks.identities[0].email,
            first_name: mocks.identities[0].first_name,
            last_name: mocks.identities[0].last_name,
            buildings: [mocks.buildings[0]],
            identity: mocks.identities[0],
            permission_group: mocks.permission_groups.administrator,
          },
          {
            _id: "1",
            organization: mocks.organizations[1],
            cre_account: mocks.accounts[1],
            email: mocks.identities[1].email,
            first_name: mocks.identities[1].first_name,
            last_name: mocks.identities[1].last_name,
            buildings: [mocks.buildings[1]],
            identity: mocks.identities[1],
            permission_group: mocks.permission_groups.vendor,
          },
          {
            _id: "2",
            organization: mocks.organizations[0],
            cre_account: mocks.accounts[2],
            email: mocks.identities[2].email,
            first_name: mocks.identities[2].first_name,
            last_name: mocks.identities[2].last_name,
            buildings: [mocks.buildings[0]],
            identity: mocks.identities[2],
            permission_group: mocks.permission_groups.administrator,
          },
          {
            _id: "3",
            organization: mocks.organizations[0],
            cre_account: mocks.accounts[3],
            email: mocks.identities[3].email,
            first_name: mocks.identities[3].first_name,
            last_name: mocks.identities[3].last_name,
            buildings: [mocks.buildings[0]],
            identity: mocks.identities[3],
            permission_group: mocks.permission_groups.vendor,
          },
          {
            _id: "4",
            organization: mocks.organizations[0],
            cre_account: mocks.accounts[4],
            email: mocks.identities[4].email,
            first_name: mocks.identities[4].first_name,
            last_name: mocks.identities[4].last_name,
            buildings: [mocks.buildings[0]],
            identity: mocks.identities[4],
            permission_group: mocks.permission_groups.vendor,
          },
        ];

        // =================
        // Banner Messages
        // =================

        mocks.bannerMessages = [
          {
            _id: "0",
            message: "first banner message",
            organization: null,
            permission_group: null,
          },
          {
            _id: "1",
            message: "second banner message",
            organization: mocks.organizations[0],
            permission_group: null,
          },
          {
            _id: "2",
            message: "third banner message",
            organization: mocks.organizations[0],
            permission_group: mocks.permission_groups[0],
          },
        ];

        // =================
        // Inspections
        // =================

        mocks.inspections = [
          {
            _id: "0",
            number: 100,
            name: "Inspection Program 0",
            inspection_program: "0",
            start_date: new Date(),
            due_date: new Date(),
            compliance_code: "mock_compliance_code",
            priority: "medium",
          },
          {
            _id: "1",
            number: 101,
            name: "Inspection Program 1",
            inspection_program: "0",
            start_date: new Date(),
            due_date: new Date(),
            compliance_code: "mock_compliance_code",
            priority: "medium",
          },
        ];

        // =================
        // Checklists
        // =================

        mocks.checklists = [
          {
            _id: "0",
            revision_number: 0,
            organization: mocks.organizations[0]._id,
            inspection: mocks.inspections[0]._id,
            checklist_template: "mock_checklist_template",
            task: mocks.workOrders[0]._id,
            asset: null,
            room: null,
            level: null,
            last_mod_account: null,
            last_mod_date: null,
            deleted_account: null,
            deleted_date: null,
            completed_user: null,
            completed_date: null,
            status: "IN_PROGRESS",
            reactive_inspection_tasks: [],
            reactive_inspection_tasks_external_cmms: [],
            items: [
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "This is some header text. It describes the questions below. You can place headers anywhere in this list.",
                _id: "mock_question_0",
                type: "header",
                message: null,
                markdown: false,
                enum_options: [],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "Does the first thing pass?",
                _id: "mock_question_1",
                type: "enum_single_value_radio",
                message: null,
                markdown: false,
                enum_options: [
                  { display_value: "YES", is_passing: true },
                  { display_value: "NO", is_passing: false },
                ],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "The next two questions are enumerated questions.",
                _id: "mock_question_2",
                type: "header",
                message: null,
                markdown: false,
                enum_options: [],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "Which are colors?",
                _id: "mock_question_3",
                type: "enum_multi_value",
                message: null,
                markdown: false,
                enum_options: [
                  {
                    display_value: "Green",
                    is_passing: true,
                  },
                  {
                    display_value: "Blue",
                    is_passing: true,
                  },
                  {
                    display_value: "Dog",
                    is_passing: false,
                  },
                ],
                value: [],
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "Which is highest?",
                _id: "mock_question_4",
                type: "enum_single_value",
                message: null,
                markdown: false,
                enum_options: [
                  {
                    display_value: "100",
                    is_passing: true,
                  },
                  {
                    display_value: "7",
                    is_passing: false,
                  },
                  {
                    display_value: "1",
                    is_passing: false,
                  },
                ],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "I hereby certify that the thing was done well",
                _id: "mock_question_5",
                type: "signature",
                message: null,
                markdown: false,
                enum_options: [],
                value: null,
              },
            ],
          },
          {
            _id: "1",
            revision_number: 0,
            organization: mocks.organizations[0]._id,
            inspection: mocks.inspections[0]._id,
            checklist_template: "mock_checklist_template",
            task: mocks.workOrders[0]._id,
            asset: null,
            room: null,
            level: null,
            last_mod_account: null,
            last_mod_date: null,
            deleted_account: null,
            deleted_date: null,
            completed_user: null,
            completed_date: null,
            status: "IN_PROGRESS",
            reactive_inspection_tasks: [],
            reactive_inspection_tasks_external_cmms: [],
            items: [
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "This is some header text. It describes the questions below. You can place headers anywhere in this list.",
                _id: "mock_question_0",
                type: "header",
                message: null,
                markdown: false,
                enum_options: [],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "Does the first thing pass?",
                _id: "mock_question_1",
                type: "enum_single_value_radio",
                message: null,
                markdown: false,
                enum_options: [
                  { display_value: "YES", is_passing: true },
                  { display_value: "NO", is_passing: false },
                ],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "The next two questions are enumerated questions.",
                _id: "mock_question_2",
                type: "header",
                message: null,
                markdown: false,
                enum_options: [],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "Which are colors?",
                _id: "mock_question_3",
                type: "enum_multi_value",
                message: null,
                markdown: false,
                enum_options: [
                  {
                    display_value: "Green",
                    is_passing: true,
                  },
                  {
                    display_value: "Blue",
                    is_passing: true,
                  },
                  {
                    display_value: "Dog",
                    is_passing: false,
                  },
                ],
                value: [],
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "Which is highest?",
                _id: "mock_question_4",
                type: "enum_single_value",
                message: null,
                markdown: false,
                enum_options: [
                  {
                    display_value: "100",
                    is_passing: true,
                  },
                  {
                    display_value: "7",
                    is_passing: false,
                  },
                  {
                    display_value: "1",
                    is_passing: false,
                  },
                ],
                value: null,
              },
              {
                updatedAt: null,
                createdAt: new Date(),
                text: "I hereby certify that the thing was done well",
                _id: "mock_question_5",
                type: "signature",
                message: null,
                markdown: false,
                enum_options: [],
                value: null,
              },
            ],
          },
        ];

        // =================
        // Checklist Templates
        // =================

        mocks.checklistTemplates = [
          {
            _id: "0",
            name: "Mock Checklist Template",
            organization: mocks.organizations[0]._id,
            cre_account: "testuser_akitabox",
            deleted_account: null,
            deleted_date: null,
            cre_date: "2020-07-31T21:14:26.627Z",
            revision_number: 1,
            status: "active",
            questions: [
              {
                text: "This is a header.",
                _id: "0",
                message: null,
                markdown: false,
                enum_options: [],
                type: "header",
              },
              {
                text: "Does the thing pass?",
                _id: "0",
                message: null,
                markdown: false,
                enum_options: [
                  { display_value: "Yes", is_passing: true },
                  { display_value: "No", is_passing: false },
                ],
                type: "enum_single_value_radio",
              },
            ],
            compliance_code: "mock code",
            __v: 1,
          },
          {
            _id: "checklistTemplate1",
            name: "Mock Checklist Template (2)",
            organization: mocks.organizations[0]._id,
            cre_account: "testuser_akitabox",
            deleted_account: null,
            deleted_date: null,
            cre_date: "2020-07-30T21:14:26.627Z",
            revision_number: 1,
            status: "active",
            questions: [
              {
                text: "This is a header.",
                _id: "0",
                message: null,
                markdown: false,
                enum_options: [],
                type: "header",
              },
              {
                text: "Does the thing pass?",
                _id: "0",
                message: null,
                markdown: false,
                enum_options: [
                  { display_value: "Yes", is_passing: true },
                  { display_value: "No", is_passing: false },
                ],
                type: "enum_single_value_radio",
              },
            ],
            compliance_code: "mock code",
            __v: 1,
          },
        ];

        // =================
        // Helpers
        // =================
        /**
         * Create a changes-like object for testing purposes.
         * @param {String} binding - The binding that "triggered" this $onChange
         * @param {*} previousValue - The previous value for the binding
         * @param {*} currentValue - The new value for the binding
         * @param {Boolean} [isFirst=false] - Return value for isFirstChange
         * @returns A changes-like object for the change described by the supplied args
         */
        mocks.createChanges = (
          binding,
          previousValue,
          currentValue,
          isFirst
        ) => {
          if (isFirst === undefined) {
            isFirst = false;
          }
          let changes = {};
          changes[binding] = {
            previousValue,
            currentValue,
            isFirstChange: () => isFirst,
          };
          return changes;
        };

        /**
         * Invoke changes for a binding on a controller (using its `$onChanges`
         * lifecycle hook).
         *
         * @param {Object} ctrl - Controller to invoke changes for
         * @param {String} binding - The binding to trigger the change for
         * @param {*} currentValue - The new value for the binding
         * @param {*} [previousValue=ctrl[binding]] - The previous value for the binding. Defaults to the initial value on the given controller
         * @param {Boolean} [isFirst=false] - Return value for isFirstChange
         */
        mocks.invokeChanges = (
          ctrl,
          binding,
          currentValue,
          previousValue,
          isFirst = false
        ) => {
          previousValue = previousValue || ctrl[binding];

          ctrl[binding] = currentValue;

          if (isFirst) {
            // On first change, Angular sets `previousValue` to an
            // `UNINITIALIZED_VALUE` empty object
            previousValue = {};
          }

          const changes = {
            [binding]: {
              previousValue,
              currentValue,
              isFirstChange: () => isFirst,
            },
          };
          ctrl.$onChanges(changes);
        };

        /**
         * Generate an arbitrary string of the given length
         * @param {number} length - Length of string to generate
         */
        mocks.generateString = (length) => "a".repeat(length);

        function getVendorPermissionGroup(organization) {
          return {
            name: "Vendor",
            organization,
            is_administrator: false,
            assembly: {
              read: true,
              record_condition: true,
              create: false,
              remove: false,
              update: false,
              update_severity_of_failure: false,
              rpam: false,
              add_attachment: true,
            },
            asset: {
              read: true,
              create: false,
              remove: false,
              update: false,
              update_room: false,
              update_level: false,
              set_location: false,
              decommission: false,
              recommission: true,
              add_attachment: true,
            },
            checklist: {
              answer_own: true,
              answer_any: false,
              complete_own: true,
              complete_any: false,
              reopen_own: true,
              reopen_any: false,
              read: true,
              create: false,
              remove: false,
              update: false,
              skip_own: true,
              skip_any: false,
              add_attachment: true,
            },
            checklist_template: {
              create: false,
              read: true,
              update: false,
              activate: false,
              deactivate: false,
              remove: false,
            },
            comment: {
              read: true,
              create: true,
              update: true,
              remove_own_internal: true,
              create_public: false,
              remove_any_internal: false,
            },
            condition: {
              read: true,
              create: true,
              remove: false,
              update: false,
              rpam: false,
              add_attachment: true,
            },
            condition_assessment: {
              read_completed: false,
              read_in_progress: false,
              create: false,
              remove: false,
              update: false,
              update_status: false,
              add_attachment: true,
            },
            cost_adjustment: {
              create: false,
              read: true,
              remove: false,
              update: false,
            },
            cost_line: {
              read: true,
              create: false,
              remove: false,
              update: false,
              complete: false,
              rpam: false,
            },
            cost_line_task: {
              read: false,
              create: false,
              remove: false,
              rpam: false,
              update: false,
              complete: false,
            },
            document: {
              read: true,
              create: false,
              remove: false,
              rename: false,
              revert: false,
              rotate: false,
              archive: false,
              unarchive: false,
            },
            future_task: {
              read: true,
              create: false,
              remove: false,
              update: false,
              cancel: false,
              reopen: false,
            },
            inspection: {
              create: false,
              read: false,
              update: false,
              remove: false,
            },
            inspection_program: {
              update: false,
              remove: false,
              create: false,
              read: false,
              activate: false,
              deactivate: false,
            },
            markup: {
              read: true,
              create: true,
              update: true,
              remove_own: true,
              remove_any: false,
            },
            qrcode: {
              read: true,
              associate: false,
              disassociate: false,
            },
            request: {
              read: true,
              create: false,
              remove: false,
              update: false,
              deny: false,
              reopen: false,
              add_attachment: true,
            },
            room: {
              read: true,
              create: false,
              remove: false,
              update: false,
              update_level: false,
              set_location: false,
              decommission: false,
              recommission: true,
              add_attachment: true,
            },
            tag: {
              read: true,
              create: false,
              remove: false,
            },
            task: {
              read_own: true,
              read_all: false,
              create: false,
              remove: false,
              update: false,
              assign: false,
              cancel: false,
              reopen: false,
              complete_own: true,
              complete_any: false,
              add_attachment: true,
            },
            work_order_log: {
              read: true,
              create_own_log_on_own_task: true,
              create_own_log_on_any_task: false,
              create_any: false,
              update_own_log_on_own_task: true,
              update_own_log_on_any_task: false,
              update_any: false,
              remove_own_log_on_own_task: true,
              remove_own_log_on_any_task: false,
              remove_any: false,
            },
          };
        }

        function getTechnicianPermissionGroup(organization) {
          const permissions = getVendorPermissionGroup(organization);

          permissions.name = "Technician";

          permissions.asset.create = true;
          permissions.asset.update = true;
          permissions.asset.update_room = true;
          permissions.asset.update_level = true;
          permissions.asset.set_location = true;

          permissions.checklist.update = true;
          permissions.checklist.skip = true;

          permissions.comment.create_public = true;

          permissions.condition.create = true;

          permissions.document.create = true;
          permissions.document.rename = true;
          permissions.document.revert = true;
          permissions.document.rotate = true;
          permissions.document.archive = true;
          permissions.document.unarchive = true;

          permissions.inspection.create = true;

          permissions.qrcode.associate = true;
          permissions.qrcode.disassociate = true;

          permissions.request.create = true;

          permissions.room.create = true;
          permissions.room.update = true;
          permissions.room.update_level = true;
          permissions.room.set_location = true;

          permissions.tag.create = true;
          permissions.tag.remove = true;

          permissions.task.read_all = true;
          permissions.task.create = true;
          permissions.task.update = true;
          permissions.task.assign = true;
          permissions.task.cancel = true;
          permissions.task.reopen = true;
          permissions.task.complete_any = true;

          permissions.work_order_log.create_own_log_on_any_task = true;
          permissions.work_order_log.update_own_log_on_any_task = true;
          permissions.work_order_log.remove_own_log_on_any_task = true;

          return permissions;
        }

        function getManagerPermissionGroup(organization) {
          const permissions = getTechnicianPermissionGroup(organization);

          permissions.name = "Manager";

          permissions.assembly.record_condition = true;
          permissions.assembly.create = true;
          permissions.assembly.remove = true;
          permissions.assembly.update = true;
          permissions.assembly.rpam = true;

          permissions.asset.remove = true;
          permissions.asset.decommission = true;
          permissions.asset.recommission = true;

          permissions.checklist.answer_any = true;
          permissions.checklist.complete_any = true;
          permissions.checklist.reopen_any = true;
          permissions.checklist.skip_any = true;

          permissions.checklist_template.create = true;
          permissions.checklist_template.update = true;
          permissions.checklist_template.activate = true;
          permissions.checklist_template.deactivate = true;
          permissions.checklist_template.remove = true;
          permissions.checklist_template.read = true;

          permissions.comment.remove_any_internal = true;

          permissions.condition.remove = true;
          permissions.condition.rpam = true;

          permissions.cost_adjustment.create = true;
          permissions.cost_adjustment.remove = true;
          permissions.cost_adjustment.update = true;

          permissions.cost_line.create = true;
          permissions.cost_line.remove = true;
          permissions.cost_line.update = true;
          permissions.cost_line.complete = true;
          permissions.cost_line.rpam = true;

          permissions.cost_line_task.create = true;
          permissions.cost_line_task.complete = true;
          permissions.cost_line_task.update = true;
          permissions.cost_line_task.remove = true;
          permissions.cost_line_task.rpam = true;

          permissions.document.remove = true;

          permissions.future_task.remove = true;
          permissions.future_task.create = true;
          permissions.future_task.update = true;
          permissions.future_task.cancel = true;
          permissions.future_task.reopen = true;

          permissions.inspection_program.read = true;
          permissions.inspection_program.create = true;
          permissions.inspection_program.update = true;
          permissions.inspection_program.remove = true;
          permissions.inspection_program.activate = true;
          permissions.inspection_program.deactivate = true;

          permissions.request.remove = true;
          permissions.request.update = true;
          permissions.request.deny = true;
          permissions.request.reopen = true;

          permissions.room.remove = true;
          permissions.room.decommission = true;
          permissions.room.recommission = true;

          permissions.task.remove = true;

          permissions.work_order_log.create_any = true;
          permissions.work_order_log.update_any = true;
          permissions.work_order_log.remove_any = true;

          return permissions;
        }

        function getAdministratorPermissionGroup(organization) {
          const permissions = getManagerPermissionGroup(organization);

          permissions.name = "Administrator";
          permissions.is_administrator = true;

          return permissions;
        }
      };
    });
})();
