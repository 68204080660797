(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.history
   */
  angular
    .module("akitabox.core.services.history", ["ui.router"])
    .factory("HistoryService", HistoryService);

  /**
   * @ngdoc factory
   * @module akitabox.core.services.history
   * @name HistoryService
   *
   * @description
   * This service manages application state history
   */
  function HistoryService($state, $window) {
    var service = {
      clear: clear,
      back: back,
      isEmpty: isEmpty,
      push: push,
      replace: replace,
    };

    var history = [];
    var previous = null;

    return service;

    /**
     * Clear history
     */
    function clear() {
      history = [];
    }

    /**
     * Navigate to the previous state
     */
    function back(state, params) {
      if (state) {
        previous = $state.current;
        $state.go(state, params);
      } else if (service.isEmpty()) {
        $window.history.back();
      } else {
        previous = $state.current;
        var item = history.pop();
        $state.go(item.state.name, item.params);
      }
    }

    /**
     * Check if the history is empty
     *
     * @return {Boolean} True if empty, false if not
     */
    function isEmpty() {
      return history.length === 0;
    }

    /**
     * Push a state onto the history stack
     *
     * @param  {Object} state  State information
     * @param  {Object} params State params
     */
    function push(state, params) {
      // Push state only if it is a valid state and not abstract
      if (
        state &&
        !state.abstract &&
        Object.prototype.hasOwnProperty.call(state, "name")
      ) {
        // Don't push state if we just came from there (via back navigation)
        // or if the state does not wish to be added to the history stack (data.pushHistory)
        if (
          (previous && state.name === previous.name) ||
          (state.data && state.data.pushHistory === false)
        ) {
          previous = null;
          return;
        }
        history.push({ state: state, params: params });
      }
    }

    function replace(state, title) {
      $window.history.replaceState(state, title);
    }
  }
})();
