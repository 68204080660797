(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller("FloorOverviewTabController", FloorOverviewTabController);

  /* @ngInject */
  function FloorOverviewTabController(
    // Angular
    $scope,
    // Akitabox
    models,
    // Constants
    WORK_ORDER_STATUS_OPEN,
    // Dialogs
    CreateWorkOrderDialog,
    CreateScheduleDialog,
    DeleteFloorDialog,
    SelectDocumentDialog,
    // Resolves
    building,
    floor,
    organization,
    Router,
    // Services
    FloorService,
    NoteService,
    TagCategoryService,
    ToastService,
    UserService,
    WorkOrderService,
    WorkOrdersBFFService
  ) {
    var self = this;

    var OVERDUE_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      due_date: "$lte," + Date.now(),
      level: floor._id,
    };
    var OPEN_QUERY = {
      status: WORK_ORDER_STATUS_OPEN,
      start_date: "$lte," + Date.now(),
      due_date: "$gte," + Date.now(),
      level: floor._id,
    };

    var userPermissions = UserService.getPermissions();

    // Attributes
    self.building = building;
    self.organization = organization;
    self.floor = floor;
    self.allOverdueWorkOrdersState = {
      state: "app.workOrders",
      params: OVERDUE_QUERY,
    };
    self.allOpenWorkOrdersState = {
      state: "app.workOrders",
      params: OPEN_QUERY,
    };
    self.overdueWorkOrders = [];
    self.openWorkOrders = [];
    self.overdueWorkOrdersCount = 0;
    self.openWorkOrdersCount = 0;
    self.activityParams = {
      level: floor._id,
    };
    self.limit = 5;
    self.lastActivityItem = {
      activityType: "created-entity",
      buildingUri: building.uri,
      account: floor.cre_account,
      entity: "Floor",
      time: floor.cre_date,
    };
    self.permissions = getDefaultPermissions();

    // Functions
    self.createNote = createNote;
    self.createWorkOrder = createWorkOrder;
    self.createSchedule = createSchedule;
    self.deleteFloor = deleteFloor;
    self.showSelectDocumentDialog = showSelectDocumentDialog;
    self.getOverdueWorkOrdersLimit = getOverdueWorkOrdersLimit;
    self.getOpenWorkOrdersLimit = getOpenWorkOrdersLimit;

    init();

    function init() {
      if (organization.show_tasks) {
        // Populate lists
        getOverdueWorkOrdersLimit();
        getOpenWorkOrdersLimit();

        // Get total work order list counts
        getOverdueWorkOrdersCount();
        getOpenWorkOrdersCount();
      }
    }

    function getDefaultPermissions() {
      return {
        canEditFloorPlan: userPermissions.is_administrator,
        canCreateWorkOrder:
          userPermissions.task.create && organization.show_tasks,
        canCreateSchedule:
          userPermissions.future_task.create && organization.show_tasks,
        canDelete: userPermissions.is_administrator,
      };
    }

    /**
     * Shows the select document dialog. Called when the Floor Plan section's "Edit" button is clicked.
     */
    function showSelectDocumentDialog() {
      TagCategoryService.getAll(building._id)
        .then(function (tagCategories) {
          var locals = {
            title: "Edit Floor Plan",
            building: building,
            tagCategories: tagCategories,
            followLink: false,
          };
          return SelectDocumentDialog.show({ locals: locals });
        })
        .then(function (document) {
          self.floor = null;
          return FloorService.updateDocument(
            building._id,
            floor._id,
            document._id
          );
        })
        .then(function (updatedFloor) {
          self.floor = angular.extend(floor, updatedFloor);
        })
        .catch((error) => {
          ToastService.showError(error);
          self.floor = floor; //put back original floor
        });
    }

    function createNote(noteText) {
      var data = {
        level: floor._id,
        building: building._id,
        text: noteText,
      };

      NoteService.create(building._id, data)
        .then(function () {
          refreshActivity();
        })
        .catch(ToastService.showError);
    }

    function createWorkOrder() {
      var locals = {
        building: building,
        floors: [floor],
      };
      CreateWorkOrderDialog.show({ locals: locals })
        .then(function (workOrders) {
          if (workOrders[0].level._id === floor._id) {
            self.openWorkOrders.unshift(workOrders[0]);
          }
        })
        .catch(ToastService.showError);
    }

    function createSchedule() {
      var locals = {
        building: self.building,
        floors: [self.floor],
      };
      CreateScheduleDialog.show({ locals: locals })
        .then(function () {
          return getOpenWorkOrdersLimit();
        })
        .catch(ToastService.showError);
    }

    function deleteFloor() {
      var locals = { building: building, floorIDs: [floor._id] };
      DeleteFloorDialog.show({ locals: locals })
        .then(function () {
          Router.go("app.floors");
        })
        .catch(ToastService.showError);
    }

    function refreshActivity() {
      $scope.$broadcast("activity:refresh");
    }

    function getOverdueWorkOrdersLimit() {
      var query = angular.extend({}, OVERDUE_QUERY, { limit: 5 });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        building._id,
        query
      )
        .then(function (overdueWorkOrders) {
          self.overdueWorkOrders = overdueWorkOrders;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrdersLimit() {
      var query = angular.extend({}, OPEN_QUERY, { limit: 5 });
      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        building._id,
        query
      )
        .then(function (openWorkOrders) {
          self.openWorkOrders = openWorkOrders;
        })
        .catch(ToastService.showError);
    }

    function getOverdueWorkOrdersCount() {
      var query = angular.extend({}, OVERDUE_QUERY, { count: true });
      WorkOrderService.getByBuildingId(building._id, query)
        .then(function (response) {
          self.overdueWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }

    function getOpenWorkOrdersCount() {
      var query = angular.extend({}, OPEN_QUERY, { count: true });
      WorkOrderService.getByBuildingId(building._id, query)
        .then(function (response) {
          self.openWorkOrdersCount = response.count;
        })
        .catch(ToastService.showError);
    }
  }
})();
