import {
  Box,
  Button,
  Checkbox,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Color } from "../colors";
import { stylesheet } from "../stylesheet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledEditor } from "@ckeditor/ckeditor5-editor-decoupled";
import { EditorConfig, icons } from "@ckeditor/ckeditor5-core";
import {
  Bold,
  Italic,
  Strikethrough,
  Superscript,
  Underline,
} from "@ckeditor/ckeditor5-basic-styles";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Autosave } from "@ckeditor/ckeditor5-autosave";
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageUtils,
} from "@ckeditor/ckeditor5-image";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import {
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
} from "@ckeditor/ckeditor5-font";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Highlight } from "@ckeditor/ckeditor5-highlight";
import { HorizontalLine } from "@ckeditor/ckeditor5-horizontal-line";
import { Link } from "@ckeditor/ckeditor5-link";
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from "@ckeditor/ckeditor5-special-characters";
import { getCKEditorKey } from "../../utils/getCKEditorKey";
import { sanitizeXSS } from "../../utils/xss";
import { api } from "../api";
import { encodeText } from "../../utils/encodeText";
import { AbxUploadAdapterPlugin } from "../report-builder/plugins/AbxUploadAdapter";
import {
  DefaultApiReportHeadersAndFootersUpdateRequest,
  OrganizationResponse,
  ReportHeaderFooterResponse,
} from "@akitabox/api-client";
import { decodeText } from "../../utils/decodeText";
import { PageMargins } from "../report-margins-dialog/ReportMarginsDialog";
import { useRouteLoaderData } from "react-router";
import { Mention } from "@ckeditor/ckeditor5-mention";
import { MentionCustomization } from "../ck-editor/plugins/MentionCustomization.plugin";
import { customMentionItemRenderer } from "../report-template/ReportTemplateEditor";
import { SlashCommand } from "@ckeditor/ckeditor5-slash-command";
import "./style.css";
import {
  buildingFeedItems,
  buildingRPIFCIFeedItems,
} from "../ck-editor/plugins/abxMentionFeedItems";

const CK_EDITOR_LICENSE_KEY = getCKEditorKey();

const STATUS_MESSAGES = {
  LOADING: "Loading...",
  DONE_LOADING: "Done loading.",
  SAVING: "Saving...",
  NO_CHANGES: "No changes to save.",
  DONE_SAVING: "Changes saved!",
};

export interface ReportFooterDialogProps extends DialogProps {
  organizationId: string;
  reportId?: string;
  reportTemplateId?: string;
  pageMargins?: PageMargins;
  reportContext: string;
}

export const ReportFooterDialog: FC<ReportFooterDialogProps> = ({
  pageMargins,
  organizationId,
  reportId,
  reportTemplateId,
  reportContext,
  ...props
}) => {
  const { organization } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
  };

  //  dialog state
  const [saving, setSaving] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [reportFooter, setReportFooter] =
    useState<ReportHeaderFooterResponse | null>(null);

  // mentions
  const getFeedItems = useCallback(
    async (queryText: string) => {
      if (reportTemplateId && reportContext === "building") {
        const items = [...buildingFeedItems];

        if (organization.show_rpi_fci) {
          items.push(...buildingRPIFCIFeedItems);
        }

        return items.filter((item) => {
          return item.id.toLowerCase().includes(queryText.toLowerCase());
        });
      }

      return [];
    },
    [organization.show_rpi_fci, reportContext, reportTemplateId]
  );

  const updateFooter = debounce(
    async (
      payload: DefaultApiReportHeadersAndFootersUpdateRequest["payload"]
    ) => {
      if (!reportFooter || Object.keys(payload).length === 0) {
        return;
      }

      const { data } = await api.reportHeadersAndFooters.update({
        organizationId,
        reportHeaderFooterId: reportFooter._id,
        payload,
      });

      setReportFooter(data);
    },
    500
  );

  // ck-editor state
  // this is the content in the editor that was last successfully saved to the server
  const savedContent = useRef<string | null>(null);
  const [ckEditorToolbar, setCkEditorToolbar] = useState<Element | null>(null);
  const [ckEditor, setCkEditor] = useState<DecoupledEditor | null>(null);
  const [ckEditorConfig] = useState<EditorConfig>({
    plugins: [
      AbxUploadAdapterPlugin,
      Alignment,
      Autoformat,
      AutoImage,
      Autosave,
      Bold,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      Highlight,
      HorizontalLine,
      Image,
      ImageCaption,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      ImageUtils,
      Italic,
      Link,
      Mention,
      MentionCustomization,
      SlashCommand,
      SpecialCharacters,
      SpecialCharactersEssentials,
      Strikethrough,
      Superscript,
      Underline,
    ],
    toolbar: [
      "|",
      "undo",
      "redo",
      "|",
      "heading",
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "underline",
      "fontColor",
      "fontBackgroundColor",
      {
        icon: icons.pencil,
        label: "Highlight",
        items: [
          "highlight:yellowMarker",
          "highlight:greenMarker",
          "highlight:pinkMarker",
          "highlight:greenPen",
          "highlight:redPen",
          "removeHighlight",
        ],
      },
      {
        label: "More Formats",
        items: ["strikethrough", "superscript", "subscript"],
      },
      "horizontalLine",
      "|",
      "uploadImage",
      "|",
      "alignment",
    ],
    licenseKey: CK_EDITOR_LICENSE_KEY,
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: ["default", 8, 9, 10, 10.5, 11, 12, 14, 18, 24, 30, 36],
    },
    link: {
      addTargetToExternalLinks: true,
    },
    autosave: {
      waitingTime: 1000,
      save: async (editor) => {
        setSaving(true);
        setStatusMessage(STATUS_MESSAGES.SAVING);
        const currentData = editor.getData();

        try {
          // retain the content since it was last saved via the api
          // we cannot access any state variables in the autosave function
          let fetchedReportfooter: ReportHeaderFooterResponse | null = null;
          const { data: footerReponse } =
            await api.reportHeadersAndFooters.getByOrganization({
              organizationId,
              ...(reportId ? { report: reportId } : {}),
              ...(reportTemplateId ? { reportTemplate: reportTemplateId } : {}),
              type: "footer",
            });
          if (footerReponse && footerReponse.length === 1) {
            // there can be only one footer per report, since we don't have the id, we use the list api route
            // so now we need to store it as the current footer
            fetchedReportfooter = footerReponse[0];
          }

          if (fetchedReportfooter) {
            const { data: contentResponse } =
              await api.reportHeadersAndFooters.uploadContent({
                organizationId,
                reportHeaderFooterId: fetchedReportfooter._id,
                postBody: {
                  content: encodeText(sanitizeXSS(currentData)),
                },
              });
            const decodedContent = decodeText(contentResponse.content);
            savedContent.current = decodedContent;
            setStatusMessage(STATUS_MESSAGES.DONE_SAVING);
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            setError(
              new Error(err.response?.data?.error?.message || err.message)
            );
          } else if (err instanceof Error) {
            setError(err);
          }
        }

        setSaving(false);
      },
    },
    image: {
      insert: {
        type: "auto",
      },
      toolbar: [
        "toggleImageCaption",
        "|",
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "resizeImage:original",
      ],
    },
    table: {
      contentToolbar: [
        "toggleTableCaption",
        "tableRow",
        "tableColumn",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    abxUpload: {
      organizationId,
    },
    mention: {
      feeds: [
        {
          marker: "#",
          feed: getFeedItems,
          itemRenderer: customMentionItemRenderer,
        },
      ],
    },
  });

  // useeffects
  useEffect(() => {
    async function init() {
      setLoading(true);
      setStatusMessage(STATUS_MESSAGES.LOADING);

      // fetch the footer if it exists
      let fetchedReportfooter: ReportHeaderFooterResponse | null = null;
      const { data: footerReponse } =
        await api.reportHeadersAndFooters.getByOrganization({
          organizationId,
          ...(reportId ? { report: reportId } : {}),
          ...(reportTemplateId ? { reportTemplate: reportTemplateId } : {}),
          type: "footer",
        });
      if (footerReponse && footerReponse.length === 1) {
        // there can be only one footer per report, since we don't have the id, we use the list api route
        // so now we need to store it as the current footer
        fetchedReportfooter = footerReponse[0];
      } else {
        // there is no footer for this report, create it
        try {
          const createResponse = await api.reportHeadersAndFooters.create({
            organizationId,
            postBody: {
              type: "footer",
              ...(reportId ? { report: reportId } : {}),
              ...(reportTemplateId ? { reportTemplate: reportTemplateId } : {}),
            },
          });
          if (createResponse.data) {
            fetchedReportfooter = createResponse.data;
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            setError(
              new Error(err.response?.data?.error?.message || err.message)
            );
          } else if (err instanceof Error) {
            setError(err);
          }
        }
      }

      if (fetchedReportfooter) {
        setReportFooter(fetchedReportfooter);
        // try and fech the content of the footer
        const { data: contentResponse } =
          await api.reportHeadersAndFooters.getContent({
            organizationId,
            reportHeaderFooterId: fetchedReportfooter._id,
          });
        if (contentResponse && ckEditor) {
          const decodedContent = decodeText(contentResponse.content || "");
          savedContent.current = decodedContent;
          ckEditor.setData(decodedContent);
        }
      } else if (ckEditor) {
        savedContent.current = "";
        ckEditor.setData("");
      }
      setLoading(false);
      setStatusMessage(STATUS_MESSAGES.DONE_LOADING);
    }

    init();
  }, [ckEditor, organizationId, reportId, reportTemplateId]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (
      statusMessage.length &&
      (statusMessage === STATUS_MESSAGES.DONE_SAVING ||
        statusMessage === STATUS_MESSAGES.NO_CHANGES ||
        statusMessage === STATUS_MESSAGES.DONE_SAVING)
    ) {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        setStatusMessage("");
      }, 3000);
      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [statusMessage]);

  return (
    <Dialog maxWidth="xl" {...props}>
      <DialogTitle component={Box} css={ss.dialogTitle}>
        <Typography variant="h6" color="inherit">
          Footer Builder
        </Typography>
        <IconButton
          disabled={loading || saving}
          color="inherit"
          onClick={() => {
            props.onClose?.(
              {
                content: savedContent.current,
                showPageNumbers: reportFooter?.show_page_numbers || false,
              },
              "escapeKeyDown"
            );
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {ckEditorToolbar && (
          <div
            ref={(ref) => {
              // we need to clear the previous toolbar before appending it again each time the dialog opens
              if (!ref) return;
              if (ref.hasChildNodes()) {
                for (let i = 0; i < ref.childNodes.length; i++) {
                  const node = ref.childNodes[i];
                  node.remove();
                }
              }
              ref.appendChild(ckEditorToolbar);
            }}
          ></div>
        )}
        <Box
          css={(theme) => ({
            //The height of the editing area can be easily controlled with CSS.
            ".ck-editor__editable_inline:not(.ck-comment__input *)": {
              margin: "0 auto",
              padding: pageMargins
                ? `${pageMargins.top}${pageMargins.unit} ${pageMargins.right}${pageMargins.unit} ${pageMargins.bottom}${pageMargins.unit} ${pageMargins.left}${pageMargins.unit}`
                : 0,
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(3),
              height: "400px",
              minHeight: "400px",
              maxHeight: "400px",
              width: "216mm",
              overflow: "hidden",
              boxSizing: "border-box",
            },
          })}
        >
          <CKEditor<DecoupledEditor>
            editor={DecoupledEditor}
            disabled={loading}
            config={ckEditorConfig}
            onReady={async (editor) => {
              setCkEditorToolbar(editor.ui.view.toolbar.element);
              setCkEditor(editor);
            }}
          />
        </Box>
        <Typography id="editor-status" variant="caption">
          <strong>Status:</strong> {statusMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        {error && <Typography color="error">{error.message}</Typography>}
        <FormControlLabel
          control={<Checkbox checked={reportFooter?.show_page_numbers} />}
          disabled={loading || !reportFooter}
          label="Show Page Numbers"
          onChange={(_, checked) =>
            updateFooter({ show_page_numbers: checked })
          }
        />
        <Box>
          <Button
            onClick={() => {
              props.onClose?.(
                {
                  content: savedContent.current,
                  showPageNumbers: reportFooter?.show_page_numbers || false,
                },
                "escapeKeyDown"
              );
            }}
            disabled={loading || saving}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ss = stylesheet({
  dialogTitle: {
    backgroundColor: Color.White,
    color: Color.Black,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
