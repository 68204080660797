(function () {
  angular
    .module("akitabox.core.services.map", [
      "uiGmapgoogle-maps",
      "akitabox.core.utils",
    ])
    .factory("MapService", MapService);

  /** @ngInject */
  function MapService($log, $q, uiGmapGoogleMapApi, Utils) {
    var service = {
      getPlacePredictions: getPlacePredictions,
      getPlaceDetails: getPlaceDetails,
      formatDescription: formatDescription,
    };

    init();

    return service;

    function init() {
      uiGmapGoogleMapApi
        .then(function (maps) {
          if (!maps) {
            throw new Error("Google Maps library failed to load");
          } else if (!maps.places) {
            throw new Error(
              "Google Maps library does not have the Places module"
            );
          }
          service.$maps = maps;
          service.$autocomplete = new maps.places.AutocompleteService();
          var div = document.createElement("div");
          var map = new maps.Map(div);
          service.$places = new maps.places.PlacesService(map);
          Utils.removeElement(div);
        })
        .catch(function (error) {
          $log.error(error);
        });
    }

    /**
     * Provides Autocomplete prediction results via Google's Places API. Allows for filtering them on type.
     * Types should pull from: https://developers.google.com/places/supported_types#table3
     *
     * @param query
     * @param [type]
     * @returns {*}
     */
    function getPlacePredictions(query, type) {
      return $q(function (resolve, reject) {
        if (angular.isDefined(service.$autocomplete)) {
          var request = {
            input: query,
          };

          service.$autocomplete.getPlacePredictions(
            request,
            function (results, status) {
              if (status === service.$maps.places.PlacesServiceStatus.OK) {
                var pushResults = [];
                for (var i = 0; i < results.length; i++) {
                  for (var j = 0; j < results[i].types.length; j++) {
                    if (type.indexOf(results[i].types[j]) !== -1) {
                      pushResults.push(results[i]);
                    }
                  }
                }
                resolve(pushResults);
              } else {
                reject(status);
              }
            }
          );
        } else {
          reject("AutocompleteService is not available");
        }
      });
    }

    function getPlaceDetails(id, description) {
      return $q(function (resolve, reject) {
        if (angular.isDefined(service.$places)) {
          service.$places.getDetails(
            { placeId: id },
            function (results, status) {
              if (status === service.$maps.places.PlacesServiceStatus.OK) {
                resolve(parsePlaceDetails(results, description));
              } else {
                reject("Unable to obtain location information");
              }
            }
          );
        } else {
          reject("PlacesService is not available");
        }
      });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function parsePlaceDetails(details, description) {
      var result = {};
      result.lat = details.geometry.location.lat();
      result.lng = details.geometry.location.lng();
      for (var i = 0; i < details.address_components.length; ++i) {
        var component = details.address_components[i];
        for (var j = 0; j < component.types.length; ++j) {
          switch (component.types[j]) {
            case "street_number":
              result.street_number = component.short_name;
              break;
            case "route":
              result.route = component.long_name;
              break;
            case "locality":
            case "sublocality":
              result.city = component.long_name;
              break;
            case "administrative_area_level_1":
              result.state = component.short_name;
              break;
            case "postal_code":
              result.postal_code = component.short_name;
              break;
            case "country":
              result.country = component.long_name;
              break;
            default:
              break;
          }
        }
      }

      var address = "";
      if (angular.isDefined(result.route)) {
        if (angular.isDefined(result.street_number)) {
          address = result.street_number + " " + result.route;
        } else {
          address = result.route;
        }
      } else {
        address = null;
      }

      result.address = address;

      // We don't need these components after merging them into the result.address
      delete result.street_number;
      delete result.route;

      if (description) {
        result = angular.extend(result, { description: description });
      } else {
        result = angular.extend(result, {
          description: formatDescription(result),
        });
      }

      return result;
    }

    function formatDescription(addressObj) {
      var placeArray = [];
      if (addressObj.address) {
        placeArray.push(addressObj.address);
      }
      placeArray.push(addressObj.city);
      var region = addressObj.state;
      if (addressObj.postal_code) {
        region += " " + addressObj.postal_code;
      }
      placeArray.push(region);
      placeArray.push(addressObj.country);

      // return the formatted address string
      return placeArray.join(", ");
    }
  }
})();
