(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller("ScheduleDetailController", ScheduleDetailController);

  /* @ngInject */
  function ScheduleDetailController(
    // Angular
    $scope,
    // Third-party
    $stateParams,
    // Resolve
    building,
    schedule
  ) {
    var self = this;

    // Attributes
    self.schedule = schedule;
    self.building = building;
    self.subtitle = {
      name: self.schedule.status_display + " Maintenance Schedules",
      state: { name: "app.schedules", params: $stateParams },
    };
    self.tabs = getTabs();

    // Run
    init();

    function init() {
      $scope.$watchCollection("vm.schedule", function () {
        self.tabs = getTabs();
      });
    }

    function getTabs() {
      var overview = {
        title: "Overview",
        state: "app.schedule.overview",
      };
      var details = {
        title: "Details",
        state: "app.schedule.details",
      };
      var floor = { title: "Floor", state: "app.schedule.floor" };
      var room = { title: "Room", state: "app.schedule.room" };
      var asset = { title: "Asset", state: "app.schedule.asset" };
      var openWorkOrders = {
        title: "Open Work Orders",
        state: "app.schedule.openWorkOrders",
      };
      var closedWorkOrders = {
        title: "Closed Work Orders",
        state: "app.schedule.closedWorkOrders",
      };

      var tabsToReturn = [overview, details];
      if (schedule.level) tabsToReturn.push(floor);
      if (schedule.room) tabsToReturn.push(room);
      if (schedule.asset) tabsToReturn.push(asset);
      tabsToReturn.push(openWorkOrders, closedWorkOrders);

      return tabsToReturn;
    }
  }
})();
