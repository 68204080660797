(function () {
  angular.module("akitabox.core.lib", [
    "akitabox.core.lib.appcues",
    "akitabox.core.lib.exif",
    "akitabox.core.lib.leaflet",
    "akitabox.core.lib.moment",
    "akitabox.core.lib.markdown",
    "akitabox.core.lib.d3",
    "akitabox.core.lib.paper",
    "akitabox.core.lib.pluralize",
    "akitabox.core.lib.vrview",
    "akitabox.core.lib.zendesk",
  ]);
})();
