(function () {
  angular
    .module("akitabox.ui.directives.accountAutocomplete")
    .controller(
      "AbxAccountAutocompleteController",
      AbxAccountAutocompleteController
    );

  /**
   * @ngdoc controller
   * @module akitabox.ui.directives.accountAutocomplete
   * @name AbxAccountAutocompleteController
   * @restrict E
   *
   * @description
   * A controller intended to be used with the abx-account-autocomplete-directive.
   *
   * @ngInject
   */
  function AbxAccountAutocompleteController(IdentityService, ToastService) {
    var self = this;

    // Attributes
    self.label = self.label || "Account";
    self.query = null;
    self.disabled = false;
    self.required = false;

    // Functions
    self.querySearch = querySearch;
    self.selectedItemChange = selectedItemChange;

    /**
     * Search for accounts based on query. If query is
     * in process, that same request will be returned
     * until it is resolved.
     *
     * @param  {String} query   Search text
     * @return {Array}          List of accounts
     */
    function querySearch(query) {
      var params = {
        email: query,
      };

      return IdentityService.get(params)
        .then(function (identity) {
          return identity;
        })
        .catch(function (err) {
          ToastService.showError(err);
          return [];
        });
    }

    /**
     * Handle assignee select
     *
     * @param  {Object} item    Selected item (assignee)
     */
    function selectedItemChange(item) {
      self.setModelValue(item);
    }
  }
})();
