(function () {
  angular
    .module("akitabox.core.services.workOrderLog", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "akitabox.core.services.virtualRepeat",
    ])
    .factory("WorkOrderLogService", WorkOrderLogService);

  /* @ngInject */
  function WorkOrderLogService(
    // Angular
    $rootScope,
    // Events
    EVENT_LOG_WORK,
    // AkitaBox
    models,
    HttpService,
    VirtualRepeatService
  ) {
    var service = {
      // Routes
      buildBuildingListRoute: buildBuildingListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      buildBuildingDetailRoute: buildBuildingDetailRoute,
      // Create
      create: create,
      // Retrieve
      getByTaskId: getByTaskId,
      getById: getById,
      getByBuildingId: getByBuildingId,
      getByOrganization: getByOrganization,
      getAllByBuildingId: getAllByBuildingId,
      getAllByOrganization: getAllByOrganization,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrganization: getStatsByOrganization,
      getStatsByTaskId: getStatsByTaskId,
      // Update
      update: update,
      // Delete
      remove: remove,
      // Virtual Repeat
      getVirtualRepeat: getVirtualRepeat,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------
    function emitLogWorkChangedEvent(taskId, logWorkId) {
      $rootScope.$broadcast(EVENT_LOG_WORK, {
        task: taskId,
        workOrderLog: logWorkId,
      });
    }

    function buildBaseRoute(buildingId, taskId) {
      if (taskId)
        return (
          "/buildings/" +
          buildingId +
          "/" +
          models.WORK_ORDER.ROUTE_PLURAL +
          "/" +
          taskId +
          "/" +
          models.WORK_ORDER_LOG.ROUTE_PLURAL
        );
      return (
        "/buildings/" + buildingId + "/" + models.WORK_ORDER_LOG.ROUTE_PLURAL
      );
    }

    function buildBuildingDetailRoute(buildingId, workOrderLogId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + workOrderLogId;
    }

    function buildBuildingListRoute(buildingId, taskId) {
      return buildBaseRoute(buildingId, taskId);
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.WORK_ORDER_LOG.ROUTE_PLURAL
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.post(route, data).then(function (workOrderLog) {
        emitLogWorkChangedEvent(data.task, workOrderLog && workOrderLog._id);
        return workOrderLog;
      });
    }

    /******************* *
     *   Retrieve
     ******************* */

    function getById(buildingId, id, params) {
      var route = buildBuildingDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params);
    }

    function getByTaskId(buildingId, taskId, params) {
      var route = buildBuildingListRoute(buildingId, taskId);
      return HttpService.get(route, params);
    }

    function getByBuildingId(buildingId, params) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getAllByBuildingId(buildingId, params) {
      var route = buildBuildingListRoute(buildingId);
      return HttpService.getAll(route, params);
    }

    function getAllByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, params);
    }

    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildBuildingListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getStatsByOrganization(organizationId, params) {
      var route = "/stats" + buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getStatsByTaskId(buildingId, taskId, params) {
      var route = "/stats" + buildBuildingListRoute(buildingId, taskId);
      return HttpService.get(route, params);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, id, data) {
      var route = buildBuildingDetailRoute(buildingId, id);
      return HttpService.put(route, data).then(function (workOrderLog) {
        emitLogWorkChangedEvent(data.task, id);
        return workOrderLog;
      });
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, id) {
      var route = buildBuildingDetailRoute(buildingId, id);
      return HttpService.remove(route).then(function (workOrderLog) {
        emitLogWorkChangedEvent(workOrderLog.task, id);
        return workOrderLog;
      });
    }

    /******************* *
     *   Virtual Repeat
     * ****************** */

    function getVirtualRepeat(buildingId, fetchLimit, query) {
      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service.getByBuildingId(buildingId, params);
      }
    }
  }
  // ==============
})();
