(function () {
  /**
   * @controller AssetCostLineSelectionDialogController
   * Dialog for selecting or creating custom cost lines connected to an asset.
   *
   * @see AssetCostLineSelectionDialogService#show
   */
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .controller(
      "AssetCostLineSelectionDialogController",
      AssetCostLineSelectionDialogController
    );

  /* @ngInject */
  function AssetCostLineSelectionDialogController(
    $mdDialog,
    $q,
    CancellableService,
    CostLineService,
    OrganizationService,
    ConfirmationDialog,
    ToastService,
    CatalogService,
    AssetService,
    FeatureFlagService
  ) {
    // ===============
    // Properties
    // ===============

    /**
     * @property { Asset[] }
     * Provided in `locals` by the service.
     */
    this.assets;

    /**
     * @property { boolean } loading
     * Show Load Indication in init
     */
    this.loading = true;

    /**
     * @property { boolean } showFcaRsmeans
     * Based on RSMeans Cost Estimation feature option to Organization
     */
    this.showFcaRsmeans = OrganizationService.getCurrent().show_fca_rsmeans;

    /**
     * @type { boolean } Based on `Catalogs` feature flag
     */
    this.isCatalogsEnabled = FeatureFlagService.isEnabled("catalogs") || false;

    /**
     * @property { CostLine }
     * The existing cost line for the selected asset
     */
    this.existingCostLine = null;

    /**
     * @property { object } costLineData
     * Current state of cost line represented in the cost
     * line selection section
     */
    this.costLineData;

    /**
     * @property { object } catalogItemData
     * Current state of the selected abx catalog item
     * in the catalog selection section
     */
    this.catalogItemData;

    /**
     * @property { object } customCostLineData
     * Current state of cost line represented in the cost
     * line creation section
     */
    this.customCostLineData;

    /**
     * @property { boolean } deletedCostLine
     * Updated when cost line is deleted
     * and return the variable to refresh the assets if true
     */
    this.costLineDeleted = false;

    /**
     * @property { boolean } sectionIsInvalid
     * True when the currently displayed section is in an invalid state.
     */
    this.sectionIsInvalid = true;

    /**
     * @property { Record<string, string> } catalogs Map of catalog names to
     *  ids
     */
    this.catalogs = {
      "Facilities Maintenance and Repair": "fmr",
      "Commercial New Construction": "cnc",
      "Facilities and Commercial Renovation": "fcr",
      Custom: null,
    };

    /**
     * @property { Catalog[] } abxCatalogs List of all abx catalogs
     */
    this.abxCatalogs = [];

    const getCatalogModel = (catalog) => {
      if (catalog === null) {
        return { label: "Custom", value: "Custom" };
      }
      for (const catalogName of this.catalogNames) {
        if (catalog === catalogName.label) {
          return catalogName;
        }
      }
    };

    const getAbxCatalogModel = (catalogId) => {
      return this.abxCatalogs.find((catalog) => catalog.id === catalogId);
    };

    const ABX_CATALOG_PREFIX = "__AbxCatalog__";

    const getAllCatalogs = () => {
      if (!this.isCatalogsEnabled) return $q.resolve();
      return CatalogService.getAll().then(({ data: catalogs }) => {
        if (!Array.isArray(catalogs) || catalogs.length === 0) {
          return;
        }

        this.abxCatalogs = catalogs;
        for (const { id, name } of this.abxCatalogs) {
          this.catalogs[name] = `${ABX_CATALOG_PREFIX}${id}`;
          this.catalogNames.push({ value: name, label: id });
        }
      });
    };

    const removeRsmeansCatalogs = () => {
      const rsmeansCatalogs = [
        "Commercial New Construction",
        "Facilities Maintenance and Repair",
        "Facilities and Commercial Renovation",
      ];
      this.catalogNames = this.catalogNames.filter(
        (catalog) => !rsmeansCatalogs.includes(catalog.value)
      );
    };

    /**
     * Checks if the current selected catalog starts with the `ABX_CATALOG_PREFIX` prefix
     *
     * @returns {boolean} whether the selected catalog is a AkitaBox catalog
     */
    this.isAbxCatalog = () => {
      const catalog = this.catalogs[this.selectedCatalog.value];
      return (
        catalog &&
        typeof catalog === "string" &&
        catalog.startsWith(ABX_CATALOG_PREFIX)
      );
    };

    /**
     * Removes `ABX_CATALOG_PREFIX` prefix from the current
     * selected abx catalog, returning its original value
     *
     * @returns {string | undefined} the `id` of the selected abx catalog
     */
    this.getAbxCatalog = () => {
      if (!this.isAbxCatalog()) return;
      const catalog = this.catalogs[this.selectedCatalog.value];
      return catalog.replace(ABX_CATALOG_PREFIX, "");
    };

    /**
     * @property { string[] } catalogNames Names of all catalogs
     */
    this.catalogNames = [
      { label: "Custom", value: "Custom" },
      { label: "fmr", value: "Facilities Maintenance and Repair" },
      { label: "cnc", value: "Commercial New Construction" },
      { label: "fcr", value: "Facilities and Commercial Renovation" },
    ];

    /**
     * @property { boolean } deleting flag to determine if deleting or not
     */
    this.deleting = false;

    /**
     * @property {string|undefined} errorMessage holds the error messages generated from API calls
     */
    this.errorMessage = "";

    /**
     * pipeline to hold a series of cancellable async functions
     * mostly for our bulk work of assigning each asset within this dialog
     * to the selected cost line
     */
    const initPipeline = CancellableService.createPipeline("canceled");

    /**
     * @property {number} progress progress of the bulk action of assigning each asset to a cost line
     */
    this.progress = 0;

    /**
     * @property {string} lastAction helps us know what to retry, the save or delete
     */
    this.lastAction = "";

    /**
     * @property { string } selectedCatalog Currently selected catalog
     */
    this.selectedCatalog = this.catalogNames[0];

    // ===============
    // Lifecycle
    // ===============

    /**
     * Initialize the dialog.
     *
     * @note $onInit is not available for non-component-controllers
     */
    const onInit = () => {
      if (!this.showFcaRsmeans) {
        removeRsmeansCatalogs();
        this.selectedCatalog = this.catalogNames[0];
      }
      if (this.assets.length === 1) {
        getAllCatalogs()
          .then(() => CostLineService.getOneByAsset(this.assets[0]))
          .then((costLine) => {
            if (costLine && costLine.catalog) {
              this.selectedCatalog = getCatalogModel(costLine.catalog);
            } else if (costLine && costLine.abx_catalog) {
              const abxCatalog = getAbxCatalogModel(costLine.abx_catalog);
              if (!abxCatalog) {
                // eslint-disable-next-line no-console
                console.error("Cost Line with bad data", costLine);
                throw new Error("AkitaBox catalog doesn't exist");
              }
              this.selectedCatalog = {
                value: abxCatalog.name,
                label: abxCatalog.id,
              };
            }
            this.existingCostLine = costLine;
          })
          .catch(ToastService.showError)
          .finally(() => {
            this.loading = false;
          });
      } else {
        getAllCatalogs()
          .catch(ToastService.showError)
          .finally(() => {
            this.loading = false;
          });
      }
    };

    // ===============
    // Public Methods
    // ===============

    /**
     * @public
     * @method handleCatalogChange
     * @param { string } selectedCatalog The new catalog
     * Handler for input changes on catalog dropdown.
     */
    this.handleCatalogChange = (selectedCatalog) => {
      this.selectedCatalog = selectedCatalog.model;
      this.sectionIsInvalid = true;
    };

    /**
     * @param { bool } isInvalid
     */
    this.setSectionInvalid = (isInvalid) => {
      this.sectionIsInvalid = isInvalid;
    };

    this.setSaving = (saving) => {
      this.saving = saving;
    };

    this.handleCostLineDataChange = (costLineData) => {
      this.costLineData = costLineData;
    };

    this.handleCatalogItemDataChange = (catalogItemData) => {
      this.catalogItemData = catalogItemData;
    };

    this.handleCustomCostLineDataChange = (customCostLineData) => {
      this.customCostLineData = customCostLineData;
    };

    /**
     * @method cancel
     * Cancel the dialog
     */
    this.cancel = () => {
      if (this.saving | this.deleting) {
        initPipeline.cancel();
      } else {
        $mdDialog.hide(this.costLineDeleted);
      }
    };

    this.save = () => {
      if (!this.assets.length) {
        return $q.resolve();
      }

      this.progress = 0;
      this.errorMessage = "";
      this.setSaving(true);
      this.setSectionInvalid(true);
      this.lastAction = "save";

      const fns = [];
      let count = 0;
      this.assets.forEach((asset) => {
        fns.push(() => {
          /**
           * If this is for abx catalog items, use the classify method
           */
          if (this.catalogItemData?.model) {
            const { model: catalogItem } = this.catalogItemData;
            const quantity = catalogItem.quantity;
            // "costlineId"'s presence as a key indicates we are updating an existing one,
            // and only quantity can be updated
            if (catalogItem.costLineId) {
              return AssetService.updateQuantity(
                asset.building,
                asset._id,
                quantity
              );
            }
            return AssetService.classify(
              asset.building,
              asset._id,
              catalogItem,
              quantity
            );
          }
          /**
           * 1. Find if our current asset already has a cost line associated with it
           * 2. If it does, delete it
           * 3. Create a new cost line for the asset
           */
          return CostLineService.getOneByAsset(asset)
            .then((costLine) => {
              const isEditingCatalog = Boolean(
                this.catalogItemData?.model?.costLineId
              );
              if (costLine && !isEditingCatalog) {
                return CostLineService.remove(asset.building, costLine._id);
              }
            })
            .then(() => {
              if (this.catalogs[this.selectedCatalog.value]) {
                const { uniformat, quantity, costPerUnit } =
                  this.costLineData.model;
                return CostLineService.create(asset.building, {
                  uniformat: uniformat.toUpperCase(),
                  quantity,
                  user_cost: costPerUnit,
                  asset: asset._id,
                  catalog: this.catalogs[this.selectedCatalog.value],
                });
              } else {
                const { uniformat, description, quantity, unit, costPerUnit } =
                  this.customCostLineData.model;
                return CostLineService.create(asset.building, {
                  uniformat: uniformat.toUpperCase(),
                  description,
                  quantity,
                  quantity_unit: unit.toLowerCase(),
                  user_cost: costPerUnit,
                  asset: asset._id,
                });
              }
            })
            .then(() => {
              count++;
              this.progress = Math.floor((count / this.assets.length) * 100);
            });
        });
      });

      return initPipeline
        .switchTo(CancellableService.executeSeries(fns))
        .then(() => {
          this.setSaving(false);
          this.setSectionInvalid(false);
          $mdDialog.hide(true);
        })
        .catch((err) => {
          const remaining = this.assets.length - count;
          if (err !== "canceled") {
            this.errorMessage = `Error occured. Remaining ${remaining} assets could not be updated`;
            initPipeline.cancel(); // cancel remaining ones
          } else {
            this.setSectionInvalid(false);
          }
          // $mdDialog.hide() removes this from the DOM, so we can't put setSaving in a finally
          this.setSaving(false);
        });
    };

    this.delete = () => {
      ConfirmationDialog.show({
        title:
          this.assets.length > 1
            ? `Remove base costs from all ${this.assets.length} assets?`
            : `Remove base costs from ${this.assets[0].name}`,
        prompt:
          this.assets.length > 1
            ? `Are you sure you want remove the base cost from each of these ${this.assets.length} assets?`
            : `Are you sure you want to remove ${this.assets[0].name}'s base cost?`,
        isDestructive: true,
        confirmText: "Remove",
        onConfirm: () => {
          this.progress = 0;
          this.errorMessage = "";
          this.deleting = true;
          this.lastAction = "delete";
          this.costLineDeleted = true;

          let count = 0;
          const fns = [];

          this.assets.forEach((asset) => {
            fns.push(() => {
              return AssetService.classify(
                asset.building,
                asset._id,
                null
              ).then(() => {
                count++;
                this.progress = Math.floor((count / this.assets.length) * 100);

                this.existingCostLine = null;
              });
            });
          });

          // We don't return the promise here becuase we don't want the dialog
          // to wait on all the deletes. We want to go back to the selection dialog
          // and be able to cancel during the delete if needed
          initPipeline
            .switchTo(CancellableService.executeSeries(fns))
            .catch((err) => {
              const remaining = this.assets.length - count;
              if (err !== "canceled") {
                // probably some api error, we need to alert and cancel any remaining requests
                this.errorMessage = `Error occured. Remaining ${remaining} assets could not be updated`;
                initPipeline.cancel();
              }
            })
            .finally(() => {
              this.deleting = false;
            });
        },
      });
    };

    this.retry = () => {
      if (this.lastAction === "delete") {
        this.delete();
      } else if (this.lastAction === "save") {
        this.save();
      } else {
        // just make the button and error go away
        this.errorMessage = "";
        this.setSectionInvalid(false);
        this.setSaving(false);
        this.deleting = false;
      }
    };

    this.$onInit = onInit;
  }
})();
