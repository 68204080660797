(function () {
  angular
    .module("akitabox.ui.dialogs.printInspectionProgram")
    .factory("PrintInspectionProgramDialog", PrintInspectionProgramDialog);

  /* @ngInject */
  function PrintInspectionProgramDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/print-inspection-program/print-inspection-program.dialog.html",
      controller: "PrintInspectionProgramDialogController",
      controllerAs: "vm",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * AlertDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param  {Object}   options                    Dialog options
     * @param  {Object}   options.locals             Dialog options locals
     * @param  {String}   options.locals.print       Function to invoke with selected print option
     *
     * @return {Promise<Object|Error} Alert dialog promise
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
