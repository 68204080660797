(function () {
  angular
    .module("akitabox.core.services.cacheHelpers", [])
    .factory("CacheHelpers", CacheHelpers);

  function CacheHelpers() {
    var service = {
      removeModel: removeModel,
      hasCustomFilters: hasCustomFilters,
      setAll: setAll,
      updateModel: updateModel,
    };

    return service;

    /**
     * Determines if the given params object has filters
     * outside of `skip` and `limit`.
     *
     * This is useful for determining whether a `getAll` is
     * actually fetching "all" of the models at a given route.
     * If there _are_ custom filters, then this is not the case.
     *
     * @param {Object} params - The parameters to examine
     * @param {String[]} [exceptions] - Keys to ignore when evaluating if the
     *     given params have any custom filters
     */
    function hasCustomFilters(params, exceptions) {
      if (angular.isEmpty(params)) {
        return false;
      }

      var filters = angular.copy(params);
      delete filters.skip;
      delete filters.limit;

      if (exceptions) {
        exceptions.forEach(function (exception) {
          delete filters[exception];
        });
      }

      return Object.keys(filters).length > 0;
    }

    // ------------------------
    //   Cache update
    // ------------------------

    /**
     * Updates or puts a model in a cache's `all` dictionary
     *
     * @param {Object} cache    Cache to update
     * @param {Object} [model]  Model to update or put in cache
     */
    function updateModel(cache, model, id) {
      var cachedValues = cache.get("all");
      if (!cachedValues) return;

      cachedValues[id] = model;

      cache.put("all", cachedValues);
    }

    /**
     * Deletes a model from a cache's `all` dictionary
     *
     * @param {Object} cache   Cache to delete from
     * @param {String} id      ID of model to delete
     */
    function removeModel(cache, id) {
      var cachedValues = cache.get("all");
      if (!cachedValues) return;

      delete cachedValues[id];

      cache.put("all", cachedValues);
    }

    /* Takes a given set of results/entities, and puts a mapping of IDs to entities in the given
     * cache's `all` field.
     */
    function setAll(cache, results) {
      var allMap = {};
      for (var i = 0; i < results.length; ++i) {
        var entity = results[i];
        allMap[entity._id] = entity;
      }
      cache.put("all", allMap);
    }
  }
})();
