(function () {
  angular
    .module("akitabox.ui.dialogs.bulkDelete.asset")
    .controller(
      "BulkDeleteAssetDialogController",
      BulkDeleteAssetDialogController
    );

  /* @ngInject */
  function BulkDeleteAssetDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    AssetService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.isDeleting = false;
    self.performedBulkDeleteAsset = false;
    self.logs = [];

    // Functions
    self.cancel = closeDialog;
    self.deleteAsset = deleteAsset;

    // ------------------------
    //   Public Functions
    // ------------------------

    function closeDialog() {
      if (self.performedBulkDeleteAsset) {
        return $mdDialog.hide();
      } else {
        $mdDialog.cancel();
      }
    }
    /**
     * Delete assets
     */
    function deleteAsset() {
      self.isDeleting = true;
      self.determinateValue = 0;
      var increment = (1 / self.assets.length) * 100;
      var promiseArray = [];

      // Delete the assets
      for (var i = 0; i < self.assets.length; i++) {
        promiseArray[i] = AssetService.deleteAsset(
          self.assets[i].buildingId,
          self.assets[i]._id
        )
          // when we delete an asset, the backend returns an empty object
          // so we have to do some javascript trickery to ensure our i is accurate
          // This 'then' will be called when each of the promise is resolved,
          // no mather if returned an error or not. The second 'then' will
          // be called only when a promise is resolved successfully.
          .then(
            ((storedI) => {
              return () => {
                return storedI;
              };
            })(i)
          )
          .then((storedI) => {
            self.determinateValue += increment;
            self.logs.push({
              errored: false,
              message: `Asset <b>${self.assets[storedI].name}</b> was successfully deleted`,
            });
          })
          .catch((error) => {
            self.determinateValue += increment;
            const errorMessage =
              error &&
              error.data &&
              error.data.error &&
              error.data.error.message
                ? error.data.error.message
                : "Error when trying to delete the asset";
            const url =
              error && error.config && error.config.url ? error.config.url : "";

            const assetIdPattern = /\/assets\/([^/]+)/;

            // Use the regular expression to extract the asset ID from the URL
            const match = url.match(assetIdPattern);
            let assetId = "";

            if (match) {
              assetId = match[1];
            }

            const asset = self.assets.find((asset) => asset._id === assetId);
            const assetName = asset && asset.name ? asset.name : "";

            self.logs.push({
              errored: true,
              message: `Asset <b>${assetName}</b> was not deleted successfully`,
              error: errorMessage,
            });
          });
      }

      $q.all(promiseArray)
        .catch(ToastService.showError)
        .finally(function () {
          self.isDeleting = false;
          self.performedBulkDeleteAsset = true;
        });
    }
  }
})();
