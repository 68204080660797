(function () {
  angular
    .module("akitabox.ui.dialogs.request.create")
    .controller("CreateRequestDialogController", CreateRequestDialogController);

  /* @ngInject */
  function CreateRequestDialogController(
    // Angular
    $q,
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Services
    IdentityService,
    Router,
    AssetService,
    FloorService,
    IssueTypeService,
    OrganizationService,
    RequestService,
    RoomService,
    ShadowService,
    ToastService,
    // Helpers
    Utils
  ) {
    var self = this;
    const _organization = OrganizationService.getCurrent();

    // Constants
    var GA_CATEGORY = "servicerequest-modal";
    var GA_SUBMIT_ACTION = "submit-service-request";
    var GA_ATTACHMENT_ACTION = "attachments-add";

    // Attributes
    self.activeTab = 0;
    self.loading = true;
    self.issueTypes = [];
    self.formData = {};
    self.attachments = [];
    self.saving = false;
    self.newRequest = null;
    self.issueType = null;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.next = next;
    self.previous = previous;
    self.fetchIssueTypes = fetchIssueTypes;
    self.handleFilesChange = handleFilesChange;
    self.isDisabled = isDisabled;
    self.onChange = onChange;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, fetch floors, prepopulate fields if they were passed in via locals
     */
    function init() {
      // Fill provided data
      var promises = [];

      if (self.asset) {
        promises = [setFloorWithAsset(), setRoomWithAsset()];
      } else if (self.room) {
        promises = [setFloorWithRoom()];
      }

      if (!self.building) {
        self.loading = false;
        return;
      }

      promises.push(fetchIssueTypes());

      $q.all(promises)
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });

      updateIssueTypesForBuilding();
    }

    function setRoomWithAsset() {
      if (self.asset.room && self.asset.room._id) {
        return $q(function (resolve) {
          self.room = self.asset.room;
          self.room.building = self.asset.building;
          resolve();
        });
      } else {
        return AssetService.getRoom(self.building._id, self.asset.values).then(
          function (room) {
            self.room = room || null;
          }
        );
      }
    }

    function setFloorWithAsset() {
      if (self.asset.level && self.asset.level._id) {
        return FloorService.getById(
          self.asset.building,
          self.asset.level._id || self.asset.level
        ).then(function (level) {
          self.floor = level || null;
        });
      } else {
        return AssetService.getFloor(self.building._id, self.asset.values).then(
          function (floor) {
            self.floor = floor || null;
          }
        );
      }
    }

    function setFloorWithRoom() {
      if (self.room.level && self.room.level._id) {
        self.floor = self.room.level;
        self.floor.building = self.room.building;
      } else {
        return RoomService.getFloor(self.building._id, self.room.values).then(
          function (floor) {
            self.floor = floor || null;
          }
        );
      }
    }

    /**
     * Go to the newly created asset's detail page
     */
    function goToNewRequest() {
      var stateParams = {
        buildingId: $stateParams.buildingId ? self.building._id : null,
        requestId: self.newRequest._id,
      };
      Router.go("app.request", stateParams);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function onChange($event, field) {
      if (
        !$event.model &&
        !["building", "floor", "room", "asset"].includes(field)
      ) {
        return;
      }
      var changed = !Utils.isSameModel(self[field], $event.model);
      switch (field) {
        case "building":
          self.building = $event.model;
          if (changed) {
            self.floor = null;
            self.room = null;
            self.asset = null;
            if (self.building) {
              onBuildingChange();
            }
          }
          break;
        case "asset":
          self.asset = $event.model;
          if (changed && self.asset) {
            if (!self.room) {
              const asset = self.asset;
              if (asset && asset.room) {
                var params = {
                  _id: asset.room._id,
                };
                const building_id = asset.building._id
                  ? asset.building._id
                  : asset.building;
                RoomService.getBFF(
                  building_id,
                  params,
                  {},
                  _organization._id
                ).then(function (room) {
                  if (room) {
                    self.asset.room = room;
                    setRoomWithAsset();
                  }
                });
              }
            }
            if (!self.floor) setFloorWithAsset();
          }
          break;
        case "room":
          self.room = $event.model;
          if (changed) {
            self.asset = null;
            if (self.room && !self.floor) {
              setFloorWithRoom();
            }
          }
          break;
        case "floor":
          self.floor = $event.model;
          if (changed) {
            self.room = null;
            self.asset = null;
          }
          break;
        case "issueType":
          self.issueType = $event.model;
          break;
      }
      self.formData[field] = $event.model;
    }

    function onBuildingChange() {
      // Clear existing data that depends on building
      // Floor, room, and asset clear themselves
      self.issueType = null;
      self.formData.issueType = null;
      self.issueTypes = null;

      updateIssueTypesForBuilding();
      fetchIssueTypes();
    }

    /**
     * Navigate to the next tab
     */
    function next() {
      self.activeTab++;
    }

    /**
     * Navigate to the previous tab
     */
    function previous() {
      self.activeTab--;
    }

    /**
     * Create a service request
     */
    function create() {
      if (self.saving) return;
      self.saving = true;

      var data = angular.copy(self.formData);

      if (self.attachments.length > 0) {
        data.attachments = self.attachments.map(function (attachment) {
          return attachment._document._id;
        });
      }
      if (self.floor) data.level = self.floor._id;
      if (self.room) data.room = self.room._id;
      if (self.asset) data.asset = self.asset._id;
      if (self.issueType) data.issue_type = self.issueType._id;

      // Create the service request. first we need to set requester email to current user
      IdentityService.getCurrent()
        .then(function (identity) {
          data.requester_email = identity.email;
          ShadowService.sendEvent(GA_CATEGORY, GA_SUBMIT_ACTION);
          return RequestService.create(self.building._id, data);
        })
        .then(function (request) {
          self.newRequest = request;
          ShadowService.sendEvent(
            GA_CATEGORY,
            GA_ATTACHMENT_ACTION,
            "",
            self.attachments.length
          );
          $mdDialog.hide([request]);
          ToastService.complex()
            .text("Successfully created request")
            .action("View", goToNewRequest)
            .show();
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Get all available issue types to choose from
     * for the building.
     *
     * @return {Promise<Array|Error>}   Resolves with all issue types for the building
     */
    function fetchIssueTypes() {
      return IssueTypeService.getAll(self.building._id, {}, { cache: false })
        .then(function (issueTypes) {
          self.issueTypes = issueTypes.map(function (it) {
            return {
              model: it,
              value: it.name,
            };
          });
        })
        .catch(ToastService.showError);
    }

    function handleFilesChange(files) {
      self.attachments = files;
    }

    function updateIssueTypesForBuilding() {
      if (!self.building) {
        return;
      }
      self.showIssueTypes = self.building.show_issue_types;
      self.requireIssueTypes = self.building.require_issue_types;
    }

    /**
     * Determine if the form is disabled
     *
     * @return {Boolean}  True if disabled, false if not
     */
    function isDisabled() {
      return (
        self.saving ||
        !self.formData.subject ||
        !self.building ||
        (self.showIssueTypes && self.requireIssueTypes && !self.issueType) ||
        !allUploadsComplete()
      );
    }

    function allUploadsComplete() {
      return self.attachments.reduce(function (prev, current) {
        return prev && current.complete;
      }, true);
    }
  }
})();
