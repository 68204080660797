(function () {
  angular.module("akitabox.desktop.building.admin.job").run(stateBlock);

  /** @ngInject **/
  function stateBlock(models, Router) {
    Router.configureStates([
      {
        state: "app.building.admin.jobs",
        config: {
          url: "/" + models.JOB.ROUTE_PLURAL,
          templateUrl:
            "app/desktop/modules/building/admin/job/list/building-job-list.html",
          controller: "BuildingJobListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Building Jobs",
          },
        },
      },
      {
        state: "app.building.admin.job",
        config: {
          url: "/" + models.JOB.ROUTE_SINGULAR + "/:jobId",
          templateUrl: "app/desktop/modules/admin/job/detail/job-detail.html",
          controller: "JobDetailController",
          controllerAs: "vm",
          resolve: {
            job: getJob,
          },
          data: {
            pageTitle: "Building Job Details",
          },
        },
      },
    ]);
  }

  /** @ngInject **/
  function getJob($stateParams, AdminJobService) {
    return AdminJobService.getById($stateParams.jobId);
  }
})();
