(function () {
  angular
    .module("akitabox.desktop.asset.detail")
    .controller("AssetDetailsTabController", AssetDetailsTabController);

  /* @ngInject */
  function AssetDetailsTabController(
    // Angular
    $scope,
    $timeout,
    // Resolves
    building
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.asset = $scope.vm.asset;
    self.rendering = true;

    $timeout(function () {
      self.rendering = false;
    }, 1000);
  }
})();
