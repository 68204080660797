(function () {
  angular
    .module("akitabox.ui.dialogs.document.revert")
    .controller(
      "RevertDocumentDialogController",
      RevertDocumentDialogController
    );

  /* @ngInject */
  function RevertDocumentDialogController(
    // Material
    $mdDialog,
    // Services
    ToastService,
    DocumentService
  ) {
    var self = this;

    // Attributes
    self.saving = false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.revert = revert;

    // ------------------------
    //   Public Functions
    // ------------------------

    function revert() {
      self.saving = true;
      DocumentService.revert(
        self.building._id,
        self.document._id,
        self.commitId
      )
        .then(function (revertedDocument) {
          $mdDialog.hide(revertedDocument);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
