import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Refresh } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { TablePaginationProps } from "@mui/material/TablePagination";
import MuiPagination from "@mui/material/Pagination";

const ListPagination = ({
  handleRefresh,
  paginationModel,
  onPaginationModelChange,
}: {
  handleRefresh: () => void;
  paginationModel: any;
  onPaginationModelChange: (paginationModel: any) => void;
}) => {
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPaginationModelChange({
      ...paginationModel,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        showFirstButton
        showLastButton
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  }

  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: "5%" }}>
      <GridPagination
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={Pagination}
      />
      <IconButton onClick={handleRefresh}>
        <Refresh />
      </IconButton>
    </div>
  );
};

export default ListPagination;
