(function () {
  angular.module("akitabox.desktop.media-viewer").run(states);

  /* @ngInject */
  function states(models, Router) {
    Router.configureStates([
      {
        state: "media-viewer",
        config: {
          url: "/media/buildings/:buildingId/docs/:documentId?inline",
          templateUrl:
            "app/desktop/modules/media-viewer/components/media-container/media-container.html",
          controller: "MediaViewerContainerController",
          controllerAs: "vm",
          resolve: {
            account: getIdentity,
            building: getBuilding,
            document: getDocument,
            organization: getOrganization,
            user: getUser,
          },
          data: {
            pageTitle: "Media",
          },
        },
      },
    ]);
  }

  /**
   * Get the user's current organization
   *
   * @param account
   * @param OrganizationService
   *
   * @ngInject
   */
  function getOrganization(account, RedirectService, OrganizationService) {
    var path = RedirectService.getDestination();
    var current = OrganizationService.getCurrent();
    if (current) {
      return current;
    }
    return OrganizationService.init(path);
  }

  /**
   * @ngInject
   */
  function getBuilding(
    $stateParams,
    account,
    BuildingService,
    ToastService,
    CacheFactory
  ) {
    CacheFactory.clearAll();
    return BuildingService.getById($stateParams.buildingId)
      .then(function (building) {
        BuildingService.setCurrent(building);
        return building;
      })
      .catch(ToastService.showError);
  }

  /**
   * @ngInject
   */
  function getDocument($stateParams, account, building, DocumentService) {
    return DocumentService.getById(building._id, $stateParams.documentId);
  }

  /**
   * Get the current user's account.
   *
   * @ngInject
   */
  function getIdentity($state, $q, IdentityService) {
    return IdentityService.getCurrent().catch(function (err) {
      // If we catch an error because the server can't be reached, show the 503 page
      if (err.status === -1) {
        $state.go("root.503");
      }
      return $q.reject(err);
    });
  }

  /**
   * Get current user of organization
   *
   * @param organization
   * @param UserService
   *
   * @return {Object} User of organization
   *
   * @ngInject
   */
  function getUser(organization, UserService) {
    if (organization) {
      return UserService.init(organization._id);
    }
    return null;
  }
})();
