(function () {
  angular
    .module("akitabox.core.lib.appcues", ["akitabox.core.constants"])
    .factory("AppcuesService", AppcuesService);

  /* @ngInject */
  function AppcuesService($window, $interval, $q, APPCUES_ID) {
    const EXT_APPCUES_USER_ID_KEY = "apc_user_id";
    const EXT_APPCUES_LOCAL_ID_KEY = "apc_local_id";
    const EXT_APPCUES_SESSION_USER_KEY = "apc_user";

    var service = {
      init: init,
      isAvailable: isAvailable,
      getInstance: getInstance,
      removeInstance: removeInstance,
    };

    return service;

    /**
     * Add the appcues script to the DOM
     */
    function init() {
      var $appcues = angular
        .element(document.createElement("script"))
        .attr("src", "//fast.appcues.com/" + APPCUES_ID + ".js");
      angular.element(document.body).append($appcues);
    }

    /**
     * Check if Appcues is available
     *
     * @return    True if available, false if not
     */
    function isAvailable() {
      return angular.isDefined($window.Appcues);
    }

    /**
     * Checks if Appcues is available every second for three seconds and
     * resolves with Appcues instance or `undefined` if not available
     *
     * @returns   Appcues instance (if available)
     */
    function getInstance() {
      if (service.isAvailable()) {
        return $q.resolve($window.Appcues);
      }
      var check = $interval(
        function () {
          if (service.isAvailable()) {
            $interval.cancel(check);
          }
        },
        1000,
        3
      );
      return check
        .then(function () {
          return $window.Appcues;
        })
        .catch(function () {
          // Interval canceled, Appcues is available
          return $window.Appcues;
        });
    }

    /**
     * Remove data from Local and Session Storage saved by Appcues
     */
    function clearStorageData() {
      localStorage.removeItem(EXT_APPCUES_USER_ID_KEY);
      localStorage.removeItem(EXT_APPCUES_LOCAL_ID_KEY);
      sessionStorage.removeItem(EXT_APPCUES_SESSION_USER_KEY);
    }

    /**
     * Remove Appcues
     */
    function removeInstance() {
      $window.Appcues = undefined;
      clearStorageData();
    }
  }
})();
