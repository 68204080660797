(function () {
  /**
   * @ngdoc filter
   * @name abxMinutesToHours
   *
   * @description
   * Formats minutes to diplay in days, hours and minutes
   *
   * Example: {{ "130" | abxMinutesToHours }} will display "2h 10m"
   *
   * @param {Number} input - The source number to be formatted.
   * @return {String|*} the formatted string (or unformatted non-string input).
   */

  angular
    .module("akitabox.core.filters.minutesToHours", [])
    .filter("abxMinutesToHours", abxMinutesToHours);

  function abxMinutesToHours() {
    return function (input) {
      if (typeof input !== "number") {
        return input;
      }

      var hours = Math.floor(input / 60);
      var minutes = input % 60;

      var formattedTimeStamp = minutes + "m";

      if (hours > 0) {
        formattedTimeStamp = hours + "h " + formattedTimeStamp;
      }

      return formattedTimeStamp;
    };
  }
})();
