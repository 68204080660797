(function () {
  /**
   * @ngdoc component
   * @name abxUnplacedPins
   *
   * @param {Object[]} pins - Unplaced pins
   * @param {Function} onPlacePin - callback invoked once pin is selected from dropdown
   * @param {Boolean} [isOpenOnInit] - override the isOpen property on initialization
   * @param {Boolean} [disabled] - close and disable component
   *
   * @description
   * Tab for unplaced pins
   */
  angular.module("akitabox.planView").component("abxUnplacedPins", {
    bindings: {
      pins: "<abxUnplacedPins",
      onPlacePin: "&abxOnPlacePin",
      isOpenOnInit: "<?abxIsOpenOnInit",
      disabled: "<?abxDisabled",
      getPinIcon: "<abxGetPinIcon",
    },
    controller: AbxUnplacedPinsController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/unplaced-pins/unplaced-pins.component.html",
  });

  function AbxUnplacedPinsController(FeatureFlagService) {
    var self = this;

    // Attributes
    self.isOpen = false;

    // Functions
    self.toggleUnplacedPins = toggleUnplacedPins;

    init();

    // =================
    // Lifecycle
    // =================

    function init() {
      self.isOpen = self.isOpenOnInit || false;
    }

    /**
     * Toggle visibility of unplacedPin tag and list
     * @param {Boolean} [visibility] - optional override; true sets it to open, false sets it closed
     */
    function toggleUnplacedPins(visibility) {
      if (angular.isDefined(visibility)) {
        self.isOpen = visibility;
      } else {
        self.isOpen = !self.isOpen;
      }
    }
  }
})();
