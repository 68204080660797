(function () {
  angular
    .module("akitabox.ui.dialogs.request.delete")
    .controller("DeleteRequestDialogController", DeleteRequestDialogController);

  /* @ngInject */
  function DeleteRequestDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    RequestService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since requestIDs is passed in through locals
    self.multiple = self.requests ? self.requests.length > 1 : false;

    self.buildingId = self.building ? self.building._id : self.buildingId;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.deleteRequest = deleteRequest;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Delete requests
     */
    function deleteRequest() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.requests) * 100;
      var promiseArray = [];

      // Delete the requests
      for (var i = 0; i < self.requests.length; i++) {
        promiseArray[i] = RequestService.remove(
          self.requests[i].building,
          self.requests[i]._id
        )
          // when we delete a request, the backend returns an empty object so we have to do some javascript
          // trickery to ensure our i is accurate
          .then(
            (function (storedI) {
              return function () {
                self.determinateValue += increment;
                return self.requests[storedI]._id;
              };
            })(i)
          );
      }

      $q.all(promiseArray)
        .then(function (deletedRequestIDs) {
          $mdDialog.hide(deletedRequestIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
