(function () {
  angular
    .module("akitabox.desktop.directives.focus", [])
    .directive("abxFocus", AbxFocusDirective);

  /* @ngInject */
  function AbxFocusDirective($timeout, $parse) {
    return {
      link: postLink,
    };

    function postLink(scope, element, attrs) {
      var model = $parse(attrs.abxFocus);
      scope.$watch(model, function (value) {
        if (value === true) {
          $timeout(function () {
            element[0].focus();
          });
        }
      });
      element.bind("blur", function () {
        scope.$apply(model.assign(scope, false));
      });
    }
  }
})();
