(function () {
  /**
   * @ngdoc component
   * @name abxRoundInfo
   *
   * @param viewAllUiSref - The ui-sref (eg, target state) value for the "VIEW ALL" link.
   *
   * https://ui-router.github.io/ng1/docs/0.3.1/index.html#/api/ui.router.state.directive:ui-sref
   *
   * @description
   * <abx-round-info> displays round information for a work order
   */
  angular
    .module("akitabox.ui.components.roundInfo", [
      "akitabox.core.services.workOrder",
    ])
    .component("abxRoundInfo", {
      bindings: {
        workOrder: "<abxWorkOrder",
        building: "<abxBuilding",
        viewAllUiSref: "<abxViewAllUiSref",
      },
      controller: AbxRoundInfo,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/round-info/round-info.component.html",
    });

  /* @ngInject */
  function AbxRoundInfo(WorkOrderService) {
    var self = this;

    // Attributes
    self.loading = true;
    self.empty = false;
    self.floors = [];
    self.rooms = [];
    self.assets = [];

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onChanges = function (changes) {
      if ((changes.workOrder || changes.loading) && self.workOrder) {
        self.loading = true;
        self.floors = WorkOrderService.getLevels(self.workOrder);
        self.rooms = WorkOrderService.getRooms(self.workOrder);
        self.assets = WorkOrderService.getAssets(self.workOrder);

        self.empty = isEmpty();
        self.loading = false;
      }
    };

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Check if there is no location information available
     *
     * @return {Boolean} True if empty, false if not
     */
    function isEmpty() {
      return !(
        self.building ||
        self.floors.length ||
        self.rooms.length ||
        self.assets.length
      );
    }
  }
})();
