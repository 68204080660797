(function () {
  /**
   * @ngdoc component
   * @name abxRadioButtonGroup
   *
   * @description
   * Radio button group
   */
  angular
    .module("akitabox.ui.components.radioButtonGroup", [])
    .component("abxRadioButtonGroup", {
      bindings: {
        model: "<abxModel",
        options: "<?abxOptions",
        isDisabled: "<?abxDisabled",
        onChange: "&abxOnChange",
      },
      controller: AbxRadioButtonGroupController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/radio-button-group/radio-button-group.component.html",
      transclude: true,
    });

  function AbxRadioButtonGroupController() {}
})();
