(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("ManagedPinFieldFilter", ManagedPinFieldFilterClassFactory);

  /**
   * @ngInject
   * @return The ManagedPinFieldFilter class
   */
  function ManagedPinFieldFilterClassFactory(
    FilterBarManager,
    ManagedFilterConfiguration
  ) {
    /**
     * @class
     * An individual filter configuration for a cross-pin-type pinField filter
     * query. Adds queries to the manager as pinField filters.
     * @param {FilterBarManager} manager - The manager that owns this configuration.
     * @param {object} config - Configuration options. See
     *  `ManagedFilterConfiguration` class for documentation of base class options.
     * @param {string} config.queryField - The field of the query that this filter
     *  modifies.
     */
    function ManagedPinFieldFilter(manager, config) {
      // super(manager, config);
      ManagedFilterConfiguration.call(this, manager, config);

      if (typeof config.queryField !== "string" || !config.queryField.length) {
        throw new Error("ManagedPinFieldFilter: queryField must be a string");
      }

      /** @member { string } */
      this.queryField = config.queryField;
    }
    // ManagedPinFieldFilter extends ManagedFilterConfiguration
    ManagedPinFieldFilter.prototype = Object.create(
      ManagedFilterConfiguration.prototype
    );
    ManagedPinFieldFilter.prototype.constructor = ManagedPinFieldFilter;

    ManagedPinFieldFilter.prototype.applyFilter = function (filterValue) {
      this.manager.addPinFieldFilter(this.queryField, filterValue);
    };

    ManagedPinFieldFilter.prototype.removeFilter = function () {
      this.manager.removePinFieldFilter(this.queryField);
    };

    ManagedPinFieldFilter.prototype.getFilterValue = function (manager) {
      return manager.getPinFieldFilter(this.queryField);
    };

    return ManagedPinFieldFilter;
  }
})();
