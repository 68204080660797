(function () {
  /**
   * Provides a class used internally to represent overrides for inspection
   * components.
   */
  angular
    .module("akitabox.core.ui.dialogs.inspectionProgram.create.overrideList")
    .factory("OverrideList", OverrideListClassFactory);
  function OverrideListClassFactory() {
    /** @class */
    function OverrideList(stops, checklistTemplate) {
      // PERF: we could add options to suppress diff-tracking since we don't use the diff
      // results of the largest lists (default list).
      this.checklistTemplate = checklistTemplate || null;
      this.stops = stops || [];
      this.selectedStops = {};
      this.checklistTemplateEnumOptions = [];
      this.diff = {
        add: [],
        remove: [],
      };
    }

    /**
     * @private
     * @param { "add" | "remove" } change
     * @param { RoundTemplateStop } stop
     */
    OverrideList.prototype._trackChange = function _trackChange(change, stop) {
      if (change !== "add" && change !== "remove") {
        throw new Error(
          "OverrideList._trackChange: Can only track 'add' or 'remove' events"
        );
      }
      /**
       * Remove a stop association from a list if it is present.
       * @param { RoundTemplateStop[] } list - Modified in place if removal is done
       * @param { RoundTemplateStop } stop
       * @return { boolean } true if an element was removed
       */
      function pruneExistingChange(list, stop) {
        var indexToRemove = list.findIndex(function (s) {
          // safely compare fields that may be populated, ids, or null
          function hasSame(modelField) {
            var newVal = stop[modelField];
            var existingVal = s[modelField];

            // null or id comparison
            if (!newVal || typeof newVal === "string") {
              return newVal === existingVal;
            }
            // both exist, newVal isn't a string, so check _id on each
            if (newVal && existingVal) {
              return newVal._id === existingVal._id;
            }
            return false;
          }
          return hasSame("asset") && hasSame("room") && hasSame("level");
        });
        if (indexToRemove === -1) {
          return false;
        } else {
          list.splice(indexToRemove, 1);
          return true;
        }
      }

      var list = this.diff[change];
      var otherList = this.diff["addremove".replace(change, "")];

      // if the complementary change is already tracked, just remove tracking for this stop entirely
      var pruned = pruneExistingChange(otherList, stop);
      if (!pruned) {
        // the complementary change is not already tracked, track this change
        list.push(stop);
      }
    };

    OverrideList.prototype.removeSelectedStops = function () {
      var self = this;
      var newStops = [];
      var removedStops = [];

      if (!Object.keys(self.selectedStops).length) {
        return [];
      }

      this.stops.forEach(function (stop) {
        var stopId = (stop.asset || stop.room || stop.level)._id;
        if (self.selectedStops[stopId]) {
          removedStops.push(stop);
          delete self.selectedStops[stopId];
          // PERF: making _trackChange work for batches would improve efficiency here
          // this loops over the entire "add" list each time
          self._trackChange("remove", stop);
        } else {
          newStops.push(stop);
        }
      });

      this.stops = newStops;
      return removedStops;
    };

    OverrideList.prototype.removeStop = function (stopToRemove) {
      var newStops = this.stops.filter(function (stop) {
        return getPinId(stop) !== getPinId(stopToRemove);
      });
      delete this.selectedStops[getPinId(stopToRemove)];
      if (newStops.length !== this.stops.length) {
        this._trackChange("remove", stopToRemove);
      }
      this.stops = newStops;
    };

    OverrideList.prototype.addStops = function (stops) {
      var i;
      var newStops = [];
      for (i = 0; i < this.stops.length; i++) {
        newStops.push(this.stops[i]);
      }
      for (i = 0; i < stops.length; i++) {
        newStops.push(stops[i]);
        // PERF: batching
        this._trackChange("add", stops[i]);
      }
      this.stops = newStops;
    };

    /**
     * Reset the list to a newly-initialized state with no diff tracked
     * and sets the stops to the provided array
     * @param { Array } stops
     */
    OverrideList.prototype.setInitialStops = function (stops) {
      this.diff = {
        add: [],
        remove: [],
      };
      var newStops = [];
      for (var i = 0; i < stops.length; i++) {
        newStops.push(stops[i]);
      }
      this.stops = newStops;
    };

    /**
     * Returns an object with an "add" and "remove" key that lists all changes made to the list
     * since initialization.
     * @returns { this["diff" ]}
     */
    OverrideList.prototype.getDiff = function () {
      return this.diff;
    };

    function getStopPin(stop) {
      var result = stop.asset || stop.room || stop.level;
      if (!result) {
        throw new Error("Stop has no location");
      }
      return result;
    }

    function getPinId(stop) {
      return getStopPin(stop)._id;
    }

    return OverrideList;
  }
})();
