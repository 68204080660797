(function () {
  angular.module("akitabox.ui.components.documentListInput", [
    "akitabox.ui.dialogs.document.editDocumentArray",
    "akitabox.ui.dialogs.document.upload",
    "akitabox.ui.dialogs.document.editTagFilter",
    "akitabox.ui.dialogs.filePreview",
    "akitabox.core.utils",
    "akitabox.core.services.filter",
    "akitabox.core.services.flag",
    "akitabox.core.services.tag",
    "akitabox.core.services.tagCategory",
    "akitabox.core.services.user",
    "akitabox.core.toast",
  ]);
})();
