(function () {
  angular.module("akitabox.ui.dialogs.document.editTagFilter", [
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.toast",
    "akitabox.core.services.asset",
    "akitabox.core.services.document",
    "akitabox.core.services.pinValue",
    "akitabox.core.services.room",
  ]);
})();
