(function () {
  /**
   * @ngdoc directive
   * @name abxExposeController
   * @description
   * Allows parent directives/components to consume child element's controllers.
   * Covers functionality similar to react's refs.
   * @param {Function} abxSetController - An angular expression that will
   *    be invoked with the controller as the named argument `ctrl`.
   * @example
   * <md-autocomplete
   *    abx-expose-controller
   *    abx-set-controller="vm.mdAutocompleteCtrl = ctrl"
   * ></md-autocomplete>
   */
  angular
    .module("akitabox.core.directives.exposeController", [])
    .directive("abxExposeController", AbxExposeScopeDirective);

  /** @ngInject */
  function AbxExposeScopeDirective($parse) {
    return {
      restrict: "A",
      link: {
        post: postLink,
      },
    };

    function postLink($scope, $element, $attrs) {
      // Get the controller for this element's component or element-directive.
      var desiredController = kebabToCamelCase($element[0].nodeName);
      var ctrl = $element.controller(desiredController);
      // Invoke the provided callback with the data.
      var parsedExpression = $parse($attrs.abxSetController);
      parsedExpression($scope, { ctrl: ctrl });
    }

    /**
     * Convert a lower-kebab-case-string to a camelCaseString.
     * @param {string} str - The string to convert
     * @return {string} The string, converted to camelcase.
     */
    function kebabToCamelCase(str) {
      return str.toLowerCase().replace(/-([a-z])/gi, function (_, firstLetter) {
        return firstLetter.toUpperCase();
      });
    }
  }
})();
