(function () {
  angular.module("akitabox.desktop.room.organizationList", [
    "akitabox.constants",
    "akitabox.core.constants",
    "akitabox.core.services.filter",
    "akitabox.core.services.floor",
    "akitabox.core.services.room",
    "akitabox.core.services.pin",
    "akitabox.ui.dialogs.export",
    "akitabox.desktop.components.filterBarManager",
  ]);
})();
