import useSWR, { SWRResponse } from "swr";

/**
 * Hook factory. Creates an {@link useSWR} hook from the provided function.
 * The hook takes in arguments to that function, and uses it as a fetcher
 * for SWR. All arguments are part of the cache key.
 *
 * @example
 * const MyComponent = () => {
 *   const { data, isLoading, error } = makeUseServiceCall(OrganizationService.get)({
 *     subdomain_key: "foo",
 *   });
 * }
 *
 * @note
 * You MUST destructure the fields you use off of the result from this call,
 * or risk the component not rerendering when data is finished loading. See
 * {@link https://swr.vercel.app/docs/advanced/performance#dependency-collection}
 */
export function makeUseServiceCall<A extends any[], R extends Promise<any>>(
  fn: (...args: A) => R,
  shouldFetch = true,
  refreshInterval?: number
): (...args: A) => SWRResponse<Awaited<R>, Error> {
  return (...args: A) =>
    useSWR<Awaited<R>, Error>(shouldFetch ? [fn, ...args] : null, fetcher, {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      refreshInterval,
    });
}

function fetcher([fn, ...args]: any[]): any {
  return fn(...args);
}
