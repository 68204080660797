(function () {
  angular
    .module("akitabox.ui.components.workOrderCard", [])
    .component("abxWorkOrderCard", {
      bindings: {
        headerIcon: "<abxHeaderIcon",
        status: "<abxStatus",
        loadOpen: "<abxLoadOpen",
        workOrders: "<abxWorkOrders",
        orderDescending: "<abxOrderDescending",
        getRowBuilding: "&?abxGetRowBuilding",
        getRowSubtext: "&?abxGetRowSubtext",
        onViewAll: "&?abxOnViewAll",
      },
      controller: AbxWorkOrderCardController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/work-order-card/work-order-card.component.html",
    });

  function AbxWorkOrderCardController() {
    var self = this;
    self.localGetRowBuilding = localGetRowBuilding;
    self.localGetRowSubtext = localGetRowSubtext;

    function localGetRowBuilding(workOrder) {
      var result = self.getRowBuilding({
        workOrder: workOrder,
      });
      return result;
    }

    function localGetRowSubtext(workOrder) {
      var result = self.getRowSubtext({
        workOrder: workOrder,
      });
      return result;
    }
  }
})();
