(function () {
  angular
    .module("akitabox.ui.dialogs.job.create")
    .controller("ImportJobDialogController", ImportJobDialogController);

  /**
   * @ngdoc controller
   * @module akitabox.ui.dialogs.job.create
   * @name ImportJobDialogController
   *
   * @description
   * Controller of the create import job ("Import Data") dialog
   *
   * @ngInject
   */
  function ImportJobDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    AdminJobService,
    ToastService
  ) {
    var self = this;

    // Counts for each selected file, used to determine possible duplicates
    var filenameCounts = {};
    var successful = [];

    // Attributes
    self.loading = true;
    self.creating = false;
    self.supportedFileTypes = [
      "text/csv",
      "text/plain",
      "application/vnd.ms-excel",
    ];
    self.importTypes = null;
    self.selected = [];
    self.file = null;
    self.type = null;

    // Functions
    self.close = close;
    self.remove = remove;
    self.submit = submit;
    self.onFileChanged = onFileChanged;
    self.onFileSelected = onFileSelected;
    self.onFileInvalid = ToastService.showError;
    self.onTypeChanged = onTypeChanged;
    self.onTypeSelected = onTypeSelected;
    self.isDuplicated = isDuplicated;

    init();

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handle selected import file changed
     *
     * @param {File}    file    New file
     * @param {Number}  $index  Index of the import selection that changed
     */
    function onFileChanged(file, $index) {
      if ($index < 0 || $index >= self.selected.length) return;
      var item = self.selected[$index];
      removeFileCount(item.file);
      item.file = file;
      addFileCount(file);
    }

    /**
     * Handle file selection
     *
     * @param {File}  file  Selected file
     */
    function onFileSelected(file) {
      self.file = file;
      if (self.type) addItem(self.file, self.type);
    }

    /**
     * Handle selected type change
     *
     * @param {Object} type     Selected type
     * @param {Object} $index   Selected index
     */
    function onTypeChanged(type, $index) {
      if ($index < 0 || $index >= self.selected.length) return;
      self.selected[$index].type = type;
    }

    /**
     * Handle type selected
     *
     * @param {Object} type Selected type
     */
    function onTypeSelected(type) {
      self.type = type;
      // If file is also selected, add type and file to list of imports
      if (self.file && self.type) {
        addItem(self.file, self.type);
      }
    }

    /**
     * Submit all selected and create jobs, one for each selected item
     */
    function submit() {
      if (self.creating) return;
      self.creating = true;
      var requests = [];
      for (var i = 0; i < self.selected.length; ++i) {
        var item = self.selected[i];
        var model = item.type.model;
        var pinType = item.type.pinType;
        var isCompletedWO = item.type.isCompletedWO;
        var data = {
          staircase_id: "import-staircase",
          file: item.file,
          data: {
            model: model,
            filters: {
              pinType: pinType ? pinType : null,
              isCompletedWO: isCompletedWO ? isCompletedWO : null,
            },
          },
        };
        requests.push(AdminJobService.create(null, self.building._id, data));
      }

      $q.all(requests)
        .then(function (jobs) {
          // If all jobs were created successfully, close dialog
          if (jobs.length === requests.length) {
            return $mdDialog.hide(jobs);
          }
          // Add successfully created jobs
          Array.prototype.push.apply(successful, jobs);
        })
        .catch(function (err) {
          ToastService.showError(err);
          // Remove successfully created items
          self.selected = self.selected.slice(successful.length);
        })
        .finally(function () {
          self.creating = false;
        });
    }

    /**
     * Remove a selected import item
     *
     * @param {Number} $index   Item index to remove
     */
    function remove($index) {
      if ($index < 0 || $index >= self.selected.length) return;
      var file = self.selected[$index].file;
      self.selected.splice($index, 1);
      if (isDuplicated(file)) {
        filenameCounts[file.name]--;
      } else {
        delete filenameCounts[file.name];
      }
    }

    /**
     * Close the dialog
     */
    function close() {
      // Allow successfully created jobs to be send to the calling controller
      if (successful.length) {
        $mdDialog.hide(successful);
      } else {
        $mdDialog.cancel();
      }
    }

    /**
     * Determine if a file is a duplicate, that is,
     * it already exists in the list of selected import files
     *
     * @param {File}   file    The file
     *
     * @return {Boolean}    Whether the file is a duplicate
     */
    function isDuplicated(file) {
      var count = filenameCounts[file.name];
      return count && count > 1;
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, watch import type
     */
    function init() {
      // Get import model types
      AdminJobService.getModels(
        self.building.organization,
        self.building._id,
        false
      )
        .then(function (models) {
          self.importTypes = models;
        })
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Add an import item
     *
     * @param {File}    file  File containing import data
     * @param {Object}  model Model to be imported
     */
    function addItem(file, model) {
      self.selected.push({
        file: file,
        type: model,
      });
      addFileCount(file);
      // Reset file selection
      self.file = null;
      self.type = null;
    }

    /**
     * Add file to filename counts
     *
     * @param {File}   file    The file to add
     */
    function addFileCount(file) {
      var count = filenameCounts[file.name];
      if (count) {
        filenameCounts[file.name]++;
      } else {
        filenameCounts[file.name] = 1;
      }
    }

    /**
     * Remove file from filename counts
     *
     * @param {File}    file     The file to remove
     */
    function removeFileCount(file) {
      var count = filenameCounts[file.name];
      if (count) {
        // Decrement the file count
        filenameCounts[file.name]--;
        // Remove if less than one
        if (filenameCounts[file.name] < 1) {
          delete filenameCounts[file.name];
        }
      }
    }
  }
})();
