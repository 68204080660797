(function () {
  angular
    .module("akitabox.ui.components.dashboard", [
      "akitabox.core.constants",
      "akitabox.core.lib.moment",
      "akitabox.core.services.organization",
      "akitabox.core.services.session",
      "akitabox.ui.components.buildingInfo",
      "akitabox.ui.components.dashboardStats",
      "akitabox.ui.components.dashboardTasks",
    ])
    .component("abxDashboard", {
      bindings: {
        buildings: "<abxBuildings",
      },
      controller: AbxDashboardController,
      controllerAs: "vm",
      templateUrl: "app/core/ui/components/dashboard/dashboard.component.html",
    });

  function AbxDashboardController(
    // Angular
    $scope,
    $rootScope,
    $interval,
    $q,
    // Lib
    moment,
    // Constants
    DASHBOARD_CARDS,
    // EVENTS
    EVENT_REFRESH_DASHBOARD,
    EVENT_REFRESH_DASHBOARD_STATS_COMPLETE,
    EVENT_REFRESH_DASHBOARD_WO_COMPLETE,
    EVENT_REFRESH_DASHBOARD_ERROR,
    // Services
    OrganizationService,
    SessionService
  ) {
    var self = this;

    // Constants
    var REFRESH_MINUTES = 2; // minutes between auto-refreshes
    var AUTO_REFRESH_TIME_IN_SECONDS = REFRESH_MINUTES * 60; // 2 minutes in seconds

    // Private Attributes
    var lastRefreshTime = moment(); // when we last performed an auto-refresh
    var pendingEventPromises = {}; // Used for tracking Promise by unique-ish ID

    // Public Attributes
    self.organization = OrganizationService.getCurrent();
    self.selectedBuilding = null;
    self.nextRefreshInDisp = REFRESH_MINUTES + " min.";
    self.refreshIntervalTimer;
    self.refreshingStats = false;
    self.buildingSelectOpen = false;
    self.visibility = {
      stats: false,
      tasks: false,
      buildingInfo: false,
    };
    self.open = {
      stats: true,
      work_summary: true,
      tasks: true,
      buildingInfo: true,
    };

    // Functions
    self.updateDashboard = updateDashboard;
    self.onCardToggled = onCardToggled;
    self.onSelectBuilding = onSelectBuilding;

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (self.organization.show_tasks) {
        startRefreshTimer();
      }
      // Get card states
      var cardStates = SessionService.getDashboardCardStates();
      var cards = Object.keys(DASHBOARD_CARDS);
      for (var i = 0; i < cards.length; ++i) {
        var card = DASHBOARD_CARDS[cards[i]];
        var state = cardStates[card];
        if (angular.isDefined(state)) {
          self.open[card] = angular.isDefined(state.visible)
            ? state.visible
            : true;
        }
      }
      // Set selected building
      if (self.buildings.length > 1) {
        var buildingInfoState = cardStates[DASHBOARD_CARDS.BUILDING_INFO] || {};
        var savedBuildingId = buildingInfoState.selectedBuilding;
        if (savedBuildingId) {
          for (var j = 0; j < self.buildings.length; ++j) {
            var building = self.buildings[j];
            if (building._id === savedBuildingId) {
              self.selectedBuilding = building;
              break;
            }
          }
        }
      }
      if (!self.selectedBuilding) {
        self.selectedBuilding = self.buildings[0];
      }
    };

    self.$onDestroy = function () {
      if (angular.isDefined(self.refreshIntervalTimer)) {
        $interval.cancel(self.refreshIntervalTimer);
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        self.visibility.empty = true;
        self.visibility.stats = false;
        self.visibility.tasks = false;
        self.visibility.buildingInfo = false;
        resetTimer();

        if (self.buildings.length) {
          self.visibility.empty = false;
          self.visibility.buildingInfo = true;

          var show_tasks = self.organization.show_tasks;

          self.visibility.stats = show_tasks;
          self.visibility.tasks = show_tasks;
        }
      }
    };

    function resolveEventPromise(ev, eventArgs) {
      if (
        eventArgs &&
        eventArgs.reqID &&
        pendingEventPromises[eventArgs.reqID]
      ) {
        var evProm = pendingEventPromises[eventArgs.reqID];
        evProm.eventCount += 1;
        if (evProm.needCount <= evProm.eventCount) {
          delete pendingEventPromises[eventArgs.reqID];
          evProm.resolve(eventArgs);
        }
      }
    }

    function rejectEventPromise(ev, eventArgs) {
      if (
        eventArgs &&
        eventArgs.reqID &&
        pendingEventPromises[eventArgs.reqID]
      ) {
        var evProm = pendingEventPromises[eventArgs.reqID];
        delete pendingEventPromises[eventArgs.reqID];
        evProm.reject(eventArgs);
      }
    }

    $scope.$on(EVENT_REFRESH_DASHBOARD_STATS_COMPLETE, resolveEventPromise);
    $scope.$on(EVENT_REFRESH_DASHBOARD_WO_COMPLETE, resolveEventPromise);
    $scope.$on(EVENT_REFRESH_DASHBOARD_ERROR, rejectEventPromise);

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Send an event to update the dashboard
     */
    function updateDashboard() {
      self.refreshingStats = true;
      var eventArgs = {
        reqID: Math.random(),
      };
      $rootScope.$broadcast(EVENT_REFRESH_DASHBOARD, eventArgs);
      resetTimer();
      return $q(function (resolve, reject) {
        pendingEventPromises[eventArgs.reqID] = {
          resolve: resolve,
          reject: reject,
          eventCount: 0,
          needCount: 2,
        };
      }).finally(refreshComplete);
    }

    /**
     * Update UI following successful refresh
     */
    function refreshComplete() {
      resetTimer();
      self.refreshingStats = false;
    }

    function onCardToggled(cardName) {
      if (
        cardName === DASHBOARD_CARDS.BUILDING_INFO &&
        self.buildingSelectOpen
      ) {
        return true; // prevent card toggle
      }
      var open = !self.open[cardName];
      self.open[cardName] = open;
      var cardStates = SessionService.getDashboardCardStates();
      var currentState = cardStates[cardName] || {};
      angular.extend(currentState, { visible: open });
      cardStates[cardName] = currentState;
      // FIXME: (BB) remove, sync old card with new card visibility
      // work summary saves the date range or dates if custom
      // stats saves individual dates and is not compatible w/ work summary
      if (
        !Object.prototype.hasOwnProperty.call(
          cardStates,
          DASHBOARD_CARDS.WORK_SUMMARY
        )
      ) {
        cardStates[DASHBOARD_CARDS.WORK_SUMMARY] = {};
      }
      if (
        !Object.prototype.hasOwnProperty.call(cardStates, DASHBOARD_CARDS.STATS)
      ) {
        cardStates[DASHBOARD_CARDS.STATS] = {};
      }
      cardStates[DASHBOARD_CARDS.STATS].visible =
        cardStates[DASHBOARD_CARDS.WORK_SUMMARY].visible;
      SessionService.setDashboardCardStates(cardStates);
    }

    /**
     * Set selected building and save ID dashboard cards session state
     */
    function onSelectBuilding($event) {
      self.selectedBuilding = $event.model;
      var cardStates = SessionService.getDashboardCardStates();
      var buildingInfoState = cardStates[DASHBOARD_CARDS.BUILDING_INFO] || {};
      angular.extend(buildingInfoState, {
        selectedBuilding: self.selectedBuilding._id,
      });
      cardStates[DASHBOARD_CARDS.BUILDING_INFO] = buildingInfoState;
      SessionService.setDashboardCardStates(cardStates);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Reset the timer
     */
    function resetTimer() {
      lastRefreshTime = moment();
    }

    /**
     * Start an auto refresh timer that will send an
     * EVENT_REFRESH_DASHBOARD when the timer hits 0.
     */
    function startRefreshTimer() {
      // Start an interval timer that fires every second
      self.refreshIntervalTimer = $interval(function () {
        if (self.refreshingStats) {
          return;
        }
        var now = new moment();
        var elap = now.diff(lastRefreshTime, "seconds");

        if (elap >= AUTO_REFRESH_TIME_IN_SECONDS) {
          updateDashboard();
          return;
        }

        var secLeft = lastRefreshTime.diff(
          now.clone().subtract(REFRESH_MINUTES, "minutes"),
          "seconds"
        );
        if (secLeft >= 60) {
          self.nextRefreshInDisp =
            Math.max(Math.ceil(secLeft / 60), 1) + " min.";
        } else if (secLeft <= 10) {
          self.nextRefreshInDisp = Math.max(1, Math.round(secLeft)) + " sec.";
        } else {
          self.nextRefreshInDisp = "less than a min.";
        }
      }, 250);
    }
  }
})();
