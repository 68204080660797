(function () {
  angular.module("akitabox.desktop.floor").run(stateBlock);

  /* @ngInject */
  function stateBlock(models, Router) {
    var LIST_QUERY_PARAMS = models.FLOOR.QUERY_PARAMS.join("&");
    Router.configureStates([
      {
        state: "app.building.floors",
        config: {
          url: "/" + models.FLOOR.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/floor/building-list/floor-list.html",
          controller: "FloorListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floors",
          },
          reloadOnSearch: false,
        },
      },
      {
        state: "app.building.floor",
        config: {
          url: "/" + models.FLOOR.ROUTE_PLURAL + "/:floorId",
          templateUrl: "app/desktop/modules/floor/detail/floor-detail.html",
          controller: "FloorDetailController",
          controllerAs: "vm",
          resolve: {
            floor: getFloor,
            redirect: redirect,
          },
        },
      },
      {
        state: "app.floors",
        config: {
          url: "/" + models.FLOOR.ROUTE_PLURAL + "?" + LIST_QUERY_PARAMS,
          templateUrl:
            "app/desktop/modules/floor/organization-list/org-floor-list.html",
          controller: "OrganizationFloorListController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floors",
          },
          reloadOnSearch: false,
        },
      },
      {
        state: "app.floor",
        config: {
          url: "/" + models.FLOOR.ROUTE_PLURAL + "/:floorId",
          templateUrl: "app/desktop/modules/floor/detail/floor-detail.html",
          controller: "FloorDetailController",
          controllerAs: "vm",
          resolve: {
            floor: getOrganizationFloor,
            building: getBuilding,
            redirect: redirect,
          },
        },
      },
    ]);
  }

  /** @ngInject */
  function getOrganizationFloor(
    $stateParams,
    account,
    organization,
    FloorService
  ) {
    return FloorService.getByOrganization(organization._id, {
      _id: $stateParams.floorId,
    }).then(function (floor) {
      return floor;
    });
  }

  /** @ngInject */
  function getBuilding($stateParams, account, floor, BuildingService) {
    return BuildingService.getById(floor.building);
  }

  /** @ngInject */
  function getFloor($stateParams, account, building, FloorService) {
    return FloorService.getById(building._id, $stateParams.floorId);
  }

  /** @ngInject */
  function redirect(
    $stateParams,
    $state,
    account,
    organization,
    building,
    floor,
    RedirectService
  ) {
    var destination = RedirectService.getDestination();
    var floorBuildingState = organization.show_tasks
      ? "app.building.floor.overview"
      : "app.building.floor.details";
    var floorOrgState = organization.show_tasks
      ? "app.floor.overview"
      : "app.floor.details";
    if (
      destination.match(
        /^.*\/(closed-work-orders|open-work-orders|maintenance-schedules)$/
      )
    ) {
      if (!organization.show_tasks) {
        return $state.go("root.404");
      }
    } else if (
      destination.match(/^\/([a-zA-Z0-9_]*\/){2}levels\/[a-zA-Z0-9]+$/)
    ) {
      return $state.go(floorBuildingState, $stateParams);
    } else if (destination.match(/\/levels\/[a-zA-Z0-9]+$/)) {
      return $state.go(floorOrgState, $stateParams);
    }
  }
})();
