(function () {
  angular
    .module("akitabox.desktop.request.detail")
    .controller("RequestDetailsTabController", RequestDetailsTabController);

  /* @ngInject */
  function RequestDetailsTabController($timeout, building, request) {
    var self = this;

    self.request = request;
    self.building = building;
    self.rendering = true;

    $timeout(function () {
      self.rendering = false;
    }, 1000);
  }
})();
