(function () {
  angular
    .module("akitabox.ui.dialogs.room.create")
    .service("CreateRoomDialog", CreateRoomDialog);

  /* @ngInject */
  function CreateRoomDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/room/create/create-room.dialog.html",
      controller: "CreateRoomDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      focusOnOpen: false,
      multiple: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * CreateRoomDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param  {Object}    options                       Dialog options
     * @param  {Object}    [options.locals.building]     Building to create room under
     * @param  {Object}    [options.locals.floor]        A single floor object
     * @param  {Object}    [options.locals.hideToast]    Option to hide the toast that displays when the room is created
     * @return {Promise<Object[]|Error>}                 Promise that resolves with the newly created rooms.
     *                                                   Their pin types will come as populated
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
