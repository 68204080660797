(function () {
  angular
    .module("akitabox.desktop.directives.detailHeader", [
      "akitabox.core.router",
    ])
    .directive("abxDetailHeader", AbxDetailHeader);

  /**
   * @ngdoc directive
   * @module akitabox.mobile.directives.detail
   * @name AbxDetailHeaderDirective
   *
   * @restrict E
   *
   * @description
   * `<abx-detail-header>` is the header and tabs for an item detail page.
   *
   * @usage
   *  <abx-detail-header
   *    abx-building="vm.building"
   *    abx-heading="vm.workOrder.subject"
   *    abx-model="vm.workOrder"
   *    abx-model-type="workOrder"
   *    abx-subtitle="vm.subtitle"
   *    abx-tabs="vm.tabs">
   *  </abx-detail-header>
   *
   * @ngInject
   */
  function AbxDetailHeader() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/detail-header/detail-header.html",
      controller: AbxDetailHeaderController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=abxBuilding",
        heading: "=abxHeading",
        model: "=abxModel",
        modelType: "@abxModelType",
        subtitle: "=abxSubtitle",
        tabs: "=abxTabs",
        tabsOnly: "=?abxTabsOnly",
      },
    };
  }

  /**
   * @ngdoc controller
   * @module akitabox.mobile.directives.detailHeader
   * @name AbxDetailHeaderController
   *
   * @description
   * Controller for the detail header directive
   *
   * @ngInject
   */
  function AbxDetailHeaderController(
    $rootScope,
    $scope,
    $timeout,
    $element,
    Router,
    Utils
  ) {
    var self = this;

    // Attributes
    self.activeTab = 0;

    // Functions
    self.onSelect = onSelect;

    init();

    var deregisterStateChangeSuccess = $rootScope.$on(
      "$stateChangeSuccess",
      setActiveTab
    );

    $rootScope.$on("$destroy", deregisterStateChangeSuccess);

    $scope.$watchCollection("vm.tabs", setActiveTab);

    function onSelect(tab) {
      Router.go(tab.state);
    }

    function init() {
      // Set active tab
      setActiveTab();
    }

    function setActiveTab() {
      self.activeTab = 0;
      for (var i = 0; i < self.tabs.length; ++i) {
        var tab = self.tabs[i];
        var isActive = Utils.isStateActive({ name: tab.state });
        var isRelated = Utils.isStateActive({
          name: tab.state.replace(/^app\./, "app.building."),
        });
        if (isActive || isRelated) {
          self.activeTab = i;
        }
      }
      $timeout(function () {
        var $items = $element.find("li");
        if ($items.length) {
          var tab = $items[self.activeTab];
          angular.element(tab.children[0]).removeClass("md-unselected");
        }
      });
    }
  }
})();
