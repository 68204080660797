(function () {
  angular
    .module("akitabox.core.services.costLineTask", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("CostLineTaskService", CostLineTaskService);

  /* @ngInject */
  function CostLineTaskService(
    // AkitaBox
    models,
    HttpService
  ) {
    var service = {
      // Routes
      buildListRoute: buildListRoute,
      // Retrieve
      get: get,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------
    function buildListRoute(buildingId) {
      return (
        "/buildings/" + buildingId + "/" + models.COST_LINE_TASK.ROUTE_PLURAL
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Get an array of cost line tasks within a building
     *
     * @param {String} buildingId     the building id in which to fetch cost line tasks
     * @param {Object} params              an object of query string parameters
     */
    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }
  }
})();
