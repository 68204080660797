(function () {
  angular
    .module("akitabox.ui.dialogs.delete")
    .controller("DeleteDialogController", DeleteDialogController);

  /* @ngInject */
  function DeleteDialogController(
    // Angular
    $q,
    $window,
    // Material
    $mdDialog,
    // Utils
    ServiceHelpers,
    // Services
    AssemblyService,
    AssetService,
    EnvService,
    OrganizationService,
    RequestService,
    RoundTemplateService,
    RoomService,
    ScheduleService,
    ToastService,
    WorkOrderService,
    // Constants
    MAINTENANCE_SCHEDULE_STATUS_ACTIVE,
    MAINTENANCE_SCHEDULE_STATUS_CANCELED,
    SERVICE_REQUEST_STATUS_NEW,
    SERVICE_REQUEST_STATUS_OPEN,
    SERVICE_REQUEST_STATUS_DENIED,
    WORK_ORDER_STATUS_OPEN,
    WORK_ORDER_STATUS_SCHEDULED,
    WORK_ORDER_STATUS_COMPLETED,
    WORK_ORDER_STATUS_CANCELED
  ) {
    var self = this;

    // fields
    /**
     * @prop {Asset} [asset] - the Asset that is being deleted
     */
    /**
     * @prop {Room} [room] - the Room that is being deleted
     */
    /**
     * @prop {('asset'|'room')} modelType - Type of model for easy access in template html
     */
    self.modelType = self.asset ? "asset" : "room";
    /**
     * @prop {Object} count - holds all the counts needed to delete
     */
    self.count = {
      activeAsset: 0,
      decommissionedAsset: 0,
      newRequest: 0,
      openRequest: 0,
      deniedRequest: 0,
      openTask: 0,
      scheduledTask: 0,
      completedTask: 0,
      canceledTask: 0,
      activeSchedule: 0,
      canceledSchedule: 0,
      roundTemplate: 0,
      assembly: 0,
    };

    /**
     * @prop {string} assemblyId - the ID of the linked assembly (if one exists)
     */
    self.assemblyId = null;

    /**
     * @prop {string} roomDisplayName - the full room name, [room_number] - [room_name]
     */
    self.roomDisplayName = "...";

    /**
     * @prop {boolean} saving - Whether the dialog is currently saving
     */
    self.saving = false;
    /**
     * @prop {boolean} loading - Whether the dialog is currently loading
     */
    self.loading = true;

    /**
     * @prop {string} buildingId - the ID of the building the room/asset is in
     */
    self.buildingId = self.asset ? self.asset.building : self.room.building._id;

    /**
     * @prop {Organization} organization - The org the user belongs to
     */
    self.organization = OrganizationService.getCurrent();

    /**
     * @prop {boolean} showRoundTemplates - flag to indicate if RoundTemplates should be considered during deletion
     */
    self.showRoundTemplates = self.organization.show_inspections;

    /**
     * @prop {boolean} assembliesEnabled - flag to indicate if Assemblies should be considered durring deletion
     */
    self.assembliesEnabled = self.organization.show_rpi_fci;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.disabled = disabled;
    self.deleteRoomAsset = deleteRoomAsset;
    self.onClickHandler = onClickHandler;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      // Get the room's display name if needed
      if (self.room) {
        // TODO: use self.room.display_name
        ServiceHelpers.getRoomDisplayName(self.room)
          .then(function (roomDisplayName) {
            self.roomDisplayName = roomDisplayName;
          })
          .catch(function () {
            // default to the room number if we can't get the full name
            self.roomDisplayName = self.room.number;
          });
      }

      /* Create a config array to fetch & display associated items */
      self.associatedItems = [];
      var countRequests = [];

      if (self.room) {
        // Active Assets
        countRequests.push(getActiveAssetCount());
        self.associatedItems.push({
          title: "Active Assets",
          countParam: "activeAsset",
          onClick: function () {
            self.onClickHandler("assets", null, false);
          },
        });
        // Decommissioned Assets
        countRequests.push(getdecommissionedAssetCount());
        self.associatedItems.push({
          title: "Decommissioned Assets",
          countParam: "decommissionedAsset",
          onClick: function () {
            self.onClickHandler("assets", null, true);
          },
        });
      }

      if (self.organization.show_tasks) {
        // New Service Requests
        countRequests.push(getServiceRequestCount(SERVICE_REQUEST_STATUS_NEW));
        self.associatedItems.push({
          title: "New Service Requests",
          countParam: "newRequest",
          onClick: function () {
            self.onClickHandler("requests", "new");
          },
        });
        // Open Service Requests
        countRequests.push(getServiceRequestCount(SERVICE_REQUEST_STATUS_OPEN));
        self.associatedItems.push({
          title: "Open Service Requests",
          countParam: "openRequest",
          onClick: function () {
            self.onClickHandler("requests", "open");
          },
        });
        // Denied Service Requests
        countRequests.push(
          getServiceRequestCount(SERVICE_REQUEST_STATUS_DENIED)
        );
        self.associatedItems.push({
          title: "Denied Service Requests",
          countParam: "deniedRequest",
          onClick: function () {
            self.onClickHandler("requests", "denied");
          },
        });
        // Open Work Orders
        countRequests.push(getWorkOrderCount(WORK_ORDER_STATUS_OPEN));
        self.associatedItems.push({
          title: "Open Work Orders",
          countParam: "openTask",
          onClick: function () {
            self.onClickHandler("tasks", "open");
          },
        });
        // Scheduled Work Orders
        countRequests.push(getWorkOrderCount(WORK_ORDER_STATUS_SCHEDULED));
        self.associatedItems.push({
          title: "Scheduled Work Orders",
          countParam: "scheduledTask",
          onClick: function () {
            self.onClickHandler("tasks", "scheduled");
          },
        });
        // Completed Work Orders
        countRequests.push(getWorkOrderCount(WORK_ORDER_STATUS_COMPLETED));
        self.associatedItems.push({
          title: "Completed Work Orders",
          countParam: "completedTask",
          onClick: function () {
            self.onClickHandler("tasks", "completed");
          },
        });
        // Canceled Work Orders
        countRequests.push(getWorkOrderCount(WORK_ORDER_STATUS_CANCELED));
        self.associatedItems.push({
          title: "Canceled Work Orders",
          countParam: "canceledTask",
          onClick: function () {
            self.onClickHandler("tasks", "canceled");
          },
        });

        // Active Maintenance Schedules
        countRequests.push(
          getScheduleCount(MAINTENANCE_SCHEDULE_STATUS_ACTIVE)
        );
        self.associatedItems.push({
          title: "Active Maintenance Schedules",
          countParam: "activeSchedule",
          onClick: function () {
            self.onClickHandler("future_tasks", "active");
          },
        });
        // Canceled Maintenance Schedules
        countRequests.push(
          getScheduleCount(MAINTENANCE_SCHEDULE_STATUS_CANCELED)
        );
        self.associatedItems.push({
          title: "Canceled Maintenance Schedules",
          countParam: "canceledSchedule",
          onClick: function () {
            self.onClickHandler("future_tasks", "canceled");
          },
        });
      }

      if (self.showRoundTemplates) {
        // Round Templates
        countRequests.push(getRoundTemplateCount());
        self.associatedItems.push({
          title: "Round Templates",
          countParam: "roundTemplate",
          onClick: function () {
            // url filtering for round templates doesn't work
            // the best we can do is take the user to the round template list
            var url = EnvService.getOrgSettingsUrl(
              "/organization/settings/round_templates",
              self.organization.subdomain_key
            );
            $window.open(url, "_blank");
          },
        });
      }

      if (self.assembliesEnabled && self.asset) {
        // Assemblies
        countRequests.push(getAssembly());
        self.associatedItems.push({
          title: "Assemblies",
          countParam: "assembly",
          onClick: function () {
            // url filtering for assmeblies doesn't work
            // the best we can do is take the user to the assembly list
            var url;
            if (!self.assemblyId) {
              url = EnvService.getCmUrl(
                "/cm/assemblies",
                self.organization.subdomain_key
              );
            } else {
              url = EnvService.getCmUrl(
                "/cm/assemblies/" + self.assemblyId,
                self.organization.subdomain_key
              );
            }
            $window.open(url, "_blank");
          },
        });
      }

      // Get all the necessary counts
      $q.all(countRequests)
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Grabs a count of all the active assets within this building
     * for the attached Room
     * @returns {Promise<void>}
     */
    function getActiveAssetCount() {
      if (!self.room) return;

      var query = {
        count: true,
        deleted_date: null,
        decommissioned: false,
        room: self.room._id,
      };

      // useCache is false because we always want this list to be nice and fresh
      return AssetService.getAll(self.buildingId, query, false)
        .then(function (response) {
          self.count.activeAsset = response.count;
        })
        .catch(ToastService.showError);
    }

    /**
     * Grabs a count of all the active assets within this building
     * for the attached Room
     * @returns {Promise<void>}
     */
    function getdecommissionedAssetCount() {
      if (!self.room) return;

      var query = {
        count: true,
        deleted_date: null,
        decommissioned: true,
        room: self.room._id,
      };

      // useCache is false because we always want this list to be nice and fresh
      return AssetService.getAll(self.buildingId, query, false)
        .then(function (response) {
          self.count.decommissionedAsset = response.count;
        })
        .catch(ToastService.showError);
    }

    /**
     * Grabs a count of all the new service requests within this building
     * for the attached Room/Asset
     * @returns {Promise<void>}
     */
    function getServiceRequestCount(status) {
      var query = {
        count: true,
        status: status,
      };

      if (self.room) {
        query.room = self.room._id;
      } else {
        query.asset = self.asset._id;
      }

      // useCache is false because we always want this list to be nice and fresh
      return RequestService.getAll(self.buildingId, query, false)
        .then(function (response) {
          self.count[status + "Request"] = response.count;
        })
        .catch(ToastService.showError);
    }

    /**
     * Grabs a count of all the work orders with {status} within this building
     * for the attached Room/Asset
     * @param   {('open'|'scheduled')} status - The status of the work orders we're counting
     * @returns {Promise<void>}
     */
    function getWorkOrderCount(status) {
      var query = {
        count: true,
        status: status,
      };

      if (self.room) {
        query.room = self.room._id;
      } else {
        query.asset = self.asset._id;
      }

      // useCache is false because we always want this list to be nice and fresh
      return WorkOrderService.getAllByBuildingId(self.buildingId, query, false)
        .then(function (response) {
          self.count[status + "Task"] = response.count;
        })
        .catch(ToastService.showError);
    }

    /**
     * Grabs a count of all the active schedules within this building
     * for the attached Room/Asset
     * @returns {Promise<void>}
     */
    function getScheduleCount(status) {
      var query = {
        count: true,
        status: status,
      };

      if (self.room) {
        query.room = self.room._id;
      } else {
        query.asset = self.asset._id;
      }

      // useCache is false because we always want this list to be nice and fresh
      return ScheduleService.getAll(self.buildingId, query, false)
        .then(function (response) {
          self.count[status + "Schedule"] = response.count;
        })
        .catch(ToastService.showError);
    }

    /**
     * Grabs a count of all the active assets within this building
     * for the attached Room
     * @returns {Promise<void>}
     */
    function getRoundTemplateCount() {
      var query = {
        count: true,
        status: "active",
        stops: [],
      };

      if (self.room) {
        query.stops.push({ room: self.room._id });
      } else if (self.asset) {
        query.stops.push({ asset: self.asset._id });
      }

      if (!self.showRoundTemplates || !query.stops.length) return;

      // useCache is false because we always want this list to be nice and fresh
      return RoundTemplateService.getAll(self.organization._id, query, false)
        .then(function (response) {
          self.count.roundTemplate = response.count;
        })
        .catch(ToastService.showError);
    }

    /**
     * Grabs a all the assemblies within this org
     * for the attached Asset
     * @returns {Promise<void>}
     */
    function getAssembly() {
      var query = {
        delete_date: null,
      };
      if (self.asset) {
        query.associated_assets = self.asset._id;
      } else {
        // assembly count not necessary for rooms
        return;
      }

      // useCache is false because we always want this list to be nice and fresh
      return AssemblyService.getByOrganization(
        self.organization._id,
        query,
        false
      )
        .then(function (response) {
          self.count.assembly = response ? response.length : 0;
          self.assemblyId =
            response && response.length ? response[0]._id : null;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handles the user click on the {modelType} item. Sends them to the {modelType}?status={status} page
     * @param {('requests'|'tasks')}       modelType - the type of model we're going to
     * @param {('new'|'open'|'scheduled')} status    - the status of the model we're going to
     */
    function onClickHandler(modelType, status, decommissioned) {
      var id = self.asset ? self.asset._id : self.room._id;
      var url =
        EnvService.getCurrentBaseUrl() +
        "/buildings/" +
        self.buildingId +
        "/" +
        modelType +
        "?" +
        self.modelType +
        "=" +
        id;
      if (status) {
        url += "&status=" + status;
      }
      if (decommissioned !== null && decommissioned !== undefined) {
        url += "&decommissioned=" + decommissioned;
      }
      // do the actual navigate here
      $window.open(url, "_blank");
    }

    /**
     * Returns whether or not the 'delete' button should be disabled based
     * on the counts we have
     * @returns {boolean}
     */
    function disabled() {
      for (var key in self.count) {
        if (self.count[key] > 0) {
          return true;
        }
      }
      return false;
    }

    /**
     * Deletes the attached room/asset
     * @returns {Promise<void>}
     */
    function deleteRoomAsset() {
      // Safety check, delete button should be disabled if disabled() returns true
      if (disabled()) {
        return ToastService.showError(
          "All associated items must be handled before deletion"
        );
      }

      self.saving = true;
      var promise;
      var id = self.asset ? self.asset._id : self.room._id;

      if (self.room) {
        promise = RoomService.deleteRoom;
      } else {
        promise = AssetService.deleteAsset;
      }

      return promise(self.buildingId, id)
        .then(function () {
          $mdDialog.hide(id);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
