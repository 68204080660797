(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.step
   */
  angular
    .module("akitabox.ui.directives.step", [
      "ui.router",
      "ngMaterial",
      "angular.filter",
      "akitabox.core.router",
      "akitabox.core.services.document",
      "akitabox.core.services.job",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.document.download",
      "akitabox.ui.dialogs.job.viewStepLog",
    ])
    .controller("AbxStepController", AbxStepController)
    .directive("abxStep", AbxStepDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.step
   * @name AbxStepDirective
   *
   * @restrict E
   *
   * @description
   * `<abx-step>` is a component that displays step information including
   * status, order, name, and duration. By default, this component will
   * refresh the step information every few seconds.
   *
   * @param {Boolean} abx-no-refresh  If provided, the step will not auto-refresh
   *
   * @usage
   * <hljs lang="html">
   *   <abx-step ng-model="vm.step" abx-job="vm.job" [abx-no-refresh]></abx-step>
   * </hljs>
   *
   * @ngInject
   */
  function AbxStepDirective(
    // Angular
    $filter,
    $interval,
    // Services
    AttachmentService,
    AdminJobService,
    ToastService
  ) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/step/step.html",
      require: ["abxStep", "ngModel"],
      controller: "AbxStepController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        building: "=abxBuilding",
        type: "=abxType",
      },
    };

    function postLink($scope, $element, attrs, controllers) {
      // Constants
      var UPDATE_INTERVAL = 3000; // 3 seconds
      var ONE_MINUTE = 60 * 1000;
      var ONE_HOUR = 60 * ONE_MINUTE;
      var MIN_HEIGHT = 48;
      var MAX_HEIGHT = 160;
      var CLASS_FADED = "faded-log";

      // Controllers
      var vm = controllers[0];
      var ngModelCtrl = controllers[1];

      // Whether or not to auto-refresh
      var noRefresh = angular.isDefined(attrs.abxNoRefresh);

      // Interval promises
      var updateInterval;

      // Height watcher
      var cancelHeightWatcher = $scope.$watch(function () {
        return $element[0].clientHeight;
      }, evaluateHeight);

      // UI elements
      var $duration = $element.find("time");

      // Attributes
      vm.step = null;
      vm.active = false;

      init();

      // ------------------------
      //   Private Functions
      // ------------------------

      /**
       * Initialize the directive
       */
      function init() {
        $element.addClass(CLASS_FADED);
        // Listen for external model changes
        ngModelCtrl.$formatters.push(onExternalChange);
        // Add $destroy event handler
        $scope.$on("$destroy", onDestroy);
      }

      function evaluateHeight(height) {
        if (!vm.step) return;
        if (height > MIN_HEIGHT && height < MAX_HEIGHT) {
          $element.removeClass(CLASS_FADED);
        } else if (height > MIN_HEIGHT) {
          cancelHeightWatcher();
          $element.addClass(CLASS_FADED);
        }
      }

      /**
       * Handle external model changes
       *
       * @param  {*} value    New model value
       * @return {*}          Formatted model value
       */
      function onExternalChange(value) {
        if (!value) return value;
        vm.step = value;
        // Update attributes
        updateStatus();
        updateDuration();
        // Refresh intervals
        refreshIntervals();
        return value;
      }

      /**
       * Handle scope $destroy event
       */
      function onDestroy() {
        if (updateInterval) $interval.cancel(updateInterval);
      }

      /**
       * Update job
       */
      function updateStep() {
        AdminJobService.getStep(vm.step.parent_job, vm.step._id)
          .then(function (step) {
            vm.step = step;
            ngModelCtrl.$setViewValue(
              angular.extend(ngModelCtrl.$modelValue, vm.step)
            );
            updateStatus();
            updateDuration();
            refreshIntervals();
          })
          .catch(ToastService.showError);
      }

      /**
       * Update status and active based on new step
       */
      function updateStatus() {
        vm.active =
          vm.step.status === "queued" || vm.step.status === "processing";
        if (vm.step.status === "completed" && vm.type === "export") {
          var params = {
            entity_id: vm.step._id,
            entity_type: "step",
          };
          AttachmentService.getAll(vm.building._id, params)
            .then(function (attachments) {
              if (!angular.isEmpty(attachments)) {
                var documents = [];
                for (var i = 0; i < attachments.length; i += 1) {
                  documents.push(attachments[i].document);
                }
                vm.document = documents[0];
              }
            })
            .catch(ToastService.showError);
        }
      }

      /**
       * Refresh intervals and determine, based on visibility and status,
       * which ones should start or stop
       */
      function refreshIntervals() {
        if (!vm.step || noRefresh) return;
        // Update step
        if (vm.active) {
          startUpdateInterval();
        } else {
          stopUpdateInterval();
        }
      }

      /**
       * Update job duration element
       */
      function updateDuration() {
        if (vm.step.is_queued) return;
        // Build timestamp
        var format;
        if (vm.step.process_duration < 1000) {
          format = "sss'ms'";
        } else if (vm.step.process_duration < ONE_MINUTE) {
          format = "s's'";
        } else if (vm.step.process_duration < ONE_HOUR) {
          format = "m'm' s's'";
        } else {
          format = "h'h' m'm' s's'";
        }
        var filtered = $filter("duration")(vm.step.process_duration, format);
        $duration.attr("datetime", filtered);
        $duration.html(filtered);
      }

      /**
       * Start the job update interval
       */
      function startUpdateInterval() {
        if (!updateInterval) {
          updateInterval = $interval(updateStep, UPDATE_INTERVAL);
        }
      }

      /**
       * Stop the job update interval
       */
      function stopUpdateInterval() {
        if (updateInterval) {
          $interval.cancel(updateInterval);
          updateInterval = null;
        }
      }
    }
  }

  /**
   * @ngdoc Controller
   * @module akitabox.ui.directives.step
   *
   * @description
   * Controller for abx-step
   *
   * @ngInject
   */
  function AbxStepController(
    Router,
    ToastService,
    DownloadDocumentDialog,
    ViewStepLogDialog
  ) {
    var self = this;

    // Functions
    self.viewStepLog = viewStepLog;
    self.download = download;
    self.goToCreatedJob = goToCreatedJob;

    /**
     * Open step log dialog
     */
    function viewStepLog() {
      var locals = { step: self.step };
      ViewStepLogDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    /**
     * Download step document
     */
    function download() {
      var locals = {
        docs: [self.document],
      };
      DownloadDocumentDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    /**
     * Goes to job detail of job created by step
     */
    function goToCreatedJob() {
      var building = self.step.data.building;

      Router.go("app.building.admin.job", {
        buildingId: building,
        jobId: self.step.data.created_job,
      });
    }
  }
})();
