(function () {
  angular.module("akitabox.desktop.document.detail", [
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.core.services.document",
    "akitabox.core.services.flag",
    "akitabox.core.services.note",
    "akitabox.core.services.tag",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "akitabox.ui.dialogs.document.archive",
    "akitabox.ui.dialogs.document.delete",
    "akitabox.ui.dialogs.document.download",
    "akitabox.ui.dialogs.document.revert",
    "akitabox.ui.dialogs.document.tag",
  ]);
})();
