(function () {
  angular
    .module("akitabox.ui.components.reportingFilterBar", [
      "720kb.tooltips",
      "akitabox.core.constants",
      "akitabox.core.services.building",
      "akitabox.core.services.trade",
      "akitabox.core.services.type",
      "akitabox.core.services.organization",
      "akitabox.core.services.shadow",
      "akitabox.core.services.user",
    ])
    .component("abxReportingFilterBar", {
      bindings: {
        onChange: "&abxOnChange",
        disabled: "<?abxDisabled",
        taskFiltersDisabled: "<?abxTaskFiltersDisabled",
        filters: "<?abxFilters",
      },
      controller: AbxReportingFilterBarController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-filter-bar/reporting-filter-bar.component.html",
    });
  function AbxReportingFilterBarController(
    // Constants
    PRIORITIES,
    // Angular
    $q,
    // Services
    BuildingService,
    TradeService,
    TypeService,
    OrganizationService,
    ShadowService,
    UserService
  ) {
    var self = this;

    // Private attributes
    var GA_CATEGORY = "reporting";
    var tradeRequest = null;
    var assigneeRequest = null;
    var typeRequest = null;

    // transform values from the model
    // used for setting chip values to email addresses
    // or setting _ids for the query string
    var TRANSFORMS = {
      displayNameTransform: function (model) {
        return model.identity.display_name;
      },
      idTransform: function (model) {
        return model.identity._id;
      },
    };
    var ASSIGNEE_SOURCES = [
      {
        publicAlias: "Assignee",
        filterType: "assignee",
      },
      {
        publicAlias: "Work Performed By",
        filterType: "work performed by",
      },
    ];
    self.filterTypes = {
      assignee: ASSIGNEE_SOURCES[0].filterType,
      assigneeSource: "assigneeSource", // value will be from ASSIGNEE_SOURCES
      priority: "priority",
      trade: "trade",
      type: "type",
    };

    // Public attributes
    self.organization = OrganizationService.getCurrent();
    self.building = BuildingService.getCurrent();
    self.filterChips = [];
    self.selectedFilters = {};
    self.assigneeOptions = null;
    self.priorityOptions = PRIORITIES;
    self.assigneeSourceOptions = ASSIGNEE_SOURCES.map(function (as) {
      return as.publicAlias;
    });
    self.tradeOptions = null;
    self.typeOptions = null;

    // Functions
    self.onClearAll = onClearAll;
    self.removeFilter = removeFilter;
    self.fetchAssignees = fetchAssignees;
    self.onAssigneeFilterChange = onAssigneeFilterChange;
    self.onChangeAssigneeFilterSource = onChangeAssigneeFilterSource;
    self.onPriorityFilterChange = onPriorityFilterChange;
    self.onTradeFilterChange = onTradeFilterChange;
    self.onTypeFilterChange = onTypeFilterChange;

    // =================
    // Lifecycle
    // =================

    self.$onInit = function () {
      self.filters = self.filters || {};

      if (!self.building) {
        self.taskFiltersDisabled = false;
        return;
      }
      // Initialize selected filters
      var filterTypeKeys = Object.keys(self.filterTypes);
      for (var i = 0; i < filterTypeKeys.length; ++i) {
        var filterKey = filterTypeKeys[i];
        if (filterKey === "assigneeSource") {
          self.selectedFilters[self.filterTypes[filterKey]] = self.filters
            .workers
            ? ASSIGNEE_SOURCES[1]
            : ASSIGNEE_SOURCES[0];
        } else {
          self.selectedFilters[self.filterTypes[filterKey]] = [];
        }
      }
      // Fetch stuff
      fetchAssignees();
      fetchTrades();
      fetchTypes();

      return $q
        .all([assigneeRequest, tradeRequest, typeRequest])
        .then(function () {
          applyFilters();
        });
    };

    self.$onChanges = function (changes) {
      if (changes.taskFiltersDisabled) {
        setChipVisibility();
      }
      if (changes.taskFiltersDisabled && self.building) {
        typeRequest = null;
        fetchTypes();
      }
    };

    // =================
    // Public Functions
    // =================

    function onAssigneeFilterChange($event) {
      var filterType = self.filterTypes.assignee;
      var model = $event ? $event.model : self.selectedFilters[filterType];
      var filters = model.map(function (assignee) {
        var value = TRANSFORMS.displayNameTransform(assignee);
        return {
          type: filterType,
          model: assignee,
          value: value,
          disabled: self.taskFiltersDisabled,
        };
      });
      handleFilterChange(
        "assignee",
        model.map(function (m) {
          return m.identity._id;
        })
      );
      self.selectedFilters[filterType] = angular.extend([], model);
      updateFilters(filterType, filters);
    }

    function onChangeAssigneeFilterSource($event) {
      var filterType = self.filterTypes.assigneeSource;
      var model = $event.model;

      // Get the proper Assignee Source object
      var assigneeSource = ASSIGNEE_SOURCES.find(function (as) {
        return as.publicAlias === model;
      });

      // Copy existing models from old filter type to new filter type
      var oldFilterType = self.filterTypes.assignee;
      var newFilterType = assigneeSource.filterType;
      self.selectedFilters[newFilterType] = self.selectedFilters[oldFilterType];
      self.selectedFilters[oldFilterType] = [];

      // Change which collection the "Assignee" filter is referencing
      self.selectedFilters[filterType] = assigneeSource;
      self.filterTypes.assignee = newFilterType;

      // refresh filters
      updateFilters(oldFilterType, []);
      self.onAssigneeFilterChange();
    }

    function onPriorityFilterChange($event) {
      var filterType = self.filterTypes.priority;
      var model = $event.model;
      var filters = model.map(function (priority) {
        return {
          type: filterType,
          model: priority,
          value: priority,
          disabled: self.taskFiltersDisabled,
        };
      });
      handleFilterChange("priority", model);
      self.selectedFilters[filterType] = angular.extend([], model);
      updateFilters(filterType, filters);
    }

    function onTradeFilterChange($event) {
      var model = $event.model;
      var filterType = self.filterTypes.trade;
      var filters = model.map(function (trade) {
        return {
          type: filterType,
          model: trade,
          value: trade.name,
          disabled: self.taskFiltersDisabled,
        };
      });
      handleFilterChange(
        "trade",
        model.map(function (m) {
          return m.name;
        })
      );
      self.selectedFilters[filterType] = angular.extend([], model);
      updateFilters(filterType, filters);
    }

    function onTypeFilterChange($event) {
      var model = $event.model;
      var filterType = self.filterTypes.type;
      var filters = model.map(function (type) {
        return {
          type: filterType,
          model: type,
          value: type.name,
        };
      });
      handleFilterChange(
        "type",
        model.map(function (m) {
          return m.name;
        })
      );
      self.selectedFilters[filterType] = angular.extend([], model);
      updateFilters(filterType, filters);
    }

    function onClearAll() {
      self.filterChips = [];
      var filterTypeKeys = Object.keys(self.filterTypes);
      ShadowService.sendEvent(GA_CATEGORY, "clear-filters", null, null);
      for (var i = 0; i < filterTypeKeys.length; i++) {
        var key = filterTypeKeys[i];
        var type = self.filterTypes[key];
        if (key === "assigneeSource") {
          self.selectedFilters[type] = ASSIGNEE_SOURCES[0];
        } else {
          self.selectedFilters[type] = [];
        }
        updateFilters(type, []);
      }
    }

    function removeFilter(chip) {
      var filterType = chip.type;

      ShadowService.setSelectedOption(chip.value);
      ShadowService.sendEvent(GA_CATEGORY, "remove-filter", chip.type);

      var selected = self.selectedFilters[filterType];
      if (selected.length === 1) {
        self.selectedFilters[filterType] = [];
        updateFilters(filterType, []);
      } else {
        var compare;
        var onChange;
        switch (filterType) {
          case self.filterTypes.assignee:
            onChange = onAssigneeFilterChange;
            compare = function (chip, option) {
              return chip.value === option.identity.display_name;
            };
            break;
          case self.filterTypes.priority:
            onChange = onPriorityFilterChange;
            compare = function (chip, option) {
              return chip.value.toLowerCase() === option.toLowerCase();
            };
            break;
          case self.filterTypes.trade:
            onChange = onTradeFilterChange;
            compare = function (chip, option) {
              return chip.value === option.name;
            };
            break;
          case self.filterTypes.type:
            onChange = onTypeFilterChange;
            compare = function (chip, option) {
              return chip.value === option.name;
            };
            break;
          default:
            onChange = function () {};
            compare = function () {
              return false;
            };
            break;
        }
        var index = -1;
        // Find the filter option
        for (var i = 0; i < selected.length; ++i) {
          if (compare(chip, selected[i])) {
            index = i;
            break;
          }
        }
        // Remove the filter option
        if (index > -1) {
          selected.splice(index, 1);
          var $event = { invalid: false, model: selected };
          onChange($event);
        }
      }
    }

    function fetchAssignees() {
      if (assigneeRequest) {
        return assigneeRequest;
      }
      assigneeRequest = UserService.getAll(self.organization._id, {
        buildings: self.building._id,
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
        // status: "active", may want to filter on deactivated user
      }).then(function (users) {
        self.assigneeOptions = users.map(function (user) {
          return {
            model: user,
            value: user.identity.display_name,
          };
        });
      });

      return assigneeRequest;
    }

    // =================
    // Private Functions
    // =================
    function handleFilterChange(filterType, newModel) {
      var prev = self.selectedFilters[filterType];
      var action = false;
      var value = false;
      if (prev.length > newModel.length) {
        action = "remove-filter";
        var delta = prev.filter(function (m) {
          return newModel.indexOf(m) < 0;
        });
        if (delta.length > 0) {
          value = delta[0];
        }
      } else {
        action = "add-filter";
        value = newModel[newModel.length - 1];
      }

      if (action && value) {
        gaFilterEvent(action, filterType, value);
      }
    }

    function gaFilterEvent(eventType, filterType, filterDimension) {
      if (typeof filterDimension !== "undefined") {
        ShadowService.setSelectedOption(filterDimension);
      }
      ShadowService.sendEvent(GA_CATEGORY, eventType, filterType);
    }

    function makeChipable(type, filter) {
      var model;
      var value;
      var disabled = false;
      switch (type) {
        case "work performed by":
        case "assignee":
          model = filter.model;
          value = TRANSFORMS.displayNameTransform(filter.model);
          disabled = self.taskFiltersDisabled;
          break;
        case "priority":
          model = filter;
          value = filter;
          disabled = self.taskFiltersDisabled;
          break;
        case "type":
          model = filter.model;
          value = filter.value;
          // not disabled
          break;
        case "trade":
        default:
          model = filter.model;
          value = filter.value;
          disabled = self.taskFiltersDisabled;
          break;
      }
      return {
        type: type,
        value: value,
        model: model,
        disabled: disabled,
      };
    }

    function lookupBy(type, field, value, subField) {
      var options;
      switch (type) {
        case self.filterTypes.assignee:
          options = self.assigneeOptions;
          break;
        case self.filterTypes.type:
          options = self.typeOptions;
          break;
        case self.filterTypes.trade:
          options = self.tradeOptions;
          break;
        case self.filterTypes.priority:
          options = [];
          var keys = Object.keys(self.priorityOptions);
          for (var k = 0; k < keys.length; ++k) {
            var key = keys[k];
            options.push(self.priorityOptions[key]);
          }
          value = value[0].toUpperCase() + value.slice(1).toLowerCase();
          break;
        default:
          throw new Error("Unsupported type: " + type);
      }
      for (var i = 0; i < options.length; ++i) {
        var opt = options[i];
        if (field) {
          if (opt.model[field] === value) {
            return opt;
          }
          if (subField) {
            if (opt.model[field][subField] === value) {
              return opt;
            }
          }
        } else if (opt.model) {
          if (opt.model === value) {
            return opt;
          }
        } else if (opt === value) {
          return opt;
        }
      }
      return;
    }

    function makeFilterProcessingFunction(type, lookupField, subField) {
      return function (data) {
        if (!angular.isArray(data)) {
          data = [data];
        }
        return data
          .map(function (f) {
            // find by key field
            return lookupBy(type, lookupField, f, subField);
          })
          .filter(function (f) {
            // remove missing items
            return typeof f !== "undefined";
          })
          .map(function (f) {
            // prepare for use as a chip
            return makeChipable(type, f);
          });
      };
    }

    function mapField(arr, field) {
      return arr.map(function (f) {
        return f[field];
      });
    }

    function applyFilters() {
      var filters = Object.keys(self.filters);
      var filteredAssignees;
      filters.forEach(function (name) {
        switch (name) {
          case "assignees":
            self.filterTypes.assignee = ASSIGNEE_SOURCES[0].filterType;
            filteredAssignees = makeFilterProcessingFunction(
              "assignee",
              "identity",
              "_id"
            )(self.filters.assignees);
            self.selectedFilters.assignee = mapField(
              filteredAssignees,
              "model"
            );
            updateFilters("assignee", filteredAssignees);
            break;
          case "workers":
            self.filterTypes.assignee = ASSIGNEE_SOURCES[1].filterType;
            var filteredWorkers;
            filteredWorkers = makeFilterProcessingFunction(
              "work performed by",
              "identity",
              "_id"
            )(self.filters.workers);
            self.selectedFilters["work performed by"] = mapField(
              filteredWorkers,
              "model"
            );
            updateFilters("work performed by", filteredWorkers);
            break;
          case "type_name":
            var filteredTypes = makeFilterProcessingFunction(
              "type",
              "name"
            )(self.filters.type_name);
            self.selectedFilters.type = mapField(filteredTypes, "model");
            updateFilters("type", filteredTypes);
            break;
          case "trade_name":
            var filteredTrades = makeFilterProcessingFunction(
              "trade",
              "name"
            )(self.filters.trade_name);
            self.selectedFilters.trade = mapField(filteredTrades, "model");
            updateFilters("trade", filteredTrades);
            break;
          case "priority":
            var filteredPriorities = makeFilterProcessingFunction(
              "priority",
              null
            )(self.filters.priority);
            self.selectedFilters.priority = mapField(
              filteredPriorities,
              "model"
            );
            updateFilters("priority", filteredPriorities);
            break;
          default:
            throw new Error("Unsupported type: " + name);
        }
      });
    }

    function setChipVisibility() {
      for (var i = 0; i < self.filterChips.length; ++i) {
        var chip = self.filterChips[i];
        var type = chip.type;
        chip.disabled =
          self.taskFiltersDisabled && type !== self.filterTypes.type; // yo dawg, heard you liked types
      }
    }

    function fetchTrades() {
      if (tradeRequest) {
        return tradeRequest;
      }
      tradeRequest = TradeService.getAll(self.building._id).then(function (
        trades
      ) {
        self.tradeOptions = trades.map(function (trade) {
          return {
            model: trade,
            value: trade.name,
          };
        });
      });

      return tradeRequest;
    }

    function fetchTypes(options) {
      if (typeRequest) {
        return typeRequest;
      }
      typeRequest = TypeService.getTypes(self.building).then(function (types) {
        var typeNames = [];
        // Filter out duplicate names (for now...)
        var filtered = types.filter(function (type) {
          if (typeNames.indexOf(type.name) < 0) {
            typeNames.push(type.name);
            return true;
          }
          return false;
        });
        self.typeOptions = filtered.map(function (type) {
          return {
            model: type,
            value: type.name,
          };
        });
      });

      return typeRequest;
    }

    function updateFilters(filterType, filters) {
      // Update filter chips
      self.filterChips = self.filterChips.filter(function (chip) {
        return chip.type !== filterType;
      });
      Array.prototype.push.apply(self.filterChips, filters);
      // Send change event
      var $event = {};

      // update filters that will be passed to the API
      if (filters.length) {
        var transform;
        switch (filterType) {
          case "assignee":
            transform = function (filter) {
              return TRANSFORMS.idTransform(filter.model);
            };
            break;
          case "work performed by":
            transform = function (filter) {
              return TRANSFORMS.idTransform(filter.model);
            };
            break;
          case "priority":
            transform = function (filter) {
              return filter.model.toLowerCase();
            };
            break;
          case "trade":
            transform = function (filter) {
              return filter.model.name;
            };
            break;
          case "type":
          default:
            transform = function (filter) {
              return filter.model.name;
            };
            break;
        }
        $event[filterType] = filters.map(transform);
      } else {
        $event["task." + filterType] = [];
        $event[filterType] = [];
      }
      self.onChange({ $event: $event });
    }
  }
})();
