(function () {
  angular.module("akitabox.ui.dialogs.delete", [
    "ngMaterial",
    "akitabox.core.services.env",
    "akitabox.core.services.flag",
    "akitabox.core.services.helpers",
    "akitabox.core.services.organization",
    "akitabox.core.services.request",
    "akitabox.ui.components.dateInput",
    "akitabox.ui.components.preloaders",
  ]);
})();
