(function () {
  angular
    .module("akitabox.core.services.qrcode", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "akitabox.core.services.env",
      "akitabox.core.services.flag",
    ])
    .factory("QRCodeService", QRCodeService);

  /** @ngInject */
  function QRCodeService(models, HttpService, $q, EnvService) {
    var service = {
      getByUrl: getByUrl,
      getById: getById,
      associate: associate,
      disassociate: disassociate,
      replace: replace,
      parse: parse,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute() {
      return models.QR_CODE.ROUTE_SINGULAR;
    }

    function buildDetailRoute(codeId) {
      var base = buildBaseRoute();
      return base + "/" + codeId;
    }

    function buildListRoute() {
      return buildBaseRoute();
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function getByUrl(redirectURL) {
      var params = { redirect_uri: redirectURL };
      var route = buildListRoute();
      return HttpService.get(route, params);
    }

    function getById(id, params) {
      var route = buildDetailRoute(id);
      return HttpService.getById(route, id, params);
    }

    /**
     * @param {String} existingCode - The `_id` or `_code` of the existing QR
     *     code for the model
     * @param {String} newCode - The `_id` or `_code` of the new QR code to
     *     associate with the model
     */
    function replace(existingCode, newCode, association) {
      if (existingCode && newCode) {
        return disassociate(existingCode).then(function () {
          return associate(newCode, association).catch(function (err) {
            // Reverse the disassociation, since the entire replacement action
            // did not succeed
            associate(existingCode, association);
            return $q.reject(err);
          });
        });
      } else if (existingCode && !newCode) {
        return disassociate(existingCode);
      } else {
        return associate(newCode, association);
      }
    }

    /**
     * @param {String} newCode - The `_id` or `_code` of the new QR code to
     *     associate with the model
     */
    function associate(codeId, association) {
      var route = buildDetailRoute(codeId);
      return HttpService.put(
        route,
        angular.extend({ action: "associate" }, association)
      );
    }

    /**
     * @param {String} codeId - The `_id` or `_code` of the QR code to
     *     disassociate
     */
    function disassociate(codeId) {
      var route = buildDetailRoute(codeId);
      return HttpService.put(route, {
        action: "disassociate",
      });
    }

    /**
     * Parse QR Code for path and url
     *
     * @param {Organization} organization
     * @param {QRCode} qrCode
     *
     * @return {Object} containing model, path, url, subdomain, domain
     */
    function parse(qrCode) {
      if (qrCode && qrCode.is_associated) {
        var redirectURL = qrCode.redirect_url;
        var model = qrCode.asset || qrCode.room || qrCode.task;
        var domainParts = EnvService.extractDomainParts(redirectURL);
        var subdomain = domainParts.subdomain;
        var domain = domainParts.domain + "." + domainParts.suffix;

        var mobilePath = "/m" + model.uri;
        var mobileUrl = "";
        if (subdomain) {
          mobileUrl = subdomain + ".";
        }
        mobileUrl += domain + mobilePath;

        var port = domainParts.port;
        if (port) {
          mobileUrl = mobileUrl.replace(port, "3008");
        }

        return {
          model: model,
          path: model.uri,
          url: redirectURL,
          subdomain: subdomain,
          mobilePath: mobilePath,
          mobileUrl: mobileUrl,
        };
      }

      return null;
    }
  }
})();
