(function () {
  /**
   * AbxContentEditable behavioral directive.
   *
   * Conditionally adds the contenteditable attribute to an element
   * based on an expression.
   *
   * Evaluates a defined expression when you blur or press enter.
   */

  angular
    .module("akitabox.core.directives.editable", [])
    .directive("abxContentEditable", AbxEditable);

  var ENTER_KEY_CODE = 13;

  function AbxEditable() {
    return {
      restrict: "A",
      scope: {
        editable: "=abxContentEditable",
        onBlur: "&?abxOnBlur",
        onEnter: "&?abxOnEnter",
      },
      link: function ($scope, $element) {
        if ($scope.onBlur) {
          $element.on("blur", function () {
            if ($scope.editable) {
              $scope.onBlur({ $value: $element.text() });
            }
          });

          $element.on("keydown keypress", function ($event) {
            if ($scope.editable && $event.which === ENTER_KEY_CODE) {
              $scope.onBlur({ $value: $element.text() });
              $event.preventDefault();

              if ($scope.onEnter) {
                $scope.onEnter();
              }
            }
          });
        }

        $scope.$watch("editable", function (value) {
          if (value) {
            $element.attr("contenteditable", true);
            $element[0].focus();
          } else {
            $element.removeAttr("contenteditable");
          }
        });
      },
    };
  }
})();
