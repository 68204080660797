(function () {
  angular.module("akitabox.ui.dialogs.workOrder", [
    "akitabox.ui.dialogs.workOrder.cancel",
    "akitabox.ui.dialogs.workOrder.complete",
    "akitabox.ui.dialogs.workOrder.create",
    "akitabox.ui.dialogs.workOrder.reopen",
    "akitabox.ui.dialogs.workOrder.reschedule",
    "akitabox.ui.dialogs.workOrder.delete",
    "akitabox.ui.dialogs.workOrder.detail",
    "akitabox.ui.dialogs.assign.assign",
  ]);
})();
