(function () {
  angular
    .module("akitabox.ui.dialogs.schedule.delete")
    .factory("DeleteScheduleDialog", DeleteScheduleDialog);

  /* @ngInject */
  function DeleteScheduleDialog($log, $q, $mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/schedule/delete/delete-schedule.dialog.html",
      controller: "DeleteScheduleDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
       * Show dialog
       *
       * @param  {Object}                 options                       Dialog options
       * @param  {Object}                 options.locals                Dialog options
       * @param  {Object}                 options.locals.building       Building model
       * @param  {Object}                 options.locals.shedule        Schedule to delete
  
       * @return {Promise<Object|Error>} - Promise that resolves when schedule successfully deleted
       */
    function show(options) {
      // Verify we have schedule local
      if (
        !options ||
        !options.locals ||
        !options.locals.schedule ||
        !options.locals.schedule.building
      ) {
        $log.error(
          "DeleteScheduleDialog: you must pass in schedule via locals"
        );
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
