(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller("ScheduleFloorTabController", ScheduleFloorTabController);

  /* @ngInject */
  function ScheduleFloorTabController(
    // Angular
    $timeout,
    // Resolves
    building,
    schedule,
    // Services
    FloorService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.rendering = true;
    self.building = building;
    self.schedule = schedule;
    self.floor = null;

    init();

    function init() {
      var floorId = schedule.level._id;
      FloorService.getById(building._id, floorId)
        .then(function (floor) {
          self.floor = floor;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
          $timeout(function () {
            self.rendering = false;
          }, 500);
        });
    }
  }
})();
