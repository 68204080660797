(function () {
  angular
    .module("akitabox.ui.dialogs.job.create")
    .service("CreateJobDialog", CreateJobDialog);

  /* @ngInject */
  function CreateJobDialog($mdDialog, $log, $q, $mdMedia) {
    var base = {
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      base.fullscreen = true;
    }

    var dialogs = {
      script: angular.extend({}, base, {
        templateUrl:
          "app/core/ui/dialogs/job/create/script/create-script.dialog.html",
        controller: "CreateScriptDialogController",
      }),
      export: angular.extend({}, base, {
        templateUrl:
          "app/core/ui/dialogs/job/create/export/export-job.dialog.html",
        controller: "ExportJobDialogController",
      }),
      import: angular.extend({}, base, {
        templateUrl:
          "app/core/ui/dialogs/job/create/import/import-job.dialog.html",
        controller: "ImportJobDialogController",
      }),
      accountImport: angular.extend({}, base, {
        templateUrl:
          "app/core/ui/dialogs/job/create/account-import/account-import-job.dialog.html",
        controller: "AccountImportJobDialogController",
      }),
      accountExport: angular.extend({}, base, {
        templateUrl:
          "app/core/ui/dialogs/job/create/account-export/account-export-job.dialog.html",
        controller: "AccountExportJobDialogController",
      }),
      dev: angular.extend({}, base, {
        templateUrl: "app/core/ui/dialogs/job/create/dev/dev-job.dialog.html",
        controller: "DevJobDialogController",
      }),
    };

    /**
     * CreateJobDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog, `options.locals.building` is required
     *
     * @param  {String}                 type                    Type of job
     * @param  {Object}                 options                 Dialog options
     * @return {Promise<Object|Error}                           Promise that resolves with the newly created job
     */
    function show(type, options) {
      if (!Object.prototype.hasOwnProperty.call(dialogs, type)) {
        $log.error("CreateJobDialog: invalid type: " + type);
        return $q.reject("Sorry, we weren't able to complete your request.");
      }
      var dialog = angular.extend({}, dialogs[type], options);
      return $mdDialog.show(dialog);
    }
  }
})();
