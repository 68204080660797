(function () {
  angular
    .module("akitabox.planView.dialogs.legacy-fac-filter")
    .service("LegacyFacFilterDialog", LegacyFacFilterDialog);

  /* @ngInject */
  function LegacyFacFilterDialog(AlertDialog) {
    var title = "We've Made Some Changes";
    var message =
      "Our URL links look a little different than they used to. That means your old bookmarks won’t work. Don’t worry though! You can use the filter bar on the left side of the screen to set the rooms and assets you’d like to see. You can still bookmark the filters once they’re set, just like before!";
    var locals = {
      title: title,
      textContent: message,
    };

    /**
     * LegacyFacFilterDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @return {Promise<Object|Error} LegacyFacFilterDialog dialog promise
     */
    function show() {
      return AlertDialog.show({ locals: locals });
    }
  }
})();
