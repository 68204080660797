(function () {
  angular
    .module("akitabox.core.services.costLine", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("CostLineService", CostLineService);

  /* @ngInject */
  function CostLineService(
    // Angular
    $q,
    // AkitaBox
    models,
    HttpService
  ) {
    var service = {
      // Routes
      buildListRoute,
      // Retrieve
      get: get,
      getOneByAsset: getOneByAsset,
      getByAssetNameBFF: getByAssetNameBFF,
      getRSMeansCategories: getRSMeansCategories,
      getRSMeansCostLines: getRSMeansCostLines,
      getRSMeansBaseDivisions,
      getRSMeansDivisionsChildren,
      // Update
      updateById: updateById,
      // Create
      create: create,
      remove,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------
    function buildListRoute(buildingId) {
      return "/buildings/" + buildingId + "/" + models.COST_LINE.ROUTE_PLURAL;
    }

    function buildDetailRoute(buildingId, costLineId) {
      return `${buildListRoute(buildingId)}/${costLineId}`;
    }

    function buildBFFListRoute(organizationId) {
      return "/organizations/" + organizationId + "/web/fca_cost_lines";
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Get an array of assemblies within a building
     *
     * @param {String} buildingId     the building id in which to fetch assemblies
     * @param {Object} params              an object of query string parameters
     */
    function get(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getOneByAsset(asset) {
      var buildingId = asset.building;
      var route = buildListRoute(buildingId);
      var params = {
        asset: asset._id,
      };
      return HttpService.get(route, params).then(function (costLines) {
        if (costLines.length) {
          return costLines[0];
        } else {
          return null;
        }
      });
    }

    function getByAssetNameBFF(organizationId, assetName) {
      var route = buildBFFListRoute(organizationId);
      var params = {
        name: assetName,
      };
      return HttpService.get(route, params);
    }

    function remove(buildingId, costLineId) {
      const route = buildDetailRoute(buildingId, costLineId);
      return HttpService.remove(route);
    }

    function getRSMeansCategories(buildingId, uniformat) {
      if (!buildingId) {
        return $q.reject("No building id provided");
      } else if (!uniformat) {
        return $q.reject("No uniformat provided");
      }

      var route =
        "/buildings/" +
        buildingId +
        "/costdata/fmr/divisions/" +
        uniformat +
        "/children";

      return HttpService.get(route);
    }

    function getRSMeansCostLines(buildingId, uniformat) {
      if (!buildingId) {
        return $q.reject("No building id provided");
      } else if (!uniformat) {
        return $q.reject("No uniformat provided");
      }

      var route =
        "/buildings/" +
        buildingId +
        "/costdata/fmr/divisions/" +
        uniformat +
        "/cost_lines";

      return HttpService.get(route);
    }

    function getRSMeansBaseDivisions(buildingId, catalogType) {
      if (!buildingId) {
        return $q.reject(new Error("No building id provided"));
      } else if (!catalogType) {
        return $q.reject(new Error("No catalog type provided"));
      }

      var route =
        "/buildings/" + buildingId + "/costdata/" + catalogType + "/divisions";

      return HttpService.get(route);
    }

    function getRSMeansDivisionsChildren(buildingId, catalogType, uniformat) {
      if (!buildingId) {
        return $q.reject(new Error("No building id provided"));
      } else if (!catalogType) {
        return $q.reject(new Error("No catalog type provided"));
      } else if (!uniformat) {
        return $q.reject(new Error("No uniformat provided"));
      }

      var route =
        "/buildings/" +
        buildingId +
        "/costdata/" +
        catalogType +
        "/divisions/" +
        uniformat +
        "/children";

      return HttpService.get(route);
    }

    function create(buildingId, params) {
      const route = buildListRoute(buildingId);
      return HttpService.post(route, params);
    }

    function updateById(buildingId, costLineId, data, params = {}) {
      const route = buildDetailRoute(buildingId, costLineId);
      return HttpService.patch(route, data, params);
    }
  }
})();
