import { useEffect, useRef } from "react";
import { Observer } from "./Observer";
import { Subject } from "./Subject";

/**
 * React hook for subscribing to a {@link Subject} from within a component.
 * @param subject The subject to subscribe to
 * @param observerOrNext The observer or next function to fire when a message
 *  is published on the subject.
 */

export const useSubject = <T>(
  subject: Subject<T>,
  observerOrNext: Observer<T> | Observer<T>["next"]
) => {
  // use a ref here so that we don't close over `observerOrNext` incorrectly
  // this way, if the function changes, it doesn't really impact anything
  // for this hook. No unnecessary subscribing/unsubscribing.
  const observerRef = useRef(observerOrNext);
  observerRef.current = observerOrNext;
  // register a proxy observer that just invokes the latest observer
  // we've received
  useEffect(
    () =>
      subject.subscribe({
        next: (v) => {
          if (typeof observerRef.current === "function") {
            observerRef.current(v);
          } else {
            observerRef.current.next(v);
          }
        },
      }),
    [subject]
  );
};
