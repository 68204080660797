(function () {
  angular.module("akitabox.desktop.floor.detail").run(states);

  /* @ngInject */
  function states(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/floor/detail/overview/floor-overview.html",
          controller: "FloorOverviewTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Overview",
          },
        };
      },
      assets: function () {
        return {
          url: "/assets",
          templateUrl:
            "app/desktop/modules/floor/detail/assets/floor-assets.html",
          controller: "FloorAssetsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Assets",
          },
        };
      },
      closedWorkOrders: function () {
        return {
          url: "/closed-work-orders",
          templateUrl:
            "app/desktop/modules/floor/detail/closed-work-orders/floor-closed-work-orders.html",
          controller: "FloorClosedWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Closed WOs",
          },
        };
      },
      details: function () {
        return {
          url: "/details",
          templateUrl:
            "app/desktop/modules/floor/detail/details/floor-details.html",
          controller: "FloorDetailsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Details",
          },
        };
      },
      schedules: function () {
        return {
          url: "/maintenance-schedules",
          templateUrl:
            "app/desktop/modules/floor/detail/schedules/floor-schedules.html",
          controller: "FloorSchedulesTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Schedules",
          },
        };
      },
      openWorkOrders: function () {
        return {
          url: "/open-work-orders",
          templateUrl:
            "app/desktop/modules/floor/detail/open-work-orders/floor-open-work-orders.html",
          controller: "FloorOpenWorkOrdersTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Open WOs",
          },
        };
      },
      rooms: function () {
        return {
          url: "/rooms",
          templateUrl:
            "app/desktop/modules/floor/detail/rooms/floor-rooms.html",
          controller: "FloorRoomsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Floor Rooms",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.floor.overview",
        config: config.overview(),
      },
      {
        state: "app.building.floor.assets",
        config: config.assets(),
      },
      {
        state: "app.building.floor.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.building.floor.details",
        config: config.details(),
      },
      {
        state: "app.building.floor.schedules",
        config: config.schedules(),
      },
      {
        state: "app.building.floor.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.building.floor.rooms",
        config: config.rooms(),
      },
      {
        state: "app.floor.overview",
        config: config.overview(),
      },
      {
        state: "app.floor.assets",
        config: config.assets(),
      },
      {
        state: "app.floor.closedWorkOrders",
        config: config.closedWorkOrders(),
      },
      {
        state: "app.floor.details",
        config: config.details(),
      },
      {
        state: "app.floor.schedules",
        config: config.schedules(),
      },
      {
        state: "app.floor.openWorkOrders",
        config: config.openWorkOrders(),
      },
      {
        state: "app.floor.rooms",
        config: config.rooms(),
      },
    ]);
  }
})();
