(function () {
  /**
   * @ngdoc component
   * @name abxPinDetailSidebar
   *
   * @param {Function} onClose - Function called on sidebar close.
   * @param {Function} onUpdate - Function called on pin update.
   * @param {Object} pin - Pin to display and be responsible for.
   * @param {Object} pin.pinType - Populated pin type for the pin. Required.
   * @param {Object[]} [pin.values] - Pin's pin values. If omitted, this
   *     component will display a loading state for all fields while fetching
   *     their values.
   * @param {Function} setMode - updates the PlanView's current mode
   * @param {Boolean} isShown - Used to show/hide the sidebar
   *
   * @fires pin:duplicate Indicates the desire to duplicate a pin
   *
   * @description
   * Sidebar display for the details of a pin.
   *
   * NOTE: Will currently disable floor inputs for all pins.
   */
  angular.module("akitabox.planView").component("abxPinDetailSidebar", {
    bindings: {
      onClose: "&abxOnClose",
      onPinUpdate: "&abxOnPinUpdate",
      pin: "<?abxPin",
      setPlacePinMode: "&?abxSetPlacePinMode",
      onPinDelete: "&abxOnPinDelete",
      setMode: "&abxSetMode",
      isShown: "<?abxIsShown",
      versionMode: "<abxVersionMode",
      onAttachmentUploadStart: "<?abxOnAttachmentUploadStart",
      onAttachmentUploadsFinished: "<?abxOnAttachmentUploadsFinished",
      direction: "@?abxDirection",
      classes: "<?abxClasses",
      hiddenActions: "<?abxHiddenActions",
    },
    controller: AbxPinDetailSidebarController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/pin-detail-sidebar/pin-detail-sidebar.component.html",
  });

  /* @ngInject */
  function AbxPinDetailSidebarController(
    // Angular
    $mdSidenav,
    $q,
    $scope,
    $rootScope,
    $timeout,
    $location,
    // Constants
    COMPONENT_STATE,
    EVENT_PIN_DUPLICATE,
    EVENT_PIN_REFRESH,
    MODES,
    PV_GA,
    VERSION_MODES,
    // Dialogs
    AlertDialog,
    DeleteAssetDialog,
    DeleteRoomDialog,
    // Services
    Router,
    ServiceHelpers,
    ShadowService,
    UserService,
    ToastService
  ) {
    var self = this;

    var permissions = UserService.getPermissions();
    var isUploadingAttachment = false;

    // Constants
    var ROOM_PROTECTED_TYPE = "Room";
    var ASSET_PROTECTED_TYPE = "Asset";
    var MINIMUM_RENDER_TIME = 750; // Minumium amount of time to show loading state (ms)

    // Attributes
    self.currentVersion = true;
    self.disabledFieldTypes = {};
    self.MODES = MODES;
    self.state = COMPONENT_STATE.loading;
    self.permissions = getPermissions();
    self.detailsLink = null;
    self.planViewLink = null;
    self.direction = self.direction || "left";
    self.classes = self.classes || {};
    if (!("sidenav" in self.classes)) self.classes.sidenav = "";
    self.hiddenActions = self.hiddenActions || {};
    if (!("move" in self.hiddenActions)) self.hiddenActions.move = false;
    if (!("delete" in self.hiddenActions)) self.hiddenActions.delete = false;
    if (!("duplicate" in self.hiddenActions))
      self.hiddenActions.duplicate = false;
    if (!("overviewLink" in self.hiddenActions))
      self.hiddenActions.overviewLink = false;
    if (!("planViewLink" in self.hiddenActions))
      self.hiddenActions.planViewLink = true;

    // Functions
    self.close = close;
    self.duplicatePin = duplicatePin;
    self.handleDeleteButtonClick = handleDeleteButtonClick;
    self.getMoveActionText = getMoveActionText;
    self.onViewDetailsClick = onViewDetailsClick;
    self.handleAttachmentUploadStart = handleAttachmentUploadStart;
    self.handleAttachmentUploadsFinished = handleAttachmentUploadsFinished;
    self.sideBarIcon;

    // Async lookup for sidenav instance;
    // allows us to run our close function when the escape key closes the sidebar
    $q.when($mdSidenav("abx-pin-detail-sidebar", true)).then(function (
      instance
    ) {
      // On close callback to handle close, backdrop click, or escape key pressed.
      // Callback happens BEFORE the close action occurs.
      instance.onClose(function () {
        close();
      });
    });

    $rootScope.$on(EVENT_PIN_REFRESH, function () {
      if (self.pin) {
        getPinValues();
      }
    });

    // =================
    // Life Cycle
    // =================
    /**
     * Handle changes of provided bindings.
     * @param {Object} changes - A hash providing .previousValue, .currentValue,
     *    and .isFirstChange() for each binding that changed.
     */
    self.$onChanges = function (changes) {
      if (changes.pin && self.pin) {
        self.sideBarIcon = self.pin.icon;
        if (!self.pin.values) {
          getPinValues();
        } else {
          self.state = COMPONENT_STATE.default;
        }
      }

      // either (1) we have not set permissions yet or (2) a new pin is selected
      if (changes.pin && self.pin) {
        self.permissions = getPermissions();
        self.detailsLink = getDetailsLink();
        self.planViewLink = getPlanViewLink();
      }

      if (self.versionMode !== VERSION_MODES.DEFAULT && self.pin) {
        // Add all pin fields to disabled types when the version is not current
        self.disabledFieldTypes = self.pin.pinType.fields.reduce(function (
          acc,
          field
        ) {
          acc[field.data_type] = true;
          return acc;
        },
        {});
        self.currentVersion = false;
      } else if (self.pin) {
        // disable inputs in user does not have permission to edit them
        if (self.pin && !angular.isEmpty(self.permissions)) {
          self.disabledFieldTypes = {
            ...self.disabledFieldTypes,
            room: !self.permissions.canChangeLocation,
          };
        }

        self.currentVersion = true;
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Set permissions for the current user when `flexible_user_permissions`
     * flag is enabled
     */
    function getPermissions() {
      // permissions are different depening on if the pin is a room or asset
      if (!self.pin) {
        return {
          canMovePin: false,
          canDuplicatePin: false,
          canEditPin: false,
          canChangeLocation: false,
          canAssociateQRCodes: false,
          canDisassociateQRCodes: false,
          canDeletePin: false,
          canUpload: false,
        };
      }
      switch (self.pin.pinType.protected_type) {
        case ROOM_PROTECTED_TYPE:
          return {
            canMovePin: permissions.room.set_location,
            canDuplicatePin: permissions.room.create,
            canEditPin: permissions.room.update,
            canChangeLocation: false,
            canAssociateQRCodes: permissions.qrcode.associate,
            canDisassociateQRCodes: permissions.qrcode.disassociate,
            canDeletePin: permissions.room.remove,
            canUpload: permissions.document.create,
            canAddAttachments:
              permissions.document.create && permissions.room.add_attachment,
          };
        case ASSET_PROTECTED_TYPE:
          return {
            canMovePin: permissions.asset.set_location,
            canDuplicatePin: permissions.asset.create,
            canEditPin: permissions.asset.update,
            canChangeLocation: permissions.asset.update_room,
            canAssociateQRCodes: permissions.qrcode.associate,
            canDisassociateQRCodes: permissions.qrcode.disassociate,
            canDeletePin: permissions.asset.remove,
            canUpload: permissions.document.create,
            canAddAttachments:
              permissions.document.create && permissions.asset.add_attachment,
          };
      }
    }
    /**
     * Close the sidebar.
     */
    function close() {
      if (isUploadingAttachment) {
        AlertDialog.show({
          locals: {
            title: "File Upload in Progress",
            textContent: "Please wait until the files are done uploading!",
            isShort: true,
          },
        });
      } else {
        self.pin = null;
        self.onClose();
      }
    }

    /**
     * Emit event for pin duplication
     */
    function duplicatePin() {
      $scope.$emit(EVENT_PIN_DUPLICATE, { pin: self.pin });
    }

    /**
     * Show the correct delete confirmation dialog depending on the pin type
     */
    function handleDeleteButtonClick() {
      var dialog = false;
      var dialogOpts;

      switch (self.pin.pinType.protected_type) {
        case ROOM_PROTECTED_TYPE:
          dialog = DeleteRoomDialog;
          dialogOpts = {
            roomIDs: [self.pin._id],
            buildingId: self.pin.building,
          };
          break;
        case ASSET_PROTECTED_TYPE:
          dialog = DeleteAssetDialog;
          dialogOpts = {
            assetIDs: [self.pin._id],
            buildingId: self.pin.building,
          };
          break;
      }
      if (dialog) {
        dialog
          .show({
            locals: dialogOpts,
          })
          .then(self.onPinDelete)
          .then(function () {
            ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.DELETE_PIN);
          });
      }
    }

    function getDetailsLink() {
      var destinationState = isRoom() ? "app.room" : "app.asset";
      var params = {
        buildingId: self.pin.building,
      };
      if (isRoom()) {
        params.roomId = self.pin._id;
      } else if (isAsset()) {
        params.assetId = self.pin._id;
      }

      return Router.href(destinationState, params);
    }

    function getPlanViewLink() {
      const {
        _id,
        level,
        pinType,
        building,
        is_decommissioned,
        is_placed_on_document,
      } = self.pin;

      if (!level || !is_placed_on_document || is_decommissioned) return null;

      const path = `/plan/buildings/${building}/levels/${level._id}`;
      const base = `${$location.protocol()}://${$location.host()}`;
      const url = new URL(path, base);

      const modelParam = isRoom() ? "room" : "asset";
      url.searchParams.append(modelParam, _id);
      const pinTypeParam = { [pinType._id]: { _id: pinType._id } };
      url.searchParams.append("pin_types", JSON.stringify(pinTypeParam));
      return url.toString();
    }

    function onViewDetailsClick() {
      ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.VIEW_MORE_DETAILS);
    }

    /**
     * Sets the text used for the Move/Place button of the current pin
     */
    function getMoveActionText() {
      if (self.pin) {
        return self.pin.is_placed_on_document ? "Move" : "Place";
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * Get the pin values for the current pin, and attach them.
     */
    function getPinValues() {
      var renderDelay = $timeout(angular.noop, MINIMUM_RENDER_TIME);

      var _PinService = ServiceHelpers.parsePinService(self.pin.pinType);
      var valuesRequest = _PinService
        .getAllValues(self.pin.building, self.pin._id)
        .then(function (pinValues) {
          self.pin.values = pinValues;
        });

      self.state = COMPONENT_STATE.loading;
      $q.all([valuesRequest, renderDelay])
        .catch(ToastService.showError)
        .finally(function () {
          self.state = COMPONENT_STATE.default;
        });
    }

    /**
     * Determine if the pin is an asset
     *
     * @return {Boolean} True if pin is an asset, false if not
     */
    function isAsset() {
      return self.pin.pinType.protected_type === ASSET_PROTECTED_TYPE;
    }

    /**
     * Determine if the pin is an room
     *
     * @return {Boolean} True if pin is an room, false if not
     */
    function isRoom() {
      return self.pin.pinType.protected_type === ROOM_PROTECTED_TYPE;
    }

    function handleAttachmentUploadStart() {
      isUploadingAttachment = true;
      self.onAttachmentUploadStart();
    }

    function handleAttachmentUploadsFinished() {
      isUploadingAttachment = false;
      self.onAttachmentUploadsFinished();
    }
  }
})();
