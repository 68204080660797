(function () {
  angular
    .module("akitabox.desktop.workOrder.organizationList")
    .controller(
      "OrganizationWorkOrderListController",
      OrganizationWorkOrderListController
    );

  /* @ngInject */
  function OrganizationWorkOrderListController(
    // Angular
    $location,
    $scope,
    $timeout,
    $q,
    // Constants
    EVENT_WORK_ORDER_CREATE,
    // Dialogs
    ExportDialog,
    PrintDialog,
    // Services
    TimeZoneService,
    FeatureFlagService,
    SessionService,
    ToastService,
    WorkOrderService,
    WorkOrdersBFFService,
    UserService,
    // Resolve
    organization,
    status,
    // Filtering
    FilterBarManager,
    ManagedMultipleValueFilter,
    ManagedModelFieldFilter,
    ManagedBuildingFilter,
    ManagedFilterHelpers,
    ManagedAssigneesFilter
  ) {
    var self = this;

    // Feature flags

    // Filter Configs
    var assigneesConfig;
    var workPerformedConfig;
    var intentConfig;
    var sourceConfig;
    var priorityConfig;
    var subjectConfig;
    var startDateConfig;
    var completionDateConfig;
    var dueDateConfig;
    var canceledDateConfig;
    var idConfig;
    var descriptionConfig;
    var srIdConfig;
    var msIdConfig;
    var buildingConfig;
    var inspectionProgramConfig;
    var customFieldConfig;

    // Attributes
    self.utcOffset = TimeZoneService.getCurrentUTCOffset();
    self.workOrders = null;
    self.organization = organization;
    self.status = status;
    self.fabActions = getFabActions();
    self.activeFilters = null;
    self.fetchLimit = 100;

    // WO calendar props
    self.showCalendar = SessionService.getWOCalendarState();
    self.showStartDateColumn = SessionService.getStartDateColumnState();

    // wo sorting
    self.sortBy = getInitialSortColumn();
    self.sortOrder = getInitialSortOrder();

    var inspectionsEnabled = organization.show_inspections;

    // Functions
    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;
    self.changeFilters = changeFilters;
    self.onToggleShowCalendar = onToggleShowCalendar;
    self.onToggleShowStartDate = onToggleShowStartDate;
    self.changeSort = changeSort;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_WORK_ORDER_CREATE, onWorkOrderCreate);

    // ------------------------
    //   Filter Configs
    // ------------------------

    self.filterBarManager = new FilterBarManager({
      onFilterChange: function () {
        var query = self.filterBarManager.getQuery();
        // apply the static ?status=${status} portion of the query
        query.status = status;
        // update the list
        changeFilters(query);
      },
    });

    subjectConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Subject",
      queryField: "subject",
    });

    idConfig = new ManagedMultipleValueFilter(self.filterBarManager, {
      displayName: "ID",
      queryField: "number",
      prefix: "WO-",
      inputType: "number",
      modelValueToChipText: function (inputValue) {
        return "WO-" + inputValue;
      },
    });

    descriptionConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Description",
      queryField: "description_text",
    });

    srIdConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Service Request ID",
      queryField: "sr_number",
      inputType: "number",
      prefix: "SR-",
      modelValueToChipText: function (inputValue) {
        return "SR-" + inputValue;
      },
    });

    msIdConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Maintenance Schedule ID",
      queryField: "ms_number",
      inputType: "number",
      prefix: "MS-",
      modelValueToChipText: function (inputValue) {
        return "MS-" + inputValue;
      },
    });

    priorityConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Priority",
      queryField: "priority",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Low", "Medium", "High", "Emergency"];
      },
      modelValueToFilterValue: function (priority) {
        return priority.toLowerCase();
      },
      modelValueToChipText: function (priority) {
        return priority.charAt(0).toUpperCase() + priority.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        return [queryStringValue];
      },
    });

    intentConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Reactive or Preventive",
      queryField: "intent",
      inputType: "typeahead",
      getEnumOptions: function () {
        return ["Reactive", "Preventive"];
      },
      modelValueToFilterValue: function (intent) {
        intent = intent.toLowerCase();
        // TODO: Remove this and allow 'inspection as filter type'
        if (intent === "preventive") {
          return "$in,preventive,inspection";
        }
        return intent;
      },
      modelValueToChipText: function (intent) {
        return intent.charAt(0).toUpperCase() + intent.slice(1);
      },
      filterValueToModelValue: function (queryStringValue) {
        if (queryStringValue === "reactive") return ["reactive"];
        return ["preventive"];
      },
    });

    var SOURCE_LABELS = [
      "Service Requests",
      "Maintenance Schedules",
      "Reactive Self-Identified",
      "Preventive Self-Identified",
    ];
    var SOURCE_VALUES = [
      "request",
      "future_task",
      "reactive_self_identified",
      "preventive_self_identified",
    ];

    if (self.organization.show_inspections) {
      SOURCE_LABELS.push("Inspections");
      SOURCE_VALUES.push("inspection");
    }

    sourceConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Source",
      queryField: "source",
      inputType: "typeahead",
      getEnumOptions: function () {
        return SOURCE_LABELS;
      },
      modelValueToFilterValue: function (source) {
        return SOURCE_VALUES[SOURCE_LABELS.indexOf(source)];
      },
      modelValueToChipText: function (source) {
        return source;
      },
      filterValueToModelValue: function (queryStringValue) {
        var sourceLabels =
          SOURCE_LABELS[SOURCE_VALUES.indexOf(queryStringValue)];
        return [sourceLabels];
      },
    });

    startDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Start Date",
      queryField: "start_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    completionDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Completion Date",
      queryField: "completed_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    canceledDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Canceled Date",
      queryField: "canceled_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    dueDateConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Due Date",
      queryField: "due_date",
      inputType: "date-range",
      utcOffset: self.utcOffset,
      modelValueToFilterValue: ManagedFilterHelpers.dateModelValueToFilterValue,
      modelValueToChipText: ManagedFilterHelpers.dateModelValueToChipText,
      filterValueToModelValue: ManagedFilterHelpers.dateFilterValueToModelValue,
    });

    var UNASSIGNED_USER = {
      _id: "null",
      identity: {
        _id: "null",
        display_name: "Unassigned",
      },
    };

    assigneesConfig = new ManagedAssigneesFilter(self.filterBarManager, {
      getEnumOptions: function () {
        return fetchAssignees().then(function (assignees) {
          var models = assignees.map(function (assignee) {
            var value = assignee.email;
            var identity = assignee.identity;

            if (identity) {
              value =
                identity.display_name !== identity.email
                  ? identity.display_name + " | " + identity.email
                  : identity.display_name;
            }

            return {
              model: assignee,
              value: value,
            };
          });

          // Add `Unassigned` as a typeahead option
          models.unshift({
            model: UNASSIGNED_USER,
            value: UNASSIGNED_USER.identity.display_name,
          });

          return models;
        });
      },
      modelValueToFilterValue: function (model) {
        var identity = model.identity;
        return identity._id || identity;
      },
      modelValueToChipText: function (model) {
        return model.identity.display_name;
      },
      filterValueToModelValue: function (assigneeQueryValue) {
        var models = [];
        // assigneeQueryValue is a string
        var identityIdList = assigneeQueryValue.split(",").slice(1);
        // Remove duplicates
        identityIdList = identityIdList.filter(function (_accountId, index) {
          return identityIdList.indexOf(_accountId) === index;
        });

        var userQuery = ["$in"];
        for (var i = 0; i < identityIdList.length; i++) {
          var identityId = identityIdList[i];
          if (identityId === "null") {
            models.push(UNASSIGNED_USER);
          } else {
            userQuery.push(identityId);
          }
        }
        return UserService.getAll(self.organization._id, {
          identity: userQuery.join(","),
          sort: "firstOrEmail,asc",
        }).then(function (users) {
          Array.prototype.push.apply(models, users);
          return models;
        });
      },
    });

    workPerformedConfig = new ManagedModelFieldFilter(self.filterBarManager, {
      displayName: "Work Performed By",
      queryField: "workers",
      inputType: "typeahead",
      getEnumOptions: function () {
        return fetchAssignees().then(function (assignees) {
          return assignees.map(function (assignee) {
            var value = assignee.email;
            var identity = assignee.identity;
            if (identity) {
              value =
                identity.display_name !== identity.email
                  ? identity.display_name + " | " + identity.email
                  : identity.display_name;
            }
            return {
              model: assignee,
              value: value,
            };
          });
        });
      },
      modelValueToFilterValue: function (model) {
        var identity = model.identity;
        return identity._id || identity;
      },
      modelValueToChipText: function (model) {
        return model.identity.display_name;
      },
      filterValueToModelValue: function (queryStringValue) {
        return UserService.get(self.organization._id, {
          identity: queryStringValue,
        });
      },
    });

    buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
      getOrganizationId: function () {
        return self.organization._id;
      },
    });

    inspectionProgramConfig = new ManagedModelFieldFilter(
      self.filterBarManager,
      {
        displayName: "Inspection Program ID",
        queryField: "ip_number",
        inputType: "number",
        prefix: "IP-",
        modelValueToChipText: function (inputValue) {
          return "IP-" + inputValue;
        },
      }
    );

    self.filterBarManager.addFilterConfiguration(subjectConfig);
    self.filterBarManager.addFilterConfiguration(idConfig);
    self.filterBarManager.addFilterConfiguration(descriptionConfig);
    self.filterBarManager.addFilterConfiguration(srIdConfig);
    self.filterBarManager.addFilterConfiguration(msIdConfig);
    if (inspectionsEnabled) {
      self.filterBarManager.addFilterConfiguration(inspectionProgramConfig);
    }
    self.filterBarManager.addFilterConfiguration(priorityConfig);
    self.filterBarManager.addFilterConfiguration(assigneesConfig);
    self.filterBarManager.addFilterConfiguration(workPerformedConfig);
    self.filterBarManager.addFilterConfiguration(intentConfig);
    self.filterBarManager.addFilterConfiguration(sourceConfig);
    self.filterBarManager.addFilterConfiguration(buildingConfig);
    self.filterBarManager.addFilterConfiguration(startDateConfig);
    self.filterBarManager.addFilterConfiguration(dueDateConfig);
    self.filterBarManager.addFilterConfiguration(completionDateConfig);
    self.filterBarManager.addFilterConfiguration(canceledDateConfig);

    if (self.organization.show_custom_srp_field) {
      var customLabel = self.organization.srp_custom_label;
      var customOptions = self.organization.srp_custom_options;
      if (customOptions && customOptions.length) {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
          inputType: "typeahead",
          getEnumOptions: function () {
            return customOptions;
          },
          modelValueToFilterValue: function (option) {
            return option;
          },
          modelValueToChipText: function (option) {
            return option;
          },
          filterValueToModelValue: function (queryStringValue) {
            return [queryStringValue];
          },
        });
      } else {
        customFieldConfig = new ManagedModelFieldFilter(self.filterBarManager, {
          displayName: customLabel,
          queryField: "custom_srp_value",
        });
      }
      self.filterBarManager.addFilterConfiguration(customFieldConfig);
    }

    self.filterBarManager.modifyQuery = function (query) {
      var statusQuery = WorkOrderService.getStatusQuery(query);
      Object.assign(query, statusQuery);
    };

    self.filterInitPromise = self.filterBarManager.applyQuery(
      $location.search()
    );

    // ------------------------
    //   Private Functions
    // ------------------------

    function getInitialSortColumn() {
      var column = SessionService.getWorkOrderSortColumn(
        self.showStartDateColumn,
        self.status
      );

      return column;
    }

    function getInitialSortOrder() {
      var order = SessionService.getWorkOrderSortOrder(self.status);
      return order;
    }

    function getFabActions() {
      return [
        {
          icon: "grid_on",
          label: "Export",
          action: exportWorkOrders,
        },
        {
          icon: "print",
          label: "Print",
          action: printWorkOrders,
        },
      ];
    }

    function getExportLocals() {
      var filters = angular.extend(
        {},
        self.activeFilters,
        WorkOrderService.getStatusQuery({
          status: status,
          start_date: self.activeFilters.start_date,
        })
      );
      return {
        route: WorkOrderService.buildOrganizationListRoute(organization._id),
        filters: filters,
      };
    }

    function printWorkOrders() {
      PrintDialog.show({
        locals: getExportLocals(),
      }).catch(ToastService.showError);
    }

    function exportWorkOrders() {
      ExportDialog.show({
        locals: getExportLocals(),
      }).catch(ToastService.showError);
    }

    function fetchAssignees() {
      return UserService.getAll(self.organization._id, {
        identity: "$ne,null",
        sort: "firstOrEmail,asc",
      }).catch(ToastService.showError);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function onToggleShowCalendar() {
      // no value is passed from the switch. `showCalendar already has the new value`
      SessionService.setWOCalendarState(self.showCalendar);
    }

    function onToggleShowStartDate() {
      // no value is passed from the switch. `showStartDateColumn already has the new value`
      SessionService.setStartDateColumnState(self.showStartDateColumn);
    }

    function fetchWorkOrders(skip, limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          skip: skip,
          limit: limit,
          status: status,
        };

        if (self.sortBy !== null && self.sortOrder !== null) {
          params.sort_by = self.sortBy + "," + self.sortOrder;
        }

        var filters = angular.extend({}, params, self.activeFilters);

        const setWO = function (workOrders) {
          let wos = workOrders;
          wos = WorkOrdersBFFService.addCalculatedProperties(wos);

          if (angular.isArray(self.workOrders)) {
            self.workOrders = self.workOrders.concat(wos);
          } else {
            self.workOrders = wos;
          }
          return workOrders.length;
        };

        return WorkOrdersBFFService.getByOrganization(
          self.organization._id,
          filters
        ).then(setWO);
      };

      return $q
        .resolve(self.filterInitPromise)
        .then(doFetch)
        .finally(function () {
          $scope.$broadcast("list:refreshComplete");
        });
    }

    function fetchAllWorkOrders(limit) {
      // Add status to url if not preset in active filters
      if (
        self.activeFilters &&
        !Object.prototype.hasOwnProperty.call(self.activeFilters, "status")
      ) {
        $location.search("status", status);
      }
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          status: status,
        };

        if (self.sortBy !== null && self.sortOrder !== null) {
          params.sort_by = self.sortBy + "," + self.sortOrder;
        }

        var filters = angular.extend({}, params, self.activeFilters);

        return WorkOrderService.getAllByOrganization(
          self.organization._id,
          filters
        ).then(function (workOrders) {
          self.workOrders = workOrders;
          return workOrders.length;
        });
      };

      return $q
        .resolve(self.filterInitPromise)
        .then(doFetch)
        .finally(function () {
          $scope.$broadcast("list:refreshComplete");
        });
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.calendarFilters = angular.extend({ status: status }, activeFilters);
      self.workOrders = null;
      $scope.$broadcast("list:refresh");
    }

    function changeSort(sortBy, sortOrder) {
      self.workOrders = null;
      SessionService.setWorkOrderSortColumn(
        self.showStartDateColumn,
        self.status,
        sortBy
      );
      SessionService.setWorkOrderSortOrder(self.status, sortOrder);
      self.sortBy = sortBy;
      self.sortOrder = sortOrder;
      $scope.$broadcast("list:refresh");
    }

    /**
     * Handle work order creation event
     *
     * @param {Event}   $event      Angular event
     * @param {WorkOrder[]} workOrders  List of new work orders
     */
    function onWorkOrderCreate(event, workOrders) {
      if (!self.workOrders) {
        self.workOrders = [];
      }
      Array.prototype.unshift.apply(self.workOrders, workOrders);
      $timeout(function () {
        $scope.$broadcast("list:refreshClickEvents");
      });
    }
  }
})();
