(function () {
  angular.module("akitabox.desktop.workOrder.detail", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.building",
    "akitabox.core.services.checklist",
    "akitabox.core.services.document",
    "akitabox.core.services.env",
    "akitabox.core.services.file",
    "akitabox.core.services.flag",
    "akitabox.core.services.http",
    "akitabox.core.services.inspection",
    "akitabox.core.services.room",
    "akitabox.core.services.schedule",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.organization",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "akitabox.core.utils",
    "akitabox.ui.dialogs.assign.assign",
    "akitabox.ui.dialogs.editLocation",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.print",
    "akitabox.ui.dialogs.workOrder",
    "akitabox.ui.dialogs.workOrderLog",
    "akitabox.ui.directives",
    "akitabox.ui.components.multipleAssigneeInput",
  ]);
})();
