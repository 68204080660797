(function () {
  /**
   * @ngdoc component
   * @name abxPinTypeSubfilterSidebar
   *
   * @param {Object} floor - Floor to limit filter values on. Specifically,
   *     this is required for filtering on rooms, as our room inputs aren't
   *     enabled unless they have a room. If this binding is omitted, inputs
   *     that rely on floor will not be usable.
   * @param {Function} onClose - To be invoked when the user clicks a close
   *     button for the sidebar.
   * @param {Function} onFiltersChange - To be invoked when the given pin
   *     field filters should change (i.e. pin field filters were applied or
   *     removed). Will be invoked with: $event.pinTypeFilter
   * @param {Object} pinType - Pin type to sub-filter for
   * @param {Object} pinTypeFilter - Filter object for the pin type currently
   *    being sub-filtered on
   * @param {Object[]} [pinTypeFilter.pin_fields] - Array of pin field filter
   *    objects for the pin type filter
   * @param {String} [pinTypeFilter.name] - String to filter on a pin's `name`
   *    field for requests for this pin type
   * @param {Boolean} isShown - Used to show/hide the sidebar
   *
   * @description
   * Sidebar to house the sub-filtering of a pin type.
   */
  angular.module("akitabox.planView").component("abxPinTypeSubfilterSidebar", {
    bindings: {
      floor: "<abxFloor",
      onClose: "&abxOnClose",
      onFiltersChange: "&abxOnFiltersChange",
      pinType: "<abxPinType",
      pinTypeFilter: "<abxPinTypeFilter",
      isShown: "<abxIsShown",
    },
    controller: AbxPinTypeSubfilterSidebarController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/pin-type-subfilter-sidebar/pin-type-subfilter-sidebar.component.html",
  });

  function AbxPinTypeSubfilterSidebarController(
    // Angular
    $mdSidenav,
    $q,
    // Constants
    PV_GA,
    // Helpers
    PlanViewUtils,
    Utils,
    // Services
    ShadowService,
    RoomService
  ) {
    var self = this;

    // Attributes
    // All currently shown, but not necessarily applied, filters
    self.currentFilters = [];
    // Maps pin field IDs to consumable object of form { fieldAlias, filterValue }
    self.currentPinFieldFilters = {};
    // Maps attribute keys to their current filter values
    self.currentAttributeFilters = {};

    // Functions
    self.applyCurrentFilters = applyCurrentFilters;
    self.clearCurrentFilters = clearCurrentFilters;
    self.handleAttributeFilterChange = handleAttributeFilterChange;
    self.handleSubfilterClear = handleSubfilterClear;
    self.handlePinFieldFiltersChange = handlePinFieldFiltersChange;

    // Async lookup for sidenav instance;
    // allows us to run our close function when the escape key closes the sidebar
    $q.when($mdSidenav("abx-pin-type-subfilter-sidebar", true)).then(function (
      instance
    ) {
      // On close callback to handle close, backdrop click, or escape key pressed.
      // Callback happens BEFORE the close action occurs.
      instance.onClose(function () {
        self.onClose();
      });
    });

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.pinTypeFilter) {
        parseActiveAttributeFilters();
        parseActivePinFieldFilters();
        setCurrentFilters(self.pinTypeFilter);
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Apply the current/pending filters by notifying the parent of them. Will
     * convert the pin field filters back into an array format, like the active
     * filters come in as.
     *
     * @returns {Promise}
     */
    function applyCurrentFilters() {
      var pinTypeFilter = getCurrentPinTypeFilter();
      ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.SUBFILTER);
      return self.onFiltersChange({
        $event: { pinTypeFilter: pinTypeFilter },
      });
    }

    /**
     * Clear the current/pending filters and apply the result.
     */
    function clearCurrentFilters() {
      self.currentAttributeFilters = {};
      self.currentPinFieldFilters = {};
      self.currentFilters = [];

      self.applyCurrentFilters();
    }

    /**
     * Handle new attribute filter values. Add valid filters to the
     * current/pending filters
     * Don't apply them unless they've changed
     *
     * @param {Object} $event - Propagated event
     * @param {Object} $event.newValue - New value to filter by
     * @param {Boolean} $event.invalid - True if new value is invalid
     * @param {Object} attribute - attribute to filter
     *
     */
    function handleAttributeFilterChange($event, attribute) {
      var validFilter = !$event.invalid;
      if (validFilter) {
        // If the attribute is unchanged, don't update
        if (self.currentAttributeFilters[attribute] !== $event.newValue) {
          var isEmpty = angular.isEmpty($event.newValue);
          if (isEmpty) {
            delete self.currentAttributeFilters[attribute];
          } else {
            self.currentAttributeFilters[attribute] = $event.newValue;
          }

          handleFilterChange();
        }
      }
    }

    /**
     * Handle new pin field filter values. Add valid filters to the
     * current/pending filters
     * Don't apply them unless they've changed
     *
     * @param {Object} $event - Propagated event
     * @param {Object} $event.filters - New current filters
     * @param {Boolean} $event.invalid - True if selected filter is invalid
     */
    function handlePinFieldFiltersChange($event) {
      var validFilter = !$event.invalid;
      if (validFilter) {
        // If the pin is unchanged, don't update
        if (!angular.equals(self.currentPinFieldFilters, $event.filters)) {
          self.currentPinFieldFilters = $event.filters;
          handleFilterChange();
        }
      }
    }

    /**
     * Handle the clearing/removal of a sub-filter from the list of pending
     * filters
     *
     * @param {Object} subfilter - Sub-filter item to be cleared
     * @param {Object} [subfilter.pinField] - Pin field the sub-filter is
     *     responsible for
     * @param {String} [subfilter.attribute] - Pin ttribute/key the sub-filter
     *     is responsible for
     */
    function handleSubfilterClear(subfilter) {
      if (subfilter.pinField) {
        delete self.currentPinFieldFilters[subfilter.pinField._id];
      } else if (subfilter.attribute) {
        delete self.currentAttributeFilters[subfilter.attribute];
      }

      handleFilterChange();
    }

    // =================
    // Private Functions
    // =================

    /**
     * Extract the current filters (both attribute and pin field filters) into
     * one combined array, and attach them
     *
     * @param {Object} pinTypeFilter - Pin type filter object to get current
     *     sub-filters for
     * @returns {Promise}
     */
    function setCurrentFilters(pinTypeFilter) {
      if (pinTypeFilter) {
        return PlanViewUtils.getPinTypeSubfilters(
          pinTypeFilter,
          self.pinType
        ).then(function (subfilters) {
          self.currentFilters = subfilters;
        });
      } else {
        self.currentFilters = [];
        return $q.resolve();
      }
    }

    /**
     * Set the current filters and applies them.
     * Handles the loading state to prevent additional
     * changes occuring while a previous change is still loading
     */
    function handleApplyFilters(pinTypeFilter) {
      if (self.isLoading) return;
      self.isLoading = true;
      setCurrentFilters(pinTypeFilter)
        .then(function () {
          return self.applyCurrentFilters();
        })
        .finally(function () {
          self.isLoading = false;
        });
    }

    /**
     * Get the "would be" pin type filter object based on the pending filters
     *
     * @param {Object} [options]
     * @param {Boolean} [options.attachValueAliases] - Attach an alias for the
     *     value of a pin field filter, where appropriate
     * @return {Object} - Pin type filter for the current sub-filters state
     */
    function getCurrentPinTypeFilter(options) {
      options = options || {};

      var attributeFilters = parseCurrentAttributeFilters();
      var pinFieldFilters = parseCurrentPinFieldFilters(options);

      return angular.extend(attributeFilters, {
        _id: self.pinType._id,
        pin_fields: pinFieldFilters,
      });
    }

    /**
     * Respond to changes of any filter inputs
     */
    function handleFilterChange() {
      var options = { attachValueAliases: true };
      var currentPinTypeFilter = getCurrentPinTypeFilter(options);
      handleApplyFilters(currentPinTypeFilter);
    }

    /**
     * Parse active filters for pin attributes tied to the given pin type
     * filter into an object mapping the attribute name to the filter value.
     */
    function parseActiveAttributeFilters() {
      if (!self.pinTypeFilter) {
        self.currentAttributeFilters = {};
        return;
      }

      var reservedKeys = ["_id", "pin_fields"];
      for (var key in self.pinTypeFilter) {
        if (reservedKeys.indexOf(key) > -1) continue;

        var filterValue = self.pinTypeFilter[key];
        self.currentAttributeFilters[key] = filterValue;
      }
    }

    /**
     * Parse the active pin field filters (given as an array of objects) into
     * an object mapping pin field IDs to their current filter.
     */
    function parseActivePinFieldFilters() {
      var noPinFieldFilters =
        !self.pinTypeFilter || angular.isEmpty(self.pinTypeFilter.pin_fields);
      if (noPinFieldFilters) {
        self.currentPinFieldFilters = {};
        return;
      }

      var requests = [];
      self.pinTypeFilter.pin_fields.forEach(function (pinFieldFilter) {
        var request = $q
          .resolve(parseActivePinFieldFilter(pinFieldFilter))
          .then(function (parsedFilterValue) {
            self.currentPinFieldFilters[pinFieldFilter._id] = parsedFilterValue;
          });
        requests.push(request);
      });
      $q.all(requests);
    }

    /**
     * Parse the actual filter value from a pin field filter.
     *
     * @param {Object} filter - Active pin field filter to parse
     * @return {*} - Parsed filter value
     */
    function parseActivePinFieldFilter(filter) {
      if (isRoomFilter(filter._id)) {
        return parseActiveRoomFilter(filter);
      } else if (isRangeFilter(filter)) {
        return {
          start: filter.start,
          end: filter.end,
        };
      } else {
        return filter.value;
      }
    }

    /**
     * Parse a room pin field filter
     *
     * @param {Object} filter - Active room field filter to parse
     * @return {Promise<Object>} - Resolves with the room specified by the filter
     */
    function parseActiveRoomFilter(filter) {
      // Both the values and pin type are necessary to get the room's display value
      var params = { include_values: true };
      var options = { includePinType: true };
      return RoomService.getById(
        self.floor.building,
        filter.value,
        params,
        options
      );
    }

    /**
     * Parse/filter the current filters for pin attributes, and return them.
     *
     * @return {Object} - Map attribute keys to their filter values
     */
    function parseCurrentAttributeFilters() {
      var parsedFilters = {};
      for (var attr in self.currentAttributeFilters) {
        var value = self.currentAttributeFilters[attr];
        if (!angular.isEmpty(value)) {
          parsedFilters[attr] = value;
        }
      }

      return parsedFilters;
    }

    /**
     * Convert the current pin field filters back into an array format (like the
     * active filters given), as this is how our API parses them.
     *
     * @param {Object} [options]
     * @param {Boolean} [options.attachValueAliases] - Attach an alias for the
     *     value of a pin field filter, where appropriate
     * @return {Object[]} - Array form of given filters
     */
    function parseCurrentPinFieldFilters(options) {
      var filters = [];
      for (var pinFieldId in self.currentPinFieldFilters) {
        var filter = parseCurrentPinFieldFilter(pinFieldId, options);
        filters.push(filter);
      }

      return filters;
    }

    /**
     * Parse a current pin field filter into a form that our API respects
     *
     * @param {String} pinFieldId - Pin field of filter to parse
     * @param {Object} [options]
     * @param {Boolean} [options.attachValueAliases] - Attach an alias for the
     *     value of a pin field filter, where appropriate
     * @return {Object} - Filter object for the pin field
     */
    function parseCurrentPinFieldFilter(pinFieldId, options) {
      var filterObject = { _id: pinFieldId };
      var filterValue = self.currentPinFieldFilters[pinFieldId];

      if (isRoomFilter(pinFieldId)) {
        var room = filterValue;
        filterObject.value = room._id;
        if (options.attachValueAliases) {
          filterObject.valueAlias = room.number;
        }
      } else if (isRangeFilter(filterValue)) {
        angular.extend(filterObject, filterValue);
      } else {
        filterObject.value = filterValue;
      }

      return filterObject;
    }

    /**
     * Determine if the given pin field filter is a range filter (i.e. ints,
     * dates, etc.)
     *
     * @param {Object} - Pin field filter value
     * @return {Boolean} - Filter is a range
     */
    function isRangeFilter(filter) {
      return (
        angular.isObject(filter) &&
        (Object.prototype.hasOwnProperty.call(filter, "start") ||
          Object.prototype.hasOwnProperty.call(filter, "end"))
      );
    }

    /**
     * Given a pin field for a filter, determine if the filter is for a room.
     *
     * @param {String} pinFieldId - ID of pin field for the filter
     * @return {Boolean} - Filter is for a room
     */
    function isRoomFilter(pinFieldId) {
      var pinField = Utils.findModelById(self.pinType.fields, pinFieldId);
      return pinField.is_room_field;
    }
  }
})();
