(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.inputSection
   */
  angular
    .module("akitabox.ui.directives.inputSection", ["ngMaterial"])
    .directive("abxInputSection", AbxInputSectionDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.inputSection
   * @name AbxInputSectionDirective
   *
   * @description
   * `abx-input-section` wraps a set of input elements in a form with
   * dedicated submit (save) and cancel buttons that allow a user to
   * edit and save all fields at the same time.
   *
   * @usage
   * <hljs lang="html">
   *   <abx-input-section abx-form-name="detailsForm" abx-editable="vm.canEdit" abx-submit="vm.save()">
   *     <md-input-container md-theme="black">
   *         <label for="name">Building Name</label>
   *         <input type="text"
   *                id="name"
   *                name="name"
   *                ng-model="vm.building.name"
   *                placeholder="Enter your building's name"
   *                required />
   *         <div ng-messages="detailsForm.name.$error" role="alert"
   *                ng-if="detailsForm.name.$invalid && detailsForm.name.$touched">
   *             <div class="md-caption" ng-message="required">Building name is required</div>
   *         </div>
   *     </md-input-container>
   *     <md-input-container md-theme="black">
   *         <label for="address">Address</label>
   *         <input type="text"
   *                id="address"
   *                name="address"
   *                ng-model="vm.building.address"
   *                placeholder="Enter your building's address"
   *                required />
   *     </md-input-container>
   *     <md-input-container md-theme="black">
   *         <label for="state">State</label>
   *         <md-select
   *                 id="state"
   *                 name="state"
   *                 ng-model="vm.state"
   *                 placeholder="Select a state"
   *                 abx-readonly-placeholder="Not provided">
   *             <md-option value="" selected></md-option>
   *             <md-option ng-repeat="item in vm.states" ng-value="item" ng-bind="item.display"></md-option>
   *         </md-select>
   *     </md-input-container>
   *   </abx-input-section>
   * </hljs>
   *
   * @ngInject
   */
  function AbxInputSectionDirective($timeout, $log, $q) {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/input-section/input-section.html",
      transclude: {
        header: "?header",
      },
      link: postLink,
      scope: {
        formName: "@abxFormName",
        submit: "&abxSubmit",
        isEditable: "&?abxEditable",
        onEdit: "=?abxOnEdit",
        onCancel: "=?abxOnCancel",
        isValid: "=?abxIsValid",
        saving: "<?abxSaving",
      },
    };

    function postLink($scope, $element, attrs) {
      // Class constants
      var CLASS_SECTION_EDITING = "abx-section-editing";
      var CLASS_SECTION_READONLY = "abx-section-readonly";
      // Attribute constants
      var ATTR_ABX_READONLY_PLACEHOLDER = "abx-readonly-placeholder";
      var ATTR_ABX_CUSTOM_INPUT = "abx-custom-input";
      // Tag constants
      var TAG_MD_SELECT = "MD-SELECT";
      var TAG_MD_AUTOCOMPLETE = "MD-AUTOCOMPLETE";
      // List of input element tags
      var INPUT_TAGS = ["input", "textarea", "md-select", "md-autocomplete"];

      // Input data
      var models = {};
      var placeholders = {};

      // UI elements
      var $header = $element.find("header");
      var $standardInputs = {};
      var $customInputs = [];
      var $allInputs = {};
      var $autocompletes = [];

      // Attributes
      $scope.form = {};
      $scope.editable = false;

      // optional prop to allow more flexibility with disabling the save button.
      // if a value is passed in to the directive, isValid will determine when the save button should
      // be enabled (in addition to default checks for $form.invalid and $form.pristine)
      $scope.isValid = $scope.isValid ? $scope.isValid : true;

      // Functions
      $scope.edit = edit;
      $scope.save = save;
      $scope.cancel = cancel;

      init();

      // ------------------------
      //   Public Functions
      // ------------------------

      /**
       * Enable form and inputs for editing
       */
      function edit() {
        $scope.editing = true;
        enableSection();
        enableForm();
        if (angular.isFunction($scope.onEdit)) $scope.onEdit();
      }

      /**
       * Save form
       */
      function save() {
        if ($scope.form.$invalid || $scope.form.$pristine) return;

        var submit = $scope.submit() || angular.noop;
        if (submit.then) {
          $scope.saving = true;
        }

        disableForm();

        $q.resolve(submit)
          .then(function () {
            $scope.editing = false;
            updateModels();
            updatePlaceholders();
            disableSection();
          })
          .catch(function () {
            enableForm();
          })
          .finally(function () {
            $scope.saving = false;
            // Reset form
            $scope.form.$setUntouched();
            $scope.form.$setPristine();
          });
      }

      /**
       * Cancel unsaved edits, reverting values to their
       * previously saved state
       */
      function cancel() {
        $scope.editing = false;
        resetModels();
        disableForm();
        disableSection();
        if (angular.isFunction($scope.onCancel)) $scope.onCancel();
        // Reset form
        $scope.form.$setUntouched();
        $scope.form.$setPristine();
      }

      // ------------------------
      //   Private Functions
      // ------------------------

      /**
       * Initialize the input section, find inputs and form controller
       */
      function init() {
        $element.addClass("abx-input-section " + CLASS_SECTION_READONLY);

        if ($header.length) {
          $element.addClass("abx-section-with-header");
        }

        // Verify we have a form name
        if (angular.isEmpty($scope.formName)) {
          return $log.error(
            "<abx-input-section>: abx-form-name must be specified"
          );
        }

        // Check if we have submit function
        if (!angular.isDefined(attrs.abxSubmit)) {
          if ($scope.$eval(attrs.abxEditable) !== false) {
            $log.warn("<abx-input-section>: abx-submit was not specified");
          }
          $scope.submit = angular.noop;
        }

        $scope.form = getFormController();
        if ($scope.form) {
          attachEditableWatcher();
          // Wait for inputs to load
          $timeout(function () {
            findAllInputs();
          }, 0);
        } else {
          $scope.form = {};
          $scope.editable = false;
        }
      }

      /**
       * Find angular form controller
       *
       * @return {Object}     Form controller
       */
      function getFormController() {
        var formCtrl = $scope[$scope.formName];
        $scope.$parent[$scope.formName] = formCtrl;
        return formCtrl;
      }

      /**
       * If provided, watch the editable flag for changes
       */
      function attachEditableWatcher() {
        if (angular.isDefined($scope.isEditable)) {
          $scope.$watch("isEditable()", function (value) {
            if (angular.isDefined(value)) $scope.editable = value;
          });
        }
      }

      /**
       * Find all the input elements. This allows the directive to mark them
       * as readonly and/or disabled depending the input section's state and
       * the type of element. This also evaluates the initial model values
       * in order to revert if the user cancels.
       */
      function findAllInputs() {
        for (var i = 0; i < INPUT_TAGS.length; ++i) {
          findStandardInputs(INPUT_TAGS[i]);
        }

        $timeout(function () {
          findCustomInputs();
        }, 500);
      }

      /**
       * Find and add all inputs by tag name
       *
       * @param  {String} tag     Tag name
       */
      function findStandardInputs(tag) {
        var elements = $element.find(tag);
        for (var i = 0; i < elements.length; ++i) {
          var elem = angular.element(elements[i]);
          switch (tag.toUpperCase()) {
            case TAG_MD_AUTOCOMPLETE:
              addAutocomplete(elem);
              break;
            case TAG_MD_SELECT:
              addSelect(elem);
              break;
            default:
              var name = elem.attr("name");
              if (name && !isElementReadonly(elem)) {
                // Disable autocomplete
                elem.attr("autocomplete", "off");
                setPlaceholders(elem, name);
                addStandardInput(elem, name);
              }
          }
        }
      }

      /**
       * Find and add all nested custom input elements
       */
      function findCustomInputs() {
        var customElements = $element[0].querySelectorAll(
          "[" + ATTR_ABX_CUSTOM_INPUT + "]"
        );
        for (var i = 0; i < customElements.length; ++i) {
          var elem = angular.element(customElements[i]);
          if (isElementReadonly(elem)) {
            // Disable element
            // IE / Edge does not support :read-only css selectors
            elem.attr("disabled", "disabled");
          } else {
            var name = elem.attr("name");
            if (name) {
              setPlaceholders(elem, name);
              setModel(elem, name);
              disableElement(elem, name);
              $customInputs.push(elem);
              $allInputs[name] = elem;
            }
          }
        }
      }

      /**
       * Check if element has any readonly attributes
       *
       * @param  {Object}  elem   Angular (jqLite) element
       * @return {Boolean}        True if the element is valid, false if not
       */
      function isElementReadonly(elem) {
        var hasReadonly = !angular.isEmpty(elem.attr("readonly"));
        var hasNgReadonly = !angular.isEmpty(elem.attr("ng-readonly"));
        return hasReadonly || hasNgReadonly;
      }

      /**
       * Add md-autocomplete element. This element requires additional setup
       * in order to display properly.
       *
       * @param {Object} elem     Angular (jqLite) element
       */
      function addAutocomplete(elem) {
        var $label = angular.element(elem.find("label")[0]);
        var $input = angular.element(elem.find("input")[0]);
        var name = $input.attr("name");
        if (name && !isElementReadonly(elem)) {
          disableElement(elem);
          $label.addClass("md-no-float");
          $input.attr("placeholder", elem.attr("placeholder"));
          $input.attr(
            ATTR_ABX_READONLY_PLACEHOLDER,
            elem.attr(ATTR_ABX_READONLY_PLACEHOLDER)
          );
          if (Object.prototype.hasOwnProperty.call($standardInputs, name)) {
            setPlaceholders($input, name);
            addStandardInput($input, name);
          }
          $autocompletes.push(elem);
        }
      }

      /**
       * Add md-select element. This element requires additional setup
       * in order to display the placholders properly.
       *
       * @param {Object} elem     Angular (jqLite) element
       */
      function addSelect(elem) {
        var name = elem.attr("name");
        if (name && !isElementReadonly(elem)) {
          disableElement(elem);
          var $selectValue = angular.element(elem.find("md-select-value")[0]);
          setPlaceholders(elem, name);
          var placeholder = placeholders[name].read;
          $selectValue.append(
            '<span class="abx-select-placeholder">' + placeholder + "</span>"
          );
          addStandardInput(elem, name);
        }
      }

      /**
       * Add input element
       *
       * @param {Object} elem     Angular (jqLite) element
       * @param {String} name     Element name
       */
      function addStandardInput(elem, name) {
        setModel(elem, name);
        disableElement(elem, name);
        $standardInputs[name] = elem;
        $allInputs[name] = elem;
      }

      /**
       * Set an input's placeholders (readonly and edit)
       *
       * @param {Object} elem     Angular (jqLite) element
       * @param {String} name     Element name
       */
      function setPlaceholders(elem, name) {
        var edit = elem.attr("placeholder");
        var read = elem.attr(ATTR_ABX_READONLY_PLACEHOLDER);
        if (!read) read = "Not provided";
        placeholders[name] = {
          edit: edit,
          read: read,
        };
      }

      /**
       * Update all inputs' placeholders (readonly and write)
       */
      function updatePlaceholders() {
        var inputNames = Object.keys($allInputs);
        for (var i = 0; i < inputNames.length; ++i) {
          var inputName = inputNames[i];
          var $input = $allInputs[inputName];
          setPlaceholders($input, inputName);
        }
      }

      /**
       * Set the model for an input
       *
       * @param {Object} elem     Angular (jqLite) element
       * @param {String} name     Element name
       */
      function setModel(elem, name) {
        name = name ? name : elem.attr("name");
        var ngModelCtrl = $scope.form[name];
        if (!Object.prototype.hasOwnProperty.call(models, name)) {
          // Subscribe to external changes
          ngModelCtrl.$formatters.push(function (value) {
            var hasOnlyOneInput = Object.keys(models).length === 1;
            if (!$scope.editing) {
              models[name] = value;
            } else if (hasOnlyOneInput) {
              /**
               * Special handling for input sections with just one
               * value (commonly in pin value lists)
               * We are going to brutally assume that any external
               * changes from outside of this input section should
               * permanently change model values inside this input
               * section. This is differentiated from when we have
               * incoming changes from _inside_ the current input
               * section (i.e. populating a trade from an issue type)
               */
              models[name] = value;
            }
            return value;
          });
        }
        // Add current model value
        models[name] = angular.copy(ngModelCtrl.$modelValue);
      }

      /**
       * Reset all input models to their original values
       */
      function resetModels() {
        var keys = Object.keys(models);
        for (var i = 0; i < keys.length; ++i) {
          var k = keys[i];
          var modelCtrl = $scope.form[k];
          if (!modelCtrl) continue;
          modelCtrl.$setViewValue(models[k]);
          modelCtrl.$render();
        }
      }

      /**
       * Update all input models with their new values
       */
      function updateModels() {
        var keys = Object.keys($standardInputs);
        for (var i = 0; i < keys.length; ++i) {
          var name = keys[i];
          setModel($standardInputs[name], name);
        }
        for (var c = 0; c < $customInputs.length; ++c) {
          var elem = $customInputs[c];
          setModel(elem);
        }
      }

      /**
       * Disable the input section element
       */
      function disableSection() {
        $element.removeClass(CLASS_SECTION_EDITING);
        $element.addClass(CLASS_SECTION_READONLY);
      }

      /**
       * Enable the input section element
       */
      function enableSection() {
        $element.removeClass(CLASS_SECTION_READONLY);
        $element.addClass(CLASS_SECTION_EDITING);
      }

      /**
       * Disable all form elements
       */
      function disableForm() {
        // Disable standard inputs
        var keys = Object.keys($standardInputs);
        for (var i = 0; i < keys.length; ++i) {
          var name = keys[i];
          var input = $standardInputs[name];
          disableElement(input, name);
        }
        // Disable autocompletes
        for (var a = 0; a < $autocompletes.length; ++a) {
          disableElement($autocompletes[a]);
        }
        // Disable custom inputs
        for (var c = 0; c < $customInputs.length; ++c) {
          var elem = $customInputs[c];
          disableElement(elem, elem.attr("name"));
        }
      }

      /**
       * Enable all form elements
       */
      function enableForm() {
        // Enable standard inputs
        var keys = Object.keys($standardInputs);
        for (var i = 0; i < keys.length; ++i) {
          var name = keys[i];
          var input = $standardInputs[name];
          enableElement(input, name);
        }
        // Enable autocompletes
        for (var a = 0; a < $autocompletes.length; ++a) {
          enableElement($autocompletes[a]);
        }
        // Enable custom inputs
        for (var c = 0; c < $customInputs.length; ++c) {
          var elem = $customInputs[c];
          enableElement(elem, elem.attr("name"));
        }
      }

      /**
       * Enable an element
       *
       * @param {Object} elem     Angular (jqLite) element
       * @param {String} name     [Optional] element name
       */
      function enableElement(elem, name) {
        elem.removeClass("abx-readonly");
        if (name) {
          $timeout(function () {
            elem.attr("placeholder", placeholders[name].edit);
          });
        }
        switch (elem[0].tagName) {
          case "INPUT":
            elem.removeAttr("readonly");
            break;
          default:
            elem.removeAttr("readonly");
            elem.removeAttr("disabled");
        }
      }

      /**
       * Disable an element
       *
       * @param {Object} elem     Angular (jqLite) element
       * @param {String} name     [Optional] element name
       */
      function disableElement(elem, name) {
        elem.addClass("abx-readonly");
        if (name) {
          $timeout(function () {
            elem.attr("placeholder", placeholders[name].read);
          });
        }
        switch (elem[0].tagName) {
          case "INPUT":
            elem.attr("readonly", "readOnly");
            break;
          default:
            elem.attr("readonly", "readOnly");
            elem.attr("disabled", "disabled");
        }
      }
    }
  }
})();
