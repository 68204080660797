import { Command } from "@ckeditor/ckeditor5-core";
import { Writer } from "@ckeditor/ckeditor5-engine";
import { InsightsFilter } from "../../report-builder/insights/types";
import { uid } from "@ckeditor/ckeditor5-utils";

export type InsertInsightsBlockPromptCommandParams = {
  dashboardId: string;
  widgetType: string | undefined;
  filters: InsightsFilter[];
  height: string;
  title: string;
  widgetId: string;
  width: string;
  showIndicatorTitles: boolean;
};

export class InsertInsightsBlockPromptCommand extends Command {
  execute(params: InsertInsightsBlockPromptCommandParams) {
    this.editor?.model.change((writer) => {
      // Insert <abxReportBlockPrompt>*</abxReportBlockPrompt> at the current selection position
      // in a way that will result in creating a valid model structure.
      const nextPos = this.editor.model.document.selection.getLastPosition();
      if (nextPos) {
        this.editor.model.insertObject(
          createReportBlock(writer, params),
          nextPos
        );
      }
    });
  }

  refresh() {
    // refresh is routinely called by the editor
    // here we want to check if the command should be enabled or disabled depending on where
    // the user is in the document
    const model = this.editor?.model;
    const selection = model.document.selection;
    const firstPos = selection.getFirstPosition();
    if (firstPos) {
      const allowedIn = model.schema.findAllowedParent(
        firstPos,
        "abxInsightsBlockPrompt"
      );
      this.isEnabled = allowedIn !== null;
    } else {
      this.isEnabled = false;
    }
  }
}

function createReportBlock(
  writer: Writer,
  params: InsertInsightsBlockPromptCommandParams
) {
  const dashboardId = params.dashboardId;
  const widgetType = params.widgetType;
  const filters = params.filters;
  const height = params.height;
  const title = params.title;
  const widgetId = params.widgetId;
  const width = params.width;
  const showIndicatorTitles = params.showIndicatorTitles;
  const uniqueElementId = uid();

  let filterDisplay = "";
  filters.forEach((filter) => {
    // join the members with a "||" in case the filter values have commas in their names
    const members = filter.members.join("||");
    const expression = filter.attribute.expression;
    filterDisplay += `${expression}=${members}; `;
  });

  const insightsBlockPrompt = writer.createElement("abxInsightsBlockPrompt", {
    abxInsightsWidgetTitle: title,
    abxInsightsDashboardId: dashboardId,
    abxInsightsWidgetType: widgetType,
    abxInsightsWidgetId: widgetId,
    abxInsightsFilters: filterDisplay,
    abxInsightsHeight: height,
    abxInsightsWidth: width,
    abxInsightsShowIndicatorTitles: showIndicatorTitles,
    uniqueElementId: uniqueElementId,
  });

  return insightsBlockPrompt;
}
