(function () {
  angular
    .module("akitabox.desktop.workOrder.detail")
    .controller(
      "WorkOrderScheduleTabController",
      WorkOrderScheduleTabController
    );

  /* @ngInject */
  function WorkOrderScheduleTabController(
    // Angular
    $timeout,
    // Services
    ScheduleService,
    ToastService,
    // Resolve
    building,
    workOrder
  ) {
    var self = this;

    // Attributes
    self.loading = true;
    self.rendering = true;
    self.building = building;
    self.workOrder = workOrder;
    self.schedule = null;

    init();

    function init() {
      var scheduleId = workOrder.future_task._id;
      ScheduleService.getById(building._id, scheduleId)
        .then(function (schedule) {
          self.schedule = schedule;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
          $timeout(function () {
            self.rendering = false;
          }, 500);
        });
    }
  }
})();
