(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.reopen")
    .controller(
      "ReopenWorkOrderDialogController",
      ReopenWorkOrderDialogController
    );

  /* @ngInject */
  function ReopenWorkOrderDialogController(
    // Angular
    $q,
    // Material
    $mdDialog,
    // Services
    ToastService,
    WorkOrderService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    // have to set default false in order to run tests since workOrderIDs is passed in through locals
    self.multiple = self.workOrders ? self.workOrders.length > 1 : false;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.reopen = reopen;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Reopen work orders
     */
    function reopen() {
      self.saving = true;
      self.determinateValue = 0;
      var increment = (1 / self.workOrders.length) * 100;
      var promiseArray = [];

      // Reopen the work orders
      for (var i = 0; i < self.workOrders.length; i++) {
        // The `building` structure in `workOrders[]` might be:
        // - a string value representing the building id;
        // - or an object including an `_id` property.
        const buildingId =
          self.workOrders[i].building._id || self.workOrders[i].building;

        promiseArray[i] = WorkOrderService.reopen(
          buildingId,
          self.workOrders[i]._id
        ).then(function (removedWorkOrder) {
          self.determinateValue += increment;
          return removedWorkOrder._id;
        });
      }

      $q.all(promiseArray)
        .then(function (removedWorkOrderIDs) {
          $mdDialog.hide(removedWorkOrderIDs);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
