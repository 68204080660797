(function () {
  angular
    .module("akitabox.core.services.tree", [
      "angular-storage",
      "akitabox.core.services.http",
    ])
    .constant("TREE_STORE", "tree")
    .factory("TreeService", TreeService);

  /* @ngInject */
  function TreeService($q, HttpService, store, TREE_STORE) {
    var service = {
      getByPinField: getByPinField,
    };

    return service;

    function getByPinField(pinField) {
      var TreeStore = store.getNamespacedStore(TREE_STORE);
      var tree = TreeStore.get(pinField.name);

      if (pinField.data_type !== "tree") {
        // Makes sure they passed in a tree type pin field
        return $q.reject(new Error("data_type is not tree"));
      } else if (pinField.acceptable_enum_values.length > 0) {
        // Don't go grabbing the tree if it is stored with the pinField
        return $q.resolve(angular.copy(pinField.acceptable_enum_values));
      } else if (!angular.isEmpty(tree)) {
        // The tree was in the cache, just use it
        return $q.resolve(angular.copy(tree));
      }

      // This should only happen on the big ass tree, and only once
      var url = pinField.uri.split("/");

      url[5] = "trees";
      url = url.join("/");

      return HttpService.get(url).then(function (tree) {
        TreeStore.set(pinField.name, tree); // cache that mother

        return $q.resolve(tree);
      });
    }
  }
})();
