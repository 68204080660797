(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostLineAssetDetailsSection", {
      bindings: {
        asset: "<abxAsset",
      },
      controllerAs: "vm",
      controller: AbxCostLineAssetDetailsSectionController,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/cost-line-asset-details-section/cost-line-asset-details-section.component.html",
    });

  /* @ngInject */
  function AbxCostLineAssetDetailsSectionController(
    $timeout,
    // Services
    models,
    AssetService,
    AttachmentService,
    BuildingService,
    ToastService,
    // Third-party
    moment
  ) {
    const _private = {};
    // =================
    // Properties
    // =================

    const CONDITION_GRADES_VERBIAGE =
      models.CONDITION.CONDITION_GRADES_VERBIAGE;
    const DATE_FORMAT = "MM/DD/YYYY";
    const VALID_IMAGE_EXTENSIONS = models.DOCUMENT.FILE_PREVIEW_SUPPORTED_TYPES;

    /**
     * @property { Asset }
     * Asset with all necessary information.
     */
    this.loadedAsset;

    /**
     * @property { Building }
     * The asset's building.
     */
    this.building;

    /**
     * @property { boolean } loading
     * Show Load Indication in init
     */
    this.loading = true;

    /**
     * @property { boolean } imageLoading
     * Show Asset Image Load Indication in init
     */
    this.imageLoading = true;

    this.attachments = [];
    this.currentDocument;
    this.currentAttachmentPosition = 1;

    // =================
    // Lifecycle
    // =================

    this.$onInit = () => {
      _private.fetchBuilding(this.asset.building);
      this.loading = true;
      AssetService.getById(
        this.asset.building,
        this.asset._id,
        { include_values: true },
        { includePinType: true, includeFloor: true, includeRoom: true }
      )
        .then((asset) => {
          this.loading = false;
          this.loadedAsset = asset;
          _private.fetchAttachments(asset._id, asset.building);
        })
        .catch((err) => {
          ToastService.showError(err);
          this.loading = false;
        });
    };

    // ================
    // Utils
    // ================

    /**
     * Get building and attach to this.building
     *
     * @param {String} id Building ID
     *
     * @return {Promise}  Promise with building
     */
    _private.fetchBuilding = (id) => {
      return BuildingService.getById(id).then((building) => {
        this.building = building;
        return building;
      });
    };

    /**
     * Get attachments for the asset and initialize document
     *
     * @param {String} assetId Asset ID
     * @param {String} buildingId Building ID
     *
     */
    _private.fetchAttachments = (assetId, buildingId) => {
      const params = {
        entity_id: assetId,
        entity_type: "asset",
      };
      this.imageLoading = true;
      AttachmentService.getAll(buildingId, params)
        .then((_attachments) => {
          if (!angular.isEmpty(_attachments)) {
            const filteredAttachments = _attachments.filter((attachment) =>
              VALID_IMAGE_EXTENSIONS.includes(
                attachment.document.extension.toLowerCase()
              )
            );
            this.attachments = filteredAttachments;
            this.currentDocument = this.attachments[0].document;
          }
        })
        .catch(ToastService.showError)
        .finally(() => {
          this.imageLoading = false;
        });
    };

    this.onDocumentPagination = (moveForward) => {
      let currentDocument = this.currentDocument;
      const move = moveForward ? 1 : -1;
      const attachmentCurrentIndex = this.attachments.findIndex(
        (_attachment) => _attachment.document._id === currentDocument._id
      );

      if (attachmentCurrentIndex >= 0) {
        // Check if it's the last item of the carousel. If yes, return to
        let newIndex =
          attachmentCurrentIndex + move === this.attachments.length
            ? 0
            : attachmentCurrentIndex + move;
        // If it should return to the last position
        newIndex = newIndex === -1 ? this.attachments.length - 1 : newIndex;
        this.imageLoading = true;
        this.currentDocument = this.attachments[newIndex].document;
        this.currentAttachmentPosition = newIndex + 1;
        $timeout(() => {
          this.imageLoading = false;
        }, 10);
      } else {
        ToastService.showError("Document selected is not available");
      }
    };

    /**
     * Get the display value for the asset condition
     */
    this.getConditionDisplayValue = () => {
      if (!this.loadedAsset.condition) return null;
      return CONDITION_GRADES_VERBIAGE[
        this.loadedAsset.condition.condition_rating
      ];
    };

    this.getFieldValue = (field) => {
      let fieldValue = "";

      const assetPinField = this.loadedAsset.pinType.fields.find(
        (_field) => _field.name === field.name
      );
      if (!assetPinField) {
        return fieldValue;
      }

      const assetPinFieldValue = this.loadedAsset.values[assetPinField._id];
      if (!assetPinFieldValue) {
        return fieldValue;
      }

      fieldValue = assetPinFieldValue.value;

      if (fieldValue === null || fieldValue === undefined) {
        fieldValue = "";
      }
      if (fieldValue && field.data_type === "date") {
        fieldValue = moment(fieldValue).format(DATE_FORMAT);
      }
      return fieldValue;
    };

    this.isFieldHidden = (field) => {
      if (field.is_hidden) {
        // The field has been hidden by the user
        return true;
      } else if (
        field.data_type === "tag_filter" ||
        field.data_type === "document_array" ||
        field.data_type === "tree" ||
        field.data_type === "room" ||
        field.data_type === "level"
      ) {
        // shouldn't display tag filters, trees, or document arrays
        return true;
      }
      return false;
    };
  }
})();
