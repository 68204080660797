(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.pagination
   */
  angular
    .module("akitabox.ui.directives.pagination", ["ngMaterial"])
    .controller("AbxPaginationController", AbxPaginationController)
    .directive("abxPagination", AbxPaginationDirective);

  /**
   * @ngdoc directive
   * @module akitabox.ui.directives.pagination
   * @name AbxPaginationDirective
   *
   * @restrict E
   *
   * @description
   * `<abx-pagination>` is a component used to navigate between a set of pages
   *
   * @param {Number}      abx-num-pages       Total number of pages
   * @param {Expression}  abx-on-page-change  Callback when a page is requested
   *
   * @usage
   * <hljs lang="html">
   *   <abx-pagination
   *       abx-num-pages="vm.numPages"
   *       abx-on-page-change="vm.onPageChange(page)">
   *   </abx-pagination>
   * </hljs>
   *
   * @ngInject
   */
  function AbxPaginationDirective() {
    return {
      restrict: "E",
      templateUrl: "app/core/ui/directives/pagination/pagination.html",
      replace: true,
      controller: "AbxPaginationController",
      controllerAs: "vm",
      bindToController: true,
      scope: {
        numPages: "=abxNumPages",
        onPageChange: "&abxOnPageChange",
      },
    };
  }

  /**
   * @ngdoc controller
   * @module akitabox.ui.directives.pagination
   * @name AbxPaginationController
   *
   * @description
   * Controller for abx-pagination
   *
   * @ngInject
   */
  function AbxPaginationController($scope, $q) {
    var self = this;

    // Attributes
    self.page = 0;
    self.pages = new Array(self.numPages);
    self.loading = false;

    // Functions
    self.goToPage = goToPage;

    init();

    function init() {
      $scope.$watch("vm.numPages", function (newVal) {
        if (angular.isUndefined(newVal)) return;
        self.pages = newVal === 0 ? new Array(1) : new Array(newVal);
        if (self.page >= self.pages.length) {
          self.goToPage(self.pages.length - 1);
        }
      });
    }

    function goToPage(page, event) {
      if (page < 0 || page >= self.pages.length) return;
      self.page = page;
      self.loading = true;
      $q.resolve(self.onPageChange({ page: page, event: event })).finally(
        function () {
          self.loading = false;
        }
      );
    }
  }
})();
