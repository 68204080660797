(function () {
  angular
    .module("akitabox.ui.dialogs.workOrder.create")
    .controller(
      "CreateWorkOrderDialogController",
      CreateWorkOrderDialogController
    );

  /* @ngInject */
  function CreateWorkOrderDialogController(
    // Angular
    $q,
    $scope,
    // Third-party
    $stateParams,
    // Material
    $mdDialog,
    // Libraries
    moment,
    // Constants
    models,
    PRIORITIES,
    INTENT_MAINTENANCE_TYPE,
    // Services
    Router,
    AssetService,
    FloorService,
    InspectionService,
    IssueTypeService,
    OrganizationService,
    PinValueService,
    RoomService,
    TimeZoneService,
    ToastService,
    TradeService,
    TypeService,
    UserService,
    WorkOrderService,
    ChecklistTemplateService,
    // Helpers
    Utils
  ) {
    var self = this;

    var _permissions = UserService.getPermissions();

    var assigneesRequest;
    var tradesRequest;

    var multipleFloorsProvided = false;
    var multipleRoomsProvided = false;
    var multipleAssetsProvided = false;

    // set if the create WO dialog is launched from an inspection stop - will
    // store the `round` and skip the location tab of the dialog
    var fromStopList = self.fromStopList;

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.defaultDueDateIntervalsMap = getDefaultIntervalMap();
    // Locations for the updated WO dialog
    self.locations = [{}];
    self.formData = {
      priority: "medium",
      estimated_man_hr: 1,
    };
    self.activeTab = 0;
    self.loading = true;
    self.saving = false;
    self.progress = 0;
    self.showProgress = false;
    self.utcOffset = "";

    self.today = {};
    self.startDate = {};
    self.dueDate = {};

    self.locationProvided = false;
    self.newWorkOrder = null;
    self.minDate = self.today;
    self.workOrderTypes = [];
    self.tradeTypes = [];
    self.attachments = [];
    self.showCustomField = self.organization.show_custom_srp_field;
    self.customFieldLabel = self.organization.srp_custom_label;
    self.customFieldOptions = self.showCustomField
      ? self.organization.srp_custom_options
      : [];
    self.showChecklists = self.organization.show_inspections;
    self.multiLocationEnabled = self.organization.show_inspections;
    self.checklistTemplate = null;

    self.model = models.WORK_ORDER.PLURAL;
    self.fromRequest = Boolean(self.request);
    self.assignees = [];
    self.locationIsDuplicated = false;

    // Details
    self.canAddAttachments = _permissions.task.add_attachment;
    self.priorities = [];

    // Functions
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.next = next;
    self.previous = previous;
    self.fetchTypes = fetchTypes;
    self.fetchTrades = fetchTrades;
    self.fetchAssignees = fetchAssignees;
    self.onTradeChange = onTradeChange;
    self.onTypeChange = onTypeChange;
    self.onChange = onChange;
    self.onBlur = onBlur;
    self.isDisabled = isDisabled;
    self.handleFilesChange = handleFilesChange;
    self.handleAddLocationClick = handleAddLocationClick;
    self.handleRemoveLocationClick = handleRemoveLocationClick;
    self.handleChecklistChange = handleChecklistChange;
    self.hasValidLocations = hasValidLocations;

    self.onAssigneesChange = function onAssigneesChange(changeEvent) {
      self.assignees = changeEvent.value;
      return $q.resolve();
    };

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Initialize the dialog, fetch floors
     */
    function init() {
      Object.keys(PRIORITIES).forEach(function (k) {
        self.priorities.push({
          model: k,
          value: PRIORITIES[k],
        });
      });

      if (self.fromRequest) {
        // Initialize formData if we've been given a request
        if (self.multiLocationEnabled) {
          if (self.request.level) self.locations[0].floor = self.request.level;
          if (self.request.room) self.locations[0].room = self.request.room;
          if (self.request.asset) self.locations[0].asset = self.request.asset;
        } else {
          if (self.request.level) self.floor = self.request.level;
          if (self.request.room) self.room = self.request.room;
          if (self.request.asset) self.asset = self.request.asset;
        }

        if (self.request.issue_type) {
          self.type = self.request.issue_type;
        }

        if (self.request.custom_srp_value) {
          self.customFieldValue = self.request.custom_srp_value;
        }
        self.formData.description_text = self.request.description;
        self.formData.subject = self.request.subject;

        // Leaving this intact for now - creating WOs for multiple selected assets
        // should not change
      } else if (!angular.isEmpty(self.floors) && self.floors.length > 1) {
        multipleFloorsProvided = true;
        self.count = self.floors.length;
      } else if (!angular.isEmpty(self.rooms) && self.rooms.length > 1) {
        multipleRoomsProvided = true;
        self.count = self.rooms.length;
      } else if (!angular.isEmpty(self.assets) && self.assets.length > 1) {
        multipleAssetsProvided = true;
        self.count = self.assets.length;
      }

      if (self.building) {
        let promises = [];
        promises.push(
          setTradeOptions(self.building),
          setTypeOptions(self.building),
          setDefaults()
        );

        updateTimeZoneDate(self.building.time_zone_offset);

        $q.all(promises)
          .catch(ToastService.showError)
          .finally(function () {
            self.loading = false;
          });
      } else {
        self.loading = false;
      }

      if (self.showChecklists) {
        fetchChecklists();
      }

      /**
       * Seems to be no other solution to actively watch this without setting objectEquality -> true
       * We can wait until they click "Create" to tell them they have duplicate locations, but it seems
       * too late already by that point. Also using $watchCollection here seems to not work with an array
       * of objects, since it only compares the objects inside of an array via shallow comparison
       */
      $scope.$watch(
        "dialog.locations",
        function (watchedLocations) {
          if (!watchedLocations || watchedLocations.length <= 1)
            return (self.locationIsDuplicated = false);

          var hasDuplicates = false;
          var duplicateLocations = {};
          for (var i = 0; i < watchedLocations.length; i++) {
            var watchedLocation = watchedLocations[i];
            var watchedAsset = watchedLocation.asset
              ? watchedLocation.asset._id
              : "_";
            var watchedFloor = watchedLocation.floor
              ? watchedLocation.floor._id
              : "_";
            var watchedRoom = watchedLocation.room
              ? watchedLocation.room._id
              : "_";
            var hash = watchedAsset + watchedFloor + watchedRoom;
            if (duplicateLocations[hash]) {
              // if we already have this location, mark it as duplicated
              self.locations[i].duplicated = true;
              hasDuplicates = true;
            } else {
              // otherwise, allow blank locations or add the new unique location
              // to our map for tracking
              if (hash === "___") {
                continue;
              }
              duplicateLocations[hash] = [i];
              self.locations[i].duplicated = false;
            }
          }
          self.locationIsDuplicated = hasDuplicates;
        },
        true
      );
    }

    function onBuildingChange() {
      // Clear existing data that depends on building
      // Floor, room, and asset clear themselves
      self.type = null;
      self.trade = null;
      self.assignees = [];
      // Clear any outstanding requests and cached options
      assigneesRequest = null;
      tradesRequest = null;
      self.workOrderTypes = null;
      self.workOrderTypeOptions = null;
      self.trades = null;
      self.tradeOptions = null;

      setTradeOptions(self.building);
      setTypeOptions(self.building);

      setDefaults();
    }

    function handleChecklistChange(event) {
      self.checklistTemplate = event.model;
    }

    /**
     * Set default floor, room, asset, type, trade, and assignees
     * according to the data provided to this dialog and the building defaults
     */
    function setDefaults() {
      var promises = [fetchAssignees()];

      if (self.showType) {
        fetchTypes().then(function () {
          // Wait to trigger type change until types have been fetched
          if (self.type) {
            onTypeChange();
          } else {
            fetchTrades();
          }
        });
      }
      if (self.showTrade) {
        fetchTrades();
      }

      if (
        multipleFloorsProvided ||
        multipleRoomsProvided ||
        multipleAssetsProvided ||
        fromStopList
      ) {
        // skip the location tab if locations have been provided
        self.activeTab = 1;
        self.locationProvided = true;
        self.loading = false;
      } else {
        // Populate the first item in self.locations
        if (self.multiLocationEnabled) {
          if (!angular.isEmpty(self.assets)) {
            self.locations[0].asset = self.assets[0];
            // In general, we already have the self.level under self.asset
            // but we need the list to include the level
            promises.push(setFloorWithAsset(), setRoomWithAsset());
          } else if (!angular.isEmpty(self.rooms)) {
            self.locations[0].room = self.rooms[0];
            promises.push(setFloorWithRoom());
          } else if (!angular.isEmpty(self.floors)) {
            self.locations[0].floor = self.floors[0];
          }
        } else {
          if (!angular.isEmpty(self.assets)) {
            self.asset = self.assets[0];
            if (!self.floor) {
              promises.push(setFloorWithAsset());
            }
            if (!self.room) {
              promises.push(setRoomWithAsset());
            }
          } else if (!angular.isEmpty(self.rooms)) {
            self.room = self.rooms[0];
            promises.push(setFloorWithRoom());
          } else if (!angular.isEmpty(self.floors)) {
            self.floor = self.floors[0];
          }
        }
      }

      return $q.all(promises);
    }

    function setRoomWithAsset() {
      var asset = self.asset ? self.asset : self.locations[0].asset;
      if (asset.room && asset.room._id) {
        self.room = asset.room;
        self.room.building = asset.building;
      } else {
        return AssetService.getRoom(self.building._id, asset.values).then(
          function (room) {
            if (self.locations[0]) {
              self.locations[0].room = room || null;
            }
            self.room = room || null;
          }
        );
      }
    }

    function setFloorWithAsset() {
      var asset = self.asset ? self.asset : self.locations[0].asset;
      if (asset.level && asset.level._id) {
        FloorService.getById(
          asset.building._id || asset.building,
          asset.level._id || asset.level
        ).then(function (level) {
          self.floor = level || null;
        });
      } else {
        return AssetService.getFloor(self.building._id, asset.values).then(
          function (floor) {
            if (self.locations[0]) {
              self.locations[0].floor = floor || null;
            }
            self.floor = floor || null;
          }
        );
      }
    }

    function setFloorWithRoom() {
      var room = self.room ? self.room : self.locations[0].room;
      return room._id
        ? $q(function (resolve, reject) {
            self.floor = room.level;
            self.floor.building = room.building;
            if (self.locations[0]) {
              self.locations[0].floor = self.floor || null;
            }
            resolve();
          })
        : RoomService.getFloor(self.building._id, room.values).then(function (
            floor
          ) {
            if (self.locations[0]) {
              self.locations[0].floor = floor || null;
            }
            self.floor = floor || null;
          });
    }

    /**
     * Go to the newly created work order's detail page
     */
    function goToNewWorkOrder() {
      var stateParams = {
        buildingId: $stateParams.buildingId ? self.building._id : null,
        workOrderId: self.newWorkOrder._id,
      };
      Router.go("app.workOrder", stateParams);
    }

    /**
     * Set trade shown and required flags according to the building
     *
     * @param {Building} building Selected building
     */
    function setTradeOptions(building) {
      self.showTrade = building ? building.show_trades : false;
      self.requireTrade = building ? building.require_trades : false;
    }

    /**
     * Set type shown and required flags according to the building
     *
     * @param {Building} building Selected building
     */
    function setTypeOptions(building) {
      // Show Types and Trades
      if (self.fromRequest) {
        self.showType = building.show_issue_types;
      } else {
        self.showType =
          building.show_issue_types || building.show_maintenance_types;
      }

      // Require Types and Trades
      self.requireType =
        building.require_issue_types && building.require_maintenance_types;
    }

    function handleAddLocationClick() {
      self.locations.push({});
    }

    function handleRemoveLocationClick(location) {
      if (self.locations.length > 1) {
        var indexToRemove = self.locations.indexOf(location);
        self.locations.splice(indexToRemove, 1);
      } else {
        self.locations = [{}];
      }
    }

    function getDefaultIntervalMap() {
      var defaultIntervals = self.organization.work_order_due_date_defaults;
      var map = {};

      if (defaultIntervals) {
        for (var i = 0; i < defaultIntervals.length; i++) {
          var defaultInterval = defaultIntervals[i];
          map[defaultInterval.priority] = defaultInterval;
        }
      }

      return map;
    }

    function setDefaultDueDate(startDate) {
      var defaultInterval =
        self.defaultDueDateIntervalsMap[self.formData.priority];

      var startMoment = moment(startDate);
      var dueDate;

      if (defaultInterval) {
        var intervalNumber = defaultInterval.interval;
        var intervalType = defaultInterval.interval_type;
        dueDate = startMoment
          .clone()
          .add(intervalNumber, intervalType)
          .toDate();
      } else {
        dueDate = startMoment.clone().add(1, "days").toDate();
      }

      return dueDate;
    }

    function updateTimeZoneDate(utcOffset) {
      self.utcOffset = utcOffset;
      var nowMoment = moment();
      if (self.utcOffset) {
        nowMoment.utcOffset(self.utcOffset);
      }
      var startMoment = nowMoment.clone().startOf("day");
      self.today = startMoment.toDate();
      self.startDate = self.today;
      self.dueDate = setDefaultDueDate(self.startDate);
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Handle field change
     *
     * @param {Object} $event blur event
     * @param {String} field  Field to update
     */
    function onBlur($event, field) {
      switch (field) {
        case "start_date":
          var originalStartDate = self.startDate;
          self.startDate = $event.newValue;
          if (self.startDate > self.dueDate) {
            // keep previous duration between start and due date
            var difference = moment(self.dueDate).diff(
              moment(originalStartDate),
              "days"
            );
            self.dueDate = moment(self.startDate)
              .add(difference, "days")
              .toDate();
          }
          if (self.startDate > self.today) {
            // prevent selecting a dueDate before startDate
            self.minDate = self.startDate;
          } else {
            // prevent selecting a dueDate before today if startDate is in the past
            self.minDate = self.today;
          }
          break;
        default:
          self.formData[field] = $event.model;
      }
    }
    /**
     * Handle field change
     *
     * @param {Object} $event Change event
     * @param {String} type Type of update (e.g name, address)
     */
    function onChange($event, field) {
      if (
        !$event.model &&
        !["building", "floor", "room", "asset", "due_date"].includes(field)
      ) {
        return;
      }
      var changed = !Utils.isSameModel(self[field], $event.model);
      switch (field) {
        case "building":
          self.building = $event.model;
          if (changed) {
            self.floor = null;
            self.room = null;
            self.asset = null;
            if (self.building) {
              updateTimeZoneDate(self.building.time_zone_offset);
              onBuildingChange();
            }
          }
          break;
        case "asset":
          self.asset = $event.model;
          if (changed && self.asset) {
            if (!self.room) setRoomWithAsset();
            if (!self.floor) setFloorWithAsset();
          }
          break;
        case "room":
          self.room = $event.model;
          if (changed) {
            self.asset = null;
            if (self.room && !self.floor) {
              setFloorWithRoom();
            }
          }
          break;
        case "floor":
          self[field] = $event.model;
          if (changed) {
            self.room = null;
            self.asset = null;
          }
          break;
        case "type":
          self.type = $event.model;
          if (changed) {
            onTypeChange();
          }
          break;
        case "trade":
          self.trade = $event.model;
          if (changed) {
            onTradeChange();
          }
          break;
        case "due_date":
          self.dueDate = $event.model;
          break;
        case "custom_srp_value":
          self.customFieldValue = $event.model;
          break;
        case "priority":
          self.formData[field] = $event.model;
          self.dueDate = setDefaultDueDate(self.startDate);
          break;
        default:
          self.formData[field] = $event.model;
      }
    }

    /**
     * Navigate to the next tab
     */
    function next() {
      self.activeTab++;
    }

    /**
     * Navigate to the previous tab
     */
    function previous() {
      self.activeTab--;
    }

    /**
     * Create a work order
     */
    function create() {
      if (self.saving) return;
      self.saving = true;

      var amt = self.count || 1;
      self.showProgress = amt > 1;
      var increment = 100 / amt;

      var requests = [];
      for (var i = 0; i < amt; ++i) {
        var data = getData(i);
        var r;

        // if this is an ad-hoc inspections, create an inspection.
        // this will also create a task and checklists
        if (data.checklist_template) {
          // inspectionService.create will return the new WO
          r = InspectionService.create(self.organization._id, data).then(
            function (workOrder) {
              if (self.showProgress) self.progress += increment;
              return workOrder;
            }
          );
          requests.push(r);
        } else {
          r = WorkOrderService.create(self.building._id, data).then(function (
            workOrder
          ) {
            if (self.showProgress) self.progress += increment;
            return workOrder;
          });
          requests.push(r);
        }
      }

      return $q
        .all(requests)
        .then(function (results) {
          if (results.length) {
            // One schedule created. Show toast with "View" action
            if (results.length === 1) {
              self.newWorkOrder = results[0];
              $mdDialog.hide([results[0]]);
              ToastService.complex()
                .text("Successfully created work order")
                .action("View", goToNewWorkOrder)
                .show();
              // Batch create. Show simple toast
            } else {
              ToastService.showSimple(
                "Successfully created " + results.length + " work orders"
              );
            }
          }
          $mdDialog.hide(results);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });

      function getData(index) {
        var data = angular.copy(self.formData);
        var roomId;
        var floorId;

        if (self.attachments.length > 0) {
          data.attachments = self.attachments.map(function (attachment) {
            return attachment._document._id;
          });
        }

        if (self.building) {
          data.building = self.building._id;
        }

        data.assignees = self.assignees.map(function (assignee) {
          return assignee._id;
        });

        data.start_date = self.startDate.getTime();
        data.due_date = self.dueDate.getTime();

        if (self.fromRequest) {
          data.request = self.request._id;
          if (self.organization.show_custom_srp_field) {
            data.custom_srp_value = self.request.custom_srp_value;
          }
        }
        if (self.type) {
          if (self.type.intent === INTENT_MAINTENANCE_TYPE) {
            data.maintenance_type = self.type._id;
          } else {
            data.issue_type = self.type._id;
          }
        }
        if (self.trade) {
          data.trade = self.trade._id;
        }
        if (self.customFieldValue) {
          data.custom_srp_value = self.customFieldValue;
        }

        var asset;
        var room;

        if (self.multiLocationEnabled) {
          // if multiple entities were provided, still create one work
          // order for each provided entity
          if (multipleAssetsProvided) {
            asset = self.assets[index];
            roomId = PinValueService.getRoomId(asset.values);
            floorId = PinValueService.getFloorId(asset.values);
            if (floorId) self.locations[0].floor = floorId;
            if (roomId) self.locations[0].room = roomId;
            self.locations[0].asset = asset._id;
          } else if (multipleRoomsProvided) {
            room = self.rooms[index];
            floorId = PinValueService.getFloorId(room);
            if (floorId) self.locations[0].floor = floorId;
            self.locations[0].room = room._id;
          } else if (multipleFloorsProvided) {
            self.locations[0].floor = self.floors[index]._id;
          }
        } else {
          if (multipleAssetsProvided) {
            asset = self.assets[index];
            roomId = PinValueService.getRoomId(asset.values);
            floorId = PinValueService.getFloorId(asset.values);
            if (floorId) data.level = floorId;
            if (roomId) data.room = roomId;
            data.asset = asset._id;
          } else if (multipleRoomsProvided) {
            room = self.rooms[index];
            floorId = PinValueService.getFloorId(room.values);
            if (floorId) data.level = floorId;
            data.room = room._id;
          } else if (multipleFloorsProvided) {
            data.level = self.floors[index]._id;
          } else {
            if (self.floor) data.level = self.floor._id;
            if (self.room) data.room = self.room._id;
            if (self.asset) data.asset = self.asset._id;
          }
        }

        var round = [];
        if (fromStopList) {
          // if dialog was launched from the stop list, the whole
          // round was passed in
          data.round = self.round;
        } else if (self.multiLocationEnabled) {
          // if multiLocationEnabled, parse self.locations into round
          for (var i = 0; i < self.locations.length; i++) {
            var location = self.locations[i];
            if (location.asset) round.push({ asset: location.asset });
            else if (location.room) round.push({ room: location.room });
            else if (location.floor) round.push({ level: location.floor });
          }

          data.round = round;

          delete data.asset;
          delete data.room;
          delete data.level;

          if (self.checklistTemplate) {
            data.checklist_template = self.checklistTemplate._id;
          }
        } else {
          // adHocInspections is disabled, so we're only dealing with one round
          // make sure we create WO.round instead of WO.floor, WO.room, WO.asset
          if (data.asset) round.push({ asset: data.asset });
          else if (data.room) round.push({ room: data.room });
          else if (data.level) round.push({ level: data.level });
          data.round = round;
          delete data.asset;
          delete data.room;
          delete data.level;
        }

        return data;
      }
    }

    /**
     * Get get the correct types to choose from for the building.
     *
     * @return {Promise<Array|Error>}   Resolves with types for the building, limited to Issue Types if this workOrder
     * is being created from a Service Request
     */
    function fetchTypes() {
      if (self.fromRequest) {
        return IssueTypeService.getAll(self.building._id, {}, { cache: false })
          .then(function (issueTypes) {
            self.workOrderTypes = issueTypes.map(function (it) {
              return {
                intent: "Reactive",
                model: it,
                value: it.name,
              };
            });
          })
          .catch(ToastService.showError);
      }

      return TypeService.getTypes(self.building, {}, { cache: false })
        .then(function (types) {
          self.workOrderTypes = types.map(function (it) {
            return {
              intent: it.intent,
              model: it,
              value: it.name,
            };
          });
        })
        .catch(ToastService.showError);
    }

    function handleFilesChange(files) {
      self.attachments = files;
    }

    /**
     * Get all available trades to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all trades for the building
     */
    function fetchTrades() {
      // Return outstanding request
      if (tradesRequest) return tradesRequest;

      tradesRequest = TradeService.getAll(
        self.building._id,
        {},
        { cache: false }
      )
        .then(function (trades) {
          self.tradeTypes = trades.map(function (t) {
            return {
              model: t,
              value: t.name,
            };
          });
          return trades;
        })
        .catch(ToastService.showError);
      return tradesRequest;
    }

    /**
     * Get all available assignees to choose from for the building.
     *
     * @return {Promise<Array|Error>}    Resolves with all users for the building
     */
    function fetchAssignees() {
      if (assigneesRequest) return assigneesRequest;

      assigneesRequest = UserService.getAll(self.organization._id, {
        buildings: self.building._id,
        identity: "$ne,null",
        status: "active",
        sort: "firstOrEmail,asc",
      })
        .then(function (users) {
          return users.map(function (user) {
            return user.identity;
          });
        })
        .catch(ToastService.showError);

      return assigneesRequest;
    }

    /**
     * Populates the trade input with the newly selected type's default trade,
     * iff there isn't a trade selected currently.
     */
    function onTypeChange() {
      var selectedType = self.type;
      if (
        !self.showTrade ||
        !selectedType ||
        !selectedType.trade ||
        self.trade
      ) {
        return;
      }

      fetchTrades().then(function (trades) {
        // Don't set trade if one was selected while tradesRequest was executing
        if (self.trade) return;

        // Find trade object based on the trade ID given by the new type
        var newTradeId = selectedType.trade;
        for (var i = 0; i < trades.length; ++i) {
          var trade = trades[i];
          if (trade._id === newTradeId) {
            self.trade = trade;
            onTradeChange();
            break;
          }
        }
      });
    }

    /**
     * Populates the assignees input with the newly selected trade's default assignee,
     * if there isn't an assignee selected currently.
     */
    function onTradeChange() {
      var selectedTrade = self.trade;
      if (!selectedTrade) return;

      var formHasAssignees = !!self.assignees.length;
      var tradeHasDefaultAssignee = !!selectedTrade.default_assignee;

      if (!tradeHasDefaultAssignee || formHasAssignees) {
        return;
      }

      fetchAssignees().then(function (assignees) {
        // Find assignee object based on the assignee ID given by the new trade
        var newAssigneeId = selectedTrade.default_assignee;
        for (var i = 0; i < assignees.length; ++i) {
          var assignee = assignees[i];
          if (assignee._id === newAssigneeId) {
            self.assignees = [assignee];
            break;
          }
        }
      });
    }

    function fetchChecklists() {
      var params = {};

      return ChecklistTemplateService.getAll(self.organization._id, params)
        .then(function (response) {
          self.availableChecklists = response.map(function (checklistTemplate) {
            return {
              model: checklistTemplate,
              value: checklistTemplate.name,
            };
          });
        })
        .catch(ToastService.showError);
    }

    /**
     * Determine if the form is disabled
     *
     * @return {Boolean}  True if disabled, false if not
     */
    function isDisabled() {
      return (
        self.saving ||
        self.locationIsDuplicated ||
        !self.building ||
        !self.formData.subject ||
        !self.formData.priority ||
        !self.formData.estimated_man_hr ||
        self.formData.estimated_man_hr < 0 ||
        !self.startDate ||
        typeof self.startDate === "string" ||
        !self.dueDate ||
        typeof self.dueDate === "string" ||
        (self.showType && self.requireType && !self.type) ||
        (self.showTrade && self.requireTrade && !self.trade) ||
        (self.checklistTemplate && !hasValidLocations()) ||
        !allUploadsComplete()
      );
    }

    /**
     * Check if self.locations contains valid locations for an ad-hoc inspection.
     * A location is valid if it has a floor and a room or level set
     */
    function hasValidLocations() {
      // if the dialog was opened with locations provided, return true
      if (self.locationProvided) return true;
      for (var i = 0; i < self.locations.length; i++) {
        var location = self.locations[i];
        if (!(location.room || location.asset || location.floor)) return false;
      }
      return true;
    }

    function allUploadsComplete() {
      return self.attachments.reduce(function (prev, current) {
        return prev && current.complete;
      }, true);
    }
  }
})();
