(function () {
  angular.module("akitabox.desktop.floor.list", [
    "akitabox.core.constants",
    "akitabox.core.services.filter",
    "akitabox.core.services.flag",
    "akitabox.desktop.components.filterBar",
    "akitabox.desktop.components.filterBarManager",
    "akitabox.core.services.floor",
    "akitabox.core.services.user",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.floor.create",
  ]);
})();
