(function () {
  angular
    .module("akitabox.core.services.checklistTemplate", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "akitabox.core.services.organization",
    ])
    .factory("ChecklistTemplateService", ChecklistTemplateService);

  /** @ngInject */
  function ChecklistTemplateService(
    // Angular
    $q,
    // Constants
    models,
    // Services
    HttpService
  ) {
    init();

    function init() {}

    var service = {
      get: get,
      getAll: getAll,
      getById: getById,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Fetches checklists by their organization (limited)
     * @param {String} organizationId
     * @param {Object} queryString
     * @return {$q.Promise<Checklist[]>}
     */
    function get(organizationId, queryString) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistTemplateService: organizationId required")
        );

      return HttpService.get(buildBaseRoute(organizationId), queryString);
    }

    /**
     * Fetches all checklists by their organization
     * @param {String} organizationId
     * @param {Object} queryString
     * @return {$q.Promise<ChecklistTemplate[]>}
     */
    function getAll(organizationId, queryString) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistTemplateService: organizationId required")
        );

      var useCache = false;

      // We dont use the cache becaus it's currently broken
      return HttpService.getAll(
        buildBaseRoute(organizationId),
        queryString,
        useCache
      );
    }

    function getById(organizationId, checklistTemplateId) {
      if (!organizationId)
        return $q.reject(
          new Error("ChecklistTemplateService: organizationId required")
        );
      else if (!checklistTemplateId)
        return $q.reject(
          new Error("ChecklistTemplateService: checklistId required")
        );

      return HttpService.getById(
        buildDetailRoute(organizationId, checklistTemplateId),
        checklistTemplateId,
        {},
        false
      );
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Builds the url of: /organizations/:organiationId/checklists
     * @param {String} organizationId
     * @return {String}
     */
    function buildBaseRoute(organizationId) {
      if (!organizationId) throw new Error("organizationId required");

      return (
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        "/" +
        models.CHECKLIST_TEMPLATE.ROUTE_PLURAL
      );
    }

    /**
     * Builds the url of: /organizations/:organiationId/checklistTemplates/:checklistTemplateId
     * @param {String} organizationId
     * @return {String}
     */
    function buildDetailRoute(organizationId, checklistTemplateId) {
      if (!organizationId) throw new Error("organizationId required");
      else if (!checklistTemplateId)
        throw new Error("checklistTemplateId required");

      return buildBaseRoute(organizationId) + "/" + checklistTemplateId;
    }
  }
})();
