(function () {
  angular
    .module("akitabox.core.services.fieldGroup", [
      "akitabox.constants",
      "akitabox.core.services.cacheHelpers",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("FieldGroupService", FieldGroupService);

  /** @ngInject */
  function FieldGroupService(
    // Constants
    models,
    // Services
    HttpService
  ) {
    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      // Update
      update: update,
      // Delete
      remove: remove,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildListRoute(organizationId) {
      return "/organizations/" + organizationId + "/field_groups";
    }

    function buildDetailRoute(buildingId, pinTypeId) {
      var base = buildListRoute(buildingId);
      return base + "/" + pinTypeId;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, data) {
      var route = buildListRoute(buildingId);
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getAll(buildingId, params, options) {
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, id, params) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, id, data) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.put(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, id) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.remove(route, id);
    }
  }
})();
