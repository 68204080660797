(function () {
  /**
   * @ngdoc component
   * @name abxHeaderQrScanner
   *
   * @param {Boolean} [disabled=false] - True to disable the button.
   *
   * @description
   * A component for use in the app-header to allow for scanning and manual
   * entry of QR codes.
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .component("abxHeaderQrScanner", {
      bindings: {
        disabled: "<?abxDisabled",
      },
      controller: AbxHeaderQrScannerController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/components/header-qr-scanner/header-qr-scanner.component.html",
    });

  /* @ngInject */
  function AbxHeaderQrScannerController(
    // AngularJS
    $q,
    $scope,
    // Constants
    EVENT_QR_CODE_SCANNED,
    // Utils
    media,
    // Dialogs
    QrManualEntryDialog,
    QrScannerDialog,
    // Services
    ToastService
  ) {
    var self = this;

    // Functions
    self.handleClick = handleClick;

    // ------------------------
    //   Public Functions
    // ------------------------
    /**
     * Handle QR Code button click. Present the qr code scanner if a camera is
     * available.
     */
    function handleClick() {
      checkVideoAccess()
        .then(getQrCode)
        .then(function (qrCode) {
          $scope.$emit(EVENT_QR_CODE_SCANNED, { qrCode: qrCode });
        })
        .catch(function (err) {
          if (err) {
            // Only show the toast if there was an error passed back..
            ToastService.showError(err);
          }
        });
    }

    // ------------------------
    //   Private Functions
    // ------------------------
    /**
     * Present the user with a QR code input.
     * @param {Boolean} useCamera - True to use the camera to scan a qr code.
     * @return {Promise<String>} The QR code's id.
     */
    function getQrCode(useCamera) {
      if (useCamera) {
        return QrScannerDialog.show({
          redirect: false,
          returnModel: true,
        });
      } else {
        return QrManualEntryDialog.show();
      }
    }

    /**
     * Check if there are video devices and we have permissions to use them.
     * @return {Promise<Boolean>} True iff we can use a video device.
     */
    function checkVideoAccess() {
      if (!media.isSupported()) {
        return $q.resolve(false);
      }
      return media
        .getMedia(media.QR_SCANNER_CONSTRAINTS)
        .then(function (stream) {
          media.stopStream(stream);
          return true;
        })
        .catch(function () {
          return false;
        });
    }
  }
})();
