(function () {
  angular
    .module("akitabox.ui.dialogs.qrCode.associate")
    .factory("AssociateQrCodeDialog", AssociateQrCodeDialogService);

  /* @ngInject */
  function AssociateQrCodeDialogService($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/qr-code/associate/associate-qr-code.dialog.html",
      controller: "AssociateQrCodeDialogController",
      controllerAs: "vm",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * AssociateQrCodeService
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog.
     *
     * If a button in this dialog (outside of the close button is clicked),
     * this dialog will hide the $mdDialog with an $event object. That $event
     * object will have an `actionTaken` field that can take on the following
     * values:
     * - create: A new pin was created and associated with the given QR code.
     *   $event.room or $event.asset will be populated with the new pin created.
     *   The created pin will have the populated QR code attached iff the
     *   association was successful.
     * - search: An existing pin was searched for to associate with the given
     *   QR code. $event.room or $event.asset will be populated with
     *   searched/associated pin.
     *
     * @param {Object} options - Dialog options
     * @param {Object} options.qrCode - The unassociated QR code object
     * @param {Object} [options.building] - Building to use for subsequent
     *     workflows. Examples: the building to use for pin search, the
     *     building to use for the create pin dialogs, etc.
     * @param {Object} [options.floor] - Floor to use for subsequent workflows.
     *     Example: the floor to pre-fill for create pin dialogs.
     * @param {Boolean} [options.showPlanViewActions=false] - Flag used to set the visibility of PV-specific actions
     * @param {Boolean} [options.hidePinCreateToast] - Don't show default
     *     toasts on creating a new pin to associate with the QR code
     */
    function show(options) {
      validateLocals(options.locals);
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);

      function validateLocals(locals) {
        if (!locals.qrCode) {
          throw Error(
            "AssociateQrCodeDialog: locals.qrCode is required but wasn't provided"
          );
        }
      }
    }
  }
})();
