(function () {
  angular
    .module("akitabox.core.services.pinField", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("PinFieldService", PinFieldService);

  /** @ngInject */
  function PinFieldService(models, HttpService) {
    var service = {
      // Create
      create: create,
      // Retrieve
      get: get,
      getAll: getAll,
      getById: getById,
      getTree: getTree,
      // Update
      update: update,
      // Delete
      remove: remove,
      // Misc
      getFloorPinField: getFloorPinField,
      getRoomPinField: getRoomPinField,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute(buildingId, pinTypeId) {
      return (
        "/" +
        models.BUILDING.ROUTE_PLURAL +
        "/" +
        buildingId +
        "/" +
        models.PIN_TYPE.ROUTE_PLURAL +
        "/" +
        pinTypeId
      );
    }

    function buildListRoute(buildingId, pinTypeId) {
      var base = buildBaseRoute(buildingId, pinTypeId);
      return base + "/" + models.PIN_FIELD.ROUTE_PLURAL;
    }

    function buildDetailRoute(buildingId, pinTypeId, pinFieldId) {
      var list = buildListRoute(buildingId, pinTypeId);
      return list + "/" + pinFieldId;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, pinTypeId, data) {
      var route = buildListRoute(buildingId, pinTypeId);
      return HttpService.post(route, data);
    }

    /******************* *
     *   Retrieve
     * ****************** */

    function get(buildingId, pinTypeId, params) {
      var route = buildListRoute(buildingId, pinTypeId);
      return HttpService.get(route, params);
    }

    function getAll(buildingId, pinTypeId, params) {
      var route = buildListRoute(buildingId, pinTypeId);
      return HttpService.getAll(route, params);
    }

    function getById(buildingId, pinTypeId, pinFieldId, params) {
      var route = buildDetailRoute(buildingId, pinTypeId, pinFieldId);
      return HttpService.getById(route, pinFieldId, params);
    }

    function getTree(buildingId, pinTypeId, pinFieldId) {
      var base = buildBaseRoute(buildingId, pinTypeId);
      var route = base + "/trees/" + pinFieldId;
      return HttpService.get(route);
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, pinTypeId, pinFieldId, data) {
      var route = buildDetailRoute(buildingId, pinTypeId, pinFieldId);
      return HttpService.put(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, pinTypeId, pinFieldId) {
      var route = buildDetailRoute(buildingId, pinTypeId, pinFieldId);
      return HttpService.remove(route);
    }

    /******************* *
     *   Misc
     * ****************** */

    function getFloorPinField(fields) {
      if (fields) {
        for (var i = 0; i < fields.length; ++i) {
          var field = fields[i];
          if (field.is_level_field) {
            return field;
          }
        }
      }

      return null;
    }

    function getRoomPinField(fields) {
      if (fields) {
        for (var i = 0; i < fields.length; ++i) {
          var field = fields[i];
          if (field.is_room_field) {
            return field;
          }
        }
      }

      return null;
    }
  }
})();
