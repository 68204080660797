(function () {
  /**
   * @ngdoc component
   * @name abxDateInput
   *
   * @param {Boolean} [disabled] - Disable editing of the input. Defaults to
   *     falsey.
   * @param {Function} onBlur - To be invoked when the input blurs
   * @param {Function} onChange - To be invoked when the value in the input
   *     has changed (i.e. a `keyup` event), but the input is not blurred.
   *     Should be invoked with: event.model, [event.value], [event.invalid].
   * @param {Function} onFocus - To be invoked on input focus event
   * @param {Function} onSelect - To be invoked when a date is selected
   *     from the datepicker. Should be invoked with: event.model,
   *     [event.valid].
   * @param {Boolean} [required] - Require the input. Defaults to falsey.
   * @param {String} value - Human-readable string of the currently
   *     inputted date.
   * @param {Boolean} [blurOnEnter=false] - Blur the input on Enter keypress
   *
   * @param {Function} [dateFilter] - Function expecting a date and returning a boolean whether it can be selected in "day" mode or not.
   *
   * @description
   * Input for a date field. Includes a datepicker to be used by the user on
   * demand.
   */
  angular.module("akitabox.ui.components.dateInput").component("abxDateInput", {
    bindings: {
      disabled: "<?abxDisabled",
      onBlur: "&abxOnBlur",
      onChange: "&abxOnChange",
      onFocus: "&abxOnFocus",
      onSelect: "&abxOnSelect",
      required: "<?abxRequired",
      value: "<abxValue",
      min: "<?abxMin",
      max: "<?abxMax",
      timeZoneOffset: "<?abxTimeZoneOffset",
      blurOnEnter: "<?abxBlurOnEnter",
      dateFilter: "=?abxDateFilter",
    },
    controller: AbxDateInputController,
    controllerAs: "vm",
    templateUrl: "app/core/ui/components/date-input/date-input.component.html",
  });

  function AbxDateInputController(
    // Third-party
    $mdPanel,
    moment,
    // Services
    Utils
  ) {
    var self = this;
    // Custom validator error message
    var invalidDateStringError = {
      type: "invalid",
      text: "Invalid date",
    };

    // Attributes
    self.blurOnEnter = self.blurOnEnter || false;

    // Functions
    self.handleChange = handleChange;
    self.showDatepicker = showDatepicker;

    // =================
    // Public Functions
    // =================

    /**
     * Show the datepicker.
     *
     * @param {Object} $event - Click event from the user to show datepicker
     */
    function showDatepicker($event) {
      // Prevents enter from triggering md-button clicks by blurring the button
      $event.currentTarget.blur();

      var panelConfig = getDatepickerPanelConfig($event);
      $mdPanel.open(panelConfig);
    }

    /**
     * Called on input change to notify parent of new value.
     *
     * @param {Object} event - Propagated event
     * @param {String} event.value - Formatted date that reflects the input's
     *     current value
     */
    function handleChange(event) {
      // this will sometimes return wrong dates, because javascript interprets new Date('01') as 01/01/2001
      var newDate = getDateObject(event.value, true); // (DRL 5.1)

      var isInvalid = Boolean(newDate && !Utils.isDateStringValid(event.value));

      // if it is an invalid date, send up the literal value to avoid sending the potentially wrong date
      var modelToSend = isInvalid ? event.value : newDate;

      self.onChange({
        $event: {
          model: modelToSend,
          value: event.value,
          invalid: isInvalid,
          validators: [dateValidator],
        },
      });

      /**
       * Custom abxInput validator for date string formats.
       *
       * @returns {{type: string, text: string}}
       */
      function dateValidator() {
        if (isInvalid) {
          return invalidDateStringError;
        }
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * Convert a formatted date string into a date object.
     *
     * Since giving `null` or `undefined` to the JavaScript `date` contructor
     * will return a new `Date` on the epoch date, just return `null` in that
     * case.
     *
     * @param {String} dateString - Formatted date to convert
     * @param {Boolean} maintainLocalTime - Defaults to false. Indicates whether to keep the existing time of day with respect to `self.timeZoneOffset`.
     * `false` will keep the same instant in Universal Time, but the local time will change.
     * `true` will keep the same local time, but at the expense of choosing a different point in Universal Time.
     * @return {Date|null} - Converted date, or null
     */
    function getDateObject(dateString, maintainLocalTime) {
      if (dateString && self.timeZoneOffset) {
        return moment(new Date(dateString))
          .utcOffset(self.timeZoneOffset, !!maintainLocalTime)
          .toDate();
      }
      return dateString ? new Date(dateString) : null;
    }

    /**
     * Get the config for the mdPanel to be shown for the datepicker.
     *
     * @param {Object} $event - Click to event to open the panel from
     * @return {Object} - Panel config
     */
    function getDatepickerPanelConfig($event) {
      // Open the datepicker relative to the click event
      var position = $mdPanel
        .newPanelPosition()
        .relativeTo($event.target)
        .addPanelPosition(
          $mdPanel.xPosition.ALIGN_END,
          $mdPanel.yPosition.BELOW
        );

      var date = Utils.isDateStringValid(self.value)
        ? getDateObject(self.value, false)
        : null;

      return {
        attachTo: angular.element(document.body),
        clickOutsideToClose: true,
        controller: DatepickerPanelCtrl,
        controllerAs: "panel",
        escapeToClose: true,
        focusOnOpen: false,
        locals: {
          date: date,
          minDate: self.min,
          maxDate: self.max,
          timeZoneOffset: self.timeZoneOffset,
          dateFilter: self.dateFilter,
        },
        openFrom: $event,
        panelClass: "abx-calendar-panel",
        position: position,
        templateUrl: "app/core/ui/components/date-input/datepicker-panel.html",
        zIndex: 2000,
      };
    }

    /* @ngInject */
    function DatepickerPanelCtrl($scope, mdPanelRef) {
      $scope.$watch("panel.date", function (newValue, oldValue) {
        if (newValue === oldValue) return;

        // set new value to correct time zone offset
        var newValueInTimeZone = getDateObject(newValue, true);

        self.onSelect({
          $event: { model: newValueInTimeZone, valid: true },
        });
        self.onBlur({ $event: { model: newValueInTimeZone, valid: true } });
        mdPanelRef.close();
      });
    }
  }
})();
