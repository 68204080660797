(function () {
  /**
   * @ngdoc component
   * @name abxFloorPlan
   *
   * @param {Object} floor - Floor of plan to show
   * @param {Function} onDocumentChange - Inform parent component of document
   *    changes. Invoked with {$event: {floor, document}}.
   * @param {Function} onPinSelect - Called when a pin is selected
   * @param {Function} onError - Callback invoked when the floorplan fails to load.
   * @param {Function} onLoad - Callback invoked when the floorplan is loaded.
   * @param {Number} page  - Page of floor plan to show
   * @param {Object[]} pins - Pins to show on floor plan
   * @param {Object} selectedPin - Currently selected pin
   * @param {Boolean} [mode] - current App mode; see planView module constant for list of modes
   * @param {Function} [updateSelectedPin] - Update the selected pin with $event.field & $event.newValue
   *
   * @description
   * Interactive map of a floor plan and its pins.
   */
  angular.module("akitabox.planView").component("abxFloorPlan", {
    bindings: {
      floor: "<abxFloor",
      task: "<?abxTask",
      page: "<abxPage",
      onDocumentChange: "&abxOnDocumentChange",
      onError: "&abxOnError",
      onLoad: "&abxOnLoad",
      onPrimaryPinSelect: "&abxOnPrimaryPinSelect",
      onMultiPinSelect: "&abxOnMultiPinSelect",
      pins: "<abxPins",
      selectedPin: "<abxSelectedPin",
      multiSelectedPins: "<abxMultiSelectedPins",
      mode: "<?abxMode",
      onVersionSelected: "&abxOnVersionSelected",
      versionMode: "<?abxVersionMode",
      updateSelectedPin: "&?abxUpdateSelectedPin",
      getPinIcon: "<abxGetPinIcon",
    },
    transclude: true,
    controller: AbxFloorPlanController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/floor-plan/floor-plan.component.html",
  });

  function AbxFloorPlanController(
    // Angular
    $location,
    $log,
    $q,
    $rootScope,
    $scope,
    $timeout,
    $document,
    $window,
    // Constants
    MODES,
    COMPONENT_STATE,
    VERSION_MODES,
    EVENT_FLOOR_ROTATE,
    MARKUP_TOOL,
    // Dialogs
    PrintFloorPlanDialog,
    // Services
    CancellableService,
    DocumentService,
    ServiceHelpers,
    FeatureFlagService,
    OrganizationService,
    ToastService,
    MathService,
    MarkupService,
    abxMarkupTools,
    Utils,
    // Third-party
    $state,
    $stateParams,
    Leaflet,
    paper
  ) {
    var self = this;
    self.organization = OrganizationService.getCurrent();

    /* Constants */

    // The paperjs delta is derived from the leaflet delta.  They are not independent of each other
    var LEAFLET_ZOOM_DELTA = 0.5;
    var MIN_ZOOM_LEVEL = -0.5;
    var CANCEL_REASON = {};
    var DEFAULT_ERROR_MESSAGE =
      "Sorry, we weren't able to complete your request.";

    var initPipeline = CancellableService.createPipeline(CANCEL_REASON);
    var componentState = COMPONENT_STATE.default;
    var _map;
    var imageDimensions;
    var floorPlanBounds;
    var pinMarkers = {}; // Maps pin IDs to their markers
    var pinsLayer = Leaflet.layerGroup([]);
    var selectedPinMarkers = []; // Leaflet marker of the selected pin
    var allPinNamesShown = false; // whether or not the user has the all pin names shown toggle on

    // Functions to be invoked to handle a page change
    var PAGE_CHANGE_FUNCTIONS = [
      createPageChangeState,
      imageMiddleware,
      applyState,
    ];

    // Functions to be invoked to (re)initialize the component.
    var COMPONENT_INIT_FUNCTIONS = [
      createState,
      revisionsMiddleware,
      revisionMiddleware,
      numPagesMiddleware,
      imageMiddleware,
      applyState,
    ];

    /* Attributes */
    self.MODES = MODES;
    self.disableVersionChange = false;
    self.isInspection = self.mode === MODES.INSPECTION;
    self.image = null;
    self.isOldVersion = false;

    /* Functions */
    self.loadingState = loadingState;
    self.loadedState = loadedState;
    self.errorState = errorState;
    self.zoomIn = zoomIn;
    self.zoomOut = zoomOut;
    self.togglePinNames = togglePinNames;
    self.initializeComponent = initializeComponent;
    self.resetZoom = resetZoom;
    self.rotatePlan = rotatePlan;
    self.selectNewRevision = selectNewRevision;
    self.toggleAllPins = toggleAllPins;
    self.print = print;
    self.isPinPrimarySelected = isPinPrimarySelected;
    self.isPinMultiSelected = isPinMultiSelected;

    // =================
    // Lifecycle
    // =================

    self.$onInit = function () {
      loadingState(true);
    };

    self.$onChanges = function (changes) {
      if (changes.selectedPin || changes.multiSelectedPins) {
        if (!allPinNamesShown) {
          selectedPinMarkers.forEach(function (selectedMarker) {
            if (!selectedMarker) {
              return;
            }
            hideSelectedPinTooltip(selectedMarker);
          });
        }

        var newSelectedPinMarkers = [];

        if (self.multiSelectedPins && self.multiSelectedPins.length > 1) {
          self.multiSelectedPins.forEach(function (selectedPin) {
            if (!selectedPin) {
              return;
            }
            var selectedMarker = pinMarkers[selectedPin._id];
            setPinMarkerOptions(selectedMarker, selectedPin);
            newSelectedPinMarkers.push(selectedMarker);
          });
        } else if (self.selectedPin) {
          // this is a seperate case because multiSelectedPins will always include the primarySelectedPin
          var primarySelectedPinMarker = pinMarkers[self.selectedPin._id];
          setPinMarkerOptions(primarySelectedPinMarker, self.selectedPin);
          newSelectedPinMarkers.push(primarySelectedPinMarker);
        }

        selectedPinMarkers = newSelectedPinMarkers;

        updatePinStyles();
      }

      if (changes.floor) {
        $rootScope.$broadcast(MARKUP_TOOL.ACTIONS.CLOSE_MARKUP_DRAWER);
        if (self.floor) {
          initializeComponent();
        }
      } else {
        if (changes.pins && loadedState()) {
          updateShownPins(pinsLayer);
        }
        if (changes.page && self.page && self.revision) {
          executeOnInitPipeline(PAGE_CHANGE_FUNCTIONS);
        }
      }

      if (changes.versionMode) {
        self.isOldVersion =
          changes.versionMode.currentValue !== VERSION_MODES.DEFAULT;
      }

      if (changes.mode && angular.isDefined(MODES)) {
        var currentMode = changes.mode.currentValue;
        self.disableVersionChange =
          currentMode === MODES.PIN_PLACEMENT ||
          currentMode === MODES.INSPECTION;
        self.isInspection = currentMode === MODES.INSPECTION;
      }
    };

    // =================
    // Public Functions
    // =================

    function print() {
      PrintFloorPlanDialog.show().then(printFloorPlan);
    }

    /**
     * Initialize or reinitialize the component using its current bindings.
     * Cancels any other outstanding reinitializations.
     */
    function initializeComponent() {
      executeOnInitPipeline(COMPONENT_INIT_FUNCTIONS);
    }

    function toggleAllPins($event) {
      if ($event.hidePins) {
        _map.removeLayer(pinsLayer);
        return;
      }
      _map.addLayer(pinsLayer);
      updateShownPins(pinsLayer);
    }

    /**
     * A callback for the user changing which revision of the document
     * they wish to view
     *
     * @param {Object} $event           Change event
     * @param {Object} $event.revision  New revision
     */
    function selectNewRevision($event) {
      // Update the state and query parameters to match the new revision
      if ($event.revision === self.currentRevision) {
        $stateParams.version = null;
        $location.search("version", null);
      } else {
        $stateParams.version = $event.revision.document_rev_num;
        $location.search("version", $event.revision.document_rev_num);
      }

      var setupState = function () {
        return createState({
          revision: $event.revision,
          dismissVersionWarning: false,
        });
      };

      executeOnInitPipeline([setupState, imageMiddleware, applyState]);
    }

    /**
     * Getter/Setter. Either evaluate if the state is loading, or set it to
     * loading.
     */
    function loadingState(set) {
      if (set) componentState = COMPONENT_STATE.loading;
      return componentState === COMPONENT_STATE.loading;
    }

    /**
     * Getter/Setter. Either evaluate if the state is loaded, or set it to
     * loaded.
     */
    function loadedState(set) {
      if (set) {
        componentState = COMPONENT_STATE.success;
        self.onLoad();
      }
      return componentState === COMPONENT_STATE.success;
    }

    /**
     * Getter/Setter. Either evaluate if the state is error, or set it to
     * error.
     */
    function errorState(set) {
      if (set) {
        componentState = COMPONENT_STATE.error;
        self.onError();
      }
      return componentState === COMPONENT_STATE.error;
    }

    /**
     * Zooms in on the map
     */
    function zoomIn() {
      _map.zoomIn(LEAFLET_ZOOM_DELTA);
    }

    /**
     * Zooms out on the map
     */
    function zoomOut() {
      _map.zoomOut(LEAFLET_ZOOM_DELTA);
    }

    /**
     * Reset to original zoom
     */
    function resetZoom(animate) {
      // Account for viewport changes before moving
      _map.invalidateSize();
      var options = {
        duration: 0.2,
      };
      if (animate === false) {
        options.animate = false;
      }
      _map.flyToBounds(floorPlanBounds, options);
    }

    /**
     * Rotates the document by 90 degrees, switching between 0/90/180/270.
     */
    function rotatePlan() {
      const floor = self.floor;
      if (!floor) return;

      const { rotation: oldRotation } = floor.document;
      const newRotation = oldRotation === 270 ? 0 : oldRotation + 90;

      self.loadingState(true);

      DocumentService.rotate(
        $stateParams.buildingId,
        floor.document._id,
        newRotation
      )
        .then((document) => {
          // Notify parent component of the new document for the floor
          self.onDocumentChange({
            $event: { document, floor },
          });
          $scope.$emit(EVENT_FLOOR_ROTATE, {
            floor,
            newRotation,
          });
        })
        .catch((err) => {
          ToastService.showError(err);
          self.loadedState(true);
        });
    }
    // =================
    // Private Functions
    // =================

    /**
     * Executes a cancellable series of the given functions, cancelling any
     * outstanding work in the initPipeline. Any rejection reason besides the
     * CANCEL_REASON sentinel will be forwarded to handleError. The component
     * will be in the loading state for the duration.
     *
     * @param {Function[]} functions - Functions to run, see
     *    CancellableService.executeSeries for details.
     */
    function executeOnInitPipeline(functions) {
      loadingState(true);
      initPipeline
        .switchTo(CancellableService.executeSeries(functions))
        .catch(function (err) {
          if (err !== CANCEL_REASON) {
            $log.error(err);
            handleError(DEFAULT_ERROR_MESSAGE);
          }
        });
    }

    function handleError(err) {
      errorState(true);
      ToastService.showError(err);
    }

    /**
     * Initialize the Leaflet map. Add a layer for pins.
     */
    function initializeMap() {
      _map = Leaflet.map("floor-plan", {
        crs: Leaflet.CRS.Simple,
        zoomControl: false,
        doubleClickZoom: false,
        minZoom: MIN_ZOOM_LEVEL,
        zoomSnap: 0.5,
      });

      _map.addLayer(pinsLayer);
    }

    /**
     * Initialized Markup tool by attaching PaperJS singleton to a Leaflet pane
     *
     */
    function initializeMarkupTool() {
      if (self.markupInitialized) {
        paper.project.clear();
        return;
      }

      _map.on("zoomstart movestart", function () {
        abxMarkupTools.startMapEvent();
      });
      _map.on("zoomend moveend", function () {
        abxMarkupTools.endMapEvent();
      });

      self.markupInitialized = true;

      var floorPlanContainer = $document[0].getElementById("floor-plan");
      var mapContainer = _map.getContainer();

      var defaultPaneElevation = 400;
      var drawingPaneElevation = 800;

      // Create canvas for PaperJS to anchor
      var paperCanvas = (self.paperCanvas = Leaflet.DomUtil.create("canvas"));
      Leaflet.DomUtil.addClass(paperCanvas, "inactive");

      paperCanvas.width = floorPlanContainer.clientWidth;
      paperCanvas.height = floorPlanContainer.clientHeight;
      paperCanvas.style.boxSizing = "border-box";
      paperCanvas.style.position = "absolute";
      paperCanvas.style.top = "0";
      paperCanvas.style.left = "0";

      mapContainer.appendChild(paperCanvas);

      paper.setup(paperCanvas);

      paper.view.onFrame = function () {
        var rotation = self.floor.document.rotation;
        var bounds = _map.getBounds();

        var cameraHeight = bounds._northEast.lat - bounds._southWest.lat;
        // var cameraWidth = bounds._northEast.lng - bounds._southWest.lng;

        var zoomRatio = floorPlanContainer.clientHeight / cameraHeight;

        var imageHeight = self.image.naturalHeight;
        var imageWidth = self.image.naturalWidth;

        var leafletImage = $document[0].getElementsByClassName(
          "leaflet-image-layer"
        )[0];
        var trueRatio = leafletImage.height / imageHeight;

        // Dimensions before rotations
        var originalHeight;
        var originalWidth;
        // We rotate around the center of the image, so
        // we need to adjust our translate to account for
        // rectangular images
        var rotationTranslate;
        if (rotation % 180 === 0) {
          originalHeight = imageHeight;
          originalWidth = imageWidth;
          rotationTranslate = 0;
        } else {
          originalHeight = imageWidth;
          originalWidth = imageHeight;
          rotationTranslate = (originalWidth - originalHeight) / 2;
        }

        // We need to subtract the height at leaflet "zoom level 1" which
        // is not always the same as the naturalHeight of the image
        var canvasTop = bounds._northEast.lat - leafletImage.height / zoomRatio;
        var canvasLeft = 0 - bounds._southWest.lng;

        var transform = new paper.Matrix();
        transform.scale(trueRatio, new paper.Point(0, 0));
        transform.translate(
          // Move farther to adjust for rotation
          (canvasLeft / trueRatio) * zoomRatio - rotationTranslate,
          // Move farther down to adjust for rotation
          (canvasTop / trueRatio) * zoomRatio + rotationTranslate
        );
        transform.rotate(rotation, originalWidth / 2, originalHeight / 2);

        if (!paper.view.matrix.equals(transform)) {
          paper.view.matrix = transform;
        }
      };

      /**
       * Set pane's appropriate layer elevation
       *
       * 400 is a nice elevation as the pane will be bellow markers/tooltips
       * and yet above the floor plan.
       */
      paperCanvas.style.zIndex = defaultPaneElevation;

      $scope.$on(
        MARKUP_TOOL.ACTIONS.DRAWER_TOOL_SELECTED,
        function (event, isSelected) {
          if (!isSelected) {
            _map.dragging.enable();
            Leaflet.DomUtil.addClass(paperCanvas, "inactive");
            Leaflet.DomUtil.removeClass(paperCanvas, "hide-cursor");
            paperCanvas.style.zIndex = defaultPaneElevation;

            return;
          }

          _map.dragging.disable();
          Leaflet.DomUtil.addClass(paperCanvas, "hide-cursor");
          Leaflet.DomUtil.removeClass(paperCanvas, "inactive");
          paperCanvas.style.zIndex = drawingPaneElevation;
        }
      );
      self.prevZoom = _map.getZoom(); // set the initial zoom level when this feature initializes
    }

    /**
     * Reset floor plan layers.
     */
    function resetMap(map) {
      map.eachLayer(function (layer) {
        if (layer === pinsLayer) return;
        map.removeLayer(layer);
      });
    }

    /**
     * Download an image. This will load it into the browser's memory. This is
     * important to note, because when we give the `src` of the image to Leaflet
     * later, it won't have to make another request for it, since it will
     * already be in memory.
     *
     * @param  {String} url - URL of image to load
     * @return {Promise<Object>} - Resolves with HTMLImageElement for loaded image
     */
    function loadImage(url) {
      return $q(function (resolve, reject) {
        var img = new Image();
        img.onload = function () {
          resolve(this);
        };
        img.onerror = function (err) {
          reject(err);
        };
        img.src = url;
      });
    }

    /**
     * Add a background layer and the floor plan image layer to the map.
     */
    function addFloorPlanLayers(map) {
      imageDimensions = calculateDimensions(self.image, map);

      // Add a white background for the floor plan (to handle transparent images)
      var backgroundBounds = [
        [0, 0], // bottom-left
        [0, imageDimensions.width], // bottom-right
        [imageDimensions.height, imageDimensions.width], // top-right
        [imageDimensions.height, 0], // top-left
      ];
      var backgroundOptions = {
        color: "white",
        fillOpacity: 1,
      };
      var background = Leaflet.polygon(backgroundBounds, backgroundOptions);
      // Add the floor plan image
      floorPlanBounds = [
        [0, 0], // bottom-left
        [imageDimensions.height, imageDimensions.width], // top-right
      ];

      let imageOverlayOptions = {
        interactive: true, // Needed to get coords relative to the actual floorPlan image
        zIndex: 1000,
      };

      // It is necessary assign a value to crossOrigin allowing access to image pixel data
      // ,needed for createImageBitmapm.

      imageOverlayOptions.crossOrigin = "anonymous";

      // Make the zIndex really high to force it on top of the background
      var floorPlan = Leaflet.imageOverlay(
        self.image.src,
        floorPlanBounds,
        imageOverlayOptions
      );

      var baseLayer = Leaflet.layerGroup([background, floorPlan]);

      map.addLayer(baseLayer);

      floorPlan.on("click", function (event) {
        if (!isInPlacementMode() || !self.selectedPin) {
          return;
        }
        var rotation = self.floor.document.rotation;
        // getBounds().getNorthEast() on the image layer always returns the height/width of the image
        var planImageBounds = floorPlan.getBounds();
        var planNEBound = planImageBounds.getNorthEast();
        var planDimensions = {
          height: planNEBound.lat,
          width: planNEBound.lng,
        };

        var clickPercents = MathService.unconvertAndUnrotatePinCoords(
          event.latlng,
          planDimensions,
          rotation
        );

        self.updateSelectedPin({
          $event: [
            { field: "page", newValue: self.page },
            { field: "percentX", newValue: clickPercents.x },
            { field: "percentY", newValue: clickPercents.y },
          ],
        });
      });

      map.fitBounds(floorPlanBounds);
    }

    /**
     * Calculate the dimensions for an image within the container that holds
     * our Leaflet map.
     *
     * - If the image's original dimensions are smaller than the map's
     *   dimensions, retain the original dimensions.
     * - Otherwise, shrink the image until it is entirely visible within
     *   the map's dimensions, while retaining the aspect ratio.
     *
     * @param  {Object} img - HTMLImageElement to calculate dimensions for
     * @return {Object} - Dimensions (with a `height` and `width`)
     */
    function calculateDimensions(img, map) {
      var container = map.getContainer();
      var height = img.height;
      var width = img.width;

      // Resize to fit the height within the container first
      if (height > container.clientHeight) {
        height = container.clientHeight;
        width = (img.width / img.height) * height;
      }

      // Then, if it's still too wide, resize based on the width
      if (width > container.clientWidth) {
        width = container.clientWidth;
        height = (img.height / img.width) * width;
      }

      return {
        height: height,
        width: width,
      };
    }

    /**
     * Show our current pins.
     *
     * Currently works as simply as possible, by removing all current markers on
     * the map, and then adding in new ones for every pin we have. If this
     * turns out to not be performant enough in the future, we can remove/add
     * pins more intelligently. But as far as can be told, there are is no
     * significant performance impact, and the markers don't even flicker on
     * and off, like one might expect.
     */
    function updateShownPins(layer) {
      layer.eachLayer(function (pinMarker) {
        layer.removeLayer(pinMarker);
      });
      pinMarkers = {};

      // Add a new marker for each pin
      self.pins.forEach(function (pin) {
        var marker;

        let pin_icon = self.getPinIcon(pin);

        var pinTypeIconHtml = getPinTypeIconHtml(pin_icon);

        var icon = Leaflet.divIcon({
          html: pinTypeIconHtml,
          iconSize: [24, 24],
          className: "abx-pin-div-icon",
        });

        var rotation = self.floor.document.rotation;
        var latLng = MathService.convertAndRotatePinCoords(
          pin,
          imageDimensions,
          rotation
        );
        marker = Leaflet.marker(latLng, { icon: icon, riseOnHover: true });
        setPinMarkerOptions(marker, pin);
        marker._abxPin = pin;

        pinMarkers[pin._id] = marker;
        layer.addLayer(marker);

        // Add the color once its on the DOM and we can manipulate the element
        colorPin(marker, pin.color);
        if (self.isInspection) {
          drawInspectionStopStatus(marker, pin.inspection_status);
        }
      });

      updatePinStyles();
    }

    /**
     * Get the HTML to use the for the icon for a pin type
     *
     * @param {String} Icon - PinIcon for the icon to return
     * @return {String} - HTML for an `img` for the pin type icon
     */
    function getPinTypeIconHtml(icon) {
      var pinIcon = "<img src='/img/pin_icons/" + icon + ".png'></img>";
      var failCircle = "<i class='material-icons fail'>clear</i>";
      var passCircle = "<i class='material-icons pass'>done</i>";
      var skipLine = "<div class='skip'></div>";
      return pinIcon + failCircle + passCircle + skipLine;
    }

    /**
     * Color the given pin marker
     *
     * @param {Object} marker - Leaflet marker for the pin
     * @param {String} color - Color to set pin to in hex format
     */
    function colorPin(marker, color) {
      var iconWrapper = marker.getElement();
      if (iconWrapper) {
        iconWrapper.style.backgroundColor = color;
      }
    }

    function drawInspectionStopStatus(marker, status) {
      var iconWrapper = marker.getElement();
      if (iconWrapper) {
        for (var i = 0; i < iconWrapper.childNodes.length; i++) {
          var child = iconWrapper.childNodes[i];
          if (child.classList.contains(status)) {
            child.classList.add("show");
          } else {
            child.classList.remove("show");
          }
        }
      }
    }

    /**
     * Sets options for a Leaflet marker to be associated with a pin (e.g.
     * Leaflet events, tooltip, etc.).
     *
     * @param {Object} pinMarker - Leaflet marker for the pin
     * @param {Object} pin - Pin represented by the marker
     */
    function setPinMarkerOptions(pinMarker, pin) {
      if (!pinMarker || !pin) return;

      var isPrimarySelectedPin = self.isPinPrimarySelected(pin._id);
      var isMultiSelectedPin = self.isPinMultiSelected(pin._id);
      var isHighlighted = isPrimarySelectedPin || isMultiSelectedPin;
      if (isHighlighted) {
        selectedPinMarkers.push(pinMarker);
      }

      bindTooltip(
        pinMarker,
        pin.display_name,
        isHighlighted || allPinNamesShown
      );

      // Clears existing click events
      pinMarker.off("click");
      pinMarker.on("click", showPinDetail);

      function showPinDetail(event) {
        // We are either in PIN_PLACEMENT mode
        // or a previous click is still loading
        // so, do not allow selected pin to change
        const isLoading = self.loadingState();
        if (isInPlacementMode() || isLoading) return;

        // Distinguish between cmd/ctrl click and "regular" clicks
        const nativeEvent = event.originalEvent;
        const isControlClick =
          !!nativeEvent && (nativeEvent.ctrlKey || nativeEvent.metaKey);

        const propagateClickedPin = (clickedPin) => {
          // If this isn't a cmd/ctrl click, then use single pin selected logic
          if (!isControlClick) {
            // wrap this in an evalAsync so angular knows to trigger a digest cycle
            $scope.$evalAsync(() => {
              self.onPrimaryPinSelect({
                $event: { pin: clickedPin },
              });
            });
          } else {
            // wrap this in an evalAsync so angular knows to trigger a digest cycle
            $scope.$evalAsync(() => {
              self.onMultiPinSelect({
                $event: { pin: clickedPin },
              });
            });
          }
        };

        // The selected pin was clicked, unselect
        if (pin && self.isPinPrimarySelected(pin._id)) {
          return propagateClickedPin(null);
        }

        // Get data from clicked pin
        const { pinType, building, _id: pinId } = pin;
        const buildingId = building._id || building;
        const PinService = ServiceHelpers.parsePinService(
          pinType || { protected_type: "Asset" }
        );

        self.loadingState(true);

        // Using getById here to fetch extra data based on BFF response
        PinService.getById(
          buildingId,
          pinId,
          {
            include_values: true,
          },
          {
            includePinType: true,
            includeFloor: true,
            includeRoom: true,
          }
        )
          .then(propagateClickedPin)
          .catch(ToastService.showError)
          .finally(() => self.loadedState(true));
      }
    }

    /**
     * Binds a tooltip to the given marker.
     *
     * @param {Object} marker - Leaflet marker
     * @param {String} content - Tooltip content
     * @param {Boolean} isPermanent - Tooltip should stay visible, even when not
     *     being hovered on
     */
    function bindTooltip(marker, content, isPermanent) {
      marker.unbindTooltip();
      marker.bindTooltip(content, {
        direction: "auto",
        permanent: isPermanent,
        className: "abx-tooltip",
      });
    }

    /**
     * Makes the selected pin's tooltip not permanent (will now only be
     * visible on hover).
     */
    function hideSelectedPinTooltip(marker) {
      if (!marker) return;

      // Since Leaflet does not allow modifying an existing tooltip's options,
      // grab the existing tooltip, modify its options, and bind it back.
      var tooltip = marker.getTooltip();
      tooltip.options.permanent = false;
      marker.unbindTooltip();
      marker.bindTooltip(tooltip);
    }

    /**
     * Update the pin styles based on if there's currently a selected pin or
     * not.
     */
    function updatePinStyles() {
      if (selectedPinMarkers && selectedPinMarkers.length) {
        applyToAllPinMarkers(dimPin);
        styleSelectedPins();
      } else {
        applyToAllPinMarkers(brightenPin);
      }

      if (allPinNamesShown) {
        for (var pinId in pinMarkers) {
          var marker = pinMarkers[pinId];
          marker.openTooltip();
        }
      }
    }

    /**
     * Apply an action to all the shown pin markers
     *
     * @param {Function} - Action callback to perform on each marker
     */
    function applyToAllPinMarkers(action) {
      for (var pinId in pinMarkers) {
        var marker = pinMarkers[pinId];
        var $marker = angular.element(marker.getElement());
        action($marker);
      }
    }

    /**
     * Brighten the given pin marker
     *
     * @param {Object} $pinMarker - jQuery element of the pin marker
     */
    function brightenPin($pinMarker) {
      $pinMarker.removeClass("abx-pin-div-icon--dimmed");
      $pinMarker.removeClass("abx-pin-div-icon--selected");
    }

    /**
     * Dim the given pin marker
     *
     * @param {Object} $pinMarker - jQuery element of the pin marker
     */
    function dimPin($pinMarker) {
      $pinMarker.removeClass("abx-pin-div-icon--selected");
      $pinMarker.addClass("abx-pin-div-icon--dimmed");
    }

    /**
     * Style the currently selected pins
     */
    function styleSelectedPins() {
      selectedPinMarkers.forEach(function (selectedPinMarker) {
        if (!selectedPinMarker) {
          return;
        }
        var $iconWrapper = angular.element(selectedPinMarker.getElement());
        $iconWrapper.addClass("abx-pin-div-icon--selected");
        $iconWrapper.removeClass("abx-pin-div-icon--dimmed");
      });
    }

    /**
     * Toggle the shown state for all pin tooltips, preserving
     * the state of the selected pin's tooltip
     */
    function togglePinNames() {
      for (var pinId in pinMarkers) {
        var marker = pinMarkers[pinId];
        if (allPinNamesShown) {
          // respect the always-open selected pin tool tip
          if (
            self.selectedPin &&
            (self.isPinMultiSelected(marker._abxPin._id) ||
              self.isPinPrimarySelected(marker._abxPin._id))
          ) {
            bindTooltip(marker, marker._abxPin.display_name, true);
          } else {
            bindTooltip(marker, marker._abxPin.display_name, false);
            marker.closeTooltip();
          }
        } else {
          bindTooltip(marker, marker._abxPin.display_name, true);
          marker.openTooltip();
        }
      }
      allPinNamesShown = !allPinNamesShown;
    }

    function isPinPrimarySelected(pinId) {
      return !!self.selectedPin && self.selectedPin._id === pinId;
    }

    function isPinMultiSelected(pinId) {
      return (
        !!self.multiSelectedPins &&
        self.multiSelectedPins.some(function (p) {
          return p._id === pinId;
        })
      );
    }

    /**
     * Checks whether the Plan View's mode is set to MODES.PIN_PLACEMENT
     * Returns false if MODES isn't set, which will be the case if ANYTHING other than Plan View
     * consumes this component.
     *
     * @returns {boolean}
     */
    function isInPlacementMode() {
      if (angular.isDefined(MODES)) {
        return self.mode === MODES.PIN_PLACEMENT;
      }
      return false;
    }

    /**
     * Spread the given state onto the controller, and account for any necessary
     * side-effects such as resetting leaflet and updating shown pins. Primarily
     * intended to be used as a final step in a cancellable pipeline. This
     * function is strictly synchronous. All asynchronous work should be done
     * upstream of this function.
     *
     * @param {Object} finalState - The state to apply to the component
     */
    function applyState(finalState) {
      var changes = {};

      for (var key in finalState) {
        changes[key] = {
          previousValue: self[key],
          currentValue: finalState[key],
        };
        self[key] = finalState[key];
      }

      if (changes.image) {
        if (_map) {
          resetMap(_map);
        } else {
          initializeMap();
        }
        addFloorPlanLayers(_map);

        // Must be loaded AFTER the floor plan because it requires the floor being present
        var organization = OrganizationService.getCurrent();
        if (
          organization &&
          organization.show_markup_tool &&
          !self.isInspection
        ) {
          // only initialize this if they've enabled the tool
          initializeMarkupTool();

          MarkupService.loadActiveMarkups(
            self.floor.building,
            self.floor._id
          ).then(function () {
            MarkupService.selectLatestUserMarkup();
          });
        }
      }
      updateShownPins(pinsLayer);
      loadedState(true);

      if (
        changes.revision &&
        changes.revision.previousValue !== changes.revision.currentValue
      ) {
        self.onVersionSelected({
          $event: {
            revision: self.revision,
            current: self.currentRevision === self.revision,
          },
        });
      }
    }

    /**
     * Extend the given state with necessary data for component initialization.
     *
     * @param {Object} [fromState={}] - The state to extend.
     * @return {Object} - The given state, extended with the current page and
     *    floor.
     */
    function createState(fromState) {
      if (!fromState) {
        fromState = {};
      }
      var page = self.page;
      if (isNaN(page)) {
        page = 1;
      }
      fromState.floor = self.floor;
      fromState.page = page;
      return fromState;
    }

    /**
     * Create the initial state needed by the PAGE_CHANGE_FUNCTION middlewares.
     * @return {Object} - A state presenting the current floor, page, revisions,
     *    revision, and numPages.
     */
    function createPageChangeState() {
      return createState({
        revision: self.revision,
      });
    }

    /**
     * Initialization middleware, populates intermediateState.revisions
     *
     * @param {Object} intermediateState - The state to read/write from.
     *    intermediateState.floor must be set.
     * @return {Promise<Object>} A promise that resolves with the (mutated)
     *    intermediateState.
     */
    function revisionsMiddleware(intermediateState) {
      if (!intermediateState || !intermediateState.floor) {
        throw new Error("Cannot fetch revisions without a floor.");
      }
      var floor = intermediateState.floor;

      // Floor has no floor plan
      if (!floor.document || !floor.document._id) {
        return intermediateState;
      }

      var revParams = {
        has_binary_change: true,
      };

      return DocumentService.getRevisions(
        floor.building,
        floor.document._id,
        revParams
      ).then(function (revisions) {
        // Need at least one revision in order to have/fetch a floor plan
        if (angular.isEmpty(revisions)) {
          $state.go("planView.building.floors", {
            buildingId: floor.building,
          });
          return $q.reject("No floor plans found");
        }

        intermediateState.revisions = revisions;
        return intermediateState;
      });
    }

    /**
     * Initialization middleware, populates intermediateState.revision and
     * intermediateState.currentRevision
     *
     * @param {Object} intermediateState - The state to read/write from.
     *    intermediateState.floor and intermediateState.revisions must be set.
     * @return {Promise<Object>} A promise that resolves with the (mutated)
     *    intermediateState.
     */
    function revisionMiddleware(intermediateState) {
      if (
        intermediateState &&
        intermediateState.floor &&
        !intermediateState.floor.document
      ) {
        // In the case where we have a floor but not a floor plan, just short circuit, we'll add a placeholder file later
        return intermediateState;
      }
      if (
        !intermediateState ||
        !intermediateState.floor ||
        !intermediateState.revisions
      ) {
        throw new Error(
          "Cannot select a revision without a floor and revisions list"
        );
      }
      var revisionNum = parseInt($stateParams.version, 10);
      var revisions = intermediateState.revisions;

      // Find the revision by matching against the revision's `document_rev_num`
      intermediateState.currentRevision = null;
      intermediateState.revision = null;

      revisions.forEach(function (revision) {
        // Found the specified revision
        if (revision.document_rev_num === revisionNum) {
          intermediateState.revision = revision;
        }

        // Grab the "current" revision in case we don't find a match
        var isCurrent =
          revision.binary_commit ===
          intermediateState.floor.document._last_binary_commit;
        if (isCurrent) {
          intermediateState.currentRevision = revision;
        }
      });

      // Invalid revision for the floor, so just use the "current" one
      if (!intermediateState.revision) {
        $stateParams.version = null;
        $location.search("version", null);
        intermediateState.revision = intermediateState.currentRevision;
      }

      return intermediateState;
    }

    /**
     * Initialization middleware, populates intermediateState.image
     *
     * @param {Object} intermediateState - The state to read/write from.
     *    intermediateState.floor and intermediateState.page must be set.
     * @return {Promise<Object>} A promise that resolves with the (mutated)
     *    intermediateState.
     */
    function imageMiddleware(intermediateState) {
      if (
        intermediateState &&
        intermediateState.floor &&
        !intermediateState.floor.document
      ) {
        // add placeholder floor plan
        return loadImage("img/no-floor-plan.svg").then(function (img) {
          intermediateState.image = img;
          return intermediateState;
        });
      }
      if (!intermediateState || !intermediateState.revision) {
        throw new Error("Cannot fetch floor plan image without revision");
      }

      let documentUrl;
      if (
        intermediateState.revision &&
        intermediateState.revision.public_thumbnail_url_display
      ) {
        documentUrl = intermediateState.revision.public_thumbnail_url_display;
      } else if (intermediateState.floor && intermediateState.floor.document) {
        documentUrl =
          intermediateState.floor.document.public_thumbnail_url_display;
      }

      return loadImage(documentUrl).then(function (img) {
        intermediateState.image = img;
        return intermediateState;
      });
    }

    /**
     * Initialization middleware, populates intermediateState.numPages
     *
     * @param {Object} intermediateState - The state to read/write from.
     *    intermediateState.revision must be set.
     * @return {Promise<Object>} A promise that resolves with the (mutated)
     *    intermediateState.
     */
    function numPagesMiddleware(intermediateState) {
      if (
        intermediateState &&
        intermediateState.floor &&
        !intermediateState.floor.document
      ) {
        intermediateState.numPages = 1;
        return intermediateState;
      }
      if (!intermediateState || !intermediateState.revision) {
        throw new Error("Cannot fetch number of pages without a revision.");
      }

      intermediateState.numPages = 1;
      return intermediateState;
    }

    /**
     * Print the floor plan
     *
     * "current_view" - prints current positioning and zoom level
     * "fit"          - fits the floor plan to the bounds of the printed page
     */
    function printFloorPlan(type) {
      switch (type) {
        case "download":
          $window.location.href = self.revision.public_url;
          break;
        case "current_view":
          printCurrentView();
          break;
        case "fit":
        default:
          printFittedFloorPlan();
          break;
      }
    }

    /**
     * Print the currently visible area of the floor plan.
     */
    async function printCurrentView() {
      const $planViewContent = angular.element(
        $window.document.querySelector("abx-plan-view-content")
      );

      const $mdContent = angular.element(
        $window.document.querySelector("md-content.abx-plan-view__content")
      );

      const image = document.querySelector(".leaflet-image-layer");

      async function getBase64Image() {
        const { x, y, width, height } = image.getBoundingClientRect();
        const factor = image.naturalWidth / width;
        const planViewRect = $planViewContent[0].getBoundingClientRect();
        const clippedImage = await createImageBitmap(
          image,
          (x < planViewRect.x ? planViewRect.x - x : 0) * factor,
          (y < planViewRect.y ? planViewRect.y - y : 0) * factor,
          (width < planViewRect.width ? width : planViewRect.width) * factor,
          (height < planViewRect.height ? height : planViewRect.height) * factor
        );
        const canvas = document.createElement("canvas");
        canvas.width = clippedImage.width;
        canvas.height = clippedImage.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(clippedImage, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        return dataURL;
      }

      const dataURL = await getBase64Image();

      const currentViewImage = document.createElement("img");
      currentViewImage.className = "current-view-only show-print";
      currentViewImage.src = dataURL;

      $mdContent[0].insertAdjacentElement("afterbegin", currentViewImage);

      $planViewContent.addClass("no-print");

      // Wait, then print, then remove image element and css class
      return $timeout(function () {
        return Utils.print(5000);
      }, 1000).then(function () {
        currentViewImage.remove();
        $planViewContent.removeClass("no-print");
      });
    }

    /**
     * Print the current version of the floor plan. Will include pins with graphics enabled.
     */
    function printFittedFloorPlan() {
      var PAGE_WIDTH = 1126;
      var PAGE_HEIGHT = 1484;
      var MARGIN_TOP = 64;
      var MARGIN_BOTTOM = 135; // I have no idea what this is

      var imageHeight = self.image.naturalHeight;
      var imageWidth = self.image.naturalWidth;
      var isLandscape = imageWidth > imageHeight;

      var height = isLandscape ? PAGE_WIDTH : PAGE_HEIGHT;
      var width = isLandscape ? PAGE_HEIGHT : PAGE_WIDTH;

      var $planViewContent = angular.element(
        $window.document.querySelector("abx-floor-plan")
      );

      $planViewContent.css({
        width: width + "px",
        height: height - MARGIN_BOTTOM - MARGIN_TOP + "px",
      });

      $planViewContent.addClass("fitted-print");

      self.resetZoom(false);
      // Wait, then print, then remove the class and recalculate viewport
      return $timeout(function () {
        return Utils.print(5000);
      }, 1000).then(function () {
        $planViewContent.removeClass("fitted-print");
        $planViewContent.css({
          width: "inherit",
          height: "inherit",
        });
        _map.invalidateSize();
      });
    }
  }
})();
