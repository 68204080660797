(function () {
  angular.module("akitabox.desktop.building", [
    "angular-cache",
    "ui.router",
    "akitabox.core.router",
    "akitabox.core.services.building",
    "akitabox.core.services.env",
    "akitabox.core.services.floor",
    "akitabox.core.services.pinType",
    "akitabox.core.services.tagCategory",
    "akitabox.core.toast",
    "akitabox.desktop.building.admin",
    "akitabox.desktop.building.detail",
    "akitabox.ui.dialogs.document.select",
    "akitabox.ui.components.dashboard",
  ]);
})();
