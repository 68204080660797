(function () {
  /**
   * @ngdoc component
   * @name abxMultiPinDetailSidebar
   *
   * @param {Function} onClose - Function called on sidebar close.
   * @param {Array} pins - An array of the pins to display.
   * @param {Boolean} isShown - Used to show/hide the sidebar
   *
   * @fires pin:duplicate Indicates the desire to duplicate a pin
   *
   * @description
   * Sidebar display for the details of a pin.
   *
   * NOTE: Will currently disable floor inputs for all pins.
   */
  angular.module("akitabox.planView").component("abxMultiPinDetailSidebar", {
    bindings: {
      onClose: "&abxOnClose",
      pins: "<abxPins",
      isShown: "<?abxIsShown",
      versionMode: "<abxVersionMode",
      building: "<abxBuilding",
      floor: "<abxFloor",
      buildingPinTypes: "<?abxPinTypes",
    },
    controller: AbxPinMultiDetailSidebarController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/multi-pin-detail-sidebar/multi-pin-detail-sidebar.component.html",
  });

  /* @ngInject */
  function AbxPinMultiDetailSidebarController(
    // Angular
    $q,
    $window,
    $timeout,
    $location,
    $mdSidenav,
    // Dialogs
    BulkEditPinFieldDialog,
    BulkAddRelationshipsDialog,
    PinFieldFloorConfirmationDialog,
    // Services
    ToastService,
    UserService,
    // Constants
    MODES
  ) {
    var self = this;

    // Constants
    var ROOM_PROTECTED_TYPE = "Room";

    /**
     * @private User permissions
     * @type {Object}
     */
    const permissions = UserService.getPermissions();

    // Attributes
    self.rooms = [];
    self.assets = [];
    self.totalSquareFeet = 0;
    self.MODES = MODES;
    self.permissions = {
      canEditRoom: permissions.room.update,
      canEditAsset: permissions.asset.update,
    };

    // Functions
    self.close = close;
    self.bulkEdit = bulkEdit;
    self.bulkAddRelationships = bulkAddRelationships;

    // Async lookup for sidenav instance;
    // allows us to run our close function when the escape key closes the sidebar
    $q.when($mdSidenav("abx-multi-pin-detail-sidebar", true)).then(function (
      instance
    ) {
      // On close callback to handle close, backdrop click, or escape key pressed.
      // Callback happens BEFORE the close action occurs.
      instance.onClose(function () {
        close();
      });
    });

    // =================
    // Life Cycle
    // =================
    /**
     * Handle changes of provided bindings.
     * @param {Object} changes - A hash providing .previousValue, .currentValue,
     *    and .isFirstChange() for each binding that changed.
     */
    self.$onChanges = function (changes) {
      if (changes.pins && self.pins) {
        var pins = self.pins;
        var rooms = [];
        var assets = [];

        for (var i = 0; i < pins.length; i++) {
          var pin = pins[i];
          if (isRoom(pin)) {
            rooms.push(pin);
          } else {
            assets.push(pin);
          }
        }

        self.rooms = rooms;
        self.assets = assets;
        self.totalSquareFeet = calculateTotalSquareFeet();
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Close the sidebar.
     */
    function close() {
      self.pins = null;
      self.onClose();
    }

    // =================
    // Private Functions
    // =================

    /**
     * Determine if the pin is an room
     *
     * @return {Boolean} True if pin is an room, false if not
     */
    function isRoom(pin) {
      return pin.pinType.protected_type === ROOM_PROTECTED_TYPE;
    }

    /**
     * Returns the sum of `square_feet` in each room
     * @returns {Number} Total square feet.
     */
    function calculateTotalSquareFeet() {
      return self.rooms.reduce((accumulator, room) => {
        accumulator += room.square_feet || 0;
        return accumulator;
      }, 0);
    }

    /**
     * Takes in the `model` name as argument. Then, opens dialog
     * which allows users to bulk edit the model's pin fields
     * @param {String} model - model to be bulk edited (`rooms` or `assets`)
     */
    function bulkEdit(model) {
      let items = self.rooms;
      if (model === "assets") {
        items = self.assets;
      }

      const locals = {
        selectedItems: items,
        buildingId: self.building._id,
        model: model,
        pinTypes: self.buildingPinTypes,
      };

      BulkEditPinFieldDialog.show({ locals })
        // When bulk editing succeeds, update current (in memory) data based on what has been changed
        .then(({ model, changes }) => {
          const changedFields = Object.keys(changes);

          for (const fieldName of changedFields) {
            // Convert field name to data field, e.g.: "Square Feet" => "square_feet"
            const field = fieldName.toLowerCase().replace(/\s/g, "_");
            const fieldValue = changes[fieldName].value;

            self[model] = self[model].map((entity) => {
              entity[field] = fieldValue;
              return entity;
            });
          }

          if (model === "rooms") {
            self.totalSquareFeet = calculateTotalSquareFeet();
          }

          // Plan view should reload if floor field value have been changed
          const shouldReload = changedFields.some(
            (fieldName) => changes[fieldName].dataType === "level"
          );

          if (shouldReload) {
            // Value garanteed by shouldReload
            const floorValue = changes.floor.value._id;

            // Prompts floor change dialog if has value
            if (floorValue) {
              const currentFloor = self.floor._id;
              const selectedFloor = floorValue;
              const stayInSameFloor = () => {
                // Clear query params in order to avoid referencing old data
                $location.search("room", null);
                $location.search("asset", null);
                $timeout(() => $window.location.reload());
              };
              if (selectedFloor !== currentFloor) {
                PinFieldFloorConfirmationDialog.show({
                  onConfirmStay: stayInSameFloor,
                  onConfirmLeave: () => {
                    // Clear query params in order to avoid referencing old data
                    $location.search("room", null);
                    $location.search("asset", null);
                    $location.path(
                      `/plan/buildings/${self.building._id}/levels/${floorValue}`
                    );
                    $timeout(() => $window.location.reload());
                  },
                });
              } else {
                stayInSameFloor();
              }
            }
          }
        })
        .catch(ToastService.showError);
    }

    function bulkAddRelationships(model) {
      let items = self.rooms;
      if (model === "assets") {
        items = self.assets;
      }

      const locals = {
        selectedItems: items,
        building: self.building,
        floor: self.floor,
        model: model,
      };

      BulkAddRelationshipsDialog.show({ locals });
    }
  }
})();
