(function () {
  angular
    .module("akitabox.desktop.document.organizationList")
    .controller(
      "OrganizationDocumentListController",
      OrganizationDocumentListController
    );

  /* @ngInject */
  function OrganizationDocumentListController(
    // Angular
    $location,
    $scope,
    $q,
    // Resolve
    archived,
    organization,
    // Services
    BuildingService,
    DocumentService,
    FeatureFlagService,
    FilterService,
    ToastService,
    // Filtering
    FilterBarManager,
    ManagedBuildingFilter,
    ManagedModelFieldFilter,
    // Constants
    SUPPORTED_FILE_TYPES
  ) {
    var self = this;

    // Attributes
    self.documents = null;
    self.organization = organization;
    self.archived = archived;
    self.actions = [];
    self.activeFilters = null;

    // Functions
    self.changeFilters = changeFilters;
    self.fetchDocuments = fetchDocuments;
    self.fetchAllDocuments = fetchAllDocuments;
    self.clickTag = clickTag;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watch("vm.archived", function (value) {
        if (angular.isBoolean(value)) {
          self.archived = value;
        } else if (value) {
          self.archived = value === "true";
        }
      });

      self.filterBarManager = new FilterBarManager({
        onFilterChange: function () {
          var query = self.filterBarManager.getQuery();
          // update the list
          changeFilters(query);
        },
      });

      var nameConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "Name",
        queryField: "name",
      });

      var stringEnumOption = function (str) {
        return {
          model: str,
          value: str,
        };
      };

      var fileTypeConfig = new ManagedModelFieldFilter(self.filterBarManager, {
        displayName: "File Type",
        queryField: "extension",
        inputType: "typeahead",
        getEnumOptions: function () {
          return SUPPORTED_FILE_TYPES.map(stringEnumOption);
        },
        modelValueToChipText: function (model) {
          return model;
        },
        modelValueToFilterValue: function (model) {
          return model;
        },
        filterValueToModelValue: function (model) {
          return [model];
        },
      });

      var buildingConfig = new ManagedBuildingFilter(self.filterBarManager, {
        getOrganizationId: function () {
          return self.organization._id;
        },
      });

      self.filterBarManager.addFilterConfiguration(nameConfig);
      self.filterBarManager.addFilterConfiguration(fileTypeConfig);
      self.filterBarManager.addFilterConfiguration(buildingConfig);

      self.filterInitPromise = self.filterBarManager.applyQuery(
        $location.search()
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchDocuments(skip, limit) {
      var doFetch = function () {
        // Add archived to url if not preset in active filters
        if (
          self.activeFilters &&
          !Object.prototype.hasOwnProperty.call(self.activeFilters, "archived")
        ) {
          $location.search("archived", archived);
        }
        var params = {
          skip: skip,
          limit: limit,
          archived: archived,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return DocumentService.getByOrganization(
          organization._id,
          filters
        ).then(function (docs) {
          if (angular.isArray(self.documents)) {
            self.documents = self.documents.concat(docs);
          } else {
            self.documents = docs;
          }
          return docs.length;
        });
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function fetchAllDocuments(limit) {
      var doFetch = function () {
        var params = {
          skip: 0,
          limit: limit,
          archived: archived,
        };

        var filters = angular.extend({}, params, self.activeFilters);

        return DocumentService.getAllByOrganization(
          organization._id,
          filters
        ).then(function (docs) {
          self.documents = docs;
          return docs.length;
        });
      };
      return $q.resolve(self.filterInitPromise).then(doFetch);
    }

    function changeFilters(activeFilters) {
      $location.search(activeFilters).replace();
      self.activeFilters = activeFilters;
      self.documents = null;
      $scope.$broadcast("list:refresh");
    }

    function clickTag(tag) {
      var tagFilter = {
        key: "tags_" + tag.cat_name,
        value: tag.value_name,
        left: tag.cat_alias,
        right: tag.value_alias,
      };

      $scope.$broadcast("abxFilterBar:addFilter", tagFilter);
    }
  }
})();
