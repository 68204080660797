(function () {
  angular
    .module("akitabox.core.services.catalogItem", [
      "angular-cache",
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("CatalogItemService", CatalogItemService);

  /* @ngInject */
  function CatalogItemService(
    // Angular
    $q,
    // AkitaBox
    models,
    HttpService
  ) {
    const service = { get, getOneById };
    return service;

    function buildListRoute(catalogId) {
      return `/${models.CATALOG.ROUTE_PLURAL}/${catalogId}/${models.CATALOG_ITEM.ROUTE_PLURAL}`;
    }

    function buildDetailRoute(catalogId, catalogItemId) {
      return `/${models.CATALOG.ROUTE_PLURAL}/${catalogId}/${models.CATALOG_ITEM.ROUTE_PLURAL}/${catalogItemId}`;
    }

    /**
     * Fetches items within catalog
     *
     * @param {string} catalogId - the id of the catalog
     * @param {object} params - an object of query string parameters
     */
    function get(catalogId, params = {}) {
      if (!catalogId) {
        return $q.reject(new Error("Catalog is required"));
      }
      return HttpService.get(buildListRoute(catalogId), params);
    }

    /**
     * Fetches item
     *
     * @param {string} catalogId - the id of the catalog
     * @param {object} catalogItemId - the id of the item
     */
    function getOneById(catalogId, catalogItemId) {
      if (!catalogId) {
        return $q.reject(new Error("Catalog is required"));
      }
      return HttpService.get(buildDetailRoute(catalogId, catalogItemId));
    }
  }
})();
