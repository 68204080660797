(function () {
  angular
    .module("akitabox.ui.dialogs.message.create")
    .controller("CreateMessageDialogController", CreateMessageDialogController);

  /* @ngInject */
  function CreateMessageDialogController(
    // Angular
    $q,
    $timeout,
    // Material
    $mdDialog,
    // AkitaBox
    models,
    // Services
    ToastService,
    NoteService,
    UserService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.publicMsg = false;
    self.successful = false;
    self.formData = {
      text: "",
      emailRequester: false,
    };
    self.placeholder = "Add a message to this " + self.type;
    // Only service requests and WOs with attached requests can have public messages
    self.canBePublic =
      self.type === models.SERVICE_REQUEST.SINGULAR || self.requestId
        ? true
        : false;
    self.addButtonText = self.canBePublic
      ? "Add Message Internally"
      : "Add Message";
    self.canSendMessagesToRequester = canSendMessagesToRequester();

    // Functions
    self.cancel = $mdDialog.cancel;
    self.createNote = createNote;
    self.isValid = isValid;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Checks if user can submit note
     * the only check for now is the text length
     * @return {boolean}
     */
    function isValid() {
      return self.formData.text.length > 0;
    }

    /**
     * Sends API request to create the note
     * @return {*}
     */
    function createNote() {
      if (self.saving || self.successful) {
        return $q.resolve();
      }
      self.saving = true;
      var data = angular.copy(self.formData);

      switch (self.type) {
        case models.SERVICE_REQUEST.SINGULAR:
          data.request = self.itemId;
          break;
        case models.WORK_ORDER.SINGULAR:
          data.task = self.itemId;
          if (self.requestId) {
            data.request = self.requestId;
          }
          break;
        case models.ROOM.SINGULAR:
          data.room = self.itemId;
          break;
        case models.ASSET.SINGULAR:
          data.asset = self.itemId;
          break;
        case models.CHECKLIST.SINGULAR:
          data.checklist = self.itemId;
          data.task = self.taskId;
          // Attach the location data as well for ease of access
          if (self.roomId) {
            data.room = self.roomId;
          }
          if (self.levelId) {
            data.level = self.levelId;
          }
          if (self.assetId) {
            data.asset = self.assetId;
          }
          break;
      }

      return NoteService.create(self.building._id, data)
        .then(function (message) {
          self.successful = true;
          return $timeout(function () {
            $mdDialog.hide(message);
          }, 500);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    function canSendMessagesToRequester() {
      var permissions = UserService.getPermissions();

      return permissions.comment.create_public;
    }
  }
})();
