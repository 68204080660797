(function () {
  /**
   * @ngdoc component
   * @name abxPlanViewContent
   *
   * @param {Object} building - Currently selected building
   * @param {Object} floor - Currently selected floor plan
   * @param {Function} onDocumentChange - Notify parent component of document
   *    changes. Invoked with { $event: { document, floor }}.
   * @param {Function} onPinTypeFilterChange - To be invoked when a pin type
   *     filter is activated, deactivated, or modified
   * @param {Number} page - Page of the floor plan being viewed
   * @param {Object} pinTypeFilters - Filters for shown pins based on pin type.
   *     Should be a mapping of pin type IDs to their filter objects.
   * @param {Object[]} pinTypes - Pin types for selected building, pin type counts
   *    must be attached at pinTypes[i].pins_count
   * @param {Object[]} shownPins - Pins to show on floor plan
   * @param {Boolean} hasUnplacedPins - Whether current floor has any associated unplaced pins,
   *    used to show unplaced-pins tab before full list of unplaced pins has been retrieved
   * @param {Object[]} unplacedPins - Pins associated with the current floor that are not yet placed on the floor plan
   * @param {Function} clearSelectedPin - clear the selectedPin
   * @param {Function} setPrimarySelectedPin - set the selectedPin
   * @param {Function} updateSelectedPin - update the selectedPin
   * @param {Function} setMode - updates the PlanView's current mode
   * @param {Function} onChecklistStatusVisibilityToggle - Invoked with checklist statuses when visibility
   *  of related pins should be toggled.
   * @param {Function} getIsChecklistStatusVisible - Getter fn to dertermine if a specific
   *  status is currently hidden or visible.
   * @param {Boolean} mode - current App mode; see planView module constant for list of modes
   * @param {Boolean} [openUnplacedPinsOnInit] - set the unplaced pin component to open on initialization
   * @param {Boolean} [openMarkupToolOnInit] - set the markup tool component to open on initialization
   * @param {Object} [qrCode] - QR code scanned or input from the app header
   * @param {Object} [task] - The task to display
   * @param {(task) => Promise<void>} onTaskChange - Invoked when the task changes.
   * @param {Function} getPinIcon - Getter fn to retrieve the icon for a pin
   *
   * @description
   * Wrapper component for the sidebar and floor plan
   */
  angular.module("akitabox.planView").component("abxPlanViewContent", {
    bindings: {
      building: "<abxBuilding",
      floor: "<abxFloor",
      task: "<?abxTask",
      stops: "<abxStops",
      allStops: "<abxAllStops",
      pins: "<abxPins",
      onTaskChange: "<abxOnTaskChange",
      onPinTypeFilterChange: "&abxOnPinTypeFilterChange",
      onSelectedPinDelete: "&abxOnSelectedPinDelete",
      page: "<abxPage",
      pinTypeFilters: "<abxPinTypeFilters",
      pinTypes: "<abxPinTypes",
      shownPins: "<abxShownPins",
      hasUnplacedPins: "<abxHasUnplacedPins",
      unplacedPins: "<abxUnplacedPins",
      onDocumentChange: "&abxOnDocumentChange",
      selectedPin: "<abxSelectedPin",
      multiSelectedPins: "<abxMultiSelectedPins",
      clearSelectedPin: "&abxClearSelectedPin",
      setPrimarySelectedPin: "&abxSetPrimarySelectedPin",
      setMultiSelectedPin: "&abxSetMultiSelectedPin",
      updateSelectedPin: "&abxUpdateSelectedPin",
      onVersionSelected: "&abxOnVersionSelected",
      setMode: "&abxSetMode",
      mode: "<abxMode",
      versionMode: "<abxVersionMode",
      openUnplacedPinsOnInit: "<?abxOpenUnplacedPinsOnInit",
      openMarkupToolOnInit: "<?abxOpenMarkupToolOnInit",
      qrCode: "<?abxQrCode",
      inspectionProgram: "<abxInspectionProgram",
      onChecklistStatusVisibilityToggle:
        "&abxOnChecklistStatusVisibilityToggle",
      getIsChecklistStatusVisible: "&abxGetIsChecklistStatusVisible",
      onAttachmentUploadStart: "<?abxOnAttachmentUploadStart",
      onAttachmentUploadsFinished: "<?abxOnAttachmentUploadsFinished",
      /**
       * fn takes an object of the same type as the data param in
       * setFloor() from plan-view-app.component.js
       */
      onFloorChange: "&abxOnFloorChange",
      getPinIcon: "<abxGetPinIcon",
    },
    controller: AbxPlanViewContentController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/plan-view-content/plan-view-content.component.html",
  });

  function AbxPlanViewContentController(
    $scope,
    $location,
    // Dialogs
    QrConfirmAssignDialog,
    // Services
    OrganizationService,
    ShadowService,
    // Events,
    EVENT_ROUND_TASK_STOP_SELECTED,
    // Helpers
    Utils,
    // Constants
    MODES,
    PV_GA,
    VERSION_MODES
  ) {
    var self = this;

    // Attributes
    self.MODES = MODES;
    self.VERSION_MODES = VERSION_MODES;
    self.inInspectionMode = self.mode === MODES.INSPECTION;
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.handlePinTypeFilterChange = handlePinTypeFilterChange;
    self.hidePinFieldFilters = hidePinFieldFilters;
    self.showPinFieldFilters = showPinFieldFilters;
    self.toggleSidebar = toggleSidebar;
    self.handlePrimaryPinSelect = handlePrimaryPinSelect;
    self.handleMultiPinSelect = handleMultiPinSelect;
    self.handlePlacePin = handlePlacePin;
    self.handleFloorPlanError = handleFloorPlanError;
    self.handleFloorPlanLoad = handleFloorPlanLoad;
    self.handlePlacePinCancellation = handlePlacePinCancellation;
    self.handleFloorPlanOnLoad = handleFloorPlanOnLoad;
    self.handleFloorPlanOnError = handleFloorPlanOnError;
    self.shouldDisplayUnplacedInspectionBanner =
      shouldDisplayUnplacedInspectionBanner;
    self.handleFloorSelect = function (floor) {
      return self
        .onFloorChange({
          event: {
            floorId: floor._id,
            buildingId: floor.building,
            organization: self.organization,
            newTab: false,
            ignoreShownPins: false,
          },
        })
        .then(function () {
          // Make sure our side bar closes the selected pin and shows the list instead
          $scope.$broadcast(EVENT_ROUND_TASK_STOP_SELECTED, {
            stop: null,
          });
          // Make sure our app toggles all the stops on the floor plan back to their normal colors
          // instead of the grayed out version
          $scope.$emit(EVENT_ROUND_TASK_STOP_SELECTED, { stop: null });
        });
    };

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.mode) {
        switch (self.mode) {
          case MODES.SEARCH_RESULTS:
            toggleSidebar({ visibility: false });
            break;
          case MODES.PIN_PLACEMENT:
            toggleSidebar({ visibility: false });
            break;
          case MODES.INSPECTION:
            // Do nothing
            break;
          case MODES.DEFAULT:
          default:
            toggleSidebar({ visibility: true });
            break;
        }
      }

      // Close pinField filters when building is changed
      if (changes.building) {
        self.hidePinFieldFilters();
      }

      if (
        changes.selectedPin &&
        self.selectedPin &&
        self.mode === MODES.ASSIGN_QR_CODE
      ) {
        // User is trying to assign the QR code to the selected pin
        handleQRCodeAssignment();
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Handle changes to the pin type filter for the current sub-filter pin
     * type. Notify parent of the new pin type filter.
     *
     * @param {Object} $event - Propagated event
     * @param {Object[]} $event.pinTypeFilter - New pin type filter
     * @returns {Promise}
     */
    function handlePinTypeFilterChange($event) {
      var pinTypeFilter = $event.pinTypeFilter;

      // Empty pin field filters are the same as nothing, so just remove them
      // so we don't waste space in the query string
      if (angular.isEmpty(pinTypeFilter.pin_fields)) {
        delete pinTypeFilter.pin_fields;
      }

      return self.onPinTypeFilterChange({
        $event: { filter: pinTypeFilter, pinType: self.subfilterPinType },
      });
    }

    /**
     * Getter that determines if the banner should be displayed indicating
     * a selected round stop is unplaced or on a floor with no floor plan
     */
    function shouldDisplayUnplacedInspectionBanner() {
      if (!self.inInspectionMode || !self.selectedPin) {
        // do not display the banner if no pin is selected or we are not in round
        // mode
        return false;
      }
      var pin = self.selectedPin;
      // if the pin has no floor, or the floor has no floor plan, show banner
      if (!pin.level || !pin.level.document) {
        return true;
      } else {
        // pin has a floor, floor has a floor plan, display banner if it is
        // unplaced
        return !pin.is_placed_on_document;
      }
    }

    /**
     * Once the floor plan is loaded if we came from list view via 'Place ___ on Floor Plan'
     * we want to enter pin placement mode
     * Afterwards we delete the 'mode' querystring
     */
    function handleFloorPlanOnLoad() {
      if (
        $location &&
        $location.search().mode === "PLACEMENT" &&
        self.selectedPin
      ) {
        $location.search("mode", null);
        self.setMode({ $event: { mode: MODES.PIN_PLACEMENT } });
      }
    }

    /**
     * If we came from list view via 'Place ___ on Floor Plan' but the floor plan
     * fails to load, we still need to delete the 'mode' querystring
     */
    function handleFloorPlanOnError() {
      $location.search("mode", null);
    }

    /**
     * Hide the sidebar for filtering by a pin type's pin fields.
     */
    function hidePinFieldFilters() {
      self.subfilterPinType = null;
    }

    /**
     * For a given pin type, show its pin fields to filter the shown pins by.
     *
     * @param {Object} event - Propagated event
     * @param {String} event.pinTypeId - ID of pin type to sub-filter on
     */
    function showPinFieldFilters(event) {
      self.subfilterPinType = Utils.findModelById(
        self.pinTypes,
        event.pinTypeId
      );
    }

    /**
     * Toggle the sidebar from current value. Takes in an optional override to set the visibility explicitly.
     * @param {Object} event - Propagated event
     * @param {Boolean} [event.visibility] - Value to set visibility to
     */
    function toggleSidebar(event) {
      if (event && angular.isDefined(event.visibility)) {
        self.sidebarIsShown = event.visibility;
      } else {
        // Toggle the current visibility
        self.sidebarIsShown = !self.sidebarIsShown;
      }
    }

    /**
     * Show the pin type filter sidebar when the floorplan loads, unless we are
     * already in search mode.
     */
    function handleFloorPlanLoad() {
      if (self.mode !== MODES.SEARCH_RESULTS) {
        self.toggleSidebar({ visibility: true });
      }
    }

    /**
     * Hide the pin type filter sidebar if the floorplan errors.
     */
    function handleFloorPlanError() {
      self.toggleSidebar({ visibility: false });
    }

    /**
     * Handle opening the Pin Details sidebar when a Pin Marker is clicked in the floor-plan.
     *
     * @param {Object} $event - Propagated event
     * @param {Object} $event.pin - Pin to select
     */
    function handlePrimaryPinSelect($event) {
      self.setPrimarySelectedPin($event);
      // add to multi-slected array
      ShadowService.sendEvent(PV_GA.CATEGORY, PV_GA.ACTIONS.PIN_DETAIL);
    }

    function handleMultiPinSelect($event) {
      self.setMultiSelectedPin($event);
    }

    /**
     * Handle plan view changes resulting from placing a pin
     * @param {Object} $event - Propagated event
     * @param {Object} $event.pin - Pin to place
     */
    function handlePlacePin($event) {
      // set selectedPin
      self.setPrimarySelectedPin({ $event: { pin: $event.pin } });
      // go into pin placement mode
      self.setMode({ $event: { mode: MODES.PIN_PLACEMENT } });
    }

    /**
     * Handle plan view changes resulting from canceling placement of a pin
     */
    function handlePlacePinCancellation() {
      // go to the previous mode in the stack
      self.setMode({ $event: { mode: MODES.PREVIOUS } });
    }

    // =================
    // Private Functions
    // =================

    /**
     * Confirm QR Code assignment and notify parent
     */
    function handleQRCodeAssignment() {
      if (self.selectedPin && self.selectedPin.pinType.pinType) {
        var pinType = self.selectedPin.pinType.protected_type.toLowerCase();
        ShadowService.sendEvent("qr-code", "select-pin", pinType);
      }

      var locals = {
        qrCode: self.qrCode,
        pin: self.selectedPin,
      };
      QrConfirmAssignDialog.show({ locals: locals })
        .then(function (updatedQRCode) {
          if (updatedQRCode) {
            self.setMode({ mode: MODES.PREVIOUS });
            self.updateSelectedPin({
              $event: { field: "qrCode", newValue: updatedQRCode },
            });
          }
        })
        .catch(function () {
          self.clearSelectedPin(); // this will allow the user to try again or cancel
        });
    }
  }
})();
