(function () {
  angular
    .module("akitabox.desktop.document.detail")
    .controller(
      "DocumentVersionHistoryController",
      DocumentVersionHistoryTabController
    );

  /* @ngInject */
  function DocumentVersionHistoryTabController(
    // Angular
    $scope,
    // Resolves
    building,
    document,
    // Services
    DocumentService,
    UserService,
    // Dialogs
    DownloadDocumentDialog,
    RevertDocumentDialog
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.document = document;
    self.permissions = getDefaultPermissions();
    self.revisions = null;

    // Functions
    self.fetchRevisions = fetchRevisions;
    self.fetchAllRevisions = fetchAllRevisions;
    self.getListActions = getListActions;
    self._getDefaultPermissions = getDefaultPermissions; // exposed for unit testing

    function fetchRevisions() {
      // We don't use skip and limit because the api doesn't use them on this specific route
      var params = {
        has_binary_change: true,
      };

      return DocumentService.getRevisions(
        building._id,
        document._id,
        params
      ).then(function (revisions) {
        if (angular.isArray(self.revisions)) {
          self.revisions = self.revisions.concat(revisions);
        } else {
          self.revisions = revisions;
        }

        return 1; // always return 1 because any call for revisions will grab all of them
      });
    }

    function fetchAllRevisions(limit) {
      return self.fetchRevisions(0, limit);
    }

    function getListActions(items) {
      var actions = [];
      var revertAction = {
        text: "Revert",
        icon: "restore",
        onClick: revertDocument,
      };
      var downloadAction = {
        text: "Download",
        icon: "get_app",
        onClick: downloadDocument,
      };

      actions.push(downloadAction);

      if (items.length === 1) {
        var selectedIndex = items[0];
        var selectedRevision = self.revisions[selectedIndex];
        var isRevertable =
          selectedRevision.binary_commit !== document._last_binary_commit;

        if (self.permissions.canRevert && isRevertable)
          actions.push(revertAction);
      }

      return actions;
    }

    function getDefaultPermissions() {
      var permissions = UserService.getPermissions();
      return {
        canRevert: permissions.document.revert,
      };
    }

    function revertDocument(items) {
      var locals = {
        building: building,
        document: document,
        commitId: self.revisions[items[0]].commit,
      };

      return RevertDocumentDialog.show({ locals: locals }).then(function (
        revertedDocument
      ) {
        self.document = angular.extend(document, revertedDocument);
        $scope.$broadcast("list:refreshListActions");
      });
    }

    function downloadDocument(items) {
      var docs = [];

      for (var i = 0; i < items.length; i++) {
        docs.push(self.revisions[items[i]]);
      }

      var locals = {
        docs: docs,
      };

      return DownloadDocumentDialog.show({ locals: locals });
    }
  }
})();
