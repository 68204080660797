(function () {
  angular
    .module("akitabox.desktop.schedule.detail")
    .controller(
      "ScheduleClosedWorkOrdersTabController",
      ScheduleClosedWorkOrdersTabController
    );

  /* @ngInject */
  function ScheduleClosedWorkOrdersTabController(
    // Resolves
    building,
    schedule,
    // Services
    OrganizationService,
    WorkOrdersBFFService,
    // Constants
    WORK_ORDER_STATUS_CLOSED
  ) {
    var self = this;

    var defaultParams = {
      status: WORK_ORDER_STATUS_CLOSED,
      future_task: schedule._id,
    };

    // Attributes
    self.building = building;
    self.schedule = schedule;
    self.closedWorkOrders = null;
    self.status = WORK_ORDER_STATUS_CLOSED;
    self.organization = OrganizationService.getCurrent();

    self.fetchClosedWorkOrders = fetchClosedWorkOrders;
    self.fetchAllClosedWorkOrders = fetchAllClosedWorkOrders;

    init();

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchClosedWorkOrders(skip, limit) {
      self.loading = true;
      var params = angular.extend({}, defaultParams, {
        skip: skip,
        limit: limit,
        status: WORK_ORDER_STATUS_CLOSED,
      });

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      ).then(function (workOrders) {
        if (angular.isArray(self.closedWorkOrders)) {
          self.closedWorkOrders = self.closedWorkOrders.concat(workOrders);
        } else {
          self.closedWorkOrders = workOrders;
        }
        return workOrders.length;
      });
    }

    function fetchAllClosedWorkOrders(limit) {
      self.loading = true;
      var params = angular.extend({}, defaultParams, {
        skip: 0,
        limit: limit,
        status: WORK_ORDER_STATUS_CLOSED,
      });

      return WorkOrdersBFFService.getAllByBuilding(
        self.organization._id,
        building._id,
        params
      ).then(function (workOrders) {
        self.closedWorkOrders = workOrders;
        return workOrders.length;
      });
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      var params = angular.extend({}, defaultParams, { count: true });

      WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      ).then(function (data) {
        self.totalWorkOrders = data.count;
      });
    }
  }
})();
