(function () {
  angular.module("akitabox.ui.dialogs.document.selectBuilding", [
    "ngMaterial",
    "akitabox.core.constants",
    "akitabox.core.services.env",
    "akitabox.core.services.document",
    "akitabox.core.services.flag",
    "akitabox.core.toast",
    "akitabox.ui.components.input",
  ]);
})();
