(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.list.job
   */
  angular
    .module("akitabox.desktop.directives.list.job", [
      "akitabox.core.services.identity",
      "akitabox.core.services.organization",
      "akitabox.core.services.user",
      "akitabox.core.toast",
    ])
    .directive("abxJobList", AbxJobListDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.list.job
   * @name AbxJobList
   *
   * @restrict E
   *
   * @description
   * `<abx-job-list>` is a list of jobs in the current building.
   *
   * @usage
   *  <abx-job-list
   *          building="vm.buildings"
   *          models="vm.jobs"
   *          abx-fetch="vm.fetchJobs(skip, limit)"
   *          abx-fetch-all="vm.fetchAllJobs(limit)"
   *          abx-cancel="vm.cancelJob(job)"
   *  </abx-job-list>
   *
   * @ngInject
   */
  function AbxJobListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/job/job-list.html",
      controller: JobListController,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        fetch: "&abxFetch",
        fetchAll: "&abxFetchAll",
        permissions: "=abxPermissions",
      },
    };
  }

  /* @ngInject */
  function JobListController(
    // Angular
    $q,
    // Services
    IdentityService,
    OrganizationService,
    ToastService,
    UserService
  ) {
    var self = this;

    var userRequest = null;
    var assignees = null;

    // Attributes
    self.permissions = self.permissions || {};

    // Functions
    self.fetchJobs = fetchJobs;
    self.fetchAllJobs = fetchAllJobs;
    self.getOwner = getOwner;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      // If user is a super admin, we will fetch
      // account info via the admin route
      if (self.permissions.is_super_admin) return;
      if (self.building) {
        var organization = OrganizationService.getCurrent();
        userRequest = UserService.getAll(organization._id, {
          identity: "$ne,null",
        })
          .then(function (users) {
            assignees = users.reduce(function (accumulator, user) {
              var identity = user.identity;
              accumulator[identity._id] = identity;
              return accumulator;
            }, {});
          })
          .catch(ToastService.showError);
      }
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchJobs(skip, limit) {
      return self.fetch({
        skip: skip,
        limit: limit,
      });
    }

    function fetchAllJobs(limit) {
      return self.fetchAll({
        limit: limit,
      });
    }

    function getOwner(identityId) {
      // Find the account in building assignees if we have them
      if (assignees) {
        var identity = assignees[identityId];
        if (identity) {
          return $q.resolve(identity);
        }
        return $q.reject("Assignee not found");
      }
      if (userRequest) {
        return userRequest.then(function () {
          return assignees[identityId];
        });
      }
      // Attempt to fetch account information
      return IdentityService.getById(identityId).then(function (identity) {
        return identity;
      });
    }
  }
})();
