(function () {
  angular
    .module("akitabox.ui.components.reportingCharts.woAgeChart", [
      "akitabox.constants",
      "akitabox.core.services.chart",
      "akitabox.ui.directives.stackChart",
      "akitabox.ui.components.reportingCharts.chartLegend",
    ])
    .component("abxWoAgeChart", {
      bindings: {
        buildings: "<abxBuildings",
        startDate: "<abxStartDate",
        endDate: "<abxEndDate",
        filters: "<abxFilters",
      },
      controller: AbxWoAgeChartController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/reporting-charts/wo-age-chart/wo-age-chart.component.html",
    });

  // @ngInject
  function AbxWoAgeChartController(
    $log,
    $timeout,
    models,
    MINIMUM_RENDER_DELAY,
    ChartService
  ) {
    var self = this;

    self.config;
    self.data = [];
    self.groupKey = "_id";
    self.highlightedStack;
    self.hoverLabel = "hover label";
    self.identities = [];
    self.idTag = "wo-age-chart";
    self.legendValues = [];
    self.loading = true;
    self.showChart = false;
    self.stackSort;

    // initialized and updated whenever $onChange detects changes with self.startDate || self.endDate
    self.yAxisConfig;
    // initialized and updated whenever $onChange detects changes with self.startDate || self.endDate
    self.yAxisFilterOptions;
    self.yAxisOptions = ["# Completed", "# Open", "# Overdue"];
    self.activeYAxisOption = self.yAxisOptions[0];

    // initialized when $onChanges fires, []<String>
    self.stackOptions = [];
    self.disabledStackOptions = [];
    self.activeStackOption;

    self.onYAxisChange = function ($event) {
      // TODO: implement
      self.activeYAxisOption = $event.model;
      self.yAxisConfig = self.yAxisFilterOptions[self.activeYAxisOption];
      self.hoverLabel = self.yAxisConfig.label;
      updateFilters(self.yAxisConfig.lookupModel);
      calculateStats();
    };
    self.onStackChange = function ($event) {
      self.activeStackOption = $event.model;
      updateFilters();
      calculateStats();
    };
    self.onStackHover = function ($event) {
      self.highlightedStack = $event.value;
    };

    // ------------------------
    //   Life Cycle
    // ------------------------

    self.$onInit = function () {
      if (!self.buildings || self.buildings.length === 0) {
        return $log.error("abxAssignee: abx-buildings is required");
      }
    };

    self.$onChanges = function (changes) {
      if (changes.buildings) {
        /**
         * Every time the buildings change, we need to reset the config and stack options
         */
        self.config = ChartService.getChartConfig(
          self.buildings,
          models.WORK_ORDER.MODEL
        );
        self.stackOptions = ChartService.getStackOptions(
          models.WORK_ORDER.MODEL,
          self.buildings.length > 1,
          null,
          ["Assignee", "Work Performed By", "Time Code"]
        );
        self.activeStackOption = self.stackOptions[0];
      }

      if (changes.startDate || changes.endDate) {
        self.yAxisFilterOptions = ChartService.getYAxisConfig(
          self.yAxisOptions,
          self.startDate,
          self.endDate
        );
        self.yAxisConfig = self.yAxisFilterOptions[self.activeYAxisOption];
        self.hoverLabel = self.yAxisConfig.label;
      }

      // This should always be called AFTER self.yAxisConfig is defined
      if (changes.filters && self.yAxisConfig) {
        updateFilters(self.yAxisConfig.lookupModel);
      }

      if (
        changes.startDate ||
        changes.endDate ||
        changes.filters ||
        changes.buildings
      ) {
        calculateStats();
      }
    };

    // ------------------------
    //   Private Methods
    // ------------------------

    function updateFilters(lookupModel) {
      self.filters = ChartService.updateStackFilter(
        self.activeStackOption,
        self.filters,
        lookupModel
      );
    }

    function setShowChart() {
      var options = {
        startDate: self.startDate,
        endDate: self.endDate,
        yAxis: self.activeYAxisOption,
      };
      self.showChart = ChartService.shouldShowChart(
        self.modelName,
        ChartService.CHART_TYPE_BAR,
        self.data,
        options
      );
    }

    function calculateStats() {
      self.loading = true;
      self.showChart = false;
      if (
        self.startDate.toString() === "Invalid Date" ||
        self.endDate.toString() === "Invalid Date"
      ) {
        return;
      }

      // hide the chart while we determine the data to be shown
      self.loading = true;
      self.showChart = false;

      var params = {
        group_field: "age_group",
        start_date: ChartService.buildDateString(self.startDate, self.endDate),
      };

      self.config = ChartService.getChartConfig(
        self.buildings,
        models.WORK_ORDER.MODEL
      );
      params = angular.extend(params, self.filters, self.yAxisConfig.params);

      if (self.config.buildingInString) {
        params.buildings = self.config.buildingInString;
      }

      self.config
        .statsFunction(self.config.parentId, params)
        .then(function (results) {
          var woAgeChartTemplate = {
            "<1_days": {
              _id: "<1 Day",
            },
            "1-7_days": {
              _id: "1-7 Days",
            },
            "7-14_days": {
              _id: "7-14 Days",
            },
            "14-30_days": {
              _id: "14-30 Days",
            },
            ">30_days": {
              _id: ">30 Days",
            },
          };

          if (params.stack_field) {
            woAgeChartTemplate["<1_days"].results = [];
            woAgeChartTemplate["1-7_days"].results = [];
            woAgeChartTemplate["7-14_days"].results = [];
            woAgeChartTemplate["14-30_days"].results = [];
            woAgeChartTemplate[">30_days"].results = [];
          } else {
            woAgeChartTemplate["<1_days"].result = 0;
            woAgeChartTemplate["1-7_days"].result = 0;
            woAgeChartTemplate["7-14_days"].result = 0;
            woAgeChartTemplate["14-30_days"].result = 0;
            woAgeChartTemplate[">30_days"].result = 0;
          }

          for (var i = 0; i < results.length; i++) {
            var result = results[i];
            var resultField = Object.prototype.hasOwnProperty.call(
              result,
              "result"
            )
              ? "result"
              : "results";
            if (woAgeChartTemplate[result._id]) {
              woAgeChartTemplate[result._id][resultField] = result[resultField];
            }
          }

          var ageData = [
            woAgeChartTemplate[">30_days"],
            woAgeChartTemplate["14-30_days"],
            woAgeChartTemplate["7-14_days"],
            woAgeChartTemplate["1-7_days"],
            woAgeChartTemplate["<1_days"],
          ];

          if (!params.stack_field) {
            self.data = ageData;
          }

          return ChartService.parseStackData(
            ageData,
            params.stack_field,
            params.stack_field === "building" ? self.config.buildingMap : null,
            null,
            self.buildings
          );
        })
        .then(function (stackedData) {
          if (!stackedData) {
            return;
          }
          self.legendValues = stackedData.keys.sort(self.stackSort);
          self.data = stackedData.data;
        })
        .then(function () {
          return $timeout(angular.noop, MINIMUM_RENDER_DELAY);
        })
        .finally(function () {
          self.loading = false;
          setShowChart();
        });
    }
  }
})();
