import { useCallback, useEffect, useState } from "react";

import {
  getGridDateOperators,
  GridFilterItem,
  GridFilterOperator,
  GridRenderHeaderFilterProps,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import {
  DatePicker,
  DateRange,
  DateRangePicker,
  DateView,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import moment from "moment";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

export const DateRangeHeaderFilter = (props: GridRenderHeaderFilterProps) => {
  const { item, inputRef } = props;
  const apiRef = useGridApiContext();

  const [range, setRange] = useState<DateRange<moment.Moment>>([null, null]);
  useEffect(() => {
    if (item.value) {
      const [startRaw, endRaw] = item.value.split(",");
      const start = startRaw ? moment(startRaw) : null;
      const end = endRaw ? moment(endRaw) : null;
      setRange([start, end]);
    } else {
      setRange([null, null]);
    }
  }, [item.value]);

  const applyFilterChanges = useCallback(
    (updatedItem: GridFilterItem) => {
      if (item.value && !updatedItem.value) {
        apiRef.current.deleteFilterItem(updatedItem);
      } else {
        apiRef.current.upsertFilterItem(updatedItem);
      }
    },
    [apiRef, item]
  );

  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <DateRangePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={range}
      onError={(range) => {
        setHasError(range.some((item) => item !== null));
      }}
      onChange={(range) => {
        setRange(range);
        const [start, end] = range;
        setHasError(
          (start !== null && end === null) || (end !== null && start === null)
        );
        applyFilterChanges({
          ...item,
          value:
            start?.isValid() && end?.isValid()
              ? start.format("YYYY-MM-DD") + "," + end.format("YYYY-MM-DD")
              : null,
        });
      }}
      slots={{
        field: SingleInputDateRangeField,
        textField: TextField,
      }}
      inputRef={inputRef as React.MutableRefObject<HTMLInputElement>}
      slotProps={{
        field: {
          clearable: true,
          onClear: () => {
            setRange([null, null]);
          },
        },
        textField: {
          variant: "standard",
          error: hasError && !open,
          sx: {
            marginTop: "16px",
            width: "220px",
          },
        },
      }}
    />
  );
};

export const DatePickerFilter = (
  props: GridRenderHeaderFilterProps & { views?: DateView[] }
) => {
  const { item, inputRef, views } = props;
  const apiRef = useGridApiContext();

  const [date, setDate] = useState<moment.Moment | null>(null);
  useEffect(() => {
    if (item.value) {
      setDate(moment(item.value));
    } else {
      setDate(null);
    }
  }, [item.value]);

  const applyFilterChanges = useCallback(
    (updatedItem: GridFilterItem) => {
      if (item.value && !updatedItem.value) {
        apiRef.current.deleteFilterItem(updatedItem);
      } else {
        apiRef.current.upsertFilterItem(updatedItem);
      }
    },
    [apiRef, item]
  );

  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        views={views}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={date}
        onError={(date) => {
          setHasError(date !== null);
        }}
        onChange={(date) => {
          setDate(date);
          setHasError(date === null);
          applyFilterChanges({
            ...item,
            value: date?.isValid() ? date.format("YYYY") : null,
          });
        }}
        slots={{
          textField: TextField,
        }}
        inputRef={inputRef as React.MutableRefObject<HTMLInputElement>}
        slotProps={{
          textField: {
            variant: "standard",
            error: hasError && !open,
            sx: {
              marginTop: "16px",
              width: "220px",
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const getDatePickerOperator = (
  views?: DateView[],
  operators: string[] = []
): GridFilterOperator[] => {
  let dateOperators = getGridDateOperators();
  if (operators.length) {
    dateOperators = dateOperators.filter((operator) =>
      operators.includes(operator.value)
    );
  }

  return dateOperators.map((operator) => ({
    ...operator,
    InputComponent: DatePickerFilter,
    InputComponentProps: { type: "date", views },
  }));
};

// export default DateRangeHeaderFilter;
