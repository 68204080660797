(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory("ManagedFilterHelpers", function (moment, TimeZoneService) {
      return {
        associateAssetRoomLevelFilters,
        // date range helpers
        dateFilterValueToModelValue,
        dateModelValueToChipText,
        dateModelValueToFilterValue,
        // number range helpers
        numberRangeModelValueToChipText,
        numberRangeModelValueToFilterValue,
        numberRangeFilterValueToModelValue,
        map,
        modelToEnumOption,
        modelToId,
        modelToName,
        /**
         * Create an array of enum options from an array of models.
         * @param {object[]} models - The models to iterate over
         * @return An array created by mapping `modelToEnumOption` over `models`
         */
        mapModelsToEnumOption: map(modelToEnumOption),
      };

      /**
       * Helper function to hook up a ManagedAssetFilter, ManagedRoomFilter,
       * and/or ManagedLevelFilter to eachother.
       * @param {object} configurations - Up to one each of asset, room, and
       *  level configurations to couple together.
       * @param {ManagedAssetFilter} [configurations.asset] - A ManagedAssetFilter
       * @param {ManagedRoomFilter} [configurations.room] - A ManagedRoomFilter
       * @param {ManagedLevelFilter} [configurations.level] - A ManagedLevelFilter
       * @return { void }
       */
      function associateAssetRoomLevelFilters(configurations) {
        var level = configurations.level;
        var room = configurations.room;
        var asset = configurations.asset;

        if (room) {
          asset && room.setAssetConfig(asset);
          level && room.setLevelConfig(level);
        }
        if (level) {
          asset && level.setAssetConfig(asset);
          room && level.setRoomConfig(room);
        }
        if (asset) {
          room && asset.setRoomConfig(room);
          level && asset.setLevelConfig(level);
        }
      }

      /**
       * Create a function that takes in an array, and returns the result of mapping
       * `fn` over its input.
       * @param { (arrayElement) => any } fn - The function to map over the input
       *  array.
       * @return { any[] => any[] } A function that accepts an array, and returns
       *  an array with `output[i] === fn(input[i])`
       */
      function map(fn) {
        return function (arr) {
          return arr.map(fn);
        };
      }

      /**
       * Converts a model to an enum option using its name and the model itself
       * as the input model.
       * @param {object} model - The model (asset, room, building, etc) to convert
       * @return An enum option representing this model.
       */
      function modelToEnumOption(model) {
        return {
          model: model,
          value: model.name,
        };
      }

      /** Reduce a model to its id */
      function modelToId(model) {
        return model._id;
      }

      /** Reduce a model to its name */
      function modelToName(model) {
        return model.name;
      }

      /**
       * Convert a number range to a text representation like "Minium 45" or
       * "Maxium 99" or "45-99"
       * @param {object} numberRange - The range input model
       * @return {string} A human-readable string representing the same range as
       * `numberRange`
       */
      function numberRangeModelValueToChipText(numberRange) {
        var hasStart = Boolean(numberRange.start) || numberRange.start === 0;
        var hasEnd = Boolean(numberRange.end) || numberRange.end === 0;
        if (hasStart && !hasEnd) {
          return "Minimum " + numberRange.start;
        }
        if (!hasStart && hasEnd) {
          return "Maximum " + numberRange.end;
        }
        if (numberRange.start === numberRange.end) {
          return String(numberRange.start);
        }
        return numberRange.start + " - " + numberRange.end;
      }

      /** Serializes a number range object {start, end} as a query string parameter */
      function numberRangeModelValueToFilterValue(numberRange) {
        var parts = [];
        if (numberRange && numberRange.start) {
          parts.push("$gte", numberRange.start);
        }
        if (numberRange && numberRange.end) {
          parts.push("$lte", numberRange.end);
        }
        return parts.join(",");
      }

      /** Parse a number range query string as an object {start, end} */
      function numberRangeFilterValueToModelValue(queryStringValue) {
        var numberRange = {};
        if (queryStringValue && queryStringValue.length > 0) {
          var parts = queryStringValue.split(",");
          while (parts.length > 0 && parts.length % 2 === 0) {
            var operation = parts.shift();
            var numberValue = parts.shift();
            var parsedNumber = numberValue;
            if (operation === "$gte") {
              numberRange.start = parsedNumber;
            } else if (operation === "$lte") {
              numberRange.end = parsedNumber;
            }
          }
        }
        return [numberRange];
      }

      /** Parsed a date range query string as an object {start, end} */
      function dateFilterValueToModelValue(queryStringValue) {
        var dateRange = {};
        if (queryStringValue && queryStringValue.length > 0) {
          var parts = queryStringValue.split(",");
          while (parts.length > 0 && parts.length % 2 === 0) {
            var operation = parts.shift();
            var dateValue = parts.shift();
            var parsedDate = moment(+dateValue)
              .utcOffset(TimeZoneService.getCurrentUTCOffset())
              .toDate();
            if (operation === "$gte") {
              dateRange.start = parsedDate;
            } else if (operation === "$lte") {
              dateRange.end = parsedDate;
            }
          }
        }
        return [dateRange];
      }

      /** Formats a date range object {start, end} as a chip */
      function dateModelValueToChipText(dateRange) {
        if (dateRange.start && dateRange.end) {
          return (
            moment(dateRange.start)
              .utcOffset(TimeZoneService.getCurrentUTCOffset())
              .format("l hh:mm a") +
            " - " +
            moment(dateRange.end)
              .utcOffset(TimeZoneService.getCurrentUTCOffset())
              .format("l hh:mm a")
          );
        } else if (dateRange.start) {
          return (
            "On or after " +
            moment(dateRange.start)
              .utcOffset(TimeZoneService.getCurrentUTCOffset())
              .format("l hh:mm a")
          );
        } else if (dateRange.end) {
          return (
            "On or before " +
            moment(dateRange.end)
              .utcOffset(TimeZoneService.getCurrentUTCOffset())
              .format("l hh:mm a")
          );
        }
      }

      /** Serializes a date range object {start, end} as a query string parameter */
      function dateModelValueToFilterValue(dateRange) {
        var parts = [];
        if (dateRange.start) {
          var start = moment(dateRange.start).valueOf();
          parts.push("$gte", start);
        }
        if (dateRange.end) {
          var end = moment(dateRange.end).valueOf();
          parts.push("$lte", end);
        }
        return parts.join(",");
      }
    });
})();
