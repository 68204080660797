(function () {
  angular.module("akitabox.ui.dialogs.asset.create", [
    "ngMaterial",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.flag",
    "akitabox.core.services.room",
    "akitabox.core.services.pinType",
    "akitabox.core.services.pinField",
    "akitabox.core.toast",
    "akitabox.ui.components.input",
  ]);
})();
