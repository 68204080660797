(function () {
  /**
   * @ngdoc component
   * @name abxAddress
   *
   * @param {Object}    model       Initial place data model
   * @param {Function}  onChange    Invoked when the user selects an option
   * @param {Boolean}   [disabled]  Disable the input, defaults to false
   * @param {Boolean}   [required]  Visually marks the input as required and
   *                                adds validation
   *
   * @description
   * Address input that pulls suggestions from Google Places API
   */
  angular
    .module("akitabox.ui.components.input.address", [
      "akitabox.core.services.map",
      "akitabox.core.utils",
      "akitabox.ui.components.typeAheadInput",
    ])
    .component("abxAddress", {
      bindings: {
        model: "<abxModel",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onBlur: "&abxOnBlur",
        onFocus: "&abxOnFocus",
        disabled: "<?abxDisabled",
        required: "<?abxRequired",
      },
      controller: AbxAddressController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/input/components/address/address.component.html",
    });

  /* @ngInject */
  function AbxAddressController(
    $attrs,
    $q,
    $log,
    MapService,
    ToastService,
    Utils
  ) {
    var self = this;

    // Constants
    var PLACE_TYPES = ["street_address", "route", "locality", "premise"];

    // Attributes
    self.value = parseModel(self.model);
    self.suggestions = [];
    self.disabled = Utils.parseAttribute($attrs, "disabled", false, self);
    self.required = Utils.parseAttribute($attrs, "required", false, self);

    // Functions
    self.handleChange = handleChange;

    // =================
    // Life Cycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.model) {
        self.value = parseModel(self.model);
        if (self.value) {
          queryPlaces(self.value).then(function (results) {
            self.suggestions = results;
          });
        }
      }
    };

    // =================
    // Private Functions
    // =================

    function parseModel(model) {
      if (angular.isEmpty(model, true)) {
        return null;
      }
      return MapService.formatDescription(self.model);
    }

    function queryPlaces(searchText) {
      if (searchText) {
        return MapService.getPlacePredictions(searchText, PLACE_TYPES)
          .then(function (results) {
            if (results) {
              return results.map(function (suggestion) {
                return {
                  model: suggestion,
                  value: suggestion.description,
                };
              });
            } else {
              return [];
            }
          })
          .catch(function (error) {
            $log.error(error);
            return [];
          });
      } else {
        return $q.resolve([]);
      }
    }

    function getPlaceDetails($event) {
      var place = $event.model;
      if (place) {
        return MapService.getPlaceDetails(place.place_id, place.description);
      } else {
        return $q.resolve(null);
      }
    }

    // =================
    // Public Functions
    // =================

    function handleChange($event) {
      if ($event.invalid) {
        queryPlaces($event.value).then(function (results) {
          self.suggestions = results;
        });
      } else {
        return getPlaceDetails($event)
          .then(function (place) {
            $event.value = place && place.description;
            $event.model = place;
            self.onChange({ $event: $event });
          })
          .catch(function (error) {
            $log.error(error);
            ToastService.showError(
              "Unable to get detailed address information"
            );
          });
      }
    }
  }
})();
