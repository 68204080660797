(function () {
  angular
    .module("akitabox.core.services.costAdjustmentRule", [
      "akitabox.constants",
      "akitabox.core.services.http",
      "angular-cache",
    ])
    .factory("CostAdjustmentRuleService", CostAdjustmentRuleService);

  /* @ngInject */
  function CostAdjustmentRuleService(
    // Angular
    $q,
    // AkitaBox
    HttpService,
    models,
    // Librares
    CacheFactory
  ) {
    var service = {
      count: count,
      create: create,
      get: get,
      getAll: getAll,
      getById: getById,
      remove: remove,
      updateById: updateById,
    };

    const cache = _createCache();

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------
    function _buildListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.COST_ADJUSTMENT_RULE.ROUTE_PLURAL
      );
    }

    function _buildDetailRoute(organizationId, costAdjustmentRuleId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.COST_ADJUSTMENT_RULE.ROUTE_PLURAL +
        "/" +
        costAdjustmentRuleId
      );
    }

    function _createCache() {
      let cache = CacheFactory.get("cost_adjustment_rules");

      if (!cache) {
        cache = CacheFactory("cost_adjustment_rules");
      }

      return cache;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Count cost ddjustment rules in an org by filter
     *
     * @param {String} organizationId - the parent organization's id
     * @param {Object} params - an object of query string params
     */
    function count(organizationId, params = {}) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      params.count = true;

      return HttpService.get(_buildListRoute(organizationId), params).then(
        (response) => {
          return response.count;
        }
      );
    }

    /**
     * Create a Cost Adjustment Rule in an org
     *
     * @param {string} organizationId - the parent organization's id
     * @param {object} [body={}] - the payload
     * @param {string} body.adjustment_percent - the new adjustment percent
     * @param {string} body.building - the building id of the new cost adjustment rule
     * @param {string} body.adjustment_percent - the new description
     */
    function create(organizationId, body = {}) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      } else if (!body.adjustment_percent) {
        return $q.reject(new Error("body.adjustment_percent is required"));
      } else if (!body.building) {
        return $q.reject(new Error("body.building is required"));
      } else if (!body.description) {
        return $q.reject(new Error("body.description is required"));
      }

      return HttpService.post(
        _buildListRoute(organizationId),
        body,
        null,
        null,
        cache
      );
    }

    /**
     * Fetch many Cost Adjustments in an org by filter
     *
     * @param {string} organizationId - the parent organization's id
     * @param {object} params - an object of query string parameters
     */
    function get(organizationId, params = {}) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      return HttpService.get(_buildListRoute(organizationId), params);
    }

    /**
     * Fetch a cost adjustment rule by its id
     *
     * @param {string} organizationId - the parent organization's id
     * @param {string} costAdjustmentRuleId - the cost adjustment rule's id
     */
    function getById(organizationId, costAdjustmentRuleId) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      if (!costAdjustmentRuleId) {
        return $q.reject(new Error("costAdjustmentRuleId is required"));
      }

      return HttpService.getById(
        _buildDetailRoute(organizationId, costAdjustmentRuleId),
        null,
        {},
        null
      );
    }

    /**
     * Fetches all cost adjustment rules within organization
     *
     * @param {string} organizationId - the parent organization's id
     * @param {object} params - an object of query string parameters
     */
    function getAll(organizationId, params = {}) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      return HttpService.getAll(_buildListRoute(organizationId), params);
    }

    /**
     * Deletes a cost adjustment rule
     *
     * @param {String} organizationId - the parent organization's id
     * @param {String} costAdjustmentRuleId - the cost adjustment rule's id
     */
    function remove(organizationId, costAdjustmentRuleId) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      if (!costAdjustmentRuleId) {
        return $q.reject(new Error("costAdjustmentRuleId is required"));
      }

      return HttpService.remove(
        _buildDetailRoute(organizationId, costAdjustmentRuleId),
        costAdjustmentRuleId,
        cache
      );
    }

    /**
     * Update a cost adjustment rule (acts as a PATCH, not a real PUT)
     *
     * @param {String} organizationId - the parent organization id
     * @param {String} costAdjustmentRuleId - the cost adjustment rule's id
     * @param {Object} data - the updates to perform
     */
    function updateById(organizationId, costAdjustmentRuleId, body) {
      if (!organizationId) {
        return $q.reject(new Error("organizationId is required"));
      }

      if (!costAdjustmentRuleId) {
        return $q.reject(new Error("costAdjustmentRuleId is required"));
      }

      return HttpService.patch(
        _buildDetailRoute(organizationId, costAdjustmentRuleId),
        body,
        {},
        cache
      );
    }
  }
})();
