(function () {
  angular
    .module("akitabox.desktop.room.detail")
    .controller(
      "RoomClosedWorkOrdersTabController",
      RoomClosedWorkOrdersTabController
    );

  /* @ngInject */
  function RoomClosedWorkOrdersTabController(
    // Constants
    WORK_ORDER_STATUS_CLOSED,
    // Resolve
    building,
    room,
    // Dialogs
    ExportDialog,
    // Services
    OrganizationService,
    WorkOrderService,
    WorkOrdersBFFService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.room = room;
    self.status = WORK_ORDER_STATUS_CLOSED;
    self.workOrders = null;
    self.createOptions = getCreateOptions();
    self.fabActions = getFabActions();
    self.organization = OrganizationService.getCurrent();

    // Functions
    self.fetchWorkOrders = fetchWorkOrders;
    self.fetchAllWorkOrders = fetchAllWorkOrders;

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchWorkOrders(skip, limit) {
      var params = {
        status: self.status,
        room: self.room._id,
        skip: skip,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      )
        .then(function (workOrders) {
          if (angular.isArray(self.workOrders)) {
            self.workOrders = self.workOrders.concat(workOrders);
          } else {
            self.workOrders = workOrders;
          }
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllWorkOrders(limit) {
      var params = {
        status: self.status,
        room: self.room._id,
        skip: 0,
        limit: limit,
      };

      return WorkOrdersBFFService.getByBuilding(
        self.organization._id,
        self.building._id,
        params
      )
        .then(function (workOrders) {
          self.workOrders = workOrders;
          return workOrders.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getFabActions() {
      return [
        {
          icon: "get_app",
          label: "Export",
          action: exportWorkOrders,
        },
      ];
    }

    function exportWorkOrders() {
      var locals = {
        route: WorkOrderService.buildListRoute(building._id),
        filters: {
          status: self.status,
          room: self.room._id,
        },
      };
      ExportDialog.show({ locals: locals }).catch(ToastService.showError);
    }

    function getCreateOptions() {
      return {
        locals: {
          building: building,
          floors: room.level ? [room.level] : null,
          rooms: [room],
        },
      };
    }
  }
})();
