(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller("FloorAssetsTabController", FloorAssetsTabController);

  /* @ngInject */
  function FloorAssetsTabController(
    // Angular
    $scope,
    // Constants
    EVENT_ASSET_CREATE,
    // Services
    AssetService,
    PinService,
    PinValueService,
    ToastService,
    // Resolve
    building,
    floor
  ) {
    var self = this;

    // Attributes
    self.building = building;
    self.floor = floor;
    self.assets = null;
    self.createOptions = getCreateOptions();

    // Functions
    self.fetchAssets = fetchAssets;
    self.fetchAllAssets = fetchAllAssets;

    // ------------------------
    //   Events
    // ------------------------

    $scope.$on(EVENT_ASSET_CREATE, onAssetCreate);

    // ------------------------
    //   Public Functions
    // ------------------------

    function fetchAssets(skip, limit) {
      var params = {
        skip: skip,
        limit: limit,
        include_values: true,
        level: self.floor._id,
      };

      var options = {
        includePinType: true,
      };

      return AssetService.get(building._id, params, options)
        .then(function (assets) {
          if (angular.isArray(self.assets)) {
            self.assets = self.assets.concat(assets);
          } else {
            self.assets = assets;
          }
          return assets.length;
        })
        .catch(ToastService.showError);
    }

    function fetchAllAssets(limit) {
      var params = {
        skip: 0,
        limit: limit,
        include_values: true,
        level: self.floor._id,
      };

      var options = {
        includePinType: true,
      };

      return AssetService.getAll(building._id, params, options)
        .then(function (assets) {
          self.assets = assets;
          return assets.length;
        })
        .catch(ToastService.showError);
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function getCreateOptions() {
      return {
        locals: {
          building: building,
          floor: floor,
        },
      };
    }

    /**
     * Handle asset creation event
     *
     * @param {Event}   $event    Angular event
     * @param {Asset[]} assets    List of new assets
     */
    function onAssetCreate($event, assets) {
      var asset = assets[0];
      var floorId = PinValueService.getFloorId(asset.values);
      if (floorId === self.floor._id) {
        PinService.includePinType(asset.building, [asset]).then(function (
          results
        ) {
          if (!self.assets) {
            self.assets = [];
          }
          self.assets.unshift(results[0]);
        });
      }
    }
  }
})();
