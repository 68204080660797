(function () {
  angular
    .module("akitabox.desktop.components.filterBarManager")
    .factory(
      "ManagedAssetFilter",
      function (
        $q,
        ManagedFilterHelpers,
        ManagedModelFieldFilter,
        AssetService
      ) {
        /**
         * @class
         * An asset filter can be used with any query that has an `asset` field
         * expecting an asset id. It may be paired with up to one level filter,
         * and one room filter, which will cause their filter values to influence
         * which assets are available for selection.
         * @param {object} options
         * @param {() => string} options.getBuildingId - A function that should return the
         *  building ID to use for fetching assets when loading enum options/chip
         *  text.
         */
        function ManagedAssetFilter(manager, options) {
          if (!options) {
            throw new Error("ManagedAssetFilter: Options must be provided");
          }
          if (typeof options.getBuildingId !== "function") {
            throw new Error(
              "ManagedAssetFilter: Invalid options, getBuildingId must be a function."
            );
          }
          if (typeof options.getOrganization !== "function") {
            throw new Error(
              "ManagedAssetFilter: Invalid options, getOrganization must be a function."
            );
          }

          /**
           * @member
           * @type {() => string}
           */
          this.getBuildingId = options.getBuildingId;

          /**
           * @member
           * @type {() => object}
           */
          this.getOrganization = options.getOrganization;

          /**
           * @member
           * @type { ManagedFilterConfiguration | undefined}
           * The room configuration (if any) that this filter depends on.
           */
          this.roomConfig = undefined;

          /**
           * @member
           * @type { ManagedFilterConfiguration | undefined}
           * The level configuration (if any) that this filter depends on.
           */
          this.levelConfig = undefined;

          var filterConfigurationOptions = {
            displayName: "Asset",
            queryField: "asset",
            inputType: "typeahead-dynamic",
            modelValueToFilterValue: ManagedFilterHelpers.modelToId,
            modelValueToChipText: ManagedFilterHelpers.modelToName,
          };

          ManagedModelFieldFilter.call(
            this,
            manager,
            filterConfigurationOptions
          );
        }
        // ManagedAssetFilter extends ManagedFilterConfiguration
        ManagedAssetFilter.prototype = Object.create(
          ManagedModelFieldFilter.prototype
        );
        ManagedAssetFilter.prototype.constructor = ManagedAssetFilter;

        /**
         * @param {object={}} params - What to pass into the mongo query as the filters
         *
         * Fetch enum options for this filter. Fetches assets filtered by
         * any associated room & level filter values.
         * @return {Promise<[]>} - An array of enum options, one for each asset.
         * @param {object} params
         * @param {boolean} [params.noLimit]
         */
        ManagedAssetFilter.prototype.getEnumOptions = function (params) {
          // Sets params to a default empty object if none provided
          !params && (params = {});
          var levelId = this.getLevelId();
          var roomId = this.getRoomId();
          levelId && (params.level = levelId);
          roomId && (params.room = roomId);
          const { _id: organizationId } = this.getOrganization();

          const getAllRequest = AssetService.getAllTypeAheads(
            organizationId,
            params
          );

          const getRequest = AssetService.getTypeAheads(organizationId, params);

          if (params.noLimit) {
            return getAllRequest.then(
              ManagedFilterHelpers.mapModelsToEnumOption
            );
          } else {
            return getRequest.then(ManagedFilterHelpers.mapModelsToEnumOption);
          }
        };

        /**
         * Fetch an asset by its id.
         * @return {Promise<Asset>}
         */
        ManagedAssetFilter.prototype.filterValueToModelValue = function (
          assetId
        ) {
          return AssetService.getById(this.getBuildingId(), assetId).then(
            function (asset) {
              return [asset];
            }
          );
        };

        /**
         * Get the room ID from the associated room filter.
         * @return { string | undefined } The room ID, or undefined if there is no
         *  associated room filter, or it has no value currently.
         */
        ManagedAssetFilter.prototype.getRoomId = function () {
          if (this.roomConfig) {
            return this.roomConfig.getFilterValue(this.manager);
          }
        };

        /**
         * Update this asset filter configuration to use the provided room filter
         * configuration.
         * @param [roomConfig] - The room filter configuration associated with this
         *  asset filter configuration. Its filter value will be used to narrow down
         *  enum options. If not provided, clears the associated room configuration.
         * @return { void }
         */
        ManagedAssetFilter.prototype.setRoomConfig = function (roomConfig) {
          this.roomConfig = roomConfig;
        };

        /**
         * Get the level ID from the associated level filter.
         * @return { string | undefined } The level ID, or undefined if there is no
         *  associated level filter, or it has no value set.
         */
        ManagedAssetFilter.prototype.getLevelId = function () {
          if (this.levelConfig) {
            return this.levelConfig.getFilterValue(this.manager);
          }
        };

        /**
         * Update this asset filter configuration to use the provided level filter
         * configuration.
         * @param [levelConfig] - The level filter configuration associated with this
         *  asset filter configuration. Its filter value will be used to narrow down
         *  enum options. If not provided, clears the associated level configuration.
         * @return { void }
         */
        ManagedAssetFilter.prototype.setLevelConfig = function (levelConfig) {
          this.levelConfig = levelConfig;
        };

        return ManagedAssetFilter;
      }
    );
})();
