(function () {
  /**
   * @ngdoc directive
   * @name abxFocusOut
   * @restrict 'A'
   *
   * @description
   * Event handler that emulates the focusout event. Since AngularJS doesn't have
   * its own (it only has ngBlur), we have to make our own. The difference is
   * that focusout will bubble the event, whereas blur will not. This way, we
   * can receive blur events from children elements of this directive, and still
   * get the callback to trigger.
   *
   * @example
   * <div abx-focus-out="vm.onBlur()">
   *   <input type=text" /> // Will invoke vm.onBlur on focus lost!
   * </div>
   */
  angular
    .module("akitabox.core.directives.focusOut", [])
    .directive("abxFocusOut", AbxFocusOutDirective);

  function AbxFocusOutDirective($document, $timeout) {
    var doc = $document[0];

    return {
      restrict: "A",
      link: postLink,
    };

    function postLink($scope, $element, attrs) {
      var el = $element[0];

      el.addEventListener("blur", onFocusOut, true);

      $scope.$on("$destroy", function () {
        el.removeEventListener("blur", onFocusOut, true);
      });

      function onFocusOut() {
        $timeout(
          function () {
            if (!el.contains(doc.activeElement)) {
              $scope.$evalAsync(attrs.abxFocusOut);
            }
          },
          0,
          false
        );
      }
    }
  }
})();
