import { DocumentResponse } from "@akitabox/api-client";
import { List, ListItem } from "@mui/material";
import { FC, useCallback } from "react";
import { Color } from "../../colors";
import { stylesheet } from "../../stylesheet";
import { AttachmentDialogTestids } from "../AttachmentDialog";
import { FileListItem, PendingFileListItem } from "./FileListItem";
import { UploadButton } from "./UploadButton";
import { constants } from "../../../utils/constants";
export interface UploadSectionProps {
  className?: string;
  onFileUpload(file: File): void;
  onDocumentRemove(document: DocumentResponse): void;
  documents: DocumentResponse[];
  pendingFiles: File[];
  isLoading: boolean;
  canUploadMultipleFiles: boolean;
  isReportBuilder?: boolean;
}
/**
 * Attachment Dialog upload section component. Includes
 * upload button and list of uploaded files.
 */
export const UploadSection: FC<UploadSectionProps> = ({
  className,
  onFileUpload,
  onDocumentRemove,
  documents,
  isLoading,
  pendingFiles,
  canUploadMultipleFiles,
  isReportBuilder = false,
}) => {
  const handleFileUpload = useCallback<(files?: File[]) => void>(
    (files) => {
      if (!files) return;
      for (const file of files) {
        // make sure this file extension is in our allowed list
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        if (
          fileExtension &&
          (isReportBuilder
            ? constants.MODELS.DOCUMENT.REPORT_BUILDER_UPLOAD_SUPPORT_EXTENSIONS.indexOf(
                fileExtension
              ) === -1
            : constants.MODELS.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.indexOf(
                fileExtension
              ) === -1)
        ) {
          continue;
        }

        onFileUpload(file);
      }
    },
    [onFileUpload, isReportBuilder]
  );

  if (documents.length === 0 && pendingFiles.length === 0) {
    return (
      <UploadButton
        data-testid={AttachmentDialogTestids.UploadButton}
        css={ss.root}
        className={className}
        onFileSelect={handleFileUpload}
        isLoading={isLoading}
        canUploadMultipleFiles={canUploadMultipleFiles}
        accept={
          isReportBuilder
            ? constants.MODELS.DOCUMENT.REPORT_BUILDER_UPLOAD_SUPPORT_EXTENSIONS.join(
                ","
              )
            : constants.MODELS.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.join(",")
        }
      />
    );
  }
  return (
    <List css={[ss.root, ss.list]} className={className}>
      <>
        {[
          ...documents.map((document) => (
            <FileListItem
              document={document}
              key={`${document._id}`}
              onRemove={() => onDocumentRemove(document)}
            />
          )),
          ...pendingFiles.map((file) => (
            <PendingFileListItem key={`pending/${file.name}`} />
          )),
        ]}
        <ListItem css={ss.buttonContainer}>
          <UploadButton
            data-testid={AttachmentDialogTestids.UploadButton}
            css={ss.listItemButton}
            onFileSelect={handleFileUpload}
            isLoading={isLoading && false}
            canUploadMultipleFiles={canUploadMultipleFiles}
            accept={
              isReportBuilder
                ? constants.MODELS.DOCUMENT.REPORT_BUILDER_UPLOAD_SUPPORT_EXTENSIONS.join(
                    ","
                  )
                : ""
            }
          />
        </ListItem>
      </>
    </List>
  );
};

const ss = stylesheet({
  /** Root element of the UploadSection */
  root: {
    border: `1px solid ${Color.BorderGray}`,
    width: "100%",
    flexBasis: "100px",
  },
  /** List item containing the upload button */
  buttonContainer: (theme) => ({
    padding: 0,
    marginTop: theme.spacing(-1),
  }),
  /**
   * Applied to the upload button when it's inside the file list
   * (ie, one or more files have been selected)
   */
  listItemButton: {
    width: "100%",
    height: "60px",
  },
  list: (theme) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  }),
});
