import { FunctionComponent, ReactNode } from "react";
import { useAngularReactRouterSync } from "./legacy/angularApp";
import { To, useNavigate, useNavigation } from "react-router";
import { LoadingBar } from "./LoadingBar";
import { WebClientContexts } from "./WebClientContexts";
import { useSubject } from "../../util/useSubject";
import { Subject } from "../../util/Subject";

export const reactRouterFromAngular = new Subject<{ to: To }>();

/**
 * Main application container.
 * @returns
 */
export const WebClientApp: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  useAngularReactRouterSync();
  const navigate = useNavigate();
  const { state: navigationState } = useNavigation();

  useSubject(reactRouterFromAngular, (message) => navigate(message.to));

  return (
    <WebClientContexts>
      <LoadingBar loading={navigationState !== "idle"} />
      {children}
    </WebClientContexts>
  );
};
