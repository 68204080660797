(function () {
  angular
    .module("akitabox.core.services.recentActivity")
    .factory("RecentActivityService", RecentActivityService);

  /* @ngInject */
  function RecentActivityService(
    // Angular
    $q,
    // AkitaBox
    models,
    // Services
    AttachmentService,
    NoteService,
    OrganizationService,
    ChecklistService,
    UserActionService,
    WorkOrderService,
    WorkOrderLogService,
    RecentActivityFeed
  ) {
    // Activity type constants
    var ACTIVITY_TYPE_NOTE = "note";
    var ACTIVITY_TYPE_WORK_ORDER = "workOrder";
    var ACTIVITY_TYPE_WORK_ORDER_LOG = "workOrderLog";
    var ACTIVITY_TYPE_ATTACHMENT = "attachment";
    var ACTIVITY_TYPE_REACTIVE_INSPECTION_WO = "reactiveInspectionTask";
    var ACTIVITY_TYPE_REACTIVE_INSPECTION_WO_LINK =
      "reactiveInspectionTaskLink";
    var ACTIVITY_TYPE_USER_ACTION = "userAction";

    // Optional method query to get all user actions
    var ALL_USER_ACTION_QUERY = "$in,PATCH,PUT,POST,DELETE";

    // Request parameters
    var NOTE_PARAMS = {
      sort_by: "cre_date,desc",
    };

    var USER_ACTION_PARAMS = {
      status: 200,
      method: "$in,PATCH,PUT",
      sort_by: "time,desc",
      route_name: "$nin,attachment_list,attachment_detail",
    };

    var WORK_ORDER_PARAMS = {
      status: "completed",
      sort_by: "completed_date",
    };
    var ATTACHMENT_PARAMS = {
      delete_date: "null",
      sort_by: "cre_date,desc",
    };

    /**
     * Service
     *
     * @type {object}
     */
    var service = {
      fetch: fetch,
      getActivityFeed: getActivityFeed,
      getOrgActivityFeed: getOrgActivityFeed,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Fetch notes
     * @param { string } buildingId The building ID to scope requests to
     * @param { object } params Additional query params
     * @return { Promise<object[]> } Matching notes
     */
    function fetchNotes(buildingId, params) {
      var noteParams = angular.extend({}, NOTE_PARAMS, params);

      return NoteService.get(buildingId, noteParams).then(function (notes) {
        return notes.map(function (note) {
          note.activityType = ACTIVITY_TYPE_NOTE;
          note.time = new Date(note.cre_date);
          return note;
        });
      });
    }

    /**
     * Fetch user actions
     * @param { string } buildingId Building ID to scope requests to
     * @param { object } params Additional query params
     * @param { boolean } showAllUserActionMethods If true, modifies params to fetch
     *    user actions for all HTTP methods
     * @return { Promise<object[]> } Matching user actions
     */
    function fetchUserActions(buildingId, params, showAllUserActionMethods) {
      var userActionParams = angular.extend({}, USER_ACTION_PARAMS, params);
      if (showAllUserActionMethods) {
        userActionParams.method = ALL_USER_ACTION_QUERY;
      }
      return UserActionService.getBuildingUserActions(
        buildingId,
        userActionParams
      ).then(function (userActions) {
        return userActions.map(function (userAction) {
          userAction.activityType = ACTIVITY_TYPE_USER_ACTION;
          userAction.time = new Date(userAction.time);
          return userAction;
        });
      });
    }

    function fetchOrgUserActions(
      organizationId,
      params,
      showAllUserActionMethods
    ) {
      var userActionParams = angular.extend({}, USER_ACTION_PARAMS, params);
      if (showAllUserActionMethods) {
        userActionParams.method = ALL_USER_ACTION_QUERY;
      }
      return UserActionService.getOrgUserActions(
        organizationId,
        userActionParams
      ).then(function (userActions) {
        return userActions.map(function (userAction) {
          userAction.activityType = ACTIVITY_TYPE_USER_ACTION;
          userAction.time = new Date(userAction.time);
          return userAction;
        });
      });
    }

    /**
     * Fetches reactive inspection tasks for a provided work order.
     * This fetch is not paginated (no limit/uses getAll).
     * @param { string } organizationId Organization ID to scope requests to
     * @param { string } taskId ID of the work order
     * @return { Promise<object[]> } All related reactive inspection tasks
     */
    function fetchReactiveInspectionTasks(organizationId, taskId) {
      // find all checklists associated with this WO,
      // then return all tasks in checklist.reactive_inspection_task
      return ChecklistService.getAllByWorkOrderId(organizationId, taskId)
        .then(function (checklists) {
          var reactiveInspectionTasks = [];
          checklists.forEach(function (checklist) {
            if (checklist.reactive_inspection_tasks.length > 0) {
              reactiveInspectionTasks.push.apply(
                reactiveInspectionTasks,
                checklist.reactive_inspection_tasks
              );
            }
          });
          return reactiveInspectionTasks;
        })
        .then(function (reactiveInspectionTasks) {
          return $q.all(
            reactiveInspectionTasks.map(function (task) {
              return WorkOrderService.getById(task.building, task._id);
            })
          );
        })
        .then(function (tasks) {
          return tasks.map(function (task) {
            task.time = new Date(task.cre_date);
            task.activityType = ACTIVITY_TYPE_REACTIVE_INSPECTION_WO;
            return task;
          });
        });
    }

    /**
     * Fetch work orders
     * @param {*} buildingId Building ID to scope requests to
     * @param {*} params Additional query params
     * @return { Promise<object[]> }
     */
    function fetchWorkOrders(buildingId, params) {
      var workOrderParams = angular.extend({}, WORK_ORDER_PARAMS, params);
      return WorkOrderService.getByBuildingId(buildingId, workOrderParams).then(
        function (workOrders) {
          return workOrders.map(function (workOrder) {
            workOrder.time = new Date(workOrder.completed_date);
            workOrder.activityType = ACTIVITY_TYPE_WORK_ORDER;
            return workOrder;
          });
        }
      );
    }

    /**
     * Fetch work order logs
     * @param {*} buildingId  Building ID to scope requests to
     * @param {*} params Additional query params
     * @return { Promise<object[]> }
     */
    function fetchWorkOrderLogs(buildingId, params) {
      return WorkOrderLogService.getByBuildingId(buildingId, params).then(
        function (workOrderLogs) {
          return workOrderLogs.map(function (workOrderLog) {
            workOrderLog.time = new Date(workOrderLog.work_performed_date);
            workOrderLog.activityType = ACTIVITY_TYPE_WORK_ORDER_LOG;
            return workOrderLog;
          });
        }
      );
    }

    /**
     * Fetch attachments for activity feed
     * @param { string } buildingId Building ID to scope requests
     * @param { object } params Additional query params
     * @return { Promise<object[]> } Matching attachments
     */
    function fetchAttachments(buildingId, params) {
      return AttachmentService.get(buildingId, params).then(function (
        attachments
      ) {
        return attachments.map(function (attachment) {
          attachment.time = new Date(attachment.cre_date);
          attachment.activityType = ACTIVITY_TYPE_ATTACHMENT;
          return attachment;
        });
      });
    }

    /**
     * Fetch 3rd party CMMS links related to a specific work order
     * @param { string } organizationId Organization ID to scope requests to
     * @param { object } taskId Work order ID
     * @return { Promise<object[]> } All 3rd party cmms reactive inspection task
     *  links related to the work order
     */
    function fetchReactiveInspectionTaskLinks(organizationId, taskId) {
      return ChecklistService.getAllByWorkOrderId(organizationId, taskId)
        .then(function (checklists) {
          var reactiveInspectionTaskLinks = [];
          checklists.forEach(function (checklist) {
            if (checklist.reactive_inspection_tasks_external_cmms.length > 0) {
              reactiveInspectionTaskLinks.push.apply(
                reactiveInspectionTaskLinks,
                checklist.reactive_inspection_tasks_external_cmms
              );
            }
          });
          return reactiveInspectionTaskLinks;
        })
        .then(function (reactiveInspectionTaskLinks) {
          return reactiveInspectionTaskLinks.map(function (link) {
            link.time = new Date(link.cre_date);
            link.activityType = ACTIVITY_TYPE_REACTIVE_INSPECTION_WO_LINK;
            return link;
          });
        });
    }

    function getOrgActivityFeed(organizationId, query, options) {
      var keys = [];
      var params = {};

      if (options.showUserActions) {
        keys.push(ACTIVITY_TYPE_USER_ACTION);
        // Extend standard query params with
        params[ACTIVITY_TYPE_USER_ACTION] = angular.extend(
          {},
          USER_ACTION_PARAMS,
          query
        );
      }

      return new RecentActivityFeed(keys, query.limit, handleFetch);

      function handleFetch(key, fetchOptions) {
        var getParams = function (params) {
          return getFetchParams(params, fetchOptions, query);
        };

        var fetchRequest = $q.resolve([]);
        if (key === ACTIVITY_TYPE_USER_ACTION) {
          var userActionParams = getParams(params[ACTIVITY_TYPE_USER_ACTION]);
          if (userActionParams.cre_date) {
            userActionParams.time = userActionParams.cre_date;
            delete userActionParams.cre_date;
          }
          fetchRequest = fetchOrgUserActions(
            organizationId,
            userActionParams,
            options.showAllUserActionMethods
          );
        }

        return fetchRequest.then(function (items) {
          var hasMore = items.length === query.limit;
          if (key === ACTIVITY_TYPE_USER_ACTION) {
            items = items.filter(function (item) {
              var html = item.entity_action_html || item.building_action_html;
              return Boolean(html);
            });
          }
          return { items: items, hasMore: hasMore };
        });
      }
    }

    /**
     * Create an activity feed object for fetching recent activity. See
     * RecentActivityFeed class for details.
     * @param {*} buildingId
     * @param {*} query
     * @param {*} options
     * @return { RecentActivityFeed }
     */
    function getActivityFeed(buildingId, query, options) {
      var organization = OrganizationService.getCurrent();
      var keys = [];

      /**
       * Object of params to use for different cache keys when fetching
       */
      var params = {};

      // Evaluate options and set up cache keys and query params

      // Notes
      if (options.showNotes) {
        keys.push(ACTIVITY_TYPE_NOTE);
        params[ACTIVITY_TYPE_NOTE] = angular.extend(
          {},
          query,
          options.noteParams,
          { method: undefined }
        );
      }

      // User Actions
      if (options.showUserActions) {
        keys.push(ACTIVITY_TYPE_USER_ACTION);

        var userActionParams = angular.extend({}, query, {
          time: query.cre_date,
          cre_date: undefined,
        });
        if (options.showAllUserActionMethods) {
          userActionParams.method = ALL_USER_ACTION_QUERY;
        }
        params[ACTIVITY_TYPE_USER_ACTION] = userActionParams;
      }

      // Reactive Inspection Tasks
      if (options.showReactiveInspectionTasks) {
        keys.push(ACTIVITY_TYPE_REACTIVE_INSPECTION_WO);
      }

      // Reactive 3rd Party CMMS Links
      if (options.showReactiveInspectionTaskLinks) {
        keys.push(ACTIVITY_TYPE_REACTIVE_INSPECTION_WO_LINK);
      }

      // Work Orders
      if (options.showWorkOrders) {
        keys.push(ACTIVITY_TYPE_WORK_ORDER);
        params[ACTIVITY_TYPE_WORK_ORDER] = angular.extend({}, query, {
          method: undefined,
        });
      }

      // WO Logs
      if (options.showWorkOrderLogs) {
        keys.push(ACTIVITY_TYPE_WORK_ORDER_LOG);
        params[ACTIVITY_TYPE_WORK_ORDER_LOG] = angular.extend({}, query, {
          method: undefined,
        });
      }

      // Attachments
      if (options.showAttachments) {
        var attachmentParams = angular.extend({}, ATTACHMENT_PARAMS, query);
        var supportedTypes = models.ATTACHMENT.SUPPORTED_ENTITY_TYPES;
        var isValid = false;
        for (var i = 0; i < supportedTypes.length; ++i) {
          var entityType = supportedTypes[i];
          var entityId = query[entityType];
          if (entityId) {
            delete attachmentParams[entityType];
            attachmentParams.entity_type = entityType;
            attachmentParams.entity_id = entityId;
            isValid = true;
            break;
          }
        }
        if (isValid) {
          keys.push(ACTIVITY_TYPE_ATTACHMENT);
          params[ACTIVITY_TYPE_ATTACHMENT] = attachmentParams;
        }
      }

      return new RecentActivityFeed(keys, query.limit, handleFetch);

      /**
       * Fetch a new page of data for the provided cache
       * @param { string } key Cache key to fetch for
       * @param { object } fetchOptions
       * @param { Date } fetchOptions.olderThan If set, only fetch items older this
       *  date (or exactly this old)
       * @param { Date } fetchOptions.newerThan If set, only fetch items newer than
       *  this date
       * @param { number } fetchOptions.skip Number of results to skip (for pagination)
       * @return { { items : object[], hasMore: boolean } }
       */
      function handleFetch(key, fetchOptions) {
        var getParams = function (params) {
          return getFetchParams(params, fetchOptions, query);
        };

        var fetchRequest = $q.resolve([]);

        var hasMore = function (results) {
          return results.length === query.limit;
        };

        if (key === ACTIVITY_TYPE_NOTE) {
          fetchRequest = fetchNotes(
            buildingId,
            getParams(params[ACTIVITY_TYPE_NOTE])
          );
        } else if (key === ACTIVITY_TYPE_USER_ACTION) {
          var userActionParams = getParams(params[ACTIVITY_TYPE_USER_ACTION]);
          if (userActionParams.cre_date) {
            userActionParams.time = userActionParams.cre_date;
            delete userActionParams.cre_date;
          }
          userActionParams.sort_by = "time,desc";
          const showAllUserActionMethods = userActionParams.asset
            ? true
            : false;
          fetchRequest = fetchUserActions(
            buildingId,
            userActionParams,
            showAllUserActionMethods
          );
        } else if (key === ACTIVITY_TYPE_REACTIVE_INSPECTION_WO) {
          fetchRequest = fetchReactiveInspectionTasks(
            organization._id,
            query.task
          );
          hasMore = function () {
            return false;
          };
        } else if (key === ACTIVITY_TYPE_ATTACHMENT) {
          fetchRequest = fetchAttachments(
            buildingId,
            getParams(params[ACTIVITY_TYPE_ATTACHMENT])
          );
        } else if (key === ACTIVITY_TYPE_REACTIVE_INSPECTION_WO_LINK) {
          fetchRequest = fetchReactiveInspectionTaskLinks(
            organization._id,
            query.task
          );
          hasMore = function () {
            return false;
          };
        } else if (key === ACTIVITY_TYPE_WORK_ORDER) {
          var woParams = getParams(params[ACTIVITY_TYPE_WORK_ORDER]);
          if (woParams.cre_date) {
            woParams.completed_date = woParams.cre_date;
            delete woParams.cre_date;
          }
          fetchRequest = fetchWorkOrders(buildingId, woParams);
        } else if (key === ACTIVITY_TYPE_WORK_ORDER_LOG) {
          var woLogParams = getParams(params[ACTIVITY_TYPE_WORK_ORDER_LOG]);
          if (woLogParams.cre_date) {
            woLogParams.work_performed_date = woLogParams.cre_date;
            delete woLogParams.cre_date;
          }
          woLogParams.sort_by = "work_performed_date,desc";
          fetchRequest = fetchWorkOrderLogs(buildingId, woLogParams);
        }

        return fetchRequest.then(function (items) {
          var requestHasMore = hasMore(items);

          if (key === ACTIVITY_TYPE_USER_ACTION) {
            // discard user actions without HTML
            items = items.filter(function (item) {
              var html = item.entity_action_html || item.building_action_html;
              return Boolean(html);
            });
          }

          return { items: items, hasMore: requestHasMore };
        });
      }
    }
  }

  /**
   * @function
   * Get params with additional common filters added
   * @param { object } params params to enhance
   * @param { object } fetchOptions
   * @param { object } query
   * @return { object }
   */
  function getFetchParams(params, fetchOptions, query) {
    var additionalParams = {};
    if (fetchOptions.olderThan) {
      additionalParams.cre_date =
        "$lte," + fetchOptions.olderThan.toISOString();
    } else if (fetchOptions.newerThan) {
      additionalParams.cre_date = "$gt," + fetchOptions.newerThan.toISOString();
    }
    additionalParams.skip = fetchOptions.skip || undefined;
    additionalParams.limit = query.limit;

    return angular.extend({}, params, additionalParams);
  }
})();
