(function () {
  angular
    .module("akitabox.ui.dialogs.bulkAdd.relationships")
    .controller(
      "BulkAddRelationshipsDialogController",
      BulkAddRelationshipsDialogController
    );

  /* @ngInject */
  function BulkAddRelationshipsDialogController(
    // Angular
    $q,
    $timeout,
    // Material
    $mdDialog,
    // Services
    Utils,
    ToastService,
    OrganizationService,
    AssociationService,
    AssociationTypeService
  ) {
    this.retry = false;
    this.saving = false;
    this.loading = false;
    this.determinateValue = 0;
    this.determinateIncrement = 0;

    this.organization = OrganizationService.getCurrent();
    this.relationTypes = [];
    this.associations = {
      room: null,
      asset: null,
      relationType: null,
      floor: this.floor,
      building: this.building,
    };

    // Mapping model to entity
    const entityModelMap = {
      assets: "Asset",
      rooms: "Room",
    };

    // Data to be used when doing bulk action
    let payload = [];

    // Pointer for index reference when doing bulk action
    let dataIndex = 0;

    // Loaders
    const fetchAssociationTypes = () => {
      return AssociationTypeService.getAll(this.organization._id, {
        sort: "upstream_text,asc",
        insensitive: true,
      }).then((associationTypes) => {
        this.relationTypes = associationTypes.reduce((accumulator, value) => {
          const upstream = {
            model: {
              _id: Utils.uuidv4(),
              association: value,
              text: value.upstream_text,
            },
            value: value.upstream_text,
          };

          const downstream = {
            model: {
              _id: Utils.uuidv4(),
              association: value,
              text: value.downstream_text,
            },
            value: value.downstream_text,
          };

          accumulator.push(upstream);
          accumulator.push(downstream);
          return accumulator;
        }, []);
      });
    };

    /**
     * Checks if the error is a 400 and, if it is, checks
     * if the error itself is from a duplicate scenario
     * @param {Object} error - The error object returned by the API
     * @returns {Boolean} whether it is a duplication error or not
     */
    const shouldBypass = (error) => {
      if (typeof error !== "object") return false;

      const { status, data } = error;

      if (status !== 400) return false;

      if (!data || !data.error || !data.error.message) return false;

      const duplicateErrorMessageRegexp = new RegExp(
        /^(Association).+(already exists)$/,
        "s"
      );

      if (!duplicateErrorMessageRegexp.test(data.error.message)) return false;

      return true;
    };

    /**
     * Returns the percentage that the progress bar should be incremented by
     * @returns {Number} increment percentage
     */
    const getDeterminateIncrement = () => {
      const total = this.selectedItems.length;
      return (1 / total) * 100;
    };

    /**
     * Takes in the data from an item (asset/room). Then, adds the selected
     * relation type between the item and the selected Asset/Room in the dialog.
     * Calls itself again (recursively) until all data has been processed.
     * @param {Object} data - The item (asset/room) being processed
     */
    const bulkAddAction = (data) => {
      const request = AssociationService.create(this.organization._id, data);

      const done = () => {
        // Reset pointer
        dataIndex = 0;

        ToastService.showSimple(
          `Successfully added relationships in ${
            this.selectedItems.length
          } ${this.getModelName()}`
        );

        const close = () => {
          this.saving = false;
          this.determinateValue = 0;
          this.determinateIncrement = 0;
          $mdDialog.hide();
        };

        $timeout(close, 2000);
      };

      const next = () => {
        bulkAddAction(payload[dataIndex]);
      };

      const hasMoreData = () => {
        return !!payload[dataIndex];
      };

      const increment = () => {
        // Increment pointer
        dataIndex += 1;
        // Increment progress bar
        this.determinateValue += this.determinateIncrement;
      };

      const process = () => {
        increment();

        if (hasMoreData()) {
          next();
        } else {
          done();
        }
      };

      const handleError = (error) => {
        if (shouldBypass(error)) {
          // Move forward to next requests
          return process();
        }

        this.retry = true;
        this.saving = false;
        ToastService.showError(error);
      };

      request.then(process).catch(handleError);
    };

    // Life cycle
    this.$onInit = () => {
      this.loading = true;

      $q.all([fetchAssociationTypes()]).finally(() => {
        this.loading = false;
      });
    };

    // On change handlers
    this.handleRoomChange = ({ model }) => {
      this.associations.room = model;
    };
    this.handleAssetChange = ({ model }) => {
      this.associations.asset = model;
    };
    this.handleFloorChange = ({ model }) => {
      this.associations.floor = model;
    };
    this.handleBuildingChange = ({ model }) => {
      this.associations.building = model;
    };
    this.handleRelationTypeChange = ({ model }) => {
      this.associations.relationType = model;
    };

    this.getSelectedItemsInfo = () =>
      this.selectedItems.map((item) => item.name || "").join(", ");

    this.shouldDisable = () => {
      const { relationType, building, asset, room } = this.associations;

      // Required
      if (!relationType || !building) return true;

      // Needs at least one
      if (!asset && !room) return true;

      return false;
    };

    this.onRetry = () => {
      this.retry = false;
      this.saving = true;

      // Calling bulkAddAction with data at same state from last time (when failed)
      bulkAddAction(payload[dataIndex]);
    };

    this.save = () => {
      this.saving = true;

      const { relationType } = this.associations;
      const { association: associationType } = relationType;

      let downstream_entity, upstream_entity;
      let downstream_entity_model, upstream_entity_model;

      for (const asset in this.selectedItems) {
        const detailAsset = this.selectedItems[asset];
        if (relationType.text === associationType.downstream_text) {
          /**
           * This is a downstream relationship
           * ie: "is powered by" room/asset
           */
          downstream_entity = detailAsset._id;
          upstream_entity = this.associations.asset
            ? this.associations.asset._id
            : this.associations.room._id;

          downstream_entity_model = entityModelMap[this.model];
          upstream_entity_model = this.associations.asset
            ? entityModelMap.assets
            : entityModelMap.rooms;
        } else {
          /**
           * This is an upstream relationship
           * ie: "powers" room/asset
           */
          downstream_entity = this.associations.asset
            ? this.associations.asset._id
            : this.associations.room._id;
          upstream_entity = detailAsset._id;

          downstream_entity_model = this.associations.asset
            ? entityModelMap.assets
            : entityModelMap.rooms;
          upstream_entity_model = entityModelMap[this.model];
        }

        payload.push({
          association_type: associationType._id,
          downstream_entity,
          downstream_entity_model,
          upstream_entity,
          upstream_entity_model,
        });
      }

      this.determinateIncrement = getDeterminateIncrement();

      // Starting bulk action at 0
      bulkAddAction(payload[0]);
    };

    this.getModelName = () => {
      const isPlural = this.selectedItems.length > 1;
      const modelName = entityModelMap[this.model].toLowerCase();

      if (isPlural) return `${modelName}s`;
      return modelName;
    };

    // Modal close
    this.cancel = $mdDialog.cancel;
  }
})();
