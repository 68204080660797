(function () {
  angular
    .module("akitabox.core.services.workOrderRound", [
      "akitabox.core.services.http",
    ])
    .factory("WorkOrderRoundService", WorkOrderRoundService);

  function WorkOrderRoundService(
    //Services
    HttpService
  ) {
    var services = {
      get,
    };

    return services;

    function buildBaseRoute(organizationId, workOrderId) {
      return `organizations/${organizationId}/web/work_order_round/${workOrderId}`;
    }

    function get(organizationId, workOrderId, params) {
      const route = buildBaseRoute(organizationId, workOrderId);
      return HttpService.get(route, params).then((stops) =>
        stops.map((stop) => {
          if (stop.room) {
            stop.room.pinType.protected_type = "Room";
          }
          if (stop.asset) {
            stop.asset.pinType.protected_type = "Asset";
          }
          return stop;
        })
      );
    }
  }
})();
