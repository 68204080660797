(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.componentState
   */
  angular
    .module("akitabox.core.services.componentState", [
      "akitabox.core.constants",
    ])
    .factory("ComponentStateService", ComponentStateService);

  /**
   * @ngdoc factory
   * @name akitabox.core.services.componentState:ComponentStateService
   *
   * @description
   * This service provides functions to handle component state changes
   */
  /* @ngInject */
  function ComponentStateService(COMPONENT_STATE) {
    var service = {
      isValid: isValid,
      watchState: watchState,
      setElementState: setElementState,
    };

    return service;

    /**
     * Watch the 'state' scope variable
     *
     * @param {Object}   scope     Element scope
     * @param {Object}   elem      Angular element
     * @param {Function} func      Function to call on state change
     */
    function watchState(scope, elem, func) {
      if (Object.prototype.hasOwnProperty.call(scope, "state")) {
        scope.$watch("state", onStateChange);
      } else if (
        Object.prototype.hasOwnProperty.call(scope, "vm") &&
        Object.prototype.hasOwnProperty.call(scope.vm, "state")
      ) {
        scope.$watch("vm.state", onStateChange);
      }

      function onStateChange(newState, oldState) {
        if (newState && newState !== oldState) {
          if (angular.isFunction(func)) func(newState, oldState);
          setElementState(elem, newState);
        }
      }
    }

    /**
     * Set the element state class
     *
     * @param {Object} elem     Angular element
     * @param {String} state    New state
     */
    function setElementState(elem, state) {
      removeAllStateClasses(elem);
      switch (state) {
        case COMPONENT_STATE.success:
          elem.addClass("success");
          break;
        case COMPONENT_STATE.error:
          elem.addClass("error");
          break;
        case COMPONENT_STATE.disabled:
          elem.addClass("disabled");
          break;
        case COMPONENT_STATE.loading:
          elem.addClass("loading");
          break;
        case COMPONENT_STATE.editing:
          elem.addClass("editing");
          break;
        case COMPONENT_STATE.saving:
          elem.addClass("saving");
          break;
        default:
          removeAllStateClasses(elem);
      }

      function removeAllStateClasses(elem) {
        elem.removeClass("success");
        elem.removeClass("error");
        elem.removeClass("disabled");
        elem.removeClass("loading");
        elem.removeClass("editing");
        elem.removeClass("saving");
      }
    }

    function isValid(state) {
      for (var key in COMPONENT_STATE) {
        if (
          Object.prototype.hasOwnProperty.call(COMPONENT_STATE, key) &&
          COMPONENT_STATE[key] === state
        )
          return true;
      }

      return false;
    }
  }
})();
