(function () {
  /**
   * @ngdoc component
   * @name abxHelpTooltip
   *
   * @description
   * <abx-tooltip abx-text="your tooltip text here" abx-direction="top|right|bottom|left"></abx-tab-content>
   */
  angular
    .module("akitabox.ui.components.helpTooltip", ["720kb.tooltips"])
    .component("abxHelpTooltip", {
      bindings: {
        text: "@abxText",
        direction: "@?abxDirection",
        size: "@?abxSize",
      },
      transclude: true,
      controller: AbxHelpTooltipController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/help-tooltip/help-tooltip.component.html",
    });

  /* @ngInject */
  function AbxHelpTooltipController() {
    var self = this;

    self.direction = self.direction || "top";
    self.size = self.size || "medium";
  }
})();
