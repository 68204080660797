(function () {
  angular
    .module("akitabox.ui.dialogs.asset.costAdjustment")
    .service("AssetCostAdjustmentDialog", AssetCostAdjustmentDialog);

  /* @ngInject */
  function AssetCostAdjustmentDialog($mdDialog, $mdMedia) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/asset/cost-adjustment/asset-cost-adjustment.dialog.html",
      controller: "AssetCostAdjustmentDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
      multiple: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    /**
     * AssetCostAdjustmentDialog service
     *
     * @type {Object}
     */
    const service = {
      show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param { Object } options Dialog options
     * @param { Object } options.locals
     * @param { Asset[] } options.locals.assets One or more Asset(s) to manage
     *  cost adjustments for
     * @return {Promise}
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
