(function () {
  angular
    .module("akitabox.ui.dialogs.room.create")
    .controller("CreateRoomDialogController", CreateRoomDialogController);

  /* @ngInject */
  function CreateRoomDialogController(
    // Angular
    $q,
    // Third-party
    $stateParams,
    // Constants
    models,
    // Material
    $mdDialog,
    // Services
    FeatureFlagService,
    Router,
    PinTypeService,
    PinFieldService,
    RoomService,
    ToastService
  ) {
    var self = this;

    var UNSUPPORTED_DATA_TYPES = ["document_array", "tag_filter"];

    var data = {};
    data.values = {};

    // Attributes
    self.saving = false;
    self.loading = true;
    self.pinTypeLoaded = false;
    self.room = {
      building: self.building ? self.building._id : null,
      name: self.name || null,
      number: angular.isDefined(self.number) ? self.number : null,
      square_feet: angular.isDefined(self.square_feet)
        ? self.square_feet
        : null,
      pinType: null,
      values: {},
    };
    self.newRoom = null;
    self.model = models.ROOM.PLURAL;

    // Functions
    self.cancel = $mdDialog.cancel;
    self.create = create;
    self.onNameChange = onNameChange;
    self.onNumberChange = onNumberChange;
    self.onSqFeetChange = onSqFeetChange;
    self.onValueChange = onValueChange;
    self.isDisabled = isDisabled;
    self.isFieldSupported = isFieldSupported;
    self.onBuildingChange = onBuildingChange;

    init();

    /**
     * Initializes this dialog by pulling for the room pin type
     */
    function init() {
      if (angular.isEmpty(self.building)) {
        self.loading = false;
        return;
      }

      $q.all([setPinType(), setFloor()])
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function setFloor() {
      if (!self.floor && self.values) {
        return RoomService.getFloor(self.building._id, self.values).then(
          function (floor) {
            self.floor = floor || null;
            if (self.room.pinType) {
              var pinField = PinFieldService.getFloorPinField(
                self.room.pinType.fields
              );
              self.onValueChange({ model: floor }, pinField);
            }
            return floor;
          }
        );
      }
      return $q.resolve();
    }

    function setPinType() {
      /**
       * Grab the room pin type for the building, and automatically set it as the
       * pin type for the new room. There should only be one for the whole
       * building.
       */
      var params = { protected_type: models.ROOM.SINGULAR };
      var options = { cache: false };
      return PinTypeService.getAll(self.building._id, params, options).then(
        function (pinTypes) {
          // If we have been provided a pin type,
          // find it in the list and select it
          var pinTypeId = self.pinType ? self.pinType._id : null;
          if (pinTypeId) {
            for (var i = 0; i < pinTypes.length; ++i) {
              var pinType = pinTypes[i];
              if (pinType._id === pinTypeId) {
                self.room.pinType = pinType;
                selectPinType();
              }
              if (self.values) {
                populateRoomValues(self.pinType, self.values);
              }
            }
          } else if (pinTypes.length === 1 && pinTypes[0]) {
            self.room.pinType = pinTypes[0];
            selectPinType();
          }
        }
      );
    }

    /**
     * Handle room building change
     *
     * @param {Event} $event Change event
     */
    function onBuildingChange($event) {
      self.building = $event.model;
      self.room.building = self.building ? self.building._id : null;
      self.floor = null;

      if (!self.building) return;

      self.pinTypeLoaded = false;
      setPinType().then(function () {
        self.pinTypeLoaded = true;
      });
    }

    /**
     * Called on pin type change. Responsible for setting initial values for the new pin type.
     */
    function selectPinType() {
      self.pinTypeLoaded = false;
      self.room.values = {};

      var pinFields = self.room.pinType.fields || [];

      for (var i = pinFields.length - 1; i >= 0; --i) {
        var pinField = pinFields[i];

        var tempValue = getEmptyPinValueObject(pinField);

        // Set values (that we already have) for the new pin type
        if (tempValue.is_level_pin_value && !angular.isEmpty(self.floor)) {
          tempValue.name = self.floor.name;
          tempValue.is_empty = false;
          tempValue.value = self.floor._id;
          data.values[pinField._id] = self.floor._id;
        }

        if (!pinField.is_default_empty) {
          var defaultValue = pinField.default_value;
          data.values[pinField._id] = defaultValue;
          tempValue.value = defaultValue;
        }

        self.room.values[pinField._id] = tempValue;
      }

      self.pinTypeLoaded = true;

      function getEmptyPinValueObject(pinField) {
        return {
          building: pinField.building,
          pinField: pinField._id,
          is_empty: true,
          is_room_pin_value: pinField.is_room_field,
          is_level_pin_value: pinField.is_level_field,
        };
      }
    }

    /**
     * Handle room name change
     *
     * @param {Event} $event Change event
     */
    function onNameChange($event) {
      self.room.name = $event.model;
    }

    function onNumberChange($event) {
      self.room.number = $event.model;
    }

    /**
     * Handle room square feet change
     *
     * @param {Event} $event Change event
     */
    function onSqFeetChange($event) {
      self.room.square_feet = $event.model;
    }

    /**
     * Function which handles the updating of the field values as the user changes them
     *
     * @param   field   pinField object
     * @param   value   value chosen for the given field
     */
    function onValueChange($event, field) {
      var value = $event.model;
      if (!Object.prototype.hasOwnProperty.call(self.room.values, field._id)) {
        // the field doesn't exist in this room, don't update anything
        return;
      }

      if (Object.prototype.hasOwnProperty.call(data.values, field._id)) {
        // clear out the data we send to the database
        delete data.values[field._id];
      }

      if (field.is_level_field) {
        self.floor = value;
        if (value) {
          value = value._id;
        }
      }

      if (angular.isEmpty(value)) {
        self.room.values[field._id] = {
          value: value,
        };
      } else {
        var pinValue = self.room.values[field._id];
        self.room.values[field._id] = angular.extend({}, pinValue, {
          value: value,
        });
        data.values[field._id] = value;
      }
    }

    /**
     * Function which sends the room data to be made on the server
     */
    function create() {
      if (self.saving) return;
      self.saving = true;
      data.name = angular.copy(self.room.name);
      data.number = angular.copy(self.room.number);
      data.square_feet = angular.copy(self.room.square_feet);
      data.pinType = angular.copy(self.room.pinType._id);
      var params = { include_values: true };

      RoomService.create(self.building._id, data, params)
        .then(function (room) {
          room.pinType = angular.copy(self.room.pinType);
          room.square_feet = angular.copy(self.room.square_feet);
          self.newRoom = room;

          $mdDialog.hide([room]);
          if (!self.hideToast) {
            ToastService.complex()
              .text("Successfully created room")
              .action("View", goToNewRoom)
              .show();
          }
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }

    /**
     * Determine if the submit button should be disabled.
     * Asset name, building, required pin fields are required
     *
     * @return {Boolean} True if disabled, false if not
     */
    function isDisabled() {
      if (
        !self.room.name ||
        !self.room.building ||
        !self.room.number ||
        self.saving
      ) {
        return true;
      }

      // Check pin values
      var fields = self.room.pinType.fields;
      for (var i = 0; i < fields.length; ++i) {
        var pinField = fields[i];
        var value = data.values[pinField._id];
        if (pinField.is_required && angular.isEmpty(value)) {
          return true;
        }
      }

      return false;
    }

    /**
     * Determine if a pin field is supported by this create dialog
     *
     * @return {Boolean}  True if supported, false if not
     */
    function isFieldSupported(field) {
      return UNSUPPORTED_DATA_TYPES.indexOf(field.data_type) < 0;
    }

    /**
     * Go to the newly created room's detail page
     */
    function goToNewRoom() {
      var stateParams = {
        buildingId: $stateParams.buildingId ? self.building._id : null,
        roomId: self.newRoom._id,
      };
      Router.go("app.room", stateParams);
    }

    /**
     * Populate room (form) values, ignores floor value
     *
     * @param {Object}    pinType     Selected pin type
     * @param {Object[]}  newValues   Pin values to populate
     */
    function populateRoomValues(pinType, newValues) {
      var pinFields = pinType.fields.reduce(function (map, field) {
        map[field._id] = field;
        return map;
      }, {});
      var keys = Object.keys(newValues);
      for (var i = 0; i < keys.length; ++i) {
        var key = keys[i];
        var value = newValues[key];
        var field = pinFields[value.pinField];
        if (!field || field.is_level_field) {
          continue; // ignore floor
        } else {
          self.onValueChange({ model: value.value }, field);
        }
      }
    }
  }
})();
