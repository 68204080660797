(function () {
  angular
    .module("akitabox.ui.dialogs.bulkAssign")
    .factory("BulkAssignDialog", BulkAssignDialog);

  /** @ngInject */
  function BulkAssignDialog($log, $mdDialog, $mdMedia, $q) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/bulk-assign/bulk-assign.dialog.html",
      controller: "BulkAssignDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    return {
      show: show,
    };

    /**
     *
     * @note
     * Make sure the workOrders are passed in by reference or their assignees
     * will not be updated correctly on the client side here
     *
     * @param options
     * @param options.locals
     * @param {Building} options.building - an entire building obj
     * @param {Array<Task>>} options.locals.workOrders - list of WOs
     * @param {Array<FutureTask>>} options.locals.maintenanceSchedules - list of MSs
     * @param {Array<Task>>} options.locals.selectedIndexes - list of indexes for the WOs or MSs
     * @param {Boolean}    [options.locals.showTextNotificationByEmail] - (Optional) If true shows the text of notification by email
     *
     * @return {Promise<void>}
     */
    function show(options) {
      var error = new Error("Sorry, we weren't able to complete your request");
      var hasErrored = false;

      if (!options) {
        $log.error("BulkAssignDialog: no options provided");
        hasErrored = true;
      } else if (!options.locals) {
        $log.error("BulkAssignDialog: no locals provided");
        hasErrored = true;
      } else if (
        !options.locals.selectedIndexes ||
        !Array.isArray(options.locals.selectedIndexes)
      ) {
        $log.error("BulkAssignDialog: invalid selectedIndexes provided");
        hasErrored = true;
      } else if (
        options.locals.workOrders &&
        !Array.isArray(options.locals.workOrders)
      ) {
        $log.error("BulkAssignDialog: invalid workOrders provided");
        hasErrored = true;
      } else if (
        options.locals.maintenanceSchedules &&
        !Array.isArray(options.locals.maintenanceSchedules)
      ) {
        $log.error("BulkAssignDialog: invalid maintenanceSchedules provided");
        hasErrored = true;
      } else if (
        !options.locals.workOrders &&
        !options.locals.maintenanceSchedules
      ) {
        $log.error(
          "BulkAssignDialog: at least workOrders or maintenanceSchedules has to be provided"
        );
        hasErrored = true;
      } else if (
        options.locals.workOrders &&
        options.locals.maintenanceSchedules
      ) {
        $log.error(
          "BulkAssignDialog: workOrders and maintenanceSchedules cannot both be provided"
        );
        hasErrored = true;
      }

      if (hasErrored) {
        return $q.reject(error);
      }

      return $mdDialog.show(angular.extend({}, modal, options));
    }
  }
})();
