(function () {
  /**
   * @ngdoc module
   * @name akitabox.ui.directives.attachmentUpload
   */
  angular
    .module("akitabox.ui.directives.attachmentUpload")
    .directive("abxAttachmentUpload", AbxAttachmentUpload);

  /** @ngInject */
  function AbxAttachmentUpload(DocumentService, ToastService, models) {
    function hasInvalidFileExtension(files) {
      var hasInvalidExtension;
      for (var i = 0; i < files.length; i++) {
        var fileName = files[i].name;
        // We can have files with multiple dots in the name
        // We want to always get the last position in this case
        var fileNameSplitted = fileName.split(".");
        var extension = "." + fileNameSplitted[fileNameSplitted.length - 1];
        if (
          !models.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.includes(
            extension.toLowerCase()
          )
        ) {
          // we found one file that has an invalid extension so we can quit looking
          hasInvalidExtension = fileName;
          break;
        }
      }

      if (hasInvalidExtension) {
        ToastService.showError(
          "The following file " +
            hasInvalidExtension +
            " has an invalid extension, please upload a file with a valid extension."
        );
      }

      return Boolean(hasInvalidExtension); // ! ! turns the string into a boolean
    }

    return {
      restrict: "A",
      controller: "AbxAttachmentUploadController",
      controllerAs: "vm",
      bindToController: true,
      scope: {
        buildingId: "=building",
        onUploadComplete: "&abxUploadComplete",
        onChange: "&abxAttachmentUpload",
        limit: "=abxLimit",
      },
      link: function (scope, element, attributes, vm) {
        element[0].addEventListener("change", function (event) {
          var files = event.target.files;

          if (files.length > vm.limit) {
            ToastService.showError("Attachment upload limit reached");
          }

          if (files.length > 0) {
            if (hasInvalidFileExtension(files)) {
              event.target.value = null;
              return;
            }

            var fileUploads = [];

            // if limit has been exceeded, don't upload additional files
            var numFilesToUpload = Math.min(files.length, vm.limit);
            for (var i = 0; i < numFilesToUpload; i++) {
              (function () {
                var file = files[i];
                var fileUpload = {
                  file: file,
                  retry: function () {
                    vm.uploadFile(fileUpload);
                  },
                };

                vm.uploadFile(fileUpload);

                fileUploads.push(fileUpload);
              })();
            }

            if (vm.onChange) {
              vm.onChange()(fileUploads);
            }

            // clear state of the <input> so that the same file can be selected again
            event.target.value = null;
          }
        });
      },
    };
  }
})();
