(function () {
  angular
    .module("akitabox.desktop.directives.list.room", [
      "akitabox.core.services.building",
      "akitabox.core.services.flag",
      "akitabox.core.services.organization",
      "akitabox.core.services.pinValue",
      "akitabox.core.services.request",
      "akitabox.core.services.schedule",
      "akitabox.core.services.user",
      "akitabox.core.services.workOrder",
      "akitabox.core.services.flag",
      "akitabox.core.toast",
      "akitabox.ui.dialogs.decommission",
      "akitabox.ui.dialogs.recommission",
      "akitabox.ui.dialogs.delete",
      "akitabox.ui.dialogs.room.delete",
      "akitabox.ui.dialogs.bulkEdit.pinField",
      "akitabox.ui.dialogs.schedule.create",
      "akitabox.ui.dialogs.request.create",
      "akitabox.ui.dialogs.workOrder.create",
    ])
    .directive("abxRoomList", AbxRoomListDirective);

  /* @ngInject */
  function AbxRoomListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/list/room/room-list.html",
      controller: RoomListDirective,
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=?abxBuilding",
        models: "=abxModels",
        parentFetch: "&abxFetch",
        parentFetchAll: "&abxFetchAll",
        allowAdd: "&?abxAllowAdd",
        createOptions: "=?abxCreateOptions",
        decommissioned: "<?abxDecommissioned",
        buildingPinTypes: "=?abxPinTypes",
      },
    };
  }

  /* @ngInject */
  function RoomListDirective(
    // Angular
    $scope,
    $timeout,
    // Dialogs
    CreateScheduleDialog,
    CreateRequestDialog,
    CreateWorkOrderDialog,
    DecommissionDialog,
    BulkEditPinFieldDialog,
    DeleteDialog,
    RecommissionDialog,
    // Services
    BuildingService,
    OrganizationService,
    PinValueService,
    PinTypeService,
    ToastService,
    UserService
  ) {
    var self = this;
    var permissions = UserService.getPermissions();

    // Attributes
    self.permissions = getDefaultPermissions();
    self.organization = OrganizationService.getCurrent();
    self.buildings = {};

    // Functions
    self.getListActions = getListActions;
    self.getRooms = getRooms;
    self.roundValue = roundValue;
    self.getAllRooms = getAllRooms;
    self.getPinValueByFieldName = PinValueService.getByFieldName;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      if (!self.building) {
        $scope.$watchCollection("vm.models", function (models) {
          if (models && !self.building) {
            BuildingService.populateBuildings(self.buildings, models);
          }
        });
      }

      self.permissions.canAddSchedule = permissions.future_task.create;
      self.permissions.canAddRequest = permissions.request.create;
      self.permissions.canAddWorkOrder = permissions.task.create;
      self.permissions.canDeleteRoom = permissions.room.remove;
      self.permissions.canEditRoom = permissions.room.update;
      self.permissions.canDecommissionRoom = permissions.room.decommission;
      self.permissions.canRecommissionRoom = permissions.room.recommission;
    }

    function getDefaultPermissions() {
      return {
        canAddRequest: false,
        canAddWorkOrder: false,
        canAddSchedule: false,
        canDeleteRoom: false,
      };
    }

    function createServiceRequest(items) {
      var locals;
      var room = self.models[items[0]];
      var building = self.building
        ? self.building
        : self.buildings[room.building];
      if (!building) {
        building = self.buildings[room.building._id];
      }
      const currBuilding = BuildingService.orgBuildings.find((building) => {
        return building._id === building._id;
      });
      locals = {
        building: {
          ...building,
          show_issue_types: currBuilding.show_issue_types,
          require_issue_types: currBuilding.require_issue_types,
        },
        room: room,
        floor: room.level,
      };

      return CreateRequestDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function createWorkOrder(items) {
      var locals;
      var rooms = items.map(function (index) {
        return self.models[index];
      });
      var building = self.building
        ? self.building
        : self.buildings[rooms[0].building._id];
      const currBuilding = BuildingService.orgBuildings.find((building) => {
        return building._id === building._id;
      });
      locals = {
        building: {
          ...building,
          show_issue_types: currBuilding.show_issue_types,
          show_trades: currBuilding.show_trades,
          require_issue_types: currBuilding.require_issue_types,
          require_trades: currBuilding.require_trades,
        },
        rooms: rooms,
      };

      return CreateWorkOrderDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    function createSchedule(items) {
      var locals;
      var rooms = items.map(function (index) {
        return self.models[index];
      });
      var building = self.building
        ? self.building
        : self.buildings[rooms[0].building._id];
      const currBuilding = BuildingService.orgBuildings.find((building) => {
        return building._id === building._id;
      });
      locals = {
        building: {
          ...building,
          show_maintenance_types: currBuilding.show_maintenance_types,
          show_trades: currBuilding.show_trades,
          require_maintenance_types: currBuilding.require_maintenance_types,
          require_trades: currBuilding.require_trades,
        },
        rooms: rooms,
      };
      return CreateScheduleDialog.show({ locals: locals }).catch(
        ToastService.showError
      );
    }

    /**
     * Opens the dialog to delete a room
     * @param {Number[]} roomIndexes - the index of the selected room
     * @return {Promise<void>}
     */
    function deleteRooms(roomIndexes) {
      var locals = {};
      // There should only be 1 index passed in ever
      locals.room = self.models[roomIndexes[0]];
      // TODO: Refresh/filter list after deletion
      return DeleteDialog.show({
        locals: locals,
      })
        .then(function (deletedRoomId) {
          // update self.models to remove the relevant room
          self.models = self.models.filter(function (room) {
            return room._id !== deletedRoomId;
          });
          $timeout(function () {
            $scope.$broadcast("list:refreshClickEvents");
          });
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens the dialog to decommission a room
     * @param {Number[]} roomIndexes - the indexes of the selected room
     * @return {Promise<void>}
     */
    function decommissionRoom(roomIndexes) {
      // There should only be 1 index passed in ever
      var room = self.models[roomIndexes[0]];
      var locals = {
        room: room,
      };

      return DecommissionDialog.show({
        locals: locals,
      })
        .then(function (decommissionedRoom) {
          if (decommissionedRoom) {
            // remove the room from the list if it was decommissioned
            self.models.splice(roomIndexes[0], 1);
          }
        })
        .catch(ToastService.showError);
    }

    /**
     * Opens the recommission dialog
     * @param {Number[]} roomIndexes - the indexes of the selected rooms
     * @return {Promise<void>}
     */
    function recommissionRooms(roomIndexes) {
      // There should only be 1 index passed in ever
      var rooms = roomIndexes.map(function (index) {
        return self.models[index];
      });
      var locals = {
        rooms: rooms,
      };

      return RecommissionDialog.show({ locals: locals })
        .then(function (recommissionedRooms) {
          // Remove the rooms from this list
          if (recommissionedRooms && recommissionedRooms.length) {
            var recomissionedIds = recommissionedRooms.map(function (room) {
              return room._id;
            });
            self.models = self.models.filter(function (room) {
              return recomissionedIds.indexOf(room._id) === -1;
            });
            $timeout(function () {
              $scope.$broadcast("list:refreshClickEvents");
            });
          }
        })
        .catch(ToastService.showError);
    }

    const bulkEditRoomsShowDialog = (buildingId, rooms, pinTypes) => {
      const locals = {
        selectedItems: rooms,
        buildingId,
        model: "rooms",
        pinTypes,
      };

      return BulkEditPinFieldDialog.show({ locals: locals })
        .then(() =>
          $timeout(() => {
            self.models = null;
            $scope.$broadcast("list:refresh");
          })
        )
        .catch(ToastService.showError);
    };

    const bulkEditRooms = (roomIndexes) => {
      const rooms = roomIndexes.map((room) => self.models[room]);

      const building = self.building
        ? self.building._id
        : self.buildings[rooms[0].building._id]._id;

      if (!self.buildingPinTypes) {
        // In this case it's necessary to get PinTypes
        // because it is being called using All Buildings option
        return PinTypeService.get(building, {
          protected_type: "Room",
        })
          .then(function (_pinTypes) {
            const pinTypes = _pinTypes;
            bulkEditRoomsShowDialog(building, rooms, pinTypes);
          })
          .catch(ToastService.showError);
      } else {
        return bulkEditRoomsShowDialog(building, rooms, self.buildingPinTypes);
      }
    };
    // ------------------------
    //   Public Functions
    // ------------------------
    this.onSelect = (index, deselected) => {
      const model = deselected ? null : self.models[index];
      $scope.$emit("room_selected", model);
    };

    function getListActions(items) {
      var addRequestAction = {
        text: "Add Service Request",
        icon: "announcement",
        onClick: createServiceRequest,
      };
      var addWorkOrderAction = {
        text: "Add Work Order",
        icon: "build",
        onClick: createWorkOrder,
      };
      var addScheduleAction = {
        text: "Add Maintenance Schedule",
        icon: "insert_invitation",
        onClick: createSchedule,
      };
      var deleteRoomAction = {
        text: "Delete",
        icon: "delete",
        onClick: deleteRooms,
      };
      var decommissionAction = {
        text: "Decommission",
        icon: "location_off",
        onClick: decommissionRoom,
      };
      var recommissionAction = {
        text: "Recommission",
        icon: "pin_drop",
        onClick: recommissionRooms,
      };
      const bulkEditRoomsAction = {
        text: "Edit Fields",
        icon: "edit",
        onClick: bulkEditRooms,
      };

      var actions = [];
      var isDecommissioned = false;
      var room = self.models[items[0]];
      var isWorkOrderOrg = self.organization.show_tasks;

      if (self.decommissioned === "true" || room.is_decommissioned) {
        isDecommissioned = true;
      }

      if (isDecommissioned) {
        // Add recommission action
        if (self.permissions.canRecommissionRoom)
          actions.push(recommissionAction);
      } else {
        if (isWorkOrderOrg) {
          // Allow service request creation if one room is selected
          if (items.length === 1 && self.permissions.canAddRequest) {
            actions.push(addRequestAction);
          }
        }

        var allSameBuilding;
        // building list or only one item, same bldg by definition
        if (self.building || items.length === 1) {
          allSameBuilding = true;
        } else {
          // org list and multiple items, check if all same bldg
          var rooms = items.map(function (index) {
            return self.models[index];
          });
          allSameBuilding = rooms.every(function (room) {
            var firstBuildingId = rooms[0].building._id || rooms[0].building;
            var curBuildingId = room.building._id || room.building;
            return firstBuildingId === curBuildingId;
          });
        }
        if (items.length && allSameBuilding) {
          if (self.permissions.canAddWorkOrder)
            actions.push(addWorkOrderAction);
          if (self.permissions.canAddSchedule) actions.push(addScheduleAction);
          if (self.permissions.canEditRoom) actions.push(bulkEditRoomsAction);
        }

        // Add decommission action
        if (items.length === 1 && self.permissions.canDecommissionRoom) {
          actions.push(decommissionAction);
        }
      }

      // Add delete action
      if (self.permissions.canDeleteRoom) {
        if (items.length === 1) {
          actions.push(deleteRoomAction);
        }
      }

      return actions;
    }

    function getRooms(skip, limit) {
      return self.parentFetch({
        skip: skip,
        limit: limit,
      });
    }

    function roundValue(value) {
      return value ? Math.round(value) : "";
    }

    function getAllRooms(limit) {
      return self.parentFetchAll({
        limit: limit,
      });
    }
  }
})();
