(() => {
  angular
    .module("akitabox.ui.components.catalogItemsSelection", [
      "akitabox.core.toast",
      "akitabox.core.services.catalogItem",
    ])
    .component("abxCatalogItemsSelection", {
      bindings: {
        catalog: "<abxCatalog",
        setInvalid: "<abxSetInvalid",
        existingCostLine: "<abxExistingCostLine",
        onCatalogItemDataChange: "<abxOnCatalogItemDataChange",
      },
      controllerAs: "vm",
      controller: AbxCatalogItemsSelectionController,
      templateUrl:
        "app/core/ui/components/catalog-items-selection/catalog-items-selection.component.html",
    });

  /* @ngInject */
  function AbxCatalogItemsSelectionController(
    enums,
    $scope,
    ToastService,
    CatalogItemService
  ) {
    /**
     * Holds private methods to be internally used within the controller
     */
    const _private = {
      formatUnit: (enumKey, value, variant) => {
        if (!enumKey || !value) return "";

        let formattedValue = null;
        const enumObj = enums[enumKey];

        switch (variant) {
          case "long":
            formattedValue = enumObj?.DISPLAY_LONG[value];
            break;
          case "short":
          default:
            formattedValue = enumObj?.DISPLAY_SHORT[value];
            break;
        }

        return formattedValue ? formattedValue : value;
      },
      reset: (shouldResetSearch = true) => {
        if (shouldResetSearch) {
          this.searchText = null;
        }
        this.quantity = 1;
        this.baseCost = 0;
        this.catalogItems = [];
        this.selectedCatalogItem = null;
      },
      calcBaseCost: () => {
        if (this.selectedCatalogItem && this.quantity > 0) {
          this.baseCost = this.toDollars(
            this.selectedCatalogItem.unit_cost_cents * this.quantity
          );
        }
      },
      emitData: () => {
        let model = null;
        if (this.selectedCatalogItem !== null) {
          model = { quantity: this.quantity, ...this.selectedCatalogItem };
        }
        const invalid =
          this.quantity < 1 ||
          this.baseCost === 0 ||
          this.selectedCatalogItem === null;

        this.setInvalid(invalid);
        this.onCatalogItemDataChange({ invalid, model });
      },
    };

    // ==============================
    // ========= ATTRIBUTES =========
    // ==============================
    this.quantity = 1;
    this.baseCost = 0;
    this.searchText = null;
    this.catalogItems = [];
    this.isFetching = false;
    this.selectedCatalogItem = null;

    // ===============================
    // ======== DATA FETCHING ========
    // ===============================
    this.fetchCatalogItems = (skip, limit) => {
      const params = { skip, limit };
      if (this.searchText) {
        params.search = this.searchText;
      }
      this.isFetching = true;
      return CatalogItemService.get(this.catalog, params)
        .then(({ data: catalogItems }) => {
          if (!Array.isArray(catalogItems)) return 0;
          const filteredCatalogItems = catalogItems.filter(
            // only catalog items with cost
            (catalogItem) => !!catalogItem.unit_cost_cents
          );
          this.catalogItems = this.catalogItems.concat(filteredCatalogItems);
          return filteredCatalogItems.length;
        })
        .catch(ToastService.showError)
        .finally(() => {
          this.isFetching = false;
        });
    };

    // ==============================
    // ======= EVENT HANDLERS =======
    // ==============================
    this.onCatalogItemSelect = (index) => {
      const catalogItem = this.catalogItems[index];
      if (!catalogItem) return;
      $scope.$apply(() => {
        if (
          !this.selectedCatalogItem ||
          this.selectedCatalogItem.id !== catalogItem.id
        ) {
          this.selectedCatalogItem = catalogItem;
        } else {
          this.selectedCatalogItem = null;
        }
        _private.calcBaseCost();
        _private.emitData();
      });
    };

    this.onSearchChange = ({ value }) => {
      this.searchText = value;
    };

    this.onQuantityChange = ({ value }) => {
      this.quantity = value;
      _private.calcBaseCost();
      _private.emitData();
    };

    // ==============================
    // ========= LIFE CYCLE =========
    // ==============================
    this.$onInit = () => {
      $scope.$watch(
        "vm.searchText",
        angular.debounce(() => {
          if (this.searchText !== null) {
            _private.reset(false);
            _private.emitData();
            $scope.$broadcast("list:refresh");
          }
        }, 500)
      );

      if (this.existingCostLine) {
        const {
          _id,
          quantity,
          uniformat,
          description,
          quantity_unit,
          suggested_cost,
        } = this.existingCostLine;

        this.quantity = quantity;

        if (!uniformat && this.existingCostLine.abx_catalog_item) {
          let itemId;
          CatalogItemService.getOneById(
            this.catalog,
            this.existingCostLine.abx_catalog_item
          )
            .then(({ data: catalogItem }) => {
              if (catalogItem) {
                itemId = catalogItem.item_id;
              }
            })
            .catch(ToastService.showError)
            .finally(() => {
              let descriptionText;
              if (itemId) {
                descriptionText = `${itemId} - ${description}`;
              } else {
                descriptionText = `${description}`;
              }
              this.selectedCatalogItem = {
                quantity_unit,
                costLineId: _id,
                unit_cost_cents: suggested_cost * 100,
                description: descriptionText,
              };
              _private.calcBaseCost();
            });
        } else {
          this.selectedCatalogItem = {
            quantity_unit,
            costLineId: _id,
            unit_cost_cents: suggested_cost * 100,
            description: `${uniformat} - ${description}`,
          };
          _private.calcBaseCost();
        }
      }
    };

    this.$onChanges = (changes) => {
      if (
        changes.catalog &&
        !changes.catalog.isFirstChange() &&
        changes.catalog.previousValue !== changes.catalog.currentValue
      ) {
        _private.reset();
        _private.emitData();
        $scope.$broadcast("list:refresh");
      }

      if (
        changes.existingCostLine &&
        !changes.existingCostLine.isFirstChange() &&
        changes.existingCostLine.previousValue !==
          changes.existingCostLine.currentValue
      ) {
        _private.reset();
        _private.emitData();
      }
    };

    this.$onDestroy = () => {
      _private.reset();
      _private.emitData();
    };

    // =============================
    // ========= UTILITIES =========
    // =============================
    this.toDollars = (cents) => {
      if (!cents) return 0;
      const centsNumber = Number(cents);
      if (Number.isNaN(centsNumber)) return 0;
      return centsNumber / 100;
    };

    this.toCurrency = (cents) => {
      const dollars = this.toDollars(cents);
      if (dollars === 0) return "";
      const { format } = new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
      });
      return format(dollars);
    };

    this.formatSizeUnit = (value, variant) => {
      return _private.formatUnit("SIZE_UNIT", value, variant);
    };

    this.formatQuantityUnit = (value, variant) => {
      return _private.formatUnit("QUANTITY_UNIT", value, variant);
    };
  }
})();
