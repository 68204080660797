(function () {
  angular.module("akitabox.ui.components.appHeader", [
    "angular.filter",
    "ui.router",
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.bannerMessage",
    "akitabox.core.services.building",
    "akitabox.core.services.customerAccount",
    "akitabox.core.services.document",
    "akitabox.core.services.flag",
    "akitabox.core.services.floor",
    "akitabox.core.services.helpers",
    "akitabox.core.services.identity",
    "akitabox.core.services.organization",
    "akitabox.core.services.qrcode",
    "akitabox.core.services.schedule",
    "akitabox.core.services.redirect",
    "akitabox.core.services.request",
    "akitabox.core.services.room",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.user",
    "akitabox.core.toast",
    "akitabox.core.utils",
    "akitabox.ui.components.pinSearch",
    "akitabox.ui.components.simpleDropdown",
    "akitabox.ui.dialogs.qrCode.manualEntry",
    "akitabox.ui.dialogs.qrScanner",
    "akitabox.core.lib.markdown",
  ]);
})();
