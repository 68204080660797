import { FC, useMemo } from "react";
import { Route } from "react-router-dom";
import { LegacyAwareRouter } from "./LegacyAwareRouter";
import { AngularShell } from "./AngularShell";
import { NotFound } from "../NotFound";
import { ReportBuilderList } from "../lists/report-builder/ReportBuilderList";
import { ReportBuilderEditor } from "../report-builder/ReportBuilderEditor";
import { shellLoader } from "../loaders/shellLoader";
import { AssetList } from "../asset-list/AssetList";
import { api } from "../api";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { ReportTemplateList } from "../lists/report-template/ReportTemplateList";
import { ReportTemplateEditor } from "../report-template/ReportTemplateEditor";
import { InspectionChecklistList } from "../inspection-checklists/InspectionChecklistList";
import RedirectBuildings from "./RedirectBuildings";
export const DesktopRouter: FC = () => {
  /**
   * Base desktop react routes. When we no longer need the dynamic routing
   * features that pull in angularjs routes, this can be converted to a
   * single static router.
   *
   * Register all react routes here in a `<Route>` component hierarchy. All routes
   * will be nested under a global route that renders the `<WebClientApp>`
   */

  // sucks but we need to call flags here and inside the shellLoader
  const { data: flagsResponse } = makeUseServiceCall(api.flags.get)();
  const flags = useMemo(() => flagsResponse?.data, [flagsResponse]);

  const desktopRoutes = useMemo(
    () => [
      <Route
        id="shell"
        key="shell"
        loader={shellLoader}
        element={<AngularShell />}
      >
        <Route
          path="report_builder/reports"
          key="report_builder_list"
          element={<ReportBuilderList />}
        />
        {flags && flags["report_template"] && (
          <Route
            path="report_builder/templates"
            key="report_template_list"
            element={<ReportTemplateList />}
          />
        )}
        {flags && flags["report_template"] && (
          <Route
            path="report_builder/templates/:template"
            key="report_template_editor"
            element={<ReportTemplateEditor />}
          />
        )}
        <Route
          key="report_builder_editor"
          path="report_builder/:report"
          element={<ReportBuilderEditor />}
        />
        {flags && flags["enhanced_asset_list"] && (
          <Route
            key="ehanced_asset_list"
            path="enhanced_assets"
            element={<AssetList />}
          />
        )}
        {flags && flags["enhanced_asset_list"] && (
          <Route
            key="redirect_buildings"
            path="buildings/:building/enhanced_assets"
            element={<RedirectBuildings path={"enhanced_assets"} />}
          />
        )}
        {flags && flags["enhanced_inspections_and_wo"] && (
          <Route
            key="inspection_checklist_list"
            path="inspection_checklists"
            element={<InspectionChecklistList />}
          />
        )}
      </Route>,
      <Route path="*" element={<NotFound />} key="notfound" />,
    ],
    [flags]
  );

  return <LegacyAwareRouter reactRoutes={desktopRoutes} />;
};
