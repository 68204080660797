import { useContext } from "react";

import { ShellDOMContext } from "../legacy/AngularShell";

/**
 * Utility hook to access DOM elements (refs) rendered in `<AngularShell />` component.
 * Useful to interact with DOM-level data such as focus, measure size/position, browser APIs, etc
 *
 * Important:
 * - **DO NOT** use this to modify the DOM in any way
 * - Manually modifing the DOM will cause conflicts with changes from React
 * @see https://react.dev/learn/manipulating-the-dom-with-refs#best-practices-for-dom-manipulation-with-refs
 */
export const useShellDOM = () => useContext(ShellDOMContext);
