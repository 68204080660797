(function () {
  /**
   * @ngdoc component
   * @name abxRoomInputComponent
   *
   * @param {Boolean} disabled - if input is disabled
   * @param {String} floorId - Floor to fetch rooms for
   * @param {Function} onSelect - To be invoked when a room is selected
   * @param {Function} onChange - To be invoked when the value in the input has
   *     changed (i.e. a `keyup` event), but the input is not blurred. Should be
   *     invoked with: event.model, event.value, [event.invalid].
   * @param {Function} onFocus - to be invoked when the input is focused
   * @param {Function} onBlur - To be invoked when the input is blurred
   * @param {String} value - Display value for currently selected room
   * @param {Boolean} [blurOnEnter=false] - Blur the input on Enter keypress.
   * @param {String} placeholder - placeholder attribute for the input element
   *
   * @description
   * Room input component
   */
  angular
    .module("akitabox.ui.components.roomInput")
    .component("abxRoomInputComponent", {
      bindings: {
        model: "<abxModel",
        value: "<abxValue",
        buildingId: "<abxBuildingId",
        floorId: "<abxFloorId",
        onBlur: "&abxOnBlur",
        onSelect: "&abxOnSelect",
        onChange: "&abxOnChange",
        onFocus: "&abxOnFocus",
        disabled: "<abxDisabled",
        required: "<?abxRequired",
        blurOnEnter: "<?abxBlurOnEnter",
        placeholder: "<abxPlaceholder",
      },
      controller: AbxRoomInputComponentController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/room-input/room-input.component.html",
    });

  function AbxRoomInputComponentController(
    // Angular
    $q,
    // Services
    PinFieldService,
    PinTypeService,
    PinValueService,
    RoomService,
    ServiceHelpers,
    ToastService,
    Utils,
    OrganizationService
  ) {
    var self = this;
    const _organization = OrganizationService.getCurrent();
    // Private
    var roomsRequest;
    var pinFields; // Fields of the building's room pin type
    var pinFieldsRequest;

    // Attributes
    self.loading = false;
    self.roomOptions = []; // Options objects consumable by the typeahead
    self.blurOnEnter = self.blurOnEnter || false;
    self.getRooms = getRooms;

    // Functions
    self.handleFocus = handleFocus;

    // =================
    // Life Cycle
    // =================

    /**
     * Handle changes from parent bindings
     *
     * @param {Object}    changes       changes object with currentValue and previousValue for each binding
     */
    self.$onChanges = function (changes) {
      let modelBuildingId = self.model && self.model.building;
      if (modelBuildingId && modelBuildingId._id)
        modelBuildingId = modelBuildingId._id;
      var buildingChanged =
        Utils.hasChanged(changes.buildingId) &&
        (self.model
          ? changes.buildingId.currentValue !== modelBuildingId
          : true);
      let modelLevelId = self.model && self.model.level;
      if (modelLevelId && modelLevelId._id) modelLevelId = modelLevelId._id;
      var floorChanged =
        Utils.hasChanged(changes.floorId) &&
        (self.model ? changes.floorId.currentValue !== modelLevelId : true);
      // Need to get pin fields if building changes
      pinFieldsRequest = getPinFields();
      // On building/floor change, make the next focus do a fresh rooms request
      if (buildingChanged || floorChanged) {
        roomsRequest = undefined;
        self.roomOptions = [];
        if (self.model) {
          getRoomValues(self.model).then(function (values) {
            self.model.values = values;
            var buildingId = getBuildingId();
            if (self.model.building) {
              buildingId = self.model.building;
            }
            if (buildingId && buildingId._id) {
              buildingId = buildingId._id;
            }
            var floorId =
              self.model.level || PinValueService.getFloorId(self.model.values);
            if (floorId && floorId._id) {
              floorId = floorId._id;
            }
            var diffBuilding =
              buildingChanged && !Utils.isSame(buildingId, self.buildingId);
            var diffFloor =
              floorChanged && !Utils.isSame(floorId, self.floorId);
            // If model's building or floor is different,
            // clear out input and trigger change
            if (diffBuilding || diffFloor) {
              var $event = {
                model: null,
                value: null,
              };
              self.onChange({ $event: $event });
            }
          });
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Fetch the rooms for this input's given floor.
     *
     * @param {object} [$event]
     * @param {boolean} [$event.noLimit]
     * @param {string} [$event.value]
     *
     * @return {Promise} - Resolves when rooms have been fetched and parsed
     */
    function getRooms($event) {
      // set event default to empty objects to avoid errors
      !$event && ($event = {});

      var params = {
        building: getBuildingId(),
        level: self.floorId,
      };

      $event.value &&
        (params.$or = `name=${$event.value};number=${$event.value}`);
      var request = $event.noLimit
        ? RoomService.getAllBFF(self.buildingId, params, {}, _organization._id)
        : RoomService.getBFF(self.buildingId, params, {}, _organization._id);
      self.loading = true;
      return request
        .then(function (rooms) {
          if (angular.isEmpty(rooms)) return [];

          return parseRoomOptions(rooms);
        })
        .then(function (roomOptions) {
          self.roomOptions = roomOptions;
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    /**
     * Handle input focus
     */
    function handleFocus() {
      self.onFocus();

      if (!roomsRequest && self.buildingId) {
        roomsRequest = getRooms();
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * It returns the current building id.
     * @returns {String} buildingId.
     */
    function getBuildingId() {
      const { buildingId } = self;
      // Safety check to handle { _id } within buildingId
      return buildingId?.["_id"] ?? buildingId ?? "";
    }

    /**
     * Get room pin values
     *
     * @param {Object} room  Room to get pin values for
     *
     * @return {Promise<Object|Error>} Resolves with pin values object
     */
    function getRoomValues(room) {
      return $q(function (resolve) {
        if (room) {
          if (room.values) {
            resolve(room.values);
          } else {
            resolve(RoomService.getAllValues(room.building, room._id));
          }
        } else {
          resolve({});
        }
      });
    }

    /**
     * Get and save the pin fields for the building's room pin type. These
     * fields can be used later (i.e. for parsing room display names).
     *
     * @return {Promise<Object[]>} - Resolves with room pin type pin fields
     */
    function getPinFields() {
      const buildingId = getBuildingId();
      if (!buildingId) {
        return $q.resolve([]);
      }
      var params = { protected_type: "Room" };
      return PinTypeService.getAll(buildingId, params, { cache: false })
        .then(function (pinTypes) {
          // Each building should have exactly one room pin type
          var roomPinType = pinTypes[0];
          return PinFieldService.getAll(buildingId, roomPinType._id, {
            cache: false,
          });
        })
        .then(function (roomPinFields) {
          pinFields = roomPinFields;
          return roomPinFields;
        });
    }

    /**
     * Parse a list of rooms into a list of typeahead-consumable options of
     * form: { model, value }, where `model` is the room model, and `value`
     * is the room's display name
     *
     * @param {Array} rooms - rooms array
     *
     * @return {Promise<Object[]>} - Resolves with parsed options
     */
    function parseRoomOptions(rooms) {
      if (!pinFieldsRequest) {
        return $q.resolve([]);
      }
      return pinFieldsRequest.then(function () {
        var requests = [];
        rooms.forEach(function (room) {
          var request = ServiceHelpers.getRoomDisplayName(room, pinFields).then(
            function (displayName) {
              return {
                model: room,
                value: displayName,
              };
            }
          );
          requests.push(request);
        });

        return $q.all(requests);
      });
    }
  }
})();
