(function () {
  angular
    .module("akitabox.ui.dialogs.message.edit")
    .controller("EditMessageDialogController", EditMessageDialogController);

  /* @ngInject */
  function EditMessageDialogController(
    // Angular
    $q,
    $timeout,
    // Material
    $mdDialog,
    // Services
    ToastService,
    NoteService
  ) {
    var self = this;

    // Attributes
    self.saving = false;
    self.deleting = false;
    self.deleted = false;
    self.successful = false;
    self.text = "";

    // Functions
    self.cancel = $mdDialog.cancel;
    self.editNote = editNote;
    self.isValid = isValid;
    self.deleteMessage = deleteMessage;

    self.$onInit = function () {
      self.text = self.message.text;
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Checks if user can submit note
     * the only check for now is the text length
     * @return {boolean}
     */
    function isValid() {
      return self.text.length > 0;
    }

    function deleteMessage() {
      if (self.deleting || self.deleted) {
        return $q.resolve();
      }
      self.deleting = true;
      var deletedMessage = self.message;

      return NoteService.remove(self.building._id, self.message._id)
        .then(function () {
          self.deleted = true;
          return $timeout(function () {
            $mdDialog.hide({ deleted: true, message: deletedMessage });
          }, 500);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.deleting = false;
        });
    }

    /**
     * Sends API request to create the note
     * @return {*}
     */
    function editNote() {
      if (self.saving || self.successful) {
        return $q.resolve();
      }
      self.saving = true;
      var data = {
        text: self.text,
      };

      return NoteService.update(self.building._id, self.message._id, data)
        .then(function (message) {
          self.successful = true;
          return $timeout(function () {
            $mdDialog.hide({ message: message });
          }, 500);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
