(function () {
  angular.module("akitabox.core.auth").run(stateBlock);

  /* @ngInject */
  function stateBlock(Router) {
    Router.configureStates([
      {
        state: "auth",
        config: {
          abstract: true,
          templateUrl: "app/core/modules/auth/auth.html",
          data: {
            pushHistory: false,
          },
        },
      },
      {
        state: "auth.lockedOut",
        config: {
          url: "/locked-out?email",
          templateUrl: "app/core/modules/auth/locked-out/locked-out.html",
          controller: "LockedOutController",
          controllerAs: "vm",
          data: {
            pageTitle: "Locked Out",
          },
        },
      },
      {
        state: "auth.login",
        config: {
          url: "/login?redirect_url&validation_token&email",
          templateUrl: "app/core/modules/auth/login/login.html",
          controller: "LoginController",
          controllerAs: "vm",
          reloadOnSearch: false,
          data: {
            pageTitle: "Login",
          },
        },
      },
      {
        state: "auth.logout",
        config: {
          url: "/logout?redirect_url",
          controller: "LogoutController",
          data: {
            pageTitle: "Logout",
          },
        },
      },
      {
        state: "auth.register",
        config: {
          url: "/register?email",
          templateUrl: "app/core/modules/auth/register/register.html",
          controller: "RegisterController",
          controllerAs: "vm",
          data: {
            pageTitle: "Register",
          },
        },
      },
      {
        state: "auth.sendValidation",
        config: {
          url: "/send-validation?email",
          templateUrl:
            "app/core/modules/auth/send-validation/send-validation.html",
          controller: "SendValidation",
          controllerAs: "vm",
          data: {
            pageTitle: "Validate Account",
          },
        },
      },
    ]);
  }
})();
