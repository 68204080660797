(function () {
  angular.module("akitabox.ui.dialogs.workOrder.complete", [
    "akitabox.core.constants",
    "akitabox.core.lib.moment",
    "akitabox.core.services.assembly",
    "akitabox.core.services.organization",
    "akitabox.core.services.shadow",
    "akitabox.core.services.workOrder",
    "akitabox.core.toast",
    "ngMaterial",
  ]);
})();
