import * as exif from "exif-js";

(function () {
  angular.module("akitabox.core.lib.exif", []).factory("EXIF", ExifService);

  /* @ngIject */
  function ExifService() {
    if (!exif) throw new Error("EXIF is not available");
    return exif;
  }
})();
