(function () {
  /**
   * @ngdoc service
   * @name ServiceHelpers
   *
   * @description
   * Helper functions.
   */
  angular
    .module("akitabox.core.services.helpers", [
      "akitabox.core.services.asset",
      "akitabox.core.services.room",
      "akitabox.core.services.pinField",
      "akitabox.core.services.pinValue",
      "akitabox.core.lib.exif",
    ])
    .factory("ServiceHelpers", ServiceHelpers);

  function ServiceHelpers(
    // Angular
    $log,
    $timeout,
    $q,
    // AkitaBox
    models,
    // Libraries
    EXIF,
    // Services
    AssetService,
    RoomService
  ) {
    var service = {
      getRoomDisplayName: getRoomDisplayName,
      parsePinService: parsePinService,
      getDocumentData: getDocumentData,
      get: get,
      getBlobFromImageUrl: getBlobFromImageUrl,
      getTags: getTags,
    };

    return service;

    /**
     * Given a room, parse and return its display name. This is a concatentation
     * of its number and name.
     *
     * @param {Object} room - Room to parse display name from
     * @param {Object|String} room.pinType - Room's pin type. Can be populated
     *     or not. If populated, its `fields` will be used, and the `fields`
     *     parameter will be ignored.
     * @param {Object} [room.values] - Pin values for the room. If omitted,
     *     a fetch for them will occur since a room's "Name" is stored in
     *     its `values`.
     * @param {Object[]} [fields] - Pin fields of the room's pin type. Necessary
     *     to find the name pin value of the room. Will be fetched if omitted
     *     and not present on `room.pinType`.
     * @return {Promise<String>} - The room's display name
     */
    function getRoomDisplayName(room, fields) {
      if (!room || !room.number) {
        return $q.resolve(null);
      }

      return $q.resolve(`${room.number} ${room.name}`);
    }

    /**
     * Determine the service to use for the given pin type. Each pin type is limited to
     * a specific type/model of pin, and that model's service will be returned.
     *
     * @param  {Object} pinType - Pin type used for parsing
     * @return {Object} - The parsed service
     */
    function parsePinService(pinType) {
      if (pinType.protected_type === "Asset") return AssetService;
      if (pinType.protected_type === "Room") return RoomService;

      $log.error("Cannot parse service to use for pin type", pinType);
    }

    /**
     * Get Document data. This function is intended to find if a document
     * is 2d or 3d and get data related to the document..
     *
     * @param  {Object} document - The document object to look for
     */
    function getDocumentData(document) {
      const extension = document.extension.toLowerCase();
      const rotation = document.rotation;
      return getBlobFromImageUrl(document).then((blob) => {
        let data = { blob, rotation };
        if (models.DOCUMENT.EQUIRECTANGULAR_IMAGE_TYPES.includes(extension)) {
          return getTags(blob).then((tags) => {
            data = { ...data, ...tags };
            return data;
          });
        } else {
          return data;
        }
      });
    }

    function getTags(blob) {
      return $q(function (res) {
        EXIF.enableXmp();
        EXIF.getData(blob, function () {
          const orientation = EXIF.getTag(this, "Orientation") || 0;
          let rendering = "2d";
          const usePanoramaViewer =
            get(this, [
              "xmpdata",
              "x:xmpmeta",
              "rdf:RDF",
              "rdf:Description",
              "GPano:UsePanoramaViewer",
              "#text",
            ]) ||
            get(this, [
              "xmpdata",
              "x:xmpmeta",
              "rdf:RDF",
              "rdf:Description",
              "@attributes",
              "GPano:UsePanoramaViewer",
            ]);
          const type =
            get(this, [
              "xmpdata",
              "x:xmpmeta",
              "rdf:RDF",
              "rdf:Description",
              "GPano:ProjectionType",
              "#text",
            ]) ||
            get(this, [
              "xmpdata",
              "x:xmpmeta",
              "rdf:RDF",
              "rdf:Description",
              "@attributes",
              "GPano:ProjectionType",
            ]);
          if (usePanoramaViewer && usePanoramaViewer.toLowerCase() === "true") {
            rendering = "3d";
          } else if (type === "equirectangular") {
            rendering = "3d";
          }
          res({ orientation, rendering, type });
        });
      });
    }

    function getBlobFromImageUrl(document) {
      let imageUrl = document.public_url;
      if (document.extension.toLowerCase().contains("pdf")) {
        imageUrl = document.public_thumbnail_url_display;
      }
      return $q(function (res) {
        fetch(imageUrl, {}).then((response) => res(response.blob()));
      });
    }

    // Get deeply nested property or null
    function get(o, path) {
      return path.reduce(function (xs, x) {
        return xs && xs[x] ? xs[x] : null;
      }, o);
    }
  }
})();
