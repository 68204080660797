(function () {
  angular
    .module("akitabox.desktop.floor.detail")
    .controller("FloorDetailsTabController", FloorDetailsTabController);

  /* @ngInject */
  function FloorDetailsTabController($timeout, building, floor) {
    var self = this;

    self.floor = floor;
    self.building = building;
    self.rendering = true;

    $timeout(function () {
      self.rendering = false;
    }, 1000);
  }
})();
