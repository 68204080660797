(function () {
  /**
   * @ngdoc module
   * @name akitabox.core.services.cancellable
   */
  angular
    .module("akitabox.core.services.cancellable", [])
    .factory("CancellableService", CancellableService);

  /** @ngInject */
  /**
   * @ngdoc factory
   * @module akitabox.core.services.cancellable
   * @name CancellableService
   *
   * @description
   * Service for composing promise-returning functions into a cancellable
   * series.
   */
  function CancellableService($q) {
    var FIRST_RESULT = {};

    var service = {
      executeSeries: executeSeries,
      createPipeline: createPipeline,
    };

    /**
     * Execute the given functions. Each function will be used
     * as its predecessor's onFulfilled (`.then()`) handler. Execution can be
     * halted at any time by using the result Cancellable's cancel function.
     *
     * @param {Function[]} functions - The functions to execute.
     * @return {Object} A result object with two keys:
     *  - result.promise - A promise that will resolve when the cancellable
     *    is complete with the final function's return value, or reject with the
     *    specified reason when it is cancelled. Additionally, each intermediate
     *    result will be sent to this promise's onProgress handler.
     *  - result.cancel - A function that takes a reason argument and prevents
     *    any further provided functions from being executed. Causes result.promise
     *    to reject with the provided reason.
     */
    function executeSeries(functions) {
      if (!angular.isArray(functions)) {
        throw new Error("AbxCancellableService#create: No functions provided.");
      }

      if (functions.length === 0) {
        // Since the promise is already resolved, cancelling it is not possible.
        // So we can safely just give back a noop function.
        return {
          promise: $q.resolve(),
          cancel: function () {},
        };
      }
      // Flag to indicate if a consumer has requested cancellation.
      var cancelled = false;
      // The deferred that controls this function's resulting promise.
      var resultDeferred = $q.defer();
      // Flag used to prevent notifying of the same value repeatedly on cancel.
      var doneNotifying = false;

      /**
       * Attach a function as the next operation in a promise chain, forcing it
       * to respect the cancelled flag at execution time, and notify of the
       * previous result.
       * @param {Object} promise - The promise to attach the function to.
       * @param {Function} fn - The function to execute next.
       * @param {Boolean} notify - Whether or not to notify of previous result.
       * @return {Promise<any>} - The new promise.
       */
      var attachFunction = function (promise, fn, notify) {
        return promise.then(function (intermediateResult) {
          if (!doneNotifying && notify) {
            // Notify our consumer of intermediate results.
            resultDeferred.notify(intermediateResult);
          }

          if (!cancelled) {
            try {
              if (intermediateResult === FIRST_RESULT) {
                return fn();
              } else {
                return fn(intermediateResult);
              }
            } catch (err) {
              return $q.reject(err);
            }
          } else {
            doneNotifying = true;
            return;
          }
        });
      };

      var promise = $q.resolve(FIRST_RESULT);
      for (var i = 0; i < functions.length; i++) {
        // Attach our functions, don't notify on the first resolve since it
        // will always be undefined.
        promise = attachFunction(promise, functions[i], i !== 0);
      }

      // Resolve our promise when work is totally done
      promise
        .then(function (finalResult) {
          if (!doneNotifying) {
            resultDeferred.notify(finalResult);
          }
          resultDeferred.resolve(finalResult);
        })
        .catch(function (reason) {
          resultDeferred.reject(reason);
        });

      return {
        promise: resultDeferred.promise,
        cancel: function (reason) {
          cancelled = true;
          resultDeferred.reject(reason);
        },
      };
    }

    /**
     * Creates a pipeline object for managing executions of cancellables.
     * @param {any} [cancelReason] - The default cancellation reason to use for
     *    this pipeline.
     * @return {CancellablePipeline} - A CancellablePipeline that can be used to
     *    manage execution of cancellables. It will use the provided reason to
     *    cancel.
     */
    function createPipeline(cancelReason) {
      return new CancellablePipeline(cancelReason);
    }

    /**
     * @class CancellablePipeline
     * An object for managing execution of cancellables.
     * @param {any} [cancelReason] - The default cancellation reason to use for
     *    this pipeline.
     *
     * @member {Cancellable} cancellable - The current cancellable.
     * @member {any} CancelReason - The default cancellation reason to use.
     * @member {Promise} promise - The current cancellable's promise, or
     *    an already resolved promise if no cancellables have been tracked yet.
     */
    function CancellablePipeline(cancelReason) {
      this.cancelReason = cancelReason;
      // Create a noop cancellable to start from
      this.switchTo(executeSeries([]));
    }

    /**
     * @method cancel
     * Cancel the currently running cancellable, or do nothing if there is none.
     * @param {any} [cancelReason] - The reason to use when cancelling the
     *    underlying cancellable. Defaults to this.cancelReason.
     * @return void
     */
    CancellablePipeline.prototype.cancel = function (cancelReason) {
      if (arguments.length === 0) {
        cancelReason = this.cancelReason;
      }
      if (this.cancellable) {
        this.cancellable.cancel(cancelReason);
      }
    };

    /**
     * @method switchTo
     * Cancels the currently running cancellable, and switch to another.
     * @param {Cancellable} cancellable - The new cancellable to track.
     * @return {Promise} The cancellable's promise, for easy chaining.
     */
    CancellablePipeline.prototype.switchTo = function (cancellable) {
      this.cancel();
      this.cancellable = cancellable;
      return this.cancellable.promise;
    };

    return service;
  }
})();
