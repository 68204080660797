(function () {
  angular
    .module("akitabox.ui.dialogs.qrConfirmAssign")
    .controller(
      "QrConfirmAssignDialogController",
      QrConfirmAssignDialogController
    );

  /* @ngInject */
  function QrConfirmAssignDialogController(
    // Material
    $mdDialog,
    // Services
    QRCodeService,
    ShadowService,
    ToastService
  ) {
    var self = this;

    // Attributes
    self.saving = false;

    // Functions
    self.cancel = cancel;
    self.assign = assign;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Assign the QR Code to the selected pin
     * @return {Promise | Object}  Promise that resolves with the associated QR Code
     */
    function assign() {
      self.saving = true;
      var association;

      if (self.pin.pinType.is_room) {
        association = { room: self.pin._id };
      } else {
        association = { asset: self.pin._id };
      }

      if (self.pin.qrCode) {
        return QRCodeService.disassociate(self.pin.qrCode._id).then(
          function () {
            return associateQRCode(association, true);
          }
        );
      } else {
        return associateQRCode(association, false);
      }
    }

    /**
     * Closes the dialog
     */
    function cancel() {
      if (self.pin.qrCode) {
        ShadowService.sendEvent("qr-code", "cancel-replace");
      } else {
        ShadowService.sendEvent("qr-code", "cancel-assign");
      }

      $mdDialog.cancel();
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    /**
     * Stop saving, associate the QR code, and hide the dialog
     * @param {Object}  association - room/asset with pinID (e.g. { room: roomID }) to associate with the qr code
     * @param {Boolean}  isReplaceAction - flag used to signify if it is a replace action, used for analytics
     * @return {Promise | Object} Promise that resolves with the associated QR Code
     */
    function associateQRCode(association, isReplaceAction) {
      return QRCodeService.associate(self.qrCode._id, association)
        .then(function (associatedQRCode) {
          if (isReplaceAction) {
            ShadowService.sendEvent("qr-code", "confirm-replace");
          } else {
            ShadowService.sendEvent("qr-code", "confirm-assign");
          }

          $mdDialog.hide(associatedQRCode);
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.saving = false;
        });
    }
  }
})();
