(function () {
  angular
    .module("akitabox.core.services.permissionGroups", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("PermissionGroupsService", PermissionGroupsService);

  /** @ngInject */
  function PermissionGroupsService(
    // AkitaBox
    models,
    // Services
    HttpService
  ) {
    var service = {
      getSRPPermGroupIdByOrg: getSRPPermGroupIdByOrg,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Get the Service Request Portal permission group id within an Organization
     * @param {String} organizationId    The organization id in which to fetch permission groups
     * @return { string | null }         The permission group id to use or null if is not set.
     */
    function getSRPPermGroupIdByOrg(organizationId) {
      const route = `/organizations/${organizationId}/${models.PERMISSION_GROUP.ROUTE_PLURAL}`;
      return HttpService.get(route, {
        name: models.PERMISSION_GROUP.DEFAULTS.SRP.name,
      }).then((permissionGroup) => {
        return Array.isArray(permissionGroup) && permissionGroup.length === 1
          ? permissionGroup[0]._id
          : null;
      });
    }
  }
})();
