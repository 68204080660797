(function () {
  angular.module("akitabox.desktop.reporting", [
    "akitabox.constants",
    "akitabox.core.services.building",
    "akitabox.core.services.flag",
    "akitabox.core.services.chart",
    "akitabox.core.services.organization",
    "akitabox.core.services.session",
    "akitabox.core.toast",
    "akitabox.ui.components.helpTooltip",
    "akitabox.ui.components.reportingFilterBar",
    "akitabox.ui.components.reportingCharts",
    "akitabox.ui.components.reportingKpi",
    "akitabox.ui.components.tabs",
  ]);
})();
