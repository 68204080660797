(function () {
  angular.module("akitabox.desktop.admin.job", [
    "ui.router",
    "akitabox.constants",
    "akitabox.core.router",
    "akitabox.ui.dialogs.job.create",
    "akitabox.desktop.admin.job.accountList",
    "akitabox.desktop.admin.job.devList",
    "akitabox.desktop.admin.job.detail",
  ]);
})();
