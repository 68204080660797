(function () {
  angular.module("akitabox.desktop.workOrder.list", [
    "ui.router",
    "akitabox.core.constants",
    "akitabox.core.services.asset",
    "akitabox.core.services.filter",
    "akitabox.core.services.flag",
    "akitabox.core.services.floor",
    "akitabox.core.services.room",
    "akitabox.core.services.timeZone",
    "akitabox.core.services.trade",
    "akitabox.core.services.type",
    "akitabox.core.services.workOrder",
    "akitabox.core.services.workOrdersBff",
    "akitabox.core.toast",
    "akitabox.ui.components.workOrderCalendar",
    "akitabox.ui.dialogs.export",
    "akitabox.ui.dialogs.print",
    "akitabox.ui.dialogs.workOrder",
    "akitabox.desktop.components.filterBar",
    "akitabox.desktop.components.filterBarManager",
    "ngMaterial",
  ]);
})();
