(function () {
  /**
   * @ngdoc component
   * @name abxSimpleDropdown
   *
   * @param {Array<MenuItem>} menuItems - Array of menuItems to be rendered
   * @param {String} [menuItem.dest] - URL/URI destination. menuItem will be rendered as <a> if it has this.
   * @param {Boolean} [menuItem.targetSelf] - If true, the <a> tag will have `target=_self` on it
   * @param {Func} [menuItem.onClick] - An onClick to apply to the menuItem.
   *
   * @param {String} [selectedTitle] - Applies selected styles to the menuItem with matching title
   * @param {Boolean} isOpen - Whether or not to show the dropdown
   * @param {Func} toggleOpen - Function to toggle the open status of the dropdown. Should accept a param to set the status.
   *
   * @description
   * A simple dropdown in the style of the AppSwitcher dropdown.
   * If a 'path' is provided for a menu-item it will render an <a> otherwise a <button>
   * Position (incl. 'absolute') should be handled on the parent on the `abx-simple-dropdown` ELEMENT in css
   */
  angular
    .module("akitabox.ui.components.simpleDropdown", [])
    .component("abxSimpleDropdown", {
      bindings: {
        isOpen: "<abxIsOpen",
        toggleOpen: "<abxToggleOpen",
        menuItems: "<?abxMenuItems",
        selectedTitle: "<?abxSelectedTitle",
      },
      controller: AbxSimpleDropdownController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/simple-dropdown/simple-dropdown.component.html",
    });

  /* @ngInject */
  function AbxSimpleDropdownController($scope, $timeout, $window) {
    var self = this;

    // Functions
    self.getButtonClass = getButtonClass;

    // =================
    // Public Functions
    // =================
    function getButtonClass(item) {
      var buttonClass = item.icon
        ? "abx-simple-dropdown__list__item__icon-button"
        : "abx-simple-dropdown__list__item__button";
      if (item.title === self.selectedTitle) {
        buttonClass += " selected";
      }
      return buttonClass;
    }
    // =================
    // Private Functions
    // =================
    // Function to allow closing the header by clicking
    var clickEvent = function () {
      $scope.$apply(function () {
        self.toggleOpen(false);
      });
    };

    // function to allow closing the header by pressing escape key or enter or something
    var keyDownEvent = function (event) {
      if (
        event.key === "Escape" ||
        event.key === "Esc" ||
        event.key === "Enter"
      ) {
        $scope.$apply(function () {
          self.toggleOpen(false);
        });
      }
    };

    $scope.$watch("vm.isOpen", function (newValue, oldValue) {
      if (newValue && !oldValue) {
        // Went from hidden to visible, attach click event to allow us to easily close the app-switcher
        // $timeout is used to avoid calling clickEvent on the initial click
        $timeout(function () {
          $window.document.addEventListener("click", clickEvent);
          $window.document.addEventListener("keydown", keyDownEvent);
        });
      } else if (!newValue && oldValue) {
        // Went from visible to open, remove events
        $window.document.removeEventListener("click", clickEvent);
        $window.document.removeEventListener("keydown", keyDownEvent);
      }
    });
  }
})();
