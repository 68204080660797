(function () {
  angular
    .module("akitabox.ui.dialogs.filePreview")
    .service("FilePreviewDialog", FilePreviewDialog);

  /* @ngInject */
  function FilePreviewDialog($mdDialog) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/file-preview/file-preview.dialog.html",
      controller: "FilePreviewDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: true,
      focusOnOpen: false,
      fullscreen: true,
    };

    /**
     * AlertDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param  {Object}   options                    Dialog options
     * @param  {Object}   options.locals             Dialog options locals
     * @param  {String}   options.locals.title       The Title text for the Alert dialog
     * @param  {String}   options.locals.textContent The main message text to display in the alert body
     * @param  {String}   [options.locals.ok]        The text for the Alert dialog's close/ok button. Defaults to "got it!"
     * @return {Promise<Object|Error} Alert dialog promise
     */
    function show(options) {
      var dialogOptions = angular.extend({}, modal, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
