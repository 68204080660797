(function () {
  angular.module("akitabox.desktop.workOrder.detail").run(states);

  /* @ngInject */
  function states(Router) {
    var config = {
      overview: function () {
        return {
          url: "/overview",
          templateUrl:
            "app/desktop/modules/work-order/detail/overview/work-order-overview.html",
          controller: "WorkOrderOverviewTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order Overview",
          },
        };
      },
      asset: function () {
        return {
          url: "/asset",
          templateUrl:
            "app/desktop/modules/work-order/detail/asset/work-order-asset.html",
          controller: "WorkOrderAssetTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order Asset",
          },
        };
      },
      details: function () {
        return {
          url: "/details",
          templateUrl:
            "app/desktop/modules/work-order/detail/details/work-order-details.html",
          controller: "WorkOrderDetailsTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order Details",
          },
        };
      },
      floor: function () {
        return {
          url: "/floor",
          templateUrl:
            "app/desktop/modules/work-order/detail/floor/work-order-floor.html",
          controller: "WorkOrderFloorTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order Floor",
          },
        };
      },
      schedule: function () {
        return {
          url: "/maintenance-schedule",
          templateUrl:
            "app/desktop/modules/work-order/detail/schedule/work-order-schedule.html",
          controller: "WorkOrderScheduleTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order Schedule",
          },
        };
      },
      room: function () {
        return {
          url: "/room",
          templateUrl:
            "app/desktop/modules/work-order/detail/room/work-order-room.html",
          controller: "WorkOrderRoomTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order Room",
          },
        };
      },
      request: function () {
        return {
          url: "/service-request",
          templateUrl:
            "app/desktop/modules/work-order/detail/request/work-order-request.html",
          controller: "WorkOrderRequestTabController",
          controllerAs: "vm",
          data: {
            pageTitle: "Work Order SR",
          },
        };
      },
    };
    Router.configureStates([
      {
        state: "app.building.workOrder.overview",
        config: config.overview(),
      },
      {
        state: "app.building.workOrder.asset",
        config: config.asset(),
      },
      {
        state: "app.building.workOrder.details",
        config: config.details(),
      },
      {
        state: "app.building.workOrder.floor",
        config: config.floor(),
      },
      {
        state: "app.building.workOrder.schedule",
        config: config.schedule(),
      },
      {
        state: "app.building.workOrder.room",
        config: config.room(),
      },
      {
        state: "app.building.workOrder.request",
        config: config.request(),
      },
      {
        state: "app.workOrder.overview",
        config: config.overview(),
      },
      {
        state: "app.workOrder.asset",
        config: config.asset(),
      },
      {
        state: "app.workOrder.details",
        config: config.details(),
      },
      {
        state: "app.workOrder.floor",
        config: config.floor(),
      },
      {
        state: "app.workOrder.schedule",
        config: config.schedule(),
      },
      {
        state: "app.workOrder.room",
        config: config.room(),
      },
      {
        state: "app.workOrder.request",
        config: config.request(),
      },
    ]);
  }
})();
