(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.simpleList
   */
  angular
    .module("akitabox.desktop.directives.simpleList", ["akitabox.core"])
    .controller("AbxSimpleListController", AbxSimpleListController)
    .directive("abxSimpleList", AbxSimpleListDirective);

  /**
   * @ngdoc directive
   * @name AbxSimpleListDirective
   * @module akitabox.desktop.directives.simpleList
   * @restrict E
   *
   * @description
   * `<abx-simple-list>` is directive for all simple lists
   * `<abx-simple-list items>` is an array of entity objects
   * `<abx-simple-list limit>` is the limit that will have been placed on the query that fetched the list items
   * `<abx-simple-list name>` is the string you want your list to be titled as
   * `<abx-simple-list showAllState>` is a state object that represents where you want to go when you click on the "Show
   *                                  All" button
   * `<abx-simple-list total>` is the actual total of the list being displayed, rather than just the items shown
   * `<abx-simple-list type>` is the bootstrap panel type, eg: panel-success
   *
   * @ngInject
   */
  function AbxSimpleListDirective() {
    return {
      restrict: "E",
      transclude: true,
      templateUrl: "app/desktop/directives/simple-list/simple-list.html",
      controller: "AbxSimpleListController",
      controllerAs: "vm",
      bindToController: true,
      link: postLink,
      scope: {
        emptyText: "@?",
        fullText: "@?",
        items: "=",
        name: "@",
        showAllState: "<?",
        total: "=",
        type: "@?",
        onShowAllClicked: "&?",
      },
    };

    function postLink($scope, $element, attrs, vm) {
      $scope.$watch("vm.items", function (newValue) {
        vm.items = newValue;
      });
    }
  }

  /* ngInject */
  function AbxSimpleListController($log, Router) {
    var self = this;

    if (!angular.isDefined(self.items))
      return $log.error("<abx-simple-list> items is required");
    if (!angular.isDefined(self.name))
      return $log.error("<abx-simple-list> name is required");
    if (!angular.isDefined(self.total))
      return $log.error("<abx-simple-list> total is required");

    if (!angular.isString(self.emptyText) || self.emptyText.length === 0)
      self.emptyText = "No items found";
    if (!angular.isString(self.fullText) || self.fullText.length === 0)
      self.fullText = "View all";
    if (angular.isEmpty(self.type)) self.type = "default";

    self.showEmpty = showEmpty;
    self.showFull = showFull;
    self.showAll = showAll;

    /**
     * Shows the empty message if the list is empty
     */
    function showEmpty() {
      return self.items.length === 0;
    }

    /**
     * Shows the "Show All" button if the list's items doesn't show all of the items available
     */
    function showFull() {
      return Boolean(self.items.length < self.total && self.showAllState);
    }

    /**
     * Goes to the given showAllState
     */
    function showAll() {
      if (angular.isFunction(self.onShowAllClicked)) {
        self.onShowAllClicked(); // Don't put async stuff in here, unless you know what you're doin'
      }
      Router.go(
        self.showAllState.state,
        self.showAllState.params,
        self.showAllState.options
      );
    }
  }
})();
