(function () {
  angular
    .module("akitabox.ui.dialogs.document.select")
    .controller(
      "SelectDocumentDialogController",
      SelectDocumentDialogController
    );

  /* @ngInject */
  function SelectDocumentDialogController(
    // Angular
    $element,
    $filter,
    $scope,
    // Libraries
    $mdDialog,
    $mdToast,
    // Services
    DocumentService,
    FilterService,
    ToastService,
    EnvService,
    FileService,
    // Constants
    models
  ) {
    var self = this;

    // Attributes
    self.uploading = false;
    self.fileHasFailed = false;
    self.alreadyUploaded = false;
    self.uploadingName = null;
    self.uploader = null;
    self.documents = null;
    self.filters = FilterService.getDocumentConfig(self.tagCategories);
    self.activeFilters = {};
    self.allowedFileTypes =
      models.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.join(",");

    self.fetchDocuments = fetchDocuments;
    self.changeFilters = changeFilters;
    self.selectDocument = selectDocument;
    self.startUpload = startUpload;
    self.cancel = $mdDialog.cancel;

    init();

    // Public Methods

    /**
     * Method to query for documents to populate the list
     *
     * @param {Number} skip
     * @param {Number} limit
     * @returns {Number} - the length of the returned documents
     */
    function fetchDocuments(skip, limit) {
      var params = {
        skip: skip || 0,
        limit: limit | 10,
      };
      var filters = angular.extend({}, params, self.activeFilters);

      return DocumentService.get(self.building._id, filters)
        .then(function (docs) {
          if (angular.isArray(self.documents)) {
            self.documents = self.documents.concat(docs);
          } else {
            self.documents = docs;
          }
          return docs.length;
        })
        .catch(ToastService.showError);
    }

    /**
     * Method to change the current filters on the list
     *
     * @param activeFilters
     */
    function changeFilters(activeFilters) {
      self.activeFilters = activeFilters;
      self.documents = null;
      $scope.$broadcast("list:refresh");
    }

    /**
     * Method to return the selected document from the list
     *
     * @param {Number} index
     * @returns {Object || null}
     */
    function selectDocument(index) {
      if (!self.documents[index] || self.saving) return;

      $mdDialog.hide(self.documents[index]);
    }

    /**
     * Method to execute the uploader object's .uploadItem() function
     */
    function startUpload(item) {
      self.uploader.uploadItem(item);
    }

    // Private Methods

    function init() {
      if (!self.building) return;

      initUploader();
    }

    function validateFileType(file) {
      const ext = `.${file.name.split(".").pop()?.toLowerCase()}`;
      if (!ext) {
        return false;
      }
      return models.DOCUMENT.UPLOAD_SUPPORTED_EXTENSIONS.indexOf(ext) > -1;
    }

    /**
     * Method to initialize the uploader object with some settings
     */
    function initUploader() {
      self.uploader = FileService.createUploader(
        self.building.cre_account,
        self.building._id
      );
      self.uploader.url =
        EnvService.getApiUrl() +
        DocumentService.buildBaseRoute(self.building._id);
      self.autoUpload = self.autoUpload || false;
      self.queueLimit = self.queueLimit || 100;
      self.withCredentials = true;

      // Setup filters if provided
      if (angular.isArray(self.filters) && self.filters.length) {
        for (var i = 0; i < self.filters.length; i++) {
          self.uploader.filters.push(self.filters[i]);
        }
      }

      self.uploader.filters.push({
        name: "validFileType",
        fn: validateFileType,
      });

      /**
       * onAfterAddingFile function(item) :: Fires after adding a single file to the queue
       */
      self.uploader.onAfterAddingFile = function (item) {
        item.withCredentials = true;
        item.status = {
          text: "Ready",
          className: "text-uppercase",
        };

        startUpload(item);
      };

      /**
       * onWhenAddingFileFailed function(item, filter, options) :: When adding a file failed
       */
      if (angular.isFunction(self.onWhenAddingFileFailed)) {
        self.uploader.onWhenAddingFileFailed = self.onWhenAddingFileFailed;
      } else {
        // use the default to catch errors
        self.uploader.onWhenAddingFileFailed = function (
          item,
          filter,
          options
        ) {
          var $parent = $element.find("md-dialog-content");
          var text;
          var position = "bottom right";
          var toastOptions;

          switch (filter.name) {
            case "maxFileSize":
              text =
                "File exceeds limit of " +
                $filter("byteFmt")(FileService.MAX_FILE_SIZE, 2);
              toastOptions = $mdToast
                .simple()
                .textContent(text)
                .parent($parent)
                .position(position);
              break;
            case "validFileType":
              text = "Invalid file type";
              toastOptions = $mdToast
                .simple()
                .textContent(text)
                .parent($parent)
                .position(position);
              break;
            default:
              break;
          }

          // Toast wont show if you pass undefined options, so this is fine
          // We can always try to show toast
          ToastService.show(toastOptions, true);
          // Clear the input
          angular.element(document.querySelector("#abx-file-uploader")).val("");
        };
      }

      /**
       * onAfterAddingAll function(addedItems) :: Fires after adding all the dragged or selected files to the queue
       */
      if (angular.isFunction(self.onAfterAddingAll)) {
        self.uploader.onAfterAddingAll = self.onAfterAddingAll;
      }

      /**
       * onBeforeUploadItem function(item) :: Fires before uploading an item.
       */
      self.uploader.onBeforeUploadItem = function (item) {
        self.uploading = true;
        self.fileHasFailed = false;
        self.failedReason = null;
        self.uploadingName = item.file.name;

        item.status = {
          text: "Uploading",
          className: "fg-gray text-uppercase",
        };
      };

      /**
       * onProgressItem function(item, progress) :: On file upload progress
       */
      if (angular.isFunction(self.onProgressItem)) {
        self.uploader.onProgressItem = self.onProgressItem;
      }

      /**
       * onSuccessItem function(item, response, status, headers) :: On file successfully uploaded
       */
      self.uploader.onSuccessItem = function (item, response, status, headers) {
        item.status = {
          text: "Complete",
          className: "fg-success text-uppercase",
        };
      };

      /**
       * onErrorItem function(item, response, status, headers) :: On upload error
       */
      self.uploader.onErrorItem = function (item, response, status, headers) {
        self.uploading = false;
        self.fileHasFailed = true;
        self.failedFile = item;
        item.status = {
          text: "Failed to upload",
          className: "fg-warn text-uppercase",
        };

        if (response && response.error && response.error.message) {
          item.status += ": " + response.error.message;
          self.failedReason = response.error.message;
        }
      };

      /**
       * onCancelItem function(item, response, status, headers) :: On cancel uploading
       */
      if (angular.isFunction(self.onCancelItem)) {
        self.uploader.onCancelItem = self.onCancelItem;
      }

      /**
       * onCompleteItem function(item, response, status, headers) :: On file upload complete
       *  - (independently of the success of the operation)
       */
      self.uploader.onCompleteItem = function (
        item,
        response,
        status,
        headers
      ) {
        self.uploading = false;
        if (angular.isArray(response) && response.length) {
          $mdDialog.hide(response[0]);
        } else if (angular.isArray(response) && !response.length) {
          // Nothing was uploaded because these files were already uploaded
          self.uploader.onErrorItem(item, {
            error: {
              text: "Already Uploaded",
            },
          });
          self.alreadyUploaded = true;
        }
      };

      /**
       * onProgressAll function(progress) :: On upload queue progress
       */
      if (angular.isFunction(self.onProgressAll)) {
        self.uploader.onProgressAll = self.onProgressAll;
      }
    }
  }
})();
