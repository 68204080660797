(function () {
  /**
   * @ngdoc component
   * @name abxDocumentListInput
   *
   * @param {Object} documents - The populated documents to be shown. This
   *     value **must** be updated by the parent after `onChange` calls.
   * @param {Number} [initialDocumentLimit=5] - The number of documents to display
   *    by default when the list is initialized.
   * @param {Function} onChange - To be called after edit or upload operations
   *    with the new array of document ids. Will not be called if the edit/upload
   *    operation is non-changing (eg: dialog cancelled, no files uploaded,
   *    no edits made)
   * @param {Object} pinField - Pin field model associated with the documents
   * @param {Object} pinValue - Pin value model associated with the documents
   * @param {Object} pin - Pin model associated with the documents
   * @param {Number} [showMoreStep=5] - The number of additional documents to be
   *    displayed upon using the "Show More" button.
   * @param {Function} refreshDocuments - Tell parent component to refresh the
   *     documents to be shown. Example use case is when uploading for a tag
   *     filter; the pin value hasn't been updated, so we can't call onChange.
   *     But the documents that match the tag filter should change, so we tell
   *     the parent component to re-fetch them.
   * @param {String} type - The type of document list being shown. Acceptable
   *     values:
   *       - "document_array": A simple list of documents. The edit button
   *           in this case will show a dialog that allows the user to edit
   *           this list.
   *       - "tag_filter": A list of documents that match a set of tags. The
   *           edit button in this case will show a dialog that allows the
   *           user to edit this set of tags (and therefore the matching
   *           documents).
   * @param {Boolean} [readOnly] - Property associated with displaying input values
   *    as readonly text
   *
   * @description
   * This component will display a given list of documents in a growable list
   * (via the Show More) button, and facilitate editing and uploading of
   * additional documents via our edit/upload dialogs.
   */
  angular
    .module("akitabox.ui.components.documentListInput")
    .component("abxDocumentListInput", {
      bindings: {
        documents: "<abxDocuments",
        loading: "<abxLoading",
        disabled: "<abxDisabled",
        onChange: "&abxOnChange",
        pinField: "<abxPinField",
        pinValue: "<abxPinValue",
        pin: "<abxPin",
        refreshDocuments: "&?abxRefreshDocuments",
        showMoreStep: "<?abxShowMoreStep",
        type: "@abxType",
        initialDocumentLimit: "<?abxInitialDocumentLimit",
        readOnly: "<?abxReadOnly",
        editable: "<?abxEditable",
        allowUpload: "<?abxAllowUpload",
      },
      controller: AbxDocumentListInputController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/document-list-input/document-list-input.component.html",
    });

  function AbxDocumentListInputController(
    // AkitaBox
    models,
    // Angular
    $q,
    $scope,
    // Dialogs
    EditDocumentArrayDialog,
    EditTagFilterDialog,
    UploadDocumentDialog,
    FilePreviewDialog,
    // Helpers
    Utils,
    // Services
    FeatureFlagService,
    FilterService,
    OrganizationService,
    TagService,
    TagCategoryService,
    ToastService,
    UserService
  ) {
    // Private variables
    var self = this;
    var buildingId;
    var _permissions = UserService.getPermissions();
    var canAddAttachments = self.pin.pinType.is_asset
      ? _permissions.asset.add_attachment
      : _permissions.room.add_attachment;

    // Attributes
    self.organization = OrganizationService.getCurrent();
    self.defaults = {
      showMoreStep: 5,
      initialDocumentLimit: 5,
    };
    self.currentDocumentLimit =
      self.initialDocumentLimit || self.defaults.initialDocumentLimit;
    self.saving = false;
    self.showMoreStep = self.showMoreStep || self.defaults.showMoreStep;
    self.showButtons =
      canAddAttachments &&
      (self.editable || self.allowUpload) &&
      !self.disabled &&
      !self.readOnly;

    // Functions
    self.showMore = showMore;
    self.hasMore = hasMore;
    self.showEditDialog = showEditDialog;
    self.showOcrLink = showOcrLink;
    self.showUploadDialog = showUploadDialog;
    self.onDocumentClick = onDocumentClick;

    // =================
    // Lifecycle
    // =================

    self.$onInit = function () {
      if (self.pin) {
        buildingId = self.pin.building._id || self.pin.building;
      }
    };

    self.$onChanges = function (changes) {
      if (changes.documents) {
        self.documents = self.documents || [];
      }

      if (
        changes.pin &&
        !Utils.isSameModel(self.pin, changes.pin.previousValue)
      ) {
        resetList();
      }
    };

    // =================
    // Public Functions
    // =================

    function onDocumentClick(document) {
      FilePreviewDialog.show({
        pin: self.pin,
        documentId: document._id,
        supportMultipleFiles: false,
      });
    }

    /**
     * Show more documents.
     */
    function showMore() {
      self.currentDocumentLimit = self.currentDocumentLimit + self.showMoreStep;
    }

    /**
     * Determine if there are more documents to display than are currently
     *    available
     *
     * @returns {Boolean} True iff there are more documents available
     *    than are currently displayed
     */
    function hasMore() {
      return (
        self.documents && self.currentDocumentLimit < self.documents.length
      );
    }

    /**
     * Show the edit dialog, and handle any changes from the dialog.
     */
    function showEditDialog() {
      if (self.type === "document_array") {
        editForDocumentArray();
      } else if (self.type === "tag_filter") {
        editForTagFilter();
      }
    }

    /**
     * Show the Text Recognition link if show_ocr is on
     * and the document has a valid extension.
     *
     * @param {Object} document
     */
    function showOcrLink(document) {
      if (!document) return false;

      const validExtension =
        models.DOCUMENT.FILE_PREVIEW_SUPPORTED_TYPES.includes(
          document.extension.toLowerCase()
        );

      return self.organization && self.organization.show_ocr && validExtension;
    }

    /**
     * Show the upload dialog, and handle new uploaded documents.
     */
    function showUploadDialog() {
      if (self.type === "document_array") {
        uploadForDocumentArray();
      } else if (self.type === "tag_filter") {
        uploadForTagFilter();
      }
    }

    // =================
    // Private Functions
    // =================

    /**
     * Creates and associates all the given tags to all of the given documents.
     *
     * @param {Object[]} tags - Tags to associated
     * @param {Object[]} documents - Documents to tag
     * @return {Promise<Object[]>} - Resolves with newly created tags
     */
    function createTagsForDocuments(tags, documents) {
      var requests = [];

      tags.forEach(function (tag) {
        documents.forEach(function (document) {
          var request = TagService.create(buildingId, document._id, tag);
          requests.push(request);
        });
      });

      return $q.all(requests);
    }

    /**
     * For document array lists, show the edit dialog, and notify the parent
     * component iff there changes were made.
     */
    function editForDocumentArray() {
      TagCategoryService.getAll(buildingId)
        .then(function (tagCategories) {
          var dialogOptions = getEditDialogOptions();
          dialogOptions.locals.filters =
            FilterService.getDocumentConfig(tagCategories);
          dialogOptions.locals.selectedDocuments = angular.copy(self.documents);

          return EditDocumentArrayDialog.show(dialogOptions);
        })
        .then(function (selectedDocuments) {
          if (angular.equals(self.documents, selectedDocuments)) {
            return;
          }

          self.saving = true;
          return self
            .onChange({
              $event: { newValue: getDocumentIds(selectedDocuments) },
            })
            .finally(function () {
              self.saving = false;
            });
        });
    }

    /**
     * For tag filter document lists, show the edit dialog, and notify the
     * parent component iff there changes were made.
     */
    function editForTagFilter() {
      TagCategoryService.getAll(buildingId)
        .then(function (tagCategories) {
          var dialogOptions = getEditDialogOptions();
          dialogOptions.locals.filters =
            FilterService.getTagConfig(tagCategories);

          return EditTagFilterDialog.show(dialogOptions);
        })
        .then(function (newTagFilters) {
          var initialTagFilters = self.pinValue.value;
          if (angular.equals(initialTagFilters, newTagFilters)) {
            return;
          }

          self.saving = true;
          return self
            .onChange({
              $event: { newValue: newTagFilters },
            })
            .finally(function () {
              self.saving = false;
            });
        });
    }

    /**
     * Get the base config/options for the dialog that will be shown when the
     * user clicks the "Edit" button.
     *
     * @return {Object} - Base edit dialog options
     */
    function getEditDialogOptions() {
      return {
        locals: {
          building: { _id: buildingId },
          pin: self.pin,
          isRoom: self.pin.pinType.is_room,
          isAsset: self.pin.pinType.is_asset,
          pinValue: self.pinValue,
          skipPinValueUpdate: true,
          title: "Edit " + self.pinField.name,
        },
      };
    }

    /**
     * Map an array of documents to their ids.
     * @param {Object[]} documents - An array of documents to have their ids
     *    extracted.
     * @returns {String[]} - The ID of the provided documents in the same order.
     */
    function getDocumentIds(documents) {
      var result = [];
      for (var i = 0; i < documents.length; i++) {
        result.push(documents[i]._id);
      }
      return result;
    }

    /**
     * Get the base config/options for the dialog that will be shown when the
     * user clicks the "Upload" button.
     *
     * @param {boolean} createRevisions - allow revision creation
     *
     * @return {Object} - Base upload dialog options
     */
    function getUploadDialogOptions(createRevisions) {
      if (
        self.organization &&
        self.organization.show_planview_offline_caching
      ) {
        return {
          locals: {
            indexedDBOptions: {
              parentId: self.pin._id,
              modelType: self.pin.pinType.name,
              uploadType: self.pinField.name,
            },
            building: { _id: buildingId },
            allowMultiple: true,
            revision: createRevisions,
          },
        };
      } else {
        return {
          locals: {
            building: { _id: buildingId },
            allowMultiple: true,
            revision: createRevisions,
          },
        };
      }
    }

    /**
     * Resets the list to its initial state, with the appropriate starting size.
     */
    function resetList() {
      self.currentDocumentLimit =
        self.initialDocumentLimit || self.defaults.initialDocumentLimit;
    }

    /**
     * For document array lists, show the upload dialog, and handle new
     * uploaded documents.
     */
    function uploadForDocumentArray() {
      var createRevisions = self.pinField.name !== "Photos";
      var dialogOptions = getUploadDialogOptions(createRevisions);
      UploadDocumentDialog.show(dialogOptions).then(function (documents) {
        var documentIds = getDocumentIds(self.documents);
        var newDocumentIds = getDocumentIds(documents);
        self.saving = true;
        return self
          .onChange({
            $event: { newValue: documentIds.concat(newDocumentIds) },
          })
          .then(function () {
            self.saving = false;
          });
      });
    }

    /**
     * For tag filter document lists, show the upload dialog, and handle new
     * uploaded documents.
     */
    function uploadForTagFilter() {
      if (!self.pinValue.value.length) {
        ToastService.showSimple(
          "You must assign " +
            models.TAG.PLURAL +
            " to this field first via the Edit button before uploading."
        );
        return;
      }

      var dialogOptions = getUploadDialogOptions(true);
      UploadDocumentDialog.show(dialogOptions)
        .then(function (documents) {
          var activeTagFilters = self.pinValue.value;
          return createTagsForDocuments(activeTagFilters, documents);
        })
        .then(self.refreshDocuments);
    }
  }
})();
