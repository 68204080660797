(function () {
  angular.module("akitabox.ui.components.buildingInfo", [
    "ngMaterial",
    "akitabox.constants",
    "akitabox.core.filters.tel",
    "akitabox.core.lib.moment",
    "akitabox.core.router",
    "akitabox.core.services.building",
    "akitabox.core.services.map",
    "akitabox.core.toast",
    "akitabox.ui.components.assetStats",
    "akitabox.ui.components.floorCardContainer",
    "akitabox.ui.components.input",
    "akitabox.ui.components.roomStats",
    "akitabox.ui.directives.map",
  ]);
})();
