import { FunctionComponent, useEffect } from "react";
import { useGetWidgetModel, WidgetModel } from "@sisense/sdk-ui";

export interface GetWidgetModelProps {
  dashboardOid: string;
  widgetOid: string;
  exitComponent: (widgetModel?: WidgetModel) => void;
}

export const GetWidgetModel: FunctionComponent<GetWidgetModelProps> = ({
  dashboardOid,
  widgetOid,
  exitComponent,
}) => {
  const { widget } = useGetWidgetModel({ dashboardOid, widgetOid });

  useEffect(() => {
    if (widget) {
      exitComponent(widget);
    }
  }, [widget, exitComponent]);

  return null;
};
