(function () {
  /**
   * @ngdoc component
   * @name abxCanvasControl
   *
   * @param {Function}  zoomIn            Handle zoomIn event
   * @param {Function}  zoomOut           Handle zoomOut event
   * @param {Function}  resetZoom         Handle resetZoom event
   * @param {Function}  rotate            Handle rotate event
   * @param {Object[]}  revisions         List of available revisions
   * @param {Object}    selectedRevision  Selected revision
   * @param {Function}  onRevisionChange  Handle revision change event
   * @param {Object}    revision          Currently selected/viewed revision
   * @param {Object}    currentRevision   "Current" revision for the floor plan
   *     (i.e. the latest one)
   * @param {Boolean}   [disableVersionChange]  Disable switching floor plan
   *     versions. Example use case is when the user is placing a pin (since a
   *     user cannot place a pin on an old version of a floor plan).
   * @param {Boolean}   [disablePageChange]  Disable switching floor plan pages.
   * @param {Boolean}   [disableRotation] Disable ability to rotate floor plan
   *
   * @description
   * Canvas controls to zoom, etc
   */
  angular.module("akitabox.planView").component("abxCanvasControl", {
    bindings: {
      zoomIn: "&abxZoomIn",
      zoomOut: "&abxZoomOut",
      resetZoom: "&abxResetZoom",
      onRotate: "&abxOnRotate",
      onPrint: "&abxOnPrint",
      numPages: "<abxNumPages",
      page: "<abxSelectedPage",
      revisions: "<abxRevisions",
      revision: "<abxSelectedRevision",
      onTogglePinNames: "&abxOnTogglePinNames",
      onRevisionChange: "&abxOnRevisionChange",
      onTogglePins: "&abxOnTogglePins",
      currentRevision: "<abxCurrentRevision",
      disableVersionChange: "<?abxDisableVersionChange",
      disablePageChange: "<?abxDisablePageChange",
      disableRotation: "<?abxDisableRotation",
    },
    controller: AbxCanvasControlController,
    controllerAs: "vm",
    templateUrl:
      "app/desktop/modules/plan-view/components/canvas-control/canvas-control.component.html",
  });

  function AbxCanvasControlController(
    $scope,
    $window,
    // Events
    EVENT_FLOOR_NEW_PAGE_SELECTED,
    // Services
    UserService,
    FeatureFlagService
  ) {
    var self = this;
    var _permissions = UserService.getPermissions();

    // Attributes
    self.hidePins = false;
    self.revision = self.revision || self.currentRevision;
    self.showPinNames = false;
    self.canvasControlCollapsed = false;
    self.showRotateButton = _permissions.document.rotate;

    // Functions
    self.downloadFloorPlan = downloadFloorPlan;
    self.getRevisionLabel = getRevisionLabel;
    self.onRevisionSelect = onRevisionSelect;
    self.onPageSelect = onPageSelect;
    self.rotate = rotate;
    self.toggleAllPins = toggleAllPins;
    self.togglePinNames = togglePinNames;
    self.toggleCanvasControl = toggleCanvasControl;
    self.getCanvasControlIcon = getCanvasControlIcon;
    self.isRotationDisabled = isRotationDisabled;

    // =================
    // Life Cycle
    // =================

    self.$onChanges = function (changes) {
      if (changes.page || changes.revision) {
        if (changes.page) {
          // to ensure one way binding
          self.modelPage = angular.copy(self.page);
        }
      }
    };

    // =================
    // Public Functions
    // =================

    /**
     * Download the current version of the floor plan. Will not include pins.
     */
    function downloadFloorPlan() {
      $window.location.href = self.revision.public_url;
    }

    /**
     * Get label for revision number, append "(current)" to current revision
     *
     * @param {Object} revision  Document revision
     */
    function getRevisionLabel(revision) {
      var version = revision.document_rev_num;
      return isRevisionCurrent(revision) ? version + " (current)" : version;
    }

    /**
     * Determine if rotation is disabled
     */
    function isRotationDisabled() {
      return (
        !self.currentRevision || !isRevisionCurrent() || !!self.disableRotation
      );
    }

    /**
     * Handle revision selection
     *
     * @param {Object} revision Selected revision
     */
    function onRevisionSelect(revision) {
      self.revision = revision;
      self.onRevisionChange({
        $event: {
          revision: revision,
          current: isRevisionCurrent(revision),
        },
      });
    }

    /**
     * Handle floor plan page select
     *
     * @param {Number} page New page
     */
    function onPageSelect(page) {
      // ensure we are comparing strings
      var pageString = page ? page.toString() : null;
      var previousPageString = self.page ? self.page.toString() : null;

      if (pageString !== previousPageString) {
        $scope.$emit(EVENT_FLOOR_NEW_PAGE_SELECTED, {
          page: pageString, // plan view app needs this to be a string
        });
      }
    }

    /**
     * Handle plan view rotation
     */
    function rotate() {
      if (!self.isRotationDisabled()) {
        self.onRotate();
      }
    }

    /**
     * Hide/show all pins
     */
    function toggleAllPins() {
      var hidePins = !self.hidePins;
      self.onTogglePins({
        $event: { hidePins: hidePins },
      });
      if (hidePins && self.showPinNames) {
        self.togglePinNames();
      }
      self.hidePins = hidePins;
    }

    /**
     * Hide/show pin names
     */
    function togglePinNames() {
      if (self.hidePins) return;
      self.showPinNames = !self.showPinNames;
      self.onTogglePinNames();
    }

    /**
     * Hide/show canvas control (this element)
     */
    function toggleCanvasControl() {
      self.canvasControlCollapsed = !self.canvasControlCollapsed;
    }

    /**
     * Returns the md-icon text based on the collapsed state of canvas control
     */
    function getCanvasControlIcon() {
      return self.canvasControlCollapsed ? "expand_less" : "expand_more";
    }

    // =================
    // Private Functions
    // =================

    /**
     * Check if the passed in revision is the current revision for the document
     *
     * @param {*} [revision] Document revision, defaults to selected revision
     */
    function isRevisionCurrent(revision) {
      if (!revision) {
        revision = self.revision;
      }
      var current = self.currentRevision.document_rev_num;
      var version = revision.document_rev_num;
      return current === version;
    }
  }
})();
