import { Dialog, DialogProps } from "@mui/material";
import { FC } from "react";
import { AttachmentDialog } from "../attachment-dialog/AttachmentDialog";

export interface AttachmentBuildingDialog extends DialogProps {
  organization: string;
  onClose: (documents: any) => void;
}

export const AttachmentBuildingDialog: FC<AttachmentBuildingDialog> = ({
  organization,
  onClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}) => {
  return (
    <Dialog fullWidth maxWidth="lg" {...props}>
      <AttachmentDialog
        organization={organization}
        entityType="report"
        open={true}
        imagesOnly={true}
        isReportBuilder={true}
        skipCreatingAttachments={false}
        onClose={(documents) => {
          onClose(documents);
        }}
      />
    </Dialog>
  );
};
