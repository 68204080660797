(() => {
  angular
    .module("akitabox.ui.dialogs.asset.costLineSelection")
    .component("abxCostDivisionSelectionTree", {
      bindings: {
        asset: "<abxAsset",
        catalog: "<abxCatalog",
        selectedDivisionUniformat: "<abxSelectedDivisionUniformat",
        onSelectedDivisionChange: "<abxOnSelectedDivisionChange",
        searchQuery: "<abxSearchQuery",
        onSearchQueryChange: "<abxOnSearchQueryChange",
      },
      controllerAs: "vm",
      controller: AbxCostDivisionSelectionTree,
      templateUrl:
        "app/core/ui/dialogs/asset/cost-line-selection/cost-division-selection-tree/cost-division-selection-tree.component.html",
    });

  /* @ngInject */
  function AbxCostDivisionSelectionTree(
    // Angular
    $q,
    // Services
    CostLineService,
    CostDataService,
    ToastService
  ) {
    const self = this;

    /**
     * @typedef { object } divisionNode
     * @property { divisionNode[] | null } children Division children if fetched,
     *  otherwise null.
     * @property { string } uniformat Cost division uniformat
     * @property { string } description Cost division description
     * @property { number } level How far down the tree this division node is
     *  used to control the visual hierarchy
     * @property { boolean } expanded True if this division is expanded (will
     *  show either children or a spinner)
     * @property { boolean } hasChildren True if the division can be further
     *  expanded. False for leaves
     * @property { ({model: string}) => void } onSelectedDivisionChange Invoked when
     *  the user selects a cost division. Model is the uniformat
     */

    /**
     * The model for the entire division tree.
     * @type { divisionNode }
     */
    self.divisionTree = _createDivisionTree();

    self.loading = false;

    // Functions
    self.handleDivisionClick = handleDivisionClick;
    self.debouncedOnSearchQueryChange;

    let _initPromise = _fetchBaseDivisions();

    // ------------------------
    //   Life cycle
    // ------------------------

    self.$onChanges = function (changes) {
      const changeInvalidatesTree = Boolean(
        (changes.asset && !changes.asset.isFirstChange()) ||
          (changes.catalog && !changes.catalog.isFirstChange())
      );
      const canFetchTree = Boolean(self.asset && self.catalog);

      if (changes.onSearchQueryChange && self.onSearchQueryChange) {
        self.debouncedOnSearchQueryChange = angular.debounce(
          self.onSearchQueryChange,
          500
        );
      }

      if (changeInvalidatesTree && canFetchTree) {
        _initPromise = _fetchBaseDivisions();
      }

      if (changes.selectedDivisionUniformat && self.selectedDivisionUniformat) {
        _initPromise.then(() => {
          self.divisionTree.children.forEach(_collapseRecursive);
          return _expandTo(self.selectedDivisionUniformat);
        });
      } else if (changes.searchQuery && self.searchQuery) {
        const query = self.searchQuery;
        _initPromise.then(() => {
          self.divisionTree.children.forEach(_collapseRecursive);
          if (CostDataService.looksLikeRSMeansUniformat(query)) {
            return _expandTo(query);
          } else {
            return $q.resolve();
          }
        });
      }
    };

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * @param { divisionNode } divisionNode
     */
    function handleDivisionClick(divisionNode) {
      if (!divisionNode.hasChildren) {
        self.onSelectedDivisionChange({ model: divisionNode.uniformat });
      } else {
        divisionNode.expanded = !divisionNode.expanded;
        if (!divisionNode.children && divisionNode.expanded) {
          _fetchSubDivisions(divisionNode);
        }
        if (divisionNode.expanded) {
          _collapseSiblings(divisionNode);
        }
      }
    }

    // =================
    // Private Functions
    // =================

    let _cancelToken = 0;
    function _expandTo(uniformat) {
      const curCancelToken = _cancelToken + 1;
      _cancelToken = curCancelToken;
      return _expandToRecurse(
        uniformat.toLowerCase(),
        self.divisionTree,
        () => _cancelToken !== curCancelToken
      );
    }
    /**
     * Expand the cost costline selection tree to the specified uniformat
     * (as much as possible).
     * @param { string } uniformat
     * @param { divisionNode } node The node to start expanding from.
     * @return { Promise<void> }
     */
    function _expandToRecurse(uniformat, node, isCancelled) {
      if (isCancelled()) {
        return $q.resolve();
      }
      /**
       * Base case:
       * - If node is falsy, we've fallen off the end of a tree
       *    without finding an appropriate match, and have expanded
       *    as much as possible.
       * - If node is a leaf, there's no more expansion to do,
       *    so just quit early.
       */
      if (!node) {
        return $q.resolve();
      }

      if (!node.hasChildren) {
        self.handleDivisionClick(node);
        return $q.resolve();
      }

      node.expanded = true;

      // select an appropriate fetch function based on whether we're
      // getting base divisions or child divisions.
      let fetchFn = () => $q.resolve();
      if (!node.children) {
        if (node.level === 0) {
          fetchFn = _fetchBaseDivisions;
        } else {
          fetchFn = () => _fetchSubDivisions(node);
        }
      }

      return fetchFn().then(() => {
        if (!node.children) {
          // This likely indicates that something went wrong during fetching.
          // The user will see a toast, and this function will quietly
          //  stop trying to expand.
          return;
        }

        const nextNode = node.children.find((child) =>
          uniformat.startsWith(child.uniformat.toLowerCase())
        );
        return _expandToRecurse(uniformat, nextNode, isCancelled);
      });
    }

    /**
     * Collapse all siblings of a node.
     * @param { divisionNode } divisionNode
     */
    function _collapseSiblings(divisionNode) {
      divisionNode.parent.children
        .filter((childOrSelf) => childOrSelf !== divisionNode)
        .forEach(_collapseRecursive);
    }

    /**
     * @param { divisionNode } divisionNode Collapse the specified node
     *  and all of its children recursively.
     *
     * @note This method will terminate early once it hits a
     *  non-expanded node since children shouldn't be getting
     *  expanded while their parents aren't.
     */
    function _collapseRecursive(divisionNode) {
      if (!divisionNode.expanded) {
        return;
      }
      divisionNode.expanded = false;
      if (divisionNode.children) {
        divisionNode.children.forEach(_collapseRecursive);
      }
    }

    /**
     * Populating the first level of the cost division tree.
     */
    function _fetchBaseDivisions() {
      self.loading = true;
      self.divisionTree = _createDivisionTree();
      return CostLineService.getRSMeansBaseDivisions(
        self.asset.building,
        self.catalog
      )
        .then((divisions) => {
          self.divisionTree.children = divisions.map((division) =>
            _createDivisionNode(division, self.divisionTree)
          );
        })
        .catch(ToastService.showError)
        .finally(() => {
          self.loading = false;
        });
    }

    /**
     * Create a new, empty division tree.
     * @returns { divisionNode } The root node
     */
    function _createDivisionTree() {
      return {
        expanded: false,
        level: 0,
        hasChildren: true,
        children: null,
        description: "",
        uniformat: "",
      };
    }

    /**
     * Create a new division node
     * @param { object } division RSMeans cost division
     * @param { divisionNode } parent Parent node
     * @returns { divisionNode }
     */
    function _createDivisionNode(division, parentNode) {
      const level = parentNode.level + 1;
      const parent = parentNode || null;

      return {
        expanded: false,
        description: division.description,
        uniformat: division.divisionCode,
        hasChildren: division.childDivisions.recordCount !== 0,
        children: null,
        level,
        parent,
      };
    }

    /**
     * Load subdivisions for the specified tree node.
     * @param { divisionNode } parentNode
     */
    function _fetchSubDivisions(parentNode) {
      parentNode.children = null;
      return CostLineService.getRSMeansDivisionsChildren(
        self.asset.building,
        self.catalog,
        parentNode.uniformat
      )
        .then((divisions) => {
          parentNode.children = divisions.map((division) =>
            _createDivisionNode(division, parentNode)
          );
        })
        .catch(ToastService.showError);
    }
  }
})();
