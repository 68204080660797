(function () {
  angular
    .module("akitabox.core.services.costData", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("CostDataService", CostData);

  /** @ngInject */
  function CostData(models, HttpService) {
    /**
     * Account service
     *
     * @type {Object}
     */
    var service = {
      // Routes
      buildBaseRoute: buildBaseRoute,
      buildBaseDivisionsRoute: buildBaseDivisionsRoute,
      // Retrieve
      getReleases: getReleases,
      getLocations: getLocations,
      getCatalogs: getCatalogs,
      getTopLevelDivisions: getTopLevelDivisions,
      getDivisionDetails: getDivisionDetails,
      getDivisionChildren: getDivisionChildren,
      getCostLines,
      searchCostLines,
      looksLikeRSMeansUniformat,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Routes
     * ****************** */

    function buildBaseRoute(buildingId) {
      return (
        "/" + models.BUILDING.ROUTE_PLURAL + "/" + buildingId + "/costdata"
      );
    }

    function buildBaseDivisionsRoute(buildingId, catalogType) {
      return buildBaseRoute(buildingId) + "/" + catalogType + "/divisions";
    }

    /**
     * Get a list of availible releases from RS Means
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {*} params              an object of query string parameters
     */
    function getReleases(buildingId, params) {
      var route = buildBaseRoute(buildingId) + "/releases";
      return HttpService.get(route, params);
    }

    /**
     * Get a list of availible locations from RS Means
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {*} params              an object of query string parameters
     */
    function getLocations(buildingId, params) {
      var route = buildBaseRoute(buildingId) + "/locations";
      return HttpService.get(route, params);
    }

    /**
     * Get a list of availible catalogs from RS Means
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {*} params              an object of query string parameters
     */
    function getCatalogs(buildingId, params) {
      var route = buildBaseRoute(buildingId) + "/catalogs";
      return HttpService.get(route, params);
    }

    /**
     * Get a list of top level divisions for a given catalog type ("fmr" or "fpm")
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {String} catalogType    the catalog type for which to fetch cost data ("fmr" or "fpm")
     * @param {*} params              an object of query string parameters
     */
    function getTopLevelDivisions(buildingId, catalogType, params) {
      var route = buildBaseDivisionsRoute(buildingId, catalogType);
      return HttpService.get(route, params);
    }

    /**
     * Get the details of a given division code in a given catalog type ("fmr" or "fpm")
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {String} catalogType    the catalog type for which to fetch cost data ("fmr" or "fpm")
     * @param {String} divisionCode   uniformat or partial unifoprmat code
     * @param {*} params              an object of query string parameters
     */
    function getDivisionDetails(buildingId, catalogType, divisionCode, params) {
      var route =
        buildBaseDivisionsRoute(buildingId, catalogType) + "/" + divisionCode;
      return HttpService.get(route, params);
    }

    /**
     * Get the child divisions of a given division code in a given catalog type ("fmr" or "fpm")
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {String} catalogType    the catalog type for which to fetch cost data ("fmr" or "fpm")
     * @param {String} divisionCode   uniformat or partial unifoprmat code
     * @param {*} params              an object of query string parameters
     */
    function getDivisionChildren(
      buildingId,
      catalogType,
      divisionCode,
      params
    ) {
      var route =
        buildBaseDivisionsRoute(buildingId, catalogType) +
        "/" +
        divisionCode +
        "/children";
      return HttpService.get(route, params);
    }

    /**
     * Get the cost lines of a given division code in a given catalog type ("fmr" or "fpm")
     *
     * @param {String} buildingId     the building id in which to fech cost data
     * @param {String} catalogType    the catalog type for which to fetch cost data ("fmr" or "fpm")
     * @param {String} divisionCode   uniformat or partial unifoprmat code
     * @param {*} params              an object of query string parameters
     */
    function getCostLines(buildingId, catalogType, divisionCode, params) {
      var route =
        buildBaseDivisionsRoute(buildingId, catalogType) +
        "/" +
        divisionCode +
        "/cost_lines";
      return HttpService.get(route, params);
    }

    function searchCostLines(buildingId, catalogType, searchTerm) {
      const route = `${buildBaseRoute(
        buildingId,
        catalogType
      )}/${catalogType}/cost_lines`;
      return HttpService.get(route, {
        searchTerm,
      });
    }

    function looksLikeRSMeansUniformat(uniformat) {
      return /^[a-zA-Z][0-9]+$/.test(uniformat);
    }
  }
})();
