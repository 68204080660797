(function () {
  angular
    .module("akitabox.core.services.timeCode", [
      "akitabox.constants",
      "akitabox.core.services.http",
    ])
    .factory("TimeCodeService", TimeCodeService);

  /** @ngInject */
  function TimeCodeService(
    //Constants
    models,
    //Services
    HttpService
  ) {
    var service = {
      getByOrganization: getByOrganization,
      getAll: getAll,
      getActive: getActive,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------
    function timeCodeBaseRoute(organizationId) {
      return (
        "/" +
        models.ORGANIZATION.ROUTE_PLURAL +
        "/" +
        organizationId +
        "/" +
        models.TIME_CODE.ROUTE_PLURAL
      );
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Retrieve
     ******************* */

    function getByOrganization(organizationId, params) {
      var route = timeCodeBaseRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getAll(organizationId) {
      var route = timeCodeBaseRoute(organizationId);
      return HttpService.get(route);
    }

    function getActive(organizationId) {
      var route = timeCodeBaseRoute(organizationId);
      var params = { archived: false };
      return HttpService.get(route, params);
    }
  }
})();
