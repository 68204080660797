(function () {
  /**
   * @ngdoc module
   * @name akitabox.desktop.directives.stepList
   */
  angular
    .module("akitabox.desktop.directives.stepList", [
      "akitabox.core.toast",
      "akitabox.core.services.job",
    ])
    .controller("AbxStepListController", AbxStepListController)
    .directive("abxStepList", AbxStepListDirective);

  /**
   * @ngdoc directive
   * @module akitabox.desktop.directives.stepList
   * @name AbxStepList
   *
   * @restrict E
   *
   * @description
   * `<abx-step-list>` is a list of steps in the current job.
   *
   * @usage
   *  <abx-step-list
   *          abx-job="vm.job"
   *          abx-models="vm.steps"
   *  </abx-step-list>
   *
   * @ngInject
   */
  function AbxStepListDirective() {
    return {
      restrict: "E",
      templateUrl: "app/desktop/directives/step-list/step-list.html",
      controller: "AbxStepListController",
      controllerAs: "vm",
      bindToController: true,
      scope: {
        building: "=abxBuilding",
        job: "=abxJob",
        models: "=?abxModels",
        currentStep: "=?abxCurrentStep",
        view: "<?abxView",
      },
    };
  }

  /* @ngInject */
  function AbxStepListController(
    // Angular
    $attrs,
    $scope,
    $interval,
    $timeout,
    // Services
    AdminJobService,
    ToastService
  ) {
    var self = this;

    // Constants
    var STEPS_PER_PAGE = 10;
    var UPDATE_CURRENT_INTERVAL = 1500;

    // Private attributes
    var currentPage = 0;
    var hasCurrentStep = angular.isDefined($attrs.abxCurrentStep);
    var updateCurrentInterval;

    // Public attributes
    self.loading = true;
    self.errored = false;
    self.showChanged = false;
    self.showErrorBtn = false;
    self.numPages = Math.ceil(self.job.num_steps / STEPS_PER_PAGE);

    // Functions
    self.goToPage = goToPage;

    init();

    // ------------------------
    //   Private Functions
    // ------------------------

    function init() {
      $scope.$watch("vm.job.num_errored_steps", function () {
        if (self.errored) goToPage(currentPage);
      });
      $scope.$watch("vm.view", function (newView, oldView) {
        if (oldView !== newView && newView !== null) {
          self.goToPage(0, newView);
        }
      });
      if (self.models) return;
      goToPage(0);
      if (hasCurrentStep) {
        // Start current processing step update interval
        if (self.job.status === "processing") {
          updateCurrentInterval = $interval(
            refreshCurrentStep,
            UPDATE_CURRENT_INTERVAL
          );
        }
        // Watch job status for changes. If no longer processing,
        // cancel current processing step update interval
        var cancelStatusWatcher = $scope.$watch(
          "vm.job.status",
          function (status) {
            if (status !== "processing") {
              cancelStatusWatcher();
              $interval.cancel(updateCurrentInterval);
            }
          }
        );
      }
    }

    function refreshCurrentStep() {
      var params = {
        status: "processing",
        limit: 1,
      };
      return AdminJobService.getSteps(self.job._id, params)
        .then(function (steps) {
          if (!steps || !steps.length) return;
          var step = steps[0];
          if (!self.currentStep) {
            self.currentStep = step;
          } else if (self.currentStep._id !== step._id) {
            $timeout(function () {
              self.currentStep = step;
            }, 1000);
          }
        })
        .catch(function (err) {
          ToastService.showError(err);
          self.currentStep = null;
        });
    }

    function refreshSteps(page) {
      var params = {
        skip: page * STEPS_PER_PAGE,
        limit: STEPS_PER_PAGE,
      };
      if (self.errored) params.status = "errored";
      if (self.showChanged) params.made_changes = true;
      self.loading = true;
      return AdminJobService.getSteps(self.job._id, params)
        .then(function (steps) {
          self.models = steps;
          if (self.errored) {
            self.numPages = Math.ceil(
              self.job.num_errored_steps / STEPS_PER_PAGE
            );
          } else {
            self.numPages = Math.ceil(self.job.num_steps / STEPS_PER_PAGE);
          }
        })
        .catch(ToastService.showError)
        .finally(function () {
          self.loading = false;
        });
    }

    function goToPage(page, view) {
      if (view) {
        if (view === "showErrors") {
          self.errored = true;
          self.showChanged = false;
        } else if (view === "showAll") {
          self.errored = false;
          self.showChanged = false;
        } else if (view === "showChanged") {
          self.errored = false;
          self.showChanged = true;
        }
      }
      currentPage = page;
      return refreshSteps(page);
    }
  }
})();
