(function () {
  angular.module("akitabox.ui.dialogs.qrScanner", [
    "ngMaterial",
    "bcQrReader",
    "webcam",
    "akitabox.core.utils",
    "akitabox.core.services.qrcode",
    "akitabox.ui.dialogs.qrCode.manualEntry",
  ]);
})();
