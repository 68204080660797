(function () {
  angular.module("akitabox.ui.dialogs.request.create", [
    "ngMaterial",
    "akitabox.core.constants",
    "akitabox.core.router",
    "akitabox.core.services.asset",
    "akitabox.core.services.floor",
    "akitabox.core.services.issueType",
    "akitabox.core.services.room",
    "akitabox.core.services.request",
    "akitabox.core.services.flag",
    "akitabox.core.toast",
    "akitabox.ui.components.input",
  ]);
})();
