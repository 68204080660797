(function () {
  angular
    .module("akitabox.ui.dialogs.qrScanner")
    .factory("QrScannerDialog", QrScannerDialogService);

  function QrScannerDialogService($mdDialog, $mdMedia) {
    var modal = {
      templateUrl: "app/core/ui/dialogs/qr-scanner/qr-scanner.dialog.html",
      controller: "QrScannerDialogController",
      controllerAs: "vm",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      clickOutsideToClose: false,
      escapeToClose: false,
      focusOnOpen: false,
      fullscreen: true,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    var defaultLocals = {
      redirect: true,
      returnModel: false,
    };

    /**
     * QrScannerDialog service
     *
     * @type {Object}
     */
    var service = {
      show: show,
    };

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    /**
     * Show dialog
     *
     * @param {Object} [options] - Dialog options
     * @param {String} [options.redirect=true] - Whether to redirect or not.
     * @param {Boolean} [options.returnModel=false] - Whether to return the full
     *    QR code model or not. False returns only the qrCode ID.
     */
    function show(options) {
      if (!options) {
        options = {};
      }
      var dialogOptions = angular.extend({}, modal, defaultLocals, options);
      return $mdDialog.show(dialogOptions);
    }
  }
})();
