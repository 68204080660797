(function () {
  angular
    .module("akitabox.ui.dialogs.changeDecommissionDate")
    .factory("ChangeDecommissionDateDialog", ChangeDecommissionDateDialog);

  /* @ngInject */
  function ChangeDecommissionDateDialog($log, $mdDialog, $mdMedia, $q) {
    var modal = {
      templateUrl:
        "app/core/ui/dialogs/change-decommission-date/changeDecommissionDate.dialog.html",
      controller: "ChangeDecommissionDateDialogController",
      controllerAs: "dialog",
      bindToController: true,
      parent: angular.element(document.body),
      closeTo: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      focusOnOpen: false,
    };

    // Enable fullscreen mode while on mobile
    if ($mdMedia("sm") || $mdMedia("xs")) {
      modal.fullscreen = true;
    }

    return {
      show: show,
    };

    /**
     * @param {object} options
     * @param {object} options.locals
     * @param {Asset} options.locals.asset - the asset to decommission
     * @param {Room} options.locals.room - the room to decommission
     * @return {Promise<void>}
     */
    function show(options) {
      var error = new Error("Sorry, we weren't able to complete your request");
      var hasErrored = false;

      if (!options) {
        $log.error("ChangeDecommissionDateDialog: No options provided");
        hasErrored = true;
      } else if (!options.locals) {
        $log.error("ChangeDecommissionDateDialog: No locals provided");
        hasErrored = true;
      } else if (!options.locals.asset && !options.locals.room) {
        $log.error("ChangeDecommissionDateDialog: No room/asset provided");
        hasErrored = true;
      } else if (
        options.locals.asset &&
        !options.locals.asset.is_decommissioned
      ) {
        $log.error("ChangeDecommissionDateDialog: Asset is not decommissioned");
        hasErrored = true;
      } else if (
        options.locals.room &&
        !options.locals.room.is_decommissioned
      ) {
        $log.error("ChangeDecommissionDateDialog: Room is not decommissioned");
        hasErrored = true;
      }

      if (hasErrored) {
        return $q.reject(error);
      }

      return $mdDialog.show(angular.extend({}, modal, options));
    }
  }
})();
