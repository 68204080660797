(function () {
  angular
    .module("akitabox.core.filters.trustAs", [])
    .filter("trustAs", TrustAsFilter);

  /* @ngInject */
  function TrustAsFilter($sce) {
    return function (value, type) {
      switch (type) {
        case "url":
          return $sce.trustAsResourceUrl(value);
        case "html":
          return $sce.trustAsHtml(value);
        default:
          return $sce.trustAsHtml(value);
      }
    };
  }
})();
