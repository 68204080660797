import CloseIcon from "@mui/icons-material/Close";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  IconButton,
  DialogProps,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { api } from "../api";
import {
  ReportResponse,
  ReportTemplateResponse,
  ReportsCreateRequest,
} from "@akitabox/api-client";
import { AbxDataGrid, AbxGridColDef } from "../lists/abx-data-grid/AbxDataGrid";
import { BuildingResponse } from "@akitabox/api-client";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import {
  GridFilterModel,
  GridRowSelectionModel,
  GridSortModel,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import { useGlobalSnackbar } from "../consecutive-snackbar/GlobalSnackbar";
import { Link } from "react-router-dom";
import { isAxiosError } from "axios";

const stringFilterOperators = getGridStringOperators().filter(
  (o) => o.value === "contains" || o.value === "equals"
);
const columns: AbxGridColDef<BuildingResponse>[] = [
  {
    type: "string",
    field: "name",
    headerName: "Name",
    abxGridColType: "basic",
    filterOperators: stringFilterOperators,
  },
  {
    type: "string",
    field: "address",
    headerName: "Address",
    abxGridColType: "basic",
    filterOperators: stringFilterOperators,
  },
  {
    type: "string",
    field: "city",
    headerName: "City",
    abxGridColType: "basic",
    filterOperators: stringFilterOperators,
  },
  {
    type: "string",
    field: "state",
    headerName: "State",
    abxGridColType: "basic",
    filterOperators: stringFilterOperators,
  },
  {
    type: "string",
    field: "postal_code",
    headerName: "Zip Code",
    abxGridColType: "basic",
    filterOperators: stringFilterOperators,
  },
];

const allowedPageSizes = [25, 50, 100];

export interface GenerateReportDialogProps extends DialogProps {
  // closes the dialog
  close: (report?: ReportResponse) => void;
  reportTemplate: ReportTemplateResponse;
}
export const GenerateReportDialog: FunctionComponent<
  GenerateReportDialogProps
> = ({ close, reportTemplate, ...props }) => {
  // conexts
  const { simple } = useGlobalSnackbar();

  // ref
  const buildingNamesRef = useRef<{ [key: BuildingResponse["_id"]]: string }>(
    {}
  );

  // component state
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [progressTotal, setProgressTotal] = useState<number>(0);
  const [errors, setErrors] = useState<{
    [key: BuildingResponse["_id"]]: { buildingName: string; error: string };
  }>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);

  // data grid state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: allowedPageSizes[0],
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  // api calls
  const { data: buildingsResponse, isLoading } = makeUseServiceCall(
    api.buildings.getByOrganization
  )({
    limit: paginationModel.pageSize,
    skip: paginationModel.page * paginationModel.pageSize,
    organizationId: reportTemplate.organization,
    ...filterModel.items.reduce((acc: any, item) => {
      acc[item.field] = item.value;
      return acc;
    }, {}),
    sort: sortModel.map((item) => `${item.field},${item.sort}`).join(","),
  });

  const buildings = useMemo(() => {
    if (!buildingsResponse || buildingsResponse.data.length === 0) {
      return [];
    }

    for (const building of buildingsResponse.data) {
      buildingNamesRef.current[building._id] = building.name;
    }

    return buildingsResponse.data;
  }, [buildingsResponse]);

  const { data: buildingsCountRespnse, isLoading: isCounting } =
    makeUseServiceCall(api.buildings.count)({
      organizationId: reportTemplate.organization,
      ...filterModel.items.reduce((acc: any, item) => {
        acc[item.field] = item.value;
        return acc;
      }, {}),
    });

  const count = useMemo(() => {
    if (!buildingsCountRespnse) {
      return 0;
    }
    return buildingsCountRespnse.data.count;
  }, [buildingsCountRespnse]);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortModel([{ field: "name", sort: "asc" }]);
      return;
    }
    const sortItem = sortModel[0];
    if (!sortItem) return;
    setSortModel([
      {
        field: sortItem.field,
        sort: sortItem.sort,
      },
    ]);
  }, []);

  return (
    <Dialog {...props}>
      <DialogTitle component="h6">
        Generate Report{reportTemplate.context === "building" ? "(s)" : ""}
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => close()}
          css={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {showErrors && (
          <Box>
            <p css={{ textAlign: "center", fontWeight: "bold" }}>
              The following {Object.keys(errors).length}/{progressTotal}{" "}
              buildings had errors while generating their reports.
            </p>
            <ul>
              {Object.entries(errors).map(
                ([buildingId, { buildingName, error }]) => (
                  <li key={buildingId}>
                    <b>{buildingName}:</b> {error}
                  </li>
                )
              )}
            </ul>
          </Box>
        )}
        {!showErrors && (
          <>
            <p css={{ textAlign: "center", fontWeight: "bold" }}>
              {reportTemplate.context === "organization"
                ? "Please selection any combination of buildings to have their information be pulled into this single report."
                : "Please select each building you would like to generate this report for."}{" "}
            </p>
            <AbxDataGrid
              rowCount={count}
              rows={buildings}
              columns={columns}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              pageSizeOptions={allowedPageSizes}
              onPaginationModelChange={setPaginationModel}
              headerFilters
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
              }}
              height={count > 0 ? "calc(90%)" : "200px"}
              checkboxSelection
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={setRowSelectionModel}
              keepNonExistentRowsSelected
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={() => close()} disabled={isLoading || isCounting}>
            {showErrors ? "Close" : "Cancel"}
          </Button>
          {!showErrors && (
            <LoadingButton
              loading={isSaving}
              loadingPosition="start"
              startIcon={<SubjectIcon />}
              variant="contained"
              color="primary"
              disabled={isLoading || isCounting}
              onClick={async () => {
                if (!rowSelectionModel.length) {
                  setIsSaving(false);
                  setProgress(0);
                  setProgressTotal(0);
                  return;
                }

                const report: ReportsCreateRequest = {
                  name: reportTemplate.name,
                  template: reportTemplate._id,
                };

                setIsSaving(true);
                setProgress(0);

                if (reportTemplate.context === "organization") {
                  setProgressTotal(1);

                  report.name = `${reportTemplate.name}`;

                  if (rowSelectionModel.length === 1) {
                    report.building = rowSelectionModel[0] as string; // this is the building id
                  } else {
                    const buildingIds: string[] = [];

                    for (const buildingId of rowSelectionModel) {
                      if (!buildingId) {
                        continue;
                      }
                      buildingIds.push(buildingId.toString());
                    }

                    if (buildingIds.length > 1) {
                      report.building = `$in,${buildingIds.join(",")}`;
                    } else if (buildingIds.length === 1) {
                      report.building = buildingIds[0];
                    }
                  }

                  try {
                    const response = await api.reports.create({
                      organizationId: reportTemplate.organization,
                      report,
                    });
                    setProgress((prevProgress) => prevProgress + 1);
                    simple(
                      <Box>
                        Report Generated!{" "}
                        <Link
                          to={{
                            pathname: `/report_builder/${response.data._id}`,
                          }}
                          target="_blank"
                        >
                          Click here to view your new report.
                        </Link>
                      </Box>
                    );
                  } catch (err) {
                    let message =
                      "An unkown error occurred. Unable to generate report.";
                    if (isAxiosError(err)) {
                      message = err.message;
                      if (err.response?.data?.error?.message) {
                        message = err.response.data.error.message as string;
                      }
                    }
                    simple(message, { severity: "error" });
                  } finally {
                    setIsSaving(false);
                    close();
                  }
                } else {
                  setProgressTotal(rowSelectionModel.length);
                  let errorCounter = 0;

                  for (const rowSelectionModelId of rowSelectionModel) {
                    const buildingId = rowSelectionModelId as string;
                    const buildingName = buildingNamesRef.current[buildingId];
                    if (!buildingName) {
                      continue;
                    }

                    report.name = `${reportTemplate.name} - ${buildingName}`;
                    report.building = buildingId;

                    try {
                      await api.reports.create({
                        organizationId: reportTemplate.organization,
                        report,
                      });
                    } catch (err) {
                      let message = `An unkown error occurred. Unable to generate report.`;
                      if (isAxiosError(err)) {
                        message = err.message;
                        if (err.response?.data?.error?.message) {
                          message = err.response.data.error.message as string;
                        }
                      }
                      setErrors((prevErrors) => {
                        return {
                          ...prevErrors,
                          [buildingId]: {
                            buildingName: buildingName,
                            error: message,
                          },
                        };
                      });
                      errorCounter++;
                      continue;
                    } finally {
                      setProgress((prevProgress) => prevProgress + 1);
                    }
                  }

                  if (errorCounter === 0) {
                    simple(
                      <Box>
                        All report(s) generated!{" "}
                        <Link to={{ pathname: `/report_builder/reports` }}>
                          Click here to view your report(s).
                        </Link>
                      </Box>
                    );
                    close();
                  }
                  setIsSaving(false);
                  setShowErrors(errorCounter > 0);
                }
              }}
            >
              {isSaving ? `Progress: ${progress}/${progressTotal}` : "Generate"}
            </LoadingButton>
          )}
          {showErrors && (
            <Button
              variant="contained"
              onClick={() => {
                setErrors({});
                setProgress(0);
                setProgressTotal(0);
                setShowErrors(false);
              }}
            >
              Back To List
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
